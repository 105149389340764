/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  AddCertificate,
  AddPortfolio,
  Button,
  ButtonMenu,
  CardRegister,
  ViewCertificate,
  ViewPortfolio,
} from "../../../components";
import { userPortfolioType } from "../../../types/enumerators";
import {
  Media,
  UserCurriculumCertificate,
  UserCurriculumPortfolio,
} from "../../../types/interfaces";
import { RootState } from "../../../store/reducers";
import { updateUser } from "../../../store/actions/userActions";
import { Add } from "../../../../assets/customIcons";
import { addCompanyToUser } from "../../../services/functions";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
  onNext: () => void;
}

const Certificates = ({ setIsValid, onNext }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [_user, _setUser] = useState(user);
  const [type, setType] = useState<string>();
  const [width, setWidth] = useState(window.innerWidth);
  const [editCertificateIndex, setEditCertificateIndex] = useState(-1);
  const [editPortfolioIndex, setEditPortfolioIndex] = useState(-1);
  const [portfolio, setPortfolio] = useState<UserCurriculumPortfolio>({
    title: "",
    url: "",
    description: "",
    medias: [],
  });
  const [certificate, setCertificate] = useState<UserCurriculumCertificate>({
    title: "",
    companyId: "",
    description: "",
    medias: [],
  });
  const [portfolios, setPortfolios] = useState(
    user?.curriculum?.portfolios ?? [],
  );
  const [certificates, setCertificates] = useState(
    user?.curriculum?.certificates ?? [],
  );

  const setCompanyToUser = useCallback(async () => {
    const response = await addCompanyToUser(user);
    _setUser(response);
  }, [user]);

  useEffect(() => {
    if (user?._id && !_user?._id) setCompanyToUser();
  }, [_user?._id, setCompanyToUser, user?._id]);

  useEffect(() => {
    if (_user?.curriculum?.certificates?.length && !certificates?.length)
      setCertificate(_user?.curriculum?.certificates);
  }, [
    _user?.curriculum?.certificates?.length,
    certificates?.length,
    _user?.curriculum?.certificates,
    setCertificate,
  ]);

  useEffect(() => {
    if (_user?.curriculum?.portfolios?.length && !portfolios?.length)
      setCertificate(_user?.curriculum?.portfolios);
  }, [
    _user?.curriculum?.portfolios?.length,
    portfolios?.length,
    _user?.curriculum?.portfolios,
    setPortfolio,
    setCertificate,
  ]);

  useEffect(() => {
    if (!certificates?.length && !portfolios?.length) setIsValid(false);
  }, [certificates?.length, portfolios?.length, setIsValid]);

  const onCancel = useCallback(() => {
    setType("");
    setEditCertificateIndex(-1);
    setEditPortfolioIndex(-1);
    setCertificate({
      title: "",
      companyId: "",
      description: "",
      medias: [],
    });
    setPortfolio({
      title: "",
      url: "",
      description: "",
      medias: [],
    });
  }, []);

  const addCertificate = useCallback(() => {
    let newCertificates = [...certificates] ?? [];

    if (editCertificateIndex !== -1) {
      newCertificates[editCertificateIndex] = certificate;
    } else if (newCertificates?.length) newCertificates.push(certificate);
    else newCertificates = [certificate];

    setCertificates(newCertificates);
    onCancel();
  }, [certificate, certificates, editCertificateIndex, onCancel]);

  const addPortfolio = useCallback(() => {
    let newPortfolios = [...portfolios] ?? [];

    if (editPortfolioIndex !== -1) {
      newPortfolios[editPortfolioIndex] = portfolio;
    } else if (newPortfolios?.length) newPortfolios.push(portfolio);
    else newPortfolios = [portfolio];

    setPortfolios(newPortfolios);
    onCancel();
  }, [editPortfolioIndex, onCancel, portfolio, portfolios]);

  const onRemoveCertificate = useCallback(
    (index: number) => {
      let oldCertificates = [...certificates] ?? [];
      oldCertificates.splice(index, 1);
      oldCertificates = oldCertificates.map((item: Media, i: number) => ({
        ...item,
        order: i,
      }));
      setCertificates(oldCertificates);
    },
    [certificates],
  );

  const onRemovePortfolio = useCallback(
    (index: number) => {
      let oldPortoflios = [...portfolios] ?? [];
      oldPortoflios.splice(index, 1);
      oldPortoflios = oldPortoflios.map((item: Media, i: number) => ({
        ...item,
        order: i,
      }));
      setPortfolios(oldPortoflios);
    },
    [portfolios],
  );

  const onEditCertificate = useCallback(
    (item: UserCurriculumCertificate, index: number) => {
      setEditCertificateIndex(index);
      setType("certificate");
      setCertificate({
        ...item,
        medias: item.medias,
      });
    },
    [setCertificate],
  );

  const onEditPortfolio = useCallback(
    (item: UserCurriculumPortfolio, index: number) => {
      setEditPortfolioIndex(index);
      setType(item?.type);
      setPortfolio({
        ...item,
        medias: item.medias ?? [],
      });
    },
    [],
  );

  const onSave = useCallback(() => {
    switch (type) {
      case userPortfolioType.LINK:
        addPortfolio();
        break;

      case userPortfolioType.PROJECT:
        addPortfolio();
        break;

      default:
        addCertificate();
        break;
    }
  }, [addCertificate, addPortfolio, type]);

  const onFinish = useCallback(() => {
    dispatch(
      updateUser({
        ...user,
        curriculum: { ...user?.curriculum, portfolios, certificates },
      }) as unknown as Action,
    );
    onNext();
  }, [certificates, dispatch, onNext, portfolios, user]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex-col margin-top-24 grid-column-5-12 grid-column-1-11-mobile">
      <CardRegister
        className="margin-mobile-register border-card"
        titleHeader="Comprovante"
        showBorder
      >
        <p className="title-input grid-column-1-4">
          <p className="helper-text-title-input">
            Anexe um documento comprobatório de sua formação básica em
            JavaScript. Você pode compartilhar um certificado e/ou portfólio.
          </p>
        </p>
        <div className="flex-row-center grid-column-1-4 margin-top-32">
          <ButtonMenu
            closeItemOnclick
            icon={<Add widthIcon={20} heightIcon={20} />}
            text="Adicionar"
            items={[
              {
                text: "Certificado",
                onClick: () => setType("certificate"),
              },
              {
                text: "Link do Portfolio",
                onClick: () => setType(userPortfolioType.LINK),
              },
              {
                text: "Projeto de Portfolio",
                onClick: () => setType(userPortfolioType.PROJECT),
              },
            ]}
            className="icon-button-primary icon-button-primary-mobile"
          />
        </div>
        {type === "certificate" && (
          <AddCertificate
            certificate={certificate}
            setItem={setCertificate}
            setIsValid={setIsValid}
            courseRegister
          />
        )}
        {(type === userPortfolioType.LINK ||
          type === userPortfolioType.PROJECT) && (
          <AddPortfolio
            type={type}
            portfolio={portfolio}
            setItem={setPortfolio}
            setIsValid={setIsValid}
          />
        )}
      </CardRegister>
      {!!type && (
        <div
          className={`div-btn-course flex-direction-column-reverse ${
            width < 991 && "margin-button"
          }`}
        >
          <Button
            text="Cancelar"
            className="btn-course margin-bottom-32-mobile"
            onClick={onCancel}
            outline
            appearance="secondary"
          />
          <Button
            text="Salvar"
            className="btn-course margin-left-32 margin-left-mobile"
            onClick={onSave}
            disabled={
              (type === "certificate" &&
                (!certificate?.title ||
                  !certificate?.companyId ||
                  !certificate?.title ||
                  !certificate?.date ||
                  !certificate?.description ||
                  certificate?.expirationDate === undefined)) ||
              (type === userPortfolioType.LINK &&
                (!portfolio?.title || !portfolio?.url)) ||
              (type === userPortfolioType.PROJECT &&
                (!portfolio?.title ||
                  !portfolio?.url ||
                  !portfolio?.startDate ||
                  portfolio?.endDate === undefined))
            }
          />
        </div>
      )}
      {(certificates?.length > 0 || portfolios?.length > 0) && (
        <div className="grid-column-5-12 divider margin-bottom-32 margin-top-32" />
      )}
      {certificates?.length > 0 && (
        <div
          className={`grid-column-5-12 ${width < 991 && "margin-button"}
          `}
        >
          <ViewCertificate
            hasCards
            isMyProfile
            items={certificates}
            onEdit={onEditCertificate}
            onRemove={onRemoveCertificate}
            className="padding-24"
          />
        </div>
      )}
      {portfolios?.length > 0 && (
        <div
          className={`grid-column-5-12 ${width < 991 && "margin-button"}
          `}
        >
          <ViewPortfolio
            hasCards
            isMyProfile
            items={portfolios}
            onEdit={onEditPortfolio}
            onRemove={(_, index) => onRemovePortfolio(index)}
            className="padding-24"
          />
        </div>
      )}
      <div className="div-btn-course">
        {!type && (
          <Button
            text="Finalizar"
            className={`btn-course ${width < 991 && "margin-button"}`}
            onClick={onFinish}
            // disabled={!certificates?.length && !portfolios?.length}
          />
        )}
      </div>
    </div>
  );
};

export default memo(Certificates);
