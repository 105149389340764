import React, { memo, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { Modal, Select } from "../../../components";
import { CourseStatus } from "../../../types/constants/Course";
import { ClassVacancyStages } from "../../../types/interfaces";
import { setLoad } from "../../../store/actions/configurationsActions";
import { filterClassVacancySubscription } from "../../../services/functions";
import "../style.scss";

interface ParamsProps {
  id: string;
}

interface Props {
  open: boolean;
  onclose: () => void;
  stages: ClassVacancyStages[];
  totalList: number;
}

const Candidate = ({
  open,
  onclose,
  stages,
  totalList = 0,
}: Props): JSX.Element => {
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState<{
    status?: string;
    stageId?: string;
  }>();

  const { id } = useParams<ParamsProps>();

  const dispatch = useDispatch();

  const optionsFilters = useCallback(
    (type: string) => {
      const result: { value: string | null; label: string }[] = [
        {
          value: null,
          label: "Todas",
        },
      ];

      if (type === "status")
        Object.keys(CourseStatus).forEach((key) => {
          result?.push({
            value: key,
            label: CourseStatus[key as keyof typeof CourseStatus],
          });
        });

      if (type === "stages" && stages)
        stages?.forEach((stage) =>
          result?.push({
            value: stage?.stage_id ?? "",
            label: stage?.title ?? "",
          }),
        );

      return result;
    },
    [stages],
  );

  const onChange = useCallback(
    (value, props: "stageId" | "status") =>
      setFilters((prev) => ({ ...prev, [props]: value })),
    [],
  );

  const getCandidates = useCallback(
    async (clear?: boolean) => {
      dispatch(setLoad(true) as unknown as Action);

      const currentFilters = clear ? {} : filters;
      const result = (await filterClassVacancySubscription(
        id ?? "",
        currentFilters,
        true,
      )) as { size?: number }[];

      if (result?.length) setTotal(result[0]?.size ?? 0);
      else setTotal(0);

      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, filters, id],
  );

  return (
    <Modal
      headerAction
      open={open}
      onClose={onclose}
      textHeader="Detalhes"
      className="admin-subscriptions-modal-manager-actions"
    >
      <div className="admin-subscriptions-modal-manager-actions details">
        <Select
          id="stageId"
          label="Etapa"
          value={filters?.stageId ?? ""}
          options={optionsFilters("stages")}
          className="select"
          onChange={(e) => onChange(e.target.value, "stageId")}
          onBlur={() => getCandidates()}
        />
        <Select
          id="status"
          label="Status"
          value={filters?.status ?? ""}
          options={optionsFilters("status")}
          className="select"
          onChange={(e) => onChange(e.target.value, "status")}
          onBlur={() => getCandidates()}
        />
        <button
          type="button"
          className={`clear-button cursor-pointer ${
            !filters?.stageId && !filters?.status && "button-invisibility"
          }`}
          onClick={() => {
            setFilters({});
            getCandidates(true);
          }}
        >
          Remover filtros
        </button>
      </div>
      <div className="candidates">
        <div className="candidates-title">
          <div>Nº de candidatas</div>
          <hr />
        </div>
        <div>{!filters?.stageId && !filters?.status ? totalList : total}</div>
      </div>
    </Modal>
  );
};

export default memo(Candidate);
