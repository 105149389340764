import React, { memo } from "react";
import moment from "moment";
import { Card } from "../../../components";
import { ClassVacancy } from "../../../types/interfaces";
import "../style.scss";

interface Data extends ClassVacancy {
  classTitles?: string;
}

interface Props {
  classVacancy: Data;
}

const Infos = ({ classVacancy }: Props): JSX.Element => (
  <Card
    titleHeader="Dados gerais"
    className="grid-column-4-13 grid-row-2"
    bodyClassName="card-general-data-class-vacancy"
  >
    <div className="flex flex-wrap">
      <div className="margin-right-48 margin-bottom-24">
        <p className="title-view-company">Nome</p>
        <p className="view-bold">{classVacancy?.title ?? ""}</p>
      </div>
      <div className="margin-right-48 margin-bottom-24">
        <p className="title-view-company">Turmas</p>
        <p className="view-bold">{classVacancy?.classTitles ?? ""}</p>
      </div>
      <div className="margin-right-48 margin-bottom-24">
        <p className="title-view-company">Abertura e encerramento</p>
        <p className="view-bold">
          {`${moment(classVacancy?.startDate).format("DD/MM/YYYY") ?? ""} - ${
            moment(classVacancy?.endDate).format("DD/MM/YYYY") ?? ""
          }`}
        </p>
      </div>
    </div>
    <div>
      <p className="title-view-company">Descrição</p>
      <p className="view-bold">{classVacancy?.description ?? ""}</p>
    </div>
  </Card>
);

export default memo(Infos);
