import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { RootState } from "../../../store/reducers";
import { Input, ModalConfigAndFilters, Select } from "../../../components";
import {
  setLoad,
  setPageLimit,
} from "../../../store/actions/configurationsActions";
import { Config as ConfigProps, Filters } from "..";
import {
  autoCompleteStatus,
  autoCompleteType,
  classVacancySubscriptionEmployabilitySituation,
  classVacancyTemplate,
  classVacancyType,
} from "../../../types/enumerators";
import { Class, ClassVacancy } from "../../../types/interfaces";
import { FilterScore, Score } from "../Components/table";
import {
  getAutocomplete,
  getUserAdminClassVacancyVolunterByClassVacancyId,
} from "../../../services/functions";
import {
  CourseStatus,
  LevelEducation,
  EmployabilitySituation,
  CourseOfReprograma,
  ProgramingLevel,
} from "../../../types/constants/Course";
import { ProfileGender, ProfileRace } from "../../../types/constants/User";
import "../style.scss";

interface Props {
  open: boolean;
  onclose: () => void;
  classVacancy: ClassVacancy;
  workshopList: Class[];
  expanded: string;
  reload: (filters: Filters, config: ConfigProps) => void;
  setApplyFilters: Dispatch<SetStateAction<Filters>>;
  filterScore: Score;
  setFilterScore: Dispatch<SetStateAction<Score>>;
  setApplyConfig: Dispatch<SetStateAction<ConfigProps | undefined>>;
  initialConfig: ConfigProps;
  headCells: { id: string; label: string; sort?: string; orderBy?: boolean }[];
}

interface Volunteer {
  _id: string;
  name?: string;
  email?: string;
  classVacancy: {
    [x: string]: string[];
  };
}

const RenderFilterScore = ({
  stages,
  filterScore,
  filters,
  optionsAllFilters,
  setFilterScore,
  setFilters,
}: FilterScore) => (
  <>
    <Select
      id="stageId"
      label="Selecione uma etapa"
      value={filterScore?.stageId ?? ""}
      onChange={(e) =>
        setFilterScore({
          ...filterScore,
          stageId: e.target.value ?? "",
        })
      }
      options={
        stages?.map((stage) => ({
          value: stage?.stage_id ?? "",
          label: stage?.title ?? "",
        })) ?? [{ value: "", label: "" }]
      }
    />
    <Select
      id="status"
      label="Selecione uma opção"
      value={filterScore?.type ?? ""}
      onChange={(e) =>
        setFilterScore({
          ...filterScore,
          type: e.target.value ?? "",
        })
      }
      onBlur={() =>
        setFilters({
          ...filters,
          score: {
            stageId: filterScore?.stageId,
            [filterScore?.type]: true,
          },
        })
      }
      options={optionsAllFilters("score")}
      disabled={!filterScore?.stageId}
    />
    {filterScore?.type !== "isNull" && (
      <Input
        type="number"
        label="Digite um valor"
        value={filterScore?.value ?? ""}
        onChange={(e: ChangeEvent<{ value: string }>) => {
          if (Number(e.target.value) > -1 ?? "")
            setFilterScore({
              ...filterScore,
              value: e.target.value === "" ? "" : Number(e.target.value),
            });
        }}
        onBlur={() =>
          setFilters({
            ...filters,
            score: {
              stageId: filterScore?.stageId,
              [filterScore?.type]: filterScore?.value,
            },
          })
        }
        disabled={!filterScore?.stageId || !filterScore?.type}
      />
    )}
  </>
);

const Config = ({
  open,
  onclose,
  classVacancy,
  workshopList,
  reload,
  expanded,
  setApplyFilters,
  filterScore,
  setFilterScore,
  setApplyConfig,
  initialConfig,
  headCells,
}: Props): JSX.Element => {
  const [filters, setFilters] = useState<Filters>({});
  const [config, setConfig] = useState<ConfigProps>(initialConfig);
  const [volunteersList, setVolunteersList] = useState<Volunteer[]>();
  const [tagsList, setTagsList] = useState<string[]>();
  const dispatch = useDispatch();

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  useEffect(() => {
    setConfig((prev) => ({ ...prev, limit: pageLimit ?? 10 }));
  }, [pageLimit]);

  const setLimitRdx = useCallback(
    (x: number) => {
      dispatch(setPageLimit(x) as unknown as Action);
    },
    [dispatch],
  );

  const onChangeFilters = useCallback(
    (key: keyof Filters, val: string | boolean, isCheckbox = false) => {
      if (isCheckbox)
        setFilters(
          (prev) =>
            ({
              ...(prev || {}),
              [key]:
                prev[key] && prev[key]?.includes(val)
                  ? prev[key]?.filter((el: string | boolean) => el !== val)
                  : [...(prev[key] || []), val],
            } as Filters),
        );
      else
        setFilters(
          (prev) =>
            ({
              ...prev,
              [key]: val,
            } as Filters),
        );
    },
    [],
  );

  const onChangeConfig = useCallback(
    (key: keyof typeof config, val: number | string, isCheckbox = false) => {
      if (isCheckbox)
        setConfig(
          (prev) =>
            ({
              ...(prev || {}),
              [key]:
                prev[key] && prev[key]?.includes(val)
                  ? prev[key]?.filter((el: string | boolean) => el !== val)
                  : [...(prev[key] || []), val],
            } as ConfigProps),
        );
      else
        setConfig(
          (prev) =>
            ({
              ...prev,
              [key]: val,
            } as ConfigProps),
        );
    },
    [],
  );

  const optionsAllFilters = useCallback(
    (type: string, hasAll?: boolean) => {
      const result: {
        value?: string | boolean | number | null;
        label: string;
      }[] = [];

      if (hasAll)
        result?.push({
          value: undefined,
          label: "Todas",
        });

      if (type === "genderIdentity")
        Object.keys(ProfileGender).forEach((key) => {
          result?.push({
            value: key,
            label: ProfileGender[key as keyof typeof ProfileGender],
          });
        });

      if (type === "race")
        Object.keys(ProfileRace).forEach((key) => {
          result?.push({
            value: key,
            label: ProfileRace[key as keyof typeof ProfileRace],
          });
        });

      if (type === "status")
        Object.keys(CourseStatus).forEach((key) => {
          result?.push({
            value: key,
            label: CourseStatus[key as keyof typeof CourseStatus],
          });
        });

      if (type === "stages" && classVacancy?.stages)
        classVacancy?.stages?.forEach((stage) => {
          result?.push({
            value: stage?.stage_id,
            label: stage?.title ?? "",
          });
        });

      if (type === "score")
        result?.push(
          {
            value: "min",
            label: "Maior ou igual",
          },
          {
            value: "max",
            label: "Menor ou igual",
          },
          {
            value: "isNull",
            label: "Sem nota",
          },
        );

      if (type === "educationLevel")
        Object.keys(LevelEducation).forEach((key) => {
          result?.push({
            value: key,
            label: LevelEducation[key as keyof typeof LevelEducation],
          });
        });

      if (type === "employabilitySituation")
        Object.keys(EmployabilitySituation).forEach((key) => {
          result?.push({
            value: key,
            label:
              EmployabilitySituation[
                key as keyof typeof EmployabilitySituation
              ],
          });
        });

      if (type === "montlyRevenue")
        result?.push(
          {
            value: "TIER_0",
            label: "Sem renda",
          },
          {
            value: "TIER_1",
            label: "Até R$ 1.302,00",
          },
          {
            value: "TIER_2",
            label: "Entre R$ 1.302,01 e R$ 2.604,00",
          },
          {
            value: "TIER_3",
            label: "Entre R$ 2.604,01 e R$ 3.906,00",
          },
          {
            value: "TIER_4",
            label: "Entre R$ 3.906,01 e R$ 6.510,00",
          },
          {
            value: "TIER_5",
            label: "Mais de R$ 6.510,01",
          },
        );

      if (type === "region")
        result?.push(
          {
            value: "NORTE",
            label: "Norte",
          },
          {
            value: "NORDESTE",
            label: "Nordeste",
          },
          {
            value: "CENTRO-OESTE",
            label: "Centro-Oeste",
          },
          {
            value: "SUDESTE",
            label: "Sudeste",
          },
          {
            value: "SUL",
            label: "Sul",
          },
        );

      if (type === "reprogramaStudentOtherClasses")
        Object.keys(CourseOfReprograma)?.forEach((key) => {
          result?.push({
            value: key,
            label: CourseOfReprograma[key as keyof typeof CourseOfReprograma],
          });
        });

      if (type === "programingLevel")
        Object.keys(ProgramingLevel)?.forEach((key) => {
          result?.push({
            value: key,
            label: ProgramingLevel[key as keyof typeof ProgramingLevel],
          });
        });

      const types = [
        "disabledPerson",
        "needAccessibilityAdaptation",
        "hasInternet",
        "hasComputer",
        "isLGBTQIA",
        "isReprogramaStudent",
        "similarCourse",
        "childrenOfAge",
        "hasMedia",
        "hasForm",
        "participated",
        "hasFormDiversity",
        "hasTechnical",
        "hasBehavior",
        "isEmployedInIT",
        "isTeacher",
        "hasComments",
      ];

      if (types.includes(type))
        result?.push(
          {
            value: true,
            label: "Sim",
          },
          {
            value: false,
            label: "Não",
          },
        );
      return result;
    },
    [classVacancy?.stages],
  );

  const optionsFilters = useMemo(
    () => [
      {
        id: "stageId",
        label: "Etapas",
        type: "select",
        value: filters?.stageId,
        options: optionsAllFilters("stages", true),
        handleChange: (e: ChangeEvent<{ value: string | boolean }>) =>
          onChangeFilters("stageId", e.target.value),
      },
      {
        id: "status",
        label: "Status",
        type: "select",
        value: filters?.status,
        options: optionsAllFilters("status", true),
        handleChange: (e: ChangeEvent<{ value: string | boolean }>) =>
          onChangeFilters("status", e.target.value),
      },
      {
        id: "genderIdentity",
        label: "Gênero",
        type: "checkbox",
        value: filters?.genderIdentity,
        options: optionsAllFilters("genderIdentity"),
        handleChange: (value: string) =>
          onChangeFilters("genderIdentity", value, true),
      },
      {
        id: "region",
        label: "Região",
        type: "checkbox",
        value: filters?.region,
        options: optionsAllFilters("region"),
        handleChange: (value: string) => onChangeFilters("region", value, true),
      },
      {
        id: "race",
        label: "Raça",
        type: "checkbox",
        value: filters?.race,
        options: optionsAllFilters("race"),
        handleChange: (value: string) => onChangeFilters("race", value, true),
      },
      {
        id: "educationLevel",
        label: "Escolaridade",
        type: "checkbox",
        value: filters?.educationLevel,
        options: optionsAllFilters("educationLevel"),
        handleChange: (value: string) =>
          onChangeFilters("educationLevel", value, true),
      },
      {
        id: "montlyRevenue",
        label: "Renda",
        type: "select",
        value: filters?.montlyRevenue,
        options: optionsAllFilters("montlyRevenue", true),
        handleChange: (e: ChangeEvent<{ value: string | boolean }>) =>
          onChangeFilters("montlyRevenue", e.target.value),
      },
      {
        id: "perCapitalRevenue",
        label: "Renda per capita",
        type: "select",
        value: filters?.perCapitalRevenue,
        options: optionsAllFilters("montlyRevenue", true),
        handleChange: (e: ChangeEvent<{ value: string | boolean }>) =>
          onChangeFilters("perCapitalRevenue", e.target.value),
      },
      {
        id: "disabledPerson",
        label: "Pessoa com Deficiência",
        type: "radio",
        value: filters?.disabledPerson,
        options: optionsAllFilters("disabledPerson", true),
        handleChange: (value: boolean) =>
          onChangeFilters("disabledPerson", value),
      },
      {
        id: "needAccessibilityAdaptation",
        label: "Precisa de Adaptação",
        type: "radio",
        value: filters?.needAccessibilityAdaptation,
        hide: !filters?.disabledPerson,
        options: optionsAllFilters("needAccessibilityAdaptation", true),
        handleChange: (value: boolean) =>
          onChangeFilters("needAccessibilityAdaptation", value),
      },
      {
        id: "hasComputer",
        label: "Tem computador",
        type: "radio",
        value: filters?.hasComputer,
        options: optionsAllFilters("hasComputer", true),
        handleChange: (value: boolean) => onChangeFilters("hasComputer", value),
      },
      {
        id: "hasInternet",
        label: "Tem acesso à internet",
        type: "radio",
        value: filters?.hasInternet,
        options: optionsAllFilters("hasInternet", true),
        handleChange: (value: boolean) => onChangeFilters("hasInternet", value),
      },
      {
        id: "hasForm",
        label: "Enviou formulário de apresentação",
        type: "radio",
        value: filters?.hasForm,
        options: optionsAllFilters("hasForm", true),
        handleChange: (value: boolean) => onChangeFilters("hasForm", value),
      },
      {
        id: "hasMedia",
        label: "Enviou mídia de apresentação",
        type: "radio",
        value: filters?.hasMedia,
        options: optionsAllFilters("hasMedia", true),
        handleChange: (value: boolean) => onChangeFilters("hasMedia", value),
      },
      {
        id: "participated",
        label: "Participou da oficina (manhã e tarde)",
        type: "radio",
        value: filters?.participated,
        options: optionsAllFilters("participated", true),
        handleChange: (value: boolean) =>
          onChangeFilters("participated", value),
      },
      {
        id: "hasTechnical",
        label: "Enviou desafio técnico (oficina)",
        type: "radio",
        value: filters?.hasTechnical,
        options: optionsAllFilters("hasTechnical", true),
        handleChange: (value: boolean) =>
          onChangeFilters("hasTechnical", value),
      },
      {
        id: "hasFormDiversity",
        label: "Enviou formulário de diversidade (oficina)",
        type: "radio",
        value: filters?.hasFormDiversity,
        options: optionsAllFilters("hasFormDiversity", true),
        handleChange: (value: boolean) =>
          onChangeFilters("hasFormDiversity", value),
      },
      {
        id: "hasBehavior",
        label: "Enviou atividade comportamental (oficina)",
        type: "radio",
        value: filters?.hasBehavior,
        options: optionsAllFilters("hasBehavior", true),
        handleChange: (value: boolean) => onChangeFilters("hasBehavior", value),
      },
      {
        id: "childrenOfAge",
        label: "Possui filhos menor de idade",
        type: "radio",
        value: filters?.childrenOfAge,
        options: optionsAllFilters("childrenOfAge", true),
        handleChange: (value: boolean) =>
          onChangeFilters("childrenOfAge", value),
      },
      {
        id: "isLGBTQIA",
        label: "LGBTQIAP+",
        type: "radio",
        value: filters?.isLGBTQIA,
        options: optionsAllFilters("isLGBTQIA", true),
        handleChange: (value: boolean) => onChangeFilters("isLGBTQIA", value),
      },
      {
        id: "isReprogramaStudent",
        label: "Foi aluna da reprograma",
        type: "radio",
        value: filters?.isReprogramaStudent,
        options: optionsAllFilters("isReprogramaStudent", true),
        handleChange: (value: boolean) =>
          onChangeFilters("isReprogramaStudent", value),
      },
      {
        id: "reprogramaStudentOtherClasses",
        label: "Fez o curso",
        type: "select",
        value: filters?.reprogramaStudentOtherClasses,
        hide: !filters?.isReprogramaStudent,
        options: optionsAllFilters("reprogramaStudentOtherClasses"),
        handleChange: (e: ChangeEvent<{ value: string | boolean }>) =>
          onChangeFilters(
            "reprogramaStudentOtherClasses",
            e.target.value,
            true,
          ),
      },
      {
        id: "similarCourse",
        label: "Iniciativa semelhante à reprograma",
        type: "radio",
        hide: classVacancy?.template !== classVacancyTemplate.LEVEL_ONE,
        value: filters?.similarCourse,
        options: optionsAllFilters("similarCourse", true),
        handleChange: (value: boolean) =>
          onChangeFilters("similarCourse", value),
      },
      {
        id:
          classVacancy?.template === classVacancyTemplate.TET ||
          classVacancy?.template === classVacancyTemplate.LEVEL_ONE
            ? "ITLevel"
            : "hasJSKnowledge",
        label:
          classVacancy?.template === classVacancyTemplate.TET ||
          classVacancy?.template === classVacancyTemplate.LEVEL_ONE
            ? "Nível de conhecimento em programação"
            : "Nível de conhecimento em JavaScript",
        type: "checkbox",
        value:
          classVacancy?.template === classVacancyTemplate.TET ||
          classVacancy?.template === classVacancyTemplate.LEVEL_ONE
            ? filters?.ITLevel
            : filters?.hasJSKnowledge,
        options: optionsAllFilters("programingLevel"),
        handleChange: (value: string) =>
          onChangeFilters(
            classVacancy?.template === classVacancyTemplate.TET ||
              classVacancy?.template === classVacancyTemplate.LEVEL_ONE
              ? "ITLevel"
              : "hasJSKnowledge",
            value,
            true,
          ),
      },
      {
        id: "employabilitySituation",
        label: "Empregabilidade",
        type: "checkbox",
        value: filters?.employabilitySituation,
        options: optionsAllFilters("employabilitySituation"),
        handleChange: (value: string) =>
          onChangeFilters("employabilitySituation", value, true),
      },
      {
        id: "isEmployedInIT",
        label: "Trabalhando com TI",
        type: "radio",
        hide: !(
          (filters?.employabilitySituation &&
            filters?.employabilitySituation?.length > 1) ||
          (filters?.employabilitySituation?.length === 1 &&
            !filters?.employabilitySituation?.includes(
              classVacancySubscriptionEmployabilitySituation.UNEMPLOYED,
            ))
        ),
        value: filters?.isEmployedInIT,
        options: optionsAllFilters("isEmployedInIT", true),
        handleChange: (value: boolean) =>
          onChangeFilters("isEmployedInIT", value),
      },
      {
        id: "isTeacher",
        label: "Professora Reprograma",
        type: "radio",
        value: filters?.isTeacher,
        hide: classVacancy?.template !== classVacancyTemplate.EDV,
        options: optionsAllFilters("isTeacher", true),
        handleChange: (value: boolean) => onChangeFilters("isTeacher", value),
      },
      {
        id: "workshopClass",
        label: "Oficina",
        type: "select",
        value: filters?.workshopClass,
        hide: !workshopList || !workshopList?.length,
        options: [
          {
            value: undefined,
            label: "Todas",
          },
          ...(workshopList?.map((item) => ({
            value: item?._id ?? "",
            label: item?.code ?? "",
          })) ?? []),
          { value: "isNull", label: "Sem oficina" },
        ],
        handleChange: (e: ChangeEvent<{ value: string | boolean }>) =>
          onChangeFilters("workshopClass", e.target.value),
      },
      {
        id: "workshopFirstOption",
        label: "Oficina - Opção 1",
        type: "select",
        value: filters?.workshopFirstOption,
        hide: !workshopList || !workshopList?.length,
        options: [
          {
            value: undefined,
            label: "Todas",
          },
          ...(workshopList?.map((item) => ({
            value: item?._id ?? "",
            label: item?.code ?? "",
          })) ?? []),
        ],
        handleChange: (e: ChangeEvent<{ value: string | boolean }>) =>
          onChangeFilters("workshopFirstOption", e.target.value),
      },
      {
        id: "workshopSecondOption",
        label: "Oficina - Opção 2",
        type: "select",
        value: filters?.workshopSecondOption,
        hide: !workshopList || !workshopList?.length,
        options: [
          {
            value: undefined,
            label: "Todas",
          },
          ...(workshopList?.map((item) => ({
            value: item?._id ?? "",
            label: item?.code ?? "",
          })) ?? []),
        ],
        handleChange: (e: ChangeEvent<{ value: string | boolean }>) =>
          onChangeFilters("workshopSecondOption", e.target.value),
      },
      {
        id: "age",
        label: "Idade",
        type: "text",
        value: filters?.age,
        inputType: "number" as "number" | "text" | "password",
        inputLabel: "Digite um valor",
        handleChange: (e: ChangeEvent<{ value: string | boolean }>) =>
          onChangeFilters("age", e.target.value),
      },
      {
        id: "volunteersId",
        label: "Voluntárias",
        type: "checkbox",
        value: filters?.volunteersId,
        hide: !volunteersList || !volunteersList?.length,
        options: volunteersList?.map((item) => ({
          value: item?._id ?? "",
          label: item?.name ?? item?.email ?? "",
        })),
        handleChange: (value: string) =>
          onChangeFilters("volunteersId", value, true),
      },
      {
        id: "score",
        label: "Notas",
        type: "others",
        child: (
          <RenderFilterScore
            stages={classVacancy?.stages?.filter(
              (item) => item?.type !== classVacancyType.EVALUATE,
            )}
            filterScore={filterScore}
            filters={filters}
            optionsAllFilters={optionsAllFilters}
            setFilterScore={setFilterScore}
            setFilters={setFilters}
          />
        ),
      },
      {
        id: "hasComments",
        label: "Possui comentários",
        type: "radio",
        value: filters?.hasComments,
        options: optionsAllFilters("hasComments", true),
        handleChange: (value: boolean) => onChangeFilters("hasComments", value),
      },
      {
        id: "tags",
        label: "Tags",
        type: "checkbox",
        value: filters?.tags,
        hide: !tagsList || !tagsList?.length,
        options: tagsList?.map((item) => ({
          value: item ?? "",
          label: item ?? "",
        })),
        handleChange: (value: string) => onChangeFilters("tags", value, true),
      },
    ],
    [
      classVacancy?.stages,
      classVacancy?.template,
      filterScore,
      filters,
      onChangeFilters,
      optionsAllFilters,
      setFilterScore,
      tagsList,
      volunteersList,
      workshopList,
    ],
  );

  const optionsAllConfig = useCallback(
    (type: string) => {
      const result: { value?: number | boolean | string; label: string }[] = [];
      if (type === "visibleColumns") {
        headCells
          .filter((item) => item.id !== "actions" && item.id !== "photo")
          .forEach((key) => {
            result?.push({
              value: key?.id,
              label: key?.label,
            });
          });
      }

      if (type === "sortBy") {
        headCells
          .filter((item) => !item.orderBy === false)
          .forEach((key) => {
            result?.push({
              value: key?.sort,
              label: key?.label,
            });
          });
      }

      if (type === "asc") {
        result?.push(
          {
            value: true,
            label: "Crescente",
          },
          {
            value: false,
            label: "Decrescente",
          },
        );
      }

      if (type === "limit") {
        result?.push(
          {
            value: 10,
            label: "10",
          },
          {
            value: 20,
            label: "20",
          },
          {
            value: 30,
            label: "30",
          },
          {
            value: 40,
            label: "40",
          },
          {
            value: 50,
            label: "50",
          },
        );
      }
      return result;
    },
    [headCells],
  );

  const optionsConfig = useMemo(
    () => [
      {
        id: "visibleColumns",
        label: "Colunas vísiveis",
        type: "checkbox",
        value: config?.visibleColumns,
        options: optionsAllConfig("visibleColumns"),
        handleChange: (value: number | string) =>
          onChangeConfig("visibleColumns", value, true),
      },
      {
        id: "sortBy",
        label: "Ordenar por",
        type: "radio",
        value: config?.sortBy,
        options: optionsAllConfig("sortBy"),
        handleChange: (value: number | string) =>
          onChangeConfig("sortBy", value),
      },
      {
        id: "asc",
        label: "Ordem",
        type: "radio",
        value: config?.asc,
        options: optionsAllConfig("asc"),
        handleChange: (value: number | string) => onChangeConfig("asc", value),
      },
      {
        id: "limit",
        label: "Nº de linhas por página",
        type: "radio",
        value: config?.limit,
        options: optionsAllConfig("limit"),
        handleChange: (value: number | string) =>
          onChangeConfig("limit", value),
      },
    ],
    [
      config?.asc,
      config?.limit,
      config?.sortBy,
      config?.visibleColumns,
      onChangeConfig,
      optionsAllConfig,
    ],
  );

  const getVolunteersList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getUserAdminClassVacancyVolunterByClassVacancyId(
      classVacancy?._id ?? "",
    );
    setVolunteersList(response ?? []);
    dispatch(setLoad(false) as unknown as Action);
  }, [classVacancy?._id, dispatch]);

  useEffect(() => {
    if (!volunteersList) getVolunteersList();
  }, [getVolunteersList, volunteersList]);

  const getTagsList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getAutocomplete(
      autoCompleteType.TAG,
      autoCompleteStatus.ACTIVE,
    );

    const newTags = ["Nenhuma", ...(response?.map((item) => item?.name) ?? [])];
    setTagsList(newTags);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  useEffect(() => {
    if (!tagsList) getTagsList();
  }, [getTagsList, tagsList]);

  const onClear = useCallback(() => {
    setFilters({} as Filters);
    setApplyFilters({} as Filters);
    setFilterScore({
      stageId: "",
      type: "",
      value: undefined,
    });
    setApplyConfig(initialConfig);
    setConfig(initialConfig);
  }, [
    initialConfig,
    setApplyConfig,
    setApplyFilters,
    setConfig,
    setFilterScore,
    setFilters,
  ]);

  const onSearch = useCallback(() => {
    const f = {
      ...filters,
      score: {
        stageId: filterScore?.stageId,
        [filterScore?.type]: filterScore?.value || true,
      },
    };
    setFilters(f);
    setApplyFilters(f);
    setApplyConfig(config);
    setLimitRdx(config?.limit ?? 10);
    reload(f, config);
    onclose();
  }, [
    config,
    filterScore?.stageId,
    filterScore?.type,
    filterScore?.value,
    filters,
    onclose,
    reload,
    setApplyConfig,
    setApplyFilters,
    setFilters,
    setLimitRdx,
  ]);

  return (
    <ModalConfigAndFilters
      textHeaderModal="Configurações"
      openModal={open}
      expanded={expanded}
      onClose={() => (config ? onclose : undefined)}
      isDefault={Object.keys(filters).length === 0 && config === initialConfig}
      filters={optionsFilters}
      config={optionsConfig}
      onClear={onClear}
      onSearch={onSearch}
    />
  );
};

export default memo(Config);
