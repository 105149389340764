/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  CompanyAvatarEmpty,
  BookmarkFilled,
} from "../../../assets/customIcons";
import { JobInformation } from "../../components";

interface SavedJobsProps {
  jobTitle?: string;
  companyName?: string;
  jobStatus?: string;
  jobTypeOfCandidature?: string;
  jobManagedBy?: string;
  jobDate?: string | Date;
  jobType?: string;
  jobPlace?: string;
  isBookmarked?: boolean;
  photo?: string;
  vacancyId?: string;
}

interface Props {
  data: SavedJobsProps;
  onClick: (vacanyId: string) => void;
}

const CardSavedJobs = ({ data, onClick }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="saved-vacancy-card">
      <div className="margin-24">
        {data?.photo ? (
          <img
            alt="logo"
            src={data?.photo}
            className="div-card-items company-avatar-empty-card"
          />
        ) : (
          <CompanyAvatarEmpty className="company-avatar-empty-card size-48" />
        )}
      </div>
      <div
        className="width-100"
        onClick={() =>
          history.push({
            pathname: `/job/${data?.vacancyId}`,
          })
        }
      >
        <div className="width-100">
          <div className="flex space-between width-100">
            <JobInformation
              data={data}
              rawStatus
              titleClassName="margin-top-24-only"
              statusClassName="saved-job-status"
              dateClassName="margin-bottom-24"
            />
            <div
              aria-label="save"
              className="pointer margin-top-24 margin-right-24"
              role="button"
              tabIndex={0}
              onClick={() => onClick(data?.vacancyId ?? "")}
            >
              <BookmarkFilled
                widthIcon={width < 991 ? 24 : 28}
                heightIcon={width < 991 ? 24 : 28}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CardSavedJobs);
