/* eslint-disable no-console */
/* eslint-disable camelcase */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { orderList } from "../../../utils";
import { ChevronDown } from "../../../../assets/icons";
import { Integration } from "../../../types/interfaces";
import { RootState } from "../../../store/reducers";

const ViewIntegrations = (): JSX.Element => {
  const [integrations, setIntegrations] = useState<Integration[]>();
  const company = useSelector((state: RootState) => state.companyState);

  const [headCells, setHeadCells] = useState([
    {
      id: "platform",
      numeric: false,
      disablePadding: true,
      label: "Plataforma",
      orderBy: false,
      asc: false,
    },
    {
      id: "token",
      numeric: false,
      disablePadding: true,
      label: "Api Key (Token)",
      orderBy: false,
      asc: false,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "E-mail",
      orderBy: false,
      asc: false,
    },
  ]);

  const orderIntegrations = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (id, list?: any[], sortHeadCells = true) => {
      const listOrdered = orderList(
        id,
        headCells,
        list ?? integrations ?? [],
        sortHeadCells,
      );
      setIntegrations(listOrdered.list);
      setHeadCells(listOrdered.headList);
    },
    [headCells, integrations],
  );

  const loadIntegrations = useCallback(async () => {
    try {
      const _integrations = company?.integrations?.map(
        (item: Integration): Integration => ({
          platform: item?.platform ?? "",
          user: item?.user ?? "",
          token: item?.token ?? "",
        }),
      );

      orderIntegrations("platform", _integrations ?? []);
    } catch (error) {
      console.warn(error);
    }
  }, [company?.integrations, orderIntegrations]);

  useEffect(() => {
    if (!integrations) loadIntegrations();
  }, [company._id, loadIntegrations, integrations]);

  return (
    <div className="width-100">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  onClick={() => orderIntegrations(headCell.id)}
                  className="view-table-cell text-center"
                  key={Math.random()}
                >
                  {headCell.label}
                  {headCell.label.length > 0 && (
                    <ChevronDown
                      className={`not-rotate ${
                        headCell.asc ? "rotate-row" : ""
                      }`}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {integrations && integrations?.length > 0 ? (
            <TableBody>
              {integrations?.map((row) => (
                <TableRow>
                  <TableCell className="row-table-cell text-center">
                    {row.platform}
                  </TableCell>
                  <TableCell className="row-table-cell text-center">
                    {row.token
                      ? `${row?.token
                          ?.substring(0, row.token.length - 6)
                          ?.replace(/\D/g, "*")
                          ?.replace(/\d/g, "*")}
                      ${row.token.substring(
                        row.token.length - 5,
                        row.token.length,
                      )}`
                      : ""}
                  </TableCell>
                  <TableCell className="row-table-cell text-center">
                    {row.user}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <div className="not-vacancies">
              Ainda não há integrações cadastradas
            </div>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default memo(ViewIntegrations);
