/* eslint-disable no-await-in-loop */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { Button, InputAutocomplete, Modal } from "../../../components";
import { autoCompleteType } from "../../../types/enumerators";
import { setLoad } from "../../../store/actions/configurationsActions";
import { UserBootcamps } from "../../../types/interfaces";
import {
  getAutocomplete,
  patchUser,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import { registerAutoComplete } from "../../../utils";
import "../style.scss";

interface User {
  userId: string;
  bootcamps: UserBootcamps[];
  tags: string[];
}

interface Props {
  open: boolean;
  close: () => void;
  users: User[];
  classId: string;
  isWorkshop?: boolean;
  reload: () => void;
}

const Tags = ({
  open,
  close,
  users,
  classId,
  isWorkshop = false,
  reload,
}: Props): JSX.Element => {
  const [tagsOptions, setTagsOptions] = useState<string[]>();
  const [tags, setTags] = useState<string[]>();
  const initialTags = useMemo(() => {
    if (users?.length === 1) return users[0]?.tags;
    return [];
  }, [users]);

  const dispatch = useDispatch();

  const loadTags = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getAutocomplete(autoCompleteType.TAG);
    const optionsAutocompleteTag = response?.map((option) => option?.name);
    setTagsOptions(optionsAutocompleteTag);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  useEffect(() => {
    if (!tagsOptions) loadTags();
  }, [loadTags, tagsOptions]);

  const onClose = useCallback(() => {
    setTags(undefined);
    close();
  }, [close]);

  const handleChangeAutocompleteTags = useCallback((value) => {
    if (value) {
      setTags(value);
      registerAutoComplete(value, autoCompleteType.TAG);
    }
  }, []);

  const setOnUser = useCallback(
    async (user, newTags) => {
      dispatch(setLoad(true) as unknown as Action);
      const bootcamps = [...(user?.bootcamps ?? [])];
      const idx =
        bootcamps?.findIndex((item) => item?.classId === classId) ?? -1;

      if (idx !== -1) bootcamps[idx].tags = newTags;

      await patchUser(user?.userId ?? "", {
        bootcamps,
      });

      reload();
      dispatch(setLoad(true) as unknown as Action);
    },
    [classId, dispatch, reload],
  );

  const setOnSubscription = useCallback(async (user, newTags) => {
    await updateClassVacancySubscriptionById(user?._id ?? "", {
      tags: newTags,
    });
  }, []);

  const onSave = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    let newTags;

    for (let i = 0; i < users?.length; i += 1) {
      const itemSelectedInitialTags = users[i]?.tags;

      if (itemSelectedInitialTags) {
        let filteredTagNotRemoved;

        if (users?.length > 1) filteredTagNotRemoved = itemSelectedInitialTags;
        else
          filteredTagNotRemoved = itemSelectedInitialTags?.filter(
            (item: string) => tags?.includes(item),
          );

        const filteredTagAdded = tags?.filter(
          (item: string) => !itemSelectedInitialTags?.includes(item),
        );

        newTags = [...(filteredTagAdded ?? []), ...filteredTagNotRemoved];
      } else newTags = tags;

      if (isWorkshop) setOnSubscription(users[i], newTags);
      else setOnUser(users[i], newTags);
    }

    onClose();
    dispatch(setLoad(false) as unknown as Action);
  }, [
    dispatch,
    onClose,
    users,
    tags,
    isWorkshop,
    setOnSubscription,
    setOnUser,
  ]);

  return (
    <Modal
      headerAction
      open={open}
      onClose={onClose}
      textHeader="Adcionar tags"
      className="admin-subscriptions-modal-tags"
    >
      <InputAutocomplete
        multiple
        label="Tags*"
        helperText="Pressione Enter para adicionar"
        options={tagsOptions ?? []}
        getOptionLabel={(option) => option}
        onChange={handleChangeAutocompleteTags}
        value={tags ?? initialTags}
      />
      <div className="admin-subscriptions-modal-buttons margin-top-32">
        <Button
          text="Cancelar"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button
          onClick={onSave}
          text={users?.length > 1 ? "Adicionar" : "Salvar"}
        />
      </div>
    </Modal>
  );
};

export default memo(Tags);
