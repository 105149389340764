/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { useParams } from "react-router-dom";
import { HeaderProfile, Layout, StepVagasMobile } from "../../components";
import {
  getAllSubscriptionsOfAVacancy,
  getPlacementVacancy,
} from "../../services/functions";
import "./style.scss";
import CardExpandMessages from "./CardExpandMessages";
import CardMessages from "./CardMessages";
import { setLoad } from "../../store/actions/configurationsActions";
import { PlacementVacancy } from "../../types/interfaces";
import { classVacancySubscriptionStatus } from "../../types/enumerators";

interface ParamsProps {
  placementVacancyId: string;
  placementVacancySubscriptionId?: string;
}

const CompanyVacancyMessage = (): JSX.Element => {
  const { placementVacancyId, placementVacancySubscriptionId } =
    useParams<ParamsProps>();

  const [width, setWidth] = useState(window.innerWidth);
  const [idSelected, setIdSelected] = useState("");
  const [vacancy, setVacancy] = useState<PlacementVacancy>();
  const [data, setData] = useState<any[] | undefined>();

  const dispatch = useDispatch();

  const getVacancy = useCallback(async () => {
    const response = await getPlacementVacancy(placementVacancyId);
    setVacancy(response as PlacementVacancy);
  }, [placementVacancyId]);

  useEffect(() => {
    if (!vacancy?._id && placementVacancyId) getVacancy();
  }, [getVacancy, placementVacancyId, vacancy?._id]);

  useEffect(() => {
    if (!idSelected && placementVacancySubscriptionId)
      setIdSelected(placementVacancySubscriptionId);
  }, [idSelected, placementVacancySubscriptionId]);

  const getData = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const subscriptionsList = await getAllSubscriptionsOfAVacancy(
      placementVacancyId,
      false,
      undefined,
      "userName",
    );

    const subs =
      subscriptionsList?.filter(
        (item) =>
          item?.status !== classVacancySubscriptionStatus.NONE &&
          item?.status !== classVacancySubscriptionStatus.PENDING,
      ) ?? [];

    setData(subs);

    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, placementVacancyId]);

  useEffect(() => {
    if (placementVacancyId) getData();
  }, [getData, placementVacancyId]);

  const handleClick = useCallback(
    (item: { _id: string }) => {
      if (item._id === idSelected) setIdSelected("");
      else setIdSelected(item._id);
    },
    [idSelected],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-responsive">
      <HeaderProfile />
      {width < 991 && (
        <div className="grid-column-1-11 grid-row-1 align-center">
          <StepVagasMobile id={vacancy?._id} selectedMenuTitle="Mensagens" />
        </div>
      )}
      {width > 990 && (
        <div className="grid-column-1-11 grid-row-1 align-center">
          <div className="width-100">
            <div className="flex-row-center">
              <p className="view-page">Vagas &gt;&nbsp;</p>
              <p className="view-page">{vacancy?.title}&nbsp;&gt;&nbsp;</p>
              <p className="view-step">Mensagens</p>
            </div>
          </div>
        </div>
      )}
      <div
        className={`
                    grid-column-1-13 grid-row-2
                 ${
                   idSelected
                     ? "card-candidature card-candidature-100"
                     : "card-candidature"
                 }
                `}
      >
        {width > 990 && (
          <div className="card-title-comments">
            <h2 className="card-title margin-bottom-4">Mensagens</h2>
          </div>
        )}
        <div className="flex display-block">
          <div className="card-candidature_scroll  card-scroll-comment">
            {data && data?.length ? (
              data?.map((item) => (
                <div
                  key={Math.random()}
                  role="none"
                  onClick={() => handleClick(item)}
                  className="div-border-bottom pointer"
                >
                  <CardExpandMessages idSelected={idSelected} item={item} />
                </div>
              ))
            ) : (
              <p className="margin-left-24">Não há candidatas</p>
            )}
          </div>
          {idSelected !== "" ? (
            <CardMessages
              item={data?.find((item) => item._id === idSelected)}
              fetch={getData}
            />
          ) : (
            <div className="display-none" />
          )}
        </div>
      </div>
    </Layout>
  );
};
export default memo(CompanyVacancyMessage);
