import React, { memo, useState } from "react";
import { Layout } from "../../components";
import Header from "./header";
import Team from "./team";
import Process from "./process";
import Plataform from "./plataform";
import "./style.scss";

const AdminTeam = (): JSX.Element => {
  const [page, setPage] = useState(0);

  return (
    <Layout className="admin-class">
      <Header page={page} setPage={setPage} />
      {page === 0 && <Team />}
      {page === 1 && <Process />}
      {page === 2 && <Plataform />}
    </Layout>
  );
};

export default memo(AdminTeam);
