import React, { memo, useEffect, useState } from "react";
import { Modal, Select, Button, Input } from "../../../components";
import { ReasonsRemove } from "../../../types/constants/User";
import { RemoveStatus } from "../../../types/enumerators/GeneralStatus";
import "../style.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (statusReasons: string, statusReasonsDescription: string) => void;
  userName?: string;
}

const Remove = ({ open, onClose, onSave, userName }: Props): JSX.Element => {
  const [statusReasons, setStatusReasons] = useState("");
  const [statusReasonsDescription, setStatusReasonsDescription] = useState("");

  useEffect(() => {
    if (!open && !!statusReasons && !!statusReasonsDescription) {
      setStatusReasons("");
      setStatusReasonsDescription("");
    }
  }, [open, statusReasons, statusReasonsDescription]);

  return (
    <Modal
      headerAction
      open={open}
      onClose={onClose}
      textHeader={userName ? `${userName} - Remover` : "Remover"}
    >
      <div className="admin-recap-modal">
        <Select
          id="justify"
          label="Motivo*"
          options={Object.keys(ReasonsRemove)?.map((key) => ({
            value: key,
            label: ReasonsRemove[key as keyof typeof ReasonsRemove],
          }))}
          value={statusReasons ?? ""}
          onChange={(e) => setStatusReasons(e.target.value)}
        />
        {statusReasons === RemoveStatus.OTHERS && (
          <Input
            id="statusReasonsDescription"
            label="Descreva"
            value={statusReasonsDescription}
            onChange={(e) => setStatusReasonsDescription(e.target.value)}
            maxLength={200}
            className="margin-top-32"
          />
        )}
        <div className="margin-top-32 admin-subscriptions-modal-buttons">
          <Button
            text="Cancelar"
            onClick={onClose}
            outline
            appearance="secondary"
          />
          <Button
            text="Salvar"
            className="margin-left-32 margin-left-mobile"
            onClick={() => onSave(statusReasons, statusReasonsDescription)}
            disabled={!statusReasons}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(Remove);
