import { LinearProgress } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { ProgressValue } from "..";
import "./styles.scss";

interface Props {
  value: number;
  className?: string;
}

const Progress = ({ value, className }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`progress ${className || ""}`}>
      {width > 990 && <p className="progress-linear">Progresso</p>}
      <LinearProgress
        variant="determinate"
        value={value}
        className="margin-top-16"
      />
      <div className="progress-value">
        <ProgressValue value={value} />
      </div>
    </div>
  );
};

export default memo(Progress);
