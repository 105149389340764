import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import moment from "moment";
import { Button, Header, Layout } from "../../../components";
import Person from "../../../../assets/images/Person.svg";
import { CourseSubscriptionAction } from "../../../types/enumerators/actions/CourseSubscriptionAction";
import { CourseSubscriptionState } from "../../../types/constants/Historic";
import { RootState } from "../../../store/reducers";
import { createCourseSubscriptionLog } from "../../../services/historicFunctions";
import {
  classVacancyTemplate,
  classVacancyType,
} from "../../../types/enumerators";
import { ClassVacancy } from "../../../types/interfaces";
import {
  getActiveUserClassVacancySubscription,
  getClassVacancyById,
} from "../../../services/functions";
import { setCourseSubscription } from "../../../store/actions/courseActions";
import Unsubscribe from "../../../components/Unsubscribe";

interface Props {
  setPage: Dispatch<SetStateAction<number>>;
}

const Intro = ({ setPage }: Props): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const subscription = useSelector((state: RootState) => state.courseState);
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();
  const dispatch = useDispatch();

  const isOptional = useMemo(
    () =>
      classVacancy?.stages?.find(
        (item) => item?.type === classVacancyType.UPLOAD_MEDIAS,
      )?.optional,
    [classVacancy?.stages],
  );

  const getSubscription = useCallback(async () => {
    const subscriptions = await getActiveUserClassVacancySubscription(user._id);
    if (subscriptions?.data?.length)
      dispatch(
        setCourseSubscription(subscriptions?.data?.[0]) as unknown as Action,
      );
  }, [user._id, dispatch]);

  const getClassVacancy = useCallback(async () => {
    const response = await getClassVacancyById(subscription?.classVacancyId);
    if (response?.data) setClassVacancy(response?.data);
  }, [subscription?.classVacancyId]);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  useEffect(() => {
    if (!classVacancy && !!subscription?._id) getClassVacancy();
  }, [classVacancy, subscription?._id, getClassVacancy]);

  const renderTexts = useMemo(() => {
    switch (classVacancy?.template) {
      case classVacancyTemplate.TET || classVacancyTemplate.LEVEL_ONE:
        return (
          <p className="paragraph-topics">
            - Compartilhe com a gente a sua história e porquê você quer fazer o
            curso;
            <br /> - Conte para a gente como você deseja aplicar o conhecimento
            adquirido na {`{reprograma};`}
            <br /> - Nos conte o seu ponto de vista sobre programas como este,
            voltados prioritariamente para inclusão de mulheres negras trans e
            travestis, e qual o impacto você acha que isso pode trazer para a
            sociedade;
            <br /> - Você já começou algum processo de estudos em TI por aí? Se
            sim, compartilhe com a gente;
          </p>
        );

      case classVacancyTemplate.IJS:
        return (
          <p className="paragraph-topics">
            - Compartilhe com a gente a sua história e porquê você quer fazer o
            curso;
            <br /> - Fale sobre o seu nível de conhecimento e experiência com
            javascript e conte também sobre um projeto que você se orgulha de
            ter desenvolvido (quais as tecnologias utilizadas, quais os bugs que
            apareceram no caminho, como você lidou com eles e como você
            conseguiu entregar o código lindo);
            <br /> - Nos conte o seu ponto de vista sobre programas como este,
            voltados prioritariamente para mulheres negras, trans e travestis, e
            qual o impacto você acha que isso pode trazer para a sociedade;
            <br /> - Conte para a gente como você deseja aplicar o conhecimento
            adquirido na {`{reprograma};`};
          </p>
        );

      case classVacancyTemplate.EDV:
        return (
          <p className="paragraph-topics">
            - Compartilhe com a gente a sua história, porquê você quer fazer o
            curso e como pretende utilizar os conhecimentos obtidos a partir
            desta formação;
            <br /> - Fale sobre o seu nível de conhecimento e experiência com
            programação;
            <br /> - Nos conte o seu ponto de vista sobre programas como este,
            voltados prioritariamente para mulheres negras, trans e travestis, e
            sobre o impacto que isso pode trazer para a sociedade;
            <br /> - Por último, compartilhe uma experiência em que você
            repassou conhecimentos para outra(s) pessoa(s) e como isso
            influenciou na sua construção como indivíduo;
          </p>
        );

      default:
        return <div />;
    }
  }, [classVacancy?.template]);

  return (
    <div className="container-intro">
      <Layout>
        <Header />
        <div className="content">
          <p className="title-intro">Oba!!!</p>
          {isOptional ? (
            <p className="paragraph-intro">
              Você chegou à etapa <u>obrigatória</u> de apresentação. Você deve
              se apresentar enviando um vídeo ou uma apresentação por escrito
              via
              <button type="button" className="link" onClick={() => setPage(3)}>
                formulário
              </button>
              . Isso quer dizer que o vídeo ou apresentação por escrito são
              requisitos obrigatórios para a 1&#170; fase do processo seletivo.
              <br />
              <br />
              <strong>Importante:</strong> o envio do vídeo pode ser feito até
              dia{" "}
              <strong>
                {moment(
                  classVacancy?.stages?.find(
                    (item) => item?.type === classVacancyType.UPLOAD_MEDIAS,
                  )?.endDate,
                ).format("DD/MM/YYYY")}{" "}
                às 23:59.
              </strong>
              <br />
              <br />
              Caso você seja uma candidata trans ou travesti, fique à vontade
              para enviar um áudio caso se sinta mais confortável.
              <br />
              <br />
              <strong>Vamos lá:</strong> o objetivo deste material é te conhecer
              melhor, então fique tranquila e apenas seja você mesma!
              <br />
              <br />
              <strong>
                Se você optar por enviar sua apresentação por escrito
              </strong>
              , acesse
              <button className="link" type="button" onClick={() => setPage(3)}>
                aqui
              </button>
              .
              <br />
              <br />
              <strong>Se você optar pelo envio do vídeo</strong>,{" "}
              <u>ele deve ter até 3 minutos de duração</u>, mas tudo bem se
              tiver menos.
            </p>
          ) : (
            <p className="paragraph-intro">
              Você chegou na etapa de envio do vídeo, ficamos super felizes e
              agradecemos toda a sua dedicação até aqui!
              <br />
              <br />
              Caso você seja uma candidata trans ou travesti, fique à vontade
              para enviar um áudio caso se sinta mais confortável ou entre em
              contato com a gente para outra forma de apresentação.
              <br />
              <br />
              <strong>Vamos lá:</strong> o objetivo deste material é te conhecer
              melhor, então fique tranquila e apenas seja você mesma! O vídeo
              deve ter até 3 minutos de duração.
            </p>
          )}
          <div className="container-instruction">
            {isOptional ? (
              <p className="title-instruction">
                Quer enviar o vídeo? Temos algumas dicas de tópico para você:
              </p>
            ) : (
              <p className="title-instruction">
                Separamos abaixo tópicos que consideramos importante saber sobre
                você:
              </p>
            )}
            {renderTexts}
            <p className="paragraph-obs">
              OBS: passando de 3 minutos de vídeo, não poderemos assistir o
              restante para sermos justas com todas as colegas que estão
              participando do processo seletivo. Boa sorte e esperamos te ver na
              próxima fase!
            </p>
            <p className="paragraph-instruction">
              Faça o upload no YouTube e informe seu link. Você pode subir no
              modo não listado, assim ele ficará visível apenas para quem tiver
              o link. Se o seu vídeo/áudio tiver menos de 10MB, você pode enviar
              diretamente por aqui.
            </p>
            <p className="paragraph-instruction">
              Caso queira enviá-lo depois, basta acessar novamente a plataforma
              com o e-mail e senha que você criou. Mas fique atenta para não
              perder o prazo ;)
            </p>
            <p className="paragraph-instruction">
              Se tiver alguma dúvida sobre como enviar seu vídeo/áudio, confira
              tutorial
              <a
                className="link"
                href="https://www.instagram.com/tv/CCmarKiHvz0/?utm_medium=copy_link"
                target="_blank"
                rel="noreferrer"
              >
                neste link
              </a>
            </p>
          </div>
          <Button
            text="Enviar meu vídeo/áudio"
            className="button-next"
            onClick={() => {
              createCourseSubscriptionLog(
                user._id,
                CourseSubscriptionAction.COURSE_SUBSCRIPTION_ATTACHMENT_SUBMISSION,
                CourseSubscriptionState.FORM_SUBMITTED,
                CourseSubscriptionState.ATTACHMENT_SUBMISSION,
              );
              setPage(2);
            }}
          />
          <Unsubscribe
            userId={user?._id}
            subscriptionId={subscription?._id}
            className="unsubscribe-intro-presentation"
          />
        </div>
        <Person className="person-intro " />
      </Layout>
    </div>
  );
};
export default memo(Intro);
