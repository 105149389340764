import React, { memo } from "react";
import { IconButton } from "..";
import {
  FacebookPurple,
  InstagramPurple,
  LinkedinPurple,
  TwitterPurple,
} from "../../../assets/icons";

import "./styles.scss";

const Footer = (): JSX.Element => (
  <div className="footer-component">
    <div>
      <h1 className="footer-component-title">
        <span className="footer-component-paragraph">The future is female</span>
        <span className="underline">coders</span>.
      </h1>
    </div>
    <div>
      <div className="footer-component-content">
        <ul className="footer-component-list">
          <li className="icons">
            <IconButton
              icon={<InstagramPurple />}
              onClick={() =>
                window.open("https://www.instagram.com/reprogramabr", "_blank")
              }
            />
          </li>
          <li className="icons">
            <IconButton
              icon={<FacebookPurple />}
              onClick={() =>
                window.open("https://www.facebook.com/ReprogramaBr", "_blank")
              }
            />
          </li>
          <li className="icons">
            <IconButton
              icon={<TwitterPurple />}
              onClick={() =>
                window.open("https://twitter.com/reprogramabr", "_blank")
              }
            />
          </li>
          <li className="icons">
            <IconButton
              icon={<LinkedinPurple />}
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/reprogramabr/",
                  "_blank",
                )
              }
            />
          </li>
        </ul>
        <p className="footer-component-address">
          Avenida Paulista, 1374 São Paulo - SP, Brasil
        </p>
      </div>
      <div />
    </div>
  </div>
);
export default memo(Footer);
