import React, { memo } from "react";
import "./style.scss";

interface Props {
  circle?: number;
}

const Counter = ({ circle }: Props): JSX.Element => (
  <div className="counter">{circle}</div>
);

export default memo(Counter);
