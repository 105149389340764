import React, { memo, useCallback, useEffect, useState } from "react";
import "./style.scss";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Layout, HeaderProfile, CardMenuLeft } from "../../components";
import CardCandidature from "../Candidature/CardCandidature";
import {
  addCompanyToUser,
  candidateOnGreenhouse,
  candidateOnGupy,
  getUserById,
  subscriptInVacancy,
  unsubscriptInVacancy,
  vacanciesPerUser,
} from "../../services/functions";
import {
  placementVacancyManagedBy,
  placementVacancySubscriptionStatus,
} from "../../types/enumerators";
import { RootState } from "../../store/reducers";
import AvatarEmpty from "../../../assets/customIcons/AvatarEmpty";
import { ChevronUp, Mail } from "../../../assets/customIcons";
import { setLoad } from "../../store/actions/configurationsActions";
import { User } from "../../types/interfaces";

interface Invites {
  managedBy?: string;
  jobTitle?: string;
  companyName?: string;
  jobStatus?: string;
  jobManagedBy?: string;
  jobTypeOfCandidature?: string;
  jobType?: string;
  jobPlace?: string;
  photo?: string;
  _id?: string;
  jobDescription?: string;
  startDate?: string | Date;
  partnerVacancyId?: string | number;
  messageToInvite?: {
    userId?: string;
    text?: string;
    date?: Date | string;
    name?: string;
    photo?: string;
    position?: string;
  };
}

interface InvitesProps {
  isSelected?: string;
  invites?: Invites[];
  handleClick: (id: string) => void;
  showMainCard?: boolean;
  selectedInfo?: Invites;
  user?: User;
  handleRefuseClick: (vacancyId: string) => void;
  handleCandidateClick: (
    vacancyId: string,
    managedBy: string,
    partnerVacancyId?: string | number,
  ) => void;
}

const RenderInvites = ({
  isSelected,
  invites,
  handleClick,
  showMainCard,
  selectedInfo,
  user,
  handleRefuseClick,
  handleCandidateClick,
}: InvitesProps): JSX.Element => {
  const history = useHistory();

  return (
    <div
      className={
        isSelected && invites && invites?.length > 0
          ? "card-candidature card-candidature-100"
          : "card-candidature"
      }
    >
      <div className="card-title-div">
        <h2 className="card-title">Convites para Processo Seletivo</h2>
      </div>
      <div className="flex">
        <div
          className={`card-candidature_scroll card-scroll-comment ${
            invites && invites?.length > 0 ? "" : "proposals-scroll-border"
          }`}
        >
          {invites?.map((vacancy) => (
            <div
              key={Math.random()}
              role="none"
              onClick={() => handleClick(vacancy?._id ?? "")}
              className="div-border-bottom pointer"
            >
              <CardCandidature
                data={vacancy}
                selectedId={isSelected === vacancy?._id}
              />
            </div>
          ))}
          {!invites?.length ? (
            <p className="margin-left-24">Não há convites disponíveis</p>
          ) : null}
        </div>
        {invites && invites?.length > 0 && showMainCard ? (
          <div className="div-invite-card">
            <div className="header-inside-card">
              <h2>Placement {"{reprograma}"}</h2>
              <span>
                {moment(selectedInfo?.startDate)
                  .tz("America/Sao_Paulo")
                  .format("DD [de] MMMM")}
              </span>
            </div>
            <div className="flex">
              {selectedInfo?.messageToInvite?.photo ? (
                <img
                  src={selectedInfo?.messageToInvite?.photo}
                  alt="Foto"
                  className="img-card-vacancies-advanced margin-left-24 margin-top-20"
                />
              ) : (
                <AvatarEmpty className="img-card-vacancies-advanced margin-left-24 margin-top-20 margin-right-8" />
              )}
              <div className="margin-left-16 margin-top-10">
                <p className="company-person">
                  {selectedInfo?.messageToInvite?.name}
                </p>
                <p className="job-company-person">
                  {selectedInfo?.messageToInvite?.position}
                </p>
              </div>
            </div>
            <div className="content-invite div-invite-message">
              <p>{`Olá ${user?.profile?.name?.split(" ")[0]},`}</p>
              <p>
                {`você foi convidada pela ${
                  invites?.find((e) => e?._id === isSelected)?.companyName
                } para participar do processo
                      seletivo para a vaga de ${
                        invites?.find((e) => e?._id === isSelected)?.jobTitle
                      }:`}
              </p>
              <p>
                {`${
                  invites?.find((e) => e?._id === isSelected)?.jobDescription
                }`}
              </p>
            </div>
            <div className="bottom-card-info">
              <div className="buttons-wrapper">
                <button
                  type="button"
                  className="refuse-button refuse-button-media"
                  onClick={() => handleRefuseClick(selectedInfo?._id ?? "")}
                >
                  Recusar
                </button>
                <button
                  type="button"
                  className="refuse-button refuse-button-media"
                  onClick={() =>
                    handleCandidateClick(
                      selectedInfo?._id ?? "",
                      selectedInfo?.managedBy ?? "",
                      selectedInfo?.partnerVacancyId ?? "",
                    )
                  }
                >
                  Aceitar
                </button>
                <button
                  type="button"
                  className="accept-button  accept-button-media "
                  onClick={() =>
                    history.push(`/job/${selectedInfo?._id ?? ""}`)
                  }
                >
                  VER VAGA
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div> </div>
        )}
      </div>
    </div>
  );
};

const RenderInvitesMobile = ({
  isSelected,
  invites,
  handleClick,
  showMainCard,
  selectedInfo,
  user,
  handleRefuseClick,
  handleCandidateClick,
}: InvitesProps) => {
  const history = useHistory();

  return (
    <div className="margin-top- margin-bottom-muiAccordion">
      {invites?.map((vacancy) => (
        <Accordion className="margin-top-0 header-modal">
          <AccordionSummary
            expandIcon={<ChevronUp widthIcon={24} heightIcon={24} />}
            className="expanded-icon margin-mui-expanded margin-bottom-accordion"
          >
            <div className="card-candidature_scroll card-scroll-comment">
              <div
                key={Math.random()}
                role="none"
                onClick={() => handleClick(vacancy?._id ?? "")}
                className="div-border-bottom pointer"
              >
                <CardCandidature
                  data={vacancy}
                  selectedId={isSelected === vacancy._id}
                />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="margin-mui-expanded">
            {showMainCard ? (
              <div className="div-invite-card">
                <div className="header-inside-card">
                  <h2>
                    Placement
                    {"{reprograma}"}
                  </h2>
                  <span>
                    {moment(selectedInfo?.startDate)
                      .tz("America/Sao_Paulo")
                      .format("DD [de] MMMM")}
                  </span>
                </div>
                <div className="flex">
                  {selectedInfo?.messageToInvite?.photo ? (
                    <img
                      src={selectedInfo?.messageToInvite?.photo}
                      alt="Foto"
                      className="avatar-mobile margin-left-24 margin-top-20"
                    />
                  ) : (
                    <AvatarEmpty className="avatar-mobile  margin-left-24 margin-top-20 margin-right-8" />
                  )}
                  <div className="margin-left-16 margin-top-10">
                    <p className="company-person">
                      {selectedInfo?.messageToInvite?.name}
                    </p>
                    <p className="job-company-person">
                      {selectedInfo?.messageToInvite?.position}
                    </p>
                  </div>
                </div>
                <div className="content-invite div-invite-message bottom-20-mobile">
                  <p>{`Olá ${user?.profile?.name?.split(" ")[0]},`}</p>
                  <p>
                    {`você foi convidada pela ${
                      invites?.find((e) => e?._id === isSelected)?.companyName
                    } para participar do processo
                      seletivo para a vaga de ${
                        invites?.find((e) => e?._id === isSelected)?.jobTitle
                      }:`}
                  </p>
                  <p>
                    {`${
                      invites?.find((e) => e?._id === isSelected)
                        ?.jobDescription
                    }`}
                  </p>
                </div>
                <div className="bottom-card-info ">
                  <div className="buttons-wrapper flex-direction-column-reverse">
                    <button
                      type="button"
                      className="refuse-button refuse-button-media"
                      onClick={() => handleRefuseClick(selectedInfo?._id ?? "")}
                    >
                      Recusar
                    </button>
                    <button
                      type="button"
                      className="refuse-button refuse-button-media bottom-20-mobile"
                      onClick={() =>
                        handleCandidateClick(
                          selectedInfo?._id ?? "",
                          selectedInfo?.managedBy ?? "",
                          selectedInfo?.partnerVacancyId ?? "",
                        )
                      }
                    >
                      Aceitar
                    </button>
                    <button
                      type="button"
                      className="accept-button  accept-button-media bottom-20-mobile"
                      onClick={() =>
                        history.push(`/job/${selectedInfo?._id ?? ""}`)
                      }
                    >
                      VER VAGA
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div> </div>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      {!invites?.length ? (
        <div className="card-candidature margin-top-16-responsive">
          <p className="margin-left-24 not-proposed">
            Não há convites disponíveis
          </p>
        </div>
      ) : null}
    </div>
  );
};

const Invites = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isSelected, setIsSelected] = useState("");
  const [showMainCard, setShowMainCard] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState<Invites>();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [invites, setInvites] = useState<Invites[] | []>();
  const user = useSelector((state: RootState) => state?.userState);

  const dispatch = useDispatch();

  const loadSubscritedJobs = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    try {
      const invitedJobs = await vacanciesPerUser(
        user?._id,
        placementVacancySubscriptionStatus.PENDING,
      );

      const invitedJobsMapped =
        invitedJobs?.map((job) => ({
          _id: job?.placementVacancyData?._id,
          jobTitle: job?.placementVacancyData?.title,
          companyName: job?.companyData?.profile?.name,
          jobStatus: job?.placementVacancyData?.status ?? "",
          jobManagedBy: job?.placementVacancyData?.managedBy ?? "",
          jobTypeOfCandidature:
            job?.placementVacancyData?.typeOfCandidature ?? "",
          photo: job?.companyData?.profile?.logo,
          jobDescription: job?.placementVacancyData?.description,
          jobPlace: job?.placementVacancyData?.place,
          jobType: job?.placementVacancyData?.type,
          startDate: job?.startDate,
          jobTime: job?.placementVacancyData?.startDate,
          partnerVacancyId: job?.placementVacancyData?.partnerVacancyId,
          messageToInvite: job?.messageToInvite,
        })) ?? [];
      setInvites(invitedJobsMapped);
      dispatch(setLoad(false) as unknown as Action);
    } catch (err) {
      dispatch(setLoad(false) as unknown as Action);
      toast.error("Ocorreu um erro ao carregar os convites");
    }
  }, [dispatch, user?._id]);

  useEffect(() => {
    if (!invites) loadSubscritedJobs();
  }, [user?._id, shouldUpdate, invites, loadSubscritedJobs]);

  const getUserSendInvite = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const responseUser = await addCompanyToUser(
      await getUserById(selectedInfo?.messageToInvite?.userId ?? ""),
    );

    const tagPosition = responseUser?.account?.tags?.find((item: string) =>
      item.includes("position"),
    );
    const position = tagPosition?.split(":")[1] ?? "";

    const newSelectedInfo = {
      ...selectedInfo,
      messageToInvite: {
        ...selectedInfo?.messageToInvite,
        name: responseUser?.profile?.name ?? "",
        photo: responseUser?.profile?.photo ?? "",
        position: position ?? "",
      },
    };
    setSelectedInfo(newSelectedInfo);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, selectedInfo]);

  useEffect(() => {
    if (
      selectedInfo?.messageToInvite &&
      !selectedInfo?.messageToInvite?.name &&
      !selectedInfo?.messageToInvite?.photo &&
      !selectedInfo?.messageToInvite?.position
    )
      getUserSendInvite();
  }, [getUserSendInvite, selectedInfo, selectedInfo?.messageToInvite]);

  const handleRefuseClick = useCallback(
    async (vacancyId: string) => {
      try {
        await unsubscriptInVacancy(vacancyId, user?._id);
        setShouldUpdate(true);
        toast.success("Convite recusado com sucesso");
      } catch (error) {
        // eslint-disable-next-line no-console
        console?.warn(error);
      }

      loadSubscritedJobs();
    },
    [user?._id, loadSubscritedJobs],
  );

  const handleCandidateClick = useCallback(
    async (
      vacancyId: string,
      managedBy: string,
      partnerVacancyId?: string | number,
    ) => {
      try {
        switch (managedBy) {
          case placementVacancyManagedBy.GREENHOUSE:
            if (
              partnerVacancyId &&
              !(await candidateOnGreenhouse(
                user?._id,
                vacancyId,
                partnerVacancyId,
              ))
            )
              throw new Error("");
            break;
          case placementVacancyManagedBy.GUPY:
            if (
              partnerVacancyId &&
              !(await candidateOnGupy(user?._id, vacancyId))
            )
              throw new Error("");
            break;
          default:
            await subscriptInVacancy(vacancyId ?? "", user?._id);
            break;
        }
        setShouldUpdate(true);
        toast.success("Candidatura aceita com sucesso");
      } catch (err) {
        toast.error("Ocorreu um erro ao se candidatar");
      }

      loadSubscritedJobs();
    },
    [user?._id, loadSubscritedJobs],
  );

  const handleClick = useCallback(
    (id: string) => {
      setIsSelected(id);
      setSelectedInfo(invites?.find((item) => item?._id === id) ?? {});

      setShowMainCard(true);
    },
    [invites],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-template-column-1 grid-responsive">
      <HeaderProfile />
      <div className="grid-column-1-11">
        <div className="grid-template-columns-1-5 display-block">
          <CardMenuLeft
            icon={<Mail widthIcon={20} heightIcon={20} />}
            selectedMenuTitle="Convites"
          />
          {width > 990 ? (
            <RenderInvites
              isSelected={isSelected}
              invites={invites}
              handleClick={handleClick}
              showMainCard={showMainCard}
              selectedInfo={selectedInfo}
              user={user}
              handleRefuseClick={handleRefuseClick}
              handleCandidateClick={handleCandidateClick}
            />
          ) : (
            <RenderInvitesMobile
              isSelected={isSelected}
              invites={invites}
              handleClick={handleClick}
              showMainCard={showMainCard}
              selectedInfo={selectedInfo}
              user={user}
              handleRefuseClick={handleRefuseClick}
              handleCandidateClick={handleCandidateClick}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default memo(Invites);
