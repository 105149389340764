import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import * as Yup from "yup";
import { useAuth } from "../../auth";
import {
  Layout,
  Card,
  HeaderChildrenTitle,
  Modal,
  TypeAccess,
} from "../../components";
import { ClosePurple } from "../../../assets/icons";
import {
  getClassVacancyByStatus,
  thisUserAlreadyExists,
} from "../../services/functions";
import { RootState } from "../../store/reducers";
import { redirectUsers } from "../../utils";
import Loading from "../Loading";
import { Email, Error, Password, SendEmail, SetPassword } from "./Steps";
import "./styles.scss";
import { classVacancyStatus, userRoles } from "../../types/enumerators";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = (): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [isLoad, setIsLoad] = useState(false);
  const [type, setType] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgot, setForgot] = useState(false);
  const [error, setError] = useState(false);
  const [hasEmail, setHasEmail] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);
  const [hasActiveVacancy, setHasActiveVacancy] = useState(false);
  const [openEmptyModal, setOpenEmptyModal] = useState(false);

  const query = useQuery();
  const token = query.get("token");
  const history: {
    push: (item: { pathname: string }) => void;
    location: {
      state: {
        from: { pathname: string };
      };
    };
  } = useHistory();
  const user = useSelector((state: RootState) => state.userState);
  const { signIn, isValidToken } = useAuth();

  const clear = useCallback(() => {
    setEmail("");
    setPassword("");
    setType("");
    setForgot(false);
  }, []);

  const handleLogin = useCallback(async () => {
    setIsLoad(true);
    try {
      const schema = Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string()
          .min(8)
          .required()
          .matches(
            /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z$!*&@#%^=+|}{`~˜ˆ:;"/?><.,'_]{8,}$/,
          ),
      });
      await schema.validate(
        { email, password },
        {
          abortEarly: false,
        },
      );

      const success = await signIn(email, password, type);
      if (success) redirectUsers(user, history);
      else {
        clear();
        setError(true);
      }
    } catch (err) {
      setPassword("");
      if (err instanceof Yup.ValidationError) {
        toast.error(
          `Verifique seu email/senha. Lembre-se que os caracteres "-, (, ), [, ]" não serão aceitos.`,
        );
        return;
      }
      toast.error(
        `Credenciais inválidas. Lembre-se que os caracteres "-, (, ), [, ]" não serão aceitos.`,
      );
    } finally {
      setEmail("");
      setPassword("");
      setType("");
      setIsLoad(false);
    }
  }, [clear, email, history, password, signIn, type, user]);

  useEffect(() => {
    if (!!email && !!password) handleLogin();
  }, [email, handleLogin, password]);

  useEffect(() => {
    if (!type && (!!email || !!password)) clear();
  }, [clear, email, password, type]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2500);
  }, []);

  const verifyEmailAndPassword = useCallback(async () => {
    setIsLoad(true);
    const response = await thisUserAlreadyExists(email);
    setHasEmail(response?.hasEmail ?? false);
    setHasPassword(response?.hasPassword ?? false);
    setIsLoad(false);
  }, [email]);

  useEffect(() => {
    if (email) verifyEmailAndPassword();
  }, [email, verifyEmailAndPassword]);

  const findClassVacancy = useCallback(async () => {
    const classVacancy = await getClassVacancyByStatus(
      classVacancyStatus.ACTIVE,
    );
    setHasActiveVacancy(!!classVacancy);
    if (!classVacancy) {
      clear();
      setOpenEmptyModal(true);
    }
  }, [clear]);

  useEffect(() => {
    if (type === userRoles.CANDIDATE && !!email && (!hasEmail || !hasPassword))
      findClassVacancy();
  }, [email, findClassVacancy, hasEmail, hasPassword, type]);

  useEffect(() => {
    const tokenSS = sessionStorage.getItem("@SESSION:token");
    if (tokenSS && isValidToken(tokenSS) && !!user?._id)
      redirectUsers(user, history);
  }, [history, isValidToken, user]);

  const renderCards = useMemo(() => {
    if (isLoad)
      return (
        <div className="login-loading">
          <CircularProgress />
        </div>
      );

    if (token) return <SetPassword token={token} />;

    if (forgot)
      return <SendEmail clear={clear} setError={setError} type={type} />;

    if (error) return <Error />;

    if (type === userRoles.CANDIDATE) {
      if (!email) return <Email setData={setEmail} />;

      if (!!email && hasActiveVacancy && (!hasEmail || !hasPassword))
        return <SendEmail clear={clear} type={type} />;

      if (!!email && hasEmail && hasPassword && !password)
        return <Password setData={setPassword} setForgot={setForgot} />;
    }

    if (
      type === userRoles.ADMIN ||
      type === userRoles.STUDENT ||
      type === userRoles.CORPORATE
    ) {
      if (!email) return <Email setData={setEmail} />;

      if (!!email && !hasEmail) return <Error />;

      if (!!email && hasEmail && !hasPassword)
        return <SendEmail clear={clear} type={type} />;

      if (!!email && hasEmail && hasPassword && !password)
        return <Password setData={setPassword} setForgot={setForgot} />;
    }

    if (!type)
      return (
        <div className="grid-column-5-9 margin-bottom-32 width-100">
          <TypeAccess setType={setType} />
        </div>
      );

    return (
      <div className="login-loading">
        <CircularProgress />
      </div>
    );
  }, [
    clear,
    email,
    error,
    forgot,
    hasActiveVacancy,
    hasEmail,
    hasPassword,
    isLoad,
    password,
    token,
    type,
  ]);

  useEffect(() => {
    if (type) sessionStorage.setItem("@SESSION:type", type);
  }, [type]);

  return loading ? (
    <Loading />
  ) : (
    <Layout header className="grid-responsive">
      <div className="grid-column-4-10 margin-bottom-32">
        <Card
          headerChildren={!type && !error ? <HeaderChildrenTitle /> : undefined}
          onClickBack={
            type || error
              ? () => {
                  setType("");
                  setEmail("");
                  setPassword("");
                  setForgot(false);
                  setError(false);
                }
              : undefined
          }
          headerAction
          bodyClassName="padding-0"
        >
          {renderCards}
        </Card>
      </div>
      <Modal
        open={openEmptyModal}
        onClose={() => setOpenEmptyModal(false)}
        className="welcome-modal-class"
      >
        <div className="flex-col align-end">
          <ClosePurple
            onClick={() => setOpenEmptyModal(false)}
            className="close-icon-course-empty"
          />
          <div>
            Que pena! Infelizmente no momento não há cursos disponíveis.
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default memo(Login);
