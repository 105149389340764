/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment-timezone";
import { Check, ArrowBack } from "../../../assets/icons";
import { AvatarEmpty } from "../../../assets/customIcons";
import { Button, Modal, TextArea } from "../../components";
import { RootState } from "../../store/reducers";
import { DataProps } from "./index";
import {
  getUserById,
  getActiveUserAdmin,
  createNotifications,
  updateClassVacancySubscriptionById,
} from "../../services/functions";
import type { User as UserType } from "../../types/interfaces";
import { setLoad } from "../../store/actions/configurationsActions";

interface Props {
  data: DataProps;
  fetch: () => Promise<void>;
  selected: string;
  classVacancyId: string;
}

interface UserWithPhotoProps {
  photo: string | null;
  text: string;
  date: string | Date;
  userId?: string | undefined;
  element?: string | undefined;
  grade?: number | undefined;
  userName: string;
  resolved?: boolean;
}

interface CommentProps {
  key: string;
  type: string;
  date: string | Date | number;
  comment: string;
}

const CardExpandMessages = ({
  data,
  fetch,
  selected,
  classVacancyId,
}: Props): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [comment, setComment] = useState<CommentProps>({
    key: "",
    type: "",
    date: moment().tz("America/Sao_Paulo").format("DD/MM/YYYY"),
    comment: "",
  });
  const [loadingButton, setLoadingButton] = useState<typeof comment | null>(
    null,
  );
  const [dataFormatted, setDataFormatted] = useState<
    Record<string, UserWithPhotoProps[]> | null | []
  >(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useSelector((state: RootState) => state.userState);

  const onClose = useCallback(() => {
    setOpenModal(false);
    setComment({
      key: "",
      type: "",
      date: moment().tz("America/Sao_Paulo").format("DD/MM/YYYY"),
      comment: "",
    });
  }, []);

  const submitComments = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const activeUsers = await getActiveUserAdmin();

    const filterUsers = activeUsers?.filter(
      (item) => item._id !== currentUser._id,
    );

    filterUsers?.forEach(async (user) => {
      const dataUser = {
        userId: user?._id,
        hasRead: false,
        title: "Novo comentário",
        text: "Há um novo comentário sobre uma candidata de um dos nossos processos seletivos.",
        date: new Date(),
        action: `() => history.push({pathname: '/admin-class-vacancy-comments', state: { classVacancyId: '${classVacancyId}', classVacancySubscriptionId: '${data?._id}' }})`,
      };

      await createNotifications(dataUser);
    });

    const miscellaneous = {
      ...(data?.miscellaneous || {}),
      generalComment: [
        ...(data?.miscellaneous?.generalComment || []),
        {
          text: comment?.comment,
          date: moment(comment?.date)
            .tz("America/Sao_Paulo")
            .format("DD/MM/YYYY"),
          userId: currentUser?._id,
          element: comment?.key,
          resolved: false,
        },
      ],
    };

    await updateClassVacancySubscriptionById(data?._id ?? "", {
      miscellaneous,
    });

    fetch();

    setComment({
      key: "",
      type: "",
      date: moment().tz("America/Sao_Paulo").format("DD/MM/YYYY"),
      comment: "",
    });

    dispatch(setLoad(false) as unknown as Action);
  }, [
    dispatch,
    data,
    comment?.comment,
    comment?.date,
    comment?.key,
    currentUser._id,
    fetch,
    classVacancyId,
  ]);

  const resolveComment = useCallback(
    async (type: boolean, props: typeof comment) => {
      setLoadingButton(props);

      const findIndex = data?.miscellaneous?.generalComment?.map(
        (item, idx) => {
          if (item?.text === props?.comment && item?.element === props?.key)
            return idx;
          return null;
        },
      );

      const filterIndex = findIndex?.find((item) => item !== null);

      const miscellaneous = {
        ...(data?.miscellaneous || {}),
        generalComment: data?.miscellaneous?.generalComment?.map(
          (general, idx) =>
            idx === filterIndex
              ? {
                  ...data?.miscellaneous?.generalComment?.[idx],
                  resolved: type,
                }
              : general,
        ),
      };

      await updateClassVacancySubscriptionById(data?._id ?? "", {
        miscellaneous,
      });

      await fetch();

      setLoadingButton(null);
    },
    [data, fetch],
  );

  useEffect(() => {
    const getUserPicture = async () => {
      if (data && data?.miscellaneous && data?.miscellaneous?.generalComment) {
        dispatch(setLoad(true) as unknown as Action);
        const findComments =
          data?.miscellaneous?.generalComment?.filter(
            ({ element }) => element === selected,
          ) || [];

        const getUsers = (await Promise.all(
          findComments?.map(async ({ userId }) => getUserById(userId || "")),
        )) as UserType[];

        const userWithPhotos = findComments?.map((item) => {
          const find = getUsers.find(
            (subitem) => subitem?._id === item?.userId,
          );

          return {
            ...item,
            userName: find?.profile?.name || "",
            photo: find?.profile?.photo || null,
          };
        });

        const groupUsers = userWithPhotos?.reduce((acc, curr) => {
          acc[curr?.date as string] = [...(acc[curr?.date as string] || [])];

          acc[curr?.date as string].push(curr);

          return acc;
        }, {} as Record<string, UserWithPhotoProps[]>);

        setDataFormatted(groupUsers);

        dispatch(setLoad(false) as unknown as Action);
      } else setDataFormatted([]);
    };

    getUserPicture();
  }, [data, dispatch, selected]);

  return (
    <div className="width-100 max-height-440 overflow-x-hidden">
      {data?.miscellaneous?.generalComment &&
        data?.miscellaneous?.generalComment.filter(
          ({ element }) => element === selected,
        ).length > 0 && (
          <div className="div-card-comments">
            <p className="comment-user-name">{data?.user?.name}</p>
            <p className="list-comment-date">
              {data?.miscellaneous?.generalComment[0]?.date}
            </p>
          </div>
        )}
      <div className="modal-comments-items mb-32 admin-comments-items">
        {Object.entries(dataFormatted ?? {})?.map(([key, users], idx) => (
          <div key={Math.random()}>
            {idx !== 0 && (
              <div className="modal-comments-header">
                <p>{key}</p>
              </div>
            )}
            {users.map((subitem) => (
              <div className="modal-comments-comment">
                {subitem?.photo ? (
                  <img alt="" src={subitem?.photo} />
                ) : (
                  <AvatarEmpty
                    className="border-avatar-empty img-attachment-photo-small"
                    heightIcon={32}
                    widthIcon={32}
                  />
                )}
                <div className="modal-comments-comment-body">
                  <span> {subitem?.userName}</span>
                  <div className="h1-recent-search">{subitem?.text}</div>
                </div>

                <div className="modal-comments-comment-body-buttons">
                  <button
                    type="button"
                    onClick={() => {
                      resolveComment(!subitem?.resolved, {
                        key: subitem?.element ? subitem?.element : "",
                        type: "",
                        date: subitem?.date
                          ? subitem?.date
                          : moment()
                              .tz("America/Sao_Paulo")
                              .format("DD/MM/YYYY"),
                        comment: subitem?.text,
                      });
                    }}
                  >
                    {loadingButton &&
                    loadingButton?.key === subitem?.element &&
                    loadingButton?.comment === subitem?.text ? (
                      <CircularProgress size={18} />
                    ) : !subitem?.resolved ? (
                      <Check />
                    ) : (
                      <ArrowBack />
                    )}
                    <p>{subitem?.resolved ? "Reabrir" : "Resolver"}</p>
                  </button>
                </div>
              </div>
            ))}
          </div>
        ))}

        {Object.keys(dataFormatted ?? {}).length === 0 && (
          <p className="margin-left-24">Não há mensagens</p>
        )}
      </div>

      <div className="divider-card" />
      <div className="flex space-between padding-buttons flex-col-mobile gap-16-mobile">
        <Button
          text="Voltar"
          className="width-20 margin-right-24 width-100-responsive"
          onClick={() =>
            history.push(`/company-vacancy/${data?.classVacancyId}`)
          }
          outline
          appearance="secondary"
        />
        <div className="width-30 flex width-100-mobile">
          <Button
            text="Enviar mensagem"
            className="width-100"
            onClick={() => setOpenModal(true)}
          />
        </div>
      </div>
      <Modal
        headerAction
        textHeader="Escreva sua mensagem"
        open={openModal}
        onClose={onClose}
        bodyClassName="padding-0"
      >
        <div className="width-310">
          <TextArea
            id="description"
            label="Escreva seu comentário*"
            maxLength={500}
            value={comment?.comment}
            onChange={(e) =>
              setComment({
                key: selected,
                type: "",
                date: moment().tz("America/Sao_Paulo").format("DD/MM/YYYY"),
                comment: e.target.value,
              })
            }
          />
          <div className="flex-row-center margin-top-32 flex-col-mobile gap-16-mobile">
            <Button
              text="Cancelar"
              outline
              className="width-100-mobile"
              onClick={onClose}
              appearance="secondary"
            />
            <Button
              text="Salvar"
              className="margin-left-32 width-100-mobile"
              onClick={submitComments}
              disabled={!comment?.comment}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default memo(CardExpandMessages);
