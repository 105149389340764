import React, { useMemo } from "react";
import { MediaViewProps } from "./types";
import { classVacancyType } from "../../../types/enumerators";
import { CardMedia } from "../../../components";
import { rubrics } from "./data";
import "../style.scss";

const MediaView = ({ subscription, course }: MediaViewProps): JSX.Element => {
  const stageMedia = useMemo(() => {
    if (subscription && course) {
      const media = course?.stages?.find(
        (item) => item?.type === classVacancyType.UPLOAD_MEDIAS,
      );

      const findUserMedia = subscription?.stages?.find(
        ({ stageId }) => stageId === media?.stage_id,
      );

      if (findUserMedia) return findUserMedia;
    }

    return null;
  }, [subscription, course]);

  const media = useMemo(
    () => subscription?.form?.media,
    [subscription?.form?.media],
  );

  const form = useMemo(() => stageMedia?.form, [stageMedia?.form]);

  const grade = useMemo(
    () => stageMedia?.grade?.find(({ type }) => type === "FINAL")?.value,
    [stageMedia?.grade],
  );

  return (
    <div className="subscriptions-media-form">
      {(!media || media?.url === "") && !form && (
        <p>Esta aluna não possui mídia cadastrada.</p>
      )}

      <div className={`subscriptions-media ${form && "form-media"}`}>
        {media && media?.url !== "" && (
          <div className="subscriptions-media-content">
            <div>
              {media?.type === "LINK" ? (
                <div className="subscriptions-card">
                  <div className="label-infos-content">
                    <p className="label-title">Link do vídeo</p>
                  </div>
                  <a
                    className="label-description"
                    href={
                      media?.url?.includes("http")
                        ? media?.url
                        : `http://${media?.url}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {media?.url}
                  </a>
                </div>
              ) : (
                <div className="subscriptions-media-card">
                  <CardMedia
                    item={{
                      title: media?.url,
                      url: media?.url,
                    }}
                    cantEdit
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {form &&
          rubrics(course?.template)?.map(({ question }, idx) => {
            const answerFormMedia = Object.values(form)[idx];

            if (answerFormMedia)
              return (
                <div
                  className="subscriptions-media-form media-form LALA"
                  key={Math.random()}
                >
                  <h4 key={Math.random()} className="propose-form-title">
                    {idx + 1}
                    &nbsp; &minus; &nbsp;
                    {question}
                  </h4>
                  <p>{answerFormMedia}</p>
                </div>
              );

            return null;
          })}
      </div>

      <div className="subscriptions-workshop-items-content">
        <div className="label-infos">
          <p className="label-title">Nota final</p>
          <p className="label-description">{grade ?? 0}</p>
        </div>
      </div>
    </div>
  );
};

export default MediaView;
