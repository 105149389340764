import React, { MouseEventHandler } from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Invites = ({
  widthIcon,
  heightIcon,
  onClick,
  className,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "20"}
    height={heightIcon ?? "20"}
    onClick={onClick}
    className={className}
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      d="M15.662 4.708H4.963a1.84 1.84 0 0 0-1.838 1.839v2.657H1.36a.526.526 0 0 0 0 1.053h1.765v1.414h-.88a.527.527 0 0 0 0 1.053h.88v.687a1.84 1.84 0 0 0 1.838 1.839h10.698a1.84 1.84 0 0 0 1.84-1.839V6.547a1.84 1.84 0 0 0-1.84-1.839zM4.963 5.761h10.699c.186 0 .358.066.493.175l-5.11 3.75a1.25 1.25 0 0 1-1.465 0l-5.11-3.75a.782.782 0 0 1 .493-.175zm11.484 7.65a.787.787 0 0 1-.785.786H4.963a.787.787 0 0 1-.785-.786v-.688H6.16a.526.526 0 0 0 0-1.053H4.178V7.029l4.779 3.506c.399.293.877.44 1.355.44a2.29 2.29 0 0 0 1.356-.44l4.78-3.506v6.383z"
      fill="#fff"
      stroke="#fff"
      strokeWidth=".2"
    />
  </svg>
);

export default Invites;
