/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  updateResearchEmployment,
  createResearchEmployment,
} from "../../services/functions";
import {
  EMPLOYMENT_CREATE,
  EMPLOYMENT_SET,
  EMPLOYMENT_UPDATE,
} from "../types/employmentTypes";

export const createEmployment = (employment: any) => async (dispatch: any) => {
  dispatch({
    type: EMPLOYMENT_CREATE,
    payload: employment,
  });
  try {
    const user = JSON.parse(sessionStorage.getItem("@SESSION:user") ?? "null");
    const payload = {
      ...employment,
      date: new Date(),
      userId: user._id,
    };
    await createResearchEmployment(payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
  }
};

export const setEmployment = (employment: any) => (dispatch: any) => {
  dispatch({
    type: EMPLOYMENT_SET,
    payload: employment,
  });
};

export const updateEmployment = (employment: any) => async (dispatch: any) => {
  dispatch({
    type: EMPLOYMENT_UPDATE,
    payload: employment,
  });
  try {
    const newEmployment = { ...employment };

    delete newEmployment._id;

    await updateResearchEmployment(employment._id, newEmployment);
  } catch (err) {
    console.warn(err);
  }
};
