/* eslint-disable no-await-in-loop */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ClassStep } from "reprograma-types/dist/base/Class";
import { FormControlLabel, Radio, RadioGroup, Switch } from "@material-ui/core";
import { Add } from "../../../../assets/icons";
import {
  Button,
  Card,
  CardItems,
  Checkbox,
  IconButton,
  Input,
  InputAutocomplete,
  InputDate,
  Select,
  TextArea,
} from "../../../components";
import { StageType } from "../../../types/constants/Course";
import {
  classStepType,
  classStructure,
  classVacancyStatus,
  classVacancyType,
  userRoles,
  userStatus,
} from "../../../types/enumerators";
import {
  Class,
  ClassVacancy,
  ClassVacancyStages,
} from "../../../types/interfaces";
import {
  createId,
  hasAdminClassVacancyVolunteerMediaRole,
  order,
} from "../../../utils";
import {
  createUser,
  filterClass,
  getClassById,
  getUserAdminClassVacancyVolunter,
  getUserByIdentification,
  patchUser,
  requestSignupLink,
  updateClassById,
} from "../../../services/functions";
import { setLoad } from "../../../store/actions/configurationsActions";

interface Props {
  classVacancy: ClassVacancy;
  setClassVacancy: Dispatch<SetStateAction<ClassVacancy>>;
  nextPage: () => void;
  onSave: () => void;
}

const stepsType: { [key: string]: string } = {
  hasEvaluationBehavior: "EVALUATION_BEHAVIOR",
  hasDiversityForm: "DIVERSITY_FORM",
  hasTechnicalTest: "TECHNICAL_TEST",
  hasTechnicalTestForm: "TECHNICAL_TEST_FORM",
};

const Stages = ({
  classVacancy,
  setClassVacancy,
  nextPage,
  onSave,
}: Props): JSX.Element => {
  const [addStage, setAddStage] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [allClass, setAllClass] = useState<Class[]>([]);
  const [classes, setClasses] = useState<Class[]>([]);
  const [emailVolunterOptions, setEmailVolunterOptions] = useState<string[]>();
  const [stage, setStage] = useState<ClassVacancyStages>({
    stage_id: createId() ?? "",
    status: classVacancyStatus.PENDING,
    order: classVacancy?.stages?.length ?? 0,
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const existStages = useMemo(
    () => classVacancy?.stages?.length,
    [classVacancy?.stages?.length],
  );

  const [oldListEmailsStageVolunteers, setOldListEmailsStageVolunteers] =
    useState(stage?.volunteers);

  const getClasses = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await filterClass();
    if (response?.data) setAllClass(response?.data);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  const getValueClasses = useCallback(async () => {
    const valueClasses: Class[] = [];
    dispatch(setLoad(true) as unknown as Action);

    if (stage?.classIds && stage?.classIds?.length) {
      for (let i = 0; i < stage?.classIds?.length; i += 1) {
        const response = await getClassById(stage?.classIds[i] ?? "");
        if (response?.data) valueClasses?.push(response?.data);
      }
    }

    setClasses(valueClasses);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, stage?.classIds]);

  useEffect(() => {
    if (classVacancy?.stages?.length) order(classVacancy?.stages);
  }, [classVacancy?.stages]);

  useEffect(() => {
    if (!oldListEmailsStageVolunteers && stage?.volunteers?.length)
      setOldListEmailsStageVolunteers(stage?.volunteers);
  }, [oldListEmailsStageVolunteers, stage?.volunteers]);

  useEffect(() => {
    if (stage?.type === classVacancyType.WORKSHOP && !allClass?.length)
      getClasses();
  }, [allClass?.length, getClasses, stage?.type]);

  useEffect(() => {
    if (stage?.classIds?.length) getValueClasses();
    else setClasses([]);
  }, [getValueClasses, stage?.classIds?.length]);

  const loadUserAdminClassVacancyVolunter = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getUserAdminClassVacancyVolunter();
    const responseEmailUserOptions = response?.map(
      (option) => option?.login?.identification,
    );
    setEmailVolunterOptions(responseEmailUserOptions);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  useEffect(() => {
    if (!emailVolunterOptions) loadUserAdminClassVacancyVolunter();
  }, [emailVolunterOptions, loadUserAdminClassVacancyVolunter]);

  const updatedStage = useCallback(() => {
    delete stage?.volunteers;
    setStage({ ...stage });
  }, [stage]);

  const handleChange = useCallback(
    (e, prop: "title" | "type" | "description" | "url") =>
      setStage({ ...stage, [prop]: e.target.value }),
    [stage],
  );

  const handleChangeDate = useCallback(
    (value, prop: string) =>
      setStage({
        ...stage,
        [prop]: value?.startOf("day").toISOString(),
      }),
    [stage],
  );

  const handleChangeSwitch = useCallback(
    (value, prop: "autoOpen" | "autoClose" | "optional") =>
      setStage({ ...stage, [prop]: value }),
    [stage],
  );

  const handleChangeClassIds = useCallback(
    (value) => {
      const classesIds: Class[] = [];
      value?.forEach((item: string) => {
        const valueClass = allClass?.find(
          (innerItem) => innerItem?.code === item,
        );
        if (valueClass) classesIds?.push(valueClass);
      });
      setStage({
        ...stage,
        classIds: classesIds?.map((item) => item?._id ?? ""),
      });
    },
    [stage, allClass],
  );

  const handleChangeValue = useCallback(
    (value) =>
      setStage({
        ...stage,
        volunteers:
          typeof value === "string"
            ? value?.trim()
            : value?.map((item: string) => item?.toLowerCase().trim()),
      }),
    [stage],
  );

  const handleChangeRadio = useCallback(
    (e, prop: "auto") =>
      e.target.value === "true"
        ? setStage({ ...stage, [prop]: true })
        : setStage({ ...stage, [prop]: false }),
    [stage],
  );

  const handleChangeCheckbox = useCallback(
    (
      prop:
        | "hasEvaluationBehavior"
        | "hasDiversityForm"
        | "hasTechnicalTest"
        | "hasTechnicalTestForm",
      value,
    ) => {
      const updateStage = { ...stage };
      if (prop === "hasTechnicalTest" && !value)
        delete updateStage?.hasTechnicalTestForm;

      if (prop === "hasTechnicalTestForm" && !value) {
        delete updateStage?.hasTechnicalTest;
      }

      setStage({ ...updateStage, [prop]: !value });
    },
    [stage],
  );

  const deleteCurrentClassVacancyId = useCallback(
    async (listAllEmailsAdmVolunteers) => {
      dispatch(setLoad(true) as unknown as Action);

      const classVacacancyId = classVacancy?._id ?? "";

      for (let i = 0; i < listAllEmailsAdmVolunteers?.length; i += 1) {
        const userAdmVolunteer = await getUserByIdentification(
          listAllEmailsAdmVolunteers[i],
        );

        const classVacancyObject =
          userAdmVolunteer?.account?.classVacancy ?? {};

        if (
          classVacacancyId &&
          Object.hasOwn(classVacancyObject, classVacacancyId)
        )
          delete classVacancyObject[classVacacancyId];

        if (Object.keys(classVacancyObject)?.length === 0) {
          delete userAdmVolunteer?.account?.classVacancy;

          if (
            userAdmVolunteer?.account?.roles?.length &&
            userAdmVolunteer?.account?.roles?.length > 1
          ) {
            const idx = userAdmVolunteer?.account?.roles?.findIndex(
              (item) => item === userRoles.ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA,
            );

            if (idx > -1) userAdmVolunteer?.account?.roles?.splice(idx, 1);
          } else if (userAdmVolunteer?.account?.status)
            userAdmVolunteer.account.status = userStatus.DISABLED;
        }

        await patchUser(userAdmVolunteer?._id ?? "", {
          account: {
            ...userAdmVolunteer?.account,
          },
        });
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [classVacancy, dispatch],
  );

  const addIdAdmVolunteer = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    if (stage?.volunteers) {
      const listAllEmailsAdmVolunteers = [...(stage?.volunteers ?? [])];
      const classVacacancyId = classVacancy?._id ?? "";

      for (let i = 0; i < listAllEmailsAdmVolunteers?.length; i += 1) {
        let userAdmVolunteer = await getUserByIdentification(
          listAllEmailsAdmVolunteers[i],
        );

        let changed = false;

        if (!userAdmVolunteer) {
          userAdmVolunteer = await createUser(listAllEmailsAdmVolunteers[i], [
            userRoles.ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA,
          ]);
          await requestSignupLink(listAllEmailsAdmVolunteers[i]);
        }

        if (
          userAdmVolunteer &&
          userAdmVolunteer?.account?.status === userStatus.DISABLED
        ) {
          userAdmVolunteer.account.status = userStatus.PENDING;
          changed = true;
        }

        if (
          userAdmVolunteer &&
          !hasAdminClassVacancyVolunteerMediaRole(userAdmVolunteer)
        ) {
          userAdmVolunteer.account.roles.push(
            userRoles.ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA,
          );
          changed = true;
        }

        let classVacancyObject = userAdmVolunteer?.account.classVacancy ?? {};

        if (!Object.hasOwn(classVacancyObject, classVacacancyId)) {
          classVacancyObject = {
            ...classVacancyObject,
            [classVacacancyId]: [],
          };

          changed = true;
        }

        if (changed)
          await patchUser(userAdmVolunteer?._id ?? "", {
            account: {
              ...userAdmVolunteer?.account,
              classVacancy: classVacancyObject,
            },
          });
      }
    }
    dispatch(setLoad(false) as unknown as Action);
  }, [stage?.volunteers, dispatch, classVacancy?._id]);

  const removeIdAdmVolunteer = useCallback(async () => {
    if (oldListEmailsStageVolunteers?.length) {
      const newListAllEmailsVolunteerss = [...(stage?.volunteers ?? [])];

      const filteredEmailListVolunteers = oldListEmailsStageVolunteers?.filter(
        (item: string) => !newListAllEmailsVolunteerss?.includes(item),
      );

      if (filteredEmailListVolunteers?.length)
        deleteCurrentClassVacancyId(filteredEmailListVolunteers);

      if (stage?.volunteers?.length === 0) updatedStage();
    }
  }, [
    deleteCurrentClassVacancyId,
    oldListEmailsStageVolunteers,
    stage?.volunteers,
    updatedStage,
  ]);

  const changeAutoDistribution = useCallback(async () => {
    if (!stage?.auto && stage?.volunteers) {
      const listAllEmailsAdmVolunteers = [...(stage?.volunteers ?? [])];
      deleteCurrentClassVacancyId(listAllEmailsAdmVolunteers);
      updatedStage();
    }
  }, [
    stage?.auto,
    stage?.volunteers,
    updatedStage,
    deleteCurrentClassVacancyId,
  ]);

  const buildSteps = useMemo(() => {
    const steps: ClassStep[] = [];
    let object: ClassStep;

    Object.entries(stepsType).map(([key, value]) => {
      if (stage[key as keyof ClassVacancyStages]) {
        object = {
          type: value as keyof typeof classStepType,
          enable: false,
        };

        steps.push(object);
      }
      return null;
    });
    return steps;
  }, [stage]);

  const updateClassWithSteps = useCallback(async () => {
    if (classes?.length) {
      for (let i = 0; i < classes.length; i += 1) {
        let classWorkshop = classes[i];

        classWorkshop = { ...classWorkshop, steps: buildSteps };

        await updateClassById(classWorkshop?._id ?? "", classWorkshop);
      }
    }
  }, [classes, buildSteps]);

  const onSaveStage = useCallback(() => {
    let { stages: newStages } = classVacancy;

    addIdAdmVolunteer();
    removeIdAdmVolunteer();
    changeAutoDistribution();

    updateClassWithSteps();

    if (newStages?.length) {
      if (editIndex !== -1) newStages[editIndex] = stage;
      else newStages.push(stage);
    } else newStages = [stage];

    setClassVacancy({ ...classVacancy, stages: newStages });
    setAddStage(false);
    setEditIndex(-1);
  }, [
    classVacancy,
    addIdAdmVolunteer,
    removeIdAdmVolunteer,
    changeAutoDistribution,
    updateClassWithSteps,
    stage,
    setClassVacancy,
    editIndex,
  ]);

  const onRemove = useCallback(
    (index: number) => {
      let oldStages = classVacancy?.stages;
      oldStages?.splice(index, 1);
      oldStages = oldStages?.map((item, i: number) => ({
        ...item,
        order: i,
      }));
      setClassVacancy({ ...classVacancy, stages: oldStages });
    },
    [classVacancy, setClassVacancy],
  );

  const reorderItems = useCallback(
    (items: ClassVacancyStages[]) => {
      let { stages: newStages } = classVacancy;
      newStages = items?.map((item, index) => ({
        ...item,
        order: index,
      }));
      setClassVacancy({ ...classVacancy, stages: newStages });
    },
    [classVacancy, setClassVacancy],
  );

  const onEdit = useCallback((item, index: number) => {
    setEditIndex(index);
    setAddStage(true);
    setStage(item);
  }, []);

  const onCancel = useCallback(() => {
    setAddStage(false);
    setEditIndex(-1);
  }, []);

  return (
    <>
      <div className="grid-column-5-12 media-card-vacancies grid-row-2">
        <Card
          titleHeader="Etapas"
          className="grid-column-1-8 padding-0 border-card"
        >
          {!addStage ? (
            <IconButton
              icon={<Add />}
              text="Adicionar etapa"
              onClick={() => {
                setAddStage(true);
                setStage({
                  stage_id: createId() ?? "",
                  title: "",
                  description: "",
                  status: classVacancyStatus.PENDING,
                  order: classVacancy?.stages?.length ?? 0,
                });
              }}
              className="icon-button-primary"
            />
          ) : (
            <div className="grid-2-column">
              <Input
                id="title"
                label="Título*"
                type="text"
                value={stage?.title ?? ""}
                onChange={(e) => handleChange(e, "title")}
                className="grid-row-1 grid-column-1-2"
              />
              <Select
                id="type"
                label="Tipo*"
                options={Object.keys(StageType).map((key) => ({
                  value: key,
                  label: StageType[key as keyof typeof StageType],
                }))}
                value={stage?.type ?? ""}
                onChange={(e) => handleChange(e, "type")}
                disabled={!stage?.title}
                className="grid-row-1 grid-column-2-3"
              />
              <div className="media-type grid-row-2 grid-column-1-2 date-class-vacancy">
                <InputDate
                  future
                  views={["year", "month", "date"]}
                  format="DD/MM/yyyy"
                  label="Abertura*"
                  helperText="DD/MM/AAAA"
                  minDate={classVacancy?.startDate}
                  maxDate={classVacancy?.endDate}
                  value={stage?.startDate ?? null}
                  onChange={(value) => handleChangeDate(value, "startDate")}
                  disabled={!stage?.title || !stage?.type}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={stage?.autoOpen}
                      onChange={() =>
                        handleChangeSwitch(!stage?.autoOpen, "autoOpen")
                      }
                      title="autoOpen"
                    />
                  }
                  label="Abertura automática"
                  disabled={!stage?.title || !stage?.type}
                />
              </div>
              <div className="media-type grid-row-2 grid-column-2-3 date-class-vacancy">
                <InputDate
                  future
                  label="Encerramento*"
                  views={["year", "month", "date"]}
                  format="DD/MM/yyyy"
                  helperText="DD/MM/AAAA"
                  value={stage?.endDate ?? null}
                  minDate={classVacancy?.startDate}
                  maxDate={classVacancy?.endDate}
                  onChange={(value) => handleChangeDate(value, "endDate")}
                  disabled={!stage?.title || !stage?.type || !stage?.startDate}
                />
                <FormControlLabel
                  control={
                    <Switch checked={stage?.autoClose} title="autoClose" />
                  }
                  label="Encerramento automático"
                  onChange={() =>
                    handleChangeSwitch(!stage?.autoClose, "autoClose")
                  }
                  disabled={!stage?.title || !stage?.type || !stage?.startDate}
                />
              </div>
              {stage?.type === classVacancyType.WORKSHOP && (
                <InputAutocomplete
                  multiple
                  label="Turmas"
                  helperText="Pressione Enter para adicionar"
                  options={
                    allClass
                      ?.filter(
                        (item) =>
                          item?.status === classVacancyStatus.ACTIVE &&
                          item?.classStructure === classStructure.WORKSHOP,
                      )
                      ?.map((innerItem) => innerItem.code) ?? [""]
                  }
                  getOptionLabel={(option: string) => option}
                  value={classes?.map((item) => item?.code)}
                  onChange={handleChangeClassIds}
                  disabled={
                    !stage?.title ||
                    !stage?.type ||
                    !stage?.startDate ||
                    !stage?.endDate
                  }
                  className="grid-row-3 grid-column-1-3"
                />
              )}
              {(stage?.type === classVacancyType.TEST ||
                stage?.type === classVacancyType.TALENT_ACADEMY) && (
                <Input
                  id="url"
                  label="Link"
                  type="text"
                  value={stage?.url ?? ""}
                  onChange={(e) => handleChange(e, "url")}
                  className="grid-row-3 grid-column-1-3"
                  disabled={
                    !stage?.title ||
                    !stage?.type ||
                    !stage?.startDate ||
                    !stage?.endDate
                  }
                />
              )}
              <TextArea
                id="description"
                label="Descrição"
                className={`${
                  stage?.type === classVacancyType.TEST ||
                  stage?.type === classVacancyType.TALENT_ACADEMY ||
                  stage?.type === classVacancyType.WORKSHOP
                    ? "grid-row-4"
                    : "grid-row-3"
                }
                grid-column-1-3 description-input`}
                maxLength={500}
                value={stage?.description ?? ""}
                onChange={(e) => handleChange(e, "description")}
                disabled={
                  !stage?.title ||
                  !stage?.type ||
                  !stage?.startDate ||
                  !stage?.endDate ||
                  (stage?.type === classVacancyType.TALENT_ACADEMY &&
                    !stage?.url) ||
                  (stage?.type === classVacancyType.WORKSHOP &&
                    !stage?.classIds?.length)
                }
              />
              {stage?.type === classVacancyType.UPLOAD_MEDIAS && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={stage?.optional}
                      onChange={() =>
                        handleChangeSwitch(!stage?.optional, "optional")
                      }
                      name="optional"
                    />
                  }
                  label="Etapa opcional"
                  className="grid-row-4 grid-column-1-2 custom-questions"
                  disabled={
                    !stage?.title ||
                    !stage?.type ||
                    !stage?.startDate ||
                    !stage?.endDate
                  }
                />
              )}
              {stage?.type === classVacancyType.UPLOAD_MEDIAS && (
                <div
                  className={`grid-row-5 grid-column-1-3 admin-class-edit-distribuition ${
                    !stage?.title ||
                    !stage?.type ||
                    !stage?.startDate ||
                    !stage?.endDate
                      ? `disabled`
                      : null
                  }
                  `}
                >
                  <h2>Distribuicão</h2>
                  <p>
                    Selecione o tipo de distribuição dos vídeos após o
                    encerramento da etapa
                  </p>
                  <RadioGroup
                    row
                    aria-label="distribuition"
                    name="distribuition"
                    className="radio-distribuition"
                    value={stage?.auto ?? false}
                    onChange={(e) => handleChangeRadio(e, "auto")}
                  >
                    <FormControlLabel
                      value
                      control={<Radio />}
                      label="Automática"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Manual"
                    />
                  </RadioGroup>
                </div>
              )}
              {stage?.type === classVacancyType.WORKSHOP && (
                <div
                  className={`grid-row-5 grid-column-1-3 admin-class-edit-workshop ${
                    !stage?.title ||
                    !stage?.type ||
                    !stage?.startDate ||
                    !stage?.endDate ||
                    !stage?.classIds?.length ||
                    !stage?.description
                      ? `disabled`
                      : null
                  }
                `}
                >
                  <h2>Subetapas</h2>

                  <div className="admin-class-edit-workshop-checkbox">
                    <Checkbox
                      key={Math.random()}
                      id="hasEvaluationBehavior"
                      label="Avaliação Comportamental"
                      checked={stage?.hasEvaluationBehavior}
                      className="is-checkbox"
                      onChange={() => {
                        handleChangeCheckbox(
                          "hasEvaluationBehavior",
                          stage?.hasEvaluationBehavior,
                        );
                      }}
                    />
                    <Checkbox
                      key={Math.random()}
                      id="hasDiversityForm"
                      label="Formulário de Diversidade"
                      checked={stage?.hasDiversityForm}
                      className="is-checkbox"
                      onChange={() => {
                        handleChangeCheckbox(
                          "hasDiversityForm",
                          stage?.hasDiversityForm,
                        );
                      }}
                    />
                    <Checkbox
                      key={Math.random()}
                      id="hasTechnicalTest"
                      label="Desafio Técnico"
                      checked={stage?.hasTechnicalTest}
                      className="is-checkbox"
                      onChange={() => {
                        handleChangeCheckbox(
                          "hasTechnicalTest",
                          stage?.hasTechnicalTest,
                        );
                      }}
                    />
                    <Checkbox
                      key={Math.random()}
                      id="hasTechnicalTestForm"
                      label="Desafio Técnico(formulário)"
                      checked={stage?.hasTechnicalTestForm}
                      className="is-checkbox"
                      onChange={() => {
                        handleChangeCheckbox(
                          "hasTechnicalTestForm",
                          stage?.hasTechnicalTestForm,
                        );
                      }}
                    />
                  </div>
                </div>
              )}
              {stage?.auto && (
                <InputAutocomplete
                  multiple
                  label="Voluntárias"
                  helperText="Pressione Enter para adicionar"
                  className="grid-row-6 grid-column-1-3"
                  options={emailVolunterOptions ?? [""]}
                  getOptionLabel={(option) => option}
                  value={stage?.volunteers ?? []}
                  onChange={handleChangeValue}
                />
              )}
            </div>
          )}
        </Card>
      </div>

      {existStages && (
        <div
          className={`grid-column-5-12 ${
            addStage ? "grid-row-4" : "grid-row-3"
          }`}
        >
          {classVacancy?.stages?.length > 1 && (
            <p className="text-p">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <CardItems
            isMyProfile
            fullDate
            notSort
            showDivider={false}
            items={classVacancy?.stages}
            onEdit={(item, index) => onEdit(item, index)}
            onRemove={(_, index) => onRemove(index)}
            onMove={reorderItems}
            className="card-items padding-32"
          />
        </div>
      )}

      <Button
        text="Cancelar"
        className={`${
          addStage ? "grid-row-3" : existStages ? "grid-row-4" : "grid-row-3"
        } grid-column-8-10 margin-bottom-32`}
        onClick={() => {
          if (addStage) onCancel();
          else history.push("/admin-class-vacancy");
        }}
        outline
        appearance="secondary"
      />
      <Button
        text={classVacancy?._id || addStage ? "Salvar" : "Avançar"}
        className={`${
          addStage || !existStages ? "grid-row-3" : "grid-row-4"
        } grid-column-10-12 margin-bottom-32  ${
          !classVacancy?._id ? "button-primary" : ""
        }`}
        onClick={() =>
          addStage ? onSaveStage() : !classVacancy?._id ? nextPage() : onSave()
        }
        disabled={
          addStage &&
          (!stage?.title ||
            !stage?.type ||
            !stage?.startDate ||
            !stage?.endDate ||
            (stage?.type === classVacancyType.TALENT_ACADEMY && !stage?.url) ||
            (stage?.type === classVacancyType.WORKSHOP &&
              !stage?.classIds?.length) ||
            !stage?.description)
        }
      />
    </>
  );
};

export default memo(Stages);
