/* eslint-disable no-await-in-loop */
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useHistory, useParams } from "react-router-dom";
import {
  TableRow,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { Card, Select, Table as TableComponent } from "../../../components";
import { Search } from "../../../../assets/customIcons";
import { classVacancyType } from "../../../types/enumerators";
import {
  Class,
  ClassVacancy,
  ClassVacancyStages,
  ClassVacancySubscriptionStage,
} from "../../../types/interfaces";
import {
  aceptUserFromClassVacancySubscriptionStage,
  downClassVacancySubscriptionStage,
  getClassById,
  giveUpUserFromClassVacancySubscription,
  reactiveUserFromClassVacancySubscription,
  rejectUserFromClassVacancySubscriptionStage,
  upClassVacancySubscriptionStage,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import { RootState } from "../../../store/reducers";
import { setLoad } from "../../../store/actions/configurationsActions";
import { CourseStatus } from "../../../types/constants/Course";
import {
  AddClass,
  AddWorkshop,
  Comments,
  Config as ConfigComponent,
  Proposes,
  Error,
  Tags,
  UserView,
} from "../Modal";
import { Config, Filters, List } from "..";
import "../style.scss";

export interface Score {
  stageId: string;
  type: string;
  value?: string | number;
}

export interface FilterScore {
  stages?: ClassVacancyStages[];
  filterScore: Score;
  filters: Filters;
  optionsAllFilters: (
    type: string,
    hasAll?: boolean,
  ) => {
    value?: string | boolean | number | null;
    label: string;
  }[];
  setFilterScore: Dispatch<
    SetStateAction<{
      stageId: string;
      type: string;
      value?: number | string;
    }>
  >;
  setFilters: Dispatch<SetStateAction<Filters>>;
}

interface Props {
  userClassVacancy?: { [x: string]: string[] };
  data: List[];
  getData: (filters?: Filters, config?: Config) => void;
  isVolunteer: boolean;
  classVacancy: ClassVacancy;
  classList: Class[];
  config: {
    openModal: boolean;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    config?: Config;
    setConfig: Dispatch<SetStateAction<Config | undefined>>;
    filters?: Filters;
    setFilters: Dispatch<SetStateAction<Filters | undefined>>;
  };
}

interface ParamsProps {
  id: string;
}

const Table = ({
  userClassVacancy,
  data,
  getData,
  isVolunteer,
  classVacancy,
  classList,
  config,
}: Props): JSX.Element => {
  const { id } = useParams<ParamsProps>();
  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );
  const [filterScore, setFilterScore] = useState<Score>({
    stageId: "",
    type: "",
    value: undefined,
  });
  const [applyFilters, setApplyFilters] = useState<Filters>({
    score: filterScore
      ? {
          stageId: filterScore?.stageId,
          [filterScore?.type]: filterScore?.value || true,
        }
      : null,
  });

  const initialConfig: Config = useMemo(
    () => ({
      visibleColumns: ["name", "stage", "status"],
      sortBy: "user.name",
      asc: true,
      limit: pageLimit,
    }),
    [pageLimit],
  );

  const [applyConfig, setApplyConfig] = useState<Config>();
  const [workshopList, setWorkshopList] = useState<Class[]>();
  const [selectedList, setSelectedList] = useState<List[]>();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<{
    funct: () => void;
    user: {
      name: string;
      email: string;
    }[];
  }>();
  const [expanded, setExpanded] = useState("all");
  const [openModalStatusAction, setOpenModalStatusAction] = useState(false);
  const [openModalTags, setOpenModalTags] = useState(false);
  const [openModalClass, setOpenModalClass] = useState(false);
  const [openModalWorkshop, setOpenModalWorkshop] = useState(false);
  const [openModalComments, setOpenModalComments] = useState(false);
  const [openModalProposes, setOpenModalProposes] = useState(false);
  const [openModalInfos, setOpenModalInfos] = useState(false);
  const [selected, setSelected] = useState<List>();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (JSON.stringify(applyConfig) !== JSON.stringify(config.config))
      config.setConfig(applyConfig);
  }, [applyConfig, config]);

  useEffect(() => {
    if (JSON.stringify(applyFilters) !== JSON.stringify(config.filters))
      config.setFilters(applyFilters);
  }, [applyFilters, config]);

  const headCells = useMemo(
    () => [
      {
        id: "photo",
        label: "",
        onclick: (val: string) =>
          !isVolunteer && history.push(`/profile/${val}`),
        orderBy: false,
        asc: false,
        classColumn: "w-80",
      },
      {
        id: "name",
        label: "Nome",
        orderBy: true,
        sort: "user.name",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "user.name",
          })),
        hide: !applyConfig?.visibleColumns?.includes("name"),
        classColumn: isVolunteer ? "w-total" : "w-240",
      },
      {
        id: "email",
        label: "E-mail",
        orderBy: true,
        sort: "user.email",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "user.email",
          })),
        hide: !applyConfig?.visibleColumns?.includes("email"),
        classColumn: isVolunteer ? "w-total" : "w-300",
      },
      {
        id: "volunteer",
        label: "Voluntária",
        orderBy: true,
        sort: "volunteer",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "volunteer",
          })),
        hide: !applyConfig?.visibleColumns?.includes("volunteer"),
        classColumn: isVolunteer ? "w-total" : "w-130",
      },
      {
        id: "subscription",
        label: "Inscrição",
        orderBy: true,
        sort: "subscriptionScore",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "subscriptionScore",
          })),
        hide:
          isVolunteer || !applyConfig?.visibleColumns?.includes("subscription"),
        classColumn: "w-90",
      },
      {
        id: "media",
        label: "Mídia",
        orderBy: true,
        sort: "mediaScore",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "mediaScore",
          })),
        hide: !applyConfig?.visibleColumns?.includes("media"),
        classColumn: "w-80",
      },
      {
        id: "workshop",
        label: "Oficina",
        orderBy: true,
        sort: "workshopScore",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "workshopScore",
          })),
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("workshop"),
        classColumn: "w-80",
      },
      {
        id: "diversity",
        label: "Diversidade",
        orderBy: true,
        sort: "diversityScore",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "diversityScore",
          })),
        hide:
          isVolunteer || !applyConfig?.visibleColumns?.includes("diversity"),
        classColumn: "w-105",
      },
      {
        id: "behavior",
        label: "Comportamental",
        orderBy: true,
        sort: "behaviorScore",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "behaviorScore",
          })),
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("behavior"),
        classColumn: "w-130",
      },
      {
        id: "technical",
        label: "Técnica",
        orderBy: true,
        sort: "technicalScore",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "technicalScore",
          })),
        hide:
          isVolunteer || !applyConfig?.visibleColumns?.includes("technical"),
        classColumn: "w-80",
      },
      {
        id: "morning",
        label: "Manhã",
        orderBy: false,
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("morning"),
        classColumn: "w-80",
      },
      {
        id: "afternoon",
        label: "Tarde",
        orderBy: false,
        hide:
          isVolunteer || !applyConfig?.visibleColumns?.includes("afternoon"),
        classColumn: "w-80",
      },
      {
        id: "talentAcademy",
        label: "Talent Academy",
        orderBy: true,
        sort: "talentAcademyScore",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "talentAcademyScore",
          })),
        hide:
          isVolunteer ||
          !applyConfig?.visibleColumns?.includes("talentAcademy"),
        classColumn: "w-130",
      },
      {
        id: "test",
        label: "Teste",
        orderBy: true,
        sort: "testScore",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "testScore",
          })),
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("test"),
        classColumn: "w-80",
      },
      {
        id: "total",
        label: "Total",
        orderBy: true,
        sort: "totalScore",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "totalScore",
          })),
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("total"),
        classColumn: "w-80",
      },
      {
        id: "workshopClass",
        label: "Sala Oficina",
        orderBy: true,
        sort: "classWorkshop.code",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "classWorkshop.code",
          })),
        hide:
          isVolunteer ||
          !applyConfig?.visibleColumns?.includes("workshopClass"),
        classColumn: "w-120",
      },
      {
        id: "workshopFirstOption",
        label: "Opção 1",
        orderBy: true,
        sort: "classWorkshop.code",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "classWorkshop.code",
          })),
        hide:
          isVolunteer ||
          !applyConfig?.visibleColumns?.includes("workshopFirstOption"),
        classColumn: "w-160",
      },
      {
        id: "workshopSecondOption",
        label: "Opção 2",
        orderBy: true,
        sort: "workshopSecondOption",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "workshopSecondOption",
          })),
        hide:
          isVolunteer ||
          !applyConfig?.visibleColumns?.includes("workshopSecondOption"),
        classColumn: "w-160",
      },
      {
        id: "class",
        label: "Turmas",
        orderBy: false,
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "class",
          })),
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("class"),
        classColumn: "w-80",
      },
      {
        id: "subscriptions",
        label: "Processos",
        orderBy: false,
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "subscriptions",
          })),
        hide:
          isVolunteer ||
          !applyConfig?.visibleColumns?.includes("subscriptions"),
        classColumn: "w-300",
      },
      {
        id: "stage",
        label: "Etapa",
        orderBy: false,
        asc: false,
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("stage"),
        classColumn: "w-120",
      },
      {
        id: "gender",
        label: "Gênero",
        orderBy: true,
        sort: "user.genderIdentity",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "user.genderIdentity",
          })),
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("gender"),
        classColumn: "w-150",
      },
      {
        id: "race",
        label: "Raça",
        orderBy: true,
        sort: "user.race",
        asc: applyConfig?.asc ?? true,
        onclick: () =>
          setApplyConfig((prev) => ({
            ...(prev ?? initialConfig),
            asc: !prev?.asc ?? !initialConfig?.asc,
            sortBy: "user.race",
          })),
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("race"),
        classColumn: "w-80",
      },
      {
        id: "region",
        label: "Região",
        orderBy: false,
        asc: false,
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("region"),
        classColumn: "w-90",
      },
      {
        id: "socialMedia",
        label: "Redes sociais",
        orderBy: false,
        asc: false,
        hide:
          isVolunteer || !applyConfig?.visibleColumns?.includes("socialMedia"),
        classColumn: "w-300",
      },
      {
        id: "startDate",
        label: "Data da candidatura",
        orderBy: false,
        asc: false,
        hide:
          isVolunteer || !applyConfig?.visibleColumns?.includes("startDate"),
        classColumn: "w-160",
      },
      {
        id: "endDate",
        label: "Data do encerramento",
        orderBy: false,
        asc: false,
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("endDate"),
        classColumn: "w-160",
      },
      {
        id: "status",
        label: "Status",
        orderBy: false,
        asc: false,
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("status"),
        classColumn: "w-120",
      },
      {
        id: "tags",
        label: "Tags",
        orderBy: false,
        asc: false,
        hide: isVolunteer || !applyConfig?.visibleColumns?.includes("tags"),
        classColumn: "w-240",
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
        asc: false,
        classColumn: "w-240",
      },
    ],
    [
      applyConfig?.asc,
      applyConfig?.visibleColumns,
      history,
      initialConfig,
      isVolunteer,
    ],
  );

  const getWorkshopList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const classIds =
      classVacancy?.stages?.find(
        (item) => item?.type === classVacancyType.WORKSHOP,
      )?.classIds ?? [];

    const responseClassList: Class[] = [];
    for (let i = 0; i < classIds?.length; i += 1) {
      const response = await getClassById(classIds[i]);

      if (response?.data) responseClassList?.push(response?.data);
    }

    setWorkshopList(responseClassList);
    dispatch(setLoad(false) as unknown as Action);
  }, [classVacancy?.stages, dispatch]);

  useEffect(() => {
    if (!workshopList) getWorkshopList();
  }, [getWorkshopList, workshopList]);

  const onApplyFilters = useCallback(
    (value, props) => setApplyFilters((prev) => ({ ...prev, [props]: value })),
    [],
  );

  const openConfig = useCallback(() => {
    setExpanded("config");
    config.setOpenModal(true);
  }, [config]);

  const openFilters = useCallback(() => {
    setExpanded("filters");
    config.setOpenModal(true);
  }, [config]);

  const updateFilter = useCallback(() => {
    const classVacancySubscriptionIdsVolunteer = userClassVacancy?.[id];
    setApplyFilters({
      ...applyFilters,
      ids: classVacancySubscriptionIdsVolunteer,
    });
    setApplyConfig({
      ...applyConfig,
      visibleColumns: ["name", "media"],
      sortBy: "user.name",
      asc: true,
      limit: pageLimit,
    });
  }, [userClassVacancy, id, applyFilters, applyConfig, pageLimit]);

  useEffect(() => {
    if (
      isVolunteer &&
      userClassVacancy?.[id]?.length &&
      (!applyConfig || !applyFilters)
    )
      updateFilter();
  }, [
    applyConfig,
    applyFilters,
    updateFilter,
    isVolunteer,
    userClassVacancy,
    id,
  ]);

  const optionsFilters = useCallback(
    (type: string, hasAll?: boolean) => {
      const result: {
        value?: string | boolean | number | null;
        label: string;
      }[] = [];
      if (hasAll)
        result?.push({
          value: undefined,
          label: "Todas",
        });

      if (type === "hasMedia")
        result?.push(
          {
            value: true,
            label: "Sim",
          },
          {
            value: false,
            label: "Não",
          },
        );

      if (type === "status")
        Object.keys(CourseStatus).forEach((key) => {
          result?.push({
            value: key,
            label: CourseStatus[key as keyof typeof CourseStatus],
          });
        });

      if (type === "stages" && classVacancy?.stages)
        classVacancy?.stages?.forEach((stage) => {
          result?.push({
            value: stage?.stage_id,
            label: stage?.title ?? "",
          });
        });

      return result;
    },
    [classVacancy?.stages],
  );

  const onToRedash = useCallback(
    (row: { legalIdentity: string }) =>
      window.open(
        `http://dashboard.reprograma.com.br/dashboards/7-pesquisa-por-cpf?p_cpf=${
          row?.legalIdentity ?? ""
        }`,
        "_blank",
      ),
    [],
  );

  const downStage = useCallback(
    (row: { _id: string }) => downClassVacancySubscriptionStage(row?._id ?? ""),
    [],
  );

  const upStage = useCallback(
    (row: { _id: string }) =>
      upClassVacancySubscriptionStage(row?._id ?? "", true),
    [],
  );

  const onGiveUp = useCallback(
    (row: { _id: string }) => giveUpUserFromClassVacancySubscription(row?._id),
    [],
  );

  const onReactive = useCallback(
    (row: { _id: string }) =>
      reactiveUserFromClassVacancySubscription(row?._id),
    [],
  );

  const onAccept = useCallback(
    (row: { _id: string }, waitingList = false) =>
      aceptUserFromClassVacancySubscriptionStage(row?._id ?? "", waitingList),
    [],
  );

  const onReject = useCallback(
    (row: { _id: string }) =>
      rejectUserFromClassVacancySubscriptionStage(row?._id ?? ""),
    [],
  );

  const onWaitingList = useCallback(
    (row: { _id: string }) => onAccept(row, true),
    [onAccept],
  );

  const onClass = useCallback((row) => {
    setSelectedList([row]);
    setOpenModalClass(true);
  }, []);

  const onEvaluate = useCallback((row) => {
    setSelected(row as List);
    setOpenModalProposes(true);
  }, []);

  const onComment = useCallback((row) => {
    setSelected(row as List);
    setOpenModalComments(true);
  }, []);

  const onShow = useCallback((row) => {
    setSelected(row as List);
    setOpenModalInfos(true);
  }, []);

  const onClassWorkshop = useCallback(
    (row) => {
      setSelectedList([row]);
      if (!workshopList?.length) getWorkshopList();
      setOpenModalWorkshop(true);
    },
    [workshopList?.length, getWorkshopList],
  );

  const onTag = useCallback(async (row) => {
    setSelectedList([row]);
    setOpenModalTags(true);
  }, []);

  const functAction = useCallback(
    async (funct, list, ...params) => {
      dispatch(setLoad(true) as unknown as Action);

      const errors: List[] = [];
      for (let i = 0; i < list?.length; i += 1) {
        const result = await funct(list[i]?._id, ...params);
        if (!result) errors.push(list[i]);
      }

      if (errors?.length) {
        setError({
          funct: () => {
            functAction(funct, errors, ...params);
            setOpenModalStatusAction(false);
          },
          user: errors?.map((item) => ({
            name: item?.name,
            email: item?.email,
          })),
        });

        if (errors?.length < list?.length) setSuccess(true);
        else setSuccess(false);

        setOpenModalStatusAction(true);
      } else toast.success("Ação executada com sucesso");

      dispatch(setLoad(false) as unknown as Action);
      getData(applyFilters, applyConfig);
    },
    [applyConfig, applyFilters, dispatch, getData],
  );

  const onAllAddToRecap = useCallback(
    async (row) => {
      dispatch(setLoad(true) as unknown as Action);
      for (let i = 0; i < row?.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await updateClassVacancySubscriptionById(row[i]?._id ?? "", {
          recap: {
            disabled: false,
          },
        });
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch],
  );

  const allDownStage = useCallback(
    (list) => functAction(downClassVacancySubscriptionStage, list),
    [functAction],
  );

  const allUpStage = useCallback(
    (list) => functAction(upClassVacancySubscriptionStage, list, true),
    [functAction],
  );

  const onAllReject = useCallback(
    (list) => functAction(rejectUserFromClassVacancySubscriptionStage, list),
    [functAction],
  );

  const onAllAccept = useCallback(
    (list) => functAction(aceptUserFromClassVacancySubscriptionStage, list),
    [functAction],
  );

  const onAllWaitingList = useCallback(
    (list) =>
      functAction(aceptUserFromClassVacancySubscriptionStage, list, true),
    [functAction],
  );

  const onAllClass = useCallback((list) => {
    setSelectedList(list);
    setOpenModalClass(true);
  }, []);

  const onAllTag = useCallback((list) => {
    setSelectedList(list);
    setOpenModalTags(true);
  }, []);

  const onAllClassWorkshop = useCallback(
    (list) => {
      setSelectedList(list);
      if (!workshopList?.length) getWorkshopList();
      setOpenModalWorkshop(true);
    },
    [workshopList?.length, getWorkshopList],
  );

  const closeModalErrors = useCallback(() => {
    setError(undefined);
    setSuccess(false);
    setOpenModalStatusAction(false);
  }, []);

  return (
    <>
      <Card
        className="grid-column-1-13 grid-row-4 margin-bottom-32"
        bodyClassName="padding-0"
        titleHeader="Candidatas"
        configModal={!isVolunteer}
        onClickExpandedModal={openConfig}
      >
        <TableRow className="top-filters flex width-fill">
          <TextField
            focused={
              applyFilters?.name !== "" && applyFilters?.name !== undefined
            }
            variant="filled"
            autoComplete="off"
            id="searchCandidate"
            placeholder="Buscar candidata"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={applyFilters?.name ?? ""}
            onChange={(e: ChangeEvent<{ value: string | boolean }>) =>
              onApplyFilters(e.target.value, "name")
            }
            onBlur={() => getData(applyFilters, applyConfig)}
          />
          <TextField
            focused={
              applyFilters?.email !== "" && applyFilters?.email !== undefined
            }
            variant="filled"
            autoComplete="off"
            id="searchCandidateByFilter"
            placeholder="Buscar por e-mail"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={applyFilters?.email ?? ""}
            onChange={(e: ChangeEvent<{ value: string | boolean }>) =>
              onApplyFilters(e.target.value, "email")
            }
            onBlur={() => getData(applyFilters, applyConfig)}
          />
          <TextField
            focused={
              applyFilters?.legalIdentity !== "" &&
              applyFilters?.legalIdentity !== undefined
            }
            variant="filled"
            autoComplete="off"
            id="legalIdentity"
            placeholder="Buscar por CPF"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={applyFilters?.legalIdentity ?? ""}
            onChange={(e: ChangeEvent<{ value: string | boolean }>) =>
              onApplyFilters(e.target.value, "legalIdentity")
            }
            onBlur={() => getData(applyFilters, applyConfig)}
          />
          {isVolunteer && (
            <div className="admin-class-radios">
              <span className="admin-class-radios-title">Possui mídia</span>
              <RadioGroup value={applyFilters?.hasMedia}>
                <div>
                  {optionsFilters("hasMedia", true)?.map(({ value, label }) => (
                    <FormControlLabel
                      key={Math.random()}
                      value={value}
                      control={<Radio />}
                      label={label}
                      checked={value === applyFilters?.hasMedia}
                      className="margin-right-32"
                      onChange={() => {
                        onApplyFilters(value, "hasMedia");
                        getData(
                          {
                            ...applyFilters,
                            hasMedia: value as boolean,
                          },
                          applyConfig,
                        );
                      }}
                    />
                  ))}
                </div>
              </RadioGroup>
            </div>
          )}
          {!isVolunteer && (
            <>
              <Select
                className={`status-filter last-filter ${
                  applyFilters?.stageId !== "" &&
                  applyFilters?.stageId !== undefined
                    ? "focus-selected"
                    : ""
                }`}
                id="stages"
                label="Etapas"
                value={applyFilters?.stageId ?? ""}
                onChange={(e: ChangeEvent<{ value: string | boolean }>) =>
                  onApplyFilters(e.target.value, "stageId")
                }
                onBlur={() => getData(applyFilters, applyConfig)}
                options={optionsFilters("stages", true)}
              />
              <Select
                className={`status-filter last-filter ${
                  applyFilters?.status !== "" &&
                  applyFilters?.status !== undefined
                    ? "focus-selected"
                    : ""
                }`}
                id="status"
                label="Status"
                value={applyFilters?.status ?? ""}
                onChange={(e: ChangeEvent<{ value: string | boolean }>) =>
                  onApplyFilters(e.target.value, "status")
                }
                onBlur={() => getData(applyFilters, applyConfig)}
                options={optionsFilters("status", true)}
              />
              <div
                role="presentation"
                onClick={openFilters}
                className="top-filters-more"
              >
                Mais filtros
                <span className="filters-length">
                  {applyFilters?.score?.stageId === ""
                    ? (Object.keys(applyFilters)?.length ?? 0) - 1
                    : Object.keys(applyFilters)?.length}
                </span>
              </div>
            </>
          )}
        </TableRow>
        <TableComponent
          hasMoreActions={!isVolunteer}
          cantSelect={isVolunteer}
          emptyText="Não há inscrições cadastradas"
          headCells={headCells}
          list={data ?? []}
          enumStatus={CourseStatus}
          onReload={getData}
          onToRedash={!isVolunteer ? onToRedash : undefined}
          onBack={!isVolunteer ? downStage : undefined}
          onNext={!isVolunteer ? upStage : undefined}
          onReactive={!isVolunteer ? onReactive : undefined}
          onReject={!isVolunteer ? onReject : undefined}
          onAccept={!isVolunteer ? onAccept : undefined}
          onGiveUp={!isVolunteer ? onGiveUp : undefined}
          onClass={!isVolunteer ? onClass : undefined}
          onTag={!isVolunteer ? onTag : undefined}
          onClassWorkshop={!isVolunteer ? onClassWorkshop : undefined}
          onAllAddToRecap={!isVolunteer ? onAllAddToRecap : undefined}
          onAllBack={!isVolunteer ? allDownStage : undefined}
          onAllNext={!isVolunteer ? allUpStage : undefined}
          onAllReject={!isVolunteer ? onAllReject : undefined}
          onAllAccept={!isVolunteer ? onAllAccept : undefined}
          onAllClass={!isVolunteer ? onAllClass : undefined}
          onAllTag={!isVolunteer ? onAllTag : undefined}
          onAllClassWorkshop={!isVolunteer ? onAllClassWorkshop : undefined}
          onWaitingList={!isVolunteer ? onWaitingList : undefined}
          onAllWaitingList={!isVolunteer ? onAllWaitingList : undefined}
          onEvaluate={onEvaluate}
          onComment={onComment}
          onShow={onShow}
          hasCommentUnresolved={(row) =>
            row?.miscellaneous?.generalComment?.filter(
              ({ resolved = false }) => !resolved,
            ).length > 0
          }
        />
      </Card>
      <Error
        open={openModalStatusAction}
        onclose={closeModalErrors}
        success={success}
        error={error}
      />
      <AddClass
        open={openModalClass}
        onclose={() => setOpenModalClass(false)}
        list={classList}
        users={selectedList}
        reload={() => getData(applyFilters, applyConfig)}
        setError={setError}
        setSuccess={setSuccess}
        setOpenModalStatusAction={setOpenModalStatusAction}
      />
      <AddWorkshop
        open={openModalWorkshop}
        onclose={() => setOpenModalWorkshop(false)}
        list={workshopList as Class[]}
        subscriptions={selectedList}
        stages={classVacancy?.stages}
        reload={() => getData(applyFilters, applyConfig)}
        setError={setError}
        setSuccess={setSuccess}
        setOpenModalStatusAction={setOpenModalStatusAction}
      />
      <Tags
        open={openModalTags}
        onclose={() => setOpenModalTags(false)}
        reload={() => getData(applyFilters, applyConfig)}
        subscriptions={selectedList as List[]}
      />
      <Comments
        open={openModalComments}
        onclose={() => setOpenModalComments(false)}
        reload={() => getData(applyFilters, applyConfig)}
        isVolunteer={isVolunteer}
        user={{
          subscriptionId: selected?._id ?? "",
          id: selected?.userId ?? "",
          name: selected?.name ?? "",
          photo: selected?.photo ?? "",
        }}
        stagesVacancy={classVacancy?.stages as ClassVacancyStages[]}
        stagesSubs={selected?.stages as ClassVacancySubscriptionStage[]}
      />
      <ConfigComponent
        open={config.openModal}
        onclose={() => config.setOpenModal(false)}
        classVacancy={classVacancy as ClassVacancy}
        workshopList={workshopList as Class[]}
        expanded={expanded}
        reload={getData}
        setApplyFilters={setApplyFilters}
        filterScore={filterScore}
        setFilterScore={setFilterScore}
        setApplyConfig={setApplyConfig}
        initialConfig={initialConfig}
        headCells={headCells}
      />
      <Proposes
        open={openModalProposes}
        onclose={() => setOpenModalProposes(false)}
        user={{ name: selected?.name ?? "" }}
        isVolunteer={isVolunteer}
        subscriptionId={selected?._id ?? ""}
        classVacancy={classVacancy as ClassVacancy}
        reload={() => getData(applyFilters, applyConfig)}
      />
      <UserView
        open={openModalInfos}
        onclose={() => setOpenModalInfos(false)}
        reload={() => getData(applyFilters, applyConfig)}
        subscriptionId={selected?._id ?? ""}
        classVacancy={classVacancy as ClassVacancy}
        isVolunteer={isVolunteer}
        data={{ name: selected?.name ?? "", photo: selected?.photo ?? "" }}
      />
    </>
  );
};

export default memo(Table);
