import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import { toast } from "react-toastify";
import type { ClassVacancySubscriptionStage } from "../../../types/interfaces";
import {
  Input,
  Button,
  CardMedia,
  AttachmentMedia,
  IconButton,
} from "../../../components";
import { WorkshopEditProps } from "./types";
import { updateClassVacancySubscriptionById } from "../../../services/functions";
import { classVacancyType, mediaType } from "../../../types/enumerators";
import { Delete } from "../../../../assets/icons";
import api from "../../../services/api";

export const styles = {
  slider: {
    color: "#7e459b",
  },
};

const WorkshopEdit = ({
  subscription,
  course,
  setIsEdit,
  reload,
}: WorkshopEditProps): JSX.Element => {
  const [linkMedia, setLinkMedia] = useState<string>("");
  const [dataMedia, setDataMedia] = useState<FormData | string | null>(null);
  const [source, setSource] = useState<string>("");
  const [remove, setRemove] = useState(false);

  const stageWorkshop = useMemo(() => {
    if (subscription && course) {
      const workshop = course?.stages?.find(
        (item) => item?.type === classVacancyType.WORKSHOP,
      );

      const findUserWorkshop = subscription?.stages?.find(
        ({ stageId }) => stageId === workshop?.stage_id,
      );

      if (findUserWorkshop) return findUserWorkshop;
    }

    return null;
  }, [subscription, course]);

  const participated = useMemo(
    () =>
      stageWorkshop?.participant &&
      (stageWorkshop?.participant?.morning ||
        stageWorkshop?.participant?.afternoon),
    [stageWorkshop?.participant],
  );

  const media = useMemo(() => stageWorkshop?.media, [stageWorkshop?.media]);

  const handleOnBlurMedia = useCallback(
    (e: { target: { value: string } }) => {
      setRemove(false);

      if (linkMedia === "") {
        setLinkMedia(e.target.value);
        e.target.value = "";
      }
    },
    [linkMedia],
  );

  const handleLinkMedia = useCallback(
    (e: { key: string; target: { value: string } }) => {
      setRemove(false);

      if (e.key === "Enter") {
        setLinkMedia(e.target.value);
        e.target.value = "";
      }
    },
    [],
  );

  const handleMedia = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    setRemove(false);

    if (!fileList || !fileList?.length) return;

    const newData = new FormData();

    newData.append("file", fileList[0]);
    setDataMedia(newData);

    const blobURL = URL.createObjectURL(fileList[0]);
    setSource(blobURL);
  }, []);

  const handleRemoveMedia = useCallback(() => {
    setDataMedia(null);
    setSource("");
    setRemove(true);
  }, []);

  const submit = useCallback(async () => {
    const index = subscription?.stages?.findIndex(
      (item) => item?.stageId === stageWorkshop?.stageId,
    );

    let updateMedia: unknown;

    if (linkMedia) {
      updateMedia = {
        url: linkMedia,
        type: mediaType.LINK,
      };
    }

    if (dataMedia) {
      await api
        .post("/fileUpload", dataMedia)
        .then(async (result) => {
          updateMedia = {
            url: result?.data?.file_url.url,
            type: dataMedia?.get("file")?.type?.includes("image")
              ? mediaType.PHOTO
              : mediaType.DOCUMENT,
          };
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    }

    let stages = subscription?.stages?.map((general, idx) =>
      idx === index
        ? {
            ...subscription?.stages[idx],
            media: updateMedia,
          }
        : general,
    );

    if ((remove && stages) || !updateMedia) {
      const stageWorkshopSelected = stages?.find(
        (item) => item.stageId === stageWorkshop?.stageId,
      );

      if (stageWorkshopSelected) delete stageWorkshopSelected.media;

      stages = subscription?.stages?.map((general) =>
        general?.stageId === stageWorkshop?.stageId
          ? {
              ...(stageWorkshopSelected as ClassVacancySubscriptionStage),
            }
          : general,
      );
      setRemove(false);
    }

    await updateClassVacancySubscriptionById(subscription?._id ?? "", {
      stages,
    });

    reload();
    setDataMedia(null);
  }, [
    subscription,
    linkMedia,
    dataMedia,
    remove,
    reload,
    stageWorkshop?.stageId,
  ]);

  useEffect(() => {
    if (stageWorkshop && media) {
      if (media?.type === mediaType.LINK) setLinkMedia(media?.url ?? "");
      else setDataMedia(media?.url ?? "");
    }
  }, [media, stageWorkshop]);

  return (
    <div className="subscriptions-workshop">
      <div className="subscriptions-workshop-content propose">
        <div className="content-view">
          <div className="propose-form WORKSHOP">
            {participated && (
              <>
                <div className="propose-title subtitle">
                  <h3>Desafio</h3>
                </div>
                <div className="subscriptions-media-file">
                  <div>
                    <h4>Cole o link de compartilhamento no campo abaixo:</h4>
                    <div className="link-input-video">
                      <Input
                        label="Link"
                        type="text"
                        helperText="Pressione Enter para adicionar"
                        onKeyDown={handleLinkMedia}
                        onBlur={handleOnBlurMedia}
                        className="input-link"
                        disabled={!!linkMedia || !!dataMedia}
                      />

                      <div className="flex-row-center">
                        <a
                          href={linkMedia}
                          target="_blank"
                          className="a-class"
                          rel="noreferrer"
                        >
                          {linkMedia}
                        </a>
                        {linkMedia && (
                          <IconButton
                            icon={<Delete />}
                            onClick={() => {
                              setLinkMedia("");
                              setRemove(true);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <h4>
                      Mas se preferir, você pode fazer upload do arquivo aqui
                      mesmo:
                    </h4>
                    <div className="margin-top-32">
                      <div className={linkMedia ? "disabled" : ""}>
                        <AttachmentMedia
                          className="lable-media"
                          onChange={handleMedia}
                          disabled={!!linkMedia || !!dataMedia}
                          workshopFile
                        />
                      </div>
                    </div>

                    {dataMedia && (
                      <CardMedia
                        cantEdit
                        item={{
                          title: (dataMedia as string) ?? "",
                          url: source ?? "",
                        }}
                        onRemove={handleRemoveMedia}
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="subscriptions-view-buttons">
          <Button
            text="Cancelar"
            outline
            appearance="secondary"
            onClick={() => {
              setIsEdit(false);
              setDataMedia(null);
              setSource("");
            }}
          />
          <Button text="Salvar" onClick={submit} />
        </div>
      </div>
    </div>
  );
};

export default WorkshopEdit;
