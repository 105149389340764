/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import { Refresh } from "@material-ui/icons";
import { toast } from "react-toastify";
import { ButtonMenu, Checkbox, InputDate, Select, Button } from "..";
import {
  ArrowRight,
  Block,
  ChevronDown,
  Delete,
  Edit,
  Mail,
  Search,
  Show,
  ProposalsGray,
  TestimonialGray,
  TestimonialActive,
  Unblock,
  ChevronWhite,
  ThreePoints,
  Transfer,
  Copy,
  IconVideo,
} from "../../../assets/icons";
import {
  AvatarEmpty,
  Certificate as CertificateIcon,
  Close,
  Link as LinkIcon,
} from "../../../assets/customIcons";
import ConfirmRemove from "../../pages/Profile/Modal/ConfirmRemove";
import { GeneralStatus } from "../../types/constants/Vacancy";
import { GeneralStatus as GeneralStatusEnum } from "../../types/enumerators/GeneralStatus";
import "./styles.scss";

interface HeadCell {
  id: string;
  label?: string;
  orderBy?: boolean;
  asc?: boolean;
  onclick?: (item?: any) => void;
  classColumn?: string;
  className?: string;
  hide?: boolean;
}

interface Props {
  emptyText?: string;
  headCells?: HeadCell[];
  list?: any[];
  cantSelect?: boolean;
  filters?: {
    name?: string | null;
    email?: string | null;
    userId?: string[] | null;
    stageId?: string | null;
    status?: string | null;
    startDate?: Date | null;
    endDate?: Date | null;
  };
  setFilters?: any;
  enumStatus?: any;
  onReload?: () => void;
  onClick?: (row: { _id: string }) => void;
  onToRedash?: (row: { legalIdentity: string }) => void;
  onBack?: (row: { _id: string }) => void;
  onNext?: (row: { _id: string }) => void;
  onReject?: (row: { _id: string }) => void;
  onReactive?: (row: { _id: string }) => void;
  onAccept?: (row: { _id: string }) => void;
  onPlay?: (row: { _id: string }) => void;
  onSendMessage?: (row: { _id: string }) => void;
  onEvaluate?: (row: { _id: string; userId: string }) => void;
  onComment?: (row: { _id: string; userId: string }) => void;
  onActive?: (row: { _id: string; status: string }) => void;
  onEdit?: (row: { _id: string }) => void;
  onRemoveList?: (row: { _id: string }) => void;
  onCopy?: (row: { _id: string; title: string }) => void;
  onClass?: (row: { _id: string; userId: string }) => void;
  onClassWorkshop?: (row: { _id: string }) => void;
  onMorning?: (row: { _id: string }) => void;
  onAfternoon?: (row: { _id: string }) => void;
  onRemove?: (row: { _id: string; title: string }, status: string) => void;
  onFinish?: (row: { _id: string }, status: string) => void;
  onBlocked?: (
    row: {
      status?: string;
      _id: string;
    },
    status: string,
  ) => void;
  onGiveUp?: (row: { _id: string }, status: string) => void;
  onForm?: (row: { _id: string }, status: string) => void;
  onTransferClass?: (row: { _id: string }, status: string) => void;
  onDisqualify?: (row: { _id: string }, status: string) => void;
  onActiveClass?: (row: { status: string; _id: string }) => void;
  onShow?: (row: {
    placementVacancy?: any;
    _id: string;
    userId?: string;
  }) => void;
  onTag?: (row: { _id: string; userId: string }) => void;
  onReplaced?: (row: { _id: string }, status: string) => void;
  onAddCertificate?: (row: { _id: string }) => void;
  onAllAccept?: (row: { _id: string }[]) => void;
  onAllReject?: (row: { _id: string }[]) => void;
  onAllNext?: (row: { _id: string }[]) => void;
  onAllBack?: (row: { _id: string }[]) => void;
  onAllRemove?: (row: { _id: string }[]) => void;
  onAllActive?: (row: { _id: string }[]) => void;
  onAllClass?: (row: { _id: string; userId: string }[]) => void;
  onAllClassWorkshop?: (row: { _id: string }[]) => void;
  onAllWaitingList?: (row: { _id: string }[]) => void;
  onWaitingList?: (row: { _id: string }) => void;
  onAllMorning?: (row: { _id: string }[]) => void;
  onAllAfternoon?: (row: { _id: string }[]) => void;
  onAllAddToRecap?: (row: { _id: string }[]) => void;
  onAllGiveUp?: (row: { _id: string }[], status: string) => void;
  onAllReplaced?: (row: { _id: string }[], status: string) => void;
  onAllTransferClass?: (row: { _id: string }[], status: string) => void;
  onAllActiveClass?: (row: { _id: string }[], status: string) => void;
  onAllDisabled?: (row: { _id: string }[], status: string) => void;
  onAllDisqualify?: (row: { _id: string }[], status: string) => void;
  onAllTag?: (row: { _id: string }[]) => void;
  onTransferAllClass?: (row: { _id: string }[]) => void;
  onTransferAllClassVacancy?: (row: { _id: string }[]) => void;
  onAllRemoveList?: (row: { _id: string }[]) => void;
  onAllBlocked?: (row: { _id: string }[], status: string) => void;
  hasCommentUnresolved?: (row: {
    [x: string]: any;
    comments: { element: string; resolved?: boolean }[];
    miscellaneous: {
      generalComment: { resolved?: boolean }[];
    };
  }) => boolean;
  hasMoreActions?: boolean;
  onTransferClassVacancy?: (row: { _id: string }, status: string) => void;
  onViewClassVacancy?: (row: { _id: string }, status: string) => void;
  onViewClass?: (row: { _id: string }) => void;
}

interface PropsTableCellCheckbox {
  checked: boolean;
  onSelect: (value: boolean) => void;
}

interface PropsTableCellRowCheckbox extends PropsTableCellCheckbox {
  row: any;
}

interface PropsTableCellAllActions {
  headCell: HeadCell;
  allActions: {
    className?: string;
    text: string;
    onClick: () => void;
  }[];
  hasSelected: boolean;
}

interface PropsTableCell {
  headCell: HeadCell;
  row: any;
}

interface PropsTableCellStatus extends PropsTableCell {
  enumStatus: keyof typeof GeneralStatusEnum;
}

interface PropsTableCellPhoto extends PropsTableCell {
  onClick: (onclick: any, row: any) => void;
}

interface PropsActiveClass {
  row: any;
  status?: string;
  func: (row: any) => void;
}

interface PropsActive extends PropsActiveClass {
  functAction: (funct: any, ...params: any[]) => void;
}

interface PropsActions {
  row: any;
  action: any;
  hasCommentUnresolved?: (row: any) => boolean;
}

interface PropsMoreActions {
  list: {
    title: string;
    onClick: (row: any) => void;
    disabled: (row?: any) => boolean;
  }[];
  row: any;
}

const TableCellCheckbox = ({ checked, onSelect }: PropsTableCellCheckbox) => (
  <TableCell key={Math.random()} className="table-selected">
    <Checkbox
      key={Math.random()}
      id="checkbox-subscription"
      className="checkbox-user-company-search checkbox-color"
      checked={checked}
      onClick={(e) => onSelect(e.target.checked)}
    />
  </TableCell>
);

const TableCellRowCheckbox = ({
  checked,
  onSelect,
  row,
}: PropsTableCellRowCheckbox) => (
  <TableCell key={Math.random()}>
    <Checkbox
      id="listSelected"
      className="checkbox-color"
      checked={checked}
      onChange={() => onSelect(row)}
      onClick={(e) => e.stopPropagation()}
    />
  </TableCell>
);

const TableCellAllActions = ({
  headCell,
  allActions,
  hasSelected,
}: PropsTableCellAllActions) => {
  const hTable = document.querySelector("table")?.offsetHeight ?? 48;

  if (!hasSelected)
    return (
      <TableCell key={Math.random()} className={headCell?.classColumn ?? ""} />
    );

  return (
    <TableCell
      key={Math.random()}
      className={`${headCell?.classColumn ?? ""} actions`}
    >
      <div className="flex">
        {allActions?.length < 3 ? (
          allActions?.map((item) => (
            <div key={Math.random()}>
              <Button
                text={item?.text}
                className={item?.className ?? "table-actions"}
                onClick={item?.onClick}
              />
            </div>
          ))
        ) : (
          <ButtonMenu
            text="AÇÕES EM MASSA"
            className="table-actions"
            items={allActions}
            endIcon
            icon={<ChevronWhite className="table-icon" />}
            style={{
              maxHeight: hTable - 12,
            }}
          />
        )}
      </div>
    </TableCell>
  );
};

const TableCellComponent = ({ headCell }: { headCell: HeadCell }) => (
  <TableCell
    key={Math.random()}
    className={`title-table ${headCell?.classColumn ?? ""}`}
  >
    {headCell?.label}
    {headCell?.orderBy && (
      <span role="presentation" onClick={headCell?.onclick}>
        <ChevronDown
          className={`table-icon ${headCell?.asc ? "is-active" : ""}`}
        />
      </span>
    )}
  </TableCell>
);

const TableCellPhoto = ({ headCell, row, onClick }: PropsTableCellPhoto) => (
  <TableCell key={Math.random()} className={headCell?.className ?? ""}>
    <button
      type="button"
      onClick={() => onClick(headCell?.onclick, row)}
      className="table-profile"
    >
      {row?.photo ? (
        <img alt="" src={row?.photo} className="table-body" />
      ) : (
        <AvatarEmpty
          className="border-avatar-empty img-attachment-photo-small"
          heightIcon={40}
          widthIcon={40}
        />
      )}
    </button>
  </TableCell>
);

const TableCellStatus = ({
  headCell,
  row,
  enumStatus,
}: PropsTableCellStatus) => (
  <TableCell key={Math.random()} className={headCell?.className ?? ""}>
    <div className={`status-${row?.status}`}>
      {enumStatus
        ? enumStatus[row?.status]
        : GeneralStatus[row?.status as keyof typeof GeneralStatusEnum]}
    </div>
  </TableCell>
);

const colorTags: {
  Fluindo: string;
  Atenção: string;
  Help: string;
  Destaque: string;
  "Excelente comunicação": string;
  Proatividade: string;
  Colaborativa: string;
  "Boa comunicação": string;
  "Proatividade razoável": string;
  "Colaboração aceitável": string;
  "Comunicação insatisfatória": string;
  "Dificuldade no trabalho em equipe": string;
  "Proatividade ruim": string;
} = {
  Fluindo: "green",
  Atenção: "yellow",
  Help: "red",
  Destaque: "purple",
  "Excelente comunicação": "green",
  Proatividade: "green",
  Colaborativa: "green",
  "Boa comunicação": "yellow",
  "Proatividade razoável": "yellow",
  "Colaboração aceitável": "yellow",
  "Comunicação insatisfatória": "red",
  "Dificuldade no trabalho em equipe": "red",
  "Proatividade ruim": "red",
};

const TableCellTags = ({ headCell, row }: PropsTableCell) => (
  <TableCell key={Math.random()} className={headCell?.className ?? ""}>
    <div className="content-tags">
      {row?.tags?.map((item: string) => (
        <div
          className={colorTags[item as keyof typeof colorTags] ?? ""}
          key={Math.random()}
        >
          {item}
        </div>
      ))}
    </div>
  </TableCell>
);

const TableCellSkills = ({ headCell, row }: PropsTableCell) => (
  <TableCell key={Math.random()} className={headCell?.className ?? ""}>
    <div className="flex flex-row flex-wrap">
      {row?.skills?.map((item: { key: string }) => (
        <div key={Math.random()} className="pills-skills-vacancy">
          {item.key}
        </div>
      ))}
    </div>
  </TableCell>
);

const Active = ({ row, func, functAction }: PropsActive) => (
  <Tooltip
    title={row?.status === GeneralStatusEnum.ACTIVE ? "Parar" : "Ativar"}
    placement="top"
    disableHoverListener={row?.status === GeneralStatusEnum.FINISHED}
    disableTouchListener={row?.status === GeneralStatusEnum.FINISHED}
    arrow
  >
    <FormControlLabel
      className="margin-left-12"
      onClick={(e) => e.stopPropagation()}
      control={
        <Switch
          checked={row?.status === GeneralStatusEnum.ACTIVE}
          name="status"
          onChange={() => (functAction ? functAction(func, row) : func(row))}
        />
      }
      label=""
      disabled={row?.status === GeneralStatusEnum.FINISHED}
    />
  </Tooltip>
);

const ActiveClass = ({ row, func }: PropsActiveClass) => {
  // TODO: remover !row?.classId
  const isDisabled = useMemo(
    () =>
      !row?.classId &&
      (row?.status === GeneralStatusEnum.FINISHED ||
        row?.status === GeneralStatusEnum.CHANGED ||
        row?.status === GeneralStatusEnum.GIVE_UP ||
        row?.status === GeneralStatusEnum.DISAPPROVED ||
        row?.status === GeneralStatusEnum.REPLACED),
    [row?.classId, row?.status],
  );

  return (
    <Tooltip
      title={
        row?.status === GeneralStatusEnum.IN_PROCESS ? "Trancar" : "Ativar"
      }
      placement="top"
      className={isDisabled ? "disabled-tooltip" : ""}
      disableHoverListener={isDisabled}
      disableTouchListener={isDisabled}
      arrow
    >
      <FormControlLabel
        className="margin-left-12"
        onClick={(e) => e.stopPropagation()}
        control={
          <Switch
            checked={row?.status === GeneralStatusEnum.IN_PROCESS}
            name="status"
            onChange={() => func(row)}
          />
        }
        label=""
        disabled={isDisabled}
      />
    </Tooltip>
  );
};

const Actions = ({ action, row, hasCommentUnresolved }: PropsActions) => (
  <Tooltip
    title={action?.title}
    placement="top"
    key={Math.random()}
    disableHoverListener={action?.disabled && action?.disabled(row)}
    disableTouchListener={action?.disabled && action?.disabled(row)}
    arrow
  >
    <div
      role="button"
      tabIndex={0}
      onClick={(e) => {
        e.stopPropagation();
        if (!action?.disabled || (action?.disabled && !action?.disabled(row)))
          action?.onClick(row);
      }}
      className={`${
        action?.disabled && action?.disabled(row) ? "disabled " : ""
      }${action?.className ?? ""} margin-right-16 table-button-container flex`}
    >
      {action.id !== "comment" ||
      (action.id === "comment" && !hasCommentUnresolved?.(row))
        ? action.icon
        : action.iconActive}
    </div>
  </Tooltip>
);

const MoreActions = ({ list, row }: PropsMoreActions) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // TODO: remover !row?.classId
  const isDisabled = useMemo(
    () =>
      !row?.classId &&
      (row?.status === GeneralStatusEnum.FINISHED ||
        row?.status === GeneralStatusEnum.CHANGED ||
        row?.status === GeneralStatusEnum.GIVE_UP ||
        row?.status === GeneralStatusEnum.REPLACED ||
        (row?.classId && row?.status === GeneralStatusEnum.DISAPPROVED)),
    [row?.classId, row?.status],
  );

  return (
    <>
      <Tooltip
        title="Mais ações"
        placement="top"
        className={isDisabled ? "disabled-tooltip" : ""}
        disableHoverListener={isDisabled}
        disableTouchListener={isDisabled}
        arrow
      >
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={openMenu ? "long-menu" : undefined}
            aria-expanded={openMenu ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            disabled={isDisabled}
          >
            <ThreePoints />
          </IconButton>
        </div>
      </Tooltip>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
      >
        {list?.map((item) => (
          <MenuItem
            key={Math.random()}
            onClick={() => item?.onClick(row)}
            disabled={item?.disabled(row)}
          >
            {item?.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const TableComponent = ({
  emptyText,
  headCells,
  list = [],
  cantSelect,
  filters = {},
  setFilters,
  enumStatus,
  onReload,
  onClick,
  onToRedash,
  onBack,
  onNext,
  onReject,
  onReactive,
  onAccept,
  onPlay,
  onSendMessage,
  onEvaluate,
  onComment,
  onActive,
  onEdit,
  onRemoveList,
  onCopy,
  onRemove,
  onFinish,
  onBlocked,
  onGiveUp,
  onForm,
  onTransferClass,
  onDisqualify,
  onActiveClass,
  onShow,
  onClass,
  onClassWorkshop,
  onMorning,
  onAfternoon,
  onTag,
  onAddCertificate,
  onReplaced,
  onAllMorning,
  onAllAfternoon,
  onAllAddToRecap,
  onAllAccept,
  onAllReject,
  onAllNext,
  onAllBack,
  onAllRemove,
  onAllActive,
  onAllClass,
  onAllClassWorkshop,
  onAllWaitingList,
  onWaitingList,
  onAllGiveUp,
  onAllReplaced,
  onAllTransferClass,
  onAllActiveClass,
  onAllDisabled,
  onAllDisqualify,
  onAllTag,
  hasCommentUnresolved,
  hasMoreActions,
  onTransferClassVacancy,
  onViewClassVacancy,
  onViewClass,
  onTransferAllClass,
  onTransferAllClassVacancy,
  onAllRemoveList,
  onAllBlocked,
}: Props): JSX.Element => {
  const [listSelected, setListSelected] = useState<
    { _id: string; userId: string }[]
  >([]);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [removed, setRemoved] = useState<{
    title: string;
    text: string;
    onRemove?: () => void;
  }>({
    title: "",
    text: "",
  });

  // TODO: tirar list?.filter((item) => !!item?.classId)?.length > 0 ||
  const allChecked = useMemo(
    () =>
      !!listSelected &&
      listSelected?.length > 0 &&
      !!list &&
      list?.length > 0 &&
      (list?.filter((item) => !!item?.classId)?.length > 0 ||
        listSelected?.length ===
          list?.filter((item) => item?.status !== GeneralStatusEnum.FINISHED)
            ?.length),
    [list, listSelected],
  );

  const functAction = useCallback(
    (funct, ...params) =>
      funct(...params)
        .then((result: boolean) => {
          if (result) {
            if (onReload) onReload();
            toast.success("Ação executada com sucesso");
          } else toast.error("Erro ao executar ação");
        })
        .catch(() => toast.error("Erro ao executar ação")),
    [onReload],
  );

  const listAllActions = useMemo(
    () => [
      {
        renderCondition: !!onTransferAllClass,
        id: "transfer",
        title: "Adicionar a turma",
        className: "button-transfer",
        onClick: onTransferAllClass
          ? () => onTransferAllClass(listSelected)
          : () => {},
      },
      {
        renderCondition: !!onTransferAllClassVacancy,
        id: "transfer",
        title: "Adicionar a processo",
        className: "button-transfer",
        onClick: onTransferAllClassVacancy
          ? () => onTransferAllClassVacancy(listSelected)
          : () => {},
      },
      {
        renderCondition: !!onAllRemoveList,
        id: "remove",
        title: "Remover",
        className: "button-remove",
        onClick: onAllRemoveList
          ? () => onAllRemoveList(listSelected)
          : () => {},
      },
      {
        renderCondition: !!onAllBack,
        id: "back",
        title: "Voltar etapa",
        onClick: onAllBack ? () => onAllBack(listSelected) : () => {},
      },
      {
        renderCondition: !!onAllNext,
        id: "next",
        title: "Avançar etapa",
        onClick: onAllNext ? () => onAllNext(listSelected) : () => {},
      },
      {
        renderCondition: !!onAllReject,
        id: "reject",
        title: "Reprovar",
        onClick: onAllReject ? () => onAllReject(listSelected) : () => {},
      },
      {
        renderCondition: !!onAllAccept,
        id: "accept",
        title: "Aprovar",
        onClick: onAllAccept ? () => onAllAccept(listSelected) : () => {},
      },
      {
        renderCondition: !!onAllWaitingList,
        id: "waitingList",
        title: "Lista de espera",
        onClick: onAllWaitingList
          ? () => onAllWaitingList(listSelected)
          : () => {},
      },
      {
        renderCondition: !!onAllClassWorkshop,
        id: "classWorkshop",
        title: "Atribuir oficina",
        onClick: onAllClassWorkshop
          ? () => onAllClassWorkshop(listSelected)
          : () => {},
      },
      {
        renderCondition: !!onAllClass,
        id: "class",
        title: "Atribuir turma",
        onClick: onAllClass ? () => onAllClass(listSelected) : () => {},
      },
      {
        renderCondition: !!onAllMorning,
        id: "class",
        title: "Presença manhã",
        onClick: onAllMorning ? () => onAllMorning(listSelected) : () => {},
      },
      {
        renderCondition: !!onAllAfternoon,
        id: "class",
        title: "Presença tarde",
        onClick: onAllAfternoon ? () => onAllAfternoon(listSelected) : () => {},
      },
      {
        renderCondition: !!onAllAddToRecap,
        id: "addRecap",
        title: "Adicionar a repescagem",
        onClick: onAllAddToRecap
          ? () => onAllAddToRecap(listSelected)
          : () => {},
      },
      {
        renderCondition: !!onAllActive,
        id: "active",
        title: "Ativar",
        onClick: onAllActive ? () => onAllActive(listSelected) : () => {},
      },
      {
        renderCondition: !!onAllRemove,
        id: "delete",
        title: "Excluir",
        className: "button-remove",
        onClick: onAllRemove
          ? () => {
              setRemoved({
                title: "Excluir",
                text: "Tem certeza de que deseja excluir?",
                onRemove: () => onAllRemove(listSelected),
              });
              setOpenModalRemove(true);
            }
          : () => {},
      },
      {
        renderCondition: !!onAllDisqualify,
        id: "allDisqualify",
        title: "Desclassificadas",
        onClick: onAllDisqualify
          ? () => onAllDisqualify(listSelected, GeneralStatusEnum.DISAPPROVED)
          : () => {},
      },
      {
        renderCondition: !!onAllGiveUp,
        id: "allGiveUp",
        title: "Desistentes",
        onClick: onAllGiveUp
          ? () => onAllGiveUp(listSelected, GeneralStatusEnum.GIVE_UP)
          : () => {},
      },
      {
        renderCondition: !!onAllReplaced,
        id: "allReplaced",
        title: "Substituídas",
        onClick: onAllReplaced
          ? () => onAllReplaced(listSelected, GeneralStatusEnum.REPLACED)
          : () => {},
      },

      {
        renderCondition: !!onAllTransferClass,
        id: "allTransfer",
        title: "Adicionar a turma",
        onClick: onAllTransferClass
          ? () => onAllTransferClass(listSelected, GeneralStatusEnum.CHANGED)
          : () => {},
      },
      {
        renderCondition: !!onAllActiveClass,
        id: "allActiveClass",
        title: "Trancar",
        onClick: onAllActiveClass
          ? () => onAllActiveClass(listSelected, GeneralStatusEnum.STOPPED)
          : () => {},
      },
      {
        renderCondition: !!onAllActiveClass,
        id: "allActiveClass",
        title: "Ativar",
        onClick: onAllActiveClass
          ? () => onAllActiveClass(listSelected, GeneralStatusEnum.IN_PROCESS)
          : () => {},
      },
      {
        renderCondition: !!onAllDisabled,
        id: "allDisabled",
        title: "Desabilitar",
        onClick: onAllDisabled
          ? () => onAllDisabled(listSelected, GeneralStatusEnum.DISABLED)
          : () => {},
      },
      {
        renderCondition: !!onAllTag,
        id: "onAllTag",
        title: "Adicionar tags",
        onClick: onAllTag ? () => onAllTag(listSelected) : () => {},
      },
      {
        renderCondition: !!onAllBlocked,
        id: "allBlocked",
        title: "Bloquear",
        onClick: onAllBlocked
          ? () => onAllBlocked(listSelected, GeneralStatusEnum.BLOCKED)
          : () => {},
      },
    ],
    [
      onTransferAllClass,
      onTransferAllClassVacancy,
      onAllRemoveList,
      onAllBack,
      onAllNext,
      onAllReject,
      onAllAccept,
      onAllWaitingList,
      onAllClassWorkshop,
      onAllClass,
      onAllMorning,
      onAllAfternoon,
      onAllAddToRecap,
      onAllActive,
      onAllRemove,
      onAllDisqualify,
      onAllGiveUp,
      onAllReplaced,
      onAllTransferClass,
      onAllActiveClass,
      onAllDisabled,
      onAllTag,
      onAllBlocked,
      listSelected,
    ],
  );

  const listActions = useMemo(
    () => [
      {
        renderCondition: () => !!onToRedash,
        disabled: () => false,
        id: "toRedash",
        title: "Redash",
        icon: <LinkIcon />,
        onClick: onToRedash
          ? (row: { legalIdentity: string }) => onToRedash(row)
          : () => {},
        moreAction: false,
      },
      {
        renderCondition: () => !!onBack,
        disabled: (row: { firstStage?: boolean; status?: string }) =>
          row?.firstStage ||
          row?.status === GeneralStatusEnum.FINISHED ||
          row?.status === GeneralStatusEnum.APPROVED ||
          row?.status === GeneralStatusEnum.DISAPPROVED,
        id: "back",
        title: "Voltar etapa",
        icon: <ArrowRight />,
        className: "chevron-open",
        onClick: onBack
          ? (row: { _id: string }) => functAction(onBack, row)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: () => !!onNext,
        disabled: (row: { lastStage?: boolean; status?: string }) =>
          row?.lastStage ||
          row?.status === GeneralStatusEnum.FINISHED ||
          row?.status === GeneralStatusEnum.APPROVED ||
          row?.status === GeneralStatusEnum.DISAPPROVED,
        id: "next",
        title: "Avançar etapa",
        icon: <ArrowRight />,
        onClick: onNext
          ? (row: { _id: string }) => functAction(onNext, row)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onReject && row?.status !== GeneralStatusEnum.DISAPPROVED,
        disabled: (row: { status?: string }) =>
          row?.status === GeneralStatusEnum.FINISHED ||
          row?.status === GeneralStatusEnum.DISAPPROVED,
        id: "reject",
        title: "Reprovar",
        icon: <Block className="steps-icons margin-0" />,
        onClick: onReject
          ? (row: { _id: string }) => functAction(onReject, row)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onReactive &&
          (row?.status === GeneralStatusEnum.APPROVED ||
            row?.status === GeneralStatusEnum.DISAPPROVED),
        disabled: () => false,
        id: "reactive",
        title: "Reativar",
        icon: <Refresh className="steps-icons margin-0" />,
        onClick: onReactive
          ? (row: { _id: string }) => functAction(onReactive, row)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onPlay && row?.status === GeneralStatusEnum.FINISHED,
        disabled: () => false,
        id: "reactive",
        title: "Reativar",
        icon: <IconVideo className="steps-icons margin-0" />,
        onClick: onPlay
          ? (row: { _id: string }) =>
              functAction(onPlay, row, GeneralStatusEnum.ACTIVE)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onAccept && row?.status !== GeneralStatusEnum.APPROVED,
        disabled: (row: { status?: string }) =>
          row?.status === GeneralStatusEnum.FINISHED ||
          row?.status === GeneralStatusEnum.APPROVED,
        id: "accept",
        title: "Aprovar",
        icon: <Unblock className="steps-icons margin-0" />,
        onClick: onAccept
          ? (row: { _id: string }) => functAction(onAccept, row)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onFinish && row?.status !== GeneralStatusEnum.FINISHED,
        disabled: (row: { status?: string }) =>
          row?.status === GeneralStatusEnum.FINISHED,
        id: "finish",
        title: "Finalizar",
        icon: <Unblock className="steps-icons margin-0" />,
        onClick: onFinish
          ? (row: { _id: string }) =>
              functAction(onFinish, row, GeneralStatusEnum.FINISHED)
          : () => {},
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onBlocked && row?.status !== GeneralStatusEnum.BLOCKED,
        disabled: (row: { status?: string }) =>
          row?.status === GeneralStatusEnum.BLOCKED,
        id: "blocked",
        title: "Bloquear",
        icon: <Block className="steps-icons margin-0" />,
        onClick: onBlocked
          ? (row: { _id: string }) => onBlocked(row, GeneralStatusEnum.BLOCKED)
          : () => {},
      },
      {
        renderCondition: () => !!onWaitingList,
        disabled: (row: { status?: string }) =>
          row?.status === GeneralStatusEnum.FINISHED,
        id: "waitingList",
        title: "Lista de Espera",
        icon: <Close widthIcon={24} heightIcon={24} />,
        onClick: onWaitingList
          ? (row: { _id: string }) => functAction(onWaitingList, row)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onForm && row?.status !== GeneralStatusEnum.FINISHED,
        // TODO: tirar !row?.classId
        disabled: (row: { status?: string; classId?: string }) =>
          !row?.classId && row?.status === GeneralStatusEnum.FINISHED,
        id: "form",
        title: "Formada",
        icon: <Unblock className="steps-icons margin-0" />,
        onClick: onForm
          ? (row: { _id: string }) => onForm(row, GeneralStatusEnum.FINISHED)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onDisqualify && row?.status !== GeneralStatusEnum.DISAPPROVED,
        // TODO: tirar !row?.classId
        disabled: (row: { status?: string; classId?: string }) =>
          !row?.classId && row?.status === GeneralStatusEnum.FINISHED,
        id: "disqualify",
        title: "Desclassificada",
        icon: <Block className="steps-icons margin-0" />,
        onClick: onDisqualify
          ? (row: { _id: string }) =>
              onDisqualify(row, GeneralStatusEnum.DISAPPROVED)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onGiveUp && row?.status !== GeneralStatusEnum.GIVE_UP,
        // TODO: tirar !row?.classId
        disabled: (row: { status?: string; classId?: string }) =>
          !row?.classId && row?.status === GeneralStatusEnum.FINISHED,
        id: "giveUp",
        title: "Desistente",
        icon: <Close widthIcon={24} heightIcon={24} />,
        onClick: onGiveUp
          ? (row: { _id: string }) => onGiveUp(row, GeneralStatusEnum.GIVE_UP)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onReplaced && row?.status !== GeneralStatusEnum.REPLACED,
        // TODO: tirar !row?.classId
        disabled: (row: { status?: string; classId?: string }) =>
          !row?.classId && row?.status === GeneralStatusEnum.FINISHED,
        id: "replaced",
        title: "Substituída",
        icon: <Close widthIcon={24} heightIcon={24} />,
        onClick: onReplaced
          ? (row: { _id: string }) =>
              onReplaced(row, GeneralStatusEnum.REPLACED)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: () => !!onEdit,
        id: "edit",
        title: "Editar",
        icon: <Edit />,
        disabled: (row: { status?: string }) =>
          row?.status === GeneralStatusEnum.FINISHED,
        onClick: onEdit ? (row: { _id: string }) => onEdit(row) : () => {},
      },
      {
        renderCondition: () => !!onRemove,
        id: "delete",
        title: "Excluir",
        icon: <Delete />,
        disabled: (row: { status?: string }) =>
          row?.status === GeneralStatusEnum.FINISHED,
        onClick: onRemove
          ? (row: { _id: string; title: string }) => {
              setRemoved({
                title: "Excluir",
                text: `Tem certeza de que deseja excluir ${row.title}?`,
                onRemove: () =>
                  functAction(onRemove, row, GeneralStatusEnum.DISABLED),
              });
              setOpenModalRemove(true);
            }
          : () => {},
      },
      {
        renderCondition: () => !!onCopy,
        id: "copy",
        title: "Duplicar",
        icon: <Copy />,
        disabled: () => false,
        onClick: onCopy
          ? (row: { _id: string; title: string }) => functAction(onCopy, row)
          : () => {},
      },
      {
        renderCondition: () => !!onSendMessage,
        disabled: () => false,
        id: "message",
        title: "Mensagem",
        icon: <Mail />,
        onClick: onSendMessage
          ? (row: { _id: string }) => onSendMessage(row)
          : () => {},
      },
      {
        renderCondition: () => !!onEvaluate,
        disabled: () => false,
        id: "evaluate",
        title: "Nota",
        icon: <ProposalsGray />,
        onClick: onEvaluate
          ? (row: { _id: string; userId: string }) => onEvaluate(row)
          : () => {},
      },
      {
        renderCondition: () => !!onComment,
        disabled: () => false,
        id: "comment",
        title: "Comentário interno",
        icon: <TestimonialGray />,
        onClick: onComment
          ? (row: { _id: string; userId: string }) => onComment(row)
          : () => {},
        iconActive: <TestimonialActive />,
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onAddCertificate && row?.status === GeneralStatusEnum.FINISHED,
        disabled: (row: { status?: string }) =>
          row?.status !== GeneralStatusEnum.FINISHED,
        id: "onAddCertificate",
        title: "Adicionar certificado",
        icon: <CertificateIcon className="steps-icons margin-0" />,
        onClick: onAddCertificate
          ? (row: { _id: string }) => onAddCertificate(row)
          : () => {},
      },
      {
        renderCondition: () => !!onShow,
        disabled: () => false,
        id: "show",
        title: "Visualizar",
        icon: <Show />,
        onClick: onShow
          ? (row: { _id: string; userId: string }) => onShow(row)
          : () => {},
      },
      {
        renderCondition: () => !!onRemoveList,
        id: "delete",
        title: "Excluir",
        icon: <Delete />,
        disabled: (row: { status?: string }) =>
          row?.status === GeneralStatusEnum.FINISHED,
        onClick: onRemoveList
          ? (row: { _id: string; userId: string }) => onRemoveList(row)
          : () => {},
      },
      {
        renderCondition: () => !!onClassWorkshop,
        disabled: () => false,
        id: "classWorkshop",
        title: "Atribuir oficina",
        onClick: onClassWorkshop
          ? (row: { _id: string }) => onClassWorkshop(row)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: () => !!onClass,
        disabled: () => false,
        id: "class",
        title: "Atribuir turma",
        onClick: onClass
          ? (row: { _id: string; userId: string }) => onClass(row)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: () => !!onMorning,
        disabled: () => false,
        id: "class",
        title: "Presença manhã",
        onClick: onMorning
          ? (row: { _id: string }) => onMorning(row)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: () => !!onAfternoon,
        disabled: () => false,
        id: "class",
        title: "Presença tarde",
        onClick: onAfternoon
          ? (row: { _id: string }) => onAfternoon(row)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: (row: { status?: string }) =>
          !!onTransferClass && row?.status !== GeneralStatusEnum.CHANGED,
        disabled: (row: { status?: string }) =>
          row?.status === GeneralStatusEnum.FINISHED,
        id: "transfer",
        title: "Adicionar a turma",
        icon: <Transfer className="steps-icons margin-0" />,
        onClick: onTransferClass
          ? (row: { _id: string }) =>
              onTransferClass(row, GeneralStatusEnum.CHANGED)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: () => !!onTransferClassVacancy,
        disabled: () => false,
        id: "transferRecapProcess",
        title: "Adicionar a processo",
        onClick: onTransferClassVacancy
          ? (row: { _id: string }) =>
              onTransferClassVacancy(row, GeneralStatusEnum.CHANGED)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: () => !!onViewClassVacancy,
        disabled: () => false,
        id: "recapProcess",
        title: "Ver processos seletivos",
        onClick: onViewClassVacancy
          ? (row: { _id: string }) =>
              onViewClassVacancy(row, GeneralStatusEnum.CHANGED)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: () => !!onViewClass,
        disabled: () => false,
        id: "recapCourses",
        title: "Ver cursos",
        onClick: onViewClass
          ? (row: { _id: string }) => onViewClass(row)
          : () => {},
        moreAction: true,
      },
      {
        renderCondition: () => !!onTag,
        disabled: () => false,
        id: "tag",
        title: "Adicionar tags",
        onClick: onTag
          ? (row: { _id: string; userId: string }) => onTag(row)
          : () => {},
        moreAction: true,
      },
    ],
    [
      onToRedash,
      onBack,
      onNext,
      onReject,
      onReactive,
      onPlay,
      onAccept,
      onFinish,
      onBlocked,
      onWaitingList,
      onForm,
      onDisqualify,
      onGiveUp,
      onReplaced,
      onEdit,
      onRemove,
      onCopy,
      onSendMessage,
      onEvaluate,
      onComment,
      onAddCertificate,
      onShow,
      onRemoveList,
      onClassWorkshop,
      onClass,
      onMorning,
      onAfternoon,
      onTransferClass,
      onTransferClassVacancy,
      onViewClassVacancy,
      onViewClass,
      onTag,
      functAction,
    ],
  );

  const handleChangeFilters = useCallback(
    (value, props) => {
      if (props === "startDate" || props === "endDate")
        setFilters({ ...filters, [props]: moment(value).toISOString() });
      else setFilters({ ...filters, [props]: value });
    },
    [filters, setFilters],
  );

  const listFilters = useMemo(
    () => [
      {
        renderCondition: "name" in filters,
        child: (
          <TextField
            key={Math.random()}
            autoComplete="off"
            id="searchVacancies"
            placeholder="Buscar candidata"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.name ?? ""}
            onChange={(value) => handleChangeFilters(value, "name")}
            // onBlur={searchVacancySubscriptionsWithUserFilter}
          />
        ),
      },
      {
        renderCondition: "email" in filters,
        child: (
          <TextField
            key={Math.random()}
            autoComplete="off"
            id="searchVacancies"
            placeholder="Buscar e-mail"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.email ?? ""}
            onChange={(value) => handleChangeFilters(value, "email")}
            // onBlur={searchVacancySubscriptionsWithUserFilter}
          />
        ),
      },
      {
        renderCondition: "startDate" in filters,
        child: (
          <InputDate
            key={Math.random()}
            future
            className="status-filter"
            label="Candidatura"
            views={["year", "month", "date"]}
            format="DD/MM/yyyy"
            value={filters?.startDate ?? null}
            onChange={(value) => handleChangeFilters(value, "startDate")}
            // onAccept={value => searchVacancyWithDateFilter(value, 'startDate')}
            notAuto
          />
        ),
      },
      {
        renderCondition: "endDate" in filters,
        child: (
          <InputDate
            key={Math.random()}
            future
            className="status-filter"
            label="Cancelamento"
            views={["year", "month", "date"]}
            format="DD/MM/yyyy"
            value={filters?.endDate ?? null}
            onChange={(value) => handleChangeFilters(value, "endDate")}
            // onAccept={value => searchVacancyWithDateFilter(value, 'endDate')}
            notAuto
          />
        ),
      },
      {
        renderCondition: "stageId" in filters,
        child: (
          <Select
            key={Math.random()}
            className="steps-filter"
            id="step"
            label="Etapa"
            value={filters?.stageId ?? ""}
            onChange={(e) => handleChangeFilters(e.target.value, "stageId")}
            // onBlur={searchVacancySubscriptionsWithFilter}
            // options={filterStageList}
            options={[]}
          />
        ),
      },
      {
        renderCondition: "status" in filters,
        child: (
          <Select
            key={Math.random()}
            className="status-filter last-filter"
            id="status"
            label="Status"
            value={filters?.status ?? ""}
            onChange={(e) => handleChangeFilters(e.target.value, "status")}
            // onBlur={searchVacancySubscriptionsWithFilter}
            // options={filterStatusList}
            options={[]}
          />
        ),
      },
    ],
    [filters, handleChangeFilters],
  );

  const onSelect = useCallback(
    (row: any) => {
      const newListSelected = [...listSelected];
      const index = newListSelected?.findIndex(
        (item) => item?._id === row?._id,
      );
      if (index !== -1) newListSelected?.splice(index, 1);
      else newListSelected?.push(row);
      setListSelected(newListSelected);
    },
    [listSelected],
  );

  const onSelectAll = useCallback(
    (checked) => {
      let newListSelected = [...listSelected];
      // TODO: tirar row?.classId
      if (checked) {
        newListSelected = [];
        list
          ?.filter(
            (l) => l?.classId || l?.status !== GeneralStatusEnum.FINISHED,
          )
          ?.forEach((item) => newListSelected?.push(item));
      } else newListSelected = [];

      setListSelected(newListSelected);
    },
    [listSelected, list],
  );

  const handleClickPhoto = useCallback(
    (onclick, row) =>
      row?.userStatus === GeneralStatusEnum.ACTIVE
        ? onclick(row?.userId)
        : null,
    [],
  );

  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const hasData = useMemo(() => list && list?.length > 0, [list]);
  const hasAction = useMemo(
    () => headCells?.find((item) => !item?.hide && item?.id === "actions"),
    [headCells],
  );
  const headCellsRender = useMemo(
    () => headCells?.filter((item) => !item?.hide && item?.id !== "actions"),
    [headCells],
  );
  const listAllActionsRender = useMemo(
    () => listAllActions?.filter((item) => item?.renderCondition),
    [listAllActions],
  );
  const listActionsRender = useCallback(
    (row) => listActions?.filter((item) => item?.renderCondition(row)),
    [listActions],
  );
  const listMoreActions = useCallback(
    (row) =>
      listActions?.filter(
        (item) =>
          item?.renderCondition(row) &&
          !item?.disabled(row) &&
          item?.moreAction,
      ),
    [listActions],
  );
  const listNotMoreActions = useCallback(
    (row) =>
      listActions?.filter(
        (item) => item?.renderCondition(row) && !item?.moreAction,
      ),
    [listActions],
  );

  // TODO: tirar !row?.classId
  const getClassNameRow = useCallback(
    (row) =>
      `cursor-pointer table-row ${
        (!row?.classId && row?.status === GeneralStatusEnum.FINISHED) ||
        row?.status === GeneralStatusEnum.BLOCKED
          ? "is-finished"
          : "" ||
            listSelected?.find((item: { _id: any }) => item._id === row._id)
          ? "is-selected"
          : ""
      }`,
    [listSelected],
  );

  return (
    <>
      <TableContainer
        style={{ maxHeight: height - 70 - 56 - 80 - 64 }}
        className="table"
      >
        {Object.keys(filters)?.length > 0 && (
          <TableRow
            key={Math.random()}
            className="flex padding-top-20 padding-bottom-16"
          >
            {listFilters?.map(
              (filter) => filter?.renderCondition && filter?.child,
            )}
          </TableRow>
        )}
        <Table stickyHeader>
          <TableHead>
            <TableRow key={Math.random()}>
              {!cantSelect && (
                <TableCellCheckbox
                  checked={allChecked}
                  onSelect={onSelectAll}
                />
              )}
              {headCells
                ?.filter((item) => !item?.hide)
                ?.map((headCell: HeadCell) =>
                  headCell?.id === "actions" && hasData ? (
                    <TableCellAllActions
                      key={Math.random()}
                      headCell={headCell}
                      allActions={listAllActionsRender?.map((action) => ({
                        text: action?.title,
                        onClick: action?.onClick,
                        className: action?.className,
                      }))}
                      hasSelected={listSelected?.length > 0}
                    />
                  ) : (
                    <TableCellComponent headCell={headCell} />
                  ),
                )}
            </TableRow>
          </TableHead>
          {hasData && (
            <TableBody>
              {list?.map((row: any) => (
                <TableRow
                  className={getClassNameRow(row)}
                  key={Math.random()}
                  onClick={() => (onClick ? onClick(row) : undefined)}
                >
                  {!cantSelect && (
                    <TableCellRowCheckbox
                      checked={
                        !!listSelected &&
                        listSelected?.length > 0 &&
                        !!listSelected?.find(
                          (item: { _id: string }) => item._id === row._id,
                        )
                      }
                      row={row}
                      onSelect={onSelect}
                    />
                  )}
                  {headCellsRender?.map((headCell: HeadCell) => {
                    if (headCell?.id === "photo")
                      return (
                        <TableCellPhoto
                          key={Math.random()}
                          headCell={headCell}
                          row={row}
                          onClick={handleClickPhoto}
                        />
                      );

                    if (headCell?.id === "status")
                      return (
                        <TableCellStatus
                          key={Math.random()}
                          headCell={headCell}
                          row={row}
                          enumStatus={enumStatus}
                        />
                      );

                    if (headCell?.id === "skills")
                      return (
                        <TableCellSkills
                          key={Math.random()}
                          headCell={headCell}
                          row={row}
                        />
                      );

                    if (headCell?.id === "tags")
                      return (
                        <TableCellTags
                          key={Math.random()}
                          headCell={headCell}
                          row={row}
                        />
                      );

                    return (
                      <TableCell
                        key={Math.random()}
                        className={headCell?.className ?? ""}
                      >
                        {row[headCell?.id]}
                      </TableCell>
                    );
                  })}
                  {hasAction && (
                    <TableCell key={Math.random()}>
                      <div className="row-table row-table-cell flex flex-end">
                        {!!onActive && (
                          <Active
                            row={row}
                            func={onActive}
                            functAction={functAction}
                          />
                        )}
                        {!!onActiveClass && (
                          <ActiveClass row={row} func={onActiveClass} />
                        )}
                        {hasMoreActions
                          ? listNotMoreActions(row)?.map((action: any) => (
                              <Actions
                                key={Math.random()}
                                action={action}
                                row={row}
                                hasCommentUnresolved={hasCommentUnresolved}
                              />
                            ))
                          : listActionsRender(row)?.map((action: any) => (
                              <Actions
                                key={Math.random()}
                                action={action}
                                row={row}
                                hasCommentUnresolved={hasCommentUnresolved}
                              />
                            ))}
                        {hasMoreActions && (
                          <MoreActions list={listMoreActions(row)} row={row} />
                        )}
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {!list?.length && (
          <div className="not-vacancies">
            {emptyText ?? "Não há resultados"}
          </div>
        )}
      </TableContainer>
      <ConfirmRemove
        titleHeader={removed.title}
        text={removed.text}
        onClose={() => setOpenModalRemove(false)}
        onRemove={removed.onRemove ? removed.onRemove : () => {}}
        openModal={openModalRemove}
      />
    </>
  );
};

export default memo(TableComponent);
