/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  CardMenuLeft,
  Card,
  Button,
  Layout,
  HeaderProfile,
  CardJob,
  ModalConfirmCandidate,
} from "../../components";
import "./style.scss";
import {
  Localization,
  Unblock,
  CompanyAvatarEmpty,
  Bookmark,
  BookmarkFilled,
} from "../../../assets/customIcons";
import {
  isSubscriptedThisVacancy,
  unsubscriptInVacancy,
  isSavedThisVacancy,
  saveAVacancy,
  unsaveAVacancy,
  getSuggestionsOfSimilarVacancies,
  getPlacementVacancyWithCorporateData,
  getPlacementVacancy,
  getCompany,
  createAnalytics,
} from "../../services/functions";
import { getTextDiffDate } from "../../utils";
import {
  placementVacancyManagedBy,
  placementVacancyStatus,
  placementVacancyType,
  placementVacancyTypeOfCandidature,
} from "../../types/enumerators";
import { RootState } from "../../store/reducers";
import GreenhouseLogo from "../../../assets/images/Greenhouse.png";
import GupyLogo from "../../../assets/images/Gupy.png";
import { setLoad } from "../../store/actions/configurationsActions";
import { Info, VacancyExternal } from "../../../assets/icons";
import { ManagedBy } from "../../types/constants/Vacancy";

interface ParamsProps {
  id: string;
}

interface VacancyProps {
  id: string;
  vacancyId: string;
  jobTitle: string;
  companyName: string;
  jobStatus: string;
  jobTime: Date | string;
  jobType: string;
  jobPlace: string;
  jobDescription: string;
  desiredSkills?: Array<{
    key?: string;
    level?: number;
  }>;
  jobTypeOfCandidature: "OPEN" | "CLOSE";
  companySite: string;
  url?: string;
  photo: string;
  companyDescription: string;
  companyId: string;
  managedBy: keyof typeof placementVacancyManagedBy;
  partnerVacancyId: number;
}

const JobDescription = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [vacancySelected, setVacancySelected] = useState<VacancyProps>();
  const [suggestedVacancies, setSuggestedVacancies] =
    useState<VacancyProps[]>();
  const [openModalCandidate, setOpenModalCandidate] = useState(false);
  const [isCandidate, setIsCandidate] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const { id } = useParams<ParamsProps>();
  const user = useSelector((state: RootState) => state.userState);
  const history = useHistory();
  const dispatch = useDispatch();

  const loadCurrentVacancy = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const response = await getPlacementVacancy(id);
    const response2 = await getCompany(response?.companyId ?? "");

    setVacancySelected({
      id,
      vacancyId: response?._id ?? "",
      photo: response2?.profile.logo ?? "",
      companyName:
        response?.managedBy === placementVacancyManagedBy.EXTERNAL_VACANCY
          ? response?.externalCompanyName ?? ""
          : response2?.profile.name ?? "",
      companySite: response2?.profile.site ?? "",
      companyDescription: response2?.profile.description ?? "",
      url: response?.url ?? "",
      jobDescription: response?.description ?? "",
      desiredSkills: response?.desiredSkills,
      jobStatus: response?.status ?? "",
      jobTime: response?.startDate ?? "",
      jobTitle: response?.title ?? "",
      jobType: response?.type ?? "",
      jobPlace: response?.place ?? "",
      jobTypeOfCandidature:
        response?.typeOfCandidature ?? placementVacancyTypeOfCandidature.CLOSE,
      companyId: response?.companyId ?? "",
      managedBy: response?.managedBy ?? placementVacancyManagedBy.REPROGRAMA,
      partnerVacancyId: response?.partnerVacancyId ?? 0,
    });

    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, id]);

  useEffect(() => {
    if (!vacancySelected) loadCurrentVacancy();
  }, [id, loadCurrentVacancy, vacancySelected]);

  const checkCandidateAndSaved = useCallback(async () => {
    const candidate = await isSubscriptedThisVacancy(
      vacancySelected?.vacancyId ?? "",
      user?._id,
    );

    setIsCandidate(candidate);
  }, [user?._id, vacancySelected?.vacancyId]);

  useEffect(() => {
    if (vacancySelected) checkCandidateAndSaved();
  }, [
    vacancySelected?.vacancyId,
    user?._id,
    checkCandidateAndSaved,
    vacancySelected,
  ]);

  const checkSaved = useCallback(async () => {
    const saved = await isSavedThisVacancy(
      vacancySelected?.vacancyId ?? "",
      user?._id,
    );

    setIsSaved(saved);
  }, [vacancySelected?.vacancyId, user?._id]);

  useEffect(() => {
    checkSaved();
  }, [checkSaved, vacancySelected?.vacancyId, user?._id]);

  const getVacancies = useCallback(async () => {
    const responseSuggestedVacancies = await getSuggestionsOfSimilarVacancies(
      vacancySelected?.vacancyId ?? "",
    );

    const listVacancy: any = [];

    if (responseSuggestedVacancies && responseSuggestedVacancies?.length) {
      for (let i = 0; i < responseSuggestedVacancies?.length; i += 1) {
        const t = responseSuggestedVacancies[i]?._id;
        const response = t
          ? await getPlacementVacancyWithCorporateData(t)
          : null;
        if (response) {
          const vacancy: VacancyProps = {
            id: response[0]?._id ?? "",
            vacancyId: response[0]?._id ?? "",
            photo: response[0]?.photo ?? "",
            companyName:
              response[0]?.managedBy ===
              placementVacancyManagedBy.EXTERNAL_VACANCY
                ? response[0]?.externalCompanyName ?? ""
                : response[0]?.companyName ?? "",
            companySite: response[0]?.companyWebsite ?? "",
            companyDescription: response[0]?.companyAbout ?? "",
            jobDescription: response[0]?.description ?? "",
            desiredSkills: response[0]?.desiredSkills,
            jobStatus: response[0]?.status ?? "",
            jobTime: (response[0]?.startDate as string) ?? "",
            jobTitle: response[0]?.title ?? "",
            jobType: response[0]?.type ?? "",
            jobPlace: response[0]?.place ?? "",
            jobTypeOfCandidature: response[0]?.typeOfCandidature ?? "",
            companyId: response[0]?.companyId ?? "",
            managedBy:
              response[0]?.managedBy ?? placementVacancyManagedBy.REPROGRAMA,
            partnerVacancyId:
              (response[0]?.partnerVacancyId as number) ?? undefined,
          };
          listVacancy.push(vacancy);
        }
      }
    }

    setSuggestedVacancies(listVacancy);
  }, [vacancySelected?.vacancyId]);

  useEffect(() => {
    if (!suggestedVacancies) getVacancies();
  }, [getVacancies, suggestedVacancies, vacancySelected]);

  const handleSaveVacancy = useCallback(
    async (e) => {
      e.stopPropagation();
      dispatch(setLoad(true) as unknown as Action);
      try {
        if (!isSaved) {
          await saveAVacancy(vacancySelected?.vacancyId ?? "", user?._id);

          setIsSaved(true);
          toast.success("Vaga salva com sucesso!");
        } else {
          await unsaveAVacancy(vacancySelected?.vacancyId ?? "", user?._id);
          setIsSaved(false);
        }
      } catch (err) {
        toast.error("Ocorreu um erro ao salvar a vaga");
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, isSaved, vacancySelected?.vacancyId, user?._id],
  );

  const handleUncandidateClick = useCallback(async () => {
    try {
      await unsubscriptInVacancy(vacancySelected?.vacancyId ?? "", user?._id);
      if (vacancySelected?.managedBy !== placementVacancyManagedBy.REPROGRAMA) {
        toast.success(
          "Atenção, sua candidatura na Plataforma parceira pode não ter sido cancelada. É necessário verificar esta informação diretamente com a empresa",
        );
      }
      setIsCandidate(false);
    } catch (err) {
      toast.error("Ocorreu um erro ao se candidatar");
    }
  }, [user?._id, vacancySelected?.managedBy, vacancySelected?.vacancyId]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onClick = useCallback(() => {
    if (vacancySelected?.jobStatus === placementVacancyStatus.PENDING)
      toast.error("Ops! Esta vaga ainda não está recebendo candidaturas.");
    else if (!isCandidate) setOpenModalCandidate(true);
    else handleUncandidateClick();
  }, [handleUncandidateClick, isCandidate, vacancySelected?.jobStatus]);

  const labelSkilllevel = useCallback((level) => {
    switch (level) {
      case 1:
        return "básico";
      case 2:
        return "intermediário";
      case 3:
        return "avançado";
      default:
        return "";
    }
  }, []);

  const onClickVacancy = useCallback(
    async () =>
      createAnalytics({
        userId: user?._id,
        type: "PLACEMENT_EXTERNAL_VACANCY",
        entityId: vacancySelected?.vacancyId ?? "",
        date: moment().tz("America/Sao_Paulo").toISOString(),
      }),
    [user?._id, vacancySelected?.vacancyId],
  );

  return (
    <Layout className="grid-template-column-1 margin-mobile-16">
      <HeaderProfile />
      <div>
        <div className="grid-template-columns-1-5 grid-template-columns-1">
          <CardMenuLeft
            icon={<Unblock widthIcon={20} heightIcon={20} />}
            selectedMenuTitle="Candidaturas"
          />
          <div className="header-job ">
            <Card className="card-spacement">
              <div className="width-100 flex ">
                {vacancySelected?.photo ? (
                  <img
                    src={vacancySelected.photo}
                    alt={`${vacancySelected.companyName} Logo`}
                    className="logo-company-vacancy"
                  />
                ) : (
                  <CompanyAvatarEmpty
                    heightIcon={56}
                    widthIcon={56}
                    className="company-avatar-empty-card margin-bottom-16"
                  />
                )}
                <div className="div-status-candidature">
                  {width > 990 &&
                    vacancySelected?.jobTypeOfCandidature ===
                      placementVacancyTypeOfCandidature.OPEN && (
                      <Button
                        text={
                          isCandidate
                            ? "Candidatada"
                            : vacancySelected?.jobStatus ===
                              placementVacancyStatus.PENDING
                            ? "Em breve"
                            : "Candidatar"
                        }
                        className={` max-width-100 min-width-200
                        ${
                          isCandidate
                            ? "button-candidated"
                            : "button-candidate-vacancies"
                        } ${
                          vacancySelected?.jobStatus ===
                          placementVacancyStatus.ACTIVE
                            ? "cursor-pointer"
                            : ""
                        }
                      `}
                        onClick={onClick}
                      />
                    )}
                  <div
                    className="cursor-pointer margin-top-4"
                    role="button"
                    tabIndex={0}
                    onClick={handleSaveVacancy}
                  >
                    {isSaved ? (
                      <BookmarkFilled
                        widthIcon={width < 991 ? 28 : 36}
                        heightIcon={width < 991 ? 28 : 36}
                      />
                    ) : (
                      <Bookmark
                        filled={isSaved}
                        widthIcon={width < 991 ? 28 : 36}
                        heightIcon={width < 991 ? 28 : 36}
                      />
                    )}
                  </div>
                </div>
              </div>
              <h1 className="job-title margin-top-16">
                {vacancySelected?.jobTitle}
              </h1>
              <h2 className="company-name"> {vacancySelected?.companyName} </h2>
              <p className="date-time">
                {moment(vacancySelected?.jobTime).isValid() &&
                  getTextDiffDate(vacancySelected?.jobTime ?? "")}
              </p>
              <div className="flex space-between align-end">
                <div className="location-div-candidature">
                  <Localization
                    widthIcon={width < 991 ? 20 : 24}
                    heightIcon={width < 991 ? 20 : 24}
                  />
                  <span className="city-level">
                    {vacancySelected?.jobType === placementVacancyType.Remota
                      ? vacancySelected?.jobType
                      : vacancySelected?.jobType ===
                        placementVacancyType.Híbrida
                      ? `${vacancySelected?.jobType} - ${vacancySelected?.jobPlace}`
                      : vacancySelected?.jobPlace}
                  </span>
                </div>
                {vacancySelected?.managedBy !==
                  placementVacancyManagedBy.REPROGRAMA && (
                  <div className="flex align-center">
                    {vacancySelected?.managedBy !==
                      placementVacancyManagedBy.EXTERNAL_VACANCY && (
                      <p className="title-view">Administrado por:</p>
                    )}
                    {vacancySelected?.managedBy ===
                      placementVacancyManagedBy.GREENHOUSE && (
                      <img
                        alt="logo"
                        src={GreenhouseLogo}
                        className="greenhouse-logo-job"
                      />
                    )}
                    {vacancySelected?.managedBy ===
                      placementVacancyManagedBy.GUPY && (
                      <img
                        alt="logo"
                        src={GupyLogo}
                        className="gupy-logo-job"
                      />
                    )}
                    <p className="view-bold margin-left-8">
                      {
                        ManagedBy[
                          vacancySelected?.managedBy ??
                            placementVacancyManagedBy.REPROGRAMA
                        ]
                      }
                    </p>
                    {vacancySelected?.managedBy ===
                      placementVacancyManagedBy.EXTERNAL_VACANCY && (
                      <Tooltip
                        title={
                          <p>
                            Nenhuma etapa do processo seletivo desta vaga irá
                            ocorrer pela nossa plataforma. É a divulgação de uma
                            oportunidade que consideramos interessante para
                            você.
                          </p>
                        }
                      >
                        <div>
                          <Info className="icon-info cursor-pointer" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
              {width < 991 &&
                vacancySelected?.jobTypeOfCandidature ===
                  placementVacancyTypeOfCandidature.OPEN && (
                  <Button
                    text={
                      isCandidate
                        ? "Candidatada"
                        : vacancySelected?.jobStatus ===
                          placementVacancyStatus.PENDING
                        ? "Em breve"
                        : "Candidatar"
                    }
                    className={`max-width-100 min-width-200 margin-top-16-mobile
                        ${
                          isCandidate
                            ? "button-candidated"
                            : "button-candidate-vacancies"
                        } ${
                      vacancySelected?.jobStatus ===
                      placementVacancyStatus.ACTIVE
                        ? "pointer"
                        : ""
                    }
                      `}
                    onClick={onClick}
                  />
                )}
            </Card>
            <Card
              titleHeader="Sobre a vaga"
              className="card-spacement padding-0"
              removeTitleBorder
            >
              {vacancySelected?.desiredSkills &&
                vacancySelected?.desiredSkills.length > 0 && (
                  <div className="margin-left-skill">
                    <h3 className="title-accordion margin-left-skill">
                      Habilidades
                    </h3>
                    <div className="candidature-skill-vacancy-container margin-left-skill">
                      {vacancySelected.desiredSkills.map((skill) => (
                        <div
                          key={Math.random()}
                          className="candidature-skill-vacancy "
                        >
                          {`${skill?.key} (`}
                          <span className="italic-text">
                            {labelSkilllevel(skill?.level)}
                          </span>
                          )
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              {vacancySelected?.jobDescription ? (
                <p className="p-description-vacancies margin-top-0">
                  {vacancySelected?.jobDescription}
                </p>
              ) : (
                ""
              )}

              {vacancySelected?.managedBy ===
                placementVacancyManagedBy.EXTERNAL_VACANCY && (
                <>
                  <div className="vacancy-model">
                    <h4>Modelo de trabalho:</h4>
                    <p>{vacancySelected?.jobType}</p>
                  </div>

                  <div className="flex align-items">
                    <a
                      href={
                        vacancySelected?.url?.includes("http")
                          ? vacancySelected?.url
                          : `http://${vacancySelected?.url}`
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="about-company-external"
                      onClick={onClickVacancy}
                    >
                      Ver vaga
                    </a>
                    <VacancyExternal />
                  </div>
                </>
              )}
            </Card>
            {vacancySelected?.managedBy !==
              placementVacancyManagedBy.EXTERNAL_VACANCY && (
              <Card
                titleHeader="Sobre a empresa"
                className="card-spacement"
                bodyClassName="padding-0 width-100-responsive"
                removeTitleBorder
              >
                <div className="div-photo-area-vacancy">
                  <div className="display-inline width-100 margin-mobile-company">
                    {vacancySelected?.photo ? (
                      <img
                        src={vacancySelected?.photo}
                        alt="Logo"
                        className="logo-company-vacancy"
                      />
                    ) : (
                      <CompanyAvatarEmpty
                        widthIcon={60}
                        heightIcon={60}
                        className="company-avatar-empty-card "
                      />
                    )}
                    <div className="about-company-vacancy">
                      <h2
                        className="company-name-h2"
                        onClick={() =>
                          history.push(
                            `/company-profile/${vacancySelected?.companyId}`,
                          )
                        }
                      >
                        {vacancySelected?.companyName}
                      </h2>
                      <a
                        className="company-website"
                        href={
                          vacancySelected?.companySite?.includes("http")
                            ? vacancySelected?.companySite
                            : `http://${vacancySelected?.companySite}`
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {vacancySelected?.companySite}
                      </a>
                    </div>
                  </div>
                  <p className="p-job">{vacancySelected?.companyDescription}</p>
                </div>
                <div className="line-divider-midia margin-top-10" />
                <div className="more-about-company more-about-company--padding">
                  <button
                    type="button"
                    className="more-about-button"
                    onClick={() =>
                      history.push(
                        `/company-profile/${vacancySelected?.companyId}`,
                      )
                    }
                  >
                    VER MAIS SOBRE A EMPRESA
                  </button>
                </div>
              </Card>
            )}

            <div className="divider"> </div>
            <h1 className="similar-title"> Vagas similares </h1>
            <div className="margin-bottom-32">
              <Card bodyClassName="padding-0">
                <div className="grid-suggested-vacancies ">
                  {suggestedVacancies && suggestedVacancies?.length > 1 ? (
                    suggestedVacancies?.map((item) => <CardJob data={item} />)
                  ) : (
                    <p className="paragraph-not-vacancies">
                      Não há sugestões de vagas no momento
                    </p>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {vacancySelected && (
        <ModalConfirmCandidate
          user={user}
          vacancy={vacancySelected}
          setIsCandidate={setIsCandidate}
          onClose={() => setOpenModalCandidate(false)}
          open={openModalCandidate}
        />
      )}
    </Layout>
  );
};

export default memo(JobDescription);
