/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { toast } from "react-toastify";
import { Input, InputPassword, Button, Modal } from "../../../components";
import { resetPassword } from "../../../services/functions";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { RootState } from "../../../store/reducers";
import { updateUser } from "../../../store/actions/userActions";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const EditAccount = ({ setIsValid }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [_user, _setUser] = useState(user);
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    if (user?.profile?.name && !_user?.profile?.name) _setUser({ ...user });
  }, [_user, user]);

  const handleOnBlur = useCallback(() => {
    if (_user?.login?.identification) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(updateUser({ ..._user }) as unknown as Action),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [_user, dispatch, setIsValid]);

  const handleChangeEmail = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      _setUser({
        ..._user,
        login: {
          ..._user.login,
          identification: event.target.value.toLowerCase().trim(),
        },
      }),
    [_user],
  );

  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      if (password === confirmPassword) {
        setIsChangePasswordOpen(false);
        const response = await resetPassword(password);

        if (response) {
          toast.success("Senha alterada com sucesso");
          setPassword("");
          setConfirmPassword("");
        }
      } else toast.error("As senhas digitadas não coincidem");
    } catch (err) {
      toast.error("Ocorreu um erro ao alterar a senha");
    }
  }, [confirmPassword, password]);

  return (
    <div className="grid-1-column flex-row-center">
      <Input
        id="email"
        label="E-mail"
        type="text"
        onChange={handleChangeEmail}
        value={_user?.login?.identification}
        onBlur={handleOnBlur}
        className="width-190 width-100-responsive"
      />
      <a
        className="account-change-password-button"
        onClick={() => setIsChangePasswordOpen(true)}
      >
        Alterar senha
      </a>
      <Modal
        open={isChangePasswordOpen}
        onClose={() => setIsChangePasswordOpen(false)}
        textHeader="Alterar senha"
        bodyClassName="flex-col align-items account-change-password"
      >
        <h1 className="width-100 text-center">Alterar senha</h1>
        <div className="flex-col align-items">
          <InputPassword
            label="Senha"
            helperText="Digite a nova senha"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onBlur={handleOnBlur}
            className="width-300 width-100-responsive"
          />
          <InputPassword
            label="Senha"
            helperText="Digite novamente a nova senha"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            className="width-300 margin-top-14 width-100-responsive"
          />
        </div>
        <div className="margin-top-48 flex justify-center">
          <Button
            text="Cancelar"
            className="account-button-change margin-left width-100-responsive margin-0-mobile"
            onClick={() => setIsChangePasswordOpen(false)}
            outline
            appearance="secondary"
          />
          <Button
            text="Alterar"
            className="account-button-change margin-left width-100-responsive"
            onClick={handleSubmit}
          />
        </div>
      </Modal>
    </div>
  );
};

export default memo(EditAccount);
