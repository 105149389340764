import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Phone = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#939393"
      stroke="#939393"
      strokeWidth=".1"
      d="M16.286 1.95H7.714c-.975 0-1.764.84-1.764 1.868v16.364c0 1.027.789 1.868 1.764 1.868h8.572c.976 0 1.764-.84 1.764-1.868V3.818c0-1.027-.788-1.868-1.764-1.868zM7.764 20.132V3.868h8.472l.001 16.264H7.764zM12 18.322c.504 0 .907-.431.907-.958 0-.527-.403-.96-.907-.96s-.907.433-.907.96c0 .526.403.959.907.959z"
    />
  </svg>
);

export default Phone;
