/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import {
  ClickAwayListener,
  Container,
  Grow,
  IconButton,
  InputAdornment,
  ListItem,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SwipeableDrawer,
  TextField,
  Toolbar,
} from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { MenuHeader, RecentSearch } from "../index";
import {
  ChevronDown,
  Search,
  NotificationsIcon,
  NotificationsNew,
  MenuMobile,
} from "../../../assets/icons";
import "./styles.scss";
import {
  AvatarEmpty,
  Close,
  CompanyAvatarEmpty,
  LogoBlack,
  Help,
} from "../../../assets/customIcons/index";
import { useAuth } from "../../auth";
import {
  searchCompany,
  searchPlacementVacancy,
  searchUsers,
} from "../../services/functions";
import Notifications from "../Notifications";
import { RootState } from "../../store/reducers";
import {
  hasAdminClassBootcampRole,
  hasAdminClassRole,
  hasAdminClassVacancyRole,
  hasAdminClassVacancyVolunteerMediaRole,
  hasAdminClassWorkshopRole,
  hasAdminRole,
  isAdmin as isAdminUtils,
  isCorporate as isCorporateUtils,
  isStudent as isStudentUtils,
} from "../../utils";
import {
  Company,
  User,
  PlacementVacancyWithCorporateData,
} from "../../types/interfaces";

const HeaderProfile = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const [photoMenuMobile, setPhotoMenuMobile] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isSearchAreaOpen, setIsSearchAreaOpen] = useState(false);
  const [isHelp, setIsHelp] = useState(false);
  const [isRecentSearch, setIsRecentSearch] = useState(true);
  const [usersSearch, setUsersSearch] = useState<User[]>([]);
  const [companySearch, setCompanySearch] = useState<Company[]>([]);
  const [management, setManagement] = useState(false);
  const [admin, setAdmin] = useState(false);

  const [vacancySearch, setVacancySearch] = useState<
    PlacementVacancyWithCorporateData[]
  >([]);
  const [currentSearch, setCurrentSearch] = useState<string>("");
  const [recent, setRecent] = useState(
    JSON.parse(localStorage.getItem("@SESSION:recents") ?? "[]") ?? [],
  );

  const history = useHistory();
  const { signOut } = useAuth();

  const { id: profileId } = useParams<{ id: string }>();
  const user = useSelector((state: RootState) => state.userState);
  const notificationsState = useSelector(
    (state: RootState) => state.notificationsState,
  );

  const isStudent = isStudentUtils(user);
  const isCorporate = isCorporateUtils(user);
  const isAdmin = isAdminUtils(user) && hasAdminRole(user);
  const isAdminClass = isAdminUtils(user) && hasAdminClassRole(user);
  const isAdminClassVacancy =
    isAdminUtils(user) && hasAdminClassVacancyRole(user);
  const isAdminWorkshopOrBootcamp =
    isAdminUtils(user) &&
    (hasAdminClassWorkshopRole(user) || hasAdminClassBootcampRole(user));
  const isVolunteerMedia =
    isAdminUtils(user) && hasAdminClassVacancyVolunteerMediaRole(user);

  const accountRole = useMemo(() => {
    if (isStudent) return "Aluna";

    if (isCorporate) return "Empresa";

    if (isAdmin || isAdminClass || isAdminClassVacancy) return "Admin";

    if (isAdminWorkshopOrBootcamp) return "Ensino";

    if (isVolunteerMedia) return "Voluntária";

    return "";
  }, [
    isAdmin,
    isAdminClass,
    isAdminClassVacancy,
    isAdminWorkshopOrBootcamp,
    isCorporate,
    isStudent,
    isVolunteerMedia,
  ]);

  const onClickAccount = () =>
    isStudent
      ? history.push("/account")
      : isCorporate
      ? history.push("/company-account")
      : history.push("/admin-account");

  const cleanRecentSearch = useCallback(() => {
    toast.success("Histórico de pesquisa limpo com sucesso");
    localStorage.removeItem("@SESSION:recents");
    setRecent([]);
  }, []);

  const handleClickSearch = useCallback(
    (
      id?: string,
      name?: string,
      photo?: string,
      typeCard?: string,
      vacancy?: any,
    ) => {
      window.document.getElementById("recentSearch").value = "";

      const data = {
        id,
        name,
        photo,
        type: typeCard,
        vacancy,
      };

      if (recent == null) setRecent([data]);
      else {
        let merge: any[] = [];

        if (
          recent &&
          recent.filter((props: { id: string }) => props.id === data.id)
            .length > 0
        )
          merge = recent;
        else if (recent.length > 3) {
          const removed = recent.slice(1, 4);
          merge = [...removed, data];
        } else merge = [...recent, data];

        setRecent(merge);

        localStorage.setItem("@SESSION:recents", JSON.stringify(merge));
      }

      setIsRecentSearch(true);
      setIsSearchAreaOpen(false);

      switch (typeCard) {
        case "Pessoas":
          history.push(`/profile/${id}`);
          break;
        case "Vagas":
          history.push({
            pathname: "/vacancies-advanced/",
            state: {
              vacancy,
            },
          });
          break;
        case "Empresas":
          history.push(`/company-profile/${id}`);
          break;

        default:
          break;
      }
    },
    [history, recent],
  );

  const handleToggle = useCallback((event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenMenu((prev) => !prev);
  }, []);

  const onCloseSearch = useCallback(() => {
    setIsSearchAreaOpen(false);
  }, []);

  const handleClose = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const handleListKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }, []);

  const handleInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      setIsSearchAreaOpen(true);
      try {
        if (event.target.value === "") {
          setIsRecentSearch(true);
        } else {
          setIsRecentSearch(false);
        }
        setCurrentSearch(event.target.value);
        const users = (await searchUsers(event.target.value, 3)) ?? [];
        const companies = (await searchCompany(event.target.value, 3)) ?? [];
        const vacancies =
          (await searchPlacementVacancy(event.target.value, 3)) ?? [];
        setCompanySearch(companies);
        setUsersSearch(users);
        setVacancySearch(vacancies);
      } catch (err) {
        toast.error("Ocorreu um erro ao fazer a busca");
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const company = isCorporate
    ? useSelector((state: RootState) => state.companyState)
    : {};

  const listMenu = useMemo(
    () => [
      {
        selected:
          (isStudent &&
            history.location.pathname.includes("/profile") &&
            user?._id === profileId) ||
          (isCorporate &&
            history.location.pathname.includes("/company-profile")),
        redirectTo: "/",
        text: "Perfil",
        renderCondition: isStudent || isCorporate,
      },
      {
        selected:
          history.location.pathname.includes("vacanc") ||
          history.location.pathname.includes("/candidature") ||
          history.location.pathname.includes("/proposals") ||
          history.location.pathname.includes("/saved-jobs") ||
          history.location.pathname.includes("/job/") ||
          history.location.pathname.includes("/invites"),
        redirectTo: isCorporate ? "/company-vacancies" : "/vacancies",
        text: "Vagas",
        renderCondition: isStudent || isCorporate,
      },
      {
        selected:
          history.location.pathname.includes("/admin-class") ||
          history.location.pathname.includes("/admin-recap"),
        text: "Ensino",
        renderCondition:
          isAdmin ||
          isAdminClass ||
          isAdminClassVacancy ||
          isAdminWorkshopOrBootcamp ||
          isVolunteerMedia,
        onClick: () => setAdmin(true),
      },
      {
        selected:
          history.location.pathname.includes("/admin-company") ||
          history.location.pathname.includes("/admin-placement-vacancy") ||
          history.location.pathname.includes("/admin-autocomplete") ||
          history.location.pathname.includes("/admin-user") ||
          history.location.pathname.includes("/admin-student"),
        text: "Gestão",
        renderCondition: isAdmin,
        onClick: () => {
          setManagement(true);
        },
      },
    ],
    [
      history.location.pathname,
      isAdmin,
      isAdminClass,
      isAdminClassVacancy,
      isAdminWorkshopOrBootcamp,
      isCorporate,
      isStudent,
      isVolunteerMedia,
      profileId,
      user?._id,
    ],
  );

  return (
    <div className="header-profile">
      <div className="flex header-profile-content">
        {width < 991 && (
          <Container maxWidth="md">
            <Toolbar disableGutters>
              <IconButton>
                <MenuMobile onClick={() => setOpenMenuMobile(true)} />
              </IconButton>
            </Toolbar>
          </Container>
        )}
        {/* MOBILE-MENU-LEFT */}
        <SwipeableDrawer
          anchor="left"
          open={openMenuMobile}
          onOpen={() => setOpenMenuMobile(true)}
          onClose={() => setOpenMenuMobile(false)}
          className="border-muipaper cardMenuMobilerLeft mobile-listItem-gutters"
        >
          <div className="div-logo">
            <button
              type="button"
              aria-label="home"
              className="header-profile-link"
              onClick={() => history.push("/")}
            >
              <LogoBlack widthIcon={158} heightIcon={24} />
            </button>
            <button
              aria-label="close"
              type="button"
              className="close-mobile"
              onClick={() => setOpenMenuMobile(false)}
            >
              <Close widthIcon={20} heightIcon={20} />
            </button>
          </div>
          <ListItem>
            <ul className="list-menu-header-mobile">
              {listMenu
                ?.filter?.((item) => item?.renderCondition)
                ?.map((innerItem) => (
                  <div
                    key={Math.random()}
                    className="hover-mobile cursor-pointer"
                  >
                    <li className="list-mobile">
                      <button
                        type="button"
                        className={`
                    header-profile-link
                  list-mobile ${innerItem?.selected ? "is-selected" : ""}
             `}
                        onClick={() =>
                          history.push(innerItem?.redirectTo ?? "")
                        }
                      >
                        {innerItem?.text}
                      </button>
                    </li>
                  </div>
                ))}
              <div className="hover-mobile cursor-pointer">
                <div className="list-mobile">
                  <p
                    onClick={() => {
                      setIsHelp(!isHelp);
                      setOpenMenuMobile(false);
                    }}
                    className="cursor-pointer list-mobile"
                    id="fecha"
                  >
                    Ajuda
                  </p>
                </div>
              </div>
              <div className="hover-mobile cursor-pointer">
                <button type="button" className="button-selected ">
                  {notificationsState.find(
                    (notification: { hasRead: boolean }) =>
                      notification.hasRead === false,
                  ) ? (
                    <p
                      className="list-mobile"
                      onClick={() => {
                        setOpenNotifications(!openNotifications);
                        setOpenMenuMobile(false);
                      }}
                    >
                      Notificações
                    </p>
                  ) : (
                    <p
                      className="list-mobile"
                      onClick={() => {
                        setOpenNotifications(!openNotifications);
                        setOpenMenuMobile(false);
                      }}
                    >
                      Notificações
                    </p>
                  )}
                </button>
              </div>
            </ul>
          </ListItem>
        </SwipeableDrawer>
        {width < 991 ? (
          <div
            className={` ${isHelp ? "header-profile-line-help" : ""} && ${
              openNotifications ? "header-profile-line-notification" : ""
            } `}
          >
            {isHelp && (
              <ClickAwayListener onClickAway={() => setIsHelp(false)}>
                <Paper>
                  <MenuHeader onClose={() => setIsHelp(false)} />
                </Paper>
              </ClickAwayListener>
            )}
            {openNotifications && (
              <ClickAwayListener
                onClickAway={() => setOpenNotifications(false)}
              >
                <Paper>
                  <Notifications onClose={() => setOpenNotifications(false)} />
                </Paper>
              </ClickAwayListener>
            )}
          </div>
        ) : (
          ""
        )}

        <div className="sub-div-container-header-profile">
          {width > 990 ? (
            <button
              aria-label="back"
              type="button"
              className="header-profile-link"
              onClick={() => history.push("/")}
            >
              <LogoBlack />
            </button>
          ) : (
            ""
          )}
          {(isStudent || isCorporate) && (
            <TextField
              autoComplete="off"
              id="recentSearch"
              onClick={() => setIsSearchAreaOpen(!isSearchAreaOpen)}
              placeholder="Buscar na {reprograma}"
              type="text"
              InputProps={{
                className:
                  "header-profile-search-profile Mui-input headerSearch header-input-search",
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              className={`search-header header-profile-search-area margin-0-mobile ${
                width > 990 && "margin-left-40"
              } ${isSearchAreaOpen ? "is-open" : "is-close"}`}
              onChange={handleInputChange}
            />
          )}
          {isSearchAreaOpen && (
            <Paper>
              <ClickAwayListener onClickAway={onCloseSearch}>
                <div className="header-profile-search">
                  {isRecentSearch ? (
                    <>
                      <div className="header-profile-search-header">
                        <p>Buscas Recentes</p>
                        {recent.length > 0 && (
                          <span
                            aria-label="clear"
                            role="button"
                            tabIndex={0}
                            onClick={cleanRecentSearch}
                            className="cursor-pointer"
                          >
                            Limpar
                          </span>
                        )}
                      </div>
                      {recent.length === 0 && !isCorporate && (
                        <p className="search-recent-any">
                          Você não possui nenhuma busca recente.
                        </p>
                      )}
                      <div className="header-profile-cookies">
                        {recent?.map((r: any) => (
                          <div
                            key={Math.random()}
                            className="cursor-pointer"
                            role="none"
                            onClick={() =>
                              handleClickSearch(
                                r.id ?? "",
                                r.name ?? "",
                                r.photo ?? "",
                                r.type ?? "",
                              )
                            }
                          >
                            {r?.photo ? (
                              <img
                                src={r?.photo}
                                alt="imagem"
                                className="img-photo-header-profile"
                              />
                            ) : r.type === "Pessoas" ? (
                              <AvatarEmpty
                                heightIcon={40}
                                widthIcon={40}
                                className="avatar-header-profile"
                              />
                            ) : (
                              <CompanyAvatarEmpty
                                heightIcon={40}
                                widthIcon={40}
                                className="avatar-header-profile"
                              />
                            )}
                            <p className="header-profile-cookies-name">
                              {r.name}{" "}
                            </p>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : loading ? (
                    <div className="circular-progress padding-top-24">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div>
                      {usersSearch?.map((userItem) => (
                        <div
                          key={Math.random()}
                          className="cursor-pointer"
                          role="none"
                          onClick={() =>
                            handleClickSearch(
                              userItem?._id,
                              userItem?.profile?.name,
                              userItem?.profile?.photo,
                              "Pessoas",
                            )
                          }
                        >
                          <RecentSearch
                            itemName={userItem?.profile?.name}
                            itemCategory="Pessoas"
                          />
                        </div>
                      ))}
                      {isCorporate
                        ? null
                        : vacancySearch?.map((vacancy) => (
                            <div
                              key={Math.random()}
                              className="cursor-pointer"
                              role="none"
                              onClick={() =>
                                handleClickSearch(
                                  vacancy?._id,
                                  vacancy?.title,
                                  vacancy?.photo ?? "",
                                  "Vagas",
                                  vacancy,
                                )
                              }
                            >
                              <RecentSearch
                                itemName={vacancy?.title}
                                itemCategory="Vagas"
                              />
                            </div>
                          ))}

                      {isCorporate
                        ? null
                        : companySearch?.map((itemCompany) => (
                            <div
                              key={Math.random()}
                              className="cursor-pointer"
                              role="none"
                              onClick={() =>
                                handleClickSearch(
                                  itemCompany?._id,
                                  itemCompany?.profile?.name,
                                  itemCompany?.profile?.logo ?? "",
                                  "Empresas",
                                )
                              }
                            >
                              <RecentSearch
                                itemName={itemCompany?.profile?.name}
                                itemCategory="Empresas"
                              />
                            </div>
                          ))}
                      {/* <div className="line-divider-midia margin-top-10" /> */}

                      {usersSearch?.length === 0 &&
                      vacancySearch?.length === 0 &&
                      companySearch?.length === 0 ? (
                        <p className="search-not-found">
                          Nenhum resultado encontrado
                        </p>
                      ) : (
                        <div>
                          {isCorporate ? (
                            <>
                              <div className="line-divider-midia margin-top-10" />
                              <div className="more-about-company">
                                <button
                                  type="button"
                                  className="more-about-button dark-purple uppercase"
                                  onClick={() => {
                                    history.push(
                                      `/company-search?params=${currentSearch}`,
                                    );
                                    setIsSearchAreaOpen(false);
                                  }}
                                >
                                  Ver todos os resultados
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="line-divider-midia margin-top-10" />
                              <div className="more-about-company">
                                <button
                                  type="button"
                                  className="more-about-button dark-purple uppercase"
                                  onClick={() => {
                                    history.push({
                                      pathname: "/search",
                                      search: `params=${currentSearch}`,
                                      state: { typeSearch: "all" },
                                    });
                                    setIsSearchAreaOpen(false);
                                  }}
                                >
                                  Ver todos os resultados
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {isCorporate && !currentSearch ? (
                    <div className="more-about-company div-search-header-profile">
                      <button
                        type="button"
                        className="more-about-button more-about-company button-search-header-profile"
                        onClick={() => {
                          history.push("/company-search");
                          setIsSearchAreaOpen(false);
                        }}
                      >
                        Buscar todas
                      </button>
                    </div>
                  ) : null}
                </div>
              </ClickAwayListener>
            </Paper>
          )}
        </div>
      </div>
      {width > 990 ? (
        <div className="header-profile-menu">
          <div className="header-profile-menu-list">
            {listMenu
              ?.filter?.((item) => item?.renderCondition)
              ?.map((innerItem) => (
                <button
                  key={Math.random()}
                  type="button"
                  className={`
                    header-profile-link ${
                      innerItem?.selected ? "is-selected" : ""
                    }
             `}
                  onClick={() => {
                    if (innerItem.onClick) {
                      innerItem.onClick();
                    } else {
                      history.push(innerItem.redirectTo);
                    }
                  }}
                >
                  {innerItem?.text}
                </button>
              ))}
            {management && (
              <div className="management-menu-position line-menuLis">
                <ClickAwayListener onClickAway={() => setManagement(false)}>
                  <Paper>
                    <MenuList autoFocusItem={management}>
                      <MenuItem onClick={() => history.push("/admin-company")}>
                        Empresas
                      </MenuItem>
                      <MenuItem onClick={() => history.push("/admin-user")}>
                        Usuárias
                      </MenuItem>
                      <MenuItem onClick={() => history.push("/admin-student")}>
                        Alunas
                      </MenuItem>
                      <MenuItem
                        onClick={() => history.push("/admin-placement-vacancy")}
                      >
                        Vagas
                      </MenuItem>
                      <MenuItem
                        onClick={() => history.push("/admin-autocomplete")}
                      >
                        Geral
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </ClickAwayListener>
              </div>
            )}
            {admin && (
              <div className="teaching-menu-position line-menuLis education">
                <ClickAwayListener onClickAway={() => setAdmin(false)}>
                  <Paper>
                    <MenuList autoFocusItem={admin}>
                      {(isAdmin ||
                        isAdminClass ||
                        isAdminClassVacancy ||
                        isAdminWorkshopOrBootcamp) && (
                        <MenuItem onClick={() => history.push("/admin-class")}>
                          Turmas
                        </MenuItem>
                      )}
                      {(isAdmin || isAdminClassVacancy || isVolunteerMedia) && (
                        <MenuItem
                          onClick={() => history.push("/admin-class-vacancy")}
                        >
                          Processos
                        </MenuItem>
                      )}
                      {isAdmin && (
                        <MenuItem onClick={() => history.push("/admin-recap")}>
                          Repescagem
                        </MenuItem>
                      )}
                    </MenuList>
                  </Paper>
                </ClickAwayListener>
              </div>
            )}
          </div>
          <div
            className={`screen-icon icons-menu-area-header ${
              isHelp ? "header-profile-line-help" : ""
            } && ${
              openNotifications ? "header-profile-line-notification" : ""
            } `}
          >
            <Help
              onClick={() => setIsHelp(!isHelp)}
              className="cursor-pointer"
            />

            {isHelp && (
              <ClickAwayListener onClickAway={() => setIsHelp(false)}>
                <Paper>
                  <MenuHeader onClose={() => setIsHelp(false)} />
                </Paper>
              </ClickAwayListener>
            )}

            <button
              type="button"
              className={`
              button-selected
              ${
                history.location.pathname.includes("/notification")
                  ? "line-notifi-purple"
                  : ""
              }
            `}
            >
              {notificationsState.find(
                (notification: { hasRead: boolean }) =>
                  notification.hasRead === false,
              ) ? (
                <NotificationsNew
                  onClick={() => setOpenNotifications(!openNotifications)}
                />
              ) : (
                <NotificationsIcon
                  onClick={() => setOpenNotifications(!openNotifications)}
                />
              )}
            </button>
            {openNotifications && (
              <ClickAwayListener
                onClickAway={() => setOpenNotifications(false)}
              >
                <Paper>
                  <Notifications onClose={() => setOpenNotifications(false)} />
                </Paper>
              </ClickAwayListener>
            )}
          </div>
          <button
            type="button"
            className={`
            button-selected
            ${
              openMenu ||
              history.location.pathname.includes("/account") ||
              history.location.pathname.includes("/company-account")
                ? "line-menu-purple-avatar"
                : ""
            }
          `}
            onClick={handleToggle}
          >
            <Popper
              open={openMenu}
              anchorEl={anchorEl}
              transition
              disablePortal
              className="header-profile-menu-profile"
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Paper>
                      <MenuList
                        autoFocusItem={openMenu}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem key={Math.random()} onClick={onClickAccount}>
                          Minha conta
                        </MenuItem>
                        <MenuItem key={Math.random()} onClick={signOut}>
                          Sair
                        </MenuItem>
                      </MenuList>
                    </Paper>
                  </ClickAwayListener>
                </Grow>
              )}
            </Popper>
            <div className="line-vertical div-avatar-header-profile">
              {(isCorporate && company?.profile?.logo) ||
              user?.profile?.photo ? (
                <img
                  src={
                    isCorporate ? company?.profile?.logo : user?.profile?.photo
                  }
                  alt="imagem"
                  className="img-avatar-header-profile"
                />
              ) : !isCorporate ? (
                <AvatarEmpty
                  className="border-avatar-empty img-attachment-photo-small"
                  heightIcon={40}
                  widthIcon={40}
                />
              ) : (
                <CompanyAvatarEmpty
                  className="border-avatar-empty img-attachment-photo-small"
                  heightIcon={40}
                  widthIcon={40}
                />
              )}
              <div>
                <h2 className="h2-text-header-profile">
                  {isCorporate ? company?.profile?.name : user?.profile?.name}
                </h2>
                <p className="p-text-header-profile">{accountRole}</p>
              </div>
              <ChevronDown
                className={`chervron-down ${
                  openMenu ? "open-menu-header" : "close-menu-header"
                } `}
              />
            </div>
          </button>
        </div>
      ) : (
        ""
      )}

      {/* MOBILE-PHOTO */}
      {width < 991 ? (
        <div className="photo-header-mobile">
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <IconButton>
                {(isCorporate && company?.profile?.logo) ||
                user?.profile?.photo ? (
                  <img
                    src={
                      isCorporate
                        ? company?.profile?.logo
                        : user?.profile?.photo
                    }
                    alt="imagem"
                    className="img-avatar-header-profile"
                    onClick={() => setPhotoMenuMobile(true)}
                  />
                ) : !isCorporate ? (
                  <AvatarEmpty
                    className="border-avatar-empty img-attachment-photo-small"
                    heightIcon={32}
                    widthIcon={32}
                    onClick={() => setPhotoMenuMobile(true)}
                  />
                ) : (
                  <CompanyAvatarEmpty
                    className="border-avatar-empty img-attachment-photo-small"
                    heightIcon={32}
                    widthIcon={32}
                    onClick={() => setPhotoMenuMobile(true)}
                  />
                )}
              </IconButton>
            </Toolbar>
          </Container>
          {/* MOBILE-MENU-RIGTH */}
          <SwipeableDrawer
            anchor="right"
            open={photoMenuMobile}
            onOpen={() => setPhotoMenuMobile(true)}
            onClose={() => setPhotoMenuMobile(false)}
            className="header-profile-mobile border-muipaper"
          >
            <div className="header-profile-mobile-top">
              <div className="flex">
                {(isCorporate && company?.profile?.logo) ||
                user?.profile?.photo ? (
                  <img
                    src={
                      isCorporate
                        ? company?.profile?.logo
                        : user?.profile?.photo
                    }
                    alt="imagem"
                    className="menu-rigth-mobile"
                  />
                ) : !isCorporate ? (
                  <AvatarEmpty
                    className="border-avatar-empty img-attachment-photo-small"
                    heightIcon={32}
                    widthIcon={32}
                  />
                ) : (
                  <CompanyAvatarEmpty
                    className="border-avatar-empty img-attachment-photo-small"
                    heightIcon={32}
                    widthIcon={32}
                  />
                )}
                <div className="margin-top-3-mobile">
                  <h2 className="h2-text-header-profile">
                    {isCorporate ? company?.profile?.name : user?.profile?.name}
                  </h2>
                  <p className="p-text-header-profile">{accountRole}</p>
                </div>
              </div>
              <button
                type="button"
                aria-label="close"
                onClick={() => setPhotoMenuMobile(false)}
                className="margin-top-7-mobile close-mobile"
              >
                <Close widthIcon={20} heightIcon={20} />
              </button>
            </div>
            <div className="divider-mobile" />
            <div className="hover-mobile cursor-pointer mobile-listItem-gutters">
              <MenuItem
                key={Math.random()}
                className="listMobilerLeft color-dark "
                onClick={onClickAccount}
              >
                Minha conta
              </MenuItem>
            </div>
            <div className="hover-mobile cursor-pointer mobile-listItem-gutters">
              <MenuItem
                key={Math.random()}
                className="listMobilerLeft color-dark"
                onClick={signOut}
              >
                Sair
              </MenuItem>
            </div>
          </SwipeableDrawer>
          <button
            type="button"
            aria-label="account"
            className={`
            button-selected
            ${
              openMenu ||
              history.location.pathname.includes("/account") ||
              history.location.pathname.includes("/company-account")
                ? "line-menu-purple-avatar"
                : ""
            }
          `}
            onClick={handleToggle}
          >
            <div className="line-vertical div-avatar-header-profile" />
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default memo(HeaderProfile);
