import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Gender = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#2E3451"
      stroke="#2E3451"
      strokeWidth=".2"
      d="M23.581 2.333h-4.106c-.378 0-.685.306-.685.684 0 .377.307.684.685.684h2.454l-3.826 3.82C16.945 6.694 15.528 6.207 14 6.207s-2.946.487-4.104 1.314l-.86-.858 1.113-1.112c.268-.267.268-.7 0-.967-.267-.267-.7-.267-.968 0L8.067 5.696 6.07 3.701h2.455c.378 0 .684-.307.684-.684 0-.378-.306-.684-.684-.684H4.417c-.378 0-.685.306-.685.684v4.102c0 .377.307.683.685.683.378 0 .684-.306.684-.683V4.667L7.1 6.663 5.986 7.775c-.267.266-.267.7 0 .966.134.134.309.2.484.2s.35-.066.484-.2L8.067 7.63l.794.792c-1.199 1.266-1.935 2.973-1.935 4.849 0 3.664 2.809 6.685 6.389 7.03v1.72H11.26c-.378 0-.684.306-.684.683 0 .378.306.684.684.684h2.054v1.595c0 .378.306.684.684.684.378 0 .684-.306.684-.684v-1.595h2.054c.378 0 .684-.306.684-.684 0-.377-.306-.683-.684-.683h-2.053V20.3c3.58-.344 6.388-3.366 6.388-7.03 0-1.876-.736-3.583-1.935-4.849l3.76-3.755V7.12c0 .377.306.683.684.683.378 0 .685-.306.685-.683V3.017c0-.378-.307-.684-.685-.684v0zM14 18.968c-3.145 0-5.704-2.556-5.704-5.697S10.855 7.574 14 7.574c3.145 0 5.704 2.556 5.704 5.697s-2.559 5.697-5.704 5.697z"
    />
  </svg>
);

export default Gender;
