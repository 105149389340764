import React, { Dispatch, SetStateAction, useCallback } from "react";
import { Checkbox } from "../../../../components";
import "../../style.scss";

interface Props {
  participant?: { morning?: boolean; afternoon?: boolean };
  setParticipant: Dispatch<
    SetStateAction<
      | {
          morning?: boolean | undefined;
          afternoon?: boolean | undefined;
        }
      | undefined
    >
  >;
}

const Participant = ({ participant, setParticipant }: Props): JSX.Element => {
  const onChange = useCallback(
    (checked, prop: "morning" | "afternoon") => {
      const newParticipant = { ...participant };
      newParticipant[prop] = checked;
      setParticipant(newParticipant);
    },
    [participant, setParticipant],
  );

  return (
    <>
      <div className="propose-title subtitle">
        <h3>Dados gerais</h3>
      </div>
      <div className="propose-form-workshop general-data">
        <Checkbox
          className="checkbox"
          id="morning"
          label="Presença manhã"
          checked={participant?.morning ?? false}
          onChange={(e) => onChange(e.target.checked, "morning")}
        />
        <Checkbox
          className="checkbox"
          id="afternoon"
          label="Presença tarde"
          checked={participant?.afternoon ?? false}
          onChange={(e) => onChange(e.target.checked, "afternoon")}
        />
      </div>
    </>
  );
};

export default Participant;
