import React, { memo } from "react";
import { VacancyMobile } from "../../../assets/icons";

interface Props {
  candidate?: boolean;
}

const MessageVacancyManagement = ({ candidate }: Props): JSX.Element => (
  <div className="icon-management">
    <VacancyMobile />
    <div>
      {candidate ? (
        <p className="p-info-mobile">
          Funcionalidade disponível somente para desktop.
        </p>
      ) : (
        <p className="p-info-mobile">
          A gestão das vagas está disponível somente para desktop.
        </p>
      )}
    </div>
  </div>
);
export default memo(MessageVacancyManagement);
