import React, { useCallback, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { getUserById } from "../../../../services/functions";
import { AvatarEmpty } from "../../../../../assets/customIcons";
import { ClassVacancySubscriptionStage } from "../../../../types/interfaces";
import "../../style.scss";

interface Props {
  stages?: Array<
    ClassVacancySubscriptionStage & {
      title?: string;
      users?: {
        userName?: string;
        photo?: string | null;
      }[];
      userIds?: string[];
      type?: string;
    }
  >;
}

const Historic = ({ stages }: Props): JSX.Element => {
  const [stagesComplete, setStageComplete] = useState<
    Array<
      ClassVacancySubscriptionStage & {
        title?: string;
        users?: {
          userName?: string;
          photo?: string | null;
        }[];
        userIds?: string[];
        type?: string;
      }
    >
  >();

  const getStage = useCallback(async () => {
    const newStages: Array<
      ClassVacancySubscriptionStage & {
        title?: string;
        users?: {
          userName?: string;
          photo?: string | null;
        }[];
        userIds?: string[];
        type?: string;
      }
    > = [];

    if (stages && stages?.length) {
      for (let j = 0; j < stages?.length; j += 1) {
        const stage = stages[j];
        const stageWithUser = { ...stage };
        stageWithUser.users = [];

        if (!stage?.userIds && stage?.userId) stage.userIds = [stage?.userId];

        if (stage?.userIds) {
          for (let i = 0; i < stage?.userIds?.length; i += 1) {
            const userId = stage?.userIds[i];
            // eslint-disable-next-line no-await-in-loop
            const user = await getUserById(userId ?? "");
            stageWithUser.users.push({
              userName: user?.profile?.name ?? "",
              photo: user?.profile?.photo ?? null,
            });
          }
        }

        newStages.push(stageWithUser);
      }
    }

    setStageComplete(newStages);
  }, [stages]);

  useEffect(() => {
    getStage();
  }, [getStage]);

  if (!stagesComplete)
    return (
      <div className="subscriptions-loading subscriptions-loading-propose">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <div className="propose-title">
        <h2>Histórico</h2>
      </div>
      {stagesComplete?.length > 0 ? (
        stagesComplete
          ?.filter((stage) => stage?.grade)
          ?.map((stage) => (
            <div className="propose-items">
              <div className="propose-item">
                <div className="propose-item-title">
                  <h4>Etapa: {stage?.title}</h4>
                  <p>{moment(stage?.endDate).format("DD/MM/YYYY")}</p>
                </div>
                <div className="propose-item-content">
                  <div className="propose-item-info">
                    {stage?.users && stage?.users?.length > 0 && (
                      <div>
                        {stage?.users?.[0]?.photo ? (
                          <img
                            className="propose-item-avatar"
                            alt=""
                            src={stage?.users?.[0]?.photo}
                          />
                        ) : (
                          <AvatarEmpty
                            className="border-avatar-empty img-attachment-photo-small"
                            heightIcon={32}
                            widthIcon={32}
                          />
                        )}
                      </div>
                    )}
                    <div>
                      {stage?.users && stage?.users?.length > 0 && (
                        <p>{stage?.users?.[0]?.userName ?? "-"}</p>
                      )}
                      <h4 key={Math.random()}>
                        Nota Final:{" "}
                        {stage?.grade?.find((item) => item?.type === "FINAL")
                          ?.value ?? 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
      ) : (
        <div className="propose-without">
          <p>Sem notas</p>
        </div>
      )}
    </>
  );
};

export default Historic;
