import React, { FocusEventHandler, memo } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import "./styles.scss";

interface Props {
  id?: string;
  label?: string;
  helperText?: string;
  title?: string;
  disabled?: boolean;
  className?: string;
  options: string[];
  getOptionLabel: (option: string) => string;
  value?: string[] | string | null;
  onChange?: (value: string[] | string | null) => void;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  multiple?: boolean;
}

const InputAutocomplete = ({
  label,
  helperText,
  title,
  disabled,
  className,
  options,
  getOptionLabel,
  value,
  onChange,
  onFocus,
  onBlur,
  multiple,
  id,
}: Props): JSX.Element => (
  <div className={`${className ?? ""} ${disabled ? "disabled" : ""}`}>
    {title && (
      <p
        className={` p-title-autocomplete ${
          disabled ? "grey" : "dark-grey-blue"
        } `}
      >
        {title}
      </p>
    )}
    <Autocomplete
      multiple={multiple}
      freeSolo
      options={options}
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={(_, val) => onChange && onChange(val)}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          id={id}
          label={label}
          autoComplete="off"
        />
      )}
    />
    {helperText && (
      <p
        className={`p-helper-input-autocomplete ${
          disabled ? "opacity-20" : "opacity-100"
        } `}
      >
        {helperText}
      </p>
    )}
  </div>
);

export default memo(InputAutocomplete);
