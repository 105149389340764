import React, { memo, ReactNode, useEffect, useState } from "react";
import { HeaderActionCard, IconButton, TitleCard } from "..";
import { ChevronRight, Edit } from "../../../assets/icons";
import "./styles.scss";

interface Props {
  headerAction?: boolean;
  headerChildren?: ReactNode;
  titleHeader?: string;
  subtitleHeader?: string;
  textHeader?: string;
  textInfo?: string;
  title?: string;
  children: ReactNode;
  className?: string;
  headerClassName?: string;
  childrenHeader?: ReactNode;
  onClose?: () => void;
  footer?: ReactNode;
  onEdit?: () => void;
  onRedirect?: () => void;
  bodyClassName?: string;
  showBorder?: boolean;
  onClickBack?: () => void;
}

const CardRegister = ({
  headerAction,
  titleHeader,
  subtitleHeader,
  headerChildren,
  title,
  textHeader,
  textInfo,
  children,
  className,
  headerClassName,
  childrenHeader,
  footer,
  bodyClassName,
  onClickBack,
  onClose,
  onEdit,
  onRedirect,
  showBorder,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`card-component container-mobile ${className} ${
        showBorder && width < 991 ? "border-purple-card" : ""
      }`}
    >
      {headerAction && (
        <HeaderActionCard
          className={`${
            width < 991 ? "padding-y-16 padding-x-24" : "padding-24"
          }  ${headerClassName ?? ""}  `}
          text={textHeader}
          textInfo={textInfo}
          headerChildren={headerChildren}
          onClose={onClose}
          onClickBack={onClickBack}
        />
      )}
      {titleHeader && (
        <div
          className={`card-component-content ${
            !childrenHeader ? "flex" : "display-inherit"
          } `}
        >
          <div className="card-component-header">
            <p className="card-component-header-title">{titleHeader}</p>
            {subtitleHeader && (
              <p className="card-component-header-subtitle">{subtitleHeader}</p>
            )}
          </div>
          {onEdit && <IconButton icon={<Edit />} onClick={onEdit} />}
          {onRedirect && (
            <IconButton icon={<ChevronRight />} onClick={onRedirect} />
          )}
          {childrenHeader && childrenHeader}
        </div>
      )}
      <div className={`div-padding border-radius-8 ${bodyClassName ?? ""}`}>
        {title && <TitleCard text={title} />}
        {children}
      </div>
      {footer && <div className="card-component-footer">{footer}</div>}
    </div>
  );
};

export default memo(CardRegister);
