import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { Button, Input, Modal } from "../../../components";
import { setLoad } from "../../../store/actions/configurationsActions";
import { userBootcampsStatus } from "../../../types/enumerators";
import { ScholarshipType } from "../../../types/constants/User";
import { patchUser } from "../../../services/functions";
import { UserBootcamps } from "../../../types/interfaces";
import "../style.scss";
import HasScholarship from "../Components/hasScholarship";

interface User {
  userId: string;
  bootcamps: UserBootcamps[];
}

interface Props {
  open: boolean;
  close: () => void;
  user: User;
  classId: string;
  reload: () => void;
}

const ToForm = ({ open, close, user, classId, reload }: Props): JSX.Element => {
  const [hasScholarship, setHasScholarship] = useState(false);
  const [scholarshipType, setScholarshipType] = useState<
    Array<keyof typeof ScholarshipType>
  >([]);
  const [frequency, setFrequency] = useState(
    user?.bootcamps?.find((item) => item?.classId === classId)?.frequency,
  );

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    setHasScholarship(false);
    setScholarshipType([]);
    setFrequency(undefined);
    close();
  }, [close]);

  const onChangeStatus = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const bootcamps = [...(user?.bootcamps ?? [])];
    const i = bootcamps?.findIndex((item) => item?.classId === classId) ?? -1;

    if (i > -1) {
      const bootcamp = {
        ...bootcamps[i],
        status: userBootcampsStatus.FINISHED,
        hasScholarship,
        scholarshipType,
        frequency,
      };

      bootcamps[i] = bootcamp;

      await patchUser(user?.userId ?? "", { bootcamps });
    }

    reload();
    onClose();
    dispatch(setLoad(false) as unknown as Action);
  }, [
    dispatch,
    user?.bootcamps,
    user?.userId,
    reload,
    onClose,
    classId,
    hasScholarship,
    scholarshipType,
    frequency,
  ]);

  return (
    <Modal headerAction open={open} onClose={onClose} textHeader="Formar aluna">
      <HasScholarship
        hasScholarship={hasScholarship}
        setHasScholarship={setHasScholarship}
        scholarshipType={scholarshipType}
        setScholarshipType={setScholarshipType}
      />
      <Input
        label="% de frequência/entrega"
        type="number"
        value={frequency}
        onChange={(e) => setFrequency(Number(e.target.value))}
        className="margin-top-32"
      />
      <div className="margin-top-32 admin-subscriptions-modal-buttons">
        <Button
          text="Cancelar"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button
          text="Salvar"
          className="margin-left-32 margin-left-mobile"
          onClick={onChangeStatus}
          disabled={!frequency}
        />
      </div>
    </Modal>
  );
};

export default memo(ToForm);
