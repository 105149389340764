export const diversityForm = [
  {
    key: "diversity",
    label: "1 - O que pode ser definido como diversidade?",
    options: [
      {
        index: "A",
        label:
          "É a variação das características pessoais, físicas e sociais. Compreender, aceitar e valorizar as diferentes características pessoais, físicas e sociais das pessoas é essencial para uma sociedade em harmonia.",
      },
      {
        index: "B",
        label:
          "É um grupo de pessoas homogêneas e iguais dentro de uma sociedade ou comunidade que nos levam a ter o mesmo pensamento e ações sobre determinados temas.",
      },
      {
        index: "C",
        label:
          "É a variação das características pessoais, físicas e sociais mas que não é necessária a compreensão, aceitação e valorização dessas diferenças uma vez que vivemos em sociedade e temos o direito de expressar nossas opniões mesmo que fira o outro ou outra",
      },
    ],
    rightAnswer: ["A"],
  },
  {
    key: "equity",
    label: "2 - O que é Equidade?",
    options: [
      {
        index: "A",
        label: "Equidade e igualdade significam a mesma coisa.",
      },
      {
        index: "B",
        label:
          "Equidade é o conjunto de ações para permitir que pessoas lutem para conquistar aquilo que outras pessoas já conquistaram e que ela tem potencial.",
      },
      {
        index: "C",
        label:
          "Equidade é o conjunto de ações para permitir o acesso de pessoas que possuem necessidades específicas a todas as oportunidades.",
      },
    ],
    rightAnswer: ["C"],
  },
  {
    key: "inclusion",
    label: "3 - Quando acontece a inclusão?",
    options: [
      {
        index: "A",
        label:
          "A inclusão é a integração de grupo minoritários em espaços onde não tinham acesso anteriormente, mesmo não sendo todas as oportunidades daquele lugar.",
      },
      {
        index: "B",
        label:
          "A inclusão ocorre quando a diversidade é verdadeiramente acolhida, permitindo que todas as pessoas tenham acesso às oportunidades disponíveis.",
      },
      {
        index: "C",
        label:
          "É a segregação de grupos minoritários que devem se esforçar socialmente para se integram na sociedade e espaços onde não pertencem.",
      },
    ],
    rightAnswer: ["B"],
  },
  {
    key: "respect",
    label: "4 - O que é respeito?",
    options: [
      {
        index: "A",
        label:
          "Sentimento que nos impede de fazer ou dizer algo desagradável a alguém. Apreço, consideração, deferência.",
      },
      {
        index: "B",
        label:
          "Sentimento que nos incentiva a fazer ou dizer algo desagradável a alguém. Pensamento hostil sobre algo ou alguém que não pode ser dito por consideração ou educação.",
      },
      {
        index: "C",
        label:
          "Sentimento que nos impede de fazer ou dizer algo desagradável a alguém. Pensamento hostil sobre algo ou alguém que não pode ser dito por consideração ou educação.",
      },
    ],
    rightAnswer: ["A"],
  },
  {
    label:
      "5 - O que você faria se em sala de aula presenciasse alguma situação de desrespeito e ou de discriminação, racismo ou transfobia com uma professora, mentora, facilitadora ou colega de turma?",
    description:
      "Nesta pergunta queremos que você nos explique como nos ajudaria e criar um ambiente melhor, mais seguro, harmonioso e livre de qualquer descriminação dentro da organização.",
  },
  {
    key: "resolution",
    subtitle: "Marque a verdadeira",
    options: [
      {
        index: "A",
        label:
          "Acionaria alguém do time {reprograma} para ajudar na resolução do problema - Orientadora educacional, monitora, facilitadora, professora, coordenadoras, etc.",
      },
      {
        index: "B",
        label:
          "Conversaria com outras colegas da sala, de forma que conseguíssemos excluir a pessoa que errou do nosso ciclo de convívio.",
      },
    ],
    rightAnswer: ["A"],
    value: 2,
  },
  {
    key: "communication",
    subtitle: "Marque a verdadeira",
    options: [
      {
        index: "A",
        label:
          "Me comunicaria com a agressora e chamaria a atenção de forma respeitosa apontando o erro e não praticando outra violência.",
      },
      {
        index: "B",
        label:
          "Trataria a pessoa que errou da mesma forma que ela tratou a pessoa que sofreu a violência, assim ela sentiria na prática os efeitos do seu erro.",
      },
    ],
    rightAnswer: ["A"],
  },
  {
    key: "reception",
    subtitle: "Marque a verdadeira",
    options: [
      {
        index: "A",
        label: "Acolheria a pessoa agredida.",
      },
      {
        index: "B",
        label:
          "Não faria nada porque quando uma situação de violência acontece, muitas vezes a culpa é da vítima que fez isso para merecer esse tratamento.",
      },
    ],
    rightAnswer: ["A"],
  },
  {
    key: "repair",
    subtitle: "Marque a verdadeira",
    options: [
      {
        index: "A",
        label:
          "Sugeriria medidas de reparação para o futuro, como estudo sobre o tema na turma ou individualmente, convite de pessoas especializadas para falar com a turma, fazer ações com o grupo de destaque que sofre essa violência ou criar políticas afirmativas.",
      },
      {
        index: "B",
        label:
          "Não faria nada pois a resposabilidade de manter o ambiente seguro e harmônico é apenas da equipe que trabalha na {reprograma}.",
      },
    ],
    rightAnswer: ["A"],
    value: 2,
  },
];

// TODO: popular questões técnicas
export const technicalForm = [
  {
    key: "test",
    label: "1 - Questão 1",
    options: [
      {
        index: "A",
        label: "Resposta A",
      },
      {
        index: "B",
        label: "Resposta B",
      },
      {
        index: "C",
        label: "Resposta C",
      },
    ],
    rightAnswer: ["A"],
    value: 5,
  },
  {
    label: "Pergunta com várias questões",
    description: "Descrição",
  },
  {
    key: "test_2",
    subtitle: "Marque a verdadeira",
    options: [
      {
        index: "A",
        label: "Resposta A",
      },
      {
        index: "B",
        label: "Resposta B",
      },
    ],
    rightAnswer: ["A"],
    value: 5,
  },
];

export const rubricsBehavior = [
  {
    question:
      "Imagine uma empresa de tecnologia que você é colaboradora e que você tem orgulho por trabalhar nesta organização, inclusiva e diversa. Ela tem um programa de recrutamento que busca ativamente pessoas candidatas de diversas origens, raças, gêneros, orientações sexuais e habilidades neurodiversas, e promove uma cultura de igualdade e respeito para todos os seus funcionários. Essa  empresa alega estar  passando por um período difícil e precisa fazer cortes para equilibrar suas finanças. Ela decide que precisará demitir um grande número de funcionários e afirma que é uma estratégia corporativa necessária para a  sobrevivência. No entanto, após a demissão, percebe-se  que a maioria dos demitidos eram pessoas negras, em sua maioria,  mulheres e LGBTQIAP +. A partir desse cenário, como você, enquanto colaboradora, avaliaria essa situação? Reflita e compartilhe sua resposta em grupo e, a partir das considerações, escreva uma carta destinada ao CEO da empresa com as reflexões e propostas para essa situação. Sejam detalhistas em suas propostas, trazendo soluções, e reflexões para o caso.",
    criterions: [
      {
        type: "DIVERSITY_INCLUSION",
        goal: "Avaliar o engajamento e o nível de afinidade da candidata com o tema. Analisar se ela propõe soluções para o ocorrido, trazendo detalhes e insumos como: sugestões de guia de políticas internas, grupos de afinidades, ouvidoria.",
        label: "Diversidade e inclusão",
        points: [
          {
            info: "A candidata não respondeu o formulário ou  expressou algumas violências e apresenta casos de preconceito, racismo e machismo contra alguma pessoa ou grupo durante a oficina.",
            value: 0,
          },
          {
            info: "A candidata demonstra pouco/quase nenhum engajamento. (A comunicação pode acontecer por audio/voz e escrita)",
            value: 3,
          },
          {
            info: "A candidata demonstra engajamento e se posiciona trazendo algumas sugestões e ideias, sugerindo certa afinidade com pautas de diversidade e inclusão.",
            value: 7,
          },
          {
            info: "A candidata é bem participativa e engajada, contribuindo com suas vivências em projetos, grupos de afinidades e demonstrando capacidade em lidar com situações críticas.",
            value: 10,
          },
        ],
      },
    ],
  },
  {
    question:
      "Comportamento verbal, assertividade, questionamentos relevantes, conexão entre as candidatas.",
    criterions: [
      {
        type: "COMUNICATION_RELATIONSHIPS",
        goal: "Identificar se a candidata pretende trabalhar na área de tecnologia em uma vaga depois da formação. ",
        label: "Comunicação",
        points: [
          {
            info: "A candidata interaje de forma grosseira ou expressando falas preconceituosas. (A comunicação pode acontecer por audio/voz e escrita)",
            value: 0,
          },
          {
            info: "A candidata se comunica pouco/quase nada. (A comunicação pode acontecer por audio/voz e escrita)",
            value: 3,
          },
          {
            info: "A candidata se comunica bem, expõe suas dúvidas e contribuições de forma pontual. Ex: Concordo.",
            value: 7,
          },
          {
            info: "A candidata demonstra assertividade e objetividade em todos os momentos. Consegue se conectar com o outro, falando e ouvindo com interesse na medida certa, ou seja, sabendo como e quanto falar.",
            value: 10,
          },
        ],
      },
    ],
  },
  {
    question:
      "Compartilhamento de conhecimento, entrosamento entre as candidatas.",
    criterions: [
      {
        type: "SHARE_RELATIONSHIPS",
        goal: "Observar se a candidata  tem entrosamento com a turma, compartilhamento de ideias e estimulando o trabalho em grupo/equipe.",
        label: "Relacionamento Interpessoal",
        points: [
          {
            info: "A candidata tem atitude desrespeitosa com as participantes, sejam elas mentoras, voluntarias, professora e demais candidatas (avaliar comentários no chat)",
            value: 0,
          },
          {
            info: "A candidata se posiciona pouco/quase nada com as suas colegas de sala.",
            value: 3,
          },
          {
            info: "A candidata se posiciona e realiza trocas pontuais com as colegas de sala. ",
            value: 7,
          },
          {
            info: "A candidata tem um perfil de colaboração e compartilhamento de ideias demonstrando interação entre duas ou mais pessoas. Se posiciona sobre questões sociais importantes e incentiva a conexão entre as colegas de sala. (Avaliar comentários no chat > escrita ou áudio)",
            value: 10,
          },
        ],
      },
    ],
  },
];

// TODO: popular rubricas (anexo/url)
export const rubricsBehaviorTest = [
  {
    question: "Redação",
    criterions: [
      {
        type: "TEST",
        goal: "Objetivo da rubrica 1",
        label: "Texto da rubrica 1",
        points: [
          {
            info: "Info da nota 0",
            value: 0,
          },
          {
            info: "Info da nota 3",
            value: 3,
          },
          {
            info: "Info da nota 7",
            value: 7,
          },
          {
            info: "Info da nota 10",
            value: 10,
          },
        ],
      },
    ],
  },
];
