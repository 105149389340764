/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { updateCompany } from "../../../store/actions/companyActions";
import { maskZipCode } from "../../../utils";
import { Edit, Delete, Move } from "../../../../assets/icons";
import { Add } from "../../../../assets/customIcons";
import {
  IconButton,
  Card,
  Draggable,
  Button,
  FormAddress,
} from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { RootState } from "../../../store/reducers";

interface Props {
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isEdit: boolean;
}

const MainAddress = ({ isEdit, setIsEdit }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [itemEditIndex, setItemEditIndex] = useState(-1);
  const [isValid, setIsValid] = useState(false);
  const company = useSelector((state: RootState) => state.companyState);
  const [_company, _setCompany] = useState(company);
  const [address, setAddress] = useState({});
  const dispatch = useDispatch();

  const onSave = useCallback(() => {
    let newLocation = _company?.profile?.location;
    if (itemEditIndex !== -1) newLocation[itemEditIndex] = address;
    else if (newLocation?.length) newLocation.push(address);
    else newLocation = [address];

    _setCompany({
      ..._company,
      profile: {
        ..._company?.profile,
        location: newLocation,
      },
    });

    dispatch(
      setNextButton(() =>
        dispatch(
          updateCompany({
            ..._company,
            profile: {
              ..._company?.profile,
              location: newLocation,
            },
          }) as unknown as Action,
        ),
      ) as unknown as Action,
    );

    setIsEdit(false);
  }, [_company, address, dispatch, itemEditIndex, setIsEdit]);

  const onMove = useCallback(
    (items: any[]) => {
      const { profile } = _company;
      if (profile) {
        let { location: newLocation } = profile;
        newLocation = items.map((item, index) => ({
          ...item,
          ordem: index,
        }));
        _setCompany({
          ..._company,
          profile: { ...profile, location: newLocation },
        });
        dispatch(
          setNextButton(() =>
            dispatch(
              updateCompany({
                ..._company,
                profile: { ...profile, location: newLocation },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      }
    },
    [_company, dispatch],
  );

  const onDelete = useCallback(
    (index: number) => {
      let oldLocation = _company?.profile?.location;
      oldLocation.splice(index, 1);
      oldLocation = oldLocation.map((item: any, i: number) => ({
        ...item,
        order: i,
      }));
      _setCompany({
        ..._company,
        profile: { ..._company.profile, location: oldLocation },
      });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateCompany({
              ..._company,
              profile: { ..._company.profile, location: oldLocation },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_company, dispatch],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (company?._id && !_company?._id) _setCompany(company);
  }, [_company?._id, company]);

  return (
    <div className="grid-column-5-14 media-5-12 media-1200 grid-row-2 div-main grid-column-1-11-mobile">
      {!isEdit ? (
        <Card
          className="border-card"
          titleHeader={width > 990 ? "Endereço da empresa" : ""}
        >
          <IconButton
            icon={
              <Add
                widthIcon={width < 991 ? 20 : 24}
                heightIcon={width < 991 ? 20 : 24}
              />
            }
            text="Adicionar endereço"
            onClick={() => {
              setAddress({});
              setItemEditIndex(-1);
              setIsEdit(true);
            }}
            className="icon-button-primary"
          />
        </Card>
      ) : (
        <Card
          className={`grid-column-5-12 grid-column-6-13-media grid-row-2 ${
            width < 991 ? "" : "margin-left-8"
          } border-card`}
          titleHeader={`${width > 990 ? "Endereço da empresa" : ""}`}
        >
          <FormAddress
            setIsValid={setIsValid}
            showLabel
            corporate
            address={address}
            setAddress={setAddress}
          />
        </Card>
      )}
      {isEdit && (
        <div>
          <div className="flex margin-area-buttons flex-direction-column-reverse grid-column-1-9-mobile margin-0-responsive">
            <Button
              text="Cancelar"
              outline
              className="width-100-mobile"
              onClick={() => setIsEdit(false)}
              appearance="secondary"
            />
            <Button
              text="Salvar"
              className="margin-left-32 margin-left-0-mobile width-100-mobile"
              onClick={onSave}
              disabled={!isValid}
            />
          </div>
          <div className="divider-account" />
        </div>
      )}
      {_company?.profile?.location?.length > 1 && (
        <p className="margin-bottom-20 margin-top-40">
          Clique e arraste para ordenar como preferir.
        </p>
      )}
      <Draggable items={_company?.profile?.location} reorderItem={onMove}>
        {_company?.profile?.location?.map((item: any, index: number) => (
          <DraggableComponent
            key={Math.random()}
            draggableId={Math.random().toString()}
            index={index}
            isDragDisabled={_company?.profile?.location?.length === 1}
          >
            {(innerProvided) => (
              <div
                ref={innerProvided.innerRef}
                {...innerProvided.draggableProps}
                {...innerProvided.dragHandleProps}
                className="card-items-border"
              >
                <div className="flex grid-column-5-12 grid-row-6 margin-top-20" />
                <Card bodyClassName="margin-bottom-30">
                  <div className="flex-col">
                    <div className="flex space-between">
                      <p className="paragraph-main">{item.businessUnity}</p>
                      <div className="flex">
                        <IconButton
                          icon={<Edit />}
                          onClick={() => {
                            setAddress(_company?.profile?.location[index]);
                            setItemEditIndex(index);
                            setIsEdit(true);
                          }}
                          className="margin-right-8"
                        />
                        <IconButton
                          icon={<Delete />}
                          onClick={() => onDelete(index)}
                        />
                        {_company?.profile?.location?.length > 1 && (
                          <IconButton
                            icon={<Move />}
                            className="margin-left-8"
                          />
                        )}
                      </div>
                    </div>
                    <p className="item-main-paragraph">
                      {item.city} -{item.province}
                    </p>
                    <p className="item-company-account">
                      {item.address}
                      {item.number && `, ${item.number}`}
                      {item.adjunct && `, ${item.adjunct}`}
                      {` - ${item.district} - ${item.city} - ${
                        item.province
                      } - ${item.country} - CEP: ${maskZipCode(
                        item.zipCode ?? "",
                      )}`}
                    </p>
                  </div>
                </Card>
              </div>
            )}
          </DraggableComponent>
        ))}
      </Draggable>
    </div>
  );
};

export default memo(MainAddress);
