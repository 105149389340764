import React, { memo } from "react";
import { Layout } from "../../components";

import { LogoWhiteTransp } from "../../../assets/icons/index";

import "./style.scss";

const Loading = (): JSX.Element => (
  <Layout className="loading-layout">
    <div className="title-loading-area width-90-por-cento margin-left-149">
      <h1 className="title-loading h1-loading-page">
        The future is
        <strong>
          {" "}
          female <span className="under-line">coders</span>.
        </strong>
      </h1>
    </div>

    <div className="logoAnimation loading-animation">
      <LogoWhiteTransp className="itemAnimation" />
    </div>
  </Layout>
);

export default memo(Loading);
