/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, MouseEventHandler } from "react";
import { CompanyAvatarEmpty } from "../../../assets/customIcons";
import { JobInformation } from "..";
import "./styles.scss";

interface Props {
  data?: any;
  onClick?: MouseEventHandler<HTMLDivElement>;
  selectedId?: boolean;
}

const CardVacanciesAdvanced = ({
  data,
  onClick,
  selectedId,
}: Props): JSX.Element => (
  <div
    aria-label="redirect"
    role="button"
    tabIndex={0}
    onClick={onClick}
    className={`card-vacancies-advanced ${selectedId ? "is-selected" : ""}`}
  >
    <div className="margin-24">
      {data?.photo ? (
        <img
          alt="logo"
          src={data?.photo}
          className="card-vacancies-advanced-image"
        />
      ) : (
        <CompanyAvatarEmpty className="card-vacancies-advanced-avatar size-48" />
      )}
    </div>

    <JobInformation
      data={data}
      titleClassName="margin-top-24"
      statusClassName="card-vacancies-advanced-status"
    />
  </div>
);

export default memo(CardVacanciesAdvanced);
