/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import { Button, Card } from "..";
import { CompanyAvatarEmpty } from "../../../assets/customIcons";
import { getPlacementVacancyWithCorporateData } from "../../services/functions";
import {
  placementVacancyManagedBy,
  placementVacancyStatus,
  placementVacancyTypeOfCandidature,
} from "../../types/enumerators";
import { PlacementVacancy } from "../../types/interfaces";
import "./styles.scss";
import { Info } from "../../../assets/icons";

interface Props {
  vacancies: PlacementVacancy[];
  row: string;
}

interface VacancyProps {
  id: string;
  title: string;
  photo: string;
  company: string;
  candidature: string;
}

const VacanciesProfile = ({ vacancies, row }: Props): JSX.Element => {
  const history = useHistory();
  const [data, setData] = useState<VacancyProps[]>([]);
  const [loading, setLoading] = useState(true);

  const getVacancy = useCallback(async () => {
    setLoading(true);
    const listVacancy = [];

    for (let i = 0; i < vacancies?.length; i += 1) {
      const response = await getPlacementVacancyWithCorporateData(
        vacancies[i]._id ?? "",
      );
      if (response) {
        const vacancy: VacancyProps = {
          id: response[0]?._id ?? "",
          title: response[0]?.title ?? "",
          photo: response[0]?.photo ?? "",
          company:
            response[0]?.managedBy ===
            placementVacancyManagedBy.EXTERNAL_VACANCY
              ? response[0]?.externalCompanyName ?? ""
              : response[0]?.companyName ?? "",
          candidature:
            response[0]?.managedBy ===
            placementVacancyManagedBy.EXTERNAL_VACANCY
              ? "Curadoria"
              : response[0]?.status === placementVacancyStatus.ACTIVE
              ? response[0]?.typeOfCandidature ===
                placementVacancyTypeOfCandidature.OPEN
                ? "Recebendo candidaturas"
                : "Apenas convidadas"
              : response[0]?.status === placementVacancyStatus.PENDING
              ? "Inicia em breve"
              : "Vaga fechada",
        };
        listVacancy.push(vacancy);
      }
    }

    setData(listVacancy);
    setLoading(false);
  }, [vacancies]);

  useEffect(() => {
    if (vacancies) getVacancy();
  }, [getVacancy, vacancies]);

  return (
    <Card
      className={`grid-column-10-13 vacancies-profile padding-0 jobs-profile grid-row-${row}`}
      titleHeader="Vagas com seu perfil"
      headerClassName="vacancy-profile"
    >
      {loading ? (
        <div className="flex align-items justify-center">
          <CircularProgress />
        </div>
      ) : data && data?.length > 0 ? (
        <div className="vacancies-profile-card">
          {data &&
            data?.map((item, index) => (
              <div key={Math.random()}>
                <div className="flex-row">
                  {item?.photo ? (
                    <img
                      src={item?.photo}
                      alt="Foto"
                      className="vacancies-profile-image"
                    />
                  ) : (
                    <CompanyAvatarEmpty
                      className="vacancies-profile-image"
                      heightIcon={48}
                      widthIcon={48}
                    />
                  )}
                  <div className="margin-left-16">
                    <h4 className="vacancies-profile-description">
                      {item?.title}
                    </h4>
                    <h5 className="vacancies-profile-company">
                      {item?.company}
                    </h5>
                    <div className="flex-row-center">
                      <p className="vacancies-profile-candidature">
                        {item?.candidature}
                      </p>
                      {item?.candidature === "Curadoria" && (
                        <Tooltip
                          title={
                            <p>
                              Nenhuma etapa do processo seletivo desta vaga irá
                              ocorrer pela nossa plataforma. É a divulgação de
                              uma oportunidade que consideramos interessante
                              para você.
                            </p>
                          }
                        >
                          <div>
                            <Info className="icon-info cursor-pointer" />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center">
                  <Button
                    text="Ver mais"
                    outline
                    appearance="secondary"
                    className="vacancies-profile-button"
                    onClick={() => history.push(`/job/${item?.id}`)}
                  />
                </div>
                {index < (data?.length ?? 0) - 1 && (
                  <div className="vacancies-profile-border" />
                )}
              </div>
            ))}
        </div>
      ) : (
        <h5 className="vacancies-profile-company padding-bottom-8">
          Não há sugestões de vagas no momento
        </h5>
      )}
      <div className="div-card" />
      <div>
        <div className="grid-column-10-13 div-h1-vacancies-profile" />
        <h1
          className="vacancies-profile-title padding-0 margin-0"
          onClick={() => history.push("/vacancies-advanced")}
        >
          BUSCAR VAGAS
        </h1>
      </div>
    </Card>
  );
};

export default memo(VacanciesProfile);
