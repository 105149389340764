/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  memo,
  useCallback,
  ReactNode,
  useMemo,
  SetStateAction,
  Dispatch,
  useState,
  createRef,
  useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Action } from "redux";
import { Input, LabelInput, Modal } from "../../../components";
import { RootState } from "../../../store/reducers";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateUser } from "../../../store/actions/userActions";
import {
  maskBRlegalIdentity,
  maskPhone,
  isValidMail,
  isValidBRlegalIdentity,
} from "../../../utils";

interface Item {
  child: ReactNode;
  isFullRow?: boolean;
  renderCondition?: boolean;
  className?: string;
}

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
  templateRows?: boolean;
}

const PersonalData = ({
  setIsValid,
  templateRows = false,
}: Props): JSX.Element => {
  const expandedAttribute = "expanded";
  const heightProperty = "height";
  const fullRowClassName = "grid-full-row";

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [isInvalidMail, setIsInvalidMail] = useState(false);
  const [openModalIsInvalidMail, setOpenModalIsInvalidMail] = useState(false);
  const [openModalLegalIdentity, setOpenModalLegalIdentity] = useState(false);
  const [_user, _setUser] = useState(user);

  useEffect(() => {
    if (user?.profile?.name && !_user?.profile?.name) _setUser({ ...user });
  }, [_user, user]);

  const isDisabled = useMemo(
    () => ({
      cpf: !_user?.profile?.name,
      email: !_user?.profile?.name || !_user?.profile?.legalIdentity,
      phone:
        !_user?.profile?.name ||
        !_user?.profile?.legalIdentity ||
        isInvalidMail ||
        !_user?.profile?.email,
    }),
    [
      _user?.profile?.email,
      _user?.profile?.legalIdentity,
      _user?.profile?.name,
      isInvalidMail,
    ],
  );

  const handleOnBlur = useCallback(() => {
    setIsValid(true);
    dispatch(
      setNextButton(() => {
        dispatch(updateUser({ ..._user }) as unknown as Action);
      }) as unknown as Action,
    );
  }, [_user, dispatch, setIsValid]);

  const handleOnBlurLegalIdentity = useCallback(async () => {
    if (!isValidBRlegalIdentity(_user?.profile?.legalIdentity)) {
      setOpenModalLegalIdentity(true);
    }
  }, [_user?.profile?.legalIdentity]);

  const handleOnBlurEmail = useCallback(() => {
    if (isValidMail(_user?.profile?.email)) {
      handleOnBlur();
      setIsInvalidMail(false);
    } else {
      setOpenModalIsInvalidMail(true);
      setIsInvalidMail(true);
    }
  }, [_user?.profile?.email, handleOnBlur]);

  const handleChange = useCallback(
    (value: string, prop: string) =>
      _setUser({
        ..._user,
        profile: { ..._user?.profile, [prop]: value },
      }),
    [_user],
  );

  const items = useMemo(
    (): Item[] => [
      {
        child: (
          <>
            {templateRows && (
              <LabelInput alignStart text="Qual o seu nome completo?" />
            )}
            <Input
              id="name"
              label="Nome completo"
              type="text"
              onChange={(e) => handleChange(e.target.value, "name")}
              onBlur={handleOnBlur}
              value={_user?.profile?.name ?? ""}
            />
          </>
        ),
      },
      {
        child: (
          <>
            {templateRows && (
              <LabelInput text="Qual é o seu CPF?" disabled={isDisabled.cpf} />
            )}
            <Input
              id="cpf"
              label="CPF"
              type="text"
              onChange={(e) => handleChange(e.target.value, "legalIdentity")}
              onBlur={handleOnBlurLegalIdentity}
              disabled={isDisabled.cpf}
              value={maskBRlegalIdentity(_user?.profile?.legalIdentity ?? "")}
              maxLength={11}
            />
          </>
        ),
      },
      {
        child: (
          <>
            {templateRows && (
              <LabelInput
                text="Qual e-mail você usa para se comunicar com a gente?"
                disabled={isDisabled.email}
              />
            )}
            <Input
              id="email"
              label="E-mail"
              type="text"
              onChange={(e) =>
                handleChange(e.target.value.toLowerCase().trim(), "email")
              }
              onBlur={handleOnBlurEmail}
              value={_user?.profile?.email ?? ""}
              disabled={isDisabled.email}
            />
          </>
        ),
      },
      {
        child: (
          <>
            {templateRows && (
              <LabelInput
                text="Caso tenha, qual o número do seu celular?"
                disabled={isDisabled.phone}
              />
            )}
            <Input
              id="phone"
              label="Celular"
              type="text"
              helperText="De preferência com WhatsApp."
              value={maskPhone(_user?.profile?.telephone ?? "")}
              onChange={(e) => handleChange(e.target.value, "telephone")}
              onBlur={handleOnBlur}
              disabled={isDisabled.phone}
              maxLength={14}
            />
          </>
        ),
      },
    ],
    [
      _user?.profile?.email,
      _user?.profile?.legalIdentity,
      _user?.profile?.name,
      _user?.profile?.telephone,
      handleChange,
      handleOnBlur,
      handleOnBlurEmail,
      handleOnBlurLegalIdentity,
      isDisabled.cpf,
      isDisabled.email,
      isDisabled.phone,
      templateRows,
    ],
  );

  const itemsRefs = useMemo(
    () => items.map(() => createRef<HTMLDivElement>()),
    [items],
  );

  const updateLabelHeights = useCallback(() => {
    if (templateRows) {
      const getLabel = (ref: HTMLDivElement | null) => {
        if (ref) {
          const { children } = ref;
          // @ts-ignore
          const [label] = children;
          return label;
        }
        return ref;
      };

      const getLabelHeight = (label: HTMLParagraphElement) => {
        const { offsetHeight } = label;
        return offsetHeight;
      };

      const removeLabelHeight = (label: HTMLParagraphElement) => {
        const { style } = label;
        style.removeProperty(heightProperty);
        label.removeAttribute(expandedAttribute);
        return label;
      };

      const setLabelHeight = (label: HTMLParagraphElement, height: number) => {
        const { style } = label;
        style.setProperty(heightProperty, `${height}px`);
        label.setAttribute(expandedAttribute, "");
      };

      itemsRefs
        .filter(
          ({ current }) =>
            current && !current.className.includes(fullRowClassName),
        )
        .forEach(({ current: rightItem }, index, arr) => {
          if (index % 2 !== 0) {
            // Coluna esquerda
            const { current: leftItem } = arr[index - 1];
            const leftLabel = removeLabelHeight(getLabel(leftItem));
            const leftHeight = getLabelHeight(leftLabel);

            // Coluna direita
            const rightLabel = removeLabelHeight(getLabel(rightItem));
            const rightHeight = getLabelHeight(rightLabel);

            // Expansão de altura
            if (leftHeight > rightHeight)
              setLabelHeight(rightLabel, leftHeight);
            else setLabelHeight(leftLabel, rightHeight);

            // Redução de altura
            if (
              leftHeight < rightHeight &&
              rightLabel.hasAttribute(expandedAttribute)
            ) {
              removeLabelHeight(leftLabel);
              setLabelHeight(rightLabel, leftHeight);
            } else if (
              rightHeight < leftHeight &&
              leftLabel.hasAttribute(expandedAttribute)
            ) {
              removeLabelHeight(rightLabel);
              setLabelHeight(leftLabel, rightHeight);
            }
          }
        });
    }
  }, [itemsRefs, templateRows]);

  useEffect(() => {
    const handleResize = () => {
      updateLabelHeights();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [updateLabelHeights]);

  useEffect(() => updateLabelHeights(), [updateLabelHeights]);

  useEffect(() => {
    if (!isDisabled.phone) setIsValid(true);
    else setIsValid(false);
  }, [isDisabled.phone, setIsValid]);

  return (
    <>
      <div className="grid-account-gap grid-account-gap-resposive">
        {items.map(
          ({ isFullRow, renderCondition, className, child }, index) =>
            (renderCondition ?? true) && (
              <div
                ref={itemsRefs[index]}
                className={
                  isFullRow
                    ? fullRowClassName
                    : `content-grid-row ${
                        templateRows
                          ? `content-grid-2-row ${className ?? ""}`
                          : ""
                      }`
                }
              >
                {child}
              </div>
            ),
        )}
      </div>
      <Modal
        headerAction
        open={openModalLegalIdentity}
        onClose={() => setOpenModalLegalIdentity(false)}
        onClickBack={() => setOpenModalLegalIdentity(false)}
      >
        <div className="modal-age btn-modal-course-years">
          <h3 className="title-modal-age">Ops! Este CPF é inválido.</h3>
          <p className="text-modal-age">
            Para prosseguir, por favor confira se inseriu corretamente!
          </p>
        </div>
      </Modal>
      <Modal
        headerAction
        open={openModalIsInvalidMail}
        onClose={() => setOpenModalIsInvalidMail(false)}
        onClickBack={() => setOpenModalIsInvalidMail(false)}
      >
        <div className="modal-age btn-modal-course-years">
          <h3 className="title-modal-age">Ops! Este e-mail é inválido.</h3>
          <p className="text-modal-age">
            Para prosseguir, por favor confira se inseriu corretamente!
          </p>
        </div>
      </Modal>
    </>
  );
};
export default memo(PersonalData);
