import React, { memo } from "react";
import { useSelector } from "react-redux";
import { CompanyAvatarEmpty } from "../../../../assets/customIcons";
import { LabelComponent } from "../../../components";
import { RootState } from "../../../store/reducers";
import {
  FinancialType,
  SizeCompany,
  SizeTechArea,
} from "../../../types/constants/Company";

const ViewInfos = (): JSX.Element => {
  const company = useSelector((state: RootState) => state.companyState);

  return (
    <div className="flex display-block">
      <div className="flex-mobile">
        {company?.profile?.logo ? (
          <img
            src={company?.profile?.logo}
            alt="imagem"
            className="account-avatar-empty-company"
          />
        ) : (
          <div>
            <CompanyAvatarEmpty className="account-avatar-empty-company" />
          </div>
        )}
      </div>
      <div>
        <div className="flex-row-center flex-wrap display-block">
          <div className="flex-col margin-left-32 margin-left-0-mobile margin-bottom-32">
            <LabelComponent
              label="Nome da empresa"
              value={company?.profile?.name}
            />
          </div>
          <div className="flex-col margin-left-32 margin-left-0-mobile margin-bottom-32">
            <LabelComponent label="Site" value={company?.profile?.site} />
          </div>
          <div className="flex-col margin-left-32 margin-left-0-mobile margin-bottom-32">
            <LabelComponent
              label="Tipo de empresa"
              value={FinancialType[company?.financialType] ?? ""}
            />
          </div>
        </div>
        <div className="flex-row-center flex-wrap display-block">
          <div className="flex-col margin-left-32 margin-left-0-mobile margin-bottom-32 ">
            <LabelComponent
              label="Tamanho da empresa"
              value={SizeCompany[company?.profile?.size] ?? ""}
            />
          </div>
          <div className="flex-col margin-left-32 margin-left-0-mobile margin-bottom-32 ">
            <LabelComponent
              label="Tamanho da empresa"
              value={SizeTechArea[company?.profile?.sizeTechArea] ?? ""}
            />
          </div>
          <div className="flex-col margin-left-32 margin-left-0-mobile margin-bottom-32">
            <p className="paragraph-view">Mídias</p>
            <p className="view-bold">
              {company?.profile?.medias?.length
                ? `${company?.profile?.medias?.length}`
                : ""}
            </p>
          </div>
        </div>
        {company?.profile?.description && (
          <div className="flex-col margin-left-32 margin-left-0-mobile margin-bottom-32">
            <LabelComponent
              label="Descrição"
              value={company?.profile?.description}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ViewInfos);
