import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const BlockRed = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 14C3.5 8.204 8.204 3.5 14 3.5S24.5 8.204 24.5 14 19.796 24.5 14 24.5 3.5 19.796 3.5 14zm4.026 5.346A8.36 8.36 0 0 1 5.6 14c0-4.63 3.77-8.4 8.4-8.4 2.029 0 3.892.724 5.346 1.926l-11.82 11.82zm1.612 1.5A8.353 8.353 0 0 0 14 22.4c4.63 0 8.4-3.77 8.4-8.4 0-1.81-.576-3.489-1.554-4.862L9.138 20.846z"
      fill="#EB5757"
    />
  </svg>
);

export default BlockRed;
