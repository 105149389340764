import React, { memo, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Checkbox, InputAutocomplete, Modal, TextArea, Button } from "..";
import {
  searchCorporatePlacementVacancy,
  sendInviteToUser,
} from "../../services/functions";
import "./styles.scss";
import { placementVacancyStatus } from "../../types/enumerators";
import { PlacementVacancy } from "../../types/interfaces";
import { RootState } from "../../store/reducers";
import { Info } from "../../../assets/icons";

interface Props {
  openModal: boolean;
  onClose: () => void;
  userId: string;
}

const ModalSendInvite = ({
  openModal,
  onClose,
  userId,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [currentVacancy, setCurrentVacancy] = useState<string>();
  const [vacancies, setVacancies] = useState<PlacementVacancy[]>();
  const [message, setMessage] = useState("");
  const [sendMsgInvite, setSendMsgInvite] = useState(false);
  const user = useSelector((state: RootState) => state.userState);
  const canSendMsgInvite = false;

  const loadCompanyVacancies = useCallback(async () => {
    if (user?.account?.companyIds) {
      const response = await searchCorporatePlacementVacancy(
        user?.account?.companyIds[0],
        0,
      );

      if (response && response?.length) {
        const validVacancies = response?.filter(
          (item) => item.status === placementVacancyStatus.ACTIVE,
        );

        if (validVacancies && validVacancies?.length) {
          setCurrentVacancy(validVacancies[0].title);
          setVacancies(validVacancies);
        }
      }
    }
  }, [user?.account?.companyIds]);

  useEffect(() => {
    if (!currentVacancy || !vacancies) loadCompanyVacancies();
  }, [currentVacancy, loadCompanyVacancies, vacancies]);

  const handleSendInvite = useCallback(async () => {
    const currentVacancyToSend = vacancies?.find(
      (item) => item.title === currentVacancy,
    );

    if (currentVacancyToSend?._id && userId) {
      try {
        const response = await sendInviteToUser(
          currentVacancyToSend?._id,
          userId,
          sendMsgInvite ? message : "",
          user._id,
        );

        if (response) {
          toast.success("Convite enviado com sucesso!");
          setMessage("");
          setSendMsgInvite(false);
          onClose();
        } else toast.error("Ocorreu um erro ao enviar o convite");
      } catch (err) {
        toast.error("Ocorreu um erro ao enviar o convite");
      } finally {
        setMessage("");
        setSendMsgInvite(false);
        onClose();
      }
    }
  }, [
    vacancies,
    userId,
    currentVacancy,
    sendMsgInvite,
    message,
    user._id,
    onClose,
  ]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      headerAction
      textHeader="Enviar convite"
      open={openModal}
      onClose={() => {
        setMessage("");
        setSendMsgInvite(false);
        onClose();
      }}
      bodyClassName="padding-0 height-fit-content"
    >
      <div className="modal-invite-content">
        {width < 991 && (
          <p className="modal-invite-description">
            Selecione a vaga para enviar o convite*
          </p>
        )}
        <InputAutocomplete
          label={
            width > 990 ? "Selecione a vaga para enviar o convite*" : undefined
          }
          helperText=""
          value={vacancies ? vacancies[0]?.title : ""}
          onBlur={(event) => setCurrentVacancy(event.target.value)}
          className="width-100 margin-bottom-32"
          options={vacancies?.map((item) => item.title ?? "") ?? [""]}
          getOptionLabel={(option) => option}
        />
      </div>
      {canSendMsgInvite && (
        <div className="modal-invite-container">
          <button type="button">
            <Info className="icon-info" />
            <p>Ver mensagem padrão</p>
          </button>
          <Checkbox
            id="sendMsgInvite"
            label="Enviar convite personalizado"
            checked={sendMsgInvite}
            onChange={() => setSendMsgInvite(!sendMsgInvite)}
          />
          {sendMsgInvite && (
            <TextArea
              id="message"
              label="Escreva sua mensagem"
              maxLength={500}
              value={message ?? ""}
              onChange={(e) => setMessage(e.target.value)}
            />
          )}
        </div>
      )}
      <div className="flex-row-center margin-top-32-mobile flex-end flex-col-mobile gap-16-mobile">
        <Button
          text="Cancelar"
          onClick={() => {
            setMessage("");
            setSendMsgInvite(false);
            onClose();
          }}
          outline
          className="width-160 margin-right-24 margin-right-0-mobile margin-y-20-mobile width-100-responsive"
          appearance="secondary"
        />
        <Button
          text="Enviar"
          className={`width-160 width-100-responsive ${
            sendMsgInvite && !message ? "disabled" : ""
          }`}
          onClick={handleSendInvite}
          disabled={sendMsgInvite && !message}
        />
      </div>
    </Modal>
  );
};

export default memo(ModalSendInvite);
