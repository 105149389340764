/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment-timezone";
import React, { memo, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AvatarEmpty } from "../../../assets/customIcons";
import { Button, Modal, TextArea } from "../../components";
import { getUserById, updateAVacancy } from "../../services/functions";
import { RootState } from "../../store/reducers";
import { ArrowBack, Check } from "../../../assets/icons";
import { User } from "../../types/interfaces";
import { setLoad } from "../../store/actions/configurationsActions";

interface Props {
  item: any;
  fetch: () => Promise<void>;
}

interface ParamsProps {
  placementVacancyId: string;
}

const CardComments = ({ item, fetch }: Props): JSX.Element => {
  const { placementVacancyId } = useParams<ParamsProps>();

  const [comments, setComments] = useState<any[] | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [comment, setComment] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useSelector((state: RootState) => state.userState);

  const getComments = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const commentsList = [];

    const itemComments: any[] = [];
    item?.comments?.forEach((itemC: any[], i: number) =>
      itemC?.forEach((innerItemC, j: number) =>
        itemComments.push({ ...innerItemC, i, j }),
      ),
    );

    if (itemComments?.length) {
      for (let i = 0; i < itemComments?.length; i += 1) {
        const user = (await getUserById(itemComments[i]?.userId)) as User;

        commentsList.push({
          userName: user?.profile?.name,
          userPhoto: user?.profile?.photo,
          date: moment(itemComments[i]?.date ?? "")
            .tz("America/Sao_Paulo")
            .format("DD [de] MMMM"),
          text: itemComments[i]?.text ?? "",
          resolved: itemComments[i]?.resolved ?? false,
          i: itemComments[i]?.i,
          j: itemComments[i]?.j,
        });
      }
    }

    setComments(commentsList);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, item?.comments]);

  useEffect(() => {
    getComments();
  }, [getComments]);

  const handleChange = useCallback((e) => setComment(e.target.value), []);

  const onClose = useCallback(() => {
    setOpenModal(false);
    setComment("");
  }, []);

  const onSend = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const stages = [...(item?.stages ?? [])];

    const newComment = {
      text: comment,
      date: moment().tz("America/Sao_Paulo").toISOString(),
      userId: currentUser._id,
      resolved: false,
    };

    const index = (stages?.length ?? 0) - 1;

    if (stages[index]?.comments && stages[index]?.comments?.length)
      stages[index].comments?.push(newComment);
    else
      stages[index] = {
        ...stages[index],
        comments: [newComment],
      };

    try {
      await updateAVacancy(item?.placementVacancyId, item?.userId, { stages });
      toast.success("Comentário enviado com sucesso!");
    } catch (err) {
      toast.error("Ocorreu um erro ao enviar seu comentário");
    }
    await fetch();
    setOpenModal(false);
    setComment("");
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, item, comment, currentUser._id, fetch]);

  const onResolved = useCallback(
    async (msg, value) => {
      const payload = { ...item };
      delete payload?._id;
      delete payload?.lastStage;
      delete payload?.userName;
      delete payload?.userPhoto;
      delete payload?.vacancyName;
      delete payload?.vacancyPlace;
      delete payload?.comments;
      delete payload?.id;

      payload.stages[msg?.i].comments[msg?.j].resolved = value;

      await updateAVacancy(
        payload?.placementVacancyId,
        payload?.userId,
        payload,
      );
      await fetch();
    },
    [fetch, item],
  );

  return (
    <div className="width-100 max-height-440 overflow-x-hidden">
      {comments && comments?.length > 0 && (
        <div className="div-card-comments">
          <p className="comment-user-name">{item?.userName}</p>
          <p className="list-comment-date">{comments[0]?.date}</p>
        </div>
      )}
      {comments && comments?.length > 0 ? (
        comments?.map((msg, index) => (
          <div>
            <div className="width-100">
              {index > 0 && msg?.date !== comments[index - 1]?.date && (
                <div className="linha-data">
                  <span>{msg?.date}</span>{" "}
                </div>
              )}
              <div className="div-area-comment">
                {msg?.userPhoto ? (
                  <img
                    src={msg?.userPhoto}
                    alt="Foto"
                    className="company-avatar-empty-card"
                  />
                ) : (
                  <AvatarEmpty className="company-avatar-empty-card" />
                )}
                <p className="comment-text width-100">{msg?.text}</p>
                <div className="modal-comments-comment-body-buttons ">
                  <button
                    type="button"
                    onClick={() => onResolved(msg, !msg?.resolved)}
                  >
                    {!msg?.resolved ? <Check /> : <ArrowBack />}
                    <p>{msg?.resolved ? "Reabrir" : "Resolver"}</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="margin-left-24">Não há comentários</p>
      )}
      <div className="divider-card" />
      <div className="flex flex-end padding-buttons gap-16-mobile flex-col-mobile">
        <Button
          text="Voltar"
          className="width-144 margin-right-24 width-100-responsive"
          onClick={() => history.push(`/company-vacancy/${placementVacancyId}`)}
          outline
          appearance="secondary"
        />
        <Button
          text="Enviar comentário"
          className="width-192 width-100-responsive"
          onClick={() => setOpenModal(true)}
        />
      </div>
      <Modal
        headerAction
        textHeader="Enviar comentário"
        open={openModal}
        onClose={onClose}
        bodyClassName="padding-0"
      >
        <div className="width-310 width-100-mobile">
          <TextArea
            id="comment"
            label="Comentário*"
            maxLength={500}
            value={comment}
            onChange={handleChange}
          />
          <div className="flex-row-center margin-top-32 flex-col-mobile gap-16-mobile">
            <Button
              text="Cancelar"
              className="width-50 width-100-mobile"
              appearance="secondary"
              outline
              onClick={onClose}
            />
            <Button
              text="Salvar"
              className="margin-left-32 margin-left-0-mobile width-50 width-100-mobile"
              onClick={onSend}
              disabled={!comment}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default memo(CardComments);
