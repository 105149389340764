/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import moment from "moment-timezone";
import { CourseSubscriptionAction } from "../types/enumerators/actions/CourseSubscriptionAction";
import { historicActionType, historicEntity } from "../types/enumerators";
import { RouteAction } from "../types/enumerators/actions/RouteAction";
import { Historic } from "../types/interfaces";
import historicApi from "./historicApi";

/* GENERIC */

export const getHistoricByAction = async (action: string): Promise<any> => {
  try {
    const response = await historicApi?.post("/historic/get", {
      aggregate: `[{"$match": {"action": "${action}"}}]`,
    });
    return response?.data ?? null;
  } catch (error) {
    console.warn(error);
    return null;
  }
};

export const createLog = (log: Historic) => {
  const logWithDate: Historic = {
    ...log,
    date: moment().tz("America/Sao_Paulo").format("DD/MM/YYYY"),
  };

  try {
    historicApi
      ?.post("/log", logWithDate)
      .catch((error) => console.warn(error));
  } catch (error) {
    console.warn(error);
  }
};

export const logDetails = (
  url: string,
  action: string,
  status: string,
  data?: any,
) => {
  try {
    const user = JSON.parse(sessionStorage.getItem("@SESSION:user") ?? "null");
    const collection = url?.split("/")?.[1];
    const collectionId = url?.split("/")?.[2];
    const date = moment().tz("America/Sao_Paulo").toISOString();

    const log = {
      userId: user?._id,
      collection,
      collectionId,
      action,
      date,
      status,
      data: JSON.stringify(data),
    };

    historicApi?.post("/function", log).catch((error) => console.warn(error));
  } catch (error) {
    console.warn(error);
  }
};

export const executeWithLog = async (
  func: Promise<any>,
  log: Historic,
): Promise<void> => {
  func.then(() => createLog(log));
};

/* ROUTE */

export const createRouteLog = (
  entity: keyof typeof historicEntity,
  entityId: string,
  action: RouteAction,
  previousState: string,
  currentState: string,
) =>
  createLog({
    entity,
    entityId,
    action,
    actionType: historicActionType.BEHAVIOR,
    previousState,
    currentState,
  });

/* COURSE SUBSCRIPTION */

export const createCourseSubscriptionLog = (
  entityId: string,
  action: CourseSubscriptionAction,
  previousState: string,
  currentState: string,
) =>
  createLog({
    entity: historicEntity.USER,
    entityId,
    action,
    actionType: historicActionType.BEHAVIOR,
    previousState,
    currentState,
  });
