import moment from "moment";
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { AvatarEmpty } from "../../../../assets/customIcons";
import { RootState } from "../../../store/reducers";

const ViewUserData = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const businessUnity = useMemo(() => {
    const tagBusinessUnity = user?.account?.tags?.find((item: string) =>
      item.includes("business_unity"),
    );
    if (tagBusinessUnity) {
      const arrBusinessUnity = tagBusinessUnity.split(":");
      return arrBusinessUnity[1];
    }

    return "";
  }, [user?.account?.tags]);

  const position = useMemo(() => {
    const tagPosition = user?.account?.tags?.find((item: string) =>
      item.includes("position"),
    );
    if (tagPosition) {
      const arrPosition = tagPosition.split(":");
      return arrPosition[1];
    }

    return "";
  }, [user?.account?.tags]);

  return (
    <div className="flex display-block">
      <div className="relative flex-mobile">
        {user?.profile?.photo ? (
          <img
            src={user?.profile?.photo ?? ""}
            alt="imagem"
            width={48}
            height={48}
            className="img-attachment-photo"
          />
        ) : (
          <AvatarEmpty className="img-attachment-photo" />
        )}
      </div>

      <div className="flex-row-center flex-wrap media-infos display-block">
        <div className="flex-col margin-left-24 margin-right-32 margin-bottom-32">
          <p className="title-view-company">Nome</p>
          <p className="view-bold">{user?.profile?.name}</p>
        </div>
        <div className="flex-col margin-left-24 margin-right-32 margin-bottom-32">
          <p className="title-view-company">Data de nascimento</p>
          <p className="view-bold">
            {moment(user?.profile?.birthdate, "DD/MM/YYYY").isValid()
              ? user?.profile?.birthdate
              : ""}
          </p>
        </div>
        {user?.profile?.telephone && (
          <div className="flex-col margin-left-24 margin-right-32 margin-bottom-32">
            <p className="title-view-company">Celular</p>
            <p className="view-bold">{user?.profile?.telephone}</p>
          </div>
        )}
        <div className="flex-col margin-left-24 margin-right-32 margin-bottom-32">
          <p className="title-view-company">Unidade de negócio</p>
          <p className="view-bold">{businessUnity}</p>
        </div>
        <div className="flex-col margin-left-24 margin-right-32 margin-bottom-32">
          <p className="title-view-company">Cargo</p>
          <p className="view-bold">{position}</p>
        </div>
        <div className="flex-col margin-left-24 margin-right-32 margin-bottom-32 width-50">
          <p className="title-view-company">E-mail</p>
          <p className="view-bold">{user?.login?.identification}</p>
        </div>
        <div className="flex-col margin-left-24 margin-right-32 margin-bottom-32">
          <p className="title-view-company">Senha</p>
          <p className="password-points">
            ••••••••
            {/* {user?.login?.password?.replace(/\D/g, '•').replace(/\d/g, '•')} */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(ViewUserData);
