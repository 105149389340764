import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const TikTokProfile = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M14 25.667c6.444 0 11.667-5.224 11.667-11.667S20.444 2.333 14.001 2.333C7.557 2.333 2.334 7.557 2.334 14s5.223 11.667 11.667 11.667zm1.086-5.83c-.725.715-1.642 1.172-2.637 1.318-.233.034-.473.053-.712.05-1.064 0-2.074-.345-2.902-.983-.706-.836-1.088-1.881-1.088-2.982-.003-1.238.5-2.432 1.38-3.306.887-.88 2.066-1.368 3.322-1.377v1.84c-.224-.07-.463-.111-.712-.111-1.263 0-2.283 1.02-2.264 2.27.012.802.454 1.502 1.104 1.887.382.588 1.032.99 1.779 1.03.295.018.578-.022.84-.11.901-.294 1.551-1.134 1.551-2.123l.003-3.7V6.781h1.782c.183.936.712 1.75 1.446 2.304.557.734 1.371 1.257 2.307 1.437v1.794c-.842 0-1.663-.168-2.438-.494-.5-.209-.964-.482-1.387-.809l.013 5.533c-.006 1.243-.498 2.41-1.387 3.29z"
      clipRule="evenodd"
    />
    <path
      fill="#F00044"
      d="M11.735 14.289c-1.263 0-2.283 1.02-2.264 2.27.012.802.454 1.499 1.104 1.887-.22-.338-.351-.74-.358-1.172-.018-1.25 1.002-2.27 2.264-2.27.249 0 .488.04.712.112v-2.503c-.233-.034-.472-.053-.712-.053h-.034v1.84c-.224-.07-.463-.111-.712-.111zM17.193 6.782h-.666c.187.936.712 1.75 1.446 2.304-.482-.637-.774-1.433-.78-2.304z"
    />
    <path
      fill="#F00044"
      d="M21 10.591c-.246 0-.483-.024-.716-.068v1.794c-.846 0-1.667-.165-2.438-.494-.498-.212-.96-.482-1.387-.809l.012 5.533c-.006 1.243-.497 2.41-1.386 3.29-.725.715-1.642 1.172-2.638 1.318-.233.034-.472.053-.712.053-1.063 0-2.077-.345-2.901-.983.093.112.193.221.302.327.895.883 2.083 1.371 3.349 1.371.24 0 .479-.018.712-.053.995-.146 1.912-.603 2.637-1.318.89-.88 1.38-2.046 1.387-3.29l-.047-5.533c.423.327.887.6 1.387.81.774.326 1.596.494 2.438.494"
    />
    <path
      fill="#08FFF9"
      d="M9.127 13.937c.886-.88 2.065-1.368 3.321-1.377v-.663c-.233-.034-.472-.053-.712-.053-1.269 0-2.46.489-3.355 1.378-.88.874-1.384 2.068-1.381 3.309 0 1.25.494 2.422 1.387 3.309.143.14.29.27.445.388-.703-.836-1.086-1.881-1.089-2.982.003-1.24.504-2.435 1.384-3.309zM20.284 10.523v-.666h-.006c-.865 0-1.66-.286-2.301-.77.556.733 1.371 1.255 2.307 1.436z"
    />
    <path
      fill="#08FFF9"
      d="M12.355 19.476c.295.015.578-.025.84-.11.901-.294 1.551-1.134 1.551-2.123l.003-3.7V6.781h1.78c-.047-.233-.072-.47-.075-.715h-2.45v6.757l-.004 3.7c0 .99-.65 1.83-1.552 2.125-.261.087-.544.127-.84.108-.376-.021-.727-.133-1.032-.314.383.591 1.036.992 1.779 1.033z"
    />
  </svg>
);

export default TikTokProfile;
