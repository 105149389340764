import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React, { ChangeEvent, memo, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { setNextButton } from "../../store/actions/nextButtonAction";
import { updateUser } from "../../store/actions/userActions";
import { UserProfile } from "../../types/interfaces";

interface Props {
  className?: string;
  disabled: boolean;
  formControlLabelClassName?: string;
  label: string;
  labelClassName?: string;
  profile: UserProfile;
  radioGroupClassName?: string;
  setProfile: React.Dispatch<React.SetStateAction<UserProfile>>;
}

const AccessibilitySelection = ({
  className,
  disabled,
  formControlLabelClassName,
  label,
  labelClassName,
  profile,
  radioGroupClassName,
  setProfile,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const options = useMemo(
    () => [
      { value: "disabledPerson", label: "Sim", form: true },
      { value: "notDisabledPerson", label: "Não", form: false },
    ],
    [],
  );

  const handleChangeAdaptation = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setProfile({
        ...profile,
        disabledPerson: event.target.value === "disabledPerson",
      });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              profile: {
                ...profile,
                disabledPerson: event.target.value === "disabledPerson",
              },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [setProfile, profile, dispatch],
  );

  return (
    <>
      <p
        className={`title-input ${labelClassName} ${
          disabled ? "disabled" : ""
        }`}
      >
        {label}
      </p>
      <div className={className}>
        <RadioGroup
          aria-label="profileDisabledPerson"
          name="gender1"
          className={radioGroupClassName}
          value={
            profile?.disabledPerson !== undefined
              ? profile?.disabledPerson
                ? "disabledPerson"
                : "notDisabledPerson"
              : ""
          }
          onChange={handleChangeAdaptation}
        >
          <div>
            {options.map(({ value, label: radioLabel, form }) => (
              <FormControlLabel
                value={value}
                control={<Radio />}
                label={radioLabel}
                disabled={disabled}
                className={`${
                  form ? "margin-right-32" : ""
                } ${formControlLabelClassName}`}
              />
            ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
};

export default memo(AccessibilitySelection);
