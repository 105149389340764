import moment from "moment";
import {
  ProfileRace,
  IdentityType,
  ProfileGender,
} from "../../../types/constants/User";

import type {
  ClassVacancy,
  ClassVacancySubscription,
  UserProfile,
  DefaultClassVacancySubscriptionQuestions,
  IJSClassVacancySubscriptionQuestions,
} from "../../../types/interfaces";
import {
  classVacancyTemplate,
  classVacancySubscriptionEmployabilitySituation,
  classVacancySubscriptionUnemployedTime,
  userIdentityType,
  classVacancySubscriptionOriginOfWater,
  classVacancySubscriptionTypeOfStreet,
} from "../../../types/enumerators";
import {
  Target,
  ITLevel,
  NumItems,
  TypeOfStreet,
  EnglishLevel,
  Justification,
  OriginOfWater,
  LevelEducation,
  UnemployedTime,
  HowToKnowReprograma,
  VolunteerDescription,
  EmployabilitySituation,
  DifficultiesProgramming,
  DisabledPersonDescription,
  CourseOfReprograma,
} from "../../../types/constants/Course";
import { CurrentJoblevel } from "../../../types/constants/Employment";
import { maskBRlegalIdentity, maskPhone } from "../../../utils";

export const reprograma = (
  form:
    | ({
        [x: string]: string;
      } & DefaultClassVacancySubscriptionQuestions)
    | ({
        [x: string]: string;
      } & IJSClassVacancySubscriptionQuestions)
    | undefined,
  course?: ClassVacancy | undefined,
) => [
  {
    key: "target",
    title: "Curso online ou oficina de seleção",
    value: Target[form?.target as keyof typeof Target],
    valueEdit: form?.target,
    titleRegister:
      "Você gostaria de fazer o curso online ou apenas a oficina de seleção?",
  },
  {
    key: "howToKnowReprograma",
    title: "Como conheceu a {reprograma}",
    value:
      HowToKnowReprograma[
        form?.howToKnowReprograma as keyof typeof HowToKnowReprograma
      ],
    valueEdit: form?.howToKnowReprograma,
    titleRegister: "Como você conheceu a {reprograma}?",
  },
  {
    key: "isReprogramaStudent",
    title: "Já foi aluna {reprograma}",
    value: form?.isReprogramaStudent ? "Sim" : "Não",
    valueEdit: form?.isReprogramaStudent ? "Sim" : "Não",
    titleRegister: "Você já foi aluna da {reprograma}?",
  },
  {
    key: "reprogramaStudentOtherClasses",
    title: "Curso",
    value: form?.reprogramaStudentOtherClasses?.length
      ? CourseOfReprograma[
          form
            ?.reprogramaStudentOtherClasses[0] as keyof typeof CourseOfReprograma
        ]
      : "",
    valueEdit: form?.reprogramaStudentOtherClasses?.length
      ? form?.reprogramaStudentOtherClasses[0]
      : "",
    notRender: !form?.isReprogramaStudent,
    titleRegister: "Qual curso?",
  },
  {
    key: "similarCourse",
    title: "Cursou/está cursando iniciativa similar à {reprograma}",
    value: form?.similarCourse ? "Sim" : "Não",
    valueEdit: form?.similarCourse ? "Sim" : "Não",
    notRender: course?.template !== classVacancyTemplate.LEVEL_ONE,
    titleRegister: "Cursou/está cursando iniciativa similar à {reprograma}",
  },
  {
    key: "isTeacher",
    title: "Foi/é professora da {reprograma}",
    value: form?.isTeacher ? "Sim" : "Não",
    valueEdit: form?.isTeacher ? "Sim" : "Não",
    notRender: course?.template !== classVacancyTemplate.EDV,
    titleRegister: "Você já foi/é professora da {reprograma}?",
  },
  {
    key: "isJuniorAssistant",
    title: "É monitora da {reprograma}",
    value: form?.isJuniorAssistant ? "Sim" : "Não",
    valueEdit: form?.isJuniorAssistant ? "Sim" : "Não",
    notRender: course?.template !== classVacancyTemplate.EDV,
    titleRegister: "Você já foi/é monitora da {reprograma}?",
  },

  {
    key: "canParticipateOnSelectWorkshop",
    title: "Disponibilidade dia inteiro para oficina",
    value: form?.canParticipateOnSelectWorkshop ? "Sim" : "Não",
    valueEdit: form?.canParticipateOnSelectWorkshop ? "Sim" : "Não",
    titleRegister:
      "A Oficina é uma etapa obrigatória do processo seletivo, isso significa que caso você seja aprovada para esta etapa, precisará dedicar um sábado ou domingo inteiro aos estudos, conforme datas previstas no edital. Caso você não consiga participar, será desclassificada do processo. Você possui esta disponibilidade?",
  },
  {
    key: "learningITMotivation",
    title: "Porque este curso é importante",
    value:
      Justification[form?.learningITMotivation as keyof typeof Justification],
    valueEdit: form?.learningITMotivation,
    titleRegister:
      "Por que você acredita que este curso é importante para você?",
  },
  {
    key: "isVolunteer",
    title: "Já realizou algum tipo de trabalho voluntário",
    value: form?.isVolunteer ? "Sim" : "Não",
    valueEdit: form?.isVolunteer ? "Sim" : "Não",
    titleRegister: "Você já realizou algum tipo de trabalho voluntário?",
  },
  {
    key: "volunteerDescription",
    title: "Que atividade desempenhou",
    value:
      VolunteerDescription[
        form?.volunteerDescription as keyof typeof VolunteerDescription
      ],
    valueEdit: form?.volunteerDescription,
    notRender: !form?.isVolunteer,
  },
];

export const info = (
  profile: UserProfile,
  subscription?: ClassVacancySubscription,
) => [
  {
    key: "classVacancyId",
    title: "Curso",
    value: subscription?.classVacancyId,
    valueEdit: subscription?.classVacancyId,
    notRender: true,
  },
  {
    key: "name",
    title: "Nome completo",
    value: profile?.name,
    titleRegister:
      "Qual o seu nome completo? Fique a vontade para colocar seu nome social.",
  },
  {
    key: "rg",
    title: "RG",
    value: profile?.rg,
    titleRegister: "Qual o seu RG?",
  },
  {
    key: "legalIdentity",
    title: "CPF",
    value:
      profile?.identityType === userIdentityType.BR_CPF
        ? maskBRlegalIdentity(profile?.legalIdentity)
        : profile?.legalIdentity,
    valueEdit: profile?.legalIdentity,
    titleRegister: "Qual é número do seu CPF?",
  },
  {
    key: "identityType",
    title: "Tipo de documento",
    value: IdentityType[profile?.identityType],
    notRender: profile?.identityType === userIdentityType.BR_CPF,
    titleRegister: "Escolha o tipo de documento.",
  },
  {
    key: "legalIdentity",
    title: "Número do documento",
    value: profile?.legalIdentity,
    notRender: profile?.identityType === userIdentityType.BR_CPF,
    titleRegister: "Qual é o número do documento?",
  },
  {
    title: "Idade",
    value: profile?.birthdate
      ? moment().diff(moment(profile?.birthdate, "DD/MM/YYYY"), "years")
      : "-",
    titleRegister: "Quantos anos você tem?",
  },
  {
    key: "birthdate",
    title: "Data de nascimento",
    value: profile?.birthdate,
    titleRegister: "Qual a data do seu nascimento?",
  },
  {
    key: "email",
    title: "E-mail",
    value: profile?.email?.toLowerCase().trim(),
    titleRegister: "Qual o seu melhor email para contato?",
  },
  {
    key: "telephone",
    title: "WhatsApp",
    value: maskPhone(profile?.telephone ?? ""),
    valueEdit: profile?.telephone,
    titleRegister: "Caso tenha, qual o número do seu celular com DDD?",
  },
];

export const education = (
  form:
    | ({
        [x: string]: string;
      } & DefaultClassVacancySubscriptionQuestions)
    | ({
        [x: string]: string;
      } & IJSClassVacancySubscriptionQuestions)
    | undefined,
  course: ClassVacancy | undefined,
) => [
  {
    key: "ITLevel",
    title: "Nível de conhecimento em programação",
    notRender:
      course?.template !== classVacancyTemplate.TET &&
      course?.template !== classVacancyTemplate.LEVEL_ONE,
    value: ITLevel[form?.ITLevel as keyof typeof ITLevel],
    valueEdit: form?.ITLevel,
    titleRegister: "Qual o seu nível de conhecimento em programação?",
  },
  {
    key: "hasJSKnowledge",
    title: "Nível de conhecimento em programação JavaScript",
    notRender:
      course?.template === classVacancyTemplate.TET ||
      course?.template === classVacancyTemplate.LEVEL_ONE,
    value: ITLevel[form?.hasJSKnowledge as keyof typeof ITLevel],
    valueEdit: form?.hasJSKnowledge,
    titleRegister:
      "Qual o seu nível de conhecimento de programação em JavaScript?",
  },
  {
    key: "difficultiesProgramming",
    title: "Maiores dificuldades em programação",
    value:
      DifficultiesProgramming[
        form?.difficultiesProgramming as keyof typeof DifficultiesProgramming
      ],
    valueEdit: form?.difficultiesProgramming,
    titleRegister:
      "Quais as maiores dificuldades que você encontrou ao continuar seus estudos em programação?",
    notRender:
      course?.template === classVacancyTemplate.TET ||
      course?.template === classVacancyTemplate.LEVEL_ONE,
  },
  {
    key: "educationLevel",
    title: "Nível de instrução",
    value: LevelEducation[form?.educationLevel as keyof typeof LevelEducation],
    valueEdit: form?.educationLevel,
    titleRegister: "Qual o seu nível de escolaridade?",
  },
  {
    key: "graduatedInIT",
    title: "Formação é na área de tecnologia",
    value: form?.graduatedInIT ? "Sim" : "Não",
    valueEdit: form?.graduatedInIT,
    notRender: !form?.educationLevel?.includes("COLLEGE"),
    titleRegister: "Sua formação no ensino superior é na área de tecnologia?",
  },
  {
    key: "title",
    title: "Nome do curso",
    value: form?.title,
    valueEdit: form?.title,
    notRender: !(
      form?.educationLevel?.includes("COLLEGE") && form?.graduatedInIT
    ),
    titleRegister: "Qual o nome do seu curso?",
  },
  {
    key: "englishLevel",
    title: "Nível de conhecimento de inglês",
    value: EnglishLevel[form?.englishLevel as keyof typeof EnglishLevel],
    valueEdit: form?.englishLevel,
    titleRegister: "Qual o seu nível de conhecimento de inglês?",
  },
];

export const socioEconomicProfile = (
  profile: UserProfile,
  form:
    | ({
        [x: string]: string;
      } & DefaultClassVacancySubscriptionQuestions)
    | ({
        [x: string]: string;
      } & IJSClassVacancySubscriptionQuestions)
    | undefined,
  course?: ClassVacancy,
) => [
  {
    key: "hasInternet",
    title: "Tem acesso à internet",
    value: form?.hasInternet ? "Sim" : "Não",
    valueEdit: form?.hasInternet ? "Sim" : "Não",
    titleRegister: "Você tem acesso à internet?",
  },
  {
    key: "hasComputer",
    title: "Tem computador para o curso",
    value: form?.hasComputer ? "Sim" : "Não",
    valueEdit: form?.hasComputer ? "Sim" : "Não",
    titleRegister:
      "Você tem algum computador que atenda às necessidades do curso?",
  },
  {
    key: "employabilitySituation",
    title: "Situação profissional",
    value:
      EmployabilitySituation[
        form?.employabilitySituation as keyof typeof EmployabilitySituation
      ],
    valueEdit: form?.employabilitySituation,
    titleRegister: "Qual a sua situação profissional?",
  },
  {
    key: "unemployedTime",
    title: "Desempregada há quanto tempo",
    value:
      UnemployedTime[
        form?.unemployedTime || classVacancySubscriptionUnemployedTime.ONE_MONTH
      ],
    valueEdit:
      form?.unemployedTime || classVacancySubscriptionUnemployedTime.ONE_MONTH,
    notRender:
      form?.employabilitySituation !==
      classVacancySubscriptionEmployabilitySituation.UNEMPLOYED,
    titleRegister: "Há quanto tempo está desempregada?",
  },
  {
    key: "lastJobFunction",
    title: "Função no último emprego",
    value: form?.lastJobFunction,
    valueEdit: form?.lastJobFunction,
    notRender:
      form?.employabilitySituation !==
      classVacancySubscriptionEmployabilitySituation.UNEMPLOYED,
    titleRegister: "Qual função exercia no seu último emprego?",
  },
  {
    key: "jobFunction",
    title: "Cargo atual",
    value: form?.jobFunction,
    valueEdit: form?.jobFunction,
    notRender:
      form?.employabilitySituation ===
      classVacancySubscriptionEmployabilitySituation.UNEMPLOYED,
    titleRegister: "Qual o seu cargo atual?",
  },
  {
    key: "isEmployedInIT",
    title: "Está empregada na área de TI",
    value: form?.isEmployedInIT ? "Sim" : "Não",
    valueEdit: form?.isEmployedInIT ? "Sim" : "Não",
    notRender:
      form?.employabilitySituation ===
      classVacancySubscriptionEmployabilitySituation.UNEMPLOYED,
    titleRegister: "O seu emprego atual é na área de tecnologia?",
  },
  {
    key: "seniority",
    title: "Senioridade do cargo atual",
    value: CurrentJoblevel[form?.seniority as keyof typeof CurrentJoblevel],
    valueEdit: form?.seniority,
    notRender:
      course?.template === classVacancyTemplate.TET ||
      course?.template === classVacancyTemplate.LEVEL_ONE ||
      !form?.isEmployedInIT,
    titleRegister: "Qual o nível de senioridade do seu cargo atual?",
  },
  {
    key: "montlyRevenue",
    title: "Renda mensal",
    value:
      form?.montlyRevenue?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }) ?? "",
    valueEdit:
      form?.montlyRevenue?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }) ?? "",
    titleRegister: "Qual a sua renda mensal?",
  },
  {
    key: "familyTotalRevenue",
    title: "Renda total mensal da casa",
    value:
      form?.familyTotalRevenue?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }) ?? "",
    valueEdit:
      form?.familyTotalRevenue?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }) ?? "",
    titleRegister: "Qual a renda total mensal da sua casa?",
  },
  {
    key: "perCapitalRevenue",
    title: "Renda per capta",
    value:
      form?.perCapitalRevenue?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }) ?? "",
    valueEdit:
      form?.perCapitalRevenue?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }) ?? "",
    titleRegister: "",
  },
  {
    key: "familySize",
    title: "Quantas pessoas moram na casa",
    value: form?.familySize,
    valueEdit: form?.familySize,
    titleRegister: "Quantas pessoas moram na sua casa, contando com você?",
  },
  {
    key: "hasChildren",
    title: "Tem filhas (os)",
    value: form?.hasChildren ? "Sim" : "Não",
    valueEdit: form?.hasChildren ? "Sim" : "Não",
    titleRegister: "Você tem filhas (os)?",
  },
  {
    key: "children",
    title: "Quantas filhas (os)",
    value: form?.children,
    valueEdit: form?.children,
    notRender: !form?.hasChildren,
    titleRegister: "Quantos?",
  },
  {
    key: "childrenOfAge",
    title: "Possui filho menor de 18 anos",
    value: form?.childrenOfAge ? "Sim" : "Não",
    valueEdit: form?.childrenOfAge,
    notRender: !form?.hasChildren,
    titleRegister: "Algum dos seus filhos é menor de 18 anos?",
  },
  {
    key: "disabledPerson",
    title: "Pessoa com deficiência",
    value:
      profile?.disabledPerson === undefined
        ? undefined
        : profile?.disabledPerson
        ? "Sim"
        : "Não",
    valueEdit: profile?.disabledPerson,
    titleRegister:
      "Por favor, indique se você é uma pessoa com deficiência ou neurodiversa à medida em que você se sente confortável para responder",
  },
  {
    key: "disabledPersonDescription",
    title: "Descrição da deficiência",
    value:
      DisabledPersonDescription[
        profile?.disabledPersonDescription as keyof typeof DisabledPersonDescription
      ],
    valueEdit: profile.disabledPersonDescription,
    notRender: !profile?.disabledPerson,
    titleRegister: "Qual a sua neurodiversidade ou deficiência?",
  },
  {
    key: "needAccessibilityAdaptation",
    title: "Necessita de alguma adaptação em relação à acessibilidade",
    notRender: !profile?.disabledPerson,
    value: form?.accessibilityAdaptation ? "Sim" : "Não",
    valueEdit: form?.accessibilityAdaptation,
    titleRegister:
      "Para participar das aulas você precisará de alguma adaptação em relação à acessibilidade? O nosso curso é oferecido pelo zoom, por meio de aulas ao vivo e 100% online e interativas.",
  },
  {
    key: "accessibilityAdaptation",
    title: "Adaptações de acessibilidade",
    value: form?.accessibilityAdaptation,
    valueEdit: form?.accessibilityAdaptation,
    notRender: !profile?.disabledPerson,
    titleRegister: "Quais adaptações?",
  },
  {
    key: "genderIdentity",
    title: "Identidade de gênero",
    value:
      ProfileGender[profile?.genderIdentity as keyof typeof ProfileGender] ??
      profile?.genderIdentityDescription,
    valueEdit: profile?.genderIdentity,
    titleRegister: "Por favor, indique a sua identidade de gênero",
  },
  {
    key: "isLGBTQIA",
    title: "Faz parte da comunidade LGBTQIAP+",
    value: form?.isLGBTQIA ? "Sim" : "Não",
    valueEdit: form?.isLGBTQIA,
    titleRegister: "Você se considera parte do grupo LGBTQIAP+?",
  },
  {
    key: "race",
    title: "Raça",
    value:
      ProfileRace[profile?.race as keyof typeof ProfileRace] ??
      profile?.raceDescription,
    valueEdit: profile?.race,
    titleRegister: "Qual a sua raça/cor/etnia?",
  },
];

export const socioEconomicAssessment = (
  form:
    | ({
        [x: string]: string;
      } & DefaultClassVacancySubscriptionQuestions)
    | ({
        [x: string]: string;
      } & IJSClassVacancySubscriptionQuestions)
    | undefined,
) => [
  {
    key: "privateVehicles",
    title: "Quantidade de automóveis de passeio para uso particular",
    value: NumItems[form?.privateVehicles as keyof typeof NumItems] ?? "",
    valueEdit: form?.privateVehicles ?? "",
    titleRegister:
      "Quantidade de automóveis de passeio exclusivamente para uso particular",
  },
  {
    key: "monthlyEmployees",
    title: "Quantidade de empregados mensalistas",
    value:
      NumItems[(form?.monthlyEmployees ?? "") as keyof typeof NumItems] ?? "",
    valueEdit: form?.monthlyEmployees ?? "",
    titleRegister:
      "Quantidade de empregados mensalistas, considerando apenas os que trabalham pelo menos cinco dias por semana",
  },
  {
    key: "washingMachines",
    title: "Quantidade de máquinas de lavar roupa",
    value:
      NumItems[(form?.washingMachines ?? "") as keyof typeof NumItems] ?? "",
    valueEdit: form?.washingMachines ?? "",
    titleRegister: "Quantidade de máquinas de lavar roupa, excluindo tanquinho",
  },
  {
    key: "bathrooms",
    title: "Quantidade de banheiros",
    value: NumItems[(form?.bathrooms ?? "") as keyof typeof NumItems] ?? "",
    valueEdit: form?.bathrooms ?? "",
    titleRegister: "Quantidade de banheiros",
  },
  {
    key: "dvdDevices",
    title: "Quantidade de DVDs",
    value: NumItems[(form?.dvdDevices ?? "") as keyof typeof NumItems] ?? "",
    valueEdit: form?.dvdDevices ?? "",
    titleRegister:
      "DVD, incluindo qualquer dispositivo que leia DVD e desconsiderando DVD de automóvel",
  },
  {
    key: "refrigerators",
    title: "Quantidade de geladeiras",
    value: NumItems[(form?.refrigerators ?? "") as keyof typeof NumItems] ?? "",
    valueEdit: form?.refrigerators ?? "",
    titleRegister: "Quantidade de geladeiras",
  },
  {
    key: "freezers",
    title: "Quantidade de freezers",
    value: NumItems[(form?.freezers ?? "") as keyof typeof NumItems] ?? "",
    valueEdit: form?.freezers ?? "",
    titleRegister:
      "Quantidade de freezers independentes ou parte da geladeira duplex",
  },
  {
    key: "microcomputers",
    title: "Quantidade de microcomputadores",
    value:
      NumItems[(form?.microcomputers ?? "") as keyof typeof NumItems] ?? "",
    valueEdit: form?.microcomputers ?? "",
    titleRegister:
      "Quantidade de microcomputadores, considerando computadores de mesa, laptops, notebooks e netbooks e desconsiderando tablets, palms ou smartphones",
  },
  {
    key: "dishwashers",
    title: "Quantidade de lavadora de louças",
    value: NumItems[(form?.dishwashers ?? "") as keyof typeof NumItems] ?? "",
    valueEdit: form?.dishwashers ?? "",
    titleRegister: "Quantidade de lavadora de louças",
  },
  {
    key: "microwaves",
    title: "Quantidade de fornos de micro-ondas",
    value: NumItems[(form?.microwaves ?? "") as keyof typeof NumItems] ?? "",
    valueEdit: form?.microwaves ?? "",
    titleRegister: "Quantidade de fornos de micro-ondas",
  },
  {
    key: "professionalMotorcycles",
    title: "Quantidade de motocicletas para uso profissional",
    value:
      NumItems[
        (form?.professionalMotorcycles ?? "") as keyof typeof NumItems
      ] ?? "",
    valueEdit: form?.professionalMotorcycles ?? "",
    titleRegister:
      "Quantidade de motocicletas, desconsiderando as usadas exclusivamente para uso profissional",
  },
  {
    key: "clothesDryers",
    title: "Quantidade de máquinas secadoras de roupas",
    value: NumItems[(form?.clothesDryers ?? "") as keyof typeof NumItems] ?? "",
    valueEdit: form?.clothesDryers ?? "",
    titleRegister:
      "Quantidade de máquinas secadoras de roupas, considerando lava e seca",
  },
  {
    key: "originOfWater",
    title: "A água utilizada no domicílio é proveniente de:",
    value:
      OriginOfWater[form?.originOfWater as keyof typeof OriginOfWater] ||
      classVacancySubscriptionOriginOfWater.GENERAL,
    valueEdit:
      form?.originOfWater || classVacancySubscriptionOriginOfWater.GENERAL,
    titleRegister: "A água utilizada neste domicílio é proveniente de:",
  },
  {
    key: "typeOfStreet",
    title: "Como é o trecho da rua de seu domicílio",
    value:
      TypeOfStreet[form?.typeOfStreet as keyof typeof TypeOfStreet] ||
      classVacancySubscriptionTypeOfStreet.ASPHALT,
    valueEdit:
      form?.typeOfStreet || classVacancySubscriptionTypeOfStreet.ASPHALT,
    titleRegister:
      "Considerando o trecho da rua do seu domicílio, você diria que a rua é:",
  },
  {
    key: "levelEducationBoss",
    title: "Grau de instrução do chefe da família",
    value:
      LevelEducation[form?.levelEducationBoss as keyof typeof LevelEducation] ||
      classVacancySubscriptionOriginOfWater.OTHER,
    valueEdit:
      form?.levelEducationBoss || classVacancySubscriptionOriginOfWater.OTHER,
    titleRegister:
      "Qual é o grau de instrução do chefe da família? Considere como chefe da família a pessoa que contribui com a maior parte da renda do domicílio.",
  },
];

export const rubrics = (type?: keyof typeof classVacancyTemplate) => {
  if (type === classVacancyTemplate.TET)
    return [
      {
        info: "Cada questão está relacionada a, no máximo, 2 critérios; que devem ser avaliados individualmente a partir do vídeo de apresentação da candidata. Nem sempre a candidata responderá a todas as questões de maneira linear, por isso é importante e fundamental INTERAGIR com o material enviado na íntegra. Caso surjam dúvidas em algum caso em específico, levar para o encontro diário para deliberarmos em conjunto.",
        question:
          "Compartilhe com a gente a sua história e porquê você quer fazer o curso",
        criterions: [
          {
            type: "MOTIVATION",
            goal: "Conhecer uma parte da história da candidata, avaliar aspectos que demonstrem seu interesse no curso.",
            label: "Motivação da candidata",
            points: [
              {
                info: "A candidata não faz referência a sua trajetória e como esta se relaciona com o seu desejo de participar do programa.",
                value: 0,
              },
              {
                info: "A candidata conta sobre sua trajetória de vida mas não comenta sobre qual sua motivação para participar do curso.",
                value: 5,
              },
              {
                info: "A candidata relata sua trajetória resumidamente e demonstra interesse e motivação para participar do curso.",
                value: 10,
              },
            ],
          },
        ],
      },

      {
        question:
          "Conte para a gente como você deseja aplicar o conhecimento adquirido na {reprograma}.",
        criterions: [
          {
            type: "SHARE",
            goal: "Observar se a candidata demonstra interesse em compartilhar de alguma forma o conhcimento adquirido durante o curso de alguma forma, seja na sua comunidade, em outros projetos que participa, com outras mulheres etc.",
            label: "Compartilhamento",
            points: [
              {
                info: "A candidata não expressa o desejo de perpetuar aquilo que está aprendendo na reprograma.",
                value: 0,
              },
              {
                info: "A candidata expressa que deseja continuar repassando os conhecimentos adquiridos na reprograma de alguma forma, seja através de outros projetos, do seu trabalho, na sua comunidade, na própria reprograma ou em outros espaços que já participa ou almeja participar.",
                value: 5,
              },
              {
                info: "Além da candidata expressar o desejo de continuar repassando os conhecimento na reprograma, ela também diz que gostaria de ser professora na reprograma.",
                value: 10,
              },
            ],
          },
          {
            type: "INCLUSION",
            goal: "Identificar se a candidata pretende trabalhar na área de tecnologia em uma vaga depois da formação. ",
            label:
              "Se deseja continuar na área (inclusão no mercado de tecnologia e evitar evasão)",
            points: [
              {
                info: "A candidata não expressa interesse em continuar na área de tecnologia, não deseja continuar como desenvolvedora, pretender juntar a tecnologia com outros conhecimentos mas não trabalhando diretamente em uma vaga tech.",
                value: 0,
              },
              {
                info: "A candidata expressa interresse em seguir na área tech, mas não tem certeza em qual atividade ou área de conhecimento específica.",
                value: 5,
              },
              {
                info: "A candidata expressa ter interesse em seguir carreira diretamente na área de tecnologia como desenvolvedora e além disso cita interesse em participar das ações da reprograma como monitora, professora etc. ",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Como surgiu o seu interesse por Tecnologia? Você tem estudado sobre o tema? O que mais te atrai neste universo?",
        criterions: [
          {
            type: "IMPACT",
            goal: "Identificar se a candidata acredita que o curso fará diferença em sua vida ",
            label: "Impacto na vida da pessoa",
            points: [
              {
                info: "A candidata não relata se o curso terá efetivamente algum impacto em sua vida. ",
                value: 0,
              },
              {
                info: "A candidata relata que o curso pode gerar um grande impacto em sua vida, seja financeiramente, em sua autoestima, saúde mental, ou outras formas que podem gerar uma transformação real na sua realidade.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question: "Respondeu todas as questões solicitadas",
        criterions: [
          {
            type: "ALL_QUESTIONS",
            goal: "Avaliar a preparação da canditata para a preparação do vídeo, assim como seu engajamento",
            label:
              "A candidata pontua caso tenha respondido a todas as questões solicitadas.",
            points: [
              {
                info: "A candidata não respondeu a todas as questões solicitadas",
                value: 0,
              },
              {
                info: "A candidata respondeu a todas as questões solicitadas",
                value: 10,
              },
            ],
          },
        ],
      },
    ];

  if (type === classVacancyTemplate.LEVEL_ONE)
    return [
      {
        info: "Cada questão está relacionada a, no máximo, 2 critérios; que devem ser avaliados individualmente a partir do vídeo de apresentação da candidata. Nem sempre a candidata responderá a todas as questões de maneira linear, por isso é importante e fundamental INTERAGIR com o material enviado na íntegra. Caso surjam dúvidas em algum caso em específico, levar para o encontro diário para deliberarmos em conjunto.",
        question: "Compartilhe com a gente a sua história",
        criterions: [
          {
            type: "MOTIVATION_HISTORY",
            goal: "Avaliar o impacto que o curso pode gerar na vida da candidata",
            label: "Impacto na vida da candidata",
            points: [
              {
                info: "A candidata não faz referência a sua trajetória e não detalha como impacta de forma positiva em sua vida.",
                value: 0,
              },
              {
                info: "A candidata declara ter interesse em participar do curso, mas não conecta sua motivação com fatores de sua trajetória.",
                value: 3,
              },
              {
                info: "A candidata declara interesse em participar do curso, conecta com elementos da sua trajetória, mas a motivação está relacionada a fatores financeiros e não faz conexão como isso tem potencial para colaborar com os principais desafios da sociedade de forma ética e justa.",
                value: 7,
              },
              {
                info: "A candidata declara ter interesse em participar do curso e conecta coerentemente com elementos de sua trajetória, bem como entende que as ferramentas de tecnologia, transição de carreira, tem o potencial para transformar sua vida, da sua familia, comunidades e sociedade de forma ética e justa.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question: "Porquê você quer fazer o curso",
        criterions: [
          {
            type: "MOTIVATION_IMPACT",
            goal: "Conhecer uma parte da história da candidata, avaliar aspectos que demonstrem a motivação.",
            label: "Motivação da candidata",
            points: [
              {
                info: "A candidata não deixa especifico como o curso pode transformar a sua vida. Por exemplo; transformação por ser uma pessoa preta, quer ocupar esses espaços, papel da mulher da tecnologia, afirma estar dentro do perfil.",
                value: 0,
              },
              {
                info: "A candidata menciona brevemente sobre impacto na sua vida com a realização do curso, sem explicar mais detalhes do impacto na sua vida.",
                value: 3,
              },
              {
                info: "A candidata a relata que o curso terá impacto de forma generalista, mas não detalha qual o impacto na sua vida e em suas comunidades.",
                value: 7,
              },
              {
                info: "A candidata relata que o curso pode gerar um grande impacto em sua vida, seja com habilidades desenvolvidas  em  tecnologia, financeiramente, em sua autoestima, saúde mental, ou outras formas que podem gerar uma transformação real na sua realidade.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Nos conte o seu ponto de vista sobre programas como este, voltados prioritariamente para inclusão de mulheres negras, trans e travestis e qual o impacto você acha que eles podem trazer para a sociedade.",
        criterions: [
          {
            type: "DIVERSITY",
            goal: "Identificar se a canditada tem discernimento e familiaridade sobre questões relacionadas à diversidade e inclusão.",
            label:
              "Empatia, inclusão, pensamento crítico, familiaridade com questões de diversidade.",
            points: [
              {
                info: "A candidata não faz nenhuma referência sobre a pergunta ou declara ser indiferente ou menciona que ações afirmativas são injustas para as pessoas que não são parte destes grupos, já que todas são iguais perante a lei.",
                value: 0,
              },
              {
                info: "A candidata afirma ser importante esse tipo de iniciativa, mas não aprofunda a partir do seu ponto de vista o por quê. Exemplo: `Acho importante` `Acredito que existe` sem detalhar mais sobre o tema.",
                value: 3,
              },
              {
                info: "A candidata reconhece que existem injustiças sociais e consegue classificar que elas existem em detrimento de classe, gênero, raça e ou outras intersecções. Exemplo: Lei de cotas nas universidades, ter nome social, racismo, consegue entender o tipo de violência.",
                value: 7,
              },
              {
                info: "A candidata reconhece que existem injustiças sociais e consegue classificar que elas existem em detrimento de classe, gênero, raça e outras intersecções, além de, afirmar que ações afirmativas são estratégias eficazes para reduzir as desigualdades sociais, pois são oriundas de um processo de exclussão histórico e estrutural.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Conte para a gente como você deseja repassar o conhecimento adquirido na {reprograma}",
        criterions: [
          {
            type: "SHARE",
            goal: "Observar se a candidata demonstra interesse em compartilhar de alguma forma o conhecimento adquirido durante o curso de alguma forma, seja na sua comunidade, em outros projetos que participa, com outras mulheres etc.",
            label: "Compartilhamento",
            points: [
              {
                info: "A candidata não faz menção em compartilhar seu conhecimento.",
                value: 0,
              },
              {
                info: "A candidata expressa de forma superficial o desejo de perpetuar aquilo que está aprendendo na reprograma. Ex: Quero compartilhar meu conhecimento (sem dizer onde, como ou para qual comunidade).",
                value: 3,
              },
              {
                info: "A candidata expressa que deseja continuar repassando os conhecimentos adquiridos na reprograma de alguma forma, seja através de outros projetos, do seu trabalho, na sua comunidade, na própria reprograma ou em outros espaços que já participa ou almeja participar.",
                value: 7,
              },
              {
                info: "Além da candidata expressar o desejo de continuar repassando os conhecimento na reprograma, ela também diz que gostaria de ser professora na reprograma.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question: "Qual  sua expectativa de futuro após a sua formação?",
        criterions: [
          {
            type: "INCLUSION",
            goal: "Identificar se a candidata pretende trabalhar na área de tecnologia.",
            label:
              "Se deseja continuar na área (inclusão no mercado de tecnologia e evitar evasão)",
            points: [
              {
                info: "A candidata não relata sobre expectativa de futuro após a sua formação.",
                value: 0,
              },
              {
                info: "A candidata expressa interesse em áreas pararelas, ou seja, pretende juntar a tecnologia com outros conhecimentos, mas não trabalhando diretamente em uma vaga de desenvolvedora/dados. Ex: Tech Recruiter, ux , gestora de produto (PO)",
                value: 3,
              },
              {
                info: "A candidata demonstra segurança no interresse em realizar transição de carreuira para área tech (desenvolvedora/dados), mas não tem certeza em qual carreira específica deseja seguir.",
                value: 7,
              },
              {
                info: "A candidata expressa total segurança e  interesse em seguir carreira na área de tecnologia e sabendo o que quer trilhar (área de interesse por exemplo: cita vagas, empresas que deseja trabalhar, conteúdo dos nossos cursos, projetos e possível atuação na {reprograma}",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Você já começou algum processo de estudos em TI por aí? Se sim, compartilhe com a gente? (lembrando que não é necessário ter conhecimento em programação, mas gostaríamos de entender seu interesse por tecnologia)",
        criterions: [
          {
            type: "KNOWLEDGE",
            goal: "Identificar e confirmar o interesse da candidata por meio de estudos por conta propria,  cursos gratuitos/pagos, leitura de material, meetup, se participa de alguma comunidade, realização de bootcamp, demonstrando curiosidade e segurança para a transição de carreira e/ou primeira oportunidade",
            label: "A candidata já iniciou alguma forma de estudo",
            points: [
              {
                info: "A candidata não contribui elementos que demonstrem interesse em seguir na carreira tech, ou seja, não cita grupos que participa, comunidades e cursos realizados com foco em tecnologia",
                value: 0,
              },
              {
                info: "A candidata não está estudando, mas demonstra interesse no curso. Ex: não participa de nenhuma comunidade mas gostaria de participar, tem uma amiga que apresentou a área, leitura sobre o tema de tecnologia.",
                value: 3,
              },
              {
                info: "A candidata já iniciou o estudo de maneira autonoma e demonstra interesse no curso, bem como em seguir carreira na área tech.",
                value: 7,
              },
              {
                info: "A candidata menciona que já realizou alguns cursos, leitura de livros, participa de comunidades, cita referências demonstrando total interesse na carreira tech",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question: "Respondeu todas as questões solicitadas",
        criterions: [
          {
            type: "ALL_QUESTIONS",
            goal: "Avaliar a preparação da canditata para a apresentação do vídeo e/ou audio , assim como seu engajamento.",
            label:
              "A candidata pontua caso tenha respondido a todas as questões solicitadas.",
            points: [
              {
                info: "A candidata respondeu até 3 questões, sendo um engajamento muito baixo e contribuindo pouco para avaliação",
                value: 0,
              },
              {
                info: "A candidata respondeu quase todas as questões",
                value: 3,
              },
              {
                info: "A candidata respondeu todas as questões, mas não aprofundou com base nos critérios avaliativos pontos importantes do roteiro de forma clara e objetiva",
                value: 7,
              },
              {
                info: "A candidata respondeu a todas as questões solicitadas e aprofundou pontos importantes do roteiro, além de ter informado, de forma clara e objetiva.",
                value: 10,
              },
            ],
          },
        ],
      },
    ];

  if (type === classVacancyTemplate.IJS)
    return [
      {
        info: "Cada questão está relacionada a, no máximo, 2 critérios; que devem ser avaliados individualmente a partir do vídeo de apresentação da candidata. Nem sempre a candidata responderá a todas as questões de maneira linear, por isso é importante e fundamental INTERAGIR com o material enviado na íntegra. Caso surjam dúvidas em algum caso em específico, levar para o encontro diário para deliberarmos em conjunto.",
        question: "Compartilhe com a gente a sua história.",
        criterions: [
          {
            type: "MOTIVATION_HISTORY",
            goal: "Conhecer uma parte da história da candidata, avaliar aspectos que demonstrem seu interesse no curso.",
            label:
              "Impacto na vida da candidata (entender o nível de vontade; expressão corporal, tempo de vídeo). Para formulário se a candidata descreveu de forma detalhada com argumentos alinhado com perfil esperado do curso. Exemplo: Nível de vontade, história inspiradora, expectativa de crescimento na carreira. Para audio (tempo de áudio até 3 minutos - apresenta de forma detalhada com argumentos alinhado com perfil esperado do curso.",
            points: [
              {
                info: "A candidata não faz referência a sua trajetória e não detalha como impacta de forma positiva em sua vida.",
                value: 0,
              },
              {
                info: "A candidata declara ter interesse em participar do curso, mas não conecta sua motivação com fatores de sua trajetória.",
                value: 5,
              },
              {
                info: "A candidata declara ter interesse em participar do curso e conecta coerentemente com elementos de sua trajetória, bem como entende que as ferramentas de tecnologia, transição de carreira, tem o potencial para transformar sua vida, da sua familia, comunidades e sociedade de forma ética e justa.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question: "Por que você quer fazer o curso?",
        criterions: [
          {
            type: "MOTIVATION_IMPACT",
            goal: "Observar se a candidata relata e considera que o curso teria impacto e poderia mudar sua vida.",
            label: "Motivação da candidata.",
            points: [
              {
                info: "A candidata não deixa especifico como o curso pode transformar a sua vida. Por exemplo; transformação por ser uma pessoa preta, quer ocupar esses espaços, papel da mulher da tecnologia, afirma estar dentro do perfil.",
                value: 0,
              },
              {
                info: "A candidata a relata que o curso terá impacto de forma generalista, mas não detalha qual o impacto na sua vida e em suas comunidades.",
                value: 5,
              },
              {
                info: "A candidata relata que o curso pode gerar um grande impacto em sua vida, seja com habilidades desenvolvidas  em  tecnologia, financeiramente, em sua autoestima, saúde mental, ou outras formas que podem gerar uma transformação real na sua realidade.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Na sua avaliação, seu nível de conhecimento e/ou experiência em Javascript é: introdutório, básico, intermediário ou avançado? Ao indicar seu nível, justifique.",
        criterions: [
          {
            type: "KNOWLEDGE",
            goal: "Avaliar na apresentação qual o nível de conhecimento em Javascript.",
            label:
              "Demonstra conhecimento técnico na sua apresentaçao em vídeo, áudio ou texto.",
            points: [
              {
                info: "A candidata não comunica ou não possui conhecimento básico em JavaScript (Obs: Introdutório não classifica para o curso).",
                value: 0,
              },
              {
                info: "A candidata possui conhecimento básico; intermediário; avançado  e relata interesse em se aperfeiçoar  em JavaScript mas não detalha.",
                value: 5,
              },
              {
                info: "Comunica que possui conhecimento básico em JavaScript e cita alguns tópicos que estudou relacionado a JavaScript demonstrando foco e motivação em aprofundar seu conhecimento em JavaScript",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Conte sobre um projeto que você se orgulha de ter desenvolvido (Exemplo: Como realizou o projeto, quais as tecnologias utilizadas, quais os bugs que apareceram no caminho; como você lidou com eles e quais estratégias você utilizou para entregar um código limpo).",
        criterions: [
          {
            type: "PROJECT",
            goal: "Avaliar nível de experiência da candidata na prática.",
            label:
              "Apresenta detalhando caminho  caminhos e estrategias de como seus projetos foram desenvolvidos por vídeo, áudio ou texto.",
            points: [
              {
                info: "A candidata não comunica ou não tem experiência na área.",
                value: 0,
              },
              {
                info: "A candidata apresenta caminhos percorridos para desenvolver o seu projeto não trazendo detalhes de como foi a sua experiência.",
                value: 5,
              },
              {
                info: "A candidata apresenta o seu projeto, utilizando a linguagem javascript e como lidou com os desafios, bugs e demais experiências de um projeto prático. Ex cita termos como: elaboração de script simples, tarefas elementares ou em casos mais avançados experiência com frameworks e bibliotecas.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Nos conte o seu ponto de vista sobre programas como este, voltados prioritariamente para inclusão de mulheres negras, trans e travestis e qual o impacto você acha que eles podem trazer para a sociedade.",
        criterions: [
          {
            type: "DIVERSITY",
            goal: "Identificar se a canditada tem discernimento e familiaridade sobre questões relacionadas à diversidade e inclusão.",
            label:
              "Empatia, inclusão, pensamento crítico, familiaridade com questões de diversidade.",
            points: [
              {
                info: "A candidata não faz nenhuma referência sobre a pergunta ou declara ser indiferente ou menciona que ações afirmativas são injustas para as pessoas que não são parte destes grupos, já que todas são iguais perante a lei.",
                value: 0,
              },
              {
                info: "A candidata reconhece que existem injustiças sociais e consegue classificar que elas existem em detrimento de classe, gênero, raça e ou outras intersecções. Exemplo: Lei de cotas nas universidades, ter nome social, racismo, consegue entender o tipo de violência.",
                value: 5,
              },
              {
                info: "A candidata reconhece que existem injustiças sociais e consegue classificar que elas existem em detrimento de classe, gênero, raça e outras intersecções, além de, afirmar que ações afirmativas são estratégias eficazes para reduzir as desigualdades sociais, pois são oriundas de um processo de exclussão histórico e estrutural.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Como você pretende utilizar o conhecimento adquirido na {reprograma}?",
        criterions: [
          {
            type: "APPLY_SHARE",
            goal: "Observar se a candidata demonstra interesse em compartilhar de alguma forma o conhecimento adquirido durante o curso de alguma forma, seja na sua comunidade, em outros projetos que participa, com outras mulheres etc.",
            label: "Compartilhamento/Colaboração.",
            points: [
              {
                info: "A candidata não faz menção em compartilhar seu conhecimento.",
                value: 0,
              },
              {
                info: "A candidata expressa que deseja continuar repassando os conhecimentos adquiridos na reprograma de alguma forma, seja através de outros projetos (ex: trabalho voluntário), do seu trabalho, na sua comunidade, na própria reprograma ou em outros espaços que já participa ou almeja participar.",
                value: 5,
              },
              {
                info: "Além da candidata expressar o desejo de continuar repassando os conhecimento na reprograma, ela também diz que gostaria de ser professora na reprograma.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question: "Respondeu todas as questões solicitadas",
        criterions: [
          {
            type: "ALL_QUESTIONS",
            goal: "Avaliar a preparação da canditata para a apresentação do vídeo e/ou audio , assim como seu engajamento.",
            label:
              "A candidata pontua caso tenha respondido a todas as questões solicitadas.",
            points: [
              {
                info: "A candidata respondeu até 3 questões, sendo um engajamento muito baixo e contribuindo pouco para avaliação.",
                value: 0,
              },
              {
                info: "A candidata respondeu todas as questões, mas não aprofundou com base nos critérios avaliativos pontos importantes do roteiro de forma clara e objetiva.",
                value: 5,
              },
              {
                info: "A candidata respondeu a todas as questões solicitadas e aprofundou pontos importantes do roteiro, além de ter informado, de forma clara e objetiva.",
                value: 10,
              },
            ],
          },
        ],
      },
    ];

  if (type === classVacancyTemplate.EDV)
    return [
      {
        info: "Cada questão está relacionada a, no máximo, 2 critérios; que devem ser avaliados individualmente a partir do vídeo de apresentação da candidata. Nem sempre a candidata responderá a todas as questões de maneira linear, por isso é importante e fundamental INTERAGIR com o material enviado na íntegra. Caso surjam dúvidas em algum caso em específico, levar para o encontro diário para deliberarmos em conjunto.",
        question: "Compartilhe com a gente a sua história.",
        criterions: [
          {
            type: "MOTIVATION_HISTORY",
            goal: "Conhecer uma parte da história da candidata, avaliar aspectos que demonstrem seu interesse no curso.",
            label:
              "Impacto na vida da candidata (entender o nível de vontade; expressão corporal, tempo de vídeo). Para formulário, se a candidata descreveu de forma detalhada com argumentos alinhado com perfil esperado do curso. Exemplo: Nível de vontade, história inspiradora, expectativa de crescimento na carreira. Para audio (tempo de áudio até 3 minutos - apresenta de forma detalhada com argumentos alinhado com perfil esperado do curso.",
            points: [
              {
                info: "A candidata não faz referência a sua trajetória e não detalha como impacta de forma positiva em sua vida.",
                value: 0,
              },
              {
                info: "A candidata declara ter interesse em participar do curso, mas não conecta sua motivação com fatores de sua trajetória.",
                value: 5,
              },
              {
                info: "A candidata declara ter interesse em participar do curso e conecta coerentemente com elementos de sua trajetória, bem como entende que as ferramentas de tecnologia, transição de carreira, tem o potencial para transformar sua vida, da sua familia, comunidades e sociedade de forma ética e justa.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question: "Por que você quer fazer o curso?",
        criterions: [
          {
            type: "APPLY_SHARE",
            goal: "Observar se a candidata demonstra interesse em desenvolver sua liderança, bem como, compartilhar de alguma forma o conhecimento adquirido durante o curso. Ex: Ser professora em edutch e/ou liderar treinamento da sua equipe exercendo papel de tech lead (Especialista técnica) ou squad lead (gestão de pessoas tech ); empreender ou participar de uma comunidade de tecnologia.",
            label: "Compartilhamento.",
            points: [
              {
                info: "A candidata não expressa o desejo de perpetuar aquilo que está aprendendo na reprograma.",
                value: 0,
              },
              {
                info: "A candidata demonstra interesse em compartilhar  os conhecimentos adquiridos na reprograma, mas não deixa evidente como. Ex: ensinar a amiga, quero compartilhar meus conhecimentos depois do curso.",
                value: 5,
              },
              {
                info: "A candidata demonstra interesse em compartilhar os conhecimentos na reprograma, seja em organizações similares a reprograma ou ingressar em mercado tech. Ex: reprograma; Alura; descomplica; rocketseat; tera e comunidades como R Ladies, Py Ladies dentre outras. Quero trabalhar como voluntaria.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Fale sobre o seu nível de conhecimento. Na sua avaliação, seu nível de conhecimento e/ou experiência em programação é: introdutório, básico, intermediário ou avançado? Ao indicar seu nível, justifique.",
        criterions: [
          {
            type: "KNOWLEDGE",
            goal: "Avaliar na apresentação qual o nível de conhecimento em programação.",
            label:
              "Demonstra conhecimento técnico na sua apresentaçao em vídeo, áudio ou texto.",
            points: [
              {
                info: "A candidata não comunica ou não possui conhecimento básico em programação (Obs: Introdutório não classifica para o curso).",
                value: 0,
              },
              {
                info: "A candidata possui conhecimento básico; intermediário; avançado  e relata interesse em se aperfeiçoar em programação mas não detalha.",
                value: 5,
              },
              {
                info: "A candidata apresenta conhecimento básico;intermediário; avançado  em programação e cita alguns tópicos que estudou, demonstrando  motivação em aprofundar seu conhecimento.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Nos conte o seu ponto de vista sobre programas como este, voltados prioritariamente para inclusão de mulheres negras, trans e travestis e qual o impacto você acha que eles podem trazer para a sociedade.",
        criterions: [
          {
            type: "DIVERSITY",
            goal: "Identificar se a canditada tem discernimento e familiaridade sobre questões relacionadas à diversidade e inclusão.",
            label:
              "Empatia, inclusão, pensamento crítico, familiaridade com questões de diversidade.",
            points: [
              {
                info: "A candidata não faz nenhuma referência sobre a pergunta ou declara ser indiferente ou menciona que ações afirmativas são injustas para as pessoas que não são parte destes grupos, já que todas são iguais perante a lei.",
                value: 0,
              },
              {
                info: "A candidata reconhece que existem injustiças sociais e consegue classificar que elas existem em detrimento de classe, gênero, raça e ou outras intersecções. Exemplo: Lei de cotas nas universidades, ter nome social, racismo, consegue entender o tipo de violência.",
                value: 5,
              },
              {
                info: "A candidata reconhece que existem injustiças sociais e consegue classificar que elas existem em detrimento de classe, gênero, raça e outras intersecções, além de, afirmar que ações afirmativas são estratégias eficazes para reduzir as desigualdades sociais, pois são oriundas de um processo de exclussão histórico e estrutural.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Compartilhe uma experiência em que você repassou conhecimentos para outra(s) pessoa(s) e como isso influenciou na sua construção como indivíduo.",
        criterions: [
          {
            type: "EXPERIENCE_IMPACT",
            goal: "Identificar se a candidata de alguma forma tem experiência em facilitar a aprendizagem de outras pessoas.",
            label:
              "Avaliar se a candidata tem experiência de forma indicar o seu interesse em ensinar/treinar e ser líder.",
            points: [
              {
                info: "A candidata não compartilha experiências em que repassou conhecimentos para outras pessoas e não cita como isso a influenciou em sua vida.",
                value: 0,
              },
              {
                info: "A candidata teve poucas experiências em compartilhar conhecimento, mas agora pretende fazê-lo e acredita que pode ter um impacto em sua vida. Ex apoei grupo de trabalho, auxiliei minhas amigas, liderei de alguma forma em um pequeno momento.",
                value: 5,
              },
              {
                info: "A candidata demonstra sólidas experiências onde repassou conhecimento. Ex: já liderou, foi professora, já tem experiência de alguma forma na área de educação.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question:
          "Para finalizar a apresentação, descreva  uma situação desafiadora que você enfrentou recentemente e como você lidou com ela.",
        criterions: [
          {
            type: "CHALLENGING_SITUATION",
            goal: "Identificar se a candidata tem  colaboração reconhecendo a importância do trabalho em equipe e como ele contribui para alcançar objetivos comuns  e em aprendizado uma mentalidade de aprendizado contínuo e se reflete sobre suas experiências para melhorar.",
            label:
              "Avaliar parte comportamental da candidata diante das situações apresentadas.",
            points: [
              {
                info: "A candidata não compartilha colaboração reconhecendo a importância do trabalho em equipe e como ele contribui para alcançar objetivos comuns  e em aprendizado uma mentalidade de aprendizado contínuo e se reflete sobre suas experiências para melhorar.",
                value: 0,
              },
              {
                info: "A candidata demonstra parcialmente colaboração, se reconhece a importância do trabalho em equipe e como ele contribui para alcançar objetivos comuns  e aprendizado: Se a candidata demonstra uma mentalidade de aprendizado contínuo e se reflete sobre suas experiências para melhorar.",
                value: 5,
              },
              {
                info: "A candidata demonstra total habilidade comportamental em termos de colaboração e aprendizado contínuo.",
                value: 10,
              },
            ],
          },
        ],
      },
      {
        question: "Respondeu todas as questões solicitadas",
        criterions: [
          {
            type: "ALL_QUESTIONS",
            goal: "Avaliar a preparação da canditata para a apresentação do vídeo e/ou audio, assim como seu engajamento.",
            label:
              "A candidata pontua caso tenha respondido a todas as questões solicitadas.",
            points: [
              {
                info: "A candidata respondeu até 3 questões, sendo um engajamento muito baixo e contribuindo pouco para avaliação.",
                value: 0,
              },
              {
                info: "A candidata respondeu todas as questões, mas não aprofundou com base nos critérios avaliativos pontos importantes do roteiro de forma clara e objetiva.",
                value: 5,
              },
              {
                info: "A candidata respondeu a todas as questões solicitadas e aprofundou pontos importantes do roteiro, além de ter informado, de forma clara e objetiva.",
                value: 10,
              },
            ],
          },
        ],
      },
    ];

  return [];
};
