import React, { MouseEventHandler } from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const VisibilityOff = ({
  widthIcon,
  heightIcon,
  onClick,
  className,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
    onClick={onClick}
    className={className}
  >
    <path
      fill="#606060"
      fillRule="evenodd"
      d="M19.587 3L2.62 19.615l-.002-.001-.002.001L4.03 21l3.357-3.287c1.257.574 2.78.952 4.614.952 7.592 0 9.874-6.48 9.896-6.545l.104-.31-.105-.309c-.014-.041-.929-2.642-3.515-4.552L21 4.383 19.587 3zM8.913 16.218l1.797-1.76c.392.186.83.29 1.29.29 1.642 0 3-1.33 3-2.937 0-.45-.107-.88-.296-1.263l2.26-2.213c1.767 1.19 2.62 2.8 2.92 3.476-.5 1.13-2.562 4.896-7.884 4.896-1.184 0-2.207-.187-3.087-.49z"
      clipRule="evenodd"
    />
    <path
      fill="#606060"
      d="M14.5 5.212c-.761-.163-1.593-.255-2.5-.255-7.592 0-9.874 6.479-9.896 6.544l-.104.31.105.31c.01.031.57 1.622 2.044 3.226l1.392-1.362c-.787-.872-1.227-1.727-1.424-2.174.499-1.13 2.56-4.896 7.883-4.896.252 0 .498.008.736.025L14.5 5.212z"
    />
  </svg>
);

export default VisibilityOff;
