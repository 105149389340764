/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Button, Modal, TextArea } from "../../../components";
import { updateUser } from "../../../store/actions/userActions";
import { RootState } from "../../../store/reducers";

interface Props {
  openModal: boolean;
  onClose: () => void;
  setUser: Dispatch<SetStateAction<any>>;
}

const Bio = ({ setUser, openModal, onClose }: Props): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const [_user, _setUser] = useState(user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.profile?.name && !_user?.profile?.name) _setUser({ ...user });
  }, [_user, user]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      _setUser({
        ..._user,
        profile: {
          ..._user?.profile,
          bio: e.target.value,
        },
      }),
    [_user],
  );

  const onSave = useCallback(() => {
    setUser(_user);
    dispatch(updateUser({ ..._user }) as unknown as Action);
    onClose();
  }, [_user, dispatch, onClose, setUser]);

  return (
    <Modal
      headerAction
      textHeader="Sobre"
      open={openModal}
      onClose={onClose}
      bodyClassName="modal-profile height-fit-content"
    >
      <TextArea
        id="bio"
        label="Bio"
        maxLength={400}
        value={_user?.profile?.bio ?? ""}
        onChange={handleChange}
        className="bio-input"
      />
      <div className="flex-row-center margin-top-48 margin-top-32-mobile margin-top-0-mobile space-between flex-direction-column-reverse">
        <Button
          text="Cancelar"
          className="width-200 margin-right-0-mobile margin-right-32 margin-left-0-mobile margin-top-20-mobile"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button text="Salvar" onClick={onSave} className="width-200" />
      </div>
    </Modal>
  );
};

export default memo(Bio);
