/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  ConsentForm,
  Layout,
  ModalFixedHeader,
  ConsentFormCompany,
} from "../../components";
import Person from "../../../assets/images/Person.svg";
import { contractType, userBootcampsStatus } from "../../types/enumerators";
import {
  isAdmin as isAdminUtils,
  isCorporate as isCorporateUtils,
  isStudent as isStudentUtils,
} from "../../utils";
import {
  createUserContracts,
  getContractsByType,
  getUserContractsByContractId,
} from "../../services/functions";
import "./style.scss";
import { RootState } from "../../store/reducers";
import { Errors } from "..";

const Welcome = (): JSX.Element => {
  const [acceptedPersonalData, setAcceptedPersonalData] = useState(false);
  const [acceptedSensitiveData, setAcceptedSensitiveData] = useState(false);
  const [acceptedTalentBank, setaAcceptedTalentBank] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalCompany, setOpenModalCompany] = useState(false);
  const history = useHistory();
  const user = useSelector((state: RootState) => state.userState);
  const isStudent = isStudentUtils(user);
  const isCorporate = isCorporateUtils(user);
  const isAdmin = isAdminUtils(user);

  const onAccept = useCallback(async () => {
    const type = isAdmin
      ? contractType.USER_ADMIN
      : isCorporate
      ? contractType.USER_CORPORATE
      : isStudent
      ? contractType.USER_STUDENT
      : contractType.USER_CANDIDATE;

    const contract = await getContractsByType(type);
    const i = contract ? contract.length - 1 : 0;

    if (contract && contract[i]?._id) {
      const currentContract = await getUserContractsByContractId(
        user?._id,
        contract[i]?._id ?? "",
      );

      if (!currentContract || !currentContract?.length) {
        const data = {
          userId: user?._id ?? "",
          contractId: contract[i]?._id ?? "",
          date: new Date(),
          accepted: true,
        };
        await createUserContracts(data);
      }
    }

    if (isAdmin) history.push("/admin-account");
    else if (isCorporate) history.push("/company-register");
    else if (isStudent) history.push("/register");
    else history.push("/course-welcome");
  }, [history, isAdmin, isCorporate, isStudent, user?._id]);

  const onClickAcceptTerm = useCallback(() => {
    setAcceptedPersonalData(true);
    setAcceptedSensitiveData(true);
    setOpenModal(false);
  }, []);

  const onClickAcceptDataTalent = useCallback(() => {
    setaAcceptedTalentBank(true);
    setOpenModalCompany(false);
  }, []);

  const finished = useMemo(
    () =>
      isCorporate ||
      !!user?.bootcamps?.find(
        (item: { status: string }) =>
          item?.status === userBootcampsStatus.FINISHED,
      ),
    [isCorporate, user?.bootcamps],
  );

  return !finished ? (
    <Errors error={403} />
  ) : (
    <Layout className="welcome">
      <div className="welcome-content welcome-column">
        <p className="welcome-title">Seja bem-vinda à&nbsp;</p>
        <p className="welcome-bold">{"{reprograma}"}</p>
        <p className="welcome-exclamation">!</p>
      </div>
      <p className="grid-column-3-11 grid-row-3 welcome-subtitle welcome-column">
        {isCorporate
          ? "Aqui você irá encontrar sua próxima programadora para fazer a diferença no seu time de tecnologia."
          : "Aqui começa sua jornada para transformar a realidade do setor de tecnologia para mulheres."}
      </p>
      <p className="grid-column-3-5 grid-row-4 welcome-label welcome-column">
        Vamos lá?
      </p>

      <div className="grid-row-5 grid-column-3-6 flex welcome-column">
        <Checkbox
          id="acceptedPersonalData"
          checked={acceptedPersonalData}
          onChange={() => {
            if (!acceptedPersonalData) setOpenModal(true);
            else setAcceptedPersonalData(false);
          }}
        />
        <label htmlFor="acceptedPersonalData" className="welcome-accept">
          Aceitar os&nbsp;
          <label
            htmlFor="acceptedPersonalData"
            className="welcome-term"
            onClick={() => setOpenModal(true)}
          >
            Termos de Consentimento para Tratamentos de Dados Pessoais
          </label>
        </label>
      </div>
      <div className="grid-row-6 grid-column-3-6 flex welcome-column">
        <Checkbox
          id="acceptedSensitiveData"
          checked={acceptedSensitiveData}
          onChange={() => {
            if (!acceptedSensitiveData) setOpenModal(true);
            else setAcceptedSensitiveData(false);
          }}
        />
        <label htmlFor="acceptedSensitiveData" className="welcome-accept">
          Aceitar os&nbsp;
          <label
            htmlFor="acceptedSensitiveData"
            className="welcome-term"
            onClick={() => setOpenModal(true)}
          >
            Termos de Consentimento para Tratamentos de Dados Sensíveis
          </label>
        </label>
      </div>
      {isCorporate && (
        <div className="grid-row-7 grid-column-3-6 flex welcome-column">
          <Checkbox
            id="acceptedTalentBank"
            checked={acceptedTalentBank}
            onChange={() => {
              if (!acceptedTalentBank) setOpenModalCompany(true);
              else setaAcceptedTalentBank(false);
            }}
          />
          <label htmlFor="acceptedTalentBank" className="welcome-accept">
            Aceitar os&nbsp;
            <label
              htmlFor="acceptedTalentBank"
              className="welcome-term"
              onClick={() => setOpenModalCompany(true)}
            >
              Termos e Condições de uso da Plataforma "Banco de Talentos"
            </label>
          </label>
        </div>
      )}

      <Button
        className="grid-row-8 grid-column-1-3"
        text="Iniciar"
        onClick={onAccept}
        disabled={
          isCorporate
            ? !acceptedPersonalData ||
              !acceptedSensitiveData ||
              !acceptedTalentBank
            : !acceptedPersonalData || !acceptedSensitiveData
        }
      />

      <Person className="grid-column-7-10 grid-row-8 welcome-person" />
      <ModalFixedHeader
        headerAction
        textHeader="POLÍTICA DE PRIVACIDADE DA {REPROGRAMA}"
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="welcome-modal-term"
      >
        <ConsentForm />
        <div className="flex justify-center">
          <Button
            text="Sim"
            onClick={onClickAcceptTerm}
            className="welcome-modal-term-button"
          />
        </div>
      </ModalFixedHeader>
      <ModalFixedHeader
        headerAction
        textHeader="Termos e Condições de uso da Plataforma Banco de Talentos"
        open={openModalCompany}
        onClose={() => setOpenModalCompany(false)}
        className="welcome-modal-term"
      >
        <ConsentFormCompany />
        <div className="flex justify-center">
          <Button
            text="Sim"
            onClick={onClickAcceptDataTalent}
            className="welcome-modal-term-button"
          />
        </div>
      </ModalFixedHeader>
    </Layout>
  );
};

export default memo(Welcome);
