import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Check = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <circle cx="14" cy="13.999" r="11.667" fill="#0ED2A3" />
    <path
      fill="#fff"
      stroke="#fff"
      strokeWidth=".343"
      d="M11.666 17.112l-2.94-2.942-.122-.12-.121.12-1.021 1.021-.121.121.12.122 4.084 4.083.121.121.122-.121 8.75-8.75.12-.121-.12-.121-1.021-1.021-.122-.121-.12.12-7.609 7.609z"
    />
  </svg>
);

export default Check;
