import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { TextArea } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateUser } from "../../../store/actions/userActions";
import { RootState } from "../../../store/reducers";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const CurriculumGeneral = ({ setIsValid }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [_curriculum, _setCurriculum] = useState(user?.curriculum);

  useEffect(() => {
    if (
      Object.keys(user?.curriculum)?.length &&
      !Object.keys(_curriculum)?.length
    )
      _setCurriculum({ ...user?.curriculum });
  }, [_curriculum, user?.curriculum]);

  const handleOnBlur = useCallback(() => {
    if (
      _curriculum?.openForProposals !== undefined &&
      _curriculum?.openForChanges !== undefined &&
      _curriculum?.openForFreelancer !== undefined &&
      _curriculum?.goal
    ) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [_curriculum, dispatch, setIsValid, user]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      prop: string,
    ) => _setCurriculum({ ..._curriculum, [prop]: event.target.value }),
    [_curriculum],
  );

  const handleChangeRadio = useCallback(
    (value: string, props) => {
      _setCurriculum({
        ..._curriculum,
        [props]: value === props,
      });

      if (
        _curriculum?.openForProposals !== undefined &&
        _curriculum?.openForChanges !== undefined &&
        _curriculum?.openForFreelancer !== undefined &&
        _curriculum?.goal
      ) {
        setIsValid(true);
        dispatch(
          setNextButton(() =>
            dispatch(
              updateUser({
                ...user,
                curriculum: { ..._curriculum, [props]: value === props },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      } else setIsValid(false);
    },
    [_curriculum, dispatch, setIsValid, user],
  );

  return (
    <div className="grid display-block">
      <p className="title-input grid-row-1">
        Diz pra gente: qual é seu principal objetivo de carreira?
      </p>
      <TextArea
        id="goal"
        label="Meu objetivo é..."
        maxLength={80}
        value={_curriculum?.goal ?? ""}
        onChange={(e) => handleChange(e, "goal")}
        onBlur={handleOnBlur}
        className="title-input grid-row-2 goals-input width-100 margin-12-20-mobile"
      />
      <div>
        <p className="title-input grid-row-3">
          Você tem disponibilidade/interesse em mudar de cidade para trabalhar?
        </p>
        <RadioGroup
          aria-label="openForChanges"
          name="openForChanges"
          value={
            _curriculum?.openForChanges !== undefined
              ? _curriculum?.openForChanges
                ? "openForChanges"
                : "isnOpenChanges"
              : ""
          }
          onChange={(e) => handleChangeRadio(e.target.value, "openForChanges")}
        >
          <div>
            <FormControlLabel
              value="openForChanges"
              control={<Radio />}
              label="Sim"
            />
            <FormControlLabel
              value="isnOpenChanges"
              control={<Radio />}
              label="Não"
            />
          </div>
        </RadioGroup>
      </div>
      <div>
        <p className="title-input grid-row-4">
          Você tem disponibilidade/interesse em trabalho freelancer?
        </p>
        <RadioGroup
          aria-label="openForFreelancer"
          name="openForFreelancer"
          value={
            _curriculum?.openForFreelancer !== undefined
              ? _curriculum?.openForFreelancer
                ? "openForFreelancer"
                : "isnOpenFreelancer"
              : ""
          }
          onChange={(e) =>
            handleChangeRadio(e.target.value, "openForFreelancer")
          }
        >
          <div>
            <FormControlLabel
              value="openForFreelancer"
              control={<Radio />}
              label="Sim"
            />
            <FormControlLabel
              value="isnOpenForFreelancer"
              control={<Radio />}
              label="Não"
            />
          </div>
        </RadioGroup>
      </div>
      <div>
        <p className="title-input grid-row-5">
          Você quer mostrar no seu perfil que deseja participar de processos
          seletivos?
        </p>
        <p className="sub-title-input">
          Não se preocupe, as empresas sempre poderão te convidar para
          participar.
        </p>

        <RadioGroup
          aria-label="openForProposals"
          name="openForProposals"
          value={
            _curriculum?.openForProposals !== undefined
              ? _curriculum?.openForProposals
                ? "openForProposals"
                : "isnProposals"
              : ""
          }
          onChange={(e) =>
            handleChangeRadio(e.target.value, "openForProposals")
          }
        >
          <div>
            <FormControlLabel
              value="openForProposals"
              control={<Radio />}
              label="Sim"
            />
            <FormControlLabel
              value="isnProposals"
              control={<Radio />}
              label="Não"
            />
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

export default memo(CurriculumGeneral);
