/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
import React, {
  memo,
  useEffect,
  useState,
  useMemo,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import "./style.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Down, CheckIcon } from "../../../assets/icons";
import {
  BlockRed,
  Check,
  ClosedIcon,
  AvatarEmpty,
  CompanyAvatarEmpty,
  ChevronUp,
  Unblock,
} from "../../../assets/customIcons";
import CardCandidature from "./CardCandidature";
import {
  Layout,
  HeaderProfile,
  CardMenuLeft,
  Button,
  TextArea,
  Modal,
} from "../../components";
import {
  addCompanyToUser,
  unsubscriptInVacancy,
  changeVacancySubscription,
  vacanciesPerUser,
  getUserById,
  candidateMessageCompanyVacancy,
} from "../../services/functions";
import {
  classVacancySubscriptionStatus,
  placementVacancySubscriptionStatus,
  userRoles,
} from "../../types/enumerators";
import { RootState } from "../../store/reducers";
import { isCorporate } from "../../utils";
import { setLoad } from "../../store/actions/configurationsActions";

interface SubedJobsProps {
  jobTitle?: string;
  desiredSkills?: Array<{
    key?: string;
    level?: number;
  }>;
  companyName?: string;
  jobStatus?: string;
  jobTypeOfCandidature?: string;
  jobManagedBy?: string;
  currentStatus?: string;
  jobType?: string;
  jobPlace?: string;
  photo?: string;
  _id?: string;
  subsId?: string;
  jobDescription?: string;
  startDate?: string | Date;
  vacanciesStages?: Array<{
    autoClose?: boolean;
    autoOpen?: boolean;
    id?: string;
    order?: number;
    title?: string;
    enableRecruiterContact?: boolean;
  }>;
  stages?: Array<{
    stageId?: string;
    autoClose?: boolean;
    autoOpen?: boolean;
    id?: string;
    order?: number;
    title?: string;
    status?: string;
  }>;
  messages?: Array<{
    userId?: string;
    text?: string;
    date?: any;
  }>;
}

interface PropsCandidature {
  isSelected?: string;
  showMainCard?: boolean;
  isActiveSection?: boolean;
  vacanciesSubscribed?: SubedJobsProps[] | [];
  handleClick: (id: string) => void;
  selectedVacancy?: SubedJobsProps;
  messages?: {
    type: string;
    text: string;
    photo: string;
  }[];
  verifyEnabledContact?: any;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  handleUnsubVacancy?: () => void;
  subMenu?: {
    active?: boolean;
    onClick: (e: { stopPropagation: () => void }) => void;
    title?: string;
  }[];
}

interface PropsCandidatureMobile {
  vacanciesSubscribed?: SubedJobsProps[] | [];
  handleClick: (id: string) => void;
  isActiveSection?: boolean;
  isSelected?: string;
  selectedVacancy?: SubedJobsProps;
  showMainCard?: boolean;
  messages?: {
    type: string;
    text: string;
    photo: string;
  }[];
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  handleUnsubVacancy?: () => void;
}

const RenderCandidature = ({
  isSelected,
  showMainCard,
  isActiveSection,
  vacanciesSubscribed,
  handleClick,
  selectedVacancy,
  messages,
  verifyEnabledContact,
  setOpenModal,
  handleUnsubVacancy,
  subMenu,
}: PropsCandidature): JSX.Element => {
  const labelSkilllevel = useCallback((level) => {
    switch (level) {
      case 1:
        return "básico";
      case 2:
        return "intermediário";
      case 3:
        return "avançado";
      default:
        return "";
    }
  }, []);

  return (
    <div
      className={` margin-bottom-32 margin-top-16-responsive ${
        isSelected && showMainCard
          ? "card-candidature card-candidature-100"
          : "card-candidature"
      }`}
    >
      <div className="card-title-div padding-bottom-12">
        <h2 className="card-title"> Candidaturas </h2>
        {subMenu?.map((item) => (
          <button
            key={Math.random()}
            type="button"
            className={`margin-left-24  ${item?.active && "active"}`}
            onClick={item?.onClick}
          >
            {item?.title}
          </button>
        ))}
      </div>
      <div className="flex">
        <div className="card-candidature_scroll card-candidature-area">
          {vacanciesSubscribed?.length ? (
            vacanciesSubscribed?.map((vacancy) => (
              <div
                key={Math.random()}
                role="none"
                onClick={() => handleClick(vacancy?._id ?? "")}
                className="div-border-bottom pointer"
              >
                <CardCandidature
                  data={vacancy}
                  isActiveSection={isActiveSection}
                  alreadyInProcess
                  selectedId={isSelected === vacancy._id}
                />
              </div>
            ))
          ) : (
            <p className="margin-left-24">Não há candidaturas</p>
          )}
        </div>
        {showMainCard ? (
          <div className="show-main-card candidature-main-card">
            <Accordion>
              <AccordionSummary
                expandIcon={<Down />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <h2 className="title-accordion">Informações da vaga</h2>
              </AccordionSummary>
              <AccordionDetails>
                {selectedVacancy?.desiredSkills &&
                  selectedVacancy?.desiredSkills.length > 0 && (
                    <>
                      <h3 className="title-accordion">Habilidades</h3>
                      <div className="candidature-skill-vacancy-container">
                        {selectedVacancy.desiredSkills?.map((skill) => (
                          <div
                            key={Math.random()}
                            className="candidature-skill-vacancy"
                          >
                            {`${skill?.key} (`}
                            <span className="italic-text">
                              {labelSkilllevel(skill?.level)}
                            </span>
                            )
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                <p className="p-accordion height-fit">
                  {selectedVacancy?.jobDescription}
                </p>
              </AccordionDetails>
            </Accordion>
            <div className="divider-card margin-bottom-24 " />
            <div className="margin-left-24">
              <h2 className="title-steps">Etapas</h2>
              <div
                className={`vacancy-steps ${
                  !isActiveSection && "finished-process"
                } `}
              >
                {isActiveSection ? (
                  selectedVacancy?.vacanciesStages?.map((step, index) => (
                    <div key={Math.random()} className="step connect">
                      {selectedVacancy?.stages &&
                      selectedVacancy?.stages.find(
                        (item) =>
                          item?.stageId === step?.id &&
                          (item?.status ===
                            placementVacancySubscriptionStatus.APPROVED ||
                            item?.status ===
                              placementVacancySubscriptionStatus.IN_PROCESS),
                      ) ? (
                        <>
                          <div className="svg-connect">
                            <Check widthIcon={28} heightIcon={28} />
                            {selectedVacancy &&
                            selectedVacancy?.vacanciesStages &&
                            index <
                              (selectedVacancy?.vacanciesStages?.length ?? 0) -
                                1 ? (
                              <div className="connecting-line" />
                            ) : null}
                          </div>
                          <span className="title-approved">{step?.title}</span>
                        </>
                      ) : (
                        <>
                          <div className="icon">{index + 1}</div>
                          <span>{step?.title}</span>
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <>
                    {/* this map is to run only on finished vacancies */}
                    {selectedVacancy?.jobStatus !==
                      placementVacancySubscriptionStatus.IN_PROCESS &&
                      selectedVacancy?.vacanciesStages?.map(
                        (step) =>
                          selectedVacancy?.stages &&
                          selectedVacancy?.stages.find(
                            (item) =>
                              (item?.stageId === step?.id &&
                                item?.status ===
                                  classVacancySubscriptionStatus.APPROVED) ||
                              (item?.stageId === step?.id &&
                                item?.status ===
                                  classVacancySubscriptionStatus.DISAPPROVED),
                          ) && (
                            <div key={Math.random()} className="step connect">
                              <div className="finished-step">
                                <CheckIcon />
                              </div>
                              <span className="title-approved">
                                {step?.title}
                              </span>
                            </div>
                          ),
                      )}
                    {!isActiveSection && (
                      <div className="step connect">
                        <div className="finished-step">
                          <ClosedIcon />
                        </div>
                        <span>Vaga Encerrada</span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {!isActiveSection && (
              <div className="candidature-finished-wrapper">
                <BlockRed />
                <p>Não aceita mais candidaturas</p>
              </div>
            )}
            <div className="divider-card margin-top-20" />
            <Accordion>
              <AccordionSummary
                expandIcon={<Down />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                className="margin-bottom-6"
              >
                <h2 className="title-accordion"> Mensagens </h2>
              </AccordionSummary>

              <AccordionDetails
                className={`candidature-accordion-message ${
                  messages && messages?.length > 0 ? "has-messages" : ""
                }`}
              >
                {messages && messages?.length > 0 ? (
                  <div className="candidature-accordion-messages">
                    {messages?.map((msg) => (
                      <div
                        className="flex-row-center candidature-accordion-item"
                        key={Math.random()}
                      >
                        <div>
                          {msg?.photo ? (
                            <img
                              src={msg?.photo}
                              alt="Foto"
                              className="avatar-message"
                            />
                          ) : msg?.type === userRoles.CORPORATE ? (
                            <CompanyAvatarEmpty
                              className="company-avatar-empty"
                              heightIcon={48}
                              widthIcon={48}
                            />
                          ) : (
                            <AvatarEmpty className="company-avatar-empty" />
                          )}
                        </div>
                        <p className="msg-text">{msg?.text}</p>
                      </div>
                    ))}
                  </div>
                ) : isActiveSection ? (
                  <p className="not-message">Você ainda não possui mensagens</p>
                ) : (
                  <p className="not-message">Você não possui mensagens</p>
                )}
                {isActiveSection &&
                  verifyEnabledContact &&
                  (messages && messages?.length > 0 ? (
                    <p
                      onClick={() => setOpenModal(true)}
                      className="start-a-conversation button-send-message"
                    >
                      Enviar mensagem
                    </p>
                  ) : (
                    <p
                      onClick={() => setOpenModal(true)}
                      className="start-a-conversation margin-start-talk"
                    >
                      Iniciar conversa
                    </p>
                  ))}
              </AccordionDetails>
            </Accordion>
            {isActiveSection && (
              <>
                <div className="divider-card margin-bottom-20" />
                <div className="candidature-button-wrapper">
                  <Button
                    onClick={handleUnsubVacancy}
                    text="Cancelar candidatura"
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="display-none"> </div>
        )}
      </div>
    </div>
  );
};

const RenderCandidatureMobile = ({
  vacanciesSubscribed,
  handleClick,
  isActiveSection,
  isSelected,
  selectedVacancy,
  showMainCard,
  messages,
  setOpenModal,
  handleUnsubVacancy,
}: PropsCandidatureMobile): JSX.Element => {
  const labelSkilllevel = useCallback((level) => {
    switch (level) {
      case 1:
        return "básico";
      case 2:
        return "intermediário";
      case 3:
        return "avançado";
      default:
        return "";
    }
  }, []);
  return (
    <div>
      {vacanciesSubscribed && vacanciesSubscribed?.length ? (
        vacanciesSubscribed?.map((vacancy) => (
          <Accordion key={Math.random()} className="margin-top-0 header-modal">
            <AccordionSummary
              expandIcon={<ChevronUp widthIcon={24} heightIcon={24} />}
              className="expanded-icon margin-mui-expanded margin-bottom-accordion"
            >
              <div className="card-candidature-area">
                <div
                  key={Math.random()}
                  role="none"
                  onClick={() => handleClick(vacancy?._id ?? "")}
                  className="div-border-bottom pointer"
                >
                  <CardCandidature
                    data={vacancy}
                    isActiveSection={isActiveSection}
                    alreadyInProcess
                    selectedId={isSelected === vacancy._id}
                  />
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                {showMainCard ? (
                  <div>
                    <Accordion className="border-top-1">
                      <AccordionSummary
                        expandIcon={
                          <ChevronUp widthIcon={24} heightIcon={24} />
                        }
                        className="expanded-icon"
                      >
                        <h2 className="title-accordion">Informações da vaga</h2>
                      </AccordionSummary>
                      <AccordionDetails>
                        <h3 className="title-accordion">Habilidades</h3>
                        <div className="candidature-skill-vacancy-container">
                          {selectedVacancy?.desiredSkills &&
                            selectedVacancy.desiredSkills?.map((skill) => (
                              <div
                                key={Math.random()}
                                className="candidature-skill-vacancy"
                              >
                                {`${skill?.key} (`}
                                <span className="italic-text">
                                  {labelSkilllevel(skill?.level)}
                                </span>
                                )
                              </div>
                            ))}
                        </div>
                        <p className="p-accordion height-fit">
                          {selectedVacancy?.jobDescription}
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <div className="divider-card margin-bottom-24 " />
                    <div className="margin-left-24">
                      <h2 className="title-steps">Etapas</h2>
                      <div
                        className={`vacancy-steps ${
                          !isActiveSection && "finished-process"
                        } `}
                      >
                        {isActiveSection ? (
                          selectedVacancy?.vacanciesStages?.map(
                            (step, index) => (
                              <div key={Math.random()} className="step connect">
                                {selectedVacancy?.stages &&
                                selectedVacancy?.stages.find(
                                  (item) =>
                                    item?.stageId === step?.id &&
                                    (item?.status ===
                                      classVacancySubscriptionStatus.APPROVED ||
                                      item?.status ===
                                        classVacancySubscriptionStatus.IN_PROCESS),
                                ) ? (
                                  <>
                                    <div className="svg-connect">
                                      <Check widthIcon={20} heightIcon={20} />
                                      {selectedVacancy &&
                                      selectedVacancy?.vacanciesStages &&
                                      index <
                                        (selectedVacancy.vacanciesStages
                                          .length ?? 0) -
                                          1 ? (
                                        <div className="connecting-line" />
                                      ) : null}
                                    </div>
                                    <span className="title-approved">
                                      {step?.title}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <div className="icon">{index + 1}</div>
                                    <span>{step?.title}</span>
                                  </>
                                )}
                              </div>
                            ),
                          )
                        ) : (
                          <>
                            {/* this map is to run only on finished vacancies */}
                            {selectedVacancy?.jobStatus !==
                              classVacancySubscriptionStatus.IN_PROCESS &&
                              selectedVacancy?.vacanciesStages?.map(
                                (step) =>
                                  selectedVacancy?.stages &&
                                  selectedVacancy?.stages.find(
                                    (item) =>
                                      (item?.stageId === step?.id &&
                                        item?.status ===
                                          classVacancySubscriptionStatus.APPROVED) ||
                                      (item?.stageId === step?.id &&
                                        item?.status ===
                                          classVacancySubscriptionStatus.DISAPPROVED),
                                  ) && (
                                    <div
                                      key={Math.random()}
                                      className="step connect"
                                    >
                                      <div className="finished-step">
                                        <CheckIcon />
                                      </div>
                                      <span className="title-approved">
                                        {step?.title}
                                      </span>
                                    </div>
                                  ),
                              )}
                            {!isActiveSection && (
                              <div className="step connect">
                                <div className="finished-step">
                                  <ClosedIcon widthIcon={20} heightIcon={20} />
                                </div>
                                <span>Processo encerrado</span>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {!isActiveSection && (
                      <div className="candidature-finished-wrapper">
                        <BlockRed widthIcon={20} heightIcon={20} />
                        <p>Não aceita mais candidaturas</p>
                      </div>
                    )}
                    <div className="divider-card margin-top-20" />
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<Down />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                        className="margin-bottom-6"
                      >
                        <h2 className="title-accordion"> Mensagens </h2>
                      </AccordionSummary>

                      <AccordionDetails>
                        {messages && messages?.length > 0 ? (
                          messages?.map((msg) => (
                            <div
                              className="flex-row-center margin-msg-mobile"
                              key={Math.random()}
                            >
                              <div>
                                {msg?.photo ? (
                                  <img
                                    src={msg?.photo}
                                    alt="Foto"
                                    className="avatar-message"
                                  />
                                ) : (
                                  <AvatarEmpty className="company-avatar-empty" />
                                )}
                              </div>
                              <p className="msg-text">{msg?.text}</p>
                            </div>
                          ))
                        ) : (
                          <div className="flex space-between">
                            {isActiveSection ? (
                              <p className="not-message">
                                Você ainda não possui mensagens
                              </p>
                            ) : (
                              <p className="not-message">
                                Você não possui mensagens
                              </p>
                            )}
                          </div>
                        )}
                        <div className="width-100 flex flex-end">
                          {isActiveSection &&
                            (messages && messages?.length > 0 ? (
                              <p
                                onClick={() => setOpenModal(true)}
                                className="start-a-conversation button-send-message"
                              >
                                Enviar mensagem
                              </p>
                            ) : (
                              <p
                                onClick={() => setOpenModal(true)}
                                className="start-a-conversation margin-start-talk"
                              >
                                Iniciar conversa
                              </p>
                            ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {isActiveSection && (
                      <>
                        <div className="divider-card margin-bottom-20" />
                        <div className="candidature-button-wrapper">
                          <Button
                            onClick={handleUnsubVacancy}
                            text="Cancelar candidatura"
                          />
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="display-none"> </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <div className="card-candidature margin-top-16-responsive">
          <p className="margin-left-24 not-proposed">Não há candidaturas</p>
        </div>
      )}
    </div>
  );
};

const Candidature = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isSelected, setIsSelected] = useState("");
  const [selectedVacancy, setSelectedVacancy] = useState<SubedJobsProps>();
  const [messages, setMessages] = useState<
    | {
        type: string;
        text: string;
        photo: string;
      }[]
    | undefined
  >();
  const [showMainCard, setShowMainCard] = useState(false);
  const [isActiveSection, setIsActiveSection] = useState(true);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [message, setMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [vacanciesSubscribed, setVacanciesSubscribed] = useState<
    SubedJobsProps[] | [] | undefined
  >();
  const user = useSelector((state: RootState) => state.userState);
  const dispatch = useDispatch();

  const onClick = useCallback((e, isActive) => {
    e.stopPropagation();
    setMessages(undefined);
    setVacanciesSubscribed(undefined);
    setIsActiveSection(isActive);
  }, []);

  const subMenu = useMemo(
    () => [
      {
        title: "Ativas",
        onClick: (e: { stopPropagation: () => void }) => onClick(e, true),
        active: isActiveSection,
      },
      {
        title: "Finalizadas",
        onClick: (e: { stopPropagation: () => void }) => onClick(e, false),
        active: !isActiveSection,
      },
    ],
    [isActiveSection, onClick],
  );

  const verifyEnabledContact = useMemo(() => {
    const findStage = selectedVacancy?.stages?.find(
      ({ status }) => status === placementVacancySubscriptionStatus.IN_PROCESS,
    );

    const verify = selectedVacancy?.vacanciesStages?.find(
      ({ id }) => id === findStage?.stageId,
    )?.enableRecruiterContact;

    return verify;
  }, [selectedVacancy?.stages, selectedVacancy?.vacanciesStages]);

  const getMessages = useCallback(async () => {
    const messsagesList: any = [];

    if (selectedVacancy?.messages && selectedVacancy?.messages?.length) {
      for (let i = 0; i < selectedVacancy?.messages?.length; i += 1) {
        const responseUser = await addCompanyToUser(
          await getUserById(selectedVacancy?.messages[i]?.userId ?? ""),
        );
        messsagesList.push({
          photo: responseUser?.profile?.photo ?? "",
          text: selectedVacancy?.messages[i]?.text ?? "",
          type: isCorporate(responseUser)
            ? userRoles.CORPORATE
            : userRoles.STUDENT,
        });
      }
    }
    setMessages(messsagesList);
  }, [selectedVacancy?.messages]);

  useEffect(() => {
    if (selectedVacancy) getMessages();
  }, [getMessages, selectedVacancy]);

  useEffect(() => {
    setSelectedVacancy({});
    setShowMainCard(false);
  }, [isActiveSection]);

  const loadSubscritedJobs = useCallback(async () => {
    try {
      let candidatedJobs;

      if (isActiveSection) {
        candidatedJobs = await vacanciesPerUser(
          user._id,
          placementVacancySubscriptionStatus.IN_PROCESS,
        );

        candidatedJobs = candidatedJobs?.filter((job) => {
          if (job?.placementVacancyData?.stages) {
            const proposalItem = job?.placementVacancyData?.stages.find(
              (item: { title: string }) => item?.title === "Proposta",
            );
            return !job?.stages?.find(
              (item) => item?.stageId === proposalItem?.id,
            );
          }

          return null;
        });
      } else {
        candidatedJobs = await vacanciesPerUser(user._id);
        candidatedJobs = candidatedJobs?.filter((job) => job?.stages);
      }

      const candidatedJobsMapped =
        candidatedJobs?.map((job) => ({
          _id: job?.placementVacancyData?._id,
          subsId: job?._id,
          jobTitle: job?.placementVacancyData?.title,
          desiredSkills: job?.placementVacancyData?.desiredSkills,
          companyName: job?.companyData?.profile?.name,
          jobStatus: job?.placementVacancyData?.status,
          jobTypeOfCandidature: job?.placementVacancyData?.typeOfCandidature,
          jobManagedBy: job?.placementVacancyData?.managedBy,
          currentStatus: "Candidatada",
          jobType: job?.placementVacancyData?.type,
          jobPlace: job?.placementVacancyData?.place,
          photo: job?.companyData?.profile?.logo,
          jobDescription: job?.placementVacancyData?.description,
          startDate: job?.startDate,
          vacanciesStages: job?.placementVacancyData?.stages,
          stages: job?.stages,
          messages: job?.messages,
        })) ?? [];
      setVacanciesSubscribed(candidatedJobsMapped);
    } catch (err) {
      toast.error("Ocorreu um erro ao carregar as candidaturas");
    }
  }, [isActiveSection, user._id]);

  useEffect(() => {
    if (!vacanciesSubscribed || !messages)
      dispatch(setLoad(true) as unknown as Action);
    else dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, messages, vacanciesSubscribed]);

  useEffect(() => {
    loadSubscritedJobs();
  }, [message, shouldUpdate, loadSubscritedJobs]);

  useEffect(() => {
    if (selectedVacancy?.vacanciesStages?.length) {
      selectedVacancy?.vacanciesStages?.sort((a: any, b: any) => {
        if (a.startDate && b.startDate) {
          if (moment(a.startDate).isAfter(b.startDate)) return 1;
          if (moment(a.startDate).isBefore(b.startDate)) return -1;
          return 0;
        }
        return 0;
      });
    }
  }, [selectedVacancy?.vacanciesStages]);

  const handleClick = useCallback(
    (id: string) => {
      setIsSelected(id);
      setSelectedVacancy(
        vacanciesSubscribed?.find((item) => item?._id === id) ?? {},
      );
      setShowMainCard(true);
    },
    [vacanciesSubscribed],
  );

  const handleUnsubVacancy = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    try {
      await unsubscriptInVacancy(selectedVacancy?._id ?? "", user._id);
      setSelectedVacancy({});
      setShowMainCard(false);
      setShouldUpdate(true);
    } catch (err) {
      toast.error("Ocorreu um erro ao se desinscrever da vaga");
    } finally {
      dispatch(setLoad(false) as unknown as Action);
    }
  }, [dispatch, selectedVacancy?._id, user._id]);

  const onSend = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const payload = { messages: selectedVacancy?.messages };

    const msg = {
      text: message,
      date: moment().tz("America/Sao_Paulo").toISOString(),
      userId: user._id,
    };

    if (payload.messages && payload.messages?.length)
      payload.messages?.push(msg);
    else payload.messages = [msg];

    try {
      await changeVacancySubscription(
        selectedVacancy?.subsId ?? "",
        payload as {
          messages: {
            userId: string;
            text: string;
            date: any;
          }[];
        },
      ).then(() =>
        candidateMessageCompanyVacancy(user._id, selectedVacancy?._id ?? ""),
      );

      toast.success("Mensagem enviada com sucesso!");
    } catch (err) {
      toast.error("Ocorreu um erro ao enviar sua mensagem");
    }
    setOpenModal(false);
    setMessage("");
    getMessages();
    dispatch(setLoad(false) as unknown as Action);
  }, [
    dispatch,
    getMessages,
    message,
    selectedVacancy?._id,
    selectedVacancy?.messages,
    selectedVacancy?.subsId,
    user._id,
  ]);

  const onClose = useCallback(() => {
    setOpenModal(false);
    setMessage("");
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-template-column-1 grid-responsive candidature">
      <HeaderProfile />
      <div className="grid-column-1-11">
        <div className="grid-template-columns-1-5 display-block">
          <CardMenuLeft
            selectedMenuTitle="Candidaturas"
            subMenu={subMenu}
            icon={<Unblock widthIcon={20} heightIcon={20} />}
          />
          {width > 990 ? (
            <RenderCandidature
              isSelected={isSelected}
              showMainCard={showMainCard}
              isActiveSection={isActiveSection}
              vacanciesSubscribed={vacanciesSubscribed}
              handleClick={handleClick}
              selectedVacancy={selectedVacancy}
              messages={messages}
              verifyEnabledContact={verifyEnabledContact}
              setOpenModal={setOpenModal}
              handleUnsubVacancy={handleUnsubVacancy}
              subMenu={subMenu}
            />
          ) : (
            <RenderCandidatureMobile
              vacanciesSubscribed={vacanciesSubscribed}
              handleClick={handleClick}
              isActiveSection={isActiveSection}
              isSelected={isSelected}
              selectedVacancy={selectedVacancy}
              showMainCard={showMainCard}
              messages={messages}
              setOpenModal={setOpenModal}
              handleUnsubVacancy={handleUnsubVacancy}
            />
          )}
        </div>
        <Modal
          headerAction
          textHeader="Converse com a empresa"
          open={openModal}
          onClose={onClose}
        >
          <div className="width-310 width-100-mobile">
            <TextArea
              id="message"
              label="Escreva sua mensagem*"
              maxLength={500}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              textFielRow={8}
            />
            <div className="flex-row-center margin-btn-message flex-direction-column-reverse">
              <Button
                text="Cancelar"
                className="button-secondary width-100"
                onClick={onClose}
              />
              <Button
                text="Enviar"
                className="margin-left-24 margin-left-0-mobile width-100"
                onClick={onSend}
                disabled={!message}
              />
            </div>
          </div>
        </Modal>
      </div>
    </Layout>
  );
};

export default memo(Candidature);
