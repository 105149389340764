import React, { CSSProperties, memo } from "react";

interface Props {
  value: number;
  style?: CSSProperties;
}

const ProgressValue = ({ value, style }: Props): JSX.Element => (
  <div
    className="progress-value-component"
    style={{
      paddingLeft: `${value}%`,
      ...style,
    }}
  >
    {`${value}%`}
  </div>
);

export default memo(ProgressValue);
