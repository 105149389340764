/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { toast } from "react-toastify";
import { FileImage } from "../../../../assets/icons";
import { Button, Modal } from "../../../components";
import api from "../../../services/api";
import "./styles.scss";

interface Props {
  company: any;
  openModal: boolean;
  onClose: () => void;
  setCompany: Dispatch<SetStateAction<any>>;
}

const Cover = ({
  company,
  openModal,
  onClose,
  setCompany,
}: Props): JSX.Element => {
  const [_company, _setCompany] = useState(company);

  const onChangeImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      api
        .post("/fileUpload", data)
        .then((result) => {
          _setCompany({
            ...company,
            profile: {
              ...company?.profile,
              cover: result?.data?.file_url.url,
            },
          });
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    },
    [company],
  );
  const onRemove = useCallback(() => {
    _setCompany({
      ..._company,
      profile: { ..._company.profile, cover: "" },
    });
  }, [_company]);

  return (
    <Modal
      headerAction
      textHeader="Editar foto da capa"
      open={openModal}
      onClose={onClose}
      bodyClassName="modal-profile"
    >
      <div className="flex-col align-items">
        <input
          id="addPhoto"
          accept="image/*"
          className="display-none"
          type="file"
          multiple={false}
          onChange={onChangeImage}
        />
        <label htmlFor="addPhoto">
          {_company?.profile?.cover ? (
            <img
              src={_company?.profile?.cover}
              alt="imagem"
              className="add-img-cover-modal margin-top-8"
            />
          ) : (
            <FileImage />
          )}
        </label>
        {!_company?.profile?.cover && (
          <p className="text-help-cover">
            Clique aqui para adicionar uma imagem | Tamanho: 1024x200 |
            Formatos: png e jpg
          </p>
        )}
      </div>
      <div className="flex-row-center flex-end margin-top-48 margin-top-0-mobile flex-direction-column-reverse">
        {_company?.profile?.cover && (
          <button
            type="button"
            className="button-avatar-delet margin-top-20-mobile"
            onClick={onRemove}
          >
            Excluir imagem
          </button>
        )}
        <Button
          text="Cancelar"
          className="width-160 margin-right-24 margin-right-0-responsive"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button
          text="Salvar"
          onClick={() => {
            onClose();
            setCompany({
              ...company,
              profile: {
                ...company?.profile,
                cover: _company.profile.cover,
              },
            });
          }}
          className="width-160"
        />
      </div>
    </Modal>
  );
};

export default memo(Cover);
