import React, { ChangeEvent } from "react";
import { Checkbox, CardSearch } from "..";
import "./styles.scss";

interface UserProps {
  _id: string;
  personName: string;
  personPosition: string;
  personCity: string;
  personDescription: string;
  personPhoto: string;
  personEmail: string;
}

interface Props {
  blank?: boolean;
  person?: UserProps;
  handleCheck?: (id: string, event: ChangeEvent<HTMLInputElement>) => void;
  hasVacancies?: boolean;
  selectedUsers?: UserProps[];
  setChoosedUserId?: (id: string) => void;
  setOpenModalInvite?: (bool: boolean) => void;
}

const UserCard = ({
  blank,
  person,
  handleCheck,
  selectedUsers,
  hasVacancies,
  setChoosedUserId,
  setOpenModalInvite,
}: Props): JSX.Element =>
  !blank ? (
    <div key={Math.random()} role="none" className="user-card width-100">
      <div
        className={
          selectedUsers?.find((item) => item._id === person?._id)
            ? "row-selected"
            : ""
        }
      >
        {hasVacancies && (
          <Checkbox
            className="user-card-checkbox Checkboxcolor"
            id="sendMessage"
            onChange={(e) => {
              if (handleCheck && person) handleCheck(person?._id, e);
            }}
            checked={
              selectedUsers &&
              person &&
              !!selectedUsers?.find((item) => item._id === person?._id)
            }
          />
        )}
        <CardSearch
          person
          id={person?._id ?? ""}
          personName={person?.personName}
          personPosition={person?.personPosition}
          personCity={person?.personCity}
          personDescription={person?.personDescription}
          personPhoto={person?.personPhoto}
          className="padding-left-56"
          onClick={() => {
            if (setOpenModalInvite) setOpenModalInvite(true);
            if (setChoosedUserId) setChoosedUserId(person?._id ?? "");
          }}
          hasVacancies={hasVacancies}
          sendInvite={!selectedUsers?.length}
        />
      </div>
    </div>
  ) : (
    <p key={Math.random()} className="user-card-unavailable">
      Não há nenhum usuário que corresponde a busca
    </p>
  );

export default UserCard;
