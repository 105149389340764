import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const LinkedinLogin = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#1D65C3"
      fillRule="evenodd"
      d="M16 29.333c7.364 0 13.333-5.97 13.333-13.333 0-7.364-5.97-13.334-13.333-13.334C8.636 2.666 2.666 8.636 2.666 16S8.636 29.333 16 29.333zm-5.746-18.158c.95 0 1.72-.77 1.72-1.721 0-.95-.77-1.72-1.72-1.72s-1.72.77-1.72 1.72.77 1.72 1.72 1.72zm-1.72 1.475h3.518v10.816H8.533V12.65zm13.064.115l.037.012c1.511.394 2.631 1.756 2.631 3.39v7.3H20.75v-6.884c-1.122-2.72-3.78.984-3.78.984v5.9h-3.52V12.65h3.52v2.068c.428-.576 1.729-2.068 3.78-2.068.24 0 .445.026.632.064.048.008.095.016.142.027l.075.024z"
      clipRule="evenodd"
    />
  </svg>
);

export default LinkedinLogin;
