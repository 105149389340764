import React, {
  useMemo,
  useState,
  useEffect,
  ChangeEvent,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import { MediaEditProps } from "./types";
import { Delete } from "../../../../assets/icons";
import { mediaType, classVacancyType } from "../../../types/enumerators";
import { updateClassVacancySubscription } from "../../../services/functions";
import api from "../../../services/api";
import {
  Input,
  Button,
  CardMedia,
  IconButton,
  AttachmentMedia,
} from "../../../components";
import "../style.scss";

const MediaEdit = ({
  subscription,
  course,
  setIsEdit,
  reload,
}: MediaEditProps): JSX.Element => {
  const [remove, setRemove] = useState(false);
  const [fileName, setFileName] = useState("");
  const [video, setVideo] = useState<FormData | null>(null);
  const [newLink, setNewLink] = useState("");

  const stageMedia = useMemo(() => {
    if (subscription && course) {
      const media = course?.stages?.find(
        (item) => item?.type === classVacancyType.UPLOAD_MEDIAS,
      );

      const findUserMedia = subscription?.stages?.find(
        ({ stageId }) => stageId === media?.stage_id,
      );

      if (findUserMedia) return findUserMedia;
    }

    return null;
  }, [subscription, course]);

  const handleSubmit = useCallback(async () => {
    let form;

    if (newLink) {
      form = {
        ...subscription?.form,
        media: {
          type: mediaType.LINK,
          url: newLink,
        },
      };

      await updateClassVacancySubscription(subscription?._id ?? "", form);
    }

    if (video) {
      await api
        .post("/fileUpload", video)
        .then(async (result) => {
          form = {
            ...subscription?.form,
            media: {
              url: result?.data?.file_url.url,
              type: video?.get("file")?.type?.includes("audio")
                ? mediaType.AUDIO
                : mediaType.VIDEO,
            },
          };

          await updateClassVacancySubscription(subscription?._id ?? "", form);
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    }

    if (remove) {
      form = {
        ...subscription?.form,
        media: {
          type: subscription?.form?.media?.type,
          url: "",
        },
      };
      await updateClassVacancySubscription(subscription?._id ?? "", form);

      setRemove(false);
    }

    reload();
    setIsEdit(false);
  }, [
    newLink,
    reload,
    remove,
    setIsEdit,
    subscription?._id,
    subscription?.form,
    video,
  ]);

  const handleLink = useCallback(
    (e: { key: string; target: { value: string } }) => {
      setRemove(false);

      if (e.key === "Enter") {
        setNewLink(e.target.value);
        e.target.value = "";
      }
    },
    [],
  );

  const handleMedia = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    setRemove(false);

    if (!fileList || !fileList?.length) return;

    const newData = new FormData();
    newData?.append("file", fileList[0]);

    setFileName(fileList[0]?.name);
    setVideo(newData);
  }, []);

  const handleOnBlur = useCallback((e: { target: { value: string } }) => {
    setNewLink(e.target.value);
    e.target.value = "";
    setRemove(false);
  }, []);

  const media = useMemo(
    () => subscription?.form?.media,
    [subscription?.form?.media],
  );

  const formStage = useMemo(() => stageMedia?.form, [stageMedia?.form]);

  useEffect(() => {
    if (subscription && media) {
      if (media?.type === mediaType.VIDEO || media?.type === mediaType.AUDIO)
        setFileName(media?.url);

      if (media?.type === mediaType.LINK) setNewLink(media?.url);
    }
  }, [subscription, media]);

  return (
    <div className="subscriptions-media-form">
      <div className={`subscriptions-media ${formStage ? "form-media" : ""}`}>
        <div className="subscriptions-media-file">
          <div>
            <h4>Cole o link de compartilhamento no campo abaixo:</h4>
            <div className="link-input-video">
              <Input
                disabled={!!video || !!newLink || fileName !== ""}
                label="Link"
                type="text"
                helperText="Pressione Enter para adicionar"
                onKeyDown={handleLink}
                onBlur={handleOnBlur}
                className="input-link"
              />
              <div className="flex-row-center">
                <a
                  href={newLink}
                  target="_blank"
                  className="a-class"
                  rel="noreferrer"
                >
                  {newLink}
                </a>
                {newLink && (
                  <IconButton
                    icon={<Delete />}
                    onClick={() => {
                      setNewLink("");
                      setRemove(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div>
            <h4>
              Mas se preferir, você pode fazer upload do arquivo aqui mesmo:
            </h4>
            {!video && (
              <div className={`margin-top-32 ${newLink ? "disabled" : ""}`}>
                <AttachmentMedia
                  className="lable-media"
                  onChange={handleMedia}
                  type="video/*, audio/*"
                  disabled={!!newLink || !!video}
                />
              </div>
            )}
            {(video || fileName) && (
              <div className="margin-top-32 subscriptions-media-card">
                <CardMedia
                  item={{
                    title: fileName,
                  }}
                  cantEdit
                  onRemove={() => {
                    setVideo(null);
                    setRemove(true);
                    setFileName("");
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="admin-subscriptions-infos-buttons margin-top-32">
        <Button
          text="Cancelar"
          outline
          appearance="secondary"
          onClick={() => setIsEdit(false)}
        />
        <Button
          text="Salvar"
          className="margin-left-32"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default MediaEdit;
