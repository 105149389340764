/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useHistory, useParams } from "react-router-dom";
import { InputAdornment, TableRow, TextField } from "@material-ui/core";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  HeaderProfile,
  Modal,
  Select,
  TextArea,
  Pagination,
  InputDate,
  MessageVacancyManagement,
  Table,
} from "../../components";
import { Search } from "../../../assets/icons";
import "./style.scss";
import { RootState } from "../../store/reducers";
import {
  downVacancyStage,
  filterPlacementVacancySubscription,
  getMessagesBySubscriptionsOfAVacancy,
  getPages,
  getPlacementVacancy,
  proposalUserVacancy,
  rejectUserFromVacancyStage,
  restoreRejectedUser,
  updateAVacancy,
  upVacancyStage,
} from "../../services/functions";
import { AvatarEmpty, Check } from "../../../assets/customIcons";
import {
  ManagedBy,
  VacancySubscriptionStatus,
} from "../../types/constants/Vacancy";
import { setLoad } from "../../store/actions/configurationsActions";
import { PlacementVacancy } from "../../types/interfaces";
import {
  placementVacancyManagedBy,
  placementVacancyTypeOfCandidature,
} from "../../types/enumerators";
import GreenhouseLogo from "../../../assets/images/Greenhouse.png";
import GupyLogo from "../../../assets/images/Gupy.png";

interface Filters {
  name?: string | null;
  stageId?: string | null;
  status?: string | null;
  startDate?: Date | null;
  endDate?: Date | null;
  skills?: string[] | null;
}

interface Config {
  sortBy: string;
  asc: boolean;
}

interface ParamsProps {
  id: string;
}

const CompanyVacancy = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(0);

  const [vacancy, setVacancy] = useState<PlacementVacancy>();
  const [data, setData] = useState<any[]>();

  const [totalFilteredList, setTotalFilteredList] = useState(0);
  const [inProposal, setInProposal] = useState<number>();
  const [proposal, setProposal] = useState("");
  const [listMessages, setListMessages] = useState<any[]>();

  const [openModal, setOpenModal] = useState(false);
  const [idSelected, setIdSelected] = useState("");

  const [filters, setFilters] = useState<Filters>({});
  const [config, setConfig] = useState<Config>({ asc: true, sortBy: "title" });

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const { id } = useParams<ParamsProps>();

  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.userState);
  const company = useSelector((state: RootState) => state.companyState);

  const ref = useRef<HTMLDivElement>(null);

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const proposalStage = useMemo(() => {
    let index = -1;
    const proposalId = vacancy?.stages?.find((item, i) => {
      index = i;
      return item?.title === "Proposta";
    })?.id;
    return { id: proposalId, index };
  }, [vacancy?.stages]);

  const getVacancy = useCallback(async () => {
    const response = await getPlacementVacancy(id);
    setVacancy(response as PlacementVacancy);
  }, [id]);

  useEffect(() => {
    if (!vacancy?._id && id) getVacancy();
  }, [getVacancy, id, vacancy?._id]);

  useEffect(() => {
    if (ref.current && listMessages) {
      const elem = ref.current.lastChild as HTMLElement;
      setHeight(elem.offsetHeight - 47);
    }
  }, [listMessages]);

  const getMessages = useCallback(async () => {
    const response =
      (await getMessagesBySubscriptionsOfAVacancy(
        vacancy?._id ?? "",
        company?._id,
      )) ?? [];
    setListMessages(response);
  }, [company?._id, vacancy?._id]);

  useEffect(() => {
    if (data && data?.length && !listMessages) getMessages();
  }, [data, dispatch, getMessages, listMessages]);

  const sort = useCallback(() => {
    vacancy?.stages?.sort((a: any, b: any) => {
      if (a.startDate && b.startDate) {
        if (moment(a.startDate).isAfter(b.startDate)) return 1;
        if (moment(a.startDate).isBefore(b.startDate)) return -1;
        return 0;
      }
      return 0;
    });
  }, [vacancy?.stages]);

  useEffect(() => {
    if (vacancy?.stages?.length) sort();
  }, [vacancy?.stages?.length, sort]);

  const headCells = useMemo(
    () => [
      {
        id: "photo",
        label: "",
        onclick: (item: any) => history.push(`/profile/${item}`),
        orderBy: false,
        asc: false,
        classColumn: "width-80",
      },
      {
        id: "name",
        label: "Nome",
        orderBy: true,
        sort: "user.name",
        asc: config?.asc ?? true,
        onclick: () =>
          setConfig((prev) => ({
            ...prev,
            asc: !prev?.asc,
            sortBy: "user.name",
          })),
        classColumn: "width-240",
      },
      {
        id: "currentStartDate",
        label: "Candidatura",
        orderBy: true,
        sort: "startDate",
        asc: config?.asc ?? true,
        onclick: () =>
          setConfig((prev) => ({
            ...prev,
            asc: !prev?.asc,
            sortBy: "startDate",
          })),
        classColumn: "width-160",
      },
      {
        id: "currentEndDate",
        label: "Cancelamento",
        orderBy: true,
        sort: "endDate",
        asc: config?.asc ?? true,
        onclick: () =>
          setConfig((prev) => ({
            ...prev,
            asc: !prev?.asc,
            sortBy: "endDate",
          })),
        classColumn: "width-160",
      },
      {
        id: "stage",
        label: "Etapa",
        orderBy: true,
        sort: "stage",
        asc: config?.asc ?? true,
        onclick: () =>
          setConfig((prev) => ({
            ...prev,
            asc: !prev?.asc,
            sortBy: "stage",
          })),
        classColumn: "width-160",
      },
      {
        id: "status",
        label: "Status",
        orderBy: true,
        sort: "status",
        asc: config?.asc ?? true,
        onclick: () =>
          setConfig((prev) => ({
            ...prev,
            asc: !prev?.asc,
            sortBy: "status",
          })),
        classColumn: "width-120",
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
        asc: false,
        hide: width < 991,
        classColumn: "width-160",
      },
    ],
    [config?.asc, history, width],
  );

  const getSubscriptions = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    if (pageLimit) {
      setMaxPage(
        await getPages(
          filterPlacementVacancySubscription,
          vacancy?._id,
          filters,
          true,
        ),
      );

      const result = (await filterPlacementVacancySubscription(
        vacancy?._id ?? "",
        {},
        true,
      )) as { size?: number }[];
      setTotalFilteredList(result?.length ? result[0]?.size ?? 0 : 0);

      const response = await filterPlacementVacancySubscription(
        vacancy?._id ?? "",
        filters,
        false,
        page,
        config?.sortBy,
        config?.asc,
      );

      setData(
        response?.map((item) => ({
          ...item,
          currentStartDate: moment(item?.startDate).format("DD/MM/YYYY"),
          currentEndDate: moment(item?.endDate).format("DD/MM/YYYY"),
        })) ?? [],
      );

      dispatch(setLoad(false) as unknown as Action);
    }

    dispatch(setLoad(false) as unknown as Action);
  }, [
    dispatch,
    pageLimit,
    vacancy?._id,
    filters,
    page,
    config?.sortBy,
    config?.asc,
  ]);

  useEffect(() => {
    if (vacancy?._id) getSubscriptions();
  }, [getSubscriptions, page, pageLimit, vacancy?._id]);

  const getTotalSubcriptionWithProposals = useCallback(async () => {
    const response = (await filterPlacementVacancySubscription(
      vacancy?._id ?? "",
      { stageId: proposalStage?.id },
      true,
    )) as { size?: number }[];
    setInProposal(response?.length ? response[0]?.size ?? 0 : 0);
  }, [vacancy?._id, proposalStage?.id]);

  useEffect(() => {
    if (vacancy?._id && proposalStage?.id && inProposal === undefined)
      getTotalSubcriptionWithProposals();
  }, [
    getTotalSubcriptionWithProposals,
    inProposal,
    proposalStage?.id,
    vacancy?._id,
  ]);

  const backStage = useCallback(
    async (item: any) => {
      dispatch(setLoad(true) as unknown as Action);
      try {
        await downVacancyStage(vacancy?._id ?? "", item?.userId);
        getSubscriptions();
        toast.success("Candidata voltou a etapa anterior com sucesso!");
      } catch (err) {
        toast.error("Ocorreu um erro ao voltar a candidata");
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, getSubscriptions, vacancy?._id],
  );

  const nextStage = useCallback(
    async (item: any) => {
      dispatch(setLoad(true) as unknown as Action);

      try {
        await upVacancyStage(vacancy?._id ?? "", item?.userId);
        getSubscriptions();

        if (item?.stages?.length === (vacancy?.stages?.length ?? 0) - 1) {
          setIdSelected(item?._id);
          setOpenModal(true);
        } else toast.success("Candidata avançou a etapa seguinte com sucesso!");
      } catch (err) {
        toast.error("Ocorreu um erro ao avançar a candidata");
      }

      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, getSubscriptions, vacancy?._id, vacancy?.stages?.length],
  );

  const rejectStage = useCallback(
    async (item: any) => {
      dispatch(setLoad(true) as unknown as Action);
      try {
        await rejectUserFromVacancyStage(vacancy?._id ?? "", item?.userId);
        toast.success("Candidata dispensada com sucesso!");
        getSubscriptions();
      } catch (err) {
        toast.error("Ocorreu um erro ao dispensar a candidata");
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, getSubscriptions, vacancy?._id],
  );

  const restoreStage = useCallback(
    async (item: any) => {
      dispatch(setLoad(true) as unknown as Action);
      try {
        await restoreRejectedUser(vacancy?._id ?? "", item?.userId);
        toast.success("Candidata restaurada com sucesso!");
        getSubscriptions();
      } catch (err) {
        toast.error("Ocorreu um erro ao restaurar a candidata");
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, getSubscriptions, vacancy?._id],
  );

  const handleChange = useCallback((e) => setProposal(e.target.value), []);

  const onSendProposal = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const payload = data?.find((item) => item?._id === idSelected) ?? {};
    payload.stages[(payload.stages?.length ?? 0) - 1].messages = [
      {
        text: proposal,
        date: moment().tz("America/Sao_Paulo").toISOString(),
        userId: user._id,
      },
    ];

    try {
      await updateAVacancy(vacancy?._id ?? "", payload?.userId, {
        stages: payload.stages,
      });
      await proposalUserVacancy(payload?.userId, vacancy?._id ?? "");
      toast.success("Proposta enviada com sucesso!");
    } catch (err) {
      await downVacancyStage(vacancy?._id ?? "", payload?.userId);
      toast.error("Ocorreu um erro ao enviar proposta");
    }
    dispatch(setLoad(false) as unknown as Action);
    setOpenModal(false);
    setProposal("");
  }, [data, dispatch, idSelected, proposal, user._id, vacancy?._id]);

  const onClose = useCallback(() => {
    setOpenModal(false);
    setProposal("");
    backStage(data?.find((item) => item?._id === idSelected) ?? {});
  }, [backStage, data, idSelected]);

  const onClickApprove = useCallback(
    async (listSelected) => {
      for (let i = 0; i < listSelected?.length; i += 1)
        await upVacancyStage(vacancy?._id ?? "", listSelected[i].userId);
      getSubscriptions();
    },
    [getSubscriptions, vacancy?._id],
  );

  const onClickDisapproved = useCallback(
    async (listSelected) => {
      for (let i = 0; i < listSelected?.length; i += 1)
        await rejectUserFromVacancyStage(
          vacancy?._id ?? "",
          listSelected[i].userId,
        );
      getSubscriptions();
    },
    [getSubscriptions, vacancy?._id],
  );

  const handleChangeFilters = useCallback(
    (value, props) => {
      if (props === "startDate" || props === "endDate")
        setFilters({ ...filters, [props]: moment(value).toISOString() });
      else setFilters({ ...filters, [props]: value });
    },
    [filters],
  );

  const handleChangeSkills = useCallback(
    (event: { target: { value: any } }) => {
      const {
        target: { value },
      } = event;
      const valueSkills = typeof value === "string" ? value.split(",") : value;

      const copyFiltersObject = { ...filters, skills: valueSkills };

      if (!valueSkills?.length) {
        delete copyFiltersObject?.skills;
      }

      setFilters(copyFiltersObject);
    },
    [filters],
  );

  const filterStageList = useMemo(() => {
    const list: any[] = [{ value: "", label: "Todas" }];
    vacancy?.stages?.forEach((item: { id: string; title: string }) => {
      list.push({
        value: item?.id,
        label: item?.title,
      });
    });
    return list;
  }, [vacancy?.stages]);

  const filterStatusList = useMemo(() => {
    const list: any[] = [{ value: "", label: "Todos" }];
    Object.keys(VacancySubscriptionStatus).forEach((key) => {
      list.push({
        value: key,
        label:
          VacancySubscriptionStatus[
            key as keyof typeof VacancySubscriptionStatus
          ],
      });
    });
    return list;
  }, []);

  const filterSkillsList = useMemo(() => {
    const list: { value: string; label: string }[] = [];
    if (vacancy?.desiredSkills)
      vacancy?.desiredSkills?.map((item: { key: string }) =>
        list.push({
          value: item?.key,
          label: item?.key,
        }),
      );
    return list;
  }, [vacancy?.desiredSkills]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const hasComments = useCallback(
    (row) =>
      !!row?.stages
        ?.reduce(
          (
            acc: { comments: { resolved?: boolean }[] },
            cur: { comments: { resolved?: boolean }[] },
          ) => [...(acc?.comments ?? []), ...(cur?.comments ?? [])],
        )
        ?.comments?.find(
          (comment: { resolved: boolean }) => !!comment && !comment?.resolved,
        ),
    [],
  );

  return (
    <div className="company-vacancy display-block margin-bottom-40 mobile-company-bottom">
      <HeaderProfile />
      {width > 990 && (
        <div className="company-vacancy-header">
          <div className="flex-row-center">
            <p className="view-page">Vagas &gt;&nbsp;</p>
            <p className="view-step">{vacancy?.title}</p>
          </div>

          <Button
            text="Editar"
            className="button-primary edit-vacancy-button"
            onClick={() =>
              history.push(`/company-vacancy-edit/${vacancy?._id}/${1}`)
            }
          />
        </div>
      )}
      <div className="company-vacancy-cards company-vacancy-cards--top">
        <div className="company-vacancy-cards-half">
          <Card
            removeTitleBorder
            titleHeader="Candidaturas"
            className="company-vacancy-card company-vacancy-card--is-number border-radius-mobile margin-bottom-16-mobile align-items"
          >
            <p className="title-vacancy margin-x-auto">{totalFilteredList}</p>
          </Card>

          <Card
            removeTitleBorder
            titleHeader="Propostas"
            onRedirect={() =>
              history.push(`/company-vacancy-proposals/${vacancy?._id}`)
            }
            className="company-vacancy-card  company-vacancy-card--is-number border-radius-mobile margin-bottom-16-mobile align-items"
          >
            <p
              className="title-vacancy margin-x-auto"
              onClick={() =>
                history.push(`/company-vacancy-proposals/${vacancy?._id}`)
              }
            >
              {inProposal}
            </p>
          </Card>
        </div>

        <Card
          // resultSearch
          removeTitleBorder
          titleHeader="Dados gerais"
          className="company-vacancy-card--full border-radius-mobile padding-bottom-24-mobile mobile-company-vacancy-card--full"
          onEdit={() =>
            history.push(`/company-vacancy-edit/${vacancy?._id}/${1}`)
          }
        >
          <div className="flex flex-wrap display-block ">
            {vacancy?.managedBy !== placementVacancyManagedBy.REPROGRAMA && (
              <div className="div-vacancie-area">
                {vacancy?.managedBy ===
                  placementVacancyManagedBy.GREENHOUSE && (
                  <img
                    className="green-house-logo"
                    alt="logo"
                    src={GreenhouseLogo}
                  />
                )}
                {vacancy?.managedBy === placementVacancyManagedBy.GUPY && (
                  <img className="gupy-logo" alt="logo" src={GupyLogo} />
                )}
                <div className="flex-col">
                  <p className="title-view-company">Administrada por</p>
                  <p className="view-bold">
                    {ManagedBy[vacancy?.managedBy as keyof typeof ManagedBy] ??
                      ""}
                  </p>
                </div>
              </div>
            )}
            <div className="margin-right-48 margin-bottom-24">
              <p className="title-view-company">Título</p>
              <p className="view-bold">{vacancy?.title ?? ""}</p>
            </div>
            <div className="margin-right-48 margin-bottom-24">
              <p className="title-view-company">Local</p>
              <p className="view-bold">{vacancy?.place ?? ""}</p>
            </div>
            <div className="margin-right-48 margin-bottom-24">
              <p className="title-view-company">Tipo de vaga</p>
              <p className="view-bold">{vacancy?.type ?? ""}</p>
            </div>
            <div className="margin-right-48 margin-bottom-24">
              <p className="title-view-company">Tipo de candidatura</p>
              <p className="view-bold">
                {
                  placementVacancyTypeOfCandidature[
                    vacancy?.typeOfCandidature as keyof typeof placementVacancyTypeOfCandidature
                  ]
                }
              </p>
            </div>
            <div className="margin-right-48 padding-bottom-24">
              <p className="title-view-company">Abertura e encerramento</p>
              <p className="view-bold">
                {`${moment(vacancy?.startDate).format("DD/MM/YYYY") ?? ""} - ${
                  moment(vacancy?.endDate).format("DD/MM/YYYY") ?? ""
                }`}
              </p>
            </div>
            <div>
              <p className="title-view-company">Descrição</p>
              <p className="view-bold">{vacancy?.description ?? ""}</p>
            </div>
          </div>
        </Card>
      </div>
      <div className="company-vacancy-cards company-vacancy-cards--bottom display-block">
        <Card
          removeTitleBorder
          style={{ maxHeight: height, minHeight: height }}
          titleHeader="Mensagens"
          onRedirect={() =>
            history.push(`/company-vacancy-messages/${vacancy?._id}`)
          }
          className="company-vacancy-card company-vacancy-cards--bottom-card border-radius-mobile margin-bottom-16-mobile"
        >
          <div
            className="title-vacancy"
            role="button"
            tabIndex={0}
            onClick={() =>
              history.push(`/company-vacancy-messages/${vacancy?._id}`)
            }
          >
            {listMessages && listMessages?.length > 0 ? (
              listMessages?.map((listMessage) => (
                <div
                  key={Math.random()}
                  className="flex-row-center margin-12-16 margin-48-16-mobile"
                >
                  {listMessage?.photo ? (
                    <img
                      src={listMessage?.photo}
                      alt="Foto"
                      className="img-attachment-photo-small border-radius-26 photo-list-message"
                    />
                  ) : (
                    <AvatarEmpty className="img-attachment-photo-small" />
                  )}
                  <div className="width-100">
                    <div className="list-message">
                      <p className="list-message-name">{listMessage?.name}</p>
                      <p className="list-message-date">
                        {moment(listMessage?.message?.date).format(
                          "DD/MM/YYYY",
                        )}
                      </p>
                    </div>
                    <div className="width-100">
                      <p className="list-message-text">
                        {listMessage?.message?.text}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="not-list-message">Não há mensagens</p>
            )}
          </div>
        </Card>

        <div className="company-vacancy-cards-half display-block">
          <Card
            removeTitleBorder
            style={{ maxHeight: height, minHeight: height }}
            titleHeader="Habilidades"
            className="card-skills-company-vacancy company-vacancy-card border-radius-mobile margin-bottom-16-mobile "
            onEdit={() =>
              history.push(`/company-vacancy-edit/${vacancy?._id}/${2}`)
            }
          >
            {vacancy?.desiredSkills &&
              vacancy?.desiredSkills?.length > 0 &&
              vacancy?.desiredSkills?.map((item, index) => (
                <div
                  key={Math.random()}
                  className={`flex-row-center ${
                    index < (vacancy?.desiredSkills?.length ?? 0) - 1
                      ? "margin-bottom-24"
                      : "margin-bottom-0"
                  }`}
                >
                  <div className="width-100">
                    <p className="name-skill">{item?.key}</p>
                    <p className="sub-name-skill">
                      {item?.level === 1
                        ? "Básico"
                        : item?.level === 2
                        ? "Intermediário"
                        : "Avançado"}
                    </p>
                  </div>
                  <div className="flex-row-center width-100">
                    <div className="knob" />
                    <div className={`knob ${item?.level < 2 ? "empty" : ""}`} />
                    <div className={`knob ${item?.level < 3 ? "empty" : ""}`} />
                  </div>
                </div>
              ))}
          </Card>

          <Card
            removeTitleBorder
            ref={ref}
            titleHeader="Etapas"
            className="company-vacancy-card border-radius-mobile margin-bottom-16-mobile"
            onEdit={() =>
              history.push(`/company-vacancy-edit/${vacancy?._id}/${3}`)
            }
          >
            {vacancy?.stages &&
              vacancy?.stages?.length > 0 &&
              vacancy?.stages?.map((item: { title: string }, index: number) => (
                <div
                  key={Math.random()}
                  className={`step connect ${
                    index < (vacancy?.stages?.length ?? 0) - 1
                      ? ""
                      : "margin-bottom-0"
                  }`}
                >
                  {proposalStage?.index >= index ? (
                    <div
                      className={`svg-connect conect-mobile ${
                        index < (vacancy?.stages?.length ?? 0) - 1 ? "line" : ""
                      }`}
                    >
                      <Check
                        widthIcon={width < 991 ? 20 : 28}
                        heightIcon={width < 991 ? 20 : 28}
                      />
                    </div>
                  ) : (
                    <div className="icon">{index + 1}</div>
                  )}
                  <span>{item?.title}</span>
                </div>
              ))}
          </Card>
        </div>
      </div>

      <Card
        removeTitleBorder
        titleHeader="Candidatas"
        className="margin-bottom-32 media-small-screen border-radius-mobile margin-bottom-16-mobile"
        bodyClassName="padding-0"
      >
        {width > 990 ? (
          <>
            <TableRow className="flex top-filters">
              <TextField
                autoComplete="off"
                id="searchVacancies"
                placeholder="Buscar candidata"
                type="text"
                InputProps={{
                  className:
                    "search-header-profile Mui-input headerSearch searchVacancy",
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                className="search-header search-filter margin-right-8"
                value={filters?.name ?? ""}
                onChange={(e) => handleChangeFilters(e.target.value, "name")}
                onBlur={getSubscriptions}
              />
              <InputDate
                className="status-filter open-filter-date margin-right-8"
                label="Candidatura"
                views={["year", "month", "date"]}
                format="DD/MM/yyyy"
                value={filters?.startDate ?? null}
                onChange={(value) => handleChangeFilters(value, "startDate")}
                onAccept={getSubscriptions}
                notAuto
              />
              <InputDate
                className="status-filter open-filter-date margin-right-8"
                label="Cancelamento"
                views={["year", "month", "date"]}
                format="DD/MM/yyyy"
                value={filters?.endDate ?? null}
                onChange={(value) => handleChangeFilters(value, "endDate")}
                onAccept={getSubscriptions}
                notAuto
              />
              <Select
                className="status-filter margin-right-8"
                id="step"
                label="Etapa"
                value={filters?.stageId ?? ""}
                onChange={(e) => handleChangeFilters(e.target.value, "stageId")}
                onBlur={getSubscriptions}
                options={filterStageList}
              />
              <Select
                multiple
                className="status-filter margin-right-8"
                id="skills"
                label="Habilidades"
                value={filters?.skills ?? []}
                onChange={handleChangeSkills}
                onBlur={getSubscriptions}
                options={filterSkillsList}
                renderValue={(selected) => selected.join(", ")}
              />
              <Select
                className="status-filter last-filter"
                id="status"
                label="Status"
                value={filters?.status ?? ""}
                onChange={(e) => handleChangeFilters(e.target.value, "status")}
                onBlur={getSubscriptions}
                options={filterStatusList}
              />
            </TableRow>
            <Table
              cantSelect={
                vacancy?.managedBy !== placementVacancyManagedBy.REPROGRAMA
              }
              emptyText="Não há candidaturas"
              headCells={headCells}
              list={data ?? []}
              onReload={getSubscriptions}
              onAllReject={onClickDisapproved}
              onAllAccept={onClickApprove}
              onNext={
                vacancy?.managedBy === placementVacancyManagedBy.REPROGRAMA
                  ? nextStage
                  : undefined
              }
              onBack={
                vacancy?.managedBy === placementVacancyManagedBy.REPROGRAMA
                  ? backStage
                  : undefined
              }
              onSendMessage={(row) =>
                history.push(
                  `/company-vacancy-messages/${vacancy?._id}/${row?._id}`,
                )
              }
              onReject={
                vacancy?.managedBy === placementVacancyManagedBy.REPROGRAMA
                  ? rejectStage
                  : undefined
              }
              onReactive={
                vacancy?.managedBy === placementVacancyManagedBy.REPROGRAMA
                  ? restoreStage
                  : undefined
              }
              onComment={(row) =>
                history.push(
                  `/company-vacancy-comments/${vacancy?._id}/${row?._id}`,
                )
              }
              onShow={(row) => history.push(`/profile/${row?.userId}`)}
              hasCommentUnresolved={hasComments}
            />
          </>
        ) : (
          <div className="padding-management margin-bottom-24">
            <MessageVacancyManagement />
          </div>
        )}
      </Card>
      {width < 991 && (
        <div className="dashboard-mobile">
          <Button
            text="Editar"
            className="button-primary edit-vacancy-button margin-bottom-24 margin-top-0 width-100-responsive"
            onClick={() =>
              history.push(`/company-vacancy-edit/${vacancy?._id}/${1}`)
            }
          />
        </div>
      )}

      <Modal
        headerAction
        textHeader="Converse com a candidata"
        open={openModal}
        onClose={onClose}
        bodyClassName="modal-talk-candidate "
      >
        <div className="width-350">
          <TextArea
            id="proposal"
            label="Escreva sua mensagem"
            maxLength={500}
            value={proposal}
            onChange={handleChange}
            textFielRow={10}
            textFieldClassName="text-area-proposal-vacancy"
          />

          <div className="flex-row-center margin-top-32 margin-left-modal flex-col-mobile gap-16-mobile">
            <Button
              text="Cancelar"
              outline
              className="width-100-mobile"
              onClick={onClose}
              appearance="secondary"
            />
            <Button
              text="Salvar"
              className="margin-left-32 width-100-mobile"
              onClick={onSendProposal}
              disabled={!proposal}
            />
          </div>
        </div>
      </Modal>
      {width > 990 && (
        <div className="flex">
          <p className="show-vacancies">
            Mostrando
            <span className="title-span">{data?.length}</span>
            de
            <span className="title-span">{totalFilteredList}</span>
            resultados
          </p>
          <Pagination page={page} setPage={setPage} maxPage={maxPage} />
        </div>
      )}
    </div>
  );
};

export default memo(CompanyVacancy);
