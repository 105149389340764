import moment from "moment";
import React, { memo } from "react";
import { AvatarEmpty } from "../../../../assets/customIcons";
import { Modal } from "../../../components";
import {
  ReasonsGeneral,
  ProfileGender,
  ProfileRace,
} from "../../../types/constants/User";
import { Class, UserBootcamps, UserProfile } from "../../../types/interfaces";
import "../style.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  item?: {
    profile?: UserProfile;
    guBootcamps?: Class;
    bootcamps?: UserBootcamps[];
    isGiveUp?: boolean;
  };
}

const View = ({ open, onClose, item }: Props): JSX.Element => (
  <Modal
    headerAction
    open={open}
    onClose={onClose}
    textHeader={`${item?.profile?.name} - Detalhes`}
  >
    <div className="view-details">
      <div className="flex-col view-details-photo">
        <div className="">
          {item?.profile?.photo ? (
            <img
              src={item?.profile?.photo}
              alt="imagem"
              className="view-details-img-avatar "
            />
          ) : (
            <AvatarEmpty
              widthIcon={75}
              heightIcon={75}
              className="img-attachment-photo"
            />
          )}
        </div>
        <p className="view-bold">{item?.profile?.name ?? "-"}</p>
      </div>
      <div className="flex-row-center flex-wrap view-details-infos display-block">
        <div className="flex-col margin-right-32 margin-bottom-32">
          <p className="view-details-title">CPF</p>
          <p className="view-bold">{item?.profile?.legalIdentity ?? "-"}</p>
        </div>
        <div className="flex-col margin-right-32 margin-bottom-32">
          <p className="view-details-title">Email</p>
          <p className="view-bold">{item?.profile?.email ?? "-"}</p>
        </div>
        <div className="flex-col margin-right-32 margin-bottom-32">
          <p className="view-details-title">Idade</p>
          <p className="view-bold">
            {moment().diff(
              moment(item?.profile?.birthdate, "DD/MM/YYYY"),
              "years",
            )}
          </p>
        </div>
        <div className="flex-col margin-right-32 margin-bottom-32">
          <p className="view-details-title">Gênero</p>
          <p className="view-bold">
            {ProfileGender[
              item?.profile?.genderIdentity as keyof typeof ProfileGender
            ] ?? "-"}
          </p>
        </div>
        <div className="flex-col margin-right-32 margin-bottom-32">
          <p className="view-details-title">Deficiência</p>
          <p className="view-bold">{item?.profile?.disabledPerson ?? "-"}</p>
        </div>
        <div className="flex-col margin-bottom-32">
          <div className="flex-row">
            <div className="margin-right-32">
              <p className="view-details-title">Raça</p>
              <p className="view-bold">
                {ProfileRace[item?.profile?.race as keyof typeof ProfileRace] ??
                  "-"}
              </p>
            </div>
            <div className="margin-right-32">
              <p className="view-details-title">Renda</p>
              <p className="view-bold">{item?.profile?.montlyRevenue ?? "-"}</p>
            </div>
            <div>
              <p className="view-details-title">Turma</p>
              <p className="view-bold">
                {item?.isGiveUp
                  ? "Lista de Espera"
                  : item?.guBootcamps?.title ?? ""}
              </p>
            </div>
          </div>
        </div>
        <div className="flex-col margin-right-32 margin-bottom-32 reasons">
          <p className="view-details-title">Motivo</p>
          <p className="view-bold">
            {item?.isGiveUp
              ? ReasonsGeneral[
                  item?.bootcamps?.find(
                    (i) => i?.classId === item?.guBootcamps?._id,
                  )?.statusReasons as keyof typeof ReasonsGeneral
                ]
              : "Lista de espera"}
          </p>
        </div>
      </div>
    </div>
  </Modal>
);

export default memo(View);
