/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Media, UserCurriculumEducation } from "../../../types/interfaces";
import { Add } from "../../../../assets/customIcons";
import {
  Button,
  Card,
  IconButton,
  ModalMedia,
  AddEducation,
  ViewEducation,
} from "../../../components";
import { updateUser } from "../../../store/actions/userActions";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import {
  addCompanyToUser,
  searchCompaniesAllStatus,
  setNewCompany,
} from "../../../services/functions";
import { companyType, companyStatus } from "../../../types/enumerators";
import { RootState } from "../../../store/reducers";

interface Props {
  title: string;
  addEducation: boolean;
  setAddEducation: Dispatch<SetStateAction<boolean>>;
  setIsOptional: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const Education = ({
  title,
  addEducation,
  setAddEducation,
  setIsOptional,
  setIsValid,
}: Props): JSX.Element => {
  const width = window.innerWidth;
  const [editIndex, setEditIndex] = useState(-1);
  const [editMediaIndex, setEditMediaIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [media, setMedia] = useState<Media>({} as Media);
  const [isCurrentEducation, setIsCurrentEducation] = useState(false);
  const [education, setEducation] = useState<UserCurriculumEducation>({
    companyId: "",
    level: "",
    title: "",
    scholarship: false,
    medias: [],
  });
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [_curriculum, _setCurriculum] = useState(user?.curriculum);

  useEffect(() => {
    if (_curriculum?.educations?.length) {
      setIsOptional(false);
      setIsValid(true);
    } else setIsOptional(true);
  }, [_curriculum?.educations, setIsOptional, setIsValid]);

  useEffect(() => {
    if (education?.medias?.length) {
      education?.medias?.sort((a: Media, b: Media) => {
        if ("order" in a && "order" in b) {
          if (Number(a.order) < Number(b.order)) return -1;
          if (Number(a.order) > Number(b.order)) return 1;
          return 0;
        }
        return 0;
      });
    }
  }, [education.medias]);

  const setCompany = useCallback(
    async (value: string | null) => {
      if (value && value !== "") {
        const company = await searchCompaniesAllStatus(value);

        if (!company?.length) {
          await setNewCompany(
            value,
            companyType.INSTITUTION,
            companyStatus.PENDING,
            companyStatus.NONE,
          );

          setCompany(value);
        } else {
          setEducation({
            ...education,
            companyId: company[0]._id ?? "",
          });
        }
      }
    },
    [education],
  );

  const onSave = useCallback(async () => {
    let { educations: newEducations } = _curriculum ?? { educations: [] };

    if (editIndex !== -1) {
      newEducations[editIndex] = education;
    } else if (newEducations?.length) newEducations.push(education);
    else newEducations = [education];

    setEducation({
      companyId: "",
      level: "",
      title: "",
      scholarship: false,
      medias: [],
    });
    setIsCurrentEducation(false);

    const newUser = {
      ...user,
      curriculum: { ...user?.curriculum, educations: newEducations },
    };

    const newUserWithCompany = await addCompanyToUser(newUser);

    _setCurriculum({ ...newUserWithCompany.curriculum });
    dispatch(updateUser({ ...newUser }) as unknown as Action);
    setAddEducation(false);
    setEditIndex(-1);
  }, [_curriculum, dispatch, editIndex, education, setAddEducation, user]);

  const onRemove = useCallback(
    (index: number) => {
      let { educations: oldEducations } = _curriculum;
      oldEducations.splice(index, 1);
      oldEducations = oldEducations.map(
        (item: UserCurriculumEducation, i: number) => ({
          ...item,
          order: i,
        }),
      );
      _setCurriculum({ ..._curriculum, educations: oldEducations });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, educations: oldEducations },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_curriculum, dispatch, user],
  );

  const onEdit = useCallback(
    (item: UserCurriculumEducation, index: number) => {
      if (!item.endDate) setIsCurrentEducation(true);
      setEditIndex(index);
      setAddEducation(true);
      setEducation({
        ...item,
        medias: item.medias ?? [],
      });
    },
    [setAddEducation],
  );

  const onCancel = useCallback(() => {
    setAddEducation(false);
    setEditIndex(-1);
    setEducation({
      companyId: "",
      level: "",
      title: "",
      scholarship: false,
      medias: [],
    });
    setIsCurrentEducation(false);
    setIsValid(true);
  }, [setAddEducation, setIsValid]);

  const reorderItems = useCallback(
    (items: UserCurriculumEducation[]) => {
      let { educations: newEducations } = _curriculum;
      newEducations = items.map((item, index) => ({
        ...item,
        order: index,
      }));
      _setCurriculum({ ..._curriculum, educations: newEducations });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, educations: newEducations },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_curriculum, dispatch, user],
  );

  const saveMedia = useCallback(() => {
    let { medias: newMedias } = education;

    if (newMedias?.length && editMediaIndex !== -1) {
      newMedias[editMediaIndex] = media;
    } else if (newMedias?.length) newMedias.push(media);
    else newMedias = [media];

    setEducation({ ...education, medias: newMedias });
    setMedia({} as Media);
    setEditMediaIndex(-1);
    setOpenModal(false);
  }, [editMediaIndex, education, media]);

  const handleChangeMedia = useCallback(
    (prop: string | number | symbol) =>
      (event: ChangeEvent<HTMLInputElement>) =>
        setMedia({ ...media, [prop]: event.target.value }),
    [media],
  );

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setEditMediaIndex(-1);
    setMedia({} as Media);
  }, []);

  return (
    <div
      className={`grid-column-5-12 grid-column-1-11-mobile margin-top-16 grid-7-column ${
        width > 990 ? "grid-row-1" : "grid-row-2"
      }`}
    >
      <Card
        borderNone
        titleHeader={title}
        className={`grid-column-1-8 height-fit-content ${
          addEducation || width < 991 ? "border-card" : ""
        }`}
      >
        <p className="paragraph-add">
          Agora queremos saber sobre sua formação. Adicione as informações dos
          cursos que já concluiu ou que ainda está fazendo.
        </p>
        {!addEducation ? (
          <IconButton
            icon={
              <Add
                widthIcon={width < 991 ? 20 : 24}
                heightIcon={width < 991 ? 20 : 24}
              />
            }
            text="Adicionar formação"
            onClick={() => setAddEducation(true)}
            className="icon-button-primary icon-button-primary-mobile"
          />
        ) : (
          <AddEducation
            education={education}
            setItem={setEducation}
            setIsValid={setIsValid}
            setIsCurrentEducation={setIsCurrentEducation}
          />
        )}
      </Card>
      {addEducation && (
        <div className="flex grid-column-4-8 grid-row-2 flex-direction-column-reverse">
          <Button
            text="Cancelar"
            className="width-50"
            appearance="secondary"
            outline
            onClick={onCancel}
          />
          <Button
            text="Salvar"
            className="margin-left-32 margin-left-mobile width-50"
            onClick={onSave}
            disabled={
              !education.companyId ||
              !education.level ||
              !education.title ||
              !education.startDate ||
              !education.description ||
              (!education.endDate && !isCurrentEducation)
            }
          />
        </div>
      )}
      {addEducation && _curriculum?.educations?.length > 0 && (
        <div className="grid-column-1-8 grid-row-3 divider" />
      )}
      {_curriculum?.educations?.length > 0 && (
        <div
          className={`grid-column-1-8 ${
            addEducation ? "grid-row-4" : "grid-row-2"
          }`}
        >
          {_curriculum?.educations?.length > 1 && (
            <p className="paragraph-reorder">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <ViewEducation
            hasCards
            isMyProfile
            items={_curriculum?.educations}
            onEdit={onEdit}
            onMove={reorderItems}
            onRemove={(_, index) => onRemove(index)}
            className="padding-24"
          />
        </div>
      )}
      <ModalMedia
        openModal={openModal}
        onClose={closeModal}
        onChange={handleChangeMedia}
        medias={media}
        onSave={saveMedia}
        disabled={!media.title || !media.description}
      />
    </div>
  );
};

export default memo(Education);
