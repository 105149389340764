import jwtDecode from "jwt-decode";
import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import api from "./services/api";
import {
  getCompany,
  getNotifications,
  getUserByToken,
  login,
  loginRequestToken,
} from "./services/functions";
import { clearCompany, setCompany } from "./store/actions/companyActions";
import { setNotifications } from "./store/actions/notificationsActions";
import { clearUser, setUser, updateUser } from "./store/actions/userActions";
import { setLoad } from "./store/actions/configurationsActions";
import { Company } from "./types/interfaces";
import {
  hasAnyAdminRole,
  hasCandidateRole,
  hasCorporateRole,
  hasStudentRole,
} from "./utils";
import { userStatus } from "./types/enumerators";

interface IToken {
  name: string;
  exp: number;
}

interface IAuthContext {
  isValidToken: (token: string) => boolean;
  signInWithId: (token: string) => void;
  signIn: (email: string, password: string, type: string) => Promise<boolean>;
  signOut: () => void;
}

const AuthContext = createContext({} as IAuthContext);

export const AuthProvider = ({
  children,
}: JSX.ElementChildrenAttribute): JSX.Element => {
  const dispatch = useDispatch();

  const isValidToken = useCallback((token: string) => {
    if (!!token && token !== "login error") {
      try {
        if (jwtDecode<IToken>(token)?.exp < Date.now() / 1000) return false;
      } catch {
        return false;
      }
    } else return false;
    api.defaults.headers.authorization = `Bearer ${token}`;
    return true;
  }, []);

  // const validateToken = useCallback(async (token, json) => {
  //   const validationJWTHeader = {
  //     headers: {
  //       jwtRequest: token,
  //     },
  //   };
  //   const result = await login(json, validationJWTHeader);
  //   const user = result;

  //   return user;
  // }, []);

  const getNotificationsUser = useCallback(
    async (user) => {
      await getNotifications(user?._id).then((notifications) =>
        dispatch(setNotifications(notifications ?? []) as unknown as Action),
      );
    },
    [dispatch],
  );

  const signInAdmin = useCallback(
    () => sessionStorage.setItem("@SESSION:type", "ADMIN"),
    [],
  );

  const signInCandidate = useCallback(
    async (user) => {
      sessionStorage.setItem("@SESSION:type", "CANDIDATE");

      const newUser = { ...user };

      if (user && user?._id && !hasCandidateRole(user))
        newUser?.account?.roles.push("CANDIDATE");

      if (user?.account?.status === userStatus.DISABLED)
        newUser.account.status = userStatus.PENDING;

      dispatch(updateUser({ account: newUser?.account }) as unknown as Action);
    },
    [dispatch],
  );

  const signInCorporate = useCallback(
    async (user) => {
      sessionStorage.setItem("@SESSION:type", "CORPORATE");

      let company = {} as Company;
      company =
        (await getCompany(user?.account?.companyIds[0])) ?? ({} as Company);
      dispatch(
        setCompany({
          ...company,
        }) as unknown as Action,
      );
      sessionStorage.setItem("@SESSION:company", JSON.stringify(company));
    },
    [dispatch],
  );

  const signInStudent = useCallback(
    async () => sessionStorage.setItem("@SESSION:type", "STUDENT"),
    [],
  );

  const signInWithId = useCallback(
    async (id: string) => {
      const token = await loginRequestToken(id);
      if (token) {
        api.defaults.headers.authorization = token;

        const responseUser = await getUserByToken();
        sessionStorage.setItem("@SESSION:user", JSON.stringify(responseUser));
        dispatch(setUser(responseUser) as unknown as Action);

        if (
          responseUser?.account?.companyIds &&
          responseUser?.account?.companyIds?.length
        ) {
          const responseCompany = await getCompany(
            responseUser?.account?.companyIds[0],
          );
          sessionStorage.setItem(
            "@SESSION:company",
            JSON.stringify(responseCompany),
          );
          dispatch(setCompany(responseCompany) as unknown as Action);
        }
      }
    },
    [dispatch],
  );

  const signIn = useCallback(
    async (email, password, type): Promise<boolean> => {
      const json = {
        user: email,
        password,
      };

      const response = await login(json);
      const { user, token } = response;

      if (token && token !== "login error" && isValidToken(token) && !!user) {
        api.defaults.headers.authorization = `Bearer ${token}`;
        sessionStorage.setItem("@SESSION:user", JSON.stringify(user));
        sessionStorage.setItem("@SESSION:token", token);
        dispatch(setUser(user) as unknown as Action);

        await getNotificationsUser(user);

        switch (type) {
          case "STUDENT":
            if (hasStudentRole(user)) signInStudent();
            else return false;
            // toast.error(
            //   "Ops! Parece que você não tem cadastro como aluna/ex-aluna. Favor conferir se inseriu e-mail e senha corretamente. Para solicitar sua conta, entre em contato pelo e-mail plataforma@reprograma.com.br.",
            //   {
            //     autoClose: false,
            //   },
            // );
            break;

          case "CANDIDATE":
            signInCandidate(user);
            break;

          case "CORPORATE":
            if (
              hasCorporateRole(user) &&
              user?.account?.companyIds &&
              user?.account?.companyIds?.length &&
              user?.account?.companyIds[0] !== ""
            )
              signInCorporate(user);
            else return false;
            // toast.error(
            //   "Ops! Parece que você não tem cadastro como empresa. Favor conferir se inseriu e-mail e senha corretamente. Para solicitar sua conta, entre em contato pelo e-mail plataforma@reprograma.com.br.",
            //   {
            //     autoClose: false,
            //   },
            // );
            break;

          case "ADMIN":
            if (hasAnyAdminRole(user)) signInAdmin();
            else return false;
            // toast.error(
            //   "Ops! Parece que você não tem cadastro como administradora. Favor conferir se inseriu e-mail e senha corretamente. Para solicitar sua conta, entre em contato pelo e-mail plataforma@reprograma.com.br.",
            //   {
            //     autoClose: false,
            //   },
            // );
            break;

          default:
            return false;
            // toast.error(
            //   "Ops! Parece que você tivemos algum problema. Favor tentar novamente.",
            //   {
            //     autoClose: false,
            //   },
            // );
            break;
        }
        return true;
        // } else toast.error("Credenciais inválidas");
      }
      return false;
    },
    [
      dispatch,
      getNotificationsUser,
      isValidToken,
      signInAdmin,
      signInCandidate,
      signInCorporate,
      signInStudent,
    ],
  );

  const signOut = useCallback(() => {
    sessionStorage.clear();
    dispatch(clearUser() as unknown as Action);
    dispatch(clearCompany() as unknown as Action);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  const value = useMemo(
    () => ({
      signIn,
      signOut,
      signInWithId,
      isValidToken,
    }),
    [signIn, signOut, signInWithId, isValidToken],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);

  if (!context) throw new Error("useAuth must be used within a AuthProvider");

  return context;
};
