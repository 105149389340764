import { Tooltip } from "@material-ui/core";
import React, {
  memo,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { Close, ArrowLeft } from "../../../assets/customIcons";
import { Info } from "../../../assets/icons";
import "./styles.scss";

interface Props {
  onClose?: MouseEventHandler<SVGElement>;
  onClickBack?: () => void;
  className?: string;
  text?: string;
  textInfo?: string;
  headerChildren?: ReactNode;
}

const HeaderActionCard = ({
  text,
  textInfo,
  onClose,
  onClickBack,
  headerChildren,
  className,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`${className ?? ""} flex-row-center`}>
      {text ? (
        <div className="header-card-component">
          <div className="flex-row-center">
            <p className="header-card-component-action">{text}</p>
            {textInfo && (
              <Tooltip title={<p>{textInfo}</p>}>
                <div>
                  <Info className="icon-info cursor-pointer" />
                </div>
              </Tooltip>
            )}
          </div>
          <Close
            className="header-card-component-close margin-left-6 cursor"
            widthIcon={width < 991 ? 26 : 28}
            onClick={onClose}
          />
        </div>
      ) : (
        <div
          className="header-card-component-arrow cursor-pointer"
          aria-label="back"
          role="button"
          tabIndex={0}
          onClick={
            onClickBack ?? (() => window.open("https://reprograma.com.br/"))
          }
        >
          <ArrowLeft
            widthIcon={width < 991 ? 24 : 32}
            heightIcon={width < 991 ? 24 : 32}
          />
          <div className="header-card-component-label cursor-pointer">
            {headerChildren ?? "Voltar"}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(HeaderActionCard);
