import React, { memo, useEffect, useState } from "react";
import { Recent } from "../../../assets/icons/index";
import { SearchMenu } from "../../../assets/customIcons";
import "./style.scss";

interface Props {
  itemName?: string;
  itemCategory?: string;
  recent?: boolean;
}

const RecentSearch = ({
  recent,
  itemName,
  itemCategory,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return recent ? (
    <div className="recent-search">
      <Recent />
      <span className="recent-search-title"> {itemName}</span>
      <li className="recent-search-category"> {itemCategory} </li>
    </div>
  ) : (
    <div className="recent-search-menu">
      <SearchMenu
        widthIcon={width < 991 ? 20 : 24}
        heightIcon={width < 991 ? 20 : 24}
      />
      <span className="recent-search-title"> {itemName}</span>
      <li className="recent-search-category"> {itemCategory} </li>
    </div>
  );
};

export default memo(RecentSearch);
