import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  userBootcampsStatus,
  userGenderIdentity,
  userRace,
} from "../../../types/enumerators";
import {
  Class as ClassProps,
  User,
  UserBootcamps,
  UserCurriculum,
  UserAccount,
  Comments,
} from "../../../types/interfaces";
import { ProfileGender, ProfileRace } from "../../../types/constants/User";
import {
  filterUser,
  getClassById,
  getPages,
  patchUser,
} from "../../../services/functions";
import { RootState } from "../../../store/reducers";
import { setLoad } from "../../../store/actions/configurationsActions";
import { hasAdminClassBootcampRole, region } from "../../../utils";
import { Layout, HeaderProfile } from "../../../components";
import ModalComments from "../../../components/ModalComments";
import Errors from "../../Errors";
import { Path, Count, Infos, Table, Pagination } from "../Components";
import {
  ToForm,
  ToGeneralStatus,
  Certificate,
  Tags,
  UserView,
  Documents,
} from "../Modal";
import Buttons from "./buttons";
import Forms from "./forms";
import "../style.scss";

interface ParamsProps {
  id: string;
}

interface List {
  _id: string;
  userId: string;
  account: UserAccount;
  name: string;
  photo: string;
  localization: string;
  race: keyof typeof userRace;
  gender: keyof typeof userGenderIdentity;
  legalIdentity: string;
  classId: string;
  status: string;
  curriculum: UserCurriculum;
  bootcamps: UserBootcamps[];
  tags: string[];
}

interface Filters {
  name?: string | null;
  email?: string | null;
  genderIdentity?: Array<keyof typeof userGenderIdentity> | null;
  race?: Array<keyof typeof userRace> | null;
  disabledPerson?: boolean | null;
  classId?: string | null;
  classStatus?: string | null;
  status?: string | null;
  anyStatus?: boolean | null;
  tags?: string | null;
}

const Class = (): JSX.Element => {
  const { id } = useParams<ParamsProps>();

  const [pageError, setPageError] = useState(false);

  const [currentClass, setCurrentClass] = useState<ClassProps>();
  const [title, setTitle] = useState();
  const [list, setList] = useState<List[]>();
  const [filters, setFilters] = useState<Filters>();
  const [totalFilteredList, setTotalFilteredList] = useState(0);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [userSelected, setUserSelected] = useState<List>();
  const [usersSelected, setUsersSelected] = useState<List[]>();

  const [openModalComments, setOpenModalComments] = useState(false);
  const [openModalDocument, setOpenModalDocument] = useState(false);
  const [openModalCertificate, setOpenModalCertificate] = useState(false);
  const [openModalUserView, setOpenModalUserView] = useState(false);
  const [openModalToForm, setOpenModalToForm] = useState(false);
  const [openModalTags, setOpenModalTags] = useState(false);
  const [openModalToGeneralStatus, setOpenModalToGeneralStatus] =
    useState(false);
  const [actionTitle, setActionTitle] = useState("");
  const [statusSelected, setStatusSelected] = useState<string>("");

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const user = useSelector((state: RootState) => state.userState);

  const dispatch = useDispatch();

  const getClass = useCallback(async () => {
    const response = await getClassById(id);
    setCurrentClass(response?.data);
    setTitle(response?.data?.title);
  }, [id]);

  useEffect(() => {
    if (!currentClass && id) getClass();
  }, [currentClass, getClass, id]);

  const mapStudents = useCallback(
    (response) => {
      if (!response) return [];

      return response?.map((student: User) => ({
        _id: student?._id,
        userId: student?._id,
        name: student?.profile?.name,
        account: student?.account,
        localization: student?.profile?.address?.province
          ? region(student?.profile?.address?.province)
          : "",
        race: ProfileRace[student?.profile?.race],
        legalIdentity: student?.profile?.legalIdentity ?? "",
        gender: ProfileGender[student?.profile?.genderIdentity],
        classId: student?.bootcamps?.find(
          (itemBootcamp) => itemBootcamp?.classId === currentClass?._id,
        )?.classId,
        status: student?.bootcamps?.find(
          (itemBootcamp) => itemBootcamp?.classId === currentClass?._id,
        )?.status,
        curriculum: student?.curriculum,
        tags: student?.bootcamps?.find(
          (itemBootcamp) => itemBootcamp?.classId === currentClass?._id,
        )?.tags,
        bootcamps: student?.bootcamps,
        photo: student?.profile?.photo,
      }));
    },
    [currentClass?._id],
  );

  const getListClass = useCallback(
    async (f?: Filters) => {
      if (pageLimit) {
        dispatch(setLoad(true) as unknown as Action);
        const filter = f ?? filters ?? {};
        const requestFilter: Filters = {
          classId: currentClass?._id ?? "",
          anyStatus: true,
          classStatus: filter?.status ?? null,
          ...filter,
        };

        setMaxPage(
          await getPages(filterUser, filter?.name ?? "", requestFilter),
        );

        const numTotalList = (await filterUser(
          filter?.name ?? "",
          requestFilter,
          undefined,
          undefined,
          "profile.name",
          true,
          true,
        )) as { size: 0 }[];
        if (numTotalList?.length)
          setTotalFilteredList(numTotalList[0]?.size ?? 0);
        else setTotalFilteredList(0);

        const filterResult = await filterUser(
          filter?.name ?? "",
          requestFilter,
          undefined,
          page,
          "profile.name",
        );
        setList(mapStudents(filterResult));

        dispatch(setLoad(false) as unknown as Action);
      }
    },
    [currentClass?._id, dispatch, filters, mapStudents, page, pageLimit],
  );

  useEffect(() => {
    if (currentClass?._id) getListClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClass, page, pageLimit]);

  const onCancel = useCallback(() => {
    setStatusSelected("");
    setActionTitle("");
    setUserSelected(undefined);
    setUsersSelected(undefined);
    setOpenModalComments(false);
    setOpenModalDocument(false);
    setOpenModalCertificate(false);
    setOpenModalUserView(false);
    setOpenModalToGeneralStatus(false);
    setOpenModalToForm(false);
    setOpenModalTags(false);
  }, []);

  const currentBootcampByUser = useMemo(() => {
    const currentBootcamp = userSelected?.bootcamps?.find(
      (item: { classId?: string }) => item?.classId === id,
    );

    return currentBootcamp as UserBootcamps;
  }, [id, userSelected]);

  const submitCommentData = useCallback(
    async (updatedData) => {
      if (updatedData) {
        const bootcamps = userSelected?.bootcamps as UserBootcamps[];

        const indexBootcamp = bootcamps?.indexOf(currentBootcampByUser);

        bootcamps[indexBootcamp].comments = updatedData;

        await patchUser(userSelected?.userId ?? "", {
          bootcamps,
        });
      }
    },
    [userSelected?.bootcamps, userSelected?.userId, currentBootcampByUser],
  );

  useEffect(() => {
    if (
      id &&
      user?._id &&
      hasAdminClassBootcampRole(user) &&
      (!user?.account?.classIds || !user?.account?.classIds?.includes(id))
    )
      setPageError(true);
  }, [id, user]);

  const onChangeStatus = useCallback(
    async (usr) => {
      dispatch(setLoad(true) as unknown as Action);

      const bootcamps = [...(usr?.bootcamps ?? [])];
      const i =
        bootcamps?.findIndex((item) => item?.classId === currentClass?._id) ??
        -1;

      if (i > -1) {
        const bootcamp = {
          ...bootcamps[i],
          status: userBootcampsStatus.IN_PROCESS,
        };

        bootcamps[i] = bootcamp;

        await patchUser(usr?.userId ?? "", { bootcamps });
      }

      onCancel();

      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, onCancel, currentClass?._id],
  );

  const onAllChangeStatus = useCallback(
    (listUsr) => listUsr?.forEach((item: List[]) => onChangeStatus(item)),
    [onChangeStatus],
  );

  const onAllAddToRecap = useCallback(
    async (listUsr) => {
      dispatch(setLoad(true) as unknown as Action);
      for (let i = 0; i < listUsr?.length; i += 1) {
        const bootcamps = [...(listUsr[i]?.bootcamps ?? [])];
        const idx =
          bootcamps?.findIndex((item) => item?.classId === currentClass?._id) ??
          -1;

        if (idx > -1) {
          const bootcamp = {
            ...bootcamps[i],
            recap: {
              disabled: false,
            },
          };

          bootcamps[idx] = bootcamp;

          // eslint-disable-next-line no-await-in-loop
          await patchUser(listUsr[i]?.userId ?? "", { bootcamps });
        }
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [currentClass?._id, dispatch],
  );

  const actions = useMemo(
    () => ({
      onTag: (row: List) => {
        setUsersSelected([row]);
        setOpenModalTags(true);
      },
      onAllTag: (listSelected: List[]) => {
        setUsersSelected(listSelected);
        setOpenModalTags(true);
      },
      onForm: (row: List) => {
        setUserSelected(row);
        setOpenModalToForm(true);
      },
      onDisqualify: (row: List, status: string) => {
        setUsersSelected([row]);
        setStatusSelected(status);
        setOpenModalToGeneralStatus(true);
        setActionTitle("Desclassificar");
      },
      onTransferClass: (row: List, status: string) => {
        setUsersSelected([row]);
        setStatusSelected(status);
        setOpenModalToGeneralStatus(true);
        setActionTitle("Adicionar a turma");
      },
      onGiveUp: (row: List, status: string) => {
        setUsersSelected([row]);
        setStatusSelected(status);
        setOpenModalToGeneralStatus(true);
        setActionTitle("Desistente");
      },
      onReplaced: (row: List, status: string) => {
        setUsersSelected([row]);
        setStatusSelected(status);
        setOpenModalToGeneralStatus(true);
        setActionTitle("Substituída");
      },
      onActiveClass: (row: List) => {
        if (row?.status !== userBootcampsStatus.IN_PROCESS) onChangeStatus(row);
        else {
          setUsersSelected([row]);
          setStatusSelected(userBootcampsStatus.STOPPED);
          setOpenModalToGeneralStatus(true);
          setActionTitle("Trancar");
        }
      },
      // onAddCertificate: (row: List) => {
      //   setUserSelected(row);
      //   setOpenModalCertificate(true);
      // },
      onShow: (row: List) => {
        setUserSelected(row);
        setOpenModalUserView(true);
      },
      onAllAddToRecap,
      onAllGiveUp: (listSelected: List[], status: string) => {
        setUsersSelected(listSelected);
        setStatusSelected(status);
        setOpenModalToGeneralStatus(true);
        setActionTitle("Desistente");
      },
      onAllReplaced: (listSelected: List[], status: string) => {
        setUsersSelected(listSelected);
        setStatusSelected(status);
        setOpenModalToGeneralStatus(true);
        setActionTitle("Substituída");
      },
      onAllTransferClass: (listSelected: List[], status: string) => {
        setUsersSelected(listSelected);
        setStatusSelected(status);
        setOpenModalToGeneralStatus(true);
        setActionTitle("Adicionar a turma");
      },
      onAllDisqualify: (listSelected: List[], status: string) => {
        setUsersSelected(listSelected);
        setStatusSelected(status);
        setOpenModalToGeneralStatus(true);
        setActionTitle("Desclassificar");
      },
      onAllActiveClass: (
        listSelected: List[],
        status: string | ((prevState: string) => string),
      ) => {
        if (status === userBootcampsStatus.STOPPED) {
          setUsersSelected(listSelected);
          setStatusSelected(status);
          setActionTitle("Trancar");
          setOpenModalToGeneralStatus(true);
        } else onAllChangeStatus(listSelected);
      },
      onComment: (row: List) => {
        setUserSelected(row);
        setOpenModalComments(true);
      },
      hasCommentUnresolved: (row: List) =>
        row?.bootcamps
          ?.find((item: { classId?: string }) => item?.classId === id)
          ?.comments?.filter(({ resolved = false }) => !resolved)?.length > 0,
    }),
    [id, onAllAddToRecap, onAllChangeStatus, onChangeStatus],
  );

  return pageError ? (
    <Errors error={403} />
  ) : (
    <Layout className="admin-class-view admin-subscriptions">
      <HeaderProfile />
      <Path title={title ?? ""} />
      <Buttons onClick={() => setOpenModalDocument(true)} />
      <Count classId={currentClass?._id ?? ""} />
      <Infos currentClass={currentClass as ClassProps} />
      <Forms
        currentClass={currentClass as ClassProps}
        reload={() => getListClass()}
      />
      <Table
        data={list as List[]}
        getData={getListClass}
        currentClass={currentClass as ClassProps}
        setPropFilters={setFilters}
        actions={actions}
      />
      <Pagination
        value={list?.length ?? 0}
        total={totalFilteredList}
        page={page}
        setPage={setPage}
        maxPage={maxPage}
      />

      <ToForm
        open={openModalToForm}
        close={onCancel}
        user={userSelected as List}
        classId={currentClass?._id ?? ""}
        reload={() => getListClass()}
      />

      <ToGeneralStatus
        open={openModalToGeneralStatus}
        close={onCancel}
        users={usersSelected as List[]}
        classId={currentClass?._id ?? ""}
        title={actionTitle}
        status={statusSelected}
        reload={() => getListClass()}
      />

      <Certificate
        open={openModalCertificate}
        close={onCancel}
        user={userSelected as List}
        currentClass={currentClass as ClassProps}
        reload={() => getListClass()}
      />

      <Tags
        open={openModalTags}
        close={onCancel}
        users={usersSelected as List[]}
        classId={currentClass?._id ?? ""}
        reload={() => getListClass()}
      />

      <UserView
        open={openModalUserView}
        close={onCancel}
        user={userSelected as List}
        reload={() => getListClass()}
      />

      <Documents
        open={openModalDocument}
        close={onCancel}
        medias={currentClass?.medias}
        classId={currentClass?._id ?? ""}
        reload={() => getListClass()}
      />

      <ModalComments
        openModal={openModalComments}
        onClose={onCancel}
        userId={user?._id}
        submit={submitCommentData}
        data={(currentBootcampByUser?.comments ?? []) as Comments[]}
        dataUser={{
          name: userSelected?.name ?? "",
          photo: userSelected?.photo ?? "",
          userId: userSelected?.userId ?? "",
        }}
      />
    </Layout>
  );
};

export default memo(Class);
