import React, { memo } from "react";

const Error = (): JSX.Element => (
  <div className="type-access width-fit">
    <p className="title-login padding-bottom-32">
      Usuário não cadastrado. Por favor, verifique se seu e-mail foi digitado
      corretamente ou entre em contato com nossa equipe através do e-mail:
      <br />
      <a
        href="mailto:plataforma@reprograma.com.br"
        className="a-claim-login word-break"
      >
        plataforma@reprograma.com.br
      </a>
    </p>
  </div>
);
export default memo(Error);
