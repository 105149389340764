import React, { MouseEventHandler } from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const BookmarkFilled = ({
  widthIcon,
  heightIcon,
  className,
  onClick,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    className={className}
    onClick={onClick}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#B382BA"
      d="M21.102 2.333H6.9c-.588 0-1.065.475-1.065 1.061v21.212c0 .408.235.78.604.956.369.177.807.127 1.127-.128L14 20.308l6.436 5.126c.192.154.428.233.665.233.157 0 .315-.035.462-.105.369-.176.603-.548.603-.956V3.394c0-.586-.477-1.06-1.065-1.06z"
    />
    <path
      fill="#2E3451"
      d="M21.102 2.333H6.9c-.588 0-1.065.475-1.065 1.061v21.212c0 .408.235.78.604.956.369.177.807.127 1.127-.128L14 20.308l6.436 5.126c.192.154.428.233.665.233.157 0 .315-.035.462-.105.369-.176.603-.548.603-.956V3.394c0-.586-.477-1.06-1.065-1.06zM20.037 22.4l-5.37-4.278c-.195-.154-.43-.232-.666-.232-.236 0-.471.078-.666.232L7.965 22.4V4.455h12.072v17.944z"
    />
  </svg>
);

export default BookmarkFilled;
