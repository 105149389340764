import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const ChevronUp = ({
  widthIcon,
  heightIcon,
  className,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    fill="none"
    viewBox="0 0 28 28"
    className={className}
  >
    <path
      fill="#2E3451"
      d="M22.75 16.666L14 8.166l-8.75 8.5 2.059 2 6.691-6.5 6.691 6.5 2.059-2z"
    />
  </svg>
);

export default ChevronUp;
