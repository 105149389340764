import React, { memo, useEffect, useState } from "react";
import { Button, Progress } from "..";
import "./styles.scss";

interface Props {
  type:
    | "user"
    | "company"
    | "research-ONE_MONTHS"
    | "research-THREE_MONTHS"
    | "research-SIX_MONTHS"
    | "research-TWELVE_MONTHS"
    | "research-COMPANY";
  onClick: () => void;
}

const Finish = ({ type, onClick }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {width > 990 && (
        <div className="grid-column-3-11 grid-8-column">
          <p className="paragraph-finish-1">
            {type === "user"
              ? "Uhuuul!"
              : type === "company"
              ? "Uhuuul!!!"
              : "Muito obrigada!"}
          </p>
          <p className="paragraph-finish-2">
            {type === "user"
              ? "Seu cadastro está completo e agora você pode explorar todas as funcionalidades da nossa plataforma : )"
              : type === "company"
              ? "Você completou seu cadastro e agora pode explorar todas as funcionalidades da nossa plataforma."
              : "Suas respostas são muito importantes para continuarmos melhorando cada vez mais nossos processos."}
          </p>
          <Progress value={100} className="grid-column-1-4" />
          {type !== "research-COMPANY" && (
            <Button
              className="grid-row-4 grid-column-1-3 margin-top-16"
              onClick={onClick}
              text={
                type === "user" || type.includes("research")
                  ? "VER MEU PERFIL"
                  : "VER PERFIL"
              }
            />
          )}
        </div>
      )}

      {width < 991 && (
        <div className="grid-column-1-9-mobile">
          <p className="paragraph-finish-1 finish-1-margin margin-left-16-mobile">
            {width < 991 && (
              <div className="margin-bottom-36 -margin-left-16-mobile">
                <Progress value={100} className="grid-column-1-9-mobile" />
              </div>
            )}
            {type === "user"
              ? "Uhuuul!"
              : type === "company"
              ? "Uhuuul!!!"
              : "Muito obrigada!"}
          </p>
          <p className="paragraph-finish-2 margin-left-16-mobile">
            {type === "user"
              ? "Seu cadastro está completo e agora você pode explorar todas as funcionalidades da nossa plataforma : )"
              : type === "company"
              ? "Você completou seu cadastro e agora pode explorar todas as funcionalidades da nossa plataforma."
              : "Suas respostas são muito importantes para continuarmos melhorando cada vez mais nossos processos."}
          </p>
          {type !== "research-COMPANY" && (
            <Button
              className="grid-column-1-9-mobile"
              onClick={onClick}
              text={
                type === "user" || type.includes("research")
                  ? "VER MEU PERFIL"
                  : "VER PERFIL"
              }
            />
          )}
        </div>
      )}
    </>
  );
};

export default memo(Finish);
