/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  memo,
  useCallback,
  ReactNode,
  useMemo,
  SetStateAction,
  Dispatch,
  useState,
  ChangeEvent,
  createRef,
  useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Action } from "redux";
import { Input, LabelInput, InputAutocomplete } from "../../../components";
import { RootState } from "../../../store/reducers";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateUser } from "../../../store/actions/userActions";
import { updateCompany } from "../../../store/actions/companyActions";
import { registerAutoComplete } from "../../../utils";
import { autoCompleteType } from "../../../types/enumerators";
import { getAutocomplete } from "../../../services/functions";

interface Item {
  child: ReactNode;
  isFullRow?: boolean;
  renderCondition?: boolean;
  className?: string;
}

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
  templateRows?: boolean;
}

const CompanyPersonalData = ({
  setIsValid,
  templateRows = false,
}: Props): JSX.Element => {
  const expandedAttribute = "expanded";
  const heightProperty = "height";
  const fullRowClassName = "grid-full-row";

  const dispatch = useDispatch();

  const [workPositionOptions, setWorkPositionOptions] = useState<string[]>();
  const [position, setPosition] = useState<string>();

  const company = useSelector((state: RootState) => state.companyState);
  const [_company, _setCompany] = useState(company);

  const user = useSelector((state: RootState) => state.userState);
  const [_user, _setUser] = useState(user);

  useEffect(() => {
    if (company?._id && !_company?._id) _setCompany(company);
  }, [_company?._id, company]);

  const isDisabled = useMemo(
    () => ({
      name: !_company?.profile?.name,
      position: !_company?.profile?.name || !_user?.profile?.name,
      telephone:
        !_user?.profile?.name ||
        !_company?.profile?.name ||
        !_user?.account?.tags?.find((item: string) =>
          item?.includes("position"),
        ),
    }),
    [_company?.profile?.name, _user?.account?.tags, _user?.profile?.name],
  );

  useEffect(() => {
    if (user?.account?.tags?.length) {
      const tagPosition = user?.account?.tags?.find((item: string) =>
        item.includes("position"),
      );
      if (tagPosition) {
        const arrPosition = tagPosition.split(":");
        setPosition(arrPosition[1]);
      }
    }
  }, [user?.account?.tags]);

  const loadPositions = useCallback(async () => {
    const workposition = await getAutocomplete(autoCompleteType.WORK_POSITION);

    const workpositionMapped = workposition?.map(
      (option: { name: string }) => option?.name,
    );

    setWorkPositionOptions(workpositionMapped);
  }, []);

  useEffect(() => {
    if (!workPositionOptions) loadPositions();
  }, [loadPositions, workPositionOptions]);

  const handleChangePosition = useCallback(
    async (event) => {
      registerAutoComplete(event.target.value, autoCompleteType.WORK_POSITION);
      const { account } = _user;
      if (account) {
        let { tags: newTags } = account;

        if (newTags && newTags?.length) {
          const index = account?.tags?.findIndex((item: string) =>
            item.includes("position"),
          );
          if (index !== -1)
            newTags[index] = `position:${event.target.value as string}`;
          else newTags.push(`position:${event.target.value as string}`);
        } else newTags = [`position:${event.target.value as string}`];

        setPosition(event.target.value as string);
        _setUser({
          ..._user,
          account: {
            ...account,
            tags: newTags,
          },
        });
      }
    },
    [_user],
  );

  const handleOnBlur = useCallback(() => {
    if (!isDisabled.telephone) {
      setIsValid(true);
      dispatch(
        setNextButton(() => {
          dispatch(updateCompany({ ..._company }) as unknown as Action);
          dispatch(updateUser({ ..._user }) as unknown as Action);
        }) as unknown as Action,
      );
    } else setIsValid(false);
  }, [_company, _user, dispatch, isDisabled.telephone, setIsValid]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) =>
      _setCompany({
        ..._company,
        profile: { ..._company?.profile, [prop]: event.target.value },
      }),
    [_company],
  );

  const handleChangeUser = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) =>
      _setUser({
        ..._user,
        profile: { ...user?.profile, [prop]: event.target.value },
      }),
    [_user, user?.profile],
  );

  const items = useMemo(
    (): Item[] => [
      {
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual o nome da empresa contratante da ex aluna da {reprograma} sobre a qual esse questionário é referente?"
              />
            )}
            <Input
              id="name"
              label="Nome da empresa"
              type="text"
              onChange={(e) => handleChange(e, "name")}
              onBlur={handleOnBlur}
              value={_company?.profile?.name ?? ""}
            />
          </>
        ),
      },
      {
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual o nome da pessoa representante que esta respondendo o questionário?"
                disabled={isDisabled.name}
              />
            )}
            <Input
              id="name"
              label="Nome completo"
              type="text"
              value={_user?.profile?.name ?? ""}
              onChange={(e) => handleChangeUser(e, "name")}
              onBlur={handleOnBlur}
              disabled={isDisabled.name}
            />
          </>
        ),
      },
      {
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual o seu cargo atual?"
                disabled={isDisabled.position}
              />
            )}
            <InputAutocomplete
              label="Seu cargo"
              helperText="Pressione Enter para adicionar"
              value={position ?? ""}
              className="width-100 margin-right-32"
              options={workPositionOptions ?? [""]}
              onBlur={handleChangePosition}
              getOptionLabel={(option) => option}
              disabled={isDisabled.position}
            />
          </>
        ),
      },
      // {
      //   child: (
      //     <>
      //       {templateRows && (
      //         <LabelInput
      //           text="Se puder, deixe seu telefone de contato"
      //           disabled={isDisabled.telephone}
      //           optional
      //         />
      //       )}
      //       <Input
      //         id="telephone"
      //         label="Celular"
      //         type="text"
      //         value={maskPhone(_user?.profile?.telephone ?? "")}
      //         onChange={(e) => handleChangeUser(e, "telephone")}
      //         onBlur={handleOnBlur}
      //         disabled={isDisabled.telephone}
      //         maxLength={14}
      //       />
      //     </>
      //   ),
      // },
    ],
    [
      _company?.profile?.name,
      _user?.profile?.name,
      handleChange,
      handleChangePosition,
      handleChangeUser,
      handleOnBlur,
      isDisabled.name,
      isDisabled.position,
      position,
      templateRows,
      workPositionOptions,
    ],
  );

  const itemsRefs = useMemo(
    () => items.map(() => createRef<HTMLDivElement>()),
    [items],
  );

  const updateLabelHeights = useCallback(() => {
    if (templateRows) {
      const getLabel = (ref: HTMLDivElement | null) => {
        if (ref) {
          const { children } = ref;
          // @ts-ignore
          const [label] = children;
          return label;
        }
        return ref;
      };

      const getLabelHeight = (label: HTMLParagraphElement) => {
        const { offsetHeight } = label;
        return offsetHeight;
      };

      const removeLabelHeight = (label: HTMLParagraphElement) => {
        const { style } = label;
        style.removeProperty(heightProperty);
        label.removeAttribute(expandedAttribute);
        return label;
      };

      const setLabelHeight = (label: HTMLParagraphElement, height: number) => {
        const { style } = label;
        style.setProperty(heightProperty, `${height}px`);
        label.setAttribute(expandedAttribute, "");
      };

      itemsRefs
        .filter(
          ({ current }) =>
            current && !current.className.includes(fullRowClassName),
        )
        .forEach(({ current: rightItem }, index, arr) => {
          if (index % 2 !== 0) {
            // Coluna esquerda
            const { current: leftItem } = arr[index - 1];
            const leftLabel = removeLabelHeight(getLabel(leftItem));
            const leftHeight = getLabelHeight(leftLabel);

            // Coluna direita
            const rightLabel = removeLabelHeight(getLabel(rightItem));
            const rightHeight = getLabelHeight(rightLabel);

            // Expansão de altura
            if (leftHeight > rightHeight)
              setLabelHeight(rightLabel, leftHeight);
            else setLabelHeight(leftLabel, rightHeight);

            // Redução de altura
            if (
              leftHeight < rightHeight &&
              rightLabel.hasAttribute(expandedAttribute)
            ) {
              removeLabelHeight(leftLabel);
              setLabelHeight(rightLabel, leftHeight);
            } else if (
              rightHeight < leftHeight &&
              leftLabel.hasAttribute(expandedAttribute)
            ) {
              removeLabelHeight(rightLabel);
              setLabelHeight(leftLabel, rightHeight);
            }
          }
        });
    }
  }, [itemsRefs, templateRows]);

  useEffect(() => {
    const handleResize = () => {
      updateLabelHeights();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [updateLabelHeights]);

  useEffect(() => updateLabelHeights(), [updateLabelHeights]);

  useEffect(() => {
    if (!isDisabled.telephone) setIsValid(true);
    else setIsValid(false);
  }, [isDisabled.telephone, setIsValid]);

  return (
    <div className="grid-account-gap grid-account-gap-resposive">
      {items.map(
        ({ isFullRow, renderCondition, className, child }, index) =>
          (renderCondition ?? true) && (
            <div
              ref={itemsRefs[index]}
              className={
                isFullRow
                  ? fullRowClassName
                  : `content-grid-row ${
                      templateRows
                        ? `content-grid-2-row ${className ?? ""}`
                        : ""
                    }`
              }
            >
              {child}
            </div>
          ),
      )}
    </div>
  );
};
export default memo(CompanyPersonalData);
