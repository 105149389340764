import React, { memo, ReactNode, useCallback, useRef, useState } from "react";
import {
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from "@material-ui/core";
import "./styles.scss";
import { ChevronDown, ChevronDownPurple } from "../../../assets/icons";

interface Props {
  items: {
    text: string;
    onClick: () => void;
  }[];
  text?: string;
  children?: ReactNode;
  className?: string;
  openedClassName?: string;
}

const ButtonPagination = ({
  items,
  text,
  className,
  openedClassName,
  children,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const reference = useRef<HTMLDivElement | null>(null);

  const handleToggle = useCallback(
    (event) => {
      setOpen(!open);
      event.stopPropagation();
    },
    [open],
  );

  const handleClose = useCallback(() => setOpen(false), []);

  const handleListKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }, []);

  return (
    <div ref={reference} className={openedClassName || ""}>
      <ClickAwayListener onClickAway={handleClose}>
        <Popper
          open={open}
          placement="top"
          anchorEl={reference?.current}
          transition
          disablePortal
          className="popper-pagination"
        >
          <Paper>
            <MenuList
              autoFocusItem={open}
              id="menu-list-grow"
              onKeyDown={handleListKeyDown}
            >
              {items.map((item) => (
                <MenuItem
                  key={Math.random()}
                  onClick={() => {
                    item.onClick();
                    handleClose();
                  }}
                  className={
                    text?.includes(item.text)
                      ? "popper-pagination-selected"
                      : ""
                  }
                >
                  {item.text}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Popper>
      </ClickAwayListener>
      {children}
      <button
        key={Math.random()}
        type="button"
        onClick={handleToggle}
        className={`popper-pagination-button ${className || ""} ${
          open ? "btn-menu-primary" : ""
        }`}
      >
        <p className="popper-pagination-text">{text}</p>
        {open ? (
          <ChevronDownPurple className="chevron-open" />
        ) : (
          <ChevronDown className="chevron" />
        )}
      </button>
    </div>
  );
};

export default memo(ButtonPagination);
