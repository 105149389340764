import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const SearchMenu = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#939393"
      d="M15.864 14.32h-.813l-.288-.277c1.009-1.173 1.616-2.696 1.616-4.353C16.38 5.995 13.384 3 9.69 3 5.996 3 3 5.995 3 9.69c0 3.694 2.995 6.69 6.69 6.69 1.657 0 3.18-.608 4.353-1.617l.278.289v.813L19.467 21 21 19.467l-5.136-5.146zm-6.174 0c-2.563 0-4.632-2.068-4.632-4.63 0-2.563 2.069-4.632 4.632-4.632 2.562 0 4.63 2.069 4.63 4.632 0 2.562-2.068 4.63-4.63 4.63z"
    />
  </svg>
);

export default SearchMenu;
