import React, { memo } from "react";
import "../style.scss";
import moment from "moment-timezone";
import { CardRegister } from "../../../components";
import { Class } from "../../../types/interfaces";

interface Props {
  workshopClass: Class;
}

const ConfirmationWorksop = ({ workshopClass }: Props): JSX.Element => {
  const classWorkshopStartDate = new Date(workshopClass?.startDate);

  // TODO: remover horário fixo
  // const formatDate = moment(classWorkshopStartDate)
  //   .tz("America/Sao_Paulo")
  //   .format("L");
  // const formatTime = moment(classWorkshopStartDate)
  //   .tz("America/Sao_Paulo")
  //   .format("LT");

  const formatDate = moment(classWorkshopStartDate).format("DD/MM");

  return (
    <CardRegister
      titleHeader="Sua inscrição"
      className="margin-top-16 margin-mobile-register border-card grid-column-5-12 grid-column-1-11-mobile confirmation-workshop"
    >
      <div className="information">
        <p className="information-title">
          Sua oficina foi confirmada! Veja as informações abaixo:{" "}
        </p>
        <div className="information-description">
          <div>
            <h4>Data:</h4>
            <p>{formatDate}</p>
          </div>
          <div>
            <h4>Horário:</h4>
            <p> 09:00</p>
          </div>
          <div>
            <h4>Link de acesso:</h4>
            <p>
              <a href={workshopClass?.url}>{workshopClass?.url}</a>
            </p>
          </div>
          <div>
            <h4>Instruções:</h4>
            <div className="instuctions">
              <p>{workshopClass?.info}</p>
            </div>
          </div>
        </div>
        <p>
          Em caso de dúvidas ou se tiver algum problema, entre em contato com
          &nbsp;
          <a href="mailto:selecao@reprograma.com.br">
            selecao@reprograma.com.br
          </a>
          .
        </p>
        <p className="information-title">Boa sorte! ;&nbsp;)</p>
      </div>
    </CardRegister>
  );
};
export default memo(ConfirmationWorksop);
