import React from "react";
import { mediaType } from "../../../../types/enumerators";
import { CardMedia } from "../../../../components";
import { ExternalLink } from "../../../../../assets/icons";
import { Media } from "../../../../types/interfaces";
import "../../style.scss";

interface Props {
  media?: Media;
}

const Technical = ({ media }: Props): JSX.Element => (
  <>
    <div className="propose-title subtitle">
      <h3>Desafio</h3>
    </div>
    {media ? (
      <div className={`propose-technical-test ${media?.type}`}>
        {media?.type === mediaType.LINK ? (
          <>
            <ExternalLink />
            <a target="blank" href={media?.url}>
              {media?.url}
            </a>
          </>
        ) : (
          <div className="subscriptions-media-card">
            <CardMedia
              item={{
                url: media?.url,
                title: media?.url,
              }}
              cantEdit
            />
          </div>
        )}
      </div>
    ) : (
      "Desafio técnico não enviado"
    )}
  </>
);

export default Technical;
