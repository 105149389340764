/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-unused-prop-types */
import React, {
  memo,
  useCallback,
  useMemo,
  useState,
  ChangeEvent,
  SetStateAction,
  Dispatch,
  useEffect,
  createRef,
  ReactNode,
} from "react";
import { Action } from "redux";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store/reducers";
import {
  Input,
  TextArea,
  Select,
  LabelInput,
  Checkbox,
} from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateEmployment } from "../../../store/actions/employmentAction";
import {
  ChoseAreaTech,
  CourseLevel,
} from "../../../types/constants/Employment";
import { LevelEducation } from "../../../types/constants/Course";
import {
  researchType,
  userRoles,
  userStatus,
} from "../../../types/enumerators";
import {
  createUserWithPassword,
  getUserByIdentification,
} from "../../../services/functions";
import { passwordGenerator } from "../../../utils";
import { setLoad } from "../../../store/actions/configurationsActions";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
  templateRows?: boolean;
}

interface Item {
  id: string;
  child: ReactNode;
  isFullRow?: boolean;
  renderCondition?: boolean;
  className?: string;
}

const YouReprograma = ({
  setIsValid,
  templateRows = false,
}: Props): JSX.Element => {
  const expandedAttribute = "expanded";
  const heightProperty = "height";
  const fullRowClassName = "grid-full-row";
  const employment = useSelector((state: RootState) => state.employmentState);
  const [form, setForm] = useState(employment.form);
  const [isDisabled, setIsDisabled] = useState<{
    [x: string]: boolean;
  }>({});
  const dispatch = useDispatch();

  const handleOnBlur = useCallback(
    () =>
      dispatch(
        setNextButton(() => {
          dispatch(
            updateEmployment({
              ...employment,
              hasFinished: true,
              form,
            }) as unknown as Action,
          );
        }) as unknown as Action,
      ),
    [dispatch, employment, form],
  );

  const handleChangeSelect = useCallback(
    (event: { target: { value: unknown } }, prop: string) =>
      setForm({ ...form, [prop]: event.target.value as string }),
    [form],
  );

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) => setForm({ ...form, [prop]: event.target.value }),
    [form],
  );

  const handleChangeSupervisionId = useCallback(
    async (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) => {
      const { value } = event.target;

      if (value && value !== "") {
        dispatch(setLoad(true) as unknown as Action);
        const userEmail = await getUserByIdentification(value);

        if (!userEmail) {
          await createUserWithPassword(
            value,
            passwordGenerator(),
            [userRoles.CORPORATE],
            userStatus.PENDING,
            form?.companyId,
          );

          handleChangeSupervisionId(event, prop);
          dispatch(setLoad(false) as unknown as Action);
        } else {
          dispatch(setLoad(false) as unknown as Action);
          setForm({
            ...form,
            supervisionId: userEmail?._id,
            [prop]: event.target.value,
          });
          dispatch(
            setNextButton(() => {
              dispatch(
                updateEmployment({
                  ...employment,
                  hasFinished: true,
                  form: {
                    ...form,
                    supervisionId: userEmail?._id,
                    [prop]: event.target.value,
                  },
                }) as unknown as Action,
              );
            }) as unknown as Action,
          );
        }
      }
    },
    [dispatch, employment, form],
  );

  const handleChangeRadio = useCallback(
    (event: { target: { value: unknown } }, prop: string) => {
      setForm({ ...form, [prop]: event.target.value === prop });
      dispatch(
        setNextButton(() => {
          dispatch(
            updateEmployment({
              ...employment,
              hasFinished: true,
              form: { ...form, [prop]: event.target.value === prop },
            }) as unknown as Action,
          );
        }) as unknown as Action,
      );
    },
    [dispatch, employment, form],
  );

  const handleChangeRadioNumber = useCallback(
    (event: { target: { value: unknown } }, prop: string) => {
      setForm({ ...form, [prop]: event.target.value });
      dispatch(
        setNextButton(() => {
          dispatch(
            updateEmployment({
              ...employment,
              hasFinished: true,
              form: { ...form, [prop]: event.target.value },
            }) as unknown as Action,
          );
        }) as unknown as Action,
      );
    },
    [dispatch, employment, form],
  );

  const onChangeNotInform = useCallback(
    (prop: string) => {
      if (form[prop] !== "none") {
        const updatedField = {
          ...form,
          [prop]: "none",
        };
        setForm(updatedField);
        dispatch(
          setNextButton(() =>
            dispatch(
              updateEmployment({
                ...employment,
                hasFinished: true,
                form: { ...updatedField },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      } else {
        const returnValueField = {
          ...form,
          [prop]: "",
        };
        setForm(returnValueField);
        dispatch(
          setNextButton(() =>
            dispatch(
              updateEmployment({
                ...employment,
                hasFinished: true,
                form: { ...returnValueField },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      }
    },
    [dispatch, employment, form],
  );

  const items = useMemo(
    (): Item[] => [
      {
        id: "levelEducation",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual é o seu nível educacional ATUAL?"
              />
            )}
            <Select
              id="levelEducation"
              className="width-select-course"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e, "levelEducation")}
              onBlur={handleOnBlur}
              value={form?.levelEducation ?? ""}
              options={Object.keys(LevelEducation)?.map((key) => ({
                value: key,
                label: LevelEducation[key as keyof typeof LevelEducation],
              }))}
            />
          </>
        ),
      },
      {
        id: "courseProgramming",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Depois da {reprograma} você fez/está fazendo algum tipo de curso na área de programação?"
                disabled={isDisabled.courseProgramming}
              />
            )}
            <RadioGroup
              aria-label="courseProgramming"
              name="courseProgramming"
              onChange={(e) => handleChangeRadio(e, "courseProgramming")}
              value={
                form?.courseProgramming !== undefined
                  ? form?.courseProgramming
                    ? "courseProgramming"
                    : "notCourseProgramming"
                  : ""
              }
            >
              <div>
                <FormControlLabel
                  value="courseProgramming"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.courseProgramming}
                />
                <FormControlLabel
                  value="notCourseProgramming"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.courseProgramming}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        id: "courseLevel",
        renderCondition: !!form?.courseProgramming,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual o nível/titulação do curso?"
                disabled={isDisabled.courseLevel}
              />
            )}
            <Select
              id="courseLevel"
              className="width-select-course"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e, "courseLevel")}
              onBlur={handleOnBlur}
              value={form?.courseLevel ?? ""}
              options={Object.keys(CourseLevel)?.map((key) => ({
                value: key,
                label: CourseLevel[key as keyof typeof CourseLevel],
              }))}
              disabled={isDisabled.courseLevel}
            />
          </>
        ),
      },
      {
        id: "nameCourse",
        renderCondition: !!form?.courseProgramming,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Conta pra gente qual o nome do curso que você fez/está fazendo :)"
                disabled={isDisabled.nameCourse}
              />
            )}
            <Input
              id="nameCourse"
              label="Nome do curso"
              onChange={(e) => handleChange(e, "nameCourse")}
              onBlur={handleOnBlur}
              disabled={isDisabled.nameCourse}
              value={form?.nameCourse ?? ""}
            />
          </>
        ),
      },
      {
        id: "nameInstitution",
        renderCondition: !!form?.courseProgramming,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Conta pra gente qual o nome da instituição onde você está estudando"
                disabled={isDisabled.nameInstitution}
              />
            )}
            <Input
              id="nameInstitution"
              label="Nome da instituição"
              onChange={(e) => handleChange(e, "nameInstitution")}
              onBlur={handleOnBlur}
              disabled={isDisabled.nameInstitution}
              value={form?.nameInstitution ?? ""}
              className="margin-top-20"
            />
          </>
        ),
      },
      {
        id: "workingTechnology",
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Quão motivada você está para seguir estudando e trabalhando na área de tecnologia?"
                disabled={isDisabled.workingTechnology}
                subText={
                  <p className="title-italic">
                    Sendo 0 <span>Nem um pouco motivada</span> e 10
                    <span> extremamente motivada</span>.
                  </p>
                }
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="workingTechnology"
                name="workingTechnology"
                defaultValue="top"
                onChange={(e) =>
                  handleChangeRadioNumber(e, "workingTechnology")
                }
                value={form?.workingTechnology ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.workingTechnology}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.workingTechnology}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.workingTechnology}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.workingTechnology}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.workingTechnology}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.workingTechnology}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.workingTechnology}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.workingTechnology}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.workingTechnology}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.workingTechnology}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.workingTechnology}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "positiveImpact",
        renderCondition: employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Você acha que fazer o curso da {reprograma} teve impacto positivo na sua vida profissional?"
                disabled={isDisabled.positiveImpact}
                subText={
                  <p className="title-italic">
                    Sendo 0 <span>Nenhum impacto</span> e 10
                    <span> extremamente positivo</span>.
                  </p>
                }
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="positiveImpact"
                name="positiveImpact"
                defaultValue="top"
                onChange={(e) => handleChangeRadioNumber(e, "positiveImpact")}
                value={form?.positiveImpact ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.positiveImpact}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.positiveImpact}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.positiveImpact}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.positiveImpact}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.positiveImpact}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.positiveImpact}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.positiveImpact}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.positiveImpact}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.positiveImpact}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.positiveImpact}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.positiveImpact}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "indicateReprograma",
        renderCondition: employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual a probabilidade de você recomendar a {reprograma} para outras mulheres?"
                disabled={isDisabled.indicateReprograma}
                subText={
                  <p className="title-italic">
                    Sendo 0 <span>Nem um pouco provável</span> e 10
                    <span> extremamente provável</span>.
                  </p>
                }
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="indicateReprograma"
                name="indicateReprograma"
                defaultValue="top"
                onChange={(e) =>
                  handleChangeRadioNumber(e, "indicateReprograma")
                }
                value={form?.indicateReprograma ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.indicateReprograma}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.indicateReprograma}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.indicateReprograma}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.indicateReprograma}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.indicateReprograma}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.indicateReprograma}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.indicateReprograma}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.indicateReprograma}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.indicateReprograma}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.indicateReprograma}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.indicateReprograma}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "trainingContents",
        renderCondition: employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Você considera que os conteúdos da formação são consistentes e coerentes com a sua realidade?"
                disabled={isDisabled.trainingContents}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="trainingContents"
                name="trainingContents"
                defaultValue="top"
                onChange={(e) => handleChangeRadioNumber(e, "trainingContents")}
                value={form?.trainingContents ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.trainingContents}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.trainingContents}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingContents}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingContents}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingContents}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingContents}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingContents}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingContents}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingContents}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingContents}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingContents}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "emotionalSkills",
        renderCondition: employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Você considera que desenvolveu competências emocionais importantes durante sua formação?"
                disabled={isDisabled.emotionalSkills}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="emotionalSkills"
                name="emotionalSkills"
                defaultValue="top"
                onChange={(e) => handleChangeRadioNumber(e, "emotionalSkills")}
                value={form?.emotionalSkills ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.emotionalSkills}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.emotionalSkills}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.emotionalSkills}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.emotionalSkills}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.emotionalSkills}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.emotionalSkills}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.emotionalSkills}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.emotionalSkills}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.emotionalSkills}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.emotionalSkills}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.emotionalSkills}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "technicalSkills",
        renderCondition: employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Você considera que desenvolveu competências técnicas importantes durante sua formação?"
                disabled={isDisabled.technicalSkills}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="technicalSkills"
                name="technicalSkills"
                defaultValue="top"
                onChange={(e) => handleChangeRadioNumber(e, "technicalSkills")}
                value={form?.technicalSkills ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.technicalSkills}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.technicalSkills}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalSkills}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalSkills}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalSkills}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalSkills}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalSkills}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalSkills}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalSkills}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalSkills}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalSkills}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "facilitatingLevel",
        renderCondition: employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Você considera que as facilitadoras demonstraram elevado nível de conhecimento sobre os assuntos tratados?"
                disabled={isDisabled.facilitatingLevel}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="facilitatingLevel"
                name="facilitatingLevel"
                defaultValue="top"
                onChange={(e) =>
                  handleChangeRadioNumber(e, "facilitatingLevel")
                }
                value={form?.facilitatingLevel ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.facilitatingLevel}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.facilitatingLevel}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.facilitatingLevel}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.facilitatingLevel}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.facilitatingLevel}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.facilitatingLevel}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.facilitatingLevel}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.facilitatingLevel}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.facilitatingLevel}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.facilitatingLevel}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.facilitatingLevel}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "diversityFacilitators",
        renderCondition: employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Você considera que as facilitadoras ajudaram a criar e manter um clima de diálogo, respeito e inclusão às opiniões a às diversidades?"
                disabled={isDisabled.diversityFacilitators}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="diversityFacilitators"
                name="diversityFacilitators"
                defaultValue="top"
                onChange={(e) =>
                  handleChangeRadioNumber(e, "diversityFacilitators")
                }
                value={form?.diversityFacilitators ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.diversityFacilitators}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.diversityFacilitators}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.diversityFacilitators}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.diversityFacilitators}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.diversityFacilitators}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.diversityFacilitators}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.diversityFacilitators}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.diversityFacilitators}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.diversityFacilitators}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.diversityFacilitators}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.diversityFacilitators}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "understandMatters",
        renderCondition: employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Você compreendeu os conteúdos, instrumentos e assuntos abordados nesta formação?"
                disabled={isDisabled.understandMatters}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="understandMatters"
                name="understandMatters"
                defaultValue="top"
                onChange={(e) =>
                  handleChangeRadioNumber(e, "understandMatters")
                }
                value={form?.understandMatters ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.understandMatters}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.understandMatters}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.understandMatters}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.understandMatters}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.understandMatters}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.understandMatters}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.understandMatters}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.understandMatters}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.understandMatters}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.understandMatters}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.understandMatters}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "useTraining",
        renderCondition: employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Você se sente mobilizada para usar os conteúdos, instrumentos e saberes desta formação na sua prática?"
                disabled={isDisabled.useTraining}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="useTraining"
                name="useTraining"
                defaultValue="top"
                onChange={(e) => handleChangeRadioNumber(e, "useTraining")}
                value={form?.useTraining ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.useTraining}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.useTraining}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.useTraining}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.useTraining}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.useTraining}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.useTraining}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.useTraining}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.useTraining}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.useTraining}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.useTraining}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.useTraining}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "futureVision",
        renderCondition: employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Você sai desta formação com tarefas e compromissos específicos que vai realizar?"
                disabled={isDisabled.futureVision}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="futureVision"
                name="futureVision"
                defaultValue="top"
                onChange={(e) => handleChangeRadioNumber(e, "futureVision")}
                value={form?.futureVision ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.futureVision}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.futureVision}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.futureVision}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.futureVision}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.futureVision}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.futureVision}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.futureVision}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.futureVision}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.futureVision}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.futureVision}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.futureVision}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "choseAreaTech",
        renderCondition:
          employment.type === researchType.THREE_MONTHS ||
          employment.type === researchType.SIX_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual o principal motivo pelo qual você escolheu essa área de formação?"
                disabled={isDisabled.choseAreaTech}
              />
            )}
            <Select
              id="choseAreaTech"
              className="width-select-course margin-top-24"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e, "choseAreaTech")}
              onBlur={handleOnBlur}
              value={form?.choseAreaTech ?? ""}
              options={Object.keys(ChoseAreaTech)?.map((key) => ({
                value: key,
                label: ChoseAreaTech[key as keyof typeof ChoseAreaTech],
              }))}
              disabled={isDisabled.choseAreaTech}
            />
          </>
        ),
      },
      {
        id: "testimonialSuggestion",
        renderCondition: employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Abra seu coração. Caso queira, deixe aqui qualquer comentário, depoimento ou 
              sugestão que possa nos ajudar a entender melhor como o curso impactou sua 
              vida e como podemos sempre melhorar essa formação."
                disabled={isDisabled.testimonialSuggestion}
              />
            )}
            <TextArea
              id="testimonialSuggestion"
              label="Escreva aqui"
              maxLength={500}
              onChange={(e) => handleChange(e, "testimonialSuggestion")}
              disabled={isDisabled.testimonialSuggestion}
              onBlur={handleOnBlur}
              value={form?.testimonialSuggestion ?? ""}
              className="margin-top-20"
            />
          </>
        ),
      },
      {
        id: "emailSupervision",
        renderCondition: !!form?.isJobTechnology,
        isFullRow: true,
        child: (
          <>
            <div
              className={`margin-bottom-32 ${
                isDisabled.emailSupervision ? "disabled" : ""
              }`}
            >
              <p className="title-input">
                Com o objetivo de monitorar as metas, cultura e área de
                desenvolvimento profissional das empresas contratantes,
                coletamos essa informação para enviar algumas perguntas rápidas
                para que possamos nos ajudar mutuamente. <br />
                <span>
                  Não se preocupe, não perguntaremos sobre algo que te
                  comprometa.
                </span>
              </p>
            </div>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual o email da principal pessoa encarregada pela sua supervisão/liderança?"
                disabled={isDisabled.emailSupervision}
              />
            )}
            <Input
              id="emailSupervision"
              label="Email"
              onChange={(e) => handleChange(e, "emailSupervision")}
              onBlur={(e) => handleChangeSupervisionId(e, "emailSupervision")}
              value={
                form?.emailSupervision && form?.emailSupervision !== "none"
                  ? form?.emailSupervision
                  : ""
              }
              className="margin-top-20"
              disabled={
                isDisabled.emailSupervision || form?.emailSupervision === "none"
              }
            />
            <Checkbox
              className="padding-top-8"
              id="notInformEmailSupervision"
              label={<div>Não informar</div>}
              checked={form?.emailSupervision === "none"}
              onChange={() => onChangeNotInform("emailSupervision")}
              disabled={isDisabled.emailSupervision}
            />
          </>
        ),
      },

      {
        id: "nameSupervision",
        renderCondition: !!form?.isJobTechnology,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual o nome da principal pessoa encarregada pela sua supervisão/liderança?"
                disabled={isDisabled.nameSupervision}
              />
            )}
            <Input
              id="nameSupervision"
              label="Nome"
              onChange={(e) => handleChange(e, "nameSupervision")}
              onBlur={handleOnBlur}
              disabled={
                isDisabled.nameSupervision || form?.nameSupervision === "none"
              }
              value={
                form?.nameSupervision && form?.nameSupervision !== "none"
                  ? form?.nameSupervision
                  : ""
              }
              className="margin-top-20"
            />
            <Checkbox
              className="padding-top-8"
              id="notInformNameSupervision"
              label={<div>Não informar</div>}
              checked={form?.nameSupervision === "none"}
              onChange={() => onChangeNotInform("nameSupervision")}
              disabled={isDisabled.nameSupervision}
            />
          </>
        ),
      },
    ],
    [
      templateRows,
      handleOnBlur,
      form?.levelEducation,
      form?.courseProgramming,
      form?.courseLevel,
      form?.nameCourse,
      form?.nameInstitution,
      form?.workingTechnology,
      form?.positiveImpact,
      form?.indicateReprograma,
      form?.trainingContents,
      form?.emotionalSkills,
      form?.technicalSkills,
      form?.facilitatingLevel,
      form?.diversityFacilitators,
      form?.understandMatters,
      form?.useTraining,
      form?.futureVision,
      form?.choseAreaTech,
      form?.testimonialSuggestion,
      form?.isJobTechnology,
      form?.emailSupervision,
      form?.nameSupervision,
      isDisabled.courseProgramming,
      isDisabled.courseLevel,
      isDisabled.nameCourse,
      isDisabled.nameInstitution,
      isDisabled.workingTechnology,
      isDisabled.positiveImpact,
      isDisabled.indicateReprograma,
      isDisabled.trainingContents,
      isDisabled.emotionalSkills,
      isDisabled.technicalSkills,
      isDisabled.facilitatingLevel,
      isDisabled.diversityFacilitators,
      isDisabled.understandMatters,
      isDisabled.useTraining,
      isDisabled.futureVision,
      isDisabled.choseAreaTech,
      isDisabled.testimonialSuggestion,
      isDisabled.emailSupervision,
      isDisabled.nameSupervision,
      employment.type,
      handleChangeSelect,
      handleChangeRadio,
      handleChange,
      handleChangeRadioNumber,
      handleChangeSupervisionId,
      onChangeNotInform,
    ],
  );

  const checkDisabled = useCallback(
    (index: number) =>
      !!items
        ?.filter(
          (item: { renderCondition?: boolean }) =>
            item?.renderCondition === undefined || !!item?.renderCondition,
        )
        ?.find(
          (item, i: number) =>
            !form ||
            (i < index &&
              !!form &&
              (form[item.id] === undefined ||
                form[item.id] === "" ||
                form[item.id] === 0)),
        ),
    [form, items],
  );

  const getIsDisabled = useCallback(
    () =>
      setIsDisabled(
        items
          ?.filter(
            (item: { renderCondition?: boolean }) =>
              item?.renderCondition === undefined || !!item?.renderCondition,
          )
          ?.map((innerItem: { id: string }, index: number) => {
            if (index !== 0)
              return {
                [innerItem?.id]: checkDisabled(index),
              };
            return { [innerItem?.id]: false };
          })
          .concat({ next: checkDisabled(items?.length) })
          .reduce((acc, cur) => Object.assign(acc, cur)),
      ),
    [checkDisabled, items],
  );

  useEffect(() => {
    getIsDisabled();
  }, [getIsDisabled]);

  const itemsRefs = useMemo(
    () => items.map(() => createRef<HTMLDivElement>()),
    [items],
  );

  const updateLabelHeights = useCallback(() => {
    if (templateRows) {
      const getLabel = (ref: HTMLDivElement | null) => {
        if (ref) {
          const { children } = ref;
          // @ts-ignore
          const [label] = children;
          return label;
        }
        return ref;
      };

      const getLabelHeight = (label: HTMLParagraphElement) => {
        const { offsetHeight } = label;
        return offsetHeight;
      };

      const removeLabelHeight = (label: HTMLParagraphElement) => {
        const { style } = label;
        style.removeProperty(heightProperty);
        label.removeAttribute(expandedAttribute);
        return label;
      };

      const setLabelHeight = (label: HTMLParagraphElement, height: number) => {
        const { style } = label;
        style.setProperty(heightProperty, `${height}px`);
        label.setAttribute(expandedAttribute, "");
      };

      itemsRefs
        .filter(
          ({ current }) =>
            current && !current.className.includes(fullRowClassName),
        )
        .forEach(({ current: rightItem }, index, arr) => {
          if (index % 2 !== 0) {
            // Coluna esquerda
            const { current: leftItem } = arr[index - 1];
            const leftLabel = removeLabelHeight(getLabel(leftItem));
            const leftHeight = getLabelHeight(leftLabel);

            // Coluna direita
            const rightLabel = removeLabelHeight(getLabel(rightItem));
            const rightHeight = getLabelHeight(rightLabel);

            // Expansão de altura
            if (leftHeight > rightHeight)
              setLabelHeight(rightLabel, leftHeight);
            else setLabelHeight(leftLabel, rightHeight);

            // Redução de altura
            if (
              leftHeight < rightHeight &&
              rightLabel.hasAttribute(expandedAttribute)
            ) {
              removeLabelHeight(leftLabel);
              setLabelHeight(rightLabel, leftHeight);
            } else if (
              rightHeight < leftHeight &&
              leftLabel.hasAttribute(expandedAttribute)
            ) {
              removeLabelHeight(rightLabel);
              setLabelHeight(leftLabel, rightHeight);
            }
          }
        });
    }
  }, [itemsRefs, templateRows]);

  useEffect(() => {
    const handleResize = () => {
      updateLabelHeights();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [updateLabelHeights]);

  useEffect(() => updateLabelHeights(), [updateLabelHeights]);

  useEffect(() => {
    if (!isDisabled.next) setIsValid(true);
    else setIsValid(false);
  }, [isDisabled, setIsValid]);

  return (
    <div className="grid-account-gap grid-account-gap-resposive">
      {items.map(
        ({ isFullRow, renderCondition, className, child }, index) =>
          (renderCondition ?? true) && (
            <div
              ref={itemsRefs[index]}
              className={
                isFullRow
                  ? fullRowClassName
                  : `content-grid-row ${
                      templateRows
                        ? `content-grid-2-row ${className ?? ""}`
                        : ""
                    }`
              }
            >
              {child}
            </div>
          ),
      )}
    </div>
  );
};
export default memo(YouReprograma);
