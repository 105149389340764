/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  memo,
  useEffect,
  useState,
  useCallback,
  ChangeEvent,
} from "react";
import { toast } from "react-toastify";
import { FormControlLabel, Switch } from "@material-ui/core";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { Media, UserCurriculumEducation } from "../../types/interfaces";
import { Delete } from "../../../assets/icons";
import {
  Input,
  Select,
  TextArea,
  Checkbox,
  InputDate,
  CardMedia,
  Draggable,
  IconButton,
  ModalMedia,
  AttachmentMedia,
  InputAutocomplete,
} from "..";
import api from "../../services/api";
import {
  getCompany,
  setNewCompany,
  getAutocomplete,
  searchCompaniesAllStatus,
  searchCompaniesAutocomplete,
} from "../../services/functions";
import {
  mediaType,
  companyType,
  companyStatus,
  autoCompleteType,
} from "../../types/enumerators";
import { registerAutoComplete } from "../../utils";
import "./styles.scss";
import { CurriculumEducationLevel } from "../../types/constants/User";

interface Props {
  setItem: any;
  setIsValid: any;
  education: UserCurriculumEducation;
  setIsCurrentEducation?: (value: React.SetStateAction<boolean>) => void;
}

const AddEducation = ({
  setItem,
  education,
  setIsValid,
  setIsCurrentEducation,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [editMediaIndex, setEditMediaIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [media, setMedia] = useState<Media>({} as Media);
  const [isCurrentEducation, _setIsCurrentEducation] = useState(
    education?.endDate !== undefined ? !education?.endDate : false,
  );
  const [_education, _setEducation] =
    useState<UserCurriculumEducation>(education);
  const [institution, setInstitution] = useState("");
  const [institutionsOptions, setInstitutionsOptions] =
    useState<{ name: string; id: string }[]>();
  const [titleOptions, setTitleOptions] = useState<string[]>();

  const loadTitles = useCallback(async () => {
    const titleOptionsResponse = await getAutocomplete(
      autoCompleteType.EDUCATION_TITLE,
    );

    const titleOptionsMapped = titleOptionsResponse?.map(
      (option) => option?.name,
    );

    setTitleOptions(titleOptionsMapped);
  }, []);

  useEffect(() => {
    if (!titleOptions) loadTitles();
  }, [loadTitles, titleOptions]);

  const loadInstitutions = useCallback(async () => {
    try {
      const companyOptionsResponse = await searchCompaniesAutocomplete(
        companyType.INSTITUTION,
      );

      const companyOptionsMapped = companyOptionsResponse?.map((option) => ({
        name: option?.profile?.name ?? "",
        id: option?._id ?? "",
      }));

      setInstitutionsOptions(companyOptionsMapped ?? []);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  useEffect(() => {
    if (!institutionsOptions) loadInstitutions();
  }, [institutionsOptions, loadInstitutions]);

  const getCompanyName = useCallback(async () => {
    try {
      const company = await getCompany(_education?.companyId ?? "");

      if (company) setInstitution(company?.profile?.name ?? "");
    } catch (err) {
      console.warn(err);
    }
  }, [_education?.companyId]);

  useEffect(() => {
    if (_education?.companyId) getCompanyName();
  }, [_education?.companyId, getCompanyName]);

  useEffect(() => {
    if (
      _education.companyId &&
      _education.level &&
      _education.title &&
      _education.description &&
      _education.startDate &&
      (_education.endDate || isCurrentEducation)
    )
      setIsValid(true);
    else setIsValid(false);
  }, [
    _education.companyId,
    _education.endDate,
    _education.level,
    _education.startDate,
    _education.title,
    _education.description,
    isCurrentEducation,
    setIsValid,
  ]);

  useEffect(() => {
    if (_education?.medias?.length) {
      _education?.medias?.sort((a: Media, b: Media) => {
        if ("order" in a && "order" in b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        }
        return 0;
      });
    }
  }, [_education.medias]);

  const handleOnBlur = useCallback(
    () => setItem(_education),
    [_education, setItem],
  );

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      prop: keyof UserCurriculumEducation,
      value?: boolean,
    ) => {
      if (value !== undefined) {
        _setEducation({ ..._education, [prop]: value });
      } else _setEducation({ ..._education, [prop]: event.target.value });
    },
    [_education],
  );

  const handleChangeSelect = useCallback(
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
      prop: keyof UserCurriculumEducation,
    ) =>
      _setEducation({
        ..._education,
        [prop]: event.target.value as string,
      }),
    [_education],
  );

  const handleChangeAutoComplete = useCallback(
    async (value: string | null, prop: keyof UserCurriculumEducation) => {
      registerAutoComplete(value, autoCompleteType.EDUCATION_TITLE);

      _setEducation({
        ..._education,
        [prop]: value as string,
      });

      setItem({
        ..._education,
        [prop]: value as string,
      });
    },
    [_education, setItem],
  );

  const setCompany = useCallback(
    async (value: string | null) => {
      if (value && value !== "") {
        const company = await searchCompaniesAllStatus(value);

        if (!company?.length) {
          await setNewCompany(
            value,
            companyType.INSTITUTION,
            companyStatus.PENDING,
            companyStatus.NONE,
          );

          setCompany(value);
        } else {
          _setEducation({
            ..._education,
            companyId: company[0]._id || "",
          });

          setItem({
            ..._education,
            companyId: company[0]._id,
          });
        }
      }
    },
    [_education, setItem],
  );

  const handleLink = useCallback(
    (e: { key: string; target: { value: string } }) => {
      if (e.key === "Enter") {
        let { medias: newMedias } = _education;
        const link = {
          url: e.target.value,
          type: mediaType.LINK,
          order: _education?.medias?.length ?? 0,
        };

        if (newMedias?.length) newMedias.push(link);
        else newMedias = [link];

        e.target.value = "";
        _setEducation({ ..._education, medias: newMedias });
      }
    },
    [_education],
  );

  const saveMedia = useCallback(() => {
    let { medias: newMedias } = _education;

    if (newMedias && editMediaIndex !== -1) {
      newMedias[editMediaIndex] = media;
    } else if (newMedias?.length) newMedias.push(media);
    else newMedias = [media];

    _setEducation({ ..._education, medias: newMedias });
    setItem({ ..._education, medias: newMedias });
    setMedia({} as Media);
    setEditMediaIndex(-1);
    setOpenModal(false);
  }, [_education, editMediaIndex, media, setItem]);

  const removeMedia = useCallback(
    (index: number) => () => {
      let { medias: oldMedias } = _education;
      oldMedias?.splice(index, 1);
      oldMedias = oldMedias?.map((item: Media, i: number) => ({
        ...item,
        order: i,
      }));
      _setEducation({ ..._education, medias: oldMedias });
      setItem({ ..._education, medias: oldMedias });
    },
    [_education, setItem],
  );

  const handleMedia = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      api
        .post("/fileUpload", data)
        .then((result) => {
          setOpenModal(true);
          setMedia({
            ...media,
            url: result?.data?.file_url.url,
            type: fileList[0].type.includes("image")
              ? mediaType.PHOTO
              : mediaType.DOCUMENT,
            order: _education?.medias?.length ?? 0,
          });
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    },
    [_education, media],
  );

  const handleChangeMedia = useCallback(
    (prop: string | number | symbol) =>
      (event: ChangeEvent<HTMLInputElement>) =>
        setMedia({ ...media, [prop]: event.target.value }),
    [media],
  );

  const editMedia = useCallback(
    (item: Media, index: number) => () => {
      setMedia({ ...item });
      setEditMediaIndex(index);
      setOpenModal(true);
    },
    [],
  );

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setEditMediaIndex(-1);
    setMedia({} as Media);
  }, []);

  const reorderMedias = useCallback(
    (items: Media[]) => {
      let { medias: newMedias } = _education;
      newMedias = items.map((item, index) => ({ ...item, order: index }));
      _setEducation({ ..._education, medias: newMedias });
      setItem({ ..._education, medias: newMedias });
    },
    [_education, setItem],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="add-education">
      <div className="flex flex-col-mobile">
        {width < 991 && <p className="p-mobile">Instituição*</p>}
        <InputAutocomplete
          label="Instituição*"
          helperText="Pressione Enter para adicionar"
          value={institution}
          onBlur={(event) => setCompany(event.target.value)}
          className="add-education-input margin-right-32 margin-bottom-20-mobile margin-top-MuiFormLabel-root"
          options={institutionsOptions?.map((item) => item?.name) ?? [""]}
          getOptionLabel={(option) => option}
        />
        {width < 991 && <p className="p-mobile">Nível*</p>}
        <Select
          id="level"
          label="Nível*"
          value={_education.level}
          onChange={(e) => handleChangeSelect(e, "level")}
          onBlur={handleOnBlur}
          options={Object.keys(CurriculumEducationLevel).map((key) => ({
            value: key,
            label:
              CurriculumEducationLevel[
                key as keyof typeof CurriculumEducationLevel
              ],
          }))}
          className="add-education-input margin-bottom-20-mobile"
        />
      </div>
      {width < 991 && <p className="p-mobile">Curso*</p>}
      <InputAutocomplete
        label="Curso*"
        helperText="Pressione Enter para adicionar"
        value={_education.title}
        onBlur={(event) =>
          handleChangeAutoComplete(event.target.value, "title")
        }
        className="add-education-input margin-top-24 margin-bottom-20-mobile margin-top-0-mobile margin-top-MuiFormLabel-root"
        options={titleOptions ?? [""]}
        getOptionLabel={(option) => option}
      />
      {width < 991 && <p className="p-mobile">Descrição*</p>}
      <TextArea
        id="description"
        label="Descrição*"
        maxLength={300}
        value={_education.description}
        onChange={(e) => handleChange(e, "description")}
        onBlur={handleOnBlur}
        className="description-input add-education-description margin-bottom-20-mobile margin-top-0-mobile"
      />
      <div className="flex align-items flex-col-mobile align-items-baseline-mobile">
        <div className="flex gap-16-mobile margin-bottom-16-mobile">
          <div>
            {width < 991 && <p className="p-mobile">Início*</p>}
            <InputDate
              label="Início*"
              helperText="MM/AAAA"
              value={_education.startDate || null}
              onChange={(value) => handleChangeAutoComplete(value, "startDate")}
              className="add-education-date"
            />
          </div>
          <div>
            {width < 991 && !isCurrentEducation && (
              <p className="p-mobile margin-left-10">Fim*</p>
            )}
            {!isCurrentEducation && (
              <InputDate
                label="Fim*"
                helperText="MM/AAAA"
                value={_education.endDate || null}
                minDate={_education.startDate}
                onChange={(value) => handleChangeAutoComplete(value, "endDate")}
                className="add-education-date"
              />
            )}
          </div>
        </div>
        <Checkbox
          id="isCurrentEducation"
          label="Ainda estou cursando"
          className="margin-bottom-8"
          checked={isCurrentEducation}
          onChange={() => {
            if (setIsCurrentEducation)
              setIsCurrentEducation(!isCurrentEducation);
            _setIsCurrentEducation(!isCurrentEducation);
            _setEducation({ ..._education, endDate: null });
            setItem({ ..._education, endDate: null });
          }}
        />
      </div>
      <FormControlLabel
        control={
          <Switch
            checked={_education.scholarship}
            onChange={(e) => {
              handleChange(e, "scholarship", !_education.scholarship);
              setItem({ ..._education, scholarship: !_education.scholarship });
            }}
            name="scholarship"
          />
        }
        label="Curso feito com bolsa de estudos"
        className="margin-top-MuiTypography-body1"
      />
      <p className="text-p margin-y-24">Mídias</p>
      <p className="text-p margin-bottom-24">
        Adicione um link ou arquivos para documentos, fotos, sites, vídeos e
        apresentações externas.
      </p>
      <div className="flex-row-center">
        <Input
          label="Link"
          type="text"
          helperText="Pressione Enter para adicionar"
          className="add-education-input margin-right-32 margin-right-0-responsive"
          onKeyDown={handleLink}
          onBlur={handleOnBlur}
        />
        {width > 990 ? (
          <AttachmentMedia
            onChange={handleMedia}
            className="margin-bottom-16"
          />
        ) : (
          ""
        )}
      </div>
      {width < 991 ? <AttachmentMedia onChange={handleMedia} /> : ""}
      {_education?.medias &&
        _education?.medias?.filter((item) => item?.type === mediaType.LINK)
          ?.length > 0 && (
          <div className="margin-top-32">
            {_education?.medias?.map((item, index) => {
              if (item?.type === mediaType.LINK) {
                return (
                  <div className="flex-row-center" key={Math.random()}>
                    <a
                      href={
                        item?.url?.includes("http")
                          ? item?.url
                          : `http://${item?.url}`
                      }
                      className="text-p"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item?.url}
                    </a>
                    <IconButton
                      icon={<Delete />}
                      onClick={removeMedia(index)}
                    />
                  </div>
                );
              }
              return <div />;
            })}
          </div>
        )}
      {_education?.medias &&
        _education?.medias?.filter((item) => item?.type !== mediaType.LINK)
          ?.length > 0 && (
          <Draggable items={_education.medias} reorderItem={reorderMedias}>
            <div className="flex flex-wrap align-items">
              {_education.medias?.map((item, index) => {
                if (item?.type !== mediaType.LINK) {
                  return (
                    <DraggableComponent
                      key={Math.random()}
                      draggableId={Math.random().toString()}
                      index={index}
                      isDragDisabled={
                        _education.medias?.filter(
                          (innerItem) => innerItem.type !== mediaType.LINK,
                        )?.length === 1
                      }
                    >
                      {(innerProvided) => (
                        <div
                          ref={innerProvided.innerRef}
                          {...innerProvided.draggableProps}
                          {...innerProvided.dragHandleProps}
                        >
                          <CardMedia
                            item={item}
                            onEdit={editMedia(item, index)}
                            onRemove={removeMedia(index)}
                          />
                        </div>
                      )}
                    </DraggableComponent>
                  );
                }
                return <div />;
              })}
            </div>
          </Draggable>
        )}
      <ModalMedia
        openModal={openModal}
        onClose={closeModal}
        onChange={handleChangeMedia}
        medias={media}
        onSave={saveMedia}
        disabled={!media.title || !media.description}
      />
    </div>
  );
};

export default memo(AddEducation);
