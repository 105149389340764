import React, { memo } from "react";
import { Card, Layout } from "../../components";
import "./style.scss";

const CourseEmpty = (): JSX.Element => (
  <Layout headerCourse className="grid-gap-mobile">
    <Card
      bodyClassName="card-course"
      className="grid-column-2-12 border-card mobile-card-course"
    >
      <p className="description">Olá!</p>
      <p className="description">
        Esta fase do processo seletivo já está fechada.
      </p>
      {/* <p className="description">
        Se você se inscreveu acompanhe seu e-mail. Lembre-se de sempre checar o
        spam.
      </p> */}
      <p className="description">
        Se você não se inscreveu ou não conseguiu concluir sua inscrição, fique
        de olho e acompanhe o calendário pelo nosso{" "}
        <a href="https://www.reprograma.com.br">site </a>
        ;)
      </p>
    </Card>
  </Layout>
);

export default memo(CourseEmpty);
