import React, { memo, useEffect, useState } from "react";
import { IconButton } from "..";
import { File, Move, AudioIcon } from "../../../assets/icons";
import { Delete, Edit } from "../../../assets/customIcons";
import { mediaType } from "../../types/enumerators";
import "./styles.scss";

interface Props {
  item?: {
    url?: string;
    title?: string;
    type?: string;
    originType?: string;
  };
  onEdit?: () => void;
  onRemove?: () => void;
  small?: boolean;
  cantEdit?: boolean;
}

const CardMedia = ({
  item,
  onEdit,
  onRemove,
  small,
  cantEdit,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [mediaFileName, setMediaFileName] = useState<string>("");

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const title = item?.title;
    if (title) {
      let fileName;

      if (typeof title === "object") fileName = title?.get("file")?.name;
      if (typeof title === "string") {
        if (title?.length > 50) {
          const match = title?.match(/-(.+)$/);

          if (match) [, fileName] = match;
        } else fileName = title;
      }

      setMediaFileName(fileName);
    }
  }, [item?.title]);

  return (
    <div
      className={`card-media-component  ${
        small ? "card-media-component-small" : "card-media-component-large"
      }`}
    >
      <div
        className={`card-media-component-media ${
          small
            ? "card-media-component-media-small"
            : "card-media-component-media-large"
        } `}
      >
        {!small && !cantEdit && <Move className="absolute" />}
        {item?.type === mediaType.VIDEO ? (
          <video className="video-card" autoPlay preload="metadata">
            <track kind="captions" />
            <source src={`${item?.url}#t=0.5`} type={item?.originType} />
            Your browser does not support the video tag.
          </video>
        ) : item?.type === mediaType.PHOTO ? (
          <a href={item?.url ?? ""} target="_blank" rel="noreferrer">
            <img
              src={item?.url ?? ""}
              alt="mídia"
              width={small ? 146 : 220}
              height={small ? 125 : 170}
              className="card-media-component-image"
            />
          </a>
        ) : (
          <div className="card-media-component-card">
            <a
              href={
                item?.url?.includes("http") ? item?.url : `http://${item?.url}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {item?.type === mediaType.AUDIO ? <AudioIcon /> : <File />}
            </a>
          </div>
        )}
        <div className="card-media-component-title">
          <p
            className={`p-card-media ${
              small ? "p-card-media-small" : "p-card-media-medium-large"
            }`}
          >
            {mediaFileName}
          </p>
          {!small && (
            <div className="flex align-items">
              {!cantEdit && (
                <IconButton
                  icon={
                    <Edit
                      widthIcon={width < 991 ? 20 : 24}
                      heightIcon={width < 991 ? 20 : 24}
                    />
                  }
                  onClick={onEdit}
                  className="margin-right-8 cursor-pointer"
                />
              )}

              {onRemove && (
                <IconButton
                  icon={
                    <Delete
                      widthIcon={width < 991 ? 20 : 24}
                      heightIcon={width < 991 ? 20 : 24}
                    />
                  }
                  onClick={onRemove}
                  className="margin-right-8 cursor-pointer"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(CardMedia);
