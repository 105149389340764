/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  memo,
  useRef,
  useEffect,
  useState,
  FocusEventHandler,
} from "react";
import MomentUtils from "@date-io/moment";
import moment, { Moment } from "moment";
import {
  DateTimePickerView,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Calendar } from "../../../assets/icons";
import "moment/locale/pt-br";
import "./styles.scss";

interface Props {
  label: string;
  helperText?: string;
  value?: Date | string | null;
  onChange: (date: any) => void;
  onAccept?: (date: any) => void;
  title?: string;
  minDate?: Moment | Date;
  maxDate?: Date;
  disabled?: boolean;
  future?: boolean;
  views?: DateTimePickerView[];
  format?: string;
  className?: string;
  notAuto?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

const blur = (event: any) => {
  setTimeout(() => {
    const input = event.target;
    if (input.value === "") {
      const blurInputDate = input.parentElement.parentElement;
      const label = blurInputDate.childNodes[0];
      label.classList.toggle("MuiInputLabel-shrink");
    }
  }, 100);
};

const InputDateTime = ({
  label,
  helperText,
  value,
  onChange,
  onAccept,
  title,
  minDate,
  maxDate,
  disabled,
  future,
  views,
  format,
  className,
  notAuto,
  onBlur,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const target = ref.current.querySelector(".MuiFormLabel-root");

      if (target) target.classList.remove("MuiInputLabel-shrink");
    }
  }, []);

  useEffect(() => {
    if (value && ref.current) {
      const target = ref.current.querySelector(".MuiFormLabel-root");

      if (target) target.classList.add("MuiInputLabel-shrink");
    }
  }, [value]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={` ${width < 991 && "width-100-mobile"} ${className}`}
      ref={ref}
    >
      {title && (
        <p
          className={
            disabled ? "disabled p-title-input-date" : "p-title-input-date"
          }
        >
          {title}
        </p>
      )}
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDateTimePicker
          autoOk={!notAuto}
          disableFuture={!future}
          disableToolbar
          variant={notAuto ? "dialog" : "inline"}
          inputVariant="filled"
          openTo="year"
          views={views ?? ["year", "month", "hours", "minutes"]}
          ampm={false}
          label={label}
          helperText={helperText}
          value={value}
          format={format ?? "MM/yyyy HH:mm"}
          onChange={onChange}
          onAccept={onAccept}
          keyboardIcon={<Calendar />}
          InputAdornmentProps={{ position: "start" }}
          onBlur={(e) => {
            blur(e);
            if (onBlur) onBlur(e);
          }}
          className={`${disabled ? "disabled" : ""} date-input`}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default memo(InputDateTime);
