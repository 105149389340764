import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Whatsapp = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#F5F6F8"
      d="M22.284 5.687c-2.217-2.143-5.133-3.354-8.24-3.354-8.915 0-14.513 9.66-10.061 17.343l-1.649 5.99 6.159-1.605c2.683 1.45 4.881 1.32 5.557 1.406 10.338 0 15.483-12.506 8.22-19.743l.014-.037z"
    />
    <path
      fill="#4CAF50"
      d="M14.065 23.48H14.044c-3.094 0-5.07-1.466-5.265-1.55l-3.646.947.978-3.543-.233-.365c-.962-1.532-1.474-3.297-1.474-5.114 0-8.549 10.447-12.823 16.492-6.781 6.03 5.98 1.796 16.406-6.83 16.406z"
    />
    <path
      fill="#F5F6F8"
      d="M19.355 16.243l-.01.073c-.292-.146-1.717-.843-1.982-.94-.596-.22-.428-.034-1.572 1.276-.17.19-.34.204-.629.073-.291-.146-1.227-.452-2.336-1.444-.863-.773-1.443-1.72-1.614-2.012-.284-.492.312-.562.854-1.589.097-.204.048-.364-.024-.51-.073-.145-.654-1.574-.897-2.144-.233-.568-.473-.496-.653-.496-.56-.048-.97-.04-1.33.335-1.57 1.724-1.173 3.504.17 5.396 2.638 3.453 4.044 4.089 6.614 4.972.694.22 1.327.19 1.828.117.558-.088 1.718-.7 1.96-1.386.248-.686.248-1.254.175-1.386-.072-.13-.263-.204-.554-.335z"
    />
  </svg>
);

export default Whatsapp;
