/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from "react";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { Checkbox, Select, Input, InputDate } from "../..";

export interface ModalFilterProps {
  id?: string;
  label?: string;
  type?: string;
  value?: any;
  hide?: boolean;
  handleChange?: any;
  inputType?: "number" | "text" | "password";
  inputLabel?: string;
  child?: JSX.Element;
  options?: { value: string | boolean; label: string }[];
}

interface Props {
  filters?: ModalFilterProps[];
}

const Filters = ({ filters }: Props): JSX.Element => (
  <div className="modal-filters-list">
    <div className="modal-filters-content">
      {filters
        ?.filter((filtered) => !filtered?.hide)
        .map((item) => (
          <>
            <div className="modal-filters-title">
              <h4 className="view-bold">{item?.label}</h4>
            </div>
            <div className={`modal-filters-items ${item?.type} ${item?.id}`}>
              {item?.type === "checkbox" &&
                item?.options?.map(({ value, label }) => (
                  <Checkbox
                    key={Math.random()}
                    id={value as string}
                    label={label}
                    checked={item?.value?.includes(value as string)}
                    className="is-checkbox"
                    onChange={() => item?.handleChange(value)}
                  />
                ))}
              {item?.type === "radio" && (
                <RadioGroup row value={item?.value || undefined}>
                  {item?.options?.map(({ value, label }) => (
                    <FormControlLabel
                      key={Math.random()}
                      value={value}
                      control={<Radio />}
                      label={label}
                      className="margin-right-32"
                      checked={value === item?.value}
                      onChange={() => item?.handleChange(value)}
                    />
                  ))}
                </RadioGroup>
              )}
              {item?.type === "select" && (
                <Select
                  id={item?.id ?? ""}
                  label="Selecione uma opção"
                  value={item?.value ?? ""}
                  options={
                    item?.options?.map(({ value, label }) => ({
                      value,
                      label,
                    })) ?? [{ value: "", label: "" }]
                  }
                  onChange={item?.handleChange}
                />
              )}
              {item?.type === "text" && (
                <Input
                  type={item?.inputType}
                  label={item?.inputLabel}
                  value={item?.value ?? ""}
                  onChange={item?.handleChange}
                  isGrade
                />
              )}
              {item?.type === "date" && (
                <InputDate
                  className="date"
                  label={item?.inputLabel ?? ""}
                  views={["year", "month", "date"]}
                  format="DD/MM/yyyy"
                  value={item?.value ?? null}
                  onChange={item?.handleChange}
                />
              )}
              {item?.type === "others" && item?.child && item?.child}
            </div>
          </>
        ))}
    </div>
  </div>
);

export default memo(Filters);
