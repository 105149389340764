import React, {
  memo,
  MouseEventHandler,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Action } from "redux";
import {
  AvatarEmpty,
  CompanyAvatarEmpty,
  Bookmark,
  Invites,
} from "../../../assets/customIcons";
import "./style.scss";
import {
  isSavedThisVacancy,
  saveAVacancy,
  unsaveAVacancy,
} from "../../services/functions";
import { RootState } from "../../store/reducers";
import { JobInformation } from "..";
import { hasCorporateRole } from "../../utils";
import { setLoad } from "../../store/actions/configurationsActions";
import {
  placementVacancyManagedBy,
  placementVacancyStatus,
  placementVacancyTypeOfCandidature,
} from "../../types/enumerators";

interface Props {
  id: string;
  person?: boolean;
  company?: boolean;
  jobTitle?: string;
  jobPlace?: string;
  jobType?: string;
  jobStatus?: string;
  jobManagedBy?: string;
  jobTypeOfCandidature?: string;
  jobDate?: string;
  personName?: string;
  personPosition?: string;
  personCity?: string;
  personDescription?: string;
  personPhoto?: string;
  companyName?: string;
  companyWebsite?: string;
  companyDescription?: string;
  photo?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  hasVacancies?: boolean;
  sendInvite?: boolean;
}

const CardSearch = ({
  person,
  company,
  jobTitle,
  jobStatus,
  jobManagedBy,
  jobTypeOfCandidature,
  jobDate,
  jobPlace,
  jobType,
  personName,
  personPosition,
  personCity,
  personDescription,
  personPhoto,
  companyName,
  photo,
  companyWebsite,
  companyDescription,
  id,
  hasVacancies,
  sendInvite,
  className,
  onClick,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();
  const user = useSelector((state: RootState) => state.userState);
  const isCorporate = hasCorporateRole(user);
  const [isSaved, setIsSaved] = useState(false);

  const dispatch = useDispatch();

  const checkSaved = useCallback(async () => {
    const saved = await isSavedThisVacancy(id, user._id);

    setIsSaved(saved);
  }, [id, user._id]);

  useEffect(() => {
    if (!person && !company) {
      checkSaved();
    }
  }, [checkSaved, company, id, person, user._id]);

  const handleSaveVacancy = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    try {
      if (!isSaved) {
        await saveAVacancy(id, user._id);

        setIsSaved(true);
        toast.success("Vaga salva com sucesso!");
      } else {
        await unsaveAVacancy(id, user._id);
        setIsSaved(false);
      }
    } catch (_err) {
      toast.error("Ocorreu um erro ao salvar a vaga");
    }
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, isSaved, id, user._id]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const currentStatus = useMemo(
    () =>
      jobManagedBy === placementVacancyManagedBy.EXTERNAL_VACANCY
        ? "Curadoria"
        : jobStatus === placementVacancyStatus.ACTIVE
        ? jobTypeOfCandidature === placementVacancyTypeOfCandidature.OPEN
          ? "Recebendo candidaturas"
          : "Apenas convidadas"
        : jobStatus === placementVacancyStatus.PENDING
        ? "Inicia em breve"
        : "Vaga fechada",
    [jobManagedBy, jobStatus, jobTypeOfCandidature],
  );

  return person ? (
    <div className={`card-search-component ${className || ""}`}>
      <div className="card-search-component-container">
        <div
          className="display-inline cursor-pointer"
          aria-label="profile"
          role="button"
          tabIndex={0}
          onClick={() =>
            history.push({
              pathname: `/profile/${id}`,
              state: { id },
            })
          }
        >
          <div>
            {personPhoto ? (
              <img
                src={personPhoto}
                alt="Foto"
                className="card-search-component-image"
              />
            ) : (
              <AvatarEmpty className="card-search-component-avatar" />
            )}
          </div>
          <div className="card-search-component-break">
            <h1 className="card-search-component-title"> {personName}</h1>
            <h2 className="card-search-component-name"> {personPosition} </h2>
            <p className="card-search-component-city"> {personCity}</p>
            <p className="card-search-component-description">
              {personDescription}
            </p>
          </div>
        </div>
        {isCorporate && width > 990 && hasVacancies && sendInvite ? (
          <div className="card-search-component-search">
            <button
              type="button"
              className="card-search-component-button"
              onClick={onClick}
            >
              ENVIAR CONVITE
            </button>
          </div>
        ) : null}
        {isCorporate && width < 991 && hasVacancies && sendInvite ? (
          <div className="card-search-component-search">
            <Invites
              className="card-search-component-button-mobile"
              onClick={onClick}
            />
          </div>
        ) : null}
      </div>
    </div>
  ) : company ? (
    <div className="card-search-component-search-card">
      <div className="card-search-component-container is-company">
        <div className="display-inline">
          <div>
            {photo ? (
              <img
                src={photo}
                alt="Logo"
                className="card-search-component-image"
              />
            ) : (
              <CompanyAvatarEmpty className="size-48" />
            )}
          </div>
          <div className="card-search-component-break">
            <h1 className="card-search-component-title"> {companyName}</h1>
            <h2 className="card-search-component-name"> {companyWebsite} </h2>
            <p className="card-search-component-description">
              {companyDescription}
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="card-search-component-search-card">
      <div className="card-search-component-container is-company">
        <div className="display-inline card-search-component-content">
          <div className="margin-right-20">
            {photo ? (
              <img
                src={photo}
                alt="Logo"
                className="card-search-component-image"
              />
            ) : (
              <CompanyAvatarEmpty className="size-48" />
            )}
          </div>
          <div className="flex width-100 space-between">
            <JobInformation
              data={{
                companyName,
                jobPlace,
                jobStatus,
                jobDate,
                jobTitle,
                jobType,
                jobManagedBy,
                jobTypeOfCandidature,
              }}
              rawStatus
              titleClassName="margin-0"
              statusClassName={`card-search-component-status ${
                currentStatus === "Recebendo candidaturas"
                  ? "job-inf-status-active"
                  : currentStatus === "Curadoria"
                  ? "job-inf-external-vacancy"
                  : "job-inf-status-other"
              }`}
            />
          </div>
          <div
            className="cursor-pointer"
            aria-label="save"
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.stopPropagation();
              handleSaveVacancy();
            }}
          >
            <Bookmark
              filled={isSaved}
              widthIcon={width < 991 ? 20 : 36}
              heightIcon={width < 991 ? 20 : 36}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CardSearch);
