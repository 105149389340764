/* eslint-disable @typescript-eslint/no-explicit-any */
import { Slider } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { InputAutocomplete, IconButton, Draggable } from "..";
import { getAutocomplete } from "../../services/functions";
import { autoCompleteType } from "../../types/enumerators";
import { Delete, Move } from "../../../assets/icons";
import "./styles.scss";
import { RootState } from "../../store/reducers";
import { registerAutoComplete } from "../../utils";

interface Props {
  setItem: any;
}

const styles = {
  slider: {
    width: "134px",
    marginLeft: "40px",
  },
};

const AddLanguage = ({ setItem }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [languagesOptions, setLanguagesOptions] = useState<string[]>();
  const { curriculum } = useSelector((state: RootState) => state.userState);
  const [_curriculum, _setCurriculum] = useState(curriculum);

  useEffect(() => {
    if (Object.keys(curriculum)?.length && !Object.keys(_curriculum)?.length) {
      _setCurriculum({ ...curriculum });
    }
  }, [_curriculum, curriculum]);

  const loadLanguages = useCallback(async () => {
    const response = await getAutocomplete(autoCompleteType.LANGUAGE);

    const languageOptions = response?.map((option) => option?.name);

    setLanguagesOptions(languageOptions);
  }, []);

  useEffect(() => {
    if (!languagesOptions) loadLanguages();
  }, [languagesOptions, loadLanguages]);

  const onUpdate = useCallback(
    async (value: string[] | string | null) => {
      let { languages: newLanguage } = _curriculum;
      if (value && typeof value !== "string") {
        newLanguage = value.map((item) => {
          if (newLanguage?.length) {
            const language = newLanguage?.find(
              (innerItem: { language: string }) => innerItem.language === item,
            );
            return {
              language: item,
              proecifience: language?.proecifience || 1,
              order: (language?.order || newLanguage?.length) ?? 0,
            };
          }
          return { language: item, proecifience: 1, order: 0 };
        });
      }
      registerAutoComplete(value, autoCompleteType.LANGUAGE);
      _setCurriculum({ ..._curriculum, languages: newLanguage });
      setItem(newLanguage);
    },
    [_curriculum, setItem],
  );

  const updateLevel = useCallback(
    (proecifience: number | number[], i: number) => {
      if (typeof proecifience === "number") {
        const { languages: newLanguage } = _curriculum;
        newLanguage[i].proecifience = proecifience;
        _setCurriculum({ ..._curriculum, languages: newLanguage });
        setItem(newLanguage);
      }
    },
    [_curriculum, setItem],
  );

  const reorderItem = useCallback(
    (items) => {
      let { languages: newLanguages } = _curriculum;
      newLanguages = items?.map((item: any, i: number) => ({
        ...item,
        order: i,
      }));
      _setCurriculum({ ..._curriculum, languages: newLanguages });
      setItem(newLanguages);
    },
    [_curriculum, setItem],
  );

  const removeSkill = useCallback(
    (i: number) => {
      let { languages: oldLanguage } = _curriculum;
      oldLanguage.splice(i, 1);
      oldLanguage = oldLanguage?.map((item: any, innerIndex: number) => ({
        ...item,
        order: innerIndex,
      }));
      _setCurriculum({ ..._curriculum, languages: oldLanguage });
      setItem(oldLanguage);
    },
    [_curriculum, setItem],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="add-language width-100">
      <InputAutocomplete
        multiple
        label="Idioma"
        helperText="Pressione Enter para adicionar"
        className="language-input"
        options={languagesOptions ?? [""]}
        getOptionLabel={(option) => option}
        value={
          _curriculum?.languages?.length
            ? _curriculum?.languages?.map(
                (item: { language: string }) => item.language,
              )
            : []
        }
        onChange={(values) => onUpdate(values)}
      />

      {_curriculum?.languages?.length > 0 && (
        <div>
          {_curriculum?.languages?.length > 1 && (
            <p className="text-p margin-y-24">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <Draggable items={_curriculum?.languages} reorderItem={reorderItem}>
            {_curriculum?.languages?.length > 0 &&
              _curriculum?.languages?.map(
                (
                  item: {
                    language: string;
                    proecifience: number;
                  },
                  i: number,
                ) => (
                  <DraggableComponent
                    key={Math.random()}
                    draggableId={i.toString()}
                    index={i}
                    isDragDisabled={_curriculum?.languages?.length === 1}
                  >
                    {(innerProvided) => (
                      <div
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                        {...innerProvided.dragHandleProps}
                      >
                        <div>
                          <div className="margin-top-22-mobile flex space-between-responsive padding-bottom-24">
                            <div className="width-30">
                              <div className="pills">{item.language}</div>
                            </div>
                            {width > 990 ? (
                              <div className="width-100">
                                <Slider
                                  value={item.proecifience}
                                  step={1}
                                  min={1}
                                  max={3}
                                  onChange={(_, value) => updateLevel(value, i)}
                                  marks={[
                                    { value: 1, label: "Básico" },
                                    { value: 2, label: "Intermediário" },
                                    { value: 3, label: "Avançado" },
                                  ]}
                                  style={styles.slider}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="flex">
                              <IconButton
                                icon={<Delete />}
                                onClick={() => removeSkill(i)}
                              />
                              {_curriculum?.languages?.length > 1 && (
                                <IconButton
                                  icon={<Move />}
                                  className="margin-left-8"
                                />
                              )}
                            </div>
                          </div>
                          {width < 991 ? (
                            <div className="width-100">
                              <Slider
                                className="width-100-responsive"
                                value={item.proecifience}
                                step={1}
                                min={1}
                                max={3}
                                onChange={(_, value) => updateLevel(value, i)}
                                marks={[
                                  { value: 1, label: "Básico" },
                                  { value: 2, label: "Intermediário" },
                                  { value: 3, label: "Avançado" },
                                ]}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {i < (_curriculum?.languages?.length ?? 0) - 1 && (
                            <div className="divider-card-modal margin-bottom-24" />
                          )}
                        </div>
                      </div>
                    )}
                  </DraggableComponent>
                ),
              )}
          </Draggable>
        </div>
      )}
    </div>
  );
};

export default memo(AddLanguage);
