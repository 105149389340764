import React, { memo } from "react";
import { IconButton } from "../../../components";
import { FolderMin } from "../../../../assets/icons";
import "../style.scss";

interface Props {
  onClick: () => void;
}

const Buttons = ({ onClick }: Props): JSX.Element => (
  <div className="grid-column-8-13 grid-row-1 documents-button">
    <IconButton
      icon={<FolderMin />}
      text="VER DOCUMENTOS"
      className="table-actions"
      onClick={onClick}
    />
  </div>
);

export default memo(Buttons);
