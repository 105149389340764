import React from "react";
import * as ReactDOM from "react-dom";
import moment from "moment";
import { BrowserRouter } from "react-router-dom";
import App from "./app/app";

moment.locale("pt-br");

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root"),
);
