import React, { memo, useMemo } from "react";
import "../style.scss";
import { Button, CardRegister } from "../../../components";

interface Props {
  listOfSubsteps?: string[];
  handleClickStage: () => void;
}

const NextStages = ({
  listOfSubsteps,
  handleClickStage,
}: Props): JSX.Element => {
  const text = useMemo(
    () => (
      <p className="information-title">
        Chegou a hora de começar sua etapa de <b>Oficina</b> que será composta
        pelas seguintes subetapas:
        {listOfSubsteps?.map((item: string) => (
          <ul key={item}>
            <li>{item}</li>
          </ul>
        ))}
      </p>
    ),
    [listOfSubsteps],
  );

  return (
    <>
      <CardRegister
        titleHeader="Sua inscrição"
        className="margin-top-16 margin-mobile-register border-card grid-column-5-12 grid-column-1-11-mobile next-stages"
      >
        <div className="information">
          <p className="information-title">{text}</p>
          <p className="information-title"> E aí, vamos lá?</p>
        </div>
      </CardRegister>

      <Button
        text="Iniciar"
        className="width-100 grid-column-1-11-mobile grid-row-2 grid-column-10-12 margin-bottom-48 button-course"
        appearance="secondary"
        onClick={handleClickStage}
      />
    </>
  );
};
export default memo(NextStages);
