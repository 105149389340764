/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  memo,
  useCallback,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";
import { useHistory } from "react-router-dom";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { Slider } from "@material-ui/core";
import {
  Button,
  Card,
  Draggable,
  IconButton,
  InputAutocomplete,
} from "../../../components";
import { getAutocomplete } from "../../../services/functions";
import { autoCompleteType } from "../../../types/enumerators";
import { registerAutoComplete } from "../../../utils";
import { PlacementVacancy } from "../../../types/interfaces";
import { Delete, Move } from "../../../../assets/customIcons";

interface Props {
  vacancy: PlacementVacancy;
  setVacancy: Dispatch<SetStateAction<PlacementVacancy>>;
  onSave: () => void;
  nextPage: () => void;
}

const styles = {
  slider: {
    width: "200px",
    marginLeft: "40px",
  },
};

const Skills = ({
  vacancy,
  setVacancy,
  onSave,
  nextPage,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [skillsOptions, setSkillOptions] = useState<string[]>();

  const history = useHistory();

  const loadSkills = useCallback(async () => {
    const response = await getAutocomplete(autoCompleteType.SKILL);

    const skillOptions = response?.map((option) => option?.name);

    setSkillOptions(skillOptions);
  }, []);

  useEffect(() => {
    if (!skillsOptions) loadSkills();
  }, [loadSkills, skillsOptions]);

  const onUpdate = useCallback(
    async (value: string[] | string | null) => {
      let { desiredSkills: newDesiredSkills } = vacancy;
      if (value && typeof value !== "string") {
        newDesiredSkills = value?.map((item) => {
          if (newDesiredSkills) {
            const skill = newDesiredSkills?.find(
              (innerItem: { key: string }) => innerItem?.key === item,
            );
            return {
              key: item,
              level: skill?.level || 1,
              order: (skill?.order || newDesiredSkills?.length) ?? 0,
            };
          }
          return { key: item, level: 1, order: 0 };
        });
      }

      registerAutoComplete(value, autoCompleteType.SKILL);
      setVacancy({ ...vacancy, desiredSkills: newDesiredSkills });
    },
    [setVacancy, vacancy],
  );

  const reorderItem = useCallback(
    (items) => {
      let { desiredSkills: newDesiredSkills } = vacancy;
      newDesiredSkills = items.map((item: any, index: number) => ({
        ...item,
        order: index,
      }));
      setVacancy({ ...vacancy, desiredSkills: newDesiredSkills });
    },
    [setVacancy, vacancy],
  );

  const removeSkill = useCallback(
    (index: number) => {
      let { desiredSkills: oldDesiredSkills } = vacancy;
      oldDesiredSkills?.splice(index, 1);
      oldDesiredSkills = oldDesiredSkills?.map((item: any, i: number) => ({
        ...item,
        order: i,
      }));
      setVacancy({ ...vacancy, desiredSkills: oldDesiredSkills });
    },
    [setVacancy, vacancy],
  );

  const updateLevelSkill = useCallback(
    (level: number | number[], index: number) => {
      const { desiredSkills: newDesiredSkills } = vacancy;
      if (typeof level === "number" && newDesiredSkills?.length)
        newDesiredSkills[index].level = level;
      setVacancy({ ...vacancy, desiredSkills: newDesiredSkills });
    },
    [setVacancy, vacancy],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="grid-column-1-11-mobile creat-mobile-margin grid-column-5-12 media-card-vacancies grid-7-column grid-row-2">
      <Card
        className="grid-column-1-8 border-card padding-0 "
        titleHeader={width > 990 ? "Habilidades desejadas" : undefined}
      >
        <InputAutocomplete
          multiple
          label="Habilidade"
          helperText="Pressione Enter para adicionar"
          options={skillsOptions ?? [""]}
          getOptionLabel={(option) => option}
          value={
            vacancy?.desiredSkills?.map((item: { key: string }) => item.key) ||
            []
          }
          onChange={(values) => onUpdate(values)}
          className="add-skils width-100"
        />
      </Card>
      {vacancy?.desiredSkills && vacancy?.desiredSkills?.length > 0 && (
        <div className="grid-column-1-8 grid-row-2">
          {vacancy?.desiredSkills && vacancy?.desiredSkills?.length > 1 && (
            <p className="paragraph-reorder">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <Draggable items={vacancy?.desiredSkills} reorderItem={reorderItem}>
            {vacancy?.desiredSkills?.map(
              (
                item: {
                  key: string;
                  level: number;
                },
                index: number,
              ) => (
                <DraggableComponent
                  key={Math.random()}
                  draggableId={index.toString()}
                  index={index}
                  isDragDisabled={vacancy?.desiredSkills?.length === 1}
                >
                  {(innerProvided) => (
                    <div
                      ref={innerProvided.innerRef}
                      {...innerProvided.draggableProps}
                      {...innerProvided.dragHandleProps}
                    >
                      <Card className="margin-top-16">
                        <div className="flex-row-center space-between-responsive">
                          <div className="min-width-110">
                            <div className="pills pills-mobile">{item.key}</div>
                          </div>
                          {width > 990 && (
                            <div className="width-100">
                              <Slider
                                value={item.level}
                                step={1}
                                min={1}
                                max={3}
                                onChange={(e, value) => {
                                  e.stopPropagation();
                                  updateLevelSkill(value, index);
                                }}
                                marks={[
                                  { value: 1, label: "Básico" },
                                  { value: 2, label: "Intermediário" },
                                  { value: 3, label: "Avançado" },
                                ]}
                                style={styles.slider}
                              />
                            </div>
                          )}
                          <div className="flex">
                            <IconButton
                              icon={
                                <Delete
                                  widthIcon={width < 991 ? 20 : 24}
                                  heightIcon={width < 991 ? 20 : 24}
                                />
                              }
                              onClick={() => removeSkill(index)}
                            />
                            {vacancy?.desiredSkills &&
                              vacancy?.desiredSkills?.length > 1 && (
                                <IconButton
                                  icon={
                                    <Move
                                      widthIcon={width < 991 ? 20 : 24}
                                      heightIcon={width < 991 ? 20 : 24}
                                    />
                                  }
                                  className="margin-left-8"
                                />
                              )}
                          </div>
                        </div>
                        {width < 991 && (
                          <div>
                            <Slider
                              className="slider-mobile margin-left-0-mobile"
                              value={item.level}
                              step={1}
                              min={1}
                              max={3}
                              onChange={(e, value) => {
                                e.stopPropagation();
                                updateLevelSkill(value, index);
                              }}
                              marks={[
                                { value: 1, label: "Básico" },
                                { value: 2, label: "Intermediário" },
                                { value: 3, label: "Avançado" },
                              ]}
                              style={styles.slider}
                            />
                          </div>
                        )}
                      </Card>
                    </div>
                  )}
                </DraggableComponent>
              ),
            )}
          </Draggable>
        </div>
      )}
      <div
        className={`flex grid-column-4-8 grid-column-1-8-mobile gap-16-mobile ${
          !vacancy?.desiredSkills?.length ? "grid-row-2" : "grid-row-3"
        }`}
      >
        <Button
          text="Cancelar"
          className="margin-bottom-32 margin-bottom-0-mobile margin-top-0-mobile width-100"
          onClick={() => history.goBack()}
          outline
          appearance="secondary"
        />
        <Button
          text={!vacancy?._id ? "Avançar" : "Salvar"}
          className="margin-left-32 margin-bottom-32 margin-left-0-mobile width-100"
          onClick={() => (vacancy?._id ? onSave() : nextPage())}
        />
      </div>
    </div>
  );
};

export default memo(Skills);
