/* eslint-disable no-await-in-loop */
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { Button, Modal, Select } from "../../../components";
import { Class } from "../../../types/interfaces";
import { setLoad } from "../../../store/actions/configurationsActions";
import { getUserById, patchUser } from "../../../services/functions";
import { userBootcampsStatus, userRoles } from "../../../types/enumerators";
import { List } from "..";
import "../style.scss";

interface Props {
  open: boolean;
  onclose: () => void;
  list: Class[];
  users?: List[];
  reload: () => void;
  setError: Dispatch<
    SetStateAction<
      | {
          funct: () => void;
          user: {
            name: string;
            email: string;
          }[];
        }
      | undefined
    >
  >;
  setSuccess: Dispatch<SetStateAction<boolean>>;
  setOpenModalStatusAction: Dispatch<SetStateAction<boolean>>;
}

const AddClass = ({
  open,
  onclose,
  list,
  users,
  reload,
  setError,
  setOpenModalStatusAction,
  setSuccess,
}: Props): JSX.Element => {
  const [selected, setSelected] = useState<string>("");
  const dispatch = useDispatch();

  const onSave = useCallback(
    async (errorsList?: List[]) => {
      dispatch(setLoad(true) as unknown as Action);

      const errors: List[] = [];
      const data = errorsList ?? users ?? [];

      for (let i = 0; i < data?.length; i += 1) {
        const response = await getUserById(data[i]?.userId);

        if (!response) return;

        if (!response?.bootcamps) response.bootcamps = [];
        if (!response?.account?.roles) response.account.roles = [];

        if (
          !response?.bootcamps?.find(
            (item: { classId?: string }) => item?.classId === selected,
          )
        )
          response.bootcamps.push({
            classId: selected ?? "",
            status: userBootcampsStatus.IN_PROCESS,
            order: response?.bootcamps?.length ?? 0,
          });

        if (
          !response?.account?.roles?.find(
            (item: string) => item === userRoles.STUDENT,
          )
        )
          response.account.roles.push(userRoles.STUDENT);
        const account = { ...response?.account };
        const bootcamps = [...(response?.bootcamps ?? [])];
        const result = await patchUser(response?._id ?? "", {
          account,
          bootcamps,
        });

        if (!result) errors.push(response as unknown as List);
      }

      if (errors?.length) {
        setError({
          funct: () => {
            onSave(errors);
            setOpenModalStatusAction(false);
          },
          user: errors?.map((item) => ({
            name: item?.profile?.name,
            email: item?.profile?.email,
          })),
        });

        if (errors?.length < data?.length) setSuccess(true);
        else setSuccess(false);

        setOpenModalStatusAction(true);
      } else toast.success("Ação executada com sucesso");

      setSelected("");
      reload();
      onclose();
      dispatch(setLoad(false) as unknown as Action);
    },
    [
      dispatch,
      users,
      reload,
      onclose,
      selected,
      setError,
      setSuccess,
      setOpenModalStatusAction,
    ],
  );

  return (
    <Modal
      headerAction
      open={open}
      onClose={onclose}
      textHeader="Atribuir turma"
    >
      <Select
        id="classes"
        label="Turma"
        value={selected ?? ""}
        onChange={(e) => setSelected(e.target.value)}
        options={list?.map((item) => ({
          value: item?._id ?? "",
          label: item?.code ?? "",
        }))}
      />
      <div className="admin-subscriptions-modal-buttons margin-top-32">
        <Button
          text="Cancelar"
          onClick={onclose}
          outline
          appearance="secondary"
        />
        <Button onClick={() => onSave()} text="Salvar" />
      </div>
    </Modal>
  );
};
export default memo(AddClass);
