import React, { memo, useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { IconButton } from "..";
import { ChevronUp } from "../../../assets/customIcons";

interface SubMenuItem {
  title: string;
  onClick: (e: { stopPropagation: () => void }) => void;
  active: boolean;
}
interface Props {
  id?: string;
  selectedMenuTitle?: string;
  subMenu?: SubMenuItem[];
}

const StepVagasMobile = ({
  id,
  selectedMenuTitle,
  subMenu,
}: Props): JSX.Element => {
  const history = useHistory();

  const itemsMenu = useMemo(
    () => [
      {
        text: "Mensagens",
        url: `/company-vacancy-messages/${id}`,
      },
      {
        text: "Propostas",
        url: `/company-vacancy-proposals/${id}`,
      },
      {
        text: "Comentários internos",
        url: `/company-vacancy-comments/${id}`,
      },
    ],
    [id],
  );

  return (
    <div>
      <Accordion className="steps-account-mobile margin-16">
        <AccordionSummary
          expandIcon={<ChevronUp widthIcon={24} heightIcon={24} />}
          className={`expanded-icon ${subMenu && "margin-bottom-accordion"}`}
        >
          <div className="margin-left-12-mobile">
            <div className="flex-row-center width-100">
              <p className="p-header-mobile margin-left-20">
                {selectedMenuTitle}
              </p>
            </div>

            {subMenu?.length && (
              <div className="card-title-div border-bottom-none margin-bottom-16-mobile">
                {subMenu.map((item) => (
                  <button
                    type="button"
                    className={`margin-left-24  active-mobile ${
                      item?.active && "active"
                    }`}
                    onClick={item?.onClick}
                  >
                    {item?.title}
                  </button>
                ))}
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="grid-column-1-11 width-100 grid-row-2 grid-column-4-12 ">
            <div className="border-solid-account" />
            <div className=" icon-step-account">
              {itemsMenu?.map((item) => (
                <IconButton
                  text={item?.text}
                  onClick={() => history.push(item?.url)}
                  className="steps-account steps-account-disabled"
                />
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(StepVagasMobile);
