import React, { memo, MouseEventHandler, ReactNode } from "react";
import { Header, HeaderCourse } from "..";
import "../../../styles/index.scss";
import "./styles.scss";

interface Props {
  children: ReactNode;
  header?: boolean;
  headerCourse?: boolean;
  back?: boolean;
  exit?: boolean;
  onClickBack?: MouseEventHandler<SVGElement>;
  className?: string;
}

const Layout = ({
  children,
  header,
  headerCourse,
  back,
  exit,
  onClickBack,
  className = "",
}: Props): JSX.Element => (
  <div className={`div-container-layout ${className}`}>
    {header && <Header back={back} onClickBack={onClickBack} />}
    {headerCourse && <HeaderCourse exit={exit} />}
    {children}
  </div>
);

export default memo(Layout);
