import React, { memo } from "react";
import { Modal } from "../../../components";
import {
  ClassVacancy,
  ClassVacancySubscription,
} from "../../../types/interfaces";
import { CourseStatus } from "../../../types/constants/Course";
import "../style.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  subscriptions?: ClassVacancySubscription[];
  classVacancies?: ClassVacancy[];
  legalIdentity?: string;
  userName?: string;
}

const ViewClassVacancy = ({
  open,
  onClose,
  subscriptions,
  classVacancies,
  legalIdentity,
  userName,
}: Props): JSX.Element => (
  <Modal
    headerAction
    open={open}
    onClose={onClose}
    textHeader={`${userName} - Processos seletivos`}
  >
    <div className="admin-recap-process-modal">
      <div className="display-block">
        {subscriptions && subscriptions?.length > 0 ? (
          subscriptions?.map((item) => (
            <div key={Math.random()}>
              <div className="admin-recap-process with-after margin-bottom-16">
                Processo:{" "}
                {classVacancies?.find((i) => i?._id === item?.classVacancyId)
                  ?.title ?? "-"}
              </div>
              <div className="admin-recap-text">Status</div>
              <div
                className={`admin-recap-status admin-recap-status-${item?.status}`}
              >
                <div
                  className={`admin-recap-component-status admin-recap-component-status-${item?.status}`}
                >
                  {CourseStatus[item?.status as keyof typeof CourseStatus]}
                </div>
                {legalIdentity && (
                  <a
                    href={`http://dashboard.reprograma.com.br/dashboards/7-pesquisa-por-cpf?p_cpf=${legalIdentity}`}
                    target="_blank"
                    className="admin-recap-ver-detalhes"
                    rel="noreferrer"
                  >
                    Ver detalhes
                  </a>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="admin-recap-no-result">Sem processos</div>
        )}
      </div>
    </div>
  </Modal>
);

export default memo(ViewClassVacancy);
