/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  EMPLOYMENT_CREATE,
  EMPLOYMENT_CLEAR,
  EMPLOYMENT_SET,
  EMPLOYMENT_UPDATE,
} from "../types/employmentTypes";

const initialState: any = {
  hasFinished: false,
  form: {},
};

export const employmentReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
): any => {
  switch (type) {
    case EMPLOYMENT_CLEAR:
      return { ...initialState };
    case EMPLOYMENT_CREATE:
      return { ...state, ...payload };
    case EMPLOYMENT_SET:
      return { ...state, ...payload };
    case EMPLOYMENT_UPDATE:
      return { ...state, ...payload };
    default:
      return state;
  }
};
