/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { HeaderProfile, Layout } from "../../components";
import {
  getClassVacancyById,
  getClassVacancySubscriptionWithCommentsByClassVacancy,
} from "../../services/functions";
import CardExpandMessages from "./CardExpandMessages";
import CardMessages from "./CardMessages";
import "./style.scss";
import type {
  ClassVacancyStages,
  ClassVacancySubscription,
} from "../../types/interfaces";
import { setLoad } from "../../store/actions/configurationsActions";

interface LocationProps {
  classVacancyId: string;
  classVacancySubscriptionId: string;
}

interface ClassVacancyProps {
  _id: string;
  title: string;
  stages: ClassVacancyStages[];
}

export interface DataProps extends ClassVacancySubscription {
  user: {
    city: string;
    name: string;
    race: string;
    state: string;
    photo: string;
    genderIdentity: string;
  };
}

const AdminClassComments = (): JSX.Element => {
  const location = useLocation<LocationProps>();

  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = useState<DataProps[] | null>(null);
  const [idSelected, setIdSelected] = useState("");
  const [menuActive, setMenuActive] = useState("GENERAL");
  const [classVacancy, setClassVacancy] = useState<ClassVacancyProps | null>(
    null,
  );
  const classVacancyId = location?.state?.classVacancyId;
  const classVacancySubscriptionId =
    location?.state?.classVacancySubscriptionId;

  const getDataWithActiveMenu = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const getComments =
      await getClassVacancySubscriptionWithCommentsByClassVacancy(
        classVacancyId,
      );

    const getClassVacancy = await getClassVacancyById(classVacancyId);

    const filterSubscriptionSelected = getComments.data?.filter(
      ({ _id }: any) => _id === classVacancySubscriptionId,
    ) as DataProps[];

    const findData = getComments.data?.find(
      (item: { _id: string }) =>
        item?._id === filterSubscriptionSelected[0]?._id,
    );

    const findElement =
      findData?.miscellaneous?.generalComment?.slice(-1) || [];

    if (findElement.length > 0)
      setMenuActive(findElement[0]?.element as string);

    setData(getComments.data);

    setClassVacancy(getClassVacancy.data);

    setIdSelected(filterSubscriptionSelected[0]?._id || "");

    dispatch(setLoad(false) as unknown as Action);
  }, [classVacancyId, classVacancySubscriptionId, dispatch]);

  const getData = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const getComments =
      await getClassVacancySubscriptionWithCommentsByClassVacancy(
        classVacancyId,
      );

    const getClassVacancy = await getClassVacancyById(classVacancyId);

    setData(getComments.data);

    setClassVacancy(getClassVacancy.data);

    dispatch(setLoad(false) as unknown as Action);
  }, [classVacancyId, dispatch]);

  const handleClick = useCallback(
    (id: string) => {
      if (id === idSelected) setIdSelected("");
      else setIdSelected(id);
    },
    [idSelected],
  );

  useEffect(() => {
    if (classVacancyId) getDataWithActiveMenu();
    else history.push("admin-class-vacancy");
  }, [getDataWithActiveMenu, history, classVacancyId]);

  return (
    <Layout className="admin-comments">
      <HeaderProfile />
      <div className="grid-column-1-11 grid-row-1 align-center">
        <div className="width-100">
          <div className="flex-row-center">
            <p className="view-page">Processo &gt;&nbsp;</p>
            <p className="view-page">{classVacancy?.title}&nbsp;&gt;&nbsp;</p>
            <p className="view-step">Comentários internos</p>
          </div>
        </div>
      </div>
      <div
        className={`
                    grid-column-1-13 grid-row-2
                 ${
                   idSelected
                     ? "card-candidature card-candidature-100"
                     : "card-candidature"
                 }
                `}
      >
        <div className="card-title-comments admin-comments-messages">
          <h2 className="card-title margin-bottom-4">Mensagens</h2>

          <div className="admin-comments-buttons">
            <button
              type="button"
              className={menuActive === "GENERAL" ? "is-active" : ""}
              onClick={() => {
                setMenuActive("GENERAL");
              }}
            >
              Gerais
            </button>

            {classVacancy?.stages.map(({ title, type }) => (
              <button
                key={Math.random()}
                type="button"
                className={menuActive === type ? "is-active" : ""}
                onClick={() => setMenuActive(type ?? "")}
              >
                {title}
              </button>
            ))}
          </div>
        </div>
        <div className="flex display-block">
          <div className="card-candidature_scroll card-scroll-comment">
            {data && data?.length ? (
              data?.map((item) => (
                <div
                  key={Math.random()}
                  role="none"
                  onClick={() => handleClick(item._id || "")}
                  className="div-border-bottom pointer"
                >
                  <CardExpandMessages idSelected={idSelected} item={item} />
                </div>
              ))
            ) : (
              <p className="margin-left-24">Não há mensagens</p>
            )}
          </div>
          {idSelected ? (
            <CardMessages
              data={data?.find((item) => item._id === idSelected) as DataProps}
              fetch={getData}
              selected={menuActive}
              classVacancyId={classVacancyId}
            />
          ) : (
            <div className="display-none" />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default memo(AdminClassComments);
