/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Add } from "../../../../assets/icons";
import { IconButton, ViewCertificate } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { RootState } from "../../../store/reducers";
import { updateUser } from "../../../store/actions/userActions";
import { ConfirmRemove, Items } from "../../Profile/Modal";
import { addCompanyToUser } from "../../../services/functions";

const EditCertificate = (): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [removed, setRemoved] = useState({
    title: "",
    text: "",
    onRemove: () => setOpenModalRemove(false),
  });
  const [indexItem, setIndexItem] = useState(-1);
  const user = useSelector((state: RootState) => state.userState);
  const [_user, _setUser] = useState(user);
  const dispatch = useDispatch();

  const setCompanyToUser = useCallback(async () => {
    const response = await addCompanyToUser(user);
    _setUser({ ...response });
  }, [user]);

  useEffect(() => {
    if (user?._id && !_user?._id) setCompanyToUser();
  }, [_user, setCompanyToUser, user]);

  useEffect(() => {
    dispatch(
      setNextButton(() =>
        dispatch(
          updateUser({
            ..._user,
            curriculum: { ..._user?.curriculum },
          }) as unknown as Action,
        ),
      ) as unknown as Action,
    );
  }, [_user, _user?.curriculum, dispatch]);

  const reorderItem = useCallback(
    (items: any) => {
      const { curriculum } = _user;
      if (curriculum) {
        let { certificates: newCertificates } = curriculum;
        newCertificates = items?.map((item: any, index: number) => ({
          ...item,
          order: index,
        }));
        _setUser({
          ..._user,
          curriculum: { ...curriculum, certificates: newCertificates },
        });
        dispatch(
          setNextButton(() =>
            dispatch(
              updateUser({
                ..._user,
                curriculum: {
                  ...curriculum,
                  certificates: newCertificates,
                },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      }
    },
    [_user, dispatch],
  );

  const onRemoveItem = useCallback(
    (index: number) => {
      let newItems = _user?.curriculum?.certificates;
      newItems?.splice(index, 1);
      newItems = newItems?.map((innerItem: any, i: number) => ({
        ...innerItem,
        order: i,
      }));

      _setUser({
        ..._user,
        curriculum: { ..._user?.curriculum, certificates: newItems },
      });

      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ..._user,
              curriculum: { ..._user?.curriculum, certificates: newItems },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_user, dispatch],
  );

  const onEditItem = useCallback((index: number) => {
    setIndexItem(index);
    setOpenModal(true);
  }, []);

  const onCloseItems = useCallback(() => {
    setOpenModal(false);
    setIndexItem(-1);
  }, []);

  return (
    <div>
      <IconButton
        icon={<Add />}
        text="Adicionar certificado"
        onClick={() => setOpenModal(true)}
        className={`icon-button-primary width-100-mobile ${
          _user?.curriculum?.certificates?.length > 0 ? "margin-bottom-40" : ""
        } `}
      />
      {_user?.curriculum?.certificates?.length > 0 ? (
        <div>
          {_user?.curriculum?.certificates?.length > 1 && (
            <p className="paragraph-reorder">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <ViewCertificate
            hasIcon
            isMyProfile
            items={_user?.curriculum?.certificates}
            onEdit={(_, index) => onEditItem(index)}
            onMove={(items) => reorderItem(items)}
            onRemove={(item, index) => {
              setRemoved({
                title: "Excluir certificado",
                text: `Tem certeza de que deseja excluir este certificado da ${item?.name}?`,
                onRemove: () => onRemoveItem(index),
              });
              setOpenModalRemove(true);
            }}
          />
        </div>
      ) : (
        <p className="paragraph-not-registered">
          Não há certificados cadastrados
        </p>
      )}
      <Items
        setUser={_setUser}
        index={indexItem}
        type="certificates"
        openModal={openModal}
        onClose={onCloseItems}
      />
      <ConfirmRemove
        titleHeader={removed?.title}
        text={removed?.text}
        onClose={() => setOpenModalRemove(false)}
        onRemove={removed?.onRemove}
        openModal={openModalRemove}
      />
    </div>
  );
};

export default memo(EditCertificate);
