/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  LegacyRef,
  createRef,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import { UserCurriculumLanguage } from "../../../types/interfaces";

const ViewLanguage = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const [minWidth, setMinWidth] = useState(0);

  const refs: { current: { offsetWidth: number | null } | null }[] = useMemo(
    () =>
      Array.from({ length: user?.curriculum?.languages?.length }).map(() =>
        createRef(),
      ),
    [user?.curriculum?.languages?.length],
  );

  useEffect(() => {
    if (user?.curriculum?.languages?.length === refs?.length) {
      let size = 0;
      let index = -1;
      refs?.forEach((item: any, i: number) => {
        if (item?.current?.offsetWidth > size) {
          size = item?.current?.offsetWidth;
          index = i;
        }
      });
      setMinWidth(refs[index]?.current?.offsetWidth ?? 0);
    }
  }, [user?.curriculum?.languages, user?.curriculum?.languages?.length, refs]);

  useEffect(() => {
    if (user?.curriculum?.languages?.length) {
      user?.curriculum?.languages?.sort(
        (a: UserCurriculumLanguage, b: UserCurriculumLanguage) => {
          if ("order" in a && "order" in b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          }
          return 0;
        },
      );
    }
  }, [user?.curriculum?.languages]);

  return (
    <div>
      {user?.curriculum?.languages?.length > 0 ? (
        user?.curriculum?.languages?.map((item: any, index: number) => (
          <div key={Math.random()}>
            <div className="cards-items-profile flex-row-center">
              <div
                ref={refs[index] as LegacyRef<HTMLDivElement> | undefined}
                className="margin-0"
                style={{ minWidth }}
              >
                <p className="title-bold margin-bottom-6">{item?.language}</p>
                <p className="level-view">
                  {item?.proecifience === 1
                    ? "Básico"
                    : item?.proecifience === 2
                    ? "Intermediário"
                    : "Avançado"}
                </p>
              </div>

              <div className="flex-row-center">
                <div className="knob" />
                <div
                  className={`knob ${item?.proecifience < 2 ? "empty" : ""} `}
                />
                <div
                  className={`knob ${item?.proecifience < 3 ? "empty" : ""}`}
                />
              </div>
            </div>
            {index < (user?.curriculum?.languages?.length ?? 0) - 1 && (
              <div className="divider-card divider-card-language" />
            )}
          </div>
        ))
      ) : (
        <p className="paragraph-not-registered">Não há idiomas cadastrados</p>
      )}
    </div>
  );
};

export default memo(ViewLanguage);
