/* eslint-disable @typescript-eslint/no-explicit-any */
import moment, { MomentInput } from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { Draggable, IconButton, Medias } from "..";
import { Link as LinkIcon, Project } from "../../../assets/icons";
import "../../../styles/index.scss";
import {
  CompanyAvatarEmpty,
  Edit,
  Delete,
  Move,
} from "../../../assets/customIcons";
import { mediaType } from "../../types/enumerators";
import "./styles.scss";

interface Props {
  items: any;
  isMyProfile?: boolean;
  hasIcon?: boolean;
  onMove?: (items: any) => void;
  onEdit?: (item: any, index: number) => void;
  onRemove?: (item: any, index: number) => void;
  className?: string;
  title?: string;
  subTitle?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  showDivider?: boolean;
  fullDate?: boolean;
  notSort?: boolean;
}

const CardItems = ({
  items,
  isMyProfile,
  hasIcon,
  onMove,
  onEdit,
  onRemove,
  className,
  title,
  subTitle,
  description,
  startDate,
  endDate,
  fullDate,
  notSort,
  showDivider = true,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  const orderItems = useCallback(() => {
    items?.sort(
      (
        a: {
          order: number;
          startDate: MomentInput;
        },
        b: {
          order: number;
          startDate: MomentInput;
        },
      ) => {
        if ("order" in a && "order" in b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        }
        if (a.startDate && b.startDate) {
          if (moment(a.startDate).isAfter(b.startDate)) return -1;
          if (moment(a.startDate).isBefore(b.startDate)) return 1;
          return 0;
        }
        return 0;
      },
    );
  }, [items]);

  useEffect(() => {
    if (items?.length && !notSort) orderItems();
  });

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Draggable items={items} reorderItem={onMove}>
      <div className={showDivider ? "card-items-component-divider" : ""} />
      {items?.map((item: any, index: number) => (
        <DraggableComponent
          key={Math.random()}
          draggableId={Math.random().toString()}
          index={index}
          isDragDisabled={!onMove || items?.length === 1 || !isMyProfile}
        >
          {(innerProvided) => (
            <>
              <div
                ref={innerProvided.innerRef}
                {...innerProvided.draggableProps}
                {...innerProvided.dragHandleProps}
                className="margin-y-16 display-inherit"
              >
                <div
                  className={`card-component height-fit-content ${
                    className || ""
                  }`}
                >
                  <div className="align-items-start flex">
                    {hasIcon && (
                      <div
                        className={`card-items-component-items ${
                          item?.type ? "-margin-right-8" : "margin-right-20"
                        }`}
                      >
                        {item?.type ? (
                          item?.type === mediaType.LINK ? (
                            <LinkIcon />
                          ) : (
                            <Project />
                          )
                        ) : item?.logo ? (
                          <img
                            src={item?.logo}
                            alt="imagem"
                            width={48}
                            height={48}
                            className="rounded-full"
                          />
                        ) : (
                          <CompanyAvatarEmpty widthIcon={48} heightIcon={48} />
                        )}
                      </div>
                    )}
                    <div className="card-items-component-cards">
                      <div className="card-items-component-card">
                        <p className="card-items-component-title">
                          {item?.[title ?? ""] ?? item?.title}
                        </p>
                        {isMyProfile && (
                          <div className="flex align-items margin-right-8">
                            {onEdit && (
                              <IconButton
                                icon={
                                  <Edit
                                    widthIcon={width < 991 ? 20 : 24}
                                    heightIcon={width < 991 ? 20 : 24}
                                  />
                                }
                                onClick={() => onEdit(item, index)}
                                className="margin-right-8 cursor-pointer"
                              />
                            )}
                            {onRemove && (
                              <IconButton
                                icon={
                                  <Delete
                                    widthIcon={width < 991 ? 20 : 24}
                                    heightIcon={width < 991 ? 20 : 24}
                                  />
                                }
                                className="cursor-pointer"
                                onClick={() => onRemove(item, index)}
                              />
                            )}
                            {onMove && items?.length > 1 && (
                              <IconButton
                                className="margin-left-8 cursor-pointer"
                                icon={
                                  <Move
                                    widthIcon={width < 991 ? 20 : 24}
                                    heightIcon={width < 991 ? 20 : 24}
                                  />
                                }
                              />
                            )}
                          </div>
                        )}
                      </div>
                      <p
                        onClick={
                          subTitle === "url"
                            ? () =>
                                window.open(
                                  item?.[subTitle].includes("http")
                                    ? item?.[subTitle]
                                    : `http://${item?.[subTitle]}`,
                                )
                            : undefined
                        }
                        className={`card-items-component-subtitle ${
                          subTitle === "url" ? "cursor-pointer" : ""
                        }`}
                      >
                        {item?.[subTitle ?? "subTitle"] ?? item?.subTitle}
                      </p>
                      {item?.type !== mediaType.LINK &&
                        item?.[startDate ?? "startDate"] && (
                          <p className="card-items-component-date">
                            {`${moment(item?.[startDate ?? "startDate"]).format(
                              fullDate ? "DD/MM/YYYY " : "MMMM/YYYY",
                            )} - ${
                              !item?.[endDate ?? "endDate"]
                                ? "Atualmente"
                                : moment(item?.[endDate ?? "endDate"]).format(
                                    fullDate ? "DD/MM/YYYY" : "MMMM/YYYY",
                                  )
                            }`}
                          </p>
                        )}
                      <p className="card-items-component-card-description pre-wrap">
                        {item?.[description ?? "description"]}
                      </p>
                      {item?.medias?.length > 0 && (
                        <Medias
                          medias={item?.medias.filter(
                            (innerItem: { type: string }) =>
                              innerItem.type !== mediaType.LINK,
                          )}
                          links={item?.medias.filter(
                            (innerItem: { type: string }) =>
                              innerItem.type === mediaType.LINK,
                          )}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {index < (items?.length ?? 0) - 1 && (
                <div className="card-items-component-divider margin-y-24" />
              )}
            </>
          )}
        </DraggableComponent>
      ))}
    </Draggable>
  );
};

export default memo(CardItems);
