import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const FileProfileMin = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#2E3451"
      d="M19.937 8.68c-.011-.032-.02-.063-.033-.094-.049-.106-.11-.207-.196-.293l-6-6c-.086-.086-.187-.147-.293-.196-.03-.014-.062-.022-.094-.033-.084-.028-.17-.046-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062-.005-.088-.022-.174-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5c0 .553.447 1 1 1h5l.002 10H6z"
    />
  </svg>
);

export default FileProfileMin;
