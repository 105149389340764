/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useMemo, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  TableRow,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { toast } from "react-toastify";
import {
  HeaderProfile,
  Layout,
  Card,
  Table,
  ModalComments,
  Pagination,
} from "../../components";
import { Search } from "../../../assets/icons";
import { setLoad } from "../../store/actions/configurationsActions";
import {
  Remove,
  TransferClass,
  View,
  TransferClassVacancy,
  ViewClassVacancy,
  ViewClass,
} from "./Modal";
import {
  filterClass,
  filterRecapUser,
  patchUser,
  updateClassVacancySubscriptionById,
  getAllActiveClassVacancy,
  createClassVacancySubscription,
  getPages,
  getClassVacancySubscriptionByUserIdAndClassId,
  getClassVacancyByClassId,
} from "../../services/functions";
import { ReasonsGeneral, ReasonsRemove } from "../../types/constants/User";
import {
  classStructure,
  userBootcampsStatus,
  classStatus,
  classVacancySubscriptionStatus,
} from "../../types/enumerators";
import {
  GeneralStatus,
  RemoveStatus,
} from "../../types/enumerators/GeneralStatus";
import {
  Class,
  ClassVacancy,
  ClassVacancySubscription,
  GeneralComment,
  UserBootcamps,
  UserProfile,
} from "../../types/interfaces";
import { RootState } from "../../store/reducers";
import "./style.scss";
import { maskBRlegalIdentity } from "../../utils";

interface Filters {
  name?: string | null;
  legalIdentity?: string | null;
  email?: string | null;
  classCode?: string | null;
  isGiveUp?: boolean | null;
  isWaitingList?: boolean | null;
}

interface Data {
  id?: string;
  isGiveUp?: boolean;
  name?: string;
  legalIdentity?: string;
  email?: string;
  profile?: UserProfile;
  bootcamps?: UserBootcamps[];
  oldBootcamps?: Class[];
  oldClassVacancy?: ClassVacancy[];
  oldClassVacancySubscription?: ClassVacancySubscription[];
  guBootcamps?: Class;
  classVacancy?: ClassVacancy;
  status?: string;
  miscellaneous?: {
    generalComment?: GeneralComment[];
  };
  form?: { [x: string]: string | boolean | number };
}

const AdminRecap = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [totalFilteredList, setTotalFilteredList] = useState(0);

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const [data, setData] = useState<Data[]>();
  const [filters, setFilters] = useState<Filters>();
  const [classVacancies, setClassVacancies] = useState<
    {
      _id: string;
      title: string;
      stages: { type: string; title: string }[];
    }[]
  >();
  const [classList, setClassList] =
    useState<{ _id?: string; code?: string }[]>();

  const [listSelectedTable, setListSelectedTable] =
    useState<{ _id: string }[]>();
  const [selectedUser, setSelectedUser] = useState<Data>();

  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [openModalView, setOpenModalView] = useState(false);
  const [openModalComments, setOpenModalComments] = useState(false);
  const [openModalViewClass, setOpenModalViewClass] = useState(false);
  const [openModalViewClassVacancy, setOpenModalViewClassVacancy] =
    useState(false);
  const [openModalTransferClass, setOpenModalTransferClass] = useState(false);
  const [openModalTransferClassVacancy, setOpenModalTransferClassVacancy] =
    useState(false);

  const user = useSelector((state: RootState) => state.userState);
  const dispatch = useDispatch();

  const headCells = useMemo(
    () => [
      {
        id: "name",
        label: "Nome",
        orderBy: false,
        asc: true,
      },
      {
        id: "legalIdentity",
        label: "CPF",
        orderBy: false,
        asc: false,
      },
      {
        id: "email",
        label: "E-mail",
        orderBy: false,
        asc: false,
      },
      {
        id: "class",
        label: "Turma / Processo",
        orderBy: false,
        asc: true,
      },
      {
        id: "othersClass",
        label: "Turmas Anteriores",
        orderBy: false,
        asc: true,
      },
      {
        id: "othersClassVacancy",
        label: "Processos anteriores",
        orderBy: false,
        asc: true,
      },
      {
        id: "statusReasons",
        label: "Motivo",
        orderBy: false,
        asc: true,
      },
      {
        id: "tags",
        label: "Tags",
        orderBy: false,
        asc: false,
        classColumn: "w-160",
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
        asc: false,
        classColumn: "w-160",
      },
    ],
    [],
  );

  const getData = useCallback(
    async (f?: Filters) => {
      try {
        dispatch(setLoad(true) as unknown as Action);

        const currentFilters = f ?? filters;

        if (pageLimit) {
          setMaxPage(await getPages(filterRecapUser, currentFilters, true));

          const numTotalList = (await filterRecapUser(
            currentFilters,
            true,
          )) as { size: 0 }[];

          if (numTotalList?.length)
            setTotalFilteredList(numTotalList[0]?.size ?? 0);
          else setTotalFilteredList(0);

          const response = await filterRecapUser(
            currentFilters,
            false,
            page,
            pageLimit,
            "guBootcamps.code",
          );

          const updatedData = response?.map((item) => {
            const givUp = item?.bootcamps?.find(
              (bootcamp) => bootcamp?.status === GeneralStatus.GIVE_UP,
            );

            const isGiveUp =
              item?.status !== classVacancySubscriptionStatus.WAITING_LIST;

            return {
              ...item,
              isGiveUp,
              legalIdentity: maskBRlegalIdentity(item?.legalIdentity ?? ""),
              class: !isGiveUp
                ? item?.classVacancy?.title
                : item?.guBootcamps?.code,
              othersClass:
                item?.oldBootcamps
                  ?.map((innerItem) => innerItem?.code)
                  ?.join(", ") ?? "",
              othersClassVacancy:
                item?.oldClassVacancy
                  ?.map((innerItem) => innerItem?.title)
                  ?.join(", ") ?? "",
              statusReasons: !isGiveUp
                ? "Lista de espera"
                : givUp
                ? givUp?.statusReasons === RemoveStatus.OTHERS
                  ? givUp?.statusReasonsDescription
                  : ReasonsGeneral[
                      givUp?.statusReasons as keyof typeof ReasonsGeneral
                    ]
                : "",
              tags: givUp ? givUp?.tags : [],
            };
          });
          setData(updatedData);
        }
      } catch {
        toast.error("Ops! Houve um erro ao carregar os dados.");
      } finally {
        dispatch(setLoad(false) as unknown as Action);
      }
    },
    [dispatch, filters, page, pageLimit],
  );

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageLimit]);

  const onCancel = useCallback(() => {
    setListSelectedTable(undefined);
    setOpenModalTransferClass(false);
    setOpenModalRemove(false);
    setOpenModalView(false);
    setOpenModalTransferClassVacancy(false);
    setOpenModalViewClassVacancy(false);
    setOpenModalComments(false);
    setOpenModalViewClass(false);
  }, []);

  const handleSaveTransferClass = useCallback(
    async (row, classSelected) => {
      dispatch(setLoad(true) as unknown as Action);
      const usr = { ...row };

      let idx =
        usr?.bootcamps?.findIndex(
          (item: { classId: string }) =>
            item?.classId === usr?.guBootcamps?._id,
        ) ?? -1;

      if (idx > -1)
        usr.bootcamps[idx].recap = {
          disabled: true,
          reasons: ReasonsRemove.CHANGED,
        };

      const newBootcamp = {
        classId: classSelected,
        status: userBootcampsStatus.IN_PROCESS,
      };
      const bootcamps = [...(usr?.bootcamps ?? [])];

      bootcamps?.push(newBootcamp);

      await patchUser(usr?.id ?? "", { bootcamps });

      if (idx === -1) idx = (usr?.bootcamps?.length ?? 0) - 1;
      const subscription = await getClassVacancySubscriptionByUserIdAndClassId(
        row?._id,
        row?.bootcamps?.[idx]?.classId,
      );
      const classVacancy = await getClassVacancyByClassId(classSelected);

      if (subscription && subscription?.length) {
        const subs = subscription[0];
        await updateClassVacancySubscriptionById(subs?._id, {
          ...subs,
          ticket: "BOOTCAMPS_CHANGED",
          classVacancyId: classVacancy?._id,
          oldClassVacancyId: subs?.classVacancyId,
        });
      }

      onCancel();
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, onCancel],
  );

  const onAllTransferClass = useCallback(
    async (listSelected, classSelected) => {
      for (let index = 0; index < listSelected?.length; index += 1)
        handleSaveTransferClass(listSelected[index], classSelected);

      setListSelectedTable(undefined);
    },
    [handleSaveTransferClass],
  );

  const getClasses = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    try {
      const response = await filterClass({
        status: classStatus.ACTIVE,
        classStructure: [
          classStructure.FOUR_WEEKS,
          classStructure.SIX_WEEKS,
          classStructure.EIGHT_WEEKS,
          classStructure.TWELVE_WEEKS,
          classStructure.FOURTEEN_WEEKS,
          classStructure.EIGHTEEN_WEEKS,
        ],
      });
      const allClasses = response?.data;

      const activeClasses = allClasses
        ?.filter(
          (item: { status: string }) => item.status === classStatus.ACTIVE,
        )
        ?.map((key: { _id: string; code: string }) => ({
          _id: key?._id,
          code: key?.code,
        }));

      setClassList(activeClasses);
      dispatch(setLoad(false) as unknown as Action);
    } catch {
      toast.error("Ops! Houve um erro ao buscar as turmas disponíveis.");
    } finally {
      dispatch(setLoad(false) as unknown as Action);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!classList && openModalTransferClass) getClasses();
  }, [classList, getClasses, openModalTransferClass]);

  const submitCommentSubscription = useCallback(
    async (updatedData) => {
      const index =
        selectedUser?.oldClassVacancySubscription?.findIndex(
          (item) => item?.userId === selectedUser?.id,
        ) ?? -1;

      if (index > -1) {
        const subscriptionId =
          selectedUser?.oldClassVacancySubscription?.[index]._id ?? "";

        const generalComment = updatedData?.map((comment: any) => {
          if (comment?.type === "RECAP")
            return {
              userId: comment?.userId,
              text: comment?.text,
              date: comment?.date,
              element: "RECAP",
              resolved: comment?.resolved,
            };

          return { ...comment };
        });

        const miscellaneous = {
          ...selectedUser?.miscellaneous,
          generalComment,
        };

        await updateClassVacancySubscriptionById(subscriptionId, {
          miscellaneous,
        });
      }
    },
    [
      selectedUser?.id,
      selectedUser?.miscellaneous,
      selectedUser?.oldClassVacancySubscription,
    ],
  );

  const submitCommentUser = useCallback(
    async (updatedData) => {
      const classId = selectedUser?.guBootcamps?._id ?? "";

      const bootcamps = selectedUser?.bootcamps as UserBootcamps[];

      const index = bootcamps?.findIndex((item) => item?.classId === classId);

      if (index !== -1) {
        const updatedComments = updatedData?.map((comment: any) => ({
          ...comment,
          type: "RECAP",
        }));

        bootcamps[index].comments = updatedComments;

        await patchUser(selectedUser?.id ?? "", {
          bootcamps,
        });
      }
    },
    [selectedUser?.bootcamps, selectedUser?.guBootcamps?._id, selectedUser?.id],
  );

  const handleSaveRemove = useCallback(
    async (row, reasons: string, reasonsDescription: any) => {
      dispatch(setLoad(true) as unknown as Action);
      try {
        const recap = {
          disabled: true,
          reasons,
          reasonsDescription,
        };

        if (!row?.isGiveUp) {
          const index = row?.oldClassVacancySubscription?.findIndex(
            (item: { userId: string }) => item?.userId === row?.id,
          );

          const subscriptionId =
            row?.oldClassVacancySubscription[index]?._id ?? "";

          await updateClassVacancySubscriptionById(subscriptionId, {
            recap,
          });
        } else {
          const classId = row?.guBootcamps?._id ?? "";

          const bootcamps = row?.bootcamps as UserBootcamps[];

          const index = bootcamps?.findIndex(
            (item) => item?.classId === classId,
          );

          bootcamps[index].recap = recap;

          await patchUser(row?.id ?? "", { bootcamps });
        }
      } catch {
        toast.error("Ops! Houve um erro ao tentar remover a candidata");
      } finally {
        onCancel();
        dispatch(setLoad(false) as unknown as Action);
      }
    },
    [dispatch, onCancel],
  );

  const onAllRemove = useCallback(
    async (listSelected, reasons, reasonsDescription) => {
      for (let index = 0; index < listSelected?.length; index += 1)
        handleSaveRemove(listSelected[index], reasons, reasonsDescription);
      setListSelectedTable(undefined);
    },
    [handleSaveRemove],
  );

  const fetchClassVacancies = useCallback(async () => {
    try {
      const response = await getAllActiveClassVacancy();
      setClassVacancies(response?.data);
    } catch {
      toast.error("Ops! Houve um erro ao buscar os processos disponíveis.");
    }
  }, []);

  useEffect(() => {
    if (!classVacancies && openModalTransferClassVacancy) fetchClassVacancies();
  }, [classVacancies, fetchClassVacancies, openModalTransferClassVacancy]);

  const handleSaveTransferClassVacancy = useCallback(
    async (usr, process, stage) => {
      dispatch(setLoad(true) as unknown as Action);

      await updateClassVacancySubscriptionById(usr?._id, {
        recap: { disabled: true, reasons: ReasonsRemove.CHANGED },
      });

      await createClassVacancySubscription(
        process,
        usr?.id ?? "",
        usr?.form ?? {},
        stage,
      );

      onCancel();
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, onCancel],
  );

  const onAllTransferClassVacancy = useCallback(
    async (listSelected, process, stage) => {
      for (let index = 0; index < listSelected?.length; index += 1)
        handleSaveTransferClassVacancy(listSelected[index], process, stage);

      setListSelectedTable(undefined);
    },
    [handleSaveTransferClassVacancy],
  );

  return (
    <Layout className="admin-recap">
      <HeaderProfile />
      <Card
        className=" grid-column-1-13 grid-row-1 margin-bottom-32 margin-top-20"
        bodyClassName="padding-0"
      >
        <TableRow className="top-filters flex width-fill">
          <TextField
            autoComplete="off"
            id="searchStudent"
            placeholder="Buscar aluna"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.name ?? ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            onBlur={() => getData()}
          />
          <TextField
            autoComplete="off"
            id="legalIdentity"
            placeholder="Buscar por CPF"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.legalIdentity ?? ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                legalIdentity: e.target.value,
              }))
            }
            onBlur={() => getData()}
          />
          <TextField
            autoComplete="off"
            id="searchEmail"
            placeholder="Buscar por e-mail"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.email ?? ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                email: e.target.value,
              }))
            }
            onBlur={() => getData()}
          />
          <TextField
            autoComplete="off"
            id="searchClass"
            placeholder="Buscar por turma / processo"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.classCode ?? ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                classCode: e.target.value,
              }))
            }
            onBlur={() => getData()}
          />
          <div className="admin-class-radios">
            <span className="admin-class-radios-title">Status</span>
            <RadioGroup>
              <div>
                <FormControlLabel
                  key={Math.random()}
                  control={<Radio />}
                  label="Todos"
                  checked={
                    (!filters?.isGiveUp && !filters?.isWaitingList) ?? false
                  }
                  className="margin-right-32"
                  onChange={() => {
                    setFilters((prev) => ({
                      ...prev,
                      isGiveUp: undefined,
                      isWaitingList: undefined,
                    }));

                    getData({
                      ...filters,
                      isGiveUp: undefined,
                      isWaitingList: undefined,
                    });
                  }}
                />
                <FormControlLabel
                  key={Math.random()}
                  control={<Radio />}
                  label="Desistencia"
                  checked={filters?.isGiveUp ?? false}
                  className="margin-right-32"
                  onChange={() => {
                    setFilters((prev) => ({
                      ...prev,
                      isGiveUp: true,
                      isWaitingList: undefined,
                    }));

                    getData({
                      ...filters,
                      isGiveUp: true,
                      isWaitingList: undefined,
                    });
                  }}
                />
                <FormControlLabel
                  key={Math.random()}
                  control={<Radio />}
                  label="Lista de espera"
                  checked={filters?.isWaitingList ?? false}
                  className="margin-right-32"
                  onChange={() => {
                    setFilters((prev) => ({
                      ...prev,
                      isGiveUp: undefined,
                      isWaitingList: true,
                    }));

                    getData({
                      ...filters,
                      isGiveUp: undefined,
                      isWaitingList: true,
                    });
                  }}
                />
              </div>
            </RadioGroup>
          </div>
        </TableRow>

        <Table
          hasMoreActions
          emptyText="Não há candidatas para repescagem"
          headCells={headCells}
          list={data}
          onComment={(row) => {
            setOpenModalComments(true);
            setSelectedUser(row as Data);
          }}
          onShow={(row) => {
            setOpenModalView(true);
            setSelectedUser(row as Data);
          }}
          onRemoveList={(row) => {
            setOpenModalRemove(true);
            setSelectedUser(row as Data);
          }}
          onAllRemoveList={(listSelected) => {
            setOpenModalRemove(true);
            setListSelectedTable(listSelected);
          }}
          onTransferClass={(row) => {
            setOpenModalTransferClass(true);
            setSelectedUser(row as Data);
          }}
          onTransferAllClass={(listSelected) => {
            setOpenModalTransferClass(true);
            setListSelectedTable(listSelected);
          }}
          onViewClass={(row) => {
            setOpenModalViewClass(true);
            setSelectedUser(row as Data);
          }}
          onViewClassVacancy={(row) => {
            setOpenModalViewClassVacancy(true);
            setSelectedUser(row as Data);
          }}
          onTransferClassVacancy={(row) => {
            setOpenModalTransferClassVacancy(true);
            setSelectedUser(row as Data);
          }}
          onTransferAllClassVacancy={(listSelected) => {
            setOpenModalTransferClassVacancy(true);
            setListSelectedTable(listSelected);
          }}
          hasCommentUnresolved={(row) =>
            row?.miscellaneous?.generalComment?.filter(
              ({ resolved = false }) => !resolved,
            ).length > 0 ||
            row?.bootcamps
              ?.find(
                (item: { classId: string }) =>
                  item?.classId === row?.guBootcamps?._id,
              )
              ?.comments?.filter(({ resolved = false }) => !resolved).length > 0
          }
        />
      </Card>
      <div className="grid-row-2 grid-column-1-12  margin-top--45">
        <div className="flex">
          <p className="show-vacancies">
            Mostrando
            <span className="title-span">{data?.length}</span>
            de
            <span className="title-span">{totalFilteredList}</span>
            resultados
          </p>
          <Pagination page={page} setPage={setPage} maxPage={maxPage} />
        </div>
      </div>
      <ModalComments
        openModal={openModalComments}
        onClose={() => setOpenModalComments(false)}
        userId={user?._id}
        submit={
          selectedUser?.isGiveUp ? submitCommentUser : submitCommentSubscription
        }
        data={
          (selectedUser?.isGiveUp
            ? selectedUser?.bootcamps?.find(
                (item) => item?.classId === selectedUser?.guBootcamps?._id,
              )?.comments
            : selectedUser?.miscellaneous?.generalComment ?? []) as {
            userId: string;
            date: string | Date;
            text: string;
            resolved: boolean;
          }[]
        }
        dataUser={{
          name: selectedUser?.profile?.name ?? "",
          photo: selectedUser?.profile?.photo ?? "",
          userId: selectedUser?.id ?? "",
        }}
      />
      <View open={openModalView} onClose={onCancel} item={selectedUser} />
      <Remove
        open={openModalRemove}
        onClose={onCancel}
        onSave={(reasons, reasonsDescription) =>
          listSelectedTable
            ? onAllRemove(listSelectedTable, reasons, reasonsDescription)
            : handleSaveRemove(selectedUser, reasons, reasonsDescription)
        }
        userName={selectedUser ? selectedUser.name : ""}
      />
      <TransferClass
        open={openModalTransferClass}
        onClose={onCancel}
        onSave={(classSelected) =>
          listSelectedTable
            ? onAllTransferClass(listSelectedTable, classSelected)
            : handleSaveTransferClass(selectedUser, classSelected)
        }
        list={classList}
        userName={selectedUser ? selectedUser.name : ""}
      />
      <ViewClass
        open={openModalViewClass}
        onClose={onCancel}
        bootcamps={selectedUser?.oldBootcamps}
        userName={selectedUser?.name}
      />
      <ViewClassVacancy
        open={openModalViewClassVacancy}
        onClose={onCancel}
        subscriptions={selectedUser?.oldClassVacancySubscription}
        classVacancies={selectedUser?.oldClassVacancy}
        legalIdentity={selectedUser?.legalIdentity}
        userName={selectedUser?.name}
      />
      <TransferClassVacancy
        open={openModalTransferClassVacancy}
        onClose={onCancel}
        onSave={(classVacancyId, stageId) =>
          listSelectedTable
            ? onAllTransferClassVacancy(
                listSelectedTable,
                classVacancyId,
                stageId,
              )
            : handleSaveTransferClassVacancy(
                selectedUser,
                classVacancyId,
                stageId,
              )
        }
        list={classVacancies}
        userName={selectedUser ? selectedUser.name : ""}
      />
    </Layout>
  );
};

export default memo(AdminRecap);
