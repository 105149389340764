import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Avatar = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#2E3451"
      fillRule="evenodd"
      d="M12 20.2c.088 0 .177-.001.264-.004 1.261-.04 2.45-.365 3.505-.912-.967-.962-2.3-1.555-3.769-1.555-1.47 0-2.802.593-3.77 1.555 1.053.546 2.238.87 3.494.912.083.002.166.004.25.004H12zm0-4.271c2.095 0 3.98.902 5.287 2.34C19.069 16.763 20.2 14.513 20.2 12c0-4.529-3.671-8.2-8.2-8.2S3.8 7.471 3.8 12c0 2.514 1.132 4.764 2.913 6.268 1.307-1.437 3.191-2.34 5.287-2.34zM22 12c0 3.409-1.705 6.419-4.31 8.224l-.05.034c-.049.034-.099.068-.15.101C15.916 21.396 14.029 22 12 22c-.173 0-.344-.004-.515-.013-1.835-.093-3.54-.68-4.983-1.632-.286-.189-.561-.392-.826-.608C3.432 17.913 2 15.124 2 12 2 6.477 6.477 2 12 2s10 4.477 10 10zm-7.157-1.857c0 1.57-1.273 2.843-2.843 2.843s-2.843-1.273-2.843-2.843S10.43 7.3 12 7.3s2.843 1.273 2.843 2.843zm1.8 0c0 2.564-2.079 4.643-4.643 4.643-2.564 0-4.643-2.079-4.643-4.643C7.357 7.579 9.436 5.5 12 5.5c2.564 0 4.643 2.079 4.643 4.643z"
      clipRule="evenodd"
    />
  </svg>
);

export default Avatar;
