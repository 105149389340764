import React, {
  memo,
  forwardRef,
  ReactNode,
  useEffect,
  useState,
  CSSProperties,
} from "react";
import { Tooltip } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import { HeaderActionCard, IconButton, TitleCard } from "..";
import { IconConfig, Info } from "../../../assets/icons";
import { Edit, ChevronRight } from "../../../assets/customIcons";
import "./styles.scss";

interface Props {
  style?: CSSProperties;
  hasTooltip?: boolean;
  headerAction?: boolean;
  headerChildren?: ReactNode;
  titleHeader?: string;
  removeTitleBorder?: boolean;
  subtitleHeader?: string;
  textHeader?: string;
  textInfo?: string;
  title?: string;
  children: ReactNode;
  className?: string;
  headerClassName?: string;
  childrenHeader?: ReactNode;
  onClose?: () => void;
  footer?: ReactNode;
  onEdit?: () => void;
  onRedirect?: () => void;
  onUpdate?: () => void;
  bodyClassName?: string;
  showBorder?: boolean;
  resultSearch?: boolean;
  onClickBack?: () => void;
  hideBorderRadius?: boolean;
  borderNone?: boolean;
  configModal?: boolean;
  onClickExpandedModal?: () => void;
}

const Card: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    style,
    hasTooltip = false,
    headerAction,
    titleHeader,
    subtitleHeader,
    headerChildren,
    title,
    textHeader,
    textInfo,
    children,
    className,
    headerClassName,
    childrenHeader,
    footer,
    bodyClassName,
    onClickBack,
    onClose,
    onEdit,
    onRedirect,
    onUpdate,
    showBorder,
    resultSearch,
    hideBorderRadius,
    borderNone,
    removeTitleBorder,
    configModal,
    onClickExpandedModal,
  },
  ref,
) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      ref={ref}
      className={`
        container-mobile
        ${className || ""}
        ${
          hideBorderRadius && width < 991
            ? "card-component-mobile"
            : "card-component"
        }
         ${showBorder && width < 991 ? "has-border" : ""}`}
    >
      {headerAction && (
        <HeaderActionCard
          className={`${
            width < 991 ? "padding-y-16 padding-x-24" : "padding-24"
          }  ${headerClassName ?? ""}  `}
          text={textHeader}
          textInfo={textInfo}
          headerChildren={headerChildren}
          onClose={onClose}
          onClickBack={onClickBack}
        />
      )}
      {titleHeader && (
        <div
          className={` 
            card-component-content  
            ${removeTitleBorder ? "description-margin" : "titleHeader-mobile"} 
            ${!childrenHeader ? "flex" : "display-inherit"}
            ${borderNone ? "border-top-none" : ""}
         `}
        >
          <div className="card-component-header flex-row">
            <p className="card-component-header-title">
              {titleHeader}
              {hasTooltip && (
                <Tooltip
                  title={
                    <p>
                      A ordenação é por data da mais recente para mais antiga
                    </p>
                  }
                >
                  <div>
                    <Info className="icon-info cursor-pointer" />
                  </div>
                </Tooltip>
              )}
            </p>
            {subtitleHeader && (
              <p className="card-component-header-subtitle">{subtitleHeader}</p>
            )}
          </div>
          {onEdit && (
            <IconButton
              icon={
                <Edit
                  widthIcon={width < 991 ? 20 : 24}
                  heightIcon={width < 991 ? 20 : 24}
                />
              }
              onClick={onEdit}
              className="cursor-pointer"
            />
          )}
          {onRedirect && (
            <IconButton
              icon={
                <ChevronRight
                  widthIcon={width < 991 ? 20 : 24}
                  heightIcon={width < 991 ? 20 : 24}
                />
              }
              onClick={onRedirect}
              className="cursor-pointer"
            />
          )}
          {onUpdate && (
            <IconButton
              icon={
                <Refresh
                  style={{
                    width: width < 991 ? 20 : 24,
                    height: width < 991 ? 20 : 24,
                  }}
                />
              }
              onClick={onUpdate}
              className="cursor-pointer"
            />
          )}
          {configModal && (
            <button
              aria-label="config"
              type="button"
              onClick={onClickExpandedModal}
              className="card-component-button cursor-pointer"
            >
              <IconConfig />
            </button>
          )}
          {childrenHeader && childrenHeader}
        </div>
      )}
      <div
        style={style && style}
        className={`card-component-content-mobile ${
          resultSearch && width < 991 ? "none-padding" : "div-padding"
        } ${bodyClassName ?? ""}`}
      >
        {title && <TitleCard text={title} />}
        {children}
      </div>
      {footer && <div className="card-component-footer">{footer}</div>}
    </div>
  );
};

export default memo(forwardRef(Card));
