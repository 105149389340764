/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormControlLabel, Switch } from "@material-ui/core";
import { ArrowLeft } from "../../../assets/icons";
import "./styles.scss";
import {
  Card,
  Input,
  Layout,
  Button,
  InputAutocomplete,
  Select,
} from "../../components";
import {
  createUser,
  getAllCompanies,
  requestSignupLink,
  searchCompaniesAllStatus,
  setNewCompany,
} from "../../services/functions";
import { userRoles } from "../../types/enumerators";

const SendSignUpEmailPage = (): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [classUser, setClassUser] = useState<string>("");
  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(false);
  const [userHasCompany, setUserHasCompany] = useState<boolean>(false);
  const [companyOptions, setCompanyOptions] = useState<string[]>();
  const [currentCompany, setCurrentCompany] = useState<string>("");
  const [adminRoleType, setAdminRoleType] = useState<keyof typeof userRoles>();

  const [successfulRequest, setSuccessfulRequest] = useState(false);

  const loadCompanies = useCallback(async () => {
    try {
      const companyOptionsResponse = await getAllCompanies();

      const companyOptionsMapped = companyOptionsResponse?.map(
        (option) => option?.profile?.name ?? "",
      );

      setCompanyOptions(companyOptionsMapped);
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar as empresas");
    }
  }, []);

  useEffect(() => {
    if (!!userHasCompany && !companyOptions) loadCompanies();
  }, [companyOptions, loadCompanies, userHasCompany]);

  const handleChangeAutoComplete = useCallback(async (value: string | null) => {
    if (value && value !== "") {
      const existsOnDatabase = await searchCompaniesAllStatus(value);
      if (!existsOnDatabase?.length) {
        await setNewCompany(value);
      }
    }
  }, []);

  const handleEmailSend = useCallback(async () => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().required(),
      });
      await schema.validate(
        { email },
        {
          abortEarly: false,
        },
      );
      if (userHasCompany) {
        const currentCompanyObject =
          (await searchCompaniesAllStatus(currentCompany)) ?? [];

        const createCompanyUser = await createUser(
          email,
          [userRoles.CORPORATE],
          "",
          [currentCompanyObject[0]?._id ?? ""],
        );

        if (createCompanyUser) {
          const response = await requestSignupLink(email);
          if (response) setSuccessfulRequest(true);
        }
      } else if (userIsAdmin && adminRoleType) {
        const create = await createUser(email, [adminRoleType]);
        if (create) {
          const response = await requestSignupLink(email);
          if (response) setSuccessfulRequest(true);
        }
      } else {
        let emails: any = [email];
        const errors: any = [];

        if (email.includes(";")) {
          emails = email.split(";");
        }
        for (let i = 0; i < emails?.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          const create = await createUser(
            emails[i],
            [userRoles.STUDENT],
            classUser,
          );
          if (create) {
            // eslint-disable-next-line no-await-in-loop
            const response = await requestSignupLink(emails[i]);
            if (response) setSuccessfulRequest(true);
            else errors.push(emails[i]);
          } else errors.push(emails[i]);
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.error("Verifique o email");
        return;
      }
      toast.error("Credenciais inválidas");
    }
  }, [
    adminRoleType,
    classUser,
    currentCompany,
    email,
    userHasCompany,
    userIsAdmin,
  ]);

  return (
    <Layout header>
      <div className="grid-column-5-9">
        <Card
          headerAction
          title={
            successfulRequest
              ? "E-mail enviado com sucesso!"
              : "Insira o e-mail para finalizar o cadastro"
          }
          bodyClassName="card-send-signup"
        >
          {successfulRequest ? (
            <div className="flex justify-center">
              <button type="button" className="button-arrow-left">
                <ArrowLeft /> Voltar
              </button>
            </div>
          ) : (
            <form>
              <Input
                id="email"
                label="E-mail"
                type="text"
                value={email}
                onChange={(e: { target: { value: string } }) =>
                  setEmail(e.target.value.toLowerCase().trim())
                }
                className="width-100 margin-bottom-40"
              />
              {!userIsAdmin && !userHasCompany && (
                <Input
                  id="class"
                  label="Turma"
                  type="text"
                  value={classUser}
                  onChange={(e: { target: { value: string } }) =>
                    setClassUser(e.target.value)
                  }
                  className="width-100 margin-bottom-40"
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    checked={userIsAdmin}
                    onChange={() => {
                      setUserIsAdmin(!userIsAdmin);
                      setUserHasCompany(false);
                    }}
                    name="admin"
                  />
                }
                label="Usuário administrativo"
                className="user-company-switch"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={userHasCompany}
                    onChange={() => {
                      setUserHasCompany(!userHasCompany);
                      setUserIsAdmin(false);
                    }}
                    name="company"
                  />
                }
                label="Usuário corporativo"
                className="user-company-switch"
              />
              {userIsAdmin && (
                <Select
                  id="adminRoleType"
                  label="Selecione*"
                  value={adminRoleType ?? ""}
                  onChange={(e) => setAdminRoleType(e.target.value)}
                  options={Object.keys(userRoles).map((key) => ({
                    value: key,
                    label: userRoles[key as keyof typeof userRoles],
                  }))}
                  className="input-autocomplete-company"
                />
              )}
              {userHasCompany && (
                <InputAutocomplete
                  label="Selecione a Empresa*"
                  helperText="Pressione Enter para adicionar"
                  value=""
                  className="input-autocomplete-company"
                  onBlur={(event) => {
                    handleChangeAutoComplete(event.target.value);
                    setCurrentCompany(event.target.value);
                  }}
                  options={companyOptions ?? [""]}
                  getOptionLabel={(option) => option}
                />
              )}
              <Button
                disabled={
                  (!userHasCompany && !userIsAdmin && (!email || !classUser)) ||
                  (userHasCompany && (!email || !currentCompany)) ||
                  (userIsAdmin && (!email || !adminRoleType))
                }
                onClick={handleEmailSend}
                text="ENVIAR E-MAIL"
                className="width-100"
              />
              <Link to="/" className="button-cancel-password">
                Cancelar
              </Link>
            </form>
          )}
        </Card>
      </div>
    </Layout>
  );
};

export default memo(SendSignUpEmailPage);
