import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const ArrowLeft = ({
  widthIcon,
  heightIcon,
  className,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    className={className}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#7E459B"
      stroke="#7E459B"
      strokeWidth=".5"
      d="M13.121 25.7c.38.4 1.014.4 1.408 0 .38-.388.38-1.033 0-1.42l-7.137-7.268h19.332c.549-.001 1.276-.446 1.276-1.005 0-.559-.727-1.018-1.276-1.018H7.392l7.137-7.255c.38-.4.38-1.047 0-1.433-.394-.401-1.028-.401-1.408 0L4.295 15.29c-.393.388-.393 1.033 0 1.42l8.826 8.99z"
    />
  </svg>
);

export default ArrowLeft;
