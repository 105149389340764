/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { toast } from "react-toastify";
import { AttachmentPhoto, Button, Modal } from "../../../components";
import api from "../../../services/api";
import { updateUser } from "../../../store/actions/userActions";

interface Props {
  user: any;
  openModal: boolean;
  onClose: () => void;
  setUser: Dispatch<SetStateAction<any>>;
}

const Avatar = ({ user, setUser, openModal, onClose }: Props): JSX.Element => {
  const [_user, _setUser] = useState(user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.profile?.name && !_user?.profile?.name) _setUser({ ...user });
  }, [_user, user]);

  const onChangeImage = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      try {
        await api
          .post("/fileUpload", data)
          .then((result) => {
            _setUser({
              ..._user,
              profile: { ..._user?.profile, photo: result?.data?.file_url.url },
            });
          })
          .catch(() => {
            toast.error("Ocorreu um erro durante o upload do arquivo");
          });
      } catch (error) {
        console.warn(error);
      }
    },
    [_user],
  );

  const onRemove = useCallback(
    () =>
      _setUser({
        ..._user,
        profile: { ..._user?.profile, photo: "" },
      }),
    [_user],
  );

  const onSave = useCallback(() => {
    setUser({
      ...user,
      profile: { ...user?.profile, photo: _user.profile.photo },
    });
    dispatch(
      updateUser({
        ...user,
        profile: { ...user?.profile, photo: _user.profile.photo },
      }) as unknown as Action,
    );
    onClose();
  }, [user, _user, dispatch, onClose, setUser]);

  return (
    <Modal
      headerAction
      textHeader="Editar foto do perfil"
      open={openModal}
      onClose={onClose}
      bodyClassName="modal-profile header-edit-contacts height-fit-content"
    >
      <AttachmentPhoto
        photo={_user?.profile?.photo}
        type="foto"
        onChange={onChangeImage}
      />
      <div className="flex-row-center width-100 margin-top-48 justify-center flex-direction-column-reverse margin-top-20-mobile flex-col-mobile">
        {_user?.profile?.photo && (
          <button
            className="margin-right-0-responsive button-avatar-delet"
            type="button"
            onClick={onRemove}
          >
            Excluir imagem
          </button>
        )}
        <Button
          text="Cancelar"
          className="width-160 margin-right-24 margin-right-0-mobile margin-top-20-mobile margin-bottom-20-mobile width-100-responsive"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button
          text="Salvar"
          className=" width-160 width-100-responsive"
          onClick={onSave}
        />
      </div>
    </Modal>
  );
};

export default memo(Avatar);
