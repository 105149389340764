/* eslint-disable @typescript-eslint/no-explicit-any */

import { NEXT_BUTTON } from "../types/nextButtonTypes";

const initialState: any = { nextButton: null };

export const nextButtonReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
): any => {
  switch (type) {
    case NEXT_BUTTON:
      return { ...state, nextButton: payload };

    default:
      return state;
  }
};
