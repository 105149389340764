/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useState, useEffect, useMemo } from "react";
import moment from "moment";
import {
  info,
  education,
  reprograma,
  socioEconomicProfile,
  socioEconomicAssessment,
} from "./data";
import { classVacancyType, mediaType } from "../../../types/enumerators";
import { Medias, ViewAddress, ViewSocialMedias } from "../../../components";
import type { Props, ReceiptProps } from "./types";
import "../style.scss";
import {
  DefaultClassVacancySubscriptionQuestions,
  IJSClassVacancySubscriptionQuestions,
} from "../../../types/interfaces";

const Receipt = ({
  item,
  type,
  endDate,
  startDate,
  children,
}: ReceiptProps): JSX.Element => (
  <div>
    <div className="label-infos-content">
      <p className="label-title">{type}</p>
      {children}
    </div>
    <p className="label-description">{item?.title}</p>
    {item?.name && <p className="label-description">{item?.name}</p>}
    {item?.url && <p className="label-description">{item?.url}</p>}
    {item?.type !== mediaType.LINK && startDate && (
      <p className="label-description">
        {`${moment(startDate).format(
          item?.fullDate ? "DD/MM/YYYY " : "MMMM/YYYY",
        )} - ${
          !endDate
            ? "Atualmente"
            : moment(endDate).format(
                item?.fullDate ? "DD/MM/YYYY" : "MMMM/YYYY",
              )
        }`}
      </p>
    )}
    <p className="label-description">{item?.description}</p>
    {item?.medias && item?.medias?.length > 0 && (
      <Medias
        medias={item?.medias?.filter(
          (innerItem) => innerItem?.type !== mediaType.LINK,
        )}
        links={item?.medias?.filter(
          (innerItem) => innerItem?.type === mediaType.LINK,
        )}
      />
    )}
  </div>
);

const FormView = ({ user, subscription, course, step }: Props): JSX.Element => {
  const [data, setData] = useState<any[]>([]);

  const grade = useMemo(() => {
    if (subscription && course) {
      const stage = course?.stages?.find(
        (item) => item?.type === classVacancyType.SUBSCRIPTION,
      );

      const findUserStage = subscription?.stages?.find(
        ({ stageId }) => stageId === stage?.stage_id,
      );

      if (findUserStage)
        return (
          findUserStage?.grade?.find(({ type }) => type === "FINAL")?.value ?? 0
        );
    }

    return 0;
  }, [course, subscription]);

  const form = useMemo(
    () =>
      subscription?.form as
        | ({
            [x: string]: string;
          } & DefaultClassVacancySubscriptionQuestions)
        | ({
            [x: string]: string;
          } & IJSClassVacancySubscriptionQuestions),
    [subscription?.form],
  );

  useEffect(() => {
    if (user || subscription) {
      switch (step) {
        case 1:
          setData(info(user?.profile, subscription));
          break;

        case 3:
          setData(reprograma(form, course));
          break;

        case 4:
          setData(education(form, course));
          break;

        case 5:
          setData(socioEconomicProfile(user?.profile, form, course));
          break;

        case 7:
          setData(socioEconomicAssessment(form));
          break;

        default:
          setData([]);
      }
    }
  }, [course, form, step, subscription, user]);

  return (
    <div className="media-card-account subscriptions-card">
      {step === 6 && user && (
        <div className="subscriptions-certificate">
          {user.curriculum?.portfolios?.map((item) => (
            <Receipt
              key={Math.random()}
              item={item}
              type="Portfólio"
              startDate={item?.startDate}
              endDate={item?.endDate}
            />
          ))}

          {user.curriculum?.certificates?.map((item) => (
            <Receipt
              key={Math.random()}
              item={item}
              type="Certificado"
              startDate={item?.date}
              endDate={item?.expirationDate}
            />
          ))}

          {user.curriculum?.certificates?.length === 0 &&
            (user.curriculum?.portfolios?.length === 0 ||
              user.curriculum?.portfolios === undefined) && (
              <p>Não há nenhum comprovante cadastrado.</p>
            )}
        </div>
      )}
      {step === 2 ? (
        <ViewAddress location={user?.profile?.address} />
      ) : (
        <div className="flex-wrap">
          {data?.map(
            (item) =>
              !item?.notRender && (
                <div key={Math.random()} className="label-infos">
                  <div className="label-infos-content">
                    <p className="label-title">{item?.title}</p>
                  </div>
                  <p className="label-description">{item?.value}</p>
                </div>
              ),
          )}
          {step === 1 && (
            <div className="subscriptions-view-socials">
              <ViewSocialMedias socialMedias={user?.profile?.socialMedias} />
            </div>
          )}
        </div>
      )}

      <div className="subscriptions-workshop-items-content">
        <div className="label-infos">
          <p className="label-title">Nota final</p>
          <p className="label-description">{grade ?? 0}</p>
        </div>
      </div>
    </div>
  );
};

export default memo(FormView);
