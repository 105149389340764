export enum CourseSubscriptionAction {
  COURSE_SUBSCRIPTION_SELECT_COURSE = "COURSE_SUBSCRIPTION_SELECT_COURSE",
  COURSE_SUBSCRIPTION_NEXT_STEP = "COURSE_SUBSCRIPTION_NEXT_STEP",
  COURSE_SUBSCRIPTION_PREVIOUS_STEP = "COURSE_SUBSCRIPTION_PREVIOUS_STEP",
  COURSE_SUBSCRIPTION_FORM_DONE = "COURSE_SUBSCRIPTION_FORM_DONE",
  COURSE_SUBSCRIPTION_FORM_REVIEW = "COURSE_SUBSCRIPTION_FORM_REVIEW",
  COURSE_SUBSCRIPTION_FORM_SUBMIT = "COURSE_SUBSCRIPTION_FORM_SUBMIT",
  COURSE_SUBSCRIPTION_ATTACHMENT_SUBMISSION = "COURSE_SUBSCRIPTION_ATTACHMENT_SUBMISSION",
  COURSE_SUBSCRIPTION_ATTACHMENT_BACK = "COURSE_SUBSCRIPTION_ATTACHMENT_BACK",
  COURSE_SUBSCRIPTION_ATTACHMENT_SUBMITTED = "COURSE_SUBSCRIPTION_ATTACHMENT_SUBMITTED",
  COURSE_SUBSCRIPTION_WORKSHOP_CHOICE_CLASS_SUBMITTED = "COURSE_SUBSCRIPTION_WORKSHOP_CHOICE_CLASS_SUBMITTED",
  COURSE_SUBSCRIPTION_WORKSHOP_BEHAVIOR_SUBMITTED = "COURSE_SUBSCRIPTION_WORKSHOP_BEHAVIOR_SUBMITTED",
  COURSE_SUBSCRIPTION_WORKSHOP_FORM_DIVERSITY_SUBMITTED = "COURSE_SUBSCRIPTION_WORKSHOP_FORM_DIVERSITY_SUBMITTED",
  COURSE_SUBSCRIPTION_WORKSHOP_TECHNICAL_FORM_SUBMITTED = "COURSE_SUBSCRIPTION_WORKSHOP_TECHNICAL_FORM_SUBMITTED",
  COURSE_SUBSCRIPTION_WORKSHOP_MEDIA_SUBMITTED = "COURSE_SUBSCRIPTION_WORKSHOP_MEDIA_SUBMITTED",
  COURSE_SUBSCRIPTION_DONE = "COURSE_SUBSCRIPTION_DONE",
  COURSE_SUBSCRIPTION_UNSUBSCRIBE = "COURSE_SUBSCRIPTION_UNSUBSCRIBE",
}
