import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Modal, Select } from "../../../components";
import { AvatarEmpty, Close } from "../../../../assets/customIcons";
import { FormEdit, FormView } from "../../AdminClassVacancySubscriptions/Steps";
import { getUserById } from "../../../services/functions";
import { User as UserProps } from "../../../types/interfaces";
import "../style.scss";

interface User {
  userId: string;
  photo: string;
  name: string;
}

interface Props {
  open: boolean;
  close: () => void;
  user: User;
  reload?: () => void;
}

const UserView = ({ open, close, user, reload }: Props): JSX.Element => {
  const [index, setIndex] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [_user, _setUser] = useState<UserProps>();

  const items = useMemo(() => {
    const itemsMenu = [
      {
        label: "Dados pessoais",
        value: 1,
      },
      {
        label: "Endereço",
        value: 2,
      },
    ];

    return itemsMenu;
  }, []);

  const getUser = useCallback(async () => {
    const response = await getUserById(user?.userId);
    if (response) _setUser(response);
  }, [user?.userId]);

  useEffect(() => {
    if (!_user?.profile && user?.name) getUser();
  }, [_user?.profile, getUser, user?.name]);

  const onClose = useCallback(() => {
    setIndex(1);
    setIsEdit(false);
    _setUser(undefined);
    close();
  }, [close]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="md"
      className="admin-subscriptions-infos"
    >
      <div className="admin-subscriptions-infos-header">
        <div className="admin-subscriptions-infos-header-top">
          <div>
            {user?.photo ? (
              <img
                alt=""
                src={user?.photo}
                className="img-avatar-header-modal"
              />
            ) : (
              <AvatarEmpty
                className="border-avatar-empty img-attachment-photo-small"
                heightIcon={32}
                widthIcon={32}
              />
            )}
            <p className="p-text-header-action-card">{user?.name}</p>
          </div>
          <Close
            className="close-header-action-card margin-left-6 cursor-pointer"
            widthIcon="28"
            onClick={onClose}
          />
        </div>
      </div>
      <div className="admin-subscriptions-infos-body">
        <Select
          value={index ?? ""}
          label="Selecione os dados"
          options={items}
          onChange={(e) => setIndex(e.target.value)}
          className="admin-subscriptions-infos-body-select"
        />
        {!isEdit ? (
          <>
            <div>
              <FormView user={_user as UserProps} step={index} />
            </div>
            <div className="admin-subscriptions-infos-buttons">
              <Button
                text="Cancelar"
                onClick={onClose}
                outline
                appearance="secondary"
              />
              <Button
                text="Editar"
                onClick={() => {
                  setIsEdit(true);
                }}
              />
            </div>
          </>
        ) : (
          <FormEdit
            currentUser={_user}
            step={index}
            setIsEdit={setIsEdit}
            reload={reload}
          />
        )}
      </div>
    </Modal>
  );
};

export default memo(UserView);
