/* eslint-disable @typescript-eslint/no-explicit-any */
import { SET_DEFAULT_PAGE_LIMIT, SET_LOAD } from "../types/configurationsTypes";

const initialState: any = { pageLimit: 10, isLoad: false };

export const configurationsReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
): any => {
  switch (type) {
    case SET_DEFAULT_PAGE_LIMIT:
      return { ...state, pageLimit: payload };
    case SET_LOAD:
      return { ...state, isLoad: payload };
    default:
      return state;
  }
};
