import React, { useCallback } from "react";
import { Input } from "../../../../components";
import "../../style.scss";

interface Props {
  grade?: { type?: string; value?: number }[];
  steps?: {
    type?: string;
    title?: string;
    label?: string;
    text?: string;
  }[];
  calcFinal: (data: { type?: string; value?: number }[]) => void;
}

const Proposes = ({ grade, steps, calcFinal }: Props): JSX.Element => {
  const onChange = useCallback(
    async (e, prop: string) => {
      if (e.target.value > 10) return;

      const newGrade = [...(grade ?? [])];
      const value = e.target.value ? Number(e.target.value) : undefined;

      const findIndexProp = newGrade?.findIndex(({ type }) => type === prop);

      if (findIndexProp !== -1) newGrade[findIndexProp].value = value;
      else
        newGrade.push({
          type: prop,
          value,
        });

      calcFinal(newGrade);
    },
    [calcFinal, grade],
  );

  return (
    <>
      <div className="propose-title subtitle">
        <h3>Notas Gerais</h3>
      </div>
      <div className="propose-grades">
        {steps?.map((item) => {
          const itIsDiversity = item?.label === "diversity";
          const upperCaseText = item?.label?.toUpperCase() ?? "";
          const gradeVerification = grade?.find(
            (gradeItem) => gradeItem?.type === upperCaseText,
          );
          const isTechnicalForm = item?.type === "hasTechnicalTestForm";

          return (
            <Input
              key={item?.label}
              className={
                itIsDiversity || isTechnicalForm ? "input-diversity" : ""
              }
              label={item?.text}
              type="number"
              value={gradeVerification?.value ?? ""}
              isGrade
              InputProps={
                itIsDiversity || isTechnicalForm
                  ? { readOnly: true }
                  : undefined
              }
              onChange={
                !itIsDiversity ? (e) => onChange(e, upperCaseText) : undefined
              }
            />
          );
        })}
        <Input
          className="input-diversity"
          label="Nota final *"
          type="number"
          value={grade?.find((item) => item?.type === "FINAL")?.value ?? ""}
          isGrade
          InputProps={{ readOnly: true }}
        />
      </div>
    </>
  );
};

export default Proposes;
