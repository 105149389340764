/* eslint-disable no-console */
/* eslint-disable camelcase */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { maskPhone, orderList } from "../../../utils";
import { ChevronDown } from "../../../../assets/icons";
import { AvatarEmpty } from "../../../../assets/customIcons";
import { getCompanyUsers } from "../../../services/functions";
import { User } from "../../../types/interfaces";
import { RootState } from "../../../store/reducers";

interface UserProps {
  photo: string;
  name: string;
  email: string;
  business_unity: string;
  telephone: string;
}

const ViewUsers = (): JSX.Element => {
  const [users, setUsers] = useState<UserProps[]>();
  const company = useSelector((state: RootState) => state.companyState);

  const [headCells, setHeadCells] = useState([
    {
      id: "photo",
      numeric: false,
      disablePadding: true,
      label: "",
      orderBy: false,
      asc: false,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Nome",
      orderBy: false,
      asc: false,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "E-mail",
      orderBy: false,
      asc: false,
    },
    {
      id: "business_unity",
      numeric: false,
      disabledPadding: false,
      label: "Unidade de negócios",
      orderBy: false,
      asc: false,
    },
    {
      id: "telephone",
      numeric: false,
      disablePadding: false,
      label: "Celular",
      orderBy: false,
      asc: false,
    },
  ]);

  const orderUser = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (id, usersList?: any[], sortHeadCells = true) => {
      if (id !== "photo") {
        const listOrdered = orderList(
          id,
          headCells,
          usersList ?? users ?? [],
          sortHeadCells,
        );
        setUsers(listOrdered.list);
        setHeadCells(listOrdered.headList);
      }
    },
    [headCells, users],
  );

  const loadUsers = useCallback(async () => {
    try {
      const response = await getCompanyUsers(company._id);
      const usersData = response?.map(
        (user: User): UserProps => ({
          photo: user?.profile?.photo ?? "",
          name: user?.profile?.name ?? "",
          email: user?.profile?.email ?? "",
          business_unity: user?.account?.tags?.[0] ?? "",
          telephone: user?.profile?.telephone ?? "",
        }),
      );

      orderUser("name", usersData ?? []);
    } catch (error) {
      console.warn(error);
    }
  }, [company._id, orderUser]);

  useEffect(() => {
    if (!users) loadUsers();
  }, [company._id, loadUsers, users]);

  return (
    <div className="width-100">
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  onClick={() => orderUser(headCell.id)}
                  className="view-table-cell"
                  key={Math.random()}
                >
                  {headCell.label}
                  {headCell.label.length > 0 && (
                    <ChevronDown
                      className={`not-rotate ${
                        headCell.asc ? "rotate-row" : ""
                      }`}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row) => (
              <TableRow>
                <TableCell className="width-32-px">
                  {row?.photo ? (
                    <img
                      src={row?.photo}
                      alt="foto"
                      width={32}
                      height={32}
                      className="img-attachment-photo-small"
                    />
                  ) : (
                    <AvatarEmpty
                      heightIcon={32}
                      widthIcon={32}
                      className="img-attachment-photo-small"
                    />
                  )}
                </TableCell>
                <TableCell className="row-table-cell">{row?.name}</TableCell>
                <TableCell className="row-table-cell">{row?.email}</TableCell>
                <TableCell className="row-table-cell">
                  {row?.business_unity?.replace("business_unity:", "")}
                </TableCell>
                <TableCell className="row-table-cell">
                  {maskPhone(row?.telephone ?? "")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default memo(ViewUsers);
