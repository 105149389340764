/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Card, Layout } from "../../components";
import { getResearchActive } from "../../services/functions";
import { RootState } from "../../store/reducers";
import "./styles.scss";
import { ResearchType } from "../../types/constants/Employment";
import { researchType, userBootcampsStatus } from "../../types/enumerators";

const AllResearch = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const history = useHistory();
  const [form, setForm] = useState<any[]>();

  const onBack = useCallback(() => history.push("/"), [history]);

  const getResearch = useCallback(async () => {
    const classCode = user?.bootcamps
      ?.filter((bootcamp) => bootcamp?.status === userBootcampsStatus.FINISHED)
      ?.map((item: { classId: string }) => item?.classId);
    const response = await getResearchActive(classCode);
    const forms = response
      ?.map((item) => item?.forms)
      ?.reduce((acc, cur) => acc.concat(cur), []);
    const listSearch = forms?.filter(
      (item: { type: string }) =>
        item?.type !== researchType.COMPANY && item?.enable,
    );
    setForm(listSearch);
  }, [user]);

  useEffect(() => {
    if (!form) getResearch();
  }, [form, getResearch]);

  return (
    <Layout
      back
      onClickBack={onBack}
      header
      className="padding-bottom-40 grid-responsive-register all-search"
    >
      <div className="grid-column-3-11 grid-column-1-11-mobile">
        <p className="first-text-description">Olá!</p>
        <p className="second-text-description">
          Você tem algumas pesquisas disponíveis para responder:
        </p>

        {form &&
          form?.length > 0 &&
          form?.map(
            (item: {
              enable: boolean;
              classId: string;
              type: keyof typeof ResearchType;
            }) => (
              <Card key={Math.random()} className="margin-bottom-24">
                <div className="flex-row-center space-between display-block">
                  <div>
                    <p className="title-all-search">
                      Pesquisa de {ResearchType[item?.type]}
                    </p>
                    <p className="description-all-search">
                      Pesquisa de empregabilidade realizada{" "}
                      {ResearchType[item?.type]} após a formatura
                    </p>
                  </div>
                  <div>
                    <Button
                      text="RESPONDER"
                      className="width-176-mobile width-200"
                      onClick={() =>
                        history.push(
                          `/employability/${item?.classId}/${
                            item?.type as string
                          }`,
                        )
                      }
                    />
                  </div>
                </div>
              </Card>
            ),
          )}
      </div>
    </Layout>
  );
};

export default memo(AllResearch);
