import React, { memo, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  unsaveAVacancy,
  getSavedVacanciesPerUser,
} from "../../services/functions";
import { Layout, HeaderProfile, CardMenuLeft } from "../../components";
import CardSavedJobs from "./CardSavedJobs";
import "./style.scss";
import { RootState } from "../../store/reducers";
import { Bookmark } from "../../../assets/customIcons";
import { setLoad } from "../../store/actions/configurationsActions";
import { placementVacancyStatus } from "../../types/enumerators";

interface SavedJobsProps {
  jobTitle?: string;
  companyName?: string;
  jobStatus?: string;
  jobManagedBy?: string;
  jobTypeOfCandidature?: string;
  jobDate?: string | Date;
  jobType?: string;
  jobPlace?: string;
  isBookmarked?: boolean;
  photo?: string;
  vacancyId?: string;
}

const SavedJobs = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const user = useSelector((state: RootState) => state.userState);
  const [vacanciesSaved, setVacanciesSaved] = useState<SavedJobsProps[] | []>();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const dispatch = useDispatch();

  const loadSavedJobs = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    try {
      const savedJobs = await getSavedVacanciesPerUser(user._id);
      const savedJobsMapped = savedJobs
        ?.filter(
          (item) =>
            item?.placementVacancyData?.status !==
            placementVacancyStatus.DISABLED,
        )
        ?.map((job) => ({
          vacancyId: job?.placementVacancyData?._id ?? "",
          jobTitle: job?.placementVacancyData?.title ?? "",
          companyName: job?.companyData?.profile?.name ?? "",
          jobStatus: job?.placementVacancyData?.status ?? "",
          jobManagedBy: job?.placementVacancyData?.managedBy ?? "",
          jobTypeOfCandidature:
            job?.placementVacancyData?.typeOfCandidature ?? "",
          isBookmarked: true,
          photo: job?.companyData?.profile?.logo ?? "",
          jobType: job?.placementVacancyData?.type ?? "",
          jobPlace: job?.placementVacancyData?.place ?? "",
        }));
      setVacanciesSaved(savedJobsMapped ?? []);

      dispatch(setLoad(false) as unknown as Action);
    } catch (err) {
      dispatch(setLoad(false) as unknown as Action);

      toast.error("Ocorreu um erro ao carregar as vagas salvas");
    }
  }, [dispatch, user._id]);

  useEffect(() => {
    loadSavedJobs();
  }, [user._id, shouldUpdate, loadSavedJobs]);

  const handleUnsaveJobClick = useCallback(
    async (vacancyId) => {
      dispatch(setLoad(true) as unknown as Action);
      try {
        const response = await unsaveAVacancy(vacancyId, user._id);
        setShouldUpdate(response);

        dispatch(setLoad(false) as unknown as Action);
      } catch {
        dispatch(setLoad(false) as unknown as Action);
        toast.error("Ocorreu um erro ao remover a vaga");
      }
    },
    [dispatch, user._id],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-responsive">
      <HeaderProfile />
      <div className="margin-bottom-32 grid-column-1-11">
        <div className="grid-template-columns-1-5 display-block">
          <CardMenuLeft
            icon={<Bookmark widthIcon={20} heightIcon={20} />}
            selectedMenuTitle="Vagas Salvas"
          />
          <div className="card-candidature margin-top-16-responsive">
            {width > 990 && (
              <div className="card-title-div">
                <h2 className="card-title"> Vagas Salvas </h2>
              </div>
            )}
            {vacanciesSaved?.map((item) => (
              <div
                key={Math.random()}
                role="none"
                className="divider-company-search"
              >
                <CardSavedJobs data={item} onClick={handleUnsaveJobClick} />
              </div>
            ))}
            {!vacanciesSaved?.length ? (
              <p className="margin-left-24 not-proposed">Não há vagas salvas</p>
            ) : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default memo(SavedJobs);
