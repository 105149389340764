/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStore, applyMiddleware, compose } from "redux";
import { createBrowserHistory } from "history";
import reduxLogger from "redux-logger";
import thunk from "redux-thunk";
import { isDevelopment } from "../services/api";
import { rootReducer } from "./reducers";

const middleware = isDevelopment ? [thunk, reduxLogger] : [thunk];

export const history = createBrowserHistory();

export const store = createStore(
  rootReducer(history),
  compose(applyMiddleware(...middleware)),
);
