import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useHistory } from "react-router-dom";
import {
  Account,
  Address as AddressIcon,
  Company,
  SocialMedia,
} from "../../../assets/icons";
import {
  Card,
  Modal,
  Button,
  Finish,
  Layout,
  Progress,
  CardSteps,
  FormAddress,
  EditSocialMedias,
} from "../../components";
import { getCompany, patchCompany } from "../../services/functions";
import { updateCompany } from "../../store/actions/companyActions";
import { setNextButton } from "../../store/actions/nextButtonAction";
import { setStepRegister } from "../../store/actions/stepRegisterActions";
import { updateUser } from "../../store/actions/userActions";
import { RootState } from "../../store/reducers";
import { companyStatus, userStatus } from "../../types/enumerators";
import { isActiveCompany } from "../../utils";
import { Infos, UserData } from "./Steps";
import "./style.scss";
import { setLoad } from "../../store/actions/configurationsActions";

const CompanyRegister = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isValid, setIsValid] = useState(false);
  const [onlyUser, setOnlyUser] = useState(false);
  const [isOptional, setIsOptional] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const company = useSelector((state: RootState) => state.companyState);

  const stepRegister = useSelector(
    (state: RootState) => state.stepRegisterState,
  );
  const { nextButton } = useSelector(
    (state: RootState) => state.nextButtonState,
  );
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const history = useHistory();

  const itemsMenu = useMemo(
    () => [
      {
        icon: <Company />,
        text: "Dados da empresa",
        id: 0,
      },
      {
        icon: <SocialMedia />,
        text: "Redes sociais",
        id: 1,
      },
      {
        icon: <AddressIcon />,
        text: "Endereço da empresa",
        id: 2,
      },
      {
        icon: <Account />,
        text: "Seus dados",
        id: 3,
      },
    ],
    [],
  );

  useEffect(() => {
    if (stepRegister.page === 1) setIsOptional(true);
    else setIsOptional(false);
  }, [stepRegister.page]);

  useEffect(() => {
    if (isActiveCompany(company)) {
      dispatch(setStepRegister({ page: 3 }) as unknown as Action);
      setOnlyUser(true);
    }
  }, [company, company?._id, company.status, dispatch, history, user]);

  useEffect(() => {
    const events = async (e: KeyboardEvent) => {
      const { key } = e;

      const action = key?.toString();

      if (action === "F5") {
        e.preventDefault();
        setOpenModal(true);
      }
    };

    window.addEventListener("keydown", events);

    return () => {
      window.removeEventListener("keydown", events);
    };
  }, []);

  const onNext = useCallback(() => {
    dispatch(
      setStepRegister({ page: stepRegister.page + 1 }) as unknown as Action,
    );
    setIsValid(false);

    const menuItem = itemsMenu.find(
      (item) => item.id === stepRegister.page + 1,
    );
    if (menuItem && menuItem.id > stepRegister.maxPageVisited) {
      dispatch(
        setStepRegister({ maxPageVisited: menuItem.id }) as unknown as Action,
      );
      dispatch(
        setStepRegister({ maxMenuVisited: menuItem.id }) as unknown as Action,
      );
    }

    if (nextButton !== null) {
      const funcNextButton = () => nextButton();
      funcNextButton();
      dispatch(setNextButton(null) as unknown as Action);
    }
  }, [
    dispatch,
    itemsMenu,
    nextButton,
    stepRegister.maxPageVisited,
    stepRegister.page,
  ]);

  const onBack = useCallback(() => {
    setIsValid(true);
    if (stepRegister.page !== 0 && (!onlyUser || stepRegister.page === 4))
      dispatch(
        setStepRegister({ page: stepRegister.page - 1 }) as unknown as Action,
      );
  }, [dispatch, onlyUser, stepRegister.page]);

  const onFinish = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const newUser = {
      ...user,
      account: { ...user.account, status: userStatus.ACTIVE },
    };
    dispatch(updateUser({ ...newUser }) as unknown as Action);

    await patchCompany(company._id, { status: companyStatus.ACTIVE });

    if (!company?.profile?.name && user?.account?.companyIds?.length) {
      const currentCompany = await getCompany(user?.account?.companyIds[0]);
      dispatch(updateCompany(currentCompany) as unknown as Action);
      history.push(`/company-profile/${currentCompany?._id}`);
    } else {
      const updatedCompany = { ...company, status: companyStatus.ACTIVE };
      dispatch(updateCompany(updatedCompany) as unknown as Action);
      history.push(`/company-profile/${company._id}`);
    }

    dispatch(setLoad(false) as unknown as Action);
  }, [company, dispatch, history, user]);

  const updateSocialMedia = useCallback(
    (value) => {
      dispatch(
        setNextButton(() =>
          dispatch(
            updateCompany({
              profile: {
                ...company?.profile,
                socialMedias: value,
              },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [company?.profile, dispatch],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderPages = useMemo(() => {
    switch (stepRegister.page) {
      case 0:
        return <Infos setIsValid={setIsValid} />;

      case 1:
        return (
          <EditSocialMedias
            setIsValid={setIsValid}
            setIsOptional={setIsOptional}
            socialMedias={company?.profile?.socialMedias}
            updateSocialMedia={updateSocialMedia}
          />
        );

      case 2:
        return (
          <FormAddress
            setIsValid={setIsValid}
            showLabel
            corporateDispatch
            hideCheckboxes
          />
        );

      case 3:
        return <UserData setIsValid={setIsValid} />;

      default:
        return <div />;
    }
  }, [company?.profile?.socialMedias, stepRegister.page, updateSocialMedia]);

  return (
    <Layout
      header
      back={stepRegister.page !== 0 && !onlyUser}
      onClickBack={onBack}
      className="padding-bottom-40 company-register grid-responsive-register"
    >
      {width > 990 && stepRegister.page < 4 && !onlyUser ? (
        <div className="grid-column-2-5">
          <div className="container-step">
            <CardSteps
              smallScreen
              itemsMenu={itemsMenu}
              className="card-steps-register"
            />
            <Progress
              value={Math.round(
                (stepRegister.page / (itemsMenu?.length ?? 0)) * 100,
              )}
              className="margin-top-32"
            />
          </div>
        </div>
      ) : (
        stepRegister.page < 4 &&
        !onlyUser && (
          <div className="grid-column-1-9">
            <div className="container-step">
              <Progress
                value={Math.round(
                  (stepRegister.page / (itemsMenu?.length ?? 0)) * 100,
                )}
                className="margin-bottom-progress"
              />
              <CardSteps
                smallScreen
                itemsMenu={itemsMenu}
                className="card-steps-register"
                orientation="horizontal"
              />
            </div>
          </div>
        )
      )}

      {width > 990 && stepRegister.page < 4 ? (
        <Card
          className={`height-fit ${
            onlyUser
              ? "grid-column-2-12"
              : "grid-column-5-12 grid-column-1-11-mobile"
          } border-card`}
          titleHeader={itemsMenu[stepRegister.page].text}
        >
          {renderPages}
        </Card>
      ) : (
        stepRegister.page < 4 && (
          <Card
            borderNone
            className={`height-fit margin-top-16 ${
              onlyUser
                ? "grid-column-2-12"
                : "grid-column-5-12 grid-column-1-11-mobile "
            } border-card`}
            titleHeader={itemsMenu[stepRegister.page].text}
          >
            {renderPages}
          </Card>
        )
      )}

      {isOptional && (
        <Button
          text="Pular"
          className="grid-column-10-12 grid-column-1-9-mobile"
          onClick={onNext}
          outline
          appearance="secondary"
        />
      )}

      {stepRegister.page < 4 && !isOptional && (
        <Button
          text={stepRegister.page < 3 ? "Avançar" : "Salvar"}
          className={`grid-column-10-12 grid-column-1-9-mobile ${
            stepRegister.page < 3 ? "button-primary" : ""
          }`}
          onClick={onNext}
          disabled={!isValid}
        />
      )}

      {stepRegister.page === 4 && <Finish type="company" onClick={onFinish} />}

      <Modal
        headerAction
        textHeader="Atualizar página"
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="company-register-modal"
      >
        <p className="p-class">
          Tem certeza que deseja atualizar a página? Você perderá os seus dados
          preenchidos.
        </p>

        <div className="company-register-modal-buttons">
          <Button
            text="Atualizar"
            className="width-200 margin-right-32 width-100-responsive margin-right-0-mobile margin-right-32 margin-left-0-mobile margin-top-20-mobile"
            onClick={() => window.location.reload()}
          />
          <Button
            text="Voltar"
            onClick={() => setOpenModal(false)}
            className="width-200 width-100-responsive"
          />
        </div>
      </Modal>
    </Layout>
  );
};

export default memo(CompanyRegister);
