/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  Address as AddressIcon,
  Avatar,
  EducationMini as EducationIcon,
  Newfinance,
  Reprograma as ReprogramaIcon,
  Certificate as CertificateIcon,
} from "../../../assets/icons";
import { useAuth } from "../../auth";
import {
  Button,
  CardSteps,
  Layout,
  CardRegister,
  Progress,
  FormAddress,
} from "../../components";
import {
  getActiveUserClassVacancySubscription,
  getClassVacancyById,
} from "../../services/functions";
import { createCourseSubscriptionLog } from "../../services/historicFunctions";
import { setCourseSubscription } from "../../store/actions/courseActions";
import { setNextButton } from "../../store/actions/nextButtonAction";
import { setStepRegister } from "../../store/actions/stepRegisterActions";
import { RootState } from "../../store/reducers";
import { CourseSubscriptionState } from "../../types/constants/Historic";
import { classVacancyTemplate } from "../../types/enumerators";
import { CourseSubscriptionAction } from "../../types/enumerators/actions/CourseSubscriptionAction";
import { ClassVacancy } from "../../types/interfaces";
import { hasCandidateRole } from "../../utils";
import {
  Completed,
  Education,
  Infos,
  Certificates,
  Reprograma,
  SocioEconomicProfile,
  SocioEconomicAssessment,
} from "./Steps";
import "./style.scss";

const CourseRegister = (): JSX.Element => {
  const dispatch = useDispatch();
  const { signOut } = useAuth();

  const [width, setWidth] = useState(window.innerWidth);
  const [isValid, setIsValid] = useState(false);
  const [data, setData] = useState<any>({});
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();

  const user = useSelector((state: RootState) => state.userState);
  const subscription = useSelector((state: RootState) => state.courseState);

  const stepRegister = useSelector(
    (state: RootState) => state.stepRegisterState,
  );

  const { nextButton } = useSelector(
    (state: RootState) => state.nextButtonState,
  );

  const formatLogState = (index: number, text: string) =>
    `${index + 1}. ${text}`;

  const getSubscription = useCallback(async () => {
    const subscriptions = await getActiveUserClassVacancySubscription(user._id);
    if (subscriptions?.data?.length)
      dispatch(
        setCourseSubscription(subscriptions?.data?.[0]) as unknown as Action,
      );
  }, [user._id, dispatch]);

  const getClassVacancy = useCallback(async () => {
    const response = await getClassVacancyById(subscription?.classVacancyId);
    if (response?.data) setClassVacancy(response?.data);
  }, [subscription?.classVacancyId]);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  useEffect(() => {
    if (!classVacancy && !!subscription?._id) getClassVacancy();
  }, [classVacancy, subscription?._id, getClassVacancy]);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  useEffect(() => {
    if (user?._id && !hasCandidateRole(user)) signOut();
  }, [signOut, user]);

  const itemsMenu = useMemo(() => {
    const items: {
      icon: JSX.Element;
      text: string;
      text_en: string;
      id: number;
      render?: JSX.Element;
    }[] = [
      {
        icon: <Avatar />,
        text: "Dados pessoais",
        text_en: "Personal data",
        id: 0,
        render: <Infos setIsValid={setIsValid} />,
      },
      {
        icon: <AddressIcon />,
        text: "Endereço",
        text_en: "Address",
        id: 1,
        render: (
          <FormAddress
            data={data}
            setData={setData}
            setIsValid={setIsValid}
            showLabel
          />
        ),
      },
      {
        icon: <ReprogramaIcon />,
        text: "Você e a {reprograma}",
        text_en: "You and {reprograma}",
        id: 2,
        render: <Reprograma setIsValid={setIsValid} />,
      },
      {
        icon: <EducationIcon />,
        text: "Educação",
        text_en: "Education",
        id: 3,
        render: <Education setIsValid={setIsValid} />,
      },
      {
        icon: <Newfinance />,
        text: "Perfil socioeconômico",
        text_en: "Socioeconomic profile",
        id: 4,
        render: <SocioEconomicProfile setIsValid={setIsValid} />,
      },
    ];

    if (
      classVacancy?.template === classVacancyTemplate.TET ||
      classVacancy?.template === classVacancyTemplate.LEVEL_ONE
    )
      items.push({
        icon: <Newfinance />,
        text: "Critério Brasil",
        text_en: "Socioeconomic assessment",
        id: 5,
        render: <SocioEconomicAssessment setIsValid={setIsValid} />,
      });

    if (classVacancy?.template === classVacancyTemplate.EDV)
      items.push({
        icon: <CertificateIcon />,
        text: "Comprovante",
        text_en: "Certificate",
        id: 5,
      });

    return items;
  }, [classVacancy?.template, data]);

  const onNext = useCallback(() => {
    const nextPage = stepRegister.page + 1;
    const isLastPage = nextPage === itemsMenu?.length;

    const menuItem = itemsMenu.find((item) => item.id === nextPage);
    if (menuItem && menuItem.id > stepRegister.maxPageVisited)
      dispatch(
        setStepRegister({ maxPageVisited: menuItem.id }) as unknown as Action,
      );

    const indexMenu = itemsMenu.findIndex((item) => item.id === nextPage);
    if (
      indexMenu !== -1 &&
      menuItem &&
      menuItem.id >= stepRegister.maxPageVisited
    )
      dispatch(
        setStepRegister({ maxMenuVisited: indexMenu }) as unknown as Action,
      );

    if (nextButton !== null) {
      const funcNextButton = () => nextButton();
      funcNextButton();
      dispatch(setNextButton(null) as unknown as Action);
    }

    createCourseSubscriptionLog(
      user._id,
      isLastPage
        ? CourseSubscriptionAction.COURSE_SUBSCRIPTION_FORM_DONE
        : CourseSubscriptionAction.COURSE_SUBSCRIPTION_NEXT_STEP,
      formatLogState(stepRegister.page, itemsMenu[stepRegister.page]?.text_en),
      isLastPage
        ? CourseSubscriptionState.FORM_DONE
        : formatLogState(nextPage, itemsMenu?.[nextPage]?.text_en),
    );

    dispatch(setStepRegister({ page: nextPage }) as unknown as Action);
  }, [
    dispatch,
    itemsMenu,
    nextButton,
    stepRegister.maxPageVisited,
    stepRegister.page,
    user._id,
  ]);

  const onBack = useCallback(() => {
    const previousPage = stepRegister.page - 1;
    const isLastPage = stepRegister.page === itemsMenu?.length;

    const currentState =
      stepRegister.page === 0
        ? CourseSubscriptionState.LOGGED_IN
        : formatLogState(previousPage, itemsMenu[previousPage]?.text_en);

    dispatch(setStepRegister({ page: previousPage }) as unknown as Action);
    createCourseSubscriptionLog(
      user._id,
      isLastPage
        ? CourseSubscriptionAction.COURSE_SUBSCRIPTION_FORM_REVIEW
        : CourseSubscriptionAction.COURSE_SUBSCRIPTION_PREVIOUS_STEP,
      isLastPage
        ? CourseSubscriptionState.FORM_DONE
        : formatLogState(
            stepRegister.page,
            itemsMenu?.[stepRegister.page]?.text_en,
          ),
      currentState,
    );
  }, [dispatch, itemsMenu, stepRegister.page, user._id]);

  const onMenuClick = useCallback(
    (selectedStep) =>
      createCourseSubscriptionLog(
        user._id,
        selectedStep > stepRegister.page
          ? CourseSubscriptionAction.COURSE_SUBSCRIPTION_NEXT_STEP
          : CourseSubscriptionAction.COURSE_SUBSCRIPTION_PREVIOUS_STEP,
        formatLogState(
          stepRegister.page,
          itemsMenu[stepRegister.page]?.text_en,
        ),
        formatLogState(selectedStep, itemsMenu[selectedStep]?.text_en),
      ),
    [itemsMenu, stepRegister.page, user._id],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout
      header
      back={stepRegister.page > 0}
      onClickBack={onBack}
      className="padding-bottom-40 grid-responsive course course-workshop"
    >
      {width > 990 && stepRegister.page < itemsMenu.length && (
        <div className="grid-row-1 grid-column-2-5 step-course-mobile">
          <div className="container-step-register">
            <CardSteps
              itemsMenu={itemsMenu}
              onClick={onMenuClick}
              className="card-steps-register gap"
            />
            <Progress
              className=" margin-top-32"
              value={Math.round(
                (stepRegister.page / (itemsMenu?.length ?? 1)) * 100,
              )}
            />
          </div>
        </div>
      )}
      {width < 991 && stepRegister.page < itemsMenu.length && (
        <div className="grid-column-1-9 step-and-progress-mobile margin-step-register">
          <div>
            <Progress
              className=" margin-bottom-progress"
              value={Math.round(
                (stepRegister.page / (itemsMenu?.length ?? 1)) * 100,
              )}
            />
            <CardSteps
              itemsMenu={itemsMenu}
              className="card-steps-register step-MuiPaper-root"
              orientation="horizontal"
            />
          </div>
        </div>
      )}
      {stepRegister.page < itemsMenu.length &&
        !!itemsMenu[stepRegister.page]?.render && (
          <>
            <CardRegister
              className="margin-top-16 margin-mobile-register border-card grid-column-5-12 grid-column-1-11-mobile"
              titleHeader={itemsMenu[stepRegister.page].text}
              showBorder
            >
              {itemsMenu[stepRegister.page]?.render}
            </CardRegister>
            <div className="margin-button grid-column-10-12 grid-column-1-11-mobile">
              <Button
                text={
                  stepRegister.page === itemsMenu.length - 1
                    ? "Finalizar"
                    : "Avançar"
                }
                className={
                  stepRegister.page === itemsMenu.length - 1
                    ? "margin-bottom-24 width-100"
                    : "margin-bottom-24 width-100"
                }
                appearance={
                  stepRegister.page !== itemsMenu.length - 1
                    ? "primary"
                    : "secondary"
                }
                onClick={onNext}
                disabled={!isValid}
              />
            </div>
          </>
        )}
      {stepRegister.page < itemsMenu.length &&
        !itemsMenu[stepRegister.page]?.render && (
          <Certificates setIsValid={setIsValid} onNext={onNext} />
        )}
      {stepRegister.page === itemsMenu.length && <Completed />}
    </Layout>
  );
};

export default memo(CourseRegister);
