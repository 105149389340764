import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const InstagramProfile = ({
  widthIcon,
  heightIcon,
  className,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    className={className}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="url(#paint0_linear)"
      d="M18.244 3.5H9.756C6.306 3.5 3.5 6.306 3.5 9.756v8.488c0 3.45 2.806 6.256 6.256 6.256h8.488c3.45 0 6.256-2.806 6.256-6.256V9.756c0-3.45-2.806-6.256-6.256-6.256zm4.143 14.744c0 2.288-1.855 4.143-4.143 4.143H9.756c-2.288 0-4.143-1.855-4.143-4.143V9.756c0-2.288 1.855-4.143 4.143-4.143h8.488c2.288 0 4.143 1.855 4.143 4.143v8.488z"
    />
    <path
      fill="url(#paint1_linear)"
      d="M14 8.569c-2.995 0-5.432 2.436-5.432 5.43 0 2.996 2.437 5.432 5.432 5.432 2.995 0 5.431-2.436 5.431-5.431S16.995 8.569 14 8.569zm0 8.75c-1.833 0-3.319-1.486-3.319-3.32 0-1.832 1.486-3.318 3.319-3.318s3.319 1.486 3.319 3.319-1.486 3.319-3.32 3.319z"
    />
    <path
      fill="url(#paint2_linear)"
      d="M19.442 9.91c.719 0 1.302-.582 1.302-1.3 0-.72-.583-1.302-1.302-1.302-.719 0-1.301.583-1.301 1.301 0 .72.582 1.302 1.301 1.302z"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="14"
        x2="14"
        y1="24.439"
        y2="3.663"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E09B3D" />
        <stop offset=".3" stopColor="#C74C4D" />
        <stop offset=".6" stopColor="#C21975" />
        <stop offset="1" stopColor="#7024C4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="14"
        x2="14"
        y1="24.439"
        y2="3.663"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E09B3D" />
        <stop offset=".3" stopColor="#C74C4D" />
        <stop offset=".6" stopColor="#C21975" />
        <stop offset="1" stopColor="#7024C4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="19.442"
        x2="19.442"
        y1="24.439"
        y2="3.663"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E09B3D" />
        <stop offset=".3" stopColor="#C74C4D" />
        <stop offset=".6" stopColor="#C21975" />
        <stop offset="1" stopColor="#7024C4" />
      </linearGradient>
    </defs>
  </svg>
);

export default InstagramProfile;
