import moment from "moment";
import React, { memo } from "react";
import { getTextDiffDate } from "../../utils";
import "./styles.scss";

interface Props {
  className?: string;
  datetime: string | Date;
}

const JobDatetime = ({ className = "", datetime }: Props): JSX.Element => (
  <p className={`job-inf-text job-inf-date ${className}`}>
    {moment(datetime).isValid() && getTextDiffDate(datetime)}
  </p>
);

export default memo(JobDatetime);
