import React, {
  Dispatch,
  memo,
  SetStateAction,
  useMemo,
  useState,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import {
  Button,
  Card,
  CardItems,
  IconButton,
  Input,
  Select,
  TextArea,
  InputAutocomplete,
} from "../../../components";
import {
  ClassVacancy,
  ClassVacancyCustomQuestion,
} from "../../../types/interfaces";
import { CustomQuestionType } from "../../../types/constants/Course";
import { Add, Info } from "../../../../assets/icons";
import { classVacancyCustomQuestionType } from "../../../types/enumerators";

interface Props {
  classVacancy: ClassVacancy;
  setClassVacancy: Dispatch<SetStateAction<ClassVacancy>>;
  onSave: () => void;
  finalProgress: Dispatch<SetStateAction<number>>;
}

const CustomQuestions = ({
  classVacancy,
  setClassVacancy,
  onSave,
  finalProgress,
}: Props): JSX.Element => {
  const [addCustomQuestion, setAddCustomQuestion] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [customQuestion, setCustomQuestion] =
    useState<ClassVacancyCustomQuestion>({
      code: "",
      order: classVacancy?.customQuestions?.length ?? 0,
      binding: true || false,
      question: "",
      options: [],
    });

  const history = useHistory();

  const exitsCustomQuestions = useMemo(
    () => classVacancy?.customQuestions?.length,
    [classVacancy?.customQuestions?.length],
  );

  const handleChange = useCallback(
    (e, props: "type" | "question" | "code") =>
      setCustomQuestion({ ...customQuestion, [props]: e.target.value }),
    [customQuestion],
  );

  const handleChangeValue = useCallback(
    (value, prop: "binding" | "options") =>
      setCustomQuestion({ ...customQuestion, [prop]: value }),
    [customQuestion],
  );

  const onEdit = useCallback((item, index: number) => {
    setEditIndex(index);
    setAddCustomQuestion(true);
    setCustomQuestion(item);
  }, []);

  const onRemove = useCallback(
    (index: number) => {
      let oldCustomQuestion = classVacancy?.customQuestions;
      oldCustomQuestion?.splice(index, 1);
      oldCustomQuestion = oldCustomQuestion?.map((item, i: number) => ({
        ...item,
        order: i,
      }));
      setClassVacancy({ ...classVacancy, customQuestions: oldCustomQuestion });
    },
    [classVacancy, setClassVacancy],
  );

  const reorderItems = useCallback(
    (items: ClassVacancyCustomQuestion[]) => {
      let { customQuestions: newCustomQuestions } = classVacancy;
      newCustomQuestions = items.map((item, index) => ({
        ...item,
        order: index,
      }));
      setClassVacancy({ ...classVacancy, customQuestions: newCustomQuestions });
    },
    [classVacancy, setClassVacancy],
  );

  const onSaveClassVacancy = useCallback(() => {
    onSave();
    history.push("/admin-class-vacancy");
  }, [history, onSave]);

  const onSaveCustomQuestion = useCallback(() => {
    if (
      customQuestion.options?.length === 0 ||
      customQuestion.type === classVacancyCustomQuestionType.TEXT ||
      customQuestion.type === classVacancyCustomQuestionType.RADIO
    )
      delete customQuestion.options;

    let { customQuestions: newCustomQuestions } = classVacancy;
    if (newCustomQuestions?.length) {
      if (editIndex !== -1) newCustomQuestions[editIndex] = customQuestion;
      else newCustomQuestions.push(customQuestion);
    } else newCustomQuestions = [customQuestion];

    setClassVacancy({ ...classVacancy, customQuestions: newCustomQuestions });
    setAddCustomQuestion(false);
    setEditIndex(-1);
    finalProgress(100);
  }, [customQuestion, classVacancy, setClassVacancy, finalProgress, editIndex]);

  const onCancel = useCallback(() => {
    setAddCustomQuestion(false);
    setEditIndex(-1);
  }, []);

  return (
    <>
      <div className="grid-column-5-12 media-card-vacncies grid-row-2">
        <Card
          titleHeader="Questões personalizadas"
          className="grid-column-1-8 padding-0 border-card"
        >
          {!addCustomQuestion ? (
            <IconButton
              icon={<Add />}
              text="Adicionar Questão"
              onClick={() => {
                setAddCustomQuestion(true);
                setCustomQuestion({
                  code: "",
                  order: classVacancy?.customQuestions?.length ?? 0,
                  question: "",
                  binding: true || false,
                  options: [],
                });
              }}
              className="icon-button-primary"
            />
          ) : (
            <div className="grid-2-column">
              <Select
                label="Tipo*"
                options={Object.keys(CustomQuestionType).map((key) => ({
                  value: key,
                  label:
                    CustomQuestionType[key as keyof typeof CustomQuestionType],
                }))}
                value={customQuestion?.type ?? null}
                onChange={(e) => handleChange(e, "type")}
                className="grid-row-1 grid-column-1-2"
              />
              <div className="grid-row-1 grid-column-2-2 flex-code-input">
                <Input
                  id="code"
                  label="Código*"
                  type="text"
                  value={customQuestion?.code ?? ""}
                  onChange={(e) => handleChange(e, "code")}
                />
                <div className="flex-row-center">
                  <Tooltip
                    className="tooltip"
                    arrow
                    title={<p>Ex: canParticipateOnWorkshop</p>}
                    placement="top"
                  >
                    <div>
                      <Info className="icon-info pointer" />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <TextArea
                id="question"
                label="Pergunta*"
                className="grid-row-2 grid-column-1-3 description-input"
                maxLength={1000}
                value={customQuestion?.question ?? ""}
                onChange={(e) => handleChange(e, "question")}
                disabled={!customQuestion?.code || !customQuestion?.type}
              />
              {customQuestion?.type === classVacancyCustomQuestionType?.TEXT ||
              customQuestion?.type ===
                classVacancyCustomQuestionType?.RADIO ? null : (
                <InputAutocomplete
                  options={[""]}
                  multiple
                  helperText="Pressione Enter para adicionar"
                  getOptionLabel={(option) => option}
                  value={customQuestion?.options ?? null}
                  onChange={(value) => handleChangeValue(value, "options")}
                  label="Opções de Resposta*"
                  className={`options
                    ${
                      customQuestion?.type
                        ? "is-enable grid-row-3 grid-column-1-3"
                        : "is-disabled"
                    }`}
                  disabled={!customQuestion?.question}
                />
              )}
              <FormControlLabel
                control={
                  <Switch
                    checked={customQuestion?.binding}
                    onChange={() =>
                      handleChangeValue(
                        !customQuestion?.code || !customQuestion?.binding,
                        "binding",
                      )
                    }
                    title="Binding"
                    value={customQuestion?.binding ? "true" : "false"}
                  />
                }
                className={`${
                  customQuestion?.type ===
                    classVacancyCustomQuestionType?.TEXT ||
                  customQuestion?.type === classVacancyCustomQuestionType?.RADIO
                    ? "grid-row-3"
                    : "grid-row-4"
                } grid-column-1-2 admin-class-edit-questions`}
                label="Obrigatória"
                disabled={!customQuestion?.type}
              />
            </div>
          )}
        </Card>
      </div>
      {exitsCustomQuestions && (
        <div
          className={`grid-column-5-12 ${
            addCustomQuestion ? "grid-row-4" : "grid-row-3"
          }`}
        >
          <CardItems
            isMyProfile
            fullDate
            notSort
            showDivider={false}
            items={classVacancy?.customQuestions}
            onEdit={(item, index) => onEdit(item, index)}
            onRemove={(_, index) => onRemove(index)}
            onMove={reorderItems}
            title="code"
            subTitle="type"
            description="question"
            className="card-items padding-32"
          />
        </div>
      )}
      <Button
        text="Cancelar"
        className={`${
          addCustomQuestion
            ? "grid-row-3"
            : exitsCustomQuestions
            ? "grid-row-4"
            : "grid-row-3"
        } grid-column-8-10 margin-bottom-32`}
        onClick={() => {
          if (addCustomQuestion) onCancel();
          else history.push("/admin-class-vacancy");
        }}
        outline
        appearance="secondary"
      />
      <Button
        text="Salvar"
        className={`${
          addCustomQuestion
            ? "grid-row-3"
            : exitsCustomQuestions
            ? "grid-row-4"
            : "grid-row-3"
        } grid-column-10-12 margin-bottom-32`}
        onClick={() => {
          if (addCustomQuestion) onSaveCustomQuestion();
          else onSaveClassVacancy();
        }}
      />
    </>
  );
};

export default memo(CustomQuestions);
