/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { Button, Modal, TextArea } from "../../../components";
import "./styles.scss";

interface Props {
  company: any;
  openModal: boolean;
  onClose: () => void;
  setCompany: Dispatch<SetStateAction<any>>;
}

const Description = ({
  company,
  openModal,
  onClose,
  setCompany,
}: Props): JSX.Element => {
  const [_company, _setCompany] = useState(company);
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      _setCompany({
        ..._company,
        profile: {
          ..._company?.profile,
          description: e.target.value,
        },
      }),
    [_company],
  );

  return (
    <Modal
      headerAction
      textHeader="Sobre"
      open={openModal}
      onClose={onClose}
      bodyClassName="modal-profile"
    >
      <TextArea
        id="description"
        label="Descrição"
        maxLength={400}
        value={_company?.profile?.description ?? ""}
        onChange={handleChange}
        className="description-input"
      />
      <div className="flex-row-center margin-top-0-mobile margin-top-48 modal-profile-buttons space-between flex-col-mobile gap-16-mobile">
        <Button
          text="Cancelar"
          className="width-200 width-100-mobile"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button
          text="Salvar"
          onClick={() => {
            onClose();
            setCompany(_company);
          }}
          className="width-200 width-100-mobile"
        />
      </div>
    </Modal>
  );
};

export default memo(Description);
