/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Add } from "../../../../assets/customIcons";
import ButtonMenu from "../../../components/ButtonMenu";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateUser } from "../../../store/actions/userActions";
import { mediaType, userPortfolioType } from "../../../types/enumerators";
import { UserCurriculumPortfolio } from "../../../types/interfaces";
import { ConfirmRemove, Items } from "../../Profile/Modal";
import { RootState } from "../../../store/reducers";
import { ViewPortfolio } from "../../../components";

const EditPortfolio = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [openModal, setOpenModal] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [removed, setRemoved] = useState({
    title: "",
    text: "",
    onRemove: () => setOpenModalRemove(false),
  });
  const [indexItem, setIndexItem] = useState(-1);
  const [portfolio, setPortfolio] = useState<UserCurriculumPortfolio>({
    title: "",
    url: "",
    description: "",
    medias: [],
  });
  const user = useSelector((state: RootState) => state.userState);
  const [_user, _setUser] = useState(user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.profile?.name && !_user?.profile?.name) _setUser({ ...user });
  }, [_user, user]);

  useEffect(() => {
    dispatch(
      setNextButton(() =>
        dispatch(
          updateUser({
            ..._user,
            curriculum: { ..._user?.curriculum },
          }) as unknown as Action,
        ),
      ) as unknown as Action,
    );
  }, [_user, _user?.curriculum, dispatch]);

  const reorderItem = useCallback(
    (items: any) => {
      const { curriculum } = _user;
      if (curriculum) {
        let { portfolios: newPortolios } = curriculum;
        newPortolios = items?.map((item: any, index: number) => ({
          ...item,
          order: index,
        }));
        _setUser({
          ..._user,
          curriculum: { ...curriculum, portfolios: newPortolios },
        });
        dispatch(
          setNextButton(() =>
            dispatch(
              updateUser({
                ..._user,
                curriculum: { ...curriculum, portfolios: newPortolios },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      }
    },
    [_user, dispatch],
  );

  const onRemoveItem = useCallback(
    (index: number) => {
      let newItems = _user?.curriculum?.portfolios;
      newItems?.splice(index, 1);
      newItems = newItems?.map((innerItem: any, i: number) => ({
        ...innerItem,
        order: i,
      }));

      _setUser({
        ..._user,
        curriculum: { ..._user?.curriculum, portfolios: newItems },
      });
    },
    [_user],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <ButtonMenu
        icon={
          <Add
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        }
        text="Adicionar portfólio"
        items={[
          {
            text: "Link",
            onClick: () => {
              setOpenModal(true);
              setPortfolio({ ...portfolio, type: mediaType.LINK });
            },
          },
          {
            text: "Projeto",
            onClick: () => {
              setOpenModal(true);
              setPortfolio({ ...portfolio, type: userPortfolioType.PROJECT });
            },
          },
        ]}
        className={`icon-button-primary width-100-mobile ${
          _user?.curriculum?.portfolios?.length > 0 ? "margin-bottom-40" : ""
        } `}
      />
      {_user?.curriculum?.portfolios?.length > 0 ? (
        <div>
          {_user?.curriculum?.portfolios?.length > 1 && (
            <p className="paragraph-reorder">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <ViewPortfolio
            isMyProfile
            items={_user?.curriculum?.portfolios}
            onEdit={(_, index) => {
              setIndexItem(index);
              setOpenModal(true);
            }}
            onMove={(items) => reorderItem(items)}
            onRemove={(_, index) => {
              setRemoved({
                title: "Excluir Portfolio",
                text: "Tem certeza de que deseja excluir este Portfolio?",
                onRemove: () => onRemoveItem(index),
              });
              setOpenModalRemove(true);
            }}
          />
        </div>
      ) : (
        <p className="paragraph-not-registered">
          Não há portfolios cadastrados
        </p>
      )}
      <Items
        setUser={_setUser}
        index={indexItem}
        type="portfolios"
        typePortfolio={portfolio.type}
        openModal={openModal}
        onClose={() => {
          setOpenModal(false);
          setIndexItem(-1);
        }}
      />
      <ConfirmRemove
        titleHeader={removed?.title}
        text={removed?.text}
        onClose={() => setOpenModalRemove(false)}
        onRemove={removed?.onRemove}
        openModal={openModalRemove}
      />
    </div>
  );
};

export default memo(EditPortfolio);
