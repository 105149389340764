/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEventHandler, memo, useEffect, useState } from "react";
import { IconButton, Modal } from "..";
import { Info, Upload } from "../../../assets/icons";
import { Add } from "../../../assets/customIcons";
import "./styles.scss";

interface Props {
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  type?: string;
  file?: boolean;
  workshopFile?: boolean;
}

const AttachmentMedia = ({
  className,
  onChange,
  disabled,
  type,
  file,
  workshopFile,
}: Props): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        className={`flex items-center media margin-top-20-mobile ${className}`}
      >
        <div className="flex items-center media-content">
          <IconButton
            icon={
              file ? (
                <Upload />
              ) : (
                <Add
                  widthIcon={width < 991 ? 20 : 24}
                  heightIcon={width < 991 ? 20 : 24}
                />
              )
            }
          />
          <input
            id="addFile"
            accept={
              type ??
              "image/*, application/pdf, application/vnd.ms-powerpoint, application/msword"
            }
            className="media-input"
            type="file"
            multiple={false}
            onChange={onChange}
            disabled={disabled}
          />
          <label htmlFor="addFile" className="media-label">
            {file
              ? "Enviar arquivo"
              : workshopFile
              ? "Adcionar arquivo"
              : "Adicionar mídia"}
          </label>
        </div>
        <button
          aria-label="info"
          type="button"
          className="media-button"
          onClick={() => setOpenModal(true)}
        >
          <Info className="icon-info" />
        </button>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        headerAction
        textHeader="Tipos de arquivos de mídia aceitos"
      >
        <div>
          <p className="dark-grey-blue">
            Você pode melhorar sua experiência na plataforma adicionando e
            compartilhando amostras de mídia.
          </p>
          <p className="bold dark-grey-blue margin-top-0">
            Os seguintes formatos de arquivo de amostras de mídia são aceitos:
          </p>
          <p className="dark-grey-blue">
            Adobe PDF (.pdf)
            <br />
            Microsoft PowerPoint (.ppt/.pptx)
            <br />
            Microsoft Word (.doc/.docx)
            <br />
            .jpg
            <br />
            .jpeg
            <br />
            .png
            <br />
            .gif – não compatível com fotos de perfil ou fundo.
            <br />
          </p>
          <p className="bold dark-grey-blue">Importante:</p>
          <p className="dark-grey-blue">
            O tamanho do arquivo não pode exceder 100 MB.
            <br />
            O número máximo de páginas é 300.
            <br />
            A contagem máxima de palavras é de um milhão de palavras.
            <br />A resolução máxima para imagens é de 36 mega pixels.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default memo(AttachmentMedia);
