/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Button, Card } from "..";
import { AvatarEmpty } from "../../../assets/customIcons";
import { addCompanyToUser, getUserById } from "../../services/functions";
import { User } from "../../types/interfaces";
import "./styles.scss";

interface Props {
  users: User[];
  row: string;
  hasButton?: boolean;
}

interface UserProps {
  id: string;
  name: string;
  photo: string;
  place: string;
  position?: string;
}

const UsersProfile = ({ users, row, hasButton = true }: Props): JSX.Element => {
  const history = useHistory();
  const [data, setData] = useState<UserProps[]>([]);
  const [loading, setLoading] = useState(true);

  const getUser = useCallback(async () => {
    setLoading(true);
    const listUser: any[] = [];
    const getUserPromises = [];
    const addCompanyToUserPromises = [];

    // put all the promises into an array to solve them and get the desired values
    for (let i = 0; i < users?.length; i += 1) {
      getUserPromises.push(getUserById(users[i]?._id ?? ""));
    }

    // solves the first functions in 'parallel'
    const solvedGetUserPromises = await Promise.all(getUserPromises);

    for (let i = 0; i < users?.length; i += 1)
      addCompanyToUserPromises.push(addCompanyToUser(solvedGetUserPromises[i]));

    // solves the second group of functions in 'parallel'
    const solvedAddCompanyToUserPromises = await Promise.all(
      addCompanyToUserPromises,
    );

    // iterate by the results and do the old function
    solvedAddCompanyToUserPromises.forEach((item: any) => {
      const response = item;
      if (response) {
        const user: UserProps = {
          id: response?._id,
          name: response?.profile?.name ?? "",
          photo: response?.profile?.photo ?? "",
          place: `${response?.profile?.address?.city ?? ""} - ${
            response?.profile?.address?.province ?? ""
          }`,
          position:
            response?.curriculum?.works &&
            !response?.curriculum?.works?.[
              (response?.curriculum?.works?.length ?? 0) - 1
            ]?.endDate
              ? response?.curriculum?.works?.[
                  (response?.curriculum?.works?.length ?? 0) - 1
                ]?.position
              : "",
        };
        listUser.push(user);
      }
    });

    setLoading(false);
    setData(listUser);
  }, [users]);

  useEffect(() => {
    if (users) getUser();
  }, [getUser, users]);

  return (
    <Card
      className={`grid-column-10-13 vacancies-profile padding-0 jobs-profile grid-row-${row}`}
      titleHeader="Pessoas para sua empresa"
      headerClassName="card-peoples-company padding-24"
    >
      {loading ? (
        <div className="flex align-items justify-center">
          <CircularProgress />
        </div>
      ) : data && data?.length > 0 ? (
        <div className="width-100">
          {data &&
            data?.map((item, index) => (
              <div key={Math.random()}>
                <div
                  role="button"
                  tabIndex={0}
                  onClick={() => history.push(`/profile/${item?.id}`)}
                  className="flex"
                >
                  {item?.photo ? (
                    <img
                      src={item?.photo}
                      alt="Foto"
                      className="img-photo-users-profile"
                    />
                  ) : (
                    <AvatarEmpty
                      className="avatar-empty-users-profile"
                      heightIcon={48}
                      widthIcon={48}
                    />
                  )}
                  <div className="margin-left-16">
                    <h4 className="h4-item-users-profile">{item?.name}</h4>

                    {item?.position && (
                      <h5 className="h5-item-users-profile">
                        {item?.position}
                      </h5>
                    )}

                    <p className="p-item-users-profile">{item?.place}</p>
                  </div>
                </div>
                <div className="flex justify-center">
                  <Button
                    text="Ver mais"
                    outline
                    className="button-view-more"
                    appearance="secondary"
                    onClick={() => history.push(`/profile/${item?.id}`)}
                  />
                </div>
                {index < (data?.length ?? 0) - 1 && (
                  <div className="div-border-bottom-users-profile" />
                )}
              </div>
            ))}
        </div>
      ) : (
        <h5 className="vacancies-profile-company padding-bottom-8">
          Não há sugestões de candidatas no momento
        </h5>
      )}
      {hasButton && (
        <div>
          <div className="div-card" />
          <div className="grid-column-10-13 div-h1-users-profile" />
          <h1
            className="h1-data-users-profile find-peoples"
            onClick={() => history.push("/company-search")}
          >
            {data?.length > 0 ? "BUSCAR MAIS PESSOAS" : "BUSCAR PESSOAS"}
          </h1>
        </div>
      )}
    </Card>
  );
};

export default memo(UsersProfile);
