import React, { useMemo, useCallback } from "react";
import { TalentAcademyProps } from "./types";
import { classVacancyType } from "../../../types/enumerators";
import "../style.scss";

const TalentAcademy = ({
  subscription,
  course,
}: TalentAcademyProps): JSX.Element => {
  const stageTalentAcademy = useMemo(() => {
    if (subscription && course) {
      const workshop = course?.stages?.find(
        (item) => item?.type === classVacancyType.TALENT_ACADEMY,
      );

      const findUserTalent = subscription?.stages?.find(
        ({ stageId }) => stageId === workshop?.stage_id,
      );

      if (findUserTalent) return findUserTalent;
    }

    return null;
  }, [subscription, course]);

  const grade = useCallback(
    (value: string) =>
      stageTalentAcademy?.grade?.find(({ type }) => type === value),
    [stageTalentAcademy?.grade],
  );

  const grades = useMemo(
    () => ({
      final: (grade("FINAL")?.value ?? 0) * 10 ?? 0,
      empathy: grade("EMPATHY")?.value ?? 0,
      resilience: grade("RESILIENCE")?.value ?? 0,
      flexibility: grade("FLEXIBILITY")?.value ?? 0,
      collaboration: grade("COLLABORATION")?.value ?? 0,
      selfDevelopment: grade("SELFDEVELOPMENT")?.value ?? 0,
      selfDiscipline: grade("SELFDISCIPLINE")?.value ?? 0,
    }),
    [grade],
  );

  const fieldsTalent = useMemo(
    () => [
      {
        title: "Mente aberta",
        fields: [
          {
            value: "selfDevelopment",
            label: "Autodesenvolvimento",
            response: grades?.selfDevelopment ?? 0,
          },
          {
            value: "flexibility",
            label: "Flexibilidade",
            response: grades?.flexibility ?? 0,
          },
        ],
      },
      {
        title: "Influência positiva",
        fields: [
          {
            value: "collaboration",
            label: "Colaboração",
            response: grades?.collaboration ?? 0,
          },
          {
            value: "empathy",
            label: "Empatia",
            response: grades?.empathy ?? 0,
          },
        ],
      },
      {
        title: "Autogestão",
        fields: [
          {
            value: "selfDiscipline",
            label: "Autodisciplina",
            response: grades?.selfDiscipline ?? 0,
          },
          {
            value: "resilience",
            label: "Resiliência",
            response: grades?.resilience ?? 0,
          },
        ],
      },
    ],
    [grades],
  );

  return (
    <div className="subscriptions-talent">
      <div className="subscriptions-talent-content">
        {stageTalentAcademy?.grade ? (
          <div className="subscriptions-talent-view">
            {fieldsTalent.map(({ title, fields }) => (
              <div className="subscriptions-talent-view-item">
                <div className="subscriptions-talent-view-title">
                  <h2>{title}</h2>
                </div>

                <div className="subscriptions-talent-view-grades">
                  {fields.map(({ label, response }) => (
                    <div className="subscriptions-talent-view-grade">
                      <span>{label}</span>
                      <h4>{response}</h4>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="subscriptions-talent-view-item">
              <div className="subscriptions-talent-view-title">
                <h2>Nota final</h2>
              </div>

              <div className="subscriptions-talent-view-grades">
                <div className="subscriptions-talent-view-grade">
                  <span>Nota</span>
                  <h4>{grades?.final}</h4>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>Não há testes cadastrados.</p>
        )}
      </div>
    </div>
  );
};

export default TalentAcademy;
