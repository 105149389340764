/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  subscriptInClassVacancy,
  updateClassVacancyInSubscription,
  updateClassVacancySubscription,
} from "../../services/functions";
import {
  SET_COURSE_SUBSCRIPTION,
  CREATE_COURSE_SUBSCRIPTION,
  UPDATE_COURSE_SUBSCRIPTION,
  UPDATE_CLASS_VACANCY_IN_COURSE_SUBSCRIPTION,
  CLEAR_COURSE_SUBSCRIPTION,
} from "../types/courseTypes";

export const clearCourseSubscription = () => (dispatch: any) => {
  dispatch({
    type: CLEAR_COURSE_SUBSCRIPTION,
  });
};

export const setCourseSubscription = (data: any) => (dispatch: any) => {
  dispatch({
    type: SET_COURSE_SUBSCRIPTION,
    payload: data,
  });
};

export const createCourseSubscription =
  (data: any) => async (dispatch: any) => {
    dispatch({
      type: CREATE_COURSE_SUBSCRIPTION,
      payload: data,
    });
    try {
      subscriptInClassVacancy(data.classVacancyId, data.userId, data.form);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };

export const updateCourseSubscription =
  (data: any) => async (dispatch: any) => {
    dispatch({
      type: UPDATE_COURSE_SUBSCRIPTION,
      payload: data,
    });
    try {
      const newSubscription = JSON.parse(JSON.stringify({ ...data }));
      if (newSubscription?.form?.isLGBTQIA === "null")
        newSubscription.form.isLGBTQIA = null;

      await updateClassVacancySubscription(
        newSubscription._id,
        newSubscription.form,
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };

export const updateClassVacancyInCourseSubscription =
  (data: any) => async (dispatch: any) => {
    dispatch({
      type: UPDATE_CLASS_VACANCY_IN_COURSE_SUBSCRIPTION,
      payload: data,
    });
    try {
      updateClassVacancyInSubscription(data._id, data.classVacancyId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn(error);
    }
  };
