/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import moment from "moment";
import {
  filterVacancy,
  getAllPlacementVacancyWithCorporateData,
  getPages,
  getVacancyFilters,
  searchCompany,
  searchPlacementVacancy,
} from "../../services/functions";
import {
  Layout,
  HeaderProfile,
  CardVacanciesAdvanced,
  ButtonFilter,
  Card,
  Pagination,
  ModalConfigAndFilters,
} from "../../components";
import CardDescription from "./CardDescription";
import "./style.scss";
import { RootState } from "../../store/reducers";
import { setLoad } from "../../store/actions/configurationsActions";

interface FilterVacancy {
  companyId?: string[];
  place?: string[];
  funct?: string[];
  atDate?: string[];
  key?: string[];
  managedBy?: string[];
}

interface VacanciesAdvancedMobileProps {
  items?: any;
  vacancies?: any;
  vacancieSelected?: any;
  selectedDate?: any;
  filterVacancyList?: any;
  idSelected?: any;
  applyFilters?: any;
  tempFilters?: any;
  setTempFilters?: any;
  isDate?: any;
  setIsDate?: any;
  isCompanyId?: any;
  setIsCompanyId?: any;
  isPlace?: any;
  setIsPlace?: any;
  isFunct?: any;
  setIsFunct?: any;
  isManagedBy?: any;
  setIsManagedBy?: any;
  handleChangeFilters: any;
  handleChangeDate: any;
  handleClickOpenModal: any;
  handleClick: any;
  handleCloseFilter: any;
  searchVacanciesWithFilter: any;
  onClear: any;
  page?: any;
  setPage?: any;
  maxPage?: any;
}

const RenderVacanciesAdvancedMobile = ({
  items,
  vacancies,
  vacancieSelected,
  selectedDate,
  filterVacancyList,
  idSelected,
  applyFilters,
  tempFilters,
  setTempFilters,
  isDate,
  setIsDate,
  isCompanyId,
  setIsCompanyId,
  isPlace,
  setIsPlace,
  isFunct,
  setIsFunct,
  isManagedBy,
  setIsManagedBy,
  handleChangeFilters,
  handleChangeDate,
  handleClickOpenModal,
  handleClick,
  handleCloseFilter,
  searchVacanciesWithFilter,
  onClear,
  page,
  setPage,
  maxPage,
}: VacanciesAdvancedMobileProps): JSX.Element => {
  const [description, setDescription] = useState(false);

  const openDescription = useCallback(() => setDescription(true), []);

  return (
    <>
      <div className="grid-column-1-13 max-height-search-filters">
        {description &&
          vacancies &&
          vacancies?.length > 0 &&
          vacancieSelected && <CardDescription data={vacancieSelected} />}
        {!description && (
          <div className="scroll-filter">
            <div className="flex">
              <ButtonFilter
                className="menu-search-primary menu-search-mobile"
                text="Vagas"
                items={items}
              />
              <div className="vertical-divider-search" />
              <div className="filter-vacancy-managedBy">
                <ButtonFilter
                  className="menu-search-secondary"
                  onClick={() => handleCloseFilter("managedBy")}
                  onClose={() => {
                    setIsManagedBy(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={handleChangeFilters}
                  onSearch={searchVacanciesWithFilter}
                  onClear={() => onClear("managedBy")}
                  text="Tipo de vaga"
                  values={["Plataforma", "Curadoria"]}
                  isOpen={isManagedBy}
                  checkedList={tempFilters?.managedBy ?? []}
                  prop="managedBy"
                />
              </div>
              <div
                className={` ${
                  selectedDate && !isDate
                    ? " width-169"
                    : "filter-vacancy-startDate"
                }`}
              >
                <ButtonFilter
                  className="menu-search-secondary"
                  onClick={() => handleCloseFilter("atDate")}
                  onClose={() => {
                    setIsDate(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={handleChangeDate}
                  onSearch={searchVacanciesWithFilter}
                  onClear={() => onClear("atDate")}
                  text="Data de postagem"
                  isOpen={isDate}
                  values={[
                    "A qualquer momento",
                    "Último mês",
                    "Última semana",
                    "Últimas 24h",
                  ]}
                  checkedList={selectedDate ? [selectedDate] : []}
                  prop="atDate"
                />
              </div>
              <div
                className={`filter-vacancy-company ${
                  tempFilters?.companyId && !isCompanyId && " margin-right-16"
                }`}
              >
                <ButtonFilter
                  className="menu-search-secondary"
                  onClick={() => handleCloseFilter("companyId")}
                  onClose={() => {
                    setIsCompanyId(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={handleChangeFilters}
                  onSearch={searchVacanciesWithFilter}
                  onClear={() => onClear("companyId")}
                  text="Empresa"
                  isOpen={isCompanyId}
                  values={filterVacancyList?.companyId ?? []}
                  checkedList={tempFilters?.companyId ?? []}
                  prop="companyId"
                />
              </div>
              <div
                className={`filter-vacancy-place ${
                  tempFilters?.place && !isPlace && "margin-right-16"
                }`}
              >
                <ButtonFilter
                  className="menu-search-secondary"
                  onClick={() => handleCloseFilter("place")}
                  onClose={() => {
                    setIsPlace(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={handleChangeFilters}
                  onSearch={searchVacanciesWithFilter}
                  onClear={() => onClear("place")}
                  text="Localização"
                  isOpen={isPlace}
                  values={filterVacancyList?.place ?? []}
                  checkedList={tempFilters?.place ?? []}
                  prop="place"
                />
              </div>
              <div className="filter-vacancy-function">
                <ButtonFilter
                  className="menu-search-secondary"
                  onClick={() => handleCloseFilter("funct")}
                  onClose={() => {
                    setIsFunct(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={handleChangeFilters}
                  onSearch={searchVacanciesWithFilter}
                  onClear={() => onClear("funct")}
                  text="Função"
                  isOpen={isFunct}
                  values={filterVacancyList?.funct ?? []}
                  checkedList={tempFilters?.funct ?? []}
                  prop="funct"
                />
              </div>
              <div
                className={` ${
                  selectedDate && !isDate ? "width-190" : "filter-all"
                }`}
              >
                <ButtonFilter
                  notIcon
                  text="Todos os filtros"
                  onClick={handleClickOpenModal}
                  className="top-filters-more button-all-filters"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="grid-column-1-13 ">
        {!description && (
          <div className="card-mobile-vacancies">
            <div className="card-left-header">
              <h2 className="title-card-left padding-top-14">Vagas</h2>
              <p className="subtitle-card-left">
                {vacancies?.length}
                {vacancies && vacancies?.length > 1
                  ? " resultados"
                  : " resultado"}
              </p>
            </div>
            {vacancies &&
              vacancies?.map((item: any, index: number) => (
                <div
                  key={Math.random()}
                  className="pointer"
                  role="none"
                  onClick={() => handleClick(item._id)}
                >
                  <div
                    aria-label="description"
                    tabIndex={0}
                    role="button"
                    onClick={() => handleClick(item._id)}
                  >
                    <CardVacanciesAdvanced
                      data={item}
                      selectedId={idSelected === item._id}
                      onClick={openDescription}
                    />
                  </div>
                  {index < (vacancies?.length ?? 0) - 1 && (
                    <div className="divider-card" />
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
      <div className={`${description ? "display-none-mobile" : ""}`}>
        <Pagination
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          showAllOption
        />
      </div>
    </>
  );
};

const VacanciesAdvanced = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const location: { state: { vacancy: any; searchParams: string } } =
    useLocation();
  const history = useHistory();
  const [vacancieSelected, setVacancieSelected] = useState();
  const [vacancies, setVacancies] = useState<any[]>();
  const [idSelected, setIdSelected] = useState("");
  const [filterVacancyList, setFilterVacancyList] = useState<FilterVacancy>();
  const [isDate, setIsDate] = useState(false);
  const [isPlace, setIsPlace] = useState(false);
  const [isCompanyId, setIsCompanyId] = useState(false);
  const [isFunct, setIsFunct] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string[]>();
  const [isManagedBy, setIsManagedBy] = useState(false);
  const [applyFilters, setApplyFilters] = useState<FilterVacancy>(
    {} as FilterVacancy,
  );
  const [tempFilters, setTempFilters] = useState<FilterVacancy>(
    {} as FilterVacancy,
  );
  const [openModal, setOpenModal] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );
  const dispatch = useDispatch();

  const searchParams = useMemo(
    () => location?.state?.searchParams ?? "",
    [location?.state?.searchParams],
  );

  const loadFilterVacancyList = useCallback(async () => {
    const filterList = await getVacancyFilters();
    let newFilterVacancyList = { ...filterVacancyList };

    if (filterList) {
      filterList[0]?.company?.forEach((item: string) => {
        if (newFilterVacancyList?.companyId?.length)
          newFilterVacancyList?.companyId?.push(item);
        else
          newFilterVacancyList = {
            ...newFilterVacancyList,
            companyId: [item],
          };
      });

      filterList[0]?.place?.forEach((item: string) => {
        if (newFilterVacancyList?.place?.length)
          newFilterVacancyList?.place?.push(item);
        else
          newFilterVacancyList = {
            ...newFilterVacancyList,
            place: [item],
          };
      });

      filterList[0]?.function?.forEach((item: string) => {
        if (newFilterVacancyList?.funct?.length)
          newFilterVacancyList?.funct?.push(item);
        else
          newFilterVacancyList = {
            ...newFilterVacancyList,
            funct: [item],
          };
      });

      filterList[0]?.key?.forEach((item: string) => {
        if (newFilterVacancyList?.key?.length)
          newFilterVacancyList?.key?.push(item);
        else
          newFilterVacancyList = {
            ...newFilterVacancyList,
            key: [item],
          };
      });
    }

    setFilterVacancyList(newFilterVacancyList);
  }, [filterVacancyList]);

  useEffect(() => {
    if (!filterVacancyList) loadFilterVacancyList();
  }, [filterVacancyList, loadFilterVacancyList]);

  const optionsAllFilters = useCallback(
    (type: "place" | "companyId" | "funct" | "atDate" | "key") => {
      const result: any[] = [];

      if (type === "funct") {
        filterVacancyList?.funct?.forEach((key) => {
          result?.push({
            value: key,
            label: key,
          });
        });
      }

      if (type === "companyId") {
        filterVacancyList?.companyId?.forEach((key) => {
          result?.push({
            value: key,
            label: key,
          });
        });
      }
      if (type === "place") {
        filterVacancyList?.place?.forEach((key) => {
          result?.push({
            value: key,
            label: key,
          });
        });
      }
      if (type === "key") {
        filterVacancyList?.key?.forEach((key) => {
          result?.push({
            value: key,
            label: key,
          });
        });
      }

      if (type === "atDate") {
        result?.push(
          {
            value: "A qualquer momento",
            label: "A qualquer momento",
          },
          {
            value: "Último mês",
            label: "Último mês",
          },
          {
            value: "Última semana",
            label: "Última semana",
          },
          {
            value: "Últimas 24h",
            label: "Últimas 24h",
          },
        );
      }

      return result;
    },
    [filterVacancyList],
  );

  const handleChangeFilters = useCallback(
    (
      value: string,
      checked: boolean,
      props: "place" | "companyId" | "funct" | "atDate" | "key" | "managedBy",
    ) => {
      let newFilters = { ...tempFilters };

      if (newFilters[props] && newFilters[props]?.length) {
        if (checked) newFilters[props]?.push(value);
        else {
          const index = newFilters[props]?.findIndex(
            (item: string) => item === value,
          );
          newFilters[props]?.splice(index ?? 0, 1);
          if (newFilters[props]?.length === 0) delete newFilters[props];
        }
      } else if (checked) newFilters = { ...newFilters, [props]: [value] };

      setTempFilters(newFilters);
    },
    [tempFilters],
  );

  const handleChangeDate = useCallback((value: string, checked: boolean) => {
    if (checked) setSelectedDate([value]);
    else setSelectedDate([]);
  }, []);

  const toggleSwitch = useCallback(
    (key: "place" | "companyId" | "funct" | "atDate" | "key", value: any) => {
      if (key === "atDate") setSelectedDate([value]);
      else {
        setTempFilters((prev) => ({
          ...(prev || []),
          [key]: prev[key]?.includes(value)
            ? prev[key]?.filter((el) => el !== value)
            : [...(prev[key] || []), value],
        }));
      }
    },
    [],
  );

  const options = useMemo(
    () => [
      {
        id: "atDate",
        label: "Data de Postagem",
        type: "radio",
        value: selectedDate,
        handleChange: (value: any) => toggleSwitch("atDate", value),
        options: optionsAllFilters("atDate"),
      },
      {
        id: "key",
        label: "Habilidades",
        type: "checkbox",
        options: optionsAllFilters("key"),
        value: tempFilters?.key,
        handleChange: (value: any) => toggleSwitch("key", value),
      },
      {
        id: "companyId",
        label: "Empresa",
        type: "checkbox",
        options: optionsAllFilters("companyId"),
        value: tempFilters?.companyId,
        handleChange: (value: any) => toggleSwitch("companyId", value),
      },
      {
        id: "place",
        label: "Localização",
        type: "checkbox",
        options: optionsAllFilters("place"),
        value: tempFilters?.place,
        handleChange: (value: any) => toggleSwitch("place", value),
      },
      {
        id: "funct",
        label: "Função",
        type: "checkbox",
        options: optionsAllFilters("funct"),
        value: tempFilters?.funct,
        handleChange: (value: any) => toggleSwitch("funct", value),
      },
    ],
    [
      optionsAllFilters,
      selectedDate,
      tempFilters?.companyId,
      tempFilters?.funct,
      tempFilters?.key,
      tempFilters?.place,
      toggleSwitch,
    ],
  );

  const onCloseFilters = useCallback(() => {
    setIsPlace(false);
    setIsCompanyId(false);
    setIsFunct(false);
    setIsDate(false);
    setIsManagedBy(false);
    setSelectedDate([]);
  }, []);

  const getValueManagedBy: FilterVacancy | null = useMemo(() => {
    if (tempFilters) {
      let updatedManagedBy = tempFilters.managedBy;
      const plataformValues = ["GUPY", "GREENHOUSE", "REPROGRAMA"];
      const curationValues = ["EXTERNAL_VACANCY"];

      if (updatedManagedBy) {
        if (
          updatedManagedBy.length === 1 &&
          updatedManagedBy.includes("Curadoria")
        )
          updatedManagedBy = curationValues;
        else if (
          updatedManagedBy.length === 1 &&
          updatedManagedBy.includes("Plataforma")
        )
          updatedManagedBy = plataformValues;
        else updatedManagedBy = [...plataformValues, ...curationValues];
      }

      return {
        ...tempFilters,
        managedBy: updatedManagedBy,
      };
    }

    return null;
  }, [tempFilters]);

  const searchVacanciesWithFilter = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const newFilters = JSON.parse(JSON.stringify(getValueManagedBy));
    setApplyFilters(newFilters);

    const companyIdList: string[] = [];
    if (newFilters?.companyId) {
      for (let i = 0; i < newFilters?.companyId?.length; i += 1) {
        const companyId = await searchCompany(newFilters?.companyId[i]);
        if (companyId?.length) companyIdList.push(companyId[0]._id ?? "");
      }
      newFilters.companyId = companyIdList;
    }

    if (selectedDate && selectedDate?.length) {
      const startDate = moment();

      switch (selectedDate[0]) {
        case "A qualquer momento":
          if (newFilters?.atDate) delete newFilters.startDate;
          break;

        case "Último mês":
          newFilters.atDate = [startDate.subtract(30, "days")];
          break;

        case "Última semana":
          newFilters.atDate = [startDate.subtract(7, "days")];
          break;

        case "Últimas 24h":
          newFilters.atDate = [startDate.subtract(24, "hours")];
          break;

        default:
          if (newFilters?.atDate) delete newFilters.atDate;
          break;
      }
    } else if (newFilters?.atDate) delete newFilters.atDate;

    if (pageLimit) {
      setMaxPage(
        await getPages(
          filterVacancy,
          searchParams,
          newFilters,
          undefined,
          true,
        ),
      );
      const response = await filterVacancy(
        searchParams,
        newFilters,
        page,
        true,
      );

      setVacancies(response ?? []);
      onCloseFilters();
    }
    dispatch(setLoad(false) as unknown as Action);
  }, [
    dispatch,
    getValueManagedBy,
    selectedDate,
    pageLimit,
    searchParams,
    page,
    onCloseFilters,
  ]);

  useEffect(() => {
    if (Object.keys(applyFilters)?.length) searchVacanciesWithFilter();
    else loadVacancies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageLimit]);

  const onClear = useCallback(
    async (
      props: "place" | "companyId" | "funct" | "atDate" | "key" | "managedBy",
    ) => {
      const newFilters = { ...tempFilters };
      delete newFilters[props];

      setTempFilters(newFilters);

      setMaxPage(
        await getPages(
          filterVacancy,
          searchParams,
          newFilters,
          undefined,
          true,
        ),
      );
      const response = await filterVacancy(
        searchParams,
        newFilters,
        page,
        true,
      );

      setVacancies(response ?? []);

      onCloseFilters();
    },
    [onCloseFilters, page, searchParams, tempFilters],
  );

  const onClearModalFilters = useCallback(async () => {
    setMaxPage(
      await getPages(getAllPlacementVacancyWithCorporateData, {}, true),
    );
    const response = await getAllPlacementVacancyWithCorporateData(
      {},
      false,
      page,
    );

    setVacancies(response ?? []);
    setSelectedDate([]);
    setTempFilters({} as FilterVacancy);
    setApplyFilters(tempFilters);
  }, [tempFilters, page]);

  useEffect(() => {
    if (location?.state?.vacancy) setVacancieSelected(location?.state?.vacancy);
    else if (vacancies?.length) setVacancieSelected(vacancies[0]);
  }, [location?.state?.vacancy, vacancies]);

  const loadVacancies = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    if (pageLimit) {
      let allVacancies: any[] | null = [];
      if (searchParams) {
        setMaxPage(
          await getPages(searchPlacementVacancy, searchParams, pageLimit),
        );
        allVacancies = await searchPlacementVacancy(
          searchParams,
          pageLimit,
          page,
        );
      } else {
        setMaxPage(
          await getPages(getAllPlacementVacancyWithCorporateData, {}, true),
        );
        allVacancies = await getAllPlacementVacancyWithCorporateData(
          {},
          false,
          page,
        );
      }

      setVacancies(allVacancies ?? []);
    }

    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, page, pageLimit, searchParams]);

  useEffect(() => {
    if (!vacancies) loadVacancies();
  }, [loadVacancies, vacancies]);

  const handleClick = useCallback(
    (id: string) => {
      setIdSelected(id);
      setVacancieSelected(vacancies?.filter((item) => item._id === id)[0]);
    },
    [vacancies],
  );

  const handleClickOpenModal = useCallback(() => {
    if (vacancies && vacancies?.length > 0) setOpenModal(true);
  }, [vacancies]);

  const handleCloseFilter = useCallback(
    (filterName: "managedBy" | "place" | "companyId" | "funct" | "atDate") => {
      const filters = {
        place: setIsPlace,
        companyId: setIsCompanyId,
        funct: setIsFunct,
        atDate: setIsDate,
        managedBy: setIsManagedBy,
      };

      Object.keys(filters).forEach((key) =>
        filters[key as keyof typeof filters](false),
      );
      filters[filterName](true);
    },
    [],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const items = useMemo(
    () => [
      {
        text: "Vagas",
        onClick: () => history.replace({ state: { searchParams } }),
      },
      {
        text: "Pessoas",
        onClick: () =>
          history.push({
            pathname: "/search",
            search: `params=${searchParams}`,
            state: { typeSearch: "people" },
          }),
      },
      {
        text: "Empresas",
        onClick: () =>
          history.push({
            pathname: "/search",
            search: `params=${searchParams}`,
            state: { typeSearch: "company" },
          }),
      },
      {
        text: "Ver todos",
        onClick: () =>
          history.push({
            pathname: "/search",
            search: `params=${searchParams}`,
            state: { typeSearch: "all" },
          }),
      },
    ],
    [history, searchParams],
  );

  return (
    <Layout className="grid-responsive">
      <HeaderProfile />
      {width > 990 ? (
        <>
          <div className="grid-column-1-13 max-height-search-filters">
            <div className="flex">
              <ButtonFilter
                className="menu-search-primary menu-search-mobile"
                text="Vagas"
                items={items}
              />
              <div className="vertical-divider-search" />
              <div
                className={`filter-vacancy-managedBy ${
                  tempFilters?.managedBy && !isManagedBy && "wide"
                }`}
              >
                <ButtonFilter
                  className="menu-search-secondary margin-right-24"
                  onClick={() => handleCloseFilter("managedBy")}
                  onClose={() => {
                    setIsManagedBy(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={handleChangeFilters}
                  onSearch={searchVacanciesWithFilter}
                  onClear={() => onClear("managedBy")}
                  text="Tipo de vaga"
                  values={["Plataforma", "Curadoria"]}
                  isOpen={isManagedBy}
                  checkedList={tempFilters?.managedBy ?? []}
                  prop="managedBy"
                />
              </div>

              <div
                className={`filter-vacancy-startDate ${
                  selectedDate && !isDate && "wide"
                }`}
              >
                <ButtonFilter
                  className="menu-search-secondary margin-right-24"
                  onClick={() => handleCloseFilter("atDate")}
                  onClose={() => {
                    setIsDate(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={handleChangeDate}
                  onSearch={searchVacanciesWithFilter}
                  onClear={() => onClear("atDate")}
                  text="Data de postagem"
                  isOpen={isDate}
                  values={[
                    "A qualquer momento",
                    "Último mês",
                    "Última semana",
                    "Últimas 24h",
                  ]}
                  checkedList={selectedDate ?? []}
                  prop="atDate"
                />
              </div>
              <div
                className={`filter-vacancy-company ${
                  tempFilters?.companyId && !isCompanyId && "margin-right-24"
                }`}
              >
                <ButtonFilter
                  className="menu-search-secondary"
                  onClick={() => handleCloseFilter("companyId")}
                  onClose={() => {
                    setIsCompanyId(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={handleChangeFilters}
                  onSearch={searchVacanciesWithFilter}
                  onClear={() => onClear("companyId")}
                  text="Empresa"
                  isOpen={isCompanyId}
                  values={filterVacancyList?.companyId ?? []}
                  checkedList={tempFilters?.companyId ?? []}
                  prop="companyId"
                />
              </div>
              <div
                className={`filter-vacancy-place ${
                  tempFilters?.place && !isPlace && "margin-right-24"
                } filter-localization`}
              >
                <ButtonFilter
                  className="menu-search-secondary"
                  onClick={() => handleCloseFilter("place")}
                  onClose={() => {
                    setIsPlace(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={handleChangeFilters}
                  onSearch={searchVacanciesWithFilter}
                  onClear={() => onClear("place")}
                  text="Localização"
                  isOpen={isPlace}
                  values={filterVacancyList?.place ?? []}
                  checkedList={tempFilters?.place ?? []}
                  prop="place"
                />
              </div>
              <div
                className={`filter-vacancy-function ${
                  tempFilters?.funct && !isFunct && "margin-right-24"
                }`}
              >
                <ButtonFilter
                  className="menu-search-secondary"
                  onClick={() => handleCloseFilter("funct")}
                  onClose={() => {
                    setIsFunct(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={handleChangeFilters}
                  onSearch={searchVacanciesWithFilter}
                  onClear={() => onClear("funct")}
                  text="Função"
                  isOpen={isFunct}
                  values={filterVacancyList?.funct ?? []}
                  checkedList={tempFilters?.funct ?? []}
                  prop="funct"
                />
              </div>
              <div
                className={` ${
                  selectedDate && !isDate ? "width-190" : "filter-all"
                }`}
              >
                <ButtonFilter
                  notIcon
                  text="Todos os filtros"
                  onClick={handleClickOpenModal}
                  className="top-filters-more button-all-filters"
                />
              </div>
            </div>
          </div>
          <div className="width-card-left-vacancie">
            <div className="card-left-vacancies card-left-vacancies-media">
              <div className="card-left-header">
                <h2 className="title-card-left">Vagas</h2>
                <p className="subtitle-card-left">
                  {vacancies?.length}
                  {vacancies && vacancies?.length > 1
                    ? " resultados"
                    : " resultado"}
                </p>
              </div>
              {vacancies &&
                vacancies?.map((item: any, index: number) => (
                  <div
                    key={Math.random()}
                    className="pointer"
                    role="none"
                    onClick={() => handleClick(item._id)}
                  >
                    <div
                      aria-label="advanced"
                      tabIndex={0}
                      role="button"
                      onClick={() => handleClick(item._id)}
                    >
                      <CardVacanciesAdvanced
                        data={item}
                        selectedId={idSelected === item._id}
                      />
                    </div>
                    {index < (vacancies?.length ?? 0) - 1 && (
                      <div className="divider-card" />
                    )}
                  </div>
                ))}
            </div>
            <div>
              <Pagination
                page={page}
                setPage={setPage}
                maxPage={maxPage}
                showAllOption
              />
            </div>
          </div>
        </>
      ) : (
        <RenderVacanciesAdvancedMobile
          items={items}
          vacancies={vacancies}
          vacancieSelected={vacancieSelected}
          selectedDate={selectedDate}
          filterVacancyList={filterVacancyList}
          idSelected={idSelected}
          applyFilters={applyFilters}
          tempFilters={tempFilters}
          setTempFilters={setTempFilters}
          isDate={isDate}
          setIsDate={setIsDate}
          isCompanyId={isCompanyId}
          setIsCompanyId={setIsCompanyId}
          isPlace={isPlace}
          setIsPlace={setIsPlace}
          isFunct={isFunct}
          setIsFunct={setIsFunct}
          isManagedBy={isManagedBy}
          setIsManagedBy={setIsManagedBy}
          handleChangeFilters={handleChangeFilters}
          handleChangeDate={handleChangeDate}
          handleClickOpenModal={handleClickOpenModal}
          handleClick={handleClick}
          handleCloseFilter={handleCloseFilter}
          searchVacanciesWithFilter={searchVacanciesWithFilter}
          onClear={onClear}
          page={page}
          setPage={setPage}
          maxPage={maxPage}
        />
      )}
      {width > 990 &&
        vacancies &&
        vacancies?.length > 0 &&
        vacancieSelected && (
          <CardDescription data={vacancieSelected} columnDescription />
        )}
      {width > 990 && vacancies && !vacancies?.length && vacancieSelected && (
        <Card className="grid-column-4-13 card-notresults">
          <p className="title-unavaliable">Não há nenhuma vaga selecionada</p>
        </Card>
      )}
      <ModalConfigAndFilters
        openModal={openModal}
        onClose={() => setOpenModal(false)}
        textHeaderModal="Todos os Filtros"
        filters={options}
        onClear={onClearModalFilters}
        onSearch={() => {
          searchVacanciesWithFilter();
          setOpenModal(false);
        }}
      />
    </Layout>
  );
};

export default memo(VacanciesAdvanced);
