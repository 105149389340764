import React, { memo } from "react";
import "./styles.scss";
import { Tooltip } from "@material-ui/core";
import { Info } from "../../../assets/icons";

interface Props {
  className?: string;
  status: string;
  showInfo?: boolean;
}

const JobStatus = ({
  className = "",
  status,
  showInfo = false,
}: Props): JSX.Element => (
  <div className="flex-row-center">
    <p className={`job-inf-text job-inf-status ${className}`}>{status}</p>
    {showInfo && (
      <Tooltip
        title={
          <p>
            Nenhuma etapa do processo seletivo desta vaga irá ocorrer pela nossa
            plataforma. É a divulgação de uma oportunidade que consideramos
            interessante para você.
          </p>
        }
      >
        <div>
          <Info className="icon-info cursor-pointer" />
        </div>
      </Tooltip>
    )}
  </div>
);

export default memo(JobStatus);
