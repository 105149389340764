import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const SocialMedia = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#606060"
      d="M12 9c-1.642 0-3 1.359-3 3 0 1.642 1.358 3 3 3 1.641 0 3-1.358 3-3 0-1.641-1.359-3-3-3z"
    />
    <path
      fill="#606060"
      d="M12 5c-7.592 0-9.874 6.617-9.895 6.684L2 12l.104.316C2.126 12.383 4.408 19 12 19c7.592 0 9.874-6.617 9.895-6.684L22 12l-.104-.316C21.874 11.617 19.592 5 12 5zm0 12c-5.322 0-7.384-3.846-7.883-5 .5-1.158 2.564-5 7.883-5 5.322 0 7.384 3.846 7.883 5-.5 1.158-2.564 5-7.883 5z"
    />
  </svg>
);

export default SocialMedia;
