import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { Button, Input } from "../../../components";

interface Props {
  setData: Dispatch<SetStateAction<string>>;
}

const Email = ({ setData }: Props): JSX.Element => {
  const [email, setEmail] = useState("");

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (email) setData(email);
      }
    },
    [email, setData],
  );

  return (
    <form onKeyDown={handleKeyDown} className="width-100">
      <div className="margin-bottom-32 type-access">
        <p className="title-login">
          Insira seu e-mail para acessar a plataforma.
        </p>
        <Input
          id="email"
          label="E-mail"
          type="text"
          value={email}
          className="input-email email-input-login"
          onChange={(e: { target: { value: string } }) =>
            setEmail(e.target.value.toLowerCase().trim())
          }
        />
        <Button
          onClick={() => setData(email)}
          text="Entrar"
          className="margin-bottom-32 width-100"
        />
      </div>
    </form>
  );
};

export default memo(Email);
