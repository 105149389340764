import React, { memo, useCallback, useEffect, useState } from "react";
import { Card } from "../../../components";
import {
  filterClassVacancySubscription,
  filterUser,
} from "../../../services/functions";
import "../style.scss";

interface Props {
  classId?: string;
  classVacancyId?: string;
  isWorkshop?: boolean;
}

const Count = ({
  classId = "",
  classVacancyId = "",
  isWorkshop = false,
}: Props): JSX.Element => {
  const [totalList, setTotalList] = useState<number>();

  const getTotalList = useCallback(async () => {
    const funct = isWorkshop
      ? filterClassVacancySubscription(
          classVacancyId,
          { workshopClass: classId },
          true,
        )
      : filterUser(
          "",
          { classId, anyStatus: true },
          undefined,
          undefined,
          "profile.name",
          true,
          true,
        );
    const numTotalList = (await funct) as { size: 0 }[];
    if (numTotalList?.length) setTotalList(numTotalList[0]?.size ?? 0);
  }, [classId, classVacancyId, isWorkshop]);

  useEffect(() => {
    if (classId && totalList === undefined) getTotalList();
  }, [classId, getTotalList, totalList]);

  return (
    <Card titleHeader="Alunas" className="grid-column-1-4 grid-row-2">
      <p className="title-vacancy">{totalList ?? 0}</p>
    </Card>
  );
};

export default memo(Count);
