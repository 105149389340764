/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { CardItems } from "..";
import { RootState } from "../../store/reducers";
import { UserCurriculumWork } from "../../types/interfaces";
import { addCompanyToUser } from "../../services/functions";
import "../style.scss";

interface Props {
  className?: string;
  hasCards?: boolean;
  isMyProfile?: boolean;
  items?: UserCurriculumWork[];
  onEdit?: (item: any, index: number) => void;
  onRemove?: (item: any, index: number) => void;
  showDivider?: boolean;
}

const ViewWork = ({
  className = "",
  hasCards = false,
  isMyProfile = true,
  items,
  onEdit,
  onRemove,
  showDivider = false,
}: Props): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const [data, setData] = useState(items);
  const [loading, setLoading] = useState(false);

  const setCompanyToUser = useCallback(async () => {
    setLoading(true);
    const response = await addCompanyToUser(user);
    setData(response?.curriculum?.works);
    setLoading(false);
  }, [user]);

  useEffect(() => {
    if (isMyProfile) setCompanyToUser();
  }, [isMyProfile, setCompanyToUser]);

  return loading ? (
    <div className="circular-progress">
      <CircularProgress />
    </div>
  ) : data && data?.length > 0 ? (
    <CardItems
      hasIcon
      isMyProfile={isMyProfile}
      showDivider={showDivider}
      items={data}
      title="position"
      subTitle="name"
      className={`${!hasCards ? "cards-items-profile" : ""} ${className}`}
      onEdit={onEdit}
      onRemove={onRemove}
    />
  ) : (
    <p className="paragraph-not-registered">Não há experiências cadastradas</p>
  );
};

export default memo(ViewWork);
