import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const Infos = ({ widthIcon, heightIcon, className }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    className={className}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#7E459B"
      d="M14.8 10v2.4h2.4V10h-2.4zM14.8 17.2V22h3.6v-2.4h-1.2v-4.8h-3.6v2.4h1.2z"
    />
    <path
      fill="#7E459B"
      fillRule="evenodd"
      d="M16 4C9.376 4 4 9.376 4 16s5.376 12 12 12 12-5.376 12-12S22.624 4 16 4zM6.4 16c0 5.292 4.308 9.6 9.6 9.6s9.6-4.308 9.6-9.6-4.308-9.6-9.6-9.6-9.6 4.308-9.6 9.6z"
      clipRule="evenodd"
    />
  </svg>
);

export default Infos;
