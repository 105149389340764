import React, { memo, useMemo } from "react";
import { Tooltip } from "@material-ui/core";
import "./styles.scss";
import JobCompany from "./JobCompany";
import JobDatetime from "./JobDatetime";
import JobLocation from "./JobLocation";
import JobStatus from "./JobStatus";
import JobTitle from "./JobTitle";
import {
  placementVacancyManagedBy,
  placementVacancyStatus,
  placementVacancyTypeOfCandidature,
} from "../../types/enumerators";
import { Info } from "../../../assets/icons";

interface Data {
  externalCompanyName?: string;
  managedBy?: string;
  companyName?: string;
  jobDate?: string | Date;
  jobPlace?: string;
  jobStatus?: string;
  jobManagedBy?: string;
  jobTime?: string | Date;
  jobTitle?: string;
  jobType?: string;
  jobTypeOfCandidature?: string;
  place?: string;
  startDate?: string;
  status?: string;
  title?: string;
  type?: string;
  typeOfCandidature?: string;
}

interface Props {
  data: Data;
  rawStatus?: boolean;
  titleClassName?: string;
  statusClassName: string;
  dateClassName?: string;
}

const JobInformation = ({
  data,
  rawStatus = false,
  titleClassName = "",
  statusClassName,
  dateClassName = "",
}: Props): JSX.Element => {
  const type = useMemo(() => data?.jobType ?? data?.type ?? "", [data]);
  const place = useMemo(() => data?.jobPlace ?? data?.place ?? "", [data]);
  const status = useMemo(() => data?.jobStatus ?? data?.status ?? "", [data]);
  const managedBy = useMemo(
    () => data?.jobManagedBy ?? data?.managedBy ?? "",
    [data],
  );
  const candidature = useMemo(
    () => data?.jobTypeOfCandidature ?? data?.typeOfCandidature ?? "",
    [data],
  );
  const date = useMemo(
    () => data?.jobTime ?? data?.startDate ?? data?.jobDate ?? "",
    [data],
  );
  const currentStatus = useMemo(
    () =>
      managedBy === placementVacancyManagedBy.EXTERNAL_VACANCY
        ? "Curadoria"
        : status === placementVacancyStatus.ACTIVE
        ? candidature === placementVacancyTypeOfCandidature.OPEN
          ? "Recebendo candidaturas"
          : "Apenas convidadas"
        : status === placementVacancyStatus.PENDING
        ? "Inicia em breve"
        : "Vaga fechada",
    [managedBy, status, candidature],
  );
  const showExternalVacancy =
    status === placementVacancyStatus.ACTIVE &&
    managedBy === placementVacancyManagedBy.EXTERNAL_VACANCY;

  return (
    <div className="job-inf-text">
      <JobTitle
        className={titleClassName}
        title={data?.jobTitle ?? data?.title ?? ""}
      />
      <JobCompany
        companyName={
          showExternalVacancy ? data?.externalCompanyName : data?.companyName
        }
      />
      <JobLocation type={type} place={place} />
      {rawStatus ? (
        <JobStatus
          className={statusClassName}
          status={currentStatus}
          showInfo={showExternalVacancy}
        />
      ) : (
        <div className="flex-row-center">
          <p
            className={`job-inf-status ${
              showExternalVacancy
                ? "job-inf-external-vacancy"
                : status === placementVacancyStatus.ACTIVE
                ? "job-inf-status-active"
                : "job-inf-status-other"
            } ${statusClassName}`}
          >
            {showExternalVacancy
              ? "Curadoria"
              : status === placementVacancyStatus.ACTIVE
              ? candidature === placementVacancyTypeOfCandidature.OPEN
                ? "Recebendo candidaturas"
                : "Apenas convidadas"
              : status === placementVacancyStatus.PENDING
              ? "Inicia em breve"
              : "Vaga fechada"}
          </p>
          {showExternalVacancy && (
            <Tooltip
              title={
                <p>
                  Nenhuma etapa do processo seletivo desta vaga irá ocorrer pela
                  nossa plataforma. É a divulgação de uma oportunidade que
                  consideramos interessante para você.
                </p>
              }
            >
              <div>
                <Info className="icon-info cursor-pointer" />
              </div>
            </Tooltip>
          )}
        </div>
      )}
      <JobDatetime className={dateClassName} datetime={date} />
    </div>
  );
};

export default memo(JobInformation);
