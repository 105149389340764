/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { Vacancy } from "../../../../../assets/icons";
import { CardJob } from "../../../../components";
import { searchCorporatePlacementVacancy } from "../../../../services/functions";
import { setLoad } from "../../../../store/actions/configurationsActions";

interface Props {
  company: any;
}

interface Description {
  id: string;
  jobTitle?: string;
  jobType: string;
  jobPlace: string;
}

const Vacancies = ({ company }: Props): JSX.Element => {
  const [vacancyCard, setVacancyCard] = useState<Description[] | undefined>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const vacancySession = async () => {
      dispatch(setLoad(true) as unknown as Action);

      const vacancy = await searchCorporatePlacementVacancy(company?._id);

      const vacancies = vacancy?.map((item) => ({
        id: item._id,
        jobTitle: item.title,
        jobType: item.type,
        jobPlace: item.place,
        jobTime: item.startDate,
        jobDescription: item.description,
        companyName: company?.profile?.name,
        companyAbout: company?.profile?.description,
        companyWebsite: company?.profile?.site,
        photo: company?.profile?.logo,
        ...item,
      }));
      setVacancyCard(vacancies);

      dispatch(setLoad(false) as unknown as Action);
    };
    vacancySession();
  }, [
    company?._id,
    company?.profile?.description,
    company?.profile?.logo,
    company?.profile?.name,
    company?.profile?.site,
    dispatch,
  ]);

  return (
    <div>
      <div className="cards-profile">
        <div className="flex-row-center title-cards">
          <Vacancy />
          <p className="cards-title-profile">Vagas</p>
        </div>
        <div className="divider-card-title" />
        <div className="cards-items-profile flex-direction-wrap padding-24">
          {vacancyCard?.map((item) => (
            <CardJob key={Math.random()} data={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(Vacancies);
