/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  SET_COURSE_SUBSCRIPTION,
  CREATE_COURSE_SUBSCRIPTION,
  UPDATE_COURSE_SUBSCRIPTION,
  UPDATE_CLASS_VACANCY_IN_COURSE_SUBSCRIPTION,
  CLEAR_COURSE_SUBSCRIPTION,
} from "../types/courseTypes";

const initialState: any = {
  stages: [],
  form: {},
};

export const courseReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
): any => {
  switch (type) {
    case CLEAR_COURSE_SUBSCRIPTION:
      return { ...initialState };
    case SET_COURSE_SUBSCRIPTION:
      return { ...state, ...payload };
    case CREATE_COURSE_SUBSCRIPTION:
      return { ...state, ...payload };
    case UPDATE_COURSE_SUBSCRIPTION:
      return { ...state, ...payload };
    case UPDATE_CLASS_VACANCY_IN_COURSE_SUBSCRIPTION:
      return { ...state, ...payload };
    default:
      return state;
  }
};
