import React, { memo, useMemo, useState } from "react";
import { ChevronWhite } from "../../../../assets/icons";
import { ButtonMenu } from "../../../components";
import { ClassVacancy, ClassVacancyStages } from "../../../types/interfaces";
import { AddCandidate, Candidate, ConfirmWorkshop, Distribute } from "../Modal";
import { classVacancyType } from "../../../types/enumerators";
import "../style.scss";

interface Props {
  classVacancy: ClassVacancy;
  totalList: number;
  reload: () => void;
  stagesIds: {
    mediaStageId?: string;
    subscriptionStageId?: string;
    workshopStageId?: string;
    talentAcademyStageId?: string;
    testStageId?: string;
  };
}

const Button = ({
  classVacancy,
  totalList,
  reload,
  stagesIds,
}: Props): JSX.Element => {
  const [openModalCandidates, setOpenModalCandidates] = useState(false);
  const [openModalDistribute, setOpenModalDistribute] = useState(false);
  const [openModaWorkshop, setOpenModalWorkshop] = useState(false);
  const [openModalAddCandidate, setOpenModalAddCandidate] = useState(false);

  const stageMedia = useMemo(
    () =>
      classVacancy?.stages?.find(
        (item) => item?.type === classVacancyType.UPLOAD_MEDIAS,
      ),
    [classVacancy?.stages],
  );

  const stageEvaluate = useMemo(
    () =>
      classVacancy?.stages?.find(
        (item) => item?.type === classVacancyType.EVALUATE,
      ),
    [classVacancy?.stages],
  );

  const items = useMemo(
    () => [
      {
        id: "details",
        text: "Ver detalhes",
        onClick: () => setOpenModalCandidates(true),
      },
      {
        id: "distribution",
        text: "Distribuir apresentações",
        onClick: () => stageMedia?.auto && setOpenModalDistribute(true),
        disabled: !stageMedia?.auto,
      },
      {
        id: "confirmationWorkshop",
        text: "Confirmar oficina",
        onClick: () => setOpenModalWorkshop(true),
      },
      {
        id: "addCandidate",
        text: "Adicionar candidata",
        onClick: () => setOpenModalAddCandidate(true),
      },
    ],
    [stageMedia?.auto],
  );

  return (
    <div className="grid-column-11-13 grid-row-1 admin-subscriptions-manager-actions">
      <ButtonMenu
        text="AÇÕES"
        className="table-actions"
        items={items}
        endIcon
        closeItemOnclick
        icon={<ChevronWhite className="icon-rotate" />}
      />
      <Candidate
        open={openModalCandidates}
        onclose={() => setOpenModalCandidates(false)}
        stages={classVacancy?.stages}
        totalList={totalList}
      />
      <Distribute
        open={openModalDistribute}
        onclose={() => setOpenModalDistribute(false)}
        stage={stageMedia as ClassVacancyStages}
        stageId={stageEvaluate?.stage_id ?? ""}
        classVacancy={classVacancy}
        reload={reload}
        stagesIds={stagesIds}
      />
      <ConfirmWorkshop
        open={openModaWorkshop}
        onclose={() => setOpenModalWorkshop(false)}
        stages={classVacancy?.stages}
      />
      <AddCandidate
        open={openModalAddCandidate}
        onclose={() => setOpenModalAddCandidate(false)}
        stages={classVacancy?.stages}
        classVacancyId={classVacancy?._id ?? ""}
      />
    </div>
  );
};

export default memo(Button);
