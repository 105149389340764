/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  CompanyAvatarEmpty,
  Infos,
  Video,
} from "../../../../../assets/customIcons";
import { Vacancy } from "../../../../../assets/icons";
import { youtubeApi } from "../../../../services/api";
import { searchCorporatePlacementVacancy } from "../../../../services/functions";
import { youTubeIdFromLink } from "../../../../utils/index";
import "./style.scss";

interface Props {
  company: any;
  setPageSelected: any;
}

interface Description {
  jobTitle?: string;
  jobType: string;
  jobPlace: string;
}

const Initial = ({ company, setPageSelected }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [vacancyCard, setVacancyCard] = useState<Description[] | undefined>([]);
  const [lastVideo, setLastVideo] = useState<any>();
  const [youTubeList, setYouTubeList] = useState<any>();
  const history = useHistory();

  useEffect(() => {
    const vacancySession = async () => {
      const vacancy = await searchCorporatePlacementVacancy(company?._id, 3);
      const vacancies = vacancy?.map((item) => ({
        id: item._id,
        jobTitle: item.title,
        jobType: item.type,
        jobPlace: item.place,
        jobDescription: item.description,
        companyName: company?.profile?.name,
        companyAbout: company?.profile?.description,
        companyWebsite: company?.profile?.site,
        photo: company?.profile?.logo,
        ...item,
      }));
      setVacancyCard(vacancies);
    };
    vacancySession();
  }, [
    company?._id,
    company?.profile?.description,
    company?.profile?.logo,
    company?.profile?.name,
    company?.profile?.site,
  ]);

  const getYouTubeList = useCallback(async () => {
    const youTubeIds = company?.profile?.medias
      ?.filter((item: { url: string }) => item?.url?.includes("youtu"))
      ?.map((innerItem: { url: string }) => ({
        url: innerItem?.url,
        id: youTubeIdFromLink(innerItem?.url),
      }));

    const videosList = [];

    for (let i = 0; i < youTubeIds?.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const response = await youtubeApi.get(
        `/videos?key=AIzaSyAz3zSCJ-Du4DyU8sr2X2xxn4xhv7RRzHM&part=snippet&id=${youTubeIds[i]?.id}`,
      );

      if (
        response?.data?.items?.length &&
        response?.data?.items[0]?.kind === "youtube#video"
      )
        videosList.push(response?.data?.items[0]);
    }

    setYouTubeList(videosList);
    setLastVideo(videosList[videosList.length - 1]);
  }, [company?.profile?.medias]);

  useEffect(() => {
    if (!youTubeList?.length) getYouTubeList();
  }, [youTubeList?.length, getYouTubeList]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {company?.profile?.description && (
        <div className="cards-profile">
          <div className="flex-row-center title-cards">
            <Infos
              className="cards-icon-title"
              widthIcon={width < 991 ? 24 : 32}
              heightIcon={width < 991 ? 24 : 32}
            />
            <p className="cards-title-profile">Sobre</p>
          </div>
          <div className="divider-card-title" />
          <div className="cards-items-profile ">
            <p className="paragraph-about">{company?.profile?.description}</p>
          </div>
          <div className="div-description-company">
            <button
              type="button"
              className="cards-footer-profile"
              onClick={() => setPageSelected("about")}
            >
              Ver mais sobre a empresa
            </button>
          </div>
        </div>
      )}
      {lastVideo && (
        <div className="cards-profile">
          <div className="flex-row-center title-cards">
            <Video
              widthIcon={width < 991 ? 24 : 32}
              heightIcon={width < 991 ? 24 : 32}
            />
            <p className="cards-title-profile">Vídeos recentes</p>
          </div>
          <div className="divider-card-title" />
          <div className="cards-items-profile">
            {lastVideo?.id && (
              <div key={Math.random()} className="flex-direction">
                <div className="container-videos display-block">
                  <iframe
                    width="264px"
                    height="152px"
                    src={`https://www.youtube.com/embed/${lastVideo?.id}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="videos"
                  />
                  <div className="div-description-video margin-top-16-responsive">
                    <p className="title-video">
                      {lastVideo?.snippet?.title ?? ""}
                    </p>
                    <p className="description-video">
                      {lastVideo?.snippet?.description ?? ""}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="see-all-media">
            <button
              type="button"
              className="cards-footer-profile"
              onClick={() => setPageSelected("medias")}
            >
              Ver todas as mídias
            </button>
          </div>
        </div>
      )}
      {vacancyCard && vacancyCard?.length > 0 && (
        <div className="cards-profile">
          <div className="flex-row-center padding-24">
            <Vacancy className="cards-icon-title" />
            <p className="cards-title-profile">Vagas recentes</p>
          </div>
          <div className="divider-card-title" />
          <div
            role="button"
            tabIndex={0}
            className="cards-items-profile button-vacancies-recent"
          >
            {vacancyCard?.map((item) => (
              <div
                className="vacancy-card-button"
                role="button"
                tabIndex={0}
                key={Math.random()}
                onClick={() =>
                  history.push({
                    pathname: "/vacancies-advanced",
                    state: { vacancy: item },
                  })
                }
              >
                {company?.profile?.logo ? (
                  <img
                    alt="logo"
                    src={company?.profile?.logo}
                    className="div-card-items company-avatar-empty-card"
                  />
                ) : (
                  <CompanyAvatarEmpty className="div-card-items company-avatar-empty-card" />
                )}
                <div className="flex-col margin-left-16">
                  <p className="view-bold">{item.jobTitle}</p>
                  <p className="title-view-14">
                    {item.jobType === "Remota"
                      ? item.jobType
                      : item.jobType === "Híbrida"
                      ? `${item.jobType} - ${item.jobPlace}`
                      : item.jobPlace}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="card-view-all-vacancies">
            <button
              type="button"
              className="cards-footer-profile"
              onClick={() => setPageSelected("vacancy")}
            >
              Ver todas as vagas
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Initial);
