import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const CompanyIcon = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="M21.474 20.949h-1.58V6.761a.515.515 0 0 0-.357-.526l-4.905-1.608V2.525A.525.525 0 0 0 14.105 2H4.632a.527.527 0 0 0-.527.525V20.95H2.526a.527.527 0 0 0-.372.897.527.527 0 0 0 .372.154h18.948a.527.527 0 0 0 .372-.897.527.527 0 0 0-.372-.154zm-2.632-13.81v13.81h-4.21V5.731l4.21 1.408zm-10 13.81v-2.102h1.053v2.102H8.842zm1.58-3.153H8.315a.527.527 0 0 0-.527.526v2.627H5.158V3.082h8.42V20.95h-2.63V18.29a.525.525 0 0 0-.527-.494zM8.82 15.17a.525.525 0 0 1-.505.525H7.263a.527.527 0 0 1-.372-.897.527.527 0 0 1 .372-.154h1.053a.527.527 0 0 1 .505.526zm2.716.525H10.42a.527.527 0 0 1-.372-.897.527.527 0 0 1 .372-.154h1.053a.527.527 0 0 1 .372.897.527.527 0 0 1-.372.154h.063zM8.82 12.016a.525.525 0 0 1-.505.525H7.263a.527.527 0 1 1 0-1.05h1.053a.527.527 0 0 1 .505.525zm1.053 0a.525.525 0 0 1 .547-.526h1.053a.526.526 0 1 1 0 1.051H10.42a.527.527 0 0 1-.505-.525h-.042zM8.82 8.863a.525.525 0 0 1-.505.525H7.263a.527.527 0 1 1 0-1.05h1.053a.527.527 0 0 1 .505.525zm1.053 0a.525.525 0 0 1 .547-.526h1.053a.527.527 0 1 1 0 1.051H10.42a.527.527 0 0 1-.505-.525h-.042zM8.82 5.71a.525.525 0 0 1-.505.525H7.263a.527.527 0 0 1-.372-.897.527.527 0 0 1 .372-.154h1.053a.527.527 0 0 1 .505.526zm1.053 0a.525.525 0 0 1 .547-.526h1.053a.527.527 0 1 1 0 1.051H10.42a.527.527 0 0 1-.505-.525h-.042zm7.873 12.612a.525.525 0 0 1-.526.525h-1.053a.527.527 0 0 1-.372-.897.527.527 0 0 1 .372-.154h1.053a.527.527 0 0 1 .569.494l-.043.032zm-2.105-3.153a.536.536 0 0 1 .526-.526h1.053a.537.537 0 0 1 .526.526.525.525 0 0 1-.526.525h-1.053a.527.527 0 0 1-.484-.546l-.042.02zm0-3.153a.525.525 0 0 1 .526-.526h1.053a.527.527 0 1 1 0 1.051h-1.053a.527.527 0 0 1-.484-.525h-.042zm0-3.153a.525.525 0 0 1 .526-.526h1.053a.527.527 0 0 1 .526.526.535.535 0 0 1-.526.525h-1.053a.537.537 0 0 1-.484-.504l-.042-.021z"
      fill="#939393"
      stroke="#939393"
      strokeWidth=".5"
    />
  </svg>
);

export default CompanyIcon;
