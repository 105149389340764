/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Add } from "../../../../assets/customIcons";
import {
  AddWork,
  Button,
  Card,
  IconButton,
  ViewWork,
} from "../../../components";
import { updateUser } from "../../../store/actions/userActions";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import {
  addCompanyToUser,
  searchCompaniesAllStatus,
  setNewCompany,
} from "../../../services/functions";
import { companyType, companyStatus } from "../../../types/enumerators";
import { RootState } from "../../../store/reducers";
import { UserCurriculumWork } from "../../../types/interfaces";

interface Props {
  title: string;
  addWork: boolean;
  setAddWork: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
  hasTooltip?: boolean;
  setIsOptional: Dispatch<SetStateAction<boolean>>;
}

const CurriculumWork = ({
  title,
  addWork,
  setIsValid,
  setAddWork,
  hasTooltip = false,
  setIsOptional,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [editIndex, setEditIndex] = useState(-1);
  const [isCurrentJob, setIsCurrentJob] = useState(false);
  const [work, setWork] = useState<UserCurriculumWork>({
    companyId: "",
    position: "",
    description: "",
    startDate: "",
  });
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [_curriculum, _setCurriculum] = useState(user?.curriculum);

  useEffect(() => {
    if (_curriculum?.works?.length) {
      setIsOptional(false);
      setIsValid(true);
    } else setIsOptional(true);
  }, [_curriculum?.works, setIsOptional, setIsValid]);

  const setCurrentCompany = useCallback(
    async (value: string | null) => {
      if (value && value !== "") {
        const currentCompany = await searchCompaniesAllStatus(value);

        if (!currentCompany?.length) {
          await setNewCompany(
            value,
            companyType.WORK_COMPANY,
            companyStatus.PENDING,
            companyStatus.NONE,
          );
          setCurrentCompany(value);
        } else
          setWork({
            ...work,
            companyId: currentCompany[0]?._id ?? "",
          });
      }
    },
    [work],
  );

  const onSave = useCallback(async () => {
    let { works: newWorks } = _curriculum ?? { works: [] };

    if (editIndex !== -1) {
      newWorks[editIndex] = work;
    } else if (newWorks?.length) newWorks.push(work);
    else newWorks = [work];

    setWork({
      companyId: "",
      position: "",
      description: "",
      startDate: "",
    });
    setIsCurrentJob(false);
    setAddWork(false);
    setEditIndex(-1);

    const newUser = {
      ...user,
      curriculum: { ...user?.curriculum, works: newWorks },
    };

    const newUserWithCompany = await addCompanyToUser(newUser);

    _setCurriculum({ ...newUserWithCompany.curriculum });
    dispatch(
      setNextButton(() =>
        dispatch(updateUser({ ...newUser }) as unknown as Action),
      ) as unknown as Action,
    );
  }, [_curriculum, dispatch, editIndex, setAddWork, user, work]);

  const onRemove = useCallback(
    (index: number) => {
      const { works: oldWorks } = _curriculum;
      oldWorks.splice(index, 1);
      _setCurriculum({ ..._curriculum, works: oldWorks });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, works: oldWorks },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_curriculum, dispatch, user],
  );

  const onEdit = useCallback(
    (item, index) => {
      if (!item.endDate) setIsCurrentJob(true);
      setEditIndex(index);
      setAddWork(true);
      setWork(item);
    },
    [setAddWork],
  );

  const onCancel = useCallback(() => {
    setAddWork(false);
    setEditIndex(-1);
    setWork({
      companyId: "",
      position: "",
      description: "",
      startDate: "",
    });
    setIsCurrentJob(false);
    setIsValid(true);
  }, [setAddWork, setIsValid]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`grid-column-5-12 grid-column-1-11-mobile margin-top-16 grid-7-column ${
        width > 990 ? "grid-row-1" : "grid-row-2"
      }`}
    >
      <Card
        borderNone
        hasTooltip={hasTooltip}
        titleHeader={title}
        className={`grid-column-1-8 height-fit-content ${
          addWork || width < 991 ? "border-card" : ""
        }`}
      >
        <p className="paragraph-work-title">
          Conte um pouco sobre a sua experiência profissional e as empresas que
          já trabalhou.
        </p>
        {!addWork ? (
          <IconButton
            icon={
              <Add
                widthIcon={width < 991 ? 20 : 24}
                heightIcon={width < 991 ? 20 : 24}
              />
            }
            text="Adicionar experiência"
            onClick={() => setAddWork(true)}
            className="icon-button-primary margin-top-8 icon-button-primary-mobile"
          />
        ) : (
          <AddWork
            work={work}
            setItem={setWork}
            setIsValid={setIsValid}
            setIsCurrentJob={setIsCurrentJob}
          />
        )}
      </Card>
      {addWork && (
        <div className="flex grid-column-4-8 grid-row-2 flex-direction-column-reverse">
          <Button
            text="Cancelar"
            className="width-50"
            appearance="secondary"
            outline
            onClick={onCancel}
          />
          <Button
            text="Salvar"
            className="margin-left-32 margin-left-mobile width-50"
            onClick={onSave}
            disabled={
              !work.companyId ||
              !work.position ||
              !work.description ||
              !work.startDate ||
              (!work.endDate && !isCurrentJob)
            }
          />
        </div>
      )}
      {addWork && _curriculum?.works?.length > 0 && (
        <div className="grid-column-1-8 grid-row-3 divider" />
      )}
      {_curriculum?.works?.length > 0 && (
        <div
          className={`grid-column-1-8 ${addWork ? "grid-row-4" : "grid-row-2"}`}
        >
          <ViewWork
            hasCards
            items={_curriculum?.works}
            onEdit={onEdit}
            onRemove={(_, index) => onRemove(index)}
            className="padding-24"
          />
        </div>
      )}
    </div>
  );
};

export default memo(CurriculumWork);
