import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const Info = ({ widthIcon, heightIcon, className }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    className={className}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#606060"
      d="M11.1 7.5v1.8h1.8V7.5h-1.8zM11.1 12.9v3.6h2.7v-1.8h-.9v-3.6h-2.7v1.8h.9z"
    />
    <path
      fill="#606060"
      fillRule="evenodd"
      d="M12 3c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm-7.2 9c0 3.969 3.231 7.2 7.2 7.2 3.969 0 7.2-3.231 7.2-7.2 0-3.969-3.231-7.2-7.2-7.2-3.969 0-7.2 3.231-7.2 7.2z"
      clipRule="evenodd"
    />
  </svg>
);

export default Info;
