import React, { memo } from "react";
import { Modal } from "../../../components";
import { Class } from "../../../types/interfaces";
import { CourseStatus } from "../../../types/constants/Course";
import "../style.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  bootcamps?: Class[];
  userName?: string;
}

const ViewClass = ({
  open,
  onClose,
  bootcamps,
  userName,
}: Props): JSX.Element => (
  <Modal
    headerAction
    open={open}
    onClose={onClose}
    textHeader={`${userName} - Outros cursos`}
  >
    <div className="admin-recap-process-modal">
      {bootcamps && bootcamps?.length > 0 ? (
        bootcamps?.map((item: Class) => (
          <div key={Math.random()} className="margin-bottom-48">
            <div className="admin-recap-process with-after margin-bottom-24">
              Curso: {item?.title}
            </div>
            <div className="flex">
              <div className="admin-recap-margin">
                <div className="admin-recap-text">Código</div>
                <div className="admin-recap-text courses">{item?.code}</div>
              </div>
              <div>
                <div className="admin-recap-text">Status</div>
                <div
                  className={`admin-recap-component-status admin-recap-component-status-${item?.status}`}
                >
                  {CourseStatus[item?.status as keyof typeof CourseStatus]}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="admin-recap-no-result">Sem curso</div>
      )}
    </div>
  </Modal>
);

export default memo(ViewClass);
