import React, { memo } from "react";
import "../style.scss";

interface Props {
  title: string;
}

const Path = ({ title }: Props): JSX.Element => (
  <div className="grid-column-1-12 grid-row-1 header-class">
    <p className="view-page">Turmas &gt;&nbsp;</p>
    <p className="view-bold-16">{title}</p>
  </div>
);

export default memo(Path);
