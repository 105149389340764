/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { toast } from "react-toastify";
import { AttachmentPhoto, Button, Modal } from "../../../components";
import api from "../../../services/api";
import "./styles.scss";

interface Props {
  company: any;
  openModal: boolean;
  onClose: () => void;
  setCompany: Dispatch<SetStateAction<any>>;
}

const Avatar = ({
  company,
  openModal,
  onClose,
  setCompany,
}: Props): JSX.Element => {
  const [_company, _setCompany] = useState(company);

  const onChangeImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      api
        .post("/fileUpload", data)
        .then((result) => {
          _setCompany({
            ...company,
            profile: { ...company?.profile, logo: result?.data?.file_url.url },
          });
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    },
    [company],
  );
  const onRemove = useCallback(() => {
    _setCompany({
      ..._company,
      profile: { ..._company.profile, logo: "" },
    });
  }, [_company]);

  return (
    <Modal
      headerAction
      textHeader="Editar foto do perfil"
      open={openModal}
      onClose={onClose}
      bodyClassName="modal-profile"
    >
      <AttachmentPhoto
        photo={_company?.profile?.logo}
        type="logo"
        onChange={onChangeImage}
      />
      <div className="flex-row-center justify-center margin-top-48 flex-col-mobile gap-16-mobile margin-top-0-mobile">
        {_company?.profile?.logo && (
          <button
            type="button"
            className="button-avatar-delet"
            onClick={onRemove}
          >
            Excluir imagem
          </button>
        )}
        <Button
          text="Cancelar"
          className="width-160 margin-right-24 margin-right-0-mobile width-100-mobile"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button
          text="Salvar"
          onClick={() => {
            onClose();
            setCompany({
              ...company,
              profile: { ...company?.profile, logo: _company?.profile.logo },
            });
          }}
          className="width-160 width-100-mobile"
        />
      </div>
    </Modal>
  );
};

export default memo(Avatar);
