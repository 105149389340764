/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getSuggestionsOfUsers,
  getSuggestionsOfVacancies,
} from "../../services/functions";
import { PlacementVacancy, User } from "../../types/interfaces";
import {
  Layout,
  HeaderProfile,
  UsersProfile,
  VacanciesProfile,
  Notifications,
} from "../../components";
import "./styles.scss";
import { RootState } from "../../store/reducers";
import { isCorporate as isCorporateUtils } from "../../utils";

const Notification = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const [users, setUsers] = useState<User[] | null>([]);
  const [vacancies, setVacancies] = useState<PlacementVacancy[] | null>([]);
  const isCorporate = isCorporateUtils(user);

  const getUsers = useCallback(async () => {
    if (user?.account?.companyIds && user?.account?.companyIds?.length) {
      const response = await getSuggestionsOfUsers(
        user?.account?.companyIds[0] ?? "",
      );
      setUsers(response);
    }
  }, [user?.account?.companyIds]);

  useEffect(() => {
    if (
      isCorporate &&
      user?.account?.companyIds &&
      user?.account?.companyIds?.length
    )
      getUsers();
  }, [getUsers, isCorporate, user?.account?.companyIds]);

  const getVacancies = useCallback(async () => {
    const response = await getSuggestionsOfVacancies(user?._id);
    setVacancies(response);
  }, [user?._id]);

  useEffect(() => {
    if (!isCorporate) getVacancies();
  }, [getVacancies, isCorporate]);

  return (
    <Layout className="grid-responsive">
      <HeaderProfile />
      <div className="grid-column-1-10">
        <Notifications isPage />
      </div>
      {isCorporate ? (
        <UsersProfile users={users ?? []} row="1" />
      ) : (
        <VacanciesProfile vacancies={vacancies ?? []} row="1" />
      )}
    </Layout>
  );
};

export default memo(Notification);
