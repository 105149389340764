import React, { ChangeEventHandler, FocusEventHandler, memo } from "react";
import { TextField } from "@material-ui/core";
import "./styles.scss";

interface Props {
  id?: string;
  label: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  title?: string;
  maxLength?: number;
  className?: string;
  disabled?: boolean;
  textFieldClassName?: string;
  textFielRow?: number;
}

const TextArea = ({
  id,
  label,
  value,
  onChange,
  onBlur,
  title,
  maxLength,
  className,
  disabled,
  textFieldClassName,
  textFielRow,
}: Props): JSX.Element => (
  <div className={`textarea ${className || ""}`}>
    {title && <p className="textarea-title">{title}</p>}
    <TextField
      multiline
      autoComplete="off"
      id={id}
      label={label}
      inputProps={{ maxLength: maxLength || 400 }}
      type="text"
      variant="filled"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={textFieldClassName ?? "textarea-field"}
      disabled={disabled}
      rows={textFielRow}
    />
    <p className="textarea-value">
      {value?.length || 0}/{maxLength || 400}
    </p>
  </div>
);

export default memo(TextArea);
