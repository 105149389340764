import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const GitHubProfile = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#181516"
      fillRule="evenodd"
      d="M14 2.567c-6.443 0-11.666 5.222-11.666 11.666 0 5.154 3.342 9.528 7.979 11.072.583.105.794-.254.794-.563 0-.277-.01-1.012-.013-1.984-3.246.704-3.931-1.565-3.931-1.565-.53-1.347-1.295-1.707-1.295-1.707-1.059-.724.08-.707.08-.707 1.172.082 1.787 1.202 1.787 1.202 1.04 1.783 2.732 1.268 3.394.97.107-.755.41-1.27.743-1.56-2.59-.294-5.313-1.295-5.313-5.767 0-1.272.455-2.315 1.198-3.13-.118-.295-.522-1.482.116-3.087 0 0 .979-.315 3.207 1.194.933-.258 1.929-.388 2.92-.393.993.005 1.99.135 2.92.393 2.228-1.51 3.206-1.194 3.206-1.194.638 1.605.238 2.792.116 3.088.749.814 1.198 1.856 1.198 3.129 0 4.483-2.725 5.468-5.325 5.757.416.36.79 1.072.79 2.16 0 1.56-.014 2.819-.014 3.201 0 .312.208.675.804.56 4.634-1.546 7.972-5.916 7.972-11.069 0-6.444-5.222-11.666-11.666-11.666z"
      clipRule="evenodd"
    />
  </svg>
);

export default GitHubProfile;
