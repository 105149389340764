import React, { memo, useEffect, useState } from "react";
import {
  Logout,
  LogoCourse,
  LogoWhiteMobile,
  ExitMobile,
} from "../../../assets/icons";
import { useAuth } from "../../auth";
import "./styles.scss";

interface Props {
  exit?: boolean;
}

const HeaderCourse = ({ exit }: Props): JSX.Element => {
  const { signOut } = useAuth();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="header-course">
      <div className="header-course-logo">
        {width < 991 ? <LogoWhiteMobile /> : <LogoCourse />}
      </div>
      {exit && (
        <div className="padding-bottom-24">
          <button
            type="button"
            onClick={signOut}
            className="header-course-button"
          >
            {width > 990 && "Sair"}
            {width < 991 ? (
              <ExitMobile />
            ) : (
              <Logout className="header-course-exit" />
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default memo(HeaderCourse);
