import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../auth";
import { Layout, Card } from "../../components";
import { getActiveUserClassVacancySubscription } from "../../services/functions";
import { setCourseSubscription } from "../../store/actions/courseActions";
import { RootState } from "../../store/reducers";
import { ClassVacancySubscription } from "../../types/interfaces";
import { hasCandidateRole } from "../../utils";
import { Status } from "./Steps";
import "./style.scss";
import Unsubscribe from "../../components/Unsubscribe";

const CourseTest = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const courseSubscription = useSelector(
    (state: RootState) => state.courseState,
  );
  const user = useSelector((state: RootState) => state.userState);
  const dispatch = useDispatch();
  const { signOut } = useAuth();

  useEffect(() => {
    if (user?._id && !hasCandidateRole(user)) signOut();
  }, [signOut, user]);

  const getSubscription = useCallback(async () => {
    const subscription = await getActiveUserClassVacancySubscription(user._id);
    if (subscription?.data?.length) {
      const dataSubscription: ClassVacancySubscription =
        subscription?.data?.[0];
      dispatch(setCourseSubscription(dataSubscription) as unknown as Action);
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (!courseSubscription?._id) getSubscription();
  }, [courseSubscription?._id, getSubscription]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderPages = useMemo(() => {
    switch (courseSubscription?.stage) {
      // case courseSubscription?.stage?.length === 1:
      //   return <Status />
      // case courseSubscription?.stage?.length === 2:
      //   return <LinkTest />
      // case courseSubscription?.stage?.length === 3:
      //   return <CourseResult />

      default:
        return <Status />;
    }
  }, [courseSubscription?.stage]);

  return (
    <Layout headerCourse exit className="grid-responsive">
      <Unsubscribe
        userId={user?._id}
        subscriptionId={courseSubscription?._id}
        className="unsubscribe-test"
      />
      {/* <div className="grid-column-2-5">
        <div className="container-step-test step-test-mobile">
          <CardSteps
            itemsMenu={itemsMenu}
            steps={{
              page: 3,
              maxMenuVisited: 3,
              maxPageVisited: 3,
            }}
            title="Etapas"
            className="card-steps-register step-course-registration card-step"
            notNavegation
          />
        </div>
      </div> */}
      {width > 990 ? (
        <Card
          bodyClassName="card-course"
          className="grid-column-2-12 border-card margin-top-150"
        >
          {renderPages}
        </Card>
      ) : (
        <Card
          bodyClassName="card-course"
          className="grid-column-1-11-mobile border-card staus-margin-mobile margin-top-212"
        >
          {renderPages}
        </Card>
      )}
    </Layout>
  );
};

export default memo(CourseTest);
