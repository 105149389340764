import React, { memo } from "react";
import {
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import LogoBlack from "../../../assets/images/LogoBlack.png";

const PrivacyPolicy = (): JSX.Element => (
  <div className="consent-form">
    <div className="consent-form-logo">
      <img alt="logoBlack" className="reprograma-logo" src={LogoBlack} />
    </div>

    <p className="consent-form-title">
      POLÍTICA DE PRIVACIDADE DA {`{REPROGRAMA}`}
    </p>
    <span>Última atualização em 23 de Janeiro de 2023</span>
    <p>
      A presente Política de Privacidade (“<b>Política</b>”) da{" "}
      <b>
        {`{REPROGRAMA}`} {`(“Nós” ou “{reprograma}”)`}
      </b>{" "}
      objetiva esclarecer as nossas práticas relativas ao Tratamento de Dados
      Pessoais no contexto de uso da nossa plataforma{" "}
      <b>(“Plataforma {`{reprograma}”`})</b> e dos cursos de programação
      voltados a mulheres cis e trans <b>(“Cursos”)</b>.
    </p>
    <div className="index">
      <span>Abaixo você encontrará informações sobre:</span>
      <p> ● Por que tratamos seus Dados Pessoais</p>
      <p> ● Com quem compartilhamos seus Dados Pessoais</p>
      <p>
        ● Quais são seus Diretos na qualidade de titular de seus Dados Pessoais
      </p>
      <p> ● Transferência internacional</p>
      <p> ● Segurança</p>
      <p> ● Prazo de Tratamento</p>
      <p> ● Atualizações desta Política</p>
      <p> ● Resolução de Disputas</p>
    </div>

    <ol>
      <li className="subtitle">
        <span>Definições e Disposições Gerais</span>

        <p>
          Todos os termos aqui usados, independentemente do gênero, são uma
          representação geral para todos os gêneros. E os termos em letra
          maiúscula da presente Política de Privacidade têm o mesmo significado
          abaixo indicado ou conforme previstos na LGPD.
        </p>
        <ol className="roman-list">
          <li>
            <b>Aluna(s) ou Você:</b> Significa a candidata nos processos
            seletivos ou matriculadas nos Cursos.
          </li>
          <li>
            <b>Controlador.</b> Significa a pessoa física ou jurídica a quem
            compete as decisões relacionadas aos Tratamentos de Dados Pessoais,
            cabendo a ele determinar as regras do Tratamento de acordo com seu
            modelo de negócios.
          </li>
          <li>
            <b>Dados Pessoais.</b> Significam quaisquer dados que identificam ou
            possam identificar uma pessoa natural.
          </li>
          <li>
            <b>Dados Pessoais Sensíveis.</b> Significam Dados Pessoais sobre
            origem racial ou étnica, convicção religiosa, opinião política,
            filiação a sindicato ou a organização de caráter religioso,
            filosófico ou político, dado referente à saúde ou à vida sexual,
            dado genético ou biométrico.
          </li>
          <li>
            <b>Empresas Parceiras.</b> Significam as empresas que apoiam os
            Serviços e Plataforma da {`{reprograma}`} e que têm interesse em
            recrutar as profissionais formadas em nossos Cursos e/ou Usuárias de
            nossa Plataforma.
          </li>
          <li>
            <b>Legislação Aplicável.</b> Todos os Tratamentos de Dados Pessoais
            realizados pela {`{reprograma}`} observam os termos da Lei no
            13.709/18 <b>(“LGPD”)</b>.
          </li>
          <li>
            <b>Operador.</b> Significa a pessoa física ou jurídica que Trata
            Dados Pessoais, processando e gerenciando as informações de acordo
            com as regras estabelecidas pelo Controlador.
          </li>
          <li>
            <b>Política.</b> Refere-se a esta Política de privacidade que tem
            como intuito regular os direitos e deveres da
            {`{reprograma}`} no que tange à proteção de Dados Pessoais e Dados
            Pessoais Sensíveis.
          </li>
          <li>
            <b>Plataforma {`{reprograma}`}.</b> Significa a plataforma que visa
            conectar as profissionais (Usuárias) e Empresas Parceiras por meio
            dos perfis profissionais criados pela Usuária e pelas Empresas
            Parceiras.
          </li>
          <li>
            <b>Responsável Legal:</b> Significa a pessoa com poder de
            representar uma menor de idade (Aluna), por exemplo, seu pai e/ou
            mãe.
          </li>
          <li>
            <b>Serviço(s).</b> Significa os serviços disponibilizados a Você por
            meio dos Cursos, quais sejam: ensino de programação para mulheres
            cis e trans que não têm recursos e/ou oportunidades para aprender a
            programar.
          </li>
          <li>
            <b>Titular de Dados.</b> Refere-se à pessoa natural a quem se
            referem os Dados Pessoais que são objeto de Tratamento.
          </li>
          <li>
            <b>Tratamento ou Tratar (e suas conjugações).</b> Tratamento de
            Dados Pessoais significa toda operação que pode ser realizada com
            seus Dados Pessoais, como coleta, uso, compartilhamento,
            armazenamento, exclusão.
          </li>
          <li>
            <b>Usuária ou Você.</b> Significa a pessoa física que utilizará a
            Plataforma{`{reprograma}`}.
          </li>
        </ol>
      </li>
      <li className="subtitle">
        <span>Por que Tratamos seus Dados Pessoais</span>
        <p>
          Sempre realizaremos o Tratamento lícito, benéfico e transparente dos
          seus Dados Pessoais. Por isso, todos os Dados Pessoais sobre Você são
          Tratados por nós para os fins gerais aqui descritos. Nossa intenção é
          fomentar uma cultura de diversidade e inclusão nas empresas,
          contribuir para a formação e acesso ao mercado de trabalho de mulheres
          cis, trans, pretas, pardas e/ou em vulnerabilidade socioeconômica.
          Sendo assim, os Dados Pessoais solicitados cumprem este propósito.
          Você deve se atentar ao fato de que eventual uso indevido dos Dados
          Pessoais publicados na Plataforma {`{reprograma}`} pelas Usuárias não
          está sob nosso controle ou responsabilidade. Repudiamos e vedamos o
          uso indevido dos Dados Pessoais publicizados por Você por quaisquer
          terceiros.
          <br />
          <br />
          As Empresas Parceiras que acessam a Plataforma {`{reprograma}`} ou que
          venham a interagir com os Dados Pessoais das Usuárias da Plataforma{" "}
          {`{reprograma}`} nos Cursos são Controladoras de Dados e sujeitas aos
          limites e responsabilidades legais sobre seu uso.
          <br />
          <br />
          As tabelas abaixo indicam quais as finalidades do Tratamento de Dados
          Pessoais realizado por nós, dividida nos contextos (i) Plataforma{" "}
          {`{reprograma}`} e (ii) Cursos.
        </p>
        <div className="title-table-term">
          <p>PLATAFORMA {`{reprograma}`}</p>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="simple table" className="table">
            <TableHead className="table-head">
              <TableRow className="table-row">
                <TableCell>Categoria de Dado Pessoal</TableCell>
                <TableCell>
                  Especificação de Dados Pessoais das Usuárias
                </TableCell>
                <TableCell>Finalidades</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className="table-row">
                <TableCell>Dados Pessoais e Dados Sensíveis</TableCell>
                <TableCell>
                  Dados Pessoais: Nome, nome legal, pronome, data de nascimento,
                  CPF (ou outro documento legal, como RNE), telefone(s), e-mail,
                  endereço residencial, nacionalidade, redes sociais, se tem
                  internet, se tem computador pessoal, se está empregada, se
                  está desempregada, há quanto tempo, cargo/função, nível
                  escolar, nível de inglês, se obteve bolsa escolar, renda
                  mensal individual, renda mensal da família, quantas pessoas
                  moram com a candidata, se tem filhos, quantos e idade, se
                  é/foi voluntária, vídeo e/ou áudio. Dados Sensíveis: Gênero,
                  deficiência (CID), raça, orientação sexual
                </TableCell>
                <TableCell>
                  Estes Dados Pessoais são utilizados na Plataforma{" "}
                  {`{reprograma}`}
                  para coleta de dados de candidatas para o processo seletivo de
                  cursos oferecidos.
                  <br />
                  <br />
                  Além disso, somente os dados que são informados por Você,
                  serão disponibilizados às Empresas Parceiras que utilizam a
                  Plataforma.
                </TableCell>
              </TableRow>

              <TableRow className="table-row">
                <TableCell>Dados Pessoais e Dados Sensíveis</TableCell>
                <TableCell>
                  Dados Pessoais: Nome, CPF, E-mail, Endereço, Foto, Data da
                  Nascimento, Telefone (WhatsApp), Usuários Redes Sociais
                  (Facebook, Instagram, Linkedln, Tiktok, Twitter, GitHub),
                  currículo, experiência profissional, formação, idiomas e
                  portfólio. Dados Sensíveis: Gênero, deficiência (CID), raça
                </TableCell>
                <TableCell>
                  Estes Dados Pessoais são utilizados na Plataforma{" "}
                  {`{reprograma}`} para cadastro, criação de um perfil
                  individual, para interação com outros perfis/Empresas
                  Parceiras para o preenchimento de vagas, inserção em lista de
                  e-mails, divulgação das competências das Usuárias em busca de
                  oportunidades de emprego.
                  <br />
                  <br /> Você pode escolher se quer criar o perfil ou não. Para
                  aquelas que optarem por fazer um perfil, os Dados Pessoais (à
                  exceção das credenciais de acesso e dados cadastrais básicos
                  vinculados ao perfil), a serem inseridos na Plataforma, são
                  todos facultativos. Somente os dados que voluntariamente sejam
                  informados por Você estarão disponíveis para as Empresas
                  Parceiras que utilizam a Plataforma.
                  <br />
                  <br /> Observe que alguns Dados Pessoais e Dados Pessoais
                  Sensíveis são opcionais, mas encorajamos seu fornecimento pois
                  possuem a finalidade de tornar seu perfil mais atrativo,
                  fomentar as medidas de diversidade, contribuir para formação
                  de indicadores de sucesso de empregabilidade por nós e
                  facilitar a interação com outros perfis e Empresas Parceiras
                  para uma vaga adequada de acordo com cada perfil.
                </TableCell>
              </TableRow>

              <TableRow className="table-row">
                <TableCell>
                  Dados Pessoais de funcionários das Empresas Parceiras
                </TableCell>
                <TableCell>
                  Nome, data de nascimento, cargo na empresa, unidade de
                  negócio, telefone, e-mail, foto
                </TableCell>
                <TableCell>
                  Estes Dados Pessoais são utilizados na Plataforma{" "}
                  {`{reprograma}`} para conceder acesso e possibilitar
                  interações com as Usuárias da Plataforma a partir do perfil de
                  representantes das Empresas Parceiras, de acordo com o
                  pactuado junto às Empresas Parceiras, visando conectar
                  oportunidades de trabalho às profissionais, conforme interesse
                  das partes.
                </TableCell>
              </TableRow>

              <TableRow className="table-row">
                <TableCell>Dados de Acesso</TableCell>
                <TableCell>Login e senha</TableCell>
                <TableCell>
                  Para termos rastreabilidade quanto a efetivação de login na
                  Plataforma
                  {`{reprograma}`}, por questão de segurança.
                  <br />
                  <br /> Ressaltamos que não visualizamos sua senha, a qual é
                  criptografada na Plataforma
                  {`{reprograma}`} da seguinte maneira:
                  <br />
                  <br /> Em repouso: criptografia md5 + BCrypt
                  <br />
                  <br />
                  Em transporte: criptografia md5 + BCrypt + AE
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <div className="margin-top-32">
          <div className="title-table-term">
            <p>CURSOS {`{reprograma}`}</p>
          </div>
          <TableContainer>
            <Table stickyHeader aria-label="simple table" className="table">
              <TableHead className="table-head">
                <TableRow className="table-row">
                  <TableCell>Categoria de Dado Pessoal</TableCell>
                  <TableCell>
                    Especificação de Dados Pessoais das Alunas
                  </TableCell>
                  <TableCell>Finalidades</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow className="table-row">
                  <TableCell>
                    Dados de potenciais Alunas e seus Responsáveis Legais*
                  </TableCell>
                  <TableCell>
                    Potencial Aluna:
                    <br />
                    <p>
                      ● Dados Pessoais: Nome, nome legal, pronome, data de
                      nascimento, CPF (ou outro documento legal, como RNE),
                      telefone(s), e-mail, endereço residencial, nacionalidade,
                      redes sociais, se tem internet, se tem computador pessoal,
                      se está empregada, se está desempregada, há quanto tempo,
                      cargo/função, nível escolar, nível de inglês, se obteve
                      bolsa escolar, renda mensal individual, renda mensal da
                      família, quantas pessoas moram com a candidata, se tem
                      filhos, quantos e idade, se é/foi voluntária, vídeo e/ou
                      áudio.
                    </p>
                    <p>
                      ● Dados Sensíveis: raça, gênero, deficiência, se membro da
                      comunidade LGBTQIAP+.
                    </p>
                    Responsáveis Legais1: Nome, e-mail, telefone e CPF, RG,
                    endereço.
                  </TableCell>
                  <TableCell>
                    Procedimentos de processo seletivo nos casos em que a oferta
                    de vagas for insuficiente com relação à demanda, realização
                    de pré-matrícula e matrícula. Usamos as informações para
                    priorizar e viabilizar o acesso ao curso a pessoas em
                    vulnerabilidade social.
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableBody>
                <TableRow className="table-row">
                  <TableCell>Dados de Potenciais Alunas</TableCell>
                  <TableCell>
                    Nome da candidata, gênero, endereço, nacionalidade, e-mail,
                    contato de celular, documento de identidade, CPF.
                  </TableCell>
                  <TableCell>
                    Envio de comunicados institucionais sobre o status das
                    candidatas no processo seletivo dos cursos, o que inclui o
                    fomento e auxílio à participação de mulheres trans e
                    travestis durante o processo seletivo, através de
                    comunicações direcionadas a elas via E-mail e Whatsapp.
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableBody>
                <TableRow className="table-row">
                  <TableCell>Dados de Cadastro das Alunas.</TableCell>
                  <TableCell>
                    Nome, data de nascimento, raça, gênero, se membro da
                    comunidade LGBTQIAP+, nome do responsável, CPF, e-mail,
                    telefone, fotografia, endereço, certidão de nascimento,
                    declaração de escolaridade, comprovante de residência.
                  </TableCell>
                  <TableCell>
                    (i) Possibilitar a inscrição e matrícula;
                    <br />
                    (ii) Emitir declarações acadêmicas, certidões, certificados
                    e demais atividades das rotinas pedagógicas e
                    administrativas; e
                    <br />
                    (iii) Facilitar oportunidades de trabalho oferecidas pelas
                    Empresas Parceiras.
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableBody>
                <TableRow className="table-row">
                  <TableCell>Dados de performance das Alunas.</TableCell>
                  <TableCell>
                    Controle de frequência, avaliações, histórico no curso,
                    trabalhos acadêmicos, resultados e notas, informações sobre
                    aptidões.
                  </TableCell>
                  <TableCell>
                    (i) Viabilizar o oferecimento dos Serviços; e<br />
                    (ii) Facilitar oportunidades de trabalho oferecidas pelas
                    Empresas Parceiras.
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableBody>
                <TableRow className="table-row">
                  <TableCell>Dados Pessoais das Alunas</TableCell>
                  <TableCell>
                    Nome, curso, foto, vídeo e voz, captados durante atividades
                    do Curso ou eventos da {`{reprograma}`}.
                  </TableCell>
                  <TableCell>
                    Divulgar os eventos e atividades desempenhadas pela{" "}
                    {`{reprograma}`} para o público (uso não doméstico),
                    incluindo distribuição gratuita por meio de quaisquer
                    mídias, inclusive por meio do site/portal da
                    {`{reprograma}`} na Internet, suas redes sociais e a de
                    parceiros e quaisquer canais vinculados à {`{reprograma}`} e
                    aos parceiros (mediante termo autorizativo).
                  </TableCell>
                </TableRow>
              </TableBody>
              <TableBody>
                <TableRow className="table-row">
                  <TableCell>Dados Pessoais</TableCell>
                  <TableCell>
                    Nome, e-mail e confirmação de matrícula (acesso a cópia do
                    RG ou CPF e ao comprovante de residência).
                  </TableCell>
                  <TableCell>
                    Comunicados oficiais sobre o curso para a turma via Grupos
                    do Telegram e, ao fim do curso, sobre novos cursos e
                    oportunidades (turmas adolescentes).
                  </TableCell>
                </TableRow>
              </TableBody>

              <TableBody>
                <TableRow className="table-row">
                  <TableCell>Dados Pessoais e Dados Sensíveis</TableCell>
                  <TableCell>
                    Dados Pessoais: Nome, nome legal, data de nascimento, RG,
                    telefone(s), e-mail, endereço residencial, cargo/função,
                    nível escolar, se obteve bolsa escolar, renda mensal
                    individual, renda mensal da família, quantas pessoas moram
                    com a candidata, idade, se é/foi voluntária, como ficou
                    sabendo do curso Dados Sensíveis: Gênero, deficiência (CID),
                    raça, orientação sexual
                  </TableCell>
                  <TableCell>
                    Coleta de dados das alunas e/ou de seus responsáveis legais
                    (no caso de menores) nas inscrições para selecionar as
                    alunas que estão concorrendo a vaga enquanto mentora ou
                    algum outro projeto de extensão oferecido por Nós e pelas
                    Empresas Parceiras do projeto em questão.
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <h6>
            *Coletamos Dados Pessoais de Responsáveis Legais somente para
            eventuais Cursos direcionados às Alunas menores de 18 anos.
          </h6>
        </div>
      </li>
      <li className="subtitle">
        <span>Compartilhamento de Dados Pessoais</span>
        <p>
          A {`{reprograma}`} poderá fornecer seus Dados Pessoais a terceiros,
          nas hipóteses indicadas abaixo. Note que somente compartilharemos suas
          informações se for estritamente necessário.
        </p>
        <TableContainer>
          <Table stickyHeader aria-label="simple table" className="table">
            <TableHead className="table-head">
              <TableRow className="table-row">
                <TableCell>
                  Terceiros com quem podemos compartilhar Dados Pessoais das
                  Usuárias e Alunas
                </TableCell>
                <TableCell>Finalidades</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className="table-row">
                <TableCell>Empresas Parceiras</TableCell>
                <TableCell>
                  Para que a Empresa Parceira possa realizar análise do
                  perfil/currículo da Usuária ou Aluna para potenciais
                  contratações, a{`{reprograma}`} poderá compartilhar os
                  seguintes Dados Pessoais, além daqueles que estão disponíveis
                  na Plataforma {`{reprograma}`}
                  * :<b />● Nome, e-mail, redes sociais (GitHub), raça, gênero,
                  idade, se é membro da comunidade LGBTQIAP+, atividades do
                  curso, experiência profissional, portfólio, certificado e
                  habilidades, tudo baseado em autodeclaração.
                </TableCell>
              </TableRow>
            </TableBody>

            <TableBody>
              <TableRow className="table-row">
                <TableCell>Empresas Parceiras (Cursos)</TableCell>
                <TableCell>
                  Para que possamos fornecer relatórios visando a prestação de
                  contas, inclusive quanto às metas de inclusão, alguns Dados
                  Pessoais e Dados Sensíveis (necessidades especiais, raça,
                  identidade de gênero) poderão ser compartilhados com Empresas
                  Parceiras que apoiam nossos cursos, mediante sua prévia
                  autorização. O compartilhamento de Dados Sensíveis com as
                  Empresas Parceiras ocorrerá, sempre que possível, de maneira
                  anonimizada, agregada, estatística ou pseudonimizada.
                </TableCell>
              </TableRow>
            </TableBody>

            <TableBody>
              <TableRow className="table-row">
                <TableCell>Fornecedores e Prestadores de Serviço</TableCell>
                <TableCell>
                  Poderemos compartilhar seus Dados Pessoais para terceiros que
                  auxiliem a {`{reprograma}`} a atingir as finalidades indicadas
                  no Item 3 acima e para prestadores de serviços que operam a
                  infraestrutura técnica do Serviço. Seus Dados Pessoais poderão
                  ser compartilhados com prestadores de programas de computador
                  de apoio aos chamados de Usuário.
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow className="table-row">
                <TableCell>Autoridades</TableCell>
                <TableCell>
                  Compartilharemos seus Dados Pessoais quando acreditarmos que
                  seja necessário para o cumprimento de uma obrigação ou
                  prerrogativa legal, nos termos da legislação aplicável ou para
                  responder a uma decisão judicial, como um mandado de busca,
                  uma ordem judicial, uma intimação ou uma ordem de
                  interceptação legal de comunicações.
                </TableCell>
              </TableRow>
            </TableBody>

            <TableBody>
              <TableRow className="table-row">
                <TableCell>Terceiro</TableCell>
                <TableCell>
                  Para viabilizar eventuais reorganizações ou reestruturações do
                  negócio, auditorias para avaliação da {`{reprograma}`},
                  poderemos compartilhar parte de seus Dados Pessoais com
                  terceiros que façam parte dos negócios, como contadores,
                  auditores, consultores financeiros e advogados.
                  <b />
                  <b />
                  Nessa hipótese, adotaremos as medidas necessárias para
                  garantir que seus Dados Pessoais continuem a ser protegidos,
                  conforme esta Política de Privacidade.
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow className="table-row">
                <TableCell>Outros</TableCell>
                <TableCell>
                  Poderemos compartilhar os seus Dados Pessoais se acreditarmos
                  ser necessário para: (i) cumprimento de lei, (ii) exercício
                  regular de direitos em processos, (iii) segurança de qualquer
                  pessoa, e/ou (iv) para impedir a morte ou danos físicos
                  iminentes.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <h6>
          *Somente os dados que voluntariamente sejam informados por Você,
          estarão disponíveis para as Empresas Parceiras que utilizam a
          Plataforma.
        </h6>
      </li>
      <li className="subtitle">
        <span>Direitos dos Titulares de Dados Pessoais</span>
        <p>
          Em relação aos seus Dados Pessoais e Tratamentos realizados pela{" "}
          {`{reprograma}`}, Você tem os direitos indicados na tabela abaixo, os
          quais serão analisados e respondidos conforme viabilidade de
          atendê-los, na forma da lei aplicável.
        </p>
        <div className="title-table-term">
          <p>Direitos</p>
        </div>
        <TableContainer>
          <Table stickyHeader aria-label="simple table" className="table">
            <TableBody>
              <TableRow className="table-row">
                <TableCell>Acesso</TableCell>
                <TableCell>
                  A qualquer momento Você poderá requisitar cópia dos seus Dados
                  armazenados em nossos sistemas. Você poderá solicitar uma
                  cópia: (1) das categorias e dos Dados Pessoais específicos que
                  coletamos sobre Você; (2) das categorias e das fontes
                  específicas das quais os Dados Pessoais são coletados; (3) da
                  finalidade da coleta dos seus Dados Pessoais; e (4) das
                  categorias e dos terceiros específicos com quem compartilhamos
                  os seus Dados Pessoais.
                </TableCell>
              </TableRow>
            </TableBody>

            <TableBody>
              <TableRow className="table-row">
                <TableCell>Correção e Atualização</TableCell>
                <TableCell>
                  Solicitar a correção e atualização de seus Dados Pessoais. Se
                  for o caso, informaremos a correção e atualização aos
                  terceiros com os quais os seus Dados Pessoais tenham sido
                  compartilhados.
                </TableCell>
              </TableRow>
            </TableBody>

            <TableBody>
              <TableRow className="table-row">
                <TableCell>Portabilidade</TableCell>
                <TableCell>
                  Solicitar transferência de seus Dados Pessoais da base de
                  Dados da {`{reprograma}`}
                  para terceiro.
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow className="table-row">
                <TableCell>Revogação de Consentimento</TableCell>
                <TableCell>
                  Revogar consentimento concedido por Você. Caso Você solicite a
                  revogação de seu consentimento, a utilização do Serviço poderá
                  ser imediata e automaticamente cancelada, de modo que você não
                  terá acesso ao Serviço, a partir da revogação do consentimento
                  concedido por você.
                </TableCell>
              </TableRow>
            </TableBody>

            <TableBody>
              <TableRow className="table-row">
                <TableCell>Restrição, Oposição ou Exclusão</TableCell>
                <TableCell>
                  Caso suspeite que haja algum Tratamento em desconformidade com
                  a LGPD, você pode: (i) solicitar o bloqueio de seus Dados
                  Pessoais, suspendendo temporariamente a nossa possibilidade de
                  Tratá-los , (ii) se opor ao Tratamento de seus Dados Pessoais,
                  (iii) bem como solicitar eliminação de seus dados, caso em que
                  apagaremos todos os seus dados sem possibilidade de reversão,
                  salvo os casos previstos em lei.
                  <b />
                  <b />
                  Poderemos manter seus Dados Pessoais se necessário para (i)
                  para cumprir a legislação aplicável; ou (ii) cumprimento de
                  contrato ou para exercer nossos direitos em processos
                  judiciais, administrativos ou arbitrais. Exceto nos casos
                  mencionados, excluiremos ou manteremos seus dados anonimizados
                  (utilizaremos a técnica disponível a época).
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow className="table-row">
                <TableCell>
                  Solicitar Revisão de Decisões Automatizadas
                </TableCell>
                <TableCell>
                  A Plataforma {`{reprograma}`} não realiza decisões
                  automatizadas com os Dados Pessoais. Mas, caso algum dia
                  realizemos (atualizaremos esta Política), você terá o direito
                  de solicitar a revisão de decisões tomadas unicamente com base
                  em Tratamento automatizado de seus Dados Pessoais (por
                  exemplo, cálculo de risco ou de probabilidade, ou outro
                  semelhante, realizado pelo Tratamento de Dados Pessoais
                  utilizando regras, cálculos, instruções, algoritmos, análises
                  estatísticas, inteligência artificial, aprendizado de máquina,
                  ou outra técnica computacional).
                </TableCell>
              </TableRow>
            </TableBody>
            <TableBody>
              <TableRow className="table-row">
                <TableCell>Reclamação à ANPD</TableCell>
                <TableCell>
                  Você pode realizar reclamações para a ANPD sobre Tratamentos
                  de Dados Pessoais realizados pela {`{reprograma}`}. Você pode
                  entrar em contato com a ANPD nos meios indicados no site da
                  autoridade:
                  <a
                    href="https://www.gov.br/anpd/pt-br"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ANPD — Português (Brasil) (www.gov.br)
                  </a>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <ol className="roman-list">
          <li>
            É necessária a comprovação de sua identidade e/ou de seu procurador
            para que possamos garantir o exercício dos seus direitos. Esta é uma
            medida de segurança para garantir que os Dados Pessoais não sejam
            divulgados a qualquer pessoa que não tenha direito de recebê-los.
          </li>
          <li>
            A {`{reprograma}`} possui uma Encarregada de Proteção de Dados
            Pessoais nomeada que poderá ser contatada por meio do e-mail
            dados@reprograma.com.br. Nossa Encarregada está disponível para
            atender solicitações, reclamações ou dúvidas. Responderemos a
            mensagem no menor tempo possível.
          </li>
        </ol>
      </li>
      <li className="subtitle">
        <span>Segurança da Informação</span>
        <p>
          A {`{reprograma}`} adota medidas técnicas e administrativas de
          segurança para proteger os Dados Pessoais que trata, em conformidade
          com o padrão de mercado. Dentre as medidas de segurança que adotamos
          estão:
        </p>
        <p>
          O acesso aos Dados Pessoais limitado a pessoas previamente autorizadas
          e submetidas a dever de confidencialidade.
        </p>
        <div className="index">
          <p>
            ✔ Realização de treinamentos aos colaboradores que possuem acesso a
            Dados Pessoais sobre aspectos de proteção de dados.
          </p>
          <p>
            {" "}
            ✔ Controle de aspectos de proteção de dados em contratos celebrados
            com Empresas Parceiras, prestadores de serviço ou terceiros que
            Tratem Dados Pessoais.
          </p>
          <p>
            ✔ Registro das operações de Tratamento de Dados Pessoais
            (inventário).
          </p>
          <p>
            {" "}
            ✔ Sistemas de segurança da informação, como antivírus e
            criptografia.
          </p>
        </div>
      </li>
      <li className="subtitle">
        <span>Prazo do Armazenamento dos Dados</span>
        <p>
          Manteremos os dados e informações somente até quando estas forem
          necessárias ou relevantes para as finalidades descritas nesta Política
          de Privacidade e conforme a relação contratual firmada com Você, ou em
          caso de períodos pré-determinados por lei, ou seja, quando tivermos
          uma obrigação ou prerrogativa de retenção fundada em lei ou
          regulamento.
        </p>
        <ol className="roman-list">
          <li>
            Para determinarmos o período de retenção adequado para os Dados
            Pessoais, consideramos a quantidade, a natureza e a sensibilidade
            dos Dados Pessoais, o risco potencial de danos decorrentes do uso
            não autorizado ou da divulgação de seus Dados Pessoais, a finalidade
            de Tratamento dos seus Dados Pessoais e se podemos alcançar tais
            propósitos através de outros meios, observando os requisitos legais
            aplicáveis.
          </li>
        </ol>
      </li>
      <li className="subtitle">
        <span>Transferências Internacionais</span>
        <p>
          Os Dados Pessoais e Dados Pessoais Sensíveis coletados pela{" "}
          {`{reprograma}`} sempre serão tratados com base na LGPD. A{" "}
          {`{reprograma}`} está sediada no Brasil, mas nossos dados são
          armazenados e processados nos EUA, por meio dos fornecedores tais como
          AWS, MongoDB, Redash, Google Drive, Typeform.
        </p>
        <p>
          Além disso, para melhorar a entrega dos nossos serviços, dependendo do
          lugar que Você estiver no mundo, podemos transferir a plataforma para
          lhe atender melhor. Isto não significa que seus Dados serão
          armazenados em outros locais, mas sim que estes Dados podem ser
          enviados temporariamente para tais lugares, seguindo os conceitos
          tecnológicos de CDN (Rede de Entrega de Conteúdo - é um grupo de
          servidores geograficamente distribuídos que aceleram a entrega do
          conteúdo da Web, aproximando-o de onde os usuários estão).
        </p>
        <p>
          Nesse caso, garantimos que as nossas subcontratadas localizadas no
          exterior, empresas prestadoras de serviços ou parceiras, serão
          submetidas a mecanismos legalmente disponíveis para efetuar a
          transferência, visando vinculá-los também às obrigações da LGPD.
          Assim, ao contratar os nossos Serviços e concordar com esta Política
          de Privacidade, o Usuário concorda também que os Dados Pessoais e
          Dados Pessoais Sensíveis podem estar sujeitos a transferências
          internacionais e se declara ciente das garantias aplicáveis ao
          Tratamento no exterior.{" "}
        </p>
      </li>
      <li className="subtitle margin-left-10">
        <span> Atualizações</span>
        <p>
          Esta Política de Privacidade poderá ser alterada sempre que a{" "}
          {`{reprograma}`} entender necessário. Você encontrará no início deste
          documento a data da última atualização e, em caso de alterações
          significativas, Você será diretamente informada.
        </p>
      </li>
      <li className="subtitle margin-left-10">
        <span> Foro</span>
        <p>
          Este documento é regido e deve ser interpretado de acordo com as leis
          da República Federativa do Brasil. Fica eleito o Foro da Comarca de
          São Paulo/SP, como o competente para dirimir quaisquer questões
          porventura oriundas do presente documento, com expressa renúncia a
          qualquer outro, por mais privilegiado que seja.
        </p>
      </li>
    </ol>

    <p>
      Caso <span> ACEITE</span> este termo " Política de Privacidade{" "}
      {`{REPROGRAMA}"`} ", por favor, clique em <span>“SIM”</span>.
    </p>
  </div>
);

export default memo(PrivacyPolicy);
