/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { STEP_REGISTER } from "../types/stepRegisterTypes";

export const setStepRegister = (stepRegister: any) => (dispatch: any) => {
  dispatch({
    type: STEP_REGISTER,
    payload: stepRegister,
  });
};
