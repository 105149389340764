import React, { ComponentType, FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  RouteProps as ReactRouterDOMRouterProps,
  Route as ReactDOMRoute,
  Redirect,
} from "react-router-dom";
import { useAuth } from "../auth";
import { setCompany } from "../store/actions/companyActions";
import { setUser } from "../store/actions/userActions";
import { RootState } from "../store/reducers";
import {
  hasAnyAdminRole,
  hasCandidateRole,
  hasCorporateRole,
  hasStudentRole,
  isActiveCompany,
  isActiveUser,
  isCorporate,
} from "../utils";
import { Errors, Loading, Login } from "../pages";
import { userStatus } from "../types/enumerators";

interface RouteProps extends ReactRouterDOMRouterProps {
  isPrivate?: boolean;
  isActive?: boolean;
  type?: string[];
  component: ComponentType;
}

const Route: FC<RouteProps> = ({
  isPrivate = false,
  isActive = false,
  type = [],
  component: Component,
  ...rest
}) => {
  const userRedux = useSelector((state: RootState) => state.userState);
  const companyRedux = useSelector((state: RootState) => state.companyState);
  const user = JSON.parse(sessionStorage.getItem("@SESSION:user") ?? "null");
  const company = JSON.parse(
    sessionStorage.getItem("@SESSION:company") ?? "null",
  );
  const typeSS = sessionStorage.getItem("@SESSION:type") ?? "NONE";
  const token = sessionStorage.getItem("@SESSION:token");
  const { isValidToken, signOut } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && !!user?._id && !userRedux?._id) {
      if (isValidToken(token)) dispatch(setUser(user) as unknown as Action);
      else signOut();
    }
  }, [dispatch, isValidToken, signOut, token, user, userRedux?._id]);

  useEffect(() => {
    if (
      token &&
      !!user?._id &&
      !!company &&
      hasCorporateRole(user) &&
      !companyRedux?._id
    ) {
      if (isValidToken(token))
        dispatch(setCompany(company) as unknown as Action);
      else signOut();
    }
  }, [
    company,
    companyRedux?._id,
    dispatch,
    isValidToken,
    signOut,
    token,
    user,
  ]);

  useEffect(() => {
    if (
      (typeSS === "ADMIN" && !hasAnyAdminRole(user)) ||
      (typeSS === "CANDIDATE" && !hasCandidateRole(user)) ||
      (typeSS === "CORPORATE" && !hasCorporateRole(user)) ||
      (typeSS === "STUDENT" && !hasStudentRole(user))
    )
      signOut();
  }, [signOut, typeSS, user]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (!isPrivate) return <Component />;

        if (
          user?.account?.status === userStatus.DISABLED &&
          typeSS !== "CANDIDATE"
        )
          return <Errors error={403} />;

        if (
          isPrivate &&
          !!user?._id &&
          !!token &&
          (!isCorporate(user) || (isCorporate(user) && !!company?._id))
        ) {
          if (
            type?.length > 0 &&
            !type?.find((item) => user?.account?.roles?.includes(item))
          )
            return <Errors error={403} />;

          if (
            isActive &&
            (!isActiveUser(user) ||
              (isCorporate(user) && !isActiveCompany(company)))
          )
            return (
              <Redirect
                exact
                from="/"
                to={{
                  pathname: "/welcome",
                  state: { from: location },
                }}
              />
            );

          return <Component />;
        }

        if (isPrivate && !user?._id && !token) return <Login />;

        return <Loading />;
      }}
    />
  );
};

export default Route;
