/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-console */
import moment from "moment";
import React, {
  ChangeEvent,
  createRef,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { toast } from "react-toastify";
import { Tooltip } from "@material-ui/core";
import {
  AccessibilitySelection,
  AttachmentPhoto,
  Checkbox,
  Input,
  LabelInput,
  Select,
  TextArea,
} from "..";
import api from "../../services/api";
import { setNextButton } from "../../store/actions/nextButtonAction";
import { maskBRlegalIdentity, maskDate } from "../../utils";
import {
  IdentityType,
  ProfileGender,
  ProfilePronouns,
  ProfileRace,
} from "../../types/constants/User";
import { RootState } from "../../store/reducers";
import { updateUser } from "../../store/actions/userActions";
import { Info } from "../../../assets/icons";
import { UserProfile } from "../../types/interfaces";
import {
  userGenderIdentity,
  userIdentityType,
  userPronous,
  userRace,
} from "../../types/enumerators";

interface Item {
  isFullRow?: boolean;
  renderCondition?: boolean;
  className?: string;
  child: ReactNode;
}
interface Props {
  registration?: boolean;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const FormPersonalData = ({
  registration = false,
  setIsValid,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const expandedAttribute = "expanded";
  const heightProperty = "height";
  const fullRowClassName = "grid-full-row";

  const [width, setWidth] = useState(window.innerWidth);
  const [hasOtherDocument, setHasOtherDocument] = useState(false);
  const user = useSelector((state: RootState) => state.userState);
  const [_profile, _setProfile] = useState<UserProfile>(user?.profile);

  const {
    name,
    pronouns,
    otherPronouns,
    photo,
    birthdate,
    identityType,
    legalIdentity,
    genderIdentity,
    genderIdentityDescription,
    disabledPerson,
    disabledPersonDescription,
    race,
    raceDescription,
    bio,
  } = useMemo(() => _profile, [_profile]);

  const [ofAge, setOfAge] = useState<boolean>();

  const basicInfoValidation = useMemo(
    () =>
      !name ||
      !pronouns ||
      !birthdate ||
      (birthdate && birthdate?.length !== 10) ||
      !moment(birthdate, "DD/MM/YYYY").isValid(),
    [birthdate, name, pronouns],
  );

  const deficiencyValidation = useMemo(
    () =>
      basicInfoValidation ||
      !legalIdentity ||
      !genderIdentity ||
      (genderIdentity === userGenderIdentity.OTHERS &&
        !genderIdentityDescription),
    [
      genderIdentity,
      genderIdentityDescription,
      basicInfoValidation,
      legalIdentity,
    ],
  );

  const isDisabled = useMemo(
    () => ({
      pronouns: !name,
      birthdate: !name || !pronouns,
      document: !ofAge || basicInfoValidation || hasOtherDocument,
      hasOtherdocument: !ofAge || !name || !pronouns || !birthdate,
      identityType: !ofAge || basicInfoValidation || !hasOtherDocument,
      otherDocument:
        !ofAge ||
        basicInfoValidation ||
        (hasOtherDocument &&
          (!identityType || identityType === userIdentityType.BR_CPF)),
      gender:
        !ofAge ||
        basicInfoValidation ||
        !legalIdentity ||
        (hasOtherDocument &&
          (!identityType || identityType === userIdentityType.BR_CPF)) ||
        (hasOtherDocument && !legalIdentity) ||
        (!hasOtherDocument && legalIdentity?.length !== 11),
      deficiency:
        !ofAge ||
        !legalIdentity ||
        (hasOtherDocument &&
          (!identityType || identityType === userIdentityType.BR_CPF)) ||
        (hasOtherDocument && !legalIdentity) ||
        (!hasOtherDocument && legalIdentity?.length !== 11) ||
        deficiencyValidation,
      race:
        !ofAge ||
        !legalIdentity ||
        (hasOtherDocument &&
          (!identityType || identityType === userIdentityType.BR_CPF)) ||
        (hasOtherDocument && !legalIdentity) ||
        (!hasOtherDocument && legalIdentity?.length !== 11) ||
        deficiencyValidation ||
        disabledPerson === undefined ||
        (disabledPerson && !disabledPersonDescription),
      bio:
        !ofAge ||
        !legalIdentity ||
        (hasOtherDocument &&
          (!identityType || identityType === userIdentityType.BR_CPF)) ||
        (hasOtherDocument && !legalIdentity) ||
        (!hasOtherDocument && legalIdentity?.length !== 11) ||
        deficiencyValidation ||
        (!disabledPerson !== true && !disabledPersonDescription) ||
        !race ||
        (race === userRace.OTHERS && !raceDescription),
    }),
    [
      name,
      pronouns,
      ofAge,
      basicInfoValidation,
      hasOtherDocument,
      birthdate,
      identityType,
      legalIdentity,
      deficiencyValidation,
      disabledPerson,
      disabledPersonDescription,
      race,
      raceDescription,
    ],
  );

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) => _setProfile({ ..._profile, [prop]: event.target.value }),
    [_profile],
  );

  const handleChangeDate = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (moment().diff(moment(maskDate(event.target.value)), "years") < 18)
        setOfAge(false);
      _setProfile({
        ..._profile,
        birthdate: maskDate(event.target.value),
      });
    },
    [_profile],
  );

  const handleChangeSelect = useCallback(
    (event: { target: { value: unknown } }, prop: string) =>
      _setProfile({ ..._profile, [prop]: event.target.value as string }),
    [_profile],
  );

  const handleOnBlur = useCallback(() => {
    if (!isDisabled.bio) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              profile: { ..._profile },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [_profile, dispatch, isDisabled.bio, setIsValid]);

  const onChangeImage = useCallback(
    async (e) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      try {
        await api
          .post("/fileUpload", data)
          .then((result) => {
            _setProfile({ ..._profile, photo: result?.data?.file_url.url });
            dispatch(
              setNextButton(() =>
                dispatch(
                  updateUser({
                    profile: {
                      ..._profile,
                      photo: result?.data?.file_url.url,
                    },
                  }) as unknown as Action,
                ),
              ) as unknown as Action,
            );
          })
          .catch(() => {
            toast.error("Ocorreu um erro durante o upload do arquivo");
          });
      } catch {
        console.warn("err");
      }
    },
    [_profile, dispatch],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const items = useMemo(
    (): Item[] => [
      {
        isFullRow: true,
        child: (
          <AttachmentPhoto photo={photo} type="foto" onChange={onChangeImage} />
        ),
      },
      {
        child: (
          <>
            {registration && (
              <div className="flex justify-between align-items-end">
                {registration && (
                  <LabelInput text="Para começar, como podemos te chamar?" />
                )}
                <Tooltip
                  title={
                    <p>
                      Mulheres trans e travestis, mesmo sem ter retificado o
                      nome em seus documentos pessoais, informam aqui seu nome
                      social.
                    </p>
                  }
                >
                  <div>
                    <Info className="cursor-pointer" />
                  </div>
                </Tooltip>
              </div>
            )}
            <div>
              {width < 991 && !registration && (
                <p className="p-mobile">Nome social</p>
              )}
              <Input
                id="name"
                label="Nome social"
                helperText="Por favor, coloque o nome completo."
                type="text"
                value={name ?? ""}
                onChange={(e) => handleChange(e, "name")}
                onBlur={handleOnBlur}
              />
            </div>
          </>
        ),
      },
      {
        child: (
          <>
            {registration && (
              <LabelInput
                disabled={isDisabled.pronouns}
                text="Qual pronome você prefere que usemos?"
              />
            )}
            {width < 991 && !registration && (
              <p className="p-mobile margin-bottom-0">Pronome de preferência</p>
            )}
            <Select
              id="otherPronouns"
              label="Pronome de preferência"
              onChange={(e) => {
                if (e.target.value === userPronous.OTHERS) {
                  _setProfile({
                    ..._profile,
                    otherPronouns: true,
                    pronouns: "",
                  });
                } else {
                  _setProfile({
                    ..._profile,
                    otherPronouns: false,
                    pronouns: e.target.value as string,
                  });
                }
              }}
              onBlur={handleOnBlur}
              value={otherPronouns ? userPronous.OTHERS : pronouns ?? ""}
              options={Object.keys(ProfilePronouns).map((key) => ({
                value: key,
                label: ProfilePronouns[key as keyof typeof ProfilePronouns],
              }))}
              disabled={isDisabled.pronouns}
            />
          </>
        ),
      },
      {
        renderCondition: !!otherPronouns,
        child: (
          <>
            {registration && (
              <LabelInput
                disabled={isDisabled.pronouns}
                text="Escreva como você prefere."
              />
            )}
            {width < 991 && !registration && (
              <p className="p-mobile margin-bottom-0">Prefiro...</p>
            )}
            <Input
              id="pronouns"
              label="Prefiro..."
              type="text"
              value={pronouns ?? ""}
              onChange={(e) => handleChange(e, "pronouns")}
              onBlur={handleOnBlur}
              disabled={isDisabled.pronouns}
            />
          </>
        ),
      },
      {
        child: (
          <>
            {registration && (
              <LabelInput
                disabled={isDisabled.birthdate}
                text="Agora nos diga sua data de nascimento."
              />
            )}
            <div>
              {width < 991 && !registration && (
                <p className="p-mobile">Data de nascimento</p>
              )}
              <Input
                id="birthdate"
                type="text"
                label="Data de nascimento"
                onChange={handleChangeDate}
                maxLength={10}
                onBlur={handleOnBlur}
                value={birthdate ?? ""}
                disabled={isDisabled.birthdate}
              />
              <Checkbox
                className="padding-top-8"
                id="ofAge"
                label={<div>Sou maior de 18 anos</div>}
                checked={
                  ofAge === undefined
                    ? moment().diff(moment(birthdate, "DD/MM/YYYY"), "years") >=
                      18
                    : ofAge
                }
                onChange={() =>
                  moment().diff(moment(birthdate, "DD/MM/YYYY"), "years") >= 18
                    ? setOfAge(!ofAge)
                    : {}
                }
                disabled={isDisabled.birthdate}
              />
            </div>
          </>
        ),
      },
      {
        child: (
          <>
            {registration && (
              <LabelInput
                disabled={isDisabled.document}
                text="Qual é seu CPF?"
              />
            )}
            <div>
              {width < 991 && !registration && <p className="p-mobile">CPF</p>}
              <Input
                id="cpf"
                type="text"
                label="CPF"
                onChange={(e) => handleChange(e, "legalIdentity")}
                maxLength={11}
                value={maskBRlegalIdentity(
                  hasOtherDocument ? "" : legalIdentity ?? "",
                )}
                onBlur={handleOnBlur}
                disabled={isDisabled.document}
              />
              <Checkbox
                className="padding-top-8"
                id="hasOtherDocument"
                label={<div>Informar outro documento</div>}
                checked={hasOtherDocument}
                onChange={() => setHasOtherDocument(!hasOtherDocument)}
                disabled={!ofAge || isDisabled.birthdate}
              />
            </div>
          </>
        ),
      },
      {
        renderCondition: hasOtherDocument,
        child: (
          <>
            {registration && (
              <LabelInput
                disabled={isDisabled.identityType}
                text="Escolha o tipo de documento."
              />
            )}
            {width < 991 && !registration && (
              <p className="p-mobile margin-bottom-0">Tipo de documento</p>
            )}
            <Select
              id="identityType"
              label="Tipo de documento"
              onChange={(e) => handleChangeSelect(e, "identityType")}
              onBlur={handleOnBlur}
              value={identityType ?? ""}
              options={Object.keys(IdentityType)
                .map((key) => ({
                  value: key,
                  label: IdentityType[key as keyof typeof IdentityType],
                }))
                .filter((item) => item.value !== userIdentityType.BR_CPF)}
              disabled={isDisabled.identityType}
            />
          </>
        ),
      },
      {
        renderCondition: hasOtherDocument,
        child: (
          <>
            {registration && (
              <LabelInput
                disabled={isDisabled.otherDocument}
                text="Qual é o número do documento?"
              />
            )}
            {width < 991 && !registration && (
              <p className="p-mobile margin-bottom-0">N°</p>
            )}
            <Input
              id="legalIdentity"
              label="N°"
              type="text"
              value={legalIdentity ?? ""}
              onChange={(e) => handleChange(e, "legalIdentity")}
              onBlur={handleOnBlur}
              disabled={isDisabled.otherDocument}
            />
          </>
        ),
      },
      {
        child: (
          <>
            {registration && (
              <div className="flex jspace-between align-end">
                {registration && (
                  <LabelInput
                    disabled={isDisabled.gender}
                    text="Qual é sua identidade de gênero?"
                  />
                )}
                <Tooltip
                  title={
                    <p>
                      É qualquer agrupamento de indivíduos, objetos, ideias, que
                      tenham caracteres comuns. Podemos também definir como uma
                      forma de entender, visualizar e referir-se à organização
                      social da relação entre os sexos. Muitas vezes o termo
                      gênero é erroneamente utilizado em referência ao sexo
                      biológico. Por isso, é importante enfatizar que o gênero
                      diz respeito aos aspectos sociais atribuídos ao sexo. Ou
                      seja, gênero está vinculado a construções sociais, não a
                      características naturais.
                      <br />
                      Dicionário Aurélio, 1986
                      <br />
                      Guedes, 1995
                      <br />
                      https://www.politize.com.br/vamos-falar-sobre-genero
                    </p>
                  }
                  disableFocusListener={isDisabled.gender}
                  disableHoverListener={isDisabled.gender}
                  disableTouchListener={isDisabled.gender}
                >
                  <div>
                    <Info
                      className={`cursor-pointer ${
                        isDisabled.gender ? "disabled" : ""
                      }`}
                    />
                  </div>
                </Tooltip>
              </div>
            )}
            {width < 991 && !registration && (
              <p className="p-mobile margin-bottom-0">Identidade de gênero</p>
            )}
            <Select
              id="genderIdentity"
              label="Identidade de gênero"
              onChange={(e) => handleChangeSelect(e, "genderIdentity")}
              onBlur={handleOnBlur}
              value={genderIdentity ?? ""}
              options={Object.keys(ProfileGender).map((key) => ({
                value: key,
                label: ProfileGender[key as keyof typeof ProfileGender],
              }))}
              disabled={isDisabled.gender}
            />
          </>
        ),
      },
      {
        renderCondition: genderIdentity === userGenderIdentity.OTHERS,
        child: (
          <>
            {registration && (
              <LabelInput
                disabled={isDisabled.gender}
                text="Escreva como você se identifica."
              />
            )}
            {width < 991 && !registration && (
              <p className="p-mobile margin-bottom-0">Me identifico como...</p>
            )}
            <Input
              id="genderIdentityDescription"
              label="Me identifico como..."
              type="text"
              value={genderIdentityDescription ?? ""}
              onChange={(e) => handleChange(e, "genderIdentityDescription")}
              onBlur={handleOnBlur}
              disabled={isDisabled.gender}
            />
          </>
        ),
      },
      {
        child: (
          <AccessibilitySelection
            disabled={isDisabled.deficiency}
            label={
              registration
                ? "Você precisa de algum tipo de adaptação em relação à acessibilidade?"
                : "Preciso de adaptação em relação à acessibilidade"
            }
            labelClassName={`${registration ? "font-20" : "font-16"}`}
            profile={_profile}
            setProfile={_setProfile}
          />
        ),
      },
      {
        renderCondition: !!disabledPerson,
        child: (
          <>
            {registration && (
              <LabelInput
                disabled={isDisabled.deficiency}
                text="Conta pra gente qual o CID de referência e como podemos te
                  apoiar?"
              />
            )}
            {width < 991 && !registration && (
              <p className="p-mobile margin-bottom-0">CID10</p>
            )}
            <Input
              id="CID10"
              label="CID10"
              type="text"
              value={disabledPersonDescription ?? ""}
              onChange={(e) => handleChange(e, "disabledPersonDescription")}
              onBlur={handleOnBlur}
              disabled={isDisabled.deficiency}
            />
          </>
        ),
      },
      {
        child: (
          <>
            {registration && (
              <LabelInput
                disabled={isDisabled.race}
                text="Como você se declara?"
              />
            )}
            {width < 991 && !registration && (
              <p className="p-mobile margin-bottom-0">Raça</p>
            )}
            <Select
              id="race"
              label="Raça"
              helperText={
                registration ? "Esta informação é muito importante pra nós" : ""
              }
              onChange={(e) => handleChangeSelect(e, "race")}
              onBlur={handleOnBlur}
              value={race ?? ""}
              options={Object.keys(ProfileRace).map((key) => ({
                value: key,
                label: ProfileRace[key as keyof typeof ProfileRace],
              }))}
              disabled={isDisabled.race}
            />
          </>
        ),
      },
      {
        renderCondition: race === userRace.OTHERS,
        child: (
          <>
            {registration && (
              <LabelInput
                disabled={isDisabled.race}
                text="Escreva com qual raça você se identifica."
              />
            )}
            {width < 991 && !registration && (
              <p className="p-mobile margin-bottom-0">Me identifico como...</p>
            )}
            <Input
              id="raceDescription"
              label="Me identifico como..."
              type="text"
              value={raceDescription ?? ""}
              onChange={(e) => handleChange(e, "raceDescription")}
              onBlur={handleOnBlur}
              disabled={isDisabled.race}
            />
          </>
        ),
      },
      {
        isFullRow: true,
        child: (
          <>
            {registration && (
              <LabelInput
                disabled={isDisabled.bio}
                optional
                text="Se quiser contar um pouco mais sobre você, escreva um breve
              texto para sua bio."
              />
            )}
            {width < 991 && !registration && <p className="p-mobile">Bio</p>}
            <TextArea
              id="bio"
              label="Bio"
              maxLength={400}
              value={bio ?? ""}
              onChange={(e) => handleChange(e, "bio")}
              onBlur={handleOnBlur}
              disabled={isDisabled.bio}
              className="margin-top-16 bio-input"
            />
          </>
        ),
      },
    ],
    [
      registration,
      _profile,
      bio,
      birthdate,
      disabledPerson,
      disabledPersonDescription,
      genderIdentity,
      genderIdentityDescription,
      handleChange,
      handleChangeDate,
      handleChangeSelect,
      handleOnBlur,
      hasOtherDocument,
      identityType,
      isDisabled.bio,
      isDisabled.birthdate,
      isDisabled.deficiency,
      isDisabled.document,
      isDisabled.gender,
      isDisabled.identityType,
      isDisabled.otherDocument,
      isDisabled.pronouns,
      isDisabled.race,
      legalIdentity,
      name,
      ofAge,
      onChangeImage,
      otherPronouns,
      photo,
      pronouns,
      race,
      raceDescription,
      width,
    ],
  );

  const itemsRefs = useMemo(
    () => items.map(() => createRef<HTMLDivElement>()),
    [items],
  );

  const updateLabelHeights = useCallback(() => {
    if (registration) {
      const getLabel = (ref: HTMLDivElement | null) => {
        if (ref) {
          const { children } = ref;
          // @ts-ignore
          const [label] = children;
          return label;
        }
        return ref;
      };

      const getLabelHeight = (label: HTMLParagraphElement) => {
        const { offsetHeight } = label;
        return offsetHeight;
      };

      const removeLabelHeight = (label: HTMLParagraphElement) => {
        const { style } = label;
        style.removeProperty(heightProperty);
        label.removeAttribute(expandedAttribute);
        return label;
      };

      const setLabelHeight = (label: HTMLParagraphElement, height: number) => {
        const { style } = label;
        style.setProperty(heightProperty, `${height}px`);
        label.setAttribute(expandedAttribute, "");
      };

      itemsRefs
        .filter(
          ({ current }) =>
            current && !current.className.includes(fullRowClassName),
        )
        .forEach(({ current: rightItem }, index, arr) => {
          if (index % 2 !== 0) {
            // Coluna esquerda
            const { current: leftItem } = arr[index - 1];
            const leftLabel = removeLabelHeight(getLabel(leftItem));
            const leftHeight = getLabelHeight(leftLabel);

            // Coluna direita
            const rightLabel = removeLabelHeight(getLabel(rightItem));
            const rightHeight = getLabelHeight(rightLabel);

            // Expansão de altura
            if (leftHeight > rightHeight)
              setLabelHeight(rightLabel, leftHeight);
            else setLabelHeight(leftLabel, rightHeight);

            // Redução de altura
            if (
              leftHeight < rightHeight &&
              rightLabel.hasAttribute(expandedAttribute)
            ) {
              removeLabelHeight(leftLabel);
              setLabelHeight(rightLabel, leftHeight);
            } else if (
              rightHeight < leftHeight &&
              leftLabel.hasAttribute(expandedAttribute)
            ) {
              removeLabelHeight(rightLabel);
              setLabelHeight(leftLabel, rightHeight);
            }
          }
        });
    }
  }, [itemsRefs, registration]);

  useEffect(() => {
    const handleResize = () => {
      updateLabelHeights();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [updateLabelHeights]);

  useEffect(() => updateLabelHeights(), [updateLabelHeights]);

  useEffect(() => {
    if (user?.profile?.name && !name) {
      _setProfile({ ...user?.profile });
    }
  }, [name, user?.profile]);

  useEffect(() => {
    if (ofAge === undefined && birthdate)
      setOfAge(moment().diff(moment(birthdate, "DD/MM/YYYY"), "years") >= 18);
  }, [birthdate, ofAge]);

  useEffect(() => {
    if (!isDisabled.bio) setIsValid(true);
    else setIsValid(false);
  }, [isDisabled.bio, setIsValid]);

  return (
    <div
      className={`${
        registration ? "grid-account-gap" : "grid-account-personal"
      } grid-account-gap-resposive`}
    >
      {items.map(
        ({ isFullRow, renderCondition, className, child }, index) =>
          (renderCondition ?? true) && (
            <div
              ref={itemsRefs[index]}
              className={
                isFullRow
                  ? fullRowClassName
                  : `content-grid-row ${
                      registration
                        ? `content-grid-2-row ${className ?? ""}`
                        : ""
                    }`
              }
            >
              {child}
            </div>
          ),
      )}
    </div>
  );
};

export default memo(FormPersonalData);
