import React, { memo, ReactNode } from "react";
import { Dialog } from "@material-ui/core";
import { Card } from "..";
import "./styles.scss";

interface Props {
  headerAction?: boolean;
  titleHeader?: string;
  textHeader?: string;
  textInfo?: string;
  title?: string;
  children: ReactNode;
  className?: string;
  bodyClassName?: string;
  open: boolean;
  onClose: () => void;
  onClickBack?: () => void;
  footer?: ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg";
}

const ModalFixedHeader = ({
  headerAction,
  titleHeader,
  title,
  textHeader,
  textInfo,
  children,
  className,
  bodyClassName,
  open,
  onClose,
  onClickBack,
  footer,
  maxWidth,
}: Props): JSX.Element => (
  <Dialog
    open={open}
    onClose={onClose}
    className={className}
    maxWidth={maxWidth}
  >
    <Card
      textInfo={textInfo}
      headerAction={headerAction}
      footer={footer}
      titleHeader={titleHeader}
      textHeader={textHeader}
      onClose={onClose}
      onClickBack={onClickBack}
      title={title}
      headerClassName="header-modal-fixed"
      className={bodyClassName ?? ""}
    >
      {children}
    </Card>
  </Dialog>
);

export default memo(ModalFixedHeader);
