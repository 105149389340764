import React, { MouseEventHandler } from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const VisibilityOn = ({
  widthIcon,
  heightIcon,
  className,
  onClick,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "20"}
    height={heightIcon ?? "20"}
    fill="none"
    viewBox="0 0 20 20"
    className={className}
    onClick={onClick}
  >
    <path
      fill="#7E459B"
      d="M10 7.5C8.63 7.5 7.5 8.632 7.5 10s1.131 2.5 2.5 2.5c1.367 0 2.5-1.132 2.5-2.5S11.366 7.5 10 7.5z"
    />
    <path
      fill="#7E459B"
      d="M10 4.167c-6.327 0-8.229 5.514-8.246 5.57L1.666 10l.087.263c.018.056 1.92 5.57 8.246 5.57 6.327 0 8.228-5.514 8.246-5.57l.088-.263-.087-.263c-.019-.056-1.92-5.57-8.247-5.57zm0 10c-4.436 0-6.154-3.205-6.57-4.167.418-.965 2.137-4.167 6.57-4.167 4.435 0 6.153 3.205 6.569 4.167-.418.965-2.137 4.167-6.57 4.167z"
    />
  </svg>
);

export default VisibilityOn;
