/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from "react";
import {
  Edit,
  MailMax,
  WhatsappMax,
  TelegramMax,
} from "../../../../assets/icons";
import { Modal } from "../../../components";

interface Props {
  user: any;
  openModal: boolean;
  onClose: () => void;
  onEdit: () => void;
  isMyProfile: boolean;
}

const HiddenText = () => (
  <p className="hidden-information">Informação ocultada pela usuária</p>
);

const Contact = ({
  user,
  openModal,
  onClose,
  onEdit,
  isMyProfile,
}: Props): JSX.Element => (
  <Modal
    headerAction
    textHeader="Contato"
    open={openModal}
    onClose={onClose}
    bodyClassName="modal-profile header-edit-contacts height-fit-content"
  >
    <div className="flex flex-row margin-bottom-16">
      <MailMax />
      <div className="flex div-contact">
        <p className="title-view paragraph-edit-general">E-mail</p>
        {(user?.profile?.email &&
          !user?.filters?.publicProfile?.includes("profile.email")) ||
        isMyProfile ? (
          <p className="view-bold sub-paragraph-edit-general">
            {user?.profile?.email}
          </p>
        ) : (
          <HiddenText />
        )}
      </div>
      {isMyProfile && <Edit onClick={onEdit} className="pointer" />}
    </div>
    <div className="flex flex-row margin-top-24">
      <WhatsappMax />
      <div className="flex-col margin-left-10">
        <p className="title-view paragraph-edit-general">WhatsApp</p>
        {(user?.profile?.telephone &&
          !user?.filters?.publicProfile?.includes("profile.telephone")) ||
        isMyProfile ? (
          <p className="view-bold sub-paragraph-edit-general">
            {user?.profile?.telephone}
          </p>
        ) : (
          <HiddenText />
        )}
      </div>
    </div>
    <div className="flex flex-row margin-top-24">
      <TelegramMax />
      <div className="flex-col margin-left-10">
        <p className="title-view">Telegram</p>
        {(user?.profile?.telegram &&
          !user?.filters?.publicProfile?.includes("profile.telegram")) ||
        isMyProfile ? (
          <p className="view-bold">{user?.profile?.telegram}</p>
        ) : (
          <HiddenText />
        )}
      </div>
    </div>
  </Modal>
);

export default memo(Contact);
