/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-regex-literals */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import moment from "moment-timezone";
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useState,
  useEffect,
  useMemo,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Input,
  InputAutocomplete,
  InputDate,
  Select,
  TextArea,
  LabelInput,
} from "../../../components";
import {
  searchCompaniesAutocomplete,
  setNewCompany,
  searchCompaniesAllStatus,
} from "../../../services/functions";
import { RootState } from "../../../store/reducers";
import {
  companyType,
  placementVacancyManagedBy,
  placementVacancyTypeOfCandidature,
} from "../../../types/enumerators";
import { Localization, PlacementVacancy } from "../../../types/interfaces";
import { Info } from "../../../../assets/icons";
import { ManagedBy } from "../../../types/constants/Vacancy";

interface Props {
  vacancy: PlacementVacancy;
  setVacancy: Dispatch<SetStateAction<PlacementVacancy>>;
  onSave: () => void;
  nextPage: () => void;
}

interface Item {
  id: string;
  child: ReactNode;
  isFullRow?: boolean;
  renderCondition?: boolean;
  className?: string;
}

const GeneralData = ({
  vacancy,
  setVacancy,
  onSave,
  nextPage,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const company = useSelector((state: RootState) => state.companyState);
  const [companyOptions, setCompanyOptions] =
    useState<{ name: string; id: string; location?: Localization[] }[]>();
  const [isDisabled, setIsDisabled] = useState<{
    [x: string]: boolean;
  }>({});
  const history = useHistory();

  const location = useMemo(
    () =>
      company?._id
        ? company?.profile?.location
        : companyOptions?.find((item) => vacancy?.companyId === item?.id)
            ?.location,
    [
      company?._id,
      company?.profile?.location,
      companyOptions,
      vacancy?.companyId,
    ],
  );

  useEffect(() => {
    if (vacancy?.autoOpen === undefined)
      setVacancy({ ...vacancy, autoOpen: false });

    if (vacancy?.autoClose === undefined)
      setVacancy({ ...vacancy, autoClose: false });
  }, [setVacancy, vacancy]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) => {
      if (
        prop === "partnerVacancyId" &&
        event.target.value.includes("greenhouse.io")
      ) {
        event.target.value = event.target.value
          .split("?")[0]
          .split(new RegExp(/https.*greenhouse.io\/.*\/(?=[0-9]{9,})/g))[1]
          .split(new RegExp(/([0-9]{9,})/g))[1];
      }

      if (
        prop === "partnerVacancyId" &&
        event.target.value.includes("gupy.io")
      ) {
        event.target.value = event.target.value
          .split("?")[0]
          .split("/candidates")[0]
          .split(
            new RegExp(/https.*gupy.io\/companies\/jobs\/(?=[0-9]{7,})/g),
          )[1]
          .split(new RegExp(/([0-9]{9,})/g))[1];
      }

      setVacancy({
        ...vacancy,
        [prop]: event.target.value,
      });
    },
    [setVacancy, vacancy],
  );

  const handleChangeSelect = useCallback(
    (
      event: ChangeEvent<{
        name?: string;
        value: unknown;
      }>,
      prop: string,
    ) =>
      setVacancy({
        ...vacancy,
        [prop]: event.target.value as string,
      }),
    [setVacancy, vacancy],
  );

  const handleChangeDate = useCallback(
    (value: any, prop: string) => {
      setVacancy({
        ...vacancy,
        [prop]: value?.tz("America/Sao_Paulo").toISOString(),
      });
    },
    [setVacancy, vacancy],
  );

  const loadCompany = useCallback(async () => {
    try {
      const companyOptionsResponse = await searchCompaniesAutocomplete(
        companyType.WORK_COMPANY,
      );

      const companyOptionsMapped = companyOptionsResponse?.map((option) => ({
        name: option?.profile?.name ?? "",
        id: option?._id ?? "",
        location: option?.profile?.location,
      }));
      setCompanyOptions(companyOptionsMapped ?? []);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  useEffect(() => {
    if (!companyOptions) loadCompany();
  }, [companyOptions, loadCompany]);

  const setCurrentCompanyName = useCallback(
    async (value: string | null, prop: string) => {
      if (value && value !== "") {
        const currentCompany = await searchCompaniesAllStatus(value);

        if (!currentCompany?.length) {
          await setNewCompany(value, companyType.WORK_COMPANY);

          setCurrentCompanyName(value, prop);
        } else {
          setVacancy({
            ...vacancy,
            externalCompanyId: currentCompany[0]._id,
            [prop]: value,
          });
        }
      }
    },
    [setVacancy, vacancy],
  );

  const setCompany = useCallback(
    async (value: string | null, prop: string) => {
      if (value && value !== "") {
        const currentCompany = await searchCompaniesAllStatus(value);

        if (!currentCompany?.length) {
          await setNewCompany(value, companyType.WORK_COMPANY);

          setCompany(value, prop);
        } else {
          setVacancy({
            ...vacancy,
            companyId: currentCompany[0]._id,
          });
        }
      }
    },
    [setVacancy, vacancy],
  );

  const handleChangeSwitch = useCallback(
    (value: boolean, prop: string) => {
      setVacancy({
        ...vacancy,
        [prop]: value,
      });
    },
    [setVacancy, vacancy],
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const items = useMemo(
    (): Item[] => [
      {
        id: "managedBy",
        className: "grid-row-1 grid-column-1-2",
        child: (
          <>
            {width < 991 && <LabelInput alignStart text="Tipo de vaga" />}
            <div className="flex-row-center">
              <Select
                id="managedBy"
                label={width > 990 ? "Tipo de vaga*" : ""}
                value={
                  vacancy?.managedBy ?? placementVacancyManagedBy.REPROGRAMA
                }
                onChange={(e) => handleChangeSelect(e, "managedBy")}
                options={Object.keys(ManagedBy).map((key) => ({
                  value: key,
                  label: ManagedBy[key as keyof typeof ManagedBy],
                }))}
              />
              <Tooltip
                title={
                  <>
                    <p>
                      {
                        "{reprograma} - São vagas em que todo o processo seletivo acontecerá na nossa plataforma."
                      }
                    </p>
                    <p>
                      Greenhouse - São vagas em que apenas a primeira etapa do
                      processo seletivo acontecerá na nossa plataforma. Ou seja,
                      temos uma integração que permite que nossas ex-alunas se
                      candidatem por aqui, mas o restante do processo seletivo
                      irá ocorrer pela Greenhouse.
                    </p>
                    <p>
                      Gupy - São vagas em que apenas a primeira etapa do
                      processo seletivo acontecerá na nossa plataforma. Ou seja,
                      temos uma integração que permite que nossas ex-alunas se
                      candidatem por aqui, mas o restante do processo seletivo
                      irá ocorrer pela Gupy.
                    </p>
                    <p>
                      Curadoria - São vagas em que nenhuma etapa do processo
                      seletivo irá ocorrer pela nossa plataforma. É a divulgação
                      de uma oportunidade em que você irá disponibilizar o link
                      informativo da vaga e/ou processo seletivo.
                    </p>
                  </>
                }
              >
                <div>
                  <Info className="icon-info cursor-pointer" />
                </div>
              </Tooltip>
            </div>
          </>
        ),
      },
      {
        renderCondition:
          vacancy?.managedBy === placementVacancyManagedBy.GUPY ||
          vacancy?.managedBy === placementVacancyManagedBy.GREENHOUSE,
        id: "partnerVacancyId",
        className: "grid-row-1 grid-column-2-3",
        child: (
          <>
            {width < 991 && (
              <LabelInput
                alignStart
                text="ID da vaga"
                disabled={isDisabled.partnerVacancyId}
              />
            )}
            <Input
              id="partnerVacancyId"
              label="ID da vaga*"
              type="text"
              value={vacancy?.partnerVacancyId ?? ""}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => handleChange(e, "partnerVacancyId")}
              className={`width-100 ${
                width < 991 &&
                "margin-top-10 width-100-mobile margin-left-0-mobile"
              }`}
              maxLength={11}
              disabled={isDisabled.partnerVacancyId}
            />
          </>
        ),
      },
      {
        renderCondition:
          vacancy?.managedBy === placementVacancyManagedBy.EXTERNAL_VACANCY,
        id: "externalCompanyName",
        className: "grid-row-2 grid-column-1-2",
        child: (
          <>
            {width < 991 && (
              <LabelInput
                alignStart
                text="Nome da empresa"
                disabled={isDisabled.externalCompanyName}
              />
            )}
            <InputAutocomplete
              id="externalCompanyName"
              label="Nome da empresa"
              value={vacancy?.externalCompanyName ?? ""}
              onBlur={(event) =>
                setCurrentCompanyName(event.target.value, "externalCompanyName")
              }
              options={companyOptions?.map((item) => item?.name) ?? [""]}
              getOptionLabel={(option) => option}
              disabled={isDisabled.externalCompanyName}
              className={`${width < 991 && "margin-top-10"}`}
            />
          </>
        ),
      },
      {
        renderCondition:
          !company?._id &&
          vacancy?.managedBy !== placementVacancyManagedBy.EXTERNAL_VACANCY,
        id: "companyId",
        className: "grid-row-1",
        child: (
          <>
            {width < 991 && <LabelInput alignStart text="Nome da empresa" />}
            <InputAutocomplete
              id="companyId"
              label="Nome da empresa"
              value={
                companyOptions?.find((item) => item?.id === vacancy?.companyId)
                  ?.name ?? ""
              }
              onBlur={(event) => setCompany(event.target.value, "companyId")}
              options={companyOptions?.map((item) => item?.name) ?? [""]}
              getOptionLabel={(option) => option}
              className={`${width < 991 && "margin-top-10"}`}
            />
          </>
        ),
      },
      {
        id: "title",
        className: "grid-row-2",
        child: (
          <>
            {width < 991 && (
              <LabelInput
                alignStart
                text="Título"
                disabled={isDisabled.title}
              />
            )}
            <Input
              id="title"
              label={width > 990 ? "Título*" : ""}
              type="text"
              value={vacancy?.title ?? ""}
              onChange={(
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => handleChange(e, "title")}
              className={`${width < 991 && "margin-top-10"}`}
              disabled={isDisabled.title}
            />
          </>
        ),
      },
      {
        renderCondition:
          vacancy?.managedBy !== placementVacancyManagedBy.EXTERNAL_VACANCY,
        id: "place",
        className: "grid-row-2 grid-column-2-3",
        child: (
          <>
            {width < 991 && (
              <LabelInput alignStart text="Local" disabled={isDisabled.place} />
            )}
            <Select
              id="place"
              label={width > 990 ? "Local*" : ""}
              value={vacancy?.place ?? ""}
              onChange={(e) => handleChangeSelect(e, "place")}
              options={
                location
                  ? location?.map(
                      (item: { city: string; province: string }) => ({
                        label: `${item?.city} - ${item?.province}`,
                        value: `${item?.city} - ${item?.province}`,
                      }),
                    )
                  : [{ label: "Não informado", value: "Não informado" }]
              }
              disabled={isDisabled.place}
              className={`${width < 991 && "margin-top-10"}`}
            />
          </>
        ),
      },
      {
        id: "type",
        className: "grid-row-2 grid-column-3-4",
        child: (
          <>
            {width < 991 && (
              <LabelInput
                alignStart
                text="Modelo de trabalho"
                disabled={isDisabled.type}
              />
            )}

            <Select
              id="type"
              label={width > 990 ? "Modelo de trabalho*" : ""}
              value={vacancy?.type ?? ""}
              onChange={(e) => handleChangeSelect(e, "type")}
              options={[
                { label: "Presencial", value: "Presencial" },
                { label: "Híbrida", value: "Híbrida" },
                { label: "Remota", value: "Remota" },
              ]}
              className={`${width < 991 && "margin-top-10"}`}
              disabled={isDisabled.type}
            />
          </>
        ),
      },
      {
        renderCondition:
          vacancy?.managedBy !== placementVacancyManagedBy.EXTERNAL_VACANCY,
        id: "typeOfCandidature",
        className: "grid-row-3 grid-column-1-2",
        child: (
          <>
            {width < 991 && (
              <LabelInput
                alignStart
                text="Tipo de candidatura"
                disabled={isDisabled.typeOfCandidature}
              />
            )}

            <Select
              id="typeOfCandidature"
              label={width > 990 ? "Tipo de candidatura*" : ""}
              value={vacancy?.typeOfCandidature ?? ""}
              onChange={(e) => handleChangeSelect(e, "typeOfCandidature")}
              options={[
                {
                  label: "Aberta: qualquer aluna pode se inscrever",
                  value: placementVacancyTypeOfCandidature.OPEN,
                },
                {
                  label: "Fechada: apenas alunas convidadas podem se inscrever",
                  value: placementVacancyTypeOfCandidature.CLOSE,
                },
              ]}
              disabled={isDisabled.typeOfCandidature}
              className={`select-vacancy-candidature ${
                width < 991 && "margin-top-10"
              }`}
            />
          </>
        ),
      },
      {
        renderCondition:
          vacancy?.managedBy !== placementVacancyManagedBy.EXTERNAL_VACANCY,
        id: "startDate",
        className: "grid-row-3 grid-column-2-3",
        child: (
          <>
            {width < 991 && (
              <LabelInput
                alignStart
                text="Abertura"
                disabled={isDisabled.startDate}
              />
            )}
            <InputDate
              future
              views={["year", "month", "date"]}
              format="DD/MM/yyyy"
              label={width > 990 ? "Abertura*" : ""}
              helperText="DD/MM/AAAA"
              className={`width-100-mobile date-create-vacancy {
                width < 991 && "margin-top-10"
              }`}
              minDate={vacancy?._id ? vacancy?.startDate : moment().toDate()}
              value={vacancy?.startDate ?? null}
              onChange={(value) => handleChangeDate(value, "startDate")}
              disabled={isDisabled.startDate}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={vacancy?.autoOpen}
                  onChange={() =>
                    handleChangeSwitch(!vacancy?.autoOpen, "autoOpen")
                  }
                  name="autoOpen"
                />
              }
              disabled={isDisabled.startDate}
              label="Abertura automática"
              className="flex-start-mobile"
            />
          </>
        ),
      },
      {
        renderCondition:
          vacancy?.managedBy !== placementVacancyManagedBy.EXTERNAL_VACANCY,
        id: "endDate",
        className: "grid-row-3 grid-column-3-4",
        child: (
          <>
            {width < 991 && (
              <LabelInput
                alignStart
                text="Encerramento"
                disabled={isDisabled.endDate}
              />
            )}
            <InputDate
              future
              label={width > 990 ? "Encerramento*" : ""}
              views={["year", "month", "date"]}
              format="DD/MM/yyyy"
              helperText="DD/MM/AAAA"
              className={`width-100-mobile date-create-vacancy ${
                width < 991 && "margin-top-10"
              }`}
              value={vacancy?.endDate ?? null}
              minDate={vacancy?.startDate}
              onChange={(value) => handleChangeDate(value, "endDate")}
              disabled={isDisabled.endDate}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={vacancy?.autoClose}
                  onChange={() =>
                    handleChangeSwitch(!vacancy?.autoClose, "autoClose")
                  }
                  name="autoClose"
                />
              }
              label="Encerramento automático"
              className="flex-start-mobile"
              disabled={isDisabled.endDate}
            />
          </>
        ),
      },
      {
        renderCondition:
          vacancy?.managedBy === placementVacancyManagedBy.EXTERNAL_VACANCY,
        id: "place",
        className: "grid-row-3 grid-column-1-2",
        child: (
          <>
            {width < 991 && (
              <LabelInput alignStart text="Local" disabled={isDisabled.place} />
            )}
            <Input
              id="place"
              label={width > 990 ? "Local*" : ""}
              value={vacancy?.place ?? ""}
              onChange={(e) => handleChange(e, "place")}
              disabled={isDisabled.place}
              className={` width-100 ${width < 991 && "margin-top-10"}`}
            />
          </>
        ),
      },
      {
        renderCondition:
          vacancy?.managedBy === placementVacancyManagedBy.EXTERNAL_VACANCY,
        id: "url",
        className: "grid-row-3 grid-column-2-4",
        child: (
          <>
            {width < 991 && (
              <LabelInput alignStart text="Link" disabled={isDisabled.url} />
            )}
            <Input
              id="url"
              label={width > 990 ? "Link*" : ""}
              value={vacancy?.url ?? ""}
              onChange={(e) => handleChange(e, "url")}
              disabled={isDisabled.url}
              className={`width-100 ${width < 991 && "margin-top-10"}`}
            />
          </>
        ),
      },
      {
        id: "description",
        className: "grid-row-4 grid-column-1-4",
        child: (
          <>
            {width < 991 && (
              <LabelInput
                alignStart
                text="Descrição"
                disabled={isDisabled.description}
              />
            )}
            <TextArea
              id="description"
              label={width > 990 ? "Descrição*" : ""}
              className={`media-width-description width-100-mobile ${
                width < 991 && "margin-top-10"
              }`}
              maxLength={2000}
              value={vacancy?.description ?? ""}
              onChange={(e) => handleChange(e, "description")}
              disabled={isDisabled.description}
            />
          </>
        ),
      },
    ],
    [
      width,
      vacancy?.managedBy,
      vacancy?.partnerVacancyId,
      vacancy?.externalCompanyName,
      vacancy?.title,
      vacancy?.place,
      vacancy?.type,
      vacancy?.typeOfCandidature,
      vacancy?._id,
      vacancy?.startDate,
      vacancy?.autoOpen,
      vacancy?.endDate,
      vacancy?.autoClose,
      vacancy?.url,
      vacancy?.description,
      vacancy?.companyId,
      isDisabled.partnerVacancyId,
      isDisabled.externalCompanyName,
      isDisabled.title,
      isDisabled.place,
      isDisabled.type,
      isDisabled.typeOfCandidature,
      isDisabled.startDate,
      isDisabled.endDate,
      isDisabled.url,
      isDisabled.description,
      companyOptions,
      company?._id,
      location,
      handleChangeSelect,
      handleChange,
      setCurrentCompanyName,
      setCompany,
      handleChangeDate,
      handleChangeSwitch,
    ],
  );

  const checkDisabled = useCallback(
    (index: number) =>
      !!items
        ?.filter(
          (item: { renderCondition?: boolean; id: string }) =>
            item?.renderCondition === undefined || !!item?.renderCondition,
        )
        ?.find(
          (item, i: number) =>
            !vacancy ||
            (i < index &&
              !!vacancy &&
              (vacancy[item?.id] === undefined ||
                vacancy[item?.id] === "" ||
                vacancy[item?.id] === 0)),
        ),
    [vacancy, items],
  );

  const getIsDisabled = useCallback(
    () =>
      setIsDisabled(
        items
          ?.filter(
            (item: { renderCondition?: boolean }) =>
              item?.renderCondition === undefined || !!item?.renderCondition,
          )
          ?.map((innerItem: { id: string }, index: number) => {
            if (index !== 0)
              return {
                [innerItem?.id]: checkDisabled(index),
              };
            return { [innerItem?.id]: false };
          })
          .concat({ next: checkDisabled(items?.length) })
          .reduce((acc, cur) => Object.assign(acc, cur)),
      ),
    [checkDisabled, items],
  );

  useEffect(() => {
    getIsDisabled();
  }, [getIsDisabled]);

  const onClick = useCallback(() => {
    if (
      vacancy?._id ||
      vacancy?.managedBy === placementVacancyManagedBy.EXTERNAL_VACANCY
    )
      onSave();
    else nextPage();
  }, [nextPage, onSave, vacancy?._id, vacancy?.managedBy]);

  return (
    <div className="grid-column-1-11-mobile creat-mobile-margin grid-column-5-12 card-account-vacancies grid-7-column grid-row-2">
      <Card
        className="grid-column-1-8 border-card"
        titleHeader={width > 990 ? "Dados gerais" : undefined}
      >
        <div className="grid-create-vacancy-general-data grid-create-vacancy-general-data-responsive">
          {items?.map(
            ({ renderCondition, className, child }) =>
              (renderCondition ?? true) && (
                <div className={` ${width > 990 ? className ?? "" : ""}`}>
                  {child}
                </div>
              ),
          )}
        </div>
      </Card>
      <div
        className={`flex grid-column-4-8 grid-row-2 ${
          width < 991 && "flex-direction-column-reverse grid-column-1-8-mobile"
        }`}
      >
        <Button
          text="Cancelar"
          className={`margin-bottom-32  width-100 grid-column-8-10 ${
            width < 991 && "margin-bottom-0-mobile margin-top-0-mobile"
          }`}
          onClick={() => history.goBack()}
          outline
          appearance="secondary"
        />
        <Button
          text={
            !vacancy?._id &&
            vacancy?.managedBy !== placementVacancyManagedBy.EXTERNAL_VACANCY
              ? "Avançar"
              : "salvar"
          }
          className={`margin-left-32 margin-bottom-24 width-100 ${
            width < 991 && "margin-left-0-mobile margin-top-20"
          }`}
          onClick={onClick}
          disabled={isDisabled.next}
        />
      </div>
    </div>
  );
};

export default memo(GeneralData);
