/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  memo,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from "react";
import { toast } from "react-toastify";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { Delete } from "../../../assets/icons";
import {
  Input,
  Checkbox,
  InputDate,
  TextArea,
  CardMedia,
  Draggable,
  ModalMedia,
  IconButton,
  AttachmentMedia,
  InputAutocomplete,
} from "..";
import { UserCurriculumCertificate, Media } from "../../types/interfaces";
import api from "../../services/api";
import {
  getCompany,
  setNewCompany,
  searchCompaniesAllStatus,
  searchCompaniesAutocomplete,
} from "../../services/functions";
import { companyStatus, companyType, mediaType } from "../../types/enumerators";
import "./styles.scss";

interface Props {
  setItem: any;
  certificate: UserCurriculumCertificate;
  setIsValid?: Dispatch<SetStateAction<boolean>>;
  setNotExpire?: Dispatch<SetStateAction<boolean>>;
  courseRegister?: boolean;
  cantEditMedia?: boolean;
  cantEdit?: boolean;
}

const AddCertificate = ({
  setItem,
  setIsValid,
  certificate,
  setNotExpire,
  courseRegister,
  cantEditMedia,
  cantEdit,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [editMediaIndex, setEditMediaIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [media, setMedia] = useState<Media>({} as Media);
  const [notExpire, _setNotExpire] = useState(
    certificate?.expirationDate === null,
  );
  const [emissor, setEmissor] = useState("");
  const [emissorOptions, setEmissorOptions] =
    useState<{ name: string; id: string }[]>();
  const [_certificate, _setCertificate] =
    useState<UserCurriculumCertificate>(certificate);

  const loadEmissors = useCallback(async () => {
    try {
      const companyOptionsResponse = await searchCompaniesAutocomplete(
        companyType.INSTITUTION,
      );
      const companyOptionsMapped = companyOptionsResponse?.map((option) => ({
        name: option?.profile?.name ?? "",
        id: option?._id ?? "",
      }));

      setEmissorOptions(companyOptionsMapped ?? []);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  useEffect(() => {
    if (!emissorOptions) loadEmissors();
  }, [emissorOptions, loadEmissors]);

  const getCompanyName = useCallback(async () => {
    try {
      const company = await getCompany(_certificate?.companyId ?? "");

      if (company) setEmissor(company?.profile?.name ?? "");
    } catch (err) {
      console.warn(err);
    }
  }, [_certificate?.companyId]);

  useEffect(() => {
    if (_certificate?.companyId) getCompanyName();
  }, [_certificate?.companyId, getCompanyName]);

  useEffect(() => {
    if (setIsValid) {
      if (
        _certificate &&
        _certificate?.title &&
        _certificate?.companyId &&
        _certificate?.description &&
        _certificate?.date &&
        (_certificate?.expirationDate || notExpire)
      )
        setIsValid(true);
      else setIsValid(false);
    }
  }, [_certificate, notExpire, setIsValid]);

  useEffect(() => {
    if (_certificate && _certificate?.medias?.length)
      _certificate?.medias?.sort((a: Media, b: Media) => {
        if ("order" in a && "order" in b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        }
        return 0;
      });
  }, [_certificate]);

  const handleOnBlur = useCallback(
    () => setItem(_certificate),
    [_certificate, setItem],
  );

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      prop: keyof UserCurriculumCertificate,
      value?: boolean,
    ) => {
      if (value !== undefined) {
        _setCertificate({ ..._certificate, [prop]: value });
      } else _setCertificate({ ..._certificate, [prop]: event.target.value });
    },
    [_certificate],
  );

  const setCompany = useCallback(
    async (value: string | null) => {
      if (value && value !== "") {
        const company = await searchCompaniesAllStatus(value);

        if (!company?.length) {
          await setNewCompany(
            value,
            companyType.INSTITUTION,
            companyStatus.PENDING,
            companyStatus.NONE,
          );

          setCompany(value);
        } else {
          _setCertificate({
            ..._certificate,
            companyId: company[0]._id,
          });

          setItem({
            ..._certificate,
            companyId: company[0]._id,
          });
        }
      }
    },
    [_certificate, setItem],
  );

  const handleChangeDate = useCallback(
    (value: any, prop: string) => {
      _setCertificate({
        ..._certificate,
        [prop]: value?.clone().startOf("month").toISOString(),
      });
      setItem({
        ..._certificate,
        [prop]: value?.clone().startOf("month").toISOString(),
      });
    },
    [_certificate, setItem],
  );

  const handleLink = useCallback(
    (e: { key: string; target: { value: string } }) => {
      if (e.key === "Enter") {
        let { medias: newMedias } = _certificate;
        const link = {
          url: e.target.value,
          type: mediaType.LINK,
          order: newMedias?.length ?? 0,
        };

        if (newMedias?.length) newMedias.push(link);
        else newMedias = [link];

        e.target.value = "";
        _setCertificate({ ..._certificate, medias: newMedias });
      }
    },
    [_certificate],
  );

  const handleMedia = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      api
        .post("/fileUpload", data)
        .then((result) => {
          setOpenModal(true);
          setMedia({
            ...media,
            url: result?.data?.file_url.url,
            type: fileList[0].type.includes("image")
              ? mediaType.PHOTO
              : mediaType.DOCUMENT,
            order: _certificate?.medias?.length ?? 0,
          });
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    },
    [_certificate, media],
  );

  const handleChangeMedia = useCallback(
    (prop: string | number | symbol) =>
      (event: ChangeEvent<HTMLInputElement>) =>
        setMedia({ ...media, [prop]: event.target.value }),
    [media],
  );

  const saveMedia = useCallback(() => {
    let { medias: newMedias } = _certificate;

    if (editMediaIndex !== -1) {
      newMedias[editMediaIndex] = media;
    } else if (newMedias?.length) newMedias.push(media);
    else newMedias = [media];

    _setCertificate({ ..._certificate, medias: newMedias });
    setItem({ ..._certificate, medias: newMedias });
    setMedia({} as Media);
    setEditMediaIndex(-1);
    setOpenModal(false);
  }, [_certificate, editMediaIndex, setItem, media]);

  const editMedia = useCallback((item: Media, index: number) => {
    setMedia(item);
    setEditMediaIndex(index);
    setOpenModal(true);
  }, []);

  const removeMedia = useCallback(
    (index: number) => {
      let { medias: oldMedias } = _certificate;
      oldMedias.splice(index, 1);
      oldMedias = oldMedias.map((item: Media, i: number) => ({
        ...item,
        order: i,
      }));
      _setCertificate({ ..._certificate, medias: oldMedias });
      setItem({ ..._certificate, medias: oldMedias });
    },
    [_certificate, setItem],
  );

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setEditMediaIndex(-1);
    setMedia({} as Media);
  }, []);

  const reorderMedias = useCallback(
    (items: Media[]) => {
      let { medias: newMedias } = _certificate;
      newMedias = items.map((item, index) => ({ ...item, order: index }));
      _setCertificate({ ..._certificate, medias: newMedias });
      setItem({ ..._certificate, medias: newMedias });
    },
    [_certificate, setItem],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="certificate">
      <div className="flex certificate-content">
        {width < 991 && <p className="p-mobile">Título*</p>}
        <Input
          id="title"
          label="Título*"
          type="text"
          value={_certificate?.title ?? ""}
          onChange={(e) => handleChange(e, "title")}
          onBlur={handleOnBlur}
          className={`certificate-input margin-bottom-20-mobile ${
            courseRegister ? "width-100" : "certificate-input-register"
          }`}
        />
        {width < 991 && <p className="p-mobile">Emissor*</p>}
        <InputAutocomplete
          label="Emissor*"
          helperText="Pressione Enter para adicionar"
          value={emissor}
          onBlur={(event) => setCompany(event.target.value)}
          className={`certificate-input margin-top-MuiFormLabel-root ${
            courseRegister
              ? "width-100 margin-right-0"
              : "certificate-input-register"
          }`}
          options={emissorOptions?.map((item) => item?.name) ?? [""]}
          getOptionLabel={(option) => option}
        />
      </div>
      {width < 991 && <p className="p-mobile">Descrição*</p>}
      <TextArea
        id="description"
        label="Descrição*"
        maxLength={300}
        value={_certificate?.description ?? ""}
        onChange={(e) => handleChange(e, "description")}
        onBlur={handleOnBlur}
        className={`description-input ${
          courseRegister ? "width-100" : "certificate-description"
        }`}
      />
      <div className="margin-top-16-mobile flex align-items flex-col-mobile align-items-baseline-mobile gap-16-mobile">
        <div className="flex gap-16-mobile">
          <div>
            {width < 991 && <p className="p-mobile">Emissão*</p>}
            <InputDate
              label="Emissão*"
              helperText="MM/AAAA"
              value={_certificate?.date ?? null}
              onChange={(value) => handleChangeDate(value, "date")}
              className="certificate-date"
            />
          </div>
          <div>
            {width < 991 && !notExpire && (
              <p className="p-mobile margin-bottom-16-mobile margin-left-10">
                Fim*
              </p>
            )}
            {!notExpire && (
              <InputDate
                label="Validade*"
                helperText="MM/AAAA"
                value={_certificate?.expirationDate ?? null}
                minDate={_certificate?.date ?? undefined}
                onChange={(value) => handleChangeDate(value, "expirationDate")}
                className="certificate-date"
              />
            )}
          </div>
        </div>

        <Checkbox
          id="notExpire"
          label="Este certificado não expira"
          checked={notExpire}
          onChange={() => {
            if (setNotExpire) setNotExpire(!notExpire);
            _setNotExpire(!notExpire);
            _setCertificate({
              ..._certificate,
              expirationDate: null,
            });
            setItem({
              ..._certificate,
              expirationDate: null,
            });
          }}
        />
      </div>
      <p className="certificate-title">Mídias</p>
      <p className="certificate-subtitle">
        Adicione um link ou arquivos para seus certificados.
      </p>
      <div className="flex-row-center">
        <Input
          label="Link"
          type="text"
          helperText="Pressione Enter para adicionar"
          className="certificate-input-add margin-right-24"
          onKeyDown={handleLink}
          onBlur={handleOnBlur}
        />
        {width > 990 && (
          <AttachmentMedia
            onChange={handleMedia}
            className="margin-bottom-16"
          />
        )}
      </div>
      {width < 991 && <AttachmentMedia onChange={handleMedia} />}
      {_certificate &&
        _certificate?.medias?.filter((item) => item?.type === mediaType.LINK)
          ?.length > 0 && (
          <div className="margin-top-32">
            {_certificate?.medias.map((item, index) => {
              if (item?.type === mediaType.LINK) {
                return (
                  <div className="flex-row-center" key={Math.random()}>
                    <a
                      href={
                        item?.url?.includes("http")
                          ? item?.url
                          : `http://${item?.url}`
                      }
                      className="certificate-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item?.url}
                    </a>
                    <IconButton
                      icon={<Delete />}
                      onClick={() => removeMedia(index)}
                    />
                  </div>
                );
              }
              return <div />;
            })}
          </div>
        )}
      <div className="flex flex-wrap align-items">
        {_certificate &&
          _certificate?.medias?.filter((item) => item?.type !== mediaType.LINK)
            ?.length > 0 && (
            <Draggable items={_certificate?.medias} reorderItem={reorderMedias}>
              {_certificate?.medias?.map((item, index) => {
                if (item?.type !== mediaType.LINK) {
                  return (
                    <DraggableComponent
                      key={Math.random()}
                      draggableId={Math.random().toString()}
                      index={index}
                      isDragDisabled={
                        _certificate?.medias?.filter(
                          (innerItem) => innerItem.type !== mediaType.LINK,
                        )?.length === 1
                      }
                    >
                      {(innerProvided) => (
                        <div
                          ref={innerProvided.innerRef}
                          {...innerProvided.draggableProps}
                          {...innerProvided.dragHandleProps}
                        >
                          <CardMedia
                            item={item}
                            onEdit={() => editMedia(item, index)}
                            onRemove={() => removeMedia(index)}
                            cantEdit={cantEdit || cantEditMedia}
                          />
                        </div>
                      )}
                    </DraggableComponent>
                  );
                }
                return <div />;
              })}
            </Draggable>
          )}
      </div>

      <ModalMedia
        openModal={openModal}
        onClose={closeModal}
        onChange={handleChangeMedia}
        medias={media}
        onSave={saveMedia}
        disabled={!media?.title || !media?.description}
      />
    </div>
  );
};

export default memo(AddCertificate);
