/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from "react";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import { Checkbox } from "../..";

export interface ModalConfigProps {
  id?: string;
  label?: string;
  type?: string;
  value?: any;
  hide?: boolean;
  handleChange?: (value: string | boolean) => void;
  options?: { value: string | boolean; label: string }[];
}

interface Props {
  config?: ModalConfigProps[] | [];
}

const Config = ({ config }: Props): JSX.Element => (
  <div className="modal-filters-list">
    {config
      ?.filter((filtered) => !filtered?.hide)
      .map((item) => (
        <div key={Math.random()} className="modal-filters-content">
          <div className="modal-filters-title">
            <h4 className="view-bold">{item?.label}</h4>
          </div>
          <div className={`modal-filters-items ${item?.type} ${item?.id}`}>
            {item?.type === "checkbox" &&
              item?.options?.map(({ value, label }) => (
                <Checkbox
                  key={Math.random()}
                  id={value as string}
                  label={label}
                  checked={item?.value?.includes(value)}
                  className="is-checkbox"
                  onChange={() => item?.handleChange(value)}
                />
              ))}
            {item?.type === "radio" && (
              <RadioGroup row value={item?.value || undefined}>
                {item?.options?.map(({ value, label }) => (
                  <FormControlLabel
                    key={Math.random()}
                    value={value}
                    control={<Radio />}
                    label={label}
                    className="margin-right-32"
                    checked={value === item?.value}
                    onChange={() => item?.handleChange(value)}
                  />
                ))}
              </RadioGroup>
            )}
          </div>
        </div>
      ))}
  </div>
);

export default memo(Config);
