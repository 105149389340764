import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const TwitterProfile = ({
  widthIcon,
  heightIcon,
  className,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    className={className}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#22A2F2"
      fillRule="evenodd"
      d="M14 25.667c6.444 0 11.667-5.224 11.667-11.667S20.444 2.333 14.001 2.333C7.557 2.333 2.334 7.557 2.334 14s5.223 11.667 11.667 11.667zm5.792-15.55c.627-.08 1.228-.244 1.792-.491-.423.629-.941 1.176-1.558 1.614.006.132.011.264.011.402 0 4.108-3.128 8.848-8.85 8.848-1.76 0-3.392-.507-4.77-1.396.245.032.49.043.744.043 1.455 0 2.798-.499 3.856-1.326-1.36-.03-2.503-.935-2.906-2.166.189.042.39.053.589.053.286 0 .562-.033.815-.103-1.413-.289-2.491-1.544-2.491-3.055v-.034c.415.229.905.374 1.405.383-.827-.552-1.381-1.507-1.381-2.584 0-.573.157-1.106.425-1.571 1.537 1.887 3.824 3.126 6.41 3.252-.052-.226-.073-.463-.073-.71 0-1.712 1.386-3.11 3.107-3.11.888 0 1.701.377 2.272.985.707-.142 1.37-.395 1.971-.753-.231.729-.723 1.334-1.368 1.719z"
      clipRule="evenodd"
    />
  </svg>
);

export default TwitterProfile;
