/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactNode,
  createRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Select, LabelInput } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { RootState } from "../../../store/reducers";
import {
  EnglishLevel,
  LevelEducation,
  ITLevel as ITLevelConstant,
  UniversityTitle,
  DifficultiesProgramming,
} from "../../../types/constants/Course";
import {
  getActiveUserClassVacancySubscription,
  getClassVacancyById,
} from "../../../services/functions";
import { classVacancyTemplate } from "../../../types/enumerators";
import {
  setCourseSubscription,
  updateCourseSubscription,
} from "../../../store/actions/courseActions";
import { ClassVacancy } from "../../../types/interfaces";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

interface Item {
  renderCondition?: boolean;
  child: ReactNode;
}

const Education = ({ setIsValid }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const subscription = useSelector((state: RootState) => state.courseState);
  const [_form, _setForm] = useState<any>(subscription?.form);
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();

  const {
    ITLevel,
    hasJSKnowledge,
    difficultiesProgramming,
    educationLevel,
    graduatedInIT,
    title,
    englishLevel,
  } = useMemo(() => _form, [_form]);

  const isDisabledTeT = useMemo(
    () =>
      (classVacancy?.template === classVacancyTemplate.TET ||
        classVacancy?.template === classVacancyTemplate.LEVEL_ONE) &&
      !ITLevel,
    [ITLevel, classVacancy?.template],
  );

  const isDisabledNotTeT = useMemo(
    () =>
      classVacancy?.template !== classVacancyTemplate.TET &&
      classVacancy?.template !== classVacancyTemplate.LEVEL_ONE &&
      !hasJSKnowledge &&
      !difficultiesProgramming,
    [classVacancy?.template, hasJSKnowledge, difficultiesProgramming],
  );

  const isDisabled = useMemo(
    () => ({
      difficultiesProgramming: isDisabledNotTeT,
      educationLevel: isDisabledTeT || isDisabledNotTeT,
      graduatedInIT: isDisabledTeT || isDisabledNotTeT || !educationLevel,
      title:
        isDisabledTeT ||
        isDisabledNotTeT ||
        !educationLevel ||
        graduatedInIT === undefined,
      englishLevel:
        isDisabledTeT ||
        isDisabledNotTeT ||
        !educationLevel ||
        (educationLevel?.includes("COLLEGE") && graduatedInIT === undefined) ||
        (educationLevel?.includes("COLLEGE") && graduatedInIT && !title),
    }),
    [educationLevel, graduatedInIT, isDisabledNotTeT, isDisabledTeT, title],
  );

  const handleOnBlur = useCallback(() => {
    if (!isDisabled.englishLevel && !!englishLevel) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(
            updateCourseSubscription({
              ...subscription,
              form: { ..._form },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [
    isDisabled.englishLevel,
    englishLevel,
    setIsValid,
    dispatch,
    subscription,
    _form,
  ]);

  const handleChangeSelect = useCallback(
    (value: string, prop: string) =>
      _setForm({
        ..._form,
        [prop]: value as string,
      }),
    [_form],
  );

  const handleChangeRadio = useCallback(
    (value: string, props) =>
      _setForm({
        ..._form,
        [props]: value === props,
      }),

    [_form],
  );

  const items = useMemo(
    (): Item[] => [
      {
        renderCondition:
          classVacancy?.template === classVacancyTemplate.TET ||
          classVacancy?.template === classVacancyTemplate.LEVEL_ONE,
        child: (
          <>
            <LabelInput
              marginBottom
              text="Qual o seu nível de conhecimento em programação?"
            />
            <Select
              id="ITLevel"
              label="Selecione"
              className="width-select-course"
              onChange={(e) => handleChangeSelect(e.target.value, "ITLevel")}
              onBlur={handleOnBlur}
              value={ITLevel ?? ""}
              options={Object.keys(ITLevelConstant)?.map((key) => ({
                value: key,
                label: ITLevelConstant[key as keyof typeof ITLevelConstant],
              }))}
            />
          </>
        ),
      },
      {
        renderCondition:
          classVacancy?.template !== classVacancyTemplate.TET &&
          classVacancy?.template !== classVacancyTemplate.LEVEL_ONE,
        child: (
          <>
            <LabelInput
              marginBottom
              text="Qual o seu nível de conhecimento de programação em JavaScript?"
            />
            <Select
              id="hasJSKnowledge"
              label="Selecione"
              className="width-select-course"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "hasJSKnowledge")
              }
              onBlur={handleOnBlur}
              value={hasJSKnowledge ?? ""}
              options={Object.keys(ITLevelConstant)?.map((key) => ({
                value: key,
                label: ITLevelConstant[key as keyof typeof ITLevelConstant],
              }))}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.difficultiesProgramming}
              text="Quais as maiores dificuldades que você encontrou ao continuar seus estudos em programação?"
            />
            <Select
              id="difficultiesProgramming"
              label="Selecione"
              className="width-select-course"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "difficultiesProgramming")
              }
              onBlur={handleOnBlur}
              value={difficultiesProgramming ?? ""}
              options={Object.keys(DifficultiesProgramming).map((key) => ({
                value: key,
                label:
                  DifficultiesProgramming[
                    key as keyof typeof DifficultiesProgramming
                  ],
              }))}
              disabled={isDisabled.difficultiesProgramming}
            />
          </>
        ),
        renderCondition:
          classVacancy?.template !== classVacancyTemplate.TET &&
          classVacancy?.template !== classVacancyTemplate.LEVEL_ONE,
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.educationLevel}
              text="Qual o seu nível de escolaridade?"
            />
            <Select
              id="educationLevel"
              label="Selecione"
              className="width-select-course"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "educationLevel")
              }
              onBlur={handleOnBlur}
              value={educationLevel ?? ""}
              options={Object.keys(LevelEducation).map((key) => ({
                value: key,
                label: LevelEducation[key as keyof typeof LevelEducation],
              }))}
              disabled={isDisabled.educationLevel}
            />
          </>
        ),
      },
      {
        renderCondition: educationLevel?.includes("COLLEGE"),
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.graduatedInIT}
              text="Sua formação no ensino superior é na área de tecnologia?"
            />
            <RadioGroup
              aria-label="graduatedInIT"
              name="student"
              value={
                graduatedInIT !== undefined
                  ? graduatedInIT
                    ? "graduatedInIT"
                    : "isntGraduatedInIT"
                  : ""
              }
              onChange={(e) =>
                handleChangeRadio(e.target.value, "graduatedInIT")
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="graduatedInIT"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.graduatedInIT}
                />
                <FormControlLabel
                  value="isntGraduatedInIT"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.graduatedInIT}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        renderCondition: educationLevel?.includes("COLLEGE") && graduatedInIT,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.title}
              text="Qual o nome do seu curso?"
            />
            <Select
              id="title"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e.target.value, "title")}
              onBlur={handleOnBlur}
              value={title ?? ""}
              options={UniversityTitle.map((item: string) => ({
                value: item,
                label: item,
              }))}
              disabled={isDisabled.title}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.englishLevel}
              text="Qual o seu nível de conhecimento de inglês?"
            />
            <Select
              id="englishLevel"
              label="Selecione"
              helperText="Fique tranquila, esta questão não é eliminatória."
              onChange={(e) =>
                handleChangeSelect(e.target.value, "englishLevel")
              }
              onBlur={handleOnBlur}
              value={englishLevel ?? ""}
              options={Object.keys(EnglishLevel).map((key) => ({
                value: key,
                label: EnglishLevel[key as keyof typeof EnglishLevel],
              }))}
              disabled={isDisabled.englishLevel}
            />
          </>
        ),
      },
    ],
    [
      ITLevel,
      classVacancy?.template,
      difficultiesProgramming,
      educationLevel,
      englishLevel,
      graduatedInIT,
      handleChangeRadio,
      handleChangeSelect,
      handleOnBlur,
      hasJSKnowledge,
      isDisabled.difficultiesProgramming,
      isDisabled.educationLevel,
      isDisabled.englishLevel,
      isDisabled.graduatedInIT,
      isDisabled.title,
      title,
    ],
  );

  const itemsRefs = useMemo(
    () => items.map(() => createRef<HTMLDivElement>()),
    [items],
  );

  const getSubscription = useCallback(async () => {
    const subscriptions = await getActiveUserClassVacancySubscription(user._id);
    if (subscriptions?.data?.length)
      dispatch(
        setCourseSubscription(subscriptions?.data?.[0]) as unknown as Action,
      );
  }, [user._id, dispatch]);

  const getClassVacancy = useCallback(async () => {
    const response = await getClassVacancyById(subscription?.classVacancyId);
    if (response?.data) setClassVacancy(response?.data);
  }, [subscription?.classVacancyId]);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  useEffect(() => {
    if (!classVacancy && !!subscription?._id) getClassVacancy();
  }, [classVacancy, subscription?._id, getClassVacancy]);

  useEffect(() => {
    if (isDisabled.englishLevel || !englishLevel) setIsValid(false);
    else handleOnBlur();
  }, [englishLevel, handleOnBlur, isDisabled.englishLevel, setIsValid]);

  return (
    <div className="grid-account-gap grid-account-gap-resposive education-stage">
      {items.map(
        ({ renderCondition, child }, index) =>
          (renderCondition ?? true) && (
            <div ref={itemsRefs[index]} className="grid-full-row">
              {child}
            </div>
          ),
      )}
    </div>
  );
};

export default memo(Education);
