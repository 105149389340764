import React, { Fragment, memo } from "react";
import { Tooltip } from "@material-ui/core";
import Card from "../Card";
import { Check, Info } from "../../../assets/icons";
import "./styles.scss";
import { ClassVacancyStages } from "../../types/interfaces";
import { classVacancyStatus, classVacancyType } from "../../types/enumerators";

interface Props {
  info?: boolean;
  stages: ClassVacancyStages[];
  listOfSubsteps?: string[];
}

const CardStepsCourse = ({
  stages,
  info,
  listOfSubsteps,
}: Props): JSX.Element => (
  <div className="grid-row-1 grid-column-2-5 margin-top-16 card-steps-course">
    <Card titleHeader="Etapas">
      {stages &&
        stages?.map(({ order, title, status, type }) => (
          <div key={Math.random()} className="step connect margin-bottom-20">
            {status === classVacancyStatus.FINISHED ? (
              <div className="svg-connect line">
                <Check />
              </div>
            ) : status === classVacancyStatus.ACTIVE ? (
              <div className="icon-step-vacancy-subscription active">
                {(order ?? 0) + 1}
              </div>
            ) : (
              <div className="icon-step-vacancy-subscription">
                {(order ?? 0) + 1}
              </div>
            )}
            <div
              className={`flex space-between align-items width-100 ${
                type === classVacancyType.WORKSHOP && "workshop-tooltip"
              }`}
            >
              <span>{title}</span>
              {type === classVacancyType.WORKSHOP && info && (
                <Tooltip
                  title={
                    <div>
                      <div>Subetapas</div>
                      {listOfSubsteps?.map((item: string) => (
                        <Fragment key={item}>
                          <li>{item}</li>
                        </Fragment>
                      ))}
                    </div>
                  }
                  placement="top"
                  arrow
                  className="tooltip"
                >
                  <div>
                    <Info className="icon-info cursor-pointer" />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        ))}
    </Card>
  </div>
);

export default memo(CardStepsCourse);
