/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  memo,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CompanyAvatarEmpty } from "../../../assets/customIcons";
import { ChevronRight } from "../../../assets/icons/index";
import {
  JobCompany,
  JobDatetime,
  JobLocation,
  JobStatus,
  JobTitle,
} from "../../components";
import {
  placementVacancyManagedBy,
  placementVacancyStatus,
  placementVacancyTypeOfCandidature,
} from "../../types/enumerators";

interface Props {
  data?: any;
  onClick?: MouseEventHandler<HTMLDivElement>;
  selectedId?: boolean;
  isActiveSection?: boolean;
  alreadyInProcess?: boolean;
}

const CardCandidature = ({
  data,
  onClick,
  selectedId,
  isActiveSection,
  alreadyInProcess = false,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  const currentStatus = useMemo(
    () =>
      data?.currentStatus
        ? data?.currentStatus
        : data?.jobManagedBy === placementVacancyManagedBy.EXTERNAL_VACANCY
        ? "Curadoria"
        : data?.jobStatus === placementVacancyStatus.ACTIVE
        ? data?.jobTypeOfCandidature === placementVacancyTypeOfCandidature.OPEN
          ? "Recebendo candidaturas"
          : "Apenas convidadas"
        : data?.jobStatus === placementVacancyStatus.PENDING
        ? "Inicia em breve"
        : "Vaga fechada",
    [
      data?.currentStatus,
      data?.jobManagedBy,
      data?.jobStatus,
      data?.jobTypeOfCandidature,
    ],
  );

  const verifyJobStatus = useMemo(() => {
    if (
      currentStatus === "Recebendo candidaturas" ||
      currentStatus === "Candidatada"
    )
      return true;
    return false;
  }, [currentStatus]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      role="button"
      tabIndex={0}
      className={`${selectedId && width > 990 && "selected-card-candidature"}`}
      onClick={onClick}
    >
      <div className="card-candidature-content flex">
        <div className="margin-24">
          {data?.photo ? (
            <img
              alt="logo"
              src={data?.photo}
              className="div-card-items company-avatar-empty-card"
            />
          ) : (
            <CompanyAvatarEmpty className="div-card-items company-avatar-empty-card" />
          )}
        </div>
        <div className="width-100">
          <JobTitle className="job-title-candidature" title={data?.jobTitle} />
          <div className="flex space-between width-100">
            <JobCompany
              className="margin-horizontal-4"
              companyName={data?.companyName}
            />
            <div>
              {width > 990 && (
                <ChevronRight
                  className={
                    selectedId
                      ? "Chevron-Rotaten margin-right-24"
                      : "margin-right-24"
                  }
                />
              )}
            </div>
          </div>
          <JobLocation
            className="margin-bottom-8"
            type={data?.jobType}
            place={data?.jobPlace}
          />

          <div className="margin-bottom-24">
            {alreadyInProcess ? (
              <p className="job-inf-text job-inf-date flex">
                Candidatou-se&nbsp;
                <JobDatetime datetime={data?.startDate} />
                {!isActiveSection && (
                  <>
                    | <strong>ENCERRADO</strong>
                  </>
                )}
              </p>
            ) : (
              <>
                <JobStatus
                  className={`job-status-candidature
                    ${
                      verifyJobStatus
                        ? "job-inf-status-active"
                        : "job-status-candidature-false"
                    }`}
                  status={currentStatus}
                  showInfo={currentStatus === "Curadoria"}
                />
                <JobDatetime datetime={data?.jobTime} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CardCandidature);
