/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { Card } from "..";
import { Curriculum } from "../../../assets/icons";
import "./styles.scss";

const NotificationResearch = (): JSX.Element => {
  const history = useHistory();

  return (
    <div className="grid-column-10-13 new-search">
      <Card
        className="grid-column-10-13 new-search-header"
        titleHeader="Novidade"
      >
        <div className="flex-row-center padding-bottom-24">
          <div>
            <Curriculum />
          </div>
          <p className="new-search-notification">
            Temos uma nova pesquisa disponível para você.
          </p>
        </div>
        <div className="new-search-divider" />
        <div>
          <h1
            className="new-search-button margin-top-24"
            onClick={() => history.push("/all-research")}
          >
            RESPONDER PESQUISA
          </h1>
        </div>
      </Card>
    </div>
  );
};

export default memo(NotificationResearch);
