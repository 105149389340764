/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useParams } from "react-router-dom";
import moment, { MomentInput } from "moment";
import Errors from "../Errors";
import { ProfileRace, ProfileGender } from "../../types/constants/User";
import {
  hasAdminClassVacancyVolunteerMediaRole,
  isAdmin,
  order,
  region,
} from "../../utils";
import { Layout, HeaderProfile, ViewSocialMedias } from "../../components";
import {
  getPages,
  getClassById,
  getClassVacancyById,
  filterClassVacancySubscription,
  getAllClassVacancySubscriptionByClassVacancy,
  Revenue,
  Region,
} from "../../services/functions";
import { RootState } from "../../store/reducers";
import {
  classVacancyType,
  userGenderIdentity,
  userRace,
} from "../../types/enumerators";
import {
  User,
  ClassVacancy,
  UserBootcamps,
  UserAccount,
  UserProfile,
  ClassVacancySubscriptionStage,
  Class,
} from "../../types/interfaces";
import { setLoad } from "../../store/actions/configurationsActions";
import {
  ActionButton,
  Count,
  Infos,
  Pagination,
  Path,
  Stages,
  Table,
} from "./Components";
import "./style.scss";

interface ParamsProps {
  id: string;
}

interface SubscriptionTDO {
  _id: string;
  stages: {
    stageId: string;
    workshopClass: string;
    participant?: {
      morning?: boolean;
      afternoon?: boolean;
    };
    order: number;
    options: {
      classId: string;
      order: number;
    }[];
  }[];
  miscellaneous: { generalComment: { enable: boolean }[] };
  userId: any;
  user: {
    name: string;
    email: string;
    bootcamps: UserBootcamps[];
    account: UserAccount;
    legalIdentity: string;
    genderIdentity: string;
    race: string;
    state: string;
    socialMedias: any;
    status: string;
    photo: string;
  };
  subscriptionScore: number;
  mediaScore: number;
  workshopScore: number;
  diversityScore: number;
  behaviorScore: number;
  technicalScore: number;
  talentAcademyScore: number;
  testScore: number;
  totalScore: number;
  startDate: MomentInput;
  endDate: MomentInput;
  status: string;
  classWorkshop: { code: string };
  classBootcamps: { code: string }[];
  subscriptions: string[];
  workshopFirstOption: string;
  workshopSecondOption: string;
  tags: string;
  volunteerName: string;
  volunteerEmail: string;
}

export interface Config {
  visibleColumns?: string[];
  sortBy?: string;
  asc?: boolean;
  limit?: number;
}

export interface Filters {
  ids?: string[] | null;
  userId?: string[] | null;
  stageId?: string | null;
  status?: string | null;
  workshopClass?: string | null;
  workshopFirstOption?: string | null;
  workshopSecondOption?: string | null;
  score?: {
    max?: number;
    min?: number;
    isNull?: boolean;
    stageId: string;
  } | null;
  childrenOfAge?: boolean | null;
  isLGBTQIA?: boolean | null;
  isReprogramaStudent?: boolean | null;
  similarCourse?: boolean | null;
  educationLevel?: string[] | null;
  perCapitalRevenue?: Revenue | null;
  montlyRevenue?: Revenue | null;
  isTeacher?: boolean | null;
  employabilitySituation?: string[] | null;
  isEmployedInIT?: boolean;
  ITLevel?: string[] | null;
  hasJSKnowledge?: string[] | null;
  reprogramaStudentOtherClasses?: string[] | null;
  hasMedia?: boolean | null;
  participated?: boolean | null;
  hasForm?: boolean | null;
  hasFormDiversity?: boolean | null;
  hasBehavior?: boolean | null;
  hasTechnical?: boolean | null;
  hasComments?: boolean | null;
  tags?: string[] | null;
  /* --- filters user --- */
  legalIdentity?: string | null;
  name?: string | null;
  email?: string | null;
  genderIdentity?: Array<keyof typeof userGenderIdentity> | null;
  race?: Array<keyof typeof userRace> | null;
  disabledPerson?: boolean | null;
  needAccessibilityAdaptation?: boolean | null;
  hasComputer?: boolean | null;
  hasInternet?: boolean | null;
  region?: Region[] | null;
  age?: number | null;
  volunteersId?: string[] | null;
}

export interface List {
  _id: string;
  profile: UserProfile;
  userId: string;
  name: string;
  email: string;
  volunteer: string;
  userStatus: string;
  photo: string;
  legalIdentity: string;
  subscription: string;
  media: string;
  workshop: string;
  diversity: string;
  behavior: string;
  technical: string;
  morning: string;
  afternoon: string;
  talentAcademy: string;
  test: string;
  total: string;
  workshopFirstOption: string;
  workshopSecondOption: string;
  workshopClass: string;
  class: string;
  gender: string;
  race: string;
  region: string;
  socialMedia: JSX.Element;
  startDate: string;
  endDate: string;
  stage: string;
  status: string;
  miscellaneous: {
    generalComment: {
      enable: boolean;
    }[];
  };
  bootcamps: UserBootcamps[];
  account: UserAccount;
  tags: string[];
  lastStage: string;
  firstStage: string;
  stages: ClassVacancySubscriptionStage[];
}

const AdminClassVacancySubscriptions = (): JSX.Element => {
  const { id } = useParams<ParamsProps>();

  const user: User = useSelector((state: RootState) => state.userState);
  const isVolunteerMedia =
    isAdmin(user) && hasAdminClassVacancyVolunteerMediaRole(user);

  const dispatch = useDispatch();

  const [pageError, setPageError] = useState(false);
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();

  const [list, setList] = useState<List[]>();
  const [config, setConfig] = useState<Config>();
  const [filters, setFilters] = useState<Filters>();
  const [totalList, setTotalList] = useState<number>();
  const [totalFilteredList, setTotalFilteredList] = useState<number>();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const [openModal, setOpenModal] = useState(false);

  const [classList, setClassList] = useState<{ _id?: string; code?: string }[]>(
    [],
  );

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const stagesIds = useMemo(
    () => ({
      mediaStageId:
        classVacancy?.stages?.find(
          (item) => item?.type === classVacancyType.UPLOAD_MEDIAS,
        ) !== undefined
          ? classVacancy?.stages?.find(
              (item) => item?.type === classVacancyType.UPLOAD_MEDIAS,
            )?.stage_id
          : undefined,
      subscriptionStageId:
        classVacancy?.stages?.find(
          (item) => item?.type === classVacancyType.SUBSCRIPTION,
        ) !== undefined
          ? classVacancy?.stages?.find(
              (item) => item?.type === classVacancyType.SUBSCRIPTION,
            )?.stage_id
          : undefined,
      workshopStageId:
        classVacancy?.stages?.find(
          (item) => item?.type === classVacancyType.WORKSHOP,
        ) !== undefined
          ? classVacancy?.stages?.find(
              (item) => item?.type === classVacancyType.WORKSHOP,
            )?.stage_id
          : undefined,
      talentAcademyStageId:
        classVacancy?.stages?.find(
          (item) => item?.type === classVacancyType.TALENT_ACADEMY,
        ) !== undefined
          ? classVacancy?.stages?.find(
              (item) => item?.type === classVacancyType.TALENT_ACADEMY,
            )?.stage_id
          : undefined,
      testStageId:
        classVacancy?.stages?.find(
          (item) => item?.type === classVacancyType.TEST,
        ) !== undefined
          ? classVacancy?.stages?.find(
              (item) => item?.type === classVacancyType.TEST,
            )?.stage_id
          : undefined,
    }),
    [classVacancy?.stages],
  );

  const getClassVacancy = useCallback(async () => {
    const response = await getClassVacancyById(id);
    const classIds = response?.data?.classIds ?? [];
    const classTitles: any = [];
    const classes: any = [];
    for (let i = 0; i < classIds?.length; i += 1) {
      const responseClass = await getClassById(classIds[i]);
      classes?.push({
        _id: responseClass?.data?._id,
        code: responseClass?.data?.code,
      });
      classTitles?.push(responseClass?.data?.title ?? "");
    }
    setClassList(classes);
    setClassVacancy({
      ...response?.data,
      classTitles: classTitles?.join(", "),
    });
  }, [id]);

  useEffect(() => {
    if (!classVacancy && id) getClassVacancy();
  }, [classVacancy, getClassVacancy, id]);

  useEffect(() => {
    const getTotalList = async () => {
      const result = await getAllClassVacancySubscriptionByClassVacancy(
        id,
        true,
        stagesIds,
      );

      if (result?.length) setTotalList(result[0]?.size ?? 0);
      else setTotalList(0);
    };

    if (classVacancy?._id && totalList === undefined) getTotalList();
  }, [classVacancy?._id, id, stagesIds, totalList]);

  const getList = useCallback(
    async (response) => {
      dispatch(setLoad(true) as unknown as Action);
      const currentList: any = [];
      if (response) {
        response?.forEach(async (fields: SubscriptionTDO) => {
          order(fields?.stages);
          const stageId =
            fields?.stages[(fields?.stages?.length ?? 1) - 1]?.stageId ?? "";
          const stage =
            classVacancy?.stages?.find((item) => item?.stage_id === stageId)
              ?.title ?? "";
          const firstStage =
            classVacancy?.stages &&
            classVacancy?.stages[0]?.stage_id === stageId;
          const lastStage =
            classVacancy?.stages &&
            classVacancy?.stages[(classVacancy?.stages?.length ?? 1) - 1]
              ?.stage_id === stageId;
          const subsWithUser = {
            _id: fields?._id,
            userId: fields?.userId,
            name: fields?.user?.name ?? "",
            email: fields?.user?.email ?? "",
            volunteer: fields?.volunteerName ?? fields?.volunteerEmail ?? "",
            userStatus: fields?.user?.status,
            photo: fields?.user?.photo,
            legalIdentity: fields?.user?.legalIdentity,
            subscription:
              classVacancy?.stages?.find(
                (item) => item?.type === classVacancyType.SUBSCRIPTION,
              ) !== undefined
                ? fields?.subscriptionScore
                : "",
            media:
              classVacancy?.stages?.find(
                (item) => item?.type === classVacancyType.UPLOAD_MEDIAS,
              ) !== undefined
                ? fields?.mediaScore
                : "",
            workshop:
              classVacancy?.stages?.find(
                (item) => item?.type === classVacancyType.WORKSHOP,
              ) !== undefined
                ? fields?.workshopScore
                : "",
            diversity: fields?.diversityScore ?? "",
            behavior: fields?.behaviorScore ?? "",
            technical: fields?.technicalScore ?? "",
            morning: fields?.stages?.find((item) => item?.participant?.morning)
              ? "Sim"
              : "Não",
            afternoon: fields?.stages?.find(
              (item) => item?.participant?.afternoon,
            )
              ? "Sim"
              : "Não",
            talentAcademy:
              classVacancy?.stages?.find(
                (item) => item?.type === classVacancyType.TALENT_ACADEMY,
              ) !== undefined
                ? fields?.talentAcademyScore
                : "",
            test:
              classVacancy?.stages?.find(
                (item) => item?.type === classVacancyType.TEST,
              ) !== undefined
                ? fields?.testScore
                : "",
            total: parseFloat(fields?.totalScore?.toFixed(2) ?? 0) ?? "",
            workshopFirstOption: fields?.workshopFirstOption ?? "",
            workshopSecondOption: fields?.workshopSecondOption ?? "",
            workshopClass: fields?.classWorkshop?.code ?? "",
            class: fields?.classBootcamps
              ?.map((item: { code: string }) => item?.code)
              ?.join(", "),
            subscriptions: fields?.subscriptions?.join(", "),
            gender: fields?.user?.genderIdentity
              ? ProfileGender[fields?.user?.genderIdentity]
              : undefined,
            race: fields?.user?.race
              ? ProfileRace[fields?.user?.race]
              : undefined,
            region: fields?.user?.state ? region(fields?.user?.state) : "",
            socialMedia: (
              <div className="admin-subscriptions-socials">
                <ViewSocialMedias
                  socialMedias={fields?.user?.socialMedias ?? []}
                />
              </div>
            ),
            startDate: moment(fields?.startDate).isValid()
              ? moment(fields?.startDate).format("DD/MM/YYYY")
              : "",
            endDate: moment(fields?.endDate).isValid()
              ? moment(fields?.endDate).format("DD/MM/YYYY")
              : "",
            stage,
            status: fields?.status ?? "",
            miscellaneous: fields?.miscellaneous,
            bootcamps: fields?.user?.bootcamps,
            account: fields?.user?.account,
            stages: fields?.stages,
            tags: fields?.tags,
            lastStage,
            firstStage,
          };
          currentList?.push(subsWithUser);
        });
      }
      setList(currentList);
      dispatch(setLoad(false) as unknown as Action);
    },
    [classVacancy?.stages, dispatch],
  );

  const handleFilterCandidate = useCallback(
    async (f?: Filters, c?: Config) => {
      dispatch(setLoad(true) as unknown as Action);

      const currentConfig = c ?? config;
      const currentFilters = f ?? filters;

      if (pageLimit && classVacancy?._id) {
        setMaxPage(
          await getPages(
            filterClassVacancySubscription,
            id,
            currentFilters,
            true,
          ),
        );

        const result = (await filterClassVacancySubscription(
          id ?? "",
          currentFilters,
          true,
          stagesIds,
        )) as { size?: number }[];
        if (result?.length) setTotalFilteredList(result[0]?.size ?? 0);

        const response = await filterClassVacancySubscription(
          id ?? "",
          currentFilters,
          false,
          stagesIds,
          page,
          pageLimit,
          currentConfig?.sortBy,
          currentConfig?.asc,
          pageLimit,
          currentConfig?.visibleColumns?.includes("volunteer"),
          currentConfig?.visibleColumns?.includes("subscriptions"),
        );

        getList(response);
      } else setList([]);

      dispatch(setLoad(false) as unknown as Action);
    },
    [
      dispatch,
      config,
      filters,
      pageLimit,
      classVacancy?._id,
      id,
      stagesIds,
      page,
      getList,
    ],
  );

  useEffect(() => {
    if (
      classVacancy?._id &&
      config &&
      (list || (isVolunteerMedia && user?.account?.classVacancy?.[id]?.length))
    )
      handleFilterCandidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isVolunteerMedia,
    classVacancy?._id,
    page,
    pageLimit,
    config?.asc,
    config?.sortBy,
    user?.account?.classVacancy?.[id],
  ]);

  useEffect(() => {
    if (!isVolunteerMedia && !!user?._id) setOpenModal(true);
  }, [isVolunteerMedia, user]);

  useEffect(() => {
    if (
      id &&
      user?._id &&
      isVolunteerMedia &&
      (!user?.account?.classVacancy ||
        (user?.account?.classVacancy &&
          !Object.hasOwn(user?.account?.classVacancy, id)))
    )
      setPageError(true);
  }, [id, isVolunteerMedia, user]);

  return pageError ? (
    <Errors error={403} />
  ) : (
    <Layout
      className={`admin-subscriptions ${
        !isVolunteerMedia ? "not-volunteer-media" : ""
      }`}
    >
      <HeaderProfile />
      <Path title={classVacancy?.title ?? ""} />
      {!isVolunteerMedia && id && (
        <ActionButton
          classVacancy={classVacancy as ClassVacancy}
          stagesIds={stagesIds}
          reload={getClassVacancy}
          totalList={totalList ?? 0}
        />
      )}
      <Count
        totalList={
          isVolunteerMedia
            ? user?.account?.classVacancy?.[id]?.length ?? 0
            : totalList ?? 0
        }
      />
      <Infos classVacancy={classVacancy as ClassVacancy} />
      {!isVolunteerMedia && (
        <Stages
          classVacancy={classVacancy as ClassVacancy}
          reload={getClassVacancy}
        />
      )}
      <Table
        userClassVacancy={user?.account?.classVacancy}
        data={list as List[]}
        getData={handleFilterCandidate}
        isVolunteer={isVolunteerMedia}
        classVacancy={classVacancy as ClassVacancy}
        classList={classList as Class[]}
        config={{
          openModal,
          setOpenModal,
          config,
          setConfig,
          filters,
          setFilters,
        }}
      />
      <Pagination
        page={page}
        setPage={setPage}
        maxPage={maxPage}
        value={list?.length ?? 0}
        total={totalFilteredList ?? 0}
      />
    </Layout>
  );
};

export default memo(AdminClassVacancySubscriptions);
