import React, { Dispatch, memo, SetStateAction } from "react";
import { Button } from "..";
import "./styles.scss";

interface Props {
  setType: Dispatch<SetStateAction<string>>;
}

const TypeAccess = ({ setType }: Props): JSX.Element => (
  <div className="margin-bottom-32 type-access">
    <p className="type-access-title">
      Escolha uma das opções para acessar a plataforma:
    </p>
    <div className="flex-col">
      <Button
        onClick={() => setType("STUDENT")}
        text="PORTAL DE EX-ALUNA"
        className="margin-bottom-24"
      />
      <Button
        onClick={() => setType("CANDIDATE")}
        text="INSCRIÇÕES"
        className="margin-bottom-24"
        appearance="secondary"
      />
      <Button
        onClick={() => setType("CORPORATE")}
        text="PORTAL DE EMPRESA"
        outline
        className="margin-bottom-24"
        appearance="secondary"
      />
      <Button
        onClick={() => setType("ADMIN")}
        text="PORTAL ADMIN"
        outline
        className="margin-bottom-40"
        appearance="secondary"
      />
    </div>
  </div>
);

export default memo(TypeAccess);
