/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControlLabel, Switch } from "@material-ui/core";
import moment from "moment-timezone";
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import {
  Button,
  Card,
  Input,
  InputAutocomplete,
  InputDate,
  Select,
  TextArea,
} from "../../../components";
import { filterClass, getClassById } from "../../../services/functions";
import {
  ClassVacancyRestrictions,
  ClassVacancyTemplate,
} from "../../../types/constants/Course";
import { classStructure, classVacancyStatus } from "../../../types/enumerators";
import { Class, ClassVacancy } from "../../../types/interfaces";
import "../style.scss";
import { setLoad } from "../../../store/actions/configurationsActions";

interface Props {
  classVacancy: ClassVacancy;
  setClassVacancy: Dispatch<SetStateAction<ClassVacancy>>;
  nextPage: () => void;
  onSave: () => void;
}

const GeneralData = ({
  classVacancy,
  setClassVacancy,
  nextPage,
  onSave,
}: Props): JSX.Element => {
  const history = useHistory();
  const [allClass, setAllClass] = useState<Class[]>([]);
  const [classes, setClasses] = useState<Class[]>([]);
  const [useTemplate, setUseTemplate] = useState(!!classVacancy?.template);
  const dispatch = useDispatch();
  const listRestrictions = useMemo(
    () =>
      Object.keys(ClassVacancyRestrictions)?.map((key) => ({
        key,
        value:
          ClassVacancyRestrictions[
            key as keyof typeof ClassVacancyRestrictions
          ] ?? "",
      })) ?? [],
    [],
  );

  const getClasses = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await filterClass();
    if (response?.data) setAllClass(response?.data);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  const getValueClasses = useCallback(async () => {
    const valueClasses: Class[] = [];
    dispatch(setLoad(true) as unknown as Action);
    for (let i = 0; i < classVacancy?.classIds?.length; i += 1) {
      const response = await getClassById(classVacancy?.classIds[i] ?? "");
      if (response?.data) valueClasses?.push(response?.data);
    }
    setClasses(valueClasses);
    dispatch(setLoad(false) as unknown as Action);
  }, [classVacancy?.classIds, dispatch]);

  useEffect(() => {
    if (!allClass?.length) getClasses();
  }, [allClass?.length, getClasses]);

  useEffect(() => {
    if (classVacancy?._id) setUseTemplate(!!classVacancy?.template);
  }, [classVacancy?._id, classVacancy?.template]);

  useEffect(() => {
    if (classVacancy?.classIds?.length) getValueClasses();
    else setClasses([]);
  }, [getValueClasses, classVacancy?.classIds?.length]);

  const handleChange = useCallback(
    (e, props: "title" | "classIds" | "description") =>
      setClassVacancy({ ...classVacancy, [props]: e.target.value }),
    [classVacancy, setClassVacancy],
  );

  const handleChangeDate = useCallback(
    (value, prop: "startDate" | "endDate") =>
      setClassVacancy({
        ...classVacancy,
        [prop]: value?.startOf("day").tz("America/Sao_Paulo").toISOString(),
      }),
    [classVacancy, setClassVacancy],
  );

  const handleChangeSwitch = useCallback(
    (value, prop: "autoOpen" | "autoClose") =>
      setClassVacancy({ ...classVacancy, [prop]: value }),
    [classVacancy, setClassVacancy],
  );

  const handleChangeSelect = useCallback(
    (
      event: { target: { value: keyof typeof ClassVacancyTemplate } },
      prop: "template",
    ) =>
      setClassVacancy({
        ...classVacancy,
        [prop]: event.target.value,
      }),
    [classVacancy, setClassVacancy],
  );

  const handleChangeClassIds = useCallback(
    (value) => {
      const classesIds: Class[] = [];
      value?.forEach((item: string) => {
        const valueClass = allClass?.find(
          (innerItem) => innerItem?.code === item,
        );
        if (valueClass) classesIds?.push(valueClass);
      });
      setClassVacancy({
        ...classVacancy,
        classIds: classesIds?.map((item) => item?._id ?? ""),
      });
    },
    [setClassVacancy, classVacancy, allClass],
  );

  const handleChangeRestrictions = useCallback(
    (value) => {
      const restrictions: {
        key?: string;
        value?: string;
      }[] = [];
      value?.forEach((item: string) => {
        const valueRestriction = listRestrictions?.find(
          (innerItem) => innerItem?.value === item,
        );
        if (valueRestriction) restrictions?.push(valueRestriction);
      });
      setClassVacancy({
        ...classVacancy,
        restrictions: restrictions?.map((item) => item?.key ?? ""),
      });
    },
    [classVacancy, listRestrictions, setClassVacancy],
  );

  return (
    <>
      <div className="grid-column-5-12 grid-row-2">
        <Card
          className="grid-column-1-8 border-card"
          titleHeader="Dados gerais"
        >
          <div className="grid-2-column">
            <div className="media-type grid-row-1 grid-column-1-2 date-class-vacancy">
              <FormControlLabel
                control={
                  <Switch
                    checked={useTemplate}
                    onChange={() => setUseTemplate(!useTemplate)}
                    name="autoOpen"
                  />
                }
                label="Usar template"
              />
            </div>
            {useTemplate && (
              <Select
                id="template"
                label="Template*"
                value={classVacancy?.template ?? ""}
                options={Object.keys(ClassVacancyTemplate)?.map((key) => ({
                  value: key,
                  label:
                    ClassVacancyTemplate[
                      key as keyof typeof ClassVacancyTemplate
                    ],
                }))}
                onChange={(e) => handleChangeSelect(e, "template")}
                className="grid-row-1 grid-column-2-3"
              />
            )}
            <Input
              id="title"
              label="Título*"
              type="text"
              value={classVacancy?.title ?? ""}
              onChange={(e) => handleChange(e, "title")}
              className="grid-row-2 grid-column-1-2"
              disabled={useTemplate && !classVacancy?.template}
            />
            <InputAutocomplete
              multiple
              label="Turmas"
              helperText="Pressione Enter para adicionar"
              options={
                allClass
                  ?.filter(
                    (item) =>
                      item?.status === classVacancyStatus.ACTIVE &&
                      item?.classStructure !== classStructure.WORKSHOP,
                  )
                  ?.map((innerItem) => innerItem.code) ?? [""]
              }
              getOptionLabel={(option: any) => option}
              value={classes?.map((item) => item?.code)}
              onChange={handleChangeClassIds}
              disabled={
                (useTemplate && !classVacancy?.template) || !classVacancy?.title
              }
              className="grid-row-2 grid-column-2-3"
            />
            <div className="media-type grid-row-3 grid-column-1-2 date-class-vacancy">
              <InputDate
                future
                views={["year", "month", "date"]}
                format="DD/MM/yyyy"
                label="Abertura*"
                helperText="DD/MM/AAAA"
                minDate={classVacancy?._id ? undefined : moment().toDate()}
                value={classVacancy?.startDate ?? null}
                onChange={(value) => handleChangeDate(value, "startDate")}
                disabled={
                  (useTemplate && !classVacancy?.template) ||
                  !classVacancy?.title ||
                  !classVacancy?.classIds?.length
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={classVacancy?.autoOpen}
                    onChange={() =>
                      handleChangeSwitch(!classVacancy?.autoOpen, "autoOpen")
                    }
                    name="autoOpen"
                  />
                }
                label="Abertura automática"
                disabled={
                  (useTemplate && !classVacancy?.template) ||
                  !classVacancy?.title ||
                  !classVacancy?.classIds?.length
                }
              />
            </div>
            <div className="media-type grid-row-3 grid-column-2-3 date-class-vacancy">
              <InputDate
                future
                label="Encerramento*"
                views={["year", "month", "date"]}
                format="DD/MM/yyyy"
                helperText="DD/MM/AAAA"
                value={classVacancy?.endDate ?? null}
                minDate={classVacancy?.startDate}
                onChange={(value) => handleChangeDate(value, "endDate")}
                disabled={
                  (useTemplate && !classVacancy?.template) ||
                  !classVacancy?.title ||
                  !classVacancy?.classIds?.length ||
                  !classVacancy?.startDate
                }
              />
              <FormControlLabel
                control={
                  <Switch checked={classVacancy?.autoClose} name="autoClose" />
                }
                label="Encerramento automático"
                onChange={() =>
                  handleChangeSwitch(!classVacancy?.autoClose, "autoClose")
                }
                disabled={
                  (useTemplate && !classVacancy?.template) ||
                  !classVacancy?.title ||
                  !classVacancy?.classIds?.length ||
                  !classVacancy?.startDate
                }
              />
            </div>
            <TextArea
              id="description"
              label="Descrição*"
              className="grid-row-4 grid-column-1-3 description-input"
              maxLength={500}
              value={classVacancy?.description ?? ""}
              onChange={(e) => handleChange(e, "description")}
              disabled={
                (useTemplate && !classVacancy?.template) ||
                !classVacancy?.title ||
                !classVacancy?.classIds?.length ||
                !classVacancy?.startDate ||
                !classVacancy?.endDate
              }
            />
            <InputAutocomplete
              multiple
              label="Restrições"
              className="grid-row-5 grid-column-1-3 description-input"
              options={listRestrictions?.map((item) => item?.value ?? "")}
              getOptionLabel={(option: any) => option}
              value={classVacancy?.restrictions?.map(
                (item: string) =>
                  listRestrictions?.find((innerItem) => innerItem?.key === item)
                    ?.value ?? "",
              )}
              onChange={handleChangeRestrictions}
              disabled={
                (useTemplate && !classVacancy?.template) ||
                !classVacancy?.title ||
                !classVacancy?.classIds?.length ||
                !classVacancy?.startDate ||
                !classVacancy?.endDate ||
                !classVacancy?.description
              }
            />
          </div>
        </Card>
      </div>
      <Button
        text="Cancelar"
        className="grid-column-8-10 grid-row-4 margin-bottom-40"
        onClick={() => {
          history.push("/admin-class-vacancy");
        }}
        outline
        appearance="secondary"
      />
      <Button
        text={!classVacancy?._id ? "Avançar" : "Salvar"}
        className={`${
          !classVacancy?._id ? "button-primary" : ""
        } grid-column-10-12 grid-row-4`}
        onClick={() => (!classVacancy?._id ? nextPage() : onSave())}
        disabled={
          (useTemplate && !classVacancy?.template) ||
          !classVacancy?.title ||
          !classVacancy?.classIds?.length ||
          !classVacancy?.startDate ||
          !classVacancy?.endDate ||
          !classVacancy?.description
        }
      />
    </>
  );
};

export default memo(GeneralData);
