/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactNode,
  createRef,
} from "react";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Select, LabelInput } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { RootState } from "../../../store/reducers";
import {
  LevelEducation,
  NumItems,
  OriginOfWater,
  TypeOfStreet,
} from "../../../types/constants/Course";
import {
  getActiveUserClassVacancySubscription,
  getClassVacancyById,
} from "../../../services/functions";
import {
  setCourseSubscription,
  updateCourseSubscription,
} from "../../../store/actions/courseActions";
import { ClassVacancy } from "../../../types/interfaces";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

interface Item {
  renderCondition?: boolean;
  child: ReactNode;
}

const SocioEconomicAssessment = ({ setIsValid }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const subscription = useSelector((state: RootState) => state.courseState);
  const [_form, _setForm] = useState<any>(subscription?.form);
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();

  const {
    privateVehicles,
    monthlyEmployees,
    washingMachines,
    bathrooms,
    dvdDevices,
    refrigerators,
    freezers,
    microcomputers,
    dishwashers,
    microwaves,
    professionalMotorcycles,
    clothesDryers,
    originOfWater,
    typeOfStreet,
    levelEducationBoss,
  } = useMemo(() => _form, [_form]);

  const isDisabled = useMemo(
    () => ({
      monthlyEmployees: !privateVehicles,
      washingMachines: !privateVehicles || !monthlyEmployees,
      bathrooms: !privateVehicles || !monthlyEmployees || !washingMachines,
      dvdDevices:
        !privateVehicles || !monthlyEmployees || !washingMachines || !bathrooms,
      refrigerators:
        !privateVehicles ||
        !monthlyEmployees ||
        !washingMachines ||
        !bathrooms ||
        !dvdDevices,
      freezers:
        !privateVehicles ||
        !monthlyEmployees ||
        !washingMachines ||
        !bathrooms ||
        !dvdDevices ||
        !refrigerators,
      microcomputers:
        !privateVehicles ||
        !monthlyEmployees ||
        !washingMachines ||
        !bathrooms ||
        !dvdDevices ||
        !refrigerators ||
        !freezers,
      dishwashers:
        !privateVehicles ||
        !monthlyEmployees ||
        !washingMachines ||
        !bathrooms ||
        !dvdDevices ||
        !refrigerators ||
        !freezers ||
        !microcomputers,
      microwaves:
        !privateVehicles ||
        !monthlyEmployees ||
        !washingMachines ||
        !bathrooms ||
        !dvdDevices ||
        !refrigerators ||
        !freezers ||
        !microcomputers ||
        !dishwashers,
      professionalMotorcycles:
        !privateVehicles ||
        !monthlyEmployees ||
        !washingMachines ||
        !bathrooms ||
        !dvdDevices ||
        !refrigerators ||
        !freezers ||
        !microcomputers ||
        !dishwashers ||
        !microwaves,
      clothesDryers:
        !privateVehicles ||
        !monthlyEmployees ||
        !washingMachines ||
        !bathrooms ||
        !dvdDevices ||
        !refrigerators ||
        !freezers ||
        !microcomputers ||
        !dishwashers ||
        !microwaves ||
        !professionalMotorcycles,
      originOfWater:
        !privateVehicles ||
        !monthlyEmployees ||
        !washingMachines ||
        !bathrooms ||
        !dvdDevices ||
        !refrigerators ||
        !freezers ||
        !microcomputers ||
        !dishwashers ||
        !microwaves ||
        !professionalMotorcycles ||
        !clothesDryers,
      typeOfStreet:
        !privateVehicles ||
        !monthlyEmployees ||
        !washingMachines ||
        !bathrooms ||
        !dvdDevices ||
        !refrigerators ||
        !freezers ||
        !microcomputers ||
        !dishwashers ||
        !microwaves ||
        !professionalMotorcycles ||
        !clothesDryers ||
        !originOfWater,
      levelEducationBoss:
        !privateVehicles ||
        !monthlyEmployees ||
        !washingMachines ||
        !bathrooms ||
        !dvdDevices ||
        !refrigerators ||
        !freezers ||
        !microcomputers ||
        !dishwashers ||
        !microwaves ||
        !professionalMotorcycles ||
        !clothesDryers ||
        !originOfWater ||
        !typeOfStreet,
    }),
    [
      bathrooms,
      clothesDryers,
      dishwashers,
      dvdDevices,
      freezers,
      microcomputers,
      microwaves,
      monthlyEmployees,
      originOfWater,
      privateVehicles,
      professionalMotorcycles,
      refrigerators,
      typeOfStreet,
      washingMachines,
    ],
  );

  const handleOnBlur = useCallback(() => {
    if (!isDisabled.levelEducationBoss && !!levelEducationBoss) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(
            updateCourseSubscription({
              ...subscription,
              form: { ..._form },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [
    _form,
    dispatch,
    isDisabled.levelEducationBoss,
    levelEducationBoss,
    setIsValid,
    subscription,
  ]);

  const handleChangeSelect = useCallback(
    (value: string, prop: string) =>
      _setForm({
        ..._form,
        [prop]: value as string,
      }),
    [_form],
  );

  const items = useMemo(
    (): Item[] => [
      {
        child: (
          <>
            <LabelInput
              marginBottom
              text="Quantidade de automóveis de passeio exclusivamente para uso particular"
            />
            <Select
              id="privateVehicles"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "privateVehicles")
              }
              onBlur={handleOnBlur}
              value={privateVehicles ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.monthlyEmployees}
              text="Quantidade de empregados mensalistas, considerando apenas os que trabalham pelo menos cinco dias por semana"
            />
            <Select
              id="monthlyEmployees"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "monthlyEmployees")
              }
              onBlur={handleOnBlur}
              value={monthlyEmployees ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
              disabled={isDisabled.monthlyEmployees}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.washingMachines}
              text="Quantidade de máquinas de lavar roupa, excluindo tanquinho"
            />
            <Select
              id="washingMachines"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "washingMachines")
              }
              onBlur={handleOnBlur}
              value={washingMachines ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
              disabled={isDisabled.washingMachines}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.bathrooms}
              text="Quantidade de banheiros"
            />
            <Select
              id="bathrooms"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e.target.value, "bathrooms")}
              onBlur={handleOnBlur}
              value={bathrooms ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
              disabled={isDisabled.bathrooms}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.dvdDevices}
              text="DVD, incluindo qualquer dispositivo que leia DVD e desconsiderando DVD de automóvel"
            />
            <Select
              id="dvdDevices"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e.target.value, "dvdDevices")}
              onBlur={handleOnBlur}
              value={dvdDevices ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
              disabled={isDisabled.dvdDevices}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.refrigerators}
              text="Quantidade de geladeiras"
            />
            <Select
              id="refrigerators"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "refrigerators")
              }
              onBlur={handleOnBlur}
              value={refrigerators ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
              disabled={isDisabled.refrigerators}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.freezers}
              text="Quantidade de freezers independentes ou parte da geladeira duplex"
            />
            <Select
              id="freezers"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e.target.value, "freezers")}
              onBlur={handleOnBlur}
              value={freezers ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
              disabled={isDisabled.freezers}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.microcomputers}
              text="Quantidade de microcomputadores, considerando computadores de mesa, laptops, notebooks e netbooks e desconsiderando tablets, palms ou smartphones"
            />
            <Select
              id="microcomputers"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "microcomputers")
              }
              onBlur={handleOnBlur}
              value={microcomputers ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
              disabled={isDisabled.microcomputers}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.dishwashers}
              text="Quantidade de lavadora de louças"
            />
            <Select
              id="dishwashers"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "dishwashers")
              }
              onBlur={handleOnBlur}
              value={dishwashers ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
              disabled={isDisabled.dishwashers}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.microwaves}
              text="Quantidade de fornos de micro-ondas"
            />
            <Select
              id="microwaves"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e.target.value, "microwaves")}
              onBlur={handleOnBlur}
              value={microwaves ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
              disabled={isDisabled.microwaves}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.professionalMotorcycles}
              text="Quantidade de motocicletas, desconsiderando as usadas exclusivamente para uso profissional"
            />
            <Select
              id="professionalMotorcycles"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "professionalMotorcycles")
              }
              onBlur={handleOnBlur}
              value={professionalMotorcycles ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
              disabled={isDisabled.professionalMotorcycles}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.clothesDryers}
              text="Quantidade de máquinas secadoras de roupas, considerando lava e seca"
            />
            <Select
              id="clothesDryers"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "clothesDryers")
              }
              onBlur={handleOnBlur}
              value={clothesDryers ?? ""}
              options={Object.keys(NumItems)?.map((key) => ({
                value: key,
                label: NumItems[key as keyof typeof NumItems],
              }))}
              disabled={isDisabled.clothesDryers}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.originOfWater}
              text="A água utilizada neste domicílio é proveniente de:"
            />
            <Select
              id="originOfWater"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "originOfWater")
              }
              onBlur={handleOnBlur}
              value={originOfWater ?? ""}
              options={Object.keys(OriginOfWater)?.map((key) => ({
                value: key,
                label: OriginOfWater[key as keyof typeof OriginOfWater],
              }))}
              disabled={isDisabled.originOfWater}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.typeOfStreet}
              text="Considerando o trecho da rua do seu domicílio, você diria que a rua é:"
            />
            <Select
              id="typeOfStreet"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "typeOfStreet")
              }
              onBlur={handleOnBlur}
              value={typeOfStreet ?? ""}
              options={Object.keys(TypeOfStreet)?.map((key) => ({
                value: key,
                label: TypeOfStreet[key as keyof typeof TypeOfStreet],
              }))}
              disabled={isDisabled.typeOfStreet}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.levelEducationBoss}
              text="Qual é o grau de instrução do chefe da família? Considere como chefe da família a pessoa que contribui com a maior parte da renda do domicílio."
            />
            <Select
              id="levelEducationBoss"
              label="Selecione"
              className="width-select-course"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "levelEducationBoss")
              }
              onBlur={handleOnBlur}
              value={levelEducationBoss ?? ""}
              options={Object.keys(LevelEducation)?.map((key) => ({
                value: key,
                label: LevelEducation[key as keyof typeof LevelEducation],
              }))}
              disabled={isDisabled.levelEducationBoss}
            />
          </>
        ),
      },
    ],
    [
      bathrooms,
      clothesDryers,
      dishwashers,
      dvdDevices,
      freezers,
      handleChangeSelect,
      handleOnBlur,
      isDisabled.bathrooms,
      isDisabled.clothesDryers,
      isDisabled.dishwashers,
      isDisabled.dvdDevices,
      isDisabled.freezers,
      isDisabled.levelEducationBoss,
      isDisabled.microcomputers,
      isDisabled.microwaves,
      isDisabled.monthlyEmployees,
      isDisabled.originOfWater,
      isDisabled.professionalMotorcycles,
      isDisabled.refrigerators,
      isDisabled.typeOfStreet,
      isDisabled.washingMachines,
      levelEducationBoss,
      microcomputers,
      microwaves,
      monthlyEmployees,
      originOfWater,
      privateVehicles,
      professionalMotorcycles,
      refrigerators,
      typeOfStreet,
      washingMachines,
    ],
  );

  const itemsRefs = useMemo(
    () => items.map(() => createRef<HTMLDivElement>()),
    [items],
  );

  const getSubscription = useCallback(async () => {
    const subscriptions = await getActiveUserClassVacancySubscription(user._id);
    if (subscriptions?.data?.length)
      dispatch(
        setCourseSubscription(subscriptions?.data?.[0]) as unknown as Action,
      );
  }, [user._id, dispatch]);

  const getClassVacancy = useCallback(async () => {
    const response = await getClassVacancyById(subscription?.classVacancyId);
    if (response?.data) setClassVacancy(response?.data);
  }, [subscription?.classVacancyId]);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  useEffect(() => {
    if (!classVacancy && !!subscription?._id) getClassVacancy();
  }, [classVacancy, subscription?._id, getClassVacancy]);

  useEffect(() => {
    if (isDisabled.levelEducationBoss || !levelEducationBoss) setIsValid(false);
    else handleOnBlur();
  }, [
    handleOnBlur,
    isDisabled.levelEducationBoss,
    levelEducationBoss,
    setIsValid,
  ]);

  return (
    <div className="grid-account-gap grid-account-gap-resposive">
      <div className="grid-full-row">
        <LabelInput
          marginBottom
          preWrap={false}
          optional
          text={`Para finalizar, aplicaremos a pesquisa do Critério Brasil para efeito de
        classificação econômica. Estas informações são sigilosas e serão
        utilizadas para analisarmos o perfil econômico das candidatas e alunas
        {reprograma}. Todos os itens de eletroeletrônicos citados devem estar
        funcionando, incluindo os que estão guardados. Caso não estejam
        funcionando, considere apenas se tiver intenção de consertar ou repor
        nos próximos seis meses.`}
        />
      </div>
      {items.map(
        ({ renderCondition, child }, index) =>
          (renderCondition ?? true) && (
            <div ref={itemsRefs[index]} className="grid-full-row">
              {child}
            </div>
          ),
      )}
    </div>
  );
};

export default memo(SocioEconomicAssessment);
