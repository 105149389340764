/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  filterCompany,
  filterUser,
  getCompanyFilters,
  getPages,
  getSuggestionsOfVacancies,
  getUserFilters,
  searchCompany,
  searchPlacementVacancy,
  searchUsers,
} from "../../services/functions";
import {
  Layout,
  HeaderProfile,
  Card,
  VacanciesProfile,
  CardSearch,
  ButtonFilter,
  Pagination,
  SearchResult,
  ModalConfigAndFilters,
} from "../../components";
import "./style.scss";
import { Company, User, PlacementVacancy } from "../../types/interfaces";
import { FinancialType, SizeCompany } from "../../types/constants/Company";
import { RootState } from "../../store/reducers";
import { placementVacancyManagedBy } from "../../types/enumerators";
import { setLoad } from "../../store/actions/configurationsActions";

interface ButtonFilterItem {
  text: string;
  onClick: () => void;
}

interface FilterItem {
  text: string;
  pathname?: string;
  typeSearch?: string;
}

interface FilterCompany {
  localization?: string[];
  size?: Array<"STARTUP" | "SMALL" | "MEDIUM" | "BIG">;
}

interface FilterUser {
  localization?: string[];
  work?: string[];
}

interface Filters {
  localization?: string[];
  work?: string[];
  financialType?: string[];
  size?: string[];
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Search = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const query = useQuery();
  const [usersSearch, setUsersSearch] = useState<User[] | null>([]);
  const [companySearch, setCompanySearch] = useState<Company[] | null>([]);
  const [vacancySearch, setVacancySearch] = useState<any[] | null>([]);
  const [filterCompanyList, setFilterCompanyList] = useState<FilterCompany>({});
  const [filterUserList, setFilterUserList] = useState<FilterUser>({});
  const [applyFilters, setApplyFilters] = useState<Filters>({});
  const [tempFilters, setTempFilters] = useState<Filters>({});
  const [isLocalization, setIsLocalization] = useState(false);
  const [isCompanyId, setIsCompanyId] = useState(false);
  const [isSize, setIsSize] = useState(false);
  const [isFinancialType, setIsFinancialType] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [vacancies, setVacancies] = useState<PlacementVacancy[] | []>();
  const [openModal, setOpenModal] = useState(false);
  const user = useSelector((state: RootState) => state.userState);
  const history = useHistory();
  const location: {
    state: {
      typeSearch: string;
      searchParams: string;
    };
  } = useLocation();
  const typeSearch = useMemo(
    () => location?.state?.typeSearch ?? "all",
    [location?.state?.typeSearch],
  );
  const searchParams = query.get("params") ?? location?.state?.searchParams;

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const dispatch = useDispatch();

  const filterItems = useMemo((): FilterItem[] => {
    const _vacancy = {
      text: "Vagas",
      pathname: "/vacancies-advanced",
    };

    const _people = {
      text: "Pessoas",
      typeSearch: "people",
    };

    const _company = {
      text: "Empresas",
      typeSearch: "company",
    };

    const _all = {
      text: "Ver todos",
      typeSearch: "all",
    };

    const items =
      typeSearch === "people"
        ? [_people, _vacancy, _company]
        : typeSearch === "company"
        ? [_company, _vacancy, _people]
        : [_vacancy, _people, _company];

    if (typeSearch !== "all") items.push(_all);

    return items;
  }, [typeSearch]);

  const filterItemsMapped = useCallback(
    (isButton: boolean) =>
      filterItems?.map(({ text, pathname, typeSearch: type }) => {
        const _onClick = () => {
          const _history = type
            ? {
                pathname: "/search",
                search: `params=${searchParams ?? ""}`,
                state: { typeSearch: type },
              }
            : {
                pathname,
                state: { searchParams },
              };
          if (type) history.replace({ state: { typeSearch: type } });
          history.push(_history);
          setApplyFilters({});
          setTempFilters({});
        };

        return isButton ? (
          <ButtonFilter text={text} onClick={_onClick} notIcon />
        ) : (
          {
            text,
            onClick: _onClick,
          }
        );
      }),
    [filterItems, history, searchParams],
  );

  const getVacancies = useCallback(async () => {
    const response = await getSuggestionsOfVacancies(user?._id);
    setVacancies(response ?? []);
  }, [user?._id]);

  useEffect(() => {
    if (!vacancies) getVacancies();
  }, [getVacancies, user._id, vacancies]);

  const loadFilterCompanyList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const filterList = await getCompanyFilters();
    let newFilterCompanyList: FilterCompany = {};

    if (filterList) {
      filterList[0]?.localization?.forEach((item: any) => {
        if (newFilterCompanyList?.localization?.length)
          newFilterCompanyList?.localization?.push(item);
        else {
          newFilterCompanyList = {
            ...newFilterCompanyList,
            localization: [item],
          };
        }
      });

      filterList[0]?.size?.forEach((item: any) => {
        if (newFilterCompanyList?.size?.length)
          newFilterCompanyList?.size?.push(item);
        else {
          newFilterCompanyList = {
            ...newFilterCompanyList,
            size: [item],
          };
        }
      });
    }

    setFilterCompanyList(newFilterCompanyList);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  const loadFilterUserList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const filterList = await getUserFilters(
      searchParams ?? "",
      applyFilters,
      "",
    );
    let newFilterUserList: FilterUser = {};

    if (filterList) {
      filterList[0]?.localization?.forEach((item: any) => {
        if (newFilterUserList?.localization?.length)
          newFilterUserList?.localization?.push(item);
        else {
          newFilterUserList = {
            ...newFilterUserList,
            localization: [item],
          };
        }
      });

      filterList[0]?.company?.forEach((item: any) => {
        if (newFilterUserList?.work?.length)
          newFilterUserList?.work?.push(item);
        else {
          newFilterUserList = {
            ...newFilterUserList,
            work: [item],
          };
        }
      });
    }

    setFilterUserList(newFilterUserList);
    dispatch(setLoad(false) as unknown as Action);
  }, [applyFilters, dispatch, searchParams]);

  const optionsAllFiltersCompany = useCallback(
    (type: "localization" | "size" | "financialType") => {
      let result: { value?: string; label?: string }[] = [];

      if (type === "localization")
        result =
          filterCompanyList?.localization?.map((item) => ({
            value: item ?? "",
            label: item ?? "",
          })) ?? [];

      if (type === "size")
        result =
          filterCompanyList?.size?.map((item) => ({
            value: item,
            label: item,
          })) ?? [];

      if (type === "financialType")
        Object.values(FinancialType)?.forEach((key) => {
          result?.push({
            value: key,
            label: key,
          });
        });

      return result;
    },
    [filterCompanyList],
  );

  const optionsAllFiltersPeople = useCallback(
    (type: "localization" | "work") => {
      const result: any[] = [];

      if (type === "work") {
        filterUserList?.work?.forEach((key) => {
          result?.push({
            value: key,
            label: key,
          });
        });
      }

      if (type === "localization") {
        filterUserList?.localization?.forEach((key) => {
          result?.push({
            value: key,
            label: key,
          });
        });
      }

      return result;
    },
    [filterUserList],
  );

  const loadAllList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    try {
      const users = await searchUsers(searchParams ?? "", 3);
      const companies = await searchCompany(searchParams ?? "", 3);
      const vacanciesList = await searchPlacementVacancy(searchParams ?? "", 3);
      setCompanySearch(companies);
      setUsersSearch(users);
      setVacancySearch(vacanciesList);
    } catch (err) {
      toast.error("Ocorreu um erro ao carregar a pesquisa");
    } finally {
      dispatch(setLoad(false) as unknown as Action);
    }
  }, [dispatch, searchParams]);

  const showCard = useCallback(
    async (card: string) => {
      dispatch(setLoad(true) as unknown as Action);

      if (pageLimit) {
        if (card === "people") {
          setMaxPage(await getPages(searchUsers, searchParams ?? ""));
          const users = await searchUsers(
            searchParams ?? "",
            pageLimit,
            "",
            page,
          );
          setUsersSearch(users);
          loadFilterUserList();
        } else if (card === "company") {
          setMaxPage(await getPages(searchCompany, searchParams ?? ""));
          const companies = await searchCompany(
            searchParams ?? "",
            pageLimit,
            page,
          );
          loadFilterCompanyList();
          setCompanySearch(companies);
        } else await loadAllList();
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [
      dispatch,
      loadAllList,
      loadFilterCompanyList,
      loadFilterUserList,
      page,
      pageLimit,
      searchParams,
    ],
  );

  const onClear = useCallback(
    async (props: "localization" | "work" | "size" | "financialType") => {
      dispatch(setLoad(true) as unknown as Action);

      const newFilters = JSON.parse(JSON.stringify(tempFilters));
      delete newFilters[props];

      if (typeSearch === "people") {
        setMaxPage(
          await getPages(filterUser, searchParams ?? "", newFilters, ""),
        );
        const users = await filterUser(
          searchParams ?? "",
          newFilters,
          "",
          page,
        );
        setUsersSearch(users as User[]);
      }

      if (typeSearch === "company") {
        setMaxPage(
          await getPages(filterCompany, searchParams ?? "", newFilters),
        );
        const companies = await filterCompany(
          searchParams ?? "",
          newFilters,
          page,
        );
        setCompanySearch(companies);
      }

      setTempFilters(newFilters);
      setApplyFilters(newFilters);
      setIsLocalization(false);
      setIsCompanyId(false);
      setIsSize(false);
      setIsFinancialType(false);

      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, page, searchParams, tempFilters, typeSearch],
  );

  const onClearModalFilters = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    if (typeSearch === "people") {
      setMaxPage(await getPages(filterUser, searchParams ?? "", {}, ""));
      const users = await filterUser(searchParams ?? "", {}, "", page);
      setUsersSearch(users as User[]);
    }

    if (typeSearch === "company") {
      setMaxPage(await getPages(filterCompany, searchParams ?? "", {}));
      const companies = await filterCompany(searchParams ?? "", {}, page);
      setCompanySearch(companies);
    }

    setTempFilters({});
    setApplyFilters({});
    setIsLocalization(false);
    setIsCompanyId(false);
    setIsSize(false);
    setIsFinancialType(false);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, page, searchParams, typeSearch]);

  const handleCloseFilter = useCallback(
    (filterName: "localization" | "work" | "size" | "financialType") => {
      const filters = {
        localization: setIsLocalization,
        work: setIsCompanyId,
        size: setIsSize,
        financialType: setIsFinancialType,
      };
      Object.keys(filters).forEach((key) =>
        filters[key as keyof typeof filters](false),
      );
      filters[filterName](true);
    },
    [],
  );

  const handleChangeFilters = useCallback(
    (
      value: string,
      checked: boolean,
      props: "localization" | "work" | "size" | "financialType",
    ) => {
      let newFilters = JSON.parse(JSON.stringify(tempFilters));

      if (newFilters[props] && newFilters[props]?.length) {
        if (checked) newFilters[props]?.push(value);
        else {
          const index = newFilters[props]?.findIndex(
            (item: string) => item === value,
          );
          newFilters[props]?.splice(index ?? 0, 1);
          if (newFilters[props]?.length === 0) delete newFilters[props];
        }
      } else if (checked) newFilters = { ...newFilters, [props]: [value] };

      setTempFilters(newFilters);
    },
    [tempFilters],
  );

  const toggleSwitch = useCallback(
    (key: "localization" | "work" | "size" | "financialType", value: any) => {
      setTempFilters((prev) => ({
        ...(prev || []),
        [key]: prev[key]?.includes(value)
          ? prev[key]?.filter((el) => el !== value)
          : [...(prev[key] || []), value],
      }));
    },
    [],
  );

  const optionsCompany = useMemo(
    () => [
      {
        id: "localization",
        label: "Localização",
        type: "checkbox",
        options: optionsAllFiltersCompany("localization"),
        value: tempFilters?.localization,
        handleChange: (value: any) => toggleSwitch("localization", value),
      },
      {
        id: "size",
        label: "Tamanho da empresa",
        type: "checkbox",
        options: optionsAllFiltersCompany("size"),
        value: tempFilters?.size,
        handleChange: (value: any) => toggleSwitch("size", value),
      },
      {
        id: "financialType",
        label: "Tipo da empresa",
        type: "checkbox",
        options: optionsAllFiltersCompany("financialType"),
        value: tempFilters?.financialType,
        handleChange: (value: any) => toggleSwitch("financialType", value),
      },
    ],
    [
      optionsAllFiltersCompany,
      tempFilters?.financialType,
      tempFilters?.localization,
      tempFilters?.size,
      toggleSwitch,
    ],
  );

  const optionsPeople = useMemo(
    () => [
      {
        id: "localization",
        label: "Localização",
        type: "checkbox",
        options: optionsAllFiltersPeople("localization"),
        value: tempFilters?.localization,
        handleChange: (value: any) => toggleSwitch("localization", value),
      },
      {
        id: "work",
        label: "Empresa atual",
        type: "checkbox",
        options: optionsAllFiltersPeople("work"),
        value: tempFilters?.work,
        handleChange: (value: any) => toggleSwitch("work", value),
      },
    ],
    [
      optionsAllFiltersPeople,
      tempFilters?.localization,
      tempFilters?.work,
      toggleSwitch,
    ],
  );

  const searchUsersWithFilter = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    if (pageLimit) {
      setMaxPage(
        await getPages(filterUser, searchParams ?? "", tempFilters, ""),
      );
      const response = await filterUser(
        searchParams ?? "",
        tempFilters,
        "",
        page,
      );
      setUsersSearch(response as User[]);

      setIsLocalization(false);
      setIsCompanyId(false);
      setApplyFilters(tempFilters);
      setIsLocalization(false);
      setIsCompanyId(false);
      setIsSize(false);
      setIsFinancialType(false);
    }
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, pageLimit, searchParams, tempFilters, page]);

  const searchCompaniesWithFilter = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const newFilters = JSON.parse(JSON.stringify(tempFilters));
    setApplyFilters(tempFilters);

    if (pageLimit) {
      setMaxPage(await getPages(filterCompany, searchParams ?? "", newFilters));
      const response = await filterCompany(
        searchParams ?? "",
        newFilters,
        page,
      );

      setCompanySearch(response);
      setIsLocalization(false);
      setIsSize(false);
      setIsFinancialType(false);
      dispatch(setLoad(false) as unknown as Action);
    }
  }, [dispatch, tempFilters, pageLimit, searchParams, page]);

  useEffect(() => {
    showCard(typeSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeSearch, page, pageLimit]);

  const SecondaryFilters = useCallback(
    ({ label, onSearch, filterList, filters }) => (
      <div className="grid-column-1-13 grid-row-1 max-height-search-filters">
        <div className="scroll-filter">
          <div className="flex">
            <ButtonFilter
              className="menu-search-primary cursor-pointer"
              text={label}
              items={filterItemsMapped(false) as ButtonFilterItem[]}
            />
            <div className="vertical-divider-search" />
            <div
              className={`filter-vacancy-place ${
                tempFilters?.localization &&
                !isLocalization &&
                "margin-right-20"
              }`}
            >
              <ButtonFilter
                className="menu-search-secondary"
                onClick={() => handleCloseFilter("localization")}
                onClose={() => {
                  setIsLocalization(false);
                  setTempFilters(applyFilters);
                }}
                onChange={handleChangeFilters}
                onSearch={onSearch}
                onClear={() => onClear("localization")}
                text="Localização"
                isOpen={isLocalization}
                values={filterList?.localization ?? []}
                checkedList={tempFilters?.localization ?? []}
                prop="localization"
              />
            </div>
            {filters}
          </div>
        </div>
      </div>
    ),
    [
      applyFilters,
      filterItemsMapped,
      handleChangeFilters,
      handleCloseFilter,
      isLocalization,
      onClear,
      tempFilters?.localization,
    ],
  );

  const SearchResults = useCallback(
    ({ item, index, historyPath, search, cardSearch }) => (
      <div
        role="button"
        tabIndex={0}
        onClick={() => {
          history.push(`/${historyPath}/${item._id}`);
        }}
        className="pointer"
        key={Math.random()}
      >
        {cardSearch}
        {index < (search?.length ?? 0) - 1 && <div className="divider-card" />}
      </div>
    ),
    [history],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-responsive">
      <HeaderProfile />
      {typeSearch === "all" && (
        <div className="grid-column-1-13 grid-row-1 max-height-search-filters">
          <div className="display-inline margin-mobile-filter gap-12">
            {filterItemsMapped(true)}
          </div>
        </div>
      )}
      {typeSearch === "people" && (
        <>
          <SecondaryFilters
            label="Pessoas"
            onSearch={searchUsersWithFilter}
            filterList={filterUserList}
            filters={[
              <div
                className={`filter-people ${
                  tempFilters?.work &&
                  !isCompanyId &&
                  "margin-right-20 width-menu-search"
                }`}
              >
                <ButtonFilter
                  currentCompany
                  className="menu-search-secondary"
                  onClick={() => handleCloseFilter("work")}
                  onClose={() => {
                    setIsCompanyId(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={handleChangeFilters}
                  onSearch={searchUsersWithFilter}
                  onClear={() => onClear("work")}
                  text="Empresa atual"
                  isOpen={isCompanyId}
                  values={filterUserList?.work ?? []}
                  checkedList={tempFilters?.work ?? []}
                  prop="work"
                />
              </div>,
              <div
                className={`filter-all  ${
                  !isCompanyId && "margin-right-20 width-menu-search"
                }`}
              >
                <ButtonFilter
                  notIcon
                  text="Todos os filtros"
                  onClick={() => setOpenModal(true)}
                  className="menu-search-secondary"
                />
              </div>,
            ]}
          />
          <ModalConfigAndFilters
            openModal={openModal}
            onClose={() => setOpenModal(false)}
            textHeaderModal="Todos os Filtros"
            filters={optionsPeople}
            onClear={onClearModalFilters}
            onSearch={() => {
              searchUsersWithFilter();
              setOpenModal(false);
            }}
          />
        </>
      )}
      {typeSearch === "company" && (
        <>
          <SecondaryFilters
            label="Empresas"
            onSearch={searchCompaniesWithFilter}
            filterList={filterCompanyList}
            filters={[
              <div
                className={`filter-company-size ${
                  tempFilters?.size &&
                  !isSize &&
                  " margin-right-20 width-filter"
                }`}
              >
                <ButtonFilter
                  typeCompany
                  className="menu-search-secondary"
                  onClick={() => handleCloseFilter("size")}
                  onClose={() => {
                    setIsSize(false);
                    setTempFilters(applyFilters);
                  }}
                  onChange={(value: string, checked: boolean) =>
                    handleChangeFilters(
                      Object.keys(SizeCompany).find((key) => {
                        if (
                          SizeCompany[key as keyof typeof SizeCompany] === value
                        )
                          return key;
                        return "";
                      }) ?? "",
                      checked,
                      "size",
                    )
                  }
                  onSearch={searchCompaniesWithFilter}
                  onClear={() => onClear("size")}
                  text="Tamanho da empresa"
                  isOpen={isSize}
                  values={Object.keys(SizeCompany).map(
                    (key) => SizeCompany[key as keyof typeof SizeCompany],
                  )}
                  checkedList={
                    tempFilters?.size?.map(
                      (item) => SizeCompany[item as keyof typeof SizeCompany],
                    ) ?? []
                  }
                  prop="size"
                />
              </div>,
              <div
                className={`filter-company-type ${
                  tempFilters?.financialType &&
                  !isFinancialType &&
                  "margin-right-20 width-search"
                }`}
              >
                <ButtonFilter
                  widthCompany
                  className="menu-search-secondary"
                  onClick={() => handleCloseFilter("financialType")}
                  onClose={() => {
                    setIsFinancialType(false);
                    setTempFilters({} as Filters);
                  }}
                  onChange={(value: string, checked: boolean) =>
                    handleChangeFilters(
                      Object.keys(FinancialType).find((key) => {
                        if (
                          FinancialType[key as keyof typeof FinancialType] ===
                          value
                        )
                          return key;
                        return "";
                      }) ?? "",
                      checked,
                      "financialType",
                    )
                  }
                  onSearch={searchCompaniesWithFilter}
                  onClear={() => onClear("financialType")}
                  text="Tipo da empresa"
                  isOpen={isFinancialType}
                  values={Object.keys(FinancialType).map(
                    (key) => FinancialType[key as keyof typeof FinancialType],
                  )}
                  checkedList={
                    tempFilters?.financialType?.map(
                      (item) =>
                        FinancialType[item as keyof typeof FinancialType],
                    ) ?? []
                  }
                  prop="financialType"
                />
              </div>,
              <div
                className={`filter-company-modal-filters ${
                  tempFilters?.size &&
                  !isSize &&
                  " margin-right-20 width-filter"
                }`}
              >
                <ButtonFilter
                  notIcon
                  text="Todos os filtros"
                  onClick={() => setOpenModal(true)}
                  className="top-filters-more button-all-filters"
                />
              </div>,
            ]}
          />
          <ModalConfigAndFilters
            openModal={openModal}
            onClose={() => setOpenModal(false)}
            textHeaderModal="Todos os Filtros"
            filters={optionsCompany}
            onClear={onClearModalFilters}
            onSearch={() => {
              searchCompaniesWithFilter();
              setOpenModal(false);
            }}
          />
        </>
      )}
      {/* === RESULTADOS === */}
      <div className="grid-column-1-10 grid-row-2">
        {/* RESULTADOS VAGAS */}
        {typeSearch === "all" && (
          <Card
            bodyClassName="card-search"
            titleHeader="Vagas"
            removeTitleBorder
            hideBorderRadius
          >
            {vacancySearch &&
              vacancySearch?.map((vacancy, index) => (
                <div key={Math.random()}>
                  <div
                    aria-label="search"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      history.push({
                        pathname: "/vacancies-advanced",
                        state: { vacancy },
                      });
                    }}
                    className="pointer"
                  >
                    <CardSearch
                      jobTitle={vacancy?.title ?? ""}
                      companyName={
                        vacancy?.managedBy ===
                        placementVacancyManagedBy.EXTERNAL_VACANCY
                          ? vacancy?.externalCompanyName
                          : vacancy?.companyName ?? ""
                      }
                      jobType={vacancy?.type ?? ""}
                      jobPlace={vacancy?.place ?? ""}
                      jobStatus={vacancy?.status}
                      jobManagedBy={vacancy?.managedBy}
                      jobTypeOfCandidature={vacancy?.typeOfCandidature}
                      jobDate={vacancy?.startDate ?? ""}
                      photo={vacancy?.photo ?? ""}
                      id={vacancy?._id ?? ""}
                    />
                  </div>
                  {index < (vacancySearch?.length ?? 0) - 1 && (
                    <div className="divider-card" />
                  )}
                </div>
              ))}

            {vacancySearch?.length === 0 && (
              <p className="title-unavaliable">
                Não há nenhuma vaga que corresponde a busca
              </p>
            )}

            {vacancySearch && vacancySearch?.length > 2 && (
              <>
                <div className="line-divider-midia margin-top-10" />
                <div className="more-about-company">
                  <button
                    type="button"
                    className="more-about-button search-more"
                    onClick={() =>
                      history.push({
                        pathname: "/vacancies-advanced",
                        state: { searchParams },
                      })
                    }
                  >
                    Ver todos os resultados
                  </button>
                </div>
              </>
            )}
          </Card>
        )}

        {/* RESULTADOS PESSOAS */}
        <SearchResult
          renderCardSearch={
            usersSearch &&
            usersSearch?.map((person, index) => (
              <SearchResults
                key={Math.random()}
                historyPath="profile"
                item={person}
                index={index}
                search={usersSearch}
                cardSearch={
                  <CardSearch
                    key={Math.random()}
                    id={person?._id ?? ""}
                    person
                    personName={person?.profile?.name}
                    personPosition={
                      person?.curriculum?.works &&
                      !person?.curriculum?.works?.[
                        (person?.curriculum?.works?.length ?? 0) - 1
                      ]?.endDate
                        ? person?.curriculum?.works?.[
                            (person?.curriculum?.works?.length ?? 0) - 1
                          ]?.position
                        : ""
                    }
                    personCity={`${person?.profile?.address?.city ?? ""}${
                      person?.profile?.address?.city &&
                      person?.profile?.address?.province
                        ? " - "
                        : ""
                    }${person.profile?.address?.province ?? ""}`}
                    personDescription={person?.profile?.bio ?? ""}
                    personPhoto={person?.profile?.photo}
                  />
                }
              />
            ))
          }
          search={usersSearch}
          typeSearch="people"
        />

        {/* RESULTADOS EMPRESAS */}
        <SearchResult
          renderCardSearch={
            companySearch &&
            companySearch?.map((company, index) => (
              <SearchResults
                key={Math.random()}
                historyPath="company-profile"
                item={company}
                index={index}
                search={companySearch}
                cardSearch={
                  <CardSearch
                    company
                    companyName={company?.profile?.name}
                    companyWebsite={company?.profile?.site}
                    companyDescription={company?.profile?.description}
                    photo={company?.profile?.logo}
                    id={company?._id ?? ""}
                  />
                }
              />
            ))
          }
          search={companySearch}
          typeSearch="company"
        />

        {typeSearch !== "all" &&
          ((companySearch && companySearch?.length > 0) ||
            (usersSearch && usersSearch?.length > 0)) && (
            <div className="div-search margin-up">
              {width > 990 && <div className="divider" />}
              <div className="flex-row-center margin-bottom-6">
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  showAllOption
                />
              </div>
            </div>
          )}
      </div>
      <VacanciesProfile vacancies={vacancies ?? []} row="2" />
    </Layout>
  );
};

export default memo(Search);
