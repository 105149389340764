/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-console */
import React, {
  createRef,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Tooltip } from "@material-ui/core";
import moment from "moment";
import {
  Button,
  Checkbox,
  Input,
  LabelInput,
  Modal,
  Select,
} from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import {
  isValidBRlegalIdentity,
  isValidMail,
  maskBRlegalIdentity,
  maskDate,
  maskPhone,
} from "../../../utils";
import { IdentityType } from "../../../types/constants/User";
import { RootState } from "../../../store/reducers";
import { updateUser } from "../../../store/actions/userActions";
import { UserProfile } from "../../../types/interfaces";
import { Info } from "../../../../assets/icons";
import {
  Linkedin,
  InstagramProfile,
  FacebookProfile,
  TwitterProfile,
} from "../../../../assets/customIcons";
import { sociais } from "../../../types/constants/Sociais";
import { getUserByLegalIdentity } from "../../../services/functions";
import { userIdentityType } from "../../../types/enumerators";

interface Item {
  renderCondition?: boolean;
  child: ReactNode;
}

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const Infos = ({ setIsValid }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const [openModalAge, setOpenModalAge] = useState(false);
  const [openModalDiffBirthdate, setOpenModalDiffBirthdate] = useState(false);
  const [openModalIsInvalidMail, setOpenModalIsInvalidMail] = useState(false);
  const [openModalLegalIdentity, setOpenModalLegalIdentity] = useState(false);
  const [openModalHasUserLegalIdentity, setOpenModalHasUserLegalIdentity] =
    useState(false);
  const [hasOtherUserLegalIdentity, setHasOtherUserLegalIdentity] =
    useState(false);
  const [isntAge, setIsntAge] = useState(false);
  const [diffBirthdate, setDiffBirthdate] = useState(false);
  const [isInvalidMail, setIsInvalidMail] = useState(false);
  const [hasOtherDocument, setHasOtherDocument] = useState(false);

  const user = useSelector((state: RootState) => state.userState);
  const [_profile, _setProfile] = useState<UserProfile>(user?.profile);

  const {
    name,
    rg,
    identityType,
    legalIdentity,
    age,
    birthdate,
    email,
    telephone,
    isWhatsapp,
    socialMedias,
  } = useMemo(() => _profile, [_profile]);

  const isDisabled = useMemo(
    () => ({
      rg: !name,
      hasOtherDocument: !name || !rg,
      cpf: !name || !rg || hasOtherDocument,
      identityType: !name || !rg,
      otherDocument:
        !name ||
        !rg ||
        (hasOtherDocument &&
          (!identityType || identityType === userIdentityType.BR_CPF)),
      age:
        !name ||
        !rg ||
        hasOtherUserLegalIdentity ||
        (hasOtherDocument &&
          (!identityType || identityType === userIdentityType.BR_CPF)) ||
        (hasOtherDocument && !legalIdentity) ||
        (!hasOtherDocument && !isValidBRlegalIdentity(legalIdentity)),
      birthdate:
        !name ||
        !rg ||
        hasOtherUserLegalIdentity ||
        (hasOtherDocument &&
          (!identityType || identityType === userIdentityType.BR_CPF)) ||
        (hasOtherDocument && !legalIdentity) ||
        (!hasOtherDocument && !isValidBRlegalIdentity(legalIdentity)) ||
        isntAge ||
        !age,
      email:
        !name ||
        !rg ||
        hasOtherUserLegalIdentity ||
        (hasOtherDocument &&
          (!identityType || identityType === userIdentityType.BR_CPF)) ||
        (hasOtherDocument && !legalIdentity) ||
        (!hasOtherDocument && !isValidBRlegalIdentity(legalIdentity)) ||
        !age ||
        isntAge ||
        diffBirthdate ||
        (birthdate && birthdate?.length !== 10) ||
        !moment(birthdate, "DD/MM/YYYY").isValid(),
      telephone:
        !name ||
        !rg ||
        hasOtherUserLegalIdentity ||
        (hasOtherDocument &&
          (!identityType || identityType === userIdentityType.BR_CPF)) ||
        (hasOtherDocument && !legalIdentity) ||
        (!hasOtherDocument && !isValidBRlegalIdentity(legalIdentity)) ||
        !age ||
        isntAge ||
        diffBirthdate ||
        !moment(birthdate, "DD/MM/YYYY").isValid() ||
        isInvalidMail ||
        !email,
    }),
    [
      age,
      birthdate,
      diffBirthdate,
      email,
      hasOtherDocument,
      hasOtherUserLegalIdentity,
      identityType,
      isInvalidMail,
      isntAge,
      legalIdentity,
      name,
      rg,
    ],
  );

  const handleChange = useCallback(
    (value: string | number | boolean, prop: string) =>
      _setProfile({ ..._profile, [prop]: value }),
    [_profile],
  );

  const handleChangeSelect = useCallback(
    (value: unknown, prop: string) =>
      _setProfile({ ..._profile, [prop]: value as string }),
    [_profile],
  );

  const handleChangeDate = useCallback(
    (value) =>
      _setProfile({
        ..._profile,
        birthdate: maskDate(value),
      }),
    [_profile],
  );

  const handleOnBlur = useCallback(() => {
    if (!isDisabled.telephone) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              profile: { ..._profile },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [_profile, dispatch, isDisabled.telephone, setIsValid, user]);

  const handleOnFocus = useCallback(
    (
      value: string,
      type:
        | "LINKEDIN"
        | "INSTAGRAM"
        | "FACEBOOK"
        | "TIKTOK"
        | "TWITTER"
        | "GITHUB",
    ) => {
      if (!sociais[type].baseRegex.test(value)) {
        let { socialMedias: newSocialMedias } = _profile;

        if (newSocialMedias && newSocialMedias?.length) {
          const index = newSocialMedias?.findIndex(
            (item: { type: string }) => item.type === type,
          );
          if (index !== -1) newSocialMedias[index].url = sociais[type].baseURL;
          else newSocialMedias?.push({ type, url: sociais[type].baseURL });
        } else newSocialMedias = [{ type, url: sociais[type].baseURL }];

        _setProfile({ ..._profile, socialMedias: newSocialMedias });
      }
    },
    [_profile],
  );

  const handleChangeSociais = useCallback(
    (
      val: string,
      type:
        | "LINKEDIN"
        | "INSTAGRAM"
        | "FACEBOOK"
        | "TIKTOK"
        | "TWITTER"
        | "GITHUB",
    ) => {
      let value = val;
      if (value === "" || sociais[type].baseRegex.test(value)) {
        let { socialMedias: newSocialMedias } = _profile;

        const regex = new RegExp(sociais[type].baseURL, "g");
        const count = (value.match(regex) ?? []).length;
        if (count > 1) value = value.replace(sociais[type].baseURL, "");

        if (newSocialMedias && newSocialMedias?.length) {
          const index = newSocialMedias?.findIndex(
            (item: { type: string }) => item.type === type,
          );
          if (index !== -1) newSocialMedias[index].url = value;
          else newSocialMedias?.push({ type, url: value });
        } else newSocialMedias = [{ type, url: value }];

        _setProfile({ ..._profile, socialMedias: newSocialMedias });
      }
    },
    [_profile],
  );

  const handleOnBlurSociais = useCallback(
    (
      val: string,
      type:
        | "LINKEDIN"
        | "INSTAGRAM"
        | "FACEBOOK"
        | "TIKTOK"
        | "TWITTER"
        | "GITHUB",
    ) => {
      const value = val.replace(sociais[type].baseURL, "");
      if (value === "") {
        const { socialMedias: newSocialMedias } = _profile;
        const index = newSocialMedias?.findIndex(
          (item: { type: string }) => item.type === type,
        );
        if (index) newSocialMedias?.splice(index, 1);
        _setProfile({ ..._profile, socialMedias: newSocialMedias });
      }
      if (!isDisabled.telephone) {
        setIsValid(true);
        dispatch(
          setNextButton(() =>
            dispatch(
              updateUser({
                ...user,
                profile: { ..._profile },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      } else setIsValid(false);
    },
    [_profile, dispatch, isDisabled.telephone, setIsValid, user],
  );

  const handleOnBlurLegalIdentity = useCallback(async () => {
    if (
      hasOtherDocument ||
      (!hasOtherDocument && isValidBRlegalIdentity(legalIdentity))
    ) {
      const response = await getUserByLegalIdentity(legalIdentity);
      if (response?.length) {
        const otherUser = response?.find((item) => item?._id !== user?._id);
        if (otherUser) {
          setHasOtherUserLegalIdentity(true);
          setOpenModalHasUserLegalIdentity(true);
        } else setHasOtherUserLegalIdentity(false);
      } else setHasOtherUserLegalIdentity(false);
    } else setOpenModalLegalIdentity(true);
  }, [legalIdentity, hasOtherDocument, user?._id]);

  const handleOnBlurAge = useCallback(() => {
    if (Number(age) < 18) {
      setOpenModalAge(true);
      setIsntAge(true);
    } else {
      handleOnBlur();
      setIsntAge(false);
    }
  }, [age, handleOnBlur]);

  const handleOnBlurBirthdate = useCallback(() => {
    if (moment().diff(moment(birthdate, "DD/MM/YYYY"), "years") < 18) {
      setOpenModalAge(true);
      setDiffBirthdate(true);
    } else if (
      moment().diff(moment(birthdate, "DD/MM/YYYY"), "years") !== Number(age)
    ) {
      setOpenModalDiffBirthdate(true);
      setDiffBirthdate(true);
    } else {
      handleOnBlur();
      setIsntAge(false);
      setDiffBirthdate(false);
    }
  }, [age, birthdate, handleOnBlur]);

  const handleOnBlurEmail = useCallback(() => {
    if (isValidMail(email)) {
      handleOnBlur();
      setIsInvalidMail(false);
    } else {
      setOpenModalIsInvalidMail(true);
      setIsInvalidMail(true);
    }
  }, [email, handleOnBlur]);

  const items = useMemo(
    (): Item[] => [
      {
        child: (
          <>
            <LabelInput marginBottom text="Qual o seu nome completo?" />
            <Input
              id="name"
              label="Nome"
              helperText="Mulheres trans e travestis, mesmo sem ter retificado o nome em seus documentos pessoais, informam aqui seu nome social"
              type="text"
              value={name ?? ""}
              onChange={(e) => handleChange(e.target.value, "name")}
              onBlur={handleOnBlur}
              className="name-helper-text-input"
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              text="Qual o seu RG?"
              disabled={isDisabled.rg}
            />
            <Input
              id="rg"
              disabled={isDisabled.rg}
              type="text"
              label="RG"
              onChange={(e) => handleChange(e.target.value, "rg")}
              value={rg ?? ""}
              onBlur={handleOnBlur}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.cpf}
              text="Qual é número do seu CPF?"
            />
            <div>
              <Input
                id="cpf"
                type="text"
                label="CPF"
                onChange={(e) => handleChange(e.target.value, "legalIdentity")}
                maxLength={11}
                value={maskBRlegalIdentity(
                  hasOtherDocument ? "" : legalIdentity ?? "",
                )}
                onBlur={handleOnBlurLegalIdentity}
                disabled={isDisabled.cpf}
              />
              <Checkbox
                className="padding-top-8"
                id="hasOtherDocument"
                label={<div>Informar outro documento</div>}
                checked={hasOtherDocument}
                onChange={(e) => {
                  if (!e.target.checked)
                    handleChange(userIdentityType.BR_CPF, "identityType");
                  handleChange("", "legalIdentity");
                  setHasOtherDocument(!hasOtherDocument);
                  setHasOtherUserLegalIdentity(false);
                }}
                disabled={isDisabled.hasOtherDocument}
              />
            </div>
          </>
        ),
      },
      {
        renderCondition: hasOtherDocument,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.identityType}
              text="Escolha o tipo de documento."
            />
            <Select
              id="identityType"
              label="Tipo de documento"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "identityType")
              }
              onBlur={handleOnBlur}
              value={identityType ?? ""}
              options={Object.keys(IdentityType)
                .map((key) => ({
                  value: key,
                  label: IdentityType[key as keyof typeof IdentityType],
                }))
                .filter((item) => item.value !== userIdentityType.BR_CPF)}
              disabled={isDisabled.identityType}
            />
          </>
        ),
      },
      {
        renderCondition: hasOtherDocument,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.otherDocument}
              text="Qual é o número do documento?"
            />
            <Input
              id="legalIdentity"
              label="N°"
              type="text"
              value={legalIdentity ?? ""}
              onChange={(e) => handleChange(e.target.value, "legalIdentity")}
              onBlur={handleOnBlur}
              disabled={isDisabled.otherDocument}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.age}
              text="Quantos anos você tem?"
            />
            <Input
              id="age"
              disabled={isDisabled.age}
              type="number"
              helperText="Escreva somente números"
              onChange={({ target: { value } }) => {
                if (Number(value) > -1) handleChange(value, "age");
              }}
              onBlur={handleOnBlurAge}
              maxLength={2}
              value={age}
              label="Idade"
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.birthdate}
              text="Qual a data do seu nascimento?"
            />
            <Input
              id="birthdate"
              disabled={isDisabled.birthdate}
              type="text"
              onChange={(e) => handleChangeDate(e.target.value)}
              maxLength={10}
              onBlur={handleOnBlurBirthdate}
              value={(birthdate as string) ?? ""}
              label="Data de nascimento"
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.email}
              text="Qual o seu melhor email para contato?"
            />
            <Input
              id="email"
              value={email ?? ""}
              onChange={(e) =>
                handleChange(e.target.value.toLowerCase().trim(), "email")
              }
              onBlur={handleOnBlurEmail}
              type="text"
              label="E-mail"
              helperText="Escreva com letra minúscula"
              disabled={isDisabled.email}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.telephone}
              optional
              text="Caso tenha, qual o número do seu celular com DDD?"
            />
            <div>
              <Input
                id="telephone"
                value={maskPhone(telephone ?? "")}
                maxLength={14}
                onChange={(e) => handleChange(e.target.value, "telephone")}
                onBlur={handleOnBlur}
                type="text"
                label="Celular"
                disabled={isDisabled.telephone}
              />
              <Checkbox
                className="padding-top-8"
                id="telephone"
                label="Este número também é do WhatsApp"
                checked={isWhatsapp}
                onChange={() => handleChange(!isWhatsapp, "isWhatsapp")}
                disabled={isDisabled.telephone}
              />
            </div>
          </>
        ),
      },
    ],
    [
      age,
      birthdate,
      email,
      handleChange,
      handleChangeDate,
      handleChangeSelect,
      handleOnBlur,
      handleOnBlurAge,
      handleOnBlurBirthdate,
      handleOnBlurEmail,
      handleOnBlurLegalIdentity,
      hasOtherDocument,
      identityType,
      isDisabled.age,
      isDisabled.birthdate,
      isDisabled.cpf,
      isDisabled.email,
      isDisabled.hasOtherDocument,
      isDisabled.identityType,
      isDisabled.otherDocument,
      isDisabled.rg,
      isDisabled.telephone,
      isWhatsapp,
      legalIdentity,
      name,
      rg,
      telephone,
    ],
  );

  const itemsRefs = useMemo(
    () => items.map(() => createRef<HTMLDivElement>()),
    [items],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (
      new Map(Object.entries(_profile)).size < 1 &&
      new Map(Object.entries(user?.profile)).size > 1
    )
      _setProfile({ ...user?.profile });
  }, [_profile, user?.profile]);

  useEffect(() => {
    if (isDisabled.telephone) setIsValid(false);
    else handleOnBlur();
  }, [handleOnBlur, isDisabled.telephone, setIsValid]);

  useEffect(() => {
    if (!!identityType && identityType !== userIdentityType.BR_CPF)
      setHasOtherDocument(true);
  }, [identityType]);

  useEffect(() => {
    if (moment(user?.profile?.birthdate, moment.ISO_8601).isValid())
      _setProfile({
        ...user?.profile,
        birthdate: moment(user?.profile?.birthdate).format("DD/MM/YYYY"),
      });
  }, [user?.profile, setIsValid]);

  useEffect(() => {
    if (age === undefined && birthdate)
      _setProfile({
        ..._profile,
        age: moment().diff(moment(birthdate, "DD/MM/YYYY"), "years"),
      });
  }, [_profile, age, birthdate]);

  return (
    <>
      <div className="grid-account-gap grid-account-gap-resposive">
        {items.map(
          ({ renderCondition, child }, index) =>
            (renderCondition ?? true) && (
              <div ref={itemsRefs[index]} className="grid-full-row">
                {child}
              </div>
            ),
        )}
        <div className="grid-full-row flex">
          <LabelInput
            marginBottom
            disabled={isDisabled.telephone}
            optional
            text="Caso tenha, compartilhe o link das suas redes sociais."
          />
          <Tooltip
            title={
              <p>
                Lembre-se de testar se o link está sendo direcionado para o seu
                perfil.
              </p>
            }
          >
            <div>
              <Info
                className={`cursor-pointer ${
                  isDisabled.telephone ? "disabled" : ""
                }`}
              />
            </div>
          </Tooltip>
        </div>
        <div className="grid-column-1-3 contents display-block">
          <div className="flex-row-center grid-column-1-2 row-mobile margin-bottom-16-mobile">
            <Linkedin
              widthIcon={width < 991 ? 40 : 55}
              heightIcon={width < 991 ? 40 : 55}
              className={isDisabled.telephone ? "disabled icone-media" : ""}
            />
            <Input
              label="Linkedin"
              type="text"
              value={
                socialMedias?.find(
                  (item: { type: string }) => item.type === "LINKEDIN",
                )?.url ?? ""
              }
              onChange={(e) => handleChangeSociais(e.target.value, "LINKEDIN")}
              onBlur={(e) => handleOnBlurSociais(e.target.value, "LINKEDIN")}
              onFocus={(e) => handleOnFocus(e.target.value, "LINKEDIN")}
              className="margin-left-12 width-100"
              disabled={isDisabled.telephone}
            />
          </div>
          <div className="flex-row-center grid-column-2-3 row-mobile margin-bottom-16-mobile">
            <InstagramProfile
              widthIcon={width < 991 ? 40 : 55}
              heightIcon={width < 991 ? 40 : 55}
              className={isDisabled.telephone ? "disabled icone-media" : ""}
            />
            <Input
              label="Instagram"
              type="text"
              className="margin-left-12 width-100"
              value={
                socialMedias?.find(
                  (item: { type: string }) => item.type === "INSTAGRAM",
                )?.url ?? ""
              }
              onChange={(e) => handleChangeSociais(e.target.value, "INSTAGRAM")}
              onBlur={(e) => handleOnBlurSociais(e.target.value, "INSTAGRAM")}
              onFocus={(e) => handleOnFocus(e.target.value, "INSTAGRAM")}
              disabled={isDisabled.telephone}
            />
          </div>
          <div className="flex-row-center grid-column-1-2 row-mobile margin-bottom-16-mobile">
            <FacebookProfile
              widthIcon={width < 991 ? 40 : 55}
              heightIcon={width < 991 ? 40 : 55}
              className={isDisabled.telephone ? "disabled icone-media" : ""}
            />
            <Input
              label="Facebook"
              type="text"
              value={
                socialMedias?.find(
                  (item: { type: string }) => item.type === "FACEBOOK",
                )?.url ?? ""
              }
              onChange={(e) => handleChangeSociais(e.target.value, "FACEBOOK")}
              onBlur={(e) => handleOnBlurSociais(e.target.value, "FACEBOOK")}
              onFocus={(e) => handleOnFocus(e.target.value, "FACEBOOK")}
              className="margin-left-12 width-100"
              disabled={isDisabled.telephone}
            />
          </div>
          <div className="flex-row-center grid-column-2-3 row-mobile">
            <TwitterProfile
              widthIcon={width < 991 ? 40 : 55}
              heightIcon={width < 991 ? 40 : 55}
              className={isDisabled.telephone ? "disabled icone-media" : ""}
            />
            <Input
              label="Twitter"
              type="text"
              value={
                socialMedias?.find(
                  (item: { type: string }) => item.type === "TWITTER",
                )?.url ?? ""
              }
              onChange={(e) => handleChangeSociais(e.target.value, "TWITTER")}
              onBlur={(e) => handleOnBlurSociais(e.target.value, "TWITTER")}
              onFocus={(e) => handleOnFocus(e.target.value, "TWITTER")}
              className="margin-left-12 width-100"
              disabled={isDisabled.telephone}
            />
          </div>
        </div>
      </div>
      <Modal
        headerAction
        open={openModalAge}
        onClose={() => setOpenModalAge(false)}
        onClickBack={() => setOpenModalAge(false)}
      >
        <div className="modal-age btn-modal-course-years">
          <h3 className="title-modal-age">
            Que pena! Infelizmente você não poderá participar deste curso. É
            necessário ter 18 anos ou mais.
          </h3>
          <p className="text-modal-age">
            Mas fique atenta ao nosso site e não perca as novidades!
          </p>
          <Button
            onClick={() =>
              window.open("https://www.reprograma.com.br/#courses", "_self")
            }
            text="VER PÁGINA DE CURSOS"
            className=""
          />
        </div>
      </Modal>
      <Modal
        headerAction
        open={openModalDiffBirthdate}
        onClose={() => setOpenModalDiffBirthdate(false)}
        onClickBack={() => setOpenModalDiffBirthdate(false)}
      >
        <div className="modal-age btn-modal-course-years">
          <h3 className="title-modal-age">Ops! Encontramos um erro.</h3>
          <p className="text-modal-age">
            Sua data de nascimento é diferente da sua idade. Para prosseguir,
            por favor confira se inseriu corretamente!
          </p>
        </div>
      </Modal>
      <Modal
        headerAction
        open={openModalLegalIdentity}
        onClose={() => setOpenModalLegalIdentity(false)}
        onClickBack={() => setOpenModalLegalIdentity(false)}
      >
        <div className="modal-age btn-modal-course-years">
          <h3 className="title-modal-age">Ops! Este CPF é inválido.</h3>
          <p className="text-modal-age">
            Para prosseguir, por favor confira se inseriu corretamente!
          </p>
        </div>
      </Modal>
      <Modal
        headerAction
        open={openModalHasUserLegalIdentity}
        onClose={() => setOpenModalHasUserLegalIdentity(false)}
        onClickBack={() => setOpenModalHasUserLegalIdentity(false)}
      >
        <div className="modal-age btn-modal-course-years">
          <h3 className="title-modal-age">
            Ops! Parece que você já está cadastrada.
          </h3>
          <p className="text-modal-age">
            Encontramos outro cadastro com seu CPF. Por favor, faça login com o
            seu e-mail já cadastrado.
          </p>
        </div>
      </Modal>
      <Modal
        headerAction
        open={openModalIsInvalidMail}
        onClose={() => setOpenModalIsInvalidMail(false)}
        onClickBack={() => setOpenModalIsInvalidMail(false)}
      >
        <div className="modal-age btn-modal-course-years">
          <h3 className="title-modal-age">Ops! Este e-mail é inválido.</h3>
          <p className="text-modal-age">
            Para prosseguir, por favor confira se inseriu corretamente!
          </p>
        </div>
      </Modal>
    </>
  );
};

export default memo(Infos);
