/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import "./styles.scss";
import {
  TextField,
  InputAdornment,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Search, Down } from "../../../assets/icons";
import { getFAQ } from "../../services/functions";
import { Faq } from "../../types/interfaces";
import { userRoles } from "../../types/enumerators";
import Close from "../../../assets/customIcons/Close";
import { isCorporate as isCorporateUtils } from "../../utils";
import { setLoad } from "../../store/actions/configurationsActions";
import { RootState } from "../../store/reducers";

interface Props {
  onClose?: () => void;
}

const MenuHeader = ({ onClose }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [expanded, setExpanded] = useState<number>(-1);
  const [searchTopic, setSearchTopic] = useState<string>("");
  const [listQuestion, setListQuestion] = useState<Faq[]>([]);
  const user = useSelector((state: RootState) => state.userState);
  const dispatch = useDispatch();
  const isCorporate = isCorporateUtils(user);

  const loadFAQ = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const list = await getFAQ(
      isCorporate ? userRoles.CORPORATE : userRoles.STUDENT,
    );
    if (list?.length) setListQuestion(list);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, isCorporate]);

  useEffect(() => {
    if (!listQuestion.length) loadFAQ();
  }, [listQuestion.length, loadFAQ]);

  const normalize = useCallback((str) => {
    let newStr = str
      .toLowerCase()
      .trim()
      .replace(/\\s{2,}/g, " ");

    const converterLib = {
      "-": "_",
      a: "á|à|ã|â|ä|À|Á|Ã|Â|Ä",
      e: "é|è|ê|ë|É|È|Ê|Ë",
      i: "í|ì|î|ï|Í|Ì|Î|Ï",
      o: "ó|ò|ô|õ|ö|Ó|Ò|Ô|Õ|Ö",
      u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
      c: "ç|Ç",
      n: "ñ|Ñ",
    };

    for (const r in converterLib) {
      newStr = newStr.replace(
        new RegExp(converterLib[r.toString()], "g"),
        r.toString(),
      );
    }
    return newStr;
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="menu-header">
      <div className="menu-header-title">
        <span>Ajuda</span>
        <Close
          widthIcon={width < 991 ? 24 : 32}
          heightIcon={width < 991 ? 24 : 32}
          onClick={onClose}
        />
      </div>

      <TextField
        autoComplete="off"
        id="recentSearch"
        placeholder="Buscar tópico"
        type="text"
        InputProps={{
          className:
            "search-header-profile Mui-input headerSearch header-input-search",
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        className="search-header menu-text-field"
        onChange={(event) => {
          setSearchTopic(event.target.value);
        }}
      />
      <div className="menu-header-card">
        {listQuestion.length > 0 &&
          listQuestion
            .filter((item) => {
              if (searchTopic === "") return item;
              if (
                `${normalize(item.question)} ${normalize(
                  item.response,
                )}`.includes(normalize(searchTopic))
              )
                return item;
              return null;
            })
            .map((item, index, listQuestionFiltered) => (
              <div key={Math.random()} className="help-muiAccordion-root">
                <Accordion
                  expanded={index === expanded}
                  onChange={() => setExpanded(index === expanded ? -1 : index)}
                >
                  <AccordionSummary className="help-card" expandIcon={<Down />}>
                    <div>
                      <p className="menu-header-question">{item.question}</p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="menu-header-description">{item.response}</p>
                  </AccordionDetails>
                </Accordion>
                {index < listQuestionFiltered.length - 1 && (
                  <div className="menu-header-divider" />
                )}
              </div>
            ))}
      </div>
    </div>
  );
};
export default memo(MenuHeader);
