/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { NEXT_BUTTON } from "../types/nextButtonTypes";

export const setNextButton = (nextButton: any) => (dispatch: any) => {
  dispatch({
    type: NEXT_BUTTON,
    payload: nextButton,
  });
};
