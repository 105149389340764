import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import { history, store } from "./store";
import { AuthProvider } from "./auth";
import Routes from "./routes/index";
import { Load } from "./pages";
import { PopUp } from "./components";

injectStyle();

const App = (): JSX.Element => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Load />
      <PopUp />
      <AuthProvider>
        <Routes />
      </AuthProvider>
      <ToastContainer autoClose={3000} />
    </ConnectedRouter>
  </Provider>
);

export default App;
