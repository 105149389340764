/* eslint-disable no-await-in-loop */
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { Button, Modal, Select } from "../../../components";
import { Class, ClassVacancyStages } from "../../../types/interfaces";
import { setLoad } from "../../../store/actions/configurationsActions";
import {
  getClassVacancySubscriptionById,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import { classVacancyType } from "../../../types/enumerators";
import { List } from "..";
import "../style.scss";

interface Props {
  open: boolean;
  onclose: () => void;
  list: Class[];
  subscriptions?: List[];
  stages: ClassVacancyStages[];
  reload: () => void;
  setError: Dispatch<
    SetStateAction<
      | {
          funct: () => void;
          user: {
            name: string;
            email: string;
          }[];
        }
      | undefined
    >
  >;
  setSuccess: Dispatch<SetStateAction<boolean>>;
  setOpenModalStatusAction: Dispatch<SetStateAction<boolean>>;
}

const AddWorkshop = ({
  open,
  onclose,
  list,
  subscriptions,
  stages,
  reload,
  setError,
  setOpenModalStatusAction,
  setSuccess,
}: Props): JSX.Element => {
  const [selected, setSelected] = useState<string>("");
  const dispatch = useDispatch();

  const onSave = useCallback(
    async (errorsList?: List[]) => {
      dispatch(setLoad(true) as unknown as Action);
      const errors: List[] = [];
      const data = errorsList ?? subscriptions ?? [];

      for (let i = 0; i < data?.length; i += 1) {
        const response = await getClassVacancySubscriptionById(
          data[i]?._id ?? "",
        );
        const stageWorkshop = stages?.find(
          (item) => item?.type === classVacancyType.WORKSHOP,
        );
        const stageIndex = response?.stages?.findIndex(
          (item: { stageId: string }) =>
            item?.stageId === stageWorkshop?.stage_id,
        );

        if (stageIndex !== -1)
          response.stages[stageIndex].workshopClass = selected;

        const result = await updateClassVacancySubscriptionById(
          response?._id ?? "",
          { stages: response?.stages },
        );

        if (!result)
          errors.push({
            ...response,
            name: data[i]?.name,
            email: data[i]?.email,
          });
      }

      if (errors?.length) {
        setError({
          funct: () => {
            onSave(errors);
            setOpenModalStatusAction(false);
          },
          user: errors?.map((item) => ({
            name: item?.name,
            email: item?.email,
          })),
        });

        if (errors?.length < data?.length) setSuccess(true);
        else setSuccess(false);

        setOpenModalStatusAction(true);
      } else toast.success("Ação executada com sucesso");

      setSelected("");
      reload();
      onclose();
      dispatch(setLoad(false) as unknown as Action);
    },
    [
      dispatch,
      subscriptions,
      reload,
      onclose,
      stages,
      selected,
      setError,
      setSuccess,
      setOpenModalStatusAction,
    ],
  );
  return (
    <Modal
      headerAction
      open={open}
      onClose={onclose}
      textHeader="Atribuir oficina"
    >
      <Select
        id="workshopClass"
        label="Oficina"
        value={selected ?? ""}
        onChange={(e) => setSelected(e.target.value)}
        options={list?.map((item) => ({
          value: item?._id ?? "",
          label: item?.code ?? "",
        }))}
      />
      <div className="admin-subscriptions-modal-buttons margin-top-32">
        <Button
          text="Cancelar"
          onClick={onclose}
          outline
          appearance="secondary"
        />
        <Button onClick={() => onSave()} text="Salvar" />
      </div>
    </Modal>
  );
};
export default memo(AddWorkshop);
