/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Add } from "../../../../assets/customIcons";
import {
  Button,
  Card,
  IconButton,
  ModalMedia,
  AddCertificate,
  ViewCertificate,
} from "../../../components";
import { UserCurriculumCertificate, Media } from "../../../types/interfaces";
import { updateUser } from "../../../store/actions/userActions";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import {
  addCompanyToUser,
  getCompany,
  searchCompaniesAllStatus,
  searchCompaniesAutocomplete,
  setNewCompany,
} from "../../../services/functions";
import { companyType, companyStatus } from "../../../types/enumerators";
import { RootState } from "../../../store/reducers";

interface Props {
  title: string;
  addCertificate: boolean;
  setAddCertificate: Dispatch<SetStateAction<boolean>>;
  setIsOptional: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const Certificate = ({
  title,
  addCertificate,
  setAddCertificate,
  setIsOptional,
  setIsValid,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [editIndex, setEditIndex] = useState(-1);
  const [editMediaIndex, setEditMediaIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [media, setMedia] = useState<Media>({} as Media);
  const [notExpire, setNotExpire] = useState(false);
  const [certificate, setCertificate] = useState<UserCurriculumCertificate>({
    title: "",
    companyId: "",
    description: "",
    medias: [],
  });
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [_curriculum, _setCurriculum] = useState(user?.curriculum);
  const [emissor, setEmissor] = useState("");
  const [emissorOptions, setEmissorOptions] =
    useState<{ name: string; id: string }[]>();

  useEffect(() => {
    if (_curriculum?.certificates?.length) {
      setIsOptional(false);
      setIsValid(true);
    } else setIsOptional(true);
  }, [_curriculum?.certificates, setIsOptional, setIsValid]);

  const loadEmissor = useCallback(async () => {
    try {
      const companyOptionsResponse = await searchCompaniesAutocomplete(
        companyType.INSTITUTION,
      );

      const companyOptionsMapped = companyOptionsResponse?.map((option) => ({
        name: option?.profile?.name ?? "",
        id: option?._id ?? "",
      }));

      setEmissorOptions(companyOptionsMapped ?? []);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  useEffect(() => {
    if (!emissorOptions) loadEmissor();
  }, [emissorOptions, loadEmissor]);

  const getCompanyName = useCallback(async () => {
    try {
      const company = await getCompany(certificate?.companyId ?? "");
      if (company) setEmissor(company?.profile?.name ?? "");
    } catch (err) {
      console.warn(err);
    }
  }, [certificate?.companyId]);

  useEffect(() => {
    if (certificate?.companyId) getCompanyName();
  }, [certificate?.companyId, getCompanyName]);

  useEffect(() => {
    if (certificate?.medias?.length) {
      certificate?.medias.sort((a: Media, b: Media) => {
        if ("order" in a && "order" in b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        }
        return 0;
      });
    }
  }, [certificate.medias]);

  const setCompany = useCallback(
    async (value: string | null) => {
      if (value && value !== "") {
        const company = await searchCompaniesAllStatus(value);

        if (!company?.length) {
          await setNewCompany(
            value,
            companyType.INSTITUTION,
            companyStatus.PENDING,
            companyStatus.NONE,
          );

          setCompany(value);
        } else {
          setCertificate({
            ...certificate,
            companyId: company[0]._id,
          });
        }
      }
    },
    [certificate],
  );

  const onSave = useCallback(async () => {
    let { certificates: newCertificates } = _curriculum;

    if (editIndex !== -1) {
      newCertificates[editIndex] = certificate;
    } else if (newCertificates?.length) newCertificates.push(certificate);
    else newCertificates = [certificate];

    setCertificate({
      title: "",
      companyId: "",
      description: "",
      medias: [],
    });
    setEmissor("");
    setNotExpire(false);

    const newUser = {
      ...user,
      curriculum: { ...user?.curriculum, certificates: newCertificates },
    };

    const newUserWithCompany = await addCompanyToUser(newUser);

    _setCurriculum({ ...newUserWithCompany.curriculum });
    dispatch(updateUser({ ...newUser }) as unknown as Action);
    setAddCertificate(false);
    setEditIndex(-1);
  }, [_curriculum, editIndex, certificate, user, dispatch, setAddCertificate]);

  const onRemove = useCallback(
    (index: number) => {
      let { certificates: oldCertificates } = _curriculum;
      oldCertificates.splice(index, 1);
      oldCertificates = oldCertificates.map((item: Media, i: number) => ({
        ...item,
        order: i,
      }));
      _setCurriculum({ ..._curriculum, certificates: oldCertificates });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, certificates: oldCertificates },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_curriculum, dispatch, user],
  );

  const onEdit = useCallback(
    (item: UserCurriculumCertificate, index: number) => {
      if (!item.expirationDate) setNotExpire(true);
      setEditIndex(index);
      setAddCertificate(true);
      setCertificate({
        ...item,
        medias: item.medias,
      });
    },
    [setAddCertificate],
  );

  const onCancel = useCallback(() => {
    setAddCertificate(false);
    setEditIndex(-1);
    setCertificate({
      title: "",
      companyId: "",
      description: "",
      medias: [],
    });
    setEmissor("");
    setNotExpire(false);
    setIsValid(true);
  }, [setAddCertificate, setIsValid]);

  const reorderItems = useCallback(
    (items: any) => {
      let { certificates: newCertificates } = _curriculum;
      newCertificates = items.map((item: any, index: number) => ({
        ...item,
        order: index,
      }));
      _setCurriculum({ ..._curriculum, certificates: newCertificates });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, certificates: newCertificates },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_curriculum, dispatch, user],
  );

  const handleChangeMedia = useCallback(
    (prop: string | number | symbol) =>
      (event: ChangeEvent<HTMLInputElement>) =>
        setMedia({ ...media, [prop]: event.target.value }),
    [media],
  );

  const saveMedia = useCallback(() => {
    let { medias: newMedias } = certificate;

    if (editMediaIndex !== -1) {
      newMedias[editMediaIndex] = media;
    } else if (newMedias?.length) newMedias.push(media);
    else newMedias = [media];

    setCertificate({ ...certificate, medias: newMedias });
    setMedia({} as Media);
    setEditMediaIndex(-1);
    setOpenModal(false);
  }, [certificate, editMediaIndex, media]);

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setEditMediaIndex(-1);
    setMedia({} as Media);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`height-fit grid-column-5-12 grid-column-1-11-mobile margin-top-16 grid-7-column ${
        width > 990 ? "grid-row-1" : "grid-row-2"
      }`}
    >
      <Card
        borderNone
        titleHeader={title}
        className={`grid-column-1-8 height-fit-content ${
          addCertificate || width < 991 ? "border-card" : ""
        }`}
      >
        <p className="paragraph-add">
          Para finalizar, adicione suas certificações para deixar seu currículo
          ainda mais completo.
        </p>
        {!addCertificate ? (
          <IconButton
            icon={<Add />}
            text="Adicionar certificado"
            onClick={() => setAddCertificate(true)}
            className="icon-button-primary icon-button-primary-mobile"
          />
        ) : (
          <AddCertificate
            certificate={certificate}
            setItem={setCertificate}
            setIsValid={setIsValid}
            setNotExpire={setNotExpire}
          />
        )}
      </Card>
      {addCertificate && (
        <div className="flex grid-column-4-8 grid-row-2 flex-direction-column-reverse">
          <Button
            text="Cancelar"
            className="width-50"
            appearance="secondary"
            outline
            onClick={onCancel}
          />
          <Button
            text="Salvar"
            className="margin-left-32 margin-left-mobile width-50"
            onClick={onSave}
            disabled={
              !certificate.title ||
              !certificate.companyId ||
              !certificate.title ||
              !certificate.date ||
              !certificate.description ||
              (!certificate.expirationDate && !notExpire)
            }
          />
        </div>
      )}
      {addCertificate && _curriculum?.certificates?.length > 0 && (
        <div className="grid-column-1-8 grid-row-3 divider" />
      )}
      {_curriculum?.certificates?.length > 0 && (
        <div
          className={`grid-column-1-8 ${
            addCertificate ? "grid-row-4" : "grid-row-2"
          }`}
        >
          {_curriculum?.certificates?.length > 1 && (
            <p className="paragraph-reorder margin-top-40">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <ViewCertificate
            hasCards
            isMyProfile
            items={_curriculum?.certificates}
            onEdit={onEdit}
            onMove={reorderItems}
            onRemove={onRemove}
            className="padding-24"
          />
        </div>
      )}
      <ModalMedia
        openModal={openModal}
        onClose={closeModal}
        onChange={handleChangeMedia}
        medias={media}
        onSave={saveMedia}
        disabled={!media.title || !media.description}
      />
    </div>
  );
};

export default memo(Certificate);
