/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  memo,
  useCallback,
  useMemo,
  SetStateAction,
  Dispatch,
  useState,
  ChangeEvent,
  useEffect,
  createRef,
  ReactNode,
} from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import { Action } from "redux";
import { useSelector, useDispatch } from "react-redux";
import {
  Input,
  LabelInput,
  Select,
  TextArea,
  InputAutocomplete,
} from "../../../components";
import { RootState } from "../../../store/reducers";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateEmployment } from "../../../store/actions/employmentAction";
import {
  CurrentSituation,
  UndertakenArea,
  FirstTechJob,
  PlatformCurrentTechJob,
  CurrentJoblevel,
  JobsSinceGraduation,
  DurationEmploymentGraduation,
  TimeEmployed,
  ReasonLeavingWork,
  NumberSelectionProcesses,
  CompanySegment,
  TimeSameOccupation,
} from "../../../types/constants/Employment";
import {
  researchCurrentSituation,
  researchCompanySegment,
  researchType,
  researchUndertakenArea,
  companyType,
  autoCompleteType,
} from "../../../types/enumerators";
import { maskReal, removeMaskReal, registerAutoComplete } from "../../../utils";
import {
  searchCompaniesAutocomplete,
  searchCompaniesAllStatus,
  setNewCompany,
  getAutocomplete,
} from "../../../services/functions";
import { setLoad } from "../../../store/actions/configurationsActions";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
  templateRows?: boolean;
}

interface Item {
  id: string;
  child: ReactNode;
  isFullRow?: boolean;
  renderCondition?: boolean;
  className?: string;
}

const Employment = ({
  setIsValid,
  templateRows = false,
}: Props): JSX.Element => {
  const expandedAttribute = "expanded";
  const heightProperty = "height";
  const fullRowClassName = "grid-full-row";
  const employment = useSelector((state: RootState) => state.employmentState);
  const [form, setForm] = useState(employment.form);
  const [workPositionOptions, setWorkPositionOptions] = useState<string[]>();
  const [companyOptions, setCompanyOptions] =
    useState<{ name: string; id: string }[]>();
  const [isDisabled, setIsDisabled] = useState<{
    [x: string]: boolean;
  }>({});
  const dispatch = useDispatch();

  const handleOnBlur = useCallback(
    () =>
      dispatch(
        setNextButton(() => {
          dispatch(
            updateEmployment({
              ...employment,
              form,
            }) as unknown as Action,
          );
        }) as unknown as Action,
      ),
    [dispatch, employment, form],
  );

  const handleChangeSelect = useCallback(
    (event: { target: { value: unknown } }, prop: string) =>
      setForm({ ...form, [prop]: event.target.value as string }),
    [form],
  );

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) => setForm({ ...form, [prop]: event.target.value }),
    [form],
  );

  const loadCompany = useCallback(async () => {
    try {
      const companyOptionsResponse = await searchCompaniesAutocomplete(
        companyType.WORK_COMPANY,
      );

      const companyOptionsMapped = companyOptionsResponse?.map((option) => ({
        name: option?.profile?.name ?? "",
        id: option?._id ?? "",
      }));
      setCompanyOptions(companyOptionsMapped ?? []);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  useEffect(() => {
    if (!companyOptions) loadCompany();
  }, [companyOptions, loadCompany]);

  const setCurrentCompanyName = useCallback(
    async (value: string | null, prop: string) => {
      if (value && value !== "") {
        dispatch(setLoad(true) as unknown as Action);
        const currentCompany = await searchCompaniesAllStatus(value);

        if (!currentCompany?.length) {
          await setNewCompany(value, companyType.WORK_COMPANY);

          setCurrentCompanyName(value, prop);
          dispatch(setLoad(false) as unknown as Action);
        } else {
          dispatch(setLoad(false) as unknown as Action);

          setForm({
            ...form,
            companyId: currentCompany[0]._id,
            [prop]: value,
          });
          dispatch(
            setNextButton(() => {
              dispatch(
                updateEmployment({
                  ...employment,
                  form: {
                    ...form,
                    companyId: currentCompany[0]._id,
                    [prop]: value,
                  },
                }) as unknown as Action,
              );
            }) as unknown as Action,
          );
        }
      }
    },
    [dispatch, employment, form],
  );

  const loadPositions = useCallback(async () => {
    const workPosition = await getAutocomplete(autoCompleteType.WORK_POSITION);

    const workPositionMapped = workPosition?.map((option) => option?.name);

    setWorkPositionOptions(workPositionMapped);
  }, []);

  useEffect(() => {
    if (!workPositionOptions) loadPositions();
  }, [loadPositions, workPositionOptions]);

  const handleChangeAutoComplete = useCallback(
    async (value: string | null, prop: string) => {
      registerAutoComplete(value, autoCompleteType.WORK_POSITION);
      setForm({ ...form, [prop]: value as string });
    },
    [form],
  );

  const handleChangeRadio = useCallback(
    (event: { target: { value: unknown } }, prop: string) => {
      setForm({ ...form, [prop]: event.target.value === prop });
      dispatch(
        setNextButton(() => {
          dispatch(
            updateEmployment({
              ...employment,
              form: { ...form, [prop]: event.target.value === prop },
            }) as unknown as Action,
          );
        }) as unknown as Action,
      );
    },
    [dispatch, employment, form],
  );

  const handleChangeRadioNumber = useCallback(
    (event: { target: { value: unknown } }, prop: string) => {
      setForm({ ...form, [prop]: event.target.value });
      dispatch(
        setNextButton(() => {
          dispatch(
            updateEmployment({
              ...employment,
              form: { ...form, [prop]: event.target.value },
            }) as unknown as Action,
          );
        }) as unknown as Action,
      );
    },
    [dispatch, employment, form],
  );

  const handleChangeRevenue = useCallback(
    (value: string, prop: string) => {
      setForm({
        ...form,
        [prop]: removeMaskReal(maskReal(value)),
      });
    },
    [form],
  );

  const items = useMemo(
    (): Item[] => [
      {
        id: "currentSituation",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual é a sua situação profissional HOJE, depois do curso da {reprograma}?"
                disabled={isDisabled.currentSituation}
              />
            )}
            <Select
              id="currentSituation"
              className="width-select-course"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e, "currentSituation")}
              onBlur={handleOnBlur}
              options={Object.keys(CurrentSituation)?.map((key) => ({
                value: key,
                label: CurrentSituation[key as keyof typeof CurrentSituation],
              }))}
              value={form?.currentSituation ?? ""}
              disabled={isDisabled.currentSituation}
            />
          </>
        ),
      },
      {
        id: "isJobTechnology",
        renderCondition:
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED,
        child: (
          <>
            {templateRows && (
              <LabelInput
                text="O seu trabalho é na área de tecnologia?"
                disabled={isDisabled.isJobTechnology}
              />
            )}
            <RadioGroup
              aria-label="isJobTechnology"
              name="isJobTechnology"
              onChange={(e) => handleChangeRadio(e, "isJobTechnology")}
              value={
                form?.isJobTechnology !== undefined
                  ? form?.isJobTechnology
                    ? "isJobTechnology"
                    : "isntJobTechnology"
                  : ""
              }
            >
              <div>
                <FormControlLabel
                  value="isJobTechnology"
                  control={<Radio />}
                  label="Sim"
                  disabled={isDisabled.isJobTechnology}
                />
                <FormControlLabel
                  value="isntJobTechnology"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.isJobTechnology}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        id: "undertakenArea",
        renderCondition:
          (employment.type === researchType.THREE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          (form?.currentSituation === researchCurrentSituation.ENTREPRENEUR ||
            form?.currentSituation === researchCurrentSituation.PJ ||
            form?.currentSituation === researchCurrentSituation.FREELANCER) &&
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                text="Após a conclusão do curso, se você empreendeu na área de formação, qual era a situação antes do empreendimento"
                disabled={isDisabled.undertakenArea}
              />
            )}
            <Select
              id="undertakenArea"
              label="Selecione"
              className="width-select-course"
              onChange={(e) => handleChangeSelect(e, "undertakenArea")}
              onBlur={handleOnBlur}
              options={Object.keys(UndertakenArea)?.map((key) => ({
                value: key,
                label: UndertakenArea[key as keyof typeof UndertakenArea],
              }))}
              value={form?.undertakenArea ?? ""}
              disabled={isDisabled.undertakenArea}
            />
          </>
        ),
      },
      {
        id: "jobBeforeUndertaking",
        renderCondition:
          (employment.type === researchType.THREE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          (form?.currentSituation === researchCurrentSituation.ENTREPRENEUR ||
            form?.currentSituation === researchCurrentSituation.PJ ||
            form?.currentSituation === researchCurrentSituation.FREELANCER) &&
          form?.undertakenArea !== researchUndertakenArea.UNEMPLOYED &&
          form?.undertakenArea !== researchUndertakenArea.STUDYING &&
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Se estava empregada antes de empreender no seu negócio atual, qual era a ocupação/cargo?"
                disabled={isDisabled.jobBeforeUndertaking}
              />
            )}
            <Input
              id="jobBeforeUndertaking"
              label="Ocupação/cargo"
              onChange={(e) => handleChange(e, "jobBeforeUndertaking")}
              onBlur={handleOnBlur}
              value={form?.jobBeforeUndertaking ?? ""}
              disabled={isDisabled.jobBeforeUndertaking}
            />
          </>
        ),
      },
      {
        id: "companyName",
        renderCondition:
          form?.currentSituation === researchCurrentSituation.CLT ||
          form?.currentSituation === researchCurrentSituation.PJ ||
          form?.currentSituation === researchCurrentSituation.INTERNSHIP,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Conta para gente o nome da empresa onde você trabalha atualmente."
                disabled={isDisabled.companyName}
              />
            )}

            <InputAutocomplete
              label="Nome da empresa"
              value={form?.companyName ?? ""}
              onBlur={(event) =>
                setCurrentCompanyName(event.target.value, "companyName")
              }
              options={companyOptions?.map((item) => item?.name) ?? [""]}
              getOptionLabel={(option) => option}
              disabled={isDisabled.companyName}
            />
          </>
        ),
      },
      {
        id: "currentPosition",
        renderCondition:
          form?.currentSituation === researchCurrentSituation.CLT ||
          form?.currentSituation === researchCurrentSituation.PJ ||
          form?.currentSituation === researchCurrentSituation.FREELANCER ||
          form?.currentSituation === researchCurrentSituation.INTERNSHIP,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Conta para gente o nome do seu cargo atual."
                disabled={isDisabled.currentPosition}
              />
            )}
            <InputAutocomplete
              label="Nome do cargo"
              onBlur={(event) =>
                handleChangeAutoComplete(event.target.value, "currentPosition")
              }
              options={workPositionOptions ?? [""]}
              getOptionLabel={(option) => option}
              value={form?.currentPosition ?? ""}
              disabled={isDisabled.currentPosition}
            />
          </>
        ),
      },
      {
        id: "firstTechJob",
        renderCondition:
          (form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
            form?.currentSituation !== researchCurrentSituation.UNEMPLOYED) ||
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Conta para gente após quanto tempo da conclusão do curso você conseguiu um emprego?"
                disabled={isDisabled.firstTechJob}
              />
            )}
            <Select
              id="firstTechJob"
              label="Selecione"
              className="width-select-course"
              onChange={(e) => handleChangeSelect(e, "firstTechJob")}
              onBlur={handleOnBlur}
              options={Object.keys(
                FirstTechJob[
                  employment.type === researchType.ONE_MONTHS
                    ? "RESPONSE_ONE_MONTHS"
                    : "RESPONSE_MORE_ONE_MONTHS"
                ],
              )?.map((key) => ({
                value: key,
                label:
                  FirstTechJob[
                    employment.type === researchType.ONE_MONTHS
                      ? "RESPONSE_ONE_MONTHS"
                      : "RESPONSE_MORE_ONE_MONTHS"
                  ][key],
              }))}
              value={form?.firstTechJob ?? ""}
              disabled={isDisabled.firstTechJob}
            />
          </>
        ),
      },
      {
        id: "platformCurrentTechJob",
        renderCondition:
          form?.currentSituation !== researchCurrentSituation.ENTREPRENEUR &&
          form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Através de qual plataforma você conseguiu seu emprego atual?"
                disabled={isDisabled.platformCurrentTechJob}
              />
            )}
            <Select
              id="platformCurrentTechJob"
              label="Selecione"
              className="width-select-course"
              onChange={(e) => handleChangeSelect(e, "platformCurrentTechJob")}
              onBlur={handleOnBlur}
              options={Object.keys(PlatformCurrentTechJob)?.map((key) => ({
                value: key,
                label:
                  PlatformCurrentTechJob[
                    key as keyof typeof PlatformCurrentTechJob
                  ],
              }))}
              value={form?.platformCurrentTechJob ?? ""}
              disabled={isDisabled.platformCurrentTechJob}
            />
          </>
        ),
      },
      {
        id: "currentJoblevel",
        renderCondition:
          (form?.currentSituation === researchCurrentSituation.CLT ||
            form?.currentSituation === researchCurrentSituation.PJ) &&
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual o nível de senioridade do seu cargo atual?"
                disabled={isDisabled.currentJoblevel}
              />
            )}
            <Select
              id="currentJoblevel"
              label="Selecione"
              className="width-select-course"
              onChange={(e) => handleChangeSelect(e, "currentJoblevel")}
              onBlur={handleOnBlur}
              options={Object.keys(CurrentJoblevel)?.map((key) => ({
                value: key,
                label: CurrentJoblevel[key as keyof typeof CurrentJoblevel],
              }))}
              value={form?.currentJoblevel ?? ""}
              disabled={isDisabled.currentJoblevel}
            />
          </>
        ),
      },
      {
        id: "numberSelectionProcesses",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="De quantos processos seletivos você está participando?"
                disabled={isDisabled.numberSelectionProcesses}
              />
            )}
            <Select
              className="width-select-course"
              id="numberSelectionProcesses"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e, "numberSelectionProcesses")
              }
              onBlur={handleOnBlur}
              options={Object.keys(NumberSelectionProcesses)?.map((key) => ({
                value: key,
                label:
                  NumberSelectionProcesses[
                    key as keyof typeof NumberSelectionProcesses
                  ],
              }))}
              value={form?.numberSelectionProcesses ?? ""}
              disabled={isDisabled.numberSelectionProcesses}
            />
          </>
        ),
      },
      {
        id: "relevanceTraining",
        renderCondition:
          (employment.type === researchType.THREE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                text="Você considera que a formação da {reprograma} foi relevante para o seu trabalho atual?"
                disabled={isDisabled.relevanceTraining}
              />
            )}
            <RadioGroup
              aria-label="relevanceTraining"
              name="relevanceTraining"
              onChange={(e) => handleChangeRadio(e, "relevanceTraining")}
              value={
                form?.relevanceTraining !== undefined
                  ? form?.relevanceTraining
                    ? "relevanceTraining"
                    : "isntRelevanceTraining"
                  : ""
              }
              className="margin-top-10"
            >
              <div>
                <FormControlLabel
                  value="relevanceTraining"
                  control={<Radio />}
                  label="Sim"
                  disabled={isDisabled.relevanceTraining}
                />
                <FormControlLabel
                  value="isntRelevanceTraining"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.relevanceTraining}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        id: "jobsSinceGraduation",
        renderCondition:
          employment.type === researchType.SIX_MONTHS ||
          employment.type === researchType.TWELVE_MONTHS,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Em quantos empregos você trabalhou desde a formatura?"
                disabled={isDisabled.jobsSinceGraduation}
              />
            )}
            <Select
              id="jobsSinceGraduation"
              label="Selecione"
              className="width-select-course"
              onChange={(e) => handleChangeSelect(e, "jobsSinceGraduation")}
              onBlur={handleOnBlur}
              options={Object.keys(JobsSinceGraduation)?.map((key) => ({
                value: key,
                label:
                  JobsSinceGraduation[key as keyof typeof JobsSinceGraduation],
              }))}
              value={form?.jobsSinceGraduation ?? ""}
              disabled={isDisabled.jobsSinceGraduation}
            />
          </>
        ),
      },
      {
        id: "durationEmploymentGraduation",
        renderCondition:
          employment.type === researchType.SIX_MONTHS ||
          employment.type === researchType.TWELVE_MONTHS,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual o maior tempo você ficou em um mesmo emprego após a formatura?"
                disabled={isDisabled.durationEmploymentGraduation}
              />
            )}
            <Select
              className="width-select-course"
              id="durationEmploymentGraduation"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e, "durationEmploymentGraduation")
              }
              onBlur={handleOnBlur}
              options={Object.keys(DurationEmploymentGraduation)?.map(
                (key) => ({
                  value: key,
                  label:
                    DurationEmploymentGraduation[
                      key as keyof typeof DurationEmploymentGraduation
                    ],
                }),
              )}
              value={form?.durationEmploymentGraduation ?? ""}
              disabled={isDisabled.durationEmploymentGraduation}
            />
          </>
        ),
      },
      {
        id: "timeSameOccupation",
        renderCondition:
          (employment.type === researchType.THREE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Há quanto tempo você está nesta mesma ocupação?"
                disabled={isDisabled.timeSameOccupation}
              />
            )}
            <Select
              id="timeSameOccupation"
              className="width-select-course"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e, "timeSameOccupation")}
              onBlur={handleOnBlur}
              value={form?.timeSameOccupation ?? ""}
              options={Object.keys(TimeSameOccupation)?.map((key) => ({
                value: key,
                label:
                  TimeSameOccupation[key as keyof typeof TimeSameOccupation],
              }))}
              disabled={isDisabled.timeSameOccupation}
            />
          </>
        ),
      },
      {
        id: "timeEmployed",
        renderCondition:
          (employment.type === researchType.THREE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Mesmo que tenha mudado de emprego, há quanto tempo você se mantém empregada?"
                disabled={isDisabled.timeEmployed}
              />
            )}
            <Select
              className="width-select-course"
              id="timeEmployed"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e, "timeEmployed")}
              onBlur={handleOnBlur}
              options={Object.keys(TimeEmployed)?.map((key) => ({
                value: key,
                label: TimeEmployed[key as keyof typeof TimeEmployed],
              }))}
              value={form?.timeEmployed ?? ""}
              disabled={isDisabled.timeEmployed}
            />
          </>
        ),
      },
      {
        id: "reasonLeavingWork",
        renderCondition: employment.type !== researchType.ONE_MONTHS,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Caso tenha conseguido um emprego ou estágio na área de tecnologia após concluir o
              curso realizado na {reprograma}, mas tenha saído, conta pra gente o principal motivo?"
                disabled={isDisabled.reasonLeavingWork}
              />
            )}
            <Select
              className="width-select-course"
              id="reasonLeavingWork"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e, "reasonLeavingWork")}
              onBlur={handleOnBlur}
              options={Object.keys(ReasonLeavingWork)?.map((key) => ({
                value: key,
                label: ReasonLeavingWork[key as keyof typeof ReasonLeavingWork],
              }))}
              value={form?.reasonLeavingWork ?? ""}
              disabled={isDisabled.reasonLeavingWork}
            />
          </>
        ),
      },
      {
        id: "experienceTechnologyEducator",
        renderCondition:
          employment.type === researchType.TWELVE_MONTHS ||
          employment.type === researchType.SIX_MONTHS,
        child: (
          <>
            {templateRows && (
              <LabelInput
                text="Nos últimos meses, você teve alguma experiência como educadora na área de tecnologia?"
                disabled={isDisabled.experienceTechnologyEducator}
              />
            )}
            <RadioGroup
              aria-label="experienceTechnologyEducator"
              name="experienceTechnologyEducator"
              onChange={(e) =>
                handleChangeRadio(e, "experienceTechnologyEducator")
              }
              value={
                form?.experienceTechnologyEducator !== undefined
                  ? form?.experienceTechnologyEducator
                    ? "experienceTechnologyEducator"
                    : "isntExperienceTechnologyEducator"
                  : ""
              }
            >
              <div>
                <FormControlLabel
                  value="experienceTechnologyEducator"
                  control={<Radio />}
                  label="Sim"
                  disabled={isDisabled.experienceTechnologyEducator}
                />
                <FormControlLabel
                  value="isntExperienceTechnologyEducator"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.experienceTechnologyEducator}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        id: "institutionTechEducation",
        renderCondition:
          (employment.type === researchType.TWELVE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          !!form?.experienceTechnologyEducator,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Em qual escola ou iniciativa tech você lecionou?"
                disabled={isDisabled.institutionTechEducation}
              />
            )}
            <Input
              id="institutionTechEducation"
              label="Escreva aqui"
              onChange={(e) => handleChange(e, "institutionTechEducation")}
              onBlur={handleOnBlur}
              value={form?.institutionTechEducation ?? ""}
              disabled={isDisabled.institutionTechEducation}
            />
          </>
        ),
      },
      {
        id: "hasCNPJ",
        renderCondition:
          employment.type !== researchType.SIX_MONTHS &&
          employment.type !== researchType.THREE_MONTHS &&
          (form?.currentSituation === researchCurrentSituation.PJ ||
            form?.currentSituation === researchCurrentSituation.FREELANCER),
        child: (
          <>
            {templateRows && (
              <LabelInput
                text="Trabalhando como MEI ou ME você já formalizou sua empresa abrindo o CNPJ dela?"
                disabled={isDisabled.hasCNPJ}
              />
            )}
            <RadioGroup
              aria-label="hasCNPJ"
              name="hasCNPJ"
              onChange={(e) => handleChangeRadio(e, "hasCNPJ")}
              value={
                form?.hasCNPJ !== undefined
                  ? form?.hasCNPJ
                    ? "hasCNPJ"
                    : "hasntCNPJ"
                  : ""
              }
            >
              <div>
                <FormControlLabel
                  value="hasCNPJ"
                  control={<Radio />}
                  label="Sim"
                  disabled={isDisabled.hasCNPJ}
                />
                <FormControlLabel
                  value="hasntCNPJ"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.hasCNPJ}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        id: "hoursWorkedWeekly",
        renderCondition:
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Quantas horas, em média, você trabalha semanalmente?"
                disabled={isDisabled.hoursWorkedWeekly}
              />
            )}
            <Input
              id="hoursWorkedWeekly"
              label="Digite aqui"
              helperText="Escreva somente números"
              type="number"
              onChange={(e) => handleChange(e, "hoursWorkedWeekly")}
              onBlur={handleOnBlur}
              value={form?.hoursWorkedWeekly ?? ""}
              disabled={isDisabled.hoursWorkedWeekly}
            />
          </>
        ),
      },
      {
        id: "jobQuality",
        renderCondition:
          (employment.type === researchType.THREE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Você considera que seu emprego te ajuda a ter boa qualidade de vida?"
                disabled={isDisabled.jobQuality}
              />
            )}
            <RadioGroup
              aria-label="jobQuality"
              name="jobQuality"
              onChange={(e) => handleChangeRadio(e, "jobQuality")}
              value={
                form?.jobQuality !== undefined
                  ? form?.jobQuality
                    ? "jobQuality"
                    : "notJobQuality"
                  : ""
              }
            >
              <div>
                <FormControlLabel
                  value="jobQuality"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.jobQuality}
                />
                <FormControlLabel
                  value="notJobQuality"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.jobQuality}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        id: "commentJobQuality",
        renderCondition:
          (employment.type === researchType.THREE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Comente, por favor, a resposta da pergunta anterior."
                disabled={isDisabled.commentJobQuality}
              />
            )}
            <TextArea
              id="commentJobQuality"
              label="Escreva aqui"
              maxLength={500}
              onChange={(e) => handleChange(e, "commentJobQuality")}
              onBlur={handleOnBlur}
              value={form?.commentJobQuality ?? ""}
              disabled={isDisabled.commentJobQuality}
            />
          </>
        ),
      },
      {
        id: "companySegment",
        renderCondition:
          (employment.type === researchType.THREE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual a atividade finalística da empresa onde trabalha atualmente?"
                disabled={isDisabled.companySegment}
              />
            )}
            <Select
              id="companySegment"
              className="width-select-course"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e, "companySegment")}
              onBlur={handleOnBlur}
              options={Object.keys(CompanySegment)?.map((key) => ({
                value: key,
                label: CompanySegment[key as keyof typeof CompanySegment],
              }))}
              value={form?.companySegment ?? ""}
              disabled={isDisabled.companySegment}
            />
          </>
        ),
      },
      {
        id: "commentCompanySegment",
        renderCondition:
          (employment.type === researchType.THREE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          form?.companySegment === researchCompanySegment.OTHER,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Qual?"
                disabled={isDisabled.commentCompanySegment}
              />
            )}
            <Input
              id="commentCompanySegment"
              label="Escreva aqui"
              onChange={(e) => handleChange(e, "commentCompanySegment")}
              onBlur={handleOnBlur}
              value={form?.commentCompanySegment ?? ""}
              disabled={isDisabled.commentCompanySegment}
            />
          </>
        ),
      },
      {
        id: "jobQualityLife",
        renderCondition:
          form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          employment.type === researchType.TWELVE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Em uma escala de 0 (Influencia Negativamente) a 10 (Melhora muito), como o seu emprego influencia na sua qualidade de vida?"
                disabled={isDisabled.jobQualityLife}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="jobQualityLife"
                name="jobQualityLife"
                defaultValue="top"
                onChange={(e) => handleChangeRadioNumber(e, "jobQualityLife")}
                value={form?.jobQualityLife ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.jobQualityLife}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.jobQualityLife}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.jobQualityLife}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.jobQualityLife}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.jobQualityLife}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.jobQualityLife}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.jobQualityLife}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.jobQualityLife}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.jobQualityLife}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.jobQualityLife}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.jobQualityLife}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "commentJobQualityLife",
        renderCondition:
          form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          employment.type === researchType.TWELVE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Por quê você escolheu determinado valor na resposta anterior?"
                disabled={isDisabled.commentJobQualityLife}
              />
            )}
            <TextArea
              id="commentJobQualityLife"
              label="Escreva aqui"
              maxLength={500}
              onChange={(e) => handleChange(e, "commentJobQualityLife")}
              onBlur={handleOnBlur}
              value={form?.commentJobQualityLife ?? ""}
              className="margin-top-20"
              disabled={isDisabled.commentJobQualityLife}
            />
          </>
        ),
      },
      {
        id: "grossIncome",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                disabled={isDisabled.grossIncome}
                text="Qual a sua renda mensal?"
              />
            )}
            <Input
              id="grossIncome"
              type="text"
              label="Renda mensal"
              onChange={(e) =>
                handleChangeRevenue(e.target.value, "grossIncome")
              }
              onBlur={handleOnBlur}
              value={
                form?.grossIncome?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }) ?? ""
              }
              disabled={isDisabled.grossIncome}
            />
          </>
        ),
      },
      {
        id: "sufficientRemuneration",
        renderCondition:
          (employment.type === researchType.THREE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
          !!form?.isJobTechnology,
        child: (
          <>
            {templateRows && (
              <LabelInput
                text="Você considera que a sua remuneração atual é suficiente para suprir suas necessidades básicas?"
                disabled={isDisabled.sufficientRemuneration}
              />
            )}
            <RadioGroup
              aria-label="sufficientRemuneration"
              name="sufficientRemuneration"
              onChange={(e) => handleChangeRadio(e, "sufficientRemuneration")}
              value={
                form?.sufficientRemuneration !== undefined
                  ? form?.sufficientRemuneration
                    ? "sufficientRemuneration"
                    : "insufficientRemuneration"
                  : ""
              }
            >
              <div>
                <FormControlLabel
                  value="sufficientRemuneration"
                  control={<Radio />}
                  label="Sim"
                  disabled={isDisabled.sufficientRemuneration}
                />
                <FormControlLabel
                  value="insufficientRemuneration"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.sufficientRemuneration}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        id: "commentSufficientRemuneration",
        renderCondition:
          (employment.type === researchType.THREE_MONTHS ||
            employment.type === researchType.SIX_MONTHS) &&
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          form?.currentSituation !== researchCurrentSituation.SCHOLARSHIP &&
          !!form?.isJobTechnology,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Comente, por favor, a resposta da pergunta anterior."
                disabled={isDisabled.commentSufficientRemuneration}
              />
            )}
            <TextArea
              id="commentSufficientRemuneration"
              label="Escreva aqui"
              maxLength={500}
              onChange={(e) => handleChange(e, "commentSufficientRemuneration")}
              onBlur={handleOnBlur}
              value={form?.commentSufficientRemuneration ?? ""}
              className="margin-top-20"
              disabled={isDisabled.commentSufficientRemuneration}
            />
          </>
        ),
      },
      {
        id: "trainingInstruments",
        renderCondition:
          form?.currentSituation !== researchCurrentSituation.UNEMPLOYED &&
          employment.type === researchType.ONE_MONTHS,
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Os conteúdos e instrumentos utilizados na formação são aplicáveis à sua prática de trabalho?"
                disabled={isDisabled.trainingInstruments}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="trainingInstruments"
                name="trainingInstruments"
                defaultValue="top"
                onChange={(e) =>
                  handleChangeRadioNumber(e, "trainingInstruments")
                }
                value={form?.trainingInstruments ?? ""}
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingInstruments}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.trainingInstruments}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingInstruments}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingInstruments}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingInstruments}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingInstruments}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingInstruments}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingInstruments}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingInstruments}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingInstruments}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.trainingInstruments}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
    ],
    [
      companyOptions,
      employment.type,
      form?.commentCompanySegment,
      form?.commentJobQuality,
      form?.commentJobQualityLife,
      form?.commentSufficientRemuneration,
      form?.companyName,
      form?.companySegment,
      form?.currentJoblevel,
      form?.currentPosition,
      form?.currentSituation,
      form?.durationEmploymentGraduation,
      form?.experienceTechnologyEducator,
      form?.firstTechJob,
      form?.grossIncome,
      form?.hasCNPJ,
      form?.hoursWorkedWeekly,
      form?.institutionTechEducation,
      form?.isJobTechnology,
      form?.jobBeforeUndertaking,
      form?.jobQuality,
      form?.jobQualityLife,
      form?.jobsSinceGraduation,
      form?.numberSelectionProcesses,
      form?.platformCurrentTechJob,
      form?.reasonLeavingWork,
      form?.relevanceTraining,
      form?.sufficientRemuneration,
      form?.timeEmployed,
      form?.timeSameOccupation,
      form?.trainingInstruments,
      form?.undertakenArea,
      handleChange,
      handleChangeAutoComplete,
      handleChangeRadio,
      handleChangeRadioNumber,
      handleChangeRevenue,
      handleChangeSelect,
      handleOnBlur,
      isDisabled.commentCompanySegment,
      isDisabled.commentJobQuality,
      isDisabled.commentJobQualityLife,
      isDisabled.commentSufficientRemuneration,
      isDisabled.companyName,
      isDisabled.companySegment,
      isDisabled.currentJoblevel,
      isDisabled.currentPosition,
      isDisabled.currentSituation,
      isDisabled.durationEmploymentGraduation,
      isDisabled.experienceTechnologyEducator,
      isDisabled.firstTechJob,
      isDisabled.grossIncome,
      isDisabled.hasCNPJ,
      isDisabled.hoursWorkedWeekly,
      isDisabled.institutionTechEducation,
      isDisabled.isJobTechnology,
      isDisabled.jobBeforeUndertaking,
      isDisabled.jobQuality,
      isDisabled.jobQualityLife,
      isDisabled.jobsSinceGraduation,
      isDisabled.numberSelectionProcesses,
      isDisabled.platformCurrentTechJob,
      isDisabled.reasonLeavingWork,
      isDisabled.relevanceTraining,
      isDisabled.sufficientRemuneration,
      isDisabled.timeEmployed,
      isDisabled.timeSameOccupation,
      isDisabled.trainingInstruments,
      isDisabled.undertakenArea,
      setCurrentCompanyName,
      templateRows,
      workPositionOptions,
    ],
  );

  const checkDisabled = useCallback(
    (index: number) =>
      !!items
        ?.filter(
          (item: { renderCondition?: boolean }) =>
            item?.renderCondition === undefined || !!item?.renderCondition,
        )
        ?.find(
          (item, i: number) =>
            !form ||
            (i < index &&
              !!form &&
              (form[item?.id] === undefined || form[item?.id] === "")),
        ),
    [form, items],
  );

  const getIsDisabled = useCallback(
    () =>
      setIsDisabled(
        items
          ?.filter(
            (item: { renderCondition?: boolean }) =>
              item?.renderCondition === undefined || !!item?.renderCondition,
          )
          ?.map((innerItem: { id: string }, index: number) => {
            if (index !== 0)
              return {
                [innerItem?.id]: checkDisabled(index),
              };
            return { [innerItem?.id]: false };
          })
          .concat({ next: checkDisabled(items?.length) })
          .reduce((acc, cur) => Object.assign(acc, cur)),
      ),
    [checkDisabled, items],
  );

  useEffect(() => {
    getIsDisabled();
  }, [getIsDisabled]);

  const itemsRefs = useMemo(
    () => items.map(() => createRef<HTMLDivElement>()),
    [items],
  );

  const updateLabelHeights = useCallback(() => {
    if (templateRows) {
      const getLabel = (ref: HTMLDivElement | null) => {
        if (ref) {
          const { children } = ref;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const [label] = children;
          return label;
        }
        return ref;
      };

      const getLabelHeight = (label: HTMLParagraphElement) => {
        const { offsetHeight } = label;
        return offsetHeight;
      };

      const removeLabelHeight = (label: HTMLParagraphElement) => {
        const { style } = label;
        style.removeProperty(heightProperty);
        label.removeAttribute(expandedAttribute);
        return label;
      };

      const setLabelHeight = (label: HTMLParagraphElement, height: number) => {
        const { style } = label;
        style.setProperty(heightProperty, `${height}px`);
        label.setAttribute(expandedAttribute, "");
      };

      itemsRefs
        .filter(
          ({ current }) =>
            current && !current.className.includes(fullRowClassName),
        )
        .forEach(
          (
            { current: rightItem }: any,
            index: number,
            arr: { current: any }[],
          ) => {
            if (index % 2 !== 0) {
              // Coluna esquerda
              const { current: leftItem } = arr[index - 1];
              const leftLabel = removeLabelHeight(getLabel(leftItem));
              const leftHeight = getLabelHeight(leftLabel);

              // Coluna direita
              const rightLabel = removeLabelHeight(getLabel(rightItem));
              const rightHeight = getLabelHeight(rightLabel);

              // Expansão de altura
              if (leftHeight > rightHeight)
                setLabelHeight(rightLabel, leftHeight);
              else setLabelHeight(leftLabel, rightHeight);

              // Redução de altura
              if (
                leftHeight < rightHeight &&
                rightLabel.hasAttribute(expandedAttribute)
              ) {
                removeLabelHeight(leftLabel);
                setLabelHeight(rightLabel, leftHeight);
              } else if (
                rightHeight < leftHeight &&
                leftLabel.hasAttribute(expandedAttribute)
              ) {
                removeLabelHeight(rightLabel);
                setLabelHeight(leftLabel, rightHeight);
              }
            }
          },
        );
    }
  }, [itemsRefs, templateRows]);

  useEffect(() => {
    const handleResize = () => {
      updateLabelHeights();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [updateLabelHeights]);

  useEffect(() => updateLabelHeights(), [updateLabelHeights]);

  useEffect(() => {
    if (!isDisabled.next) setIsValid(true);
    else setIsValid(false);
  }, [isDisabled, setIsValid]);

  return (
    <div className="grid-account-gap grid-account-gap-resposive">
      {items.map(
        ({ isFullRow, renderCondition, className, child }, index) =>
          (renderCondition ?? true) && (
            <div
              ref={itemsRefs[index]}
              className={
                isFullRow
                  ? fullRowClassName
                  : `content-grid-row ${
                      templateRows
                        ? `content-grid-2-row ${className ?? ""}`
                        : ""
                    }`
              }
            >
              {child}
            </div>
          ),
      )}
    </div>
  );
};
export default memo(Employment);
