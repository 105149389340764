import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const PhoneBlack = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#2E3451"
      stroke="#2E3451"
      strokeWidth=".1"
      d="M19 2.284H9c-1.133 0-2.05.976-2.05 2.17v19.092c0 1.194.917 2.17 2.05 2.17h10c1.133 0 2.05-.976 2.05-2.17V4.455c0-1.195-.917-2.171-2.05-2.171zM9.05 23.495V4.505h9.9l.002 18.99H9.05zM14 21.368c.583 0 1.05-.5 1.05-1.11 0-.61-.467-1.11-1.05-1.11s-1.05.5-1.05 1.11c0 .61.467 1.11 1.05 1.11z"
    />
  </svg>
);

export default PhoneBlack;
