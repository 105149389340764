/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { patchCompany } from "../../services/functions";
import {
  COMPANY_UPDATE,
  CLEAR_COMPANY,
  SET_COMPANY,
} from "../types/companyTypes";

export const setCompany = (company: any) => (dispatch: any) => {
  dispatch({
    type: SET_COMPANY,
    payload: company,
  });
};

export const clearCompany = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_COMPANY,
    payload: {},
  });
};

export const updateCompany = (company: any) => async (dispatch: any) => {
  dispatch({
    type: COMPANY_UPDATE,
    payload: company,
  });
  try {
    const updatedCompany = { ...company };
    const oldCompany = sessionStorage.getItem("@SESSION:company");

    const newCompany = {
      ...JSON.parse(oldCompany ?? "[]"),
      ...updatedCompany,
    };

    sessionStorage.setItem("@SESSION:company", JSON.stringify(newCompany));

    delete updatedCompany._id;
    if (oldCompany) {
      await patchCompany(JSON.parse(oldCompany)._id, updatedCompany);
    }
  } catch (err) {
    console.warn(err);
  }
};
