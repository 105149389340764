/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import React, { memo } from "react";
import { AvatarEmpty } from "../../../assets/customIcons";
import { ChevronRight } from "../../../assets/icons/index";
import { getTextDiffDate } from "../../utils";

interface Props {
  idSelected: string;
  item: any;
}

const CardExpand = ({ idSelected, item }: Props): JSX.Element => (
  <div role="button" tabIndex={0}>
    <div
      className={` flex padding-24 ${
        idSelected === item._id ? "selected-card" : ""
      }`}
    >
      {item.userPhoto ? (
        <img
          src={item.userPhoto}
          alt="imagem"
          width={48}
          height={48}
          className="img-attachment-photo-small border-radius-26"
        />
      ) : (
        <AvatarEmpty className="img-attachment-photo-small" />
      )}
      <div className="div-infos-card-expand">
        <p className="view-bold margin-bottom-4">{item.userName}</p>
        <p className="title-view margin-bottom-4">{item.vacancyName}</p>
        <p className="title-view-14 margin-bottom-8">{item.vacancyPlace}</p>
        <p className="date-candidature">
          Candidatou-se
          {moment(item.startDate).isValid() && getTextDiffDate(item.startDate)}
        </p>
      </div>
      <div
        className={`align-center ${
          idSelected === item._id ? "chevron-open" : ""
        }`}
      >
        <ChevronRight />
      </div>
    </div>
  </div>
);

export default memo(CardExpand);
