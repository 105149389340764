import React, { memo, useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Action } from "redux";
import {
  BookmarkDark,
  BookmarkFilled,
  CompanyAvatarEmpty,
} from "../../../assets/customIcons";
import {
  isSavedThisVacancy,
  saveAVacancy,
  unsaveAVacancy,
} from "../../services/functions";
import { RootState } from "../../store/reducers";
import { JobInformation } from "..";
import "./styles.scss";
import { setLoad } from "../../store/actions/configurationsActions";

interface Props {
  data: {
    companyName?: string;
    id?: string;
    jobPlace?: string;
    jobStatus?: string;
    jobTime?: string | Date;
    jobTitle?: string;
    jobType?: string;
    jobTypeOfCandidature?: string;
    photo?: string;
  };
}

const CardJob = ({ data }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.userState);
  const [isSaved, setIsSaved] = useState(false);

  const checkCandidateAndSaved = useCallback(async () => {
    const saved = await isSavedThisVacancy(data?.id ?? "", user._id);

    setIsSaved(saved);
  }, [data?.id, user._id]);

  useEffect(() => {
    checkCandidateAndSaved();
  }, [checkCandidateAndSaved, data.id, user._id]);

  const handleSaveVacancy = useCallback(
    async (e) => {
      e.stopPropagation();
      dispatch(setLoad(true) as unknown as Action);
      try {
        if (!isSaved) {
          await saveAVacancy(data?.id ?? "", user._id);

          setIsSaved(true);
          toast.success("Vaga salva com sucesso!");
        } else {
          await unsaveAVacancy(data?.id ?? "", user._id);
          setIsSaved(false);
        }
      } catch (err) {
        toast.error("Ocorreu um erro ao salvar a vaga");
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [data?.id, dispatch, isSaved, user._id],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="card-job-component" key={Math.random()}>
      <div className="display-inline">
        {data?.photo ? (
          <img
            className="card-job-component-image"
            src={data?.photo}
            alt="company logo"
          />
        ) : (
          <CompanyAvatarEmpty
            className="card-job-component-avatar"
            widthIcon={50}
            heightIcon={50}
          />
        )}
        {isSaved ? (
          <BookmarkFilled
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
            onClick={handleSaveVacancy}
            className="card-job-component-bookmark"
          />
        ) : (
          <BookmarkDark
            className="card-job-component-bookmark-dark"
            onClick={handleSaveVacancy}
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        )}
      </div>
      <div
        aria-label="infos"
        role="button"
        tabIndex={0}
        onClick={() => history.push(`/job/${data?.id}`)}
        className="cursor-pointer"
      >
        <JobInformation
          data={data}
          titleClassName="margin-top-16"
          statusClassName="card-job-component-status"
        />
      </div>
    </div>
  );
};

export default memo(CardJob);
