import React, { memo, useState, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Banner,
  Button,
  ModalFixedHeader,
  CookiesConsent,
  PrivacyPolicy,
} from "..";
import {
  getContractsByType,
  createUserContracts,
} from "../../services/functions";
import { contractType } from "../../types/enumerators";
import { RootState } from "../../store/reducers";

const PopUp = (): JSX.Element => {
  const [openModalPrivacy, setOpenModalPrivacy] = useState(false);
  const [openModalCookies, setOpenModalCookies] = useState(false);
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
  const [acceptedCookiesConsent, setAcceptedCookiesConsent] = useState(false);
  const user = useSelector((state: RootState) => state.userState);
  const accepted = localStorage.getItem("@SESSION:cookies");

  const onAcceptCookies = useCallback(async () => {
    const contract = await getContractsByType(contractType.COOKIES);
    const i = contract ? (contract?.length ?? 1) - 1 : 0;

    if (contract && contract[i]?._id) {
      const data = {
        userId: user?._id ?? "",
        contractId: contract[i]?._id ?? "",
        date: new Date(),
        accepted: true,
      };
      await createUserContracts(data);
    }

    localStorage.setItem("@SESSION:cookies", "accepted");
  }, [user?._id]);

  useEffect(() => {
    if (acceptedPrivacyPolicy && acceptedCookiesConsent) onAcceptCookies();
  }, [acceptedCookiesConsent, acceptedPrivacyPolicy, onAcceptCookies]);

  const secondaryButton = useMemo(
    () => ({
      text: "POLÍTICAS DE COOKIES",
      onClick: () => setOpenModalCookies(true),
      showIcon: acceptedCookiesConsent,
    }),
    [acceptedCookiesConsent],
  );

  const tertiaryButton = useMemo(
    () => ({
      text: "TERMO DE CONSENTIMENTO",
      onClick: () => setOpenModalPrivacy(true),
      showIcon: acceptedPrivacyPolicy,
    }),
    [acceptedPrivacyPolicy],
  );

  return !accepted ? (
    <div />
  ) : (
    <div className="new-search-mobile">
      <Banner
        secondaryButton={secondaryButton}
        tertiaryButton={tertiaryButton}
        message="Antes de seguir, precisamos que você leia e aceite as políticas abaixo:"
        className="pop-up"
        notIconClose={acceptedPrivacyPolicy && acceptedCookiesConsent}
      />
      <ModalFixedHeader
        headerAction
        textHeader="POLÍTICA DE COOKIES DA {REPROGRAMA}"
        open={openModalCookies}
        onClose={() => setOpenModalCookies(false)}
        className="modal-term"
      >
        <CookiesConsent />
        <Button
          text="Sim"
          onClick={() => {
            setAcceptedCookiesConsent(true);
            setOpenModalCookies(false);
          }}
          className="button-accept-term"
        />
      </ModalFixedHeader>
      <ModalFixedHeader
        headerAction
        textHeader="POLÍTICA DE PRIVACIDADE DA {REPROGRAMA}"
        open={openModalPrivacy}
        onClose={() => setOpenModalPrivacy(false)}
        className="modal-term"
      >
        <PrivacyPolicy />
        <Button
          text="Sim"
          onClick={() => {
            setAcceptedPrivacyPolicy(true);
            setOpenModalPrivacy(false);
          }}
          className="button-accept-term"
        />
      </ModalFixedHeader>
    </div>
  );
};
export default memo(PopUp);
