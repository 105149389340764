/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { CardItems } from "..";
import { RootState } from "../../store/reducers";
import { UserCurriculumPortfolio } from "../../types/interfaces";

interface Props {
  className?: string;
  hasCards?: boolean;
  isMyProfile?: boolean;
  items?: UserCurriculumPortfolio[];
  onEdit?: (item: any, index: number) => void;
  onMove?: (items: any) => void;
  onRemove?: (item: any, index: number) => void;
  showDivider?: boolean;
}

const ViewPortfolio = ({
  className,
  hasCards,
  isMyProfile,
  items,
  onEdit,
  onMove,
  onRemove,
  showDivider = false,
}: Props): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);

  const data = useMemo(
    () => items ?? user?.curriculum?.portfolios,
    [items, user?.curriculum?.portfolios],
  );

  return data?.length > 0 ? (
    <CardItems
      hasIcon
      isMyProfile={isMyProfile}
      subTitle="url"
      items={data}
      onEdit={onEdit}
      onMove={onMove}
      onRemove={onRemove}
      className={`${!hasCards ? "cards-items-profile" : ""} ${className}`}
      showDivider={showDivider}
    />
  ) : (
    <p className="paragraph-not-registered">Não há portfólios cadastrados</p>
  );
};

export default memo(ViewPortfolio);
