import React, { useCallback, useMemo, useState } from "react";
import { Input, Select } from "../../../components";
import {
  ClassVacancy,
  ClassVacancySubscription,
} from "../../../types/interfaces";
import Historic from "./Grades/historic";
import Workshop from "../../AdminClassView/Modal/Grades/edit";
import "../style.scss";
import {
  classVacancyTemplate,
  classVacancyType,
} from "../../../types/enumerators";
import { Media } from "./Grades";

interface Props {
  course?: ClassVacancy;
  subscription?: ClassVacancySubscription;
  onClose: () => void;
  onlyType?: string;
  reload: () => void;
}

const Proposes = ({
  course,
  subscription,
  onClose,
  onlyType,
  reload,
}: Props): JSX.Element => {
  const [menu, setMenu] = useState<string>(onlyType ?? "");

  const options = useMemo(() => {
    if (subscription && course) {
      const findItems = subscription?.stages?.map((item) => {
        const find = course?.stages?.find(
          (subitem) => item?.stageId === subitem?.stage_id,
        );

        return {
          ...item,
          type: find?.type,
          title: find?.title,
        };
      });

      return findItems?.filter(
        ({ type }) => type !== classVacancyType.EVALUATE,
      );
    }

    return [];
  }, [course, subscription]);

  const gradeSubs = useMemo(() => {
    if (subscription && course) {
      const stageSubsCourse = course?.stages?.find(
        (item) => item?.type === classVacancyType.SUBSCRIPTION,
      );

      const stageSubsUser = subscription?.stages?.find(
        ({ stageId }) => stageId === stageSubsCourse?.stage_id,
      );

      if (stageSubsUser)
        return (
          stageSubsUser?.grade?.find(({ type }) => type === "FINAL")?.value ?? 0
        );
    }

    return 0;
  }, [course, subscription]);

  const getGradeTalentAcademy = useCallback(
    (value: string) => {
      if (subscription && course) {
        const stageTalentAcademyCourse = course?.stages?.find(
          (item) => item?.type === classVacancyType.TALENT_ACADEMY,
        );

        const stageTalentAcademySubs = subscription?.stages?.find(
          ({ stageId }) => stageId === stageTalentAcademyCourse?.stage_id,
        );

        if (stageTalentAcademySubs)
          return (
            stageTalentAcademySubs?.grade?.find(({ type }) => type === value)
              ?.value ?? 0
          );
      }

      return 0;
    },

    [course, subscription],
  );

  const gradesTalentAcademy = useMemo(
    () => ({
      final: getGradeTalentAcademy("FINAL") * 10 ?? 0,
      empathy: getGradeTalentAcademy("EMPATHY") ?? 0,
      resilience: getGradeTalentAcademy("RESILIENCE") ?? 0,
      flexibility: getGradeTalentAcademy("FLEXIBILITY") ?? 0,
      collaboration: getGradeTalentAcademy("COLLABORATION") ?? 0,
      selfDevelopment: getGradeTalentAcademy("SELFDEVELOPMENT") ?? 0,
      selfDiscipline: getGradeTalentAcademy("SELFDISCIPLINE") ?? 0,
    }),
    [getGradeTalentAcademy],
  );

  const fieldsTalent = useMemo(
    () => [
      {
        title: "Mente aberta",
        fields: [
          {
            value: "selfDevelopment",
            label: "Autodesenvolvimento",
            response: gradesTalentAcademy
              ? gradesTalentAcademy?.selfDevelopment
              : "",
          },
          {
            value: "flexibility",
            label: "Flexibilidade",
            response: gradesTalentAcademy
              ? gradesTalentAcademy?.flexibility
              : "",
          },
        ],
      },
      {
        title: "Influência positiva",
        fields: [
          {
            value: "collaboration",
            label: "Colaboração",
            response: gradesTalentAcademy
              ? gradesTalentAcademy?.collaboration
              : "",
          },
          {
            value: "empathy",
            label: "Empatia",
            response: gradesTalentAcademy ? gradesTalentAcademy?.empathy : "",
          },
        ],
      },
      {
        title: "Autogestão",
        fields: [
          {
            value: "selfDiscipline",
            label: "Autodisciplina",
            response: gradesTalentAcademy
              ? gradesTalentAcademy?.selfDiscipline
              : "",
          },
          {
            value: "resilience",
            label: "Resiliência",
            response: gradesTalentAcademy
              ? gradesTalentAcademy?.resilience
              : "",
          },
        ],
      },
    ],
    [gradesTalentAcademy],
  );

  const stages = useMemo(() => {
    if (course && subscription)
      return subscription?.stages?.map((stg) => {
        const stageCourse = course?.stages?.find(
          (item) => item?.stage_id === stg?.stageId,
        );
        return { ...stg, type: stageCourse?.type, title: stageCourse?.title };
      });

    return [];
  }, [course, subscription]);

  return (
    <div className="admin-subscriptions-infos-body propose">
      <Historic stages={stages} />

      <div className="propose-title">
        <h2>Atribuir nota</h2>
      </div>

      <div className={`propose-form ${menu} `}>
        <div
          className={`propose-form-steps ${
            menu !== classVacancyType.WORKSHOP
              ? "display-block"
              : "display-flex"
          }`}
        >
          <Select
            value={menu}
            label="Selecione a etapa"
            options={
              !onlyType
                ? options?.map(({ type = "", title = "" }) => ({
                    value: type,
                    label: title,
                  })) ?? [{ value: "", label: "" }]
                : options
                    ?.filter?.((item) => item?.type === onlyType)
                    ?.map(({ type = "", title = "" }) => ({
                      value: type,
                      label: title,
                    })) ?? [{ value: "", label: "" }]
            }
            onChange={(e) => setMenu(e.target.value)}
          />
        </div>

        {menu === classVacancyType.SUBSCRIPTION && (
          <Input type="text" label="Nota" value={gradeSubs ?? 0} disabled />
        )}

        {menu === classVacancyType.TALENT_ACADEMY && (
          <div>
            {fieldsTalent?.map(({ title, fields }) => (
              <div key={Math.random()} className="propose-form-talent">
                <p className="propose-form-talent-title">{title}</p>
                <div className="propose-form-talent-fields">
                  {fields?.map(({ value, label }) => (
                    <Input
                      className="input-diversity"
                      label={`Nota ${label}`}
                      type="number"
                      value={
                        gradesTalentAcademy[
                          value as keyof typeof gradesTalentAcademy
                        ]
                      }
                      isGrade
                      InputProps={{ readOnly: true }}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        {menu === classVacancyType.UPLOAD_MEDIAS && (
          <Media
            course={course as ClassVacancy}
            subscription={subscription as ClassVacancySubscription}
            onClose={onClose}
            reload={reload}
            template={course?.template as keyof typeof classVacancyTemplate}
          />
        )}

        {menu === classVacancyType.WORKSHOP && (
          <Workshop
            className="subscriptions"
            course={course as ClassVacancy}
            subscription={subscription as ClassVacancySubscription}
            reload={reload}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
};

export default Proposes;
