import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const ChevronRight = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#606060"
      d="M11.332 22.75l8.5-8.75-8.5-8.75-2 2.059 6.5 6.691-6.5 6.691 2 2.059z"
    />
  </svg>
);

export default ChevronRight;
