/* eslint-disable no-await-in-loop */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { Modal } from "../../../components";
import { classVacancyType } from "../../../types/enumerators";
import { AvatarEmpty, Close } from "../../../../assets/customIcons";
import { Comments as CommentsComponent } from "../Steps";
import {
  ClassVacancyStages,
  ClassVacancySubscription,
  ClassVacancySubscriptionStage,
} from "../../../types/interfaces";
import { getAllUserClassVacancySubscription } from "../../../services/functions";
import { setLoad } from "../../../store/actions/configurationsActions";
import "../style.scss";

interface ParamsProps {
  id: string;
}

interface Props {
  open: boolean;
  onclose: () => void;
  reload: () => void;
  isVolunteer: boolean;
  user: { id: string; subscriptionId: string; name: string; photo: string };
  stagesVacancy: ClassVacancyStages[];
  stagesSubs: ClassVacancySubscriptionStage[];
}

const Comments = ({
  open,
  onclose,
  reload,
  isVolunteer,
  user,
  stagesVacancy,
  stagesSubs,
}: Props): JSX.Element => {
  const { id } = useParams<ParamsProps>();
  const [menu, setMenu] = useState(
    isVolunteer ? classVacancyType.UPLOAD_MEDIAS : "GENERAL",
  );
  const [allSubscriptions, setAllSubscriptions] =
    useState<ClassVacancySubscription[]>();

  const dispatch = useDispatch();

  const options = useMemo(() => {
    if (stagesSubs && stagesVacancy) {
      const findItems = stagesSubs?.map((item) => {
        const find = stagesVacancy?.find(
          (subitem) => item?.stageId === subitem?.stage_id,
        );
        return {
          ...item,
          type: find?.type,
          title: find?.title,
        };
      });

      return findItems?.filter(
        ({ type }) => type !== classVacancyType.EVALUATE,
      );
    }
    return [];
  }, [stagesVacancy, stagesSubs]);

  const onClose = useCallback(async () => {
    setMenu(isVolunteer ? classVacancyType.UPLOAD_MEDIAS : "GENERAL");
    onclose();
    reload();
  }, [isVolunteer, onclose, reload]);

  const getAllSubscriptions = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getAllUserClassVacancySubscription(user?.id ?? "");
    setAllSubscriptions(response?.data as ClassVacancySubscription[]);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, user?.id]);

  useEffect(() => {
    if (!allSubscriptions) getAllSubscriptions();
  }, [getAllSubscriptions, allSubscriptions]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="md"
      className="admin-subscriptions-infos"
    >
      <div className="admin-subscriptions-infos-header">
        <div className="admin-subscriptions-infos-header-top">
          <div>
            {user?.photo ? (
              <img
                alt=""
                src={user?.photo}
                className="img-avatar-header-modal"
              />
            ) : (
              <AvatarEmpty
                className="border-avatar-empty img-attachment-photo-small"
                heightIcon={32}
                widthIcon={32}
              />
            )}
            <p className="p-text-header-action-card">{user?.name}</p>
          </div>
          <Close
            className="close-header-action-card margin-left-6 cursor-pointer"
            widthIcon="28"
            onClick={onClose}
          />
        </div>
        {!isVolunteer && (
          <div className="admin-subscriptions-infos-header-bottom">
            <button
              type="button"
              className={menu === "GENERAL" ? "is-active" : ""}
              onClick={() => setMenu("GENERAL")}
            >
              Gerais
            </button>
            {options?.map(({ title, type }) => (
              <button
                key={Math.random()}
                type="button"
                className={menu === type ? "is-active" : ""}
                onClick={() => setMenu(type ?? "GENERAL")}
              >
                {title}
              </button>
            ))}
            {allSubscriptions && allSubscriptions?.length > 1 && (
              <button
                type="button"
                className={menu === "PREVIOUSCOMMENTS" ? "is-active" : ""}
                onClick={() => setMenu("PREVIOUSCOMMENTS")}
              >
                Comentários anteriores
              </button>
            )}
          </div>
        )}
      </div>
      <CommentsComponent
        id={user?.subscriptionId ?? ""}
        allSubscriptions={allSubscriptions}
        step={menu}
        onClose={onclose}
        classVacancyId={id ?? ""}
        previousComments={menu === "PREVIOUSCOMMENTS"}
      />
    </Modal>
  );
};

export default memo(Comments);
