import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const InvitesNew = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#939393"
      stroke="#939393"
      strokeWidth=".3"
      d="M19.794 5.65H6.956c-1.217 0-2.207.99-2.207 2.206v3.189H2.632c-.35 0-.632.283-.632.632 0 .348.283.631.632.631h2.117v1.697H3.693c-.35 0-.632.282-.632.631 0 .35.283.632.632.632h1.056v.825c0 1.217.99 2.207 2.207 2.207h12.838c1.216 0 2.206-.99 2.206-2.207V7.856c0-1.216-.99-2.206-2.206-2.206zM6.956 6.913h12.838c.224 0 .43.08.592.21l-6.132 4.5c-.518.38-1.24.38-1.758 0l-6.132-4.5c.162-.13.368-.21.592-.21zm13.78 9.18c0 .52-.422.943-.942.943H6.956c-.52 0-.943-.423-.943-.943v-.825h2.379c.349 0 .632-.283.632-.632 0-.349-.283-.631-.632-.631h-2.38V8.433l5.736 4.208c.479.352 1.053.527 1.627.527.573 0 1.148-.175 1.626-.527l5.736-4.208v7.66h0z"
    />
  </svg>
);

export default InvitesNew;
