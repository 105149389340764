import React, { memo } from "react";
import { useSelector } from "react-redux";
import { ViewSocialMedias } from "../../../components";
import { RootState } from "../../../store/reducers";
import { maskPhone } from "../../../utils";

const ViewContact = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);

  return (
    <div className="grid-1-column flex-row-center flex-wrap display-block">
      <div className="flex flex-col margin-bottom-20-mobile">
        <p className="title-view margin-bottom-6">Celular</p>
        <p className="view-bold">{maskPhone(user?.profile?.telephone ?? "")}</p>
      </div>
      {user?.profile?.telegram && (
        <div className="flex-col margin-contact margin-bottom-20-mobile">
          <p className="title-view margin-bottom-6 ">Telegram</p>
          <p className="view-bold">
            {maskPhone(user?.profile?.telegram ?? "")}
          </p>
        </div>
      )}
      {user?.profile?.socialMedias &&
      user?.profile?.socialMedias?.length > 0 ? (
        <ViewSocialMedias socialMedias={user?.profile?.socialMedias} />
      ) : null}
    </div>
  );
};

export default memo(ViewContact);
