export const CurrentSituation = {
  CLT: "Contratada CLT",
  INTERNSHIP: "Estagiária",
  PJ: "Contratada PJ",
  FREELANCER: "Freelancer",
  ENTREPRENEUR: "Empreendedora",
  SCHOLARSHIP: "Bolsista de pesquisa na área tecnológica",
  UNEMPLOYED: "Desempregada",
};

export const CurrentJoblevel = {
  INTERNSHIP: "Estágio",
  TRAINEE: "Trainee",
  JUNIOR: "Júnior",
  PLENO: "Pleno",
  SENIOR: "Senior",
  COORDINATION: "Coordenação",
  LEAD: "Lead",
};

export const FirstTechJob = {
  RESPONSE_ONE_MONTHS: {
    SAME_WORK_BEFORE_COURSE: "Já estava neste mesmo emprego antes do curso",
    DURING_COURSE: "Ainda durante o curso",
    TO_ONE_MONTHS: "Em até um mês de formada",
  },
  RESPONSE_MORE_ONE_MONTHS: {
    SAME_WORK_BEFORE_COURSE: "Já estava neste mesmo emprego antes do curso",
    DURING_COURSE: "Ainda durante o curso",
    TO_ONE_MONTHS: "Em até um mês de formada",
    BETWEEN_ONE_THREE_MONTHS: "Entre 1 e 3 meses de formada",
    BETWEEN_THREE_SIX_MONTHS: "Entre 3 e 6 meses de formada",
    BETWEEN_SEVEN_ONE_YEAR_MONTHS: "Entre 6 meses e 1 ano de formada",
  },
};

export const PlatformCurrentTechJob = {
  PLATAFORMA_REPROGRAMA: "Plataforma reprograma",
  LINKEDIN: "Linkedin",
  VAGAS: "Vagas",
  GUPY: "Gupy",
  GREENHOUSE: "Greenhouse",
  NINETY_NINE_JOBS: "99jobs",
  COMPLEO: "Compleo",
  SAP: "SAP",
  OTHERS: "Outro",
  NOT_APPLY: "Não aplica",
};

export const NumberSelectionProcesses = {
  NONE: "Nenhum",
  ONE: "1",
  TWO: "2",
  THREE: "3",
  BIGGER_THAN_THREE: "+3",
};

export const JobsSinceGraduation = {
  NONE: "Nenhum",
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR: "4",
  FIVE: "5",
  BIGGER_THAN_FIVE: "+5",
};

export const DurationEmploymentGraduation = {
  UNEMPLOYED: "Ainda não consegui emprego",
  LESS_THAN_THREE: "Menos de 3 meses",
  THREE_TO_SIX_MONTHS: "Entre 3 e 6 meses",
  SIX_TO_NINE_MONTHS: "Entre 6 e 9 meses",
  NINE_TO_TWELVE_MONTHS: "Entre 9 e 12 meses",
};

export const ReasonLeavingWork = {
  COMPANY_CULTURE: "A cultura da empresa não estava de acordo com meus valores",
  UNSATISFACTORY_SALARY: "Salário insatisfatório",
  JOB_NOT_EXPECTED: "O meu trabalho não era o que eu esperava",
  BEST_OFFER: "Recebi uma proposta melhor",
  NO_RECOGNITION: "Não era reconhecida",
  TOXIC_LEADERSHIP: "Liderança tóxica",
  VICTIM_DISCRIMINATION: "Fui vítima de discriminação/assédio",
  OTHERS: "Outro motivo",
  NOT_APPLY: "Não aplica",
};

export const CourseLevel = {
  GRADUATE: "Graduação",
  TECHNICAL: "Técnico",
  TECHNOLOGIST: "Tecnólogo",
  SPECIALIZATION: "Especialização (lato senso)",
  BOOTCAMP: "Bootcamp (intensivo)",
  CURSES_ONLINE: "curso livres online",
};

export const UndertakenArea = {
  UNEMPLOYED: "Desempregada",
  ENTERPRISING_NOT_TECH: "Empreendendo outra coisa",
  EMPLOYEE_CLT: "Empregado com registro em carteira",
  EMPLOYEE_WITHOUT_REGISTRATION: "Empregado sem registro em carteira",
  EMPLOYEE_PJ: "Empregado como pessoa jurídica (PJ)",
  STUDYING: "Estudando",
};

export const CompanySegment = {
  BANK: "Banco",
  RETAIL: "Varejo",
  HEALTH: "Saúde",
  INFORMATION_TECHNOLOGY: "Tecnologia da Informação",
  EDUCATION: "Educação",
  OTHER: "Outro",
};

export const TimeEmployed = {
  LESS_THREE_MONTHS: "Menos de 3 meses",
  BETWEEN_THREE_SIX_MONTHS: "Entre 3 e 6 meses",
  BETWEEN_SIX_NINE_MONTHS: "Entre 6 e 9 meses",
  BETWEEN_NINE_ELEVEN_MONTHS: "Entre 9 e 11 meses",
  ONE_YEAR: "1 ano",
  MORE_ONE_YEAR: "Mais de 1 ano",
};

export const ChoseAreaTech = {
  CAREER_TRANSITION: "Realizar transição de carreira",
  BETTER_JOB_OPPORTUNITIES: "Obter oportunidades melhores de trabalho",
  INTEREST_IDENTIFICATION_TECHNPLOGY:
    "Interesse e identificação com a área de tecnologia",
  INCREASE_INCOME: "Aumentar a renda",
};

export const TimeSameOccupation = {
  LESS_THREE_MONTHS: "Menos de 3 meses",
  BETWEEN_THREE_SIX_MONTHS: "Entre 3 e 6 meses",
  BETWEEN_SIX_NINE_MONTHS: "Entre 6 e 9 meses",
  BETWEEN_NINE_ELEVEN_MONTHS: "Entre 9 e 11 meses",
  ONE_YEAR: "1 ano",
  MORE_ONE_YEAR: "Mais de 1 ano",
};

export const ResearchType = {
  ONE_MONTHS: "1 mês",
  THREE_MONTHS: "3 meses",
  SIX_MONTHS: "6 meses",
  TWELVE_MONTHS: "12 meses",
  COMPANY: "Empresa",
};
