import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CircularProgress } from "@mui/material";
import moment from "moment-timezone";
import { getUserById } from "../../../../services/functions";
import { AvatarEmpty, Show } from "../../../../../assets/customIcons";
import { ClassVacancySubscriptionStage } from "../../../../types/interfaces";
import "../../style.scss";

interface Props {
  stage: ClassVacancySubscriptionStage & {
    users?: {
      userName?: string;
      photo?: string | null;
    }[];
    userIds?: string[];
    userId?: string;
  };
  setView: Dispatch<SetStateAction<boolean>>;
}

const Historic = ({ stage, setView }: Props): JSX.Element => {
  const [stageComplete, setStageComplete] = useState(stage);
  const userView = useMemo(
    () => stageComplete?.users?.[(stageComplete?.users?.length ?? 1) - 1],
    [stageComplete?.users],
  );

  const getStage = useCallback(async () => {
    const stageWithUser = { ...stage };
    stageWithUser.users = [];

    const users = stage?.userIds ?? [stage?.userId];
    if (users) {
      const getUsers = await Promise.all(
        users?.map(async (userId) => getUserById(userId ?? "")),
      );
      stageWithUser.users = getUsers?.map((item) => ({
        userName: item?.profile?.name ?? "",
        photo: item?.profile?.photo ?? null,
      }));
    }

    setStageComplete(stageWithUser);
  }, [stage]);

  useEffect(() => {
    getStage();
  }, [getStage]);

  if (!stageComplete)
    return (
      <div className="subscriptions-loading subscriptions-loading-propose">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <div className="propose-title">
        <h2>Histórico</h2>
      </div>
      {stageComplete?.grade ? (
        <div className="propose-items">
          <div className="propose-item">
            <div className="propose-item-title">
              <p>
                {moment(stageComplete?.endDate)
                  .tz("America/Sao_Paulo")
                  .format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="propose-item-content">
              <div className="propose-item-info">
                <div>
                  {userView?.photo ? (
                    <img
                      className="propose-item-avatar"
                      alt=""
                      src={userView?.photo}
                    />
                  ) : (
                    <AvatarEmpty
                      className="border-avatar-empty img-attachment-photo-small"
                      heightIcon={32}
                      widthIcon={32}
                    />
                  )}
                </div>
                <div>
                  <p>{userView?.userName ?? "-"}</p>
                  <h4 key={Math.random()}>
                    Nota Final:{" "}
                    {stageComplete?.grade?.find(
                      (item) => item?.type === "FINAL",
                    )?.value ?? 0}
                  </h4>
                </div>
              </div>
              <div className="propose-item-buttons">
                <button
                  aria-label="show"
                  type="button"
                  onClick={() => setView(true)}
                >
                  <Show />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="propose-without">
          <p>Sem notas</p>
        </div>
      )}
    </>
  );
};

export default Historic;
