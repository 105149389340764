import React, { memo, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ClassVacancy } from "reprograma-types/dist/interface";
import {
  Layout,
  CardStepsCourse,
  CardRegister,
  CardSteps,
} from "../../components";
import { RootState } from "../../store/reducers";
import {
  getActiveUserClassVacancySubscription,
  getClassVacancyById,
} from "../../services/functions";
import { setCourseSubscription } from "../../store/actions/courseActions";
import Loading from "../Loading";
import { classVacancyStatus } from "../../types/enumerators";
import { Check } from "../../../assets/icons";
import Unsubscribe from "../../components/Unsubscribe";

const CourseEvaluate = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const subscription = useSelector((state: RootState) => state.courseState);
  const dispatch = useDispatch();
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();
  const [width, setWidth] = useState(window.innerWidth);
  const getSubscription = useCallback(async () => {
    const subscriptions = await getActiveUserClassVacancySubscription(user._id);
    if (subscriptions?.data?.length)
      dispatch(
        setCourseSubscription(subscriptions?.data?.[0]) as unknown as Action,
      );
  }, [dispatch, user._id]);

  const getClassVacancy = useCallback(async () => {
    const response = await getClassVacancyById(subscription?.classVacancyId);
    if (response?.data) setClassVacancy(response?.data);
  }, [subscription?.classVacancyId]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  useEffect(() => {
    if (!classVacancy && !!subscription?._id) getClassVacancy();
  }, [classVacancy, subscription?._id, getClassVacancy]);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  const activeStage = classVacancy?.stages.find(
    (stage) => stage.status === classVacancyStatus.ACTIVE,
  )?.order;

  const itemsMenu: [{ icon: JSX.Element; text: string; id: number }] =
    classVacancy?.stages.map(({ order, title }) => ({
      icon: <Check />,
      text: title,
      id: order,
    }));

  const steps = {
    page: 0,
    maxPageVisited: activeStage,
    maxMenuVisited: activeStage,
  };

  return classVacancy === undefined ? (
    <Loading />
  ) : (
    <Layout
      headerCourse
      exit
      className="padding-bottom-40 grid-responsive course course-evaluate"
    >
      {width > 990 ? (
        <CardStepsCourse stages={classVacancy?.stages} />
      ) : (
        <div>
          <CardSteps
            smallScreen
            className="card-steps-register step-MuiPaper-root"
            orientation="horizontal"
            itemsMenu={itemsMenu}
            steps={steps}
          />
        </div>
      )}

      <Unsubscribe userId={user?._id} subscriptionId={subscription?._id} />
      <CardRegister
        titleHeader="Sua inscrição"
        className="margin-top-16 margin-mobile-register border-card grid-column-5-12 grid-column-1-11-mobile"
      >
        <div className="information">
          <p className="information-title">
            Olá! Aqui você tem informações a respeito da sua candidatura para a
            turma da
            <b>
              &nbsp;
              {`{reprograma}`}
              &nbsp;
              {classVacancy?.title}.
            </b>
          </p>
          <p className="information-title">
            Acompanhe seu e-mail que te avisaremos das próximas etapas.
          </p>
        </div>
      </CardRegister>
    </Layout>
  );
};

export default memo(CourseEvaluate);
