/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useState } from "react";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  ExternalLink,
  FileProfileMin,
  IconVideo,
  FileProfile,
} from "../../../../../assets/customIcons";
import { mediaType } from "../../../../types/enumerators";
import { CardMedia, Draggable } from "../../../../components";
import { youtubeApi } from "../../../../services/api";
import { RootState } from "../../../../store/reducers";
import { isStudent, youTubeIdFromLink } from "../../../../utils/index";
import { setLoad } from "../../../../store/actions/configurationsActions";

interface Props {
  company: any;
}

const Medias = ({ company }: Props): JSX.Element => {
  const [youTubeList, setYouTubeList] = useState<any>();
  const [width, setWidth] = useState(window.innerWidth);

  const user = useSelector((state: RootState) => state.userState);
  const dispatch = useDispatch();

  const getYouTubeList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const youTubeIds = company?.profile?.medias
      ?.filter((item: { url: string }) => item?.url?.includes("youtu"))
      ?.map((innerItem: { url: string }) => ({
        url: innerItem?.url,
        id: youTubeIdFromLink(innerItem?.url),
      }));

    const videosList: any = [];

    for (let i = 0; i < youTubeIds?.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const response = await youtubeApi.get(
        `/videos?key=AIzaSyAz3zSCJ-Du4DyU8sr2X2xxn4xhv7RRzHM&part=snippet&id=${youTubeIds[i]?.id}`,
      );

      if (
        response?.data?.items?.length &&
        response?.data?.items[0]?.kind === "youtube#video"
      )
        videosList.push(response?.data?.items[0]);
    }

    setYouTubeList(videosList);

    dispatch(setLoad(false) as unknown as Action);
  }, [company?.profile?.medias, dispatch]);

  useEffect(() => {
    if (!youTubeList) getYouTubeList();
  }, [youTubeList, getYouTubeList]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <div className="cards-profile padding-cards-profile">
        <div className="flex-row-center all-title-midia">
          <FileProfile
            widthIcon={width < 991 ? 24 : 32}
            heightIcon={width < 991 ? 24 : 32}
          />
          <p className="cards-title-profile title-allMidia">Mídias</p>
        </div>
        <div className="divider-card-title" />

        <div className="flex-row-center padding-title-card">
          <IconVideo
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
          <p className="title-paragraph">Vídeos</p>
        </div>
        <div className="flex-wrap-midia" />

        <div className="cards-items-profile">
          {youTubeList?.length > 0 && (
            <div key={Math.random()} className="flex-direction-wrap">
              {youTubeList?.map(
                (item: { id: string; snippet: { title: string } }) => (
                  <div className="container-videos-all-midias">
                    <iframe
                      width="264px"
                      height="152px"
                      src={`https://www.youtube.com/embed/${item?.id}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="videos-all-midias"
                    />
                    <p className="all-title-video">
                      {item?.snippet?.title ?? ""}
                    </p>
                  </div>
                ),
              )}
            </div>
          )}
        </div>

        {company?.profile?.medias?.find(
          (item: { type: string }) => item.type !== mediaType.LINK,
        ) && (
          <div>
            <div className="line-divider" />
            <div className="flex-row-center padding-title-card">
              <FileProfileMin
                widthIcon={width < 991 ? 20 : 24}
                heightIcon={width < 991 ? 20 : 24}
              />
              <p className="title-paragraph">Documentos</p>
            </div>
            <div className="flex-wrap-midia padding-title-card">
              <Draggable items={company?.profile?.medias}>
                <div className="flex-wrap-midia">
                  {company?.profile?.medias?.map((item: any, index: number) => {
                    if (item.type !== mediaType.LINK) {
                      return (
                        <DraggableComponent
                          key={Math.random()}
                          draggableId={Math.random().toString()}
                          index={index}
                          isDragDisabled={
                            isStudent(user) ||
                            company?.profile?.medias?.filter(
                              (innerItem: { type: string }) =>
                                innerItem.type !== mediaType.LINK,
                            )?.length === 1
                          }
                        >
                          {(innerProvided) => (
                            <div
                              ref={innerProvided.innerRef}
                              {...innerProvided.draggableProps}
                              {...innerProvided.dragHandleProps}
                              className="margin-bottom-24"
                            >
                              <CardMedia small item={item} />
                            </div>
                          )}
                        </DraggableComponent>
                      );
                    }
                    return <div />;
                  })}
                </div>
              </Draggable>
            </div>
          </div>
        )}
        <div className="line-divider" />
        <div>
          {company?.profile?.medias?.find(
            (item: { type: string }) => item.type === mediaType.LINK,
          ) && (
            <div>
              <div className="flex-row-center padding-wrap">
                <ExternalLink
                  widthIcon={width < 991 ? 20 : 24}
                  heightIcon={width < 991 ? 20 : 24}
                />
                <p className="title-paragraph">Publicações</p>
              </div>
              <div className="flex-wrap">
                {company?.profile?.medias?.map(
                  (item: { type: string; url: string }) => {
                    if (item.type === mediaType.LINK) {
                      return (
                        <a
                          key={Math.random()}
                          href={
                            item?.url?.includes("http")
                              ? item?.url
                              : `http://${item?.url}`
                          }
                          className="link-publication"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.url}
                        </a>
                      );
                    }
                    return <div />;
                  },
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Medias);
