/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import moment from "moment";
import {
  Button,
  Card,
  HeaderProfile,
  Layout,
  Pagination,
  Table,
} from "../../components";
import { RootState } from "../../store/reducers";
import { classVacancyStatus } from "../../types/enumerators";
import { ClassVacancy, User } from "../../types/interfaces";
import {
  createClassVacancy,
  filterClassVacancy,
  findUsesClassVacancy,
  getPages,
  updateClassVacancyById,
} from "../../services/functions";
import {
  createId,
  hasAdminClassVacancyVolunteerMediaRole,
  isAdmin,
} from "../../utils";
import "./style.scss";
import { setLoad } from "../../store/actions/configurationsActions";

const AdminClassVacancy = (): JSX.Element => {
  const user: User = useSelector((state: RootState) => state.userState);
  const history = useHistory();
  const dispatch = useDispatch();
  const isVolunteerMedia =
    isAdmin(user) && hasAdminClassVacancyVolunteerMediaRole(user);

  const [list, setList] = useState<ClassVacancy[]>();
  const [totalFilteredList, setTotalFilteredList] = useState(0);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const getList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    if (pageLimit) {
      setMaxPage(await getPages(filterClassVacancy, {}, true));

      const filters = {} as { ids?: string[] };
      if (isVolunteerMedia)
        filters.ids = Object.keys(user?.account?.classVacancy ?? {});

      const numTotalList = await filterClassVacancy(filters, true);
      if (numTotalList?.data?.length)
        setTotalFilteredList(numTotalList?.data?.[0]?.size ?? 0);

      const response = await filterClassVacancy(
        filters,
        false,
        page,
        pageLimit,
      );

      const currentList: any[] = [];
      if (response?.data && response?.data?.length) {
        for (let j = 0; j < response?.data?.length; j += 1) {
          const current = response?.data[j];
          const classCodes: any = [];
          let currentStage = "";
          let startDateFormat = "";
          let endDateFormat = "";

          const classes = current?.class ?? [];
          for (let i = 0; i < classes?.length; i += 1)
            classCodes.push(classes[i]?.code ?? "");

          currentStage =
            current?.stages?.find(
              (item: { status: string }) =>
                item?.status === classVacancyStatus.ACTIVE,
            )?.title ?? "";

          startDateFormat = moment(current?.startDate).isValid()
            ? moment(current?.startDate).format("DD/MM/YYYY")
            : "";
          endDateFormat = moment(current?.endDate).isValid()
            ? moment(current?.endDate).format("DD/MM/YYYY")
            : "";

          currentList.push({
            ...current,
            classCodes: classCodes.join(", "),
            currentStage,
            startDateFormat,
            endDateFormat,
          });
        }

        setList(currentList);
      }

      dispatch(setLoad(false) as unknown as Action);
    } else setList([]);

    dispatch(setLoad(false) as unknown as Action);
  }, [
    dispatch,
    isVolunteerMedia,
    page,
    pageLimit,
    user?.account?.classVacancy,
  ]);

  useEffect(() => {
    getList();
  }, [getList, page, pageLimit]);

  const headCells = useMemo(
    () => [
      {
        id: "title",
        label: "Nome",
        orderBy: false,
        asc: false,
      },
      {
        id: "classCodes",
        label: "Turmas",
        orderBy: false,
        asc: false,
      },
      {
        id: "startDateFormat",
        label: "Data de abertura",
        orderBy: false,
        asc: false,
      },
      {
        id: "endDateFormat",
        label: "Data de encerramento",
        orderBy: false,
        asc: false,
      },
      {
        id: "currentStage",
        label: "Etapa atual",
        orderBy: false,
        asc: false,
        classColumn: "w-8",
      },
      {
        id: "status",
        label: "Status",
        orderBy: false,
        asc: false,
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
        asc: false,
      },
    ],
    [],
  );

  const onChangeStatus = useCallback(
    async (row: any, status?: string) => {
      dispatch(setLoad(true) as unknown as Action);
      let currentStatus = status;

      if (status === classVacancyStatus.DISABLED) {
        const usedClass = await findUsesClassVacancy(row?._id);
        if (usedClass) {
          dispatch(setLoad(false) as unknown as Action);
          return false;
        }
      }

      if (!status) {
        if (row?.status !== classVacancyStatus.ACTIVE)
          currentStatus = classVacancyStatus.ACTIVE;
        else currentStatus = classVacancyStatus.PENDING;
      }

      try {
        await updateClassVacancyById(row?._id, { status: currentStatus });
        return true;
      } catch {
        return false;
      } finally {
        dispatch(setLoad(false) as unknown as Action);
      }
    },
    [dispatch],
  );

  const onRemove = useCallback(
    (listSelected) => {
      for (let i = 0; i < listSelected?.length; i += 1)
        onChangeStatus(listSelected[i], classVacancyStatus.DISABLED);

      getList();
    },
    [onChangeStatus, getList],
  );

  const handleCopyClassVacancy = useCallback(
    async (row: { title?: string }) => {
      dispatch(setLoad(true) as unknown as Action);
      const newClassVacancy = JSON.parse(JSON.stringify(row));

      newClassVacancy.title = `${row?.title} (cópia)`;
      newClassVacancy.status = classVacancyStatus.PENDING;
      delete newClassVacancy?._id;
      delete newClassVacancy?.classCodes;
      delete newClassVacancy?.currentStage;
      delete newClassVacancy?.startDateFormat;
      delete newClassVacancy?.endDateFormat;

      const stagesClass = newClassVacancy?.stages;

      if (stagesClass?.length) {
        for (let i = 0; i < stagesClass?.length; i += 1) {
          stagesClass[i].stage_id = createId();
          stagesClass[i].status = classVacancyStatus.PENDING;
        }
      }

      try {
        await createClassVacancy(newClassVacancy);
        return true;
      } catch {
        return false;
      } finally {
        dispatch(setLoad(false) as unknown as Action);
      }
    },
    [dispatch],
  );

  return (
    <Layout className="admin-class">
      <HeaderProfile />
      {!isVolunteerMedia && (
        <Button
          text="Criar"
          className="grid-column-11-13 grid-row-1 invite"
          onClick={() => history.push("/admin-class-vacancy-new")}
        />
      )}
      <Card
        className=" grid-column-1-13 grid-row-2 margin-bottom-32"
        bodyClassName="padding-0 "
      >
        <Table
          cantSelect={isVolunteerMedia}
          emptyText="Não há processos seletivos cadastrados"
          headCells={headCells}
          list={list}
          onActive={
            !isVolunteerMedia ? (row) => onChangeStatus(row) : undefined
          }
          onEdit={
            !isVolunteerMedia
              ? (row) => history.push(`/admin-class-vacancy-edit/${row._id}`)
              : undefined
          }
          onCopy={
            !isVolunteerMedia ? (row) => handleCopyClassVacancy(row) : undefined
          }
          onRemove={
            !isVolunteerMedia
              ? (row, status) => onChangeStatus(row, status)
              : undefined
          }
          onFinish={
            !isVolunteerMedia
              ? (row, status) => onChangeStatus(row, status)
              : undefined
          }
          onReactive={
            !isVolunteerMedia
              ? (row) => onChangeStatus(row, classVacancyStatus.ACTIVE)
              : undefined
          }
          onReload={getList}
          onAllRemove={!isVolunteerMedia ? onRemove : undefined}
          onShow={(row) =>
            history.push(`/admin-class-vacancy-subscriptions/${row._id}`)
          }
        />
      </Card>
      <div className="grid-row-3 grid-column-1-9 width-100 flex margin-top--45">
        <div className="width-100">
          <p className="show-vacancies">
            Mostrando
            <span className="title-span">{list?.length}</span>
            de
            <span className="title-span">{totalFilteredList}</span>
            resultados
          </p>
        </div>
        <div className="width-100 justify-center">
          <Pagination page={page} setPage={setPage} maxPage={maxPage} />
        </div>
      </div>
    </Layout>
  );
};

export default memo(AdminClassVacancy);
