/* eslint-disable no-await-in-loop */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import moment from "moment";
import { Button, Input, Modal, Select, TextArea } from "../../../components";
import { Class, ClassVacancyStages } from "../../../types/interfaces";
import { classVacancyType } from "../../../types/enumerators";
import { setLoad } from "../../../store/actions/configurationsActions";
import {
  getClassById,
  sendWorkshopConfirm,
  updateClassById,
} from "../../../services/functions";
import "../style.scss";

interface Props {
  open: boolean;
  onclose: () => void;
  stages: ClassVacancyStages[];
}

const ConfirmWorkshop = ({ open, onclose, stages }: Props): JSX.Element => {
  const [workshopOptions, setWorkshopOptions] = useState<Class[]>();
  const [workshopClass, setWorkshopClass] = useState<string>();
  const [workshop, setWorkshop] = useState<{
    _id?: string;
    startDate?: string;
    url?: string;
    info?: string;
  }>();

  const dispatch = useDispatch();

  const getWorkshopList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const classIds =
      stages?.find((item) => item?.type === classVacancyType.WORKSHOP)
        ?.classIds ?? [];

    const responseClassList: Class[] = [];
    for (let i = 0; i < classIds?.length; i += 1) {
      const response = await getClassById(classIds[i]);
      if (response?.data) responseClassList?.push(response?.data);
    }

    setWorkshopOptions(responseClassList);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, stages]);

  useEffect(() => {
    if (open && !workshopOptions?.length) getWorkshopList();
  }, [getWorkshopList, open, workshopOptions?.length]);

  const onChange = useCallback(
    (value, props: "url" | "info") =>
      setWorkshop({
        ...workshop,
        [props]: value,
      }),
    [workshop],
  );

  const onConfirm = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const updateClass = {
      ...workshop,
      confirmationWorkshop: true,
    };

    // const startDate = workshopOptions?.find(
    //   (item) => item?._id === workshopClass,
    // )?.startDate;

    await updateClassById(workshopClass ?? "", updateClass);
    await sendWorkshopConfirm(workshopClass ?? "", {
      date: `${moment(updateClass?.startDate).format("DD/MM")} às 09:00`,
      //   date: moment(startDate).format("DD/MM [às] HH:mm") ?? "",
      url: updateClass?.url ?? "",
      info: updateClass?.info ?? "",
    });

    onclose();
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, onclose, workshop, workshopClass]);

  return (
    <Modal
      headerAction
      open={open}
      onClose={() => {
        setWorkshop({});
        setWorkshopClass("");
        onclose();
      }}
      textHeader="Confirmar oficina"
      className="admin-subscriptions-modal-manager-actions"
    >
      <div
        className={`admin-subscriptions-modal-manager-actions confirmation-workshop ${
          workshop?._id ? "show-input" : ""
        }`}
      >
        <Select
          id="classesWorkshop"
          label="Turmas de oficina"
          value={workshopClass ?? ""}
          options={
            workshopOptions?.map((item) => ({
              value: item?._id ?? "",
              label: item?.code ?? "",
            })) ?? [{ value: "", label: "" }]
          }
          className="select"
          onChange={(e) => setWorkshopClass(e.target.value)}
        />
        {workshopClass && (
          <>
            <Input
              type="text"
              label="Link de acesso"
              value={workshop?.url ?? ""}
              onChange={(e) => onChange(e.target.value, "url")}
            />
            <TextArea
              label="Escreva as instruções"
              value={workshop?.info ?? ""}
              onChange={(e) => onChange(e.target.value, "info")}
              textFielRow={4}
              maxLength={2000}
            />
          </>
        )}
      </div>
      <div className="admin-subscriptions-modal-buttons">
        <Button
          text="Cancelar"
          onClick={() => {
            setWorkshop({});
            setWorkshopClass("");
            onclose();
          }}
          outline
          appearance="secondary"
        />
        <Button
          onClick={onConfirm}
          text="Confirmar"
          disabled={workshopClass === "" || !workshop?.url || !workshop?.info}
        />
      </div>
    </Modal>
  );
};

export default memo(ConfirmWorkshop);
