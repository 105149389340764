import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
} from "react";
import { toast } from "react-toastify";
import { AttachmentPhoto, Input, InputPassword } from "../../../components";
import api from "../../../services/api";
import { User } from "../../../types/interfaces";

interface Props {
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  password: string;
  setPassword: Dispatch<SetStateAction<string>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const EditAccount = ({
  user,
  setUser,
  password,
  setPassword,
  setIsValid,
}: Props): JSX.Element => {
  const inputClassName = "grid-row-2 width-100 margin-right-32";

  useEffect(() => {
    if (user?.profile?.name && user?.login?.identification && password)
      setIsValid(true);
    else setIsValid(false);
  }, [user, password, setIsValid]);

  const handleChangeName = useCallback(
    (value: string) =>
      setUser({
        ...user,
        profile: { ...user?.profile, name: value },
      }),
    [setUser, user],
  );

  const handleChangeEmail = useCallback(
    (value: string) =>
      setUser({
        ...user,
        login: { ...user?.login, identification: value },
      }),
    [setUser, user],
  );

  const handleChangePassword = useCallback(
    (value: string) => setPassword(value),
    [setPassword],
  );

  const onChangeImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      api
        .post("/fileUpload", data)
        .then((result) => {
          setUser({
            ...user,
            profile: { ...user?.profile, photo: result?.data?.file_url.url },
          });
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    },
    [setUser, user],
  );

  return (
    <>
      <AttachmentPhoto
        photo={user?.profile?.photo}
        photoSize={96}
        type="foto"
        onChange={onChangeImage}
      />
      <div className="grid-4-column">
        <Input
          label="Nome"
          type="text"
          value={user?.profile?.name ?? ""}
          onChange={({ target: { value } }) => handleChangeName(value)}
          className={inputClassName}
        />
        <Input
          label="E-mail"
          type="text"
          value={user?.login?.identification ?? ""}
          onChange={({ target: { value } }) =>
            handleChangeEmail(value.toLowerCase().trim())
          }
          className={inputClassName}
        />
        <InputPassword
          label="Senha"
          value={password}
          onChange={({ target: { value } }) => handleChangePassword(value)}
          className={inputClassName}
        />
      </div>
    </>
  );
};

export default memo(EditAccount);
