/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useHistory } from "react-router-dom";
import { InputAdornment, TableRow, TextField } from "@material-ui/core";
import moment from "moment";
import {
  Button,
  Card,
  HeaderProfile,
  Layout,
  Pagination,
  MessageVacancyManagement,
  Table,
  Select,
  InputDate,
} from "../../components";
import {
  placementVacancyStatus,
  placementVacancySubscriptionStatus,
  placementVacancyTypeOfCandidature,
} from "../../types/enumerators";
import { ManagedBy, VacancyStatus } from "../../types/constants/Vacancy";
import {
  filterPlacementVacancySubscription,
  filterVacancy,
  getPages,
  unsubscriptInVacancyById,
  updateVacancyById,
} from "../../services/functions";
import { PlacementVacancy } from "../../types/interfaces";
import { RootState } from "../../store/reducers";
import { setLoad } from "../../store/actions/configurationsActions";
import "./style.scss";
import { Search } from "../../../assets/icons";

interface Filters {
  title?: string | null;
  managedBy?: string[] | null;
  status?: string[] | null;
  companyId?: string[] | null;
  startDate?: Date | null;
  endDate?: Date | null;
}

interface Config {
  sortBy: string;
  asc: boolean;
}

const CompanyVacancies = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const company = useSelector((state: RootState) => state.companyState);
  const [vacancies, setVacancies] = useState<PlacementVacancy[]>();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [filters, setFilters] = useState<Filters>({
    companyId: [company?._id],
  });
  const [config, setConfig] = useState<Config>({ asc: true, sortBy: "title" });

  const dispatch = useDispatch();
  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );
  const history = useHistory();

  const headCells = useMemo(
    () => [
      {
        id: "title",
        label: "Título",
        orderBy: true,
        sort: "user.name",
        asc: config?.asc ?? true,
        onclick: () =>
          setConfig((prev) => ({
            ...prev,
            asc: !prev?.asc,
            sortBy: "title",
          })),
        classColumn: "width-240",
      },
      {
        id: "currentManagedBy",
        label: "Administrada por",
        orderBy: true,
        sort: "managedBy",
        asc: config?.asc ?? true,
        onclick: () =>
          setConfig((prev) => ({
            ...prev,
            asc: !prev?.asc,
            sortBy: "managedBy",
          })),
        classColumn: "width-160",
      },
      {
        id: "currentStartDate",
        label: "Abertura",
        orderBy: true,
        sort: "startDate",
        asc: config?.asc ?? true,
        onclick: () =>
          setConfig((prev) => ({
            ...prev,
            asc: !prev?.asc,
            sortBy: "startDate",
          })),
        classColumn: "width-160",
      },
      {
        id: "currentEndDate",
        label: "Encerramento",
        orderBy: true,
        sort: "endDate",
        asc: config?.asc ?? true,
        onclick: () =>
          setConfig((prev) => ({
            ...prev,
            asc: !prev?.asc,
            sortBy: "endDate",
          })),
        classColumn: "width-160",
      },
      {
        id: "skills",
        label: "Habilidades",
        orderBy: false,
        asc: false,
        classColumn: "width-240",
      },
      {
        id: "currentStatus",
        label: "Status",
        orderBy: true,
        sort: "status",
        asc: config?.asc ?? true,
        onclick: () =>
          setConfig((prev) => ({
            ...prev,
            asc: !prev?.asc,
            sortBy: "status",
          })),
        classColumn: "width-120",
      },
      {
        id: "subscriptions",
        label: "Candidatas",
        orderBy: true,
        sort: "subscriptions",
        asc: config?.asc ?? true,
        onClick: () =>
          setConfig((prev) => ({
            ...prev,
            asc: !prev?.asc,
            sortBy: "subscriptions",
          })),
        classColumn: "w-80",
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
        asc: false,
        hide: width < 991,
        classColumn: "width-160",
      },
    ],
    [config?.asc, width],
  );

  const getVacancies = useCallback(
    async (currentFilters?: Filters) => {
      dispatch(setLoad(true) as unknown as Action);
      if (pageLimit) {
        setMaxPage(
          await getPages(
            filterVacancy,
            filters?.title ?? "",
            currentFilters ?? filters,
          ),
        );

        const newVacancies = await filterVacancy(
          filters?.title ?? "",
          currentFilters ?? filters,
          page,
        );

        const data = newVacancies?.map((item) => ({
          ...item,
          currentManagedBy:
            ManagedBy[item?.managedBy as keyof typeof ManagedBy],
          currentStartDate: moment(item?.startDate).format("DD/MM/YYYY"),
          currentEndDate: moment(item?.endDate).format("DD/MM/YYYY"),
          currentStatus:
            item?.status !== placementVacancyStatus.ACTIVE
              ? VacancyStatus[item?.status ?? ""]
              : `${VacancyStatus[item?.status ?? ""]}/${
                  item?.typeOfCandidature ===
                  placementVacancyTypeOfCandidature.OPEN
                    ? "Em captação"
                    : "Sem captação"
                }`,
          skills: item?.desiredSkills,
        })) as PlacementVacancy[];

        setVacancies(data);
      }

      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, filters, page, pageLimit],
  );

  useEffect(() => {
    if (filters?.companyId) getVacancies();
  }, [filters?.companyId, getVacancies, page, pageLimit]);

  useEffect(() => {
    if (company?._id && !filters?.companyId)
      setFilters({ companyId: [company?._id] });
  }, [company?._id, filters?.companyId]);

  const onChangeStatus = useCallback(async (item: any, status?: string) => {
    let currentStatus = status;

    if (!status) {
      if (status !== placementVacancyStatus.ACTIVE)
        currentStatus = placementVacancyStatus.ACTIVE;
      else currentStatus = placementVacancyStatus.PENDING;
    }

    const result = await updateVacancyById(item?._id, {
      status: currentStatus,
    } as PlacementVacancy);

    return result;
  }, []);

  const onRemove = useCallback(
    async (list) => {
      let result;
      for (let i = 0; i < list?.length; i += 1) {
        result = onChangeStatus(list[i], placementVacancyStatus.DISABLED);

        const subs = await filterPlacementVacancySubscription(list[i]?._id, {
          status: placementVacancySubscriptionStatus.IN_PROCESS,
        });
        if (subs && subs?.length) {
          for (let j = 0; j < subs?.length; j += 1)
            await unsubscriptInVacancyById(subs[j]?._id ?? "");
        }
      }

      return result;
    },
    [onChangeStatus],
  );

  const filterStatusList = useMemo(() => {
    const list: any[] = [
      {
        value: Object.keys(VacancyStatus).filter(
          (item) => item !== placementVacancyStatus.DISABLED,
        ),
        label: "Todos",
      },
    ];
    Object.keys(VacancyStatus).forEach((key) =>
      list.push({
        value: [key],
        label: VacancyStatus[key as keyof typeof VacancyStatus],
      }),
    );
    return list;
  }, []);

  const filterManagedByList = useMemo(() => {
    const list: any[] = [
      {
        value: Object.keys(ManagedBy).map((item) => item),
        label: "Todos",
      },
    ];
    Object.keys(ManagedBy).forEach((key) => {
      list.push({
        value: [key],
        label: ManagedBy[key as keyof typeof ManagedBy],
      });
    });
    return list;
  }, []);

  const clearFilters = useCallback(() => {
    setFilters({ companyId: [company?._id] });
    getVacancies({ companyId: [company?._id] });
  }, [company?._id, getVacancies]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-responsive creat-mobile-margin margin-bottom-40 company-vacancies">
      <HeaderProfile />
      {width > 990 && (
        <Button
          text="Criar vaga"
          className="grid-column-11-13 grid-row-1 request-vacancy"
          onClick={() => history.push("company-vacancy-new")}
        />
      )}

      <Card
        className=" grid-column-1-13 grid-row-2 media-small-screen"
        bodyClassName="padding-0 "
      >
        {width > 990 && (
          <TableRow className="top-filters flex">
            <TextField
              autoComplete="off"
              id="searchVacancies"
              placeholder="Buscar vaga"
              type="text"
              InputProps={{
                className:
                  "search-header-profile Mui-input headerSearch searchVacancy",
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              className="search-header search-filter margin-right-8"
              value={filters?.title ?? ""}
              onChange={(e) =>
                setFilters({ ...filters, title: e.target.value })
              }
              onBlur={() => getVacancies()}
            />
            <Select
              className="status-filter margin-right-8"
              id="managedBy"
              label="Administrada por"
              value={filters?.managedBy ?? ""}
              onChange={(e) =>
                setFilters({ ...filters, managedBy: e.target.value })
              }
              onBlur={() => getVacancies()}
              options={filterManagedByList}
            />
            <InputDate
              future
              className="status-filter open-filter-date margin-right-8"
              label="Abertura"
              views={["year", "month", "date"]}
              format="DD/MM/yyyy"
              value={filters?.startDate ?? null}
              onChange={(value) => setFilters({ ...filters, startDate: value })}
              onBlur={() => getVacancies()}
            />
            <InputDate
              future
              className="status-filter close-filter-date margin-right-8"
              label="Encerramento"
              views={["year", "month", "date"]}
              format="DD/MM/yyyy"
              value={filters?.endDate ?? null}
              onChange={(value) => setFilters({ ...filters, endDate: value })}
              onBlur={() => getVacancies()}
            />
            <Select
              className="status-filter last-filter margin-right-8"
              id="status"
              label="Status"
              value={filters?.status ?? ""}
              onChange={(e) =>
                setFilters({ ...filters, status: e.target.value })
              }
              onBlur={() => getVacancies()}
              options={filterStatusList}
            />
            {Object.keys(filters)?.filter(
              (item) => !!filters[item as keyof typeof filters],
            )?.length > 1 && (
              <div className="align-center">
                <button
                  className="button-clean-filters"
                  type="button"
                  onClick={clearFilters}
                >
                  Limpar filtros
                </button>
              </div>
            )}
          </TableRow>
        )}
        <Table
          cantSelect
          emptyText="Não há vagas cadastradas"
          headCells={headCells}
          list={vacancies ?? []}
          onClick={(row) => history.push(`/company-vacancy/${row?._id}`)}
          onActive={onChangeStatus}
          onFinish={onChangeStatus}
          onPlay={onChangeStatus}
          onRemove={(row) => onRemove([row])}
          onReload={() => getVacancies()}
          onEdit={(row) => history.push(`company-vacancy-edit/${row?._id}`)}
          onShow={(row) => history.push(`/vacancy-description/${row._id}`)}
        />
      </Card>

      {width < 991 ? (
        <div className="grid-column-1-11-mobile grid-row-3 margin-top-32">
          <MessageVacancyManagement />

          <div>
            <Button
              text="Criar vaga"
              className="grid-column-1-11-mobile grid-row-3 request-vacancy margin-bottom-32 width-100-responsive"
              onClick={() => history.push("company-vacancy-new")}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="grid-row-3 grid-column-1-2">
            <p className="show-vacancies">
              Mostrando
              <span className="title-span">{vacancies?.length}</span>
              de
              <span className="title-span">{vacancies?.length}</span>
              resultados
            </p>
          </div>
          <div className="grid-row-3 grid-column-3-11 justify-center">
            <Pagination page={page} setPage={setPage} maxPage={maxPage} />
          </div>
        </>
      )}
    </Layout>
  );
};

export default memo(CompanyVacancies);
