/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import {
  AttachmentMedia,
  AttachmentPhoto,
  CardMedia,
  Draggable,
  IconButton,
  Input,
  ModalMedia,
  Select,
  TextArea,
} from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateCompany } from "../../../store/actions/companyActions";
import { RootState } from "../../../store/reducers";
import {
  FinancialType,
  SizeCompany,
  SizeTechArea,
} from "../../../types/constants/Company";
import api from "../../../services/api";
import { Media } from "../../../types/interfaces";
import { mediaType } from "../../../types/enumerators";
import { Delete } from "../../../../assets/icons";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
  setItem?: any;
}

const Infos = ({ setIsValid, setItem }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [editMediaIndex, setEditMediaIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [media, setMedia] = useState<Media>({} as Media);
  const dispatch = useDispatch();
  const company = useSelector((state: RootState) => state.companyState);
  const [_company, _setCompany] = useState(company);

  useEffect(() => {
    if (company?._id && !_company?._id) _setCompany(company);
  }, [_company?._id, company]);

  useEffect(() => {
    if (
      _company?.profile?.name &&
      _company?.profile?.site &&
      _company?.profile?.size &&
      _company?.profile?.sizeTechArea &&
      _company?.financialType &&
      _company?.profile.description
    )
      setIsValid(true);
    else setIsValid(false);
  }, [
    _company?.financialType,
    _company?.profile.description,
    _company?.profile?.name,
    _company?.profile?.site,
    _company?.profile?.size,
    _company?.profile?.sizeTechArea,
    setIsValid,
  ]);

  const handleOnBlur = useCallback(() => {
    if (
      _company?.profile?.name &&
      _company?.profile?.site &&
      _company?.profile?.size &&
      _company?.profile?.sizeTechArea &&
      _company?.financialType &&
      _company?.profile.description
    ) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(
            updateCompany({
              ..._company,
              profile: { ..._company?.profile },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [_company, dispatch, setIsValid]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) =>
      _setCompany({
        ..._company,
        profile: { ..._company?.profile, [prop]: event.target.value },
      }),
    [_company],
  );

  const handleChangeSelect = useCallback(
    (event: { target: { value: unknown } }, prop: string) =>
      _setCompany({
        ..._company,
        profile: { ..._company?.profile, [prop]: event.target.value as string },
      }),
    [_company],
  );

  const onChangeImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      api
        .post("/fileUpload", data)
        .then((result) => {
          _setCompany({
            ..._company,
            profile: { ..._company?.profile, logo: result?.data?.file_url.url },
          });
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    },
    [_company],
  );

  const handleChangeMedia = useCallback(
    (prop: string | number | symbol) =>
      (event: ChangeEvent<HTMLInputElement>) =>
        setMedia({ ...media, [prop]: event.target.value }),
    [media],
  );

  const saveMedia = useCallback(() => {
    const { profile } = _company;
    if (profile) {
      let { medias: newMedias } = profile;

      if (editMediaIndex !== -1) {
        newMedias[editMediaIndex] = media;
      } else if (newMedias?.length) newMedias.push(media);
      else newMedias = [media];

      _setCompany({
        ..._company,
        profile: { ...profile, medias: newMedias },
      });
      setMedia({} as Media);
      setEditMediaIndex(-1);
      setOpenModal(false);
    }
  }, [_company, editMediaIndex, media]);

  const handleLink = useCallback(
    (e: { key: string; target: { value: string } }) => {
      if (e.key === "Enter") {
        let { medias: newMedias } = _company?.profile ?? [];
        const link = {
          url: e.target.value,
          type: mediaType.LINK,
          order: newMedias?.length ?? 0,
        };

        if (newMedias?.length) newMedias.push(link);
        else newMedias = [link];

        e.target.value = "";
        _setCompany({
          ..._company,
          profile: { ..._company?.profile, medias: newMedias },
        });
      }
    },
    [_company],
  );

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setEditMediaIndex(-1);
    setMedia({} as Media);
  }, []);

  const handleMedia = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      api
        .post("/fileUpload", data)
        .then((result) => {
          setOpenModal(true);
          setMedia({
            ...media,
            url: result?.data?.file_url.url,
            type: fileList[0].type.includes("image")
              ? mediaType.PHOTO
              : mediaType.DOCUMENT,
          });
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    },
    [media],
  );

  const removeMedia = useCallback(
    (index: number) => {
      let { medias: oldMedias } = _company?.profile ?? [];
      oldMedias.splice(index, 1);
      oldMedias = oldMedias.map((item: Media, i: number) => ({
        ...item,
        order: i,
      }));
      _setCompany({
        ..._company,
        profile: { ..._company?.profile, medias: oldMedias },
      });
    },
    [_company],
  );

  const editMedia = useCallback((item: Media, index: number) => {
    setMedia(item);
    setEditMediaIndex(index);
    setOpenModal(true);
  }, []);

  const reorderMedias = useCallback(
    (items: Media[]) => {
      let { medias: newMedias } = _company;
      newMedias = items.map((item, index) => ({ ...item, order: index }));
      _setCompany({ ..._company, medias: newMedias });
      setItem({ ..._company, medias: newMedias });
    },
    [_company, setItem],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="grid-1-column column-gap-0 display-block">
      <AttachmentPhoto
        photo={_company?.profile?.logo}
        type="logo"
        onChange={onChangeImage}
        className="grid-row-1 grid-column-1-3"
      />
      <div className="flex-row-center grid-row-2 space-between display-block">
        {width < 991 && (
          <p className="p-mobile margin-top-24-mobile">Nome da empresa*</p>
        )}
        <Input
          id="name"
          label={width > 990 ? "Nome da empresa*" : ""}
          type="text"
          value={_company?.profile?.name ?? ""}
          onChange={(e) => handleChange(e, "name")}
          onBlur={handleOnBlur}
          className="width-100 margin-right-32"
        />
        {width < 991 && <p className="p-mobile margin-top-24-mobile">Site*</p>}
        <Input
          id="site"
          label={width > 990 ? "Site*" : ""}
          type="text"
          value={_company?.profile?.site ?? ""}
          onChange={(e) => handleChange(e, "site")}
          onBlur={handleOnBlur}
          className="width-100"
          disabled={!_company?.profile?.name}
        />
      </div>
      <div className="flex-row-center grid-row-3 space-between display-block">
        {width < 991 && (
          <p className="p-mobile margin-top-24-mobile">Tamanho da empresa</p>
        )}
        <Select
          id="size"
          label={width > 990 ? "Tamanho da empresa" : "Selecione"}
          onChange={(e) => handleChangeSelect(e, "size")}
          onBlur={handleOnBlur}
          value={_company?.profile?.size ?? ""}
          options={Object.keys(SizeCompany).map((key) => ({
            value: key,
            label: SizeCompany[key as keyof typeof SizeCompany],
          }))}
          className="width-100 margin-right-32"
          disabled={!_company?.profile?.name || !_company?.profile?.site}
        />
        {width < 991 && (
          <p className="p-mobile margin-top-24-mobile">
            Tamanho da área de Tecnologia
          </p>
        )}
        <Select
          id="sizeTechArea"
          label={width > 990 ? "Tamanho da área de Tecnologia" : "Selecione"}
          onChange={(e) => handleChangeSelect(e, "sizeTechArea")}
          onBlur={handleOnBlur}
          value={_company?.profile?.sizeTechArea ?? ""}
          options={Object.keys(SizeTechArea).map((key) => ({
            value: key,
            label: SizeTechArea[key as keyof typeof SizeTechArea],
          }))}
          className="width-100"
          disabled={
            !_company?.profile?.name ||
            !_company?.profile?.site ||
            !_company?.profile?.size
          }
        />
      </div>
      <div className="flex-row-center grid-row-4 space-between display-block">
        {width < 991 && (
          <p className="p-mobile margin-top-24-mobile">Tipo da empresa</p>
        )}
        <Select
          id="financialType"
          label={width > 990 ? "Tipo da empresa" : "Selecione"}
          onChange={(e) =>
            _setCompany({ ..._company, financialType: e.target.value })
          }
          onBlur={handleOnBlur}
          value={_company?.financialType ?? ""}
          options={Object.keys(FinancialType).map((key) => ({
            value: key,
            label: FinancialType[key as keyof typeof FinancialType],
          }))}
          className="width-100"
          disabled={
            !_company?.profile?.name ||
            !_company?.profile?.site ||
            !_company?.profile?.size ||
            !_company?.profile?.sizeTechArea
          }
        />
      </div>

      {width < 991 && (
        <p className="p-mobile margin-top-24-mobile">Descrição</p>
      )}
      <TextArea
        id="description"
        label={width > 990 ? "Descrição" : ""}
        maxLength={500}
        value={_company?.profile?.description ?? ""}
        onChange={(e) => handleChange(e, "description")}
        onBlur={handleOnBlur}
        className="grid-row-5 description-input width-100"
        disabled={
          !_company?.profile?.name ||
          !_company?.profile?.site ||
          !_company?.profile?.size ||
          !_company?.profile?.sizeTechArea ||
          !_company?.financialType
        }
      />
      <div
        className={`grid-row-6 ${
          !_company?.profile?.name ||
          !_company?.profile?.site ||
          !_company?.profile?.size ||
          !_company?.profile?.sizeTechArea ||
          !_company?.financialType ||
          !_company?.profile?.description
            ? "disabled"
            : ""
        }`}
      >
        <p className="title-midia ">Mídias</p>
        <p className="paragraph-midia">
          Adicione um link ou arquivos para documentos, fotos, vídeos e
          apresentações externas.
        </p>
        <div className="flex-row-center">
          <Input
            label="Link"
            type="text"
            helperText="Pressione Enter para adicionar"
            className="width-346 margin-right-24"
            onKeyDown={handleLink}
            onBlur={handleOnBlur}
          />
          {width > 990 && (
            <AttachmentMedia
              onChange={handleMedia}
              className="margin-bottom-16"
            />
          )}
        </div>
        {width < 991 && (
          <AttachmentMedia
            onChange={handleMedia}
            className="margin-bottom-16 margin-top-24-mobile"
          />
        )}
        {_company?.profile?.medias?.filter(
          (item: { type: string }) => item.type === mediaType.LINK,
        )?.length > 0 && (
          <div className="margin-top-32">
            {_company?.profile?.medias.map((item: Media, index: number) => {
              if (item.type === mediaType.LINK) {
                return (
                  <div className="flex-row-center" key={Math.random()}>
                    <a
                      href={
                        item?.url?.includes("http")
                          ? item?.url
                          : `http://${item?.url}`
                      }
                      className="link-midia"
                    >
                      {item.url}
                    </a>
                    <IconButton
                      icon={<Delete />}
                      onClick={() => removeMedia(index)}
                    />
                  </div>
                );
              }
              return <div />;
            })}
          </div>
        )}
        {_company?.profile?.medias?.filter(
          (item: { type: string }) => item.type !== mediaType.LINK,
        )?.length > 0 && (
          <Draggable
            items={_company?.profile?.medias}
            reorderItem={reorderMedias}
          >
            <div className="flex-row-center flex-wrap">
              {_company?.profile?.medias.map((item: Media, index: number) => {
                if (item.type !== mediaType.LINK) {
                  return (
                    <DraggableComponent
                      key={Math.random()}
                      draggableId={Math.random().toString()}
                      index={index}
                      isDragDisabled={
                        _company?.profile?.medias?.filter(
                          (innerItem: { type: string }) =>
                            innerItem.type !== mediaType.LINK,
                        )?.length === 1
                      }
                    >
                      {(innerProvided) => (
                        <div
                          ref={innerProvided.innerRef}
                          {...innerProvided.draggableProps}
                          {...innerProvided.dragHandleProps}
                        >
                          <CardMedia
                            item={item}
                            onEdit={() => editMedia(item, index)}
                            onRemove={() => removeMedia(index)}
                          />
                        </div>
                      )}
                    </DraggableComponent>
                  );
                }
                return <div />;
              })}
            </div>
          </Draggable>
        )}
      </div>
      <ModalMedia
        openModal={openModal}
        onClose={closeModal}
        onChange={handleChangeMedia}
        medias={media}
        onSave={saveMedia}
        disabled={!media.title ?? !media.description}
      />
    </div>
  );
};

export default memo(Infos);
