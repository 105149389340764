/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createRef,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Input, LabelInput, Select } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { RootState } from "../../../store/reducers";
import {
  Justification,
  HowToKnowReprograma,
  Target,
  CourseOfReprograma,
  VolunteerDescription,
} from "../../../types/constants/Course";
import {
  updateCourseSubscription,
  setCourseSubscription,
} from "../../../store/actions/courseActions";
import {
  getActiveUserClassVacancySubscription,
  getClassVacancyById,
} from "../../../services/functions";
import { ClassVacancy } from "../../../types/interfaces";
import { classVacancyTemplate } from "../../../types/enumerators";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

interface Item {
  renderCondition?: boolean;
  child: ReactNode;
}

const Reprograma = ({ setIsValid }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const subscription = useSelector((state: RootState) => state.courseState);
  const user = useSelector((state: RootState) => state.userState);
  const [_form, _setForm] = useState<any>(subscription?.form);
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();

  const {
    target,
    howToKnowReprograma,
    isReprogramaStudent,
    similarCourse,
    reprogramaStudentOtherClasses,
    isTeacher,
    isJuniorAssistant,
    canParticipateOnSelectWorkshop,
    learningITMotivation,
    isVolunteer,
    volunteerDescription,
  } = useMemo(() => _form, [_form]);

  const isDisabledEdv = useMemo(
    () =>
      classVacancy?.template === classVacancyTemplate.EDV &&
      (isTeacher === undefined || isJuniorAssistant === undefined),
    [classVacancy?.template, isJuniorAssistant, isTeacher],
  );

  const isDisabledLevelOne = useMemo(
    () =>
      classVacancy?.template === classVacancyTemplate.LEVEL_ONE &&
      similarCourse === undefined,
    [classVacancy?.template, similarCourse],
  );

  const isDisabled = useMemo(
    () => ({
      howToKnowReprograma: !target,
      isReprogramaStudent: !target || !howToKnowReprograma,
      reprogramaStudentOtherClasses:
        !target || !howToKnowReprograma || isReprogramaStudent === undefined,
      similarCourse:
        !target ||
        !howToKnowReprograma ||
        isReprogramaStudent === undefined ||
        (isReprogramaStudent && !reprogramaStudentOtherClasses),
      isTeacher:
        !target ||
        !howToKnowReprograma ||
        isReprogramaStudent === undefined ||
        (isReprogramaStudent && !reprogramaStudentOtherClasses) ||
        isDisabledLevelOne,
      isJuniorAssistant:
        !target ||
        !howToKnowReprograma ||
        isReprogramaStudent === undefined ||
        (isReprogramaStudent && !reprogramaStudentOtherClasses) ||
        isDisabledLevelOne ||
        isTeacher === undefined,
      canParticipateOnSelectWorkshop:
        !target ||
        !howToKnowReprograma ||
        isReprogramaStudent === undefined ||
        (isReprogramaStudent && !reprogramaStudentOtherClasses) ||
        isDisabledLevelOne ||
        isDisabledEdv,
      learningITMotivation:
        !target ||
        !howToKnowReprograma ||
        isReprogramaStudent === undefined ||
        (isReprogramaStudent && !reprogramaStudentOtherClasses) ||
        isDisabledLevelOne ||
        canParticipateOnSelectWorkshop === undefined ||
        isDisabledEdv,
      isVolunteer:
        !target ||
        !howToKnowReprograma ||
        isReprogramaStudent === undefined ||
        (isReprogramaStudent && !reprogramaStudentOtherClasses) ||
        isDisabledLevelOne ||
        canParticipateOnSelectWorkshop === undefined ||
        isDisabledEdv ||
        !learningITMotivation,
      volunteerDescription:
        !target ||
        !howToKnowReprograma ||
        isReprogramaStudent === undefined ||
        (isReprogramaStudent && !reprogramaStudentOtherClasses) ||
        isDisabledLevelOne ||
        canParticipateOnSelectWorkshop === undefined ||
        isDisabledEdv ||
        !learningITMotivation ||
        isVolunteer === undefined,
      finished:
        !target ||
        !howToKnowReprograma ||
        isReprogramaStudent === undefined ||
        (isReprogramaStudent && !reprogramaStudentOtherClasses) ||
        isDisabledLevelOne ||
        canParticipateOnSelectWorkshop === undefined ||
        isDisabledEdv ||
        !learningITMotivation ||
        isVolunteer === undefined ||
        (isVolunteer && !volunteerDescription),
    }),
    [
      target,
      howToKnowReprograma,
      isReprogramaStudent,
      reprogramaStudentOtherClasses,
      isDisabledLevelOne,
      isTeacher,
      isDisabledEdv,
      canParticipateOnSelectWorkshop,
      learningITMotivation,
      isVolunteer,
      volunteerDescription,
    ],
  );

  const handleOnBlur = useCallback(() => {
    if (!isDisabled.finished) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(
            updateCourseSubscription({
              ...subscription,
              form: { ..._form },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [isDisabled.finished, setIsValid, dispatch, subscription, _form]);

  const handleChange = useCallback(
    (value: string, prop: string) =>
      _setForm({
        ..._form,
        [prop]: value,
      }),
    [_form],
  );

  const handleChangeSelect = useCallback(
    (value: string, prop: string) => {
      if (prop === "reprogramaStudentOtherClasses")
        _setForm({
          ..._form,
          [prop]: [value],
        });
      else
        _setForm({
          ..._form,
          [prop]: value as string,
        });
    },
    [_form],
  );

  const handleChangeRadio = useCallback(
    (value: string, props) =>
      _setForm({
        ..._form,
        [props]: value === props,
      }),
    [_form],
  );

  const items = useMemo(
    (): Item[] => [
      {
        child: (
          <>
            <LabelInput
              marginBottom
              text="Você gostaria de fazer o curso online ou apenas a oficina de seleção?"
            />
            <Select
              id="target"
              label="Selecione"
              onChange={(e) => handleChangeSelect(e.target.value, "target")}
              onBlur={handleOnBlur}
              value={target ?? ""}
              options={Object.keys(Target)?.map((key) => ({
                value: key,
                label: Target[key as keyof typeof Target],
              }))}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.howToKnowReprograma}
              text="Como você conheceu a {reprograma}?"
            />
            <Select
              id="howToKnowReprograma"
              label="Selecione"
              className="width-select-course"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "howToKnowReprograma")
              }
              onBlur={handleOnBlur}
              value={howToKnowReprograma ?? ""}
              options={Object.keys(HowToKnowReprograma)?.map((key) => ({
                value: key,
                label:
                  HowToKnowReprograma[key as keyof typeof HowToKnowReprograma],
              }))}
              disabled={isDisabled.howToKnowReprograma}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.isReprogramaStudent}
              text="Você já foi aluna da {reprograma}?"
            />
            <RadioGroup
              aria-label="isReprogramaStudent"
              name="student"
              value={
                isReprogramaStudent !== undefined
                  ? isReprogramaStudent
                    ? "isReprogramaStudent"
                    : "isntReprogramaStudent"
                  : ""
              }
              onChange={(e) =>
                handleChangeRadio(e.target.value, "isReprogramaStudent")
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="isReprogramaStudent"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.isReprogramaStudent}
                />
                <FormControlLabel
                  value="isntReprogramaStudent"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.isReprogramaStudent}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        renderCondition: isReprogramaStudent,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.reprogramaStudentOtherClasses}
              text="Qual curso?"
            />
            <Select
              id="reprogramaStudentOtherClasses"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(
                  e.target.value,
                  "reprogramaStudentOtherClasses",
                )
              }
              onBlur={handleOnBlur}
              value={
                reprogramaStudentOtherClasses?.length
                  ? reprogramaStudentOtherClasses[0]
                  : ""
              }
              options={Object.keys(CourseOfReprograma)?.map((key) => ({
                value: key,
                label:
                  CourseOfReprograma[key as keyof typeof CourseOfReprograma],
              }))}
              className="grid-column-1-4 width-select-course"
              disabled={isDisabled.reprogramaStudentOtherClasses}
            />
          </>
        ),
      },
      {
        renderCondition:
          classVacancy?.template === classVacancyTemplate.LEVEL_ONE,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.similarCourse}
              text="Cursou/está cursando iniciativa similar à {reprograma}?"
            />
            <RadioGroup
              aria-label="similarCourse"
              name="student"
              value={
                similarCourse !== undefined
                  ? similarCourse
                    ? "similarCourse"
                    : "notSimilarCourse"
                  : ""
              }
              onChange={(e) =>
                handleChangeRadio(e.target.value, "similarCourse")
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="similarCourse"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.similarCourse}
                />
                <FormControlLabel
                  value="notSimilarCourse"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.similarCourse}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        renderCondition: classVacancy?.template === classVacancyTemplate.EDV,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.isTeacher}
              text="Você já foi/é professora da {reprograma}?"
            />
            <RadioGroup
              aria-label="isTeacher"
              name="teacher"
              value={
                isTeacher !== undefined
                  ? isTeacher
                    ? "isTeacher"
                    : "isntTeacher"
                  : ""
              }
              onChange={(e) => handleChangeRadio(e.target.value, "isTeacher")}
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="isTeacher"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.isTeacher}
                />
                <FormControlLabel
                  value="isntTeacher"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.isTeacher}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        renderCondition: classVacancy?.template === classVacancyTemplate.EDV,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.isJuniorAssistant}
              text="Você já foi/é monitora da {reprograma}?"
            />
            <RadioGroup
              aria-label="isJuniorAssistant"
              name="juniorAssistant"
              value={
                isJuniorAssistant !== undefined
                  ? isJuniorAssistant
                    ? "isJuniorAssistant"
                    : "isntJuniorAssistant"
                  : ""
              }
              onChange={(e) =>
                handleChangeRadio(e.target.value, "isJuniorAssistant")
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="isJuniorAssistant"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.isJuniorAssistant}
                />
                <FormControlLabel
                  value="isntJuniorAssistant"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.isJuniorAssistant}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.canParticipateOnSelectWorkshop}
              text="A Oficina é uma etapa obrigatória do processo seletivo, isso significa que caso você seja aprovada para esta etapa, precisará dedicar um sábado ou domingo inteiro aos estudos, conforme datas previstas no edital. Caso você não consiga participar, será desclassificada do processo. Você possui esta disponibilidade?"
            />
            <RadioGroup
              aria-label="canParticipateOnSelectWorkshop"
              name="canParticipateOnSelectWorkshop"
              value={
                canParticipateOnSelectWorkshop !== undefined
                  ? canParticipateOnSelectWorkshop
                    ? "canParticipateOnSelectWorkshop"
                    : "cantParticipateOnSelectWorkshop"
                  : ""
              }
              onChange={(e) =>
                handleChangeRadio(
                  e.target.value,
                  "canParticipateOnSelectWorkshop",
                )
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="canParticipateOnSelectWorkshop"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.canParticipateOnSelectWorkshop}
                />
                <FormControlLabel
                  value="cantParticipateOnSelectWorkshop"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.canParticipateOnSelectWorkshop}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.learningITMotivation}
              text="Por que você acredita que este curso é importante para você?"
            />
            <Select
              id="learningITMotivation"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "learningITMotivation")
              }
              onBlur={handleOnBlur}
              value={learningITMotivation ?? ""}
              options={Object.keys(Justification)?.map((key) => ({
                value: key,
                label: Justification[key as keyof typeof Justification],
              }))}
              className="grid-column-1-4 width-select-course"
              disabled={isDisabled.learningITMotivation}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.isVolunteer}
              text="Você já realizou algum tipo de trabalho voluntário?"
            />
            <RadioGroup
              aria-label="isVolunteer"
              name="isVolunteer"
              value={
                isVolunteer !== undefined
                  ? isVolunteer
                    ? "isVolunteer"
                    : "isntVolunteer"
                  : ""
              }
              onChange={(e) => handleChangeRadio(e.target.value, "isVolunteer")}
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="isVolunteer"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.isVolunteer}
                />
                <FormControlLabel
                  value="isntVolunteer"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.isVolunteer}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        renderCondition: isVolunteer,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.volunteerDescription}
              text="Qual atividade você desempenhou?"
            />
            <Select
              id="volunteerDescription"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "volunteerDescription")
              }
              onBlur={handleOnBlur}
              value={volunteerDescription ?? ""}
              options={Object.keys(VolunteerDescription)?.map((key) => ({
                value: key,
                label:
                  VolunteerDescription[
                    key as keyof typeof VolunteerDescription
                  ],
              }))}
              className="grid-column-1-4 width-select-course"
              disabled={isDisabled.volunteerDescription}
            />
          </>
        ),
      },
    ],
    [
      canParticipateOnSelectWorkshop,
      classVacancy?.template,
      handleChangeRadio,
      handleChangeSelect,
      handleOnBlur,
      howToKnowReprograma,
      isDisabled.canParticipateOnSelectWorkshop,
      isDisabled.howToKnowReprograma,
      isDisabled.isJuniorAssistant,
      isDisabled.isReprogramaStudent,
      isDisabled.isTeacher,
      isDisabled.isVolunteer,
      isDisabled.learningITMotivation,
      isDisabled.reprogramaStudentOtherClasses,
      isDisabled.similarCourse,
      isDisabled.volunteerDescription,
      isJuniorAssistant,
      isReprogramaStudent,
      isTeacher,
      isVolunteer,
      learningITMotivation,
      reprogramaStudentOtherClasses,
      similarCourse,
      target,
      volunteerDescription,
    ],
  );

  const itemsRefs = useMemo(
    () => items.map(() => createRef<HTMLDivElement>()),
    [items],
  );

  const getSubscription = useCallback(async () => {
    const subscriptions = await getActiveUserClassVacancySubscription(user._id);
    if (subscriptions?.data?.length)
      dispatch(
        setCourseSubscription(subscriptions?.data?.[0]) as unknown as Action,
      );
  }, [user._id, dispatch]);

  const getClassVacancy = useCallback(async () => {
    const response = await getClassVacancyById(subscription?.classVacancyId);
    if (response?.data) setClassVacancy(response?.data);
  }, [subscription?.classVacancyId]);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  useEffect(() => {
    if (!classVacancy && !!subscription?._id) getClassVacancy();
  }, [classVacancy, subscription?._id, getClassVacancy]);

  useEffect(() => {
    if (isDisabled.finished) setIsValid(false);
    else handleOnBlur();
  }, [handleOnBlur, isDisabled.finished, setIsValid]);

  return (
    <div className="grid-account-gap grid-account-gap-resposive">
      {items.map(
        ({ renderCondition, child }, index) =>
          (renderCondition ?? true) && (
            <div ref={itemsRefs[index]} className="grid-full-row">
              {child}
            </div>
          ),
      )}
      {classVacancy?.customQuestions &&
        classVacancy?.customQuestions?.length > 0 &&
        classVacancy?.customQuestions?.map((item, index) => (
          <div ref={itemsRefs[index]} className="grid-full-row">
            <LabelInput
              marginBottom
              disabled={isDisabled.volunteerDescription}
              text={item?.question ?? ""}
              optional={item?.binding}
            />
            {item?.type === "TEXT" && (
              <Input
                id={item?.code}
                label="Resposta"
                type="text"
                value={_form[item?.code ?? ""] ?? ""}
                onChange={(e) => handleChange(e.target.value, item?.code ?? "")}
                onBlur={handleOnBlur}
                disabled={isDisabled.volunteerDescription}
              />
            )}
            {item?.type === "RADIO" && (
              <RadioGroup
                aria-label={`is${item?.code}`}
                name={`is${item?.code}`}
                value={
                  _form[`is${item?.code}`] !== undefined
                    ? _form[`is${item?.code}`]
                      ? `is${item?.code}`
                      : `isnt${item?.code}`
                    : ""
                }
                onChange={(e) =>
                  handleChangeRadio(e.target.value, `is${item?.code}`)
                }
                onBlur={handleOnBlur}
              >
                <div>
                  <FormControlLabel
                    value={`is${item?.code}`}
                    control={<Radio />}
                    label="Sim"
                    className="margin-right-32"
                    disabled={isDisabled.volunteerDescription}
                  />
                  <FormControlLabel
                    value={`isnt${item?.code}`}
                    control={<Radio />}
                    label="Não"
                    disabled={isDisabled.volunteerDescription}
                  />
                </div>
              </RadioGroup>
            )}
            {item?.type === "SELECT" && (
              <Select
                id={item?.code}
                label="Selecione"
                onChange={(e) =>
                  handleChangeSelect(e.target.value, item?.code ?? "")
                }
                onBlur={handleOnBlur}
                value={_form[item?.code ?? ""] ?? ""}
                options={
                  item?.options?.map((option) => ({
                    value: option,
                    label: option,
                  })) ?? []
                }
                className="width-select-course"
              />
            )}
            {/* {item?.type === "AUTOCOMPLETE" && (
              <InputAutocomplete
                label="Resposta"
                helperText="Pressione Enter para adicionar"
                value={_form[item?.code]}
                onChange={handleChangeAutoComplete}
                options={item?.options ?? [""]}
                getOptionLabel={(option) => option}
              />
            )} */}
          </div>
        ))}
    </div>
  );
};

export default memo(Reprograma);
