/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  COMPANY_UPDATE,
  SET_COMPANY,
  CLEAR_COMPANY,
} from "../types/companyTypes";

const initialState: any = {
  profile: {},
};

export const companyReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
): any => {
  switch (type) {
    case SET_COMPANY:
      return { ...state, ...payload };
    case CLEAR_COMPANY:
      return { ...initialState };
    case COMPANY_UPDATE:
      return { ...state, ...payload };

    default:
      return state;
  }
};
