import React, { memo } from "react";
import {
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import LogoBlack from "../../../assets/images/LogoBlack.png";
import "./styles.scss";

const ConsentForm = (): JSX.Element => (
  <div className="consent-form">
    <div className="consent-form-logo">
      <img alt="logoBlack" className="reprograma-logo" src={LogoBlack} />
    </div>

    <p className="consent-form-title">
      Termo de Consentimento para Tratamento de Dados Pessoais e Dados Sensíveis
    </p>
    <ol>
      <li className="consent-form-subtitle">
        <span>Considerações Preliminares</span>

        <ol>
          <li>
            Considerando que a <b>REPROGRAMA</b>, associação privada, com sede
            na Avenida Paulista, nº 1439, Conjunto 12, Bela Vista, CEP
            01311-926, Município de São Paulo, Estado de São Paulo, inscrita no
            CNPJ/ME sob o nº. 26.549.913/0001-55 <ins>(“Reprograma”)</ins>, na
            condição de prestadora de serviço de ensino, necessita realizar
            Tratamento de Dados Pessoais do Titular para as finalidades
            especificadas no item 1.4 abaixo;
          </li>
          <li>Considerando que o Titular é candidato; e</li>
          <li>
            Considerando que nos termos da LGPD, o Tratamento de Dados Pessoais
            previsto requer a obtenção de consentimento prévio do Titular;
          </li>
          <li>
            Por meio deste Termo, solicitamos e formalizamos a coleta do
            consentimento para o Tratamento dos Dados Pessoais do Titular
            indicados na tabela abaixo:
          </li>
        </ol>

        <TableContainer>
          <Table stickyHeader aria-label="simple table" className="table">
            <TableHead className="table-head">
              <TableRow className="table-row">
                <TableCell>Finalidade</TableCell>
                <TableCell>Dados Pessoais</TableCell>
                <TableCell>Dados Sensíveis</TableCell>
                <TableCell>
                  Terceiros envolvidos e finalidade do Tratamento pelos
                  Terceiros
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className="table-row">
                <TableCell>
                  Procedimentos de processo seletivo nos casos em que a oferta
                  de vagas for insuficiente com relação à demanda, realização de
                  pré-matrícula e matrícula. Usamos as informações para
                  priorizar e viabilizar o acesso ao curso a pessoas em
                  vulnerabilidade social.
                </TableCell>
                <TableCell>
                  Nome, nome legal, pronome, data de nascimento, CPF, RG (ou
                  outro documento legal, como RNE), telefone(s), e-mail,
                  endereço residencial, redes sociais, se tem internet, se tem
                  computador pessoal, se está empregada, se está desempregada,
                  há quanto tempo, cargo/função, nível escolar, nível de inglês,
                  se obteve bolsa escolar, renda mensal individual, renda mensal
                  da família, quantas pessoas moram com a candidata, se tem
                  filhos, quantos e idade, se é/foi voluntária, vídeo e/ou áudio
                </TableCell>
                <TableCell>
                  Gênero, deficiência (CID), raça, orientação sexual
                </TableCell>
                <TableCell>
                  Empresas parceiras e financiadoras do projeto
                </TableCell>
              </TableRow>

              <TableRow className="table-row">
                <TableCell>
                  Estes Dados Pessoais são utilizados na Plataforma
                  &#123;reprograma&#125; para cadastro, criação de um perfil
                  individual, para interação com outros perfis/Empresas
                  Parceiras para o preenchimento de vagas, inserção em lista de
                  e-mails, divulgação das competências das Usuárias em busca de
                  oportunidades de emprego. Você pode escolher se quer criar o
                  perfil ou não. Para aquelas que optarem por fazer um perfil,
                  os Dados Pessoais (à exceção das credenciais de acesso e dados
                  cadastrais básicos vinculados ao perfil), a serem inseridos na
                  Plataforma, são todos facultativos. Somente os dados que
                  voluntariamente sejam informados por Você estarão disponíveis
                  para as Empresas Parceiras que utilizam a Plataforma. Observe
                  que alguns Dados Pessoais e Dados Pessoais Sensíveis são
                  opcionais, mas encorajamos seu fornecimento pois possuem a
                  finalidade de tornar seu perfil mais atrativo, fomentar as
                  medidas de diversidade, contribuir para formação de
                  indicadores de sucesso de empregabilidade por nós e facilitar
                  a interação com outros perfis e Empresas Parceiras para uma
                  vaga adequada de acordo com cada perfil.
                </TableCell>
                <TableCell>
                  Nome, CPF, E-mail, Endereço, Foto, Data da Nascimento,
                  Telefone (WhatsApp), Usuários Redes Sociais (Facebook,
                  Instagram, Linkedln, Tiktok, Twitter, GitHub), currículo,
                  experiência profissional, formação, idiomas e portfólio.
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>

              <TableRow className="table-row">
                <TableCell>
                  Para termos rastreabilidade quanto a efetivação de login na
                  Plataforma &#123;reprograma&#125;, por questão de segurança.
                  Ressaltamos que não visualizamos sua senha, a qual é
                  criptografada na Plataforma &#123;reprograma&#125; da seguinte
                  maneira: Em repouso: criptografia md5 + BCrypt Em transporte:
                  criptografia md5 + BCrypt + AE
                </TableCell>
                <TableCell>Login e senha</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>

              <TableRow className="table-row">
                <TableCell>
                  Cumprir com a obrigação imposta pelo Marco Civil da Internet
                  (Lei no 12.965/2014).
                </TableCell>
                <TableCell>
                  Informações de registro do servidor, quais sejam endereço IP
                  (internet protocol) do dispositivo, acesso a datas e horários.
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>

              <TableRow className="table-row">
                <TableCell>
                  Envio de comunicados institucionais sobre o status das
                  candidatas no processo seletivo dos cursos.
                </TableCell>
                <TableCell>Nome, e-mail, telefone.</TableCell>
                <TableCell />
                <TableCell />
              </TableRow>

              <TableRow className="table-row">
                <TableCell>
                  <ol className="roman-list">
                    <li>Possibilitar a inscrição e matrícula; </li>
                    <li>
                      Emitir declarações acadêmicas, certidões, certificados e
                      demais atividades das rotinas pedagógicas e
                      administrativas; e
                    </li>
                    <li>
                      Facilitar oportunidades de trabalho oferecidas pelas
                      Empresas Parceiras.
                    </li>
                  </ol>
                </TableCell>
                <TableCell>
                  Nome, data de nascimento, nome do responsável se menor de 18
                  anos, CPF, e-mail, telefone, fotografia, endereço, certidão de
                  nascimento, declaração de escolaridade, comprovante de
                  residência.
                </TableCell>
                <TableCell>
                  Raça, gênero, se membro da comunidade LGBTQIAP+
                </TableCell>
                <TableCell />
              </TableRow>

              <TableRow className="table-row">
                <TableCell>
                  <ol className="roman-list">
                    <li>Viabilizar o oferecimento dos Serviços; e</li>
                    <li>
                      Facilitar oportunidades de trabalho oferecidas pelas
                      Empresas Parceiras.
                    </li>
                  </ol>
                </TableCell>
                <TableCell>
                  Controle de frequência, avaliações, histórico no curso,
                  trabalhos acadêmicos, resultados e notas, informações sobre
                  aptidões.
                </TableCell>
                <TableCell />
                <TableCell>
                  Empresas parceiras e financiadoras do projeto
                </TableCell>
              </TableRow>

              <TableRow className="table-row">
                <TableCell>
                  Divulgar os eventos e atividades desempenhadas pela
                  &#123;reprograma&#125; para o público (uso não doméstico),
                  incluindo distribuição gratuita por meio de quaisquer mídias,
                  inclusive por meio do site/portal da &#123;reprograma&#125; na
                  Internet, suas redes sociais e a de parceiros e quaisquer
                  canais vinculados à &#123;reprograma&#125; e aos parceiros
                  (mediante termo autorizativo).
                </TableCell>
                <TableCell>
                  Nome, curso, foto, vídeo e voz, captados durante atividades do
                  Curso ou eventos da &#123;reprograma&#125;.
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>

              <TableRow className="table-row">
                <TableCell>
                  Comunicados oficiais sobre o curso para a turma via Grupos do
                  Telegram e, ao fim do curso, sobre novos cursos e
                  oportunidades (turmas adolescentes).
                </TableCell>
                <TableCell>
                  Nome, e-mail e confirmação de matrícula (acesso a cópia do RG
                  ou CPF e ao comprovante de residência).
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>

              <TableRow className="table-row">
                <TableCell>
                  Coleta de dados das alunas e/ou de seus responsáveis legais
                  (no caso de menores) nas inscrições para selecionar as alunas
                  que estão concorrendo a vaga enquanto mentora ou algum outro
                  projeto de extensão oferecido por Nós e pelas Empresas
                  Parceiras do projeto em questão.
                </TableCell>
                <TableCell>
                  Nome, nome legal, data de nascimento, RG, telefone(s), e-mail,
                  endereço residencial, cargo/função, nível escolar, se obteve
                  bolsa escolar, renda mensal individual, renda mensal da
                  família, quantas pessoas moram com a candidata, idade, se
                  é/foi voluntária, como ficou sabendo do curso
                </TableCell>
                <TableCell>
                  Gênero, deficiência (CID), raça, orientação sexual
                </TableCell>
                <TableCell>
                  Empresas parceiras e financiadoras do projeto
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </li>

      <li className="subtitle">
        <span>Especificações do Tratamento de Dados Pessoais</span>
        <ol>
          <li>
            <b>Conceitos.</b> O termo “Tratamento” utilizado neste Termo
            significa toda operação realizada com Dados Pessoais, como as que se
            referem a coleta, produção, recepção, classificação, utilização,
            acesso, reprodução, transmissão, distribuição, processamento,
            arquivamento, armazenamento, eliminação, avaliação ou controle da
            informação, modificação, comunicação, transferência, difusão ou
            extração; o termo “Dados Pessoais” significa toda informação que
            identifica ou pode identificar o Titular; e “Dados Sensíveis”
            significa quaisquer Dados Pessoais sobre origem racial ou étnica,
            convicção religiosa, opinião política, filiação a sindicato ou a
            organização de caráter religioso, filosófico ou político, dado
            referente à saúde ou à vida sexual, dado genético ou biométrico.
          </li>
          <li>
            <b>Duração do Tratamento:</b> 10 anos
          </li>
          <li>
            <b>Compartilhamento de Dados Pessoais:</b> Para a realização das
            finalidades indicadas no Item 1.4 acima, a Reprograma poderá
            compartilhar Dados Pessoais e Sensíveis do Titular com terceiros,
            conforme informado neste Termo e na Política de Privacidade
            disponível no sítio eletrônico (
            <a href="www.reprograma.com.br">www.reprograma.com.br</a>).
          </li>
          <li>
            <b>Direitos do Titular.</b>A Reprograma assegura aos Titulares o
            exercício dos seus direitos assegurados pela LGPD. Para saber quais
            são estes direitos e a forma de exercê-los, acesse a Política de
            Privacidade disponível no sítio eletrônico (
            <a href="www.reprograma.com.br" target="_blank">
              www.reprograma.com.br
            </a>
            ).
            <ol className="alphabetical-list">
              <li>
                <b>Não consentimento.</b> Na hipótese de o Titular não consentir
                com o Tratamento, considerando que o tratamento dos dados é
                indispensável para a execução das atividades, a Reprograma se
                reserva o direito de levar essa opção em consideração na
                avaliação do processo seletivo.
              </li>
              <li>
                <b>Revogação do consentimento.</b>Na hipótese de revogação de
                consentimento, a Reprograma deixará de realizar o Tratamento de
                Dados Pessoais para as finalidades previstas neste Termo.
                Entretanto, Tratamentos realizados até o momento da revogação do
                consentimento não serão afetados.
              </li>
              <li>
                Para dúvidas e solicitações do exercício de direitos o Titular
                deve entrar em contato através do e-mail dados@reprograma.com.br
              </li>
            </ol>
          </li>
        </ol>
      </li>
    </ol>

    <p>
      Caso <span> ACEITE</span> que os seus <span> Dados Pessoais</span> sejam
      tratados nos limites indicados neste termo, por favor, clique em{" "}
      <span>“SIM”</span>.
    </p>
    <p>
      Caso <span> ACEITE</span> que os seus <span> Dados Sensíveis</span> sejam
      tratados nos limites indicados neste termo, por favor, clique em{" "}
      <span>“SIM”</span>.
    </p>
  </div>
);

export default memo(ConsentForm);
