import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const ClosedIcon = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <circle cx="13.999" cy="14" r="11.667" fill="#939393" />
    <path
      stroke="#fff"
      strokeWidth="2"
      strokeLinecap="round"
      d="M8 14.167h12"
    />
  </svg>
);

export default ClosedIcon;
