/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment-timezone";
import React, { memo, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AvatarEmpty } from "../../../assets/customIcons";
import { Button, Modal, TextArea } from "../../components";
import { getUserById, updateAVacancy } from "../../services/functions";
import { RootState } from "../../store/reducers";
import { hasCorporateRole } from "../../utils";
import { User } from "../../types/interfaces";
import { setLoad } from "../../store/actions/configurationsActions";

interface Props {
  item: any;
  fetch: () => Promise<void>;
}

interface ParamsProps {
  placementVacancyId: string;
}

const CardProposal = ({ item, fetch }: Props): JSX.Element => {
  const { placementVacancyId } = useParams<ParamsProps>();

  const [messages, setMessages] = useState<any[] | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [proposal, setProposal] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useSelector((state: RootState) => state.userState);
  const company = useSelector((state: RootState) => state.companyState);

  const getMessages = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const messagesList = [];

    const stages = item?.stages[(item?.stages?.length ?? 0) - 1];
    if (stages?.messages) {
      for (let i = 0; i < stages?.messages?.length; i += 1) {
        const user = (await getUserById(stages?.messages[i]?.userId)) as User;

        messagesList.push({
          userName: user?.profile?.name,
          userPhoto: hasCorporateRole(user)
            ? company?.profile?.logo
            : user?.profile?.photo,
          date: moment(stages?.messages[i]?.date ?? "")
            .tz("America/Sao_Paulo")
            .format("DD [de] MMMM"),
          text: stages?.messages[i]?.text ?? "",
        });
      }
    }

    setMessages(messagesList);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, item?.stages, company?.profile?.logo]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const handleChange = useCallback((e) => setProposal(e.target.value), []);

  const onClose = useCallback(() => {
    setOpenModal(false);
    setProposal("");
  }, []);

  const onSend = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const stages = [...(item?.stages ?? [])];

    const newProposal = {
      text: proposal,
      date: moment().tz("America/Sao_Paulo").toISOString(),
      userId: currentUser._id,
    };

    const index = (stages?.length ?? 0) - 1;

    if (stages[index].messages && stages[index].messages?.length)
      stages[index].messages?.push(newProposal);
    else stages[index].messages = [newProposal];

    try {
      await updateAVacancy(item?.placementVacancyId, item?.userId, { stages });
      toast.success("Mensagem enviada com sucesso!");
    } catch (err) {
      toast.error("Ocorreu um erro ao enviar sua mensagem");
    }
    setOpenModal(false);
    setProposal("");
    await fetch();
    dispatch(setLoad(false) as unknown as Action);
  }, [currentUser._id, dispatch, fetch, item, proposal]);

  return (
    <div className="width-100 max-height-440 overflow-x-hidden">
      {messages && messages?.length > 0 && (
        <div className="div-card-comments">
          <p className="comment-user-name">{item?.userName ?? ""}</p>
          <p className="list-comment-date">
            {messages[(messages?.length ?? 0) - 1]?.date}
          </p>
        </div>
      )}
      {messages && messages?.length > 0 ? (
        messages?.map((msg) => (
          <div className="div-area-comment">
            {msg?.userPhoto ? (
              <img
                src={msg?.userPhoto}
                alt="Foto"
                className="company-avatar-empty-card"
              />
            ) : (
              <AvatarEmpty className="company-avatar-empty-card" />
            )}
            <p className="comment-text">{msg?.text}</p>
          </div>
        ))
      ) : (
        <p className="margin-left-24">Não há mensagens</p>
      )}
      <div className="divider-card" />
      <div className="flex flex-end padding-buttons display-block">
        <Button
          text="Voltar"
          className="width-144 margin-right-24"
          onClick={() => history.push(`/company-vacancy/${placementVacancyId}`)}
          outline
          appearance="secondary"
        />
        <Button
          text="Enviar proposta"
          className="width-192"
          onClick={() => setOpenModal(true)}
        />
      </div>
      <Modal
        headerAction
        textHeader="Converse com a candidata"
        open={openModal}
        onClose={onClose}
        bodyClassName="padding-0"
      >
        <div className="width-310">
          <TextArea
            id="proposal"
            label="Proposta*"
            maxLength={500}
            value={proposal}
            onChange={handleChange}
          />
          <div className="flex-row-center margin-top-32 flex-col-mobile gap-16-mobile">
            <Button
              text="Cancelar"
              outline
              className="width-100-mobile"
              onClick={onClose}
              appearance="secondary"
            />
            <Button
              text="Salvar"
              className="margin-left-32 width-100-mobile"
              onClick={onSend}
              disabled={!proposal}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default memo(CardProposal);
