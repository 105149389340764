import React, { memo } from "react";
import { useSelector } from "react-redux";
import {
  AvatarEmpty,
  CompanyAvatarEmpty,
} from "../../../../assets/customIcons";
import { RootState } from "../../../store/reducers";
import { User } from "../../../types/interfaces";
import { isCorporate as isCorporateUtils } from "../../../utils";

const ViewAccount = (): JSX.Element => {
  const user: User = useSelector((state: RootState) => state.userState);
  const { login, profile }: User = user;
  const isCorporate = isCorporateUtils(user);

  const company = useSelector((state: RootState) => state.companyState);

  const emptyClassName = "border-avatar-empty img-attachment-photo-small";

  const items = [
    { showAvatar: true, label: "Nome", value: profile?.name },
    { label: "E-mail", value: login?.identification },
    { label: "Senha", value: "••••••••" },
  ];

  return (
    <div className="grid-4-column gap-0">
      {items.map(({ label, showAvatar, value }) => (
        <div className="flex" key={Math.random()}>
          {showAvatar && (
            <div className="margin-right-24">
              {profile?.photo ? (
                <img
                  src={isCorporate ? company?.profile?.logo : profile?.photo}
                  width={48}
                  height={48}
                  alt="imagem"
                  className="account-profile"
                />
              ) : !isCorporate ? (
                <AvatarEmpty className={emptyClassName} />
              ) : (
                <CompanyAvatarEmpty className={emptyClassName} />
              )}
            </div>
          )}
          <div className="flex direction-column margin-right-24">
            <p className="title-view">{label}</p>
            <p className="view-account">{value}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(ViewAccount);
