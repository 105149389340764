/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  hasAdminClassBootcampRole,
  hasAdminClassWorkshopRole,
} from "../../utils";
import { RootState } from "../../store/reducers";
import { getClassById } from "../../services/functions";
import { Class as ClassProps } from "../../types/interfaces";
import { classStructure } from "../../types/enumerators";
import Errors from "../Errors";
import Class from "./Class";
import Workshop from "./Workshop";
import Loading from "../Loading";
import "./style.scss";

interface ParamsProps {
  id: string;
}

const AdminClassView = (): JSX.Element => {
  const [currentClass, setCurrentClass] = useState<ClassProps>();
  const [pageError, setPageError] = useState(false);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state: RootState) => state.userState);
  const { id } = useParams<ParamsProps>();

  const getClass = useCallback(async () => {
    const response = await getClassById(id);
    setCurrentClass(response?.data);
  }, [id]);

  useEffect(() => {
    if (!currentClass && id) getClass();
  }, [currentClass, getClass, id]);

  useEffect(() => {
    if (
      id &&
      user?._id &&
      (hasAdminClassWorkshopRole(user) || hasAdminClassBootcampRole(user)) &&
      (!user?.account?.classIds || !user?.account?.classIds?.includes(id))
    )
      setPageError(true);
  }, [id, user]);

  const isWorkshop = useMemo(
    () => currentClass?.classStructure === classStructure.WORKSHOP,
    [currentClass?.classStructure],
  );

  useEffect(() => {
    if (currentClass?._id) setLoading(false);
  }, [currentClass?._id]);

  return pageError ? (
    <Errors error={403} />
  ) : loading ? (
    <Loading />
  ) : isWorkshop ? (
    <Workshop />
  ) : (
    <Class />
  );
};

export default memo(AdminClassView);
