import React, { memo, MouseEventHandler, ReactNode } from "react";
import "./styles.scss";

interface Props {
  icon?: ReactNode;
  disabled?: boolean;
  isVacancies?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  text?: string;
  className?: string;
  endIcon?: boolean;
}

const IconButton = ({
  disabled,
  onClick,
  text,
  icon,
  className,
  isVacancies,
  endIcon,
}: Props): JSX.Element => (
  <button
    key={Math.random()}
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={`${disabled ? "disabled" : ""} ${
      className || ""
    } button-container-icon-button`}
  >
    {!endIcon && icon}
    {text &&
      (isVacancies ? (
        <p className="icon-button-component-vacancies">{text}</p>
      ) : (
        <p className="icon-button-component-title">{text}</p>
      ))}
    {endIcon && icon}
  </button>
);

export default memo(IconButton);
