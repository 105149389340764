/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Add } from "../../../../assets/icons";
import { IconButton, ViewEducation } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { RootState } from "../../../store/reducers";
import { updateUser } from "../../../store/actions/userActions";
import { ConfirmRemove, Items } from "../../Profile/Modal";
import { addCompanyToUser } from "../../../services/functions";

const EditEducation = (): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [removed, setRemoved] = useState({
    title: "",
    text: "",
    onRemove: () => setOpenModalRemove(false),
  });
  const [indexItem, setIndexItem] = useState(-1);
  const user = useSelector((state: RootState) => state.userState);
  const [_user, _setUser] = useState(user);
  const dispatch = useDispatch();

  const setCompanyToUser = useCallback(async () => {
    const response = await addCompanyToUser(user);
    _setUser({ ...response });
  }, [user]);

  useEffect(() => {
    if (user?._id && !_user?._id) setCompanyToUser();
  }, [_user, setCompanyToUser, user]);

  useEffect(() => {
    dispatch(
      setNextButton(() =>
        dispatch(
          updateUser({
            ..._user,
            curriculum: { ..._user?.curriculum },
          }) as unknown as Action,
        ),
      ) as unknown as Action,
    );
  }, [_user, _user?.curriculum, dispatch]);

  const reorderItem = useCallback(
    (items: any) => {
      const { curriculum } = _user;
      if (curriculum) {
        let { educations: newEducations } = curriculum;
        newEducations = items.map((item: any, index: number) => ({
          ...item,
          order: index,
        }));

        _setUser({
          ..._user,
          curriculum: { ...curriculum, educations: newEducations },
        });

        dispatch(
          setNextButton(() =>
            dispatch(
              updateUser({
                ..._user,
                curriculum: { ...curriculum, educations: newEducations },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      }
    },
    [_user, dispatch],
  );

  const onRemoveItem = useCallback(
    (index: number) => {
      let newItems = _user?.curriculum?.educations;
      newItems.splice(index, 1);
      newItems = newItems.map((innerItem: any, i: number) => ({
        ...innerItem,
        order: i,
      }));

      _setUser({
        ..._user,
        curriculum: { ..._user?.curriculum, educations: newItems },
      });

      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ..._user,
              curriculum: { ..._user?.curriculum, educations: newItems },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_user, dispatch],
  );

  const onEdit = useCallback((index: number) => {
    setIndexItem(index);
    setOpenModal(true);
  }, []);

  const onRemove = useCallback(
    (item, index: number) => {
      setRemoved({
        title: "Excluir formação",
        text: `Tem certeza de que deseja excluir esta formação na ${item?.name}?`,
        onRemove: () => onRemoveItem(index),
      });
      setOpenModalRemove(true);
    },
    [onRemoveItem],
  );

  const onClose = useCallback(() => {
    setOpenModal(false);
    setIndexItem(-1);
  }, []);

  return (
    <div>
      <IconButton
        icon={<Add />}
        text="Adicionar formação"
        onClick={() => setOpenModal(true)}
        className={`icon-button-primary width-100-mobile ${
          _user?.curriculum?.educations?.length > 0 ? "margin-bottom-40" : ""
        }`}
      />
      {_user?.curriculum?.educations?.length > 0 ? (
        <div>
          {_user?.curriculum?.educations?.length > 1 && (
            <p className="paragraph-reorder">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <ViewEducation
            className="padding-0"
            isMyProfile
            items={_user?.curriculum?.educations}
            onEdit={(_, index) => onEdit(index)}
            onMove={(items) => reorderItem(items)}
            onRemove={(item, index) => onRemove(item, index)}
          />
        </div>
      ) : (
        <p className="paragraph-not-registered">Não há formações cadastradas</p>
      )}
      <Items
        setUser={_setUser}
        index={indexItem}
        type="educations"
        openModal={openModal}
        onClose={onClose}
      />
      <ConfirmRemove
        titleHeader={removed?.title}
        text={removed?.text}
        onClose={() => setOpenModalRemove(false)}
        onRemove={removed?.onRemove}
        openModal={openModalRemove}
      />
    </div>
  );
};

export default memo(EditEducation);
