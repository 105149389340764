import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const FileProfile = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#7E459B"
      d="M26.582 11.573c-.014-.043-.026-.084-.044-.126-.065-.141-.146-.276-.261-.39l-8-8c-.115-.115-.25-.196-.39-.262-.04-.018-.083-.029-.126-.044-.112-.037-.227-.061-.345-.068-.03-.002-.055-.017-.083-.017H8c-1.471 0-2.667 1.196-2.667 2.667v21.333c0 1.47 1.196 2.667 2.667 2.667h16c1.47 0 2.666-1.196 2.666-2.667V11.999c0-.028-.014-.053-.017-.082-.007-.118-.03-.232-.067-.344zm-4.468-.907h-3.448V7.218l3.448 3.448zM8 26.666V5.333h8v6.666c0 .738.596 1.334 1.333 1.334H24l.002 13.333H8z"
    />
  </svg>
);

export default FileProfile;
