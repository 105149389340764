import React, { memo } from "react";
import { useSelector } from "react-redux";
import { LogoPurple } from "../../../assets/customIcons";
import { RootState } from "../../store/reducers";

import "./style.scss";

export interface LoadProps {
  loading?: boolean;
}

const Load = ({ loading }: LoadProps): JSX.Element => {
  const { isLoad } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  return isLoad || loading ? (
    <>
      <div className="container-card-load" />
      <div className="card-logo-animation">
        <LogoPurple widthIcon={140} heightIcon={140} />
        <p className="paragraph-card-load">Carregando...</p>
      </div>
    </>
  ) : (
    <div />
  );
};

export default memo(Load);
