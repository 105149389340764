/* eslint-disable @typescript-eslint/no-explicit-any */
import { Slider } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { InputAutocomplete, IconButton, Draggable } from "..";
import { getAutocomplete } from "../../services/functions";
import { autoCompleteType } from "../../types/enumerators";
import { Delete, Move } from "../../../assets/customIcons";
import "./styles.scss";
import { RootState } from "../../store/reducers";
import { registerAutoComplete } from "../../utils";

interface Props {
  setItem: any;
}

const styles = {
  slider: {
    width: "134px",
    marginLeft: "40px",
  },
};

const AddSkill = ({ setItem }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [skillsOptions, setSkillOptions] = useState<string[]>();
  const { curriculum } = useSelector((state: RootState) => state.userState);
  const [_curriculum, _setCurriculum] = useState(curriculum);

  useEffect(() => {
    if (Object.keys(curriculum)?.length && !Object.keys(_curriculum)?.length) {
      _setCurriculum({ ...curriculum });
    }
  }, [_curriculum, curriculum]);

  const loadSkills = useCallback(async () => {
    const response = await getAutocomplete(autoCompleteType.SKILL);

    const skillOptions = response?.map((option) => option?.name);

    setSkillOptions(skillOptions);
  }, []);

  useEffect(() => {
    if (!skillsOptions) loadSkills();
  }, [loadSkills, skillsOptions]);

  const updateLevel = useCallback(
    (level: number | number[], i: number) => {
      if (typeof level === "number") {
        const { skills: newSkills } = _curriculum;
        newSkills[i].level = level;
        _setCurriculum({ ..._curriculum, skills: newSkills });
        setItem(newSkills);
      }
    },
    [_curriculum, setItem],
  );

  const onUpdate = useCallback(
    async (value: string[] | string | null) => {
      let { skills: newSkills } = _curriculum;
      if (value && typeof value !== "string") {
        newSkills = value?.map((item) => {
          if (newSkills?.length) {
            const skill = newSkills?.find(
              (innerItem: { key: string }) => innerItem.key === item,
            );
            return {
              key: item,
              level: skill?.level || 1,
              order: (skill?.order || newSkills?.length) ?? 0,
            };
          }
          return { key: item, level: 1, order: 0 };
        });
      }

      registerAutoComplete(value, autoCompleteType.SKILL);
      _setCurriculum({ ..._curriculum, skills: newSkills });
      setItem(newSkills);
    },
    [_curriculum, setItem],
  );

  const removeSkill = useCallback(
    (i: number) => {
      let { skills: oldSkills } = _curriculum;
      oldSkills.splice(i, 1);
      oldSkills = oldSkills?.map((item: any, innerIndex: number) => ({
        ...item,
        order: innerIndex,
      }));
      _setCurriculum({ ..._curriculum, skills: oldSkills });
      setItem(oldSkills);
    },
    [_curriculum, setItem],
  );

  const reorderItem = useCallback(
    (items) => {
      let { skills: newSkills } = _curriculum;
      newSkills = items?.map((item: any, i: number) => ({
        ...item,
        order: i,
      }));
      _setCurriculum({ ..._curriculum, skills: newSkills });
      setItem(newSkills);
    },
    [_curriculum, setItem],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="add-skill">
      <InputAutocomplete
        multiple
        label="Habilidade"
        helperText="Pressione Enter para adicionar"
        className="skills-input"
        options={skillsOptions ?? [""]}
        getOptionLabel={(option) => option}
        value={
          _curriculum?.skills?.length
            ? _curriculum?.skills?.map((item: { key: string }) => item.key)
            : []
        }
        onChange={(values) => onUpdate(values)}
      />
      {_curriculum?.skills?.length > 1 && (
        <p className="p-class">Clique e arraste para ordenar como preferir</p>
      )}
      <Draggable items={_curriculum?.skills} reorderItem={reorderItem}>
        {_curriculum?.skills?.length > 0 &&
          _curriculum?.skills?.map(
            (
              item: {
                key: string;
                level: number;
              },
              i: number,
            ) => (
              <DraggableComponent
                key={Math.random()}
                draggableId={i.toString()}
                index={i}
                isDragDisabled={_curriculum?.skills?.length === 1}
              >
                {(innerProvided) => (
                  <div
                    ref={innerProvided.innerRef}
                    {...innerProvided.draggableProps}
                    {...innerProvided.dragHandleProps}
                  >
                    <div>
                      <div className="flex-row-center-margin margin-mobile-slider space-between-responsive">
                        <div className="width-30">
                          <div className="pills">{item.key}</div>
                        </div>
                        {width > 990 ? (
                          <div className="width-100">
                            <Slider
                              value={item.level}
                              step={1}
                              min={1}
                              max={3}
                              onChange={(_, value) => updateLevel(value, i)}
                              marks={[
                                { value: 1, label: "Básico" },
                                { value: 2, label: "Intermediário" },
                                { value: 3, label: "Avançado" },
                              ]}
                              style={styles.slider}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex">
                          <IconButton
                            icon={
                              <Delete
                                widthIcon={width < 991 ? 20 : 24}
                                heightIcon={width < 991 ? 20 : 24}
                              />
                            }
                            onClick={() => removeSkill(i)}
                          />
                          {_curriculum?.skills?.length > 1 && (
                            <IconButton
                              icon={
                                <Move
                                  widthIcon={width < 991 ? 20 : 24}
                                  heightIcon={width < 991 ? 20 : 24}
                                />
                              }
                              className="margin-left-8"
                            />
                          )}
                        </div>
                      </div>
                      {width < 991 ? (
                        <div className="width-100">
                          <Slider
                            className="slider-mobile"
                            value={item.level}
                            step={1}
                            min={1}
                            max={3}
                            onChange={(_, value) => updateLevel(value, i)}
                            marks={[
                              { value: 1, label: "Básico" },
                              { value: 2, label: "Intermediário" },
                              { value: 3, label: "Avançado" },
                            ]}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {i < (_curriculum?.skills?.length ?? 0) - 1 && (
                        <div className="divider-card-modal" />
                      )}
                    </div>
                  </div>
                )}
              </DraggableComponent>
            ),
          )}
      </Draggable>
    </div>
  );
};

export default memo(AddSkill);
