/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback } from "react";
import { Step, StepLabel, Stepper, Orientation } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { RootState } from "../../store/reducers";
import { setStepRegister } from "../../store/actions/stepRegisterActions";
import "./styles.scss";

interface Props {
  itemsMenu: Menu[];
  onClick?: any;
  className?: string;
  title?: string;
  bodyClassName?: string;
  orientation?: Orientation;
  steps?: {
    page: number;
    maxPageVisited?: number;
    maxMenuVisited?: number;
  };
  notNavigation?: boolean;
  smallScreen?: boolean;
}

interface Menu {
  icon?: JSX.Element;
  text?: string;
  id?: number;
}

const CardSteps = ({
  itemsMenu,
  onClick,
  className,
  title,
  bodyClassName,
  steps,
  notNavigation,
  smallScreen,
  orientation = "vertical",
}: Props): JSX.Element => {
  const currentSteps =
    steps ?? useSelector((state: RootState) => state.stepRegisterState);
  const dispatch = useDispatch();

  const onClickNavigation = useCallback(
    (item: { id?: number }) => {
      if (item?.id && item?.id <= currentSteps.maxPageVisited)
        dispatch(setStepRegister({ page: item?.id }) as unknown as Action);
      if (onClick) onClick(item?.id);
    },
    [currentSteps.maxPageVisited, dispatch, onClick],
  );

  return (
    <div className={`card-steps ${className || ""}`}>
      {title && (
        <>
          <p className="card-steps-title">{title}</p>
          <div className="divider-card-title" />
        </>
      )}
      <Stepper
        activeStep={currentSteps.maxMenuVisited}
        orientation={orientation}
      >
        {itemsMenu?.map((item: Menu) => (
          <Step
            key={Math.random()}
            onClick={() => (!notNavigation ? onClickNavigation(item) : {})}
            className={
              item?.id &&
              item?.id <= currentSteps.maxPageVisited &&
              !notNavigation
                ? "cursor-pointer"
                : "steps-not-navegation-completed"
            }
          >
            <StepLabel>
              <button
                className={`card-steps-step button-text-card-steps ${
                  bodyClassName ?? ""
                } ${
                  item?.id &&
                  item?.id <= currentSteps.maxPageVisited &&
                  !notNavigation
                    ? "cursor-pointer"
                    : ""
                }`}
                type="button"
              >
                {item?.icon}
                <p
                  className={`${bodyClassName ?? ""}  ${
                    smallScreen ? "margin-right-0" : ""
                  }`}
                >
                  {item?.text}
                </p>
              </button>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default memo(CardSteps);
