import React, { memo, useMemo } from "react";
import { Tooltip } from "@material-ui/core";
import { Card, CardJob } from "../../components";
import { Check, IconVideo, Pause, Unblock } from "../../../assets/icons";
import "./style.scss";

const Process = (): JSX.Element => {
  const data = useMemo(
    () => [
      {
        jobTitle: "HTML",
        jobPlace: "Front-End",
        managedBy: "EXTERNAL_VACANCY",
        status: "ACTIVE",
        photo:
          "https://medias.app.reprograma.com.br/612f8fb8cd95f723c8a6-html.png",
      },
      {
        jobTitle: "CSS",
        jobPlace: "Front-End",
        managedBy: "EXTERNAL_VACANCY",
        status: "ACTIVE",
        photo:
          "https://medias.app.reprograma.com.br/92387300ac9f79523cdc-css.png",
      },
      {
        jobTitle: "JavaSript",
        jobPlace: "Front-End",
        managedBy: "EXTERNAL_VACANCY",
        status: "ACTIVE",
        photo:
          "https://medias.app.reprograma.com.br/099c64e965e901250987-javascript.png",
      },
      {
        jobTitle: "React",
        jobPlace: "Front-End",
        managedBy: "EXTERNAL_VACANCY",
        status: "ACTIVE",
        photo:
          "https://medias.app.reprograma.com.br/96609cef67af1a7e6797-react.png",
      },
      {
        jobTitle: "Node",
        jobPlace: "Back-End",
        managedBy: "EXTERNAL_VACANCY",
        status: "ACTIVE",
        photo:
          "https://medias.app.reprograma.com.br/1fd95c23a9fe8bf008d0-node.png",
      },
      {
        jobTitle: "MongoDB",
        jobPlace: "Banco de Dados",
        managedBy: "EXTERNAL_VACANCY",
        status: "ACTIVE",
        photo:
          "https://medias.app.reprograma.com.br/5db2f07816edf1ee858f-mongodb.png",
      },
      {
        jobTitle: "BitBucket",
        jobPlace: "Repositório",
        managedBy: "EXTERNAL_VACANCY",
        status: "ACTIVE",
        photo:
          "https://medias.app.reprograma.com.br/09ce6976b697c643d70f-bitbucket.jpeg",
      },
      {
        jobTitle: "Git",
        jobPlace: "Versionamento de código",
        managedBy: "EXTERNAL_VACANCY",
        status: "ACTIVE",
        photo:
          "https://medias.app.reprograma.com.br/fd219c46e4920d3c3e74-git.png",
      },
      {
        jobTitle: "AWS",
        jobPlace: "Infra",
        managedBy: "EXTERNAL_VACANCY",
        status: "ACTIVE",
        photo:
          "https://medias.app.reprograma.com.br/0077b82e35019e9a8728-aws.jpeg",
      },
      {
        jobTitle: "Zeplin",
        jobPlace: "Design",
        managedBy: "EXTERNAL_VACANCY",
        status: "ACTIVE",
        photo:
          "https://medias.app.reprograma.com.br/f2f7b8959c674880ef15-zeplin.jpeg",
      },
      {
        jobTitle: "Miro",
        jobPlace: "Fluxograma",
        managedBy: "EXTERNAL_VACANCY",
        status: "ACTIVE",
        photo:
          "https://medias.app.reprograma.com.br/2dd6fb316c2eb2eb28e3-miro.png",
      },
    ],
    [],
  );

  const stages = useMemo(
    () => [
      { title: "Levantamento de requisitos", status: "FINISHED" },
      { title: "Fluxograma", status: "FINISHED" },
      { title: "Designer", status: "FINISHED" },
      { title: "Mapeamento de dados", status: "FINISHED" },
      { title: "Desenvolvimento", status: "FINISHED" },
      { title: "Testes", status: "FINISHED" },
      { title: "Implementação", status: "ACTIVE" },
    ],
    [],
  );

  return (
    <div className="admin-team-process">
      <div className="card-techs">
        {data.map((item) => (
          <CardJob key={Math.random()} data={item} />
        ))}
      </div>
      <div className="card-divider" />
      <Card className="card-stages" titleHeader="Etapas">
        {stages.map((item, idx) => (
          <div
            key={Math.random()}
            className={`step connect ${
              idx < stages.length - 1 ? "margin-bottom-24" : "margin-bottom-0"
            }`}
          >
            {item.status === "FINISHED" ? (
              <div className="svg-connect line">
                <Check />
              </div>
            ) : item.status === "ACTIVE" ? (
              <div className="icon-step-vacancy-subscription active">
                {idx + 1}
              </div>
            ) : (
              <div className="icon-step-vacancy-subscription">{idx + 1}</div>
            )}
            <div className="flex space-between align-items width-100">
              <span className="margin-right-24 title-step-view">
                {item.title}
              </span>
              <div className="divider-steps" />
              <div className="flex">
                <Tooltip title="Ativar">
                  <div
                    className={`button-attachment-media ${
                      item.status === "ACTIVE" && "disabled-btn"
                    }`}
                  >
                    <IconVideo className="steps-icons cursor-pointer" />
                  </div>
                </Tooltip>
                <Tooltip title="Pausar">
                  <div
                    className={`button-attachment-media cursor-pointer ${
                      item.status !== "ACTIVE" && "disabled-btn"
                    }`}
                  >
                    <Pause className="steps-icons cursor-pointer" />
                  </div>
                </Tooltip>
                <Tooltip title="Finalizar">
                  <div
                    className={`button-attachment-media cursor-pointer ${
                      item.status !== "ACTIVE" && "disabled-btn"
                    }`}
                  >
                    <Unblock className="steps-icons" />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </Card>
    </div>
  );
};

export default memo(Process);
