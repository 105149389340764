import React, { memo } from "react";
import { Card } from "../../../components";
import "../style.scss";

interface Props {
  totalList: number;
}

const Count = ({ totalList }: Props): JSX.Element => (
  <Card titleHeader="Candidaturas" className="grid-column-1-4 grid-row-2">
    <p className="title-vacancy margin-x-auto">{totalList}</p>
  </Card>
);

export default memo(Count);
