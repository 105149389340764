/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  AddCertificate,
  AddEducation,
  AddLanguage,
  AddPortfolio,
  AddSkill,
  AddWork,
  Button,
  Modal,
} from "../../../components";
import { RootState } from "../../../store/reducers";
import { updateUser } from "../../../store/actions/userActions";
import { addCompanyToUser } from "../../../services/functions";

interface Props {
  index: number;
  type: string;
  typePortfolio?: string;
  openModal: boolean;
  onClose: () => void;
  setUser: Dispatch<SetStateAction<any>>;
  certificate?: any;
  portfolio?: any;
}

const Items = ({
  setUser,
  index,
  type,
  typePortfolio,
  openModal,
  onClose,
  portfolio,
  certificate,
}: Props): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const [item, setItem] = useState({});
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();

  const onSave = useCallback(async () => {
    if (!certificate && !portfolio) {
      let newItem = user?.curriculum[type];
      if (type === "skills" || type === "languages") newItem = item;
      else if (index !== -1) newItem[index] = item;
      else if (newItem?.length) newItem.push(item);
      else newItem = [item];

      const newUser = {
        ...user,
        curriculum: { ...user?.curriculum, [type]: newItem },
      };
      const newUserWithCompany = await addCompanyToUser(newUser);

      setUser({
        ...newUserWithCompany,
      });

      dispatch(updateUser({ ...newUser }) as unknown as Action);
    } else
      setUser((prev: { curriculum: { [x: string]: any } }) => ({
        ...prev,
        curriculum: {
          ...(prev?.curriculum || {}),
          [type]: [
            ...(prev?.curriculum?.[type] || []).filter(
              (_: any, idx: number) => idx !== index,
            ),
            item,
          ],
        },
      }));

    onClose();
  }, [
    user,
    type,
    index,
    item,
    setUser,
    dispatch,
    onClose,
    certificate,
    portfolio,
  ]);

  const renderChildren = useMemo(() => {
    switch (type) {
      case "works":
        return (
          <AddWork
            work={index !== -1 ? user?.curriculum?.works[index] : {}}
            setItem={setItem}
            setIsValid={setIsValid}
          />
        );

      case "educations":
        return (
          <AddEducation
            education={
              index !== -1
                ? user?.curriculum?.educations[index]
                : { scholarship: false }
            }
            setItem={setItem}
            setIsValid={setIsValid}
          />
        );

      case "certificates":
        return (
          <AddCertificate
            certificate={
              index !== -1 && !certificate
                ? user?.curriculum?.certificates[index]
                : certificate
                ? certificate[index]
                : {}
            }
            setItem={setItem}
            setIsValid={setIsValid}
          />
        );

      case "portfolios":
        return (
          <AddPortfolio
            portfolio={
              index !== -1 && !portfolio
                ? user?.curriculum?.portfolios[index]
                : portfolio
                ? portfolio[index]
                : {}
            }
            type={index === -1 ? typePortfolio : ""}
            setItem={setItem}
            setIsValid={setIsValid}
          />
        );

      case "skills":
        return <AddSkill setItem={setItem} />;

      case "languages":
        return <AddLanguage setItem={setItem} />;

      default:
        return <div />;
    }
  }, [
    user?.curriculum,
    index,
    setItem,
    type,
    certificate,
    portfolio,
    typePortfolio,
  ]);

  const title = {
    works: "experiência",
    educations: "formação",
    certificates: "certificado",
    skills: "habilidade",
    portfolios: "Portfolio",
    languages: "idioma",
  };

  return (
    <div>
      {type ? (
        <Modal
          headerAction
          textHeader={`${index === -1 ? "Adicionar" : "Editar"} ${title[type]}`}
          open={openModal}
          onClose={onClose}
          bodyClassName="modal-profile height-fit-content"
        >
          {renderChildren}
          <div className="margin-left-auto margin-top-48 flex-end flex-direction-column-reverse margin-top-20-mobile">
            <Button
              text="Cancelar"
              className="width-200 margin-right-32 margin-right-0-mobile margin-top-20-mobile margin-bottom-20-mobile width-100-responsive"
              onClick={onClose}
              outline
              appearance="secondary"
            />
            <Button
              text="Salvar"
              className="width-100-responsive width-200"
              onClick={onSave}
              disabled={!isValid && type !== "skills" && type !== "languages"}
            />
          </div>
        </Modal>
      ) : (
        <div />
      )}
    </div>
  );
};

export default memo(Items);
