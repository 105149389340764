import React, { memo, useMemo } from "react";
import { Card, Table } from "../../components";
import "./style.scss";

const Team = (): JSX.Element => {
  const list = useMemo(
    () => [
      {
        name: "Camila",
        position: "Estagiária de Dados",
        status: "ACTIVE",
      },
      {
        name: "Carol",
        position: "Desenvolvedora Front-End Pl",
        status: "FINISHED",
      },
      {
        name: "Danilo",
        position: "Consultor",
        status: "ACTIVE",
      },
      {
        name: "Larissa",
        position: "Analista de dados",
        status: "ACTIVE",
      },
      {
        name: "Leandro",
        position: "Desenvolvedor Back-End Pl",
        status: "FINISHED",
      },
      {
        name: "Letícia",
        position: "Desenvolvedora Front-End Jr",
        status: "FINISHED",
      },
      {
        name: "Marcus",
        position: "Designer",
        status: "FINISHED",
      },
      {
        name: "Natália",
        position: "Tech Lead",
        status: "ACTIVE",
      },
      {
        name: "Nayla",
        position: "Desenvolvedora Front-End Jr",
        status: "ACTIVE",
      },
      {
        name: "Priscilla",
        position: "P.O.",
        status: "FINISHED",
      },

      {
        name: "Raquel",
        position: "Desenvolvedora Front-End Jr",
        status: "FINISHED",
      },
      {
        name: "Ylana",
        position: "Desenvolvedora Front-End Jr",
        status: "ACTIVE",
      },
    ],
    [],
  );

  const headCells = useMemo(
    () => [
      {
        id: "name",
        label: "Nome",
      },
      {
        id: "position",
        label: "Função",
      },
      {
        id: "status",
        label: "Status",
      },
    ],
    [],
  );

  return (
    <Card
      className=" grid-column-1-13 grid-row-1"
      bodyClassName="padding-0"
      titleHeader="Equipe"
    >
      <Table
        cantSelect
        emptyText="Não há pessoas cadastradas"
        headCells={headCells}
        list={list}
      />
    </Card>
  );
};

export default memo(Team);
