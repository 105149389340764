import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const Linkedin = ({ widthIcon, heightIcon, className }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "40"}
    height={heightIcon ?? "40"}
    className={className}
    fill="none"
    viewBox="0 0 48 48"
  >
    <circle cx="24" cy="24" r="20" fill="#1D65C3" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M17.962 14.181c0 1.426-1.155 2.582-2.58 2.582-1.426 0-2.582-1.156-2.582-2.582 0-1.425 1.155-2.58 2.581-2.58s2.581 1.155 2.581 2.58zm.115 4.794H12.8V35.2h5.277V18.975zm14.376.19c-.019-.005-.037-.012-.056-.018-.036-.012-.073-.024-.112-.035-.071-.016-.142-.03-.214-.041-.28-.056-.587-.096-.947-.096-3.077 0-5.028 2.238-5.672 3.102v-3.102h-5.277V35.2h5.277v-8.85s3.989-5.555 5.672-1.475V35.2H36.4V24.251c0-2.451-1.68-4.494-3.947-5.086z"
      clipRule="evenodd"
    />
  </svg>
);

export default Linkedin;
