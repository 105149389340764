import React, { MouseEventHandler } from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const Help = ({
  widthIcon,
  heightIcon,
  onClick,
  className,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    fill="none"
    viewBox="0 0 32 32"
    onClick={onClick}
    className={className}
  >
    <path
      fill="#606060"
      fillRule="evenodd"
      d="M4 16C4 9.376 9.376 4 16 4s12 5.376 12 12-5.376 12-12 12S4 22.624 4 16zm12 9.6c-5.292 0-9.6-4.308-9.6-9.6s4.308-9.6 9.6-9.6 9.6 4.308 9.6 9.6-4.308 9.6-9.6 9.6zm.017-15.9c3.226.132 4.63 3.372 2.713 5.802-.256.307-.592.56-.912.802-.307.23-.6.452-.794.698-.404.498-.404 1.098-.404 1.698h-1.809c0-1.002 0-1.848.404-2.448.271-.408.732-.703 1.156-.974.2-.127.39-.25.55-.376 1.46-1.344 1.098-3.246-.904-3.402-.48 0-.94.19-1.279.527-.339.338-.53.796-.53 1.273H12.4c0-.955.381-1.87 1.06-2.546.678-.675 1.598-1.054 2.557-1.054zm-1.206 10.8h1.809v1.8h-1.809v-1.8z"
      clipRule="evenodd"
    />
  </svg>
);

export default Help;
