import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { Banner } from "..";
import "./styles.scss";

const NotificationResearchMobile = (): JSX.Element => {
  const history = useHistory();

  const secondaryButton = {
    text: "Ver pesquisa",
    onClick: () => history.push("/all-research"),
  };

  return (
    <div className="new-search-mobile">
      <Banner
        secondaryButton={secondaryButton}
        message="Temos uma nova pesquisa disponível para você"
        iconClose
      />
    </div>
  );
};
export default memo(NotificationResearchMobile);
