import React, { memo } from "react";
import "./style.scss";

const Status = (): JSX.Element => (
  <>
    <p className="title-card-course">Sua inscrição</p>
    <div className="divider-card-title" />
    <p className="description">
      Olá! Você já concluiu esta fase do nosso processo seletivo :)
    </p>
    <p className="description">
      Acompanhe seu e-mail que te avisaremos das próximas etapas. Lembre-se de
      sempre checar o spam.
    </p>
    <p className="description">
      Se ainda tiver alguma dúvida entre em contato com a gente pelo e-mail
      equipe@reprograma.com.br
    </p>
  </>
);

export default memo(Status);
