import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const Experiences = ({
  widthIcon,
  heightIcon,
  className,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    className={className}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#7E459B"
      fillRule="evenodd"
      d="M26.583 11.573l-.012-.037c-.01-.03-.02-.059-.032-.088-.065-.141-.146-.276-.261-.39l-8-8c-.115-.115-.25-.197-.39-.262-.028-.013-.057-.022-.086-.031l-.04-.013c-.112-.037-.227-.061-.345-.068-.014-.001-.027-.005-.039-.009-.015-.004-.029-.008-.044-.008H8.001c-1.471 0-2.667 1.196-2.667 2.666v21.334c0 1.47 1.196 2.666 2.667 2.666h16c1.47 0 2.666-1.196 2.666-2.666V12c0-.015-.004-.03-.008-.044-.004-.012-.008-.025-.009-.039-.007-.117-.03-.232-.067-.344zm-4.468-.906h-3.448V7.219l3.448 3.448zm-14.114 16V5.333h8V12c0 .737.596 1.333 1.333 1.333h6.667l.002 13.334H8.001zm2.666-9c0-.553.448-1 1-1h8.667c.552 0 1 .447 1 1 0 .552-.448 1-1 1h-8.667c-.552 0-1-.448-1-1zm1 4.333c-.552 0-1 .448-1 1s.448 1 1 1h8.667c.552 0 1-.448 1-1s-.448-1-1-1h-8.667z"
      clipRule="evenodd"
    />
  </svg>
);

export default Experiences;
