import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Action } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Delete } from "../../../../assets/icons";
import {
  AttachmentMedia,
  Button,
  Card,
  CardMedia,
  Header,
  IconButton,
  Input,
  Layout,
} from "../../../components";
import api from "../../../services/api";
import {
  getClassVacancyById,
  sendCandidateMediaEmail,
  upClassVacancySubscriptionStage,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import { createCourseSubscriptionLog } from "../../../services/historicFunctions";
import { RootState } from "../../../store/reducers";
import { CourseSubscriptionState } from "../../../types/constants/Historic";
import { CourseSubscriptionAction } from "../../../types/enumerators/actions/CourseSubscriptionAction";
import { classVacancyTemplate, mediaType } from "../../../types/enumerators";
import { validateCourseSubscription } from "../../../utils";
import { setLoad } from "../../../store/actions/configurationsActions";
import "../style.scss";

interface Props {
  setPage: Dispatch<SetStateAction<number>>;
}

const Attachment = ({ setPage }: Props): JSX.Element => {
  const [data, setData] = useState<FormData | null>(null);
  const [link, setLink] = useState<string>("");
  const [source, setSource] = useState<string>("");
  const [template, setTemplate] = useState<keyof typeof classVacancyTemplate>();
  const [title, setTitle] = useState<string>("");
  const user = useSelector((state: RootState) => state.userState);
  const courseSubscription = useSelector(
    (state: RootState) => state.courseState,
  );

  const getTemplateAndTitle = useCallback(async () => {
    const reponseClassVacany = await getClassVacancyById(
      courseSubscription?.classVacancyId,
    );
    const temp = reponseClassVacany?.data?.template;
    const titl = reponseClassVacany?.data?.title;
    setTemplate(temp);
    setTitle(titl);
  }, [courseSubscription?.classVacancyId]);

  useEffect(() => {
    if (!template || !title) getTemplateAndTitle();
  }, [getTemplateAndTitle, template, title]);

  const sendLog = useCallback(() => {
    createCourseSubscriptionLog(
      user._id,
      CourseSubscriptionAction.COURSE_SUBSCRIPTION_ATTACHMENT_SUBMITTED,
      CourseSubscriptionState.ATTACHMENT_SUBMISSION,
      CourseSubscriptionState.ATTACHMENT_SUBMITTED,
    );
  }, [user._id]);

  const handleMedia = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList || !fileList?.length) return;

    const newData = new FormData();

    newData.append("file", fileList[0]);

    setData(newData);

    const blobURL = URL.createObjectURL(fileList[0]);
    setSource(blobURL);
  }, []);

  const handleRemove = useCallback(() => {
    setData(null);
    setSource("");
  }, []);

  const dispatch = useDispatch();
  const handleSubmit = useCallback(async () => {
    if (validateCourseSubscription(courseSubscription, template, user)?.length)
      return;

    if (link) {
      dispatch(setLoad(true) as unknown as Action);
      await updateClassVacancySubscriptionById(courseSubscription?._id ?? "", {
        form: {
          ...courseSubscription.form,
          media: {
            url: link,
            type: mediaType.LINK,
          },
        },
      });

      await upClassVacancySubscriptionStage(courseSubscription?._id ?? "");
      await sendCandidateMediaEmail(
        [user?.profile?.email ?? user?.login?.identification],
        title,
        "mídia",
      );
      setPage(4);
      sendLog();
    } else {
      dispatch(setLoad(true) as unknown as Action);
      api
        .post("/fileUpload", data)
        .then(async (result) => {
          await updateClassVacancySubscriptionById(
            courseSubscription?._id ?? "",
            {
              form: {
                ...courseSubscription.form,
                media: {
                  url: result?.data?.file_url.url,
                  type: data?.get("file")?.type?.includes("audio")
                    ? mediaType.AUDIO
                    : mediaType.VIDEO,
                },
              },
            },
          );
          await upClassVacancySubscriptionStage(courseSubscription?._id ?? "");
          await sendCandidateMediaEmail(
            [user?.profile?.email ?? user?.login?.identification],
            title,
            "mídia",
          );
          setPage(4);
          sendLog();
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        })
        .finally(() => dispatch(setLoad(false) as unknown as Action));
    }

    dispatch(setLoad(false) as unknown as Action);
  }, [
    courseSubscription,
    data,
    dispatch,
    link,
    sendLog,
    setPage,
    template,
    title,
    user,
  ]);

  const handleLink = useCallback(
    (e: { key: string; target: { value: string } }) => {
      if (e.key === "Enter") {
        setLink(e.target.value);
        e.target.value = "";
      }
    },
    [],
  );

  const handleOnBlur = useCallback(
    (e: { target: { value: string } }) => {
      if (link === "") {
        setLink(e.target.value);
        e.target.value = "";
      }
    },
    [link],
  );

  return (
    <div className="sending-container">
      <Layout>
        <Header
          back
          onClickBack={() => {
            createCourseSubscriptionLog(
              user._id,
              CourseSubscriptionAction.COURSE_SUBSCRIPTION_ATTACHMENT_BACK,
              CourseSubscriptionState.ATTACHMENT_SUBMISSION,
              CourseSubscriptionState.FORM_SUBMITTED,
            );
            setPage(1);
          }}
        />
        <div className="content-sending">
          <Card
            titleHeader="Envio de vídeo/áudio"
            className="border-card grid-row-3 card-attachment-mobile"
          >
            <div>
              <p className="paragraph-primary-link">
                Se você fez upload no YouTube, cole o link de compartilhamento
                no campo abaixo:
              </p>
              <p className="paragraph-secondary-link">
                <span className="attention">Atenção!</span> Deixe seu vídeo como
                “não listado” pois não conseguimos ver os privados.
              </p>
              <div className="link-input-video">
                <Input
                  disabled={!!data || !!link}
                  label="Link"
                  type="text"
                  helperText="Pressione Enter para adicionar"
                  onKeyDown={handleLink}
                  onBlur={handleOnBlur}
                  className="input-link"
                />
                <div className="display-flex">
                  <a
                    href={link}
                    target="_blank"
                    className="a-class"
                    rel="noreferrer"
                  >
                    {link}
                  </a>
                  {link && (
                    <IconButton icon={<Delete />} onClick={() => setLink("")} />
                  )}
                </div>
              </div>
              <div>
                <p className="paragraph-primary-file">
                  Mas se preferir, você pode fazer upload do arquivo aqui mesmo.
                </p>
                <p className="paragraphy-secondary-file">
                  Seu vídeo/áudio precisa ter no máximo 10MB
                </p>
                <div className={link ? "disabled" : ""}>
                  <AttachmentMedia
                    className="lable-media"
                    onChange={handleMedia}
                    type="video/*, audio/*"
                    disabled={!!link || !!data}
                  />
                </div>
                {data && (
                  <CardMedia
                    cantEdit
                    item={{
                      title: data?.get("file")?.name,
                      originType: data?.get("file")?.type,
                      type: data?.get("file")?.type?.includes("video")
                        ? mediaType.VIDEO
                        : mediaType.AUDIO,
                      url: source,
                    }}
                    onRemove={handleRemove}
                  />
                )}
              </div>
            </div>
          </Card>
        </div>
        <Button
          text="Enviar"
          className="button-sending"
          onClick={handleSubmit}
          disabled={!link && !data}
        />
      </Layout>
    </div>
  );
};

export default memo(Attachment);
