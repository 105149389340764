import React, {
  ChangeEventHandler,
  memo,
  MouseEventHandler,
  ReactNode,
} from "react";
import {
  Checkbox as CheckboxComponent,
  FormControlLabel,
} from "@material-ui/core";

interface Props {
  id: string;
  disabled?: boolean;
  label?: string | ReactNode;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  onClick?: MouseEventHandler<HTMLLabelElement>;
}

const Checkbox = ({
  id,
  disabled,
  label,
  checked,
  onChange,
  className,
  onClick,
}: Props): JSX.Element => (
  <FormControlLabel
    className={className}
    disabled={disabled}
    onClick={onClick}
    control={
      <CheckboxComponent checked={checked} onChange={onChange} name={id} />
    }
    label={label}
  />
);

export default memo(Checkbox);
