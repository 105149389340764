import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Subjects = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#7E459B"
      stroke="#7E459B"
      strokeWidth=".3"
      d="M25.323 13.647c.953-.716 1.571-1.856 1.571-3.137 0-2.163-1.759-3.922-3.921-3.922 0-2.162-1.76-3.921-3.922-3.921-1.281 0-2.421.618-3.137 1.571-.716-.953-1.856-1.571-3.137-1.571-2.163 0-3.922 1.759-3.922 3.921-2.162 0-3.921 1.76-3.921 3.922 0 1.281.618 2.421 1.57 3.137-.952.716-1.57 1.856-1.57 3.137 0 2.163 1.759 3.922 3.921 3.922h.227c.064.33.184.65.362.948.398.667 1.034 1.134 1.764 1.317v3.225h1.569v3.137h6.274v-3.137h1.569v-3.318c.68-.239 1.273-.703 1.671-1.331.086-.137.162-.278.226-.422l.711-.428c2.044-.133 3.666-1.836 3.666-3.913 0-1.281-.617-2.42-1.571-3.137zM10.791 20.85c-.239-.4-.26-.888-.057-1.304l1.179-2.418-1.41-.688-1.18 2.418c-.044.091-.082.185-.117.28h-.35c-1.298 0-2.354-1.056-2.354-2.354 0-1.297 1.056-2.353 2.353-2.353v-1.568c-1.297 0-2.353-1.056-2.353-2.353 0-1.298 1.056-2.353 2.353-2.353v1.569h1.569V6.588c0-1.297 1.055-2.353 2.353-2.353 1.297 0 2.353 1.056 2.353 2.353V21.49h-3.216l-.062-.001c-.443-.018-.83-.25-1.06-.639zm6.692 6.915h-3.138v-1.569h3.138v1.569zm1.568-3.138h-6.274V23.06h6.274v1.568zm3.922-5.49h-.021l-1.66-2.757-1.344.809 1.164 1.933c.209.52.156 1.109-.146 1.585-.303.478-.796.763-1.354.782l-.064.001h-2.85V6.588c0-1.297 1.056-2.353 2.353-2.353 1.298 0 2.353 1.056 2.353 2.353v3.137h1.569V8.157c1.297 0 2.353 1.055 2.353 2.353 0 1.297-1.056 2.353-2.353 2.353v1.568c1.297 0 2.353 1.056 2.353 2.353 0 1.298-1.056 2.353-2.353 2.353z"
    />
  </svg>
);

export default Subjects;
