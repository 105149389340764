import React, { memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";
import { PlanCompanyStatus } from "../../../types/constants/Company";

const ViewPlan = (): JSX.Element => {
  const company = useSelector((state: RootState) => state.companyState);

  return (
    <div>
      <div className="grid-1-column flex-row-center flex-wrap">
        <div className="flex-col space-right">
          <p className="paragraph-view">Seu plano é</p>
          <p className="view-bold">{company?.plan?.name ?? ""}</p>
        </div>
        <div className="flex-col space-right">
          <p className="paragraph-view">Status</p>
          <p className="view-bold">
            {
              PlanCompanyStatus[
                (company?.plan?.name ??
                  "ACTIVE") as keyof typeof PlanCompanyStatus
              ]
            }
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(ViewPlan);
