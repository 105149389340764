/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eval */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Button, IconButton, ModalSendInvite } from "..";
import {
  Edit,
  FacebookProfile,
  GitHubProfile,
  InstagramProfile,
  LinkedinLogin,
  TikTokProfile,
  TwitterProfile,
} from "../../../assets/icons";
import {
  CompanyAvatarEmpty,
  AvatarEmpty,
  SocialMedia,
  Avatar,
  PhoneBlack,
} from "../../../assets/customIcons/index";
import "./styles.scss";
import { RootState } from "../../store/reducers";
import { isCorporate as isCorporateUtils } from "../../utils";
import {
  companySocialMedias,
  userSocialMediaType,
} from "../../types/enumerators";

interface Props {
  isMyProfile?: boolean;
  position?: string;
  name: string;
  userId?: string;
  goal?: string;
  avatar?: string;
  cover?: string;
  localization: string;
  proposal?: string;
  changing?: string;
  freelancer?: string;
  publicProfile?: string[];
  type: string;
  pageSelected?: string;
  site?: string;
  socialMedias?: any[];
  setPageSelected?: Dispatch<SetStateAction<string>>;
  editAvatar?: () => void;
  editCover?: () => void;
  onEdit?: () => void;
  onClickGeneralData?: () => void;
  onClickContact?: () => void;
  onClickContactSocial?: () => void;
}

const InfosProfile = ({
  isMyProfile,
  position,
  avatar,
  cover,
  name,
  userId,
  goal,
  socialMedias,
  publicProfile,
  localization,
  proposal,
  changing,
  freelancer,
  type,
  pageSelected,
  site,
  setPageSelected,
  editAvatar,
  editCover,
  onEdit,
  onClickGeneralData,
  onClickContact,
  onClickContactSocial,
}: Props): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const isCorporate = isCorporateUtils(user);
  const [openModalInvite, setOpenModalInvite] = useState(false);
  const [notShowGoal, setNotShowGoal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (publicProfile?.length) {
      const filterGoal = publicProfile?.find((item) => item.includes("goal"));
      if (filterGoal) setNotShowGoal(true);
    }
  }, [publicProfile]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`myProfile-${type} my-profile`}>
      <div className="sub-div-infos-profile relative">
        {cover && <img src={cover} alt="imagem" className="my-profile-infos" />}
        {type === "company" && isMyProfile && (
          <IconButton
            icon={<Edit className="my-profile-icon is-edit" />}
            onClick={editCover}
            className="my-profile-icon is-cover"
          />
        )}
      </div>
      {position && <h1 className="my-profile-position">{position}</h1>}
      <div className="flex padding-y-24">
        <div>
          <div className="my-profile-image">
            {avatar ? (
              <img
                src={avatar}
                alt="imagem"
                width={128}
                height={128}
                className="my-profile-avatar"
              />
            ) : type === "user" ? (
              <AvatarEmpty
                widthIcon={width < 991 ? 96 : 128}
                heightIcon={width < 991 ? 96 : 128}
                className="my-profile-avatar-empty"
              />
            ) : (
              <CompanyAvatarEmpty
                widthIcon={width < 991 ? 96 : 128}
                heightIcon={width < 991 ? 96 : 128}
                className="my-profile-company-empty"
              />
            )}
          </div>
          {isMyProfile && (
            <IconButton
              icon={<Edit className="my-profile-edit" />}
              onClick={editAvatar}
              className="my-profile-edit-avatar cursor-pointer"
            />
          )}
        </div>
        <div
          className={`flex-col ${type === "user" ? "width-66" : "width-100"}`}
        >
          <div className="flex">
            <div className="width-100">
              <h2
                className={`my-profile-title ${
                  type === "company"
                    ? "margin-title-company"
                    : "margin-title-name"
                } `}
              >
                {name}
              </h2>
              <p className="p-localization-infos-profile">{localization}</p>
            </div>
            {type === "user" && width > 1100 && (
              <div className="margin-top-24-only margin-right-12 my-profile-proposal">
                {!isMyProfile && isCorporate && (
                  <Button
                    text="Enviar convite"
                    className="button-invite-profile button-primary"
                    onClick={() => setOpenModalInvite(true)}
                  />
                )}
              </div>
            )}
          </div>
          {type === "user" && !notShowGoal && (
            <p className="p-goal-infos-profile">{goal}</p>
          )}
          <div className={`mediaBtn-${type} mediaBtn1021-${type}`}>
            {proposal && (
              <Button
                text={proposal}
                appearance="tertiary"
                className="button-proposal"
              />
            )}
            {changing && (
              <Button
                text={changing}
                appearance="quaternary"
                className="button-proposal"
              />
            )}
            {freelancer && (
              <Button
                text={freelancer}
                appearance="quaternary"
                className="button-proposal"
              />
            )}
          </div>
        </div>
        <div className="margin-top-24-only media-width-100">
          <div
            className={`flex ${
              width > 1100 && !isMyProfile && "div-avatar-infos-profile"
            } label-icon-mobile`}
          >
            {type === "company" ? (
              <div className="div-contact-visit">
                {socialMedias && socialMedias?.length > 0 ? (
                  <div className="flex-row-center company-profile-social-medias">
                    {socialMedias?.find(
                      (item: { type: string }) =>
                        item.type === userSocialMediaType.LINKEDIN ||
                        companySocialMedias.LINKEDIN_COMPANY,
                    ) && (
                      <a
                        aria-label="linkedin"
                        href={
                          (socialMedias?.find(
                            (item: { type: string }) =>
                              item.type === userSocialMediaType.LINKEDIN,
                          )?.url ||
                            socialMedias?.find(
                              (item: { type: string }) =>
                                item.type ===
                                companySocialMedias.LINKEDIN_COMPANY,
                            )?.url) ??
                          ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <LinkedinLogin />
                      </a>
                    )}
                    {socialMedias?.find(
                      (item: { type: string }) => item.type === "INSTAGRAM",
                    ) && (
                      <a
                        aria-label="instagram"
                        href={
                          socialMedias?.find(
                            (item: { type: string }) =>
                              item.type === "INSTAGRAM",
                          )?.url ?? ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <InstagramProfile />
                      </a>
                    )}
                    {socialMedias?.find(
                      (item: { type: string }) => item.type === "FACEBOOK",
                    ) && (
                      <a
                        aria-label="facebook"
                        href={
                          socialMedias?.find(
                            (item: { type: string }) =>
                              item.type === "FACEBOOK",
                          )?.url ?? ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <FacebookProfile />
                      </a>
                    )}
                    {socialMedias?.find(
                      (item: { type: string }) => item.type === "TWITTER",
                    ) && (
                      <a
                        aria-label="twitter"
                        href={
                          socialMedias?.find(
                            (item: { type: string }) => item.type === "TWITTER",
                          )?.url ?? ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <TwitterProfile />
                      </a>
                    )}
                    {socialMedias?.find(
                      (item: { type: string }) => item.type === "TIKTOK",
                    ) && (
                      <a
                        aria-label="tiktok"
                        href={
                          socialMedias?.find(
                            (item: { type: string }) => item.type === "TIKTOK",
                          )?.url ?? ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <TikTokProfile />
                      </a>
                    )}
                    {socialMedias?.find(
                      (item: { type: string }) => item.type === "GITHUB",
                    ) && (
                      <a
                        aria-label="github"
                        href={
                          socialMedias?.find(
                            (item: { type: string }) => item.type === "GITHUB",
                          )?.url ?? ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <GitHubProfile />
                      </a>
                    )}
                  </div>
                ) : null}
                {!isMyProfile && site && (
                  <a
                    className="visit-button"
                    href={site.includes("http") ? site : `http://${site}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Visitar site
                  </a>
                )}
              </div>
            ) : (
              <ul className="mediaUl ul-avatar-infos-profile flex-direction-mobile mediaUl-responsive">
                <li className="mediaLi media990px">
                  <IconButton
                    icon={
                      <Avatar
                        widthIcon={width < 991 ? 24 : 28}
                        heightIcon={width < 991 ? 24 : 28}
                      />
                    }
                    text="Dados gerais"
                    onClick={onClickGeneralData}
                    className="icon-button-perfil icon-button-perfil-generaldata"
                  />
                </li>
                <li className="mediaLi media990px">
                  <IconButton
                    icon={
                      <PhoneBlack
                        widthIcon={width < 991 ? 24 : 28}
                        heightIcon={width < 991 ? 24 : 28}
                      />
                    }
                    text="Contato"
                    onClick={onClickContact}
                    className="icon-button-perfil icon-button-perfil-contact"
                  />
                </li>
                <li className="mediaLi media990px margin-0 my-profile-socials">
                  <IconButton
                    icon={
                      <SocialMedia
                        widthIcon={width < 991 ? 24 : 28}
                        heightIcon={width < 991 ? 24 : 28}
                      />
                    }
                    text="Redes sociais"
                    onClick={onClickContactSocial}
                    className="icon-button-perfil"
                  />
                </li>
              </ul>
            )}
            {isMyProfile &&
              (type !== "company" ||
                (socialMedias && socialMedias?.length > 0)) && (
                <IconButton
                  icon={<Edit />}
                  className="icon-button-perfil btnEdit edit-button-perfil"
                  onClick={onEdit}
                />
              )}
          </div>
          {type === "user" && width < 1100 && (
            <div className="margin-right-12 my-profile-proposal">
              {!isMyProfile && isCorporate && (
                <Button
                  text="Enviar convite"
                  className="button-invite-profile button-primary"
                  onClick={() => setOpenModalInvite(true)}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {!isMyProfile && type === "company" && (
        <div className="div-profile-infos-profile">
          <button
            type="button"
            className={`button-generic-infos-profile ${
              pageSelected === "initial"
                ? "color-page-selected"
                : "color-page-not-selected"
            } `}
            onClick={() => setPageSelected && setPageSelected("initial")}
          >
            Início
            <div
              className={
                pageSelected === "initial"
                  ? "tab-profile-selected"
                  : "tab-profile"
              }
            />
          </button>
          <button
            type="button"
            className={`button-generic-infos-profile  ${
              pageSelected === "about"
                ? "color-page-selected"
                : "color-page-not-selected"
            }`}
            onClick={() => setPageSelected && setPageSelected("about")}
          >
            Sobre
            <div
              className={
                pageSelected === "about"
                  ? "tab-profile-selected"
                  : "tab-profile"
              }
            />
          </button>
          <button
            type="button"
            className={`button-generic-infos-profile ${
              pageSelected === "vacancy"
                ? "color-page-selected"
                : "color-page-not-selected"
            } `}
            onClick={() => setPageSelected && setPageSelected("vacancy")}
          >
            Vagas
            <div
              className={
                pageSelected === "vacancy"
                  ? "tab-profile-selected"
                  : "tab-profile"
              }
            />
          </button>
          <button
            type="button"
            className={`button-generic-infos-profile ${
              pageSelected === "medias"
                ? "color-page-selected"
                : "color-page-not-selected"
            } `}
            onClick={() => setPageSelected && setPageSelected("medias")}
          >
            Mídias
            <div
              className={
                pageSelected === "medias"
                  ? "tab-profile-selected"
                  : "tab-profile"
              }
            />
          </button>
        </div>
      )}
      <ModalSendInvite
        userId={userId ?? ""}
        openModal={openModalInvite}
        onClose={() => setOpenModalInvite(false)}
      />
    </div>
  );
};

export default memo(InfosProfile);
