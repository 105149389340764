/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { SET_USER, USER_UPDATE, CLEAR_USER } from "../types/userTypes";

const initialState: any = {
  profile: {
    isWhatsapp: true,
    identityType: "BR_CPF",
  },
  account: {},
  login: {},
  integrations: [],
  curriculum: {},
  subjects: [],
  filters: {},
};

export const userReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
): any => {
  switch (type) {
    case USER_UPDATE:
      return { ...state, ...payload };
    case SET_USER:
      return { ...initialState, ...payload };
    case CLEAR_USER:
      return { ...initialState };

    default:
      return state;
  }
};
