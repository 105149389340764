/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { toast } from "react-toastify";
import { Slider } from "@material-ui/core";
import {
  candidateOnGreenhouse,
  candidateOnGupy,
  subscriptInVacancy,
} from "../../services/functions";
import { updateUser } from "../../store/actions/userActions";
import { maskPhone } from "../../utils";
import { Checkbox, Input, Button, Modal } from "..";
import "./styles.scss";
import { setLoad } from "../../store/actions/configurationsActions";
import { placementVacancyManagedBy } from "../../types/enumerators";

interface Props {
  user: any;
  vacancy: any;
  setIsCandidate: (value: SetStateAction<boolean>) => void;
  onClose: () => void;
  open: boolean;
}

const ModalConfirmCandidate = ({
  user,
  vacancy,
  setIsCandidate,
  onClose,
  open,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState(user?.profile?.email);
  const [telephone, setTelephone] = useState(user?.profile?.telephone);
  const [skills, setSkills] = useState(user?.curriculum?.skills);

  const dispatch = useDispatch();

  const handleClick = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    try {
      if (
        email !== user?.profile?.email ||
        telephone !== user?.profile?.telephone ||
        skills !== user?.curriculum?.skills
      ) {
        dispatch(
          updateUser({
            profile: {
              ...user.profile,
              telephone,
            },
            curriculum: {
              ...user.curriculum,
              skills: [...skills],
            },
          }) as unknown as Action,
        );
      }

      switch (vacancy?.managedBy) {
        case placementVacancyManagedBy.GREENHOUSE:
          if (
            !(await candidateOnGreenhouse(
              user,
              vacancy?.vacancyId,
              vacancy?.partnerVacancyId,
            ))
          )
            throw new Error("");
          break;
        case placementVacancyManagedBy.GUPY:
          if (!(await candidateOnGupy(user, vacancy?.vacancyId)))
            throw new Error("");
          break;
        default:
          await subscriptInVacancy(vacancy?.vacancyId ?? "", user?._id);
          break;
      }
      setIsCandidate(true);
      toast.success("Candidatura enviada com sucesso");
    } catch (err) {
      toast.error("Ocorreu um erro ao se candidatar");
    } finally {
      dispatch(setLoad(false) as unknown as Action);
      onClose();
    }
  }, [
    email,
    user,
    telephone,
    dispatch,
    vacancy,
    setIsCandidate,
    onClose,
    skills,
  ]);

  const verifyChecked = useCallback(
    (selectedKey) =>
      skills?.find((skill: { key: string }) => selectedKey === skill?.key),
    [skills],
  );

  const handleCheckboxChange =
    (desiredSkills: { key: string }) =>
    (event: { target: { checked: boolean } }) => {
      if (event.target.checked) {
        const existingSkill = skills?.find(
          (skill: { key: string }) => skill?.key === desiredSkills?.key,
        );

        if (!existingSkill) {
          const updatedSkills = [...skills, { ...desiredSkills, level: 1 }];
          setSkills(updatedSkills);
        }
      } else {
        const updatedSkills = skills?.filter(
          (skill: { key: string }) => skill?.key !== desiredSkills?.key,
        );
        setSkills(updatedSkills);
      }
    };

  const labelSkillLevel = useCallback((level) => {
    switch (level) {
      case 1:
        return "básico";
      case 2:
        return "intermediário";
      case 3:
        return "avançado";
      default:
        return "";
    }
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      headerAction
      textHeader="Confirmar candidatura"
    >
      <div className="modal-confirm">
        <h2>Confira seus dados para confirmar sua candidatura</h2>
        <div className="display-inline width-100">
          {width < 991 && <p className="p-mobile">E-mail*</p>}
          <Input
            label="E-mail*"
            type="text"
            value={email ?? ""}
            onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
            className="modal-confirm-email"
          />
          {width < 991 && <p className="p-mobile">Celular*</p>}
          <Input
            label="Celular*"
            value={maskPhone(telephone ?? "")}
            onChange={(e) => setTelephone(e.target.value)}
            className="modal-confirm-phone"
          />
        </div>
        {vacancy?.desiredSkills && vacancy?.desiredSkills.length > 0 && (
          <div className="modal-confirm-skill-modal">
            <p>Habilidade</p>

            {vacancy?.desiredSkills?.map(
              (desiredSkills: { key: string; level?: number }) => (
                <div
                  key={Math.random()}
                  className="modal-confirm-skill-confirm-modal-container"
                >
                  <div>
                    <Checkbox
                      id="skillsConfirm"
                      label={`${desiredSkills?.key} (${labelSkillLevel(
                        desiredSkills?.level,
                      )})`}
                      checked={verifyChecked(desiredSkills?.key)}
                      onChange={handleCheckboxChange(desiredSkills)}
                    />
                  </div>

                  {verifyChecked(desiredSkills?.key) && (
                    <Slider
                      step={1}
                      min={1}
                      max={3}
                      marks={[
                        { value: 1, label: "Básico" },
                        { value: 2, label: "Intermediário" },
                        { value: 3, label: "Avançado" },
                      ]}
                      className="modal-confirm-slider"
                      value={
                        skills?.find(
                          (skill: { key: string }) =>
                            skill?.key === desiredSkills?.key,
                        )?.level || 1
                      }
                      onChange={(event, value) => {
                        const result = skills?.map(
                          (item: { key: string; level: number }) => {
                            if (item?.key === desiredSkills?.key)
                              item.level = value as number;

                            return item;
                          },
                        );
                        setSkills(result);
                      }}
                    />
                  )}
                </div>
              ),
            )}
          </div>
        )}

        <div className="modal-confirm-buttons justify-content-end flex-col-mobile">
          <Button
            text="Cancelar"
            outline
            className="modal-confirm-button-action"
            onClick={onClose}
            appearance="secondary"
          />
          <Button
            text="Confirmar"
            onClick={handleClick}
            className="modal-confirm-button-action"
            disabled={!email || !telephone}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(ModalConfirmCandidate);
