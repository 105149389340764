import React, { memo } from "react";
import moment from "moment";
import { Card } from "../../../components";
import { Class } from "../../../types/interfaces";
import { StructureOptions } from "../../../types/constants/Course";
import "../style.scss";

interface Props {
  currentClass: Class;
}

const Infos = ({ currentClass }: Props): JSX.Element => (
  <Card
    titleHeader="Dados gerais"
    className="grid-column-4-13 grid-row-2"
    bodyClassName="card-general-data-class-vacancy"
  >
    <div className="flex flex-wrap">
      <div className="margin-right-48 margin-bottom-24">
        <p className="title-view-company">Código</p>
        <p className="view-bold">{currentClass?.code ?? ""}</p>
      </div>
      <div className="margin-right-48 margin-bottom-24">
        <p className="title-view-company">Nome</p>
        <p className="view-bold">{currentClass?.title ?? ""}</p>
      </div>
      <div className="margin-right-48 margin-bottom-24">
        <p className="title-view-company">Data de Abertura</p>
        <p className="view-bold">
          {moment(currentClass?.startDate).format("DD/MM/YYYY") ?? ""}
        </p>
      </div>
      <div className="margin-right-48 margin-bottom-24">
        <p className="title-view-company">Data de Encerramento</p>
        <p className="view-bold">
          {moment(currentClass?.endDate).format("DD/MM/YYYY") ?? ""}
        </p>
      </div>
      <div className="margin-right-48 margin-bottom-24">
        <p className="title-view-company">Estrutura</p>
        <p className="view-bold">
          {StructureOptions[
            currentClass?.classStructure as keyof typeof StructureOptions
          ] ?? ""}
        </p>
      </div>
    </div>
    <div>
      <p className="title-view-company">Habilidades</p>
      <p className="view-bold">{currentClass?.description ?? ""}</p>
    </div>
  </Card>
);

export default memo(Infos);
