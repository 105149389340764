/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import {
  CardMedia,
  Draggable,
  HeaderProfile,
  IconButton,
  InfosProfile,
  InfosProfileMobile,
  Layout,
  ModalEditVisibilityInfosProfile,
  UsersProfile,
} from "../../../components";
import {
  IconVideo,
  FileProfileMin,
  Link as LinkIcon,
  Edit,
  Infos,
  FileProfile,
  AddProfile,
} from "../../../../assets/customIcons";
import { Down } from "../../../../assets/icons";
import { RootState } from "../../../store/reducers";
import { Avatar, Description, Cover, Media } from "../Modal";
import { updateCompany } from "../../../store/actions/companyActions";
import { mediaType } from "../../../types/enumerators";
import { getSuggestionsOfUsers } from "../../../services/functions";
import { Company, User } from "../../../types/interfaces";
import { youTubeIdFromLink } from "../../../utils/index";
import { youtubeApi } from "../../../services/api";
import { setLoad } from "../../../store/actions/configurationsActions";
import "../styles.scss";

const CompanyProfile = (): JSX.Element => {
  const [openModalEditDescription, setOpenModalEditDescription] =
    useState(false);
  const [openModalEditMedia, setOpenModalEditMedia] = useState(false);
  const [openModalEditAvatar, setOpenModalEditAvatar] = useState(false);
  const [openModalEditCover, setOpenModalEditCover] = useState(false);
  const [youTubeList, setYouTubeList] = useState<any>();
  const [youTubeLinksList, setYouTubeLinksList] = useState<any>();
  const [openModalEditInfos, setOpenModalEditInfos] = useState(false);
  const [users, setUsers] = useState<User[]>();
  const [width, setWidth] = useState(window.innerWidth);

  const company = useSelector((state: RootState) => state.companyState);
  const [_company, _setCompany] = useState(company);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_company?._id || !users) dispatch(setLoad(true) as unknown as Action);
    else dispatch(setLoad(false) as unknown as Action);
  }, [_company?._id, dispatch, users]);

  useEffect(() => {
    if (company?._id && !_company?._id) _setCompany(company);
  }, [dispatch, company, _company?._id]);

  const getUsers = useCallback(async () => {
    const response = await getSuggestionsOfUsers(company?._id);
    setUsers(response ?? []);
  }, [company?._id]);

  useEffect(() => {
    if (!users) getUsers();
  }, [company._id, getUsers, users]);

  const reorderMedias = useCallback(
    (items: any) => {
      const { profile } = _company;
      if (profile) {
        let { medias: newMedias } = profile;
        newMedias = items?.map((item: any, index: number) => ({
          ...item,
          order: index,
        }));
        _setCompany({
          ..._company,
          profile: { ...profile, medias: newMedias },
        });
        dispatch(
          updateCompany({
            ..._company,
            profile: { ...profile, medias: newMedias },
          }) as unknown as Action,
        );
      }
    },
    [_company, dispatch],
  );

  const getYouTubeList = useCallback(async () => {
    const youTubeIds = _company?.profile?.medias
      ?.filter((item: { url: string }) => item?.url?.includes("youtu"))
      ?.map((innerItem: { url: string }) => ({
        url: innerItem?.url,
        id: youTubeIdFromLink(innerItem?.url),
      }));

    const videosList = [];
    const linksList = [];

    for (let i = 0; i < youTubeIds?.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const response = await youtubeApi.get(
        `/videos?key=AIzaSyAz3zSCJ-Du4DyU8sr2X2xxn4xhv7RRzHM&part=snippet&id=${youTubeIds[i]?.id}`,
      );

      if (
        response?.data?.items?.length &&
        response?.data?.items[0]?.kind === "youtube#video"
      )
        videosList.push(response?.data?.items[0]);
      else linksList.push({ url: youTubeIds[i]?.url });
    }

    setYouTubeList(videosList);
    setYouTubeLinksList(linksList);
  }, [_company?.profile?.medias]);

  const handleChangeCompany = useCallback(
    (item: Company) => {
      _setCompany(item);
      dispatch(updateCompany(item) as unknown as Action);
      getYouTubeList();
    },
    [dispatch, getYouTubeList],
  );

  useEffect(() => {
    if (!youTubeList) getYouTubeList();
  }, [youTubeList, getYouTubeList]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-responsive">
      <HeaderProfile />
      <div className="margin-bottom-40 grid-column-1-10">
        {width > 990 ? (
          <InfosProfile
            isMyProfile
            name={_company?.profile?.name}
            avatar={_company?.profile?.logo ?? ""}
            cover={_company?.profile?.cover ?? ""}
            socialMedias={_company?.profile?.socialMedias ?? []}
            editAvatar={() => setOpenModalEditAvatar(true)}
            editCover={() => setOpenModalEditCover(true)}
            onEdit={() => setOpenModalEditInfos(true)}
            type="company"
            localization={
              _company?.profile?.location &&
              _company?.profile?.location?.length > 0
                ? `${_company?.profile?.location[0]?.city} - ${_company?.profile?.location[0]?.province}`
                : ""
            }
          />
        ) : (
          <InfosProfileMobile
            isMyProfile
            name={_company?.profile?.name}
            avatar={_company?.profile?.logo ?? ""}
            cover={_company?.profile?.cover ?? ""}
            socialMedias={_company?.profile?.socialMedias ?? []}
            editAvatar={() => setOpenModalEditAvatar(true)}
            editCover={() => setOpenModalEditCover(true)}
            onEdit={() => setOpenModalEditInfos(true)}
            type="company"
            localization={
              _company?.profile?.location &&
              _company?.profile?.location?.length > 0
                ? `${_company?.profile?.location[0]?.city} - ${_company?.profile?.location[0]?.province}`
                : ""
            }
          />
        )}
        {_company?.profile?.description && (
          <div className="cards-profile">
            <div className="flex-row-center title-cards">
              <Infos
                widthIcon={width < 991 ? 24 : 32}
                heightIcon={width < 991 ? 24 : 32}
                className="cards-icon-title"
              />
              <p className="cards-title-profile">Sobre</p>
              <Edit
                className="pointer"
                onClick={() => setOpenModalEditDescription(true)}
              />
            </div>
            <div className="divider-card-title" />
            <div className="cards-items-profile padding-24-32">
              <p className="paragraph-about">
                {_company?.profile?.description}
              </p>
            </div>
          </div>
        )}
        {_company?.profile?.medias && (
          <Accordion defaultExpanded className="cards-profile margin-bottom-32">
            <AccordionSummary
              expandIcon={<Down />}
              className="div-border-bottom expanded-icon"
            >
              <div className="flex-row-center width-100 margin-0-24">
                <FileProfile
                  widthIcon={width < 991 ? 24 : 32}
                  heightIcon={width < 991 ? 24 : 32}
                />
                <p className="cards-title-profile">Mídias</p>
                <div>
                  <AddProfile
                    widthIcon={width < 991 ? 24 : 32}
                    heightIcon={width < 991 ? 24 : 32}
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpenModalEditMedia(true);
                    }}
                  />
                </div>
              </div>
            </AccordionSummary>
            <div className="line-divider-midia" />
            <AccordionDetails>
              {youTubeList?.length > 0 && (
                <div>
                  <div className="flex-row-center flex-end margin-top-24">
                    <IconButton
                      icon={
                        <Edit
                          widthIcon={width < 991 ? 20 : 24}
                          heightIcon={width < 991 ? 20 : 24}
                        />
                      }
                      onClick={() => setOpenModalEditMedia(true)}
                      className="margin-right-24 margin-bottom-0"
                    />
                  </div>

                  <div className="flex-row-center padding-title-card margin-icon">
                    <IconVideo
                      widthIcon={width < 991 ? 20 : 24}
                      heightIcon={width < 991 ? 20 : 24}
                    />
                    <p className="title-paragraph">Vídeos</p>
                  </div>
                  <div className="flex-wrap-midia" />

                  <div className="cards-items-profile">
                    <div key={Math.random()} className="flex-direction-wrap">
                      {youTubeList?.map(
                        (item: { id: string; snippet: { title: string } }) => (
                          <div
                            key={Math.random()}
                            className="container-videos-all-midias margin-bottom-24"
                          >
                            <iframe
                              width="264px"
                              height="152px"
                              src={`https://www.youtube.com/embed/${item?.id}`}
                              title="YouTube video player"
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              className="videos-all-midias"
                            />
                            <p className="all-title-video">
                              {item?.snippet?.title ?? ""}
                            </p>
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                </div>
              )}
              {_company?.profile?.medias?.find(
                (item: { type: string }) => item.type !== mediaType.LINK,
              ) && (
                <div>
                  <div className="div-medias-profile" />
                  <div className="flex-row-center flex-end margin-top-24">
                    <IconButton
                      icon={
                        <Edit
                          widthIcon={width < 991 ? 20 : 24}
                          heightIcon={width < 991 ? 20 : 24}
                        />
                      }
                      onClick={() => setOpenModalEditMedia(true)}
                      className="margin-right-24 margin-bottom-0"
                    />
                  </div>
                  <div className="flex-wrap-midia" />
                  <div className="flex-row-center padding-title-card margin-icon">
                    <FileProfileMin
                      widthIcon={width < 991 ? 20 : 24}
                      heightIcon={width < 991 ? 20 : 24}
                    />
                    <p className="paragraph-files-area">
                      {`${
                        _company?.profile?.medias?.filter(
                          (item: { type: string }) =>
                            item.type !== mediaType.LINK,
                        )?.length > 1
                          ? "Documentos"
                          : "Documento"
                      }`}
                    </p>
                  </div>
                  <div className="flex-wrap-midia padding-24">
                    <Draggable
                      items={_company?.profile?.medias}
                      reorderItem={reorderMedias}
                    >
                      <div className="flex-wrap-midia">
                        {_company?.profile?.medias?.map(
                          (item: any, index: number) => {
                            if (item.type !== mediaType.LINK) {
                              return (
                                <DraggableComponent
                                  key={Math.random()}
                                  draggableId={Math.random().toString()}
                                  index={index}
                                  isDragDisabled={
                                    _company?.profile?.medias?.filter(
                                      (innerItem: { type: string }) =>
                                        innerItem.type !== mediaType.LINK,
                                    )?.length === 1
                                  }
                                >
                                  {(innerProvided) => (
                                    <div
                                      ref={innerProvided.innerRef}
                                      {...innerProvided.draggableProps}
                                      {...innerProvided.dragHandleProps}
                                      // className="margin-bottom-24"
                                    >
                                      <CardMedia small item={item} />
                                    </div>
                                  )}
                                </DraggableComponent>
                              );
                            }
                            return <div />;
                          },
                        )}
                      </div>
                    </Draggable>
                  </div>
                </div>
              )}
              {(_company?.profile?.medias?.find(
                (item: { type: string; url: string }) =>
                  item?.type === mediaType.LINK &&
                  !item?.url?.includes("youtu"),
              ) ||
                youTubeLinksList?.length > 0) && (
                <div>
                  <div className="div-medias-profile" />
                  <div className="flex-row-center margin-flex">
                    <IconButton
                      icon={
                        <Edit
                          widthIcon={width < 991 ? 20 : 24}
                          heightIcon={width < 991 ? 20 : 24}
                        />
                      }
                      onClick={() => setOpenModalEditMedia(true)}
                      className="margin-ringht-24"
                    />
                  </div>
                  <div className="flex-row-center padding-title-card margin-icon">
                    <LinkIcon
                      widthIcon={width < 991 ? 20 : 24}
                      heightIcon={width < 991 ? 20 : 24}
                    />
                    <p className="p-publication">
                      {`${
                        _company?.profile?.medias?.filter(
                          (item: { type: string; url: string }) =>
                            item.type === mediaType.LINK &&
                            !item?.url?.includes("youtu"),
                        )?.length
                      }${
                        _company?.profile?.medias?.filter(
                          (item: { type: string; url: string }) =>
                            item.type === mediaType.LINK &&
                            !item?.url?.includes("youtu"),
                        )?.length > 1
                          ? " Publicações"
                          : " Publicação"
                      }`}
                    </p>
                  </div>
                  <div className="flex-wrap padding-24-0">
                    {_company?.profile?.medias
                      ?.filter(
                        (item: { type: string; url: string }) =>
                          item.type === mediaType.LINK &&
                          !item?.url?.includes("youtu"),
                      )
                      ?.concat(youTubeLinksList)
                      ?.map((innerItem: { url: string }) => (
                        <a
                          key={Math.random()}
                          href={
                            innerItem?.url?.includes("http")
                              ? innerItem?.url
                              : `http://${innerItem?.url}`
                          }
                          className="link-style"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {innerItem?.url}
                        </a>
                      ))}
                  </div>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        )}
      </div>
      <UsersProfile users={users ?? []} row="1" />
      <Description
        setCompany={handleChangeCompany}
        company={_company}
        openModal={openModalEditDescription}
        onClose={() => setOpenModalEditDescription(false)}
      />
      <Media
        setCompany={handleChangeCompany}
        company={_company}
        openModal={openModalEditMedia}
        onClose={() => setOpenModalEditMedia(false)}
        titleModal="Adicionar mídia"
        showModalInstruction={false}
      />
      <Avatar
        setCompany={handleChangeCompany}
        company={_company}
        openModal={openModalEditAvatar}
        onClose={() => setOpenModalEditAvatar(false)}
      />
      <Cover
        setCompany={handleChangeCompany}
        company={company}
        openModal={openModalEditCover}
        onClose={() => setOpenModalEditCover(false)}
      />
      <ModalEditVisibilityInfosProfile
        isCompany
        openModal={openModalEditInfos}
        onClose={() => setOpenModalEditInfos(false)}
        user={_company}
      />
    </Layout>
  );
};

export default memo(CompanyProfile);
