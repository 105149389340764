/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Checkbox, InputAutocomplete, InputDate, TextArea } from "..";
import {
  getAutocomplete,
  getCompany,
  searchCompaniesAllStatus,
  searchCompaniesAutocomplete,
  setNewCompany,
} from "../../services/functions";
import {
  autoCompleteType,
  companyStatus,
  companyType,
} from "../../types/enumerators";
import { registerAutoComplete } from "../../utils";
import "./styles.scss";

interface Props {
  work: any;
  setItem: any;
  setIsValid: any;
  setIsCurrentJob?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddWork = ({
  work,
  setItem,
  setIsValid,
  setIsCurrentJob,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [_work, _setWork] = useState(work);
  const [_isCurrentJob, _setIsCurrentJob] = useState(
    _work?.endDate !== undefined ? !_work?.endDate : false,
  );
  const [company, setCompany] = useState("");
  const [companyOptions, setCompanyOptions] =
    useState<{ name: string; id: string }[]>();
  const [workPositionOptions, setWorkPositionOptions] = useState<string[]>();

  useEffect(() => {
    if (
      _work.companyId &&
      _work.position &&
      _work.description &&
      _work.startDate &&
      (_work.endDate || _isCurrentJob)
    )
      setIsValid(true);
    else setIsValid(false);
  }, [_work, _isCurrentJob, setIsValid]);

  const loadPositions = useCallback(async () => {
    const workPosition = await getAutocomplete(autoCompleteType.WORK_POSITION);

    const workPositionMapped = workPosition?.map((option) => option?.name);

    setWorkPositionOptions(workPositionMapped);
  }, []);

  useEffect(() => {
    if (!workPositionOptions) loadPositions();
  }, [loadPositions, workPositionOptions]);

  const loadCompany = useCallback(async () => {
    try {
      const companyOptionsResponse = await searchCompaniesAutocomplete(
        companyType.WORK_COMPANY,
      );

      const companyOptionsMapped = companyOptionsResponse?.map((option) => ({
        name: option?.profile?.name ?? "",
        id: option?._id ?? "",
      }));

      setCompanyOptions(companyOptionsMapped ?? []);
    } catch (err) {
      console.warn(err);
    }
  }, []);

  useEffect(() => {
    if (!companyOptions) loadCompany();
  }, [companyOptions, loadCompany]);

  const getCompanyName = useCallback(async () => {
    try {
      const currentCompany = await getCompany(_work?.companyId ?? "");

      if (currentCompany) setCompany(currentCompany?.profile?.name ?? "");
    } catch (err) {
      console.warn(err);
    }
  }, [_work?.companyId]);

  useEffect(() => {
    if (_work?.companyId) getCompanyName();
  }, [_work?.companyId, getCompanyName]);

  const handleOnBlur = useCallback(() => setItem(_work), [_work, setItem]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) => _setWork({ ..._work, [prop]: event.target.value }),
    [_work],
  );

  const handleChangeAutoComplete = useCallback(
    async (value: string | null, prop: string) => {
      registerAutoComplete(value, autoCompleteType.WORK_POSITION);
      _setWork({ ..._work, [prop]: value as string });
      setItem({ ..._work, [prop]: value as string });
    },
    [_work, setItem],
  );

  const setCurrentCompany = useCallback(
    async (value: string | null) => {
      if (value && value !== "") {
        const currentCompany = await searchCompaniesAllStatus(value);

        if (!currentCompany?.length) {
          await setNewCompany(
            value,
            companyType.WORK_COMPANY,
            companyStatus.PENDING,
            companyStatus.NONE,
          );

          setCurrentCompany(value);
        } else {
          _setWork({
            ..._work,
            companyId: currentCompany[0]._id,
          });

          setItem({
            ..._work,
            companyId: currentCompany[0]._id,
          });
        }
      }
    },
    [_work, setItem],
  );

  const handleChangeDate = useCallback(
    (value: any, prop: string) => {
      _setWork({
        ..._work,
        [prop]: value?.clone().startOf("month").toISOString(),
      });
      setItem({
        ..._work,
        [prop]: value?.clone().startOf("month").toISOString(),
      });
    },
    [_work, setItem],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="add-work">
      <div className="flex flex-col-mobile">
        {width < 991 && <p className="p-mobile">Empresa*</p>}
        <InputAutocomplete
          label="Empresa*"
          helperText="Pressione Enter para adicionar"
          value={company}
          onBlur={(event) => setCurrentCompany(event.target.value)}
          options={companyOptions?.map((item) => item?.name) ?? [""]}
          className="add-work-input muiInput-formControl-responsive margin-bottom-20-mobile width-100 margin-top-MuiFormLabel-root"
          getOptionLabel={(option) => option}
        />
        {width < 991 && <p className="p-mobile">Cargo*</p>}
        <InputAutocomplete
          label="Cargo*"
          helperText="Pressione Enter para adicionar"
          value={_work.position}
          onBlur={(event) =>
            handleChangeAutoComplete(event.target.value, "position")
          }
          className="muiInput-formControl-responsive width-100 margin-bottom-20-mobile margin-top-MuiFormLabel-root"
          options={workPositionOptions ?? [""]}
          getOptionLabel={(option) => option}
        />
      </div>
      {width < 991 && <p className="p-mobile">Descrição*</p>}
      <TextArea
        id="description"
        label="Descrição*"
        maxLength={300}
        value={_work.description ?? ""}
        onChange={(e) => handleChange(e, "description")}
        onBlur={handleOnBlur}
        className="add-work-description margin-bottom-20-mobile"
      />
      <div className="flex align-items flex-col-mobile align-items-baseline-mobile">
        {width < 991 && (
          <p className="p-mobile margin-bottom-16-mobile">Início*</p>
        )}
        <div className="flex width-100-responsive add-work-inputs">
          <div className="add-work-date">
            <InputDate
              label="Início*"
              helperText="MM/AAAA"
              value={_work.startDate || null}
              onChange={(value) => handleChangeDate(value, "startDate")}
            />
          </div>
          <div className="add-work-date-end">
            {width < 991 && !_isCurrentJob && (
              <p className="p-mobile margin-left-10">Fim*</p>
            )}
            {!_isCurrentJob ? (
              <InputDate
                label="Fim*"
                helperText="MM/AAAA"
                value={_work.endDate || null}
                minDate={_work.startDate}
                onChange={(value) => handleChangeDate(value, "endDate")}
                className="add-work-input"
              />
            ) : (
              <div className="add-work-input" />
            )}
          </div>
        </div>
        <div>
          <Checkbox
            id="isCurrentJob"
            label="Trabalho atual"
            checked={_isCurrentJob}
            onChange={() => {
              if (setIsCurrentJob) setIsCurrentJob(!_isCurrentJob);
              _setIsCurrentJob(!_isCurrentJob);
              _setWork({
                ..._work,
                endDate: null,
              });
              setItem({
                ..._work,
                endDate: null,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(AddWork);
