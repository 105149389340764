import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Telegram = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#27A6E8"
      fillRule="evenodd"
      d="M25.667 14c0 6.443-5.223 11.667-11.666 11.667-6.444 0-11.667-5.224-11.667-11.667S7.557 2.333 14.001 2.333c6.443 0 11.666 5.224 11.666 11.667zm-7.216-4.754s-2.63 1.079-3.405 1.416c-2.023.843-7.857 3.237-7.857 3.237-1.382.54-.573 1.045-.573 1.045s1.18.405 2.192.708c1.012.304 1.551-.034 1.551-.034s2.36-1.55 4.754-3.203c1.686-1.146 1.282-.202.877.203-.877.876-2.326 2.259-3.54 3.371-.54.473-.27.877-.034 1.08.686.58 2.302 1.636 3.036 2.116.204.133.34.222.37.244.168.135 1.112.742 1.685.607.574-.135.641-.91.641-.91s.438-2.766.843-5.295c.075-.496.15-.982.22-1.435.181-1.178.329-2.135.353-2.476.101-1.146-1.113-.674-1.113-.674z"
      clipRule="evenodd"
    />
  </svg>
);

export default Telegram;
