import React, { memo, useCallback, useEffect, useState } from "react";
import { ClassStep } from "reprograma-types/dist/base/Class";
import { FormControlLabel, Switch } from "@material-ui/core";
import { Card } from "../../../components";
import { Class } from "../../../types/interfaces";
import { updateClassById } from "../../../services/functions";
import "../style.scss";

interface Props {
  currentClass: Class;
  reload: () => void;
}

const typeLabel: { [key: string]: string } = {
  DIVERSITY_FORM: "Formulário de Diversidade",
  TECHNICAL_TEST: "Desafio Técnico",
  TECHNICAL_TEST_FORM: "Desafio Técnico",
  EVALUATION_BEHAVIOR: "Avaliação Comportamental",
};

const Steps = ({ currentClass, reload }: Props): JSX.Element => {
  const [steps, setSteps] = useState<ClassStep[]>();

  const stepsUpdated = useCallback(
    () => setSteps(currentClass?.steps),
    [currentClass?.steps],
  );

  useEffect(() => {
    if (currentClass?.steps) stepsUpdated();
  }, [currentClass?.steps, stepsUpdated]);

  const onChangeStep = useCallback(
    async (type: string) => {
      const copySteps = steps?.map((item) => {
        if (item?.type === type) return { ...item, enable: !item?.enable };
        return { ...item, enable: false };
      });

      await updateClassById(currentClass?._id ?? "", { steps: copySteps });
      setSteps(copySteps);
      reload();
    },
    [currentClass?._id, reload, steps],
  );

  return (
    <Card
      titleHeader="Subetapas"
      className="grid-column-1-4 grid-row-3"
      bodyClassName="card-general-form"
    >
      <div>
        {steps?.length ? (
          steps?.map(({ type, enable }) => (
            <div key={Math.random()} className="month-form">
              <div className="label-icon">
                <p>{typeLabel[type ?? ""]}</p>
              </div>
              <div className="flex-row-center">
                <FormControlLabel
                  className="margin-left-12"
                  onClick={(e) => e.stopPropagation()}
                  control={
                    <Switch
                      checked={enable}
                      onChange={() => onChangeStep(type ?? "")}
                      name="enable"
                    />
                  }
                  label=""
                />
              </div>
            </div>
          ))
        ) : (
          <div>
            <p className="title-form">
              Não existem subetapas cadastradas nesse processo seletivo
            </p>
          </div>
        )}
      </div>
    </Card>
  );
};

export default memo(Steps);
