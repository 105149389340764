/* eslint-disable no-await-in-loop */
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment-timezone";
import { Edit, Check, Delete, ArrowBack } from "../../../../assets/icons";
import { RootState } from "../../../store/reducers";
import { AvatarEmpty } from "../../../../assets/customIcons/index";
import type {
  CommentProps,
  CommentsProps,
  GeneralCommentsTDO,
  UserWithPhotoProps,
} from "./types";
import type {
  User as UserType,
  ClassVacancySubscription,
} from "../../../types/interfaces";
import { Button, TextArea } from "../../../components";
import {
  getUserById,
  getActiveUserAdmin,
  createNotifications,
  getClassVacancySubscriptionById,
  updateClassVacancySubscriptionById,
  getClassVacancyById,
} from "../../../services/functions";
import { StageType } from "../../../types/constants/Course";

const Comments = ({
  id,
  step,
  onClose,
  classVacancyId,
  previousComments,
  allSubscriptions,
}: CommentsProps): JSX.Element => {
  const [data, setData] = useState<Record<string, UserWithPhotoProps[]> | []>();
  const [dataComment, setDataComment] = useState<
    {
      title?: string;
      classVacancyId?: string;
      stages?: { [x: string]: GeneralCommentsTDO[] };
    }[]
  >();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState<typeof comment | null>(
    null,
  );
  const [subscription, setSubscription] = useState<ClassVacancySubscription>();
  const [comment, setComment] = useState<CommentProps>({
    key: "",
    type: "",
    date: moment().tz("America/Sao_Paulo").format("DD/MM/YYYY"),
    comment: "",
  });
  const [editComment, setEditComment] = useState({
    user: "",
    state: false,
    comment: "",
  });

  const [selectedClassVacancy, setSelectedClassVacancy] = useState("");
  const [noComments, setNoComments] = useState(false);

  const userLogged = useSelector((state: RootState) => state.userState);

  const getUserPicture = useCallback(async () => {
    if (
      subscription &&
      subscription?.miscellaneous &&
      subscription?.miscellaneous?.generalComment
    ) {
      setLoading(true);

      const findComments =
        subscription?.miscellaneous?.generalComment?.filter(
          ({ element }) => element === step,
        ) ?? [];

      const getUsers = (await Promise.all(
        findComments?.map(async ({ userId }) => getUserById(userId ?? "")),
      )) as UserType[];

      const userWithPhotos = findComments?.map((item) => {
        const find = getUsers?.find((subitem) => subitem?._id === item?.userId);

        return {
          ...item,
          userName: find?.profile?.name ?? "",
          photo: find?.profile?.photo ?? null,
        };
      });

      const groupUsers = userWithPhotos?.reduce((acc, curr) => {
        acc[curr?.date as string] = [...(acc[curr?.date as string] ?? [])];

        acc[curr?.date as string].push(curr);

        return acc;
      }, {} as Record<string, UserWithPhotoProps[]>);

      setData(groupUsers);

      setLoading(false);
    } else setData([]);
    setLoading(false);
  }, [step, subscription]);

  const getSubsAndUser = useCallback(async () => {
    const responseSubs = await getClassVacancySubscriptionById(id);
    setSubscription(responseSubs);
  }, [id]);

  const submitComments = useCallback(async () => {
    setLoading(true);

    const activeUsers = await getActiveUserAdmin();

    const filterUsers = activeUsers?.filter(
      (item) => item?._id !== userLogged?._id,
    );

    filterUsers?.forEach(async (user) => {
      const dataUser = {
        userId: user?._id,
        hasRead: false,
        title: "Novo comentário",
        text: "Há um novo comentário sobre uma candidata de um dos nossos processos seletivos.",
        date: new Date(),
        action: `() => history.push({pathname: '/admin-class-vacancy-comments', state: { classVacancyId: '${classVacancyId}', classVacancySubscriptionId: '${id}' }})`,
      };

      await createNotifications(dataUser);
    });

    const miscellaneous = {
      ...(subscription?.miscellaneous ?? {}),
      generalComment: [
        ...(subscription?.miscellaneous?.generalComment ?? []),
        {
          text: comment?.comment,
          date: moment(comment?.date)
            .tz("America/Sao_Paulo")
            .format("DD/MM/YYYY"),
          userId: userLogged?._id,
          element: comment?.key,
          resolved: false,
        },
      ],
    };

    await updateClassVacancySubscriptionById(subscription?._id ?? "", {
      miscellaneous,
    });

    getSubsAndUser();

    setComment({
      key: "",
      type: "",
      date: moment().tz("America/Sao_Paulo").format("DD/MM/YYYY"),
      comment: "",
    });

    setLoading(false);
  }, [id, subscription, comment, userLogged, getSubsAndUser, classVacancyId]);

  const removeComment = useCallback(
    async (props: typeof comment) => {
      setLoading(true);

      const findIndex = subscription?.miscellaneous?.generalComment?.map(
        (item, idx) => {
          if (
            item?.text === props?.comment &&
            item?.element === props?.key &&
            item?.userId === userLogged?._id
          )
            return idx;
          return null;
        },
      );

      const filterIndex = findIndex?.find((item) => item !== null);

      const removed = subscription?.miscellaneous?.generalComment?.filter(
        (item, idx) => idx !== filterIndex,
      );

      const miscellaneous = {
        ...(subscription?.miscellaneous ?? {}),
        generalComment: removed,
      };

      await updateClassVacancySubscriptionById(subscription?._id ?? "", {
        miscellaneous,
      });

      await getSubsAndUser();

      setLoading(false);
    },
    [userLogged?._id, getSubsAndUser, subscription],
  );

  const editComments = useCallback(async () => {
    setLoading(true);
    const findIndex = subscription?.miscellaneous?.generalComment?.map(
      (item, idx) => {
        if (
          item?.text === editComment?.comment &&
          item?.element === comment?.key &&
          item?.userId === editComment?.user
        )
          return idx;
        return null;
      },
    );

    const filterIndex = findIndex?.find((item) => item !== null);

    const miscellaneous = {
      ...(subscription?.miscellaneous ?? {}),
      generalComment: subscription?.miscellaneous?.generalComment?.map(
        (general, idx) =>
          idx === filterIndex
            ? {
                text: comment?.comment,
                date: moment(comment?.date)
                  .tz("America/Sao_Paulo")
                  .format("DD/MM/YYYY"),
                userId: userLogged?._id,
                element: comment?.key,
              }
            : general,
      ),
    };

    await updateClassVacancySubscriptionById(subscription?._id ?? "", {
      miscellaneous,
    });

    await getSubsAndUser();

    setEditComment({
      user: "",
      state: false,
      comment: "",
    });

    setComment({
      key: "",
      type: "",
      date: "",
      comment: "",
    });

    setLoading(false);
  }, [comment, editComment, subscription, userLogged?._id, getSubsAndUser]);

  const resolveComment = useCallback(
    async (type: boolean, props: typeof comment) => {
      setLoadingButton(props);

      const findIndex = subscription?.miscellaneous?.generalComment?.map(
        (item, idx) => {
          if (item?.text === props?.comment && item?.element === props?.key)
            return idx;
          return null;
        },
      );

      const filterIndex = findIndex?.find((item) => item !== null);

      const miscellaneous = {
        ...(subscription?.miscellaneous ?? {}),
        generalComment: subscription?.miscellaneous?.generalComment?.map(
          (general, idx) =>
            idx === filterIndex
              ? {
                  ...subscription?.miscellaneous?.generalComment?.[idx],
                  resolved: type,
                }
              : general,
        ),
      };

      await updateClassVacancySubscriptionById(subscription?._id ?? "", {
        miscellaneous,
      });

      await getSubsAndUser();

      setLoadingButton(null);
    },
    [subscription, getSubsAndUser],
  );

  useEffect(() => {
    if (subscription) getUserPicture();
  }, [getUserPicture, subscription]);

  useEffect(() => {
    if (!!id && subscription?._id !== id) getSubsAndUser();
  }, [getSubsAndUser, id, subscription]);

  const mapSubscription = useCallback(async () => {
    const filterSubscriptions = allSubscriptions?.filter(
      (subscriptionItem) => subscriptionItem?._id !== subscription?._id,
    );

    const newArray: {
      title?: string;
      classVacancyId?: string;
      stages?: { [x: string]: GeneralCommentsTDO[] };
    }[] = [];

    if (filterSubscriptions) {
      for (let i = 0; i < filterSubscriptions?.length; i += 1) {
        const subscriptionFiltered = filterSubscriptions[i];

        if (
          subscriptionFiltered &&
          subscriptionFiltered?.miscellaneous &&
          subscriptionFiltered?.miscellaneous?.generalComment
        ) {
          setLoading(true);
          const allComments =
            subscriptionFiltered?.miscellaneous?.generalComment;

          const response = await getClassVacancyById(
            subscriptionFiltered.classVacancyId,
          );

          const title = response?.data?.title;
          const classVacancyIdFiltered = response?.data?._id;

          const sameStage: { [x: string]: GeneralCommentsTDO[] } = {};

          for (let idx = 0; idx < allComments?.length; idx += 1) {
            const item = allComments[idx] as GeneralCommentsTDO;
            const getUser = await getUserById(item?.userId ?? "");

            if (getUser?._id === item?.userId) {
              item.userName = getUser?.profile?.name ?? "";
              item.photo = getUser?.profile?.photo ?? "";
            }

            const element = item?.element ?? "GENERAL";

            if (!sameStage[element]) sameStage[element] = [];

            sameStage[element].push(item);
          }

          const holeObjectComments = {
            title,
            classVacancyId: classVacancyIdFiltered,
            stages: sameStage,
          };
          newArray.push(holeObjectComments);

          setDataComment(newArray);
          setLoading(false);
        } else {
          setNoComments(true);
        }
      }
    }
  }, [allSubscriptions, subscription]);

  useEffect(() => {
    if (previousComments) mapSubscription();
  }, [getSubsAndUser, mapSubscription, previousComments]);

  if (!data || !subscription || loading)
    return (
      <div className="subscriptions-loading subscriptions-loading-propose">
        <CircularProgress />
      </div>
    );

  return (
    <div className="modal-comments">
      <div className="modal-comments-items mb-32">
        {!previousComments &&
          data &&
          Object.entries(data)?.map(([key, users]) => (
            <div key={Math.random()}>
              <div className="modal-comments-header">
                <p>{moment(key).format("DD/MM/YYYY")}</p>
              </div>
              {users?.map((subitem) => (
                <div className="modal-comments-comment">
                  {subitem?.photo ? (
                    <img
                      className="border-avatar-empty img-attachment-photo-small"
                      alt=""
                      src={subitem?.photo}
                    />
                  ) : (
                    <AvatarEmpty
                      className="border-avatar-empty img-attachment-photo-small"
                      heightIcon={32}
                      widthIcon={32}
                    />
                  )}
                  <div className="modal-comments-comment-body">
                    <span> {subitem?.userName}</span>
                    <div className="h1-recent-search">{subitem?.text}</div>
                  </div>

                  <div className="modal-comments-comment-body-buttons">
                    {subitem?.userId === userLogged?._id && (
                      <div className="modal-comments-comment-body-buttons">
                        <button
                          type="button"
                          aria-label="edit"
                          onClick={() => {
                            setComment({
                              key: subitem?.element ? subitem?.element : "",
                              type: "",
                              date: subitem?.date,
                              comment: subitem?.text,
                            });

                            setEditComment({
                              user: subitem?.userId ? subitem?.userId : "",
                              state: true,
                              comment: subitem?.text,
                            });
                          }}
                        >
                          <Edit />
                        </button>

                        <button
                          aria-label="delete"
                          type="button"
                          onClick={() => {
                            removeComment({
                              key: subitem?.element ? subitem?.element : "",
                              type: "",
                              date: subitem?.date
                                ? subitem?.date
                                : moment()
                                    .tz("America/Sao_Paulo")
                                    .format("DD/MM/YYYY"),
                              comment: subitem?.text,
                            });
                          }}
                        >
                          <Delete />
                        </button>
                      </div>
                    )}

                    <button
                      type="button"
                      onClick={() => {
                        resolveComment(!subitem?.resolved, {
                          key: subitem?.element ? subitem?.element : "",
                          type: "",
                          date: subitem?.date
                            ? subitem?.date
                            : moment()
                                .tz("America/Sao_Paulo")
                                .format("DD/MM/YYYY"),
                          comment: subitem?.text,
                        });
                      }}
                    >
                      {loadingButton &&
                      loadingButton?.key === subitem?.element &&
                      loadingButton?.comment === subitem?.text ? (
                        <CircularProgress size={18} />
                      ) : !subitem?.resolved ? (
                        <Check />
                      ) : (
                        <ArrowBack />
                      )}
                      <p>{subitem?.resolved ? "Reabrir" : "Resolver"}</p>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>

      {previousComments && dataComment?.length && (
        <div className="modal-comments-items previous-comments">
          <div className="previous-comments-header">
            <div>
              <div className="previous-comments-header-title text-p">
                Processo
              </div>
            </div>
            <div className="previous-comments-header-buttons">
              {dataComment?.length &&
                dataComment?.map(
                  (item: { title?: string; classVacancyId?: string }) => (
                    <Button
                      key={Math.random()}
                      text={item?.title ?? ""}
                      onClick={() =>
                        setSelectedClassVacancy(item?.classVacancyId ?? "")
                      }
                      className={
                        selectedClassVacancy === item?.classVacancyId
                          ? "button-selected"
                          : ""
                      }
                    />
                  ),
                )}
            </div>
          </div>

          {!noComments &&
            dataComment?.map(
              (itemSelected) =>
                itemSelected?.classVacancyId === selectedClassVacancy && (
                  <div className="previous-comments-body">
                    <div className="previous-comments-body">
                      <div className="previous-comments-body-stage-section">
                        {itemSelected?.stages &&
                          Object.entries(itemSelected?.stages)?.map(
                            ([key, stageComment]) => (
                              <div>
                                <div className="previous-comments-body-stage-section-title">
                                  {StageType[key as keyof typeof StageType] ===
                                  undefined ? (
                                    <p>GERAL</p>
                                  ) : (
                                    <p>
                                      ETAPA:
                                      <span>
                                        {
                                          StageType[
                                            key as keyof typeof StageType
                                          ]
                                        }
                                      </span>
                                    </p>
                                  )}
                                </div>
                                <div className="modal-comments-items mb-32">
                                  {Object.values(stageComment).map(
                                    (valueStage, index) => (
                                      <div className="previous-comments-body-stage-section-container">
                                        {(index === 0 ||
                                          stageComment[index - 1]?.date !==
                                            valueStage?.date) && (
                                          <div className="modal-comments-header">
                                            <p>{valueStage?.date}</p>
                                          </div>
                                        )}
                                        <div className="modal-comments-comment">
                                          {valueStage?.photo ? (
                                            <img
                                              className="border-avatar-empty img-attachment-photo-small"
                                              alt=""
                                              src={valueStage?.photo}
                                            />
                                          ) : (
                                            <AvatarEmpty
                                              className="border-avatar-empty img-attachment-photo-small"
                                              heightIcon={32}
                                              widthIcon={32}
                                            />
                                          )}
                                          <div className="modal-comments-comment-body">
                                            <span> {valueStage?.userName}</span>
                                            <div className="h1-recent-search">
                                              {valueStage?.text}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            ),
                          )}
                      </div>
                    </div>
                  </div>
                ),
            )}
        </div>
      )}

      {!previousComments && (
        <TextArea
          id="description"
          label="Escreva seu comentário"
          value={comment?.comment}
          maxLength={500}
          textFielRow={4}
          onChange={(e) =>
            setComment({
              key: step,
              type: "",
              date: moment().tz("America/Sao_Paulo").format("DD/MM/YYYY"),
              comment: e.target.value,
            })
          }
          className="mb-32"
        />
      )}

      {previousComments && noComments && (
        <div className="modal-comments-header">
          <p>Sem comentários</p>
        </div>
      )}

      {!previousComments && (
        <div className="admin-subscriptions-infos-buttons">
          <Button
            text="Cancelar"
            outline
            appearance="secondary"
            onClick={onClose}
          />
          <Button
            text={!editComment?.state ? "Adicionar" : "Editar"}
            disabled={comment?.comment === "" || loading}
            onClick={!editComment?.state ? submitComments : editComments}
          />
        </div>
      )}
    </div>
  );
};

export default Comments;
