/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
// @ts-ignore
import { encrypt, decrypt } from "@danilo_pereira/json-crypto";

const thirtyTwoHash = "4bdd7d8cdf787b4b8b1b88d2d123010c";
const sixteenHash = "a8aec73c3091cc99";

export function encode(object: object | object[]): object | object[] {
  return encrypt(JSON.stringify(object), true, thirtyTwoHash, sixteenHash);
}

export function decode(json: object | object[]): object | object[] | never {
  return decrypt(json, true, thirtyTwoHash, sixteenHash);
}
