import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { Button, Progress } from "../../../components";
import {
  calcSubscriptionRanking,
  getActiveUserClassVacancySubscription,
  getClassVacancyById,
  sendCandidateSignUpEmail,
  sendCandidateSignUpWithMediaEmail,
  sendCandidateSignUpWithOptionalMediaEmail,
  upClassVacancySubscriptionStage,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import { createCourseSubscriptionLog } from "../../../services/historicFunctions";
import { setStepRegister } from "../../../store/actions/stepRegisterActions";
import { RootState } from "../../../store/reducers";
import { CourseSubscriptionState } from "../../../types/constants/Historic";
import { classVacancyType } from "../../../types/enumerators";
import { CourseSubscriptionAction } from "../../../types/enumerators/actions/CourseSubscriptionAction";
import {
  ClassVacancyStages,
  ClassVacancySubscription,
} from "../../../types/interfaces";
import { redirectCandidate, validateCourseSubscription } from "../../../utils";
import "../style.scss";
import { setLoad } from "../../../store/actions/configurationsActions";
import { setCourseSubscription } from "../../../store/actions/courseActions";

const Completed = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);

  const onSend = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const subscription = await getActiveUserClassVacancySubscription(user._id);

    if (subscription?.data?.length) {
      const dataSubscription: ClassVacancySubscription =
        subscription?.data?.[0];

      const reponseClassVacany = await getClassVacancyById(
        dataSubscription?.classVacancyId,
      );
      const classVacancy = reponseClassVacany?.data;

      if (
        validateCourseSubscription(
          dataSubscription,
          classVacancy?.template,
          user,
        )?.length
      )
        return;

      const subscriptionStage = classVacancy?.stages?.find(
        (item: { type: keyof typeof classVacancyType }) =>
          item?.type === classVacancyType.SUBSCRIPTION,
      );

      const currentSubscriptionStage = dataSubscription?.stages?.find(
        (item) => item?.stageId === subscriptionStage?.stage_id,
      );
      const index = dataSubscription?.stages?.findIndex(
        (item) => item?.stageId === subscriptionStage?.stage_id,
      );

      const grade = await calcSubscriptionRanking(dataSubscription?._id ?? "");
      if (currentSubscriptionStage && index !== -1 && grade) {
        currentSubscriptionStage.grade = [
          { type: "FINAL", value: parseFloat(grade?.score?.toFixed(2)) ?? 0 },
        ];
        dataSubscription.stages[index] = currentSubscriptionStage;
      }

      await updateClassVacancySubscriptionById(dataSubscription?._id ?? "", {
        stages: dataSubscription?.stages,
      });

      await upClassVacancySubscriptionStage(dataSubscription?._id ?? "");

      const stageMedia = classVacancy?.stages?.find(
        (item: ClassVacancyStages) =>
          item?.type === classVacancyType.UPLOAD_MEDIAS,
      );

      if (stageMedia) {
        if (stageMedia?.optional)
          await sendCandidateSignUpWithOptionalMediaEmail(
            [user?.profile?.email ?? user?.login?.identification],
            classVacancy?.title,
          );
        else
          await sendCandidateSignUpWithMediaEmail(
            [user?.profile?.email ?? user?.login?.identification],
            classVacancy?.title,
            moment(stageMedia?.endDate).format("DD/MM/YYYY"),
          );
      } else
        await sendCandidateSignUpEmail(
          [user?.profile?.email ?? user?.login?.identification],
          classVacancy?.title,
        );

      const newSubscription = await getActiveUserClassVacancySubscription(
        user._id,
      );

      if (newSubscription?.data?.length) {
        dispatch(
          setCourseSubscription({
            ...newSubscription?.data[0],
          }) as unknown as Action,
        );

        redirectCandidate(classVacancy, newSubscription?.data[0], history);
      }

      dispatch(setLoad(false) as unknown as Action);
    } else toast.error("Ocorreu um erro ao enviar os seus dados");
  }, [dispatch, history, user]);

  return (
    <div className="container-completed">
      <div>
        <h1 className="title-completed">Muito obrigada!</h1>
        <p className="paragraph-completed">
          Você concluiu sua inscrição! Envie seus dados e fique de olho no seu
          e-mail, uma mensagem automática será enviada a você e nela consta os
          próximos passos.
        </p>
      </div>

      <div className="progress-area">
        <Progress className="progress" value={100} />
      </div>
      <div className="flex-direction-column-reverse">
        <Button
          text="Revisar dados"
          className="review-button"
          appearance="secondary"
          outline
          onClick={() => {
            createCourseSubscriptionLog(
              user._id,
              CourseSubscriptionAction.COURSE_SUBSCRIPTION_FORM_REVIEW,
              CourseSubscriptionState.FORM_DONE,
              CourseSubscriptionState.FORM_REVIEW,
            );
            dispatch(setStepRegister({ page: 0 }) as unknown as Action);
          }}
        />
        <Button text="Enviar" className="send-button" onClick={onSend} />
      </div>
    </div>
  );
};
export default memo(Completed);
