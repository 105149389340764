/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from "react";
import {
  Checkbox,
  Card,
  UsersProfile,
  ModalSendInvite,
  Button,
  ButtonFilter,
  Pagination,
  UserCard,
  ModalConfigAndFilters,
  ModalSendMessage,
} from "../../components";
import "./style.scss";

interface Props {
  styled: any;
  page: any;
  setPage: any;
  maxPage: any;
  users: any;
  options: any;
  suggestedUser: any;
  filterUserList: any;
  selectedUsers: any;
  setSelectedUsers: any;
  applyFilters: any;
  tempFilters: any;
  setTempFilters: any;
  isLocalization: any;
  setIsLocalization: any;
  isEducation: any;
  setIsEducation: any;
  isSkill: any;
  setIsSkill: any;
  isLanguage: any;
  setIsLanguage: any;
  choosedUserId: any;
  setChoosedUserId: any;
  openModal: any;
  setOpenModal: any;
  openModalSend: any;
  setOpenModalSend: any;
  openModalInvite: any;
  setOpenModalInvite: any;
  handleCloseFilter: any;
  handleChangeFilters: any;
  onSearch: any;
  onClear: any;
  onClearModalFilters: any;
  handleCheckboxSelect: any;
  hasVacancies: any;
}

const MobileCompanySearch = ({
  styled,
  page,
  setPage,
  maxPage,
  users,
  options,
  suggestedUser,
  filterUserList,
  selectedUsers,
  setSelectedUsers,
  applyFilters,
  tempFilters,
  setTempFilters,
  isLocalization,
  setIsLocalization,
  isEducation,
  setIsEducation,
  isSkill,
  setIsSkill,
  isLanguage,
  setIsLanguage,
  choosedUserId,
  setChoosedUserId,
  openModal,
  setOpenModal,
  openModalSend,
  setOpenModalSend,
  openModalInvite,
  setOpenModalInvite,
  handleCloseFilter,
  handleChangeFilters,
  onSearch,
  onClear,
  onClearModalFilters,
  handleCheckboxSelect,
  hasVacancies,
}: Props): JSX.Element => (
  <>
    <div
      className={`grid-column-1-13 max-height-search-filters max-height-search-filters-company ${styled}`}
    >
      <div className="scroll-filter">
        <div className="flex">
          <div
            className={`filter-vacancy-place margin-left-16 ${
              tempFilters?.localization && !isLocalization && "margin-right-16"
            }`}
          >
            <ButtonFilter
              onClick={() => handleCloseFilter("localization")}
              onClose={() => {
                setIsLocalization(false);
                setTempFilters(applyFilters);
              }}
              onChange={handleChangeFilters}
              onSearch={onSearch}
              onClear={() => onClear("localization")}
              text="Localização"
              isOpen={isLocalization}
              values={filterUserList.localization}
              checkedList={tempFilters?.localization ?? []}
              prop="localization"
            />
          </div>
          <div
            className={` ${
              tempFilters?.education && !isEducation
                ? "width-184"
                : "filter-company-education"
            }`}
          >
            <ButtonFilter
              onClick={() => handleCloseFilter("education")}
              onClose={() => {
                setIsEducation(false);
                setTempFilters(applyFilters);
              }}
              onChange={handleChangeFilters}
              onSearch={onSearch}
              onClear={() => onClear("education")}
              text="Instituição de ensino"
              isOpen={isEducation}
              values={filterUserList.education}
              checkedList={tempFilters?.education ?? []}
              prop="education"
            />
          </div>
          <div
            className={`filter-company-skill ${
              tempFilters?.skill && !isSkill && "margin-right-16"
            }`}
          >
            <ButtonFilter
              onClick={() => handleCloseFilter("skill")}
              onClose={() => {
                setIsSkill(false);
                setTempFilters(applyFilters);
              }}
              onChange={handleChangeFilters}
              onSearch={onSearch}
              onClear={() => onClear("skill")}
              text="Habilidade"
              isOpen={isSkill}
              values={filterUserList.skill}
              checkedList={tempFilters?.skill ?? []}
              prop="skill"
            />
          </div>
          <div
            className={`filter-company-language ${
              tempFilters?.language && !isLanguage && "margin-right-16"
            }`}
          >
            <ButtonFilter
              onClick={() => handleCloseFilter("language")}
              onClose={() => {
                setIsLanguage(false);
                setTempFilters(applyFilters);
              }}
              onChange={handleChangeFilters}
              onSearch={onSearch}
              onClear={() => onClear("language")}
              text="Idioma"
              isOpen={isLanguage}
              values={filterUserList.language}
              checkedList={tempFilters?.language ?? []}
              prop="language"
            />
          </div>
        </div>
      </div>
    </div>
    <div className={`relative margin-top-10-mobile ${styled}`}>
      <Card hideBorderRadius bodyClassName="card-search">
        {users?.length > 0 && (
          <div className="divider-company-search flex-between padding-16-mobile">
            <Checkbox
              className=" Checkboxcolor"
              id="sendMessage"
              checked={selectedUsers?.length === users?.length}
              onClick={() => {
                if (selectedUsers?.length === users?.length)
                  setSelectedUsers([]);
                else setSelectedUsers(users);
              }}
            />
            {selectedUsers?.length > 0 && (
              <div className="header-button-wrapper">
                <Button
                  text="Enviar convite"
                  onClick={() => setOpenModalSend(true)}
                  className="margin-top-0-mobile"
                />
              </div>
            )}
          </div>
        )}
        {users?.length > 0 ? (
          users?.map((person: any) => (
            <UserCard
              person={person}
              handleCheck={handleCheckboxSelect}
              hasVacancies={hasVacancies}
              selectedUsers={selectedUsers}
              setChoosedUserId={setChoosedUserId}
              setOpenModalInvite={setOpenModalInvite}
            />
          ))
        ) : (
          <UserCard blank />
        )}
      </Card>
    </div>
    <ModalConfigAndFilters
      openModal={openModal}
      onClose={() => setOpenModal(false)}
      textHeaderModal="Todos os Filtros"
      filters={options}
      onClear={() => onClearModalFilters()}
      onSearch={() => {
        onSearch();
        setOpenModal(false);
      }}
    />
    {suggestedUser && suggestedUser.length > 0 && (
      <UsersProfile users={suggestedUser ?? []} row="2" hasButton={false} />
    )}
    {suggestedUser || users?.length > 0 ? (
      <div className="grid-column-1-11 grid-row-3 flex justify-center margin-suggested-user">
        <div>
          <Pagination
            page={page}
            setPage={setPage}
            maxPage={maxPage}
            showAllOption
          />
        </div>
      </div>
    ) : null}
    <ModalSendInvite
      userId={choosedUserId}
      openModal={openModalInvite}
      onClose={() => setOpenModalInvite(false)}
    />
    <ModalSendMessage
      setSelectedUsers={setSelectedUsers}
      selectedUsers={selectedUsers}
      openModal={openModalSend}
      onClose={() => setOpenModalSend(false)}
    />
  </>
);

export default memo(MobileCompanySearch);
