import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import { setLoad } from "../../store/actions/configurationsActions";
import {
  Button,
  Card,
  HeaderProfile,
  Layout,
  Pagination,
  Table,
  ModalInviteCompany,
} from "../../components";
import {
  filterPlacementVacancy,
  getPages,
  updateVacancyById,
} from "../../services/functions";
import {
  PlacementVacancy,
  PlacementVacancyWithCorporateData,
} from "../../types/interfaces";
import { RootState } from "../../store/reducers";
import {
  placementVacancyManagedBy,
  placementVacancyStatus as placementVacancyStatusEnum,
} from "../../types/enumerators";
import {
  PlacementVacancyStatus,
  PlacementVacancyTypeOfCandidature,
} from "../../types/constants/Admin";
import { ManagedBy } from "../../types/constants/Vacancy";
import "./style.scss";

const AdminPlacementVacancy = (): JSX.Element => {
  const [list, setList] = useState<PlacementVacancyWithCorporateData[]>();
  const [filters, setFilters] = useState<{
    status?: string;
    managedBy?: string;
  }>();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [config, setConfig] = useState({ asc: true, sortBy: "title" });
  const [openModalInviteCompany, setOpenModalInviteCompany] = useState(false);

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const getList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    setMaxPage(await getPages(filterPlacementVacancy, filters, true));

    const response =
      (await filterPlacementVacancy(filters, false, page, pageLimit)) ?? [];
    setList(
      response?.map((item) => ({
        ...item,
        vacancyTypeOfCandidature:
          PlacementVacancyTypeOfCandidature[
            (item?.typeOfCandidature ??
              "") as keyof typeof PlacementVacancyTypeOfCandidature
          ],
        vacancyManagedBy: ManagedBy[item?.managedBy as keyof typeof ManagedBy],
        startDate: moment(item?.startDate).format("DD/MM/YYYY"),
        endDate: moment(item?.endDate).format("DD/MM/YYYY"),
        qtd:
          item?.managedBy === placementVacancyManagedBy.EXTERNAL_VACANCY
            ? item?.clicks
            : item?.subscriptions,
      })),
    );

    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, filters, page, pageLimit]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page, pageLimit]);

  const headCells = useMemo(
    () => [
      {
        id: "photo",
        label: "",
        orderBy: false,
        classColumn: "w-40",
      },
      {
        id: "title",
        label: "Título",
        orderBy: true,
        sort: "title",
        asc: config?.asc,
        onclick: () =>
          setConfig((prev) => ({
            asc: !prev?.asc,
            sortBy: "title",
          })),
        classColumn: "w-130",
      },
      {
        id: "companyName",
        label: "Empresa",
        orderBy: true,
        sort: "companyName",
        asc: config?.asc,
        onclick: () =>
          setConfig((prev) => ({
            asc: !prev?.asc,
            sortBy: "companyName",
          })),
        classColumn: "w-130",
      },
      {
        id: "vacancyManagedBy",
        label: "Tipo",
        orderBy: true,
        sort: "managedBy",
        asc: config?.asc,
        onclick: () =>
          setConfig((prev) => ({
            asc: !prev?.asc,
            sortBy: "managedBy",
          })),
        classColumn: "w-90",
      },
      {
        id: "type",
        label: "Regime",
        orderBy: true,
        sort: "type",
        asc: config?.asc,
        onclick: () =>
          setConfig((prev) => ({
            asc: !prev?.asc,
            sortBy: "type",
          })),
        classColumn: "w-80",
      },
      {
        id: "vacancyTypeOfCandidature",
        label: "Candidatura",
        orderBy: true,
        sort: "typeOfCandidature",
        asc: config?.asc,
        onclick: () =>
          setConfig((prev) => ({
            asc: !prev?.asc,
            sortBy: "typeOfCandidature",
          })),
        classColumn: "w-110",
      },
      {
        id: "qtd",
        label: "Candidatas",
        orderBy: false,
        classColumn: "w-105",
      },
      {
        id: "startDate",
        label: "Abertura",
        orderBy: false,
        classColumn: "w-90",
      },
      {
        id: "endDate",
        label: "Encerramento",
        orderBy: false,
        classColumn: "w-90",
      },
      {
        id: "status",
        label: "Status",
        orderBy: false,
        classColumn: "w-160",
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
        classColumn: "w-240",
      },
    ],
    [config?.asc],
  );

  const onChangeStatus = useCallback(
    async (row: { _id: string }, status: string) => {
      const newRow = { status };
      try {
        await updateVacancyById(row?._id, newRow as PlacementVacancy);
        return true;
      } catch {
        return false;
      }
    },
    [],
  );

  return (
    <Layout className="admin-class admin-subscriptions not-volunteer-media">
      <HeaderProfile />
      <Button
        text="Convidar"
        className="grid-column-11-13 grid-row-1 request-vacancy"
        onClick={() => setOpenModalInviteCompany(true)}
      />
      <Button
        text="Criar vaga"
        className="grid-column-9-11 grid-row-1 request-vacancy"
        onClick={() => history.push("company-vacancy-new")}
      />
      <Card
        className=" grid-column-1-13 grid-row-2 margin-bottom-32"
        bodyClassName="padding-0"
      >
        <TableRow className="admin-class-filters">
          <div className="admin-class-radios">
            <span className="admin-class-radios-title">Status</span>
            <RadioGroup value={filters?.status || undefined}>
              <div>
                <FormControlLabel
                  key={Math.random()}
                  value={null}
                  control={<Radio />}
                  label="Todas"
                  checked={!filters?.status}
                  className="margin-right-32"
                  onChange={() => setFilters({ ...filters, status: undefined })}
                />
                {Object.keys(PlacementVacancyStatus)?.map((key) => (
                  <FormControlLabel
                    key={Math.random()}
                    value={key}
                    control={<Radio />}
                    label={
                      PlacementVacancyStatus[
                        key as keyof typeof PlacementVacancyStatus
                      ]
                    }
                    checked={key === filters?.status}
                    className="margin-right-32"
                    onChange={() => setFilters({ ...filters, status: key })}
                  />
                ))}
              </div>
            </RadioGroup>
          </div>

          <div className="admin-class-radios">
            <span className="admin-class-radios-title">Tipo</span>
            <RadioGroup value={filters?.managedBy || undefined}>
              <div>
                <FormControlLabel
                  key={Math.random()}
                  value={null}
                  control={<Radio />}
                  label="Todas"
                  checked={!filters?.managedBy}
                  className="margin-right-32"
                  onChange={() =>
                    setFilters({ ...filters, managedBy: undefined })
                  }
                />
                {Object.keys(placementVacancyManagedBy)?.map((key) => (
                  <FormControlLabel
                    key={Math.random()}
                    value={key}
                    control={<Radio />}
                    label={
                      ManagedBy[key as keyof typeof placementVacancyManagedBy]
                    }
                    checked={key === filters?.managedBy}
                    className="margin-right-32"
                    onChange={() => setFilters({ ...filters, managedBy: key })}
                  />
                ))}
              </div>
            </RadioGroup>
          </div>
        </TableRow>
        <Table
          cantSelect
          emptyText="Não há vagas cadastradas"
          headCells={headCells}
          list={list}
          onReload={getList}
          onShow={(row) => history.push(`/vacancy-descriptionn/${row._id}`)}
          onEdit={(row) => history.push(`company-vacancy-edit/${row._id}`)}
          onActive={(row) =>
            onChangeStatus(
              row,
              row.status === placementVacancyStatusEnum.ACTIVE
                ? placementVacancyStatusEnum.PENDING
                : placementVacancyStatusEnum.ACTIVE,
            )
          }
          onRemove={(row) =>
            onChangeStatus(row, placementVacancyStatusEnum.DISABLED)
          }
          onFinish={(row) =>
            onChangeStatus(row, placementVacancyStatusEnum.FINISHED)
          }
        />
      </Card>
      <div className="grid-row-3 grid-column-1-13 width-100 flex margin-top--45">
        <div className="width-100 justify-center">
          <Pagination page={page} setPage={setPage} maxPage={maxPage} />
        </div>
      </div>

      <ModalInviteCompany
        open={openModalInviteCompany}
        onClose={() => setOpenModalInviteCompany(false)}
      />
    </Layout>
  );
};

export default memo(AdminPlacementVacancy);
