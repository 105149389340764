import React from "react";
import "./styles.scss";

interface Props {
  label: string;
  value: number | string;
}

const LabelComponent = ({ label, value }: Props): JSX.Element => (
  <>
    <p className="p-label-label-component">{label}</p>
    <p className="p-value-label-component">{value}</p>
  </>
);

export default LabelComponent;
