/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

interface Props {
  items: any;
  reorderItem?: (items: any) => void;
  children: any;
}

const Draggable = ({ items, reorderItem, children }: Props): JSX.Element => {
  const reorder = (startIndex: number, endIndex: number) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newItems = reorder(result.source.index, result.destination.index);

    if (reorderItem) reorderItem(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="skills">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default memo(Draggable);
