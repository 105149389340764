/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  LegacyRef,
  createRef,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { ViewWork } from "../../../components";
import { RootState } from "../../../store/reducers";

interface Props {
  selected: string;
}

const ViewCurriculum = ({ selected }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const user = useSelector((state: RootState) => state.userState);
  const [minWidth, setMinWidth] = useState(0);

  const refs: { current: { offsetWidth: number | null } | null }[] = useMemo(
    () =>
      Array.from({ length: user?.curriculum?.skills?.length }).map(() =>
        createRef(),
      ),
    [user?.curriculum?.skills?.length],
  );

  useEffect(() => {
    if (user?.curriculum?.skills?.length === refs?.length) {
      let size = 0;
      let index = -1;
      refs?.forEach((item: any, i: number) => {
        if (item?.current?.offsetWidth > size) {
          size = item?.current?.offsetWidth;
          index = i;
        }
      });
      setMinWidth(refs[index]?.current?.offsetWidth ?? 0);
    }
  }, [user?.curriculum?.skills, user?.curriculum?.skills?.length, refs]);

  useEffect(() => {
    if (selected === "skills" && user?.curriculum?.skills?.length)
      user?.curriculum?.skills?.sort(
        (
          a: {
            order: number;
          },
          b: {
            order: number;
          },
        ) => {
          if (a?.order && b?.order) {
            if (a?.order < b?.order) return -1;
            if (a?.order > b?.order) return 1;
            return 0;
          }
          return 0;
        },
      );
  }, [user?.curriculum?.skills, selected]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [user?.curriculum?.openForProposals]);

  return (
    <div>
      {selected === "general" && (
        <div className="grid-1-column flex-row-center flex-col-mobile align-items-baseline-mobile">
          <div className="flex-col">
            <p className="title-view">Objetivo de carreira</p>
            <p className="view-bold">{user?.curriculum?.goal ?? ""}</p>
          </div>
          <div className="flex-col">
            <p className="title-view">
              Você tem disponibilidade/interesse em mudar de cidade para
              trabalhar?
            </p>
            <p className="view-bold">
              {user?.curriculum?.openForChanges ? "Sim" : "Não"}
            </p>
          </div>
          <div className="flex-col">
            <p className="title-view">
              Você tem disponibilidade/interesse em trabalho freelancer?
            </p>
            <p className="view-bold">
              {user?.curriculum?.openForFreelancer ? "Sim" : "Não"}
            </p>
          </div>
          <div className="flex-col">
            <p className="title-view">
              Você quer mostrar no seu perfil que deseja participar de processos
              seletivos?
            </p>
            <p className="view-bold">
              {user?.curriculum?.openForProposals ? "Sim" : "Não"}
            </p>
          </div>
        </div>
      )}
      {selected === "works" && (
        <ViewWork className="padding-0" isMyProfile={false} />
      )}
      {selected === "skills" &&
        (user?.curriculum?.skills?.length > 0 ? (
          user?.curriculum?.skills?.map((item: any, index: number) => (
            <div key={Math.random()}>
              <div className="cards-items-profile flex-row-center">
                <div
                  ref={refs[index] as LegacyRef<HTMLDivElement> | undefined}
                  className="margin-24"
                  style={{ minWidth }}
                >
                  <p className="title-bold">{item?.key}</p>
                  <p className="level-view width-120">
                    {item?.level === 1
                      ? "Básico"
                      : item?.level === 2
                      ? "Intermediário"
                      : "Avançado"}
                  </p>
                </div>
                {width > 990 && (
                  <div className="flex-row-center width-100">
                    <div className="knob" />
                    <div className={`knob ${item?.level < 2 ? "empty" : ""}`} />
                    <div className={`knob ${item?.level < 3 ? "empty" : ""}`} />
                  </div>
                )}
              </div>
              {width < 991 && (
                <div className="flex-row-center width-100 margin-knob-mobile">
                  <div className="knob" />
                  <div className={`knob ${item?.level < 2 ? "empty" : ""}`} />
                  <div className={`knob ${item?.level < 3 ? "empty" : ""}`} />
                </div>
              )}
              {index < (user?.curriculum?.skills?.length ?? 0) - 1 && (
                <div className="divider-card" />
              )}
            </div>
          ))
        ) : (
          <p className="paragraph-not-registered">
            Não há habilidades cadastradas
          </p>
        ))}
    </div>
  );
};

export default memo(ViewCurriculum);
