import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
} from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { ArrowLeft } from "../../../../assets/customIcons";
import { Button, IconButton, Input } from "../../../components";
import {
  createCandidate,
  requestMagicLink,
  thisUserAlreadyExists,
} from "../../../services/functions";
import { userRoles } from "../../../types/enumerators";

interface Props {
  clear: () => void;
  setError?: Dispatch<SetStateAction<boolean>>;
  type: string;
}

const SendEmail = ({ clear, setError, type }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [sendEmail, setSendEmail] = useState(false);
  const [email, setEmail] = useState("");

  const handleResetPassword = useCallback(async () => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().email().required(),
      });
      await schema.validate(
        { email },
        {
          abortEarly: false,
        },
      );

      const hasUser = await thisUserAlreadyExists(email);
      if (!hasUser?.hasEmail) {
        if (type === userRoles.CANDIDATE) await createCandidate(email);
        else if (setError) setError(true);
      }

      const response = await requestMagicLink(email);

      if (response) setSendEmail(true);
      else toast.error("Verifique seu email");
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.error("Verifique seu email");
        return;
      }
      toast.error("Credenciais inválidas");
    }
  }, [email, setError, type]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (email) handleResetPassword();
      }
    },
    [email, handleResetPassword],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return sendEmail ? (
    <div className="padding-bottom-32 type-access">
      <p className="title-login">
        Siga as instruções no seu e-mail para definir a sua senha.
      </p>
      <IconButton
        text="Voltar para o login"
        onClick={clear}
        icon={
          <ArrowLeft
            widthIcon={width < 991 ? 24 : 32}
            heightIcon={width < 991 ? 24 : 32}
          />
        }
        className="button-back-login flex align-items"
      />
    </div>
  ) : (
    <form onKeyDown={handleKeyDown}>
      <div className=" margin-bottom-32 type-access">
        <p className="title-login">Confirme o seu e-mail</p>
        <p className="help-text">
          Enviaremos instruções para criação da senha no e-mail informado
        </p>
        <Input
          id="email"
          label="E-mail"
          type="text"
          value={email}
          className="input-email email-input-login"
          onChange={(e: { target: { value: string } }) =>
            setEmail(e.target.value.toLowerCase().trim())
          }
        />
        <Button
          onClick={handleResetPassword}
          text="Enviar"
          className="margin-bottom-32 width-100"
        />
        <button
          type="button"
          onClick={clear}
          className="margin-bottom-32 button-borderless"
        >
          Cancelar
        </button>
      </div>
    </form>
  );
};

export default memo(SendEmail);
