import React, { memo } from "react";
import { placementVacancyType } from "../../types/enumerators";
import "./styles.scss";

interface Props {
  className?: string;
  type: string;
  place: string;
}

const JobInformation = ({
  className = "",
  type,
  place,
}: Props): JSX.Element => (
  <p className={`job-inf-text job-inf-location ${className}`}>
    {type === placementVacancyType.Remota
      ? type
      : type === placementVacancyType.Híbrida
      ? `${type} - ${place}`
      : place}
  </p>
);

export default memo(JobInformation);
