/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, { memo, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Checkbox, InputAutocomplete, Modal, TextArea } from "..";
import { AvatarEmpty } from "../../../assets/customIcons";
import { placementVacancyStatus } from "../../types/enumerators";
import {
  sendInviteToUser,
  searchCorporatePlacementVacancy,
} from "../../services/functions";
import { PlacementVacancy } from "../../types/interfaces";
import { RootState } from "../../store/reducers";
import { DeleteInvitation, Info } from "../../../assets/icons";
import "./styles.scss";

interface UserProps {
  _id: string;
  personName: string;
  personPosition: string;
  personCity: string;
  personDescription: string;
  personPhoto: string;
  personEmail: string;
}

interface Props {
  openModal: boolean;
  selectedUsers: UserProps[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<UserProps[]>>;
  onClose: () => void;
}

const ModalSendMessage = ({
  openModal,
  onClose,
  selectedUsers,
  setSelectedUsers,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [vacancies, setVacancies] = useState<PlacementVacancy[]>();
  const [currentVacancy, setCurrentVacancy] = useState<string>();
  const [message, setMessage] = useState("");
  const [sendMsgInvite, setSendMsgInvite] = useState(false);
  const user = useSelector((state: RootState) => state.userState);
  const canSendMsgInvite = false;

  const loadCompanyVacancies = useCallback(async () => {
    if (user?.account?.companyIds) {
      try {
        const response = await searchCorporatePlacementVacancy(
          user?.account?.companyIds[0],
          0,
        );
        if (response && response?.length) {
          const validVacancies = response?.filter(
            (item) => item.status === placementVacancyStatus.ACTIVE,
          );
          setCurrentVacancy(validVacancies[0].title);
          setVacancies(validVacancies);
        }
      } catch (err) {
        toast.error("Ocorreu um erro ao buscar as vagas da empresa");
      }
    }
  }, [user?.account?.companyIds]);

  useEffect(() => {
    if (!currentVacancy || !vacancies) loadCompanyVacancies();
  }, [
    currentVacancy,
    loadCompanyVacancies,
    user?.account?.companyIds,
    vacancies,
  ]);

  const handleSendInvite = useCallback(async () => {
    const currentVacancyToSend = vacancies?.find(
      (item) => item.title === currentVacancy,
    );

    if (currentVacancyToSend?._id && selectedUsers?.length) {
      try {
        const errors = [];

        for (let i = 0; i < selectedUsers?.length; i += 1) {
          const response = await sendInviteToUser(
            currentVacancyToSend?._id,
            selectedUsers[i]?._id ?? "",
            sendMsgInvite ? message : "",
            user._id,
          );
          if (!response) errors.push(selectedUsers[i]);
        }

        if (!errors?.length) {
          toast.success("Convites enviados com sucesso!");
          onClose();
        } else {
          const users = errors?.map((item) => item.personName)?.join(", ");
          toast.warn(`Não foi possível enviar convite para: ${users}`);
          onClose();
        }
      } catch (err) {
        toast.error("Ocorreu um erro ao enviar o convite");
        onClose();
      } finally {
        setSelectedUsers([]);
      }
    }
  }, [
    onClose,
    message,
    user._id,
    vacancies,
    sendMsgInvite,
    selectedUsers,
    currentVacancy,
    setSelectedUsers,
  ]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      headerAction
      textHeader="Enviar convite em massa"
      open={openModal}
      onClose={onClose}
      bodyClassName="padding-0"
    >
      <div className="modal-message-content">
        <p className="title-modal">Enviando para:</p>
        <div className="users-list">
          {selectedUsers.map((person) => (
            <div className="user">
              {person.personPhoto ? (
                <img
                  className="person-photo-send-message"
                  src={person.personPhoto}
                  alt="Foto"
                />
              ) : (
                <AvatarEmpty className="person-photo-send-message" />
              )}

              <span>{person?.personName}</span>
              <DeleteInvitation
                onClick={() =>
                  setSelectedUsers((current) =>
                    current.filter((item) => item._id !== person._id),
                  )
                }
              />
            </div>
          ))}
        </div>
        {width < 991 && (
          <p className="p-mobile margin-modal-mobile">
            Selecione a vaga para enviar o convite*
          </p>
        )}
        <InputAutocomplete
          label={
            width > 990
              ? "Selecione a vaga para enviar o convite*"
              : "Selecione"
          }
          helperText=""
          value={vacancies ? vacancies[0]?.title : ""}
          onBlur={(event) => setCurrentVacancy(event.target.value)}
          className="input-select-vacancy-invite margin-top-MuiFormLabel-root"
          options={vacancies?.map((item) => item.title ?? "") ?? [""]}
          getOptionLabel={(option) => option}
        />
      </div>

      {canSendMsgInvite && (
        <div className="modal-send-container">
          <button type="button">
            <Info className="icon-info" />
            <p>Ver mensagem padrão</p>
          </button>
          <Checkbox
            id="sendMsgInvite"
            label="Enviar convite personalizado"
            checked={sendMsgInvite}
            onChange={() => setSendMsgInvite(!sendMsgInvite)}
          />
          {sendMsgInvite && width < 991 && (
            <p className="p-mobile margin-top-20-mobile">Mensagem</p>
          )}
          {sendMsgInvite && (
            <TextArea
              id="message"
              label={width > 990 ? "Escreva sua mensagem" : ""}
              maxLength={500}
              value={message ?? ""}
              onChange={(e) => setMessage(e.target.value)}
            />
          )}
        </div>
      )}

      {width > 990 ? (
        <div className="modal-message-items">
          <button
            type="button"
            onClick={() => {
              setMessage("");
              setSendMsgInvite(false);
              onClose();
            }}
            className="modal-message-items-cancel width-100-mobile margin-left-0-mobile"
          >
            Cancelar
          </button>
          <button
            type="button"
            className={`modal-message-items-send margin-top-20-mobile width-100-mobile margin-left-0-mobile ${
              sendMsgInvite && !message ? "disabled" : ""
            }`}
            onClick={handleSendInvite}
            disabled={sendMsgInvite && !message}
          >
            Enviar
          </button>
        </div>
      ) : (
        <div className="modal-message-items">
          <button
            type="button"
            className={`modal-message-items-send width-100-mobile margin-left-0-mobile ${
              sendMsgInvite && !message ? "disabled" : ""
            }`}
            onClick={handleSendInvite}
            disabled={sendMsgInvite && !message}
          >
            Enviar
          </button>
          <button
            type="button"
            onClick={() => {
              setMessage("");
              setSendMsgInvite(false);
              onClose();
            }}
            className="modal-message-items-cancel width-100-mobile margin-left-0-mobile margin-top-24"
          >
            Cancelar
          </button>
        </div>
      )}
    </Modal>
  );
};

export default memo(ModalSendMessage);
