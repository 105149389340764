/* eslint-disable no-await-in-loop */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { Button, InputAutocomplete, Modal } from "../../../components";
import "../style.scss";
import { setLoad } from "../../../store/actions/configurationsActions";
import {
  getAutocomplete,
  getClassVacancySubscriptionById,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import { autoCompleteType } from "../../../types/enumerators";
import { List } from "..";
import { registerAutoComplete } from "../../../utils";

interface Props {
  open: boolean;
  onclose: () => void;
  reload: () => void;
  subscriptions: List[];
}

const Tags = ({ open, onclose, reload, subscriptions }: Props): JSX.Element => {
  const [list, setList] = useState<string[]>();
  const [tags, setTags] = useState<string[]>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (subscriptions?.length === 1) setTags(subscriptions?.[0]?.tags);
  }, [subscriptions]);

  const getTags = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getAutocomplete(autoCompleteType.TAG);
    const optionsAutocompleteTag = response?.map((option) => option?.name);
    setList(optionsAutocompleteTag);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  useEffect(() => {
    if (!list) getTags();
  }, [getTags, list]);

  const handleChange = useCallback((value) => {
    if (value) {
      setTags(value);
      registerAutoComplete(value, autoCompleteType.TAG);
    }
  }, []);

  const onSave = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    let newTags = [...(tags ?? [])];
    if (subscriptions) {
      for (let i = 0; i < subscriptions?.length; i += 1) {
        const response = await getClassVacancySubscriptionById(
          subscriptions[i]?._id ?? "",
        );
        const itemSelectedInitialTags = response?.tags;

        if (itemSelectedInitialTags) {
          let filteredTagNotRemoved;

          if (response?.length > 1)
            filteredTagNotRemoved = itemSelectedInitialTags;
          else
            filteredTagNotRemoved = itemSelectedInitialTags?.filter(
              (item: string) => tags?.includes(item),
            );

          const filteredTagAdded =
            tags?.filter(
              (item: string) => !itemSelectedInitialTags?.includes(item),
            ) ?? [];
          newTags = [...filteredTagAdded, ...filteredTagNotRemoved];
        }

        await updateClassVacancySubscriptionById(subscriptions[i]?._id ?? "", {
          tags: newTags,
        });
      }
    }

    setTags(undefined);
    reload();
    onclose();
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, onclose, reload, subscriptions, tags]);

  return (
    <Modal
      headerAction
      open={open}
      onClose={onclose}
      textHeader="Adicionar tags"
      className="admin-subscriptions-modal-tags"
    >
      <InputAutocomplete
        multiple
        label="Tags*"
        helperText="Pressione Enter para adicionar"
        options={list ?? []}
        getOptionLabel={(option) => option}
        onChange={handleChange}
        value={tags ?? []}
      />
      <div className="admin-subscriptions-modal-buttons margin-top-32">
        <Button
          text="Cancelar"
          onClick={onclose}
          outline
          appearance="secondary"
        />
        <Button
          onClick={onSave}
          text={subscriptions?.length > 1 ? "Adicionar" : "Salvar"}
        />
      </div>
    </Modal>
  );
};

export default memo(Tags);
