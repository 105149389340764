/* eslint-disable no-await-in-loop */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { toast } from "react-toastify";
import { Account as AccountIcon } from "../../../assets/icons";
import { Button, Card, HeaderProfile, Layout } from "../../components";
import { resetPassword } from "../../services/functions";
import { updateUser } from "../../store/actions/userActions";
import { RootState } from "../../store/reducers";
import { userStatus } from "../../types/enumerators";
import { User } from "../../types/interfaces";
import Loading from "../Loading";
import { EditAccount, ViewAccount } from "./Steps";
import "./style.scss";

export interface AdminAccountItem {
  showAvatar?: boolean;
  label: string;
  value: string;
}

const AdminAccount = (): JSX.Element => {
  const dispatch = useDispatch();

  const _user: User = useSelector((state: RootState) => state.userState);
  const [user, setUser] = useState(_user);

  const [password, setPassword] = useState("");
  const [initialPassword, setInitialPassword] = useState(password);

  const isNewUser = _user?.account?.status === userStatus.PENDING;

  const [step] = useState(1);
  const [isEdit, setIsEdit] = useState(isNewUser);
  const [isValid, setIsValid] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (password) setInitialPassword(password);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) setLoading(false);
  }, [user]);

  const itemsMenu = useMemo(
    () => [
      {
        icon: <AccountIcon />,
        text: "Minha conta",
        subText: "Editar",
        id: 1,
      },
    ],
    [],
  );

  const renderPages = useMemo(() => {
    switch (step) {
      case 1:
        return isEdit ? (
          <EditAccount
            user={user}
            setUser={setUser}
            password={password}
            setPassword={setPassword}
            setIsValid={setIsValid}
          />
        ) : (
          <ViewAccount />
        );

      default:
        return <div />;
    }
  }, [isEdit, password, step, user]);

  const onCancel = useCallback(() => {
    setIsEdit(false);
    setUser(_user);
    setPassword("");
  }, [_user]);

  const onSave = useCallback(async (): Promise<void> => {
    setIsEdit(false);

    if (initialPassword !== password) {
      try {
        const response = await resetPassword(password);

        if (response) {
          toast.success("Senha alterada com sucesso");
        }
      } catch (err) {
        toast.error("Ocorreu um erro ao alterar a senha");
      }
    }

    dispatch(
      updateUser(
        isNewUser
          ? {
              ...user,
              account: { ...user.account, status: userStatus.ACTIVE },
            }
          : {
              ...user,
            },
      ) as unknown as Action,
    );
  }, [dispatch, initialPassword, isNewUser, password, user]);

  return !loading ? (
    <Layout>
      {!isNewUser && <HeaderProfile />}
      <div className="grid-column-1-13 grid-row-1">
        <div className="account-header">
          <p>{itemsMenu[step - 1].text}</p>
          {isEdit && <p>&nbsp;&gt; {itemsMenu[step - 1].subText}</p>}
        </div>
      </div>
      <Card
        className={`grid-column-2-12 container-mobile height-fit ${
          isEdit ? "border-card" : ""
        } `}
        titleHeader={itemsMenu[step - 1].text}
      >
        {renderPages}
      </Card>
      {isEdit ? (
        <div className="flex grid-column-8-12 grid-row-3 margin-bottom-48">
          <Button
            text="Cancelar"
            className="button-secondary width-100"
            onClick={onCancel}
          />
          <Button
            text="Salvar"
            className="margin-left-32 width-100"
            onClick={onSave}
            disabled={!isValid}
          />
        </div>
      ) : (
        <Button
          text="Editar"
          className="button-primary grid-row-3 grid-column-10-12 margin-bottom-48"
          onClick={() => setIsEdit(true)}
        />
      )}
    </Layout>
  ) : (
    <Loading />
  );
};

export default memo(AdminAccount);
