import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";
import { classVacancyType } from "../../../../types/enumerators";
import {
  getClassVacancyById,
  getClassVacancySubscriptionById,
} from "../../../../services/functions";
import {
  ClassVacancy,
  ClassVacancyStages,
  ClassVacancySubscription,
  ClassVacancySubscriptionStage,
} from "../../../../types/interfaces";
import { Button } from "../../../../components";
import Historic from "./historic";
import View from "./view";
import "../../style.scss";
import Edit from "./edit";

interface Props {
  id: string;
  onClose: () => void;
  reload: () => void;
}

const susbtepInformations: Record<
  | "hasDiversityForm"
  | "hasEvaluationBehavior"
  | "hasTechnicalTest"
  | "hasTechnicalTestForm",
  { title: string; label: string; text: string }
> = {
  hasDiversityForm: {
    title: "Formulário de diversidade",
    label: "diversity",
    text: "Nota de diversidade",
  },
  hasEvaluationBehavior: {
    title: "Comportamental",
    label: "behavior",
    text: "Nota comportamental",
  },
  hasTechnicalTest: {
    title: "Desafio técnico",
    label: "technical",
    text: "Nota técnica",
  },
  hasTechnicalTestForm: {
    title: "Desafio técnico",
    label: "technical",
    text: "Nota técnica",
  },
};

const Grades = ({ id, onClose, reload }: Props): JSX.Element => {
  const [view, setView] = useState(false);
  const [course, setCourse] = useState<ClassVacancy>();
  const [subscription, setSubscription] = useState<ClassVacancySubscription>();

  const stageWorkshopCourse = useMemo(
    () =>
      course?.stages?.find((item) => item?.type === classVacancyType.WORKSHOP),
    [course?.stages],
  );

  const stageWorkshopSubscription = useMemo(
    () =>
      subscription?.stages?.find(
        (stage) => stage?.stageId === stageWorkshopCourse?.stage_id,
      ),
    [subscription?.stages, stageWorkshopCourse?.stage_id],
  );

  const menuOptions = useMemo(() => {
    if (subscription && course && stageWorkshopCourse) {
      const newList = (Object.entries(susbtepInformations)
        ?.map(
          ([prop, value]) =>
            Object.hasOwn(stageWorkshopCourse, prop) &&
            !!stageWorkshopCourse[prop as keyof ClassVacancyStages] && {
              type: prop,
              ...(value as object),
            },
        )
        ?.filter((item) => item !== false) ?? []) as {
        type?: string;
        title?: string;
        label?: string;
        text?: string;
      }[];

      return newList;
    }

    return [];
  }, [course, subscription, stageWorkshopCourse]);

  const fetch = useCallback(async () => {
    const response = await getClassVacancySubscriptionById(id);
    const responseCourse = await getClassVacancyById(response?.classVacancyId);
    setSubscription(response);
    setCourse(responseCourse?.data);
  }, [id]);

  useEffect(() => {
    if (!course || !subscription) fetch();
  }, [course, subscription, fetch]);

  if (!subscription || !subscription?.stages)
    return (
      <div className="subscriptions-loading subscriptions-loading-propose">
        <CircularProgress />
      </div>
    );

  return (
    <div>
      {view ? (
        <>
          <View stage={stageWorkshopSubscription} steps={menuOptions} />
          <div className="admin-subscriptions-modal-buttons">
            <Button
              text="Editar"
              outline
              appearance="secondary"
              onClick={() => setView(false)}
            />
          </div>
        </>
      ) : (
        <div className="admin-subscriptions-infos-body propose">
          <Historic
            stage={stageWorkshopSubscription as ClassVacancySubscriptionStage}
            setView={setView}
          />

          <div className="propose-title">
            <h2>Atribuir nota</h2>
          </div>

          <Edit
            course={course as ClassVacancy}
            subscription={subscription as ClassVacancySubscription}
            onClose={onClose}
            reload={reload}
          />
        </div>
      )}
    </div>
  );
};

export default Grades;
