import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { toast } from "react-toastify";
import moment from "moment";
import {
  AttachmentPhoto,
  Checkbox,
  Input,
  InputAutocomplete,
  Select,
} from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { maskDate, maskPhone, registerAutoComplete } from "../../../utils";
import api from "../../../services/api";
import { RootState } from "../../../store/reducers";
import { updateUser } from "../../../store/actions/userActions";
import { getAutocomplete } from "../../../services/functions";
import { autoCompleteType } from "../../../types/enumerators";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const UserData = ({ setIsValid }: Props): JSX.Element => {
  const [ofAge, setOfAge] = useState<boolean>();
  const [width, setWidth] = useState(window.innerWidth);
  const [workPositionOptions, setWorkPositionOptions] = useState<string[]>();
  const [position, setPosition] = useState("");
  const [businessUnity, setBusinessUnity] = useState("");
  const dispatch = useDispatch();
  const company = useSelector((state: RootState) => state.companyState);
  const user = useSelector((state: RootState) => state.userState);
  const [_user, _setUser] = useState(user);

  useEffect(() => {
    if (moment(user?.profile?.birthdate, moment.ISO_8601).isValid())
      _setUser({
        ..._user,
        profile: {
          ..._user?.profile,
          birthdate: moment(user?.profile?.birthdate).format("DD/MM/YYYY"),
        },
      });
  }, [_user, user?.profile?.birthdate]);

  useEffect(() => {
    if (user?.account?.tags?.length) {
      const tagBusinessUnity = user?.account?.tags?.find((item: string) =>
        item.includes("business_unity"),
      );
      if (tagBusinessUnity) {
        const arrBusinessUnity = tagBusinessUnity.split(":");
        setBusinessUnity(arrBusinessUnity[1]);
      }

      const tagPosition = user?.account?.tags?.find((item: string) =>
        item.includes("position"),
      );
      if (tagPosition) {
        const arrPosition = tagPosition.split(":");
        setPosition(arrPosition[1]);
      }
    }
  }, [user?.account?.tags]);

  useEffect(() => {
    if (
      ofAge &&
      _user?.profile?.name &&
      _user?.profile?.birthdate &&
      _user?.profile?.birthdate &&
      _user?.profile?.birthdate?.length === 10 &&
      moment(_user?.profile?.birthdate, "DD/MM/YYYY").isValid() &&
      businessUnity &&
      position
    )
      setIsValid(true);
    else setIsValid(false);
  }, [
    _user?.profile?.birthdate,
    _user?.profile?.name,
    businessUnity,
    ofAge,
    position,
    setIsValid,
  ]);

  useEffect(() => {
    if (ofAge === undefined && _user?.profile?.birthdate)
      setOfAge(
        moment().diff(
          moment(_user?.profile?.birthdate, "DD/MM/YYYY"),
          "years",
        ) >= 18,
      );
  }, [_user?.profile?.birthdate, ofAge]);

  const loadPositions = useCallback(async () => {
    const workposition = await getAutocomplete(autoCompleteType.WORK_POSITION);

    const workpositionMapped = workposition?.map(
      (option: { name: string }) => option?.name,
    );

    setWorkPositionOptions(workpositionMapped);
  }, []);

  useEffect(() => {
    if (!workPositionOptions) loadPositions();
  }, [loadPositions, workPositionOptions]);

  const handleOnBlur = useCallback(
    () =>
      dispatch(
        setNextButton(() =>
          dispatch(updateUser({ ..._user }) as unknown as Action),
        ) as unknown as Action,
      ),
    [_user, dispatch],
  );

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) =>
      _setUser({
        ..._user,
        profile: { ..._user?.profile, [prop]: event.target.value },
      }),
    [_user],
  );

  const handleChangeDate = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      _setUser({
        ..._user,
        profile: { ..._user?.profile, birthdate: maskDate(event.target.value) },
      }),
    [_user],
  );

  const handleChangePosition = useCallback(
    async (event) => {
      registerAutoComplete(event.target.value, autoCompleteType.WORK_POSITION);
      const { account } = _user;
      if (account) {
        let { tags: newTags } = account;

        if (newTags && newTags?.length) {
          const index = account?.tags?.findIndex((item: string) =>
            item.includes("position"),
          );
          if (index !== -1)
            newTags[index] = `position:${event.target.value as string}`;
          else newTags.push(`position:${event.target.value as string}`);
        } else newTags = [`position:${event.target.value as string}`];

        setPosition(event.target.value as string);

        _setUser({
          ..._user,
          account: {
            ...account,
            tags: newTags,
          },
        });
      }
    },
    [_user],
  );

  const handleChangeBusinessUnity = useCallback(
    (e: { target: { value: unknown } }) => {
      const { account } = _user;
      if (account) {
        let { tags: newTags } = account;

        if (newTags && newTags?.length) {
          const index = account?.tags?.findIndex((item: string) =>
            item.includes("business_unity"),
          );
          if (index !== -1)
            newTags[index] = `business_unity:${e.target.value as string}`;
          else newTags.push(`business_unity:${e.target.value as string}`);
        } else newTags = [`business_unity:${e.target.value as string}`];

        setBusinessUnity(e.target.value as string);

        _setUser({
          ..._user,
          account: {
            ...account,
            tags: newTags,
          },
        });
      }
    },
    [_user],
  );

  const onChangeImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      api
        .post("/fileUpload", data)
        .then((result) => {
          _setUser({
            ..._user,
            profile: { ..._user?.profile, photo: result?.data?.file_url.url },
          });
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    },
    [_user],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="grid-1-column-account display-block">
      <AttachmentPhoto
        photo={_user?.profile?.photo}
        type="foto"
        onChange={onChangeImage}
        className="grid-row-1 grid-column-1-3"
      />
      {width < 991 && <p className="p-mobile margin-top-24-mobile">Seu nome</p>}
      <Input
        id="name"
        label={width > 990 ? "Seu nome" : ""}
        helperText="Por favor, coloque seu nome completo."
        type="text"
        value={_user?.profile?.name ?? ""}
        onChange={(e) => handleChange(e, "name")}
        onBlur={handleOnBlur}
        className="grid-row-2 width-100 margin-right-32"
      />
      <div className="grid-row-2 width-100 margin-right-32">
        {width < 991 && (
          <p className="p-mobile margin-top-24-mobile">Data de nascimento</p>
        )}
        <Input
          id="birthdate"
          label={width > 990 ? "DD/MM/AAAA" : ""}
          type="text"
          value={_user?.profile?.birthdate ?? ""}
          maxLength={10}
          onChange={handleChangeDate}
          onBlur={handleOnBlur}
          disabled={!_user?.profile?.name}
        />
        <Checkbox
          id="ofAge"
          label="Sou maior de 18 anos"
          checked={
            ofAge === undefined
              ? moment().diff(
                  moment(_user?.profile?.birthdate, "DD/MM/YYYY"),
                  "years",
                ) >= 18
              : ofAge
          }
          onChange={() =>
            moment().diff(
              moment(_user?.profile?.birthdate, "DD/MM/YYYY"),
              "years",
            ) >= 18
              ? setOfAge(!ofAge)
              : {}
          }
          disabled={!_user?.profile?.name}
        />
      </div>
      {width < 991 && <p className="p-mobile margin-top-24-mobile">Celular</p>}
      <Input
        id="telephone"
        label={width > 990 ? "Celular" : ""}
        type="text"
        value={maskPhone(_user?.profile?.telephone ?? "")}
        onChange={(e) => handleChange(e, "telephone")}
        onBlur={handleOnBlur}
        maxLength={14}
        className="grid-row-3 width-100 margin-right-32"
        disabled={
          !ofAge ||
          !_user?.profile?.name ||
          !_user?.profile?.birthdate ||
          (_user?.profile?.birthdate &&
            _user?.profile?.birthdate?.length !== 10) ||
          !moment(_user?.profile?.birthdate, "DD/MM/YYYY").isValid()
        }
      />
      {width < 991 && (
        <p className="p-mobile margin-top-24-mobile">Unidade de negócio</p>
      )}
      <Select
        id="company"
        label={width > 990 ? "Unidade de negócio" : ""}
        onChange={handleChangeBusinessUnity}
        onBlur={handleOnBlur}
        value={
          _user?.account?.tags
            ?.find((item: string | string[]) => item.includes("business_unity"))
            ?.replace("business_unity:", "") ?? ""
        }
        options={
          company?.profile?.location?.map(
            (item: { businessUnity: string }) => ({
              value: item?.businessUnity ?? "Matriz",
              label: item?.businessUnity ?? "Matriz",
            }),
          ) || [
            {
              value: "Outros",
              label: "Outros",
            },
          ]
        }
        className="grid-row-3 width-100 margin-right-32"
        disabled={
          !ofAge ||
          !_user?.profile?.name ||
          !_user?.profile?.birthdate ||
          (_user?.profile?.birthdate &&
            _user?.profile?.birthdate?.length !== 10) ||
          !moment(_user?.profile?.birthdate, "DD/MM/YYYY").isValid()
        }
      />
      {width < 991 && <p className="p-mobile margin-top-24-mobile">Cargo</p>}
      <InputAutocomplete
        label={width > 990 ? "Cargo" : ""}
        helperText="Pressione Enter para adicionar"
        value={position}
        onBlur={handleChangePosition}
        className="width-100 margin-right-32"
        options={workPositionOptions ?? [""]}
        getOptionLabel={(option) => option}
        disabled={
          !ofAge ||
          !_user?.profile?.name ||
          !_user?.profile?.birthdate ||
          (_user?.profile?.birthdate &&
            _user?.profile?.birthdate?.length !== 10) ||
          !moment(_user?.profile?.birthdate, "DD/MM/YYYY").isValid() ||
          !businessUnity
        }
      />
    </div>
  );
};

export default memo(UserData);
