/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createRef,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@material-ui/core";
import { Input, LabelInput, Modal, Select } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { RootState } from "../../../store/reducers";
import { Gender, ProfileRace } from "../../../types/constants/User";
import { CurrentJoblevel } from "../../../types/constants/Employment";
import { updateUser } from "../../../store/actions/userActions";
import {
  setCourseSubscription,
  updateCourseSubscription,
} from "../../../store/actions/courseActions";
import {
  DisabledPersonDescription,
  EmployabilitySituation,
  UnemployedTime,
} from "../../../types/constants/Course";
import { Info } from "../../../../assets/icons";
import { maskReal, removeMaskReal } from "../../../utils";
import { ClassVacancy } from "../../../types/interfaces";
import {
  getActiveUserClassVacancySubscription,
  getClassVacancyById,
} from "../../../services/functions";
import { classVacancySubscriptionEmployabilitySituation } from "../../../types/enumerators";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

interface Item {
  renderCondition?: boolean;
  child: ReactNode;
}

const SocioEconomicProfile = ({ setIsValid }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const subscription = useSelector((state: RootState) => state.courseState);
  const [_profile, _setProfile] = useState(user?.profile);
  const [_form, _setForm] = useState<any>(subscription?.form);
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();
  const [needAccessibilityAdaptation, setNeedAccessibilityAdaptation] =
    useState<boolean | undefined>(
      !!subscription?.form?.accessibilityAdaptation || undefined,
    );
  const [openModal, setOpenModal] = useState(false);
  const [diffRevenue, setDiffRevenue] = useState(false);
  const [diffSize, setDiffSize] = useState(false);

  const {
    hasInternet,
    hasComputer,
    employabilitySituation,
    unemployedTime,
    lastJobFunction,
    jobFunction,
    isEmployedInIT,
    seniority,
    montlyRevenue,
    familyTotalRevenue,
    familySize,
    hasChildren,
    children,
    childrenOfAge,
    accessibilityAdaptation,
    isLGBTQIA,
  } = useMemo(() => _form, [_form]);

  const {
    disabledPerson,
    disabledPersonDescription,
    genderIdentity,
    race,
    address,
  } = useMemo(() => _profile, [_profile]);

  const isDisabled = useMemo(
    () => ({
      hasComputer: hasInternet === undefined,
      employabilitySituation:
        hasInternet === undefined || hasComputer === undefined,
      unemployedTime:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED,
      lastJobFunction:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          !unemployedTime),
      jobFunction:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED,
      isEmployedInIT:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          !jobFunction),
      seniority:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!unemployedTime || !lastJobFunction)) ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!jobFunction || isEmployedInIT === undefined)),
      montlyRevenue:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!unemployedTime || !lastJobFunction)) ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!jobFunction ||
            isEmployedInIT === undefined ||
            (isEmployedInIT && !seniority))),
      familyTotalRevenue:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!unemployedTime || !lastJobFunction)) ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!jobFunction ||
            isEmployedInIT === undefined ||
            (isEmployedInIT && !seniority))) ||
        montlyRevenue === undefined ||
        montlyRevenue < 0,
      familySize:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!unemployedTime || !lastJobFunction)) ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!jobFunction ||
            isEmployedInIT === undefined ||
            (isEmployedInIT && !seniority))) ||
        montlyRevenue === undefined ||
        montlyRevenue < 0 ||
        familyTotalRevenue === undefined ||
        familyTotalRevenue < 0 ||
        diffRevenue,
      hasChildren:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!unemployedTime || !lastJobFunction)) ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!jobFunction ||
            isEmployedInIT === undefined ||
            (isEmployedInIT && !seniority))) ||
        montlyRevenue === undefined ||
        montlyRevenue < 0 ||
        familyTotalRevenue === undefined ||
        familyTotalRevenue < 0 ||
        diffRevenue ||
        !familySize ||
        diffSize,
      children:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!unemployedTime || !lastJobFunction)) ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!jobFunction ||
            isEmployedInIT === undefined ||
            (isEmployedInIT && !seniority))) ||
        montlyRevenue === undefined ||
        montlyRevenue < 0 ||
        familyTotalRevenue === undefined ||
        familyTotalRevenue < 0 ||
        diffRevenue ||
        !familySize ||
        diffSize ||
        hasChildren === undefined,
      childrenOfAge:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!unemployedTime || !lastJobFunction)) ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!jobFunction ||
            isEmployedInIT === undefined ||
            (isEmployedInIT && !seniority))) ||
        montlyRevenue === undefined ||
        montlyRevenue < 0 ||
        familyTotalRevenue === undefined ||
        familyTotalRevenue < 0 ||
        diffRevenue ||
        !familySize ||
        diffSize ||
        hasChildren === undefined ||
        (hasChildren && !children),
      disabledPerson:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!unemployedTime || !lastJobFunction)) ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!jobFunction ||
            isEmployedInIT === undefined ||
            (isEmployedInIT && !seniority))) ||
        montlyRevenue === undefined ||
        montlyRevenue < 0 ||
        familyTotalRevenue === undefined ||
        familyTotalRevenue < 0 ||
        diffRevenue ||
        !familySize ||
        diffSize ||
        hasChildren === undefined ||
        (hasChildren && (!children || childrenOfAge === undefined)),
      genderIdentity:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!unemployedTime || !lastJobFunction)) ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!jobFunction ||
            isEmployedInIT === undefined ||
            (isEmployedInIT && !seniority))) ||
        montlyRevenue === undefined ||
        montlyRevenue < 0 ||
        familyTotalRevenue === undefined ||
        familyTotalRevenue < 0 ||
        diffRevenue ||
        !familySize ||
        diffSize ||
        hasChildren === undefined ||
        (hasChildren && (!children || childrenOfAge === undefined)) ||
        (disabledPerson &&
          needAccessibilityAdaptation &&
          !accessibilityAdaptation),
      isLGBTQIA:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!unemployedTime || !lastJobFunction)) ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!jobFunction ||
            isEmployedInIT === undefined ||
            (isEmployedInIT && !seniority))) ||
        montlyRevenue === undefined ||
        montlyRevenue < 0 ||
        familyTotalRevenue === undefined ||
        familyTotalRevenue < 0 ||
        diffRevenue ||
        !familySize ||
        diffSize ||
        hasChildren === undefined ||
        (hasChildren && (!children || childrenOfAge === undefined)) ||
        !genderIdentity ||
        (disabledPerson &&
          needAccessibilityAdaptation &&
          !accessibilityAdaptation),
      race:
        hasInternet === undefined ||
        hasComputer === undefined ||
        !employabilitySituation ||
        (employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!unemployedTime || !lastJobFunction)) ||
        (employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED &&
          (!jobFunction ||
            isEmployedInIT === undefined ||
            (isEmployedInIT && !seniority))) ||
        montlyRevenue === undefined ||
        montlyRevenue < 0 ||
        familyTotalRevenue === undefined ||
        familyTotalRevenue < 0 ||
        diffRevenue ||
        !familySize ||
        diffSize ||
        hasChildren === undefined ||
        (hasChildren && (!children || childrenOfAge === undefined)) ||
        !genderIdentity ||
        isLGBTQIA === undefined ||
        (disabledPerson &&
          needAccessibilityAdaptation &&
          !accessibilityAdaptation),
    }),
    [
      accessibilityAdaptation,
      children,
      childrenOfAge,
      diffRevenue,
      diffSize,
      disabledPerson,
      employabilitySituation,
      familySize,
      familyTotalRevenue,
      genderIdentity,
      hasChildren,
      hasComputer,
      hasInternet,
      isEmployedInIT,
      isLGBTQIA,
      jobFunction,
      lastJobFunction,
      montlyRevenue,
      needAccessibilityAdaptation,
      seniority,
      unemployedTime,
    ],
  );

  const handleOnBlur = useCallback(() => {
    if (!isDisabled.race && !!race) {
      setIsValid(true);

      const states = {
        NORTE: ["AC", "AM", "RR", "RO", "PA", "AP", "TO"],
        NORDESTE: ["MA", "PI", "CE", "RN", "PB", "PE", "AL", "SE", "BA"],
        "CENTRO-OESTE": ["GO", "MT", "MS", "DF"],
        SUDESTE: ["SP", "MG", "ES", "RJ"],
        SUL: ["RS", "PR", "SC"],
      };

      dispatch(
        setNextButton(() => {
          dispatch(
            updateCourseSubscription({
              ...subscription,
              form: {
                ..._form,
                genderIdentity,
                race,
                region: Object.keys(states)?.find((key) =>
                  states[key as keyof typeof states]?.includes(
                    address?.province,
                  ),
                ),
                perCapitalRevenue:
                  familyTotalRevenue && familySize
                    ? familyTotalRevenue / Number(familySize)
                    : 0,
              },
            }) as unknown as Action,
          );
          dispatch(
            updateUser({ ...user, profile: _profile }) as unknown as Action,
          );
        }) as unknown as Action,
      );
    } else setIsValid(false);
  }, [
    _form,
    _profile,
    address?.province,
    dispatch,
    familySize,
    familyTotalRevenue,
    genderIdentity,
    isDisabled.race,
    race,
    setIsValid,
    subscription,
    user,
  ]);

  const handleOnBlurRevenue = useCallback(() => {
    if (
      montlyRevenue !== undefined &&
      montlyRevenue > -1 &&
      familyTotalRevenue !== undefined &&
      familyTotalRevenue > -1 &&
      familyTotalRevenue < montlyRevenue
    ) {
      setOpenModal(true);
      setDiffRevenue(true);
    } else {
      handleOnBlur();
      setDiffRevenue(false);
    }
  }, [familyTotalRevenue, handleOnBlur, montlyRevenue]);

  const handleOnBlurSize = useCallback(() => {
    if (
      familyTotalRevenue !== undefined &&
      familyTotalRevenue > -1 &&
      montlyRevenue !== undefined &&
      montlyRevenue > -1 &&
      familyTotalRevenue > montlyRevenue &&
      familySize === "1"
    ) {
      setOpenModal(true);
      setDiffSize(true);
    } else {
      handleOnBlur();
      setDiffSize(false);
    }
  }, [familySize, familyTotalRevenue, handleOnBlur, montlyRevenue]);

  const handleChange = useCallback(
    (value: string | number | boolean, prop: string) =>
      _setForm({
        ..._form,
        [prop]: value,
      }),
    [_form],
  );

  const handleChangeSelect = useCallback(
    (value: string, prop: string) =>
      _setForm({
        ..._form,
        [prop]: value as string,
      }),
    [_form],
  );

  const handleChangeRadio = useCallback(
    (value: string, props) =>
      _setForm({
        ..._form,
        [props]: value === props,
      }),
    [_form],
  );

  const handleChangeProfile = useCallback(
    (value: string | number, prop: string) =>
      _setProfile({
        ..._profile,
        [prop]: value,
      }),
    [_profile],
  );

  const handleChangeSelectProfile = useCallback(
    (value: string, prop: string) =>
      _setProfile({
        ..._profile,
        [prop]: value as string,
      }),
    [_profile],
  );

  const handleChangeRadioProfile = useCallback(
    (value: string, props) =>
      _setProfile({
        ..._profile,
        [props]: value === props,
      }),
    [_profile],
  );

  const handleChangeRevenue = useCallback(
    (value: string, prop: string) => {
      _setForm({
        ..._form,
        [prop]: removeMaskReal(maskReal(value)),
      });
      handleChangeProfile(removeMaskReal(maskReal(value)), prop);
    },
    [_form, handleChangeProfile],
  );

  const items = useMemo(
    (): Item[] => [
      {
        child: (
          <>
            <LabelInput marginBottom text="Você tem acesso à internet?" />
            <RadioGroup
              aria-label="hasInternet"
              name="internet"
              value={
                hasInternet !== undefined
                  ? hasInternet
                    ? "hasInternet"
                    : "hasntInternet"
                  : ""
              }
              onChange={(e) => handleChangeRadio(e.target.value, "hasInternet")}
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="hasInternet"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                />
                <FormControlLabel
                  value="hasntInternet"
                  control={<Radio />}
                  label="Não"
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.hasComputer}
              text="Você tem algum computador que atenda às necessidades do curso?"
            />
            {/* Em nosso site, você encontra todas as informações sobre o que é necessário. */}
            {/* href="https://www.reprograma.com.br/#courses" */}
            {/* Aqui você encontra as informações sobre o que é necessário. */}
            <RadioGroup
              aria-label="hasComputer"
              name="computer"
              value={
                hasComputer !== undefined
                  ? hasComputer
                    ? "hasComputer"
                    : "hasntComputer"
                  : ""
              }
              onChange={(e) => handleChangeRadio(e.target.value, "hasComputer")}
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="hasComputer"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.hasComputer}
                />
                <FormControlLabel
                  value="hasntComputer"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.hasComputer}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.employabilitySituation}
              text="Qual a sua situação profissional?"
            />
            <Select
              id="employabilitySituation"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "employabilitySituation")
              }
              onBlur={handleOnBlur}
              value={employabilitySituation ?? ""}
              options={Object.keys(EmployabilitySituation).map((key) => ({
                value: key,
                label:
                  EmployabilitySituation[
                    key as keyof typeof EmployabilitySituation
                  ],
              }))}
              disabled={isDisabled.employabilitySituation}
            />
          </>
        ),
      },
      {
        renderCondition:
          employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.unemployedTime}
              text="Há quanto tempo está desempregada?"
            />
            <Select
              id="unemployedTime"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelect(e.target.value, "unemployedTime")
              }
              onBlur={handleOnBlur}
              value={unemployedTime ?? ""}
              options={Object.keys(UnemployedTime).map((key) => ({
                value: key,
                label: UnemployedTime[key as keyof typeof UnemployedTime],
              }))}
              disabled={isDisabled.unemployedTime}
            />
          </>
        ),
      },
      {
        renderCondition:
          employabilitySituation ===
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.lastJobFunction}
              text="Qual função exercia no seu último emprego?"
            />
            <Input
              label="Função"
              value={lastJobFunction}
              onChange={(e) => handleChange(e.target.value, "lastJobFunction")}
              onBlur={handleOnBlur}
              disabled={isDisabled.lastJobFunction}
            />
          </>
        ),
      },
      {
        renderCondition:
          employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.jobFunction}
              text="Qual o seu cargo atual"
            />
            <Input
              label="Função"
              value={jobFunction}
              onChange={(e) => handleChange(e.target.value, "jobFunction")}
              onBlur={handleOnBlur}
              disabled={isDisabled.jobFunction}
            />
          </>
        ),
      },
      {
        renderCondition:
          employabilitySituation !==
          classVacancySubscriptionEmployabilitySituation.UNEMPLOYED,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.isEmployedInIT}
              text="O seu emprego atual é na área de tecnologia?"
            />
            <RadioGroup
              aria-label="isEmployedInIT"
              name="isEmployedInIT"
              value={
                isEmployedInIT !== undefined
                  ? isEmployedInIT
                    ? "isEmployedInIT"
                    : "isntEmployedInIT"
                  : ""
              }
              onChange={(e) =>
                handleChangeRadio(e.target.value, "isEmployedInIT")
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="isEmployedInIT"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.isEmployedInIT}
                />
                <FormControlLabel
                  value="isntEmployedInIT"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.isEmployedInIT}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        renderCondition: !!isEmployedInIT,
        child: (
          <>
            <LabelInput
              marginBottom
              optional
              disabled={isDisabled.seniority}
              text="Qual o nível de senioridade do seu cargo atual?"
            />
            <Select
              id="seniority"
              label="Selecione"
              className="width-select-course"
              onChange={(e) => handleChangeSelect(e.target.value, "seniority")}
              onBlur={handleOnBlur}
              value={seniority ?? ""}
              options={Object.keys(CurrentJoblevel)?.map((key) => ({
                value: key,
                label: CurrentJoblevel[key as keyof typeof CurrentJoblevel],
              }))}
              disabled={isDisabled.seniority}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.montlyRevenue}
              text="Qual a sua renda mensal?"
            />
            <Input
              id="montlyRevenue"
              type="text"
              label="Renda mensal"
              onChange={(e) =>
                handleChangeRevenue(e.target.value, "montlyRevenue")
              }
              onBlur={handleOnBlurRevenue}
              value={
                montlyRevenue?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }) ?? ""
              }
              disabled={isDisabled.montlyRevenue}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.familyTotalRevenue}
              text="Qual a renda total mensal da sua casa?"
            />
            <Input
              id="familyTotalRevenue"
              type="text"
              label="Renda total"
              onChange={(e) =>
                handleChangeRevenue(e.target.value, "familyTotalRevenue")
              }
              onBlur={handleOnBlurRevenue}
              value={
                familyTotalRevenue?.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                }) ?? ""
              }
              disabled={isDisabled.familyTotalRevenue}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.familySize}
              text="Quantas pessoas moram na sua casa, contando com você?"
            />
            <Input
              id="familySize"
              type="number"
              label="Número de pessoas"
              onChange={({ target: { value } }) => {
                if (!value || Number(value) > 0)
                  handleChange(value, "familySize");
              }}
              onBlur={handleOnBlurSize}
              value={familySize ?? ""}
              disabled={isDisabled.familySize}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.hasChildren}
              text="Você tem filhas (os)?"
            />
            <RadioGroup
              aria-label="hasChildren"
              name="children"
              value={
                hasChildren !== undefined
                  ? hasChildren
                    ? "hasChildren"
                    : "hasntChildren"
                  : ""
              }
              onChange={(e) => handleChangeRadio(e.target.value, "hasChildren")}
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="hasChildren"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.hasChildren}
                />
                <FormControlLabel
                  value="hasntChildren"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.hasChildren}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        renderCondition: hasChildren,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.children}
              text="Quantos"
            />
            <Input
              id="children"
              type="number"
              label="Número de filhos"
              onChange={({ target: { value } }) => {
                if (Number(value) > 0) handleChange(value, "children");
              }}
              maxLength={10}
              onBlur={handleOnBlur}
              value={children ?? ""}
              disabled={isDisabled.children}
            />
          </>
        ),
      },
      {
        renderCondition: hasChildren,
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.childrenOfAge}
              text="Algum dos seus filhos é menor de 18 anos?"
            />
            <RadioGroup
              aria-label="childrenOfAge"
              name="childrenOfAge"
              value={
                childrenOfAge !== undefined
                  ? childrenOfAge
                    ? "childrenOfAge"
                    : "notChildrenOfAge"
                  : ""
              }
              onChange={(e) =>
                handleChangeRadio(e.target.value, "childrenOfAge")
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="childrenOfAge"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.childrenOfAge}
                />
                <FormControlLabel
                  value="notChildrenOfAge"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.childrenOfAge}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              optional
              disabled={isDisabled.disabledPerson}
              text="Por favor, indique se você é uma pessoa com deficiência ou neurodiversa à medida em que você se sente confortável para responder"
            />
            <RadioGroup
              aria-label="disabledPerson"
              name="disabledPerson"
              value={
                disabledPerson !== undefined
                  ? disabledPerson
                    ? "disabledPerson"
                    : "isntDisabledPerson"
                  : ""
              }
              onChange={(e) =>
                handleChangeRadioProfile(e.target.value, "disabledPerson")
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="disabledPerson"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.disabledPerson}
                />
                <FormControlLabel
                  value="isntDisabledPerson"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.disabledPerson}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        renderCondition: !!disabledPerson,
        child: (
          <>
            <LabelInput
              marginBottom
              optional
              disabled={isDisabled.disabledPerson}
              text="Qual a sua neurodiversidade ou deficiência?"
            />
            <Select
              id="disabledPersonDescription"
              label="Selecione"
              className="width-select-course"
              onChange={(e) =>
                handleChangeSelectProfile(
                  e.target.value,
                  "disabledPersonDescription",
                )
              }
              onBlur={handleOnBlur}
              value={disabledPersonDescription ?? ""}
              options={Object.keys(DisabledPersonDescription)?.map((key) => ({
                value: key,
                label:
                  DisabledPersonDescription[
                    key as keyof typeof DisabledPersonDescription
                  ],
              }))}
              disabled={isDisabled.disabledPerson}
            />
          </>
        ),
      },
      {
        renderCondition: !!disabledPerson,
        child: (
          <>
            <LabelInput
              marginBottom
              optional
              disabled={isDisabled.disabledPerson}
              text="Para participar das aulas você precisará de alguma adaptação em relação à acessibilidade? O nosso curso é oferecido pelo zoom, por meio de aulas ao vivo e 100% online e interativas."
            />
            <RadioGroup
              aria-label="needAccessibilityAdaptation"
              name="needAccessibilityAdaptation"
              value={
                needAccessibilityAdaptation !== undefined
                  ? needAccessibilityAdaptation
                    ? "needAccessibilityAdaptation"
                    : "notNeedAccessibilityAdaptation"
                  : ""
              }
              onChange={(e) =>
                setNeedAccessibilityAdaptation(
                  e.target.value === "needAccessibilityAdaptation",
                )
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="needAccessibilityAdaptation"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.disabledPerson}
                />
                <FormControlLabel
                  value="notNeedAccessibilityAdaptation"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.disabledPerson}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        renderCondition: !!disabledPerson && !!needAccessibilityAdaptation,
        child: (
          <>
            <LabelInput
              marginBottom
              optional
              disabled={isDisabled.disabledPerson}
              text="Quais adaptações?"
            />
            <Input
              id="accessibilityAdaptation"
              label="Acessibilidade"
              onChange={(e) =>
                handleChange(e.target.value, "accessibilityAdaptation")
              }
              onBlur={handleOnBlur}
              value={accessibilityAdaptation ?? ""}
              disabled={isDisabled.disabledPerson}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <div className="flex">
              <LabelInput
                marginBottom
                disabled={isDisabled.genderIdentity}
                text="Por favor, indique a sua identidade de gênero"
              />
              <Tooltip
                title={
                  <p>
                    É qualquer agrupamento de indivíduos, objetos, ideias, que
                    tenham caracteres comuns. Podemos também definir como uma
                    forma de entender, visualizar e referir-se à organização
                    social da relação entre os sexos. Muitas vezes o termo
                    gênero é erroneamente utilizado em referência ao sexo
                    biológico. Por isso, é importante enfatizar que o gênero diz
                    respeito aos aspectos sociais atribuídos ao sexo. Ou seja,
                    gênero está vinculado a construções sociais, não a
                    características naturais.
                    <br />
                    Dicionário Aurélio, 1986
                    <br />
                    Guedes, 1995
                    <br />
                    https://www.politize.com.br/vamos-falar-sobre-genero
                  </p>
                }
              >
                <div>
                  <Info
                    className={`icon-info pointer margin-bottom-14 ${
                      isDisabled.genderIdentity ? "disabled" : ""
                    }`}
                  />
                </div>
              </Tooltip>
            </div>

            <Select
              id="genderIdentity"
              label="Selecione"
              className="width-select-course"
              onChange={(e) =>
                handleChangeSelectProfile(e.target.value, "genderIdentity")
              }
              onBlur={handleOnBlur}
              value={genderIdentity ?? ""}
              options={Object.keys(Gender).map((key) => ({
                value: key,
                label: Gender[key as keyof typeof Gender],
              }))}
              disabled={isDisabled.genderIdentity}
            />
          </>
        ),
      },
      {
        child: (
          <>
            <div className="flex">
              <LabelInput
                marginBottom
                disabled={isDisabled.isLGBTQIA}
                text="Você se considera parte do grupo LGBTQIAP+?"
              />
              <Tooltip
                title={
                  <p>
                    L: lésbica- mulher, cis, trans ou travesti que sente atração
                    sexual e/ou afetiva por pessoas do gênero feminino.
                    <br />
                    G: gays - pessoa que se identifica pelo gênero masculino,
                    cis ou trans, que sente atração sexual e/ou afetiva por
                    pessoas do gênero masculino.
                    <br />
                    B: bissexuais - pessoas que podem ter atração sexual e/ou
                    afetiva com pessoas do mesmo gênero ou de outros.
                    <br />
                    T: travestis e transgêneros - pessoas que transcendem as
                    definições convencionais de gênero e possuem uma identidade
                    de gênero diferente do sexo designado no nascimento.
                    <br />
                    Q: queer - é um termo guarda-chuva da língua inglesa para
                    minorias sexuais e de gênero, ou seja, que não são
                    heterossexuais, cisgênero.
                    <br />
                    I: intersexo - termo para designar pessoas que nascem com
                    características sexuais (incluindo genitais, gônadas e
                    padrões cromossômicos) que não se encaixam nas típicas
                    noções binárias de corpos masculinos e femininos. Intersexo
                    é um termo guarda-chuva usado para descrever uma ampla gama
                    de variações naturais do corpo.
                    <br />
                    A: assexuais - pessoas que não sentem, ou sentem pouca,
                    atração sexual e/ou afetiva por qualquer pessoa, de qualquer
                    gênero.
                    <br />
                    P: pansexuais - pessoas que sentem atraem sexual e/ou
                    afetiva por todos os gêneros, sem limitação binária.
                    <br />
                    +: representa as demais orientações sexuais, identidades e
                    expressões de gênero
                    <br />
                    https://pt.wikipedia.org/wiki/Queer
                    <br />
                    https://capricho.abril.com.br/comportamento/voce-sabe-o-que-significa-a-sigla-lgbtqi/
                    <br />
                    https://www.unfe.org/wp-content/uploads/2018/10/Intersex-PT.pdf
                  </p>
                }
              >
                <div>
                  <Info
                    className={`icon-info pointer margin-bottom-14 ${
                      isDisabled.genderIdentity ? "disabled" : ""
                    }`}
                  />
                </div>
              </Tooltip>
            </div>
            <RadioGroup
              aria-label="isLGBTQIA"
              name="isLGBTQIA"
              value={
                isLGBTQIA !== undefined
                  ? isLGBTQIA
                    ? "isLGBTQIA"
                    : "isntLGBTQIA"
                  : ""
              }
              onChange={(e) => handleChangeRadio(e.target.value, "isLGBTQIA")}
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="isLGBTQIA"
                  control={<Radio />}
                  label="Sim"
                  className="margin-right-32"
                  disabled={isDisabled.isLGBTQIA}
                />
                <FormControlLabel
                  value="isntLGBTQIA"
                  control={<Radio />}
                  label="Não"
                  disabled={isDisabled.isLGBTQIA}
                />
              </div>
            </RadioGroup>
          </>
        ),
      },
      {
        child: (
          <>
            <LabelInput
              marginBottom
              disabled={isDisabled.race}
              text="Qual a sua raça/cor/etnia?"
            />
            <Select
              id="race"
              label="Selecione"
              onChange={(e) =>
                handleChangeSelectProfile(e.target.value, "race")
              }
              onBlur={handleOnBlur}
              value={race ?? ""}
              options={Object.keys(ProfileRace).map((key) => ({
                value: key,
                label: ProfileRace[key as keyof typeof ProfileRace],
              }))}
              disabled={isDisabled.race}
            />
          </>
        ),
      },
    ],
    [
      accessibilityAdaptation,
      children,
      childrenOfAge,
      disabledPerson,
      disabledPersonDescription,
      employabilitySituation,
      familySize,
      familyTotalRevenue,
      genderIdentity,
      handleChange,
      handleChangeRadio,
      handleChangeRadioProfile,
      handleChangeRevenue,
      handleChangeSelect,
      handleChangeSelectProfile,
      handleOnBlur,
      handleOnBlurRevenue,
      handleOnBlurSize,
      hasChildren,
      hasComputer,
      hasInternet,
      isDisabled.children,
      isDisabled.childrenOfAge,
      isDisabled.disabledPerson,
      isDisabled.employabilitySituation,
      isDisabled.familySize,
      isDisabled.familyTotalRevenue,
      isDisabled.genderIdentity,
      isDisabled.hasChildren,
      isDisabled.hasComputer,
      isDisabled.isEmployedInIT,
      isDisabled.isLGBTQIA,
      isDisabled.jobFunction,
      isDisabled.lastJobFunction,
      isDisabled.montlyRevenue,
      isDisabled.race,
      isDisabled.seniority,
      isDisabled.unemployedTime,
      isEmployedInIT,
      isLGBTQIA,
      jobFunction,
      lastJobFunction,
      montlyRevenue,
      needAccessibilityAdaptation,
      race,
      seniority,
      unemployedTime,
    ],
  );

  const itemsRefs = useMemo(
    () => items.map(() => createRef<HTMLDivElement>()),
    [items],
  );

  const getSubscription = useCallback(async () => {
    const subscriptions = await getActiveUserClassVacancySubscription(user._id);
    if (subscriptions?.data?.length)
      dispatch(
        setCourseSubscription(subscriptions?.data?.[0]) as unknown as Action,
      );
  }, [user._id, dispatch]);

  const getClassVacancy = useCallback(async () => {
    const response = await getClassVacancyById(subscription?.classVacancyId);
    if (response?.data) setClassVacancy(response?.data);
  }, [subscription?.classVacancyId]);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  useEffect(() => {
    if (!classVacancy && !!subscription?._id) getClassVacancy();
  }, [classVacancy, subscription?._id, getClassVacancy]);

  useEffect(() => {
    if (isDisabled.race || !race) setIsValid(false);
    else handleOnBlur();
  }, [handleOnBlur, isDisabled.race, race, setIsValid]);

  return (
    <div className="grid-account-gap grid-account-gap-resposive">
      {items.map(
        ({ renderCondition, child }, index) =>
          (renderCondition ?? true) && (
            <div ref={itemsRefs[index]} className="grid-full-row">
              {child}
            </div>
          ),
      )}
      <Modal
        headerAction
        open={openModal}
        onClose={() => setOpenModal(false)}
        onClickBack={() => setOpenModal(false)}
      >
        <div className="modal-age btn-modal-course-years">
          <h3 className="title-modal-age">Ops! Encontramos um erro.</h3>
          <p className="text-modal-age">
            Verifique o valor da sua renda mensal e da renda total da sua casa.
            Não se esqueça de incluir você na quantidade de pessoas que moram na
            sua casa.
            <br />
            <br />
            Para processuir, por favor confira se inseriu corretamente.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default memo(SocioEconomicProfile);
