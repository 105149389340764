import React, { ReactNode, memo, useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Card, IconButton } from "..";
import "./style.scss";
import {
  Bookmark,
  ChevronUp,
  InvitesNew,
  Mail,
  Proposals,
  ProposalsNew,
  Unblock,
} from "../../../assets/customIcons";

interface Props {
  selectedMenuTitle?: string;
  icon?: ReactNode;
  subMenu?: {
    active: boolean;
    title: string;
    onClick: (e: { stopPropagation: () => void }) => void;
  }[];
}

interface PropsCardMenu {
  // eslint-disable-next-line react/no-unused-prop-types
  items?: {
    url: string;
    text: string;
    icon: ReactNode;
  }[];
}

interface PropsCardMenuMobile extends Props, PropsCardMenu {}

const CardMenu = ({ items }: PropsCardMenu): JSX.Element => {
  const history = useHistory();

  return (
    <div>
      <Card className="card-menu-left">
        {items?.map((item) => (
          <Link
            key={Math.random()}
            to={item?.url}
            className="text-decoration-none"
          >
            <IconButton
              isVacancies
              icon={item?.icon}
              text={item?.text}
              className={`
        ${
          history.location.pathname === item?.url
            ? "card-menu-left-icon"
            : "card-menu-left-content"
        }
      `}
            />
          </Link>
        ))}
      </Card>
    </div>
  );
};

const CardMenuMobile = ({
  selectedMenuTitle,
  icon,
  subMenu,
  items,
}: PropsCardMenuMobile): JSX.Element => {
  const history = useHistory();

  return (
    <div>
      <Accordion className="steps-account-mobile margin-16 margin-y-16-mobile margin-x-0-mobile">
        <AccordionSummary
          expandIcon={<ChevronUp widthIcon={24} heightIcon={24} />}
          className={`expanded-icon ${subMenu && "margin-bottom-accordion"}`}
        >
          <div className="margin-left-12-mobile">
            <div className="flex-row-center width-100">
              <p>{icon}</p>
              <p className="margin-left-4-mobile">{selectedMenuTitle}</p>
            </div>
            {subMenu && subMenu?.length > 0 && (
              <div className="card-title-div border-bottom-none margin-bottom-16-mobile">
                {subMenu?.map((item) => (
                  <button
                    type="button"
                    className={`margin-left-24 active-mobile ${
                      item?.active ? "active" : ""
                    }`}
                    onClick={item?.onClick}
                  >
                    {item?.title}
                  </button>
                ))}
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="grid-column-1-11 width-100 grid-row-2 grid-column-4-12 ">
            <div className="border-solid-account" />
            <div className=" icon-step-account">
              {items?.map((item) => (
                <IconButton
                  icon={item?.icon}
                  text={item?.text}
                  onClick={() => history.push(item?.url)}
                  className="steps-account steps-account-disabled"
                />
              ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const CardMenuLeft = ({
  selectedMenuTitle,
  icon,
  subMenu,
}: PropsCardMenuMobile): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const hasNotificationInvite = false;
  const hasNotificationProposals = false;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const items = useMemo(
    () => [
      {
        icon: (
          <Unblock
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Candidaturas",
        url: "/candidature",
        id: 1,
      },
      {
        icon: hasNotificationProposals ? (
          <ProposalsNew
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ) : (
          <Proposals
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Propostas",
        url: "/proposals",
        id: 2,
      },
      {
        icon: (
          <Bookmark
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Vagas Salvas",
        url: "/saved-jobs",
        id: 3,
      },
      {
        icon: hasNotificationInvite ? (
          <InvitesNew
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ) : (
          <Mail
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Convites",
        url: "/invites",
        id: 4,
      },
    ],
    [hasNotificationInvite, hasNotificationProposals, width],
  );

  return width > 990 ? (
    <CardMenu items={items} />
  ) : (
    <CardMenuMobile
      items={items}
      selectedMenuTitle={selectedMenuTitle}
      icon={icon}
      subMenu={subMenu}
    />
  );
};

export default memo(CardMenuLeft);
