import React, { memo, useEffect, useState } from "react";
import { Modal, Select, Button } from "../../../components";
import "../style.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (classId: string) => Promise<void>;
  list?: { _id?: string; code?: string }[];
  userName?: string;
}

const TransferClass = ({
  open,
  onClose,
  list = [{ _id: "", code: "" }],
  onSave,
  userName,
}: Props): JSX.Element => {
  const [classId, setClassId] = useState("");

  useEffect(() => {
    if (!open && !!classId) setClassId("");
  }, [classId, open]);

  return (
    <Modal
      headerAction
      open={open}
      onClose={onClose}
      textHeader={
        userName
          ? `${userName} - Transferir para outra turma`
          : "Transferir para outra turma"
      }
    >
      <div className="admin-recap-modal">
        <Select
          id="class"
          className="margin-bottom-24"
          label="Turma*"
          options={list?.map((items) => ({
            value: items?._id ?? "",
            label: items?.code ?? "",
          }))}
          value={classId ?? ""}
          onChange={(e) => setClassId(e.target.value)}
        />
        <div className="margin-top-32 admin-subscriptions-modal-buttons">
          <Button
            text="Cancelar"
            onClick={onClose}
            outline
            appearance="secondary"
          />
          <Button
            text="Salvar"
            className="margin-left-32 margin-left-mobile"
            onClick={() => onSave(classId)}
            disabled={!classId}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(TransferClass);
