import React, { memo } from "react";
import { Class } from "../../../types/interfaces";
import { Button, Input, Modal, TextArea } from "../../../components";
import "../style.scss";

interface Props {
  open: boolean;
  close: () => void;
  workshop?: Class;
  onChange: (value: string, prop: "url" | "info") => void;
  onConfirm: () => void;
}

const Workshop = ({
  open,
  close,
  workshop,
  onChange,
  onConfirm,
}: Props): JSX.Element => (
  <Modal
    headerAction
    open={open}
    onClose={close}
    textHeader="Confirmar oficina"
    className="admin-subscriptions-modal-manager-actions"
  >
    <div className="admin-subscriptions-modal-manager-actions confirmation-workshop class-view">
      <Input
        type="text"
        label="Link de acesso"
        value={workshop?.url ?? ""}
        onChange={(e) => onChange(e.target.value, "url")}
      />
      <TextArea
        label="Escreva as instruções"
        value={workshop?.info ?? ""}
        onChange={(e) => onChange(e.target.value, "info")}
        textFielRow={4}
        maxLength={2000}
      />
    </div>
    <div className="admin-subscriptions-modal-buttons margin-top-32">
      <Button text="Cancelar" onClick={close} outline appearance="secondary" />
      <Button
        text="Confirmar"
        onClick={onConfirm}
        disabled={!workshop?.url || !workshop?.info}
      />
    </div>
  </Modal>
);

export default memo(Workshop);
