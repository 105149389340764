/* eslint-disable @typescript-eslint/no-explicit-any */
import { SET_DEFAULT_PAGE_LIMIT, SET_LOAD } from "../types/configurationsTypes";

export const setPageLimit = (pageLimit: number) => (dispatch: any) => {
  dispatch({
    type: SET_DEFAULT_PAGE_LIMIT,
    payload: pageLimit,
  });
};

export const setLoad = (load: boolean) => (dispatch: any) => {
  dispatch({
    type: SET_LOAD,
    payload: load,
  });
};
