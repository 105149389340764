import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import moment from "moment-timezone";
import { setupCache } from "axios-cache-adapter";
import { decode, encode } from "../utils/crypto";

export const isDevelopment = false
export const useLocalhost = false
export const isTrial = false

export const useCrypto = true;

const baseURL = `http${!useLocalhost ? "s" : ""}://${
  useLocalhost && isDevelopment ? "localhost:3000" : ""
}${isDevelopment && !useLocalhost ? "develop." : ""}${
  !useLocalhost ? "api.reprograma.com.br" : ""
}${isTrial && useLocalhost ? "/trial" : ""}`;

const cache = setupCache({
  maxAge: 60 * 1000,
  exclude: {
    methods: ["put", "delete"],
    // add here a list of requestes to be uncached
    paths: [/(\/placementVacancy\/)(.*[a-zA-Z0-9]{5})/],
  },
});

const api = axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  // adapter: cache.adapter,
});

if (useCrypto) {
  const requestHandler = (request: AxiosRequestConfig) => {
    const collections = [
      "user",
      "research",
      "placementVacancySubscription",
      "placementVacancy",
      "company",
      "classVacancySubscription",
      "classVacancy",
      "class",
    ];
    const date = moment().tz("America/Sao_Paulo").toISOString();
    const user = JSON.parse(sessionStorage.getItem("@SESSION:user") ?? "null");
    const criterious =
      !!user?._id &&
      !!collections?.find((collection) => request?.url?.includes(collection)) &&
      !request.url?.includes("get");
    let data = {};

    if (request.method === "post" && criterious)
      data = {
        createdBy: user._id,
        createdAt: date,
      };

    if (request.method === "patch" && criterious)
      data = {
        updatedBy: user._id,
        updatedAt: date,
      };

    if (!(request.data instanceof FormData))
      request.data = encode({ ...request.data, ...data });

    return request;
  };

  const responseHandler = (response: AxiosResponse) => {
    response.data = decode(response.data);
    return response;
  };

  api.interceptors.request.use((request) => requestHandler(request));
  api.interceptors.response.use((response) => responseHandler(response));
}

export const brasilApi = axios.create({
  baseURL: "https://brasilapi.com.br/api/cep/v1",
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export const youtubeApi = axios.create({
  baseURL: "https://www.googleapis.com/youtube/v3",
});

export default api;
