/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Down, Folder, LinkMini } from "../../../assets/icons";
import { CardMedia } from "..";
import { Media } from "../../types/interfaces";
import "./styles.scss";

interface Props {
  medias?: Media[];
  links?: Media[];
}

const Medias = ({ medias, links }: Props): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (medias?.length) {
      medias?.sort((a: Media, b: Media) => {
        if ("order" in a && "order" in b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        }
        return 0;
      });
    }
  }, [medias]);

  const openFile = (event: any, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  return (
    <div>
      <Accordion onChange={openFile} className="medias" defaultExpanded>
        <AccordionSummary className="medias-student" expandIcon={<Down />}>
          <div className="width-100">
            <div className="flex-row-center">
              <p className="medias-description">Mídias</p>
              <div className="divider width-100" />
            </div>
            <div className="width-100" />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex-col">
            <div className="flex flex-wrap align-items">
              {medias &&
                medias.map((innerItem) => (
                  <CardMedia key={Math.random()} item={innerItem} small />
                ))}
            </div>
            <div className="media-items">
              {links &&
                links?.map((innerItem) => (
                  <a
                    key={Math.random()}
                    href={
                      innerItem &&
                      innerItem?.url &&
                      innerItem?.url?.includes("http")
                        ? innerItem?.url
                        : `http://${innerItem?.url}`
                    }
                    target="_blank"
                    className="medias-link"
                    rel="noreferrer"
                  >
                    {innerItem.url}
                  </a>
                ))}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      {((medias && medias?.length > 0) || (links && links?.length > 0)) && (
        <div className="flex-row-center">
          {!isExpanded && (
            <div className="medias-container">
              <div>
                {medias && medias?.length > 0 && (
                  <div className="flex-row-center">
                    <Folder className="margin-rigth-8 " />
                    {`${medias?.length} ${
                      medias?.length > 1 ? "arquivos" : "arquivo"
                    } `}
                  </div>
                )}
                {links && links?.length > 0 && (
                  <div className="flex-row-center">
                    <LinkMini className="margin-rigth-8 " />
                    {`${links?.length} ${links?.length > 1 ? "links" : "link"}`}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(Medias);
