/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-eval */
import React, { memo, useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Delete } from "../../../assets/icons";
import { ConfirmRemove } from "../../pages/Profile/Modal";
import {
  deleteNotifications,
  updateNotifications,
} from "../../services/functions";
import { RootState } from "../../store/reducers";
import { updateNotifications as updateNotificationsRedux } from "../../store/actions/notificationsActions";
import { Notifications as NotificationsType } from "../../types/interfaces";
import Close from "../../../assets/customIcons/Close";

interface Props {
  onClose?: () => void;
  isPage?: boolean;
}

const Notifications = ({ onClose, isPage }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();
  const notificationsState = useSelector(
    (state: RootState) => state.notificationsState,
  );
  const dispatch = useDispatch();
  const [openModalRemoveAll, setOpenModalRemoveAll] = useState(false);
  const [modalRemove, setModalRemove] = useState<{
    open: boolean;
    id?: string;
  }>({ open: false });

  const handleRemoveAllNotifications = useCallback(async () => {
    notificationsState?.forEach(async (notification: { _id: string }) => {
      await deleteNotifications(notification._id);
    });
    dispatch(updateNotificationsRedux([]) as unknown as Action);
    setOpenModalRemoveAll(false);
  }, [dispatch, notificationsState]);

  const handleRemoveNotification = useCallback(async () => {
    await deleteNotifications(modalRemove?.id ?? "");
    const notifications = [...notificationsState];
    const index = notifications?.findIndex(
      (notif) => notif?._id === modalRemove?.id,
    );
    notifications?.splice(index, 1);

    dispatch(updateNotificationsRedux(notifications) as unknown as Action);
    setModalRemove({ open: false });
  }, [dispatch, modalRemove?.id, notificationsState]);

  const markAsRead = useCallback(
    async (data) => {
      const updateNotif = notificationsState?.find(
        (notif: { _id: string }) => notif?._id === data?._id,
      );
      if (updateNotif) {
        updateNotif.hasRead = true;
        await updateNotifications(data?._id, { hasRead: true });
      }
      const actionNotification = null;

      eval(`actionNotification = ${data?.action}`);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      actionNotification();
      dispatch(
        updateNotificationsRedux(notificationsState) as unknown as Action,
      );
    },
    [dispatch, notificationsState],
  );

  const markEverythingAsRead = useCallback(async () => {
    const notifications = [...notificationsState];
    for (const notification of notifications) {
      await updateNotifications(notification._id, { hasRead: true });
      notification.hasRead = true;
    }
    dispatch(updateNotificationsRedux(notifications) as unknown as Action);
  }, [dispatch, notificationsState]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      // className={` ${!isPage ? 'card-notification' : 'card-notification-page'} ${!notificationsState ? 'height-360' : 'height-fit' } `}
      className={` ${!isPage ? "notification-menu" : "notification-page"} ${
        !notificationsState ? "height-360" : "height-fit-content"
      } `}
    >
      <div className="notification-menu-title">
        <span>Notificações</span>
        {!isPage && (
          <Close
            widthIcon={width < 991 ? 24 : 28}
            heightIcon={width < 991 ? 24 : 28}
            className="icone-close"
            onClick={onClose}
          />
        )}
      </div>

      {notificationsState?.length > 0 ? (
        <>
          <div className="flex-between-center">
            <button
              type="button"
              onClick={markEverythingAsRead}
              className={`notification-mark
                ${!isPage ? "" : "is-page"}
              `}
            >
              <p className="margin-top-0">Marcar tudo como lido</p>
            </button>
            <button
              onClick={() => setOpenModalRemoveAll(true)}
              type="button"
              className={`notification-clear
                ${!isPage ? "" : "is-page"}
              `}
            >
              <p className="margin-top-0">Excluir</p>
            </button>
          </div>
          <div className={` ${!isPage ? "notification-page-card" : ""}`}>
            {notificationsState?.map(
              (data: NotificationsType, index: number) => (
                <div
                  key={Math.random()}
                  onClick={() => {
                    markAsRead(data);
                    eval(data?.action);
                  }}
                  className={
                    data?.hasRead
                      ? "cursor-pointer"
                      : "notification-item cursor-pointer"
                  }
                  role="button"
                  aria-label="read"
                  tabIndex={0}
                >
                  <div className="notification-item-content">
                    <span
                      className={`notification-title ${
                        !isPage ? "" : "is-page"
                      }`}
                    >
                      {data?.title}
                    </span>
                    <p className="notification-data">
                      {moment(data?.date).format("DD/MM")}
                    </p>
                  </div>
                  <div className="flex-between-center">
                    <p
                      className={`notification-message ${
                        !isPage ? "" : "is-page"
                      }`}
                    >
                      {data?.text}
                    </p>
                    <button
                      aria-label="delete"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalRemove({ open: true, id: data?._id });
                      }}
                      className="notification-cancel"
                    >
                      <Delete />
                    </button>
                  </div>
                  {index < notificationsState.length - 1 && (
                    <div className="divider-card-card-items" />
                  )}
                </div>
              ),
            )}
          </div>
        </>
      ) : (
        <div>
          <p className={`notification-info ${!isPage ? "" : "is-page"}`}>
            Você não tem novas notificações.
          </p>
        </div>
      )}

      {!isPage && notificationsState?.length > 0 && (
        <div className="notification-footer">
          <button
            type="button"
            onClick={() => history.push("/notification")}
            className="notification-footer-button"
          >
            VER TODAS
          </button>
        </div>
      )}

      <ConfirmRemove
        key={Math.random()}
        titleHeader="Notificações"
        text="Deseja realmente limpar todas as notificações?"
        onClose={() => setOpenModalRemoveAll(false)}
        onRemove={handleRemoveAllNotifications}
        openModal={openModalRemoveAll}
      />
      <ConfirmRemove
        key={Math.random()}
        titleHeader="Notificações"
        text="Deseja realmente excluir esta notificação?"
        onClose={() => setModalRemove({ open: false })}
        onRemove={handleRemoveNotification}
        openModal={modalRemove.open}
      />
    </div>
  );
};

export default memo(Notifications);
