/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  LegacyRef,
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { Add, Delete, Edit, Move } from "../../../../assets/customIcons";
import { Draggable, IconButton } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateUser } from "../../../store/actions/userActions";
import { ConfirmRemove, Items } from "../../Profile/Modal";
import { UserCurriculumLanguage } from "../../../types/interfaces";
import { RootState } from "../../../store/reducers";

const EditLanguage = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [openModal, setOpenModal] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [removed, setRemoved] = useState({
    title: "",
    text: "",
    onRemove: () => setOpenModalRemove(false),
  });
  const [indexItem, setIndexItem] = useState(-1);
  const user = useSelector((state: RootState) => state.userState);
  const [_user, _setUser] = useState(user);
  const dispatch = useDispatch();
  const [minWidth, setMinWidth] = useState(0);

  const refs: { current: { offsetWidth: number | null } | null }[] = useMemo(
    () =>
      Array.from({ length: _user?.curriculum?.languages?.length }).map(() =>
        createRef(),
      ),
    [_user?.curriculum?.languages?.length],
  );

  useEffect(() => {
    if (_user?.curriculum?.languages?.length === refs?.length) {
      let size = 0;
      let index = -1;
      refs?.forEach((item: any, i: number) => {
        if (item?.current?.offsetWidth > size) {
          size = item?.current?.offsetWidth;
          index = i;
        }
      });
      setMinWidth(refs[index]?.current?.offsetWidth ?? 0);
    }
  }, [
    _user?.curriculum?.languages,
    _user?.curriculum?.languages?.length,
    refs,
  ]);

  useEffect(() => {
    dispatch(
      setNextButton(() =>
        dispatch(
          updateUser({
            ..._user,
            curriculum: { ..._user?.curriculum },
          }) as unknown as Action,
        ),
      ) as unknown as Action,
    );
  }, [_user, _user?.curriculum, dispatch]);

  useEffect(() => {
    if (_user?.curriculum?.languages?.length)
      _user?.curriculum?.languages?.sort(
        (a: UserCurriculumLanguage, b: UserCurriculumLanguage) => {
          if ("order" in a && "order" in b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          }
          return 0;
        },
      );
  }, [_user?.curriculum?.languages]);

  const reorderItem = useCallback(
    (items: any) => {
      const { curriculum } = _user;
      if (curriculum) {
        let { languages: newLanguages } = curriculum;
        newLanguages = items?.map((item: any, index: number) => ({
          ...item,
          order: index,
        }));

        _setUser({
          ..._user,
          curriculum: { ...curriculum, languages: newLanguages },
        });

        dispatch(
          setNextButton(() =>
            dispatch(
              updateUser({
                ..._user,
                curriculum: { ...curriculum, languages: newLanguages },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      }
    },
    [_user, dispatch],
  );

  const onRemoveItem = useCallback(
    (index: number) => {
      let newItems = _user?.curriculum?.languages;
      newItems?.splice(index, 1);
      newItems = newItems?.map((innerItem: any, i: number) => ({
        ...innerItem,
        order: i,
      }));

      _setUser({
        ..._user,
        curriculum: { ..._user?.curriculum, languages: newItems },
      });
    },
    [_user],
  );

  const onEdit = useCallback((index: number) => {
    setIndexItem(index);
    setOpenModal(true);
  }, []);

  const onRemove = useCallback(
    (item, index: number) => {
      setRemoved({
        title: "Excluir idioma",
        text: `Tem certeza de que deseja excluir o idioma ${item?.language}?`,
        onRemove: () => onRemoveItem(index),
      });
      setOpenModalRemove(true);
    },
    [onRemoveItem],
  );

  const onClose = useCallback(() => {
    setOpenModal(false);
    setIndexItem(-1);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <IconButton
        icon={
          <Add
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        }
        text="Adicionar idioma"
        onClick={() => setOpenModal(true)}
        className={`icon-button-primary width-100-mobile ${
          _user?.curriculum?.languages?.length > 0 ? "margin-bottom-40 " : ""
        } `}
      />
      {_user?.curriculum?.languages?.length > 0 ? (
        <div>
          {_user?.curriculum?.languages?.length > 1 && (
            <p className="account-languages-reorder">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <Draggable
            items={_user?.curriculum?.languages}
            reorderItem={(items) => reorderItem(items)}
          >
            {_user?.curriculum?.languages?.map((item: any, index: number) => (
              <DraggableComponent
                key={Math.random()}
                draggableId={Math.random().toString()}
                index={index}
                isDragDisabled={_user?.curriculum?.languages?.length < 2}
              >
                {(innerProvided) => (
                  <div
                    ref={innerProvided.innerRef}
                    {...innerProvided.draggableProps}
                    {...innerProvided.dragHandleProps}
                  >
                    <div className="cards-items-profile flex-row-center width-100-responsive justify-space margin-bottom-20-mobile">
                      <div
                        ref={
                          refs[index] as LegacyRef<HTMLDivElement> | undefined
                        }
                        style={{ minWidth }}
                      >
                        <p className="title-bold margin-bottom-6">
                          {item?.language}
                        </p>
                        <p className="level-edit width-90">
                          {item?.proecifience === 1
                            ? "Básico"
                            : item?.proecifience === 2
                            ? "Intermediário"
                            : "Avançado"}
                        </p>
                      </div>
                      {width > 990 && (
                        <div className="flex-row-center space-knob">
                          <div className="knob" />
                          <div
                            className={`knob ${
                              item?.proecifience < 2 ? "empty" : ""
                            }`}
                          />
                          <div
                            className={`knob ${
                              item?.proecifience < 3 ? "empty" : ""
                            }`}
                          />
                        </div>
                      )}
                      <div className="flex-row-center margin-right-24">
                        <IconButton
                          icon={<Edit widthIcon={width < 991 ? 20 : 24} />}
                          onClick={() => onEdit(index)}
                          className="margin-right-8"
                        />
                        <IconButton
                          icon={<Delete widthIcon={width < 991 ? 20 : 24} />}
                          onClick={() => onRemove(item, index)}
                        />
                        {_user?.curriculum?.languages?.length > 1 && (
                          <IconButton
                            icon={<Move widthIcon={width < 991 ? 20 : 24} />}
                            className="margin-left-8"
                          />
                        )}
                      </div>
                    </div>
                    {width < 991 && (
                      <div className="flex-row-center space-knob margin-knob-responsive margin-top-20-mobile">
                        <div className="knob" />
                        <div
                          className={`knob ${
                            item?.proecifience < 2 ? "empty" : ""
                          }`}
                        />
                        <div
                          className={`knob ${
                            item?.proecifience < 3 ? "empty" : ""
                          }`}
                        />
                      </div>
                    )}
                    {index <
                      (_user?.curriculum?.languages?.length ?? 0) - 1 && (
                      <div className="divider-card divider-card-language" />
                    )}
                  </div>
                )}
              </DraggableComponent>
            ))}
          </Draggable>
        </div>
      ) : (
        <p className="paragraph-not-registered">Não há idiomas cadastrados</p>
      )}
      <Items
        setUser={_setUser}
        index={indexItem}
        type="languages"
        openModal={openModal}
        onClose={onClose}
      />
      <ConfirmRemove
        titleHeader={removed?.title}
        text={removed?.text}
        onClose={() => setOpenModalRemove(false)}
        onRemove={removed?.onRemove}
        openModal={openModalRemove}
      />
    </div>
  );
};

export default memo(EditLanguage);
