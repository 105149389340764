/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  memo,
  useCallback,
  useMemo,
  SetStateAction,
  Dispatch,
  useState,
  ChangeEvent,
  useEffect,
  createRef,
  ReactNode,
} from "react";
import { useParams } from "react-router-dom";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core";
import { Action } from "redux";
import { useSelector, useDispatch } from "react-redux";
import {
  Input,
  LabelInput,
  TextArea,
  InputAutocomplete,
} from "../../../components";
import { RootState } from "../../../store/reducers";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateEmployment } from "../../../store/actions/employmentAction";
import {
  getAutocomplete,
  getUserIdResearchBySupervisionIdAndClassId,
  getUserById,
} from "../../../services/functions";
import { autoCompleteType } from "../../../types/enumerators";
import { registerAutoComplete } from "../../../utils";
import { setLoad } from "../../../store/actions/configurationsActions";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
  templateRows?: boolean;
}

interface Item {
  id: string;
  child: ReactNode;
  isFullRow?: boolean;
  renderCondition?: boolean;
  className?: string;
}

interface ParamsProps {
  classId: string;
}

const CompanyEmployment = ({
  setIsValid,
  templateRows = false,
}: Props): JSX.Element => {
  const expandedAttribute = "expanded";
  const heightProperty = "height";
  const fullRowClassName = "grid-full-row";
  const employment = useSelector((state: RootState) => state.employmentState);
  const [form, setForm] = useState(employment?.form ?? {});
  const [studentName, setStudentName] = useState<string>();
  const [skillsOptions, setSkillOptions] = useState<string[]>();
  const { classId } = useParams<ParamsProps>();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);

  const isDisabled = useMemo(
    () => ({
      initiativeAgregatedValue: !form?.companyCollaborate,
      differenceTraditionalAgencies:
        !form?.companyCollaborate || !form?.initiativeAgregatedValue,
      recognizedAchievements:
        !form?.companyCollaborate ||
        !form?.initiativeAgregatedValue ||
        !form?.differenceTraditionalAgencies,
      obstaclesInInclusionHired:
        !form?.companyCollaborate ||
        !form?.initiativeAgregatedValue ||
        !form?.differenceTraditionalAgencies ||
        !form?.recognizedAchievements,
      mainLearning:
        !form?.companyCollaborate ||
        !form?.initiativeAgregatedValue ||
        !form?.differenceTraditionalAgencies ||
        !form?.recognizedAchievements ||
        !form?.aboutStudent ||
        !form?.aboutStudent?.length ||
        !form?.aboutStudent[0]?.obstaclesInInclusionHired,
      skillsTechnicalSocialemotional:
        !form?.companyCollaborate ||
        !form?.initiativeAgregatedValue ||
        !form?.differenceTraditionalAgencies ||
        !form?.recognizedAchievements ||
        !form?.aboutStudent ||
        !form?.aboutStudent?.length ||
        !form?.aboutStudent[0]?.obstaclesInInclusionHired ||
        !form?.aboutStudent[0]?.mainLearning,
      betterSkillsTechnicalSocialemotional:
        !form?.companyCollaborate ||
        !form?.initiativeAgregatedValue ||
        !form?.differenceTraditionalAgencies ||
        !form?.recognizedAchievements ||
        !form?.aboutStudent ||
        !form?.aboutStudent?.length ||
        !form?.aboutStudent[0]?.obstaclesInInclusionHired ||
        !form?.aboutStudent[0]?.mainLearning ||
        !form?.aboutStudent[0]?.skillsTechnicalSocialemotional,
      autonomyProactivity:
        !form?.companyCollaborate ||
        !form?.initiativeAgregatedValue ||
        !form?.differenceTraditionalAgencies ||
        !form?.recognizedAchievements ||
        !form?.aboutStudent ||
        !form?.aboutStudent?.length ||
        !form?.aboutStudent[0]?.obstaclesInInclusionHired ||
        !form?.aboutStudent[0]?.mainLearning ||
        !form?.aboutStudent[0]?.skillsTechnicalSocialemotional ||
        !form?.aboutStudent[0]?.betterSkillsTechnicalSocialemotional,
      technicalKnowledge:
        !form?.companyCollaborate ||
        !form?.initiativeAgregatedValue ||
        !form?.differenceTraditionalAgencies ||
        !form?.recognizedAchievements ||
        !form?.aboutStudent ||
        !form?.aboutStudent?.length ||
        !form?.aboutStudent[0]?.obstaclesInInclusionHired ||
        !form?.aboutStudent[0]?.mainLearning ||
        !form?.aboutStudent[0]?.skillsTechnicalSocialemotional ||
        !form?.aboutStudent[0]?.betterSkillsTechnicalSocialemotional ||
        !form?.aboutStudent[0]?.autonomyProactivity,
      teamWork:
        !form?.companyCollaborate ||
        !form?.initiativeAgregatedValue ||
        !form?.differenceTraditionalAgencies ||
        !form?.recognizedAchievements ||
        !form?.aboutStudent ||
        !form?.aboutStudent?.length ||
        !form?.aboutStudent[0]?.obstaclesInInclusionHired ||
        !form?.aboutStudent[0]?.mainLearning ||
        !form?.aboutStudent[0]?.skillsTechnicalSocialemotional ||
        !form?.aboutStudent[0]?.betterSkillsTechnicalSocialemotional ||
        !form?.aboutStudent[0]?.autonomyProactivity ||
        !form?.aboutStudent[0]?.technicalKnowledge,
      flexibilityAdaptability:
        !form?.companyCollaborate ||
        !form?.initiativeAgregatedValue ||
        !form?.differenceTraditionalAgencies ||
        !form?.recognizedAchievements ||
        !form?.aboutStudent ||
        !form?.aboutStudent?.length ||
        !form?.aboutStudent[0]?.obstaclesInInclusionHired ||
        !form?.aboutStudent[0]?.mainLearning ||
        !form?.aboutStudent[0]?.skillsTechnicalSocialemotional ||
        !form?.aboutStudent[0]?.betterSkillsTechnicalSocialemotional ||
        !form?.aboutStudent[0]?.autonomyProactivity ||
        !form?.aboutStudent[0]?.technicalKnowledge ||
        !form?.aboutStudent[0]?.teamWork,
      recommendation:
        !form?.companyCollaborate ||
        !form?.initiativeAgregatedValue ||
        !form?.differenceTraditionalAgencies ||
        !form?.recognizedAchievements ||
        !form?.aboutStudent ||
        !form?.aboutStudent?.length ||
        !form?.aboutStudent[0]?.obstaclesInInclusionHired ||
        !form?.aboutStudent[0]?.mainLearning ||
        !form?.aboutStudent[0]?.skillsTechnicalSocialemotional ||
        !form?.aboutStudent[0]?.betterSkillsTechnicalSocialemotional ||
        !form?.aboutStudent[0]?.autonomyProactivity ||
        !form?.aboutStudent[0]?.technicalKnowledge ||
        !form?.aboutStudent[0]?.teamWork ||
        !form?.aboutStudent[0]?.flexibilityAdaptability,
    }),
    [
      form?.aboutStudent,
      form?.companyCollaborate,
      form?.differenceTraditionalAgencies,
      form?.initiativeAgregatedValue,
      form?.recognizedAchievements,
    ],
  );

  const handleOnBlur = useCallback(() => {
    if (!isDisabled?.recommendation && !!form?.recommendation)
      dispatch(
        setNextButton(() => {
          dispatch(
            updateEmployment({
              ...employment,
              hasFinished: true,
              form,
            }) as unknown as Action,
          );
        }) as unknown as Action,
      );
  }, [dispatch, employment, form, isDisabled?.recommendation]);

  const loadSkills = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getAutocomplete(autoCompleteType.SKILL);
    const skillOptions = response?.map((option) => option?.name);
    setSkillOptions(skillOptions);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  useEffect(() => {
    if (!skillsOptions) loadSkills();
  }, [loadSkills, skillsOptions]);

  const onUpdate = useCallback(
    async (value: string[] | string | null, fieldName: string) => {
      registerAutoComplete(value, autoCompleteType.SKILL);
      setForm({
        ...form,
        aboutStudent: [{ ...form?.aboutStudent[0], [fieldName]: value }],
      });
    },
    [form],
  );

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) => setForm({ ...form, [prop]: event.target.value }),
    [form],
  );

  const handleChangeStudent = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) =>
      setForm({
        ...form,
        aboutStudent: [
          { ...form?.aboutStudent[0], [prop]: event.target.value },
        ],
      }),
    [form],
  );

  const handleChangeRadioNumberStudent = useCallback(
    (event: { target: { value: unknown } }, prop: string) => {
      setForm({
        ...form,
        aboutStudent: [
          { ...form?.aboutStudent[0], [prop]: event.target.value },
        ],
      });

      if (!isDisabled?.recommendation && !!form?.recommendation)
        dispatch(
          setNextButton(() => {
            dispatch(
              updateEmployment({
                ...employment,
                hasFinished: true,
                form: {
                  ...form,
                  aboutStudent: [
                    { ...form?.aboutStudent[0], [prop]: event.target.value },
                  ],
                },
              }) as unknown as Action,
            );
          }) as unknown as Action,
        );
    },
    [dispatch, employment, form, isDisabled?.recommendation],
  );

  const getStudent = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getUserIdResearchBySupervisionIdAndClassId(
      user?._id,
      classId,
    );
    if (response?.length) {
      const studentResponse = await getUserById(response[0]);
      if (studentResponse) {
        setStudentName(studentResponse?.profile?.name);

        if (!form?.aboutStudent)
          setForm({
            ...form,
            aboutStudent: [{ studentId: studentResponse?._id }],
          });
        else
          setForm({
            ...form,
            aboutStudent: [...(form?.aboutStudent ?? [])],
          });
      }
    }
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, form, user?._id, classId]);

  useEffect(() => {
    if (!studentName) getStudent();
  }, [getStudent, studentName]);

  const items = useMemo(
    (): Item[] => [
      {
        id: "companyCollaborate",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Quais aspectos impulsionaram a empresa a colaborar com a iniciativa e/ou contratar ex alunas da {reprograma}?"
              />
            )}
            <Input
              className="width-select-course"
              label="Escreva aqui"
              onChange={(e) => handleChange(e, "companyCollaborate")}
              onBlur={handleOnBlur}
              value={form?.companyCollaborate ?? ""}
            />
          </>
        ),
      },
      {
        id: "initiativeAgregatedValue",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Potencialmente falando, o que a iniciativa agrega para a empresa? E para os trabalhadores?"
                disabled={isDisabled.initiativeAgregatedValue}
              />
            )}
            <Input
              className="width-select-course"
              label="Escreva aqui"
              onChange={(e) => handleChange(e, "initiativeAgregatedValue")}
              onBlur={handleOnBlur}
              value={form?.initiativeAgregatedValue ?? ""}
              disabled={isDisabled.initiativeAgregatedValue}
            />
          </>
        ),
      },
      {
        id: "differenceTraditionalAgencies",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text={`Em quais aspectos a iniciativa se diferencia dos serviços tradicionais de "agências de empregos" (Exemplo: Catho)`}
                disabled={isDisabled.differenceTraditionalAgencies}
              />
            )}
            <Input
              className="width-select-course"
              label="Escreva aqui"
              onChange={(e) => handleChange(e, "differenceTraditionalAgencies")}
              onBlur={handleOnBlur}
              value={form?.differenceTraditionalAgencies ?? ""}
              disabled={isDisabled.differenceTraditionalAgencies}
            />
          </>
        ),
      },
      {
        id: "recognizedAchievements",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Até agora, quais conquistas podem ser reconhecidas?"
                disabled={isDisabled.recognizedAchievements}
              />
            )}
            <Input
              className="width-select-course"
              label="Escreva aqui"
              onChange={(e) => handleChange(e, "recognizedAchievements")}
              onBlur={handleOnBlur}
              value={form?.recognizedAchievements ?? ""}
              disabled={isDisabled.recognizedAchievements}
            />
          </>
        ),
      },
      {
        id: "obstaclesInInclusionHired",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Quais têm sido os principais obstáculos ou desafios do processo de inclusão da nossa ex aluna na sua empresa?"
                disabled={isDisabled.obstaclesInInclusionHired}
              />
            )}
            <Input
              className="width-select-course"
              label="Escreva aqui"
              onChange={(e) =>
                handleChangeStudent(e, "obstaclesInInclusionHired")
              }
              onBlur={handleOnBlur}
              value={
                form?.aboutStudent
                  ? form?.aboutStudent[0]?.obstaclesInInclusionHired
                  : ""
              }
              disabled={isDisabled.obstaclesInInclusionHired}
            />
          </>
        ),
      },
      {
        id: "mainLearning",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Considerando o propósito e a cultura organizacional da empresa, na sua avaliação, quais teriam sido as principais aprendizagens para a nossa ex aluna? E para sua empresa?"
                disabled={isDisabled.mainLearning}
              />
            )}
            <Input
              className="width-select-course"
              label="Escreva aqui"
              onChange={(e) => handleChangeStudent(e, "mainLearning")}
              onBlur={handleOnBlur}
              value={
                form?.aboutStudent ? form?.aboutStudent[0]?.mainLearning : ""
              }
              disabled={isDisabled.mainLearning}
            />
          </>
        ),
      },
      {
        id: "skillsTechnicalSocialemotional",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Quais outras habilidades técnicas e socioemocionais você acha que a ex aluna demonstra?"
                subText="Se possível, seja objetivo mencionando cada habilidade em 1 ou 2 palavras, mas sinta-se a vontade para citar exemplos ou explicações."
                disabled={isDisabled.skillsTechnicalSocialemotional}
              />
            )}
            <InputAutocomplete
              multiple
              label="Habilidade"
              helperText="Pressione Enter para adicionar"
              className="skills-input"
              options={skillsOptions ?? [""]}
              getOptionLabel={(option) => option}
              value={
                form?.aboutStudent &&
                form?.aboutStudent?.length &&
                form?.aboutStudent[0]?.skillsTechnicalSocialemotional &&
                form?.aboutStudent[0]?.skillsTechnicalSocialemotional?.length
                  ? form?.aboutStudent[0]?.skillsTechnicalSocialemotional
                  : []
              }
              onChange={(values) =>
                onUpdate(values, "skillsTechnicalSocialemotional")
              }
              disabled={isDisabled.skillsTechnicalSocialemotional}
            />
          </>
        ),
      },
      {
        id: "betterSkillsTechnicalSocialemotional",
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Quais habilidades técnicas e socioemocionais você acha que a ex aluna deveria aperfeçoar?"
                subText="Se possível, responda objetivamente mencionando cada habilidade em 1 ou 2 palavras, mas sinta-se a vontade para citar exemplos ou explicações."
                disabled={isDisabled.betterSkillsTechnicalSocialemotional}
              />
            )}
            <InputAutocomplete
              multiple
              label="Habilidade"
              helperText="Pressione Enter para adicionar"
              className="skills-input"
              options={skillsOptions ?? [""]}
              getOptionLabel={(option) => option}
              value={
                form?.aboutStudent &&
                form?.aboutStudent?.length &&
                form?.aboutStudent[0]?.betterSkillsTechnicalSocialemotional &&
                form?.aboutStudent[0]?.betterSkillsTechnicalSocialemotional
                  ?.length
                  ? form?.aboutStudent[0]?.betterSkillsTechnicalSocialemotional
                  : []
              }
              onChange={(values) =>
                onUpdate(values, "betterSkillsTechnicalSocialemotional")
              }
              disabled={isDisabled.betterSkillsTechnicalSocialemotional}
            />
          </>
        ),
      },
      {
        id: "autonomyProactivity",
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Como você avalia a nossa ex-aluna em termos de autonomia e proatividade?"
                subText="Escala de 0 (Não tem confiança para buscar soluções por conta própria) a 10 (é autônoma para buscar soluções para os desafios da função)."
                disabled={isDisabled.autonomyProactivity}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="autonomyProactivity"
                name="autonomyProactivity"
                defaultValue="top"
                onChange={(e) =>
                  handleChangeRadioNumberStudent(e, "autonomyProactivity")
                }
                className="margin-top-20"
                value={
                  form?.aboutStudent
                    ? form?.aboutStudent[0]?.autonomyProactivity
                    : ""
                }
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.autonomyProactivity}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.autonomyProactivity}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.autonomyProactivity}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.autonomyProactivity}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.autonomyProactivity}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.autonomyProactivity}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.autonomyProactivity}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.autonomyProactivity}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.autonomyProactivity}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.autonomyProactivity}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.autonomyProactivity}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },

      {
        id: "technicalKnowledge",
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Como você avalia a nossa ex-aluna em termos de conhecimento técnico no cargo ocupado?"
                subText="Escala de 0 (não atende aos requisitos da vaga) a 10 (atende aos requisitos da vaga)."
                disabled={isDisabled.technicalKnowledge}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="technicalKnowledge"
                name="technicalKnowledge"
                defaultValue="top"
                onChange={(e) =>
                  handleChangeRadioNumberStudent(e, "technicalKnowledge")
                }
                value={
                  form?.aboutStudent
                    ? form?.aboutStudent[0]?.technicalKnowledge
                    : ""
                }
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.technicalKnowledge}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.technicalKnowledge}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalKnowledge}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalKnowledge}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalKnowledge}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalKnowledge}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalKnowledge}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalKnowledge}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalKnowledge}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalKnowledge}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.technicalKnowledge}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "teamWork",
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Como você avalia a nossa ex-aluna em termos de trabalho em equipe, cooperação e relacionamento com a equipe?"
                subText="Escala de 0 (A aluna não se relaciona bem com a equipe, não aplica a escuta ativa e não coopera no desenvolvimento dos trabalhos) a 10 (A Aluna se relaciona bem com o restante da equipe, traz boas contribuições, é cooperativa e tem escuta ativa)."
                disabled={isDisabled.teamWork}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="teamWork"
                name="teamWork"
                defaultValue="top"
                onChange={(e) => handleChangeRadioNumberStudent(e, "teamWork")}
                className="margin-top-20"
                value={
                  form?.aboutStudent ? form?.aboutStudent[0]?.teamWork : ""
                }
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.teamWork}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.teamWork}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.teamWork}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.teamWork}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.teamWork}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.teamWork}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.teamWork}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.teamWork}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.teamWork}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.teamWork}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.teamWork}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "flexibilityAdaptability",
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Como você avalia a nossa ex-aluna em termos de flexibilidade e adaptabilidade?"
                subText="Escala de 0 a 10."
                disabled={isDisabled.flexibilityAdaptability}
              />
            )}
            <FormControl>
              <RadioGroup
                row
                aria-label="flexibilityAdaptability"
                name="flexibilityAdaptability"
                defaultValue="top"
                onChange={(e) =>
                  handleChangeRadioNumberStudent(e, "flexibilityAdaptability")
                }
                value={
                  form?.aboutStudent
                    ? form?.aboutStudent[0]?.flexibilityAdaptability
                    : ""
                }
                className="margin-top-20"
              >
                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="0"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.flexibilityAdaptability}
                />
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1"
                  labelPlacement="top"
                  className="margin-right-32 checkbox-welcome-accepted"
                  disabled={isDisabled.flexibilityAdaptability}
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.flexibilityAdaptability}
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="3"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.flexibilityAdaptability}
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="4"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.flexibilityAdaptability}
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="5"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.flexibilityAdaptability}
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="6"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.flexibilityAdaptability}
                />
                <FormControlLabel
                  value="7"
                  control={<Radio />}
                  label="7"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.flexibilityAdaptability}
                />
                <FormControlLabel
                  value="8"
                  control={<Radio />}
                  label="8"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.flexibilityAdaptability}
                />
                <FormControlLabel
                  value="9"
                  control={<Radio />}
                  label="9"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.flexibilityAdaptability}
                />
                <FormControlLabel
                  value="10"
                  control={<Radio />}
                  label="10"
                  labelPlacement="top"
                  className="checkbox-welcome-accepted"
                  disabled={isDisabled.flexibilityAdaptability}
                />
              </RadioGroup>
            </FormControl>
          </>
        ),
      },
      {
        id: "recommendation",
        isFullRow: true,
        child: (
          <>
            {templateRows && (
              <LabelInput
                alignStart
                text="Você gostaria de fazer alguma recomendação que pudesse ajudar a {reprograma} a evoluir?"
                disabled={isDisabled.recommendation}
              />
            )}
            <TextArea
              className="width-select-course margin-top-20"
              label="Escreva aqui"
              maxLength={500}
              onChange={(e) => handleChange(e, "recommendation")}
              onBlur={handleOnBlur}
              value={form?.recommendation ?? ""}
              disabled={isDisabled.recommendation}
            />
          </>
        ),
      },
    ],
    [
      form?.aboutStudent,
      form?.companyCollaborate,
      form?.differenceTraditionalAgencies,
      form?.initiativeAgregatedValue,
      form?.recognizedAchievements,
      form?.recommendation,
      handleChange,
      handleChangeRadioNumberStudent,
      handleChangeStudent,
      handleOnBlur,
      isDisabled.autonomyProactivity,
      isDisabled.betterSkillsTechnicalSocialemotional,
      isDisabled.differenceTraditionalAgencies,
      isDisabled.flexibilityAdaptability,
      isDisabled.initiativeAgregatedValue,
      isDisabled.mainLearning,
      isDisabled.obstaclesInInclusionHired,
      isDisabled.recognizedAchievements,
      isDisabled.recommendation,
      isDisabled.skillsTechnicalSocialemotional,
      isDisabled.teamWork,
      isDisabled.technicalKnowledge,
      onUpdate,
      skillsOptions,
      templateRows,
    ],
  );

  const itemsRefs = useMemo(
    () => items.map(() => createRef<HTMLDivElement>()),
    [items],
  );

  const updateLabelHeights = useCallback(() => {
    if (templateRows) {
      const getLabel = (ref: HTMLDivElement | null) => {
        if (ref) {
          const { children } = ref;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const [label] = children;
          return label;
        }
        return ref;
      };

      const getLabelHeight = (label: HTMLParagraphElement) => {
        const { offsetHeight } = label;
        return offsetHeight;
      };

      const removeLabelHeight = (label: HTMLParagraphElement) => {
        const { style } = label;
        style.removeProperty(heightProperty);
        label.removeAttribute(expandedAttribute);
        return label;
      };

      const setLabelHeight = (label: HTMLParagraphElement, height: number) => {
        const { style } = label;
        style.setProperty(heightProperty, `${height}px`);
        label.setAttribute(expandedAttribute, "");
      };

      itemsRefs
        .filter(
          ({ current }) =>
            current && !current.className.includes(fullRowClassName),
        )
        .forEach(
          (
            { current: rightItem }: any,
            index: number,
            arr: { current: any }[],
          ) => {
            if (index % 2 !== 0) {
              // Coluna esquerda
              const { current: leftItem } = arr[index - 1];
              const leftLabel = removeLabelHeight(getLabel(leftItem));
              const leftHeight = getLabelHeight(leftLabel);

              // Coluna direita
              const rightLabel = removeLabelHeight(getLabel(rightItem));
              const rightHeight = getLabelHeight(rightLabel);

              // Expansão de altura
              if (leftHeight > rightHeight)
                setLabelHeight(rightLabel, leftHeight);
              else setLabelHeight(leftLabel, rightHeight);

              // Redução de altura
              if (
                leftHeight < rightHeight &&
                rightLabel.hasAttribute(expandedAttribute)
              ) {
                removeLabelHeight(leftLabel);
                setLabelHeight(rightLabel, leftHeight);
              } else if (
                rightHeight < leftHeight &&
                leftLabel.hasAttribute(expandedAttribute)
              ) {
                removeLabelHeight(rightLabel);
                setLabelHeight(leftLabel, rightHeight);
              }
            }
          },
        );
    }
  }, [itemsRefs, templateRows]);

  useEffect(() => {
    const handleResize = () => {
      updateLabelHeights();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [updateLabelHeights]);

  useEffect(() => updateLabelHeights(), [updateLabelHeights]);

  useEffect(() => {
    if (!isDisabled?.recommendation && !!form?.recommendation) setIsValid(true);
    else setIsValid(false);
  }, [form?.recommendation, isDisabled?.recommendation, setIsValid]);

  return (
    <div className="grid-account-gap grid-account-gap-resposive">
      <div className={fullRowClassName}>
        <LabelInput
          alignStart
          text={`**Este formulário é referente a ex-aluna da {reprograma}: ${
            studentName ?? ""
          }**`}
          optional
        />
      </div>
      {items.map(
        ({ isFullRow, renderCondition, className, child }, index) =>
          (renderCondition ?? true) && (
            <div
              ref={itemsRefs[index]}
              className={
                isFullRow
                  ? fullRowClassName
                  : `content-grid-row ${
                      templateRows
                        ? `content-grid-2-row ${className ?? ""}`
                        : ""
                    }`
              }
            >
              {child}
            </div>
          ),
      )}
    </div>
  );
};
export default memo(CompanyEmployment);
