export const VacancyStatus = {
  PENDING: "Pendente",
  ACTIVE: "Ativa",
  INACTIVE: "Inativa",
  FINISHED: "Finalizada",
  STOPPED: "Pausada",
  DISABLED: "Desabilitada",
};

export const VacancySubscriptionResult = {
  APPROVED: "Aprovada",
  QUEUE: "Em andamento",
  DISAPPROVED: "Reprovada",
  NONE: "Cancelada",
};

export const VacancySubscriptionStatus = {
  APPROVED: "Aprovada",
  DISAPPROVED: "Reprovada",
  IN_PROCESS: "Em análise",
  PENDING: "Pendente",
  NONE: "Cancelada",
};

export const GeneralStatus = {
  PENDING: "Pendente",
  ACTIVE: "Ativa",
  INACTIVE: "Inativa",
  FINISHED: "Finalizada",
  STOPPED: "Pausada",
  DISABLED: "Desabilitada",
  IN_PROCESS: "Em processo",
  APPROVED: "Aprovada",
  DISAPPROVED: "Reprovada",
  WAITING_LIST: "Lista de Espera",
  NONE: "Cancelada",
  GIVE_UP: "Desistente",
  CHANGED: "Transferida",
  REPLACED: "Substituída",
  BLOCKED: "Bloqueada",
};

export const ManagedBy = {
  REPROGRAMA: "{reprograma}",
  GREENHOUSE: "Greenhouse",
  GUPY: "Gupy",
  EXTERNAL_VACANCY: "Curadoria",
};
