import React, { memo, MouseEventHandler, useEffect, useState } from "react";
import { ArrowLeft, LeftMobile, Logo, LogoMobile } from "../../../assets/icons";
import "./styles.scss";

interface Props {
  back?: boolean;
  onClickBack?: MouseEventHandler<SVGElement>;
}

const Header = ({ back, onClickBack }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="header-component">
      {back && (
        <button
          type="button"
          onClick={onClickBack}
          className="header header-component-button absolute left-0"
        >
          {width < 991 ? (
            <LeftMobile />
          ) : (
            <ArrowLeft fill="#ffffff" className="margin-right-8" />
          )}

          {width > 990 && "Voltar"}
        </button>
      )}
      <div className="div-logo-header">
        {width < 991 ? <LogoMobile /> : <Logo />}
      </div>
    </div>
  );
};

export default memo(Header);
