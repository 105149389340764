/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, {
  ChangeEvent,
  memo,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import classNames from "classnames";
import {
  Checkbox,
  Layout,
  HeaderProfile,
  Card,
  UsersProfile,
  ModalSendInvite,
  Button,
  ButtonFilter,
  Pagination,
  UserCard,
  ModalConfigAndFilters,
} from "../../components";
import "./style.scss";
import {
  filterUser,
  getSuggestionsOfUsers,
  getUserFilters,
  searchCorporatePlacementVacancy,
  searchUsers,
  getPages,
} from "../../services/functions";
import { placementVacancyStatus } from "../../types/enumerators";
import ModalSendMessage from "../../components/ModalSendMessage";
import { User } from "../../types/interfaces";
import { RootState } from "../../store/reducers";
import MobileCompanySearch from "./MobileCompanySearch";
import { setLoad } from "../../store/actions/configurationsActions";

interface UserProps {
  _id: string;
  personName: string;
  personPosition: string;
  personCity: string;
  personDescription: string;
  personPhoto: string;
  personEmail: string;
}

interface Filters {
  localization?: string[];
  education?: string[];
  skill?: string[];
  language?: string[];
  class?: string[];
  openForProposals?: boolean | null;
  openForChanges?: boolean | null;
  openForFreelancer?: boolean | null;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CompanySearch = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [isLocalization, setIsLocalization] = useState(false);
  const [isEducation, setIsEducation] = useState(false);
  const [isSkill, setIsSkill] = useState(false);
  const [isLanguage, setIsLanguage] = useState(false);
  // const [isClass, setIsClass] = useState(false);
  const [filtersOptions, setFiltersOptions] = useState<Filters>({});
  const [choosedUserId, setChoosedUserId] = useState<string>("");
  const [openModalInvite, setOpenModalInvite] = useState(false);
  const [openModalSend, setOpenModalSend] = useState(false);
  const [hasVacancies, setHasVacancies] = useState(false);
  const [users, setUsers] = useState<UserProps[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<Array<UserProps>>([]);
  const [filterUserList, setFilterUserList] = useState<Filters>({});
  const [applyFilters, setApplyFilters] = useState<Filters>({});
  const [tempFilters, setTempFilters] = useState<Filters>({});
  const [openModal, setOpenModal] = useState(false);
  const [suggestedUser, setSuggestedUser] = useState<User[]>();
  const user = useSelector((state: RootState) => state.userState);
  const company = useSelector((state: RootState) => state.companyState);

  const dispatch = useDispatch();

  const query = useQuery();
  const searchParams = query.get("params");

  const styled = classNames(
    suggestedUser && suggestedUser.length > 0
      ? "grid-column-1-10"
      : "grid-column-1-13",
  );

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const getUsersList = useCallback(async () => {
    const response = (await getSuggestionsOfUsers(company?._id)) ?? [];
    setSuggestedUser(response);
  }, [company?._id]);

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  const sort = useCallback((list, prop) => {
    list[prop]?.sort((a: string, b: string) => {
      if (a && b) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      }
      return 0;
    });
    return list;
  }, []);

  useEffect(() => {
    const run = async () => {
      const response = await getUserFilters(
        searchParams,
        applyFilters,
        company._id,
      );
      if (response && response?.length) {
        setFiltersOptions(response[0]);
        setFilterUserList(response[0]);
      }
    };
    run();
  }, [applyFilters, company._id, searchParams]);

  useEffect(() => {
    if (
      filtersOptions &&
      filtersOptions?.skill?.length &&
      !filterUserList?.skill
    ) {
      let newFilterUserList = { ...filterUserList };
      filtersOptions?.skill?.forEach((item: any) => {
        if (newFilterUserList?.skill?.length)
          newFilterUserList?.skill?.push(item);
        else {
          newFilterUserList = {
            ...newFilterUserList,
            skill: [item],
          };
        }
      });
      newFilterUserList = sort(newFilterUserList, "skill");
      setFilterUserList(newFilterUserList);
    }
  }, [filterUserList, filtersOptions, sort]);

  useEffect(() => {
    if (
      filtersOptions &&
      filtersOptions?.language?.length &&
      !filterUserList?.language
    ) {
      let newFilterUserList = { ...filterUserList };
      filtersOptions?.language?.forEach((item: any) => {
        if (newFilterUserList?.language?.length)
          newFilterUserList?.language?.push(item);
        else {
          newFilterUserList = {
            ...newFilterUserList,
            language: [item],
          };
        }
      });
      newFilterUserList = sort(newFilterUserList, "language");
      setFilterUserList(newFilterUserList);
    }
  }, [filterUserList, filtersOptions, sort]);

  // useEffect(() => {
  //   if (
  //     filtersOptions &&
  //     filtersOptions?.class?.length &&
  //     !filterUserList?.class
  //   ) {
  //     let newFilterUserList = { ...filterUserList };
  //     filtersOptions?.class?.forEach((item: any) => {
  //       if (newFilterUserList?.class?.length)
  //         newFilterUserList?.class?.push(item);
  //       else {
  //         newFilterUserList = {
  //           ...newFilterUserList,
  //           class: [item],
  //         };
  //       }
  //     });
  //     newFilterUserList = sort(newFilterUserList, "class");
  //     setFilterUserList(newFilterUserList);
  //   }
  // }, [filterUserList, filtersOptions, sort]);

  useEffect(() => {
    if (
      filtersOptions &&
      filtersOptions?.localization?.length &&
      !filterUserList?.localization
    ) {
      let newFilterUserList = { ...filterUserList };
      filtersOptions?.localization?.forEach((item: any) => {
        if (newFilterUserList?.localization?.length)
          newFilterUserList?.localization?.push(item);
        else {
          newFilterUserList = {
            ...newFilterUserList,
            localization: [item],
          };
        }
      });
      newFilterUserList = sort(newFilterUserList, "localization");
      setFilterUserList(newFilterUserList);
    }
  }, [filterUserList, filtersOptions, sort]);

  useEffect(() => {
    if (
      filtersOptions &&
      filtersOptions?.education?.length &&
      !filterUserList?.education
    ) {
      let newFilterUserList = { ...filterUserList };
      filtersOptions?.education?.forEach((item: any) => {
        if (newFilterUserList?.education?.length)
          newFilterUserList?.education?.push(item);
        else {
          newFilterUserList = {
            ...newFilterUserList,
            education: [item],
          };
        }
      });
      newFilterUserList = sort(newFilterUserList, "education");
      setFilterUserList(newFilterUserList);
    }
  }, [filterUserList, filtersOptions, sort]);

  const getUsers = useCallback((list) => {
    const currentList = list?.map((item: any) => ({
      _id: item?._id ?? "",
      personName: item?.profile?.name ?? "",
      personCity:
        item?.profile?.address?.city && item?.profile?.address?.province
          ? `${item?.profile?.address?.city} - ${item?.profile?.address?.province}`
          : "",
      personDescription: item?.profile?.bio ?? "",
      personPhoto: item?.profile?.photo ?? "",
      personEmail: item?.profile?.email ?? "",
      personPosition:
        item?.curriculum?.works?.length &&
        !item?.curriculum?.works?.[(item?.curriculum?.works?.length ?? 0) - 1]
          .endDate &&
        item?.curriculum?.works?.[(item?.curriculum?.works?.length ?? 0) - 1]
          ?.position
          ? item?.curriculum?.works?.[
              (item?.curriculum?.works?.length ?? 0) - 1
            ]?.position
          : "",
    }));

    return currentList;
  }, []);

  const loadSearchUsers = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    try {
      if (pageLimit) {
        setMaxPage(
          await getPages(searchUsers, searchParams ?? "", 0, company._id),
        );
        const searchedUsers = await searchUsers(
          searchParams ?? "",
          0,
          company._id,
          page,
        );
        const searchedUsersMapped = getUsers(searchedUsers);
        setUsers(searchedUsersMapped);
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao carregar as pesquisas");
    } finally {
      dispatch(setLoad(false) as unknown as Action);
    }
  }, [company._id, dispatch, getUsers, page, pageLimit, searchParams]);

  useEffect(() => {
    loadSearchUsers();
  }, [loadSearchUsers]);

  const loadVacancies = useCallback(async () => {
    if (user?.account?.companyIds?.length) {
      const vacancies = await searchCorporatePlacementVacancy(
        user?.account?.companyIds[0],
        0,
      );
      const validVacancies = vacancies?.filter(
        (item: { status: string }) =>
          item.status === placementVacancyStatus.ACTIVE,
      );
      if (validVacancies && validVacancies?.length) setHasVacancies(true);
      else setHasVacancies(false);
    }
  }, [user?.account?.companyIds]);

  useEffect(() => {
    loadVacancies();
  }, [loadVacancies]);

  const optionsAllFilters = useCallback(
    (
      type:
        | "localization"
        | "education"
        | "skill"
        | "language"
        | "openForProposals"
        | "openForChanges"
        | "openForFreelancer",
      // | "class"
    ) => {
      const result: any[] = [];

      if (type === "localization")
        filtersOptions?.localization?.forEach((key) => {
          result?.push({
            value: key,
            label: key,
          });
        });

      if (type === "education")
        filtersOptions?.education?.forEach((key) => {
          result?.push({
            value: key,
            label: key,
          });
        });

      if (type === "skill")
        filtersOptions?.skill?.forEach((key) => {
          result?.push({
            value: key,
            label: key,
          });
        });

      if (type === "language")
        filtersOptions?.language?.forEach((key) => {
          result?.push({
            value: key,
            label: key,
          });
        });

      if (
        type === "openForProposals" ||
        type === "openForChanges" ||
        type === "openForFreelancer"
      )
        result?.push(
          {
            value: undefined,
            label: "Todos",
          },
          {
            value: true,
            label: "Sim",
          },
          {
            value: false,
            label: "Não",
          },
        );

      // if (type === "class") {
      //   filtersOptions?.class?.forEach((item) => {
      //     result?.push({
      //       value: item?._id,
      //       label: item?.code,
      //     });
      //   });
      // }

      return result;
    },
    [
      // filtersOptions?.class,
      filtersOptions?.education,
      filtersOptions?.language,
      filtersOptions?.localization,
      filtersOptions?.skill,
    ],
  );

  const handleCheckboxSelect = useCallback(
    (id: string, event: ChangeEvent<HTMLInputElement>) => {
      const selectedUser = users.find((item) => item._id === id);

      if (
        event.target.checked &&
        selectedUser?.personEmail &&
        !selectedUsers.find((item) => item._id === id)
      ) {
        setSelectedUsers((currentUsers) => [...currentUsers, selectedUser]);
      }
      if (!event.target.checked) {
        setSelectedUsers((items) =>
          items.filter((item) => item._id !== selectedUser?._id),
        );
      }
    },
    [selectedUsers, users],
  );

  const handleChangeFilters = useCallback(
    (
      value: string,
      checked: boolean,
      props: "localization" | "education" | "skill" | "language",
    ) => {
      let newFilters = JSON.parse(JSON.stringify(tempFilters));

      if (newFilters[props] && newFilters[props]?.length) {
        if (checked) newFilters[props]?.push(value);
        else {
          const index = newFilters[props]?.findIndex(
            (item: string) => item === value,
          );
          newFilters[props]?.splice(index ?? 0, 1);

          if (newFilters[props]?.length === 0) delete newFilters[props];
        }
      } else if (checked) newFilters = { ...newFilters, [props]: [value] };

      setTempFilters(newFilters);
    },
    [tempFilters],
  );

  const toggleSwitch = useCallback(
    (
      key:
        | "localization"
        | "education"
        | "skill"
        | "language"
        | "openForProposals"
        | "openForChanges"
        | "openForFreelancer",
      // | "class",
      value: any,
      isCheckbox = false,
    ) => {
      if (isCheckbox)
        setTempFilters((prev) => ({
          ...(prev || []),
          [key]: prev[key]?.includes(value)
            ? prev[key]?.filter((el) => el !== value)
            : [...(prev[key] || []), value],
        }));
      else
        setTempFilters((prev) => ({
          ...prev,
          [key]: value,
        }));
    },
    [],
  );

  const options = useMemo(
    () => [
      {
        id: "localization",
        label: "Localização",
        type: "checkbox",
        options: optionsAllFilters("localization"),
        value: tempFilters?.localization,
        handleChange: (value: any) => toggleSwitch("localization", value, true),
      },
      {
        id: "education",
        label: "Educação",
        type: "checkbox",
        options: optionsAllFilters("education"),
        value: tempFilters?.education,
        handleChange: (value: any) => toggleSwitch("education", value, true),
      },
      {
        id: "skill",
        label: "Habilidades",
        type: "checkbox",
        options: optionsAllFilters("skill"),
        value: tempFilters?.skill,
        handleChange: (value: any) => toggleSwitch("skill", value, true),
      },
      {
        id: "language",
        label: "Idioma",
        type: "checkbox",
        options: optionsAllFilters("language"),
        value: tempFilters?.language,
        handleChange: (value: any) => toggleSwitch("language", value, true),
      },
      {
        id: "openForProposals",
        label: "Aberta a propostas",
        type: "radio",
        options: optionsAllFilters("openForProposals"),
        value: tempFilters?.openForProposals,
        handleChange: (value: any) =>
          toggleSwitch("openForProposals", value, false),
      },
      {
        id: "openForChanges",
        label: "Aberta a mudança de cidade",
        type: "radio",
        options: optionsAllFilters("openForChanges"),
        value: tempFilters?.openForChanges,
        handleChange: (value: any) =>
          toggleSwitch("openForChanges", value, false),
      },
      {
        id: "openForFreelancer",
        label: "Aberta a freelancer",
        type: "radio",
        options: optionsAllFilters("openForFreelancer"),
        value: tempFilters?.openForFreelancer,
        handleChange: (value: any) =>
          toggleSwitch("openForFreelancer", value, false),
      },
      // {
      //   id: "class",
      //   label: "Turma",
      //   type: "checkbox",
      //   options: optionsAllFilters("class"),
      //   value: tempFilters?.class,
      //   handleChange: (value: any) => toggleSwitch("class", value),
      // },
    ],
    [
      optionsAllFilters,
      tempFilters?.education,
      tempFilters?.language,
      tempFilters?.localization,
      tempFilters?.openForChanges,
      tempFilters?.openForFreelancer,
      tempFilters?.openForProposals,
      tempFilters?.skill,
      toggleSwitch,
    ],
  );

  const onClear = useCallback(
    async (
      props: "localization" | "education" | "skill" | "language",
      // | "class",
    ) => {
      const newFilters = JSON.parse(JSON.stringify(tempFilters));
      delete newFilters[props];

      setMaxPage(
        await getPages(searchUsers, searchParams ?? "", 0, company._id),
      );
      const searchedUsers = await searchUsers(
        searchParams ?? "",
        0,
        company._id,
        page,
      );
      const searchedUsersMapped = getUsers(searchedUsers);

      setUsers(searchedUsersMapped);
      setTempFilters(newFilters);
      setApplyFilters({});
      setOpenModal(false);
      setIsLocalization(false);
      setIsEducation(false);
      setIsSkill(false);
      setIsLanguage(false);
      // setIsCourse(false);
      // setIsClass(false);
    },
    [company._id, getUsers, page, searchParams, tempFilters],
  );

  const onClearModalFilters = useCallback(async () => {
    setMaxPage(await getPages(searchUsers, searchParams ?? "", 0, company._id));
    const searchedUsers = await searchUsers(
      searchParams ?? "",
      0,
      company._id,
      page,
    );
    const searchedUsersMapped = getUsers(searchedUsers);

    setUsers(searchedUsersMapped);
    setTempFilters({} as Filters);
    setApplyFilters({});
    setSelectedUsers([]);
  }, [company._id, getUsers, page, searchParams]);

  const onSearchAll = useCallback(async () => {
    setApplyFilters({});
    setTempFilters({});
    setSelectedUsers([]);

    if (pageLimit) {
      setMaxPage(await getPages(filterUser, searchParams, {}, company._id));
      const response = await filterUser(searchParams, {}, company._id, page);
      const currentUsers = getUsers(response);

      setUsers(currentUsers);
    }
  }, [company._id, getUsers, page, pageLimit, searchParams]);

  const onSearch = useCallback(async () => {
    const newFilters = JSON.parse(JSON.stringify(tempFilters));
    setApplyFilters(newFilters);
    setSelectedUsers([]);
    if (pageLimit) {
      setMaxPage(
        await getPages(filterUser, searchParams, newFilters, company._id),
      );
      const response = await filterUser(
        searchParams,
        newFilters,
        company._id,
        page,
      );
      const currentUsers = getUsers(response);
      setUsers(currentUsers);

      setIsLocalization(false);
      setIsEducation(false);
      setIsSkill(false);
      setIsLanguage(false);
      // setIsClass(false);
      setApplyFilters({});
    }
  }, [company._id, getUsers, page, pageLimit, searchParams, tempFilters]);

  const handleCloseFilter = useCallback(
    (
      filterName: "localization" | "education" | "skill" | "language",
      // | "class",
    ) => {
      const filters = {
        localization: setIsLocalization,
        education: setIsEducation,
        skill: setIsSkill,
        language: setIsLanguage,
        // class: setIsClass,
      };
      Object.values(filters).forEach((key) => key(false));
      filters[filterName](true);
    },
    [],
  );

  const handleClickOpenModal = useCallback(() => setOpenModal(true), []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-responsive">
      <HeaderProfile />
      {width > 990 ? (
        <>
          <div
            className={`grid-row-1 max-height-search-filters-company ${styled}`}
          >
            <div className="company-search-items">
              <div className="company-search-buttons">
                <div className="filter-vacancy-place">
                  <ButtonFilter
                    onClick={() => handleCloseFilter("localization")}
                    onClose={() => {
                      setIsLocalization(false);
                      setTempFilters(applyFilters);
                    }}
                    onChange={handleChangeFilters}
                    onSearch={onSearch}
                    onClear={() => onClear("localization")}
                    text="Localização"
                    isOpen={isLocalization}
                    values={filterUserList.localization}
                    checkedList={tempFilters?.localization ?? []}
                    prop="localization"
                  />
                </div>
                <div className="filter-company-education">
                  <ButtonFilter
                    onClick={() => handleCloseFilter("education")}
                    onClose={() => {
                      setIsEducation(false);
                      setTempFilters(applyFilters);
                    }}
                    onChange={handleChangeFilters}
                    onSearch={onSearch}
                    onClear={() => onClear("education")}
                    text="Instituição de ensino"
                    isOpen={isEducation}
                    values={filterUserList.education}
                    checkedList={tempFilters?.education ?? []}
                    prop="education"
                  />
                </div>
                <div className="filter-company-skill">
                  <ButtonFilter
                    onClick={() => handleCloseFilter("skill")}
                    onClose={() => {
                      setIsSkill(false);
                      setTempFilters(applyFilters);
                    }}
                    onChange={handleChangeFilters}
                    onSearch={onSearch}
                    onClear={() => onClear("skill")}
                    text="Habilidade"
                    isOpen={isSkill}
                    values={filterUserList.skill}
                    checkedList={tempFilters?.skill ?? []}
                    prop="skill"
                  />
                </div>
                <div className="filter-company-language">
                  <ButtonFilter
                    onClick={() => handleCloseFilter("language")}
                    onClose={() => {
                      setIsLanguage(false);
                      setTempFilters(applyFilters);
                    }}
                    onChange={handleChangeFilters}
                    onSearch={onSearch}
                    onClear={() => onClear("language")}
                    text="Idioma"
                    isOpen={isLanguage}
                    values={filterUserList.language}
                    checkedList={tempFilters?.language ?? []}
                    prop="language"
                  />
                </div>
                <div className="filter-company-course">
                  <ButtonFilter
                    notIcon
                    text="Todos os filtros"
                    onClick={handleClickOpenModal}
                    className="top-filters-more button-all-filters"
                  />
                </div>
              </div>
              {Object.keys(applyFilters)?.length > 0 && (
                <span
                  className="button-clean-filters"
                  role="button"
                  onClick={onSearchAll}
                >
                  Limpar filtros
                </span>
              )}
            </div>
          </div>
          <div className={`relative margin-top-10-mobile ${styled}`}>
            <Card hideBorderRadius bodyClassName="card-search">
              {users?.length > 0 && hasVacancies && (
                <div
                  className={`divider-company-search flex-between ${
                    width > 990 ? "padding-20" : "padding-16-mobile"
                  }`}
                >
                  <Checkbox
                    className=" Checkboxcolor"
                    id="sendMessage"
                    checked={selectedUsers?.length === users?.length}
                    onClick={() => {
                      if (selectedUsers?.length === users?.length) {
                        setSelectedUsers([]);
                      } else {
                        setSelectedUsers(users);
                      }
                    }}
                  />

                  {selectedUsers?.length > 0 && (
                    <div className="header-button-wrapper">
                      <Button
                        text="Enviar convite"
                        onClick={() => setOpenModalSend(true)}
                        className="margin-top-0-mobile"
                      />
                    </div>
                  )}
                </div>
              )}
              {users?.length > 0 ? (
                users?.map((person) => (
                  <UserCard
                    key={Math.random()}
                    person={person}
                    handleCheck={handleCheckboxSelect}
                    hasVacancies={hasVacancies}
                    selectedUsers={selectedUsers}
                    setChoosedUserId={setChoosedUserId}
                    setOpenModalInvite={setOpenModalInvite}
                  />
                ))
              ) : (
                <UserCard blank />
              )}
            </Card>
          </div>
          <ModalConfigAndFilters
            openModal={openModal}
            onClose={() => setOpenModal(false)}
            textHeaderModal="Todos os Filtros"
            filters={options}
            onClear={onClearModalFilters}
            onSearch={() => {
              onSearch();
              setOpenModal(false);
            }}
          />
          {suggestedUser && suggestedUser.length > 0 && (
            <UsersProfile
              users={suggestedUser ?? []}
              row="2"
              hasButton={false}
            />
          )}
          {suggestedUser || users?.length > 0 ? (
            <div className="grid-column-1-11 grid-row-3 flex justify-center margin-suggested-user">
              <div>
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  showAllOption
                />
              </div>
            </div>
          ) : null}
          <ModalSendInvite
            userId={choosedUserId}
            openModal={openModalInvite}
            onClose={() => setOpenModalInvite(false)}
          />
          <ModalSendMessage
            setSelectedUsers={setSelectedUsers}
            selectedUsers={selectedUsers}
            openModal={openModalSend}
            onClose={() => setOpenModalSend(false)}
          />
        </>
      ) : (
        <MobileCompanySearch
          styled={styled}
          page={page}
          setPage={setPage}
          maxPage={maxPage}
          users={users}
          options={options}
          suggestedUser={suggestedUser}
          filterUserList={filterUserList}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          applyFilters={applyFilters}
          tempFilters={tempFilters}
          setTempFilters={setTempFilters}
          isLocalization={isLocalization}
          setIsLocalization={setIsLocalization}
          isEducation={isEducation}
          setIsEducation={setIsEducation}
          isSkill={isSkill}
          setIsSkill={setIsSkill}
          isLanguage={isLanguage}
          setIsLanguage={setIsLanguage}
          // isClass={isClass}
          // setIsClass={setIsClass}
          choosedUserId={choosedUserId}
          setChoosedUserId={setChoosedUserId}
          openModal={openModal}
          setOpenModal={setOpenModal}
          openModalSend={openModalSend}
          setOpenModalSend={setOpenModalSend}
          openModalInvite={openModalInvite}
          setOpenModalInvite={setOpenModalInvite}
          handleCloseFilter={handleCloseFilter}
          handleChangeFilters={handleChangeFilters}
          onSearch={onSearch}
          onClear={onClear}
          onClearModalFilters={onClearModalFilters}
          handleCheckboxSelect={handleCheckboxSelect}
          hasVacancies={hasVacancies}
        />
      )}
    </Layout>
  );
};

export default memo(CompanySearch);
