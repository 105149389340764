import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Accessability = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#2E3451"
      stroke="#2E3451"
      strokeWidth=".7"
      d="M14 3.5C8.201 3.5 3.5 8.201 3.5 14S8.201 24.5 14 24.5 24.5 19.799 24.5 14C24.493 8.204 19.796 3.507 14 3.5zm0 20.3c-5.412 0-9.8-4.388-9.8-9.8 0-5.412 4.388-9.8 9.8-9.8 5.412 0 9.8 4.388 9.8 9.8-.006 5.41-4.39 9.794-9.8 9.8z"
    />
    <path
      fill="#2E3451"
      fillRule="evenodd"
      d="M14 11.099c1.127 0 2.04-.918 2.04-2.05C16.04 7.918 15.127 7 14 7c-1.126 0-2.04.918-2.04 2.05.002 1.13.914 2.048 2.04 2.049zm4.73.344h.005c.562-.043 1.052.38 1.095.944.043.564-.378 1.056-.94 1.1l-2.88.262c-.175.016-.31.163-.31.34v.786c0 .17.032.34.094.498l1.642 4.201c.212.515-.024 1.105-.532 1.33-.507.224-1.1 0-1.335-.504l-1.573-3.84-1.56 3.83c-.227.517-.83.751-1.345.522-.515-.23-.748-.834-.52-1.352l1.636-4.186c.062-.159.094-.328.094-.5v-.785c0-.176-.135-.324-.31-.34l-2.885-.263c-.561-.044-.98-.537-.937-1.102.044-.564.535-.985 1.097-.94l3.576.322c.121.01.243.016.364.016h1.589c.122 0 .243-.006.365-.017l3.57-.322z"
      clipRule="evenodd"
    />
  </svg>
);

export default Accessability;
