/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Add } from "../../../../assets/customIcons";
import {
  AddPortfolio,
  Button,
  Card,
  ModalMedia,
  ViewPortfolio,
} from "../../../components";
import ButtonMenu from "../../../components/ButtonMenu";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateUser } from "../../../store/actions/userActions";
import { Media, UserCurriculumPortfolio } from "../../../types/interfaces";
import { userPortfolioType } from "../../../types/enumerators";
import { PortfolioType } from "../../../types/constants/User";
import { RootState } from "../../../store/reducers";

interface Props {
  title: string;
  addPortfolio: boolean;
  setAddPortfolio: Dispatch<SetStateAction<boolean>>;
  setIsOptional: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const Portfolios = ({
  title,
  addPortfolio,
  setAddPortfolio,
  setIsOptional,
  setIsValid,
}: Props): JSX.Element => {
  const width = window.innerWidth;
  const [editIndex, setEditIndex] = useState(-1);
  const [editMediaIndex, setEditMediaIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [media, setMedia] = useState<Media>({} as Media);
  const [isCurrentProject, setIsCurrentProject] = useState(false);
  const [portfolio, setPortfolio] = useState<UserCurriculumPortfolio>({
    title: "",
    url: "",
    description: "",
    medias: [],
  });
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [_curriculum, _setCurriculum] = useState(user?.curriculum);

  useEffect(() => {
    if (
      Object.keys(user?.curriculum)?.length &&
      !Object.keys(_curriculum)?.length
    ) {
      _setCurriculum({ ...user?.curriculum });
    }
  }, [_curriculum, user?.curriculum]);

  useEffect(() => {
    if (_curriculum?.portfolios?.length) {
      setIsOptional(false);
      setIsValid(true);
    } else setIsOptional(true);
  }, [_curriculum?.portfolios, setIsOptional, setIsValid]);

  const onSave = useCallback(() => {
    let { portfolios: newPorfolios } = _curriculum;

    if (editIndex !== -1) {
      newPorfolios[editIndex] = portfolio;
    } else if (newPorfolios?.length) newPorfolios.push(portfolio);
    else newPorfolios = [portfolio];

    setPortfolio({
      title: "",
      url: "",
      description: "",
      medias: [],
    });
    setIsCurrentProject(false);
    setAddPortfolio(false);
    setEditIndex(-1);

    _setCurriculum({ ..._curriculum, portfolios: newPorfolios });
    dispatch(
      setNextButton(() =>
        dispatch(
          updateUser({
            ...user,
            curriculum: { ..._curriculum, portfolios: newPorfolios },
          }) as unknown as Action,
        ),
      ) as unknown as Action,
    );
    dispatch(
      updateUser({
        ...user,
        curriculum: { ..._curriculum, portfolios: newPorfolios },
      }) as unknown as Action,
    );
  }, [_curriculum, dispatch, editIndex, portfolio, setAddPortfolio, user]);

  const onRemove = useCallback(
    (index: number) => {
      let { portfolios: oldPortfolios } = _curriculum;
      oldPortfolios.splice(index, 1);
      oldPortfolios = oldPortfolios.map(
        (item: UserCurriculumPortfolio, i: number) => ({
          ...item,
          order: i,
        }),
      );
      _setCurriculum({ ..._curriculum, portfolios: oldPortfolios });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, portfolios: oldPortfolios },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_curriculum, dispatch, user],
  );

  const onEdit = useCallback(
    (item: UserCurriculumPortfolio, index: number) => {
      if (!item.endDate) setIsCurrentProject(true);
      setEditIndex(index);
      setAddPortfolio(true);
      setPortfolio({
        ...item,
        medias: item.medias ?? [],
      });
    },
    [setAddPortfolio],
  );

  const onCancel = useCallback(() => {
    setAddPortfolio(false);
    setEditIndex(-1);
    setPortfolio({
      title: "",
      url: "",
      description: "",
      medias: [],
    });
    setIsCurrentProject(false);
    setIsValid(true);
  }, [setAddPortfolio, setIsValid]);

  const reorderItems = useCallback(
    (items: any) => {
      let { portfolios: newPortolios } = _curriculum;
      newPortolios = items.map((item: any, index: number) => ({
        ...item,
        order: index,
      }));
      _setCurriculum({ ..._curriculum, portfolios: newPortolios });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, portfolios: newPortolios },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_curriculum, dispatch, user],
  );

  const saveMedia = useCallback(() => {
    let { medias: newMedias } = portfolio;

    if (editMediaIndex !== -1) {
      newMedias[editMediaIndex] = media;
    } else if (newMedias?.length) newMedias.push(media);
    else newMedias = [media];

    setPortfolio({ ...portfolio, medias: newMedias });
    setMedia({} as Media);
    setEditMediaIndex(-1);
    setOpenModal(false);
  }, [editMediaIndex, media, portfolio]);

  const handleChangeMedia = useCallback(
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) =>
      setMedia({ ...media, [prop]: event.target.value }),
    [media],
  );

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setEditMediaIndex(-1);
    setMedia({} as Media);
  }, []);

  return (
    <div
      className={`height-fit grid-column-5-12 grid-column-1-11-mobile margin-top-16 grid-7-column ${
        width > 990 ? "grid-row-1" : "grid-row-2"
      }`}
    >
      <Card
        borderNone
        titleHeader={
          addPortfolio
            ? `${title} - ${PortfolioType[portfolio?.type ?? "LINK"]}`
            : title
        }
        className={`grid-column-1-8 height-fit-content ${
          addPortfolio || width < 991 ? "border-card" : ""
        }`}
      >
        <p className="paragraph-portfolios">
          Mostrar seu trabalho é muito importante. Então aproveite este espaço
          para colocar o link do seu portfolio ou projetos que já fez.
        </p>
        {!addPortfolio ? (
          <div>
            <ButtonMenu
              icon={
                <Add
                  widthIcon={width < 991 ? 20 : 24}
                  heightIcon={width < 991 ? 20 : 24}
                />
              }
              text="Adicionar portfólio"
              items={[
                {
                  text: "Link",
                  onClick: () => {
                    setAddPortfolio(true);
                    setPortfolio({
                      ...portfolio,
                      type: userPortfolioType.LINK,
                    });
                  },
                },
                {
                  text: "Projeto",
                  onClick: () => {
                    setAddPortfolio(true);
                    setPortfolio({
                      ...portfolio,
                      type: userPortfolioType.PROJECT,
                    });
                  },
                },
              ]}
              className="icon-button-primary icon-button-primary-mobile"
            />
          </div>
        ) : (
          <AddPortfolio
            portfolio={portfolio}
            setItem={setPortfolio}
            setIsValid={setIsValid}
            setIsCurrentProject={setIsCurrentProject}
          />
        )}
      </Card>
      {addPortfolio && (
        <div className="flex grid-column-4-8 grid-row-2 flex-direction-column-reverse">
          <Button
            text="Cancelar"
            className="width-50"
            appearance="secondary"
            outline
            onClick={onCancel}
          />
          <Button
            text="Salvar"
            className="margin-left-32 margin-left-mobile width-50"
            onClick={onSave}
            disabled={
              !portfolio.title ||
              !portfolio.url ||
              (portfolio.type === userPortfolioType.PROJECT &&
                (!portfolio.startDate ||
                  (!isCurrentProject && !portfolio.endDate)))
            }
          />
        </div>
      )}
      {addPortfolio && _curriculum?.portfolios?.length > 0 && (
        <div className="grid-column-1-8 grid-row-3 divider" />
      )}
      {_curriculum?.portfolios?.length > 0 && (
        <div
          className={`grid-column-1-8 ${
            addPortfolio ? "grid-row-4" : "grid-row-2"
          }`}
        >
          {_curriculum?.portfolios?.length > 1 && (
            <p className="paragraph-reorder">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <ViewPortfolio
            hasCards
            isMyProfile
            items={_curriculum?.portfolios}
            onEdit={onEdit}
            onMove={reorderItems}
            onRemove={(_, index) => onRemove(index)}
            className="padding-24"
          />
        </div>
      )}
      <ModalMedia
        openModal={openModal}
        onClose={closeModal}
        onChange={handleChangeMedia}
        medias={media}
        onSave={saveMedia}
        disabled={!media.title || !media.description}
      />
    </div>
  );
};

export default memo(Portfolios);
