import React, { useMemo } from "react";
import { ClassVacancySubscriptionsFormDiversity } from "reprograma-types/dist/base/ClassVacancySubscription";
import { Check } from "../../../../../assets/customIcons";
import { Wrong } from "../../../../../assets/icons";
import {
  diversityForm,
  technicalForm,
} from "../../../CourseWorkshop/Steps/data";
import "../../style.scss";

interface Props {
  form?: ClassVacancySubscriptionsFormDiversity;
  title: string;
  prop: "diversityForm" | "technicalForm";
}

const FormData = ({ form, title, prop }: Props): JSX.Element => {
  const currentForm = useMemo(
    () => (prop === "technicalForm" ? technicalForm : diversityForm),
    [prop],
  );

  const formQuestion: {
    index?: number;
    key?: string;
    resultAnswer?: boolean;
  }[] = useMemo(() => {
    const allQuestions = currentForm
      ?.filter((item) => !!item.key)
      ?.map((question) => ({
        key: question?.key,
        rightAnswer: question?.rightAnswer,
      }));

    if (form) {
      let returnObject: {
        index?: number;
        key?: string;
        resultAnswer?: boolean;
      } = {};

      const results = Object.entries(form)?.map(([key, values]) => {
        if (Array.isArray(values)) {
          for (let i = 0; i < allQuestions?.length; i += 1) {
            if (
              key === allQuestions[i]?.key &&
              Array.isArray(allQuestions[i]?.rightAnswer)
            ) {
              const rightAnswerItem = allQuestions[i]?.rightAnswer?.sort();
              const val = values.sort();
              const result =
                JSON.stringify(rightAnswerItem) === JSON.stringify(val);

              returnObject = {
                index: i + 1,
                key,
                resultAnswer: result,
              };
            }
          }
          return returnObject ?? {};
        }
        return {};
      });
      return results ?? [];
    }

    return [];
  }, [currentForm, form]);

  return (
    <>
      <div className="propose-title subtitle">
        <h3>{title}</h3>
      </div>
      {form ? (
        <div className="workshop questions-section">
          <h4>Perguntas fechadas</h4>
          <div className="questions-section close-questions">
            {formQuestion?.map(
              (item) =>
                !!item &&
                item?.index && (
                  <div className="question" key={Math.random()}>
                    <p>Pergunta {item?.index ?? ""}</p>
                    {item?.resultAnswer ? (
                      <Check widthIcon={24} heightIcon={24} />
                    ) : (
                      <Wrong />
                    )}
                  </div>
                ),
            )}
          </div>
        </div>
      ) : (
        `${title} não respondido`
      )}
    </>
  );
};

export default FormData;
