import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { Slider, Tooltip } from "@material-ui/core";
import { Button } from "../../../../components";
import { Info } from "../../../../../assets/customIcons";
import { rubrics } from "../data";
import "../../style.scss";
import { classVacancyType } from "../../../../types/enumerators";
import {
  ClassVacancy,
  ClassVacancySubscription,
} from "../../../../types/interfaces";
import { RootState } from "../../../../store/reducers";
import { updateClassVacancySubscriptionById } from "../../../../services/functions";

interface Props {
  template: "TET" | "IJS" | "PYT" | "EDV" | "LEVEL_ONE";
  course: ClassVacancy;
  subscription: ClassVacancySubscription;
  onClose: () => void;
  reload: () => void;
}

const Media = ({
  template,
  course,
  subscription,
  onClose,
  reload,
}: Props): JSX.Element => {
  const [topics, setTopics] = useState<number>(0);
  const [grade, setGrade] = useState<{ type?: string; value?: number }[]>();

  const verifyType = useCallback(
    (type) => {
      const filterGrades = grade?.filter(
        ({ type: typeGrade }) => typeGrade === type,
      );

      if (filterGrades && filterGrades.length > 0) return filterGrades[0].value;
      return 0;
    },
    [grade],
  );

  const getValueLabelFormat = useCallback((value, points) => {
    const findIndex = points.findIndex(
      (props: { value: number }) => props.value === value,
    );

    return points[findIndex || 0]?.info;
  }, []);

  const calcMedia = useCallback(
    (data) => {
      const keys: string[] = [];

      rubrics(template)?.forEach((item: { criterions: { type: string }[] }) =>
        item?.criterions?.forEach((innerItem: { type: string }) =>
          keys.push(innerItem?.type),
        ),
      );

      const filter = data?.filter(
        ({ type }: { type: string }) => type && keys.includes(type),
      );

      if (data && data?.length && filter) {
        const find = filter?.map(({ value }: { value: number }) => value);

        return Number(
          (
            (find?.reduce(
              (total: number, num: number) => (total ?? 0) + (num ?? 0),
              0,
            ) ?? 0) / (keys?.length ?? 1) ?? 0
          ).toFixed(2),
        );
      }

      return 0;
    },
    [template],
  );

  const calcGrade = useCallback(
    (data) => {
      const newGrade = [...(data ?? [])];

      const value = calcMedia(newGrade);
      const findIndexBehavior = newGrade?.findIndex(
        (item) => item?.type === "FINAL",
      );

      if (findIndexBehavior !== -1) newGrade[findIndexBehavior].value = value;
      else
        newGrade.push({
          type: "FINAL",
          value,
        });

      setGrade(newGrade);
    },
    [calcMedia],
  );

  const onChange = useCallback(
    (val: number | number[], type: string) => {
      const value = val as number;
      const newGrade = [...(grade ?? [])];
      const fields = {
        type,
        value,
      };
      const check = newGrade?.filter((props) => props.type === type);

      let grades = [...(newGrade || []), fields];
      if (check && check.length > 0)
        grades = newGrade?.map((props) =>
          props.type === type ? fields : props,
        );

      calcGrade(grades);
    },
    [calcGrade, grade],
  );

  const stageMediaCourse = useMemo(
    () =>
      course?.stages?.find(
        (item) => item?.type === classVacancyType.UPLOAD_MEDIAS,
      ),
    [course?.stages],
  );

  const stageMediaSubscription = useMemo(
    () =>
      subscription?.stages?.find(
        (stage) => stage?.stageId === stageMediaCourse?.stage_id,
      ),
    [subscription?.stages, stageMediaCourse?.stage_id],
  );

  const indexStageMediaSubscription = useMemo(
    () =>
      subscription?.stages?.findIndex(
        (stage) => stage?.stageId === stageMediaCourse?.stage_id,
      ),
    [subscription?.stages, stageMediaCourse?.stage_id],
  );

  useEffect(() => {
    if (stageMediaSubscription?.grade && !grade)
      setGrade(stageMediaSubscription?.grade);
  }, [grade, stageMediaSubscription?.grade]);

  const userId = useSelector((state: RootState) => state.userState)?._id;

  const submit = useCallback(async () => {
    const stages = subscription?.stages?.map((general, idx) =>
      idx === indexStageMediaSubscription
        ? {
            ...subscription?.stages[idx],
            userId,
            date: moment().tz("America/Sao_Paulo").format("DD/MM/YYYY"),
            grade,
          }
        : general,
    );

    if (subscription && stages?.length)
      await updateClassVacancySubscriptionById(subscription?._id ?? "", {
        stages,
      });

    reload();
    onClose();
  }, [
    grade,
    indexStageMediaSubscription,
    onClose,
    reload,
    subscription,
    userId,
  ]);

  const firstTypeCriterion = useMemo(
    () => rubrics(template)[topics]?.criterions[0]?.type.toLowerCase(),
    [template, topics],
  );

  const mediaAnswerForm = useMemo(
    () =>
      stageMediaSubscription?.form &&
      stageMediaSubscription?.form[firstTypeCriterion],
    [firstTypeCriterion, stageMediaSubscription?.form],
  );

  return (
    <>
      <div className="propose-form-content">
        <div>
          {rubrics(template)[0]?.info && (
            <div className="propose-form-guide">
              <p className="">Guia de pontuação</p>
              <Tooltip title={<p>{rubrics(template)[0]?.info}</p>}>
                <div>
                  <Info className="icon-info pointer" />
                </div>
              </Tooltip>
            </div>
          )}
          {rubrics(template)?.length > 0 && (
            <>
              <div className="propose-form-buttons">
                {rubrics(template)?.map((_, idx: number) => (
                  <Button
                    key={Math.random()}
                    text={`Questão ${idx + 1}`}
                    onClick={() => setTopics(idx)}
                    className={`button-outline margin-right-12 ${
                      topics === idx ? "is-active" : ""
                    }`}
                  />
                ))}
              </div>
              <h3 className="propose-form-title">
                {rubrics(template)[topics]?.question}
              </h3>
              {mediaAnswerForm && (
                <h4 className="propose-form-title">
                  Resposta:{" "}
                  <span className="propose-form-title answer">
                    {mediaAnswerForm}
                  </span>
                </h4>
              )}
              <div className="propose-form-criterions">
                {rubrics(template)[topics]?.criterions?.map(
                  ({ type, label, goal, points }, idx: number) => (
                    <div key={Math.random()} className="propose-form-criterion">
                      <span>{`Critério ${idx + 1}`}</span>

                      <div className="propose-form-criterion-content">
                        <h4>{label}</h4>

                        {goal && (
                          <Tooltip title={<p>{goal}</p>}>
                            <div>
                              <Info className="icon-info pointer" />
                            </div>
                          </Tooltip>
                        )}
                      </div>

                      <div className="propose-form-criterion-slider">
                        <Slider
                          value={verifyType(type)}
                          step={0}
                          min={0}
                          max={points[points.length - 1]?.value}
                          valueLabelDisplay="auto"
                          valueLabelFormat={(value) =>
                            getValueLabelFormat(value, points)
                          }
                          onChange={(_, value) => onChange(value, type)}
                          marks={points.map(({ value }) => ({
                            value,
                            label: value,
                          }))}
                        />
                      </div>
                    </div>
                  ),
                )}
              </div>
              <p className="propose-sum margin-top-32">{`Valor Total: ${
                grade?.find((item) => item?.type === "FINAL")?.value ?? 0
              }`}</p>
            </>
          )}
        </div>
      </div>
      <div className="admin-subscriptions-modal-buttons width-auto">
        <Button
          text="Cancelar"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button text="Salvar" onClick={submit} />
      </div>
    </>
  );
};

export default Media;
