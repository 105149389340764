import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import {
  AttachmentMedia,
  Button,
  CardMedia,
  Draggable,
  IconButton,
  Input,
  Modal,
  ModalMedia,
} from "../../../components";
import { Media } from "../../../types/interfaces";
import { mediaType } from "../../../types/enumerators";
import { updateClassById } from "../../../services/functions";
import { Delete, Link as LinkIcon } from "../../../../assets/customIcons";
import { Image as ImageIcon } from "../../../../assets/icons";
import api from "../../../services/api";
import "../style.scss";
import { setLoad } from "../../../store/actions/configurationsActions";

interface Props {
  open: boolean;
  close: () => void;
  medias?: Media[];
  classId: string;
  reload: () => void;
}

const Documents = ({
  open,
  close,
  medias,
  classId,
  reload,
}: Props): JSX.Element => {
  const [_medias, _setMedias] = useState(medias);
  const [media, setMedia] = useState<Media>({} as Media);
  const [index, setIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_medias && medias) _setMedias(medias);
  }, [_medias, medias]);

  const handleChangeMedia = useCallback(
    (prop: string | number | symbol) =>
      (event: ChangeEvent<HTMLInputElement>) =>
        setMedia({ ...media, [prop]: event.target.value }),
    [media],
  );

  const handleMedia = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);
      api
        .post("/fileUpload", data)
        .then((result) => {
          setOpenModal(true);
          setMedia({
            ...media,
            url: result?.data?.file_url.url,
            type: fileList[0].type.includes("image")
              ? mediaType.PHOTO
              : mediaType.DOCUMENT,
          });
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    },
    [media],
  );

  const handleLink = useCallback(
    (e: { key: string; target: { value?: string } }) => {
      if (e.key === "Enter") {
        const newMedias = [...(_medias ?? [])];

        const link = {
          url: e.target.value,
          type: mediaType.LINK,
        };

        newMedias?.push(link as Media);

        e.target.value = "";

        _setMedias(newMedias);
      }
    },
    [_medias],
  );

  const saveMedia = useCallback(() => {
    if (media) {
      const newMedias = [...(_medias ?? [])];

      if (index !== -1) newMedias[index] = media;
      else newMedias?.push(media);

      _setMedias(newMedias);
      setMedia({} as Media);
      setIndex(-1);
      setOpenModal(false);
    }
  }, [index, media, _medias]);

  const editMedia = useCallback((item: Media, i: number) => {
    setMedia(item);
    setIndex(i);
    setOpenModal(true);
  }, []);

  const removeMedia = useCallback(
    (i: number) => {
      const newMedias = [...(_medias ?? [])];
      newMedias?.splice(i, 1);
      const _newMedias = newMedias?.map((item: Media, idx: number) => ({
        ...item,
        order: idx,
      }));
      _setMedias(_newMedias);
    },
    [_medias],
  );

  const removeAllMedia = useCallback(
    (type) => {
      const newMedias = [...(_medias ?? [])];
      const _newMedias = newMedias?.filter((item) => item?.type !== type);
      _setMedias(_newMedias);
    },
    [_medias],
  );

  const onClose = useCallback(() => {
    setMedia({} as Media);
    setIndex(-1);
    _setMedias(medias);
    setOpenModal(false);
    close();
  }, [close, medias]);

  const onSave = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    await updateClassById(classId ?? "", { medias: _medias });
    reload();
    onClose();
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, classId, _medias, reload, onClose]);

  return (
    <>
      <Modal headerAction open={open} onClose={onClose} textHeader="Documentos">
        <div className="documents">
          <Input
            label="Link"
            type="text"
            helperText="Pressione Enter para adicionar"
            className="documents-input-add"
            onKeyDown={handleLink}
          />

          <AttachmentMedia
            onChange={handleMedia}
            className="margin-bottom-16"
          />
          {_medias &&
            _medias?.filter(
              (item: { type?: string }) =>
                item?.type && item?.type === mediaType.LINK,
            )?.length > 0 && (
              <>
                <div className="line-divider-midia margin-top-10" />
                <div className="documents-icon-title">
                  <div className="documents-title">
                    <LinkIcon className="documents-icon" />
                    Links
                  </div>
                  <IconButton
                    icon={<Delete />}
                    onClick={() => removeAllMedia(mediaType.LINK)}
                    className="documents-icon-delete-title cursor-pointer"
                  />
                </div>
                <div className="documents-link">
                  {_medias?.map((item: Media, i: number) => {
                    if (item?.type === mediaType.LINK)
                      return (
                        <div className="flex-row-center" key={Math.random()}>
                          <a
                            href={
                              item?.url?.includes("http")
                                ? item?.url
                                : `http://${item?.url}`
                            }
                            className="documents-url"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item?.url}
                          </a>
                          <IconButton
                            icon={<Delete />}
                            onClick={() => removeMedia(i)}
                            className="margin-ringht-24 cursor-pointer"
                          />
                        </div>
                      );
                    return <div />;
                  })}
                </div>
              </>
            )}
          {_medias &&
            _medias?.filter(
              (item: { type?: string }) =>
                item?.type && item?.type === mediaType.PHOTO,
            )?.length > 0 && (
              <>
                <div className="line-divider-midia documents-divider" />
                <div className="documents-icon-title">
                  <div className="documents-title">
                    <ImageIcon className="documents-icon " />
                    Imagens
                  </div>
                  <IconButton
                    icon={<Delete />}
                    onClick={() => removeAllMedia(mediaType.PHOTO)}
                    className="documents-icon-delete-title cursor-pointer"
                  />
                </div>

                <Draggable items={_medias ?? []}>
                  <div className="flex-wrap-midia">
                    {_medias?.map((item: Media, i: number) => {
                      if (item?.type === mediaType.PHOTO) {
                        return (
                          <div>
                            <CardMedia
                              item={item}
                              onEdit={() => editMedia(item, i)}
                              onRemove={() => removeMedia(i)}
                            />
                          </div>
                        );
                      }
                      return <div />;
                    })}
                  </div>
                </Draggable>
              </>
            )}
          {_medias &&
            _medias?.filter(
              (item: { type?: string }) =>
                item?.type && item?.type === mediaType.DOCUMENT,
            )?.length > 0 && (
              <>
                <div className="line-divider-midia documents-divider" />
                <div className="documents-icon-title">
                  <div className="documents-title">
                    <ImageIcon className="documents-icon " />
                    Documentos
                  </div>
                  <IconButton
                    icon={<Delete />}
                    onClick={() => removeAllMedia(mediaType.DOCUMENT)}
                    className="documents-icon-delete-title cursor-pointer"
                  />
                </div>
                <Draggable items={_medias ?? []}>
                  <div className="flex-wrap-midia">
                    {_medias?.map((item: Media, i: number) => {
                      if (item?.type === mediaType.DOCUMENT) {
                        return (
                          <div>
                            <CardMedia
                              item={item}
                              onEdit={() => editMedia(item, i)}
                              onRemove={() => removeMedia(i)}
                            />
                          </div>
                        );
                      }

                      return <div />;
                    })}
                  </div>
                </Draggable>
              </>
            )}
        </div>
        <div className="line-divider-midia documents-divider" />
        <div className="documents-button">
          <Button
            text="Salvar"
            className="documents-button-save"
            onClick={onSave}
          />
        </div>
      </Modal>

      <ModalMedia
        openModal={openModal}
        onClose={onClose}
        onChange={handleChangeMedia}
        medias={media}
        onSave={saveMedia}
        disabled={!media?.title || !media?.description}
      />
    </>
  );
};

export default memo(Documents);
