import React from "react";
import "./styles.scss";

interface Props {
  alignStart?: boolean;
  disabled?: boolean;
  optional?: boolean;
  marginBottom?: boolean;
  preWrap?: boolean;
  text: string;
  subText?: string | JSX.Element;
}

const LabelInput = ({
  alignStart = false,
  disabled,
  optional = false,
  text,
  marginBottom = false,
  preWrap = true,
  subText,
}: Props): JSX.Element => (
  <div
    className={`title-input ${preWrap && "pre-wrap"} ${
      !alignStart && "flex align-items-end"
    } ${disabled && "disabled"}  ${marginBottom && "margin-bottom-20"}`}
  >
    {text}
    {!optional && "*"}
    {subText && <p className="sub-header">{subText}</p>}
  </div>
);

export default LabelInput;
