export const ProfilePronouns: { [index: string]: string } = {
  SHE_HER: "Ela/Dela",
  HE_HIM: "Ele/Dele",
  THEY_THEM: "Elu/Delu",
  OTHERS: "Outro",
};

export const IdentityType: { [index: string]: string } = {
  BR_CPF: "CPF",
  BR_NATIONAL_MIGRATION_REGISTER_CARD:
    "Carteira de Registro Nacional Migratório",
  BR_PROVISIONAL_REFUGEE_PROTOCOL: "Protocolo Provisório de Refugiados",
  BR_PASSPORT: "Passaporte",
};

export const ProfileGender: { [index: string]: string } = {
  TRANSGENDER_FEMALE: "Mulher Transgênero",
  TRANSVESTITE: "Travesti",
  CIS_FEMALE: "Mulher Cisgênero",
  NON_BINARY: "Não-binárie",
  CIS_MALE: "Homem Cisgênero",
  TRANSGENDER_MALE: "Homem Transgênero",
  AGENDER: "Sem gênero",
  OTHERS: "Outro",
};

export const Gender: { [index: string]: string } = {
  CIS_FEMALE:
    "Mulher Cisgênero - Pessoa que se identifica com o gênero que lhe foi atribuído ao nascer.",
  TRANSGENDER_FEMALE:
    "Mulher Transgênero - Pessoa que a identidade não corresponde ao gênero que lhe foi atribuído ao nascer.",
  TRANSVESTITE:
    "Travesti - Pessoa que a identidade não corresponde ao gênero que lhe foi atribuído ao nascer.",
  NON_BINARY:
    "Não-binárie - Pessoa que não se considera dentro da definição de binariedade (masculino e feminino) mas está contemplada dentro das muitas outras identidades existentes.",
  CIS_MALE:
    "Homem Cisgênero - Pessoa que se identifica com o gênero que lhe foi atribuído ao nascer.",
  TRANSGENDER_MALE:
    "Homem Transgênero - Pessoa que a identidade não corresponde ao gênero que lhe foi atribuído ao nascer.",
  AGENDER: "Agênero - Identidade definida pela ausência de gênero.",
  OTHERS: "Outro - Se nenhuma definição acima contempla seu gênero.",
};

export const ProfileRace: { [index: string]: string } = {
  BROWN: "Parda",
  BLACK: "Preta",
  INDIGENOUS: "Indígena",
  WHITE: "Branca",
  YELLOW: "Amarela",
  OTHERS: "Outra",
};

export const ProfileOpenForProposals = {
  NO: "Não",
  YES_LISTED: "Sim",
  YES_NOT_LISTED: "Sim, mas não quero mostrar no perfil",
};

export const CurriculumEducationLevel = {
  BASIC: "Ensino Básico",
  FUNDAMENTAL: "Ensino Fundamental",
  MEDIUM: "Ensino Médio",
  MEDIUM_TECHNICAL: "Ensino Médio Tecnólogo",
  UNDERGRADUATE_TECHNICAL: "Tecnólogo",
  BACHELOR: "Bacharelado",
  LICENSING: "Licenciatura",
  EXTENSION: "Curso de Extensão",
  MBA: "MBA",
  POST_GRADUATE: "Pós-Graduação",
  MASTERS: "Mestrado",
  DOCTORATE: "Doutorado",
  POST_DOCTORATE: "Pós-Doutorado",
  OTHERS: "Outro",
};

export const PortfolioType = {
  PROJECT: "Projeto",
  LINK: "Link",
};

export const ReasonsDisapproved = {
  ABSENCE: "Falta",
  PROJECT: "Não entregou projeto final",
  ACTIVITY: "Não entregou atividades",
  DISCRIMINATION: "Discriminação",
  RACISM: "Racismo",
  TRANSPHOBIA: "Transfobia",
  HOMOPHOBIA: "Homofobia",
  PREJUDICE: "Preconceito",
  OTHERS: "Outros",
};

export const ReasonsGeneral = {
  AUSENCIA: "Ausência",
  WORKLOAD: "Carga horária",
  DEMOTIVATION: "Desmotivação",
  PREGNANT: "Gravidez/maternidade",
  UNAVAILABILITY_TIME: "Indisponibilidade de tempo",
  FAMILY_DEATH: "Falecimento de familiar",
  NEW_JOB: "Novo trabalho",
  FINACIAL: "Problema financeiro",
  HEALTH: "Problema de saúde",
  OTHERS: "Outros",
};

export const ScholarshipType = {
  INTERNET: "Auxílio Internet",
  COMPUTER: "Auxílio Notebook",
  FULL_FINANCIAL: "Auxílio Financeiro Parcial",
  PARTIAL_FINANCIAL: "Auxílio Financeiro Integral",
};

export const ReasonsRemove = {
  NOT_INTERESTED: "Não tem interesse",
  NOT_RESPOND: "Não respondeu",
  ANOTHER_INITIATIVE: "Está em outra iniciativa",
  NOT_PUBLIC: "Não faz mais parte do nosso público",
  NOT_CRITERIOUS: "Não faz mais parte dos nossos critérios",
  TIME: "Tempo",
  CHANGED: "Mudança efetuada",
  OTHERS: "Outros",
};

export const GeneralStatusUser = {
  ACTIVE: "Ativa",
  PENDING: "Pendente",
  DISABLED: "Desabilitada",
  BLOCKED: "Bloqueada",
};
