/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { FormControlLabel, Switch } from "@material-ui/core";
import { Mail } from "../../../assets/icons";
import {
  Accessability,
  Birthday,
  FacebookProfile,
  Gender,
  GitHubProfile,
  InstagramProfile,
  LinkedinLogin,
  Race,
  Telegram,
  TikTokProfile,
  TwitterProfile,
  VisibilityOff,
  VisibilityOn,
  Whatsapp,
} from "../../../assets/customIcons";
import { updateCompany } from "../../store/actions/companyActions";
import { updateUser } from "../../store/actions/userActions";
import Button from "../Button";
import Input from "../Input";
import Modal from "../Modal";
import "./styles.scss";
import {
  companySocialMedias,
  userSocialMediaType,
} from "../../types/enumerators";

interface Props {
  user?: any;
  openModal: boolean;
  onClose: () => void;
  setUser?: Dispatch<SetStateAction<any>>;
  isCompany?: boolean;
}

const ModalEditVisibilityInfosProfile = ({
  user,
  setUser,
  openModal,
  onClose,
  isCompany,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  const [data, setData] = useState(user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.profile?.name && !data?.profile?.name) setData({ ...user });
  }, [data, user]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      setData({
        ...data,
        profile: {
          ...data?.profile,
          name: e.target.value,
        },
      }),
    [data],
  );

  const handleChangeProposals = useCallback(
    (value: boolean, props) => {
      setData({
        ...data,
        curriculum: {
          ...data?.curriculum,
          [props]: value,
        },
      });
    },
    [data],
  );

  const changeVisibility = useCallback(
    (props: string) => {
      let { filters: newFilters } = data;

      if (!newFilters || !newFilters?.publicProfile)
        newFilters = { publicProfile: [] };

      if (newFilters?.publicProfile?.find((item: string) => item === props)) {
        const index = newFilters.publicProfile.findIndex(
          (item: string) => item === props,
        );
        newFilters?.publicProfile?.splice(index, 1);
      } else newFilters?.publicProfile?.push(props);

      setData({ ...data, filters: newFilters });
    },
    [data],
  );

  const onSave = useCallback(() => {
    if (!isCompany && setUser)
      setUser({
        ...data,
      });

    if (isCompany) dispatch(updateCompany({ ...data }) as unknown as Action);
    else
      dispatch(
        updateUser({
          ...data,
        }) as unknown as Action,
      );

    onClose();
  }, [data, dispatch, isCompany, onClose, setUser]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      headerAction
      textHeader="Editar cabeçalho"
      open={openModal}
      onClose={onClose}
      bodyClassName="modal-profile-large"
      textInfo={
        isCompany
          ? "As pessoas verão apenas as redes sociais que estão como visíveis"
          : undefined
      }
    >
      {!isCompany && (
        <div>
          <div className="flex-row-center margin-bottom-8">
            {width < 991 ? (
              <p className="paragrapher-responsive">Nome de exibição</p>
            ) : (
              ""
            )}
            <Input
              label="Nome de exibição"
              type="text"
              value={data?.profile?.name ?? ""}
              onChange={handleChange}
              className="width-288 margin-right-32 width-100"
            />
          </div>
          <div className="flex">
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={
                <Switch
                  checked={data?.curriculum?.openForProposals}
                  name="openForProposals"
                  onChange={() =>
                    handleChangeProposals(
                      !data?.curriculum?.openForProposals,
                      "openForProposals",
                    )
                  }
                />
              }
              label="Mostrar que quero participar de processos seletivos"
              className="width-50"
            />
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              className="display-initial widht-50"
              control={
                <Switch
                  checked={
                    !data?.filters?.publicProfile?.find(
                      (item: string) => item === "curriculum.goal",
                    )
                  }
                  name="goalIsVisible"
                  onChange={() => changeVisibility("curriculum.goal")}
                />
              }
              label="Exibir meu objetivo profissional"
            />
          </div>
          <div className="flex">
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={
                <Switch
                  checked={data?.curriculum?.openForChanges}
                  name="openForChanges"
                  onChange={() =>
                    handleChangeProposals(
                      !data?.curriculum?.openForChanges,
                      "openForChanges",
                    )
                  }
                />
              }
              label="Mostrar que está disponível para se mudar"
              className="width-50"
            />
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={
                <Switch
                  checked={data?.curriculum?.openForFreelancer}
                  name="openForFreelancer"
                  onChange={() =>
                    handleChangeProposals(
                      !data?.curriculum?.openForFreelancer,
                      "openForFreelancer",
                    )
                  }
                />
              }
              label="Mostrar que está disponível para ser freelancer"
              className="width-50"
            />
          </div>
          <div className="flex-row-center">
            <p className="modal-title dark-grey-blue">Dados gerais</p>
            <div className="divider width-100 padding-right-16 margin-top-12" />
          </div>
          <div className="flex-row-center flex-wrap space-between margin-right-24 general-data-edit margin-right-0">
            <div className="flex-row-center margin-right-32 margin-right-10">
              <Birthday
                widthIcon={width < 991 ? 24 : 28}
                heightIcon={width < 991 ? 24 : 28}
              />
              <p className="title-infos-profile paragrapher-edit-general-data">
                Idade
              </p>
              {data?.filters?.publicProfile?.find(
                (item: string) => item === "profile.birthdate",
              ) ? (
                <VisibilityOff
                  widthIcon={width < 991 ? 16 : 24}
                  heightIcon={width < 991 ? 16 : 24}
                  className="cursor-pointer"
                  onClick={() => changeVisibility("profile.birthdate")}
                />
              ) : (
                <VisibilityOn
                  widthIcon={width < 991 ? 16 : 24}
                  heightIcon={width < 991 ? 16 : 24}
                  className="cursor-pointer"
                  onClick={() => changeVisibility("profile.birthdate")}
                />
              )}
            </div>
            <div className="flex-row-center margin-right-32 margin-right-10">
              <Race
                widthIcon={width < 991 ? 24 : 28}
                heightIcon={width < 991 ? 24 : 28}
              />
              <p className="title-infos-profile paragrapher-edit-general-data">
                Raça
              </p>
              {data?.filters?.publicProfile?.find(
                (item: string) => item === "profile.race",
              ) ? (
                <VisibilityOff
                  widthIcon={width < 991 ? 16 : 24}
                  heightIcon={width < 991 ? 16 : 24}
                  className="cursor-pointer"
                  onClick={() => changeVisibility("profile.race")}
                />
              ) : (
                <VisibilityOn
                  widthIcon={width < 991 ? 16 : 24}
                  heightIcon={width < 991 ? 16 : 24}
                  className="cursor-pointer"
                  onClick={() => changeVisibility("profile.race")}
                />
              )}
            </div>
            <div className="flex-row-center margin-right-32 margin-right-10">
              <Gender
                widthIcon={width < 991 ? 24 : 28}
                heightIcon={width < 991 ? 24 : 28}
              />
              <p className="title-infos-profile paragrapher-edit-general-data">
                Gênero
              </p>
              {data?.filters?.publicProfile?.find(
                (item: string) => item === "profile.genderIdentity",
              ) ? (
                <VisibilityOff
                  widthIcon={width < 991 ? 16 : 24}
                  heightIcon={width < 991 ? 16 : 24}
                  className="cursor-pointer"
                  onClick={() => changeVisibility("profile.genderIdentity")}
                />
              ) : (
                <VisibilityOn
                  widthIcon={width < 991 ? 16 : 24}
                  heightIcon={width < 991 ? 16 : 24}
                  className="cursor-pointer"
                  onClick={() => changeVisibility("profile.genderIdentity")}
                />
              )}
            </div>
            <div className="flex-row-center margin-right-32 margin-right-10">
              <Accessability
                widthIcon={width < 991 ? 24 : 28}
                heightIcon={width < 991 ? 24 : 28}
              />
              <p className="title-infos-profile">Acessibilidade</p>
              {data?.filters?.publicProfile?.find(
                (item: string) => item === "profile.disabledPerson",
              ) ? (
                <VisibilityOff
                  widthIcon={width < 991 ? 16 : 24}
                  heightIcon={width < 991 ? 16 : 24}
                  className="cursor-pointer"
                  onClick={() => changeVisibility("profile.disabledPerson")}
                />
              ) : (
                <VisibilityOn
                  widthIcon={width < 991 ? 16 : 24}
                  heightIcon={width < 991 ? 16 : 24}
                  className="cursor-pointer"
                  onClick={() => changeVisibility("profile.disabledPerson")}
                />
              )}
            </div>
          </div>
          <div className="flex-row-center">
            <p className="modal-title">Contato</p>
            <div className="divider width-100 margin-top-11" />
          </div>
          <div className="flex-row-center flex-wrap">
            <div className="flex-row-center margin-right-24 margin-right-10">
              <Mail fill="#2e3451" />
              <p className="paragraph-infos-profile paragrapher-edit-general-data">
                E-mail
              </p>
              {data?.filters?.publicProfile?.find(
                (item: string) => item === "profile.email",
              ) ? (
                <VisibilityOff
                  widthIcon={width < 991 ? 16 : 24}
                  heightIcon={width < 991 ? 16 : 24}
                  className="cursor-pointer"
                  onClick={() => changeVisibility("profile.email")}
                />
              ) : (
                <VisibilityOn
                  widthIcon={width < 991 ? 16 : 24}
                  heightIcon={width < 991 ? 16 : 24}
                  className="cursor-pointer"
                  onClick={() => changeVisibility("profile.email")}
                />
              )}
            </div>
            {data?.profile?.telephone && (
              <div className="flex-row-center margin-right-24 margin-right-10">
                <Whatsapp
                  widthIcon={width < 991 ? 24 : 28}
                  heightIcon={width < 991 ? 24 : 28}
                />
                <p className="paragraph-infos-profile paragrapher-edit-general-data">
                  WhatsApp
                </p>
                {data?.filters?.publicProfile?.find(
                  (item: string) => item === "profile.telephone",
                ) ? (
                  <VisibilityOff
                    widthIcon={width < 991 ? 16 : 24}
                    heightIcon={width < 991 ? 16 : 24}
                    className="cursor-pointer"
                    onClick={() => changeVisibility("profile.telephone")}
                  />
                ) : (
                  <VisibilityOn
                    widthIcon={width < 991 ? 16 : 24}
                    heightIcon={width < 991 ? 16 : 24}
                    className="cursor-pointer"
                    onClick={() => changeVisibility("profile.telephone")}
                  />
                )}
              </div>
            )}
            {data?.profile?.telegram && (
              <div className="flex-row-center margin-right-24 margin-right-10">
                <Telegram
                  widthIcon={width < 991 ? 24 : 28}
                  heightIcon={width < 991 ? 24 : 28}
                />
                <p className="paragraph-infos-profile paragrapher-edit-general-data">
                  Telegram
                </p>
                {data?.filters?.publicProfile?.find(
                  (item: string) => item === "profile.telegram",
                ) ? (
                  <VisibilityOff
                    widthIcon={width < 991 ? 16 : 24}
                    heightIcon={width < 991 ? 16 : 24}
                    className="cursor-pointer"
                    onClick={() => changeVisibility("profile.telegram")}
                  />
                ) : (
                  <VisibilityOn
                    widthIcon={width < 991 ? 16 : 24}
                    heightIcon={width < 991 ? 16 : 24}
                    className="cursor-pointer"
                    onClick={() => changeVisibility("profile.telegram")}
                  />
                )}
              </div>
            )}
          </div>
          {data?.profile?.socialMedias?.length > 0 && (
            <div className="flex-row-center">
              <p className="modal-title">Redes sociais</p>
              <div className="divider width-100 margin-top-12" />
            </div>
          )}
        </div>
      )}
      <div className="flex-row-center flex-wrap margin-right-32 margin-right-0 width-230-mobile">
        {(data?.profile?.socialMedias?.find(
          (item: { type: string }) =>
            item.type === userSocialMediaType.LINKEDIN ||
            companySocialMedias.LINKEDIN_COMPANY,
        ) ||
          data?.filters?.publicProfile?.find(
            (item: string) => item === "profile.socialMedias.type.linkedin",
          )) && (
          <div className="flex-row-center margin-right-20 margin-right-10">
            <LinkedinLogin
              widthIcon={width < 991 ? 24 : 32}
              heightIcon={width < 991 ? 24 : 32}
            />
            <p className="paragraph-infos-profile paragrapher-edit-general-data">
              Linkedin
            </p>
            {data?.filters?.publicProfile?.find(
              (item: string) => item === "profile.socialMedias.type.linkedin",
            ) ? (
              <VisibilityOff
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.linkedin")
                }
              />
            ) : (
              <VisibilityOn
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.linkedin")
                }
              />
            )}
          </div>
        )}
        {(data?.profile?.socialMedias?.find(
          (item: { type: string }) => item.type === "INSTAGRAM",
        ) ||
          data?.filters?.publicProfile?.find(
            (item: string) => item === "profile.socialMedias.type.instagram",
          )) && (
          <div className="flex-row-center margin-right-32 margin-right-10">
            <InstagramProfile
              widthIcon={width < 991 ? 24 : 28}
              heightIcon={width < 991 ? 24 : 28}
            />
            <p className="title-infos-profile">Instagram</p>
            {data?.filters?.publicProfile?.find(
              (item: string) => item === "profile.socialMedias.type.instagram",
            ) ? (
              <VisibilityOff
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.instagram")
                }
              />
            ) : (
              <VisibilityOn
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.instagram")
                }
              />
            )}
          </div>
        )}
        {(data?.profile?.socialMedias?.find(
          (item: { type: string }) => item.type === "FACEBOOK",
        ) ||
          data?.filters?.publicProfile?.find(
            (item: string) => item === "profile.socialMedias.type.facebook",
          )) && (
          <div className="flex-row-center margin-right-32 margin-right-10">
            <FacebookProfile
              widthIcon={width < 991 ? 24 : 32}
              heightIcon={width < 991 ? 24 : 32}
            />
            <p className="title-infos-profile paragrapher-edit-general-data">
              Facebook
            </p>
            {data?.filters?.publicProfile?.find(
              (item: string) => item === "profile.socialMedias.type.facebook",
            ) ? (
              <VisibilityOff
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.facebook")
                }
              />
            ) : (
              <VisibilityOn
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.facebook")
                }
              />
            )}
          </div>
        )}
        {(data?.profile?.socialMedias?.find(
          (item: { type: string }) => item.type === "TWITTER",
        ) ||
          data?.filters?.publicProfile?.find(
            (item: string) => item === "profile.socialMedias.type.twitter",
          )) && (
          <div className="flex-row-center margin-right-24 margin-right-10">
            <TwitterProfile
              widthIcon={width < 991 ? 24 : 32}
              heightIcon={width < 991 ? 24 : 32}
            />
            <p className="title-infos-profile paragrapher-edit-general-data">
              Twitter
            </p>
            {data?.filters?.publicProfile?.find(
              (item: string) => item === "profile.socialMedias.type.twitter",
            ) ? (
              <VisibilityOff
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.twitter")
                }
              />
            ) : (
              <VisibilityOn
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.twitter")
                }
              />
            )}
          </div>
        )}
        {(data?.profile?.socialMedias?.find(
          (item: { type: string }) => item.type === "TIKTOK",
        ) ||
          data?.filters?.publicProfile?.find(
            (item: string) => item === "profile.socialMedias.type.tiktok",
          )) && (
          <div className="flex-row-center margin-right-32 margin-right-10">
            <TikTokProfile
              widthIcon={width < 991 ? 24 : 28}
              heightIcon={width < 991 ? 24 : 28}
            />
            <p className="title-infos-profile paragrapher-edit-general-data">
              TikTok
            </p>
            {data?.filters?.publicProfile?.find(
              (item: string) => item === "profile.socialMedias.type.tiktok",
            ) ? (
              <VisibilityOff
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.tiktok")
                }
              />
            ) : (
              <VisibilityOn
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.tiktok")
                }
              />
            )}
          </div>
        )}
        {(data?.profile?.socialMedias?.find(
          (item: { type: string }) => item.type === "GITHUB",
        ) ||
          data?.filters?.publicProfile?.find(
            (item: string) => item === "profile.socialMedias.type.github",
          )) && (
          <div className="flex-row-center margin-right-24 margin-right-10">
            <GitHubProfile
              widthIcon={width < 991 ? 24 : 28}
              heightIcon={width < 991 ? 24 : 28}
            />
            <p className="title-infos-profile paragrapher-edit-general-data">
              GitHub
            </p>
            {data?.filters?.publicProfile?.find(
              (item: string) => item === "profile.socialMedias.type.github",
            ) ? (
              <VisibilityOff
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.github")
                }
              />
            ) : (
              <VisibilityOn
                widthIcon={width < 991 ? 16 : 24}
                heightIcon={width < 991 ? 16 : 24}
                className="cursor-pointer"
                onClick={() =>
                  changeVisibility("profile.socialMedias.type.github")
                }
              />
            )}
          </div>
        )}
      </div>
      <div className="flex-row-center margin-top-48 margin-top-20-mobile flex-end flex-col-mobile">
        <Button
          text="Cancelar"
          outline
          className="width-200 margin-right-32 width-100-responsive margin-0-mobile margin-right-32"
          onClick={onClose}
          appearance="secondary"
        />
        <Button
          text="Salvar"
          onClick={onSave}
          className="width-200 width-100-responsive margin-top-16-mobile"
        />
      </div>
    </Modal>
  );
};

export default memo(ModalEditVisibilityInfosProfile);
