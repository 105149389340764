/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import moment from "moment";
import {
  Checkbox,
  Layout,
  Button,
  Modal,
  Select,
  ModalFixedHeader,
  ConsentForm,
} from "../../components";
import { RootState } from "../../store/reducers";
import { ClosePurple } from "../../../assets/icons";
import {
  createUserContracts,
  getActiveUserClassVacancySubscription,
  getAllActiveClassVacancy,
  getClassById,
  getClassVacancyById,
  getContractsByType,
  getUserContractsByContractId,
} from "../../services/functions";
import {
  createCourseSubscription,
  setCourseSubscription,
  updateClassVacancyInCourseSubscription,
} from "../../store/actions/courseActions";
import {
  ClassVacancy,
  ClassVacancyStages,
  ClassVacancySubscription,
} from "../../types/interfaces";
import {
  classVacancyStatus,
  classVacancyType,
  contractType,
} from "../../types/enumerators";
import { redirectCandidate } from "../../utils";
import { createCourseSubscriptionLog } from "../../services/historicFunctions";
import { CourseSubscriptionAction } from "../../types/enumerators/actions/CourseSubscriptionAction";
import Person from "../../../assets/images/Person.svg";
import "./style.scss";
import { useAuth } from "../../auth";
import Loading from "../Loading";

const CourseWelcome = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const courseSubscription = useSelector(
    (state: RootState) => state.courseState,
  );

  const [loading, setLoading] = useState(true);

  const [accepted, setAccepted] = useState(false);
  const [openModalConsentForm, setOpenModalConsentForm] = useState(false);
  const [openModalInitial, setOpenModalInitial] = useState(false);
  const [openModalEmpty, setOpenModalEmpty] = useState(false);
  const [openModalCantApply, setOpenModalCantApply] = useState(false);
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();
  const [acceptedPersonalData, setAcceptedPersonalData] = useState(false);
  const [acceptedSensitiveData, setAcceptedSensitiveData] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const { signOut } = useAuth();

  const [classVacancyId, setClassVacancyId] = useState(
    courseSubscription?.classVacancyId,
  );
  const [classVacancyList, setClassVacancyList] = useState<ClassVacancy[]>();

  const saveCourseSubscription = courseSubscription._id
    ? updateClassVacancyInCourseSubscription
    : createCourseSubscription;

  useEffect(() => {
    if (user?._id && !classVacancyId && courseSubscription?.classVacancyId)
      setClassVacancyId(courseSubscription?.classVacancyId);
  }, [classVacancyId, courseSubscription?.classVacancyId, user?._id]);

  const getClassVacancyList = useCallback(async () => {
    const responseClassVacancy = await getAllActiveClassVacancy();
    const dataClassVacancy = responseClassVacancy?.data;

    const list =
      dataClassVacancy.filter((item: { stages: ClassVacancyStages[] }) =>
        item?.stages?.find(
          (innerItem) =>
            innerItem?.type === classVacancyType.SUBSCRIPTION &&
            innerItem?.status === classVacancyStatus.ACTIVE,
        ),
      ) ?? [];
    setClassVacancyList(list);
  }, []);

  useEffect(() => {
    if (!classVacancyList) getClassVacancyList();
  }, [classVacancyList, getClassVacancyList]);

  const getSubscription = useCallback(async () => {
    const responseSubscription = await getActiveUserClassVacancySubscription(
      user?._id,
    );

    if (responseSubscription?.data?.length) {
      const dataSubscription: ClassVacancySubscription =
        responseSubscription?.data?.[0];
      dispatch(setCourseSubscription(dataSubscription) as unknown as Action);

      const responseClassVacancy = await getClassVacancyById(
        dataSubscription?.classVacancyId,
      );
      const dataClassVacancy = responseClassVacancy?.data;

      redirectCandidate(dataClassVacancy, dataSubscription, history, () =>
        setOpenModalInitial(true),
      );
    } else if (classVacancyList?.length) setOpenModalInitial(true);
    else setOpenModalEmpty(true);
  }, [classVacancyList, dispatch, history, user?._id]);

  useEffect(() => {
    if (classVacancyList && user?._id && !courseSubscription?._id)
      getSubscription();
  }, [classVacancyList, courseSubscription?._id, getSubscription, user?._id]);

  useEffect(() => {
    if (classVacancy && classVacancy?.stages?.length)
      classVacancy?.stages
        ?.filter((item) => item?.type !== classVacancyType.EVALUATE)
        ?.sort((a, b) => {
          if ("order" in a && "order" in b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          }
          return 0;
        });
  }, [classVacancy]);

  const onAccept = useCallback(async () => {
    const contract = await getContractsByType(contractType.USER_CANDIDATE);
    const i = contract ? contract.length - 1 : 0;

    if (contract && contract[i]?._id) {
      const currentContract = await getUserContractsByContractId(
        user?._id,
        contract[i]?._id ?? "",
      );

      if (!currentContract || !currentContract?.length) {
        const data = {
          userId: user?._id ?? "",
          contractId: contract[i]?._id ?? "",
          date: new Date(),
          accepted: true,
        };
        await createUserContracts(data);
      }
    }
    history.push("/course-register");
  }, [history, user?._id]);

  const handleChange = useCallback(
    (e) => setClassVacancyId(e.target.value),
    [],
  );

  const isValidSubscription = useMemo(async () => {
    const currentClassVacancy = classVacancyList?.find(
      (item) => item?._id === classVacancyId,
    ) as unknown as ClassVacancy;

    if (
      currentClassVacancy?.restrictions?.includes("MORE_SIX_MONTHS") &&
      user?.bootcamps &&
      user?.bootcamps?.length
    ) {
      let cantApply = false;
      for (let i = 0; i < user?.bootcamps?.length; i += 1) {
        const course = await getClassById(user?.bootcamps[i]?.classId);
        if (
          course &&
          course?.length &&
          moment().diff(moment(course[0]?.endDate), "months") < 6
        )
          cantApply = true;
      }

      if (cantApply) return false;
    }

    return true;
  }, [classVacancyId, classVacancyList, user?.bootcamps]);

  const onNext = useCallback(async () => {
    if (!classVacancyId) setOpenModalInitial(true);
    else {
      setOpenModalInitial(false);
      if (await isValidSubscription) {
        dispatch(
          saveCourseSubscription({
            ...courseSubscription,
            userId: user?._id,
            classVacancyId,
          }) as unknown as Action,
        );

        const currentClassVacancy = classVacancyList?.find(
          (item) => item?._id === classVacancyId,
        ) as unknown as ClassVacancy;
        setClassVacancy(currentClassVacancy);
      } else setOpenModalCantApply(true);
    }
  }, [
    classVacancyId,
    classVacancyList,
    courseSubscription,
    dispatch,
    isValidSubscription,
    saveCourseSubscription,
    user?._id,
  ]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2500);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Layout className="card-container-welcome margin-top-32">
      <Modal
        open={openModalEmpty}
        onClose={signOut}
        className="welcome-modal-class"
      >
        <div className="flex-col align-end">
          <ClosePurple onClick={signOut} className="close-icon-course-empty" />
          <div>
            Que pena! Infelizmente no momento não há cursos disponíveis.
          </div>
        </div>
      </Modal>
      <Modal
        open={openModalCantApply}
        onClose={signOut}
        className="welcome-modal-class"
      >
        <div className="flex-col align-end">
          <ClosePurple onClick={signOut} className="close-icon-course-empty" />
          <div>
            Ahh que bom que você voltou! A gente já te viu por aqui, mas é
            necessário esperar no mínimo 6 meses para se reinscrever em um novo
            curso da reprograma. Te vejo em breve. Até logo.
          </div>
        </div>
      </Modal>
      <Modal
        headerAction
        open={openModalInitial}
        onClose={() => setOpenModalInitial(false)}
        className="welcome-modal-class"
      >
        <div>
          <p className="title-input">
            {`Na {reprograma} não serão toleradas atitudes, comportamentos e/ou posicionamentos racistas, transfóbicos, homofóbicos, xenofóbicos, gordofóbicos ou preconceitos de qualquer natureza. Você declara estar ciente que atos que recriminam a identidade de outra pessoa levarão a eliminação do processo seletivo e, no caso das pessoas aprovadas, à expulsão do curso?*`}
          </p>
          <Checkbox
            id="accepted"
            className="checkbox-welcome-accepted"
            label="Sim"
            checked={accepted}
            onChange={() => setAccepted(!accepted)}
          />

          <p className={`title-input ${!accepted && "disabled"}`}>
            Você está se inscrevendo para qual curso?*
          </p>
          <Select
            id="classVacancyId"
            label="Selecione"
            value={classVacancyId ?? ""}
            onChange={handleChange}
            options={
              classVacancyList?.map((item) => ({
                value: item?._id ?? "",
                label: item?.title ?? "",
              })) ?? []
            }
            className="margin-top-20"
            disabled={!accepted}
          />
        </div>
        <div className="width-100 buntton-submit-classVacancy">
          <Button
            text="Avançar"
            className="margin-top-40 margin-bottom-32 width-200 float-rigth"
            onClick={onNext}
            disabled={!accepted || !classVacancyId}
          />
        </div>
      </Modal>
      {classVacancy?._id && (
        <>
          <div className="grid-column-1-13 grid-row-1">
            <label className="paragraph-welcome">
              Seja bem-vinda ao processo seletivo para o(s) curso(s)&nbsp;
            </label>
            <label className="paragraph-">{classVacancy?.title}&nbsp;</label>
            <label className="paragraph-welcome">
              {moment(classVacancy?.startDate).year()}
            </label>
          </div>
          <div className="grid-column-1-13 grid-row-2">
            <p className="paragraph-sub">{classVacancy?.description}</p>
          </div>
          <div className="flex grid-column-1-13 grid-row-3">
            <div className="width-70-desktop width-100-mobile">
              <div className="container-instruction">
                <p className="instruction">
                  O processo de seleção é composto por:
                </p>
                {classVacancy?.stages
                  ?.filter((item) => item?.type !== classVacancyType.EVALUATE)
                  ?.map((item, index) => (
                    <p key={Math.random()} className="step-instruction">
                      {`${index + 1}. ${item?.title} - ${item?.description}`}
                      <br />
                    </p>
                  ))}
                <p className="success">
                  Pronta pra primeira etapa? Vamos lá!
                  <br /> Por favor, leia atentamente as perguntas. Sucesso!
                  {"<3"}
                </p>
                <div className="checkbox">
                  <Checkbox
                    id="acceptedPersonalData"
                    checked={acceptedPersonalData}
                    onChange={() => {
                      if (!acceptedPersonalData) setOpenModalConsentForm(true);
                      else setAcceptedPersonalData(false);
                    }}
                  />
                  <label
                    htmlFor="acceptedPersonalData"
                    className="label-checkbox"
                  >
                    Aceito os&nbsp;
                    <label
                      htmlFor="acceptedPersonalData"
                      className="acceptedData acceptedPersonalData"
                      onClick={() => setOpenModalConsentForm(true)}
                    >
                      Termos de Consentimento para Tratamentos de Dados Pessoais
                    </label>
                  </label>
                </div>
                <div className="checkbox margin-top-16">
                  <Checkbox
                    id="acceptedSensitiveData"
                    checked={acceptedSensitiveData}
                    onChange={() => {
                      if (!acceptedSensitiveData) setOpenModalConsentForm(true);
                      else setAcceptedSensitiveData(false);
                    }}
                  />
                  <label
                    htmlFor="acceptedSensitiveData"
                    className="label-checkbox"
                  >
                    Aceito os&nbsp;
                    <label
                      htmlFor="acceptedSensitiveData"
                      className="acceptedData acceptedPersonalData"
                      onClick={() => setOpenModalConsentForm(true)}
                    >
                      Termos de Consentimento para Tratamentos de Dados
                      Sensíveis
                    </label>
                  </label>
                </div>
              </div>
              <div className="btn-init">
                <Button
                  className="btn-start margin-bottom-40"
                  text="Iniciar"
                  onClick={onAccept}
                  disabled={!acceptedPersonalData || !acceptedSensitiveData}
                />
              </div>
            </div>
            <Person className="person-subscription" />
          </div>
          <ModalFixedHeader
            headerAction
            textHeader="Termo de Consentimento para Tratamento de Dados Pessoais..."
            open={openModalConsentForm}
            onClose={() => setOpenModalConsentForm(false)}
            className="modal-term"
          >
            <ConsentForm />
            <div className="flex justify-center">
              <Button
                text="SIM"
                onClick={() => {
                  createCourseSubscriptionLog(
                    user?._id,
                    CourseSubscriptionAction.COURSE_SUBSCRIPTION_SELECT_COURSE,
                    courseSubscription.LOGGED_IN,
                    courseSubscription.ACCEPT_TERMS,
                  );
                  setAcceptedPersonalData(true);
                  setAcceptedSensitiveData(true);
                  setOpenModalConsentForm(false);
                }}
                className="welcome-modal-term-button"
              />
            </div>
          </ModalFixedHeader>
        </>
      )}
    </Layout>
  );
};
export default memo(CourseWelcome);
