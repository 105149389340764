import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Button, IconButton, InputPassword } from "../../../components";
import { resetPassword } from "../../../services/functions";
import { useAuth } from "../../../auth";
import { ArrowLeft } from "../../../../assets/customIcons";

interface Props {
  token: string;
}

const SetPassword = ({ token }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [password, setPassword] = useState("");
  const { isValidToken } = useAuth();
  const history = useHistory();

  const handleSubmit = useCallback(async () => {
    try {
      const schema = Yup.object().shape({
        password: Yup.string()
          .min(8)
          .required()
          .matches(
            /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z$!*&@#%^=+|}{`~˜ˆ:;"/?><.,'_]{8,}$/,
          ),
      });

      await schema.validate(
        { password },
        {
          abortEarly: false,
        },
      );

      await resetPassword(password);
      toast.success("Senha definida com sucesso!");

      history.push("/");
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        toast.error(
          `Ops! A senha deve ter no mínimo 8 caracteres, com pelo menos uma letra maiúscula e um número. Os caracteres "-, (, ), [, ]" não serão aceitos.`,
        );
      }
    }
  }, [history, password]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (password) handleSubmit();
      }
    },
    [password, handleSubmit],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return !isValidToken(token) ? (
    <div className="padding-bottom-32 type-access">
      <p className="title-login padding-bottom-32">
        Este link não é mais válido.
      </p>
      <IconButton
        text="Voltar para o login"
        onClick={() => history.push("/")}
        icon={
          <ArrowLeft
            widthIcon={width < 991 ? 24 : 32}
            heightIcon={width < 991 ? 24 : 32}
          />
        }
        className="margin-bottom-32 button-back-login"
      />
    </div>
  ) : (
    <form onKeyDown={handleKeyDown}>
      <div className="margin-bottom-32 type-access">
        <p className="title-login">
          Escolha uma senha para acessar a plataforma
        </p>
        <InputPassword
          className="input-password password-input-login"
          label="Senha"
          helperText={`A senha deve ter no mínimo 8 caracteres, com pelo menos uma letra maiúscula e um número. Os caracteres "-, (, ), [, ]" não serão aceitos.`}
          value={password}
          onChange={(e: { target: { value: string } }) =>
            setPassword(e.target.value)
          }
        />
        <Button
          onClick={handleSubmit}
          text="Entrar"
          className="margin-bottom-32 width-100"
        />
      </div>
    </form>
  );
};
export default memo(SetPassword);
