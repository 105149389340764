/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { updateCompany } from "../../../store/actions/companyActions";
import { EditIntegrations } from ".";
import { Edit, Delete, Add } from "../../../../assets/icons";
import { IconButton, Card, Button } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { RootState } from "../../../store/reducers";

interface Props {
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isEdit: boolean;
}

const MainIntegration = ({ isEdit, setIsEdit }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [itemEditIndex, setItemEditIndex] = useState(-1);
  const [isValid, setIsValid] = useState(false);
  const company = useSelector((state: RootState) => state.companyState);
  const [_company, _setCompany] = useState(company);
  const [integration, setIntegration] = useState({});
  const dispatch = useDispatch();

  const onSave = useCallback(() => {
    let newIntegration = _company?.integrations;
    if (itemEditIndex !== -1) newIntegration[itemEditIndex] = integration;
    else if (newIntegration?.length) newIntegration.push(integration);
    else newIntegration = [integration];

    _setCompany({
      ..._company,
      integrations: newIntegration,
    });

    dispatch(
      setNextButton(() =>
        dispatch(
          updateCompany({
            ..._company,
            integrations: newIntegration,
          }) as unknown as Action,
        ),
      ) as unknown as Action,
    );

    setIsEdit(false);
  }, [_company, dispatch, integration, itemEditIndex, setIsEdit]);

  const onDelete = useCallback(
    (index: number) => {
      const newIntegrations = _company?.integrations;
      newIntegrations.splice(index, 1);
      _setCompany({
        ..._company,
        integrations: newIntegrations,
      });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateCompany({
              ..._company,
              integrations: newIntegrations,
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_company, dispatch],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (company?._id && !_company?._id) _setCompany(company);
  }, [_company?._id, company]);

  return (
    <div className="grid-column-5-12 media-5-14 grid-row-2 div-main grid-column-1-9-mobile">
      {!isEdit ? (
        <Card
          className="border-card"
          titleHeader={`${width > 990 ? "Integrações" : ""}`}
        >
          <IconButton
            icon={<Add />}
            text="Adicionar integração"
            onClick={() => {
              setIntegration({});
              setItemEditIndex(-1);
              setIsEdit(true);
            }}
            className="icon-button-primary"
          />
        </Card>
      ) : (
        <Card
          className="grid-column-5-12 grid-column-6-13-media grid-row-2 margin-left-8 border-card margin-left-0-mobile"
          titleHeader={`${width > 990 ? "Adicionar integração" : ""}`}
        >
          <EditIntegrations
            setIsValid={setIsValid}
            integration={integration}
            setIntegration={setIntegration}
          />
        </Card>
      )}
      {isEdit && (
        <div>
          <div className="flex margin-area-buttons margin-0-responsive flex-col-mobile">
            <Button
              text="Cancelar"
              className="button-secondary"
              onClick={() => setIsEdit(false)}
            />
            <Button
              text="Salvar"
              className="margin-left-32 margin-left-0-mobile"
              onClick={onSave}
              disabled={!isValid}
            />
          </div>
          <div className="divider-account" />
        </div>
      )}
      <p className="margin-bottom-20 margin-top-40">
        Gerencie suas integrações.
      </p>
      {_company?.integrations?.map((item: any, index: number) => (
        <div className="card-items-border">
          <div className="flex grid-column-5-12 grid-row-6 margin-top-20" />
          <Card bodyClassName="margin-bottom-30">
            <div className="flex-col">
              <div className="flex space-between">
                <p className="paragraph-main">{item?.platform}</p>
                <div className="flex">
                  <IconButton
                    icon={<Edit />}
                    onClick={() => {
                      setIntegration(_company?.integrations[index]);
                      setItemEditIndex(index);
                      setIsEdit(true);
                    }}
                    className="margin-right-8"
                  />
                  <IconButton
                    icon={<Delete />}
                    onClick={() => onDelete(index)}
                  />
                </div>
              </div>
              <p className="item-main-paragraph">{item?.user}</p>
              <p className="item-company-account">
                {item?.token &&
                  item?.token
                    .substring(0, item?.token?.length - 6)
                    ?.replace(/\D/g, "*")
                    .replace(/\d/g, "*") +
                    item?.token?.substring(
                      item?.token?.length - 5,
                      item?.token?.length,
                    )}
              </p>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default memo(MainIntegration);
