import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Action } from "redux";
import moment from "moment-timezone";
import {
  AddCertificate,
  Button,
  Modal,
  ViewCertificate,
} from "../../../components";
import {
  Class,
  UserCurriculum,
  UserCurriculumCertificate,
} from "../../../types/interfaces";
import { getCompanyIdReprograma, patchUser } from "../../../services/functions";
import { RootState } from "../../../store/reducers";
import { setLoad } from "../../../store/actions/configurationsActions";
import "../style.scss";

interface User {
  userId: string;
  curriculum: UserCurriculum;
}

interface Props {
  open: boolean;
  close: () => void;
  user: User;
  currentClass: Class;
  reload: () => void;
}

const Certificate = ({
  open,
  close,
  user,
  currentClass,
  reload,
}: Props): JSX.Element => {
  const [companyId, setCompanyId] = useState<string>();
  const [certificate, setCertificate] = useState<UserCurriculumCertificate>({
    companyId,
    expirationDate: null,
    date: moment().tz("America/Sao_Paulo").toISOString(),
    medias: [],
    title: `${currentClass?.title} - ${moment(currentClass?.endDate).format(
      "YYYY",
    )}`,
  });
  const [_user, _setUser] = useState(user);
  const dispatch = useDispatch();

  const usr = useSelector((state: RootState) => state.userState);

  const certificates = useMemo(
    () =>
      (_user?.curriculum?.certificates ?? []).filter(
        (cert: UserCurriculumCertificate) => cert?.companyId === companyId,
      ),
    [_user, companyId],
  );

  const isMyProfile = useMemo(
    () =>
      _user?.curriculum?.certificates?.some(
        (cert: UserCurriculumCertificate) => cert?.userId === usr?._id,
      ),
    [_user?.curriculum?.certificates, usr?._id],
  );

  const getCompanyId = useCallback(async () => {
    const id = await getCompanyIdReprograma();
    setCompanyId(id);
  }, []);

  useEffect(() => {
    if (!companyId) getCompanyId();
  }, [getCompanyId, companyId]);

  const remove = useCallback(
    (i) => {
      const oldCertificate = _user?.curriculum?.certificates || [];
      const indexCertificate = oldCertificate?.indexOf(i);

      oldCertificate?.splice(indexCertificate, 1);

      const updatedCertificates = oldCertificate?.map(
        (cert: UserCurriculumCertificate, idx: number) => ({
          ...cert,
          order: idx,
        }),
      );

      _setUser({
        ..._user,
        curriculum: {
          ..._user?.curriculum,
          certificates: updatedCertificates,
        },
      } as User);
    },
    [_user],
  );

  const save = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const userId = usr?._id;

    const payload = {
      ...certificate,
      companyId,
      userId,
    };

    const newCertificates = [
      ...(_user?.curriculum?.certificates ?? []),
      payload,
    ];

    await patchUser(user?.userId ?? "", {
      curriculum: {
        ..._user?.curriculum,
        certificates: newCertificates,
      },
    });

    reload();
    dispatch(setLoad(false) as unknown as Action);
  }, [
    dispatch,
    usr?._id,
    certificate,
    companyId,
    _user?.curriculum,
    user?.userId,
    reload,
  ]);

  return (
    <Modal
      headerAction
      open={open}
      onClose={close}
      textHeader="Adicionar certificado"
      className="admin-subscriptions-modal-manager-actions"
    >
      <AddCertificate
        certificate={certificate}
        setItem={setCertificate}
        cantEditMedia
      />
      {_user?.curriculum?.certificates &&
        _user?.curriculum?.certificates?.some(
          (cert: UserCurriculumCertificate) => cert?.companyId === companyId,
        ) && (
          <div className="old-certificate">
            <h2>Certificados anteriores</h2>
            <ViewCertificate
              isMyProfile={isMyProfile}
              className="padding-y-16 padding-x-24"
              showDivider
              items={certificates}
              onRemove={remove}
            />
          </div>
        )}
      <div className="documents-button margin-top-32">
        <Button
          text="Cancelar"
          onClick={close}
          outline
          appearance="secondary"
          className="documents-button-old-cancel"
        />
        <Button
          text="Salvar"
          className="documents-button-save"
          onClick={save}
        />
      </div>
    </Modal>
  );
};

export default memo(Certificate);
