/* eslint-disable no-await-in-loop */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import {
  Button,
  Checkbox,
  InputAutocomplete,
  Modal,
} from "../../../components";
import { setLoad } from "../../../store/actions/configurationsActions";
import {
  createUser,
  filterClassVacancySubscription,
  getUserAdminClassVacancyVolunter,
  getUserByIdentification,
  patchUser,
  requestSignupLink,
  updateClassVacancyById,
} from "../../../services/functions";
import { ClassVacancy, ClassVacancyStages } from "../../../types/interfaces";
import {
  classVacancyStatus,
  userGenderIdentity,
  userRoles,
  userStatus,
} from "../../../types/enumerators";
import { hasAdminClassVacancyVolunteerMediaRole } from "../../../utils";
import "../style.scss";

interface ParamsProps {
  id: string;
}

interface Props {
  open: boolean;
  onclose: () => void;
  stage: ClassVacancyStages;
  stageId: string;
  reload: () => void;
  classVacancy: ClassVacancy;
  stagesIds: {
    mediaStageId?: string;
    subscriptionStageId?: string;
    workshopStageId?: string;
    talentAcademyStageId?: string;
    testStageId?: string;
  };
}

const Distribute = ({
  open,
  onclose,
  stage,
  stageId,
  reload,
  classVacancy,
  stagesIds,
}: Props): JSX.Element => {
  const [volunteersOptions, setVolunteersOptions] = useState<string[]>();
  const [volunteers, setVolunteers] = useState<string[]>();
  const [includeAllMedia, setIncludeAllMedia] = useState(false);
  const { id } = useParams<ParamsProps>();

  const dispatch = useDispatch();

  const initialVolunteers = useMemo(() => stage?.volunteers ?? [], [stage]);

  const getVolunteersOptions = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getUserAdminClassVacancyVolunter();
    const responseEmailUserOptions = response?.map(
      (option) => option?.login?.identification,
    );
    setVolunteersOptions(responseEmailUserOptions);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  useEffect(() => {
    if (open && !volunteersOptions?.length) getVolunteersOptions();
  }, [volunteersOptions, getVolunteersOptions, open]);

  const onChange = useCallback(
    (value) =>
      setVolunteers(
        typeof value === "string"
          ? value?.trim()
          : value?.map((item: string) => item?.toLowerCase().trim()),
      ),
    [],
  );

  useEffect(() => {
    if (open && !!initialVolunteers && !volunteers) onChange(initialVolunteers);
  }, [initialVolunteers, onChange, open, volunteers]);

  const removeClassVacancyIdOfVolunteer = useCallback(
    async (listVolunteersRemoved: string[]) => {
      for (let i = 0; i < listVolunteersRemoved?.length; i += 1) {
        const userAdmVolunteerRemoved = await getUserByIdentification(
          listVolunteersRemoved[i],
        );

        const accountClassVacancy =
          userAdmVolunteerRemoved?.account?.classVacancy ?? {};

        if (id && Object.hasOwn(accountClassVacancy, id))
          delete accountClassVacancy[id];

        if (Object.keys(accountClassVacancy)?.length === 0) {
          delete userAdmVolunteerRemoved?.account?.classVacancy;

          if (
            userAdmVolunteerRemoved?.account?.roles?.length &&
            userAdmVolunteerRemoved?.account?.roles?.length > 1
          ) {
            const idx = userAdmVolunteerRemoved?.account?.roles?.findIndex(
              (item) => item === userRoles.ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA,
            );

            if (idx > -1)
              userAdmVolunteerRemoved?.account?.roles?.splice(idx, 1);
          } else if (userAdmVolunteerRemoved?.account?.status)
            userAdmVolunteerRemoved.account.status = userStatus.DISABLED;
        }

        await patchUser(userAdmVolunteerRemoved?._id ?? "", {
          account: {
            ...userAdmVolunteerRemoved?.account,
          },
        });
      }
    },
    [id],
  );

  const addClassVacancyIdInVolunteer = useCallback(
    async (listVolunteers: string[], value: string[][]) => {
      for (let i = 0; i < listVolunteers?.length; i += 1) {
        let user = await getUserByIdentification(listVolunteers[i]);

        if (!user) {
          user = await createUser(listVolunteers[i], [
            userRoles.ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA,
          ]);
          await requestSignupLink(listVolunteers[i]);
        }

        if (user && !hasAdminClassVacancyVolunteerMediaRole(user))
          user.account.roles.push(
            userRoles.ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA,
          );

        if (user?.account?.status === userStatus.DISABLED)
          user.account.status = userStatus.PENDING;

        const accountClassVacancy = {
          ...(user?.account?.classVacancy ?? {}),
          [id]: value[i],
        };

        await patchUser(user?._id ?? "", {
          account: {
            ...user?.account,
            classVacancy: accountClassVacancy,
          },
        });
      }
    },
    [id],
  );

  const getSubscription = useCallback(
    async (
      hasMedia: boolean,
      genderIdentity: Array<keyof typeof userGenderIdentity> | null,
      onlySize: boolean,
      ...params
    ) => {
      const result = await filterClassVacancySubscription(
        id ?? "",
        {
          stageId,
          status: classVacancyStatus.ACTIVE,
          genderIdentity,
          hasMedia,
        },
        onlySize,
        ...params,
      );
      return result;
    },
    [id, stageId],
  );

  const distributeMath = useCallback(
    async (
      numberStudentsWithMedia,
      numberStudentsWithoutMedia,
      genderIdentity,
    ) => {
      const numberVolunteers = volunteers?.length ?? 0;
      const subsVolunteers = {} as { [x: string]: string[] };

      for (let i = 0; i < numberVolunteers; i += 1) {
        let subsIdsWithMedia: string[] = [];
        let subsIdWithoutMedia: string[] = [];

        if (numberStudentsWithMedia) {
          const studentsPerVolunteersWithMedia = Math.ceil(
            numberStudentsWithMedia / numberVolunteers,
          );
          const subsWithMedia = await getSubscription(
            true,
            genderIdentity,
            false,
            stagesIds,
            i + 1,
            studentsPerVolunteersWithMedia,
            "user.name",
            true,
            studentsPerVolunteersWithMedia,
          );
          subsIdsWithMedia =
            subsWithMedia?.map((volunter) => volunter?._id) ?? [];
        }

        if (numberStudentsWithoutMedia) {
          const studentsPerVolunteersWithoutMedia = Math.ceil(
            numberStudentsWithoutMedia / numberVolunteers,
          );
          const subsWithoutMedia = await getSubscription(
            false,
            genderIdentity,
            false,
            stagesIds,
            i + 1,
            studentsPerVolunteersWithoutMedia,
            "user.name",
            true,
            studentsPerVolunteersWithoutMedia,
          );
          subsIdWithoutMedia =
            subsWithoutMedia?.map((volunter) => volunter?._id) ?? [];
        }

        if (volunteers?.[i])
          subsVolunteers[volunteers?.[i]] = [
            ...subsIdsWithMedia,
            ...subsIdWithoutMedia,
          ];
      }

      addClassVacancyIdInVolunteer(
        Object.keys(subsVolunteers),
        Object.values(subsVolunteers),
      );
    },
    [volunteers, addClassVacancyIdInVolunteer, getSubscription, stagesIds],
  );

  const distributeCandidates = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    if (JSON.stringify(initialVolunteers) !== JSON.stringify(volunteers)) {
      const listRemove = initialVolunteers?.filter(
        (item: string) => !volunteers?.includes(item),
      );

      if (listRemove && listRemove?.length)
        removeClassVacancyIdOfVolunteer(listRemove);

      const newClassVacancy = { ...classVacancy };
      const stages = [...(newClassVacancy?.stages ?? [])];

      for (let i = 0; i < stages.length; i += 1) {
        if (stages[i]?.stage_id === stage?.stage_id) {
          stages[i] = {
            ...stages[i],
            volunteers: [...(volunteers ?? [])],
          };
          break;
        }
      }

      await updateClassVacancyById(newClassVacancy?._id ?? "", {
        stages,
      });
      reload();
    }

    const genderIdentity = !includeAllMedia
      ? (Object.keys(userGenderIdentity)
          ?.filter(
            (key) =>
              key !== userGenderIdentity.TRANSGENDER_FEMALE &&
              key !== userGenderIdentity.TRANSVESTITE,
          )
          ?.map((key) => key) as Array<keyof typeof userGenderIdentity>) ?? null
      : null;

    const numberStudentsWithMedia = (await getSubscription(
      true,
      genderIdentity,
      true,
    )) as { size?: number }[];

    const numberStudentsWithoutMedia = (await getSubscription(
      false,
      genderIdentity,
      true,
    )) as { size?: number }[];

    distributeMath(
      numberStudentsWithMedia?.[0]?.size ?? 0,
      numberStudentsWithoutMedia?.[0]?.size ?? 0,
      genderIdentity,
    );

    onclose();
    dispatch(setLoad(false) as unknown as Action);
  }, [
    dispatch,
    initialVolunteers,
    volunteers,
    includeAllMedia,
    getSubscription,
    distributeMath,
    onclose,
    removeClassVacancyIdOfVolunteer,
    classVacancy,
    reload,
    stage?.stage_id,
  ]);

  return (
    <Modal
      headerAction
      open={open}
      onClose={onclose}
      textHeader="Distribuir vídeos"
      className="admin-subscriptions-modal-manager-actions"
    >
      <p className="admin-subscriptions-modal-manager-actions distribution-text">
        Informe os emails das voluntárias que avaliarão os vídeos:
      </p>
      <InputAutocomplete
        multiple
        label="Voluntárias"
        helperText="Pressione Enter para adicionar"
        options={volunteersOptions ?? [""]}
        getOptionLabel={(option) => option}
        value={volunteers ?? []}
        onChange={onChange}
      />
      <Checkbox
        id="includeAllMedia"
        label="Incluir mulheres trans e travestis"
        checked={includeAllMedia}
        className="is-checkbox"
        onChange={() => setIncludeAllMedia((prev) => !prev)}
      />
      <div className="admin-subscriptions-modal-buttons margin-top-32">
        <Button
          text="Cancelar"
          onClick={() => {
            setVolunteers(initialVolunteers ?? []);
            onclose();
          }}
          outline
          appearance="secondary"
        />
        <Button
          onClick={distributeCandidates}
          text={volunteers?.length ? "Distribuir" : "Salvar"}
        />
      </div>
    </Modal>
  );
};

export default memo(Distribute);
