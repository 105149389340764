import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Checkbox, Input } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateUser } from "../../../store/actions/userActions";
import { RootState } from "../../../store/reducers";
import { maskPhone } from "../../../utils";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const ContactPhone = ({ setIsValid }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [sameWhatsApp, setSameWhatsApp] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [_profile, _setProfile] = useState(user?.profile);

  useEffect(() => {
    if (user?.profile?.address && !_profile?.address) {
      _setProfile({ ...user?.profile });
    }
  }, [_profile?.address, user?.profile]);

  const handleOnBlur = useCallback(() => {
    if (_profile?.telephone) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              profile: { ..._profile },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [_profile, dispatch, setIsValid, user]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
      value?: boolean,
    ) => {
      if (value !== undefined) {
        _setProfile({ ..._profile, [prop]: value });
      } else _setProfile({ ..._profile, [prop]: event.target.value });
    },
    [_profile],
  );

  const onChange = useCallback(
    (e) => {
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              profile: { ..._profile, isWhatsapp: !_profile?.isWhatsapp },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
      handleChange(e, "isWhatsapp", !_profile?.isWhatsapp);
      if (e.target.checked) setIsValid(true);
      else setIsValid(false);
    },
    [_profile, dispatch, handleChange, setIsValid, user],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!user.profile.isWhatsapp) setIsValid(true);
  }, [setIsValid, user.profile.isWhatsapp]);

  return (
    <div className="grid display-block">
      <p className="title-input grid-row-1 grid-column-1-3">
        Para facilitar a comunicação, nos informe seu WhatsApp e, se tiver, seu
        Telegram também. Prometemos não enviar spam :)
      </p>
      {width < 991 && (
        <p className="p-mobile margin-top-24-mobile">WhatsApp*</p>
      )}
      <Input
        value={maskPhone(_profile?.telephone ?? "")}
        maxLength={14}
        onChange={(e) => handleChange(e, "telephone")}
        onBlur={handleOnBlur}
        className="grid-row-2 grid-column-1-2 width-100 "
        type="text"
        label="WhatsApp*"
        disabled={!_profile?.isWhatsapp}
      />
      <Checkbox
        className="dark-grey-blue"
        id="whatsapp"
        label="Não tenho WhatsApp"
        checked={!_profile?.isWhatsapp}
        onChange={onChange}
      />
      {width < 991 && <p className="p-mobile margin-top-24-mobile">Telegram</p>}
      <Input
        value={maskPhone(_profile?.telegram ?? "")}
        maxLength={14}
        onChange={(e) => handleChange(e, "telegram")}
        onBlur={handleOnBlur}
        className="grid-row-2 grid-column-2-3 with-100"
        type="text"
        label="Telegram"
        disabled={!_profile?.telephone || sameWhatsApp}
      />
      <Checkbox
        className="dark-grey-blue"
        id="sameWhatsApp"
        label="Mesmo número do WhatsApp"
        checked={sameWhatsApp}
        onChange={() => setSameWhatsApp(!sameWhatsApp)}
        disabled={!_profile?.telephone}
      />
    </div>
  );
};

export default memo(ContactPhone);
