import React, {
  memo,
  useCallback,
  useMemo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  Fragment,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { toast } from "react-toastify";
import {
  Button,
  CardRegister,
  CardSteps,
  CardStepsCourse,
  Layout,
  TextArea,
} from "../../../components";
import { rubrics } from "../../AdminClassVacancySubscriptions/Steps/data";
import {
  ClassVacancy,
  ClassVacancyStages,
  ClassVacancySubscription,
} from "../../../types/interfaces";
import {
  classVacancyStatus,
  classVacancyType,
} from "../../../types/enumerators";
import { Check } from "../../../../assets/icons";
import {
  sendCandidateMediaEmail,
  upClassVacancySubscriptionStage,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import { CourseSubscriptionAction } from "../../../types/enumerators/actions/CourseSubscriptionAction";
import { CourseSubscriptionState } from "../../../types/constants/Historic";
import { createCourseSubscriptionLog } from "../../../services/historicFunctions";
import { RootState } from "../../../store/reducers";
import { setLoad } from "../../../store/actions/configurationsActions";

interface Props {
  subscription?: ClassVacancySubscription;
  classVacancy?: ClassVacancy;
  setPage: Dispatch<SetStateAction<number>>;
  setExistForm: Dispatch<SetStateAction<boolean>>;
}

const FormPresentation = ({
  subscription,
  classVacancy,
  setPage,
  setExistForm,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [form, setForm] = useState<{ [x: string]: string }>();
  const user = useSelector((state: RootState) => state.userState);

  const template = useMemo(
    () => classVacancy?.template,
    [classVacancy?.template],
  );

  const title = useMemo(() => classVacancy?.title, [classVacancy?.title]);

  const expectedRubricCount = useMemo(
    () => rubrics(template).length - 1,
    [template],
  );

  const isFormIncomplete = useMemo(
    () => form && Object.keys(form)?.length < expectedRubricCount,
    [expectedRubricCount, form],
  );

  const isFormEmpty = useMemo(
    () =>
      !form ||
      (form && Object.values(form)?.some((item) => item?.trim() === "")),
    [form],
  );

  const stageMedia: ClassVacancyStages | undefined = useMemo(
    () =>
      classVacancy?.stages?.find(
        (stage) => stage?.type === classVacancyType.UPLOAD_MEDIAS,
      ),
    [classVacancy],
  );

  const activeStage: number | undefined = useMemo(
    () =>
      classVacancy?.stages?.find(
        (stage) => stage?.status === classVacancyStatus.ACTIVE,
      )?.order,
    [classVacancy?.stages],
  );

  const steps: {
    page: number;
    maxPageVisited?: number;
    maxMenuVisited?: number;
  } = useMemo(
    () => ({
      page: 1,
      maxPageVisited: activeStage,
      maxMenuVisited: activeStage,
    }),
    [activeStage],
  );

  const itemsMenu: { icon?: JSX.Element; text?: string; id?: number }[] =
    useMemo(
      () =>
        (classVacancy?.stages &&
          classVacancy?.stages?.map((item) => ({
            icon: <Check />,
            text: item?.title,
            id: item?.order,
          }))) ??
        [],
      [classVacancy],
    );

  const sendLog = useCallback(
    () =>
      createCourseSubscriptionLog(
        user._id,
        CourseSubscriptionAction.COURSE_SUBSCRIPTION_ATTACHMENT_SUBMITTED,
        CourseSubscriptionState.ATTACHMENT_SUBMISSION,
        CourseSubscriptionState.ATTACHMENT_SUBMITTED,
      ),
    [user._id],
  );

  const handleChangeValue = useCallback(
    (value: string, key: string) => {
      setForm({
        ...form,
        [key]: value,
      });
    },
    [form],
  );

  const error = useCallback(
    () =>
      toast.error(
        "Ops! Ocorreu um erro. Confira se todos os campos foram preenchidos e tente novamente, por favor",
      ),
    [],
  );
  const dispatch = useDispatch();
  const handleClickForm = useCallback(async () => {
    try {
      dispatch(setLoad(true) as unknown as Action);
      if (form && stageMedia?.stage_id) {
        const stages = subscription?.stages?.map((item) => {
          if (item?.stageId === stageMedia?.stage_id)
            return {
              ...item,
              form,
            };

          return item;
        });

        if (
          stages?.find((item) => item?.stageId === stageMedia?.stage_id)?.form
        ) {
          await updateClassVacancySubscriptionById(subscription?._id ?? "", {
            stages,
          });

          await upClassVacancySubscriptionStage(subscription?._id ?? "");

          if (title)
            await sendCandidateMediaEmail(
              [user?.profile?.email ?? user?.login?.identification],
              title,
              "formulário",
            );

          setExistForm(true);
          setPage(4);
          sendLog();
        } else error();
      } else error();
    } catch {
      error();
    } finally {
      dispatch(setLoad(false) as unknown as Action);
    }
  }, [
    dispatch,
    error,
    form,
    sendLog,
    setExistForm,
    setPage,
    stageMedia?.stage_id,
    subscription?._id,
    subscription?.stages,
    title,
    user?.login?.identification,
    user?.profile?.email,
  ]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout
      header
      className="container-form-presentation"
      back
      onClickBack={() => {
        createCourseSubscriptionLog(
          user._id,
          CourseSubscriptionAction.COURSE_SUBSCRIPTION_ATTACHMENT_BACK,
          CourseSubscriptionState.ATTACHMENT_SUBMISSION,
          CourseSubscriptionState.FORM_SUBMITTED,
        );
        setPage(1);
      }}
    >
      {width > 990 ? (
        <CardStepsCourse stages={classVacancy?.stages ?? []} />
      ) : (
        <div className="grid-column-3-5 grid-row-1 card-steps">
          <CardSteps
            smallScreen
            className="card-steps-register step-MuiPaper-root"
            orientation="horizontal"
            itemsMenu={itemsMenu}
            steps={steps ?? {}}
          />
        </div>
      )}
      <CardRegister
        titleHeader="Formulário de Apresentação"
        className="margin-top-16 border-card grid-column-5-12 course-form card-form"
      >
        <div className="information">
          {rubrics(template)
            ?.filter(
              (item) =>
                item?.criterions?.length < 2 &&
                item?.criterions[0]?.type !== "ALL_QUESTIONS",
            )
            .map((item) => {
              const criterioType: string =
                item?.criterions[0]?.type?.toLowerCase() ?? "";

              return (
                <Fragment key={criterioType}>
                  <p className="information-title">{item?.question}</p>
                  <div className="input-form">
                    <TextArea
                      label="Responda aqui"
                      className="description-input"
                      value={form?.[criterioType] ?? ""}
                      maxLength={2000}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, criterioType)
                      }
                    />
                  </div>
                </Fragment>
              );
            })}
        </div>
      </CardRegister>
      <Button
        text="Enviar"
        className="grid-row-3 margin-bottom-48 button-form"
        onClick={handleClickForm}
        disabled={isFormIncomplete || isFormEmpty}
      />
    </Layout>
  );
};

export default memo(FormPresentation);
