/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React from "react";
import moment from "moment";
import { toast } from "react-toastify";
import {
  getAutocomplete,
  getRegexAutocomplete,
  setAutocomplete,
} from "../services/functions";
import {
  autoCompleteType,
  classVacancyStatus,
  classVacancySubscriptionEmployabilitySituation,
  classVacancySubscriptionStatus,
  classVacancyTemplate,
  classVacancyType,
  companyStatus,
  userStatus,
} from "../types/enumerators";
import {
  ClassVacancy,
  ClassVacancySubscription,
  ClassVacancySubscriptionStage,
  Company,
  User,
} from "../types/interfaces";

export const createId = (): string | null => {
  try {
    const s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  } catch (error) {
    console.warn(error);
    return null;
  }
};

export const extractDynamicProperty = (
  myObj: { [key: string | number | symbol]: any },
  ...props: string[]
) => {
  let result = "";

  props.forEach((prop) => {
    if (typeof myObj[prop] !== "undefined") {
      result = myObj[prop];
    }
  });

  return result;
};

export const maskReal = (value: string): string =>
  value
    ?.replace(/\D/g, "")
    ?.replace(/(\d{1,2})$/, ",$1")
    ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

export const removeMaskReal = (value: string): number => {
  const replaceString = value?.replace(".", "")?.replace(",", ".");
  const formatStringWithDecimals = parseFloat(replaceString)?.toFixed(2);
  const formatStringtoNumber = parseFloat(formatStringWithDecimals);

  return Number.isNaN(formatStringtoNumber) ? -1 : formatStringtoNumber;
};

export const maskZipCode = (value: string): string =>
  value?.replace(/\D/g, "").replace(/^(\d{5})(\d)/, "$1-$2");

export const maskPhone = (value: string): string =>
  value
    ?.replace(/\D/g, "")
    ?.replace(/^(\d{2})(\d)/g, "($1)$2")
    ?.replace(/(\d)(\d{4})$/, "$1-$2");

export const maskBRlegalIdentity = (value: string): string =>
  value
    ?.replace(/\D/g, "")
    ?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

export const removeMaskBRlegalIdentity = (value: string): string =>
  value?.replace(/\D/g, "");

export const maskDate = (value: string): string =>
  value?.replace(/\D/g, "").replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");

export const isValidBRlegalIdentity = (value: string): boolean => {
  if (value === "") return false;
  if (value?.length !== 11 || /^(\d)\1{10}$/.test(value)) return false;

  let add = 0;
  for (let i = 0; i < 9; i += 1)
    add += parseInt(value?.charAt(i), 10) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(value?.charAt(9), 10)) return false;

  add = 0;
  for (let i = 0; i < 10; i += 1)
    add += parseInt(value?.charAt(i), 10) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(value?.charAt(10), 10)) return false;

  return true;
};

export const isValidMail = (value: string): any =>
  !!value?.match(
    /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/,
  );

export const getTextDiffDate = (date: string | Date): string => {
  const dateDifferenceInDays = (): number =>
    moment().diff(moment(date), "days");

  const dateDifferenceInWeeks = (): number =>
    moment().diff(moment(date), "weeks");

  const dateDifferenceInMonths = (): number =>
    moment().diff(moment(date), "months");

  const dateDifferenceInYears = (): number =>
    moment().diff(moment(date), "years");

  if (
    dateDifferenceInDays() === 0 &&
    dateDifferenceInWeeks() === 0 &&
    dateDifferenceInMonths() === 0 &&
    dateDifferenceInYears() === 0
  )
    return " hoje";

  if (
    dateDifferenceInDays() === 1 &&
    dateDifferenceInWeeks() === 0 &&
    dateDifferenceInMonths() === 0 &&
    dateDifferenceInYears() === 0
  )
    return " 1 dia atrás";

  if (
    dateDifferenceInDays() === -1 &&
    dateDifferenceInWeeks() === 0 &&
    dateDifferenceInMonths() === 0 &&
    dateDifferenceInYears() === 0
  )
    return " em 1 dia";

  if (
    dateDifferenceInDays() > 1 &&
    dateDifferenceInWeeks() === 0 &&
    dateDifferenceInMonths() === 0 &&
    dateDifferenceInYears() === 0
  )
    return ` ${dateDifferenceInDays()} dias atrás`;

  if (
    dateDifferenceInDays() < -1 &&
    dateDifferenceInWeeks() === 0 &&
    dateDifferenceInMonths() === 0 &&
    dateDifferenceInYears() === 0
  )
    return ` em ${dateDifferenceInDays() * -1} dias`;

  if (
    dateDifferenceInWeeks() === 1 &&
    dateDifferenceInMonths() === 0 &&
    dateDifferenceInYears() === 0
  )
    return " 1 semana atrás";

  if (
    dateDifferenceInWeeks() === -1 &&
    dateDifferenceInMonths() === 0 &&
    dateDifferenceInYears() === 0
  )
    return " em 1 semana";

  if (
    dateDifferenceInWeeks() > 1 &&
    dateDifferenceInMonths() < 1 &&
    dateDifferenceInYears() === 0
  )
    return ` ${dateDifferenceInWeeks()} semanas atrás`;

  if (
    dateDifferenceInWeeks() < -1 &&
    dateDifferenceInMonths() > -1 &&
    dateDifferenceInYears() === 0
  )
    return ` em ${dateDifferenceInWeeks() * -1} semanas`;

  if (dateDifferenceInMonths() === 1 && dateDifferenceInYears() === 0)
    return " 1 mês atrás";

  if (dateDifferenceInMonths() === -1 && dateDifferenceInYears() === 0)
    return " em 1 mês";

  if (dateDifferenceInMonths() > 1 && dateDifferenceInYears() === 0)
    return ` ${dateDifferenceInMonths()} meses atrás`;

  if (dateDifferenceInMonths() < -1 && dateDifferenceInYears() === 0)
    return ` em ${dateDifferenceInMonths() * -1} meses`;

  if (dateDifferenceInYears() === 1) return " 1 ano atrás";

  if (dateDifferenceInYears() === -1) return " em 1 ano";

  if (dateDifferenceInYears() > 1)
    return ` ${dateDifferenceInYears()} anos atrás`;

  if (dateDifferenceInYears() < -1)
    return ` em ${dateDifferenceInYears() * -1} anos`;

  return "";
};

export const order = (data: { order: string | number }[]): any => {
  data?.sort((a: { order: string | number }, b: { order: string | number }) => {
    if ("order" in a && "order" in b) {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    }
    return 0;
  });
};

export const orderList = (
  headId: string,
  head: any[],
  list: any[],
  sortHeadCells: boolean,
): any => {
  let headList: any[] = [];
  let asc = true;
  if (sortHeadCells) {
    const newHeadCells: any = [...head];
    headList = newHeadCells.map(
      (item: { id: string; orderBy: boolean; asc: boolean }) => {
        const itemOrder = { ...item };
        if (item?.id !== headId) {
          itemOrder.orderBy = false;
          itemOrder.asc = true;
        } else {
          itemOrder.orderBy = true;
          itemOrder.asc = !item?.asc;
          asc = itemOrder.asc;
        }
        return itemOrder;
      },
    );
  } else {
    headList = head;
    asc = headList.find((item) => item?.orderBy).asc;
  }

  list?.sort((a, b) => {
    if (moment(a[headId]).isValid() && moment(b[headId]).isValid()) {
      if (a[headId] && b[headId]) {
        if (moment(a[headId]).isBefore(b[headId])) return -1;
        if (moment(b[headId]).isBefore(a[headId])) return 1;
        return 0;
      }
      return 0;
    }
    if (a[headId] && b[headId]) {
      if (a[headId].toLowerCase() < b[headId].toLowerCase()) return -1;
      if (a[headId].toLowerCase() > b[headId].toLowerCase()) return 1;
      return 0;
    }
    return 0;
  });
  if (asc) {
    return { list, headList };
  }
  return { list: list.reverse(), headList };
};

export const youTubeIdFromLink = (url: string) =>
  url.match(
    /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/,
  )[1];

export const registerAutoComplete = async (
  value: string | string[] | null,
  type: keyof typeof autoCompleteType,
) => {
  if (value && value !== "") {
    const response = await getAutocomplete(type);
    const responseMapped = response?.map(
      (item: { name: string }) => item?.name,
    );
    if (Array.isArray(value)) {
      value?.map(async (item) => {
        if (!responseMapped?.includes(item)) {
          const existsOnDatabase = await getRegexAutocomplete(item, type);
          if (!existsOnDatabase?.length) {
            await setAutocomplete(type, item);
          }
        }
      });
    }
    if (typeof value === "string") {
      if (!responseMapped?.includes(value)) {
        const existsOnDatabase = await getRegexAutocomplete(value, type);
        if (!existsOnDatabase?.length) {
          await setAutocomplete(type, value);
        }
      }
    }
  }
};

export const isDisabledUser = (user: User): boolean =>
  user?.account?.status === userStatus.DISABLED;

export const isBlockedUser = (user: User): boolean =>
  user?.account?.status === userStatus.BLOCKED;

export const isActiveUser = (user: User): boolean =>
  user?.account?.status === userStatus.ACTIVE;

export const isActiveCompany = (company: Company): boolean =>
  company?.status === companyStatus.ACTIVE;

export const hasAnyAdminRole = (user: User): boolean =>
  !!user?.account?.roles?.find((role) => role?.includes("ADMIN"));

export const hasAdminRole = (user: User): boolean =>
  !!user?.account?.roles?.find((role) => role === "ADMIN");

export const hasAdminClassRole = (user: User): boolean =>
  !!user?.account?.roles?.find((role) => role === "ADMIN_CLASS");

export const hasAdminClassVacancyRole = (user: User): boolean =>
  !!user?.account?.roles?.find((role) => role === "ADMIN_CLASS_VACANCY");

export const hasAdminClassVacancyVolunteerMediaRole = (user: User): boolean =>
  !!user?.account?.roles?.find(
    (role) => role === "ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA",
  );

export const hasAdminClassWorkshopRole = (user: User): boolean =>
  !!user?.account?.roles?.find(
    (role) => role === "ADMIN_CLASS_VACANCY_WORKSHOP",
  );

export const hasAdminClassBootcampRole = (user: User): boolean =>
  !!user?.account?.roles?.find(
    (role) => role === "ADMIN_CLASS_VACANCY_BOOTCAMP",
  );

export const hasCorporateRole = (user: User): boolean =>
  !!user?.account?.roles?.find((role) => role === "CORPORATE");

export const hasCandidateRole = (user: User): boolean =>
  !!user?.account?.roles?.find((role) => role === "CANDIDATE");

export const hasStudentRole = (user: User): boolean =>
  !!user?.account?.roles?.find((role) => role === "STUDENT");

export const isAdmin = (user: User) => {
  const type = sessionStorage.getItem("@SESSION:type") ?? "";
  return type === "ADMIN" && hasAnyAdminRole(user);
};

export const isCandidate = (user: User) => {
  const type = sessionStorage.getItem("@SESSION:type") ?? "";
  return type === "CANDIDATE" && hasCandidateRole(user);
};

export const isCorporate = (user: User) => {
  const type = sessionStorage.getItem("@SESSION:type") ?? "";
  return type === "CORPORATE" && hasCorporateRole(user);
};

export const isStudent = (user: User) => {
  const type = sessionStorage.getItem("@SESSION:type") ?? "";
  return type === "STUDENT" && hasStudentRole(user);
};

export const validateCourseSubscription = (
  subscription: ClassVacancySubscription,
  template: keyof typeof classVacancyTemplate = classVacancyTemplate.LEVEL_ONE,
  user: User,
): string[] => {
  const { form } = subscription;
  const { profile } = user;
  // const { curriculum } = user;
  const { address: profileAddress } = profile ?? {};

  if (!subscription?.classVacancyId) return ["curso"];

  const infos = {
    name: !!profile?.name || "Dados gerais - Nome",
    rg: !!profile?.rg || "Dados gerais - RG",
    legalIdentity: !!profile?.legalIdentity || "Dados gerais - Documento",
    birthdate: !!profile?.birthdate || "Dados gerais - Data de nascimento",
    email: !!profile?.email || "Dados gerais - E-mail",
  };

  const address = {
    zipCode: !!profileAddress?.zipCode || "Endereço - CEP",
    address: !!profileAddress?.address || "Endereço - Rua",
    district: !!profileAddress?.district || "Endereço - Bairro",
    city: !!profileAddress?.city || "Endereço - Cidade",
    province: !!profileAddress?.province || "Endereço - Estado",
    country: !!profileAddress?.country || "Endereço - País",
  };

  const reprograma = {
    target: !!form?.target || "Você e a {reprograma} - Curso/Oficina",
    howToKnowReprograma:
      !!form?.howToKnowReprograma ||
      "Você e a {reprograma} - Como conheceu a {reprograma}",
    isTeacher:
      form?.isTeacher !== undefined ||
      template !== classVacancyTemplate.EDV ||
      "Você e a {reprograma} - É/já foi professora na {reprograma}",
    isJuniorAssistant:
      form?.isJuniorAssistant !== undefined ||
      template !== classVacancyTemplate.EDV ||
      "Você e a {reprograma} - É/já foi monitora na {reprograma}",
    isReprogramaStudent:
      form?.isReprogramaStudent !== undefined ||
      "Você e a {reprograma} - Já foi aluna",
    reprogramaStudentOtherClasses:
      !!form?.reprogramaStudentOtherClasses ||
      !form?.isReprogramaStudent ||
      "Você e a {reprograma} - Turma",
    similarCourse:
      form?.similarCourse !== undefined ||
      template !== classVacancyTemplate.LEVEL_ONE ||
      "Você e a {reprograma} - Fez/faz parte de outra iniciativa semelhante à {reprograma}",
    canParticipateOnSelectWorkshop:
      form?.canParticipateOnSelectWorkshop !== undefined ||
      "Você e a {reprograma} - Participa da oficina",
    learningITMotivation:
      !!form?.learningITMotivation ||
      "Você e a {reprograma} - Por que aprender TI",
    isVolunteer:
      form?.isVolunteer !== undefined ||
      "Você e a {reprograma} - Já fez trabalho voluntário",
    volunteerDescription:
      !!form?.volunteerDescription ||
      !form?.isVolunteer ||
      "Você e a {reprograma} - Qual trabalho voluntário",
  };

  const education = {
    ITLevel:
      !!form?.ITLevel ||
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      "Educação - Nível de conhecimento em programação",
    hasJSKnowledge:
      !!form?.hasJSKnowledge ||
      template === classVacancyTemplate.TET ||
      template === classVacancyTemplate.LEVEL_ONE ||
      "Educação - Nível de conhecimento em JS",
    difficultiesProgramming:
      !!form?.difficultiesProgramming ||
      template === classVacancyTemplate.TET ||
      template === classVacancyTemplate.LEVEL_ONE ||
      "Educação - Dificuldades de programação",
    educationLevel:
      !!form?.educationLevel || "Educação - Nível de escolaridade",
    graduatedInIT:
      form?.graduatedInIT !== undefined ||
      !form?.educationLevel?.includes("COLLEGE") ||
      "Educação - Formação em TI",
    title:
      !!form?.title ||
      !form?.graduatedInIT ||
      "Educação - Nome do curso de formação",
    englishLevel: !!form?.englishLevel || "Educação - Nível de inglês",
    // prove:
    //   !!curriculum?.certificates ||
    //   !!curriculum?.portfolios ||
    //   template !== classVacancyTemplate.EDV ||
    //   "Comprovante",
  };

  const socioEconomicProfile = {
    hasInternet:
      form?.hasInternet !== undefined ||
      "Perfil Socioeconomico - Possui internet",
    hasComputer:
      form?.hasComputer !== undefined ||
      "Perfil Socioeconomico - Possui computador",
    employabilitySituation:
      !!form?.employabilitySituation ||
      "Perfil Socioeconomico - Situação profissional",
    unemployedTime:
      !!form?.unemployedTime ||
      form?.employabilitySituation !==
        classVacancySubscriptionEmployabilitySituation.UNEMPLOYED ||
      "Perfil Socioeconomico - Tempo de desemprego",
    lastJobFunction:
      !!form?.lastJobFunction ||
      form?.employabilitySituation !==
        classVacancySubscriptionEmployabilitySituation.UNEMPLOYED ||
      "Perfil Socioeconomico - Última função no emprego",
    isEmployedInIT:
      form?.isEmployedInIT !== undefined ||
      form?.employabilitySituation ===
        classVacancySubscriptionEmployabilitySituation.UNEMPLOYED ||
      "Perfil Socioeconomico - Trabalha em TI",
    seniority:
      !!form?.seniority ||
      !form?.isEmployedInIT ||
      template === classVacancyTemplate.TET ||
      template === classVacancyTemplate.LEVEL_ONE ||
      "Perfil Socioeconomico - Trabalha em TI",
    jobFunction:
      !!form?.jobFunction ||
      form?.employabilitySituation ===
        classVacancySubscriptionEmployabilitySituation.UNEMPLOYED ||
      "Perfil Socioeconomico - Função no emprego",
    montlyRevenue:
      form?.montlyRevenue >= 0 || "Perfil Socioeconomico - Renda mensal",
    familyTotalRevenue:
      form?.familyTotalRevenue >= 0 || "Perfil Socioeconomico - Renda familiar",
    familySize:
      Number(form?.familySize) > 0 ||
      "Perfil Socioeconomico - Quantas pessoas na mesma casa",
    hasChildren:
      form?.hasChildren !== undefined ||
      "Perfil Socioeconomico - Possui filhos",
    children:
      Number(form?.children) > 0 ||
      !form?.hasChildren ||
      "Perfil Socioeconomico - Quantos filhos",
    childrenOfAge:
      form?.childrenOfAge !== undefined ||
      !form?.hasChildren ||
      "Perfil Socioeconomico - Possui filho menor de 18 anos",
    genderIdentity:
      !!profile?.genderIdentity || "Perfil Socioeconomico - Gênero",
    isLGBTQIA:
      form?.isLGBTQIA !== undefined ||
      "Perfil Socioeconomico - Grupo LGBTQIAP+",
    race: !!profile?.race || "Perfil Socioeconomico - Raça/cor/etnia",
  };

  const socioEconomicAssessment = {
    privateVehicles:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.privateVehicles ||
      "Critério Brasil - Automóveis",
    monthlyEmployees:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.monthlyEmployees ||
      "Critério Brasil - Mensalistas",
    washingMachines:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.washingMachines ||
      "Critério Brasil - Máquinas de lavar",
    bathrooms:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.bathrooms ||
      "Critério Brasil - Banheiros",
    dvdDevices:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.dvdDevices ||
      "Critério Brasil - Aparelhos de DVD",
    refrigerators:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.refrigerators ||
      "Critério Brasil - Geladeiras",
    freezers:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.freezers ||
      "Critério Brasil - Freezers",
    microcomputers:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.microcomputers ||
      "Critério Brasil - Computadores",
    dishwashers:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.dishwashers ||
      "Critério Brasil - Lavadora de louça",
    microwaves:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.microwaves ||
      "Critério Brasil - Micro-ondas",
    professionalMotorcycles:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.professionalMotorcycles ||
      "Critério Brasil - Motocicletas",
    clothesDryers:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.clothesDryers ||
      "Critério Brasil - Máquinas de lavar roupas",
    originOfWater:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.originOfWater ||
      "Critério Brasil - Proveniência da água",
    typeOfStreet:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.typeOfStreet ||
      "Critério Brasil - Tipo da rua",
    levelEducationBoss:
      (template !== classVacancyTemplate.TET &&
        template !== classVacancyTemplate.LEVEL_ONE) ||
      !!form?.levelEducationBoss ||
      "Critério Brasil - Instrução do chefe da família",
  };

  const list = {
    ...infos,
    ...address,
    ...reprograma,
    ...education,
    ...socioEconomicProfile,
    ...socioEconomicAssessment,
  };

  const errorCode = Object.keys(list)?.filter(
    (item) => typeof list[item] === "string",
  );
  const error = errorCode?.map((item) => list[item]);

  if (error?.length)
    toast.error(
      <div>
        Por favor, responda os seguintes dados:
        <br />
        {error?.map((item) => (
          <>
            {item} <br id={item} />
          </>
        ))}
      </div>,
    );

  return error;
};

export const redirectCandidate = (
  classVacancy: ClassVacancy,
  subscription: ClassVacancySubscription,
  history: any,
  redirectRegister?: () => void,
) => {
  if (classVacancy?.status === classVacancyStatus.ACTIVE) {
    const currentStageSubscription: ClassVacancySubscriptionStage | undefined =
      subscription?.stages?.find(
        (item) => item?.status === classVacancySubscriptionStatus.IN_PROCESS,
      );

    const classVacancyStage =
      classVacancy?.stages?.find(
        (item: { stage_id: string }) =>
          item?.stage_id === currentStageSubscription?.stageId,
      ) ??
      classVacancy?.stages?.find(
        (item: { stage_id: string; order: number }) => item?.order === 0,
      );

    if (classVacancyStage?.status === classVacancyStatus.ACTIVE) {
      switch (classVacancyStage?.type) {
        case classVacancyType.SUBSCRIPTION:
          if (redirectRegister) redirectRegister();
          else history.push("/");
          break;

        case classVacancyType.UPLOAD_MEDIAS:
          history.push("/course-attachment");
          break;

        case classVacancyType.EVALUATE:
          history.push("/course-evaluate");
          break;

        case classVacancyType.WORKSHOP:
          if (
            classVacancyStage?.classIds?.length === 1 &&
            !currentStageSubscription?.workshopClass
          ) {
            history.push("/course-test");
            break;
          }
          history.push("/course-workshop");
          break;

        case classVacancyType.TALENT_ACADEMY:
          history.push("/course-links");
          break;

        case classVacancyType.TEST:
          history.push("/course-links");
          break;

        case classVacancyType.INTERVIEW:
          history.push("/course-test"); // course-interview
          break;

        default: // course-status
          history.push("/course-test");
          break;
      }
    } else if (classVacancyStage?.status === classVacancyStatus.FINISHED)
      history.push("/course-empty-subscription");
    // setOpenModalClassVacancyStageClosed
    else if (
      classVacancyStage?.status === classVacancyStatus.PENDING ||
      classVacancyStage?.status === classVacancyStatus.STOPPED
    )
      history.push("/course-test"); // setOpenModalClassVacancyStageComplete
  } else history.push("/course-empty-subscription"); // setOpenModalClassVacancyClosed
};

export const redirectUsers = (user: User, history: any) => {
  const type = sessionStorage.getItem("@SESSION:type");

  if (!!user && user?._id && !!type) {
    if (
      type === "STUDENT" &&
      history?.location?.state?.from?.pathname &&
      history?.location?.state?.from?.pathname?.includes("employability")
    )
      history.push(history?.location?.state?.from);
    else if (isActiveUser(user)) {
      switch (type) {
        case "STUDENT":
          history.push(`/profile/${user._id}`);
          break;

        case "CANDIDATE":
          history.push("/course-welcome");
          break;

        case "CORPORATE":
          if (
            user?.account?.companyIds &&
            user?.account?.companyIds?.length &&
            user?.account?.companyIds[0]
          )
            history.push(`/company-profile/${user?.account?.companyIds[0]}`);
          break;

        case "ADMIN":
          history.push("/admin");
          break;

        default:
          break;
      }
    } else {
      switch (type) {
        case "STUDENT":
          history.push("/welcome");
          break;

        case "CANDIDATE":
          history.push("/course-welcome");
          break;

        case "CORPORATE":
          history.push("/welcome");
          break;

        case "ADMIN":
          history.push("/admin-account");
          break;

        default:
          break;
      }
    }
  }
};

export const region = (state: string) => {
  const NORTE = ["AC", "AM", "RR", "RO", "PA", "AP", "TO"];
  const NORDESTE = ["MA", "PI", "CE", "RN", "PB", "PE", "AL", "SE", "BA"];
  const CENTRO = ["GO", "MT", "MS", "DF"];
  const SUDESTE = ["SP", "MG", "ES", "RJ"];
  const SUL = ["RS", "PR", "SC"];

  if (SUDESTE.find((item) => item === state)) return "Sudeste";

  if (NORTE.find((item) => item === state)) return "Norte";

  if (NORDESTE.find((item) => item === state)) return "Nordeste";

  if (CENTRO.find((item) => item === state)) return "Centro-Oeste";

  if (SUL.find((item) => item === state)) return "Sul";

  return "";
};

export const delay = async (ms = 1) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const passwordGenerator = () => {
  let password = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 8; i += 1) {
    password += characters.charAt(
      Math.floor(Math.random() * characters.length),
    );
  }
  return password;
};
