/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Modal, Button } from "..";
import { Down } from "../../../assets/icons";
import "./style.scss";
import Filters from "./Tabs/filters";
import Config from "./Tabs/config";

interface Props {
  onClear?: () => void;
  onClose: () => void;
  onSearch?: () => void;
  openModal: boolean;
  textHeaderModal?: string;
  filters?: any[];
  config?: any[];
  expanded?: string;
  isDefault?: boolean;
}

const ModalConfigAndFilters = ({
  onClear,
  onClose,
  onSearch,
  openModal,
  textHeaderModal,
  filters,
  config,
  expanded,
  isDefault,
}: Props): JSX.Element => (
  <Modal
    open={openModal}
    onClose={onClose}
    maxWidth="md"
    headerAction
    textHeader={textHeaderModal}
    className="modal-filters"
  >
    <>
      {config &&
        config?.length > 0 &&
        (filters && filters?.length > 0 ? (
          <Accordion defaultExpanded={expanded === "config"}>
            <AccordionSummary
              expandIcon={<Down className="chervron-down" />}
              className={`expanded-icon "margin-bottom-accordion"}`}
            >
              <div className="width-100">
                <div className="flex-row-center">
                  <p className="p-accordion">Configurações</p>
                  <div className="divider width-100" />
                </div>
                <div className="width-100" />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Config config={config} />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Config config={config} />
        ))}
      {filters &&
        filters?.length > 0 &&
        (config && config?.length > 0 ? (
          <Accordion defaultExpanded={expanded === "filter"}>
            <AccordionSummary
              expandIcon={<Down className="chervron-down" />}
              className={`expanded-icon "margin-bottom-accordion"}`}
            >
              <div className="width-100">
                <div className="flex-row-center">
                  <p className="p-accordion">Filtros</p>
                  <div className="divider width-100" />
                </div>
                <div className="width-100" />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Filters filters={filters} />
            </AccordionDetails>
          </Accordion>
        ) : (
          <Filters filters={filters} />
        ))}
      {config?.length === 0 || !isDefault ? (
        <div className="modal-filters-buttons">
          <Button
            text="Limpar"
            onClick={onClear}
            outline
            appearance="secondary"
          />
          <Button
            onClick={onSearch}
            text={isDefault === undefined ? "Filtrar" : "Salvar"}
          />
        </div>
      ) : (
        <div className="modal-filters-buttons">
          <Button onClick={onSearch} text="CARREGAR PADRÃO" />
        </div>
      )}
    </>
  </Modal>
);

export default memo(ModalConfigAndFilters);
