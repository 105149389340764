/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from "react";
import { LogoBlack } from "../../../assets/icons";

const ErrorMobile = (): JSX.Element => (
  <div className="grid-page-error-mobile">
    <div className="div-header-error-mobile">
      <LogoBlack />
    </div>
    <p className="grid-column-3-11 grid-row-1 paragraph-error-mobile">
      Por enquanto, nossa plataforma não está disponível para tablets e
      smartphones.
    </p>
    <p className="grid-column-3-11 grid-row-2 paragraph-desktop">
      Acesse pelo seu computador para ter uma experiência melhor.
    </p>
  </div>
);

export default memo(ErrorMobile);
