import React, { memo } from "react";
import "../style.scss";
import { CardRegister } from "../../../components";

const SuccessSendSubstep = ({
  substepText,
}: {
  substepText?: string;
}): JSX.Element => (
  <CardRegister
    titleHeader="Sua inscrição"
    className="margin-top-16 margin-mobile-register border-card grid-column-5-12 grid-column-1-11-mobile"
  >
    <div className="information">
      <p className="information-title">
        Que ótimo! A subetapa de <b>{substepText}</b> foi realizada com sucesso.{" "}
        <br />
        Em breve, você receberá novas instruções.
      </p>
      <p className="information-title"> Fique ligada! ;&nbsp;)</p>
    </div>
  </CardRegister>
);
export default memo(SuccessSendSubstep);
