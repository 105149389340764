import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HeaderProfile, Layout } from "../../components";
import { RootState } from "../../store/reducers";
import { User } from "../../types/interfaces";
import Loading from "../Loading";

const Admin = (): JSX.Element => {
  const user: User = useSelector((state: RootState) => state.userState);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user?.account?.roles) setLoading(false);
  }, [user?.account?.roles]);

  return !loading ? (
    <Layout>
      <HeaderProfile />
      <div className="grid-column-2-12">
        <h4>Página inicial de admin :D</h4>
      </div>
    </Layout>
  ) : (
    <Loading />
  );
};

export default memo(Admin);
