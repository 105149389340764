export enum GeneralStatus {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  FINISHED = "FINISHED",
  STOPPED = "STOPPED",
  DISABLED = "DISABLED",
  IN_PROCESS = "IN_PROCESS",
  APPROVED = "APPROVED",
  DISAPPROVED = "DISAPPROVED",
  NONE = "NONE",
  GIVE_UP = "GIVE_UP",
  WAITING_LIST = "WAITING_LIST",
  CHANGED = "CHANGED",
  REPLACED = "REPLACED",
  BLOCKED = "BLOCKED",
}

// TODO: atualizar data-model
export enum RemoveStatus {
  NOT_INTERESTED = "NOT_INTERESTED",
  NOT_RESPOND = "NOT_RESPOND",
  ANOTHER_INITIATIVE = "ANOTHER_INITIATIVE",
  NOT_PUBLIC = "NOT_PUBLIC",
  NOT_CRITERIOUS = "NOT_CRITERIOUS",
  TIME = "TIME",
  CHANGED = "CHANGED",
  OTHERS = "OTHERS",
}

export const AdminRoles = {
  ADMIN: "Admin Geral",
  ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA: "Voluntária de mídia",
  ADMIN_CLASS_VACANCY_BOOTCAMP: "Bootcamps",
  ADMIN_CLASS_VACANCY_WORKSHOP: "Oficinas",
  ADMIN_CLASS_VACANCY: "Seleção",
  ADMIN_CLASS: "Ensino",
  ADMIN_CORPORATE: "CS",
  // ADMIN_CORPORATE_PLACEMENT_VACANCY: "CS",
};
