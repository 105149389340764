import React, { memo, useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClassVacancy } from "reprograma-types/dist/interface";
import {
  Layout,
  CardStepsCourse,
  CardRegister,
  CardSteps,
} from "../../components";
import { RootState } from "../../store/reducers";
import {
  getActiveUserClassVacancySubscription,
  getClassVacancyById,
} from "../../services/functions";
import { setCourseSubscription } from "../../store/actions/courseActions";
import Loading from "../Loading";
import { ExternalLink } from "../../../assets/customIcons";
import { ClassVacancyStages } from "../../types/interfaces";
import "./style.scss";
import { classVacancyStatus, classVacancyType } from "../../types/enumerators";
import Unsubscribe from "../../components/Unsubscribe";

const CourseLinks = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const subscription = useSelector((state: RootState) => state.courseState);
  const dispatch = useDispatch();
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();
  const [width, setWidth] = useState(window.innerWidth);
  const [activeTest, setActiveTest] = useState<
    ClassVacancyStages | undefined
  >();
  const [activeTalentAcademy, setActiveTalentAcademy] = useState<
    ClassVacancyStages | undefined
  >();

  const getSubscription = useCallback(async () => {
    const subscriptions = await getActiveUserClassVacancySubscription(user._id);
    if (subscriptions?.data?.length)
      dispatch(setCourseSubscription(subscriptions?.data?.[0]));
  }, [dispatch, user._id]);

  const getClassVacancy = useCallback(async () => {
    const response = await getClassVacancyById(subscription?.classVacancyId);
    if (response?.data) setClassVacancy(response?.data);
  }, [subscription?.classVacancyId]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  useEffect(() => {
    if (!classVacancy && !!subscription?._id) getClassVacancy();
  }, [classVacancy, subscription?._id, getClassVacancy]);

  useEffect(() => {
    if (!subscription?._id) getSubscription();
  }, [subscription?._id, getSubscription]);

  useEffect(() => {
    setActiveTest(
      classVacancy?.stages?.find(
        (item) =>
          item.type === classVacancyType.TEST &&
          item?.status === classVacancyStatus.ACTIVE,
      ),
    );
    setActiveTalentAcademy(
      classVacancy?.stages?.find(
        (item) =>
          item.type === classVacancyType.TALENT_ACADEMY &&
          item?.status === classVacancyStatus.ACTIVE,
      ),
    );
  }, [classVacancy]);

  const itemsMenu: [{ icon?: JSX.Element; text: string; id: number }] =
    classVacancy?.stages.map(({ order, title }) => ({
      text: title,
      id: order,
    }));

  const activeStage = classVacancy?.stages.find(
    (stage) => stage.status === classVacancyStatus.ACTIVE,
  )?.order;

  const steps = {
    page: 0,
    maxPageVisited: activeStage,
    maxMenuVisited: activeStage,
  };

  return classVacancy === undefined ? (
    <Loading />
  ) : (
    <Layout
      headerCourse
      exit
      className="padding-bottom-40 grid-responsive course course-links"
    >
      {width > 990 ? (
        <CardStepsCourse stages={classVacancy?.stages} />
      ) : (
        <div>
          <CardSteps
            smallScreen
            className="card-steps-register step-MuiPaper-root"
            orientation="horizontal"
            itemsMenu={itemsMenu}
            steps={steps}
          />
        </div>
      )}

      <Unsubscribe userId={user?._id} subscriptionId={subscription?._id} />

      <CardRegister
        titleHeader="Sua inscrição"
        className="margin-top-16 margin-mobile-register border-card grid-column-5-12 grid-column-1-11-mobile"
      >
        <div className="information">
          <p className="information-title">
            Olá! Aqui você acompanha sua candidatura para a turma da
            <b>
              &nbsp;
              {`{reprograma}`}
              &nbsp;
              {classVacancy?.title}.
            </b>
          </p>
          <div className="box-links">
            <p className="information-title">
              Chegou a hora de comecar a sua etapa de
              {activeTest && <b> Teste Técnino</b>}
              {activeTest && activeTalentAcademy && <> e</>}
              {activeTalentAcademy && <b> Talent Academy</b>}.
            </p>
            <p className="information-title">
              Você pode acessar pelo(s) link(s) abaixo:
            </p>
            <div className="links">
              {activeTest && (
                <p>
                  <a href={activeTest?.url ?? ""}>
                    <ExternalLink />
                    Teste técnico
                  </a>
                </p>
              )}
              {activeTalentAcademy && (
                <p>
                  <a href={activeTalentAcademy?.url ?? ""}>
                    <ExternalLink />
                    Talent Academy
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      </CardRegister>
    </Layout>
  );
};

export default memo(CourseLinks);
