/* eslint-disable no-eval */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useParams } from "react-router-dom";
import {
  HeaderProfile,
  InfosProfile,
  InfosProfileMobile,
  Layout,
  VacanciesProfile,
} from "../../../components";
import {
  getCompany,
  getSuggestionsOfVacancies,
} from "../../../services/functions";
import { RootState } from "../../../store/reducers";
import { Company, PlacementVacancy } from "../../../types/interfaces";
import { About, Initial, Medias, Vacancies } from "./Tabs";
import "../styles.scss";
import { setLoad } from "../../../store/actions/configurationsActions";

interface ParamsProps {
  id: string;
}

const CompanyProfile = (): JSX.Element => {
  const [company, setCompany] = useState<Company>();
  const [width, setWidth] = useState(window.innerWidth);
  const [pageSelected, setPageSelected] = useState("initial");
  const [vacancies, setVacancies] = useState<PlacementVacancy[] | []>();
  const user = useSelector((state: RootState) => state.userState);
  const { id } = useParams<ParamsProps>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!company) dispatch(setLoad(true) as unknown as Action);
    else dispatch(setLoad(false) as unknown as Action);
  }, [company, dispatch]);

  const loadData = useCallback(async () => {
    const response = await getCompany(id);
    if (response) setCompany(response);
  }, [id]);

  useEffect(() => {
    if (id && !company) loadData();
  }, [company, id, loadData]);

  const getVacancies = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getSuggestionsOfVacancies(user?._id ?? "");
    setVacancies(response ?? []);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, user?._id]);

  useEffect(() => {
    if (!vacancies) getVacancies();
  }, [getVacancies, user._id, vacancies]);

  useEffect(() => {
    company?.filters?.publicProfile?.forEach((item: string) => {
      if (item && item.includes("socialMedias")) {
        const strings = item.split(".");
        const type = strings[(strings?.length ?? 0) - 1];
        const index = company?.profile?.socialMedias?.findIndex(
          (innerItem: { type: string }) =>
            innerItem.type.toLowerCase() === type,
        );
        if (index !== -1)
          eval("company?.profile?.socialMedias?.splice(index, 1)");
      }
    });
  }, [company?.filters, company?.profile?.socialMedias]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-responsive">
      <HeaderProfile />
      <div className="grid-column-1-10 margin-bottom-32">
        {width > 990 ? (
          <InfosProfile
            name={company?.profile?.name ?? ""}
            avatar={company?.profile?.logo ?? ""}
            cover={company?.profile?.cover ?? ""}
            site={company?.profile?.site ?? ""}
            socialMedias={company?.profile?.socialMedias ?? []}
            type="company"
            pageSelected={pageSelected}
            setPageSelected={setPageSelected}
            localization={
              company?.profile?.location &&
              company?.profile?.location?.length > 0
                ? `${company?.profile?.location[0]?.city} - ${company?.profile?.location[0]?.province}`
                : ""
            }
          />
        ) : (
          <InfosProfileMobile
            name={company?.profile?.name ?? ""}
            avatar={company?.profile?.logo ?? ""}
            cover={company?.profile?.cover ?? ""}
            site={company?.profile?.site ?? ""}
            socialMedias={company?.profile?.socialMedias ?? []}
            type="company"
            pageSelected={pageSelected}
            setPageSelected={setPageSelected}
            localization={
              company?.profile?.location &&
              company?.profile?.location?.length > 0
                ? `${company?.profile?.location[0]?.city} - ${company?.profile?.location[0]?.province}`
                : ""
            }
          />
        )}
        {pageSelected === "initial" && (
          <Initial company={company} setPageSelected={setPageSelected} />
        )}
        {pageSelected === "about" && <About company={company} />}
        {pageSelected === "vacancy" && <Vacancies company={company} />}
        {pageSelected === "medias" && <Medias company={company} />}
      </div>
      <VacanciesProfile vacancies={vacancies ?? []} row="1" />
    </Layout>
  );
};

export default memo(CompanyProfile);
