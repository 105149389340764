import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const Unblock = ({ widthIcon, heightIcon, className }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
    className={className ?? ""}
  >
    <path
      fill="#939393"
      fillRule="evenodd"
      d="M12 3c-4.968 0-9 4.032-9 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zm-7.2 9c0 3.969 3.231 7.2 7.2 7.2 3.969 0 7.2-3.231 7.2-7.2 0-3.969-3.231-7.2-7.2-7.2-3.969 0-7.2 3.231-7.2 7.2z"
      clipRule="evenodd"
    />
    <path
      fill="#939393"
      stroke="#939393"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".343"
      d="M8.42 12.079c-.066-.067-.175-.067-.242 0l-.7.7c-.032.032-.05.075-.05.121 0 .045.018.09.05.121l2.8 2.8c.067.067.176.067.243 0l6-6c.067-.067.067-.175 0-.242l-.7-.7c-.032-.032-.076-.05-.121-.05-.046 0-.09.018-.122.05L10.4 14.058l-1.98-1.98z"
    />
  </svg>
);

export default Unblock;
