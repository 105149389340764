import React, { Dispatch, SetStateAction, memo } from "react";
import { Pagination as PaginationComponent } from "../../../components";
import "../style.scss";

interface Props {
  value: number;
  total: number;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  maxPage: number;
}

const Pagination = ({
  value,
  total,
  page,
  setPage,
  maxPage,
}: Props): JSX.Element => (
  <div className="grid-row-5 grid-column-1-9 width-100 flex margin-top--45">
    <div className="width-100">
      <p className="show-vacancies">
        Mostrando
        <span className="title-span">{value ?? 0}</span>
        de
        <span className="title-span">{total}</span>
        resultados
      </p>
    </div>
    <div className="width-100 justify-center">
      <PaginationComponent
        page={page}
        setPage={setPage}
        maxPage={maxPage}
        showAllOption
        showAnyOption
      />
    </div>
  </div>
);

export default memo(Pagination);
