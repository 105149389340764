import React, { memo, useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import "./styles.scss";
import { Input, Button, InputAutocomplete, Modal } from "..";
import {
  createUser,
  getAllCompanies,
  requestSignupLink,
  searchCompaniesAllStatus,
  setNewCompany,
} from "../../services/functions";
import { userRoles } from "../../types/enumerators";

interface Props {
  open: boolean;
  onClose: () => void;
  hideCompany?: boolean;
}

const ModalInviteCompany = ({
  open,
  onClose,
  hideCompany,
}: Props): JSX.Element => {
  const [email, setEmail] = useState<string>("");
  const [companyOptions, setCompanyOptions] = useState<string[]>();
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>("");

  const loadCompanies = useCallback(async () => {
    try {
      const companyOptionsResponse = await getAllCompanies();

      const companyOptionsMapped = companyOptionsResponse?.map(
        (option) => option?.profile?.name ?? "",
      );

      setCompanyOptions(companyOptionsMapped);
    } catch (err) {
      toast.error("Ocorreu um erro ao buscar as empresas");
    }
  }, []);

  useEffect(() => {
    if (!companyOptions) loadCompanies();
  }, [companyOptions, loadCompanies]);

  const handleChangeAutoComplete = useCallback(async (value: string | null) => {
    if (value) {
      const existingCompanies = await searchCompaniesAllStatus(value);

      if (existingCompanies?.length)
        setSelectedCompanyId(existingCompanies[0]?._id ?? "");
      else {
        const companyCreated = await setNewCompany(value);
        if (companyCreated) {
          const newCompany = await searchCompaniesAllStatus(value);
          if (newCompany?.length)
            setSelectedCompanyId(newCompany[0]?._id ?? "");
        } else toast.error("Não foi possível criar a empresa.");
      }
    }
  }, []);

  const handleEmailSend = useCallback(async () => {
    try {
      const schema = Yup.object().shape({
        email: Yup.string().required(),
      });
      await schema.validate({ email }, { abortEarly: false });

      const createCompanyUser = await createUser(
        email,
        [userRoles.CORPORATE],
        "",
        selectedCompanyId ? [selectedCompanyId] : [],
      );

      if (createCompanyUser) {
        const response = await requestSignupLink(email);
        if (response) {
          onClose();
          toast.success("E-mail enviado com sucesso!");
        } else toast.error("Falha ao enviar o e-mail.");
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) toast.error("Verifique o e-mail");
      else toast.error("Credenciais inválidas");
    }
  }, [email, selectedCompanyId, onClose]);

  return (
    <Modal
      headerAction
      open={open}
      onClose={onClose}
      textHeader="Enviar convite"
    >
      <form>
        <Input
          id="email"
          label="E-mail"
          type="text"
          value={email}
          onChange={(e: { target: { value: string } }) =>
            setEmail(e.target.value.toLowerCase().trim())
          }
          className="invite-company"
        />

        {!hideCompany && (
          <InputAutocomplete
            label="Selecione a Empresa*"
            helperText="Pressione Enter para adicionar"
            value={companyOptions}
            className="input-autocomplete-company"
            onBlur={(event) => {
              handleChangeAutoComplete(event.target.value);
            }}
            options={companyOptions ?? [""]}
            getOptionLabel={(option) => option}
          />
        )}

        <Button
          onClick={handleEmailSend}
          text="ENVIAR E-MAIL"
          className="width-100"
        />
        <p className="invite-company-button-cancel" onClick={onClose}>
          Cancelar
        </p>
      </form>
    </Modal>
  );
};

export default memo(ModalInviteCompany);
