import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import {
  AccountIcon,
  Address,
  Avatar,
  Certificate,
  Curriculum,
  EducationMini,
  Languages,
  Phone,
  Portfolio,
  ChevronUp,
} from "../../../assets/customIcons";
import {
  Button,
  Card,
  FormAddress,
  FormPersonalData,
  HeaderProfile,
  IconButton,
  Layout,
  ViewAddress,
  ViewCertificate,
  ViewEducation,
  ViewPortfolio,
} from "../../components";
import { setNextButton } from "../../store/actions/nextButtonAction";
import { RootState } from "../../store/reducers";
import {
  EditAccount,
  EditCertificate,
  EditContact,
  EditCurriculum,
  EditEducation,
  EditLanguage,
  EditPortfolio,
  ViewAccount,
  ViewContact,
  ViewCurriculum,
  ViewInfos,
  ViewLanguage,
} from "./Steps";
import "./style.scss";

const Account = (): JSX.Element => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [step, setStep] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [selected, setSelected] = useState("");
  const user = useSelector((state: RootState) => state.userState);

  const { nextButton } = useSelector(
    (state: RootState) => state.nextButtonState,
  );
  const dispatch = useDispatch();

  const itemsMenu = useMemo(
    () => [
      {
        icon: (
          <AccountIcon
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Minha conta",
        id: 1,
      },
      {
        icon: (
          <Avatar
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Dados gerais",
        id: 2,
      },
      {
        icon: (
          <Address
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Endereço",
        id: 3,
      },
      {
        icon: (
          <Phone
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Contato",
        id: 4,
      },
      {
        icon: (
          <Curriculum
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Currículo",
        id: 5,
      },
      {
        icon: (
          <EducationMini
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Formação",
        id: 6,
      },
      {
        icon: (
          <Languages
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Idiomas",
        id: 7,
      },
      {
        icon: (
          <Portfolio
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Portfólio",
        id: 8,
      },
      {
        icon: (
          <Certificate
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Certificados",
        id: 9,
      },
    ],
    [width],
  );

  const renderPages = useMemo(() => {
    switch (step) {
      case 1:
        return isEdit ? (
          <EditAccount setIsValid={setIsValid} />
        ) : (
          <ViewAccount />
        );

      case 2:
        return isEdit ? (
          <FormPersonalData setIsValid={setIsValid} />
        ) : (
          <ViewInfos />
        );

      case 3:
        return isEdit ? (
          <FormAddress setIsValid={setIsValid} is4Columns />
        ) : (
          <ViewAddress location={user?.profile?.address} />
        );

      case 4:
        return isEdit ? (
          <EditContact setIsValid={setIsValid} />
        ) : (
          <ViewContact />
        );

      case 5:
        return isEdit ? (
          <EditCurriculum selected={selected} setIsValid={setIsValid} />
        ) : (
          <ViewCurriculum selected={selected} />
        );

      case 6:
        return isEdit ? <EditEducation /> : <ViewEducation />;

      case 7:
        return isEdit ? <EditLanguage /> : <ViewLanguage />;

      case 8:
        return isEdit ? (
          <EditPortfolio />
        ) : (
          <ViewPortfolio className="padding-0" />
        );

      case 9:
        return isEdit ? <EditCertificate /> : <ViewCertificate hasIcon />;

      default:
        return <div />;
    }
  }, [isEdit, selected, step, user?.profile?.address]);

  const onClick = useCallback((e, type) => {
    e.stopPropagation();
    setSelected(type);
    setIsEdit(false);
    setIsValid(false);
  }, []);

  const renderHeader = useMemo(() => {
    if (step === 5) {
      return (
        <div className="flex-row-center margin-top-12 account">
          <button
            type="button"
            className={`account-pill ${
              selected === "general" ? "pill-selected" : ""
            }`}
            onClick={(e) => onClick(e, "general")}
          >
            Dados gerais
          </button>
          <button
            type="button"
            className={`account-pill margin-left-8 ${
              selected === "works" ? "pill-selected" : ""
            }`}
            onClick={(e) => onClick(e, "works")}
          >
            Experiências
          </button>
          <button
            type="button"
            className={`account-pill margin-left-8 ${
              selected === "skills" ? "pill-selected" : ""
            }`}
            onClick={(e) => onClick(e, "skills")}
          >
            Habilidades
          </button>
        </div>
      );
    }
    return <div />;
  }, [onClick, selected, step]);

  const onSave = useCallback(() => {
    if (nextButton !== null) {
      const funcNextButton = () => nextButton();
      funcNextButton();
      dispatch(setNextButton(null) as unknown as Action);
    }
    setIsEdit(false);
  }, [dispatch, nextButton]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-responsive-register grid-responsive margin-bottom-24-mobile account-page">
      <HeaderProfile />
      {width < 991 ? (
        <Accordion
          expanded={accordionExpanded}
          onChange={() => setAccordionExpanded(!accordionExpanded)}
          className="grid-column-1-11 account-steps-mobile"
        >
          <AccordionSummary
            expandIcon={<ChevronUp widthIcon={24} heightIcon={24} />}
            className="expanded-icon accordion-width-0-mobile"
          >
            <div>
              <div className="flex gap-8">
                <p className="margin-0">{itemsMenu[step - 1]?.icon}</p>
                <p className="p-header-mobile margin-0">
                  {itemsMenu[step - 1]?.text}
                </p>
              </div>
              {step === 5 && (
                <div className="scroll-filter">
                  <div className="margin-pills-mobile">{renderHeader}</div>
                </div>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="grid-column-1-11 width-100 grid-row-2">
              <div className="border-solid-account" />
              <div className="icon-step-account">
                {itemsMenu?.map((item) => (
                  <IconButton
                    key={Math.random()}
                    icon={item.icon}
                    text={item.text}
                    onClick={() => {
                      if (item.id === 5) setSelected("general");
                      setStep(item.id);
                      setIsEdit(false);
                      setAccordionExpanded(false);
                    }}
                    className={`steps-account ${
                      step !== item.id ? "steps-account-disabled" : ""
                    }`}
                  />
                ))}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : (
        <>
          <div className="grid-column-2-12 grid-row-1">
            <div className="account-header-student">
              <p className="account-header-student-title">Perfil &gt;&nbsp;</p>
              <p className="account-header-student-subtitle">
                {itemsMenu[step - 1]?.text}
              </p>
            </div>
          </div>
          <div className="grid-column-2-4 grid-row-2 account-student-menu">
            <Card>
              {itemsMenu?.map((item) => (
                <IconButton
                  key={Math.random()}
                  icon={item.icon}
                  text={item.text}
                  onClick={() => {
                    if (item.id === 5) setSelected("general");
                    setStep(item.id);
                    setIsEdit(false);
                  }}
                  className={`steps-account ${
                    step !== item.id ? "steps-account-disabled" : ""
                  }`}
                />
              ))}
            </Card>
          </div>
        </>
      )}
      <Card
        className={`grid-column-1-11-mobile grid-column-midia grid-row-2 media-card-account margin-account ${
          isEdit ? "border-card" : ""
        } `}
        titleHeader={`${width < 991 ? "" : itemsMenu[step - 1]?.text}`}
        childrenHeader={renderHeader}
      >
        {renderPages}
      </Card>
      {isEdit ? (
        <div className="grid-column-1-11-mobile flex grid-column-8-12 grid-row-3 margin-bottom-48 media-8-12 flex-direction-column-reverse margin-top-20-mobile">
          <Button
            text="Cancelar"
            outline
            onClick={() => setIsEdit(false)}
            appearance="secondary"
            className="width-100"
          />
          <Button
            text="Salvar"
            className="margin-left-32 margin-left-0-mobile width-100"
            onClick={onSave}
            disabled={!isValid && step < 6}
          />
        </div>
      ) : (
        <Button
          text="Editar"
          className="width-100 grid-column-1-11-mobile button-primary grid-row-3 grid-column-10-12 margin-bottom-48 margin-top-20-mobile"
          onClick={() => setIsEdit(true)}
        />
      )}
    </Layout>
  );
};

export default memo(Account);
