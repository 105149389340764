import React, { ChangeEventHandler, memo, useState, useEffect } from "react";
import { Modal } from "..";
import {
  CompanyAvatarEmpty,
  AvatarEmpty,
  Info,
} from "../../../assets/customIcons/index";
import "./styles.scss";

interface Props {
  photo?: string;
  photoSize?: number;
  type: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
}

const AttachmentPhoto = ({
  photo,
  photoSize,
  type,
  onChange,
  className,
}: Props): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const size = photoSize ?? width < 991 ? 96 : 140;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={`attachment-photo width-100 ${className || ""}`}>
        {photo ? (
          <img src={photo} alt="imagem" width={size} height={size} />
        ) : type === "foto" ? (
          <AvatarEmpty widthIcon={size} heightIcon={size} />
        ) : (
          <CompanyAvatarEmpty
            widthIcon={size}
            heightIcon={size}
            className="attachment-photo-avatar"
          />
        )}
        <input
          id="addPhoto"
          accept="image/*"
          className="display-none"
          type="file"
          multiple={false}
          onChange={onChange}
        />
        <div className="flex-row-center margin-top-12">
          <label htmlFor="addPhoto" className="attachment-photo-label">
            {photo ? `Trocar ${type}` : `Adicionar ${type}`}
          </label>
          <button
            aria-label="info"
            type="button"
            onClick={() => setOpenModal(true)}
          >
            <Info
              widthIcon={width < 991 ? 20 : 24}
              heightIcon={width < 991 ? 20 : 24}
              className="icon-info"
            />
          </button>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        headerAction
        textHeader="Tipos de imagens aceitas"
        bodyClassName="height-fit-content"
      >
        <div>
          <p className="bold dark-grey-blue margin-top-0">
            Os seguintes formatos de imagem são aceitos:
          </p>
          <p className="dark-grey-blue">
            .jpg
            <br />
            .jpeg
            <br />
            .png
            <br />
          </p>
          <p className="bold dark-grey-blue">Importante:</p>
          <p className="dark-grey-blue">
            Recomendamos que use uma imagem quadrada que se encaixe no círculo.
            <br />
            Sugerimos que use o tamanho 1280×1280 ou proporcional.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default memo(AttachmentPhoto);
