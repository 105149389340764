/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, FocusEventHandler, memo, ReactNode } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as InputSelect,
} from "@material-ui/core";
import classNames from "classnames";
import "./styles.scss";
import Checkbox from "../Checkbox";

interface Props {
  id?: string;
  label: string;
  value?: string | number | boolean | string[];
  options: {
    value?: string | number | boolean | null;
    label: string;
  }[];
  onChange?: (
    event: ChangeEvent<{
      name?: string;
      value: any;
    }>,
    child: ReactNode,
  ) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  title?: string;
  className?: string;
  disabled?: boolean;
  helperText?: string;
  hasNone?: boolean;
  appearance?: "PRIMARY" | "SECONDARY";
  multiple?: boolean;
  renderValue?: (item: string[]) => React.ReactNode;
}

const Select = ({
  id,
  label,
  value,
  options,
  onChange,
  onBlur,
  title,
  className,
  disabled,
  helperText,
  hasNone,
  appearance = "PRIMARY",
  multiple,
  renderValue,
}: Props): JSX.Element => {
  const styled = classNames(
    "select",
    className || "",
    appearance === "SECONDARY" ? "is-secondary" : "",
  );

  return (
    <div className={styled}>
      {title && <p className="select-title">{title}</p>}
      <FormControl variant="filled" className="width-100" disabled={disabled}>
        <InputLabel id={id}>{label}</InputLabel>
        <InputSelect
          labelId={id}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          multiple={multiple}
          renderValue={renderValue}
        >
          {hasNone && (
            <MenuItem key={Math.random()} value="" className="height-24" />
          )}
          {options?.map((item) => (
            <MenuItem
              key={Math.random()}
              value={item.value}
              defaultValue=""
              className="min-height-24"
            >
              {multiple && (
                <Checkbox
                  id={item?.label}
                  checked={value?.indexOf(item?.value) > -1}
                />
              )}
              {item.label}
            </MenuItem>
          ))}
        </InputSelect>
        {helperText && (
          <p
            className={`select-helper ${
              disabled ? "opacity-20" : "opacity-100"
            } `}
          >
            {helperText}
          </p>
        )}
      </FormControl>
    </div>
  );
};

export default memo(Select);
