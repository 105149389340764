import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Add = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "21"}
    height={heightIcon ?? "20"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle cx="12" cy="12" r="12" fill="#7E459B" />
    <path
      fill="#F5F6F8"
      fillRule="evenodd"
      d="M5.723 12c0 .43.348.778.777.778h11c.43 0 .778-.348.778-.778 0-.43-.349-.778-.778-.778h-11c-.43 0-.777.348-.777.778z"
      clipRule="evenodd"
    />
    <path
      fill="#F5F6F8"
      fillRule="evenodd"
      d="M12 5.723c.43 0 .778.348.778.777v11c0 .43-.348.777-.778.777-.43 0-.778-.348-.778-.777v-11c0-.43.348-.777.778-.777z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      stroke="#F5F6F8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
      d="M5.723 12c0 .43.348.778.777.778h11c.43 0 .778-.348.778-.778 0-.43-.349-.778-.778-.778h-11c-.43 0-.777.348-.777.778z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      stroke="#F5F6F8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
      d="M12 5.723c.43 0 .778.348.778.777v11c0 .43-.348.777-.778.777-.43 0-.778-.348-.778-.777v-11c0-.43.348-.777.778-.777z"
      clipRule="evenodd"
    />
  </svg>
);

export default Add;
