import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { Modal } from "../../../components";
import { Close } from "../../../../assets/customIcons";
import { Proposes as ProposesComponent } from "../Steps";
import {
  ClassVacancy,
  ClassVacancySubscription,
} from "../../../types/interfaces";
import { setLoad } from "../../../store/actions/configurationsActions";
import { getClassVacancySubscriptionById } from "../../../services/functions";
import { classVacancyType } from "../../../types/enumerators";
import "../style.scss";

interface Props {
  classVacancy?: ClassVacancy;
  subscriptionId: string;
  open: boolean;
  onclose: () => void;
  user: { name?: string };
  reload: () => void;
  isVolunteer: boolean;
}

const Proposes = ({
  classVacancy,
  subscriptionId,
  open,
  onclose,
  user,
  reload,
  isVolunteer,
}: Props): JSX.Element => {
  const [subscription, setSubscription] = useState<ClassVacancySubscription>();
  const dispatch = useDispatch();

  const getSubscription = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getClassVacancySubscriptionById(subscriptionId);
    setSubscription(response);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, subscriptionId]);

  useEffect(() => {
    if (open && !!subscriptionId && subscription?._id !== subscriptionId)
      getSubscription();
  }, [getSubscription, open, subscription, subscriptionId]);

  const onClose = useCallback(() => {
    setSubscription(undefined);
    onclose();
  }, [onclose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="admin-subscriptions-infos"
      maxWidth="md"
    >
      <div className="admin-subscriptions-infos-header">
        <div className="admin-subscriptions-infos-header-top">
          <p className="p-text-header-action-card">
            {`Notas - ${user?.name ?? ""}`}
          </p>
          <Close
            className="close-header-action-card margin-left-6 cursor-pointer"
            widthIcon="28"
            onClick={onClose}
          />
        </div>
      </div>
      <ProposesComponent
        course={classVacancy}
        subscription={subscription}
        onClose={onClose}
        reload={reload}
        onlyType={isVolunteer ? classVacancyType.UPLOAD_MEDIAS : ""}
      />
    </Modal>
  );
};

export default memo(Proposes);
