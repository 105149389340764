/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect, useState } from "react";
import { Edit } from "../../../../assets/icons";
import { Modal, ViewSocialMedias } from "../../../components";

interface Props {
  user: any;
  openModal: boolean;
  onClose: () => void;
  onEdit: () => void;
  isMyProfile: boolean;
}

const ContactSocial = ({
  user,
  openModal,
  onClose,
  onEdit,
  isMyProfile,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      headerAction
      textHeader="Redes sociais"
      open={openModal}
      onClose={onClose}
      bodyClassName="modal-profile div-info-contact-social header-edit-contacts height-fit-content"
    >
      <div className={`flex space-between ${width > 990 && "margin-top_-24"}`}>
        {user && user?.profile?.socialMedias?.length > 0 ? (
          <>
            <div>
              <ViewSocialMedias
                socialMedias={user?.profile?.socialMedias ?? []}
                showLabel
                hiden
              />
            </div>
            {isMyProfile && (
              <Edit
                onClick={onEdit}
                className={`pointer ${
                  width > 990 ? "margin-top-24" : "margin-top-5"
                } `}
              />
            )}
          </>
        ) : (
          <div>
            {isMyProfile ? (
              <p>Você não possui nenhuma rede social cadastrada.</p>
            ) : (
              <p>Não há nenhuma rede social cadastrada neste perfil.</p>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
export default memo(ContactSocial);
