/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  memo,
} from "react";
import { TextField, FilledInputProps } from "@material-ui/core";
import "./styles.scss";

interface Props {
  id?: string;
  label?: string;
  helperText?: string;
  type?: "text" | "number" | "password";
  value?: string | number;
  isGrade?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  title?: string;
  disabled?: boolean;
  className?: string;
  maxLength?: number;
  InputProps?: Partial<FilledInputProps>;
}

const Input = ({
  id,
  label,
  helperText,
  type,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  onClick,
  title,
  isGrade = false,
  disabled,
  className,
  maxLength,
  InputProps,
}: Props): JSX.Element => (
  <div className={className}>
    {title && (
      <p className={`p-input ${disabled ? "grey" : "dark-grey-blue"} `}>
        {title}
      </p>
    )}
    <TextField
      id={id}
      label={label}
      helperText={helperText}
      type={type ?? "text"}
      variant="filled"
      value={value}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      className="width-100"
      onKeyDown={onKeyDown}
      onClick={onClick}
      InputProps={{ ...InputProps, maxLength }}
      autoComplete="off"
      onWheelCapture={(e) => {
        if (isGrade) e.target.blur();
      }}
    />
  </div>
);

export default memo(Input);
