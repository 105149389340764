import React, {
  CSSProperties,
  memo,
  ReactNode,
  useCallback,
  useState,
} from "react";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
} from "@material-ui/core";
import "./styles.scss";
import { IconButton } from "..";
import { ChevronDown, ChevronDownPurple } from "../../../assets/icons";

interface Props {
  items: {
    text: string;
    onClick: (params?: unknown) => void;
    disabled?: boolean;
  }[];
  icon?: ReactNode;
  text?: string;
  className?: string;
  classNamePoper?: string;
  newPlacement?: PopperPlacementType;
  endIcon?: boolean;
  primary?: boolean;
  closeItemOnclick?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
}

const styles = {
  grow: {
    maxHeight: "320px",
    overflow: "auto",
  },
};

const ButtonMenu = ({
  items,
  icon,
  text,
  className,
  classNamePoper,
  newPlacement,
  endIcon,
  primary,
  closeItemOnclick = false,
  style,
  disabled,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [placement, setPlacement] = useState<PopperPlacementType>();

  const handleToggle = useCallback(
    (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    },
    [newPlacement, placement],
  );

  const handleClose = useCallback(() => setOpen(false), []);

  const handleListKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }, []);

  return (
    <div className={classNamePoper}>
      <Popper
        open={open}
        placement={placement}
        anchorEl={anchorEl}
        transition
        disablePortal
        className="button-menu"
        style={style}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              ...styles.grow,
              ...{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              },
              height: "fit-content",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {items?.map((item) => (
                    <MenuItem
                      key={Math.random()}
                      onClick={(params) => {
                        item?.onClick(params);
                        if (closeItemOnclick) {
                          handleClose();
                        }
                      }}
                      disabled={item?.disabled}
                      className={
                        item?.text === text ? "button-menu-selected" : ""
                      }
                    >
                      {item?.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <IconButton
        icon={
          primary ? (
            open ? (
              <ChevronDownPurple className="button-menu-chevron" />
            ) : (
              <ChevronDown className="button-menu-chevron" />
            )
          ) : (
            icon
          )
        }
        text={text}
        onClick={handleToggle}
        className={`${className ?? ""} ${
          primary && open ? "btn-menu-primary" : ""
        }`}
        endIcon={endIcon}
        disabled={disabled}
      />
    </div>
  );
};

export default memo(ButtonMenu);
