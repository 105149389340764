/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { Delete } from "../../../../assets/icons";
import {
  AttachmentMedia,
  Button,
  CardMedia,
  Draggable,
  IconButton,
  Input,
  Modal,
  ModalMedia,
} from "../../../components";
import api from "../../../services/api";
import { mediaType } from "../../../types/enumerators";
import { Media as MediaInterface } from "../../../types/interfaces";
import "./styles.scss";

interface Props {
  company: any;
  openModal: boolean;
  onClose: () => void;
  setCompany: Dispatch<SetStateAction<any>>;
  titleModal?: string;
  showModalInstruction?: boolean;
}

const Media = ({
  company,
  openModal,
  onClose,
  setCompany,
  titleModal,
  showModalInstruction = true,
}: Props): JSX.Element => {
  const [editMediaIndex, setEditMediaIndex] = useState(-1);
  const [openModalMedia, setOpenModalMedia] = useState(false);
  const [media, setMedia] = useState<MediaInterface>({});
  const [_company, _setCompany] = useState(company);

  const handleLink = useCallback(
    (e: { key: string; target: { value: string } }) => {
      if (e.key === "Enter") {
        const { profile } = _company;
        if (profile) {
          let { medias: newMedias } = profile;
          const link = {
            url: e.target.value,
            type: mediaType.LINK,
            order: newMedias?.length ?? 0,
          };

          if (newMedias?.length) newMedias.push(link);
          else newMedias = [link];

          e.target.value = "";
          _setCompany({
            ..._company,
            profile: { ...profile, medias: newMedias },
          });
        }
      }
    },
    [_company],
  );

  const handleMedia = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      try {
        await api
          .post("/fileUpload", data)
          .then((result) => {
            setOpenModalMedia(true);

            setMedia({
              ...media,
              url: result?.data?.file_url.url,
              type: fileList[0].type.includes("image")
                ? mediaType.PHOTO
                : mediaType.DOCUMENT,
              order: _company?.profile?.medias?.length ?? 0,
            });
          })
          .catch(() => {
            toast.error("Ocorreu um erro durante o upload do arquivo");
          });
      } catch (error) {
        console.warn(error);
      }
    },
    [_company?.profile?.medias?.length, media],
  );

  const handleChangeMedia = useCallback(
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) =>
      setMedia({ ...media, [prop]: event.target.value }),
    [media],
  );

  const saveMedia = useCallback(() => {
    const { profile } = _company;
    if (profile) {
      let { medias: newMedias } = profile;

      if (editMediaIndex !== -1) {
        newMedias[editMediaIndex] = media;
      } else if (newMedias?.length) newMedias.push(media);
      else newMedias = [media];

      _setCompany({
        ..._company,
        profile: { ...profile, medias: newMedias },
      });
      setMedia({});
      setEditMediaIndex(-1);
      setOpenModalMedia(false);
    }
  }, [_company, editMediaIndex, media]);

  const editMedia = useCallback((item: MediaInterface, index: number) => {
    setMedia(item);
    setEditMediaIndex(index);
    setOpenModalMedia(true);
  }, []);

  const removeMedia = useCallback(
    (index: number) => {
      const { profile } = _company;
      if (profile) {
        let { medias: oldMedias } = profile;
        oldMedias?.splice(index, 1);
        oldMedias = oldMedias?.map((item: MediaInterface, i: number) => ({
          ...item,
          order: i,
        }));
        _setCompany({
          ..._company,
          profile: { ...profile, medias: oldMedias },
        });
      }
    },
    [_company],
  );

  const closeModal = useCallback(() => {
    setOpenModalMedia(false);
    setEditMediaIndex(-1);
    setMedia({});
  }, []);

  const reorderMedias = useCallback(
    (items: MediaInterface[]) => {
      const { profile } = _company;
      if (profile) {
        let { medias: newMedias } = profile;
        newMedias = items?.map((item, index) => ({ ...item, order: index }));
        _setCompany({
          ..._company,
          profile: { ...profile, medias: newMedias },
        });
      }
    },
    [_company],
  );

  return (
    <Modal
      headerAction
      textHeader={titleModal || "Mídias"}
      open={openModal}
      onClose={onClose}
      bodyClassName="modal-profile"
    >
      <div className="grid-row-5">
        {showModalInstruction && (
          <p className="title-modal-midia">
            Adicione um link ou arquivos para documentos, fotos, vídeos e
            apresentações externas.
          </p>
        )}

        <div className="flex-row-center">
          <Input
            label="Link"
            type="text"
            helperText="Pressione Enter para adicionar"
            className="width-346 margin-right-24"
            onKeyDown={handleLink}
          />
        </div>
        <AttachmentMedia
          onChange={handleMedia}
          className="margin-top-24-only"
        />
        {_company?.profile?.medias?.filter(
          (item: { type: string }) => item.type === mediaType.LINK,
        )?.length > 0 && (
          <div className="margin-top-32">
            {_company?.profile?.medias?.map(
              (item: MediaInterface, index: number) => {
                if (item.type === mediaType.LINK) {
                  return (
                    <div className="flex-row-center" key={Math.random()}>
                      <a
                        href={
                          item?.url?.includes("http")
                            ? item?.url
                            : `http://${item?.url}`
                        }
                        className="a-class text-decoration-none"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.url}
                      </a>
                      <IconButton
                        icon={<Delete />}
                        onClick={() => removeMedia(index)}
                      />
                    </div>
                  );
                }
                return <div />;
              },
            )}
          </div>
        )}
        {_company?.profile?.medias?.filter(
          (item: { type: string }) => item.type !== mediaType.LINK,
        )?.length > 0 && (
          <Draggable
            items={_company?.profile?.medias}
            reorderItem={reorderMedias}
          >
            <div className="flex-row-center flex-wrap">
              {_company?.profile?.medias?.map(
                (item: MediaInterface, index: number) => {
                  if (item.type !== mediaType.LINK) {
                    return (
                      <DraggableComponent
                        key={Math.random()}
                        draggableId={Math.random().toString()}
                        index={index}
                        isDragDisabled={
                          _company?.profile?.medias?.filter(
                            (innerItem: { type: string }) =>
                              innerItem.type !== mediaType.LINK,
                          )?.length === 1
                        }
                      >
                        {(innerProvided) => (
                          <div
                            ref={innerProvided.innerRef}
                            {...innerProvided.draggableProps}
                            {...innerProvided.dragHandleProps}
                          >
                            <CardMedia
                              item={item}
                              onEdit={() => editMedia(item, index)}
                              onRemove={() => removeMedia(index)}
                            />
                          </div>
                        )}
                      </DraggableComponent>
                    );
                  }
                  return <div key={Math.random()} />;
                },
              )}
            </div>
          </Draggable>
        )}
      </div>
      <div className="flex-row-center margin-top-48 margin-top-20-mobile flex-end flex-col-mobile gap-16-mobile">
        <Button
          text="Cancelar"
          className="width-200 margin-right-32 margin-right-0-mobile width-100-mobile"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button
          text="Salvar"
          onClick={() => {
            onClose();
            setCompany(_company);
          }}
          className="width-200 width-100-mobile"
        />
      </div>
      <ModalMedia
        openModal={openModalMedia}
        onClose={closeModal}
        onChange={handleChangeMedia}
        medias={media}
        onSave={saveMedia}
        disabled={!media.title || !media.description}
      />
    </Modal>
  );
};

export default memo(Media);
