/* eslint-disable no-eval */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  Draggable,
  HeaderProfile,
  IconButton,
  InfosProfile,
  InfosProfileMobile,
  Layout,
  ModalEditVisibilityInfosProfile,
  NotificationResearch,
  UsersProfile,
  VacanciesProfile,
  ViewCertificate,
  ViewEducation,
  ViewPortfolio,
  ViewWork,
  NotificationResearchMobile,
} from "../../components";
import { Subjects } from "../../../assets/icons";
import {
  Infos,
  Experiences,
  AddProfile,
  ChevronUp,
  Education as EducationIcon,
  Certificates,
  PortfolioMax,
  Language,
  Delete,
  Edit,
  Move,
} from "../../../assets/customIcons";
import "./styles.scss";
import "../../../styles/index.scss";
import ButtonMenu from "../../components/ButtonMenu";
import {
  Avatar,
  Bio,
  ConfirmRemove,
  Contact,
  ContactSocial,
  GeneralData,
  Items,
} from "./Modal";
import {
  addCompanyToUser,
  getSuggestionsOfUsers,
  getSuggestionsOfVacancies,
  getUserById,
  getResearchActive,
} from "../../services/functions";
import {
  userPortfolioType,
  researchType,
  userBootcampsStatus,
} from "../../types/enumerators";
import { PlacementVacancy, User } from "../../types/interfaces";
import { RootState } from "../../store/reducers";
import { updateUser } from "../../store/actions/userActions";
import { isCorporate as isCorporateUtils } from "../../utils";
import Loading from "../Loading";

interface ParamsProps {
  id: string;
}

const Profile = (): JSX.Element => {
  const [loading, setLoading] = useState(true);
  const [openModalGeral, setOpenModalGeral] = useState(false);
  const [openModalContact, setOpenModalContact] = useState(false);
  const [openModalContactSocial, setOpenModalContactSocial] = useState(false);
  const [openModalEditInfos, setOpenModalEditInfos] = useState(false);
  const [openModalEditBio, setOpenModalEditBio] = useState(false);
  const [openModalEditItems, setOpenModalEditItems] = useState(false);
  const [openModalEditAvatar, setOpenModalEditAvatar] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [form, setForm] = useState<any[]>();
  const [width, setWidth] = useState(window.innerWidth);
  const [removed, setRemoved] = useState({
    title: "",
    text: "",
    onRemove: () => setOpenModalRemove(false),
  });
  const [indexItem, setIndexItem] = useState(-1);
  const [typeItem, setTypeItem] = useState("");
  const [typePortfolio, setTypePorfolio] = useState("");
  const [vacancies, setVacancies] = useState<PlacementVacancy[] | null>([]);
  const [users, setUsers] = useState<User[] | null>([]);

  const { id } = useParams<ParamsProps>();

  const user = useSelector((state: RootState) => state.userState);
  const isCorporate = isCorporateUtils(user);
  const isMyProfile = id === user?._id ?? false;
  const [_user, _setUser] = useState<any>(user);

  const history = useHistory();
  const dispatch = useDispatch();

  const [minWidthSkills, setMinWidthSkills] = useState(0);
  const [minWidthLanguages, setMinWidthLanguages] = useState(0);

  const refsSkills = useMemo(
    () =>
      Array.from({ length: _user?.curriculum?.skills?.length })?.map(() =>
        createRef(),
      ) as { current: { offsetWidth: number } }[],
    [_user?.curriculum?.skills?.length],
  );

  // useEffect(() => {
  //   setTimeout(() => setLoading(false), 3500);
  // }, []);

  useEffect(() => {
    if (_user?.curriculum?.skills?.length === refsSkills?.length) {
      let size = 0;
      let index = -1;
      refsSkills?.forEach((item: any, i: number) => {
        if (item?.current?.offsetWidth > size) {
          size = item?.current?.offsetWidth;
          index = i;
        }
      });
      setMinWidthSkills(refsSkills[index]?.current?.offsetWidth ?? 0);
    }
  }, [
    _user?.curriculum?.skills,
    _user?.curriculum?.skills?.length,
    refsSkills,
  ]);

  const refsLanguages = useMemo(
    () =>
      Array.from({ length: _user?.curriculum?.languages?.length }).map(() =>
        createRef(),
      ) as { current: { offsetWidth: number } }[],
    [_user?.curriculum?.languages?.length],
  );

  useEffect(() => {
    if (_user?.curriculum?.languages?.length === refsLanguages?.length) {
      let size = 0;
      let index = -1;
      refsLanguages?.forEach((item: any, i: number) => {
        if (item?.current?.offsetWidth > size) {
          size = item?.current?.offsetWidth;
          index = i;
        }
      });
      setMinWidthLanguages(refsLanguages[index]?.current?.offsetWidth ?? 0);
    }
  }, [
    _user?.curriculum?.languages,
    _user?.curriculum?.languages?.length,
    refsLanguages,
  ]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      if (isMyProfile) {
        const response = await addCompanyToUser(user);
        _setUser(response);
      } else {
        try {
          const response = await addCompanyToUser(await getUserById(id));
          _setUser(response);
        } catch (err) {
          history.goBack();
        }
      }
      setLoading(false);
    };

    if (!isMyProfile && id !== _user?._id) fetch();
    else setLoading(false);
  }, [_user?._id, history, id, isMyProfile, user]);

  useEffect(() => {
    if (!isMyProfile && _user?._id) {
      _user?.filters?.publicProfile?.forEach((item: string) => {
        if (item) {
          if (!item.includes("socialMedias")) eval(`delete _user.${item}`);
          else {
            const strings = item.split(".");
            const type = strings[(strings?.length ?? 0) - 1];
            const index = _user?.profile?.socialMedias?.findIndex(
              (innerItem: { type: string }) =>
                innerItem.type.toLowerCase() === type,
            );
            if (index !== -1)
              eval("_user?.profile?.socialMedias.splice(index, 1)");
          }
        }
      });
    }
  }, [
    _user?._id,
    _user?.filters?.publicProfile,
    _user?.profile?.socialMedias,
    isMyProfile,
  ]);

  const getVacancies = useCallback(async () => {
    const response = await getSuggestionsOfVacancies(user?._id);
    setVacancies(response);
  }, [user?._id]);

  useEffect(() => {
    if (!isCorporate) getVacancies();
  }, [getVacancies, isCorporate]);

  const getUsers = useCallback(async () => {
    if (user?.account?.companyIds && user?.account?.companyIds?.length) {
      const response = await getSuggestionsOfUsers(
        user?.account?.companyIds[0] ?? "",
      );
      setUsers(response);
    }
  }, [user?.account?.companyIds]);

  useEffect(() => {
    if (
      isCorporate &&
      user?.account?.companyIds &&
      user?.account?.companyIds?.length
    )
      getUsers();
  }, [getUsers, user?.account?.companyIds, isCorporate]);

  const reorderItem = useCallback(
    (items: any, props: string) => {
      let newItems = _user?.curriculum[props];
      newItems = items?.map((item: { order: number }, index: number) => ({
        ...item,
        order: index,
      }));

      _setUser({
        ..._user,
        curriculum: { ..._user?.curriculum, [props]: newItems },
      });

      dispatch(
        updateUser({
          ..._user,
          curriculum: { ..._user?.curriculum, [props]: newItems },
        }) as unknown as Action,
      );
    },
    [_user, dispatch],
  );

  const onRemoveItem = useCallback(
    (index: number, props: string) => {
      let newItems = _user?.curriculum[props];
      newItems?.splice(index, 1);

      if (props !== "works") {
        newItems = newItems?.map((innerItem: any, i: number) => ({
          ...innerItem,
          order: i,
        }));
      }

      _setUser({
        ..._user,
        curriculum: { ..._user?.curriculum, [props]: newItems },
      });

      dispatch(
        updateUser({
          ..._user,
          curriculum: { ..._user?.curriculum, [props]: newItems },
        }) as unknown as Action,
      );
    },
    [_user, dispatch],
  );

  const getNotificationResearch = useCallback(async () => {
    const classCode = user?.bootcamps
      ?.filter(
        (bootcamp: { status: string }) =>
          bootcamp?.status === userBootcampsStatus.FINISHED,
      )
      ?.map((item: { classId: string }) => item?.classId);

    const response = await getResearchActive(classCode);

    const forms = response
      ?.map(
        (item: { forms?: { type: string; enable?: boolean } }) => item?.forms,
      )
      ?.reduce(
        (
          acc: { type: string; enable?: boolean }[],
          cur: { type: string; enable?: boolean }[],
        ) => acc.concat(cur),
        [],
      );

    const listSearch = forms?.filter(
      (item: { type: string; enable?: boolean }) =>
        item?.type !== researchType.COMPANY && item?.enable,
    );

    setForm(listSearch);
  }, [user]);

  useEffect(() => {
    if (!form) getNotificationResearch();
  }, [form, getNotificationResearch]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Layout className="grid-responsive">
      <HeaderProfile />
      <div className="grid-column-1-10 margin-bottom-32 height-fit-content grid-row-1 relative -top-16-mobile">
        {width > 990 ? (
          <InfosProfile
            isMyProfile={isMyProfile}
            userId={_user?._id}
            name={_user?.profile?.name ?? ""}
            goal={_user?.curriculum?.goal ?? ""}
            publicProfile={_user?.filters?.publicProfile ?? []}
            avatar={_user?.profile?.photo}
            type="user"
            localization={
              _user?.profile?.address
                ? `${_user?.profile?.address?.city} - ${_user?.profile?.address?.province}`
                : ""
            }
            proposal={
              _user?.curriculum?.openForProposals
                ? "Quero participar de processos"
                : undefined
            }
            changing={
              _user?.curriculum?.openForChanges
                ? "Disponível para se mudar"
                : undefined
            }
            freelancer={
              _user?.curriculum?.openForFreelancer
                ? "Disponível para freelancer"
                : undefined
            }
            editAvatar={() => setOpenModalEditAvatar(true)}
            onEdit={() => setOpenModalEditInfos(true)}
            onClickGeneralData={() => setOpenModalGeral(true)}
            onClickContact={() => setOpenModalContact(true)}
            onClickContactSocial={() => setOpenModalContactSocial(true)}
          />
        ) : (
          <InfosProfileMobile
            isMyProfile={isMyProfile}
            userId={_user?._id}
            name={_user?.profile?.name ?? ""}
            goal={_user?.curriculum?.goal ?? ""}
            publicProfile={_user?.filters?.publicProfile ?? []}
            avatar={_user?.profile?.photo}
            type="user"
            localization={`${_user?.profile?.address?.city} - ${_user?.profile?.address?.province}`}
            proposal={
              _user?.curriculum?.openForProposals
                ? "Quero participar de processos"
                : undefined
            }
            changing={
              _user?.curriculum?.openForChanges
                ? "Disponível para se mudar"
                : undefined
            }
            freelancer={
              _user?.curriculum?.openForFreelancer
                ? "Disponível para freelancer"
                : undefined
            }
            editAvatar={() => setOpenModalEditAvatar(true)}
            onEdit={() => setOpenModalEditInfos(true)}
            onClickGeneralData={() => setOpenModalGeral(true)}
            onClickContact={() => setOpenModalContact(true)}
            onClickContactSocial={() => setOpenModalContactSocial(true)}
          />
        )}
        {_user?.profile?.bio && (
          <div className="cards-profile">
            <div className="flex-row-center title-cards">
              <Infos
                widthIcon={width < 991 ? 24 : 32}
                heightIcon={width < 991 ? 24 : 32}
                className="cards-icon-title"
              />
              <p className="cards-title-profile margin-left-10">Sobre</p>
              {isMyProfile && (
                <Edit
                  className="pointer margin-right-24 margin-right-0 "
                  onClick={() => setOpenModalEditBio(true)}
                />
              )}
            </div>
            <div className="divider-card-title" />
            <div className="div-area-bio">
              <p className="margin-0 about-paragraph">{_user?.profile?.bio}</p>
            </div>
          </div>
        )}
        {_user?.curriculum?.works && _user?.curriculum?.works?.length > 0 && (
          <Accordion defaultExpanded className="cards-profile">
            <AccordionSummary
              expandIcon={
                <ChevronUp
                  widthIcon={width < 991 ? 24 : 28}
                  heightIcon={width < 991 ? 24 : 28}
                />
              }
              className="expanded-icon"
            >
              <div className="flex-row-center width-100 margin-0-24">
                <Experiences
                  widthIcon={width < 991 ? 24 : 32}
                  heightIcon={width < 991 ? 24 : 32}
                  className="cards-icon-title"
                />
                <p className="cards-title-profile margin-left-10">
                  Experiência
                </p>
                <div>
                  {isMyProfile && (
                    <AddProfile
                      widthIcon={width < 991 ? 24 : 28}
                      heightIcon={width < 991 ? 24 : 28}
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenModalEditItems(true);
                        setTypeItem("works");
                      }}
                    />
                  )}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <ViewWork
                className="margin-right-12 padding-y-16 padding-x-24"
                onEdit={(_, index) => {
                  setIndexItem(index);
                  setTypeItem("works");
                  setOpenModalEditItems(true);
                }}
                isMyProfile={isMyProfile}
                onRemove={(item, index) => {
                  setRemoved({
                    title: "Excluir experiência",
                    text: `Tem certeza de que deseja excluir esta experiência na ${item.name}?`,
                    onRemove: () => onRemoveItem(index, "works"),
                  });
                  setOpenModalRemove(true);
                }}
                showDivider
                items={
                  isCorporate || !isMyProfile ? _user?.curriculum?.works : null
                }
              />
            </AccordionDetails>
          </Accordion>
        )}
        {_user?.curriculum?.educations &&
          _user?.curriculum?.educations?.length > 0 && (
            <Accordion defaultExpanded className="cards-profile ">
              <AccordionSummary
                expandIcon={
                  <ChevronUp
                    widthIcon={width < 991 ? 24 : 28}
                    heightIcon={width < 991 ? 24 : 28}
                  />
                }
                className="expanded-icon"
              >
                <div className="flex-row-center width-100 margin-left-24 padding-0">
                  <EducationIcon
                    widthIcon={width < 991 ? 24 : 32}
                    heightIcon={width < 991 ? 24 : 32}
                  />
                  <p className="cards-title-profile">Formação</p>
                  <div>
                    {isMyProfile && (
                      <AddProfile
                        widthIcon={width < 991 ? 24 : 28}
                        heightIcon={width < 991 ? 24 : 28}
                        onClick={(event) => {
                          event.stopPropagation();
                          setOpenModalEditItems(true);
                          setTypeItem("educations");
                        }}
                      />
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ViewEducation
                  className="margin-right-12 padding-y-16 padding-x-24"
                  isMyProfile={isMyProfile}
                  onEdit={(_, index) => {
                    setIndexItem(index);
                    setTypeItem("educations");
                    setOpenModalEditItems(true);
                  }}
                  onMove={(items) => reorderItem(items, "educations")}
                  onRemove={(item, index) => {
                    setRemoved({
                      title: "Excluir formação",
                      text: `Tem certeza de que deseja excluir esta experiência na ${item.name}?`,
                      onRemove: () => onRemoveItem(index, "educations"),
                    });
                    setOpenModalRemove(true);
                  }}
                  showDivider
                  items={
                    isCorporate || !isMyProfile
                      ? _user?.curriculum?.educations
                      : null
                  }
                />
              </AccordionDetails>
            </Accordion>
          )}
        {_user?.curriculum?.certificates &&
          _user?.curriculum?.certificates?.length > 0 && (
            <Accordion defaultExpanded className="cards-profile">
              <AccordionSummary
                expandIcon={
                  <ChevronUp
                    widthIcon={width < 991 ? 24 : 28}
                    heightIcon={width < 991 ? 24 : 28}
                  />
                }
                className="expanded-icon"
              >
                <div className="flex-row-center width-100 margin-0-24">
                  <Certificates
                    widthIcon={width < 991 ? 24 : 25}
                    heightIcon={width < 991 ? 24 : 25}
                  />
                  <p className="cards-title-profile">Certificados</p>
                  <div>
                    {isMyProfile && (
                      <AddProfile
                        widthIcon={width < 991 ? 24 : 28}
                        heightIcon={width < 991 ? 24 : 28}
                        onClick={(event) => {
                          event.stopPropagation();
                          setTypeItem("certificates");
                          setOpenModalEditItems(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <ViewCertificate
                className="padding-y-16 padding-x-24"
                isMyProfile={isMyProfile}
                onEdit={(_, index) => {
                  setIndexItem(index);
                  setTypeItem("certificates");
                  setOpenModalEditItems(true);
                }}
                onMove={(items) => reorderItem(items, "certificates")}
                onRemove={(item, index) => {
                  setRemoved({
                    title: "Excluir certificado",
                    text: `Tem certeza de que deseja excluir este certificado da ${item.name}?`,
                    onRemove: () => onRemoveItem(index, "certificates"),
                  });
                  setOpenModalRemove(true);
                }}
                showDivider
                items={
                  isCorporate || !isMyProfile
                    ? _user?.curriculum?.certificates
                    : null
                }
              />
            </Accordion>
          )}
        {_user?.curriculum?.skills && _user?.curriculum?.skills?.length > 0 && (
          <Accordion defaultExpanded className="cards-profile  ">
            <AccordionSummary
              expandIcon={
                <ChevronUp
                  widthIcon={width < 991 ? 24 : 28}
                  heightIcon={width < 991 ? 24 : 28}
                />
              }
              className="expanded-icon"
            >
              <div className="flex-row-center width-100 margin-0-24">
                <Subjects className="cards-icon-title margin-left_-5" />
                <p className="cards-title-profile">Habilidades</p>
                <div>
                  {isMyProfile && (
                    <AddProfile
                      widthIcon={width < 991 ? 24 : 28}
                      heightIcon={width < 991 ? 24 : 28}
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenModalEditItems(true);
                        setTypeItem("skills");
                      }}
                    />
                  )}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Draggable
                items={_user?.curriculum?.skills}
                reorderItem={(items) => reorderItem(items, "skills")}
              >
                <div className="divider-card-title" />
                {_user?.curriculum?.skills?.map((item: any, index: number) => (
                  <DraggableComponent
                    key={Math.random()}
                    draggableId={Math.random().toString()}
                    index={index}
                    isDragDisabled={
                      _user?.curriculum?.skills?.length < 2 || !isMyProfile
                    }
                  >
                    {(innerProvided) => (
                      <div
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                        {...innerProvided.dragHandleProps}
                      >
                        <div className="cards-items-profile flex-row-center space-between-responsivenpm ">
                          <div
                            className="margin-bottom-responsive margin-24"
                            ref={refsSkills[index]}
                            style={{ minWidth: minWidthSkills }}
                          >
                            <p className="view-bold paragraph-level">
                              {item.key}
                            </p>
                            <p className="title-view width-120 sub-paragraph-level-abiliity">
                              {item.level === 1
                                ? "Básico"
                                : item.level === 2
                                ? "Intermediário"
                                : "Avançado"}
                            </p>
                          </div>
                          <div className="flex-row-center space-knob display-none-responsive">
                            <div className="knob" />
                            <div
                              className={`knob ${
                                item.level < 2 ? "empty" : ""
                              }`}
                            />
                            <div
                              className={`knob ${
                                item.level < 3 ? "empty" : ""
                              }`}
                            />
                          </div>
                          {width < 991 && !isMyProfile && (
                            <div className="flex-row-center margin-knob space-knob">
                              <div className="knob" />
                              <div
                                className={`knob ${
                                  item.level < 2 ? "empty" : ""
                                }`}
                              />
                              <div
                                className={`knob ${
                                  item.level < 3 ? "empty" : ""
                                }`}
                              />
                            </div>
                          )}

                          {isMyProfile && (
                            <div className="flex-row-center margin-right-32">
                              <IconButton
                                icon={
                                  <Edit
                                    widthIcon={width < 991 ? 20 : 24}
                                    heightIcon={width < 991 ? 20 : 24}
                                  />
                                }
                                onClick={() => {
                                  setIndexItem(index);
                                  setTypeItem("skills");
                                  setOpenModalEditItems(true);
                                }}
                                className="margin-right-8"
                              />
                              <IconButton
                                icon={
                                  <Delete
                                    widthIcon={width < 991 ? 20 : 24}
                                    heightIcon={width < 991 ? 20 : 24}
                                  />
                                }
                                onClick={() => {
                                  setRemoved({
                                    title: "Excluir habilidade",
                                    text: `Tem certeza de que deseja excluir a habilidade ${item.key}?`,
                                    onRemove: () =>
                                      onRemoveItem(index, "skills"),
                                  });
                                  setOpenModalRemove(true);
                                }}
                              />
                              {_user?.curriculum?.skills?.length > 1 && (
                                <IconButton
                                  icon={
                                    <Move
                                      widthIcon={width < 991 ? 20 : 24}
                                      heightIcon={width < 991 ? 20 : 24}
                                    />
                                  }
                                  className="margin-left-8"
                                />
                              )}
                            </div>
                          )}
                        </div>
                        {/* {width < 991 && isMyProfile ? (
                          <div className="flex-row-center margin-20-17 space-knob">
                            <div className="knob" />
                            <div
                              className={`knob ${
                                item.level < 2 ? "empty" : ""
                              }`}
                            />
                            <div
                              className={`knob ${
                                item.level < 3 ? "empty" : ""
                              }`}
                            />
                          </div>
                        ) : (
                          ""
                        )} */}
                        {index <
                          (_user?.curriculum?.skills?.length ?? 0) - 1 && (
                          <div className="divider-card" />
                        )}
                      </div>
                    )}
                  </DraggableComponent>
                ))}
              </Draggable>
            </AccordionDetails>
          </Accordion>
        )}
        {_user?.curriculum?.portfolios &&
          _user?.curriculum?.portfolios?.length > 0 && (
            <Accordion defaultExpanded className="cards-profile">
              <AccordionSummary
                expandIcon={
                  <ChevronUp
                    widthIcon={width < 991 ? 24 : 28}
                    heightIcon={width < 991 ? 24 : 28}
                  />
                }
                className="expanded-icon"
              >
                <div className="flex-row-center width-100 margin-0-24">
                  <PortfolioMax
                    widthIcon={width < 991 ? 24 : 32}
                    heightIcon={width < 991 ? 24 : 32}
                  />
                  <p className="cards-title-profile">Portfolio</p>
                  {isMyProfile && (
                    <ButtonMenu
                      icon={
                        <AddProfile
                          widthIcon={width < 991 ? 24 : 28}
                          heightIcon={width < 991 ? 24 : 28}
                        />
                      }
                      items={[
                        {
                          text: "Link",
                          onClick: () => {
                            setTypeItem("portfolios");
                            setTypePorfolio(userPortfolioType.LINK);
                            setOpenModalEditItems(true);
                          },
                        },
                        {
                          text: "Projeto",
                          onClick: () => {
                            setTypeItem("portfolios");
                            setTypePorfolio(userPortfolioType.PROJECT);
                            setOpenModalEditItems(true);
                          },
                        },
                      ]}
                    />
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ViewPortfolio
                  className="margin-right-12 padding-y-16 padding-x-24"
                  isMyProfile={isMyProfile}
                  items={_user?.curriculum?.portfolios}
                  onEdit={(item, index) => {
                    setIndexItem(index);
                    setTypeItem("portfolios");
                    setOpenModalEditItems(true);
                  }}
                  onMove={(items) => reorderItem(items, "portfolios")}
                  onRemove={(_, index) => {
                    setRemoved({
                      title: "Excluir Portfolio",
                      text: "Tem certeza de que deseja excluir este Portfolio?",
                      onRemove: () => onRemoveItem(index, "portfolios"),
                    });
                    setOpenModalRemove(true);
                  }}
                  showDivider
                />
              </AccordionDetails>
            </Accordion>
          )}
        {_user?.curriculum?.languages &&
          _user?.curriculum?.languages?.length > 0 && (
            <Accordion
              defaultExpanded
              className="cards-profile margin-bottom-24"
            >
              <AccordionSummary
                expandIcon={
                  <ChevronUp
                    widthIcon={width < 991 ? 24 : 28}
                    heightIcon={width < 991 ? 24 : 28}
                  />
                }
                className="expanded-icon"
              >
                <div className="flex-row-center width-100 margin-0-24">
                  <Language
                    widthIcon={width < 991 ? 24 : 32}
                    heightIcon={width < 991 ? 24 : 32}
                  />
                  <p className="cards-title-profile">Idiomas</p>
                  <div>
                    {isMyProfile && (
                      <AddProfile
                        widthIcon={width < 991 ? 24 : 28}
                        heightIcon={width < 991 ? 24 : 28}
                        onClick={(event) => {
                          event.stopPropagation();
                          setTypeItem("languages");
                          setOpenModalEditItems(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Draggable
                  items={_user?.curriculum?.languages}
                  reorderItem={(items) => reorderItem(items, "languages")}
                >
                  <div className="divider-card-title" />
                  {_user?.curriculum?.languages?.map(
                    (item: any, index: number) => (
                      <DraggableComponent
                        key={Math.random()}
                        draggableId={Math.random().toString()}
                        index={index}
                        isDragDisabled={
                          _user?.curriculum?.languages?.length < 2 ||
                          !isMyProfile
                        }
                      >
                        {(innerProvided) => (
                          <div
                            ref={innerProvided.innerRef}
                            {...innerProvided.draggableProps}
                            {...innerProvided.dragHandleProps}
                          >
                            <div className="cards-items-profile flex-row-center space-between-responsivenpm ">
                              <div
                                className="margin-bottom-responsive margin-24"
                                ref={refsLanguages[index]}
                                style={{
                                  minWidth: minWidthLanguages,
                                }}
                              >
                                <p className="view-bold paragraph-level">
                                  {item.language}
                                </p>
                                <p className="title-view width-85 sub-paragraph-level">
                                  {item.proecifience === 1
                                    ? "Básico"
                                    : item.proecifience === 2
                                    ? "Intermediário"
                                    : "Avançado"}
                                </p>
                              </div>
                              <div className="flex-row-center space-knob display-none-responsive">
                                <div className="knob" />
                                <div
                                  className={`knob ${
                                    item.proecifience < 2 ? "empty" : ""
                                  }`}
                                />
                                <div
                                  className={`knob ${
                                    item.proecifience < 3 ? "empty" : ""
                                  }`}
                                />
                              </div>
                              {width < 991 && !isMyProfile && (
                                <div className="flex-row-center margin-knob space-knob">
                                  <div className="knob" />
                                  <div
                                    className={`knob ${
                                      item.level < 2 ? "empty" : ""
                                    }`}
                                  />
                                  <div
                                    className={`knob ${
                                      item.level < 3 ? "empty" : ""
                                    }`}
                                  />
                                </div>
                              )}
                              {isMyProfile && (
                                <div className="flex-row-center margin-right-32">
                                  <IconButton
                                    icon={
                                      <Edit
                                        widthIcon={width < 991 ? 20 : 24}
                                        heightIcon={width < 991 ? 20 : 24}
                                      />
                                    }
                                    onClick={() => {
                                      setIndexItem(index);
                                      setTypeItem("languages");
                                      setOpenModalEditItems(true);
                                    }}
                                    className="margin-right-8"
                                  />
                                  <IconButton
                                    icon={
                                      <Delete
                                        widthIcon={width < 991 ? 20 : 24}
                                        heightIcon={width < 991 ? 20 : 24}
                                      />
                                    }
                                    onClick={() => {
                                      setRemoved({
                                        title: "Excluir idioma",
                                        text: `Tem certeza de que deseja excluir o idioma ${item.language}?`,
                                        onRemove: () =>
                                          onRemoveItem(index, "languages"),
                                      });
                                      setOpenModalRemove(true);
                                    }}
                                  />
                                  {_user?.curriculum?.languages?.length > 1 && (
                                    <IconButton
                                      icon={
                                        <Move
                                          widthIcon={width < 991 ? 20 : 24}
                                          heightIcon={width < 991 ? 20 : 24}
                                        />
                                      }
                                      className="margin-left-8"
                                    />
                                  )}
                                </div>
                              )}
                            </div>
                            {/* {width < 991 && isMyProfile ? (
                              <div className="flex-row-center margin-20-17 space-knob">
                                <div className="knob" />
                                <div
                                  className={`knob ${
                                    item.proecifience < 2 ? "empty" : ""
                                  }`}
                                />
                                <div
                                  className={`knob ${
                                    item.proecifience < 3 ? "empty" : ""
                                  }`}
                                />
                              </div>
                            ) : (
                              ""
                            )} */}
                            {index <
                              (_user?.curriculum?.languages?.length ?? 0) -
                                1 && <div className="divider-card" />}
                          </div>
                        )}
                      </DraggableComponent>
                    ),
                  )}
                </Draggable>
              </AccordionDetails>
            </Accordion>
          )}
      </div>
      {isCorporate ? (
        <UsersProfile users={users ?? []} row="1" />
      ) : (
        <div className="grid-column-10-13">
          <div>
            <VacanciesProfile vacancies={vacancies ?? []} row="1" />
          </div>
          <div className="margin-top-32">
            {form && form?.length > 0 ? (
              width > 990 ? (
                <NotificationResearch />
              ) : (
                <NotificationResearchMobile />
              )
            ) : null}
          </div>
        </div>
      )}
      <GeneralData
        openModal={openModalGeral}
        onClose={() => setOpenModalGeral(false)}
        onEdit={() => {
          setOpenModalGeral(false);
          setOpenModalEditInfos(true);
        }}
        isMyProfile={isMyProfile}
        user={_user}
      />
      <Contact
        openModal={openModalContact}
        onClose={() => setOpenModalContact(false)}
        onEdit={() => {
          setOpenModalContact(false);
          setOpenModalEditInfos(true);
        }}
        isMyProfile={isMyProfile}
        user={_user}
      />
      <ContactSocial
        openModal={openModalContactSocial}
        onClose={() => setOpenModalContactSocial(false)}
        onEdit={() => {
          setOpenModalContactSocial(false);
          setOpenModalEditInfos(true);
        }}
        isMyProfile={isMyProfile}
        user={_user}
      />
      <ModalEditVisibilityInfosProfile
        user={_user}
        setUser={_setUser}
        openModal={openModalEditInfos}
        onClose={() => setOpenModalEditInfos(false)}
      />

      <Bio
        setUser={_setUser}
        openModal={openModalEditBio}
        onClose={() => setOpenModalEditBio(false)}
      />
      <Items
        setUser={_setUser}
        index={indexItem}
        type={typeItem}
        typePortfolio={typePortfolio}
        openModal={openModalEditItems}
        onClose={() => {
          setOpenModalEditItems(false);
          setIndexItem(-1);
          setTypeItem("");
        }}
      />
      <Avatar
        user={_user}
        setUser={_setUser}
        openModal={openModalEditAvatar}
        onClose={() => setOpenModalEditAvatar(false)}
      />
      <ConfirmRemove
        titleHeader={removed.title}
        text={removed.text}
        onClose={() => setOpenModalRemove(false)}
        onRemove={removed.onRemove}
        openModal={openModalRemove}
      />
    </Layout>
  );
};

export default memo(Profile);
