import React, { ChangeEvent, memo, MouseEventHandler } from "react";
import { Button, Modal, Input, TextArea } from "..";
import { File } from "../../../assets/icons";
import { mediaType } from "../../types/enumerators";
import { Media } from "../../types/interfaces";
import "./styles.scss";

interface Props {
  openModal: boolean;
  medias: Media;
  onChange: (
    prop: keyof Media,
  ) => (event: ChangeEvent<HTMLInputElement>) => void;
  onSave?: MouseEventHandler<HTMLButtonElement>;
  onClose: () => void;
  disabled?: boolean;
}

const ModalMedia = ({
  openModal,
  medias,
  onChange,
  onSave,
  disabled,
  onClose,
}: Props): JSX.Element => (
  <Modal
    headerAction
    textHeader="Dados do arquivo"
    open={openModal}
    onClose={onClose}
    bodyClassName="modal-media"
  >
    <div className="modal-media-container">
      {medias.type && medias.type === mediaType.PHOTO ? (
        <img
          src={medias.url}
          alt="mídia"
          width={544}
          height={360}
          className="modal-media-image"
        />
      ) : (
        <a
          className="modal-media-link"
          href={
            medias?.url?.includes("http")
              ? medias?.url
              : `http://${medias?.url}`
          }
          target="_blank"
          rel="noreferrer"
        >
          <File />
        </a>
      )}
    </div>
    <Input
      id="title"
      label="Título*"
      type="text"
      value={medias.title ?? ""}
      onChange={onChange("title")}
      className="modal-media-input"
    />
    <TextArea
      id="title"
      label="Descrição*"
      maxLength={100}
      value={medias.description ?? ""}
      onChange={onChange("description")}
      className="description-input"
    />
    <div className="flex-row-center margin-top-32 justify-end  flex-col-mobile gap-16-mobile">
      <Button
        text="Cancelar"
        outline
        className="margin-top-20-mobile width-144 width-100-mobile"
        onClick={onClose}
        appearance="secondary"
      />
      <Button
        text="Adicionar"
        className="button-primary margin-left-32 margin-left-0-mobile width-144 width-100-mobile"
        onClick={onSave}
        disabled={disabled}
      />
    </div>
  </Modal>
);

export default memo(ModalMedia);
