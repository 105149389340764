export const Target = {
  COURSE: "Quero fazer o curso",
  WORKSHOP: "Apenas a oficina",
};

export const HowToKnowReprograma = {
  ACCENTURE: "Accenture",
  BYTE_GIRLS: "Byte Girls",
  COQUE_CONECTA: "Coque Conecta",
  CREDITAS: "Creditas",
  DEVELOP_GIRLS: "Developer Girls",
  IFOOD: "iFood",
  INSTITUTO_PARAMITAS: "Instituto Paramitas",
  MANAS_DIGITAIS: "Manas Digitais",
  META: "Meta",
  NUBANK: "Nubank",
  PRETAS_NERDS: "Pretas & Nerds",
  NOTICE: "Portal de notícia",
  FACEBOOK: "Facebook",
  INSTAGRAM: "Instagram",
  LINKEDIN: "Linkedin",
  TWITTER: "Twitter",
  FRIENDS: "Amigues ou familiares",
  STUDENT: "Ex-aluna da {reprograma}",
  WOMAN: "Por outra iniciativa de mulheres em tecnologia",
  OTHER: "Outro",
};

export const CourseOfReprograma = {
  BACK_NODE: "Back-End: foco Node.Js",
  FRONT_NODE: "Front-End: foco React",
  IJS: "Imersão JavaScript",
  EDV: "EducaTech",
  PY: "Python",
  CONECTADAS: "Conectadas",
  AFRO_TECH: "Minicurso Elas em Tech: Afrotalentos",
  FRONT_TEENS: "Teens: Introdução a Front-End",
};

export const ProgramingLevel = {
  INTRODUCTORY: "Introdutório",
  BASIC: "Básico",
  INTERMEDIARY: "Intermediário",
  ADVANCED: "Avançado",
  NONE: "Nenhum",
};

export const ITLevel = {
  NONE: "Nenhum - Nunca vi, nem ouvi, só ouço falar. Não tenho nenhum conhecimento em programação ou JS.",
  INTRODUCTORY:
    "Introdutório - Compreendo o início de lógica de programação em Javascript, ou criar HTML, ou conhecimento básico em outra linguagem de programação. Sei printar um “Hello world”, realizar uma calculadora com operações básicas, etc.",
  BASIC:
    "Básico - Consigo rodar uma aplicação simples (uma página web ou uma API simples). Sei usar funções(síncronas e assíncronas), iterar por loops, usar as diversas formas de variáveis para armazenamento de dados, realizar pesquisas para aprendizado e resolução de problemas simples. Posso ter conhecimento introdutório em algum framework/biblioteca de javascript também.",
  INTERMEDIARY:
    "Intermediário - Conhecimento mais inicial em classes, heranças, paradigma funcional ou orientado a objetos. Possuo experiência de trabalho na área, compreendo um pouco sobre conhecimento sobre testes de aplicação, sei reconhecer problemas em códigos de outras pessoas e encontrar resoluções, tenho uma boa compreensão do básico e sei criar documentações.",
  ADVANCED:
    "Avançado - Tenho domínio em javascript, facilidade para aprender coisas novas relacionada a essa linguagem, consigo ler diversos códigos, explicar para outras pessoas desenvolvedoras como é o melhor processo para desenvolvimento ou refatoração de um código quando necessário. Compreendo razoavelmente (introdutório) outras linguagens de programação e sobre as necessidade básicas de outras áreas dentro do desenvolvimento (exemplo: frontend que compreende o básico de backend, de ciência de dados, etc). Sei criar aplicações com nível de complexidade mais elevados dentro da minha área de atuação.",
};

export const Justification = {
  REPLACEMENT:
    "Recolocação Profissional - retornar á área de formação ou se inserir em uma nova área profissional",
  QUALIFICATION:
    "Qualificação Profissional - adquirir conhecimento teórico e prático, habilidades e atitudes exigidos para o exercício da profissão na área tech",
  DEVELOPMENT:
    "Aperfeiçoamento Profissional - consolidar um conhecimento já adquirido na progamação para exercer novos desafios profissionais",
};

export const LevelEducation = {
  INCOMPLETE_ELEMENTARY_SCHOOL: "Ensino fundamental incompleto (1º grau)",
  COMPLETE_ELEMENTARY_SCHOOL: "Ensino fundamental completo (1º grau)",
  INCOMPLETE_HIGH_SCHOOL: "Ensino médio incompleto (2º grau)",
  COMPLETE_HIGH_SCHOOL: "Ensino médio completo (2º grau)",
  INCOMPLETE_TECHNICAL_COURSE: "Curso técnico incompleto",
  TECHNICAL_COURSE_IN_PROGRESS: "Curso técnico cursando",
  COMPLETE_TECHNICAL_COURSE: "Curso técnico completo",
  INCOMPLETE_COLLEGE_EDUCATION: "Superior incompleto",
  COLLEGE_EDUCATION_IN_PROGRESS: "Superior cursando",
  COMPLETE_COLLEGE_EDUCATION: "Superior completo",
  MBA: "MBA (completo ou incompleto)",
  POSTGRADUATE: "Pós-Graduação (completo ou incompleto)",
  MASTERS_DEGREE: "Mestrado (completo ou incompleto)",
  DOCTORATE: "Doutorado (completo ou incompleto)",
  TEACHING: "Livre Docência",
  OTHER: "Outro",
};

export const UniversityTitle = [
  "Análise e Desenvolvimento de Sistemas",
  "Banco de Dados",
  "Ciências da Computação",
  "Engenharia da Computação",
  "Engenharia de Telecomunicações",
  "Gestão da Tecnologia da Informação",
  "Informática",
  "Redes de Computadores",
  "Sistemas de Informação",
  "Outro",
];

export const EnglishLevel = {
  NONE: "Nenhum: Pode conhecer poucas palavras isoladas, mas sem conexão dialética",
  BASIC:
    "Básico: É capaz de compreender expressões e frases isoladas referentes ao cotidiano pessoal e familiar. É capaz de apresentar-se e apresentar outros, fazendo perguntas e dando respostas simples sobre o lugar onde vive, as pessoas que conhece e as coisas que tem. Se comunica de forma simples, se o interlocutor falar lentamente.",
  INTERMEDIARY:
    "Intermediário: É capaz de compreender as questões principais quando é usada uma linguagem clara e simples sobre assuntos cotidianos. É capaz de produzir discursos simples e coerentes sobre assuntos que lhe são familiares. Pode ser capaz de se comunicar com certo grau de espontaneidade com falantes nativos. É capaz de descrever experiências pessoais e justificar opiniões. Pode ser capaz de expor pontos de vista sobre temas da atualidade.",
  ADVANCED:
    "Proficiente: É capaz de compreender textos longos e exigentes, identificando significados implícitos. É capaz de se exprimir de forma eficaz e fluente sobre temas acadêmicos e profissionais, de forma clara e bem estruturada. É capaz de resumir as informações recolhidas em diversas fontes orais e escritas. ",
};

export const EmployabilitySituation = {
  CLT: "Contratada CLT",
  PJ: "Contratada PJ",
  FREELANCER: "Freelancer",
  ENTREPRENEUR: "Empreendedora",
  TREINEE: "Estagiária",
  SCHOLARSHIP: "Bolsista",
  UNEMPLOYED: "Desempregada",
};

export const VolunteerDescription = {
  PF_DONATION: "Doação para pessoas físicas",
  PJ_DONATION: "Doação para entidades/associações/campanhas",
  PJ_JOB: "Trabalho voluntário para entidades/associações/campanhas",
  OTHER: "Outro",
};

export const UnemployedTime = {
  ONE_MONTH: "Menos de um mês",
  THREE_MONTH: "De um a três meses",
  SIX_MONTH: "De três a seis meses",
  ONE_YEAR: "Um ano",
  MORE_ONE_YEAR: "Mais de um ano",
};

export const DifficultiesProgramming = {
  INSERTION_WORK: "Inserção no mercado",
  ORGANIZATION_STUDY: "Organização dos estudos, roteiro, priorização",
  APROPRIATED_LOCAL: "Falta de um local apropriado ou dispositivo",
  INSECURITY: "Insegurança",
  SUPPORT_NETWORK: "Falta de rede de apoio",
  GUIDANCE: "Falta de uma orientação",
  ACCESSIBLE_COURSE: "Cursos acessíveis",
  OVERLOADED: "Rotina sobrecarregada",
  APPLY_PRACTICE: "Colocar conhecimentos em prática",
  TIME: "Administrar o tempo",
  ANXIETY: "Ansiedade",
  SELF_MOTIVATION: "Automotivação",
  ENGLISH: "Conhecimento em inglês",
  PROGRAMMING: "Lógica de programação",
};

export const DisabledPersonDescription = {
  G80_0: "G80.0 - Paralisia cerebral quadriplégica espástica",
  G80_1: "G80.1 - Paralisia cerebral diplégica espástica",
  G80_2: "G80.2 - Paralisia cerebral hemiplégica espástica",
  G80_3: "G80.3 - Paralisia cerebral discinética",
  G80_4: "G80.4 - Paralisia cerebral atáxica",
  G80_8: "G80.8 - Outras formas de paralisia cerebral",
  G80_9: "G80.9 - Paralisia cerebral não especificada",
  G81_0: "G81.0 - Hemiplegia flácida",
  G81_1: "G81.1 - Hemiplegia espástica",
  G81_9: "G81.9 - Hemiplegia não especificada",
  G82: "G82 - Paraplegia e tetraplegia",
  G82_0: "G82.0 - Paraplegia flácida",
  G82_1: "G82.1 - Paraplegia espástica",
  G82_2: "G82.2 - Paraplegia não especificada",
  G82_3: "G82.3 - Tetraplegia flácida",
  G82_4: "G82.4 - Tetraplegia espástica",
  G82_5: "G82.5 - Tetraplegia não especificada",
  G83_1: "G83.1 - Monoplegia do membro inferior",
  G83_4: "G83.4 - Síndrome da cauda equina",
  R26_0: "R26.0 - Marcha atáxica",
  R26_1: "R26.1	Marcha paralítica",
  R26_2: "R26.2 - Dificuldade para andar não classificada em outra parte",
  G04_0: "G04.0 - Encefalite aguda disseminada",
  G04_1: "G04.1 - Paraplegia espástica tropical",
  G04_8: "G04.8 - Outras encefalites, mielites e encefalomielites",
  G04_9: "G04.9 - Encefalite, mielite e encefalomielite não especificada",
  G11_0: "G11.0 - Ataxia congênita não-progressiva",
  G11_1: "G11.1 - Ataxia cerebelar de início precoce",
  G11_2: "G11.2 - Ataxia cerebelar de início tardio",
  G11_3: "G11.3 - Ataxia cerebelar com déficit na reparação do DNA",
  G11_4: "G11.4 - Paraplegia espástica hereditária",
  G11_8: "G11.8 - Outras ataxias hereditárias",
  G11_9: "G11.9 - Ataxia hereditária não especificada",
  G20: "G20 - Doença de Parkinson",
  G30_0: "G30.0 - Doença de Alzheimer de início precoce",
  G30_1: "G30.1 - Doença de Alzheimer de início tardio",
  G30_8: "G30.8 - Outras formas de doença de Alzheimer",
  G30_9: "G30.9 - Doença de Alzheimer não especificada",
  G35: "G35 - Esclerose múltipla",
  H54_0: "H54.0 - Cegueira, ambos os olhos",
  H54_1: "H54.1 - Cegueira em um olho e visão subnormal em outro",
  H54_2: "H54.2 - Visão subnormal de ambos os olhos",
  H54_3: "H54.3 - Perda não qualificada da visão em ambos os olhos",
  H54_4: "H54.4 - Cegueira em um olho",
  H54_5: "H54.5 - Visão subnormal em um olho",
  H54_6: "H54.6 - Perda não qualificada da visão em um olho",
  H54_7: "H54.7 - Perda não especificada da visão",
  H80_0: "H80.0 - Otosclerose que compromete a janela oval, não-obliterante",
  H80_1: "H80.1 - Otosclerose que compromete a janela oval, obliterante",
  H80_2: "H80.2 - Otosclerose da cóclea",
  H80_8: "H80.8 - Outras otoscleroses",
  H80_9: "H80.9 - Otosclerose não especificada",
  H90_0: "H90.0 - Perda de audição bilateral devida a transtorno de condução",
  H90_1:
    "H90.1 - Perda de audição unilateral por transtorno de condução, sem restrição de audição contralateral",
  H90_2:
    "H90.2 - Perda não especificada de audição devida a transtorno de condução Surdez de condução SOE",
  H90_3: "H90.3 - Perda de audição bilateral neuro-sensorial",
  H90_4:
    "H90.4 - Perda de audição unilateral neuro-sensorial, sem restrição de audição contralateral",
  H90_5: "H90.5 - Perda de audição neuro-sensorial não especificada",
  H90_6:
    "H90.6 - Perda de audição bilateral mista, de condução e neuro-sensorial",
  H90_7:
    "H90.7 - Perda de audição unilateral mista, de condução e neuro-sensorial, sem restrição de audição contralateral",
  H90_8:
    "H90.8 - Perda de audição mista, de condução e neuro-sensorial, não especificada",
  H91_0: "H91.0 - Perda de audição ototóxica",
  H91_1: "H91.1 - Presbiacusia",
  H91_2: "H91.2 - Perda de audição súbita idiopática",
  H91_3: "H91.3 - Surdo-mudez não classificada em outra parte",
  H91_8: "H91.8 - Outras perdas de audição especificadas",
  H91_9: "H91.9 - Perda não especificada de audição",
  F84_0: "F84.0 - Autismo infantil",
  F84_1: "F84.1 - Autismo atípico",
  F84_5: "F84.5 - Síndrome de Asperger",
};

export const StageType = {
  SUBSCRIPTION: "Inscrição",
  UPLOAD_MEDIAS: "Áudio/Vídeo",
  WORKSHOP: "Oficina",
  INTERVIEW: "Entrevista",
  TEST: "Teste",
  TALENT_ACADEMY: "Talent Academy",
  EVALUATE: "Seleção",
};

export const CustomQuestionType = {
  SELECT: "Opção única (lista suspensa)",
  RADIO: "Opção única (sim / não)",
  // AUTOCOMPLETE: "Mútipla escolha",
  TEXT: "Aberta",
};

export const StructureOptions = {
  WORKSHOP: "Oficina",
  FOUR_WEEKS: "Quatro semanas",
  SIX_WEEKS: "Seis semanas",
  EIGHT_WEEKS: "Oito semanas",
  TWELVE_WEEKS: "Doze semanas",
  THIRTEEN_WEEKS: "Treze semanas",
  FOURTEEN_WEEKS: "Quatorze semanas",
  EIGHTEEN_WEEKS: "Dezoito semanas",
};

export const ClassVacancyTemplate = {
  EDV: "EducaTech",
  IJS: "Imersão JavaScript",
  LEVEL_ONE: "Nível 1",
};

export const ClassVacancyRestrictions = {
  MORE_SIX_MONTHS: "Ex-alunas formadas há mais de 6 meses",
};

export const NumItems = {
  NONE: "Não possui",
  ONE: "1",
  TWO: "2",
  THREE: "3",
  FOUR_MORE: "4+",
};

export const OriginOfWater = {
  GENERAL: "Rede Geral de Distribuição",
  SOURCE: "Poço ou nascente",
  OTHER: "Outro meio",
};

export const TypeOfStreet = {
  ASPHALT: "Asfaltada/Pavimentada",
  GRAVEL: "Terra/Cascalho",
};

export const GeneralStatus = {
  PENDING: "Pendente",
  ACTIVE: "Ativa",
  INACTIVE: "Inativa",
  FINISHED: "Desistente",
  STOPPED: "Pausada",
  DISABLED: "Desabilitada",
  IN_PROCESS: "Em processo",
  APPROVED: "Aprovada",
  DISAPPROVED: "Reprovada",
  WAITING_LIST: "Lista de espera",
  NONE: "Cancelada",
};

export const CourseStatus = {
  ACTIVE: "Ativa",
  APPROVED: "Aprovada",
  DISAPPROVED: "Reprovada",
  WAITING_LIST: "Lista de espera",
  NONE: "Cancelada",
  FINISHED: "Desistente",
};
