import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Vacancy = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#7E459B"
      fillRule="evenodd"
      d="M25.332 6.667H6.665C5.193 6.667 4 7.86 4 9.332v12H1.332v1.334c0 1.472 1.194 2.666 2.667 2.666h24c1.473 0 2.666-1.194 2.666-2.666v-1.334H28v-12c0-1.473-1.194-2.667-2.667-2.667zm0 14.666H6.665v-12h18.667v12z"
      clipRule="evenodd"
    />
  </svg>
);

export default Vacancy;
