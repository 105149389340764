import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  Portfolio,
  PlanIcon,
  Avatar,
  Address,
  SocialMedia,
  CompanyIcon,
  Account,
  ChevronUp,
} from "../../../assets/customIcons";
import {
  Card,
  IconButton,
  HeaderProfile,
  Layout,
  Button,
  EditSocialMedias,
  ViewSocialMedias,
  ViewAddress,
} from "../../components";
import { updateCompany } from "../../store/actions/companyActions";
import { setNextButton } from "../../store/actions/nextButtonAction";
import { RootState } from "../../store/reducers";
import {
  EditInfos,
  EditUserData,
  ViewInfos,
  ViewPlan,
  ViewUserData,
  ViewUsers,
  MainAddress,
  ViewIntegrations,
  MainIntegration,
} from "./Steps";
import "./style.scss";

const CompanyAccount = (): JSX.Element => {
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [step, setStep] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isInnerEdit, setIsInnerEdit] = useState(false);
  const company = useSelector((state: RootState) => state.companyState);

  const { nextButton } = useSelector(
    (state: RootState) => state.nextButtonState,
  );
  const dispatch = useDispatch();

  const updateSocialMedia = useCallback(
    (value) => {
      dispatch(
        setNextButton(() =>
          dispatch(
            updateCompany({
              profile: {
                ...company?.profile,
                socialMedias: value,
              },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [company?.profile, dispatch],
  );

  const itemsMenu = useMemo(
    () => [
      {
        icon: (
          <CompanyIcon
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Dados da empresa",
        id: 1,
      },
      {
        icon: (
          <SocialMedia
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Redes sociais",
        id: 2,
      },
      {
        icon: (
          <Address
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Endereço da empresa",
        id: 3,
      },
      {
        icon: (
          <Avatar
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Usuários da empresa",
        id: 4,
      },
      {
        icon: (
          <PlanIcon
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Plano",
        id: 5,
      },
      {
        icon: (
          <Account
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Seus dados",
        id: 6,
      },
      {
        icon: (
          <Portfolio
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Integrações",
        id: 7,
      },
    ],
    [width],
  );

  const renderPages = useMemo(() => {
    switch (step) {
      case 1:
        return isEdit ? <EditInfos setIsValid={setIsValid} /> : <ViewInfos />;

      case 2:
        return isEdit ? (
          <EditSocialMedias
            setIsValid={setIsValid}
            socialMedias={company?.profile?.socialMedias}
            updateSocialMedia={updateSocialMedia}
          />
        ) : (
          <div className="grid-1-column flex-row-center flex-wrap screen-small-ms display-block">
            <ViewSocialMedias socialMedias={company?.profile?.socialMedias} />
          </div>
        );

      case 4:
        return <ViewUsers />;

      case 5:
        return <ViewPlan />;

      case 6:
        return isEdit ? (
          <EditUserData setIsValid={setIsValid} />
        ) : (
          <ViewUserData />
        );

      default:
        return <div />;
    }
  }, [company?.profile?.socialMedias, isEdit, step, updateSocialMedia]);

  const onSave = useCallback(() => {
    if (nextButton !== null) {
      const funcNextButton = () => nextButton();
      funcNextButton();
      dispatch(setNextButton(null) as unknown as Action);
    }
    setIsEdit(false);
  }, [dispatch, nextButton]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-responsive-register company-account">
      <HeaderProfile />
      {width < 991 && (
        <Accordion
          expanded={accordionExpanded}
          onChange={() => {
            setAccordionExpanded(!accordionExpanded);
          }}
          className="grid-column-1-11 steps-account-mobile margin-bottom-16-mobile"
        >
          <AccordionSummary
            expandIcon={
              <ChevronUp
                widthIcon={width < 991 ? 24 : 28}
                heightIcon={width < 991 ? 24 : 28}
              />
            }
            className="expanded-icon"
          >
            <div className="display-block">
              <div className="flex-row-center width-100">
                <p className="icon-header-mobile ">
                  {itemsMenu[step - 1].icon}
                </p>
                <p className="p-header-mobile ">{itemsMenu[step - 1].text}</p>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="grid-column-1-11 width-100 grid-row-2 ">
              <div className="border-solid-account" />
              <div className=" icon-step-account">
                {itemsMenu.map((item) => (
                  <IconButton
                    icon={item.icon}
                    text={item.text}
                    onClick={() => {
                      setStep(item.id);
                      setIsEdit(false);
                      setAccordionExpanded(false);
                    }}
                    className={`steps-account ${
                      step !== item.id ? "steps-account-disabled" : ""
                    }`}
                  />
                ))}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      {width > 990 && (
        <>
          <div className="grid-column-2-5 grid-row-1 flex-row-center header-steps-company-account">
            <p className="view-page">Perfil &gt;&nbsp;</p>
            <p className="view-step">{itemsMenu[step - 1].text}</p>
          </div>
          <div className="grid-column-2-5 grid-row-2 ">
            <div className="grid-column-2-5 card-step-width grid-row-2 div-card-company">
              <Card bodyClassName="card-company-account">
                <div>
                  {itemsMenu.map((item) => (
                    <IconButton
                      icon={item.icon}
                      text={item.text}
                      onClick={() => {
                        setStep(item.id);
                        setIsEdit(false);
                      }}
                      className={`steps-account ${
                        step !== item.id ? "steps-account-disabled" : ""
                      }`}
                    />
                  ))}
                </div>
              </Card>
            </div>
          </div>
        </>
      )}

      {step !== 3 && step !== 7 && (
        <Card
          className={`grid-column-1-11-mobile media-5-14 grid-column-5-12 grid-row-2  ${
            width < 991 ? "" : "margin-left-8 "
          }${isEdit ? "border-card" : ""} `}
          titleHeader={`${width > 990 ? itemsMenu[step - 1].text : ""}`}
          bodyClassName={step === 4 ? "padding-0" : ""}
        >
          {renderPages}
        </Card>
      )}
      {step === 3 &&
        (isEdit ? (
          <MainAddress setIsEdit={setIsInnerEdit} isEdit={isInnerEdit} />
        ) : (
          <Card
            className={`grid-column-5-12 media-5-14 grid-row-2 ${
              width < 991 ? "" : "margin-left-8"
            } grid-column-1-11-mobile`}
            titleHeader={`${width > 990 ? itemsMenu[step - 1].text : ""}`}
          >
            <ViewAddress location={company?.profile?.location[0]} />
          </Card>
        ))}
      {step === 7 &&
        (isEdit ? (
          <MainIntegration setIsEdit={setIsInnerEdit} isEdit={isInnerEdit} />
        ) : (
          <Card
            className={`grid-column-1-11-mobile grid-column-5-12 media-5-14 grid-row-2 ${
              width < 991 ? "" : "margin-left-8"
            }`}
            titleHeader={`${width > 990 ? itemsMenu[step - 1].text : ""}`}
            bodyClassName="padding-0"
          >
            <ViewIntegrations />
          </Card>
        ))}
      {step !== 4 &&
        step !== 5 &&
        (isEdit ? (
          !isInnerEdit && (
            <div className="flex gap-32 flex-direction-column-reverse media-9-14 grid-column-8-12 grid-row-3 margin-bottom-48 margin-top-20-mobile">
              <Button
                text="Cancelar"
                appearance="secondary"
                className="width-100"
                onClick={() => setIsEdit(false)}
              />
              <Button
                text="Salvar"
                className="margin-left-32 margin-left-0-mobile width-100"
                onClick={onSave}
                disabled={!isValid && step !== 6 && step !== 7 && step !== 3}
              />
            </div>
          )
        ) : (
          <Button
            text="Editar"
            className="grid-column-1-11-mobile button-primary grid-row-3 grid-column-10-12 media-11-14  margin-bottom-48 margin-top-24-mobile"
            onClick={() => setIsEdit(true)}
          />
        ))}
    </Layout>
  );
};

export default memo(CompanyAccount);
