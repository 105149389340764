import React, { useEffect, useState } from "react";
import { HashRouter, withRouter } from "react-router-dom";
import Route from "./Route";
import {
  Welcome,
  Register,
  Profile,
  Account,
  CompanyAccount,
  CompanyProfile,
  CompanyRegister,
  CompanySearch,
  CompanyVacancies,
  CompanyVacancy,
  CompanyVacancyNewEdit,
  CompanyVacancyProposals,
  CompanyVacancyComments,
  CompanyVacancyMessage,
  Vacancies,
  Search,
  Errors,
  SendSignUpEmailPage,
  Candidature,
  Proposals,
  JobDescription,
  VacanciesAdvanced,
  SavedJobs,
  Invites,
  VacancyDescription,
  Notification,
  CourseAttachment,
  CourseRegister,
  CourseTest,
  CourseWelcome,
  CourseEmpty,
  CourseEvaluate,
  CourseWorkshop,
  CourseLinks,
  Admin,
  AdminAccount,
  AdminAutocomplete,
  AdminClass,
  AdminClassNewEdit,
  AdminClassVacancy,
  AdminClassVacancyNewEdit,
  AdminClassVacancyComments,
  AdminClassVacancySubscriptions,
  AdminClassView,
  AdminTeam,
  Employability,
  AllResearch,
  Login,
  AdminPlacementVacancy,
  AdminRecap,
  AdminCompanyView,
  AdminCompany,
  AdminUser,
  AdminStudent,
} from "../pages";

const Routes = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const type = sessionStorage.getItem("@SESSION:type") ?? "";

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width < 320 || (type === "ADMIN" && width < 1200) ? (
    <Errors mobile />
  ) : (
    <HashRouter basename="/" hashType="noslash">
      <Route path="/" component={Login} exact />

      <Route
        path="/send-sign-up-email"
        component={SendSignUpEmailPage}
        type={["ADMIN"]}
        isPrivate
        isActive
        exact
      />

      <Route
        path="/welcome"
        component={Welcome}
        type={["STUDENT", "CORPORATE"]}
        isPrivate
        exact
      />
      <Route
        path="/register"
        component={Register}
        type={["STUDENT"]}
        isPrivate
        exact
      />
      <Route
        path="/profile/:id"
        component={Profile}
        type={[
          "STUDENT",
          "CORPORATE",
          "ADMIN",
          "ADMIN_CLASS",
          "ADMIN_CLASS_VACANCY",
        ]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/account"
        component={Account}
        type={["STUDENT"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/vacancies"
        component={Vacancies}
        type={["STUDENT"]}
        isPrivate
        isActive
      />
      <Route
        path="/vacancies-advanced"
        component={VacanciesAdvanced}
        type={["STUDENT"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/search"
        component={Search}
        type={["STUDENT"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/candidature"
        component={Candidature}
        type={["STUDENT"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/proposals"
        component={Proposals}
        type={["STUDENT"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/job/:id"
        component={JobDescription}
        type={["STUDENT"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/saved-jobs"
        component={SavedJobs}
        type={["STUDENT"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/invites"
        component={Invites}
        type={["STUDENT"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/notification"
        component={Notification}
        type={[
          "ADMIN",
          "ADMIN_CLASS",
          "ADMIN_CLASS_VACANCY",
          "ADMIN_CLASS_VACANCY_BOOTCAMP",
          "ADMIN_CLASS_VACANCY_WORKSHOP",
          "ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA",
          "ADMIN_CORPORATE",
          "ADMIN_CORPORATE_PLACEMENT_VACANCY",
          "STUDENT",
          "CORPORATE",
          "CANDIDATE",
        ]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/vacancy-description/:id"
        component={VacancyDescription}
        type={[
          "STUDENT",
          "CORPORATE",
          "ADMIN",
          "ADMIN_CORPORATE",
          "ADMIN_CORPORATE_PLACEMENT_VACANCY",
        ]}
        isPrivate
        isActive
        exact
      />

      <Route
        path="/course-attachment"
        component={CourseAttachment}
        type={["CANDIDATE"]}
        isPrivate
        exact
      />
      <Route
        path="/course-empty-subscription"
        component={CourseEmpty}
        type={["CANDIDATE"]}
        isPrivate
        exact
      />
      <Route path="/course-empty" component={CourseEmpty} exact />
      <Route
        path="/course-register"
        component={CourseRegister}
        type={["CANDIDATE"]}
        isPrivate
        exact
      />
      <Route
        path="/course-test"
        component={CourseTest}
        type={["CANDIDATE"]}
        isPrivate
        exact
      />
      <Route
        path="/course-welcome"
        component={CourseWelcome}
        type={["CANDIDATE"]}
        isPrivate
        exact
      />

      <Route
        path="/course-evaluate"
        component={CourseEvaluate}
        type={["CANDIDATE"]}
        isPrivate
        exact
      />

      <Route
        path="/course-workshop"
        component={CourseWorkshop}
        type={["CANDIDATE"]}
        isPrivate
        exact
      />

      <Route
        path="/course-links"
        component={CourseLinks}
        type={["CANDIDATE"]}
        isPrivate
        exact
      />

      <Route
        path="/company-register"
        component={CompanyRegister}
        type={["CORPORATE"]}
        isPrivate
        exact
      />
      <Route
        path="/company-profile/:id"
        component={CompanyProfile}
        type={["STUDENT", "CORPORATE", "ADMIN"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-account"
        component={CompanyAccount}
        type={["CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-search"
        component={CompanySearch}
        type={["CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-vacancies"
        component={CompanyVacancies}
        type={["CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-vacancy-new"
        component={CompanyVacancyNewEdit}
        type={["ADMIN", "CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-vacancy-edit/:id"
        component={CompanyVacancyNewEdit}
        type={["ADMIN", "CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-vacancy-edit/:id/:page"
        component={CompanyVacancyNewEdit}
        type={["ADMIN", "CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-vacancy/:id"
        component={CompanyVacancy}
        type={["CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-vacancy-proposals/:placementVacancyId"
        component={CompanyVacancyProposals}
        type={["CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-vacancy-proposals/:placementVacancyId/:placementVacancySubscriptionId"
        component={CompanyVacancyProposals}
        type={["CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-vacancy-comments/:placementVacancyId"
        component={CompanyVacancyComments}
        type={["CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-vacancy-comments/:placementVacancyId/:placementVacancySubscriptionId"
        component={CompanyVacancyComments}
        type={["CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-vacancy-messages/:placementVacancyId"
        component={CompanyVacancyMessage}
        type={["CORPORATE"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/company-vacancy-messages/:placementVacancyId/:placementVacancySubscriptionId"
        component={CompanyVacancyMessage}
        type={["CORPORATE"]}
        isPrivate
        isActive
        exact
      />

      <Route
        path="/admin"
        component={Admin}
        type={[
          "ADMIN",
          "ADMIN_CLASS",
          "ADMIN_CLASS_VACANCY",
          "ADMIN_CLASS_VACANCY_BOOTCAMP",
          "ADMIN_CLASS_VACANCY_WORKSHOP",
          "ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA",
          "ADMIN_CORPORATE",
          "ADMIN_CORPORATE_PLACEMENT_VACANCY",
        ]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-account"
        component={AdminAccount}
        type={[
          "ADMIN",
          "ADMIN_CLASS",
          "ADMIN_CLASS_VACANCY",
          "ADMIN_CLASS_VACANCY_BOOTCAMP",
          "ADMIN_CLASS_VACANCY_WORKSHOP",
          "ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA",
          "ADMIN_CORPORATE",
          "ADMIN_CORPORATE_PLACEMENT_VACANCY",
        ]}
        isPrivate
        exact
      />
      <Route
        path="/admin-class"
        component={AdminClass}
        type={[
          "ADMIN",
          "ADMIN_CLASS",
          "ADMIN_CLASS_VACANCY",
          "ADMIN_CLASS_VACANCY_BOOTCAMP",
          "ADMIN_CLASS_VACANCY_WORKSHOP",
        ]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-class-view/:id"
        component={AdminClassView}
        type={[
          "ADMIN",
          "ADMIN_CLASS",
          "ADMIN_CLASS_VACANCY",
          "ADMIN_CLASS_VACANCY_BOOTCAMP",
          "ADMIN_CLASS_VACANCY_WORKSHOP",
        ]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-class-new"
        component={AdminClassNewEdit}
        type={["ADMIN", "ADMIN_CLASS", "ADMIN_CLASS_VACANCY"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-class-edit/:id"
        component={AdminClassNewEdit}
        type={["ADMIN", "ADMIN_CLASS", "ADMIN_CLASS_VACANCY"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-class-vacancy"
        component={AdminClassVacancy}
        type={[
          "ADMIN",
          "ADMIN_CLASS_VACANCY",
          "ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA",
        ]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-class-vacancy-new"
        component={AdminClassVacancyNewEdit}
        type={[
          "ADMIN",
          "ADMIN_CLASS_VACANCY",
          "ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA",
        ]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-class-vacancy-comments"
        component={AdminClassVacancyComments}
        type={[
          "ADMIN",
          "ADMIN_CLASS_VACANCY",
          "ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA",
        ]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-class-vacancy-edit/:id"
        component={AdminClassVacancyNewEdit}
        type={[
          "ADMIN",
          "ADMIN_CLASS_VACANCY",
          "ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA",
        ]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-class-vacancy-subscriptions/:id"
        component={AdminClassVacancySubscriptions}
        type={[
          "ADMIN",
          "ADMIN_CLASS_VACANCY",
          "ADMIN_CLASS_VACANCY_VOLUNTEER_MEDIA",
        ]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-autocomplete"
        component={AdminAutocomplete}
        type={["ADMIN"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-placement-vacancy"
        component={AdminPlacementVacancy}
        type={["ADMIN", "ADMIN_CORPORATE", "ADMIN_CORPORATE_PLACEMENT_VACANCY"]}
        isPrivate
        isActive
        exact
      />

      <Route
        path="/all-research"
        component={AllResearch}
        type={["STUDENT", "CORPORATE"]}
        isActive
        isPrivate
      />
      <Route
        path="/employability/:classId/:type"
        component={Employability}
        type={["STUDENT"]}
        isPrivate
        exact
      />
      <Route
        path="/employability/:classId/:type/:id"
        component={Employability}
        exact
      />

      <Route
        path="/admin-team"
        component={AdminTeam}
        type={["ADMIN"]}
        isPrivate
        exact
      />

      <Route
        path="/admin-recap"
        component={AdminRecap}
        type={["ADMIN"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-company-view/:_id/:placementVacancy"
        component={AdminCompanyView}
        type={["ADMIN"]}
        isPrivate
        isActive
      />
      <Route
        path="/admin-company"
        component={AdminCompany}
        type={["ADMIN"]}
        isPrivate
        exact
      />
      <Route
        path="/admin-user"
        component={AdminUser}
        type={["ADMIN"]}
        isPrivate
        isActive
        exact
      />
      <Route
        path="/admin-student"
        component={AdminStudent}
        type={["ADMIN"]}
        isPrivate
        isActive
        exact
      />
    </HashRouter>
  );
};
export default withRouter(Routes);
