import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const SocialMedia = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#2E3451"
      stroke="#2E3451"
      strokeWidth=".1"
      d="M12.855 11.817c.64-.825 1.062-1.82 1.196-2.894h4.471c.34 1.654 1.807 2.902 3.56 2.902 2.005 0 3.635-1.631 3.635-3.635 0-2.005-1.63-3.636-3.635-3.636-1.753 0-3.22 1.248-3.56 2.902H14.05c-.362-2.912-2.852-5.173-5.86-5.173-3.257 0-5.906 2.65-5.906 5.907 0 3.266 2.64 5.906 5.906 5.906 1.317 0 2.593-.437 3.628-1.242l3.332 3.334c-.638.818-1.065 1.809-1.199 2.89H9.479c-.34-1.655-1.807-2.902-3.56-2.902-2.004 0-3.635 1.63-3.635 3.635 0 2.004 1.63 3.635 3.635 3.635 1.753 0 3.22-1.248 3.56-2.902h4.472c.364 2.939 2.858 5.173 5.86 5.173 3.267 0 5.906-2.64 5.906-5.906 0-3.257-2.65-5.906-5.906-5.906-1.364 0-2.622.465-3.623 1.245l-3.333-3.333zm9.227-5.795c1.196 0 2.168.972 2.168 2.168 0 1.195-.973 2.168-2.168 2.168-1.195 0-2.168-.973-2.168-2.168 0-1.196.973-2.168 2.168-2.168zM3.752 8.19c0-2.448 1.99-4.44 4.438-4.44 2.448 0 4.439 1.992 4.439 4.44 0 1.062-.375 2.05-1.013 2.825-.254-.853-.819-1.574-1.562-2.03.286-.386.455-.862.455-1.378 0-1.28-1.04-2.32-2.319-2.32s-2.32 1.04-2.32 2.32c0 .516.17.992.457 1.378-.744.456-1.309 1.177-1.563 2.03-.638-.774-1.013-1.763-1.013-2.825zm3.586-.583c0-.47.382-.852.852-.852s.852.382.852.852-.382.852-.852.852-.852-.382-.852-.852zm-1.256 4.492c.015-1.338 1.022-2.173 2.108-2.173 1.076 0 2.093.823 2.107 2.173-1.337.72-2.928.692-4.215 0zm-.163 9.88c-1.195 0-2.168-.973-2.168-2.168 0-1.196.973-2.168 2.168-2.168 1.195 0 2.168.972 2.168 2.168 0 1.195-.973 2.168-2.168 2.168zm11.785 1.741c.014-1.337 1.02-2.173 2.107-2.173h0c1.076 0 2.093.823 2.108 2.173-1.304.702-2.895.71-4.215 0zm1.255-4.492c0-.47.383-.852.852-.852.47 0 .852.382.852.852s-.382.852-.852.852h0c-.47 0-.852-.382-.852-.852zm5.291.583c0 1.062-.375 2.05-1.012 2.825-.255-.853-.82-1.574-1.563-2.03.286-.385.456-.862.456-1.378 0-1.279-1.04-2.32-2.32-2.32-1.279 0-2.319 1.041-2.319 2.32 0 .516.17.993.456 1.378-.744.456-1.309 1.177-1.563 2.03-.638-.774-1.013-1.763-1.013-2.825 0-2.448 1.992-4.44 4.44-4.44 2.447 0 4.438 1.992 4.438 4.44z"
    />
  </svg>
);

export default SocialMedia;
