/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from "react";
import { Button, Modal } from "../../../components";
import "../styles.scss";

interface Props {
  openModal: boolean;
  onClose: () => void;
  titleHeader: string;
  text: string;
  onRemove: () => void;
}

const ConfirmRemove = ({
  openModal,
  onClose,
  titleHeader,
  text,
  onRemove,
}: Props): JSX.Element => (
  <Modal
    headerAction
    textHeader={titleHeader}
    open={openModal}
    onClose={onClose}
    bodyClassName="modal-profile height-fit-content width-min-content"
  >
    <p className="p-text">{text}</p>
    <div className="flex-row-center margin-top-48  space-between flex-direction-column-reverse">
      <Button
        text="Cancelar"
        className="width-200 width-100-responsive margin-top-20-mobile margin-bottom-20-mobile margin-right-24"
        onClick={onClose}
        outline
        appearance="secondary"
      />
      <Button
        text="Excluir"
        className="width-100-responsive width-200"
        onClick={() => {
          onRemove();
          onClose();
        }}
      />
    </div>
  </Modal>
);

export default memo(ConfirmRemove);
