/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { TableRow, TextField, InputAdornment } from "@material-ui/core";
import { Action } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Company } from "reprograma-types/dist/interface";
import { toast } from "react-toastify";
import {
  HeaderProfile,
  Layout,
  Card,
  Table,
  Button,
  ModalInviteCompany,
  Select,
  Pagination,
} from "../../components";
import { Search } from "../../../assets/icons";
import { CompanyAvatarEmpty } from "../../../assets/customIcons";
import "./style.scss";
import {
  filterCompanyUsers,
  getCompany,
  patchUser,
  getPages,
} from "../../services/functions";
import { maskZipCode } from "../../utils";
import {
  SizeTechArea,
  FinancialType,
  PlanCompanyStatus,
} from "../../types/constants/Company";
import { userStatus } from "../../types/enumerators";
import { RootState } from "../../store/reducers";
import { setLoad } from "../../store/actions/configurationsActions";

interface ParamsProps {
  _id: string;
  placementVacancy: string;
}

interface userTDO {
  _id?: string;
  business_unity?: string;
  photo?: string;
  name?: string;
  email?: string;
  tags?: string[];
  telephone?: string;
  status?: string;
}

const AdminCompanyView = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [totalFilteredList, setTotalFilteredList] = useState(0);

  const [openModalInviteCompany, setOpenModalInviteCompany] = useState(false);
  const { _id, placementVacancy } = useParams<ParamsProps>();
  const [dataUser, setDataUser] = useState<userTDO[]>();
  const [company, setCompany] = useState<Company>();
  const [filters, setFilters] = useState({
    name: "",
    status: "",
  });

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const headCells = useMemo(
    () => [
      {
        id: "photo",
        label: "",
        orderBy: false,
        asc: false,
      },
      {
        id: "name",
        label: "Nome",
        orderBy: false,
        asc: true,
      },
      {
        id: "email",
        label: "E-mail",
        orderBy: false,
        asc: false,
      },
      {
        id: "business_unity",
        label: "Unidade de negócios",
        orderBy: false,
        asc: true,
      },
      {
        id: "telephone",
        label: "Celular",
        orderBy: false,
        asc: true,
      },
      {
        id: "status",
        label: "Status",
        orderBy: false,
        asc: true,
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
        asc: false,
        classColumn: "w-160",
      },
    ],
    [],
  );

  const loadData = useCallback(async () => {
    const response = await getCompany(_id);
    if (response) setCompany(response);
  }, [_id]);

  useEffect(() => {
    if (_id && !company) loadData();
  }, [company, _id, loadData, placementVacancy]);

  const getUserCompany = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    try {
      if (pageLimit) {
        const maxPages = await getPages(filterCompanyUsers, _id, filters, true);
        setMaxPage(maxPages);

        const totalUsers = (await filterCompanyUsers(_id, filters, true)) as {
          size?: number;
        }[];
        if (totalUsers?.length) setTotalFilteredList(totalUsers[0].size ?? 0);

        const response = await filterCompanyUsers(_id, filters, false, page);
        const processedResponse = response?.map((item) => ({
          ...item,
          business_unity:
            item?.tags
              ?.find((tag: string) => tag?.startsWith("business_unity:"))
              ?.split(":")[1] ?? "",
        }));
        setDataUser(processedResponse);
      }
    } catch {
      toast.error("Ops! Houve um erro ao carregar os dados dos usuários.");
    } finally {
      dispatch(setLoad(false) as unknown as Action);
    }
  }, [_id, filters, page, pageLimit, dispatch]);

  useEffect(() => {
    if (!dataUser) getUserCompany();
  }, [dataUser, getUserCompany, company]);

  const statusUserCompany = useCallback(
    async (row, newStatus) => {
      const success = await patchUser(row._id, { "account.status": newStatus });
      getUserCompany();
      return success;
    },
    [getUserCompany],
  );

  const onAllBlocked = useCallback(
    async (row) => {
      for (let index = 0; index < row.length; index += 1) {
        if (row[index].status !== "BLOCKED")
          statusUserCompany(row[index], "BLOCKED");
      }
    },
    [statusUserCompany],
  );

  const handleChangeFilter = useCallback(
    (value, field) =>
      setFilters((prevFilters) => ({ ...prevFilters, [field]: value })),
    [setFilters],
  );

  const handleBlur = useCallback(() => getUserCompany(), [getUserCompany]);

  return (
    <Layout className="admin-company-view">
      <HeaderProfile />
      <Button
        text="Convidar"
        className="grid-column-11-13 grid-row-1 request-vacancy"
        onClick={() => setOpenModalInviteCompany(true)}
      />

      <Card className="grid-column-1-13 grid-row-2 margin-bottom-32 margin-top-20">
        <div className="space-between flex">
          <div className="width-100 flex">
            {company?.profile?.logo ? (
              <img
                src={company?.profile?.logo}
                alt="Foto"
                className="vacancies-profile-image"
              />
            ) : (
              <CompanyAvatarEmpty
                heightIcon={56}
                widthIcon={56}
                className="company-avatar-empty-card margin-bottom-16"
              />
            )}

            <div className="margin-left-24">
              <h1 className="admin-company-view-name">
                {company?.profile?.name ?? "-"}
              </h1>

              {company?.profile?.location ? (
                <div className="ladmin-company-view-city">
                  {company?.profile?.location[0]?.city} -
                  {company?.profile?.location[0]?.province}
                </div>
              ) : (
                "-"
              )}
            </div>
          </div>

          <Button
            className="admin-company-view-perfil"
            text="VER PERFIL"
            onClick={() => history.push(`/company-profile/${_id}`)}
          />
        </div>

        <div>
          <p className="admin-company-view-line">Dados gerais</p>
          <div className="flex margin-bottom-48">
            {company?.profile?.sizeTechArea ? (
              <>
                <div className="margin-right-32">
                  <div className="admin-company-view-title">
                    <p>Tamanho</p>
                  </div>
                  {company?.profile?.sizeTechArea ? (
                    <div className="admin-company-view-result">
                      <p>
                        {
                          SizeTechArea[
                            company?.profile
                              ?.sizeTechArea as keyof typeof SizeTechArea
                          ]
                        }
                      </p>
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
                <div className="margin-right-32">
                  <div className="admin-company-view-title">
                    <p>Tipo</p>
                  </div>
                  {company?.financialType ? (
                    <div className="admin-company-view-result">
                      <p>{FinancialType[company?.financialType]}</p>
                    </div>
                  ) : (
                    "-"
                  )}
                </div>
                <div>
                  <div className="admin-company-view-title">
                    <p>Vagas abertas</p>
                  </div>
                  <div className="admin-company-view-result">
                    <p>{placementVacancy}</p>
                  </div>
                </div>
              </>
            ) : (
              <div className="not-result">Não há dados</div>
            )}
          </div>
        </div>
        <div>
          <p className="admin-company-view-line">Localidades</p>
          <div className="flex">
            {company?.profile?.location ? (
              <div className="flex">
                {company?.profile?.location
                  .filter((item) => item?.businessUnity === "Matriz")
                  .map((item) => (
                    <div className="margin-right-32">
                      <div className="admin-company-view-title">
                        <p>Principal</p>
                      </div>
                      <div className="admin-company-view-result">
                        <p>
                          {item?.address}, {item?.number}
                          {item?.adjunct}
                          {` - ${item?.district} - ${item?.city} - ${
                            item?.province
                          } - ${item?.country} - CEP: ${maskZipCode(
                            item?.zipCode ?? "",
                          )}`}
                        </p>
                      </div>
                    </div>
                  ))}
                {company?.profile?.location.some(
                  (item) => item?.businessUnity !== "Matriz",
                ) &&
                  company?.profile?.location
                    .filter((item) => item?.businessUnity !== "Matriz")
                    .map((item) => (
                      <div className="margin-right-32">
                        <div className="admin-company-view-title">
                          <p>{item?.district ?? "-"}</p>
                        </div>
                        <div className="admin-company-view-result">
                          <p>
                            {item?.address ?? "-"}, {item?.number ?? "-"}{" "}
                            {item?.adjunct ?? ""}
                            {` - ${item?.district ?? "-"} - ${
                              item?.city ?? "-"
                            } - ${item?.province ?? "-"} - ${
                              item?.country ?? "-"
                            } - CEP: ${maskZipCode(item?.zipCode ?? "")}`}
                          </p>
                        </div>
                      </div>
                    ))}
              </div>
            ) : (
              <div className="not-result">Não há endereços</div>
            )}
          </div>
        </div>
      </Card>

      <Card
        className=" grid-column-1-13 grid-row-3 margin-bottom-32 margin-top-20"
        bodyClassName="padding-0"
      >
        <TableRow className="top-filters flex width-fill">
          <TextField
            autoComplete="off"
            id="searchCompany"
            placeholder="Buscar empresa"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters.name ?? ""}
            onChange={(e) => handleChangeFilter(e.target.value, "name")}
            onBlur={handleBlur}
          />
          <Select
            id="status"
            label="Status"
            value={filters?.status ?? ""}
            options={Object.keys(PlanCompanyStatus).map((key) => ({
              value: key,
              label: PlanCompanyStatus[key as keyof typeof PlanCompanyStatus],
            }))}
            className="admin-company-status "
            onChange={(e) => handleChangeFilter(e.target.value, "status")}
            onBlur={handleBlur}
          />
        </TableRow>

        <Table
          emptyText="Não há usuários cadastrados"
          headCells={headCells}
          list={dataUser}
          onActive={(row) =>
            statusUserCompany(
              row,
              row.status === userStatus.ACTIVE
                ? userStatus.DISABLED
                : userStatus.ACTIVE,
            )
          }
          onBlocked={(row) => {
            if (row.status !== "BLOCKED") statusUserCompany(row, "BLOCKED"); // TODO: Atualizar data model
          }}
          onAllBlocked={(row) => {
            onAllBlocked(row);
          }}
        />
      </Card>

      <div className="grid-row-4 grid-column-1-12  margin-top--45">
        <div className="flex">
          <p className="show-vacancies">
            Mostrando
            <span className="title-span">{dataUser?.length}</span>
            de
            <span className="title-span">{totalFilteredList}</span>
            resultados
          </p>
          <Pagination page={page} setPage={setPage} maxPage={maxPage} />
        </div>
      </div>

      <ModalInviteCompany
        open={openModalInviteCompany}
        onClose={() => setOpenModalInviteCompany(false)}
        hideCompany
      />
    </Layout>
  );
};

export default memo(AdminCompanyView);
