import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const EducationMini = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#939393"
      stroke="#939393"
      strokeWidth=".4"
      d="M22.587 8.58L12.273 4.056c-.174-.076-.372-.076-.546 0L1.413 8.58c-.55.242-.55 1.035 0 1.277l3.712 1.627v4.343C5.125 18.166 8.145 20 12 20s6.875-1.833 6.875-4.174v-4.343l.688-.302v7.428c0 .384.308.695.688.695.38 0 .687-.311.687-.695v-8.03l1.649-.723c.55-.242.55-1.035 0-1.277zM12 5.453l8.584 3.763-.68.299-7.819-.989c-.377-.047-.72.223-.767.604-.047.381.22.729.597.777l5.506.696L12 12.981 3.416 9.217 12 5.454zm5.5 10.372c0 .656-.531 1.33-1.458 1.851-2.208 1.242-5.87 1.245-8.084 0-.927-.52-1.458-1.195-1.458-1.85v-3.741l5.227 2.291c.174.077.372.077.546 0l5.227-2.291v3.74z"
    />
  </svg>
);

export default EducationMini;
