export const SizeCompany = {
  STARTUP: "Até 10 funcionários",
  SMALL: "De 11 a 200 funcionários",
  MEDIUM: "De 201 a 500 funcionários",
  BIG: "Mais de 501 funcionários",
};

export const PlanCompanyStatus = {
  PENDING: "Pendente",
  ACTIVE: "Ativo",
  FINISHED: "Encerrado",
  DISABLED: "Desativado",
};

export const FinancialType = {
  PUBLIC: "Empresa pública",
  PRIVATE: "Empresa privada",
  SOCIAL: "Negócio social",
  NONPROFIT: "Organização sem fins lucrativos",
};

export const SizeTechArea = {
  ONE_TO_TEN: "De 1 a 10 pessoas",
  ELEVEN_TO_TWENTY: "De 11 a 20 pessoas",
  TWENTY_ONE_TO_FIFTY: "De 21 a 50 pessoas",
  MORE_FIFTY: "Mais de 50 pessoas",
};
