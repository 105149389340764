import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { Button, InputPassword } from "../../../components";

interface Props {
  setData: Dispatch<SetStateAction<string>>;
  setForgot: Dispatch<SetStateAction<boolean>>;
}

const Password = ({ setData, setForgot }: Props): JSX.Element => {
  const [password, setPassword] = useState("");

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (password) setData(password);
      }
    },
    [password, setData],
  );

  return (
    <form onKeyDown={handleKeyDown} className="width-100">
      <div className="margin-bottom-32 type-access">
        <p className="title-login">
          Insira sua senha para acessar a plataforma.
        </p>
        <InputPassword
          className="input-password password-input-login"
          label="Senha"
          value={password}
          onChange={(e: { target: { value: string } }) =>
            setPassword(e.target.value)
          }
        />
        <Button
          onClick={() => setData(password)}
          text="Entrar"
          className="margin-bottom-32 width-100"
        />
        <button
          type="button"
          className="margin-bottom-32 button-borderless"
          onClick={() => setForgot(true)}
        >
          Esqueceu sua senha?
        </button>
      </div>
    </form>
  );
};

export default memo(Password);
