import React, { memo } from "react";
import "./styles.scss";

interface Props {
  text?: string;
}

const TitleCard = ({ text }: Props): JSX.Element => (
  <p className="title-card">{text}</p>
);

export default memo(TitleCard);
