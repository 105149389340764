export const CourseSubscriptionState = {
  LOGGED_IN: "Logged in",
  ACCEPT_TERMS: "Accept terms",
  FORM_DONE: "Form done",
  FORM_SUBMITTED: "Form submitted",
  FORM_REVIEW: "Form review",
  ATTACHMENT_SUBMISSION: "Attachment submission",
  ATTACHMENT_SUBMITTED: "Attachment submitted",
  WORKSHOP_CHOICE_CLASS_SUBMISSION: "Choice class submission",
  WORKSHOP_CHOICE_CLASS_SUBMITTED: "Choice class submitted",
  WORKSHOP_BEHAVIOR_SUBMISSION: "Workshop behavior submission",
  WORKSHOP_BEHAVIOR_SUBMITTED: "workshop behavior submitted",
  WORKSHOP_FORM_DIVERSITY_SUBMISSION: "Form Diversity submission",
  WORKSHOP_FORM_DIVERSITY_SUBMITTED: "Form Diversity submitted",
  WORKSHOP_TECHNICAL_FORM_SUBMISSION: "WORKSHOP_TECHNICAL_FORM_SUBMISSION",
  WORKSHOP_TECHNICAL_FORM_SUBMITTED: "WORKSHOP_TECHNICAL_FORM_SUBMITTED",
  WORKSHOP_MEDIA_SUBMISSION: "Workshop Media submission",
  WORKSHOP_MEDIA_SUBMITTED: "Workshop Media submitted",
  UNSUBSCRIBE_SUBMISSION: "Unsubscribe submission",
  UNSUBSCRIBE_SUBMITTED: "Unsubscribe submitted",
  DONE: "Done",
};
