import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const Block = ({ widthIcon, heightIcon, className }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
    className={className ?? ""}
  >
    <path
      fill="#606060"
      fillRule="evenodd"
      d="M3 12c0-4.968 4.032-9 9-9s9 4.032 9 9-4.032 9-9 9-9-4.032-9-9zm3.255 4.334C5.342 15.127 4.8 13.626 4.8 12c0-3.969 3.231-7.2 7.2-7.2 1.626 0 3.127.542 4.334 1.455l-10.08 10.08zm1.577 1.534C9.01 18.706 10.448 19.2 12 19.2c3.969 0 7.2-3.231 7.2-7.2 0-1.552-.494-2.99-1.332-4.168L7.832 17.868z"
      clipRule="evenodd"
    />
  </svg>
);

export default Block;
