import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const InvitesNew = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#939393"
      stroke="#939393"
      strokeWidth=".5"
      d="M19.794 5.648H6.956c-1.217 0-2.207.99-2.207 2.207v3.188H2.632c-.35 0-.632.283-.632.632 0 .349.283.632.632.632h2.117v1.696H3.693c-.35 0-.632.283-.632.632 0 .349.283.632.632.632h1.056v.825c0 1.216.99 2.206 2.207 2.206h12.838c1.216 0 2.206-.99 2.206-2.206V7.855c0-1.217-.99-2.207-2.206-2.207zM6.956 6.912h12.838c.224 0 .43.079.592.21l-6.132 4.5c-.518.38-1.24.38-1.758 0l-6.132-4.5c.162-.131.368-.21.592-.21zm13.78 9.18c0 .52-.422.943-.942.943H6.956c-.52 0-.943-.423-.943-.943v-.825h2.379c.349 0 .632-.283.632-.632 0-.349-.283-.632-.632-.632h-2.38V8.431l5.736 4.209c.479.351 1.053.527 1.627.527.573 0 1.148-.176 1.626-.527l5.736-4.209v7.66h0z"
    />
    <circle cx="20" cy="6" r="4" fill="#D30000" />
  </svg>
);

export default InvitesNew;
