/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { Action } from "redux";
import {
  Bookmark,
  CompanyAvatarEmpty,
  AddressCompanyProfile,
} from "../../../assets/customIcons";
import { VacancyExternal } from "../../../assets/icons";
import {
  createAnalytics,
  isSavedThisVacancy,
  isSubscriptedThisVacancy,
  saveAVacancy,
  unsaveAVacancy,
  unsubscriptInVacancy,
} from "../../services/functions";
import { PlacementVacancyWithCorporateData } from "../../types/interfaces";
import { getTextDiffDate } from "../../utils";
import { ManagedBy } from "../../types/constants/Vacancy";
import {
  placementVacancyManagedBy,
  placementVacancyStatus,
  placementVacancyType,
  placementVacancyTypeOfCandidature,
} from "../../types/enumerators";
import { RootState } from "../../store/reducers";
import GreenhouseLogo from "../../../assets/images/Greenhouse.png";
import GupyLogo from "../../../assets/images/Gupy.png";
import { ModalConfirmCandidate } from "../../components";
import "./style.scss";
import { setLoad } from "../../store/actions/configurationsActions";

interface Props {
  data?: PlacementVacancyWithCorporateData;
  columnDescription?: boolean;
}

const CardDescription = ({ data, columnDescription }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();
  const user = useSelector((state: RootState) => state.userState);
  const [isCandidate, setIsCandidate] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [openModalCandidate, setOpenModalCandidate] = useState(false);
  const dispatch = useDispatch();

  const checkCandidateAndSaved = useCallback(async () => {
    const candidate = await isSubscriptedThisVacancy(
      data?._id ?? "",
      user?._id,
    );
    const saved = await isSavedThisVacancy(data?._id ?? "", user?._id);

    setIsCandidate(candidate);
    setIsSaved(saved);
  }, [data?._id, user?._id]);

  useEffect(() => {
    checkCandidateAndSaved();
  }, [checkCandidateAndSaved]);

  const handleSaveVacancy = useCallback(
    async (e) => {
      e.stopPropagation();
      dispatch(setLoad(true) as unknown as Action);
      try {
        if (!isSaved) {
          await saveAVacancy(data?._id ?? "", user?._id);
          setIsSaved(true);
          toast.success("Vaga salva com sucesso!");
        } else {
          await unsaveAVacancy(data?._id ?? "", user?._id);
          setIsSaved(false);
        }
      } catch (err) {
        toast.error("Ocorreu um erro ao salvar a vaga");
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, isSaved, data?._id, user?._id],
  );

  const handleUncandidateClick = useCallback(async () => {
    try {
      await unsubscriptInVacancy(data?._id ?? "", user?._id);
      if (data?.managedBy !== placementVacancyManagedBy.REPROGRAMA) {
        toast.success(
          "Atenção, sua candidatura na Plataforma parceira pode não ter sido cancelada. É necessário verificar esta informação diretamente com a empresa",
        );
      }
      setIsCandidate(false);
    } catch (err) {
      console.warn(err);
      toast.error("Ocorreu um erro ao se descandidatar");
    }
  }, [data?._id, data?.managedBy, user?._id]);

  const onCandidate = useCallback(
    (event) => {
      event.stopPropagation();
      if (data?.status === placementVacancyStatus.PENDING)
        toast.error("Ops! Esta vaga ainda não está recebendo candidaturas.");
      else if (!isCandidate) setOpenModalCandidate(true);
      else handleUncandidateClick();
    },
    [data?.status, handleUncandidateClick, isCandidate],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const labelSkilllevel = useCallback((level) => {
    switch (level) {
      case 1:
        return "básico";
      case 2:
        return "intermediário";
      case 3:
        return "avançado";
      default:
        return "";
    }
  }, []);

  const onClickVacancy = useCallback(
    async () =>
      createAnalytics({
        userId: user?._id,
        type: "PLACEMENT_EXTERNAL_VACANCY",
        entityId: data?._id ?? "",
        date: moment().tz("America/Sao_Paulo").toISOString(),
      }),
    [user?._id, data?._id],
  );

  return (
    <div
      className={`${
        columnDescription ? "column-2-13" : ""
      } vacancies-card height-fit-content margin-bottom-32`}
    >
      <div className="vacancies-card-content">
        <div
          className="pointer"
          role="button"
          tabIndex={0}
          onClick={() => history.push(`/job/${data?._id}`)}
        >
          <div className="flex width-100">
            <p className="vacancies-card-title"> {data?.title}</p>
          </div>
          <div className="vacancies-card-description">
            {width > 990 &&
              data?.typeOfCandidature ===
                placementVacancyTypeOfCandidature.OPEN && (
                <button
                  type="button"
                  className={`vacancies-card-button
                 ${isCandidate ? "is-active" : ""} ${
                    data?.status === placementVacancyStatus.ACTIVE
                      ? "cursor-pointer"
                      : ""
                  }
                `}
                  onClick={onCandidate}
                >
                  {isCandidate
                    ? "Candidatada"
                    : data?.status === "PENDING"
                    ? "Em breve"
                    : "Candidatar"}
                </button>
              )}
            <div
              aria-label="save"
              className="cursor-pointer"
              role="button"
              tabIndex={0}
              onClick={handleSaveVacancy}
            >
              <Bookmark
                filled={isSaved}
                widthIcon={width < 991 ? 28 : 38}
                heightIcon={width < 991 ? 28 : 38}
              />
            </div>
          </div>

          <p className="vacancies-card-company">
            {data?.managedBy === placementVacancyManagedBy.EXTERNAL_VACANCY
              ? data?.externalCompanyName
              : data?.companyName ?? ""}
          </p>

          <p className="vacancies-card-time">
            {moment(data?.startDate).isValid() &&
              getTextDiffDate(data?.startDate ?? "")}
          </p>
          <div className="vacancies-card-icons">
            <div className="flex-row-center">
              <AddressCompanyProfile
                widthIcon={width < 991 ? 20 : 24}
                heightIcon={width < 991 ? 20 : 24}
              />
              <span className="location-font margin-left-8 margin-right-24">
                {data?.type === placementVacancyType.Remota
                  ? data?.type
                  : data?.type === placementVacancyType.Híbrida
                  ? `${data?.type} - ${data?.place}`
                  : data?.place}
              </span>
            </div>
            {data?.managedBy !== placementVacancyManagedBy.REPROGRAMA &&
              width > 990 && (
                <div className="flex-row-center">
                  {data?.managedBy !==
                    placementVacancyManagedBy.EXTERNAL_VACANCY && (
                    <p className="title-view">Administrado por:</p>
                  )}
                  {data?.managedBy === placementVacancyManagedBy.GREENHOUSE && (
                    <img
                      alt="logo"
                      className="greenhouse-logo-job"
                      src={GreenhouseLogo}
                    />
                  )}
                  {data?.managedBy === placementVacancyManagedBy.GUPY && (
                    <img alt="logo" className="gupy-logo-job" src={GupyLogo} />
                  )}
                  <p className="view-bold margin-left-8">
                    {
                      ManagedBy[
                        data?.managedBy ?? placementVacancyManagedBy.REPROGRAMA
                      ]
                    }
                  </p>
                </div>
              )}
          </div>
          {width < 991 &&
            data?.typeOfCandidature ===
              placementVacancyTypeOfCandidature.OPEN && (
              <button
                type="button"
                className={`
                margin-bottom-16-mobile vacancies-card-button
                 ${isCandidate ? "is-active" : ""} ${
                  data?.status === placementVacancyStatus.ACTIVE
                    ? "pointer"
                    : ""
                }
                `}
                onClick={onCandidate}
              >
                {isCandidate
                  ? "Candidatada"
                  : data?.status === placementVacancyStatus.PENDING
                  ? "Em breve"
                  : "Candidatar"}
              </button>
            )}
          {data?.managedBy !== placementVacancyManagedBy.REPROGRAMA &&
            width <= 990 && (
              <div className="flex-row-center margin-top-24">
                <p className="title-view">Administrado por:</p>
                {data?.managedBy === placementVacancyManagedBy.GREENHOUSE && (
                  <img
                    alt="logo"
                    className="greenhouse-logo-job"
                    src={GreenhouseLogo}
                  />
                )}
                <p className="view-bold margin-left-8">
                  {
                    ManagedBy[
                      data?.managedBy ?? placementVacancyManagedBy.REPROGRAMA
                    ]
                  }
                </p>
              </div>
            )}
        </div>
        <div className="divider margin-top-24" />
      </div>
      <div>
        <div className="vacancies-about">
          <h2 className="vacancies-about-title">Sobre a vaga</h2>
          <div className="height-fit">
            {data?.desiredSkills && data?.desiredSkills.length > 0 && (
              <>
                <h3 className="title-accordion margin-left-skill">
                  Habilidades
                </h3>
                <div className="candidature-skill-vacancy-container margin-left-skill">
                  {data?.desiredSkills.map((skill) => (
                    <div
                      key={Math.random()}
                      className="candidature-skill-vacancy"
                    >
                      {`${skill?.key} (`}
                      <span className="italic-text">
                        {labelSkilllevel(skill?.level)}
                      </span>
                      )
                    </div>
                  ))}
                </div>
              </>
            )}

            {data?.description ? (
              <p className="vacancies-about-description">{data?.description}</p>
            ) : (
              ""
            )}

            {data?.managedBy === placementVacancyManagedBy.EXTERNAL_VACANCY && (
              <>
                <div>
                  <h4>Modelo de trabalho:</h4>
                  <p>{data?.type}</p>
                </div>

                <div className="flex align-items">
                  <a
                    href={
                      data?.url?.includes("http")
                        ? data?.url
                        : `http://${data?.url}`
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="vacancies-about-company"
                    onClick={onClickVacancy}
                  >
                    Ver vaga
                  </a>
                  <VacancyExternal />
                </div>
              </>
            )}
          </div>

          {data?.managedBy !== placementVacancyManagedBy.EXTERNAL_VACANCY && (
            <>
              <div className="divider" />

              <h2 className="vacancies-about-vacancies">Sobre a empresa</h2>
              <div className="display-inline height-fit-content">
                <div className="vacancies-about-photos">
                  {data?.photo ? (
                    <img
                      alt="foto"
                      src={data?.photo}
                      className="company-avatar-empty size-64"
                    />
                  ) : (
                    <CompanyAvatarEmpty
                      heightIcon={64}
                      widthIcon={64}
                      className="company-avatar-empty"
                    />
                  )}
                </div>
                <div>
                  <p
                    className="vacancies-about-name"
                    onClick={() =>
                      history.push(`/company-profile/${data?.companyId ?? ""}`)
                    }
                  >
                    {data?.companyName}
                  </p>
                  <a
                    className="vacancies-about-website"
                    href={
                      data?.companyWebsite?.includes("http")
                        ? data?.companyWebsite
                        : `http://${data?.companyWebsite}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {data?.companyWebsite}
                  </a>
                </div>
              </div>
              <div>
                <p className="vacancies-about-description">
                  {data?.companyAbout}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      {data?.managedBy !== placementVacancyManagedBy.EXTERNAL_VACANCY && (
        <div className="vacancies-about-more">
          <button
            type="button"
            className="vacancies-about-more-button cursor-pointer"
            onClick={() => {
              history.push(`/company-profile/${data?.companyId ?? ""}`);
            }}
          >
            VER MAIS SOBRE A EMPRESA
          </button>
        </div>
      )}

      <ModalConfirmCandidate
        user={user}
        vacancy={data}
        setIsCandidate={setIsCandidate}
        onClose={() => setOpenModalCandidate(false)}
        open={openModalCandidate}
      />
    </div>
  );
};

export default memo(CardDescription);
