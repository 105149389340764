export const sociais = {
  LINKEDIN: {
    baseURL: "https://www.linkedin.com/in/",
    baseRegex: /^http(s)?:\/\/(www\.)?linkedin\.com\/in\/.*$/,
  },
  LINKEDIN_COMPANY: {
    baseURL: "https://www.linkedin.com/company/",
    baseRegex: /^http(s)?:\/\/(www\.)?linkedin\.com\/company\/.*$/,
  },
  INSTAGRAM: {
    baseURL: "https://www.instagram.com/",
    baseRegex: /^http(s)?:\/\/(www\.)?instagram\.com\/.*$/,
  },
  FACEBOOK: {
    baseURL: "https://www.facebook.com/",
    baseRegex: /^http(s)?:\/\/(www\.)?facebook\.com\/.*$/,
  },
  TIKTOK: {
    baseURL: "https://www.tiktok.com/@",
    baseRegex: /^http(s)?:\/\/(www\.)?tiktok\.com\/@.*$/,
  },
  TWITTER: {
    baseURL: "https://www.twitter.com/",
    baseRegex: /^http(s)?:\/\/(www\.)?twitter\.com\/.*$/,
  },
  GITHUB: {
    baseURL: "https://www.github.com/",
    baseRegex: /^http(s)?:\/\/(www\.)?github\.com\/.*$/,
  },
};
