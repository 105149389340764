import React, { memo, MouseEventHandler } from "react";
import "./styles.scss";

interface Props {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  text: string;
  outline?: boolean;
  appearance?: "primary" | "secondary" | "tertiary" | "quaternary";
  className?: string;
}

const Button = ({
  disabled,
  onClick,
  text,
  outline,
  className,
  appearance = "primary",
}: Props): JSX.Element => (
  <button
    aria-label="button"
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={`button ${className ?? ""} is-${appearance} ${
      outline ? `is-outline` : ""
    } ${disabled ? "is-disabled" : "is-active"}`}
  >
    {text}
  </button>
);

export default memo(Button);
