/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { HeaderProfile, Layout, StepVagasMobile } from "../../components";
import {
  getAllSubscriptionsOfAVacancy,
  getPlacementVacancy,
} from "../../services/functions";
import CardExpand from "./CardExpand";
import CardProposal from "./CardProposal";
import "./style.scss";
import { PlacementVacancy } from "../../types/interfaces";
import { setLoad } from "../../store/actions/configurationsActions";
import {
  classVacancySubscriptionStatus,
  placementVacancySubscriptionStatus,
} from "../../types/enumerators";

interface ParamsProps {
  placementVacancyId: string;
  placementVacancySubscriptionId?: string;
}

const CompanyVacancyProposals = (): JSX.Element => {
  const { placementVacancyId, placementVacancySubscriptionId } =
    useParams<ParamsProps>();

  const [width, setWidth] = useState(window.innerWidth);
  const [isActiveSection, setIsActiveSection] = useState(true);
  const [idSelected, setIdSelected] = useState("");
  const [vacancy, setVacancy] = useState<PlacementVacancy>();
  const [data, setData] = useState<any[] | undefined>();

  const dispatch = useDispatch();

  const getVacancy = useCallback(async () => {
    const response = await getPlacementVacancy(placementVacancyId);
    setVacancy(response as PlacementVacancy);
  }, [placementVacancyId]);

  useEffect(() => {
    if (!vacancy?._id && placementVacancyId) getVacancy();
  }, [getVacancy, placementVacancyId, vacancy?._id]);

  useEffect(() => {
    if (!idSelected && placementVacancySubscriptionId)
      setIdSelected(placementVacancySubscriptionId);
  }, [idSelected, placementVacancySubscriptionId]);

  const getData = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const subscriptionsList = await getAllSubscriptionsOfAVacancy(
      placementVacancyId,
    );

    const subs = subscriptionsList?.filter(
      (item) =>
        item?.status !== classVacancySubscriptionStatus.NONE &&
        item?.status !== classVacancySubscriptionStatus.PENDING,
    );

    const idProposal = vacancy?.stages?.find(
      (item: { title: string }) => item.title === "Proposta",
    )?.id;

    const currentActiveList = subs?.filter(
      (item: any) =>
        item?.stages[(item?.stages?.length ?? 1) - 1]?.stageId === idProposal,
    );

    const currentRecuseList = subscriptionsList?.filter(
      (item: any) =>
        item?.stages &&
        item?.stages?.length &&
        item?.stages[(item?.stages?.length ?? 1) - 1]?.stageId === idProposal &&
        item?.stages[(item?.stages?.length ?? 1) - 1]?.status ===
          placementVacancySubscriptionStatus.NONE,
    );

    const currentList = isActiveSection ? currentActiveList : currentRecuseList;

    setData(currentList);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, isActiveSection, placementVacancyId, vacancy?.stages]);

  useEffect(() => {
    if (placementVacancyId) getData();
  }, [placementVacancyId, getData]);

  const handleClick = useCallback(
    (item: { _id: string }) => {
      if (item._id === idSelected) setIdSelected("");
      else setIdSelected(item._id);
    },
    [idSelected],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const subMenu = [
    {
      title: "Ativas",
      onClick: (e: { stopPropagation: () => void }) => {
        e.stopPropagation();
        setIsActiveSection(true);
      },
      active: isActiveSection,
    },
    {
      title: "Recusadas",
      onClick: (e: { stopPropagation: () => void }) => {
        e.stopPropagation();
        setIsActiveSection(false);
      },
      active: !isActiveSection,
    },
  ];

  return (
    <Layout className="grid-responsive">
      <HeaderProfile />
      {width < 991 && (
        <div className="grid-column-1-11 grid-row-1 align-center">
          <StepVagasMobile
            id={vacancy?._id}
            selectedMenuTitle="Propostas"
            subMenu={subMenu}
          />
        </div>
      )}
      {width > 990 && (
        <div className="grid-column-1-11 grid-row-1 align-center">
          <div className="width-100">
            <div className="flex-row-center">
              <p className="view-page">Vagas &gt;&nbsp;</p>
              <p className="view-page">{vacancy?.title}&nbsp;&gt;&nbsp;</p>
              <p className="view-step">Propostas</p>
            </div>
          </div>
        </div>
      )}
      <div
        className={`
        grid-column-1-13 grid-row-2
          ${
            idSelected
              ? "card-candidature card-candidature-100"
              : "card-candidature"
          }
        `}
      >
        {width > 990 && (
          <div className="card-title-proposals">
            <h2 className="card-title"> Propostas </h2>
            <button
              type="button"
              className={` margin-left-24 ${
                isActiveSection && "proposals-active"
              }`}
              onClick={() => setIsActiveSection(true)}
            >
              Ativas
            </button>
            <button
              type="button"
              className={`${!isActiveSection && "proposals-active"}`}
              onClick={() => {
                setIdSelected("");
                setIsActiveSection(false);
              }}
            >
              Recusadas
            </button>
          </div>
        )}
        <div className="flex display-block">
          <div className="card-candidature_scroll  card-scroll-comment">
            {data && data?.length ? (
              data?.map((item) => (
                <div
                  key={Math.random()}
                  role="none"
                  onClick={() => handleClick(item)}
                  className="div-border-bottom pointer"
                >
                  <CardExpand idSelected={idSelected} item={item} />
                </div>
              ))
            ) : (
              <p className="margin-left-24">
                {`Não há propostas ${isActiveSection ? "ativas" : "recusadas"}`}
              </p>
            )}
          </div>
          {idSelected ? (
            <CardProposal
              item={data?.find((item) => item._id === idSelected)}
              fetch={getData}
            />
          ) : (
            <div className="display-none" />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default memo(CompanyVacancyProposals);
