/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect, useMemo, memo } from "react";
import { TableRow, TextField, InputAdornment } from "@material-ui/core";
import { toast } from "react-toastify";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { setLoad } from "../../store/actions/configurationsActions";
import { GeneralStatusUser } from "../../types/constants/User";
import { RootState } from "../../store/reducers";
import {
  HeaderProfile,
  Layout,
  Card,
  Table,
  Select,
  Pagination,
  Modal,
  Button,
  TextArea,
} from "../../components";
import { Search } from "../../../assets/icons";
import {
  userStatus,
  autoCompleteType,
  classStatus,
  classStructure,
  userBootcampsStatus,
} from "../../types/enumerators";
import {
  filterUserStudent,
  patchUser,
  getUserById,
  getAutocomplete,
  getPages,
  filterClass,
  createClassVacancySubscription,
  getAllActiveClassVacancy,
  getClassVacancySubscriptionByUserIdAndClassId,
  updateClassVacancySubscriptionById,
  getClassVacancyByClassId,
} from "../../services/functions";
import "./style.scss";
import { ClassVacancy } from "../../types/interfaces";

interface Filters {
  name?: string | null;
  email?: string | null;
  tags?: string | null;
  status?: string | null;
  classId?: string | null;
  legalIdentity?: string;
}

interface Data {
  _id?: string;
  name?: string;
  legalIdentity?: string;
  email?: string;
  status?: string;
  tags?: string;
  code?: string | string[];
}

const AdminStudent = (): JSX.Element => {
  const [users, setUsers] = useState<Data[]>([]);
  const [filters, setFilters] = useState<Filters>({});
  const [tagsOptions, setTagsOptions] = useState<string[]>();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [totalFilteredList, setTotalFilteredList] = useState(0);

  const [openModalTransferClass, setOpenModalTransferClass] = useState(false);
  const [classId, setClassId] = useState("");
  const [statusComments, setStatusComments] = useState("");
  const [selectedUser, setSelectedUser] = useState<Data>();
  const [classList, setClassList] = useState<{ _id?: string; code?: string }[]>(
    [],
  );
  const [listSelectedTable, setListSelectedTable] = useState<Data[]>();
  const [openModalTransferClassVacancy, setOpenModalTransferClassVacancy] =
    useState(false);
  const [classVacancies, setClassVacancies] = useState<ClassVacancy[]>();
  const [classVacancyId, setClassVacancyId] = useState("");
  const [stageId, setStageId] = useState("");

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const dispatch = useDispatch();

  const headCells = useMemo(
    () => [
      {
        id: "photo",
        label: "",
        orderBy: false,
        asc: true,
        classColumn: "w-40",
      },
      {
        id: "name",
        label: "Nome",
        orderBy: false,
        asc: true,
        classColumn: "w-160",
      },
      {
        id: "legalIdentity",
        label: "CPF",
        orderBy: false,
        asc: true,
      },
      {
        id: "email",
        label: "E-mail",
        orderBy: false,
        asc: false,
      },
      {
        id: "code",
        label: "Turma",
        orderBy: false,
        asc: false,
      },
      {
        id: "tags",
        label: "Tags",
        orderBy: false,
        asc: false,
      },
      {
        id: "status",
        label: "Status",
        orderBy: false,
        asc: false,
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
        asc: false,
        classColumn: "w-160",
      },
    ],
    [],
  );

  const getStudent = useCallback(async () => {
    try {
      dispatch(setLoad(true) as unknown as Action);
      if (pageLimit) {
        const totalUsers = (await filterUserStudent(filters, true)) as {
          size?: number;
        }[];

        const response = (await filterUserStudent(
          filters,
          false,
          page,
          pageLimit,
        )) as Data[];

        const usrs = response?.map((user: Data) => ({
          ...user,
          code: user?.code?.join(",  ") ?? "",
        }));
        setMaxPage(await getPages(filterUserStudent, filters, true));
        setTotalFilteredList(totalUsers?.[0]?.size ?? 0);
        setUsers(usrs);
        dispatch(setLoad(false) as unknown as Action);
      }
    } catch {
      toast.error("Ops! Houve um erro ao carregar os dados.");
    }
  }, [filters, page, pageLimit, dispatch]);

  const loadTags = useCallback(async () => {
    const response = await getAutocomplete(autoCompleteType.TAG);
    const optionsAutocompleteTag =
      response?.map((option) => option?.name) ?? [];
    setTagsOptions(optionsAutocompleteTag);
  }, []);

  const fetchClasses = async () => {
    try {
      const response = await filterClass({
        classStructure: [
          classStructure.FOUR_WEEKS,
          classStructure.SIX_WEEKS,
          classStructure.EIGHT_WEEKS,
          classStructure.TWELVE_WEEKS,
          classStructure.FOURTEEN_WEEKS,
          classStructure.EIGHTEEN_WEEKS,
        ],
      });

      const allClasses = response?.data ?? [];
      const activeAndFinishedClasses = allClasses
        .filter(
          (item: { status: string }) =>
            item.status === classStatus.ACTIVE ||
            item.status === classStatus.FINISHED,
        )
        .sort((a, b) => (a.code > b.code ? 1 : -1));

      return activeAndFinishedClasses;
    } catch (error) {
      toast.error("Erro ao buscar turmas");
      return [];
    }
  };

  const getClasses = useCallback(async () => {
    const classes = await fetchClasses();
    setClassList(classes);
  }, []);

  const getClassVacancies = useCallback(async () => {
    try {
      const response = await getAllActiveClassVacancy();
      setClassVacancies(response?.data);
    } catch {
      toast.error("Ops! Houve um erro ao buscar os processos disponíveis.");
    }
  }, []);

  useEffect(() => {
    if (!tagsOptions) loadTags();
  }, [loadTags, tagsOptions]);

  useEffect(() => {
    getStudent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageLimit]);

  useEffect(() => {
    getClasses();
  }, [getClasses]);

  useEffect(() => {
    if (openModalTransferClass) getClasses();
    if (openModalTransferClassVacancy) getClassVacancies();
  }, [
    getClasses,
    openModalTransferClass,
    getClassVacancies,
    openModalTransferClassVacancy,
  ]);

  const onChangeStatus = useCallback(
    async (row: Data, status: string) => {
      const userId = await getUserById(row?._id ?? "");
      await patchUser(row?._id ?? "", {
        account: {
          ...userId?.account,
          status,
        },
      });
      getStudent();
    },
    [getStudent],
  );

  const allChangeStatus = useCallback(
    (listSelected: Data[], status: string) => {
      for (let i = 0; i < listSelected.length; i += 1) {
        onChangeStatus(listSelected[i], status);
      }
      getStudent();
    },
    [getStudent, onChangeStatus],
  );

  const onToRedash = useCallback(
    (row: { legalIdentity: string }) =>
      window.open(
        `http://dashboard.reprograma.com.br/dashboards/7-pesquisa-por-cpf?p_cpf=${
          row?.legalIdentity ?? ""
        }`,
        "_blank",
      ),
    [],
  );

  const onCancel = useCallback(() => {
    setOpenModalTransferClass(false);
    setClassId("");
    setStatusComments("");
    setSelectedUser(undefined);
    setOpenModalTransferClassVacancy(false);
    setClassVacancyId("");
    setStageId("");
    setListSelectedTable(undefined);
  }, []);

  const handleSaveTransferClass = useCallback(
    async (row, classSelected, comments) => {
      dispatch(setLoad(true) as unknown as Action);
      const idx = (row?.bootcamps?.length ?? 0) - 1;

      const newBootcamp = {
        classId: classSelected,
        status: userBootcampsStatus.IN_PROCESS,
        comments,
      };

      const bootcamps = [...(row?.bootcamps ?? [])];

      bootcamps?.push(newBootcamp);
      await patchUser(row?._id ?? "", { bootcamps });

      const subscription = await getClassVacancySubscriptionByUserIdAndClassId(
        row?._id,
        row?.bootcamps?.[idx]?.classId,
      );
      const classVacancy = await getClassVacancyByClassId(classSelected);

      if (subscription && subscription?.length) {
        const subs = subscription[0];
        await updateClassVacancySubscriptionById(subs?._id, {
          ...subs,
          ticket: "BOOTCAMPS_CHANGED",
          classVacancyId: classVacancy?._id,
          oldClassVacancyId: subs?.classVacancyId,
        });
      }

      onCancel();
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, onCancel],
  );

  const handleAllSaveTransferClass = useCallback(
    async (listSelected, classSelected, comments) => {
      for (let index = 0; index < listSelected?.length; index += 1)
        handleSaveTransferClass(listSelected[index], classSelected, comments);

      setListSelectedTable(undefined);
    },
    [handleSaveTransferClass],
  );

  const handleSaveTransferClassVacancy = useCallback(
    async (usr, process, stage) => {
      dispatch(setLoad(true) as unknown as Action);

      await createClassVacancySubscription(
        process,
        usr?.id ?? "",
        usr?.form ?? {},
        stage,
      );
      onCancel();
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, onCancel],
  );

  const handleAllSaveTransferClassVacancy = useCallback(
    async (listSelected, process, stage) => {
      for (let index = 0; index < listSelected?.length; index += 1)
        handleSaveTransferClassVacancy(listSelected[index], process, stage);

      setListSelectedTable(undefined);
    },
    [handleSaveTransferClassVacancy],
  );

  const stages = useMemo(
    () =>
      classVacancies?.find((item) => item?._id === classVacancyId)?.stages ??
      [],
    [classVacancies, classVacancyId],
  );

  const handleChangeFilter = useCallback(
    (value, field) =>
      setFilters((prevFilters) => ({ ...prevFilters, [field]: value })),
    [setFilters],
  );

  const classOptions = [
    { value: "", label: "TODAS" },
    ...classList.map((item) => ({
      value: item._id ?? "",
      label: item.code ?? "",
    })),
  ];

  return (
    <Layout className="admin-student">
      <HeaderProfile />
      <Card
        className="grid-column-1-13 grid-row-1 margin-bottom-32 margin-top-20"
        bodyClassName="padding-0"
      >
        <TableRow className="top-filters flex width-fill">
          <TextField
            autoComplete="off"
            id="name"
            placeholder="Buscar por nome"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters.name || ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            onBlur={() => getStudent()}
          />
          <TextField
            autoComplete="off"
            id="email"
            placeholder="Buscar por e-mail"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters.email || ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                email: e.target.value,
              }))
            }
            onBlur={() => getStudent()}
          />
          <TextField
            autoComplete="off"
            id="legalIdentity"
            placeholder="Buscar por CPF"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters.legalIdentity || ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                legalIdentity: e.target.value,
              }))
            }
            onBlur={() => getStudent()}
          />

          <Select
            className={`status-filter last-filter ${
              filters?.classId ? "focus-selected" : ""
            }`}
            id="class"
            label="Turma"
            value={filters.classId ?? ""}
            options={classOptions}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                classId: e.target.value,
              }))
            }
            onBlur={() => getStudent()}
          />

          <Select
            className={`status-filter last-filter ${
              filters?.tags?.length ? "focus-selected" : ""
            }`}
            id="tags"
            label="Tags"
            value={filters.tags ?? ""}
            options={[
              { value: "", label: "Todas" },
              ...(tagsOptions ?? []).map((tag) => ({
                value: tag ?? "",
                label: tag ?? "",
              })),
            ]}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                tags: e.target.value,
              }))
            }
            onBlur={() => getStudent()}
          />
          <Select
            className={`status-filter last-filter ${
              filters?.status?.length ? "focus-selected" : ""
            }`}
            id="status"
            label="Status"
            value={filters?.status ?? ""}
            options={Object.keys(GeneralStatusUser).map((key) => ({
              value: key,
              label: GeneralStatusUser[key as keyof typeof GeneralStatusUser],
            }))}
            onChange={(e) => handleChangeFilter(e.target.value, "status")}
            onBlur={getStudent}
          />
        </TableRow>

        <Table
          hasMoreActions
          emptyText="Não há ex-alunas"
          headCells={headCells}
          list={users}
          onToRedash={onToRedash}
          onActive={(row) =>
            row.status === userStatus?.ACTIVE
              ? onChangeStatus(row, userStatus?.DISABLED)
              : onChangeStatus(row, userStatus?.ACTIVE)
          }
          onBlocked={(row) => onChangeStatus(row, userStatus?.BLOCKED)}
          onTransferClass={(row) => {
            setSelectedUser(row);
            setOpenModalTransferClass(true);
          }}
          onTransferClassVacancy={(row) => {
            setOpenModalTransferClassVacancy(true);
            setSelectedUser(row);
          }}
          onTransferAllClass={(rows) => {
            setListSelectedTable(rows);
            setOpenModalTransferClass(true);
          }}
          onTransferAllClassVacancy={(rows) => {
            setOpenModalTransferClassVacancy(true);
            setListSelectedTable(rows);
          }}
          onAllActive={(rows) => allChangeStatus(rows, userStatus.ACTIVE)}
          onAllDisabled={(rows) => allChangeStatus(rows, userStatus.DISABLED)}
          onAllBlocked={(rows) => allChangeStatus(rows, userStatus.BLOCKED)}
        />
      </Card>
      <div className="grid-row-2 grid-column-1-12 margin-top--45">
        <div className="flex">
          <p className="show-vacancies">
            Mostrando
            <span className="title-span">{users?.length}</span>
            de
            <span className="title-span">{totalFilteredList}</span>
            resultados
          </p>
          <Pagination page={page} setPage={setPage} maxPage={maxPage} />
        </div>
      </div>

      <Modal
        headerAction
        open={openModalTransferClass}
        onClose={onCancel}
        textHeader={
          selectedUser
            ? `${selectedUser.name} - Transferir para outra turma`
            : "Transferir para outra turma"
        }
      >
        <div className="admin-student-modal">
          <Select
            id="class"
            className="margin-bottom-24"
            label="Turma*"
            options={classList.map((items) => ({
              value: items?._id ?? "",
              label: items?.code ?? "",
            }))}
            value={classId ?? ""}
            onChange={(e) => setClassId(e.target.value)}
          />
          <TextArea
            id="justifyDescription"
            label="Comentário"
            value={statusComments ?? ""}
            maxLength={200}
            onChange={(e) => setStatusComments(e.target.value)}
            className="margin-top-32"
          />
          <div className="margin-top-32 admin-subscriptions-modal-buttons">
            <Button
              text="Cancelar"
              onClick={onCancel}
              outline
              appearance="secondary"
            />
            <Button
              text="Salvar"
              className="margin-left-32 margin-left-mobile"
              onClick={() =>
                selectedUser
                  ? handleSaveTransferClass(
                      selectedUser,
                      classId,
                      statusComments,
                    )
                  : handleAllSaveTransferClass(
                      listSelectedTable,
                      classId,
                      statusComments,
                    )
              }
              disabled={!classId || !statusComments}
            />
          </div>
        </div>
      </Modal>

      <Modal
        headerAction
        open={openModalTransferClassVacancy}
        onClose={onCancel}
        textHeader={
          selectedUser
            ? `${selectedUser.name} - Transferir para processo seletivo`
            : "Transferir para processo seletivo"
        }
      >
        <div className="admin-student-modal">
          <Select
            id="selectionProcess"
            className="margin-bottom-24"
            label="Processo seletivo*"
            value={classVacancyId ?? ""}
            onChange={(e) => setClassVacancyId(e.target.value)}
            options={(classVacancies ?? [])?.map((item) => ({
              value: item._id ?? "",
              label: item.title ?? "",
            }))}
          />
          <Select
            id="step"
            label="Etapa*"
            value={stageId ?? ""}
            onChange={(e) => setStageId(e.target.value)}
            options={stages?.map((stage) => ({
              value: stage?.type ?? "",
              label: stage?.title ?? "",
            }))}
            disabled={!classVacancyId}
          />
          <div className="margin-top-32 admin-subscriptions-modal-buttons">
            <Button
              text="Cancelar"
              onClick={onCancel}
              outline
              appearance="secondary"
            />
            <Button
              text="Salvar"
              className="margin-left-32 margin-left-mobile"
              onClick={() =>
                selectedUser
                  ? handleSaveTransferClassVacancy(
                      selectedUser,
                      classVacancyId,
                      stageId,
                    )
                  : handleAllSaveTransferClassVacancy(
                      listSelectedTable,
                      classVacancyId,
                      stageId,
                    )
              }
              disabled={!stageId}
            />
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default memo(AdminStudent);
