import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Video = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#7E459B"
      fillRule="evenodd"
      d="M16.001 2.667C8.641 2.667 2.668 8.64 2.668 16s5.973 13.333 13.333 13.333S29.335 23.36 29.335 16 23.36 2.666 16 2.666zM13.335 22l8-6-8-6v12zm-8-6c0 5.88 4.786 10.666 10.666 10.666 5.88 0 10.667-4.786 10.667-10.666 0-5.88-4.787-10.667-10.667-10.667S5.335 10.12 5.335 16z"
      clipRule="evenodd"
    />
  </svg>
);

export default Video;
