import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TableRow,
  TextField,
} from "@material-ui/core";
import { setLoad } from "../../store/actions/configurationsActions";
import {
  Button,
  Card,
  HeaderProfile,
  Layout,
  Pagination,
  Table,
} from "../../components";
import { Search } from "../../../assets/icons";
import { autoCompleteStatus } from "../../types/enumerators";
import {
  filterAutocomplete,
  getPages,
  updateAutocompleteById,
} from "../../services/functions";
import { AutoComplete } from "../../types/interfaces";
import {
  AutocompleteStatus,
  AutocompleteType,
} from "../../types/constants/Admin";
import { RootState } from "../../store/reducers";
import ModalAddAutocomplete from "./ModalAddAutocomplete";
import "./style.scss";

const AdminAutocomplete = (): JSX.Element => {
  const [list, setList] = useState<AutoComplete[]>();
  const [filters, setFilters] = useState<{
    type?: string;
    name?: string;
    status?: string;
  }>();
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [config, setConfig] = useState({ asc: true, sortBy: "name" });
  const [openModal, setOpenModal] = useState(false);

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const dispatch = useDispatch();

  const getList = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    setMaxPage(await getPages(filterAutocomplete, filters, true));

    const response =
      (await filterAutocomplete(filters, false, page, pageLimit)) ?? [];
    setList(response);

    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, filters, page, pageLimit]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters?.type, filters?.status, page, pageLimit]);

  const headCells = useMemo(
    () => [
      {
        id: "name",
        label: "Nome",
        orderBy: true,
        sort: "name",
        asc: config?.asc,
        onclick: () =>
          setConfig((prev) => ({
            asc: !prev?.asc,
            sortBy: "name",
          })),
      },
      {
        id: "type",
        label: "Tipo",
        orderBy: true,
        sort: "type",
        asc: config?.asc,
        onclick: () =>
          setConfig((prev) => ({
            asc: !prev?.asc,
            sortBy: "type",
          })),
      },
      {
        id: "status",
        label: "Status",
        orderBy: false,
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
      },
    ],
    [config?.asc],
  );

  const onChangeStatus = useCallback(
    async (row: { _id: string }, status: string) => {
      const newRow = { status };
      try {
        await updateAutocompleteById(row?._id, newRow as AutoComplete);
        return true;
      } catch {
        return false;
      }
    },
    [],
  );

  const allChangeStatus = useCallback(
    (rows, status) => {
      dispatch(setLoad(true) as unknown as Action);
      rows?.forEach((row: { _id: string }) => onChangeStatus(row, status));
      dispatch(setLoad(false) as unknown as Action);
      getList();
    },
    [dispatch, getList, onChangeStatus],
  );

  return (
    <Layout className="admin-class">
      <HeaderProfile />
      <Button
        text="Adicionar"
        className="grid-column-11-13 grid-row-1 request-vacancy"
        onClick={() => setOpenModal(true)}
      />
      <Card
        className=" grid-column-1-13 grid-row-2 margin-bottom-32"
        bodyClassName="padding-0"
      >
        <TableRow className="admin-class-filters">
          <TextField
            autoComplete="off"
            placeholder="Buscar nome"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.name ?? ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            onBlur={getList}
          />
          <div className="admin-class-radios">
            <span className="admin-class-radios-title">Tipo</span>
            <RadioGroup value={filters?.type || undefined}>
              <div>
                {Object.keys(AutocompleteType)?.map((key) => (
                  <FormControlLabel
                    key={Math.random()}
                    value={key}
                    control={<Radio />}
                    label={
                      AutocompleteType[key as keyof typeof AutocompleteType]
                    }
                    checked={key === filters?.type}
                    className="margin-right-32"
                    onChange={() =>
                      setFilters((prev) => ({
                        ...prev,
                        type: key,
                      }))
                    }
                  />
                ))}
              </div>
            </RadioGroup>
          </div>
          <div className="admin-class-radios">
            <span className="admin-class-radios-title">Status</span>
            <RadioGroup value={filters?.status || undefined}>
              <div>
                {Object.keys(AutocompleteStatus)?.map((key) => (
                  <FormControlLabel
                    key={Math.random()}
                    value={key}
                    control={<Radio />}
                    label={
                      AutocompleteStatus[key as keyof typeof AutocompleteStatus]
                    }
                    checked={key === filters?.status}
                    className="margin-right-32"
                    onChange={() =>
                      setFilters((prev) => ({
                        ...prev,
                        status: key,
                      }))
                    }
                  />
                ))}
              </div>
            </RadioGroup>
          </div>
        </TableRow>
        <Table
          emptyText="Não há dados cadastrados"
          headCells={headCells}
          list={list}
          onReload={getList}
          onActive={(row) =>
            row?.status === autoCompleteStatus.ACTIVE
              ? onChangeStatus(row, autoCompleteStatus.PENDING)
              : onChangeStatus(row, autoCompleteStatus.ACTIVE)
          }
          onRemove={(row) => onChangeStatus(row, autoCompleteStatus.DISABLED)}
          onAllActive={(rows) =>
            allChangeStatus(rows, autoCompleteStatus.ACTIVE)
          }
          onAllRemove={(rows) =>
            allChangeStatus(rows, autoCompleteStatus.DISABLED)
          }
        />
      </Card>
      <div className="grid-row-3 grid-column-1-13 width-100 flex margin-top--45">
        <div className="width-100 justify-center">
          <Pagination page={page} setPage={setPage} maxPage={maxPage} />
        </div>
      </div>
      <ModalAddAutocomplete
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </Layout>
  );
};

export default memo(AdminAutocomplete);
