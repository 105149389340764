/* eslint-disable no-console */
import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../store/reducers";
import { isCorporate } from "../../utils";
import { PublicProfile, PrivateProfile } from "./Profiles";
import "./styles.scss";

interface ParamsProps {
  id: string;
}
const CompanyProfile = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const company = useSelector((state: RootState) => state.companyState);
  const { id } = useParams<ParamsProps>();
  const isPrivate = useMemo(
    () =>
      !!user?._id &&
      !!company?._id &&
      isCorporate(user) &&
      user?.account?.companyIds?.includes(id),
    [company?._id, id, user],
  );

  return isPrivate ? <PrivateProfile /> : <PublicProfile />;
};

export default memo(CompanyProfile);
