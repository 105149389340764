/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from "react";
import { maskZipCode } from "../../utils";

const ViewAddress = ({ location, children }: any): JSX.Element => (
  <div className="grid-1-column">
    <div className="flex-col">
      <div className="label-infos-content margin-bottom-6">
        <p className="label-title">Endereço</p>
        {children}
      </div>
      <p className="view-account">
        {location?.address ?? ""}
        {location?.number && `, ${location?.number}`}
        {location?.adjunct && `, ${location?.adjunct}`}
        {` - ${location?.district ?? ""} - ${location?.city ?? ""} - ${
          location?.province ?? ""
        } - ${location?.country ?? ""} - CEP: ${maskZipCode(
          location?.zipCode ?? "",
        )}`}
      </p>
    </div>
    {(location?.originCountry || location?.originProvince) && (
      <div className="flex-col">
        <p className="title-view">Endereço de origem</p>
        <p className="view-account">
          {location?.originProvince}
          {location?.originProvince && location?.originCountry && " - "}
          {location?.originCountry}
        </p>
      </div>
    )}
  </div>
);

export default memo(ViewAddress);
