import React, { memo } from "react";
import "./styles.scss";

const HeaderChildrenTitle = () => (
  <div className="header-children-title">
    <p className="margin-0 ">
      Voltar para o site
      <span>{` {reprograma}`}</span>
    </p>
  </div>
);
export default memo(HeaderChildrenTitle);
