import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const Link = ({ widthIcon, heightIcon, className }: Props): JSX.Element => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#2E3451"
      d="M10 15v2H7c-2.761 0-5-2.239-5-5s2.239-5 5-5h3v2H7c-1.657 0-3 1.343-3 3s1.343 3 3 3h3zM14 9V7h3c2.761 0 5 2.239 5 5s-2.239 5-5 5h-3v-2h3c1.657 0 3-1.343 3-3s-1.343-3-3-3h-3z"
    />
    <path fill="#2E3451" d="M17 11H7v2h10v-2z" />
  </svg>
);

export default Link;
