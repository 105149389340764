import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import moment from "moment-timezone";
import {
  Button,
  InputAutocomplete,
  InputDate,
  Modal,
} from "../../../components";
import { Info } from "../../../../assets/customIcons";
import { researchType } from "../../../types/enumerators";
import { setLoad } from "../../../store/actions/configurationsActions";
import { Class, ClassForms } from "../../../types/interfaces";
import {
  getSupervision,
  notifyResearchCompany,
  notifyResearchUser,
  updateClassById,
} from "../../../services/functions";
import "../style.scss";

interface Props {
  open: boolean;
  close: () => void;
  data: ClassForms;
  type: string;
  currentClass: Class;
  reload: () => void;
}

const Research = ({
  open,
  close,
  data,
  type,
  currentClass,
  reload,
}: Props): JSX.Element => {
  const [emailsSupervision, setEmailsSupervision] = useState<string[]>();
  const [listEmailsSupervision, setListEmailsSupervision] =
    useState<string[]>();
  const [form, setForm] = useState(data);

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    setListEmailsSupervision(undefined);
    setForm({} as ClassForms);
    close();
  }, [close]);

  const activeForm = useCallback(async () => {
    if (type && currentClass) {
      dispatch(setLoad(true) as unknown as Action);
      const formIndex =
        currentClass?.forms?.findIndex(
          (item: { type?: string }) => item?.type === type,
        ) ?? -1;

      const updatedForms = [...(currentClass?.forms ?? [])];

      const newForm = {
        type,
        enable: form?.enable ?? moment(form?.startDate).isSame(moment(), "day"),
        startDate: moment(form?.startDate)
          .tz("America/Sao_Paulo")
          .toISOString(),
        endDate: moment(form?.endDate).tz("America/Sao_Paulo").toISOString(),
        classId: currentClass?._id ?? "",
        emails: emailsSupervision ?? [],
      };

      if (formIndex !== -1) updatedForms[formIndex] = newForm;
      else updatedForms?.push(newForm);

      const payload = {
        ...currentClass,
        forms: updatedForms,
      } as Class;

      await updateClassById(currentClass?._id ?? "", payload);

      if (!form?.enable && newForm?.enable) {
        if (type === researchType.COMPANY && newForm?.emails)
          await notifyResearchCompany(newForm?.emails, currentClass?._id ?? "");
        else await notifyResearchUser(currentClass?._id ?? "", type);
      }

      reload();
      onClose();
      dispatch(setLoad(false) as unknown as Action);
    }
  }, [
    type,
    currentClass,
    dispatch,
    form?.enable,
    form?.startDate,
    form?.endDate,
    emailsSupervision,
    onClose,
    reload,
  ]);

  const getEmailsSupervision = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getSupervision(currentClass?._id ?? "");
    if (response && response?.length) {
      setEmailsSupervision(response ?? []);
      setListEmailsSupervision(
        currentClass?.forms?.find(
          (item: { type?: string }) => item?.type === researchType.COMPANY,
        )?.emails ??
          response ??
          [],
      );
    }
    dispatch(setLoad(false) as unknown as Action);
  }, [currentClass?._id, currentClass?.forms, dispatch]);

  const handleChangeDate = useCallback(
    (value, props: string) =>
      setForm({
        ...form,
        [props]: value
          ?.startOf("day")
          .tz("America/Sao_Paulo")
          .format("YYYY-MM-DD"),
      }),
    [form],
  );

  useEffect(() => {
    if (type === researchType.COMPANY && !emailsSupervision)
      getEmailsSupervision();
  }, [getEmailsSupervision, emailsSupervision, type]);

  return (
    <Modal
      headerAction
      textHeader="Formulários de pesquisa"
      open={open}
      onClose={onClose}
      bodyClassName="card-form-modal"
    >
      <div className="modal-form">
        <div>
          <p className="paragraph-form">
            Escolha as datas para ativação do formulário.
          </p>
          <p className="sub-paragraph-form">
            A pesquisa será iniciada/finalizada automaticamente na data
            selecionada.
          </p>
        </div>
        <div className="flex">
          <InputDate
            label="Início"
            future
            minDate={moment()}
            views={["year", "month", "date"]}
            format="DD/MM/YYYY"
            helperText="MM/AAAA"
            value={form?.startDate ?? null}
            className="margin-right-32"
            onChange={(value) => handleChangeDate(value, "startDate")}
          />
          <InputDate
            label="Fim"
            format="DD/MM/YYYY"
            future
            minDate={form?.startDate}
            value={form?.endDate ?? null}
            onChange={(value) => handleChangeDate(value, "endDate")}
            helperText="MM/AAAA"
            views={["year", "month", "date"]}
          />
        </div>
        <div className="aux-text ">
          <Info className="icon-modal" heightIcon={25} widthIcon={25} />
          <p>
            Para iniciar imediatamente, selecione a data atual. Os e-mails e
            notificações serão enviados assim que o formulário for ativado.
          </p>
        </div>
        {type === researchType.COMPANY && (
          <div className="margin-top-24">
            {emailsSupervision && emailsSupervision?.length > 0 ? (
              <InputAutocomplete
                multiple
                label="E-mails"
                options={emailsSupervision?.map((item) => item) ?? [""]}
                value={listEmailsSupervision ?? []}
                onChange={(value) =>
                  setListEmailsSupervision(
                    typeof value === "string"
                      ? [value?.trim()]
                      : value?.map((item: string) =>
                          item?.toLowerCase().trim(),
                        ),
                  )
                }
                getOptionLabel={(option) => option}
              />
            ) : (
              <p>Não há e-mails de supervisores informados para esta turma.</p>
            )}
          </div>
        )}
        <div className="modal-form-buttons">
          <Button
            text="Cancelar"
            className="margin-right-32 button-form "
            onClick={onClose}
            outline
            appearance="secondary"
          />
          <Button
            text="Confirmar"
            onClick={activeForm}
            className="button-form"
            disabled={
              !form?.startDate ||
              !form?.endDate ||
              (form?.type === researchType.COMPANY && !form?.emails)
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(Research);
