import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";
import { AvatarEmpty } from "../../../../assets/customIcons";
import { RootState } from "../../../store/reducers";
import {
  IdentityType,
  ProfileGender,
  ProfilePronouns,
  ProfileRace,
} from "../../../types/constants/User";
import {
  userGenderIdentity,
  userIdentityType,
  userRace,
} from "../../../types/enumerators";
import { maskBRlegalIdentity } from "../../../utils";

const ViewInfos = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);

  const fields = useMemo(() => {
    if (user)
      return [
        {
          title: "Nome social",
          value: user?.profile?.name,
        },
        {
          title: "Pronome de preferência",
          value:
            ProfilePronouns[user?.profile?.pronouns] ?? user?.profile?.pronouns,
        },
        {
          title: "Data de nascimento",
          value: user?.profile?.birthdate,
        },
        {
          title:
            IdentityType[
              user?.profile?.identityType ?? userIdentityType.BR_CPF
            ],
          value:
            IdentityType[user?.profile?.identityType] ===
              userIdentityType.BR_CPF ||
            !IdentityType[user?.profile?.identityType]
              ? maskBRlegalIdentity(user?.profile?.legalIdentity)
              : user?.profile?.legalIdentity,
        },
        {
          title: "Identidade de gênero",
          value:
            user?.profile?.genderIdentity === userGenderIdentity.OTHERS
              ? user?.profile?.genderIdentityDescription
              : ProfileGender[user?.profile?.genderIdentity],
        },
        {
          title: "Necessidade de adaptação",
          value: user?.profile?.disabledPerson ? "Sim" : "Não",
        },
        {
          title: "CID10",
          value: user?.profile?.disabledPersonDescription,
          condition: user?.profile?.disabledPerson,
        },
        {
          title: "Raça",
          value:
            user?.profile?.race === userRace.OTHERS
              ? user?.profile?.raceDescription
              : ProfileRace[user?.profile?.race],
        },
        {
          title: "Bio",
          value: user?.profile?.bio,
          condition: user?.profile?.bio,
        },
      ];
    return [];
  }, [user]);

  return (
    <div className="flex display-block">
      <div className="account-area-avatar flex-col margin-right-32">
        {user?.profile?.photo ? (
          <img
            src={user?.profile?.photo}
            alt="imagem"
            width={48}
            height={48}
            className="border-avatar"
          />
        ) : (
          <AvatarEmpty className="account-avatar-empty" />
        )}
      </div>
      <div className="flex-wrap align-items-baseline gap-32">
        {fields.map(
          ({ title, value, condition = true }) =>
            condition && (
              <div key={title} className="margin-bottom-20-mobile flex-col">
                <p className="title-view margin-bottom-6">{title}</p>
                <p className="view-bold">{value}</p>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export default memo(ViewInfos);
