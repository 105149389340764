/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Checkbox, Input, Select } from "..";
import { brasilApi } from "../../services/api";
import {
  getCitiesList,
  getCountriesList,
  getStatesList,
} from "../../services/functions";
import { setNextButton } from "../../store/actions/nextButtonAction";
import { maskZipCode } from "../../utils";
import { UserProfileAddress } from "../../types/interfaces";
import { RootState } from "../../store/reducers";
import { updateUser } from "../../store/actions/userActions";
import { updateCompany } from "../../store/actions/companyActions";
import "./styles.scss";

interface FormItem {
  child: ReactNode;
  width?: string;
  title?: string;
}

interface Data {
  notSameProvince: boolean;
  notSameCountry: boolean;
}

type FormRow = Array<FormItem> | ReactNode;

interface ListItem {
  value: string | number | boolean | null;
  label: string;
}

interface FormInputProps {
  id: string;
  label: string;
  value: string | number;
  selectOptions?: ListItem[];
  isNumber?: boolean;
  maxLength?: number;
  optional?: boolean;
  disabled: boolean;
  onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onChange: any;
}

interface Props {
  data?: Data;
  setData?: Dispatch<SetStateAction<Data>>;
  setIsValid?: Dispatch<SetStateAction<boolean>>;
  showLabel?: boolean;
  is4Columns?: boolean;
  corporate?: boolean;
  corporateDispatch?: boolean;
  address?: any;
  setAddress?: any;
  hideCheckboxes?: boolean;
}

const FormInput = ({
  id,
  label: propLabel,
  value,
  selectOptions,
  isNumber = false,
  maxLength,
  optional = false,
  disabled,
  onBlur,
  onChange,
}: FormInputProps): JSX.Element => {
  const width = window.innerWidth;
  const label = `${propLabel}${!optional && width > 990 ? "*" : ""}`;

  return selectOptions ? (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={(e) => onChange(e, id)}
      onBlur={onBlur}
      options={selectOptions}
      disabled={disabled}
    />
  ) : (
    <Input
      id={id}
      label={label}
      value={value}
      type={isNumber ? "number" : "text"}
      maxLength={maxLength}
      onChange={(e) => onChange(e, id)}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

const margin = 32;

const FormAddress = ({
  data,
  setData,
  setIsValid,
  showLabel = false,
  is4Columns = false,
  corporate = false,
  corporateDispatch = false,
  address: propAddress,
  setAddress: propSetAddress,
  hideCheckboxes = false,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const [widthMobile, setWidthMobile] = useState(window.innerWidth);

  const [maxWidth, setMaxWidth] = useState(0);

  const isCorporate = useMemo(
    () => corporate || corporateDispatch,
    [corporate, corporateDispatch],
  );

  const user = useSelector((state: RootState) => state.userState);
  const company = useSelector((state: RootState) => state.companyState);
  const [profile, setProfile] = useState(
    (isCorporate ? company : user)?.profile,
  );

  const [location, setLocation] = useState(
    company?.profile?.location ? company?.profile?.location[0] : {},
  );
  const currentAddress = useMemo(
    () => propAddress || (profile?.address ?? location ?? {}),
    [location, profile, propAddress],
  );
  const {
    address,
    adjunct: addressAdjunct,
    businessUnity: addressBusinessUnity,
    city: addressCity,
    country: addressCountry,
    district: addressDistrict,
    number: addressNumber,
    originCountry,
    originProvince,
    province: addressProvince,
    zipCode: addressZipCode,
  } = useMemo(() => currentAddress, [currentAddress]);
  const {
    notSameProvince: dataNotSameProvince,
    notSameCountry: dataNotSameCountry,
  } = useMemo(
    () => data || { notSameProvince: undefined, notSameCountry: undefined },
    [data],
  );

  // --== + Localização + ==--
  const initialList = useCallback(
    (isFeminine = false) =>
      isFeminine
        ? { label: "Outras", value: "Outras" }
        : { label: "Outros", value: "Outros" },
    [],
  );
  const initializeNotSameLocation = useCallback(
    (_data, _origin, _address) =>
      _data !== undefined
        ? _data
        : !!_origin || (!!_origin && _origin !== _address),
    [],
  );
  const [countryList, setCountryList] = useState<ListItem[]>([initialList()]);
  const [stateList, setStateList] = useState<ListItem[]>([initialList()]);
  const [cityList, setCityList] = useState<ListItem[]>([initialList(true)]);
  const [internationalStateList, setInternationalStateList] = useState<
    ListItem[]
  >([initialList()]);
  const [notSameProvince, setNotSameProvince] = useState(
    initializeNotSameLocation(
      dataNotSameProvince,
      originProvince,
      addressProvince,
    ),
  );
  const [notSameCountry, setNotSameCountry] = useState(
    initializeNotSameLocation(
      dataNotSameCountry,
      originCountry,
      addressCountry,
    ),
  );

  // --== + Validações + ==--
  const hasData = useMemo(() => !!(data && setData), [data, setData]);
  const isCorporateOr3Column = useMemo(
    () => corporate || !is4Columns,
    [is4Columns, corporate],
  );
  const isDisabled = useMemo(
    () => !addressZipCode || addressZipCode?.length < 8,
    [addressZipCode],
  );

  const addressValidation = useMemo(
    () =>
      !!(
        addressZipCode &&
        address &&
        addressDistrict &&
        addressCity &&
        addressProvince &&
        addressCountry
      ),
    [
      address,
      addressCity,
      addressCountry,
      addressDistrict,
      addressProvince,
      addressZipCode,
    ],
  );
  const originProvinceOptions = useMemo(
    () =>
      notSameCountry || dataNotSameCountry ? internationalStateList : stateList,
    [dataNotSameCountry, internationalStateList, notSameCountry, stateList],
  );

  const originProvinceValidation = useMemo(
    () => originProvinceOptions.some(({ value }) => value === originProvince),
    [originProvince, originProvinceOptions],
  );

  const originValidation = useMemo(() => {
    const validate = (
      _notSame: boolean,
      _dataNotSame: boolean | undefined,
      _origin: string,
      _originValidation: boolean,
    ) =>
      !(_notSame || _dataNotSame) ||
      ((_notSame || _dataNotSame) && _origin && _originValidation);

    return !!(
      validate(
        notSameProvince,
        dataNotSameProvince,
        originProvince,
        originProvinceValidation,
      ) &&
      validate(
        notSameCountry,
        dataNotSameCountry,
        originCountry,
        originProvinceValidation,
      )
    );
  }, [
    dataNotSameCountry,
    dataNotSameProvince,
    notSameCountry,
    notSameProvince,
    originCountry,
    originProvince,
    originProvinceValidation,
  ]);

  const formValidation = useMemo(
    () =>
      addressValidation &&
      ((!corporate && originValidation) || (corporate && addressBusinessUnity)),
    [addressBusinessUnity, addressValidation, corporate, originValidation],
  );

  // --== + Callback + ==--
  const dispatchUpdate = useCallback(
    (payload) => {
      const _payload = { ...(isCorporate ? company : user), ...payload };
      dispatch(
        setNextButton(() =>
          dispatch(
            isCorporate
              ? (updateCompany({ ..._payload }) as unknown as Action)
              : (updateUser({ ..._payload }) as unknown as Action),
          ),
        ) as unknown as Action,
      );
    },
    [company, dispatch, isCorporate, user],
  );

  const getCountries = useCallback(async () => {
    const newCountries = [initialList()];

    try {
      const country = await getCountriesList();
      if (country) {
        country.map((item) =>
          newCountries.push({ label: item?.name.pt, value: item?._id }),
        );
      }
    } catch (error) {
      console.warn(error);
    }

    setCountryList(newCountries);
  }, [initialList]);

  const getStates = useCallback(
    async (country: string, isInternational?: boolean) => {
      const newProvinces = [initialList()];

      try {
        const states = await getStatesList(country);

        if (states) {
          states.forEach((item) => {
            const idState = item?._id?.split("-") ?? [];
            const state = idState[(idState?.length ?? 0) - 1];
            newProvinces.push({ label: state, value: state });
          });
        }
      } catch (error) {
        console.warn(error);
      }

      if (isInternational) setInternationalStateList(newProvinces);
      else setStateList(newProvinces);
    },
    [initialList],
  );

  const getCities = useCallback(
    async (country: string, state: string) => {
      const newCities = [initialList()];

      try {
        const cities = await getCitiesList(country, state);

        if (cities) {
          cities?.map((item) => {
            const {
              name: { pt: city },
            } = item;
            return newCities.push({ label: city, value: city });
          });
        }
      } catch (error) {
        console.warn(error);
      }

      setCityList(newCities);
    },
    [initialList],
  );

  const loadAddress = useCallback(
    async (e) => {
      const zipCode = e.target.value.replace(/\D/g, "");
      const parsedZipCode = zipCode.replace(/\D/g, "");

      const updateInformation = (
        newAddress: UserProfileAddress,
        newLocation: any,
      ) => {
        const _location = isCorporate
          ? { ...newLocation, businessUnity: "Matriz" }
          : newLocation;
        setLocation(_location);

        if (propAddress) {
          propSetAddress({ ...propAddress, ...newAddress });
        } else if (isCorporate) {
          const newProfile = {
            ...profile,
            location: [_location],
          };
          setProfile(newProfile);
          dispatchUpdate({ profile: newProfile });
        } else {
          const newProfile = {
            ...profile,
            address: { ...profile?.address, ...newAddress },
          };
          setProfile(newProfile);
          dispatchUpdate({ profile: newProfile });
        }
      };

      try {
        const { data: apiData } = await brasilApi.get(`/${zipCode}`);

        if (apiData) {
          const { street, neighborhood, city, state } = apiData;
          const _newAddress = {
            zipCode: parsedZipCode,
            address: street,
            country: "BR",
            district: neighborhood,
            province: state,
            city,
          };
          const _newLocation = {
            ...location,
            ..._newAddress,
          };
          updateInformation(_newAddress, _newLocation);
        }
      } catch (err) {
        const _newAddress = {
          zipCode: parsedZipCode,
          address: "",
          country: "",
          district: "",
          province: "",
          city: "",
        };
        const _newLocation = {
          ...location,
          ..._newAddress,
        };
        updateInformation(_newAddress, _newLocation);
      }
    },
    [
      location,
      propAddress,
      isCorporate,
      propSetAddress,
      profile,
      dispatchUpdate,
    ],
  );

  const searchZipCode = useCallback(() => {
    window.open(
      "https://buscacepinter.correios.com.br/app/endereco/index.php",
      "popup",
      "width=600, height=400",
    );
  }, []);

  const onChangeNotSameProvince = useCallback(() => {
    setNotSameProvince(!notSameProvince);
    setNotSameCountry(false);
    const clearedProfile = {
      ...profile,
      address: {
        ...profile?.address,
        originProvince: "",
        originCountry: "",
      },
    };
    setProfile(clearedProfile);
    if (notSameProvince || dataNotSameProvince) {
      dispatchUpdate({
        profile: clearedProfile,
      });
    }
    if (hasData) {
      // @ts-ignore
      setData({
        ...data,
        notSameProvince: !dataNotSameProvince,
        notSameCountry: false,
      });
    }
  }, [
    notSameProvince,
    profile,
    dataNotSameProvince,
    hasData,
    dispatchUpdate,
    setData,
    data,
  ]);

  const onChangeNotSameCountry = useCallback(() => {
    setNotSameCountry(!notSameCountry);
    setProfile((prev: any) => ({
      ...prev,
      address: { ...profile?.address, originCountry: "" },
    }));
    if (hasData) {
      // @ts-ignore
      setData({
        ...data,
        notSameCountry: !dataNotSameCountry,
      });
    }
  }, [
    notSameCountry,
    hasData,
    profile?.address,
    setData,
    data,
    dataNotSameCountry,
  ]);

  const updateIsValid = useCallback(() => {
    if (setIsValid) {
      if (formValidation) setIsValid(true);
      else setIsValid(false);
    }
  }, [formValidation, setIsValid]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) => {
      const {
        target: { value: eventValue },
      } = event;
      const value =
        prop === "zipCode" ? eventValue.replace(/\D/g, "") : eventValue;

      if (prop !== "number" || (prop === "number" && Number(value) >= 0)) {
        const propValue = { [prop]: value };

        if (propAddress) {
          propSetAddress({ ...propAddress, ...propValue });
        } else if (isCorporate) {
          const newLocation = { ...location, ...propValue };
          setLocation(newLocation);
          setProfile((prev: any) => ({ ...prev, location: [newLocation] }));
          dispatchUpdate({ profile: { ...profile, location: [newLocation] } });
        } else {
          setProfile((prev: any) => ({
            ...prev,
            address: { ...prev?.address, ...propValue },
          }));
        }
      }
    },
    [
      dispatchUpdate,
      isCorporate,
      location,
      profile,
      propAddress,
      propSetAddress,
    ],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChangeSelect = useCallback(
    (
      event: ChangeEvent<{
        name?: string;
        value: unknown;
      }>,
      prop: string,
    ) => {
      let propValue = { [prop]: event.target.value as string };

      if (prop === "country") {
        propValue = {
          ...propValue,
          province: "",
          city: "",
        };
      } else if (prop === "province") {
        propValue = {
          ...propValue,
          city: "",
        };
      }

      const newAddress = {
        ...profile?.address,
        ...propValue,
      };

      if (propAddress) {
        propSetAddress({ ...propAddress, ...propValue });
      } else if (isCorporate) {
        const newLocation = { ...location, ...propValue };
        setLocation(newLocation);
        setProfile((prev: any) => ({ ...prev, location: [newLocation] }));
      } else {
        setProfile((prev: any) => ({ ...prev, address: newAddress }));
      }
    },
    [profile?.address, propAddress, isCorporate, propSetAddress, location],
  );

  const handleOnBlur = useCallback(() => {
    if (formValidation) {
      if (isCorporate) {
        dispatchUpdate({
          profile: {
            ...profile,
            location: [location],
          },
        });
      } else {
        dispatchUpdate({
          profile: {
            ...profile,
            address: { ...profile?.address },
          },
        });
      }
    }
  }, [formValidation, isCorporate, dispatchUpdate, profile, location]);

  const handleOnBlurZipCode = useCallback((e) => loadAddress(e), [loadAddress]);

  const items = useMemo((): Array<FormRow> => {
    const unidadeDeNegocio = {
      title: "Unidade de negócio",
      child: (
        <FormInput
          id="businessUnity"
          label={width > 990 ? "Unidade de negócio" : ""}
          value={
            propAddress ? propAddress.businessUnity : addressBusinessUnity ?? ""
          }
          disabled={false}
          onBlur={handleOnBlur}
          onChange={handleChange}
        />
      ),
      width: 208,
    };

    const cep = {
      title: "CEP",
      child: (
        <>
          <FormInput
            id="zipCode"
            label={width > 990 ? "CEP" : ""}
            value={maskZipCode(addressZipCode ?? "")}
            maxLength={9}
            disabled={corporate ? !addressBusinessUnity : false}
            onBlur={handleOnBlurZipCode}
            onChange={handleChange}
          />
          <button
            className="helper-zipCode"
            type="button"
            onClick={searchZipCode}
          >
            Não sei meu CEP
          </button>
        </>
      ),
      width: corporate ? 96 : is4Columns ? 190 : 212,
    };

    const logradouro = {
      title: "Logradouro*",
      child: (
        <FormInput
          id="address"
          label={width > 990 ? "Logradouro" : ""}
          value={address ?? ""}
          disabled={isDisabled}
          onBlur={handleOnBlur}
          onChange={handleChange}
        />
      ),
      width: corporate ? 236 : is4Columns ? 240 : 360,
    };

    const numero = {
      title: "Número*",
      child: (
        <FormInput
          id="number"
          label={width > 990 ? "Número" : ""}
          value={addressNumber ?? ""}
          isNumber
          optional
          disabled={isDisabled}
          onBlur={handleOnBlur}
          onChange={handleChange}
        />
      ),
      width: isCorporateOr3Column ? 90 : 93,
    };

    const complemento = {
      title: "Complemento",
      child: (
        <FormInput
          id="adjunct"
          label={width > 990 ? "Complemento" : ""}
          value={addressAdjunct ?? ""}
          optional
          disabled={isDisabled}
          onBlur={handleOnBlur}
          onChange={handleChange}
        />
      ),
      width: isCorporateOr3Column ? 200 : 240,
    };

    const bairro = {
      title: "Bairro*",
      child: (
        <FormInput
          id="district"
          label={width > 990 ? "Bairro" : ""}
          value={addressDistrict ?? ""}
          disabled={isDisabled}
          onBlur={handleOnBlur}
          onChange={handleChange}
        />
      ),
      width: isCorporateOr3Column ? 200 : 190,
    };

    const pais = {
      title: "País*",
      child: (
        <FormInput
          id="country"
          label={width > 990 ? "País" : ""}
          value={addressCountry ?? ""}
          selectOptions={countryList}
          disabled={isDisabled}
          onBlur={handleOnBlur}
          onChange={handleChangeSelect}
        />
      ),
      width: isCorporateOr3Column ? 262 : 240,
    };

    const estado = {
      title: "Estado*",
      child: (
        <FormInput
          id="province"
          label={width > 990 ? "Estado" : ""}
          value={addressProvince ?? ""}
          selectOptions={stateList}
          disabled={isDisabled}
          onBlur={handleOnBlur}
          onChange={handleChangeSelect}
        />
      ),
      width: isCorporateOr3Column ? 90 : 93,
    };

    const cidade = {
      title: "Cidade*",
      child: (
        <FormInput
          id="city"
          label={width > 990 ? "Cidade" : ""}
          value={addressCity ?? ""}
          selectOptions={cityList}
          disabled={isDisabled}
          onBlur={handleOnBlur}
          onChange={handleChangeSelect}
        />
      ),
      width: isCorporateOr3Column ? 310 : 240,
    };

    const checkboxes = {
      child: (
        <div className="flex-col">
          <Checkbox
            id="notSameProvince"
            label={<div>Meu estado de origem não é o mesmo que o atual</div>}
            checked={dataNotSameProvince ?? notSameProvince}
            disabled={isDisabled}
            onChange={onChangeNotSameProvince}
          />
          {(notSameProvince || dataNotSameProvince) && (
            <div className="checkbox-margin-top">
              <Checkbox
                id="notSameCountry"
                label={<div>Meu país de origem não é o mesmo que o atual</div>}
                checked={dataNotSameCountry ?? notSameCountry}
                disabled={isDisabled}
                onChange={onChangeNotSameCountry}
              />
            </div>
          )}
        </div>
      ),
    };

    const origemWidth = is4Columns ? 240 : 163;

    const origemEstado = {
      child: (notSameProvince || dataNotSameProvince) && (
        <FormInput
          id="originProvince"
          label="Estado de origem"
          value={originProvinceValidation ? originProvince : ""}
          selectOptions={originProvinceOptions}
          disabled={isDisabled}
          onBlur={handleOnBlur}
          onChange={handleChangeSelect}
        />
      ),
      width: origemWidth,
    };

    const origemPais = {
      child: (notSameCountry || dataNotSameCountry) && (
        <FormInput
          id="originCountry"
          label="País de origem"
          value={originCountry ?? ""}
          selectOptions={countryList}
          disabled={isDisabled}
          onBlur={handleOnBlur}
          onChange={handleChangeSelect}
        />
      ),
      width: origemWidth,
    };

    const cepLogradouroNumero = [cep, logradouro, numero];

    const row1 = corporate
      ? [unidadeDeNegocio, ...cepLogradouroNumero]
      : is4Columns
      ? [...cepLogradouroNumero, complemento]
      : [...cepLogradouroNumero];

    const row2 = isCorporateOr3Column
      ? [complemento, bairro, pais]
      : [bairro, pais, estado, cidade];

    const row3 = isCorporateOr3Column ? [estado, cidade] : null;

    const lastRow =
      hideCheckboxes || corporate
        ? null
        : [checkboxes, origemEstado, origemPais];

    setMaxWidth(
      row1
        .map(({ width: widthItem }) => widthItem)
        .reduce((previousWidth, currentWidth, i, { length }) => {
          const isLastElement = i + 1 === length;
          return previousWidth + currentWidth + (!isLastElement ? margin : 0);
        }, 0),
    );

    return [row1, row2, row3, lastRow];
  }, [
    address,
    addressAdjunct,
    addressBusinessUnity,
    addressCity,
    addressCountry,
    addressDistrict,
    addressNumber,
    addressProvince,
    addressZipCode,
    cityList,
    corporate,
    countryList,
    dataNotSameCountry,
    dataNotSameProvince,
    handleChange,
    handleChangeSelect,
    handleOnBlur,
    handleOnBlurZipCode,
    hideCheckboxes,
    is4Columns,
    isCorporateOr3Column,
    isDisabled,
    notSameCountry,
    notSameProvince,
    onChangeNotSameCountry,
    onChangeNotSameProvince,
    originCountry,
    originProvince,
    originProvinceOptions,
    originProvinceValidation,
    propAddress,
    searchZipCode,
    stateList,
    width,
  ]);

  useEffect(() => {
    if (!isCorporate && profile !== user?.profile) setProfile(user?.profile);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.profile]);

  useEffect(() => {
    if (setIsValid) updateIsValid();
  }, [setIsValid, updateIsValid]);

  useEffect(() => {
    if (!corporate && user?.profile?.name && !profile?.name) {
      setProfile(user?.profile);
    }
  }, [profile?.name, corporate, user?.profile]);

  useEffect(() => {
    if (countryList?.length < 2) getCountries();

    if (countryList?.length > 1 && addressCountry) getStates(addressCountry);

    if (
      (notSameCountry || dataNotSameCountry) &&
      countryList?.length > 1 &&
      originCountry
    )
      getStates(originCountry, true);

    if (
      countryList?.length > 1 &&
      stateList?.length > 1 &&
      addressCountry &&
      addressProvince
    )
      getCities(addressCountry, addressProvince);
  }, [
    addressCountry,
    addressProvince,
    countryList?.length,
    dataNotSameCountry,
    getCities,
    getCountries,
    getStates,
    notSameCountry,
    originCountry,
    stateList?.length,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setWidthMobile(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {showLabel && (
        <p className={`title-input margin-bottom-${margin}`}>
          Gostaríamos de saber seu endereço completo para futuras ações.
        </p>
      )}
      {items.map(
        (row, index, arr) =>
          row &&
          (Array.isArray(row) ? (
            <div
              className={`flex display-block ${
                index !== 0 ? `margin-top-${margin}` : ""
              }`}
              style={index + 1 === arr.length ? { maxWidth } : {}}
            >
              {row.map(({ child, width: widthItem, title }, i) => (
                <>
                  {widthMobile < 991 && (
                    <p className="p-mobile-address">{title}</p>
                  )}
                  <div
                    className={`${
                      i !== 0 ? `margin-left-${margin}` : ""
                    } width-fiel-mobile`}
                    style={widthItem ? { width: `${widthItem}px` } : {}}
                  >
                    {child}
                  </div>
                </>
              ))}
            </div>
          ) : (
            row
          )),
      )}
    </div>
  );
};

export default memo(FormAddress);
