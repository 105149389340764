import React, {
  ChangeEventHandler,
  FocusEventHandler,
  memo,
  useCallback,
  useState,
} from "react";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { VisibilityOff } from "../../../assets/icons";
import "./styles.scss";

interface Props {
  className?: string;
  helperText?: string;
  id?: string;
  label: string;
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  title?: string;
  value?: string | number;
}

interface State {
  showPassword: boolean;
}

const InputPassword = ({
  className,
  helperText,
  id,
  label,
  onBlur,
  onChange,
  title,
  value,
}: Props): JSX.Element => {
  const [values, setValues] = useState<State>({
    showPassword: false,
  });

  const handleClickShowPassword = useCallback(() => {
    setValues({ ...values, showPassword: !values.showPassword });
  }, [values]);

  const handleMouseDownPassword = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    [],
  );

  return (
    <div className={className}>
      {title && <p className="p-title-input-password">{title}</p>}
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                className="input-pass padding-0 "
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        autoComplete="off"
        className="width-100"
        helperText={helperText}
        id={id}
        label={label}
        onBlur={onBlur}
        onChange={onChange}
        type={values.showPassword ? "text" : "password"}
        value={value}
        variant="filled"
      />
    </div>
  );
};

export default memo(InputPassword);
