/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { FormControlLabel, Switch, Tooltip } from "@material-ui/core";
import { createId } from "../../../utils";
import {
  Button,
  Card,
  CardItems,
  IconButton,
  Input,
  InputDate,
  TextArea,
} from "../../../components";
import { Info } from "../../../../assets/icons";
import { Add } from "../../../../assets/customIcons";
import { placementVacancyManagedBy } from "../../../types/enumerators";
import { PlacementVacancy } from "../../../types/interfaces";

interface Props {
  vacancy: PlacementVacancy;
  setVacancy: Dispatch<SetStateAction<PlacementVacancy>>;
  onSave: () => void;
}

const Stages = ({ vacancy, setVacancy, onSave }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [addStage, setAddStage] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [stage, setStage] = useState<any>({
    id: createId(),
    title: "",
    description: "",
    enableRecruiterContact: false,
    order: (vacancy?.stages?.length ?? 0) - 2 ?? 1,
  });
  const history = useHistory();

  const sort = useCallback(() => {
    vacancy?.stages?.sort((a: any, b: any) => {
      if (a.startDate && b.startDate) {
        if (moment(a.startDate).isAfter(b.startDate)) return 1;
        if (moment(a.startDate).isBefore(b.startDate)) return -1;
        return 0;
      }
      return 0;
    });
  }, [vacancy?.stages]);

  useEffect(() => {
    if (vacancy?.stages?.length) sort();
  }, [vacancy?.stages?.length, sort]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      prop: string,
    ) => setStage({ ...stage, [prop]: event.target.value }),
    [stage],
  );

  const handleChangeDate = useCallback(
    (value: any, prop: string) => {
      setStage({
        ...stage,
        [prop]: value?.startOf("day").toISOString(),
      });
    },
    [stage],
  );

  const handleChangeSwitch = useCallback(
    (value: boolean, prop: string) => {
      setStage({
        ...stage,
        [prop]: value,
      });
    },
    [stage],
  );

  const onAdd = useCallback(() => {
    let { stages: newStages } = vacancy;

    if (newStages && editIndex !== -1) newStages[editIndex] = stage;
    else if (newStages?.length) newStages.push(stage);
    else newStages = [stage];

    setVacancy({ ...vacancy, stages: newStages });
    setAddStage(false);
    setEditIndex(-1);
    sort();
  }, [vacancy, editIndex, stage, setVacancy, sort]);

  const onRemove = useCallback(
    (index: number) => {
      if (
        vacancy?.stages &&
        vacancy?.stages[index]?.title !== "Captação" &&
        vacancy?.stages[index]?.title !== "Proposta"
      ) {
        let oldStages = vacancy?.stages;
        oldStages.splice(index, 1);
        oldStages = oldStages.map((item: any, i: number) => ({
          ...item,
          order: i,
        }));
        setVacancy({ ...vacancy, stages: oldStages });
      }
    },
    [vacancy, setVacancy],
  );

  const onEdit = useCallback((item: any, index: number) => {
    setEditIndex(index);
    setAddStage(true);
    setStage(item);
  }, []);

  const onCancel = useCallback(() => {
    setAddStage(false);
    setEditIndex(-1);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="creat-mobile-margin grid-column-1-11-mobile grid-column-5-12 media-card-vacancies grid-7-column grid-row-2">
      <Card
        titleHeader={width > 990 ? "Etapas" : undefined}
        className="grid-column-1-8 padding-0 border-card"
      >
        {!addStage ? (
          <div className="flex">
            <IconButton
              icon={
                <Add
                  widthIcon={width < 991 ? 20 : 24}
                  heightIcon={width < 991 ? 20 : 24}
                />
              }
              text="Adicionar etapa"
              onClick={() => {
                setAddStage(true);
                setStage({
                  id: createId(),
                  title: "",
                  description: "",
                  enableRecruiterContact: false,
                  order: (vacancy?.stages?.length ?? 0) - 1 ?? 1,
                });
              }}
              className="icon-button-primary"
              disabled={
                vacancy?.managedBy === placementVacancyManagedBy.GREENHOUSE ||
                vacancy?.managedBy === placementVacancyManagedBy.GUPY ||
                !vacancy?.stages?.find(
                  (item: { title: string }) => item?.title === "Captação",
                )?.startDate ||
                !vacancy?.stages?.find(
                  (item: { title: string }) => item?.title === "Proposta",
                )?.endDate
              }
            />
            <Tooltip
              title={
                <p>
                  As etapas Captação e Proposta são obrigatórias.
                  <br />
                  Antes de adicionar novas etapas, defina as datas de abertura e
                  encerramento delas.
                  <br /> <br />
                  Ah! As outras etapas não podem começar antes da data de
                  abertura de Captação e nem encerrar após a data de
                  encerramento de Proposta.
                  <br /> <br />
                  <b>
                    Vagas administradas por terceiros (Greenhouse, Gupy) não
                    possuem personalização de etapas pela nossa plataforma.
                  </b>
                </p>
              }
            >
              <div>
                <Info className="icon-info pointer" />
              </div>
            </Tooltip>
          </div>
        ) : (
          <div>
            <div className="flex display-block width-100-mobile">
              {width < 991 && <p className="p-mobile">Título*</p>}
              <Input
                id="title"
                label="Título*"
                type="text"
                value={stage?.title ?? ""}
                onChange={(
                  e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => handleChange(e, "title")}
                className="input-title width-56 margin-right-20 width-100-mobile"
                disabled={
                  stage?.title === "Captação" || stage?.title === "Proposta"
                }
              />
              {width < 991 && (
                <p className="p-mobile margin-top-20">Abertura</p>
              )}
              <InputDate
                future
                views={["year", "month", "date"]}
                format="DD/MM/yyyy"
                minDate={
                  stage?.title !== "Captação" && vacancy?.stages
                    ? vacancy?.stages?.find(
                        (item: { title: string }) => item?.title === "Captação",
                      )?.startDate ?? vacancy?.startDate
                    : vacancy?.startDate ?? moment().toDate()
                }
                maxDate={
                  stage?.title !== "Proposta" && vacancy?.stages
                    ? vacancy?.stages?.find(
                        (item: { title: string }) => item?.title === "Proposta",
                      )?.endDate ?? vacancy?.endDate
                    : vacancy?.endDate
                }
                label="Abertura"
                helperText="DD/MM/AAAA"
                value={stage.startDate ?? null}
                onChange={(value) => handleChangeDate(value, "startDate")}
                className="margin-right-20 input-date-mobile"
              />
              {width < 991 && (
                <p className="p-mobile margin-top-20">Encerramento</p>
              )}
              <InputDate
                future
                views={["year", "month", "date"]}
                format="DD/MM/yyyy"
                minDate={stage.startDate ?? moment().toDate()}
                maxDate={
                  stage?.title !== "Proposta" && vacancy?.stages
                    ? vacancy?.stages?.find(
                        (item: { title: string }) => item?.title === "Proposta",
                      )?.endDate ?? vacancy?.endDate
                    : vacancy?.endDate
                }
                label="Encerramento"
                helperText="DD/MM/AAAA"
                value={stage.endDate ?? null}
                onChange={(value) => handleChangeDate(value, "endDate")}
                className="input-date-mobile"
              />
            </div>
            <div className="margin-top-30">
              {width < 991 && (
                <p className="p-mobile margin-top-20">Descrição</p>
              )}
              <TextArea
                id="description"
                label="Descrição"
                maxLength={400}
                value={stage?.description ?? ""}
                onChange={(e) => handleChange(e, "description")}
                className="description-input width-100-mobile"
              />
            </div>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={stage?.enableRecruiterContact}
                    onChange={() =>
                      handleChangeSwitch(
                        !stage?.enableRecruiterContact,
                        "enableRecruiterContact",
                      )
                    }
                    name="enableRecruiterContact"
                  />
                }
                label="Permitir contato da candidata"
              />
            </div>
          </div>
        )}
      </Card>
      {addStage && (
        <div className="flex grid-column-4-8 grid-row-2 margin-button-mobile-add flex-col-mobile gap-16-mobile stage-buttons">
          <Button
            text="Cancelar"
            appearance="secondary"
            outline
            onClick={onCancel}
            className="width-50 width-100-responsive"
          />
          <Button
            text="Salvar"
            className={`width-100-responsive ${
              !stage?.title ||
              !stage.startDate ||
              !stage.endDate ||
              !stage?.description
                ? "disabled button-save-stages margin-left-32 margin-left-0-mobile width-50"
                : "margin-left-32 margin-left-0-mobile width-50"
            }`}
            onClick={onAdd}
            disabled={
              !stage?.title ||
              !stage.startDate ||
              !stage.endDate ||
              !stage?.description
            }
          />
        </div>
      )}
      {vacancy?.stages && vacancy?.stages?.length > 0 && (
        <div
          className={`grid-column-1-8 ${
            addStage ? "grid-row-3" : "grid-row-2"
          }`}
        >
          <CardItems
            isMyProfile
            fullDate
            notSort
            showDivider={false}
            items={vacancy?.stages}
            onEdit={(item, index) => onEdit(item, index)}
            onRemove={(_, index) => onRemove(index)}
            className="padding-32"
          />
        </div>
      )}
      {!addStage && !vacancy?._id && (
        <>
          <Button
            text="Cancelar"
            className={`grid-column-4-6 margin-bottom-32 grid-column-1-8-mobile ${
              !vacancy?.stages?.length ? "grid-row-2" : "grid-row-3"
            }`}
            onClick={() => history.goBack()}
            outline
            appearance="secondary"
          />
          <Button
            text="Enviar"
            className={`grid-column-6-8 margin-bottom-32 grid-column-1-8-mobile ${
              !vacancy?.stages?.length ? "grid-row-2" : "grid-row-3"
            }`}
            onClick={onSave}
            disabled={
              !vacancy?.stages?.find(
                (item: { title: string }) => item?.title === "Captação",
              )?.startDate ||
              !vacancy?.stages?.find(
                (item: { title: string }) => item?.title === "Proposta",
              )?.endDate
            }
          />
        </>
      )}
      {!addStage && vacancy?._id && (
        <div
          className={`flex grid-column-4-8 grid-column-1-8-mobile margin-bottom-32 flex-direction-column-reverse gap-16-mobile ${
            !vacancy?.stages?.length ? "grid-row-2" : "grid-row-3"
          }
          `}
        >
          <Button
            text="Cancelar"
            className="margin-bottom-32 margin-bottom-0-mobile margin-top-0-mobile width-100"
            onClick={() => history.goBack()}
            outline
            appearance="secondary"
          />
          <Button
            text="Salvar"
            className="margin-left-32 margin-bottom-24 margin-left-0-mobile margin-top-0-mobile width-100"
            onClick={onSave}
          />
        </div>
      )}
    </div>
  );
};

export default memo(Stages);
