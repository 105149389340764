/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect, useState } from "react";
import { MapsExternal } from "../../../../../assets/icons";
import {
  AddressCompanyProfile,
  Infos,
} from "../../../../../assets/customIcons";
import {
  FinancialType,
  SizeCompany,
} from "../../../../types/constants/Company";
import { maskZipCode } from "../../../../utils";

interface Props {
  company: any;
}

const About = ({ company }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <div className="cards-profile">
        <div className="flex-row-center title-cards">
          <Infos
            className="cards-icon-title"
            widthIcon={width < 991 ? 24 : 32}
            heightIcon={width < 991 ? 24 : 32}
          />
          <p className="cards-title-profile">Visão geral</p>
        </div>
        <div className="divider-card-title" />
        <div className="cards-items-profile padding-24">
          <p className="paragraph-company-profile">
            {company?.profile?.description}
          </p>
          <div className="flex flex-row flex-wrap display-block">
            <div className="margin-right-80">
              <p className="about-company-site">Site</p>
              <a
                href={
                  company?.profile?.site?.includes("http")
                    ? company?.profile?.site
                    : `http://${company?.profile?.site}` ?? ""
                }
                className="link-site-about-company"
                target="_blank"
                rel="noreferrer"
              >
                {company?.profile?.site}
              </a>
            </div>
            <div className="margin-top-990 margin-right-80">
              <p className="title-view margin-bottom-4">Tipo de empresa</p>
              <p className="view-bold-18">
                {FinancialType[
                  company?.financialType as keyof typeof FinancialType
                ] ?? ""}
              </p>
            </div>
            <div className="margin-top-990 margin-right-80">
              <p className="title-view margin-bottom-4">Tamanho da empresa</p>
              <p className="view-bold-18">
                {SizeCompany[
                  company?.profile?.size as keyof typeof SizeCompany
                ] ?? ""}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="cards-profile">
        <div className="flex-row-center title-cards">
          <AddressCompanyProfile
            className="cards-icon-title"
            widthIcon={width < 991 ? 24 : 32}
            heightIcon={width < 991 ? 24 : 32}
          />
          <p className="cards-title-profile">Localização</p>
        </div>
        <div className="divider-card-title" />
        {company?.profile?.location?.[0] && (
          <div className="cards-items-profile padding-24">
            <p className="title-view margin-bottom-4">
              {company?.profile?.location?.[0]?.city}
            </p>
            <p className="view-bold margin-top-4">
              {company?.profile?.location?.[0]?.address}
              {company?.profile?.location?.[0]?.number &&
                `, ${company?.profile?.location?.[0]?.number}`}
              {company?.profile?.location?.[0]?.adjunct &&
                `, ${company?.profile?.location?.[0]?.adjunct}`}
              {` - ${company?.profile?.location?.[0]?.district} - ${
                company?.profile?.location?.[0]?.city
              } - ${company?.profile?.location?.[0]?.province} - ${
                company?.profile?.location?.[0]?.country
              } - CEP: ${maskZipCode(
                company?.profile?.location?.[0]?.zipCode ?? "",
              )}`}
            </p>
            <div className="margin-top-10">
              <a
                target="_blank"
                href={`https://www.google.com/maps/place/${company.profile.location?.[0].address}+ ${company.profile.location?.[0].number}+ ${company.profile.location?.[0].district}+ ${company.profile.location?.[0].city}+ ${company.profile.location?.[0].province}+ ${company.profile.location?.[0].zipCode}`}
                className="location-company text-decoration-none "
                rel="noreferrer"
              >
                Como chegar
                <MapsExternal className="maps-margin" />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(About);
