import React, { useCallback, useState } from "react";
import { Slider, Tooltip } from "@material-ui/core";
import {
  rubricsBehavior,
  rubricsBehaviorTest,
} from "../../../CourseWorkshop/Steps/data";
import { Button, CardMedia } from "../../../../components";
import { Info } from "../../../../../assets/customIcons";
import "../../style.scss";
import { Media } from "../../../../types/interfaces";
import { mediaType } from "../../../../types/enumerators";
import { ExternalLink } from "../../../../../assets/icons";

interface Props {
  grade?: { type?: string; value?: number }[];
  calcFinal: (
    data: {
      type?: string;
      value?: number;
    }[],
  ) => void;
  behavior?: Media;
}

const Behavior = ({ grade, calcFinal, behavior }: Props): JSX.Element => {
  const [topics, setTopics] = useState<number>(0);
  const rubrics: {
    question: string;
    criterions: {
      type: string;
      goal: string;
      label: string;
      points: {
        info: string;
        value: number;
      }[];
    }[];
  }[] = behavior ? rubricsBehaviorTest : rubricsBehavior;

  const verifyType = useCallback(
    (type) => {
      const filterGrades = grade?.filter(
        ({ type: typeGrade }) => typeGrade === type,
      );

      if (filterGrades && filterGrades.length > 0) return filterGrades[0].value;
      return 0;
    },
    [grade],
  );

  const getValueLabelFormat = useCallback((value, points) => {
    const findIndex = points.findIndex(
      (props: { value: number }) => props.value === value,
    );

    return points[findIndex || 0]?.info;
  }, []);

  const calcBehavior = useCallback(
    (data) => {
      const keys: string[] = [];

      rubrics?.forEach((item: { criterions: { type: string }[] }) =>
        item?.criterions?.forEach((innerItem: { type: string }) =>
          keys.push(innerItem?.type),
        ),
      );

      const filter = data?.filter(
        ({ type }: { type: string }) => type && keys.includes(type),
      );

      if (data && data?.length && filter) {
        const find = filter?.map(({ value }: { value: number }) => value);

        return Number(
          (
            (find?.reduce(
              (total: number, num: number) => (total ?? 0) + (num ?? 0),
              0,
            ) ?? 0) / (keys?.length ?? 1) ?? 0
          ).toFixed(2),
        );
      }

      return 0;
    },
    [rubrics],
  );

  const calcGrade = useCallback(
    (data) => {
      const newGrade = [...(data ?? [])];

      const value = calcBehavior(newGrade);
      const findIndexBehavior = newGrade?.findIndex(
        (item) => item?.type === "BEHAVIOR",
      );

      if (findIndexBehavior !== -1) newGrade[findIndexBehavior].value = value;
      else
        newGrade.push({
          type: "BEHAVIOR",
          value,
        });

      calcFinal(newGrade);
    },
    [calcBehavior, calcFinal],
  );

  const onChange = useCallback(
    (val: number | number[], type: string) => {
      const value = val as number;
      const newGrade = [...(grade ?? [])];
      const fields = {
        type,
        value,
      };
      const check = newGrade?.filter((props) => props.type === type);

      let grades = [...(newGrade || []), fields];
      if (check && check.length > 0)
        grades = newGrade?.map((props) =>
          props.type === type ? fields : props,
        );

      calcGrade(grades);
    },
    [calcGrade, grade],
  );

  return (
    <>
      <div className="propose-title subtitle">
        <h3>Comportamental</h3>
      </div>
      {behavior && (
        <div className={`propose-behavior-test ${behavior?.type}`}>
          {behavior?.type === mediaType.LINK ? (
            <>
              <ExternalLink />
              <a target="blank" href={behavior?.url}>
                {behavior?.url}
              </a>
            </>
          ) : (
            <div className="subscriptions-media-card">
              <CardMedia
                item={{
                  url: behavior?.url,
                  title: behavior?.url,
                }}
                cantEdit
              />
            </div>
          )}
        </div>
      )}
      <div className="questions-section open-questions">
        <div className="propose-form-content">
          {rubrics?.length > 0 && (
            <div>
              <div className="propose-form-buttons">
                {rubrics?.map((_, idx: number) => (
                  <Button
                    key={Math.random()}
                    text={`Questão ${idx + 1}`}
                    onClick={() => setTopics(idx)}
                    className={`button-outline margin-right-12 ${
                      topics === idx ? "is-active" : ""
                    }`}
                  />
                ))}
              </div>
              <h3 className="propose-form-title">
                {rubrics[topics]?.question}
              </h3>
              <div className="propose-form-criterions">
                {rubrics[topics]?.criterions?.map(
                  ({ type, label, goal, points }, idx: number) => (
                    <div
                      key={Math.random()}
                      className="propose-form-criterions-item"
                    >
                      <span>{`Critério ${idx + 1}`}</span>

                      <div className="propose-form-criterions-item-content">
                        <h4>{label}</h4>

                        {goal && (
                          <Tooltip title={<p>{goal}</p>}>
                            <div>
                              <Info className="icon-info pointer" />
                            </div>
                          </Tooltip>
                        )}
                      </div>

                      <div className="propose-form-criterions-item-behavior-slider">
                        <Slider
                          value={verifyType(type)}
                          step={0}
                          min={0}
                          max={points[points.length - 1]?.value}
                          valueLabelDisplay="auto"
                          valueLabelFormat={(value) =>
                            getValueLabelFormat(value, points)
                          }
                          onChange={(_, value) => onChange(value, type)}
                          marks={points.map(({ value }) => ({
                            value,
                            label: value,
                          }))}
                        />
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Behavior;
