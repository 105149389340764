import React, {
  memo,
  MouseEventHandler,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import { CloseBanner } from "../../../assets/icons";
import { Check } from "../../../assets/customIcons";
import "./styles.scss";

interface ButtonProps {
  text: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  showIcon?: boolean;
}

interface Props {
  message?: string;
  secondaryButton?: ButtonProps;
  tertiaryButton?: ButtonProps;
  className?: string;
  iconClose?: boolean;
  notIconClose?: boolean;
}

const Banner = ({
  message,
  secondaryButton,
  className,
  tertiaryButton,
  iconClose,
  notIconClose,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(true);

  const handleCloseIcon = useCallback(
    (event: SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") return;

      setOpen(false);
    },
    [],
  );

  const handleClose = useCallback(() => {
    if (notIconClose) setOpen(false);
  }, [notIconClose]);

  const anchorOrigin: { vertical: "bottom"; horizontal: "center" } = useMemo(
    () => ({
      vertical: "bottom",
      horizontal: `center`,
    }),
    [],
  );

  const action = (
    <div className="buttom-close-banner">
      {iconClose && <CloseBanner onClick={handleCloseIcon} />}
    </div>
  );

  const snackbarBody = (
    <div className="banner">
      <p className="margin-0-mobile">{message}</p>
      {secondaryButton && (
        <Button
          color="secondary"
          size="small"
          onClick={secondaryButton?.onClick}
        >
          {secondaryButton?.showIcon && (
            <div className="banner-icon">
              <Check widthIcon={16} heightIcon={16} />
            </div>
          )}

          {secondaryButton?.text}
        </Button>
      )}
      {tertiaryButton && (
        <Button
          color="secondary"
          size="small"
          onClick={tertiaryButton?.onClick}
        >
          {tertiaryButton?.showIcon && (
            <div className="banner-icon">
              <Check widthIcon={16} heightIcon={16} />
            </div>
          )}
          {tertiaryButton?.text}
        </Button>
      )}
    </div>
  );

  return (
    <Stack>
      <Snackbar
        open={open}
        onClose={handleClose}
        message={snackbarBody}
        action={action}
        anchorOrigin={anchorOrigin}
        className={className}
      />
    </Stack>
  );
};
export default memo(Banner);
