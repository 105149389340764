import React, { MouseEventHandler } from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const AddProfile = ({ widthIcon, heightIcon, onClick }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    onClick={onClick}
    fill="none"
    viewBox="0 0 28 28"
  >
    <circle cx="14.001" cy="14" r="11.667" fill="#2E3451" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M7.9 14c0 .418.338.756.755.756H19.35c.418 0 .756-.338.756-.756s-.338-.756-.756-.756H8.655c-.417 0-.756.338-.756.756z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M14.002 7.897c.418 0 .756.338.756.756v10.694c0 .418-.338.756-.756.756-.417 0-.756-.338-.756-.756V8.653c0-.418.339-.756.756-.756z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
      d="M7.9 14c0 .418.338.756.755.756H19.35c.418 0 .756-.338.756-.756s-.338-.756-.756-.756H8.655c-.417 0-.756.338-.756.756z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".3"
      d="M14.002 7.897c.418 0 .756.338.756.756v10.694c0 .418-.338.756-.756.756-.417 0-.756-.338-.756-.756V8.653c0-.418.339-.756.756-.756z"
      clipRule="evenodd"
    />
  </svg>
);

export default AddProfile;
