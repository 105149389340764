import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const LogoPurple = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "203"}
    height={heightIcon ?? "203"}
    fill="none"
    viewBox="0 0 203 203"
    className="logo-animation-load"
  >
    <path
      fill="#7E459B"
      d="M44.476 152.337v-34.89c0-6.27-2.864-11.691-8.812-11.691v-8.494c5.93 0 8.812-5.424 8.812-11.69V50.845c0-14.065 10.506-22.877 21.183-22.877h10.338v9.31H65.659c-6.087 0-11.186 5.593-11.186 13.56v35.756c0 7.115-2.202 12.533-7.795 14.91 5.593 2.374 7.795 7.799 7.795 14.914v35.76c0 7.795 5.085 13.56 11.186 13.56h10.338v9.31H65.659c-10.677-.003-21.183-8.816-21.183-22.712zM137.341 165.729c6.088 0 11.183-5.765 11.183-13.56v-35.757c0-7.114 2.206-12.532 7.799-14.913-5.593-2.37-7.799-7.795-7.799-14.91V50.846c0-7.967-5.081-13.56-11.183-13.56h-10.337v-9.31h10.337c10.846 0 21.184 8.813 21.184 22.878v34.732c0 6.267 2.864 11.691 8.812 11.691v8.461c-5.933 0-8.812 5.422-8.812 11.691v34.908c0 13.897-10.338 22.709-21.184 22.709h-10.337v-9.31l10.337-.007z"
    />
  </svg>
);

export default LogoPurple;
