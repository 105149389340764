/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  UPDATE_NOTIFICATIONS,
  SET_NOTIFICATIONS,
} from "../types/notificationsTypes";

const initialState: any = [];

export const notificationsReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
): any => {
  switch (type) {
    case SET_NOTIFICATIONS:
      return [...payload];
    case UPDATE_NOTIFICATIONS:
      return [...payload];

    default:
      return state;
  }
};
