/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import {
  TableRow,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Action } from "redux";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { setLoad } from "../../store/actions/configurationsActions";
import {
  HeaderProfile,
  Layout,
  Card,
  Table,
  Modal,
  Select,
  Input,
  Button,
  Pagination,
} from "../../components";
import { Search } from "../../../assets/icons";
import {
  userStatus,
  autoCompleteType,
  userRoles,
} from "../../types/enumerators";
import { AdminRoles } from "../../types/enumerators/GeneralStatus";
import {
  createUser,
  requestSignupLink,
  filterUsersAdmin,
  patchUser,
  getUserById,
  getPages,
  getAutocomplete,
  getUserByIdentification,
} from "../../services/functions";
import "./style.scss";

interface Filters {
  name?: string | null;
  roles?: string | null;
  email?: string | null;
  tags?: string | null;
  status?: string | null;
}

interface Data {
  account: any;
  _id?: string;
  name?: string;
  email?: string;
  roles?: string[];
  status?: string;
  tags?: string;
}

const AdminUser = (): JSX.Element => {
  const [users, setUsers] = useState<Data[]>();
  const [filters, setFilters] = useState<Filters>({});
  const [tagsOptions, setTagsOptions] = useState<string[]>();

  const [modalInvitePeople, setModalInvitePeople] = useState(false);
  const [email, setEmail] = useState("");
  const [rolesUser, setRolesUser] = useState<keyof typeof userRoles>();

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [totalFilteredList, setTotalFilteredList] = useState(0);

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const dispatch = useDispatch();

  const headCells = useMemo(
    () => [
      {
        id: "photo",
        orderBy: false,
        asc: true,
      },
      {
        id: "name",
        label: "Nome",
        orderBy: false,
        asc: true,
      },
      {
        id: "roles",
        label: "Papel",
        orderBy: false,
        asc: false,
      },
      {
        id: "email",
        label: "E-mail",
        orderBy: false,
        asc: false,
      },
      {
        id: "tags",
        label: "Tags",
        orderBy: false,
        asc: false,
        classColumn: "w-160",
      },
      {
        id: "status",
        label: "Status",
        orderBy: false,
        asc: false,
        classColumn: "w-160",
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
        asc: false,
        classColumn: "w-160",
      },
    ],
    [],
  );

  const getAdminUsers = useCallback(
    async (f?: Filters) => {
      try {
        dispatch(setLoad(true) as unknown as Action);

        const currentFilters = f ?? filters;
        if (pageLimit) {
          const totalUsers = await filterUsersAdmin(currentFilters, true);

          const response =
            (await filterUsersAdmin(currentFilters, false, page, pageLimit)) ??
            [];

          const filteredUsers = response
            ?.map((user: Data) => ({
              ...user,
              roles: user?.roles
                ?.filter((role) => role.startsWith("ADMIN"))
                ?.map((role) => AdminRoles[role as keyof typeof AdminRoles])
                ?.join(", "),
            }))
            ?.filter((user) => user?.roles);

          setMaxPage(await getPages(filterUsersAdmin, currentFilters, true));
          setTotalFilteredList(totalUsers?.[0]?.size ?? 0);
          setUsers(filteredUsers as Data[]);
          dispatch(setLoad(false) as unknown as Action);
        }
      } catch {
        toast.error("Ops! Houve um erro ao carregar os dados.");
      }
    },
    [dispatch, filters, page, pageLimit],
  );

  const loadTags = useCallback(async () => {
    const response = await getAutocomplete(autoCompleteType.TAG);
    const optionsAutocompleteTag = response?.map((option) => option?.name);
    setTagsOptions(optionsAutocompleteTag);
  }, []);

  useEffect(() => {
    if (!tagsOptions) loadTags();
  }, [loadTags, tagsOptions]);

  useEffect(() => {
    getAdminUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageLimit]);

  const roles = Object.keys(AdminRoles)
    .filter((key) => key.startsWith("ADMIN"))
    .map((key) => ({
      value: key,
      label: AdminRoles[key as keyof typeof AdminRoles],
    }));

  const onChangeStatus = useCallback(
    async (row: { _id: string }, status: string) => {
      const userId = await getUserById(row?._id ?? "");
      await patchUser(row?._id ?? "", {
        account: {
          ...userId?.account,
          status,
        },
      });
      getAdminUsers();
    },
    [getAdminUsers],
  );

  const allChangeStatus = useCallback(
    (listSelected: { _id: string }[], status: string) => {
      for (let i = 0; i < listSelected.length; i += 1) {
        onChangeStatus(listSelected[i], status);
      }
      getAdminUsers();
    },
    [getAdminUsers, onChangeStatus],
  );

  const createAdmin = useCallback(async () => {
    createUser(email, [rolesUser as keyof typeof userRoles], "", []).then(() =>
      requestSignupLink(email)
        .then(() => setModalInvitePeople(false))
        .catch(() => toast.error("Falha ao enviar o e-mail.")),
    );
  }, [email, rolesUser]);

  const onSave = useCallback(async () => {
    try {
      const hasUser = await getUserByIdentification(email);
      if (
        hasUser?._id &&
        (!hasUser?.account?.roles?.find((item) => item === rolesUser) ||
          hasUser?.account?.status !== userStatus.ACTIVE)
      )
        await patchUser(hasUser?._id, {
          account: {
            ...hasUser.account,
            roles: [...hasUser.account.roles, rolesUser],
            status: userStatus.PENDING,
          },
        });
      else await createAdmin();

      toast.success("Usuário adicionado com sucesso.");
    } catch (err) {
      toast.error("Ops! Ocorreu um erro.");
    }
  }, [createAdmin, email, rolesUser]);

  return (
    <Layout className="admin-user">
      <HeaderProfile />
      <Button
        text="Adicionar"
        className="grid-column-11-13 grid-row-1 admin-company-invite request-vacancy"
        onClick={() => setModalInvitePeople(true)}
      />
      <Card
        className="grid-column-1-13 grid-row-2 margin-bottom-32 margin-top-20"
        bodyClassName="padding-0"
      >
        <TableRow className="top-filters flex width-fill">
          <TextField
            autoComplete="off"
            id="searchAdminUser"
            placeholder="Buscar por usuária"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.name ?? ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                name: e.target.value,
              }))
            }
            onBlur={() => getAdminUsers()}
          />

          <TextField
            autoComplete="off"
            id="searchEmail"
            placeholder="Buscar por e-mail"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.email ?? ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                email: e.target.value,
              }))
            }
            onBlur={() => getAdminUsers()}
          />
          <Select
            className={`status-filter last-filter ${
              filters?.roles?.length ? "focus-selected" : ""
            }`}
            id="roles"
            label="Papel"
            value={filters?.roles ?? ""}
            options={Object.keys(AdminRoles).map((key) => ({
              value: key,
              label: AdminRoles[key as keyof typeof AdminRoles],
            }))}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                roles: e.target.value,
              }));
            }}
            onBlur={() => getAdminUsers()}
          />
          <Select
            className={`status-filter last-filter ${
              filters?.tags?.length ? "focus-selected" : ""
            }`}
            id="tags"
            label="Tags"
            value={filters?.tags ?? ""}
            options={[
              { value: "", label: "Todas" },
              ...(tagsOptions?.map((tag) => ({ value: tag, label: tag })) ??
                []),
            ]}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                tags: e.target.value,
              }))
            }
            onBlur={() => getAdminUsers()}
          />

          <div className="admin-class-radios">
            <span className="admin-class-radios-title">Status</span>
            <RadioGroup>
              <div>
                <FormControlLabel
                  control={<Radio />}
                  label="Todas"
                  checked={!filters.status}
                  className="margin-right-32"
                  onChange={() => {
                    setFilters((prev) => ({
                      ...prev,
                      status: undefined,
                    }));
                    getAdminUsers({
                      ...filters,
                      status: undefined,
                    });
                  }}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Ativas"
                  checked={filters.status === userStatus?.ACTIVE}
                  className="margin-right-32"
                  onChange={() => {
                    setFilters((prev) => ({
                      ...prev,
                      status: userStatus?.ACTIVE,
                    }));
                    getAdminUsers({
                      ...filters,
                      status: userStatus?.ACTIVE,
                    });
                  }}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Inativas"
                  checked={filters.status === userStatus?.PENDING}
                  className="margin-right-32"
                  onChange={() => {
                    setFilters((prev) => ({
                      ...prev,
                      status: userStatus?.PENDING,
                    }));
                    getAdminUsers({
                      ...filters,
                      status: userStatus?.PENDING,
                    });
                  }}
                />
              </div>
            </RadioGroup>
          </div>
        </TableRow>

        <Table
          emptyText="Não há usuárias"
          headCells={headCells}
          list={users?.filter((user) => user?.status !== userStatus?.DISABLED)}
          onActive={(row) =>
            row.status === userStatus?.ACTIVE
              ? onChangeStatus(row, userStatus?.DISABLED)
              : onChangeStatus(row, userStatus?.ACTIVE)
          }
          onBlocked={(row) => onChangeStatus(row, userStatus.BLOCKED)}
          onAllActive={(row) => allChangeStatus(row, userStatus.ACTIVE)}
          onAllDisabled={(row) => allChangeStatus(row, userStatus.DISABLED)}
          onAllBlocked={(row) => allChangeStatus(row, userStatus.BLOCKED)}
        />
      </Card>
      <div className="grid-row-3 grid-column-1-12 margin-top--45">
        <div className="flex">
          <p className="show-vacancies">
            Mostrando
            <span className="title-span">{users?.length}</span>
            de
            <span className="title-span">{totalFilteredList}</span>
            resultados
          </p>
          <Pagination page={page} setPage={setPage} maxPage={maxPage} />
        </div>
      </div>
      <Modal
        headerAction
        open={modalInvitePeople}
        onClose={() => setModalInvitePeople(false)}
        textHeader="Enviar convite"
      >
        <Input
          id="email"
          label="E-mail"
          type="text"
          value={email ?? ""}
          onChange={(e: { target: { value: string } }) =>
            setEmail(e.target.value.toLowerCase().trim())
          }
          className="invite-company"
        />

        <Select
          id="select"
          label="Selecione*"
          options={roles}
          value={rolesUser ?? ""}
          onChange={(e) => setRolesUser(e.target.value)}
        />

        <Button
          onClick={onSave}
          text="Adicionar"
          className="width-100 margin-top-24"
        />
        <p
          className="invite-company-button-cancel"
          onClick={() => setModalInvitePeople(false)}
        >
          Cancelar
        </p>
      </Modal>
    </Layout>
  );
};

export default memo(AdminUser);
