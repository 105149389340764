/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useMemo, useState, useEffect, useCallback } from "react";
import { TableRow, TextField, InputAdornment } from "@material-ui/core";
import { Action } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  HeaderProfile,
  Layout,
  Card,
  Table,
  Button,
  ModalInviteCompany,
  Select,
  Pagination,
} from "../../components";
import {
  filterInvitedCompany,
  patchCompany,
  getPages,
} from "../../services/functions";
import { companyStatus } from "../../types/enumerators";
import { PlanCompanyStatus } from "../../types/constants/Company";
import { Search } from "../../../assets/icons";
import "./style.scss";
import { RootState } from "../../store/reducers";
import { setLoad } from "../../store/actions/configurationsActions";
import { Company } from "../../types/interfaces";

const AdminCompany = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [totalFilteredList, setTotalFilteredList] = useState(0);

  const [openModalInviteCompany, setOpenModalInviteCompany] = useState(false);
  const [data, setData] = useState<any[]>();
  const [filters, setFilters] = useState({ name: "", status: "" });

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const dispatch = useDispatch();

  const history = useHistory();

  const headCells = useMemo(
    () => [
      {
        id: "photo",
        label: "",
        orderBy: false,
        asc: false,
        classColumn: "w-80",
      },
      {
        id: "name",
        label: "Nome",
        orderBy: false,
        asc: true,
        classColumn: "w-160",
      },
      {
        id: "placementVacancy",
        label: "Vagas",
        orderBy: false,
        asc: false,
      },
      {
        id: "status",
        label: "Status",
        orderBy: false,
        asc: true,
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
        asc: false,
        classColumn: "w-160",
      },
    ],
    [],
  );

  const getCompanies = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    try {
      if (pageLimit) {
        const maxPages = await getPages(filterInvitedCompany, filters, true);
        setMaxPage(maxPages);

        const totalCompanies = (await filterInvitedCompany(filters, true)) as {
          size?: number;
        }[];
        if (totalCompanies?.length)
          setTotalFilteredList(totalCompanies?.[0]?.size ?? 0);

        const response = await filterInvitedCompany(
          filters,
          false,
          pageLimit,
          page,
        );

        setData(response as Company[]);
      }
    } catch {
      toast.error("Ops! Houve um erro ao carregar os dados.");
    } finally {
      dispatch(setLoad(false) as unknown as Action);
    }
  }, [dispatch, filters, page, pageLimit]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const statusCompany = useCallback(
    async (row, newStatus) => {
      const success = await patchCompany(row._id, { status: newStatus });
      getCompanies();
      return success;
    },
    [getCompanies],
  );

  const onAllBlocked = useCallback(
    async (row) => {
      for (let index = 0; index < row.length; index += 1) {
        if (row[index].status !== "BLOCKED")
          statusCompany(row[index], "BLOCKED");
      }
    },
    [statusCompany],
  );

  const handleChangeFilter = useCallback(
    (value, field) =>
      setFilters((prevFilters) => ({ ...prevFilters, [field]: value })),
    [setFilters],
  );

  return (
    <Layout className="admin-company">
      <HeaderProfile />
      <Button
        text="Adicionar"
        className="grid-column-11-13 grid-row-1 admin-company-invite request-vacancy"
        onClick={() => setOpenModalInviteCompany(true)}
      />

      <Card
        className=" grid-column-1-13 grid-row-2 margin-bottom-32 margin-top-20"
        bodyClassName="padding-0"
      >
        <TableRow className="top-filters flex width-fill">
          <TextField
            autoComplete="off"
            id="searchCompany"
            placeholder="Buscar empresa"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.name ?? ""}
            onChange={(e) => handleChangeFilter(e.target.value, "name")}
            onBlur={getCompanies}
          />
          <Select
            id="status"
            label="Status"
            value={filters?.status ?? ""}
            options={Object.keys(PlanCompanyStatus).map((key) => ({
              value: key,
              label: PlanCompanyStatus[key as keyof typeof PlanCompanyStatus],
            }))}
            className="admin-company-status"
            onChange={(e) => handleChangeFilter(e.target.value, "status")}
            onBlur={getCompanies}
          />
        </TableRow>

        <Table
          emptyText="Não há empresas cadastradas"
          headCells={headCells}
          list={data}
          onShow={(row) => {
            history.push(
              `/admin-company-view/${row._id}/${row.placementVacancy}`,
            );
          }}
          onActive={(row) =>
            statusCompany(
              row,
              row.status === companyStatus.ACTIVE
                ? companyStatus.DISABLED
                : companyStatus.ACTIVE,
            )
          }
          onBlocked={(row) => {
            if (row.status !== "BLOCKED") {
              statusCompany(row, "BLOCKED"); // TODO: Atualizar data model
            }
          }}
          onAllBlocked={(row) => {
            onAllBlocked(row);
          }}
        />
      </Card>
      <div className="grid-row-3 grid-column-1-12  margin-top--45">
        <div className="flex">
          <p className="show-vacancies">
            Mostrando
            <span className="title-span">{data?.length ?? 0}</span>
            de
            <span className="title-span">{totalFilteredList}</span>
            resultados
          </p>
          <Pagination page={page} setPage={setPage} maxPage={maxPage} />
        </div>
      </div>

      <ModalInviteCompany
        open={openModalInviteCompany}
        onClose={() => setOpenModalInviteCompany(false)}
      />
    </Layout>
  );
};

export default memo(AdminCompany);
