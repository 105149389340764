import React, { memo } from "react";
import LogoBlack from "../../../assets/images/LogoBlack.png";

const ConsentFormCompany = (): JSX.Element => (
  <div className="consent-form">
    <div className="consent-form-logo">
      <img alt="logoBlack" className="reprograma-logo" src={LogoBlack} />
    </div>

    <p className="consent-form-title">
      Termos e Condições de uso da Plataforma "Banco de Talentos"
    </p>
    <ol>
      <li className="subtitle">
        <span>IDENTIFICAÇÃO DA REPROGRAMA</span>

        <p>
          <b>Razão Social:</b> REPROGRAMA
        </p>
        <p>
          <b>Natureza Jurídica:</b> Associação Privada
        </p>
        <p>
          <b>Endereço:</b> Avenida Paulista, nº1439, Conjunto 12, Bela Vista,
          São Paulo/SP, CEP 01311-926
        </p>
        <p>
          <b>CNPJ/MF:</b> 26.549.913/0001-55
        </p>
      </li>

      <li className="subtitle">
        <span>INTRODUÇÃO</span>
        <ol>
          <li>
            Estes Termos e Condições de Uso da Plataforma “Banco de Talentos” da
            REPROGRAMA (“<b>Termos e Condições</b>”), regem, de forma clara e
            transparente, as regras de acesso e uso de informações contidas na
            plataforma online de currículos denominada “Banco de Talentos”
            disponível em [●] (“<b>Plataforma”</b>), de acordo com a legislação
            em vigor, incluindo, mas não se limitando, a Lei nº 13.709, de 14 de
            agosto de 2018, conforme alterada (“<b>LGPD”</b>).
          </li>
          <li>
            Como condição de acesso e uso da Plataforma, o{" "}
            <b>
              Usuário declara que fez a leitura completa e atenta destes Termos
              e Condições, tomando plena ciência deles, conferindo sua livre e
              expressa concordância com as disposições aqui estipuladas.
            </b>
          </li>
          <li>
            Caso o Usuário <b>não esteja de acordo</b> com as disposições destes
            Termos e Condições, deverá descontinuar imediatamente o seu acesso e
            uso da Plataforma.
          </li>
          <li>
            Estes Termos e Condições se aplicam a todas as aplicações e
            funcionalidades disponibilizadas pela Plataforma, podendo haver
            termos específicos para determinadas aplicações e/ou
            funcionalidades, o que será informado oportunamente e de maneira
            clara ao Usuário.
          </li>
        </ol>
      </li>

      <li className="subtitle">
        <span>ACESSO À PLATAFORMA</span>
        <ol className="space-ol">
          <li>
            A Plataforma contém perfis e dados cadastrais de alunas egressas dos
            programas de mentoria e capacitação profissional ofertados pela
            REPROGRAMA, as quais se encontram aptas a ingressar no mercado de
            trabalho.
          </li>
          <li>
            Ao acessar a Plataforma mediante login e senha previamente
            disponibilizados pela REPROGRAMA, o Usuário reconhece e concorda
            que:
          </li>
        </ol>
        <ol className="roman-list">
          <li>
            haverá o cadastro na Plataforma da empresa representada pelo Usuário
            (“
            <b>Empresa Interessada</b>”);
          </li>
          <li>
            serão disponibilizados ao Usuário acessos aos currículos com os
            perfis de candidatas para vagas relacionadas à tecnologia da
            informação;
          </li>
          <li>
            a REPROGRAMA não se responsabiliza por intermediar o processo de
            contratação, sendo este de responsabilidade e liberalidade do
            Usuário e da Empresa Interessada, havendo apenas o compromisso da
            REPROGRAMA de informar às candidatas sobre o interesse da Empresa
            Interessada na realização de processo seletivo para possível
            contratação por meio do disparo de convite para os currículos
            escolhidos pelo Usuário; e
          </li>
          <li>
            a REPROGRAMA não garante que o Usuário encontrará candidatas
            compatíveis com os postos de trabalho disponíveis na Empresa
            Interessada, nem se obriga a restituir qualquer valor ou indenizar o
            Usuário e/ou a Empresa Interessada em caso de o acesso concedido ao
            Usuário não resultar na efetivação de contratos de trabalho entre a
            Empresa Interessada e qualquer candidata cadastrada na Plataforma.
          </li>
        </ol>
        <p className="p-sequence">
          3.3. A REPROGRAMA reserva-se o direito de determinar, a seu exclusivo
          critério, se o acesso e uso da Plataforma pelo Usuário é ou não
          apropriado e está em conformidade com estes Termos e Condições, assim
          como limitar ou bloquear o seu acesso. Nenhuma omissão da REPROGRAMA
          neste sentido deverá ser considerada ou interpretada como concordância
          ou conivência com qualquer utilização indevida e/ou inapropriada da
          Plataforma pelo Usuário.
        </p>
      </li>
      <li className="subtitle">
        <span>RESPONSABILIDADES DO USUÁRIO</span>
        <ol className="space-ol ">
          <li>
            O Usuário, por si e pela Empresa Interessada, reconhece que é de sua
            exclusiva responsabilidade:
          </li>
        </ol>
        <ol className="roman-list margin-bottom-por">
          <li>
            manter a segurança de suas credenciais de login usadas para acessar
            a Plataforma e não compartilhar essas credenciais com terceiros ou
            permitir que terceiros usem a Plataforma;
          </li>
          <li>
            notificar a REPROGRAMA imediatamente pelo e-mail
            dados@reprograma.com.br após tomar conhecimento de qualquer violação
            de segurança ou uso não autorizado de suas credenciais de login
            usadas para acessar a Plataforma;
          </li>
          <li>
            entrar em contato com a candidata de seu interesse via Plataforma e
            promover o processo seletivo e, posteriormente, caso assim deseje,
            de contratação;
          </li>
          <li>
            assegurar o enquadramento da candidata em posto compatível com suas
            competências, respeitados os direitos trabalhistas;
          </li>
          <li>
            prezar pela diversidade e não discriminação durante o processo de
            seleção e contratação das candidatas, comprometendo-se em promover a
            diversidade no setor de tecnologia;
          </li>
          <li>
            toda e qualquer consequência advinda de suas ações no acesso à
            Plataforma e utilização das informações a que tiver acesso; e
          </li>
          <li>
            respeitar as diretrizes de proteção de dados da REPROGRAMA para a
            realização de qualquer Tratamento da base de dados a que tiver
            acesso por meio da Plataforma, o que somente poderá ocorrer
            exclusivamente para finalidades compatíveis com estes Termos e
            Condições.
          </li>
        </ol>
      </li>
      <li className="subtitle">
        <span>DIREITOS DE PROPRIEDADE DA REPROGRAMA</span>
        <ol>
          <li>
            Todos os aspectos da Plataforma, bem como seu conteúdo, recursos e
            funcionalidades, são e continuarão sendo de propriedade exclusiva da
            REPROGRAMA e/ou de seus respectivos licenciadores, conforme o caso.
          </li>

          <li>
            A Plataforma é protegida por direitos de propriedade industrial,
            direitos autorais, direitos de propriedade intelectual de programas
            de computador, marcas registradas e outras leis aplicáveis no Brasil
            e/ou demais países onde tais direitos estejam registrados ou
            protegidos. As marcas registradas da REPROGRAMA não podem ser usadas
            em conexão com qualquer produto ou serviço da Empresa Interessada
            e/ou de terceiros sem o consentimento prévio e por escrito da
            REPROGRAMA.
          </li>
          <li>
            Todos e quaisquer dados e/ou informações inseridos pelo Usuário na
            Plataforma sob estes Termos e Condições poderão, a critério da
            REPROGRAMA, ser mantidos como estiverem ou em formato anonimizado, e
            utilizados para quaisquer fins lícitos relacionados às suas
            atividades.
          </li>
          <li>
            O Usuário concede à REPROGRAMA todos os direitos, títulos e
            interesses sobre qualquer Feedback que venha a fornecer acerca da
            Plataforma. Se, por qualquer motivo, tal cessão for ineficaz, o
            Usuário concorda em conceder à REPROGRAMA um direito e uma licença
            não exclusivos, perpétuos, irrevogáveis, livre de royalties e de
            abrangência mundial para usar, reproduzir, divulgar, sublicenciar,
            distribuir, modificar e explorar tal Feedback sem restrição, sempre
            visando à realização de melhorias na Plataforma.
          </li>
        </ol>
      </li>
      <li className="subtitle">
        <span>CONFIDENCIALIDADE</span>
        <ol>
          <li>
            Para fins destes Termos e Condições, consideram-se “
            <b>Informações Confidenciais</b>” toda e qualquer informação,
            independentemente de sua natureza e de sua indicação como “
            <b>confidencial</b>”, que venha a ser fornecida pela REPROGRAMA ao
            Usuário ou pelo Usuário à REPROGRAMA no âmbito da Plataforma. Para
            fins deste Capítulo, a parte que fornecer Informações Confidenciais
            será considerada uma “<b>Parte Divulgadora</b>” e a parte que
            receber Informações Confidenciais será considerada uma “
            <b>Parte Receptora</b>”.
          </li>

          <li>
            O Usuário concorda que as Informações Confidenciais não incluem
            apenas aquelas informações que a respectiva Parte Receptora possa
            comprovar: <b>(i) </b>ser de domínio público anteriormente ao seu
            recebimento; <b>(ii) </b>que se tornaram pública após o seu
            recebimento da Parte Divulgadora, desde que isto não tenha decorrido
            de uma conduta da Parte Receptora ou tenha implicado violação destes
            Termos e Condições; <b>(iii) </b>que estavam disponíveis à Parte
            Receptora, ou em seu poder, antes da data em que a informação foi
            por ela recebida da Parte Divulgadora; ou <b>(iv) </b>que tenham
            sido desenvolvidas de modo independente pela Parte Receptora por
            meios lícitos, sem qualquer violação a quaisquer disposições destes
            Termos e Condições.
          </li>
          <li>
            O eventual fornecimento de Informações Confidenciais pela Parte
            Divulgadora à Parte Receptora no âmbito da Plataforma não implica
            seu licenciamento, transmissão, transferência, cessão de direito
            e/ou autorização de uso sobre quaisquer segredos de comércio e
            indústria, marcas, patentes e/ou direitos relativos à propriedade
            industrial.
          </li>
          <li>
            A Parte Receptora se compromete a não divulgar nem fornecer
            quaisquer Informações Confidenciais que lhe venham ser prestadas
            pela Parte Divulgadora, objetivando evitar, sob qualquer forma,
            quaisquer prejuízos, perdas ou danos para a Parte Divulgadora que
            sejam resultantes de tal exposição.
          </li>
          <li>
            A Parte Receptora assegurar-se-á que as Informações Confidenciais
            não serão copiadas nem reproduzidas de forma alguma, ressalvados os
            casos de comprovada necessidade de utilização dessas cópias, ou de
            sua reprodução, para finalidades compatíveis com estes Termos e
            Condições.
          </li>
          <li>
            As obrigações de confidencialidade aqui tratadas perdurarão pelo
            prazo durante o qual o Usuário e a Empresa Interessada tiverem
            acesso à Plataforma e por mais 12 (doze) meses contados da data do
            término deste(s) acesso(s).
          </li>
        </ol>
      </li>
      <li className="subtitle">
        <span>PROTEÇÃO DE DADOS</span>
        <ol>
          <li>
            Todos os conceitos adotados nestes Termos e Condições com relação à
            proteção de dados deverão ser aqueles descritos na LGPD ou outra que
            venha a substituí-la. A eventual alteração de qualquer conceito na
            referida norma legal deverá ser imediatamente aplicável e
            incorporada a estes Termos e Condições, independentemente da
            assinatura de qualquer documento entre REPROGRAMA e o Usuário e/ou a
            Empresa Interessada.
          </li>
          <li>
            Mediante acesso à Plataforma, o Usuário reconhece e declara que, com
            relação às candidatas, terá acesso a dados pessoais, assim
            entendidos como qualquer informação relacionada a uma pessoa
            identificada ou identificável, tais como, mas não se limitando a,
            nomes, telefones pessoais, informações de contato (i.e.: endereço,
            e-mail, número de telefone), atribuições de trabalho, dados
            bancários e status de emprego (“<b>Dados Pessoais</b>”), bem como a
            dados pessoais sensíveis, assim entendidos como todos aqueles
            atualmente previstos no artigo 5º da LGPD, a saber, dado pessoal
            sobre origem racial ou étnica, convicção religiosa, opinião
            política, filiação a sindicato ou a organização de caráter
            religioso, filosófico ou político, dado referente à saúde ou à vida
            sexual, dado genético ou biométrico, quando vinculado a uma pessoa
            natural, além da identidade de gênero trans ou não binária (“
            <b>Dados Pessoais Sensíveis</b>”), os quais se encontram sob
            controle da REPROGRAMA. Portanto, a partir do momento em que os
            Dados Pessoais e Dados Pessoais Sensíveis coletados pela REPROGRAMA
            passarem a ser acessados pelo Usuário e, consequentemente, pela
            Empresa Interessada, o Usuário reconhece e declara que passarão a
            ser COCONTROLADORES dos referidos Dados Pessoais e Dados Pessoais
            Sensíveis e deverão garantir a que o seu Tratamento seja feito para
            atingir as finalidades compatíveis com estes Termos e Condições.
          </li>
          <li>
            O Usuário, por si pela Empresa Interessada, compromete-se a utilizar
            os Dados Pessoais e Dados Pessoais Sensíveis exclusivamente no
            limite do que for necessário para a realização dos processos
            seletivos visando à contratação de candidatas cadastradas na
            Plataforma para vagas relacionadas à tecnologia da informação. Os
            Dados Pessoais Sensíveis deverão ser utilizados exclusivamente para
            a finalidade de evitar a fraude dos critérios de privacidade
            adotados pela REPROGRAMA no processo seletivo, conforme previsão do
            artigo 11, inciso II, alínea “g”, da LGPD. O uso para outras
            finalidades somente poderá ser realizado de forma anonimizada ou com
            o consentimento específico e destacado dos Titulares de Dados, a
            menos que possa ser legitimado por uma das outras bases legais
            presentes no artigo 11 da LGPD. Em hipótese alguma a base de dados
            da Plataforma poderá ser comercializada pelo Usuário e/ou pela
            Empresa Interessada.
          </li>
          <li>
            O Usuário, por si e pela Empresa Interessada, enquanto potenciais
            COCONTROLADORES de Dados Pessoais e Dados Pessoais Sensíveis
            contidos na Plataforma, garante e declara à REPROGRAMA que:
          </li>
          <ol className="roman-list">
            <li>
              adotam, mantêm e aplicam políticas de segurança adequadas, tais
              como, mas não se limitando a, <b>(a) </b>controle de acesso,{" "}
              <b>(b) </b>controle de entrada de dados, <b>(c) </b>controle de
              transferência e processamento de dados, e <b>(d) </b>controle de
              disponibilidade;
            </li>
            <li>
              protegerão e salvaguardarão os Dados Pessoais e Dados Pessoais
              Sensíveis a que tiverem acesso por meio da Plataforma como se seus
              os fossem;
            </li>
            <li>
              informarão à REPROGRAMA e obterão sua autorização prévia para o
              compartilhamento de Dados Pessoais e/ou Dados Pessoais Sensíveis
              com terceiros, quando esse compartilhamento for necessário para
              atingir as finalidades compatíveis com estes Termos e Condições; e
            </li>
            <li>
              todos os eventuais contratos firmados por operadores de dados
              presentes na cadeia de Tratamento relacionada ao acesso e uso da
              Plataforma contenham disposições específicas sobre a proteção de
              Dados Pessoais e Dados Pessoais Sensíveis que garantam a proteção
              dos referidos dados e a impossibilidade de seu uso para
              finalidades discriminatórias e/ou alheias a estes Termos e
              Condições.
            </li>
          </ol>
        </ol>
        <p className="p-sequence">
          7.5. Na hipótese de uso dos Dados Pessoais e/ou Dados Pessoais
          Sensíveis para outras finalidades por parte do Usuário e/ou da Empresa
          Interessadas, o Usuário, por si e pela Empresa Interessada, reconhece
          que atuarão como controladores independentes dos referidos dados e
          deverão atribuir a tal Tratamento, extrínseco às finalidades atreladas
          a estes Termos e Condições, a base legal adequada para justificá-lo,
          dando a devida transparência quanto a esta(s) finalidade(s) para o
          Titular dos Dados.
        </p>
        <p className="p-sequence">
          7.6. O Usuário deverá, a exclusivo critério da REPROGRAMA, restituir
          ou eliminar, ou fazer com que a Empresa Interessada restitua ou
          elimine, os Dados Pessoais e/ou Dados Pessoais Sensíveis tratados no
          âmbito do seu acesso e uso da Plataforma após: <b>(i) </b>serem
          cumpridas as finalidades de Tratamento dos Dados Pessoais e/ou Dados
          Pessoais Sensíveis compatíveis com estes Termos e Condições; ou{" "}
          <b>(ii) </b>ser definitivamente encerrado o acesso do Usuário à
          Plataforma; ou <b>(iii) </b>o recebimento de instrução específica da
          REPROGRAMA para a exclusão de Dados Pessoais e/ou Dados Pessoais
          Sensíveis por parte do Usuário.
        </p>
        <p className="p-sequence">
          7.7. A REPROGRAMA, por sua vez, na condição de prestadora de serviços
          de ensino às candidatas cadastradas na Plataforma, compromete-se a
          garantir que a coleta e o Tratamento de Dados Pessoais e Dados
          Pessoais Sensíveis se dão em observância à LGPD, e que a coleta se dá
          mediante o consentimento livre, informado e inequívoco das candidatas,
          caso a caso, especialmente em relação aos Dados Pessoais Sensíveis, a
          fim de possibilitar a disponibilização dos seus currículos na
          Plataforma.
        </p>
        <p className="p-sequence">
          7.8. O Usuário reconhece que, a despeito de todos os esforços que
          venham a ser empreendidos pela REPROGRAMA para realizar a proteção dos
          Dados Pessoais e Dados Pessoais Sensíveis por meio de práticas
          adequadas e reconhecidas compatíveis com a sensibilidade dos referidos
          dados, não há qualquer sistema permanentemente imune a ameaças ou
          vulnerabilidades. Assim, o Usuário se compromete a colaborar com a
          REPROGRAMA para fins de manutenção dos Dados Pessoais e Dados Pessoais
          Sensíveis em um ambiente seguro.
        </p>
        <p className="p-sequence">
          7.9. O Usuário concorda em cooperar com a REPROGRAMA para evitar e
          gerenciar incidentes de vazamento de dados, notificando o Titular dos
          Dados e à autoridade competente quando isso for necessário, para
          responder e adequar o seu Tratamento de Dados em decorrência de
          eventuais requisições de direitos pelos Titulares dos Dados, bem como
          para responder a eventuais requisições da Autoridade Nacional de
          Proteção de Dados e outras autoridades administrativas ou judiciais
          que possam demandar a REPROGRAMA no que tange o Tratamento dos Dados
          Pessoais e Dados Pessoais Sensíveis.
        </p>
        <p className="p-sequence">
          7.10. A REPROGRAMA fica isenta de toda e qualquer responsabilidade
          referente a prejuízos diretos ou indiretos e/ou sanções
          administrativas relacionados a incidentes de segurança da informação
          que se relacionem a violações de confidencialidade, integridade ou
          disponibilidade, que sejam ocasionados por qualquer ação ou omissão do
          Usuário.
        </p>
      </li>
      <li className="subtitle">
        <span>LIMITAÇÃO DE RESPONSABILIDADE DA REPROGRAMA</span>
        <ol>
          <li>
            Na extensão máxima permitida pela lei aplicável, em nenhum caso ou
            hipótese, a REPROGRAMA será responsável por quaisquer danos diretos,
            especiais, incidentais ou indiretos de qualquer natureza (incluindo,
            mas não se limitando a, danos por lucros cessantes, perda de dados
            ou outras informações, por interrupção de negócios, por danos
            pessoais, perda de privacidade decorrente ou de qualquer forma
            relacionada ao uso ou incapacidade de usar a Plataforma, software de
            terceiros e/ou hardware de terceiros usado conjuntamente com a
            Plataforma, ou de outra forma em conexão com qualquer disposição
            destes Termos e Condições).
          </li>
          <li>
            Se as leis aplicáveis não permitirem a exclusão de garantias
            implícitas ou limitação de responsabilidade por danos diretos,
            especiais, incidentais e/ou indiretos, a responsabilidade da
            REPROGRAMA será limitada na maior extensão permitida por lei.
          </li>
          <li>
            O acesso à Plataforma é concedido ao Usuário “NO ESTADO EM QUE SE
            ENCONTRA” e “CONFORME DISPONÍVEL”, o que inclui, portanto, todas as
            suas eventuais falhas e defeitos, sem garantia de qualquer tipo. Na
            extensão máxima permitida pela lei aplicável, a REPROGRAMA, por
            conta própria e em nome de seus respectivos licenciadores e
            fornecedores, não fornece nenhuma garantia ou compromisso, e não faz
            nenhuma declaração de qualquer tipo de que a Plataforma atenderá às
            necessidades do Usuário, alcançará quaisquer resultados pretendidos,
            será compatível ou funcionará com quaisquer outros softwares,
            aplicativos, sistemas ou serviços, operará sem interrupção, atenderá
            a quaisquer padrões de desempenho ou confiabilidade ou estará livre
            de erros ou que quaisquer erros ou defeitos poderão ser ou serão
            corrigidos.
          </li>
          <li>
            A REPROGRAMA ou qualquer um de seus fornecedores não fazem qualquer
            declaração ou garantia de qualquer tipo, expressa ou implícita:
            <b>(i) </b>que o acesso à Plataforma será ininterrupto ou livre de
            erros;
            <b>(ii) </b>quanto à atualidade de qualquer informação ou conteúdo
            fornecido por meio da Plataforma; ou <b>(iii) </b>que a Plataforma,
            seus servidores, o conteúdo ou mensagens ou e-mails enviados por ou
            em nome da REPROGRAMA estarão livres de vírus, scripts, cavalos de
            Tróia, worms, malware, bombas-relógio ou outros componentes
            prejudiciais.
          </li>
          <li>
            Caso a legislação aplicável venha a considerar o Usuário e/ou a
            Empresa Interessada um consumidor de serviços disponibilizados via
            Plataforma, as exclusões e limitações estabelecidas neste Capítulo
            serão aplicadas na maior medida do possível, de acordo com a lei
            aplicável.
          </li>
        </ol>
      </li>
      <li className="subtitle">
        <span>LEI APLICÁVEL</span>
        <ol>
          <li>
            As leis da República Federativa do Brasil, excluindo seus conflitos
            de regras legais, regerão estes Termos e Condições.
          </li>
        </ol>
      </li>
      <li className="subtitle margin-left-10">
        <span> RESOLUÇÃO DE DISPUTAS</span>
        <ol>
          <li>
            Se o Usuário tiver alguma preocupação ou surgir qualquer
            controvérsia sobre a Plataforma, ele concorda em primeiro tentar
            resolver a disputa informal e amigavelmente, entrando em contato com
            a REPROGRAMA. Não obstante, fica desde já eleito o Foro da Comarca
            de São Paulo/SP como único competente para dirimir quaisquer
            disputas e/ou conflitos relacionados a estes Termos e Condições, com
            exclusão de qualquer outro, por mais privilegiado que seja.
          </li>
        </ol>
      </li>
      <li className="subtitle margin-left-10">
        <span> DIVISIBILIDADE</span>
        <ol>
          <li>
            Se qualquer disposição destes Termos e Condições for considerada
            inexequível ou inválida, tal disposição será alterada e interpretada
            para atingir os objetivos de tal disposição na maior medida possível
            sob a lei aplicável e as disposições restantes continuarão em pleno
            vigor e efeito.
          </li>
        </ol>
      </li>
      <li className="subtitle margin-left-10">
        <span> RENÚNCIA</span>
        <ol>
          <li>
            Exceto conforme disposto nestes Termos e Condições, a falha em
            exercer um direito ou exigir o cumprimento de uma obrigação sob
            estes Termos e Condições não afetará a capacidade de uma parte de
            exercer tal direito ou exigir tal desempenho a qualquer momento a
            partir de então, nem a renúncia a uma violação constituirá uma
            renúncia a qualquer violação subsequente.
          </li>
        </ol>
      </li>
      <li className="subtitle margin-left-10">
        <span> ALTERAÇÕES A ESTES TERMOS E CONDIÇÕES</span>
        <ol>
          <li>
            A REPROGRAMA reserva-se o direito, a seu exclusivo critério, de
            modificar ou substituir estes Termos e Condições a qualquer momento,
            com ou sem aviso prévio. Por exemplo, a REPROGRAMA poderá adicionar
            ou remover funcionalidades ou recursos da Plataforma, e poderá
            suspender ou interromper completamente um recurso específico ou
            criar/alterar tabelas de preços específicas para determiandos
            acessos dentro da Plataforma. Se, a exclusivo critério da
            REPROGRAMA, uma revisão for considerada materialmente relevante, a
            REPROGRAMA envidará esforços razoáveis para fornecer pelo menos duas
            semanas de antecedência antes de quaisquer novos termos entrarem em
            vigor. Ao continuar a acessar ou usar a Plataforma após quaisquer
            revisões entrarem em vigor, o Usuário concorda em se vincular aos
            novos termos revisados. Se o Usuário não concordar com os novos
            termos revisados, no todo ou em parte, o Usuário deverá
            imediatamente cancelar o seu acesso e parar de utilizar a
            Plataforma.
          </li>
        </ol>
      </li>
      <li className="subtitle margin-left-10 margin-left-15">
        <span> FALE CONOSCO</span>
        <ol>
          <li>
            Se o Usuário tiver alguma dúvida sobre estes Termos e Condições,
            entre em contato com a REPROGRAMA pelo e-mail
            dados@reprograma.com.br .
          </li>
        </ol>
      </li>
    </ol>

    <p>
      Caso <span> ACEITE</span> este "Termo e condições de uso da Plataforma
      Banco de Talentos", por favor, clique em <span>“SIM”</span>.
    </p>
  </div>
);

export default memo(ConsentFormCompany);
