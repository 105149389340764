/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  TextField,
  InputAdornment,
  ClickAwayListener,
  Paper,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Search, Unblock } from "../../../assets/customIcons";
import {
  Layout,
  HeaderProfile,
  Card,
  CardJob,
  CardMenuLeft,
  Button,
  RecentSearch,
  Pagination,
} from "../../components";
import "./style.scss";
import {
  getAllPlacementVacancyWithCorporateData,
  getPages,
  searchPlacementVacancy,
} from "../../services/functions";
import { RootState } from "../../store/reducers";
import {
  setLoad,
  setPageLimit,
} from "../../store/actions/configurationsActions";
import { placementVacancyManagedBy } from "../../types/enumerators";

interface VacancyProps {
  title?: string;
  jobTitle?: string;
  companyName?: string;
  photo?: string;
  jobStatus?: string;
  jobTime?: string | Date;
  jobPlace?: string;
  jobType?: string;
}

interface Filters {
  managedBy?: string[];
}

const dictionaryManagedBy = {
  Todos: undefined,
  Plataforma: "plataform",
  Curadoria: "curation",
};

const Vacancies = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isSearchAreaOpen, setIsSearchAreaOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [searchParams, setSearchParams] = useState("");
  const [vacancies, setVacancies] = useState<VacancyProps[]>();
  const [vacancySearch, setVacancySearch] = useState<VacancyProps[]>();
  const [recentsVacancies, setRecentsVacancies] = useState<any[]>(
    JSON.parse(
      localStorage.getItem("@SESSION:recentsVacancies" ?? "") ?? "null",
    ),
  );
  const [vacanciesManagedBy, setVacanciesManagedBy] = useState<string>();

  const history = useHistory();
  const dispatch = useDispatch();

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  useEffect(() => {
    dispatch(setPageLimit(8) as unknown as Action);
  }, [dispatch]);

  const handleClick = useCallback(
    (params: string) => {
      history.push({
        pathname: `/vacancies${params}`,
      });
    },
    [history],
  );

  useEffect(() => {
    if (history) {
      const match = history.location.pathname.match("^/vacancies/([^/]+)/?$");
      if (match) setVacanciesManagedBy(match[1]);
      else setVacanciesManagedBy(undefined);
    }
  }, [history, history.location.pathname]);

  const filter: Filters = useMemo(() => {
    let updatedManagedBy;
    const plataformValues = ["GUPY", "GREENHOUSE", "REPROGRAMA"];
    const curationValues = ["EXTERNAL_VACANCY"];

    if (vacanciesManagedBy) {
      if (vacanciesManagedBy === "curation") updatedManagedBy = curationValues;
      else if (vacanciesManagedBy === "plataform")
        updatedManagedBy = plataformValues;
      else updatedManagedBy = undefined;
    }

    return {
      managedBy: updatedManagedBy,
    };
  }, [vacanciesManagedBy]);

  const loadVacancies = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    if (pageLimit) {
      setMaxPage(
        await getPages(getAllPlacementVacancyWithCorporateData, filter, true),
      );

      const response = await getAllPlacementVacancyWithCorporateData(
        filter,
        false,
        page,
      );

      const finalData = response?.map((item) => ({
        jobTitle: item?.title,
        companyName:
          item?.managedBy === placementVacancyManagedBy.EXTERNAL_VACANCY
            ? item?.externalCompanyName
            : item?.companyName,
        photo: item?.photo,
        jobPlace: item?.place,
        jobType: item?.type,
        jobStatus: item?.status,
        jobManagedBy: item?.managedBy,
        jobTypeOfCandidature: item?.typeOfCandidature,
        jobTime: item?.startDate ?? "",
        managedBy: placementVacancyManagedBy.EXTERNAL_VACANCY,
        id: item?._id,
      }));

      setVacancies(finalData ?? []);
    }
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, pageLimit, filter, page]);

  useEffect(() => {
    loadVacancies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageLimit, filter]);

  const handleClickSearch = useCallback(
    (vacancy: VacancyProps) => {
      setIsSearchAreaOpen(false);

      let newRecentsVacancies: any[] = [];
      if (recentsVacancies?.length) newRecentsVacancies = [...recentsVacancies];

      if (!newRecentsVacancies?.find((item) => item?.title === vacancy.title)) {
        if (newRecentsVacancies?.length < 4) newRecentsVacancies?.push(vacancy);
        else {
          newRecentsVacancies?.splice(0, 1);
          newRecentsVacancies?.push(vacancy);
        }
        setRecentsVacancies(newRecentsVacancies);
        localStorage.setItem(
          "@SESSION:recentsVacancies",
          JSON.stringify(newRecentsVacancies),
        );
      }

      history.push({
        pathname: "/vacancies-advanced/",
        state: {
          vacancy,
        },
      });
    },
    [history, recentsVacancies],
  );

  const handleInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setSearchParams(event.target.value);
      setIsSearchAreaOpen(true);
      try {
        const newVacancies = await searchPlacementVacancy(
          event.target.value,
          3,
        );
        setVacancySearch(newVacancies ?? []);
      } catch (err) {
        toast.error("Ocorreu um erro ao fazer a busca");
      }
    },
    [],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-responsive-register vacancies">
      <HeaderProfile />
      <div className="grid-column-1-13">
        <div className="grid-template-columns-1-5 display-block">
          <CardMenuLeft
            icon={<Unblock widthIcon={20} heightIcon={20} />}
            selectedMenuTitle="Candidaturas"
          />
          <div className="margin-top-16-responsive">
            <Card
              removeTitleBorder
              titleHeader="Busque seu novo emprego"
              className="border-card"
            >
              <div className="grid-template-columns-5-1 margin-bottom-24 display-block">
                <div className="searchInputWrapper">
                  <TextField
                    placeholder="Buscar cargo ou competência"
                    type="text"
                    onClick={() => setIsSearchAreaOpen(!isSearchAreaOpen)}
                    autoComplete="off"
                    onChange={handleInputChange}
                    value={searchParams}
                    className="search-job-greater-input"
                    InputProps={{
                      className:
                        "search-job-input search-vacancies Mui-input headerSearch header-input-search",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          className="margin-left-12"
                        >
                          <Search
                            widthIcon={width < 991 ? 20 : 24}
                            heightIcon={width < 991 ? 20 : 24}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {isSearchAreaOpen && (
                    <Paper>
                      <ClickAwayListener
                        onClickAway={() => setIsSearchAreaOpen(false)}
                      >
                        <div className="search-area">
                          <div>
                            {vacancySearch?.map((vacancy) => (
                              <div
                                key={Math.random()}
                                className="pointer"
                                role="none"
                                onClick={() => handleClickSearch(vacancy)}
                              >
                                <RecentSearch
                                  itemName={vacancy?.title ?? ""}
                                  itemCategory="Vagas"
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </ClickAwayListener>
                    </Paper>
                  )}
                </div>
                <Button
                  text="Buscar"
                  className="max-width-100 min-width-200 margin-top-24-mobile width-100-responsive"
                  onClick={() =>
                    history.push({
                      pathname: "/vacancies-advanced",
                      state: { searchParams },
                    })
                  }
                />
              </div>
              <div className="divider width-100" />
              <h3 className="h1-recent-search">Buscas Recentes</h3>
              {recentsVacancies?.length > 0 ? (
                recentsVacancies?.map((item) => (
                  <span
                    key={Math.random()}
                    className="span-recent-search width-fit"
                  >
                    {item?.title}
                  </span>
                ))
              ) : (
                <p className="paragraph-not-suggested">
                  Não há buscas recentes
                </p>
              )}
            </Card>
            <div className="card-candidature-100 margin-vacancies card-candidature-32">
              <div className="card-title-div">
                {vacanciesManagedBy !== "curation" ? (
                  <>
                    <h2 className="h2-bold">Recomendações pra você</h2>
                    <p className="paragraph-vacancies-suggested">
                      Com base no seu perfil e histórico de pesquisas
                    </p>
                  </>
                ) : (
                  <h2 className="h2-bold">Curadoria</h2>
                )}
                <div className="card-vacancies-type">
                  {Object.entries(dictionaryManagedBy).map(([key, value]) => (
                    <Button
                      key={Math.random()}
                      className={vacanciesManagedBy === value ? "active" : ""}
                      outline
                      appearance="secondary"
                      text={key}
                      onClick={() => {
                        setVacanciesManagedBy(value);
                        handleClick(value ? `/${value}` : "");
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="card-vacancies scroll-mobile">
                {vacancies && vacancies?.length > 0 ? (
                  vacancies?.map((item) => (
                    <CardJob key={Math.random()} data={item} />
                  ))
                ) : (
                  <p className="paragraph-not-suggested">
                    No momento não há recomendações
                  </p>
                )}
              </div>
            </div>
            <div className="grid-column-1-10 div-search margin-vacancies padding-bottom-16">
              <div className="flex-row-center">
                <Pagination
                  page={page}
                  setPage={setPage}
                  maxPage={maxPage}
                  hideOptions
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default memo(Vacancies);
