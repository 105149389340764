import React, { MouseEventHandler, IntrinsicAttributes } from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Close = ({
  widthIcon,
  heightIcon,
  className,
  onClick,
  ...props
}: IntrinsicAttributes & Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "28"}
    height={heightIcon ?? "28"}
    onClick={onClick}
    fill="none"
    viewBox="0 0 32 32"
    className={className ?? ""}
    {...props}
  >
    <path
      fill="#606060"
      fillRule="evenodd"
      d="M7.122 7.123c-.608.607-.608 1.592 0 2.2l15.555 15.555c.608.607 1.593.607 2.2 0 .608-.607.608-1.592 0-2.2L9.322 7.123c-.608-.608-1.593-.608-2.2 0z"
      clipRule="evenodd"
    />
    <path
      fill="#606060"
      fillRule="evenodd"
      d="M24.877 7.123c.608.607.608 1.592 0 2.2L9.322 24.877c-.608.607-1.593.607-2.2 0-.608-.607-.608-1.592 0-2.2L22.677 7.123c.608-.608 1.593-.608 2.2 0z"
      clipRule="evenodd"
    />
  </svg>
);

export default Close;
