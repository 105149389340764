import React, { memo } from "react";
import "../style.scss";
import { CardRegister } from "../../../components";

const FinishedWorkshopError = (): JSX.Element => (
  <CardRegister
    titleHeader="Sua inscrição"
    className="margin-top-16 margin-mobile-register border-card grid-column-5-12 grid-column-1-11-mobile"
  >
    <div className="information">
      <p className="information-title error">
        Ops! Que pena, essa oficina já foi encerrada e não podemos mais receber
        seu envio.
      </p>
      <p className="information-title error">
        {" "}
        Qualquer dúvida entre em contato com as responsáveis pela sua oficina ou
        envie um e-mail para{" "}
        <a href="mailto:plataforma@reprograma.com.br">
          plataforma@reprograma.com.br
        </a>
        .
      </p>
    </div>
  </CardRegister>
);
export default memo(FinishedWorkshopError);
