import React, { memo } from "react";
import "../style.scss";

interface Props {
  title: string;
}

const Path = ({ title }: Props): JSX.Element => (
  <div className="grid-column-1-9 grid-row-1 flex admin-subscriptions-class-vacancy-title">
    <p className="view-page">Processos &gt;&nbsp;</p>
    <p className="view-bold-16">{title}</p>
  </div>
);

export default memo(Path);
