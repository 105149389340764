import { enums } from "reprograma-types";

export const autoCompleteType = { ...enums.Autocomplete.type };
export const autoCompleteStatus = { ...enums.Autocomplete.status };

export const classStatus = { ...enums.Class.status };
export const classStructure = { ...enums.Class.classStructure };
export const classStepType = { ...enums.Class.classStepType };

export const classVacancyStatus = { ...enums.ClassVacancy.status };
export const classVacancyType = { ...enums.ClassVacancy.type };
export const classVacancyTemplate = { ...enums.ClassVacancy.template };
export const classVacancyCustomQuestionType = {
  ...enums.ClassVacancy.customQuestionType,
};

export const classVacancySubscriptionStatus = {
  ...enums.ClassVacancySubscription.status,
};
export const classVacancySubscriptionNumItems = {
  ...enums.ClassVacancySubscription.numItems,
};
export const classVacancySubscriptionOriginOfWater = {
  ...enums.ClassVacancySubscription.originOfWater,
};
export const classVacancySubscriptionTypeOfStreet = {
  ...enums.ClassVacancySubscription.typeOfStreet,
};
export const classVacancySubscriptionVolunteerDescription = {
  ...enums.ClassVacancySubscription.volunteerDescription,
};
export const classVacancySubscriptionDifficultiesProgramming = {
  ...enums.ClassVacancySubscription.difficultiesProgramming,
};
export const classVacancySubscriptionLevel = {
  ...enums.ClassVacancySubscription.level,
};
export const classVacancySubscriptionEmployabilitySituation = {
  ...enums.ClassVacancySubscription.employabilitySituation,
};
export const classVacancySubscriptionUnemployedTime = {
  ...enums.ClassVacancySubscription.unemployedTime,
};
export const classVacancySubscriptionEducationLevel = {
  ...enums.ClassVacancySubscription.educationLevel,
};

export const companyStatus = { ...enums.Company.status };
export const companyType = { ...enums.Company.type };
export const companyFinancialType = { ...enums.Company.financialType };
export const companyPlanStatus = { ...enums.Company.planStatus };
export const companyAutocomplete = { ...enums.Company.autocomplete };
export const companySocialMedias = { ...enums.Company.socialMedias };

export const contractType = { ...enums.Contract.type };

export const historicActionType = { ...enums.Historic.actionType };
export const historicEntity = { ...enums.Historic.entity };

export const placementVacancyStatus = { ...enums.PlacementVacancy.status };
export const placementVacancyTypeOfCandidature = {
  ...enums.PlacementVacancy.typeOfCandidature,
};
export const placementVacancyManagedBy = {
  ...enums.PlacementVacancy.managedBy,
};
export const placementVacancyType = { ...enums.PlacementVacancy.type };

export const placementVacancySubscriptionStatus = {
  ...enums.PlacementVacancySubscription.status,
};

export const researchType = { ...enums.Research.type };
export const researchEducationLevel = { ...enums.Research.educationLevel };
export const researchCurrentSituation = { ...enums.Research.currentSituation };
export const researchCurrentJoblevel = { ...enums.Research.currentJoblevel };
export const researchFirstTechJob = { ...enums.Research.firstTechJob };
export const researchPlatformCurrentTechJob = {
  ...enums.Research.platformCurrentTechJob,
};
export const researchNumberSelectionProcesses = {
  ...enums.Research.numberSelectionProcesses,
};
export const researchJobsSinceGraduation = {
  ...enums.Research.jobsSinceGraduation,
};
export const researchDurationEmploymentGraduation = {
  ...enums.Research.durationEmploymentGraduation,
};
export const researchReasonLeavingWork = {
  ...enums.Research.reasonLeavingWork,
};
export const researchCourseLevel = { ...enums.Research.courseLevel };
export const researchUndertakenArea = { ...enums.Research.undertakenArea };
export const researchCompanySegment = { ...enums.Research.companySegment };
export const researchTimeEmployed = { ...enums.Research.timeEmployed };
export const userPronous = { ...enums.User.pronouns };
export const userIdentityType = { ...enums.User.identityType };
export const userGenderIdentity = { ...enums.User.genderIdentity };
export const userSocialMediaType = { ...enums.User.socialMediaType };
export const userRace = { ...enums.User.race };
export const userStatus = { ...enums.User.status };
export const userBootcampsStatus = { ...enums.User.bootcampsStatus };
export const userBootcampsScholarshipType = { ...enums.User.scholarshipType };
export const userRoles = { ...enums.User.roles };
export const userOpenForProposals = { ...enums.User.openForProposals };
export const userEducationLevel = { ...enums.User.educationLevel };
export const userPortfolioType = { ...enums.User.portfolioType };
export const userEventType = { ...enums.User.eventType };

export const integrationPlataform = { ...enums.Integration.platform };
export const mediaType = { ...enums.Media.type };
