import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import moment from "moment-timezone";
import { Button, IconButton } from "../../../components";
import { FolderMin, FolderMinBlue } from "../../../../assets/icons";
import { classStatus } from "../../../types/enumerators";
import { Class } from "../../../types/interfaces";
import {
  sendWorkshopConfirm,
  updateClassById,
} from "../../../services/functions";
import Workshop from "../Modal/workshop";
import { setLoad } from "../../../store/actions/configurationsActions";
import "../style.scss";

interface Props {
  data: Class;
  onClick: () => void;
  reload: () => void;
}

interface ButtonProps {
  workshop: Class;
  manager: (key: string) => void;
  setOpenModal: (value: boolean) => void;
  reload: () => void;
}

const ButtonManagerWorkshop = ({
  workshop,
  manager,
  setOpenModal,
  reload,
}: ButtonProps) => {
  if (!workshop) return null;

  const hasConfirmation = useMemo(
    () =>
      workshop?.status === classStatus.ACTIVE &&
      !workshop?.isActive &&
      !workshop?.confirmationWorkshop,
    [workshop?.confirmationWorkshop, workshop?.isActive, workshop?.status],
  );

  const hasActive = useMemo(
    () => workshop?.confirmationWorkshop && !workshop?.isActive,
    [workshop?.confirmationWorkshop, workshop?.isActive],
  );

  const finishedStatus = useMemo(
    () => workshop?.confirmationWorkshop && workshop?.isActive,
    [workshop?.confirmationWorkshop, workshop?.isActive],
  );

  const text = useMemo(
    () => (hasConfirmation ? "Confirmar" : hasActive ? "Iniciar" : "Encerrar"),
    [hasActive, hasConfirmation],
  );

  const handleClickWorkshop = useCallback(() => {
    if (hasConfirmation) setOpenModal(true);

    if (hasActive) manager("active");

    if (finishedStatus) manager("finish");

    reload();
  }, [
    finishedStatus,
    hasActive,
    hasConfirmation,
    manager,
    reload,
    setOpenModal,
  ]);

  return text
    ? ((
        <Button
          key={Math.random()}
          text={text}
          className="table-actions"
          onClick={handleClickWorkshop}
        />
      ) as JSX.Element)
    : null;
};

const Buttons = ({ data, onClick, reload }: Props): JSX.Element => {
  const [workshop, setWorkshop] = useState<Class>(data);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  const onChange = useCallback(
    (value, props: "url" | "info") =>
      setWorkshop({
        ...workshop,
        [props]: value,
      } as Class),
    [workshop],
  );

  // TODO: remover horário fixo
  const sendConfirmation = useCallback(async () => {
    await sendWorkshopConfirm(workshop?._id ?? "", {
      // date:
      //   moment(workshop?.startDate)
      //     .tz("America/Sao_Paulo")
      //     .format("DD/MM [às] HH:mm") ?? "",
      date: `${moment(workshop?.startDate).format("DD/MM")} às 09:00`,
      url: workshop?.url ?? "",
      info: workshop?.info ?? "",
    });
  }, [workshop]);

  const managerWorkshop = useCallback(
    async (props?: string) => {
      dispatch(setLoad(true) as unknown as Action);
      let currentClass = {};

      if (props === "confirm") {
        sendConfirmation();
        currentClass = { ...workshop, confirmationWorkshop: true };
      }

      if (props === "active") currentClass = { isActive: true };

      if (props === "finish")
        currentClass = { status: classStatus.FINISHED, isActive: false };

      await updateClassById(workshop?._id ?? "", currentClass as Class);

      reload();
      setOpenModal(false);
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, reload, sendConfirmation, workshop],
  );

  useEffect(() => {
    setWorkshop(data);
  }, [data]);

  return (
    <div
      className={`${
        data?.status !== classStatus.FINISHED
          ? "grid-column-8-13"
          : "grid-column-11-13"
      } grid-row-1 documents-button`}
    >
      <IconButton
        icon={
          data?.status !== classStatus.FINISHED ? (
            <FolderMinBlue />
          ) : (
            <FolderMin />
          )
        }
        text="VER DOCUMENTOS"
        className={`${
          data?.status !== classStatus.FINISHED
            ? "documents-transparent"
            : "table-actions"
        } `}
        onClick={onClick}
      />
      {data?.status !== classStatus.FINISHED && (
        <ButtonManagerWorkshop
          workshop={workshop as Class}
          manager={managerWorkshop}
          setOpenModal={setOpenModal}
          reload={reload}
        />
      )}

      <Workshop
        open={openModal}
        close={() => setOpenModal(false)}
        workshop={workshop}
        onChange={onChange}
        onConfirm={() => managerWorkshop("confirm")}
      />
    </div>
  );
};

export default memo(Buttons);
