/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Slider } from "@material-ui/core";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Add, Delete, Move } from "../../../../assets/customIcons";
import {
  Card,
  Draggable,
  IconButton,
  InputAutocomplete,
} from "../../../components";
import { updateUser } from "../../../store/actions/userActions";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { getAutocomplete } from "../../../services/functions";
import { autoCompleteType } from "../../../types/enumerators";
import { RootState } from "../../../store/reducers";
import { registerAutoComplete } from "../../../utils";

interface Props {
  title: string;
  addSkills: boolean;
  setAddSkills: Dispatch<SetStateAction<boolean>>;
  setIsOptional: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

export interface OptionsProps {
  type: string;
  name: string;
  description: string;
  userSugested: boolean;
  meta: string;
  status: string;
}

const styles = {
  slider: {
    width: "200px",
    marginLeft: "40px",
  },
};

const CurriculumSkills = ({
  title,
  addSkills,
  setAddSkills,
  setIsOptional,
  setIsValid,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [_curriculum, _setCurriculum] = useState(user?.curriculum);
  const [skillsOptions, setSkillOptions] = useState<string[]>();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (
      Object.keys(user?.curriculum)?.length &&
      !Object.keys(_curriculum)?.length
    )
      _setCurriculum({ ...user?.curriculum });
  }, [_curriculum, user?.curriculum]);

  useEffect(() => {
    if (_curriculum?.skills?.length) {
      setIsOptional(false);
      setIsValid(true);
    } else setIsOptional(true);
  }, [_curriculum?.skills, setIsOptional, setIsValid]);

  useEffect(() => {
    if (_curriculum?.skills?.length)
      _curriculum?.skills?.sort(
        (a: { order: number }, b: { order: number }) => {
          if ("order" in a && "order" in b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          }
          return 0;
        },
      );
  }, [_curriculum?.skills]);

  const loadSkills = useCallback(async () => {
    const response = await getAutocomplete(autoCompleteType.SKILL);
    const skillOptions = response?.map((option) => option?.name);
    setSkillOptions(skillOptions);
  }, []);

  useEffect(() => {
    if (!skillsOptions) loadSkills();
  }, [loadSkills, skillsOptions]);

  const onUpdate = useCallback(
    async (value: string[] | string | null) => {
      let { skills: newSkills } = _curriculum;
      if (value && typeof value !== "string") {
        newSkills = value?.map((item) => {
          if (newSkills) {
            const skill = newSkills.find(
              (innerItem: { key: string }) => innerItem?.key === item,
            );
            return {
              key: item,
              level: skill?.level || 1,
              order: (skill?.order || newSkills?.length) ?? 0,
            };
          }
          return { key: item, level: 1, order: 0 };
        });
      }
      registerAutoComplete(value, autoCompleteType.SKILL);
      _setCurriculum({ ..._curriculum, skills: newSkills });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, skills: newSkills },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [user, _curriculum, dispatch],
  );

  const updateLevelSkill = useCallback(
    (level: number | number[], index: number) => {
      const { skills: newSkills } = _curriculum;
      if (typeof level === "number") newSkills[index].level = level;
      _setCurriculum({ ..._curriculum, skills: newSkills });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, skills: newSkills },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [user, _curriculum, dispatch],
  );

  const removeSkill = useCallback(
    (index: number) => {
      let { skills: oldSkills } = _curriculum;
      oldSkills.splice(index, 1);
      oldSkills = oldSkills.map((item: any, i: number) => ({
        ...item,
        order: i,
      }));
      _setCurriculum({ ..._curriculum, skills: oldSkills });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, skills: oldSkills },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [user, _curriculum, dispatch],
  );

  const reorderItem = useCallback(
    (items) => {
      let { skills: newSkills } = _curriculum;
      newSkills = items?.map((item: any, index: number) => ({
        ...item,
        order: index,
      }));
      _setCurriculum({ ..._curriculum, skills: newSkills });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, skills: newSkills },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [user, _curriculum, dispatch],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`height-fit grid-column-5-12 grid-column-1-11-mobile margin-top-16 grid-7-column ${
        width > 990 ? "grid-row-1" : "grid-row-2"
      } `}
    >
      <Card
        borderNone
        titleHeader={title}
        className={`grid-column-1-8 height-fit-content ${
          addSkills || width < 991 ? "border-card" : ""
        }`}
      >
        <p className="p-skills-title">
          Agora é hora de mostrar suas habilidades.
        </p>
        <p className="paragraph-reorder-register">
          Adicione cada uma e ordene como preferir.
        </p>
        {!addSkills ? (
          <IconButton
            icon={
              <Add
                widthIcon={width < 991 ? 20 : 24}
                heightIcon={width < 991 ? 20 : 24}
              />
            }
            text="Adicionar habilidade"
            onClick={() => setAddSkills(true)}
            className="icon-button-primary icon-button-primary-mobile"
          />
        ) : (
          <InputAutocomplete
            multiple
            label="Habilidade"
            helperText="Pressione Enter para adicionar"
            className="skills-input margin-top-MuiFormLabel-root"
            options={skillsOptions ?? [""]}
            getOptionLabel={(option) => option}
            value={
              _curriculum?.skills?.map((item: { key: string }) => item?.key) ||
              []
            }
            onChange={(values) => onUpdate(values)}
          />
        )}
      </Card>
      {_curriculum?.skills?.length > 0 && (
        <div className="grid-column-1-8 grid-row-2">
          {_curriculum?.skills?.length > 1 && (
            <p className="paragraph-reorder margin-top-0-mobile">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <Draggable items={_curriculum?.skills} reorderItem={reorderItem}>
            {_curriculum?.skills?.map(
              (
                item: {
                  key: string;
                  level: number;
                },
                index: number,
              ) => (
                <DraggableComponent
                  key={Math.random()}
                  draggableId={index.toString()}
                  index={index}
                  isDragDisabled={_curriculum?.skills?.length === 1}
                >
                  {(innerProvided) => (
                    <div
                      ref={innerProvided.innerRef}
                      {...innerProvided.draggableProps}
                      {...innerProvided.dragHandleProps}
                    >
                      <Card className="margin-top-16 height-fit-content">
                        <div className="flex-row-center space-between-responsive">
                          <div className="width-30 ">
                            <div className="pills">{item?.key}</div>
                          </div>
                          {width > 990 && (
                            <div className="width-100">
                              <Slider
                                value={item?.level}
                                step={1}
                                min={1}
                                max={3}
                                onChange={(_e, value) =>
                                  updateLevelSkill(value, index)
                                }
                                marks={[
                                  { value: 1, label: "Básico" },
                                  { value: 2, label: "Intermediário" },
                                  { value: 3, label: "Avançado" },
                                ]}
                                style={styles.slider}
                              />
                            </div>
                          )}
                          <div className="flex">
                            <IconButton
                              icon={
                                <Delete
                                  widthIcon={width < 991 ? 20 : 24}
                                  heightIcon={width < 991 ? 20 : 24}
                                />
                              }
                              onClick={() => removeSkill(index)}
                            />
                            {_curriculum?.skills?.length > 1 && (
                              <IconButton
                                icon={
                                  <Move
                                    widthIcon={width < 991 ? 20 : 24}
                                    heightIcon={width < 991 ? 20 : 24}
                                  />
                                }
                                className="margin-left-8"
                              />
                            )}
                          </div>
                        </div>
                        {width < 991 ? (
                          <div className="width-100">
                            <Slider
                              className="slider-mobile"
                              value={item?.level}
                              step={1}
                              min={1}
                              max={3}
                              onChange={(_e, value) =>
                                updateLevelSkill(value, index)
                              }
                              marks={[
                                { value: 1, label: "Básico" },
                                { value: 2, label: "Intermediário" },
                                { value: 3, label: "Avançado" },
                              ]}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Card>
                    </div>
                  )}
                </DraggableComponent>
              ),
            )}
          </Draggable>
        </div>
      )}
    </div>
  );
};

export default memo(CurriculumSkills);
