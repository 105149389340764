/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-eval */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Button, IconButton, ModalSendInvite } from "..";
import {
  Edit,
  CompanyAvatarEmpty,
  AvatarEmpty,
  SocialMedia,
  Avatar,
  PhoneBlack,
  LinkedinLogin,
  InstagramProfile,
  FacebookProfile,
  TwitterProfile,
  TikTokProfile,
  GitHubProfile,
} from "../../../assets/customIcons";
import "./styles.scss";
import { RootState } from "../../store/reducers";
import { isCorporate } from "../../utils";
import { userSocialMediaType } from "../../types/enumerators";

interface Props {
  isMyProfile?: boolean;
  position?: string;
  name: string;
  userId?: string;
  goal?: string;
  avatar?: string;
  cover?: string;
  localization: string;
  proposal?: string;
  changing?: string;
  freelancer?: string;
  publicProfile?: string[];
  type: string;
  pageSelected?: string;
  site?: string;
  socialMedias?: any[];
  setPageSelected?: Dispatch<SetStateAction<string>>;
  editAvatar?: () => void;
  editCover?: () => void;
  onEdit?: () => void;
  onClickGeneralData?: () => void;
  onClickContact?: () => void;
  onClickContactSocial?: () => void;
}

const InfosProfileMobile = ({
  isMyProfile,
  position,
  avatar,
  cover,
  name,
  userId,
  goal,
  publicProfile,
  localization,
  proposal,
  changing,
  freelancer,
  type,
  pageSelected,
  site,
  socialMedias,
  setPageSelected,
  editAvatar,
  editCover,
  onEdit,
  onClickGeneralData,
  onClickContact,
  onClickContactSocial,
}: Props): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);
  const [openModalInvite, setOpenModalInvite] = useState(false);
  const [notShowGoal, setNotShowGoal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (!isMyProfile && publicProfile?.length) {
      const filterGoal = publicProfile?.find((item) => item.includes("goal"));
      if (filterGoal) setNotShowGoal(true);
    }
  }, [isMyProfile, publicProfile]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={`myProfile-${type} div-container-infos-profile my-profile`}>
      <div className="sub-div-infos-profile">
        {cover && (
          <img src={cover} alt="imagem" className="img-icons-infos-profile" />
        )}
        {type === "company" && isMyProfile && (
          <IconButton
            icon={
              <Edit
                widthIcon={20}
                heightIcon={20}
                className="icon-edit-infos"
              />
            }
            onClick={editCover}
            className="edit-cover-icon"
          />
        )}
      </div>
      {position && <h1 className="h1-position-infos-profile">{position}</h1>}
      <div className="flex space-between">
        <div>
          <div className="div-img-infos-profile">
            {avatar ? (
              <img
                src={avatar}
                alt="imagem"
                width={96}
                height={96}
                className="img-avatar-infos-profile"
              />
            ) : type === "user" ? (
              <AvatarEmpty
                widthIcon={96}
                heightIcon={96}
                className="avatar-empty-infos-profile"
              />
            ) : (
              <CompanyAvatarEmpty
                widthIcon={96}
                heightIcon={96}
                className="company-avatar-infos-profile"
              />
            )}
          </div>
          {isMyProfile && (
            <IconButton
              icon={<Edit className="icon-edit-infos-2" />}
              onClick={editAvatar}
              className="edit-icon-avatar-profile"
            />
          )}
        </div>
        <div>
          <div className="div-avatar-infos-profile label-icon-mobile">
            {type === "company" ? (
              ""
            ) : (
              <ul className="flex-direction-mobile ul-avatar-infos-profile">
                <li className="mediaLi media990px ">
                  <IconButton
                    icon={
                      <Avatar
                        widthIcon={width < 991 ? 24 : 28}
                        heightIcon={width < 991 ? 24 : 28}
                      />
                    }
                    text="Dados gerais"
                    onClick={onClickGeneralData}
                    className="icon-button-perfil  iconbutton-perfil-generaldata "
                  />
                </li>
                <li className="mediaLi media990px ">
                  <IconButton
                    icon={
                      <PhoneBlack
                        widthIcon={width < 991 ? 24 : 28}
                        heightIcon={width < 991 ? 24 : 28}
                      />
                    }
                    text="Contato"
                    onClick={onClickContact}
                    className="icon-button-perfil  iconbutton-perfil-contact"
                  />
                </li>
                <li className="mediaLi media990px margin-0">
                  <IconButton
                    icon={
                      <SocialMedia
                        widthIcon={width < 991 ? 24 : 28}
                        heightIcon={width < 991 ? 24 : 28}
                      />
                    }
                    text="Redes sociais"
                    onClick={onClickContactSocial}
                    className="icon-button-perfil"
                  />
                </li>
              </ul>
            )}
            {type !== "company" && isMyProfile && (
              <IconButton
                icon={<Edit />}
                className="icon-button-perfil btnEdit  edit-button-perfil"
                onClick={onEdit}
              />
            )}
          </div>
          <div>
            {!isMyProfile && isCorporate(user) && (
              <a
                className="visit-button btn-convite"
                onClick={() => setOpenModalInvite(true)}
                role="button"
                tabIndex={0}
              >
                Enviar convite
              </a>
            )}
          </div>

          <div className="right-24-mobile">
            <div>
              <div className="flex">
                {socialMedias && socialMedias?.length > 0 ? (
                  <div className="flex-wrap-midia company-profile-social-medias">
                    {socialMedias?.find(
                      (item: { type: string }) =>
                        item.type === userSocialMediaType.LINKEDIN,
                    ) && (
                      <a
                        aria-label="linkedin"
                        href={
                          socialMedias?.find(
                            (item: { type: string }) =>
                              item.type === userSocialMediaType.LINKEDIN,
                          )?.url ?? ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <LinkedinLogin
                          widthIcon={width < 991 ? 24 : 32}
                          heightIcon={width < 991 ? 24 : 32}
                        />
                      </a>
                    )}
                    {socialMedias?.find(
                      (item: { type: string }) => item.type === "INSTAGRAM",
                    ) && (
                      <a
                        aria-label="instagram"
                        href={
                          socialMedias?.find(
                            (item: { type: string }) =>
                              item.type === "INSTAGRAM",
                          )?.url ?? ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <InstagramProfile
                          widthIcon={width < 991 ? 24 : 32}
                          heightIcon={width < 991 ? 24 : 32}
                        />
                      </a>
                    )}
                    {socialMedias?.find(
                      (item: { type: string }) => item.type === "FACEBOOK",
                    ) && (
                      <a
                        aria-label="facebook"
                        href={
                          socialMedias?.find(
                            (item: { type: string }) =>
                              item.type === "FACEBOOK",
                          )?.url ?? ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <FacebookProfile
                          widthIcon={width < 991 ? 24 : 32}
                          heightIcon={width < 991 ? 24 : 32}
                        />
                      </a>
                    )}
                    {socialMedias?.find(
                      (item: { type: string }) => item.type === "TWITTER",
                    ) && (
                      <a
                        aria-label="twitter"
                        href={
                          socialMedias?.find(
                            (item: { type: string }) => item.type === "TWITTER",
                          )?.url ?? ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <TwitterProfile
                          widthIcon={width < 991 ? 24 : 32}
                          heightIcon={width < 991 ? 24 : 32}
                        />
                      </a>
                    )}
                    {socialMedias?.find(
                      (item: { type: string }) => item.type === "TIKTOK",
                    ) && (
                      <a
                        aria-label="tiktok"
                        href={
                          socialMedias?.find(
                            (item: { type: string }) => item.type === "TIKTOK",
                          )?.url ?? ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <TikTokProfile
                          widthIcon={width < 991 ? 24 : 32}
                          heightIcon={width < 991 ? 24 : 32}
                        />
                      </a>
                    )}
                    {socialMedias?.find(
                      (item: { type: string }) => item.type === "GITHUB",
                    ) && (
                      <a
                        aria-label="github"
                        href={
                          socialMedias?.find(
                            (item: { type: string }) => item.type === "GITHUB",
                          )?.url ?? ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="a-company-profile-social-medias"
                      >
                        <GitHubProfile
                          widthIcon={width < 991 ? 24 : 32}
                          heightIcon={width < 991 ? 24 : 32}
                        />
                      </a>
                    )}
                  </div>
                ) : null}
                {type === "company" && isMyProfile && (
                  <IconButton
                    icon={<Edit />}
                    className="icon-button-perfil btnEdit  margin-top-12 edit-button-perfil"
                    onClick={onEdit}
                  />
                )}
              </div>
            </div>
            <div>
              {!isMyProfile && type === "company" && site && (
                <a
                  className="visit-button"
                  href={site.includes("http") ? site : `http://${site}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Visitar site
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-col width-100 ">
        <div className="flex">
          <div className="width-100">
            <h2
              className={`title-name-profile ${
                type === "company"
                  ? "margin-title-company"
                  : "margin-title-name"
              } `}
            >
              {name}
            </h2>
            <p className="p-localization-infos-profile">{localization}</p>
          </div>
        </div>
      </div>

      <div className="padding-mobile-goal">
        {!notShowGoal && <p className="p-goal-infos-profile">{goal}</p>}
      </div>
      <div className="width-proposal">
        {proposal && (
          <div className={`mediaBtn-${type}`}>
            <Button
              text={proposal}
              className="button-proposal-infosprofile"
              appearance="tertiary"
            />
          </div>
        )}
        {changing && (
          <div className={`mediaBtn-${type} margin-top-10`}>
            <Button
              text={changing}
              className="button-changes-infosprofile"
              appearance="quaternary"
            />
          </div>
        )}
        {freelancer && (
          <div className={`mediaBtn-${type} margin-top-10`}>
            <Button
              text={freelancer}
              className="button-changes-infosprofile"
              appearance="quaternary"
            />
          </div>
        )}
      </div>

      {!isMyProfile && type === "company" && (
        <div className="div-profile-infos-profile">
          <button
            type="button"
            className={`button-generic-infos-profile ${
              pageSelected === "initial"
                ? "color-page-selected"
                : "color-page-not-selected"
            } `}
            onClick={() => setPageSelected && setPageSelected("initial")}
          >
            Início
            <div
              className={
                pageSelected === "initial"
                  ? "tab-profile-selected"
                  : "tab-profile"
              }
            />
          </button>
          <button
            type="button"
            className={`button-generic-infos-profile  ${
              pageSelected === "about"
                ? "color-page-selected"
                : "color-page-not-selected"
            }`}
            onClick={() => setPageSelected && setPageSelected("about")}
          >
            Sobre
            <div
              className={
                pageSelected === "about"
                  ? "tab-profile-selected"
                  : "tab-profile"
              }
            />
          </button>
          <button
            type="button"
            className={`button-generic-infos-profile ${
              pageSelected === "vacancy"
                ? "color-page-selected"
                : "color-page-not-selected"
            } `}
            onClick={() => setPageSelected && setPageSelected("vacancy")}
          >
            Vagas
            <div
              className={
                pageSelected === "vacancy"
                  ? "tab-profile-selected"
                  : "tab-profile"
              }
            />
          </button>
          <button
            type="button"
            className={`button-generic-infos-profile ${
              pageSelected === "medias"
                ? "color-page-selected"
                : "color-page-not-selected"
            } `}
            onClick={() => setPageSelected && setPageSelected("medias")}
          >
            Mídias
            <div
              className={
                pageSelected === "medias"
                  ? "tab-profile-selected"
                  : "tab-profile"
              }
            />
          </button>
        </div>
      )}
      <ModalSendInvite
        userId={userId ?? ""}
        openModal={openModalInvite}
        onClose={() => setOpenModalInvite(false)}
      />
    </div>
  );
};
export default memo(InfosProfileMobile);
