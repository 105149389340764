import React, { memo } from "react";
import {
  LinkedinLogin,
  InstagramProfile,
  FacebookProfile,
  TwitterProfile,
  TikTokProfile,
  GitHubProfile,
} from "../../../assets/customIcons";
import {
  companySocialMedias,
  userSocialMediaType,
} from "../../types/enumerators";

interface Props {
  socialMedias:
    | {
        type: string;
        url: string;
      }[]
    | undefined;
  showLabel?: boolean;
  hiden?: boolean;
}

const HiddenText = () => (
  <p className="hidden-information">Informação ocultada pela usuária</p>
);

const ViewSocialMedias = ({
  socialMedias,
  showLabel,
  hiden,
}: Props): JSX.Element => (
  <>
    {!socialMedias?.find(
      (item: { type: string }) =>
        item.type === "LINKEDIN" ||
        item.type === companySocialMedias.LINKEDIN_COMPANY,
    ) ? (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        {hiden && <LinkedinLogin />}
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">Linkedin</p>
          )}
          {hiden && <HiddenText />}
        </div>
      </div>
    ) : (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        <LinkedinLogin />
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">Linkedin</p>
          )}
          <a
            className="view-bold sub-paragraph-edit-general"
            href={
              socialMedias?.find(
                (item: { type: string }) =>
                  item.type === userSocialMediaType.LINKEDIN,
              )?.url ||
              socialMedias?.find(
                (item: { type: string }) =>
                  item.type === companySocialMedias.LINKEDIN_COMPANY,
              )?.url
            }
            target="_blank"
            rel="noreferrer"
          >
            {socialMedias
              ?.find(
                (item: { type: string }) =>
                  item.type === userSocialMediaType.LINKEDIN,
              )
              ?.url.replace("https://www.linkedin.com/in", "") ||
              socialMedias
                ?.find(
                  (item: { type: string }) =>
                    item.type === companySocialMedias.LINKEDIN_COMPANY,
                )
                ?.url.replace("https://www.linkedin.com/company", "")}
          </a>
        </div>
      </div>
    )}

    {!socialMedias?.find(
      (item: { type: string }) => item.type === "INSTAGRAM",
    ) ? (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        {hiden && <InstagramProfile />}
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">Instagram</p>
          )}
          {hiden && <HiddenText />}
        </div>
      </div>
    ) : (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        <InstagramProfile />
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">Instagram</p>
          )}
          <a
            href={
              socialMedias?.find(
                (item: { type: string }) => item.type === "INSTAGRAM",
              )?.url
            }
            target="_blank"
            rel="noreferrer"
            className="view-bold sub-paragraph-edit-general"
          >
            {socialMedias
              ?.find((item: { type: string }) => item.type === "INSTAGRAM")
              ?.url.replace("https://www.instagram.com", "")}
          </a>
        </div>
      </div>
    )}
    {!socialMedias?.find(
      (item: { type: string }) => item.type === "FACEBOOK",
    ) ? (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        {hiden && <FacebookProfile />}
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">Facebook</p>
          )}
          {hiden && <HiddenText />}
        </div>
      </div>
    ) : (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        <FacebookProfile />
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">Facebook</p>
          )}
          <a
            href={
              socialMedias?.find(
                (item: { type: string }) => item.type === "FACEBOOK",
              )?.url
            }
            target="_blank"
            rel="noreferrer"
            className="view-bold sub-paragraph-edit-general"
          >
            {socialMedias
              ?.find((item: { type: string }) => item.type === "FACEBOOK")
              ?.url.replace("https://www.facebook.com", "")}
          </a>
        </div>
      </div>
    )}

    {!socialMedias?.find(
      (item: { type: string }) => item.type === "TWITTER",
    ) ? (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        {hiden && <TwitterProfile />}
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">Twitter</p>
          )}
          {hiden && <HiddenText />}
        </div>
      </div>
    ) : (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        <TwitterProfile />
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">Twitter</p>
          )}
          <a
            href={
              socialMedias?.find(
                (item: { type: string }) => item.type === "TWITTER",
              )?.url
            }
            target="_blank"
            rel="noreferrer"
            className="view-bold sub-paragraph-edit-general"
          >
            {socialMedias
              ?.find((item: { type: string }) => item.type === "TWITTER")
              ?.url.replace("https://www.twitter.com", "")}
          </a>
        </div>
      </div>
    )}

    {!socialMedias?.find((item: { type: string }) => item.type === "TIKTOK") ? (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        {hiden && <TikTokProfile />}
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">TikTok</p>
          )}
          {hiden && <HiddenText />}
        </div>
      </div>
    ) : (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        <TikTokProfile />
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">TikTok</p>
          )}
          <a
            href={
              socialMedias?.find(
                (item: { type: string }) => item.type === "TIKTOK",
              )?.url
            }
            target="_blank"
            rel="noreferrer"
            className="view-bold sub-paragraph-edit-general"
          >
            {socialMedias
              ?.find((item: { type: string }) => item.type === "TIKTOK")
              ?.url.replace("https://www.tiktok.com/", "")}
          </a>
        </div>
      </div>
    )}

    {!socialMedias?.find((item: { type: string }) => item.type === "GITHUB") ? (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        {hiden && <GitHubProfile />}
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">GitHub</p>
          )}
          {hiden && <HiddenText />}
        </div>
      </div>
    ) : (
      <div className="social-account media-margin-left margin-right-24 margin-top-0-mobile">
        <GitHubProfile />
        <div className="flex-col margin-left-10">
          {showLabel && (
            <p className="title-view paragraph-edit-general">GitHub</p>
          )}
          <a
            href={
              socialMedias?.find(
                (item: { type: string }) => item.type === "GITHUB",
              )?.url
            }
            target="_blank"
            rel="noreferrer"
            className="view-bold sub-paragraph-edit-general"
          >
            {socialMedias
              ?.find((item: { type: string }) => item.type === "GITHUB")
              ?.url.replace("https://www.github.com", "")}
          </a>
        </div>
      </div>
    )}
  </>
);

export default memo(ViewSocialMedias);
