import React, { memo, MouseEventHandler, useState, useEffect } from "react";
import { Button, Progress } from "../../../components";

interface Props {
  onNext?: MouseEventHandler<HTMLButtonElement>;
}

const Skip = ({ onNext }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width > 990 ? (
    <div className="grid-column-3-11 grid-8-column">
      <p className="paragraph-finish-1">Que demais!</p>
      <p className="paragraph-finish-2">
        {`Agora que já conhecemos um pouco mais sobre você, chegou a hora de
            botar a cara no mundo: mostre os seus trabalhos e as suas conquistas
            :)`}
      </p>
      <Progress value={50} className="grid-column-1-4" />
      <Button
        className="grid-row-4 grid-column-1-3 margin-top-16"
        onClick={onNext}
        text="Vamos lá!"
      />
    </div>
  ) : (
    <div className="grid-column-1-9 skip-margin">
      <Progress value={50} />
      <div className="margin-5-mobile">
        <p className="paragraph-finish-1">Que demais!</p>
        <p className="paragraph-finish-2">
          {`Agora que já conhecemos um pouco mais sobre você, chegou a hora de
              botar a cara no mundo: mostre os seus trabalhos e as suas
              conquistas :)`}
        </p>
        <Button
          className="grid-row-4 grid-column-1-3 margin-top-16"
          onClick={onNext}
          text="Vamos lá!"
        />
      </div>
    </div>
  );
};

export default memo(Skip);
