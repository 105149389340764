import { LocationChangePayload, LOCATION_CHANGE } from "connected-react-router";
import { createRouteLog } from "../../services/historicFunctions";
import { historicEntity } from "../../types/enumerators";
import { RouteAction } from "../../types/enumerators/actions/RouteAction";
import { User } from "../../types/interfaces";
import { hasCorporateRole } from "../../utils";

interface IRoute {
  previous: string;
  current: string;
  action: RouteAction | "";
}

const initialState: IRoute = {
  previous: "",
  current: "",
  action: "",
};

export const routeReducerInit = (
  state = initialState,
  { type }: { type: string },
) => {
  switch (type) {
    default:
      return state;
  }
};

export const routeReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: LocationChangePayload },
  reduxUser: User,
) => {
  switch (type) {
    case LOCATION_CHANGE: {
      const { current: previousPath } = state;
      const {
        isFirstRendering,
        action: payloadAction,
        location: { pathname: current },
      } = payload;

      const isRefresh = isFirstRendering && payloadAction === "POP";
      const action = isRefresh
        ? RouteAction.ROUTE_REFRESH
        : RouteAction.ROUTE_UPDATE;
      const previous = isRefresh ? current : previousPath;

      const user = reduxUser?._id
        ? reduxUser
        : JSON.parse(sessionStorage.getItem("@SESSION:user") ?? "null");
      const isCorporate = hasCorporateRole(user);

      if (user?._id) {
        createRouteLog(
          isCorporate ? historicEntity.COMPANY : historicEntity.USER,
          user._id,
          action,
          previous,
          current,
        );
      }

      return {
        previous,
        current,
        action,
      };
    }
    default:
      return state;
  }
};
