/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useMemo, useState, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import {
  ChevronUp,
  Subjects,
  Unblock,
  Vacancy,
} from "../../../assets/customIcons";
import { Card, HeaderProfile, IconButton, Layout } from "../../components";
import { GeneralData, Skills, Stages } from "./Steps";
import { PlacementVacancy } from "../../types/interfaces";
import {
  createVacancy,
  getPlacementVacancy,
  updateVacancyById,
} from "../../services/functions";
import {
  placementVacancyManagedBy,
  placementVacancyStatus,
} from "../../types/enumerators";
import { createId } from "../../utils";
import "./style.scss";
import { RootState } from "../../store/reducers";

interface ParamsProps {
  id: string;
  page: string;
}

const CompanyVacancyNewEdit = (): JSX.Element => {
  const { id, page } = useParams<ParamsProps>();
  const company = useSelector((state: RootState) => state.companyState);
  const [width, setWidth] = useState(window.innerWidth);
  const [step, setStep] = useState(parseFloat(page ?? 1));
  const [vacancy, setVacancy] = useState<PlacementVacancy>({
    companyId: company?._id,
    autoOpen: false,
    autoClose: false,
    status: placementVacancyStatus.PENDING,
    managedBy: placementVacancyManagedBy.REPROGRAMA,
    stages: [
      {
        id: createId(),
        title: "Captação",
        description: "Primeira fase (obrigatória)",
        enableRecruiterContact: false,
        order: 0,
      },
      {
        id: createId(),
        title: "Proposta",
        description: "Última fase (obrigatória)",
        enableRecruiterContact: false,
        order: 1000,
      },
    ],
  } as unknown as PlacementVacancy);

  const history = useHistory();

  const getData = useCallback(async () => {
    const response = await getPlacementVacancy(id);
    setVacancy(response as PlacementVacancy);
  }, [id]);

  useEffect(() => {
    if (id) getData();
  }, [getData, id]);

  const itemsMenu = useMemo(
    () => [
      {
        icon: (
          <Vacancy
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Dados gerais",
        id: 1,
      },
      {
        icon: (
          <Subjects
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Habilidades desejadas",
        id: 2,
      },
      {
        icon: (
          <Unblock
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Etapas",
        id: 3,
      },
    ],
    [width],
  );

  const nextPage = useCallback(() => setStep(step + 1), [step]);

  const onSave = useCallback(async () => {
    const newVacancy = JSON.parse(JSON.stringify(vacancy));
    delete newVacancy?._id;
    if (id) await updateVacancyById(id, newVacancy);
    else await createVacancy(vacancy);
    history.goBack();
  }, [history, id, vacancy]);

  const renderPages = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <GeneralData
            vacancy={vacancy}
            setVacancy={setVacancy}
            onSave={onSave}
            nextPage={nextPage}
          />
        );

      case 2:
        return (
          <Skills
            vacancy={vacancy}
            setVacancy={setVacancy}
            onSave={onSave}
            nextPage={nextPage}
          />
        );

      case 3:
        return (
          <Stages vacancy={vacancy} setVacancy={setVacancy} onSave={onSave} />
        );

      default:
        return <div />;
    }
  }, [step, vacancy, onSave, nextPage]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-responsive">
      <HeaderProfile />
      {width < 991 && (
        <Accordion className="steps-account-mobile margin-16 margin-y-16-mobile margin-x-0-mobile grid-column-1-11-mobile">
          <AccordionSummary
            expandIcon={<ChevronUp widthIcon={24} heightIcon={24} />}
            className={`expanded-icon `}
          >
            <div>
              <div className="flex-row-center width-100">
                <p className="icon-header-mobile ">
                  {itemsMenu[step - 1]?.icon}
                </p>
                <p className="p-header-mobile ">{itemsMenu[step - 1]?.text}</p>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="grid-column-1-11 width-100 grid-row-2 ">
              <div className="border-solid-account" />
              <div className=" icon-step-account">
                {itemsMenu?.map((item) => (
                  <IconButton
                    key={Math.random()}
                    icon={item?.icon}
                    text={item?.text}
                    onClick={() => {
                      if (id) setStep(item?.id);
                      else if (item?.id <= step) setStep(item?.id);
                    }}
                    className={`steps-account ${
                      step !== item?.id ? "steps-account-disabled" : ""
                    }`}
                  />
                ))}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      {width > 990 && (
        <>
          <div className="grid-column-2-12 grid-row-1 header-steps-company-register-vacancy">
            <p className="view-page">Vagas &gt;&nbsp;</p>
            <p className="view-step">
              {id ? `${vacancy?.title} >` : `Solicitar nova vaga >`}
            </p>
            <p className="view-bold-16">&nbsp;{itemsMenu[step - 1]?.text}</p>
          </div>
          <div className="grid-column-2-5 grid-row-2">
            <div className="div-card-company">
              <Card bodyClassName="height-fit">
                <div>
                  {itemsMenu?.map((item) => (
                    <IconButton
                      key={Math.random()}
                      icon={item?.icon}
                      text={item?.text}
                      onClick={() => {
                        if (id) setStep(item?.id);
                        else if (item?.id <= step) setStep(item?.id);
                      }}
                      className={`steps-account ${
                        step !== item?.id ? "steps-account-disabled" : ""
                      }`}
                    />
                  ))}
                </div>
              </Card>
            </div>
          </div>
        </>
      )}
      {renderPages}
    </Layout>
  );
};

export default memo(CompanyVacancyNewEdit);
