import React, { memo, useEffect, useMemo, useState } from "react";
import { Modal, Select, Button } from "../../../components";
import "../style.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: (classVacancyId: string, stageId: string) => void;
  list?: {
    _id: string;
    title: string;
    stages: { type: string; title: string }[];
  }[];
  userName?: string;
}

const TransferClassVacancy = ({
  open,
  onClose,
  list = [{ _id: "", title: "", stages: [{ type: "", title: "" }] }],
  onSave,
  userName,
}: Props): JSX.Element => {
  const [classVacancyId, setClassVacancyId] = useState<string>("");
  const [stageId, setStageId] = useState<string>("");

  useEffect(() => {
    if (!open && !!classVacancyId && !!stageId) {
      setClassVacancyId("");
      setStageId("");
    }
  }, [classVacancyId, open, stageId]);

  const stages = useMemo(
    () => list?.find((item) => item?._id === classVacancyId)?.stages ?? [],
    [list, classVacancyId],
  );

  return (
    <Modal
      headerAction
      open={open}
      onClose={onClose}
      textHeader={`${userName} - Transferir para processo seletivo`}
    >
      <div className="admin-recap-modal">
        <Select
          id="selectionProcess"
          className="margin-bottom-24"
          label="Processo seletivo*"
          value={classVacancyId ?? ""}
          onChange={(e) => setClassVacancyId(e.target.value)}
          options={list?.map((item) => ({
            value: item?._id,
            label: item?.title,
          }))}
        />
        <Select
          id="step"
          label="Etapa*"
          value={stageId ?? ""}
          onChange={(e) => setStageId(e.target.value)}
          options={stages?.map((item) => ({
            value: item?.type,
            label: item?.title,
          }))}
          disabled={!classVacancyId}
        />
        <div className="margin-top-32 admin-subscriptions-modal-buttons">
          <Button
            text="Cancelar"
            onClick={onClose}
            outline
            appearance="secondary"
          />
          <Button
            text="Salvar"
            className="margin-left-32 margin-left-mobile"
            onClick={() => onSave(classVacancyId, stageId)}
            disabled={!stageId}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(TransferClassVacancy);
