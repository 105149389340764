import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
  onClick?: () => void;
}

const CompanyAvatarEmpty = ({
  widthIcon,
  heightIcon,
  className,
  onClick,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "128"}
    height={heightIcon ?? "128"}
    fill="none"
    viewBox="0 0 128 128"
    className={className}
    onClick={onClick}
  >
    <circle cx="64" cy="64" r="64" fill="#2E3451" />
    <path fill="#2E3451" d="M107.077 20.924H20.923v86.154h86.154V20.924z" />
    <path
      fill="#fff"
      d="M39.799 85.575V70.768c0-2.661-1.216-4.962-3.74-4.962v-3.605c2.516 0 3.74-2.302 3.74-4.961V42.502c0-5.97 4.458-9.709 8.99-9.709h4.387v3.951H48.79c-2.583 0-4.747 2.374-4.747 5.755v15.175c0 3.02-.935 5.32-3.309 6.328 2.374 1.008 3.309 3.31 3.309 6.33v15.176c0 3.309 2.157 5.755 4.747 5.755h4.387v3.951H48.79c-4.532-.001-8.99-3.741-8.99-9.639zM79.211 91.26c2.583 0 4.746-2.447 4.746-5.755V70.329c0-3.02.936-5.319 3.31-6.329-2.374-1.006-3.31-3.308-3.31-6.328v-15.17c0-3.38-2.156-5.754-4.746-5.754h-4.387v-3.951h4.387c4.603 0 8.99 3.74 8.99 9.709v14.74c0 2.66 1.216 4.962 3.74 4.962V65.8c-2.518 0-3.74 2.301-3.74 4.962v14.815c0 5.898-4.387 9.638-8.99 9.638h-4.387v-3.951l4.387-.003z"
    />
  </svg>
);

export default CompanyAvatarEmpty;
