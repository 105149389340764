import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Search = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "20"}
    height={heightIcon ?? "20"}
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#606060"
      d="M13.22 11.934h-.677l-.24-.232c.84-.977 1.346-2.247 1.346-3.627 0-3.08-2.495-5.575-5.574-5.575C4.995 2.5 2.5 4.996 2.5 8.075s2.496 5.574 5.575 5.574c1.38 0 2.65-.506 3.627-1.346l.232.24v.677l4.288 4.28 1.278-1.278-4.28-4.288zm-5.145 0c-2.136 0-3.86-1.724-3.86-3.86 0-2.135 1.724-3.859 3.86-3.859 2.135 0 3.859 1.724 3.859 3.86 0 2.135-1.724 3.859-3.86 3.859z"
    />
  </svg>
);

export default Search;
