/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  createRef,
  Dispatch,
  LegacyRef,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Add, Delete, Edit, Move } from "../../../../assets/customIcons";
import { Draggable, IconButton, TextArea, ViewWork } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateUser } from "../../../store/actions/userActions";
import { ConfirmRemove, Items } from "../../Profile/Modal";
import { RootState } from "../../../store/reducers";
import { addCompanyToUser } from "../../../services/functions";

interface Props {
  selected: string;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const EditCurriculum = ({ selected, setIsValid }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [openModal, setOpenModal] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [removed, setRemoved] = useState({
    title: "",
    text: "",
    onRemove: () => setOpenModalRemove(false),
  });
  const [indexItem, setIndexItem] = useState(-1);
  const [typeItem, setTypeItem] = useState("");
  const user = useSelector((state: RootState) => state.userState);
  const [_user, _setUser] = useState(user);
  const dispatch = useDispatch();
  const [minWidth, setMinWidth] = useState(0);

  const refs: { current: { offsetWidth: number | null } | null }[] = useMemo(
    () =>
      Array.from({ length: _user?.curriculum?.skills?.length })?.map(() =>
        createRef(),
      ),
    [_user?.curriculum?.skills?.length],
  );

  const setCompanyToUser = useCallback(async () => {
    const response = await addCompanyToUser(user);
    _setUser({ ...response });
  }, [user]);

  useEffect(() => {
    if (user?._id && !_user?._id) setCompanyToUser();
  }, [_user, setCompanyToUser, user]);

  useEffect(() => {
    if (_user?.curriculum?.skills?.length === refs?.length) {
      let size = 0;
      let index = -1;

      refs?.forEach((item: any, i: number) => {
        if (item?.current?.offsetWidth > size) {
          size = item?.current?.offsetWidth;
          index = i;
        }
      });
      setMinWidth(refs[index]?.current?.offsetWidth ?? 0);
    }
  }, [_user?.curriculum?.skills, _user?.curriculum?.skills?.length, refs]);

  useEffect(() => {
    dispatch(
      setNextButton(() =>
        dispatch(
          updateUser({
            ..._user,
            curriculum: { ..._user?.curriculum },
          }) as unknown as Action,
        ),
      ) as unknown as Action,
    );
  }, [_user, _user?.curriculum, dispatch]);

  useEffect(() => {
    if (selected === "skills" && _user?.curriculum?.skills?.length) {
      _user?.curriculum?.skills?.sort(
        (
          a: {
            order: number;
          },
          b: {
            order: number;
          },
        ) => {
          if ("order" in a && "order" in b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          }
          return 0;
        },
      );
    }
  }, [_user?.curriculum?.skills, selected]);

  const handleOnBlur = useCallback(() => {
    if (_user?.curriculum?.openForProposals !== undefined) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ..._user,
              curriculum: { ..._user?.curriculum },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [_user, dispatch, setIsValid]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      prop: string,
    ) =>
      _setUser({
        ..._user,
        curriculum: { ..._user?.curriculum, [prop]: event.target.value },
      }),
    [_user],
  );

  const handleChangeRadio = useCallback(
    (value: string, props) => {
      _setUser({
        ..._user,
        curriculum: {
          ..._user?.curriculum,
          [props]: value === props,
        },
      });
    },
    [_user],
  );

  const reorderItem = useCallback(
    (items) => {
      const { curriculum } = _user;
      if (curriculum) {
        let { skills: newSkills } = curriculum;
        newSkills = items?.map((item: any, index: number) => ({
          ...item,
          order: index,
        }));

        _setUser({
          ..._user,
          curriculum: { ...curriculum, skills: newSkills },
        });

        dispatch(
          setNextButton(() =>
            dispatch(
              updateUser({
                ..._user,
                curriculum: { ...curriculum, skills: newSkills },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      }
    },
    [_user, dispatch],
  );

  const onRemoveItem = useCallback(
    (index: number, props: string) => {
      let newItems = _user?.curriculum[props];
      newItems?.splice(index, 1);

      if (props === "skills") {
        newItems = newItems?.map((innerItem: any, i: number) => ({
          ...innerItem,
          order: i,
        }));
      }

      _setUser({
        ..._user,
        curriculum: { ..._user?.curriculum, [props]: newItems },
      });

      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ..._user,
              curriculum: { ..._user?.curriculum, [props]: newItems },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_user, dispatch],
  );

  const onAdd = useCallback((prop: string) => {
    setTypeItem(prop);
    setOpenModal(true);
  }, []);

  const onEdit = useCallback((index: number, prop: string) => {
    setIndexItem(index);
    setTypeItem(prop);
    setOpenModal(true);
  }, []);

  const onRemove = useCallback(
    (item: { name: string }, index: number, prop: string, text: string) => {
      setRemoved({
        title: `Excluir ${text}`,
        text: `Tem certeza de que deseja excluir esta ${text} na ${item?.name}?`,
        onRemove: () => onRemoveItem(index, prop),
      });
      setOpenModalRemove(true);
    },
    [onRemoveItem],
  );

  const onClose = useCallback(() => {
    setOpenModal(false);
    setIndexItem(-1);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {selected === "general" && (
        <div className="grid display-block">
          <p className="title-input grid-row-1">
            Diz pra gente: qual é seu principal objetivo profissional?
          </p>
          <TextArea
            id="goal"
            label="Meu objetivo é..."
            maxLength={80}
            value={_user?.curriculum?.goal ?? ""}
            onChange={(e) => handleChange(e, "goal")}
            onBlur={handleOnBlur}
            className="title-input grid-row-2 goals-input width-100"
          />
          {width < 991 && (
            <p className="p-mobile margin-contact">
              Aberta à propostas de trabalho?
            </p>
          )}
          <div>
            <p className="title-input grid-row-3">
              Você tem disponibilidade/interesse em mudar de cidade para
              trabalhar?
            </p>
            <RadioGroup
              aria-label="openForChanges"
              name="openForChanges"
              value={
                _user?.curriculum?.openForChanges !== undefined
                  ? _user?.curriculum?.openForChanges
                    ? "openForChanges"
                    : "isnOpenChanges"
                  : ""
              }
              onChange={(e) =>
                handleChangeRadio(e.target.value, "openForChanges")
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="openForChanges"
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value="isnOpenChanges"
                  control={<Radio />}
                  label="Não"
                />
              </div>
            </RadioGroup>
          </div>
          <div>
            <p className="title-input grid-row-3">
              Você tem disponibilidade/interesse em mudar trabalho freelancer?
            </p>
            <RadioGroup
              aria-label="openForFreelancer"
              name="openForFreelancer"
              value={
                _user?.curriculum?.openForFreelancer !== undefined
                  ? _user?.curriculum?.openForFreelancer
                    ? "openForFreelancer"
                    : "isnOpenFreelancer"
                  : ""
              }
              onChange={(e) =>
                handleChangeRadio(e.target.value, "openForFreelancer")
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="openForFreelancer"
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value="isnOpenForFreelancer"
                  control={<Radio />}
                  label="Não"
                />
              </div>
            </RadioGroup>
          </div>
          <div>
            <p className="title-input grid-row-3">
              Você quer mostrar no seu perfil que deseja participar de processos
              seletivos?
            </p>
            <p className="sub-title-input">
              Não se preocupe, as empresas sempre poderão te convidar para
              participar.
            </p>
            <RadioGroup
              aria-label="openForProposals"
              name="openForProposals"
              value={
                _user?.curriculum?.openForProposals !== undefined
                  ? _user?.curriculum?.openForProposals
                    ? "openForProposals"
                    : "isnProposals"
                  : ""
              }
              onChange={(e) =>
                handleChangeRadio(e.target.value, "openForProposals")
              }
              onBlur={handleOnBlur}
            >
              <div>
                <FormControlLabel
                  value="openForProposals"
                  control={<Radio />}
                  label="Sim"
                />
                <FormControlLabel
                  value="isnProposals"
                  control={<Radio />}
                  label="Não"
                />
              </div>
            </RadioGroup>
          </div>
        </div>
      )}
      {selected === "works" && (
        <div>
          <IconButton
            icon={<Add />}
            text="Adicionar experiência"
            onClick={() => onAdd("works")}
            className={` icon-button-primary width-100-mobile ${
              _user?.curriculum?.works?.length > 0 ? "margin-bottom-40" : ""
            } `}
          />
          <ViewWork
            className="padding-0"
            onEdit={(_, index) => onEdit(index, "works")}
            onRemove={(item, index) =>
              onRemove(item, index, "works", "experiência")
            }
          />
        </div>
      )}
      {selected === "skills" && (
        <div>
          <IconButton
            icon={
              <Add
                widthIcon={width < 991 ? 20 : 24}
                heightIcon={width < 991 ? 20 : 24}
              />
            }
            text="Adicionar habilidade"
            onClick={() => onAdd("skills")}
            className={`icon-button-primary width-100-mobile ${
              _user?.curriculum?.works?.length > 0 ? "margin-bottom-40 " : ""
            }`}
          />
          {_user?.curriculum?.skills?.length > 0 ? (
            <div>
              {_user?.curriculum?.skills?.length > 1 && (
                <p className="account-reorder-skills">
                  Clique e arraste para ordenar como preferir
                </p>
              )}
              <Draggable
                items={_user?.curriculum?.skills}
                reorderItem={(items) => reorderItem(items)}
              >
                {_user?.curriculum?.skills?.map((item: any, index: number) => (
                  <DraggableComponent
                    key={Math.random()}
                    draggableId={Math.random().toString()}
                    index={index}
                    isDragDisabled={_user?.curriculum?.skills?.length < 2}
                  >
                    {(innerProvided) => (
                      <div
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                        {...innerProvided.dragHandleProps}
                      >
                        <div className="cards-items-profile flex-row-center">
                          <div
                            ref={
                              refs[index] as
                                | LegacyRef<HTMLDivElement>
                                | undefined
                            }
                            className="margin-bottom-14"
                            style={{ minWidth }}
                          >
                            <p className="account-skills-title">{item?.key}</p>
                            <p className="account-skills-level">
                              {item?.level === 1
                                ? "Básico"
                                : item?.level === 2
                                ? "Intermediário"
                                : "Avançado"}
                            </p>
                          </div>
                          {width > 990 && (
                            <div className="flex-row-center space-knob">
                              <div className="knob" />
                              <div
                                className={`knob ${
                                  item?.level < 2 ? "empty" : ""
                                }`}
                              />
                              <div
                                className={`knob ${
                                  item?.level < 3 ? "empty" : ""
                                }`}
                              />
                            </div>
                          )}
                          <div className="flex-row-center  margin-right-24 ">
                            <IconButton
                              icon={
                                <Edit
                                  widthIcon={width < 991 ? 20 : 24}
                                  heightIcon={width < 991 ? 20 : 24}
                                />
                              }
                              onClick={() => onEdit(item, "skills")}
                              className="margin-right-8"
                            />
                            <IconButton
                              icon={
                                <Delete
                                  widthIcon={width < 991 ? 20 : 24}
                                  heightIcon={width < 991 ? 20 : 24}
                                />
                              }
                              onClick={() =>
                                onRemove(item, index, "skills", "habilidades")
                              }
                            />
                            {_user?.curriculum?.skills?.length > 1 && (
                              <IconButton
                                icon={
                                  <Move
                                    widthIcon={width < 991 ? 20 : 24}
                                    heightIcon={width < 991 ? 20 : 24}
                                  />
                                }
                                className="margin-left-8"
                              />
                            )}
                          </div>
                        </div>
                        {width < 991 && (
                          <div className="flex-row-center space-knob margin-knob-edit">
                            <div className="knob" />
                            <div
                              className={`knob ${
                                item?.level < 2 ? "empty" : ""
                              }`}
                            />
                            <div
                              className={`knob ${
                                item?.level < 3 ? "empty" : ""
                              }`}
                            />
                          </div>
                        )}
                        {index <
                          (_user?.curriculum?.skills?.length ?? 0 - 1) && (
                          <div className="card-items-component-divider" />
                        )}
                      </div>
                    )}
                  </DraggableComponent>
                ))}
              </Draggable>
            </div>
          ) : (
            <p className="paragraph-not-registered">
              Não há habilidades cadastradas
            </p>
          )}
        </div>
      )}
      <Items
        setUser={_setUser}
        index={indexItem}
        type={typeItem}
        openModal={openModal}
        onClose={onClose}
      />
      <ConfirmRemove
        titleHeader={removed?.title}
        text={removed?.text}
        onClose={() => setOpenModalRemove(false)}
        onRemove={removed?.onRemove}
        openModal={openModalRemove}
      />
    </div>
  );
};

export default memo(EditCurriculum);
