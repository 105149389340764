/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import {
  UPDATE_NOTIFICATIONS,
  SET_NOTIFICATIONS,
} from "../types/notificationsTypes";

export const setNotifications = (notifications: any) => (dispatch: any) => {
  dispatch({
    type: SET_NOTIFICATIONS,
    payload: notifications,
  });
};

export const updateNotifications =
  (notifications: any) => async (dispatch: any) => {
    dispatch({
      type: UPDATE_NOTIFICATIONS,
      payload: notifications,
    });
    try {
      // to do
    } catch (err) {
      console.warn(err);
    }
  };
