import React, { memo, useCallback, useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Modal, Select } from "../../../components";
import { ClassVacancyStages } from "../../../types/interfaces";
import {
  createClassVacancySubscription,
  createUser,
  getUserByIdentification,
} from "../../../services/functions";
import { userRoles } from "../../../types/enumerators";
import "../style.scss";

interface Props {
  open: boolean;
  onclose: () => void;
  stages: ClassVacancyStages[];
  classVacancyId: string;
}

const AddCandidate = ({
  open,
  onclose,
  stages,
  classVacancyId,
}: Props): JSX.Element => {
  const [email, setEmail] = useState("");
  const [stageId, setStageId] = useState("");

  const onClose = useCallback(() => {
    setEmail("");
    setStageId("");
    onclose();
  }, [onclose]);

  const onSave = useCallback(async () => {
    try {
      let user = await getUserByIdentification(email);

      if (!user || !user?._id)
        user = await createUser(email, [userRoles.CANDIDATE]);

      await createClassVacancySubscription(
        classVacancyId,
        user?._id ?? "",
        {},
        stageId,
      );
    } catch {
      toast.error("Ops! Houve um erro ao adicionar candidata.");
    } finally {
      onClose();
    }
  }, [email, classVacancyId, stageId, onClose]);

  return (
    <Modal
      headerAction
      open={open}
      onClose={onClose}
      textHeader="Adicionar Candidata"
    >
      <Input
        id="email"
        label="E-mail"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
        className="modal-add-candidate"
      />
      <Select
        id="step"
        label="Etapa*"
        value={stageId ?? ""}
        className="modal-add-candidate"
        options={
          stages?.map((item) => ({
            value: item?.type ?? "",
            label: item?.title ?? "",
          })) ?? [{ value: "", label: "" }]
        }
        onChange={(e) => setStageId(e.target.value)}
        disabled={!email}
      />
      <div className="admin-subscriptions-modal-buttons margin-top-32">
        <Button
          text="Cancelar"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button onClick={onSave} text="Salvar" disabled={!email || !stageId} />
      </div>
    </Modal>
  );
};

export default memo(AddCandidate);
