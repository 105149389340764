import React, { memo } from "react";
import "../style.scss";
import { CardRegister } from "../../../components";

interface Props {
  choiceConfirmed?: boolean;
}

const SucessSendWorkshop = ({ choiceConfirmed }: Props): JSX.Element => (
  <CardRegister
    titleHeader="Sua inscrição"
    className="margin-top-16 margin-mobile-register border-card grid-column-5-12 grid-column-1-11-mobile"
  >
    <div className="information">
      {choiceConfirmed && (
        <p className="information-title">
          Sua escolha para oficina foi enviada com sucesso!
        </p>
      )}
      <p className="information-title">
        Em breve, você receberá um e-mail com instruções para continuar.
      </p>
      <p className="information-title"> Fique ligada! ;&nbsp;)</p>
    </div>
  </CardRegister>
);
export default memo(SucessSendWorkshop);
