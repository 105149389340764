import React, { memo, useState, useCallback, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { toast } from "react-toastify";
import { User } from "reprograma-types/dist/interface";
import {
  AttachmentMedia,
  Button,
  CardMedia,
  CardRegister,
  IconButton,
  Input,
} from "../../../components";
import { Delete } from "../../../../assets/icons";
import { mediaType } from "../../../types/enumerators";
import {
  ClassVacancyStages,
  ClassVacancySubscription,
  ClassVacancySubscriptionStage,
} from "../../../types/interfaces";
import api from "../../../services/api";
import {
  getClassById,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import { createCourseSubscriptionLog } from "../../../services/historicFunctions";
import { CourseSubscriptionAction } from "../../../types/enumerators/actions/CourseSubscriptionAction";
import { CourseSubscriptionState } from "../../../types/constants/Historic";
import { setLoad } from "../../../store/actions/configurationsActions";

interface Props {
  userId: User["_id"];
  subscription: ClassVacancySubscription;
  findStageWorkshop: ClassVacancyStages;
  currentStageWorkshop: ClassVacancySubscriptionStage;
  smallScreen?: boolean;
  prop: "media" | "behavior";
  text: string;
}

const Media = ({
  userId,
  subscription,
  findStageWorkshop,
  currentStageWorkshop,
  smallScreen,
  prop,
  text,
}: Props): JSX.Element => {
  const [link, setLink] = useState<string>("");
  const [data, setData] = useState<FormData | null>(null);
  const [source, setSource] = useState<string>("");

  const upperCaseProp = prop.toUpperCase() as "MEDIA" | "BEHAVIOR";
  const actionLog:
    | "COURSE_SUBSCRIPTION_WORKSHOP_MEDIA_SUBMITTED"
    | "COURSE_SUBSCRIPTION_WORKSHOP_BEHAVIOR_SUBMITTED" = `COURSE_SUBSCRIPTION_WORKSHOP_${upperCaseProp}_SUBMITTED`;
  const logSubmission:
    | "WORKSHOP_MEDIA_SUBMISSION"
    | "WORKSHOP_BEHAVIOR_SUBMISSION" = `WORKSHOP_${upperCaseProp}_SUBMISSION`;
  const logSubmited:
    | "WORKSHOP_MEDIA_SUBMITTED"
    | "WORKSHOP_BEHAVIOR_SUBMITTED" = `WORKSHOP_${upperCaseProp}_SUBMITTED`;

  const handleLink = useCallback(
    (e: { key: string; target: { value: string } }) => {
      if (e.key === "Enter") {
        setLink(e.target.value);
        e.target.value = "";
      }
    },
    [],
  );

  const handleOnBlur = useCallback(
    (e: { target: { value: string } }) => {
      if (link === "") {
        setLink(e.target.value);
        e.target.value = "";
      }
    },
    [link],
  );

  const handleChange = useCallback((e: { target: { value: string } }) => {
    setLink(e.target.value);
  }, []);

  const handleMedia = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;

    if (!fileList || !fileList?.length) return;

    const newData = new FormData();

    newData.append("file", fileList[0]);

    setData(newData);

    const blobURL = URL.createObjectURL(fileList[0]);
    setSource(blobURL);
  }, []);

  const handleRemove = useCallback(() => {
    setData(null);
    setSource("");
  }, []);

  const dispatch = useDispatch();

  const handleSubmit = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const response = await getClassById(
      currentStageWorkshop?.workshopClass ?? "",
    );

    const workshop = response?.data;

    const finishedStatus = workshop?.status === "FINISHED";

    if (!finishedStatus && userId) {
      if (link) {
        const stages = subscription?.stages?.map((item) => {
          if (item?.stageId === findStageWorkshop?.stage_id)
            return {
              ...item,
              [prop]: {
                url: link,
                type: mediaType.LINK,
              },
            };

          return {
            ...item,
          };
        });

        createCourseSubscriptionLog(
          userId,
          CourseSubscriptionAction?.[actionLog],
          CourseSubscriptionState?.[logSubmission],
          CourseSubscriptionState?.[logSubmited],
        );

        await updateClassVacancySubscriptionById(subscription?._id ?? "", {
          stages,
        });

        dispatch(setLoad(false) as unknown as Action);
        window.location.reload();
      } else
        api
          .post("/fileUpload", data)
          .then(async (result) => {
            const stages = subscription?.stages?.map((item) => {
              if (item?.stageId === findStageWorkshop?.stage_id) {
                return {
                  ...item,
                  [prop]: {
                    url: result?.data?.file_url.url,
                    type: data?.get("file")?.type?.includes("image")
                      ? mediaType.PHOTO
                      : mediaType.DOCUMENT,
                  },
                };
              }
              return {
                ...item,
              };
            });

            createCourseSubscriptionLog(
              userId,
              CourseSubscriptionAction?.[actionLog],
              CourseSubscriptionState?.[logSubmission],
              CourseSubscriptionState?.[logSubmited],
            );

            await updateClassVacancySubscriptionById(subscription?._id ?? "", {
              stages,
            });
          })
          .catch(() => {
            toast.error("Ocorreu um erro durante o upload do arquivo");
          })
          .finally(() => {
            dispatch(setLoad(false) as unknown as Action);
            window.location.reload();
          });
    }
  }, [
    dispatch,
    currentStageWorkshop?.workshopClass,
    userId,
    link,
    data,
    subscription?.stages,
    subscription?._id,
    actionLog,
    logSubmission,
    logSubmited,
    findStageWorkshop?.stage_id,
    prop,
  ]);

  return (
    <>
      <CardRegister
        titleHeader="Sua inscrição"
        className="margin-top-16 margin-mobile-register border-card grid-column-5-12 grid-column-1-11-mobile course-media"
      >
        <div className="information">
          <p className="information-title">
            Agora, siga as orientações para a realização da subetapa de{" "}
            <b>{text}</b>.
          </p>
          <p className="information-span">Cole o link no campo abaixo:</p>
          <div className="link-input-video">
            <Input
              label="Link"
              type="text"
              helperText={!smallScreen ? "Pressione Enter para adicionar" : ""}
              onKeyDown={handleLink}
              onBlur={handleOnBlur}
              onChange={smallScreen ? handleChange : undefined}
              className="input-link"
              disabled={(!!link || !!data) && !smallScreen}
            />
            {!smallScreen && (
              <div className="flex-row-center">
                <a
                  href={link}
                  target="_blank"
                  className="a-class"
                  rel="noreferrer"
                >
                  {link}
                </a>
                {link && (
                  <IconButton icon={<Delete />} onClick={() => setLink("")} />
                )}
              </div>
            )}
          </div>
          <div className="upload-section">
            <p className="information-title">
              Mas se preferir, você pode fazer upload do arquivo aqui mesmo.
            </p>
            <p className="paragraphy-secondary-file">
              <b>Atenção!</b> O arquivo deve ter no máximo 10MB
            </p>
            <div className={link ? "disabled" : ""}>
              <AttachmentMedia
                className="lable-media"
                onChange={handleMedia}
                disabled={!!link || !!data}
                workshopFile
              />
            </div>
            {data && (
              <CardMedia
                cantEdit
                item={{
                  title: data?.get("file")?.name,
                  originType: data?.get("file")?.type,
                  type: data?.get("file")?.type.includes("image")
                    ? mediaType.PHOTO
                    : mediaType.DOCUMENT,
                  url: source,
                }}
                onRemove={handleRemove}
              />
            )}
          </div>
        </div>
      </CardRegister>
      <Button
        text="Enviar"
        className="width-100 grid-column-1-11-mobile grid-row-2 grid-column-10-12 margin-bottom-48 button-course"
        onClick={handleSubmit}
        disabled={!link && !data}
      />
    </>
  );
};
export default memo(Media);
