/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import {
  Radio,
  TableRow,
  TextField,
  RadioGroup,
  InputAdornment,
  FormControlLabel,
} from "@material-ui/core";
import {
  Button,
  Card,
  HeaderProfile,
  Layout,
  Pagination,
  Table,
} from "../../components";
import { Search } from "../../../assets/icons";
import {
  filterClass,
  findUsesClass,
  getPages,
  updateClassById,
} from "../../services/functions";
import { RootState } from "../../store/reducers";
import {
  classStatus,
  classStructure,
  classVacancyStatus,
} from "../../types/enumerators";
import { Class, User } from "../../types/interfaces";
import {
  hasAdminClassBootcampRole,
  hasAdminClassWorkshopRole,
  isAdmin,
} from "../../utils";
import "./style.scss";
import { setLoad } from "../../store/actions/configurationsActions";

interface FiltersProps {
  ids?: string[];
  code?: string;
  title?: string;
  status?: "ACTIVE" | "PENDING" | "FINISHED";
  structure?: "ALL" | "ACTIVE" | "PENDING";
  classStructure?: string[];
}

interface Row {
  _id: string;
  id?: string;
  status: string;
  title: string;
}

const statusOptions = [
  {
    value: "ALL",
    label: "Todas",
  },
  {
    value: "ACTIVE",
    label: "Ativa",
  },
  {
    value: "PENDING",
    label: "Pendente",
  },
  {
    value: "FINISHED",
    label: "Finalizada",
  },
];

const structure = [
  {
    value: "ALL",
    label: "Todas",
  },
  {
    value: "WORKSHOP",
    label: "Oficina",
  },
  {
    value: "CLASS",
    label: "Turma",
  },
];

const AdminClass = (): JSX.Element => {
  const user: User = useSelector((state: RootState) => state.userState);
  const isAdminWorkshopOrBootcamp =
    isAdmin(user) &&
    (hasAdminClassWorkshopRole(user) || hasAdminClassBootcampRole(user));
  const history = useHistory();
  const dispatch = useDispatch();

  const [list, setList] = useState<Class[]>();
  const [totalFilteredList, setTotalFilteredList] = useState(0);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [filters, setFilters] = useState<FiltersProps>({} as FiltersProps);

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const verifyValue = useCallback(
    (value) => (value && value === "ALL" ? undefined : value),
    [],
  );

  const findStructure = useCallback(
    (value) =>
      value
        ? value === "ALL"
          ? undefined
          : value === classStructure.WORKSHOP
          ? [classStructure.WORKSHOP]
          : [
              classStructure.FOUR_WEEKS,
              classStructure.SIX_WEEKS,
              classStructure.EIGHT_WEEKS,
              classStructure.TWELVE_WEEKS,
              classStructure.FOURTEEN_WEEKS,
              classStructure.EIGHTEEN_WEEKS,
            ]
        : undefined,
    [],
  );

  const getList = useCallback(
    async (value?: string, type?: string) => {
      dispatch(setLoad(true) as unknown as Action);

      if (pageLimit) {
        const fields = {
          ...filters,
          status: type === "STATUS" ? value : filters?.status ?? undefined,
          structure:
            type === "STRUCTURE" ? value : filters?.structure ?? undefined,
        } as FiltersProps;

        if (value) setFilters(fields);

        const fieldsRefactor = {
          ...fields,
          status: fields?.status ? verifyValue(fields?.status) : undefined,
          classStructure: fields?.structure
            ? findStructure(fields?.structure)
            : undefined,
        } as FiltersProps;

        if (isAdminWorkshopOrBootcamp)
          fieldsRefactor.ids = user?.account?.classIds;

        setMaxPage(await getPages(filterClass, fieldsRefactor, true));

        const numTotalList = await filterClass(fieldsRefactor, true);
        if (numTotalList?.data?.length)
          setTotalFilteredList(numTotalList?.data?.[0]?.size ?? 0);

        const response = await filterClass(fieldsRefactor, false, page);

        const result = response?.data?.map(
          (item: {
            classStructure: keyof typeof classStructure;
            startDate: string | Date;
            endDate: string | Date;
          }) => ({
            ...item,
            date:
              item?.classStructure === classStructure.WORKSHOP
                ? moment(item?.startDate)
                    ?.tz("America/Sao_Paulo")
                    .format("DD/MM/YYYY HH:mm")
                : `${moment(item?.startDate)?.format("DD/MM/YYYY")} - ${moment(
                    item?.endDate,
                  )?.format("DD/MM/YYYY")}`,
          }),
        );

        if (result) setList(result);
        else setList([]);

        dispatch(setLoad(false) as unknown as Action);
      } else setList([]);

      dispatch(setLoad(false) as unknown as Action);
    },
    [
      dispatch,
      filters,
      findStructure,
      isAdminWorkshopOrBootcamp,
      page,
      pageLimit,
      user?.account?.classIds,
      verifyValue,
    ],
  );

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageLimit]);

  const headCells = useMemo(
    () => [
      {
        id: "code",
        label: "Código",
        orderBy: false,
        asc: true,
      },
      {
        id: "title",
        label: "Nome",
        orderBy: false,
        asc: false,
      },
      {
        id: "description",
        label: "Descrição",
        orderBy: false,
        asc: false,
      },
      { id: "date", label: "Data", orderBy: false, asc: false },
      {
        id: "skills",
        skills: "skills",
        label: "Habilidades",
        orderBy: false,
        asc: false,
      },
      {
        id: "status",
        label: "Status",
        orderBy: false,
        asc: false,
      },
      {
        id: "actions",
        label: "",
        orderBy: false,
        asc: false,
      },
    ],
    [],
  );

  const onChangeStatus = useCallback(
    async (row, status?: string) => {
      let currentStatus = status;
      dispatch(setLoad(true) as unknown as Action);

      if (status === classVacancyStatus.DISABLED) {
        const usedClass = await findUsesClass(row?._id ?? "");
        if (usedClass) {
          dispatch(setLoad(false) as unknown as Action);
          return false;
        }
      }

      if (!status) {
        if (row?.status !== classStatus.ACTIVE)
          currentStatus = classStatus.ACTIVE;
        else currentStatus = classStatus.PENDING;
      }

      try {
        await updateClassById(row?._id, { status: currentStatus });
        return true;
      } catch {
        return false;
      } finally {
        dispatch(setLoad(false) as unknown as Action);
      }
    },
    [dispatch],
  );

  const onRemove = useCallback(
    (listSelected) => {
      for (let i = 0; i < listSelected?.length; i += 1)
        onChangeStatus(listSelected[i], classVacancyStatus.DISABLED);

      getList();
    },
    [onChangeStatus, getList],
  );

  return (
    <Layout className="admin-class">
      <HeaderProfile />
      {!isAdminWorkshopOrBootcamp && (
        <Button
          text="Criar"
          className="grid-column-11-13 grid-row-1 request-vacancy"
          onClick={() => history.push("/admin-class-new")}
        />
      )}
      <Card
        className=" grid-column-1-13 grid-row-2 margin-bottom-32"
        bodyClassName="padding-0"
      >
        <TableRow className="admin-class-filters">
          <TextField
            autoComplete="off"
            placeholder="Buscar código"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.code ?? ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                code: e.target.value,
              }))
            }
            onBlur={() => getList()}
          />

          <TextField
            autoComplete="off"
            placeholder="Buscar turma"
            type="text"
            InputProps={{
              className: "search-header-profile Mui-input headerSearch",
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="search-header search-filter"
            value={filters?.title ?? ""}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                title: e.target.value,
              }))
            }
            onBlur={() => getList()}
          />

          <div className="admin-class-radios">
            <span className="admin-class-radios-title">Status</span>
            <RadioGroup value={filters?.status || undefined}>
              <div>
                {statusOptions?.map(({ value, label }) => (
                  <FormControlLabel
                    key={Math.random()}
                    value={value}
                    control={<Radio />}
                    label={label}
                    checked={value === filters?.status}
                    className="margin-right-32"
                    onChange={() => getList(value, "STATUS")}
                  />
                ))}
              </div>
            </RadioGroup>
          </div>

          <div className="admin-class-radios">
            <span className="admin-class-radios-title">Estrutura</span>

            <RadioGroup value={filters?.structure || undefined}>
              <div>
                {structure?.map(({ value, label }) => (
                  <FormControlLabel
                    key={Math.random()}
                    value={value}
                    control={<Radio />}
                    label={label}
                    checked={value === filters?.structure}
                    className="margin-right-32"
                    onChange={() => getList(value, "STRUCTURE")}
                  />
                ))}
              </div>
            </RadioGroup>
          </div>
        </TableRow>
        <Table
          cantSelect={isAdminWorkshopOrBootcamp}
          emptyText="Não há turmas cadastradas"
          headCells={headCells}
          list={list}
          onReload={() => getList()}
          onActive={
            !isAdminWorkshopOrBootcamp
              ? (row) => onChangeStatus(row as Row)
              : undefined
          }
          onFinish={
            !isAdminWorkshopOrBootcamp
              ? (row, status) => onChangeStatus(row as Row, status)
              : undefined
          }
          onEdit={
            !isAdminWorkshopOrBootcamp
              ? (row) => history.push(`/admin-class-edit/${row._id}`)
              : undefined
          }
          onRemove={
            !isAdminWorkshopOrBootcamp
              ? (row, status) => onChangeStatus(row as Row, status)
              : undefined
          }
          onAllRemove={!isAdminWorkshopOrBootcamp ? onRemove : undefined}
          onShow={(row) => history.push(`/admin-class-view/${row._id}`)}
        />
      </Card>
      <div className="grid-row-3 grid-column-1-9 width-100 flex margin-top--45">
        <div className="width-100">
          <p className="show-vacancies">
            Mostrando
            <span className="title-span">{list?.length}</span>
            de
            <span className="title-span">{totalFilteredList}</span>
            resultados
          </p>
        </div>
        <div className="width-100 justify-center">
          <Pagination page={page} setPage={setPage} maxPage={maxPage} />
        </div>
      </div>
    </Layout>
  );
};

export default memo(AdminClass);
