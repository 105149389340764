/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from "moment";
import { toast } from "react-toastify";
import { patchUser } from "../../services/functions";
import { SET_USER, USER_UPDATE, CLEAR_USER } from "../types/userTypes";

export const setUser = (user: any) => async (dispatch: any) => {
  dispatch({
    type: SET_USER,
    payload: user,
  });
};

export const clearUser = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_USER,
    payload: {},
  });
};

export const updateUser = (user: any) => async (dispatch: any) => {
  dispatch({
    type: USER_UPDATE,
    payload: user,
  });
  try {
    const storage = sessionStorage.getItem("@SESSION:user");

    const oldUser = storage ? JSON.parse(storage) : null;

    const newUser = oldUser
      ? JSON.parse(
          JSON.stringify({
            ...oldUser,
            ...user,
            updatedAt: moment().tz("America/Sao_Paulo").toISOString(),
          }),
        )
      : user;

    sessionStorage.setItem("@SESSION:user", JSON.stringify(newUser));

    delete newUser?._id;

    if (newUser?.profile && !newUser?.profile?.email)
      newUser.profile.email = newUser.login.identification;

    if (
      newUser?.login?.identification === oldUser?.login?.identification &&
      newUser?.login?.password === oldUser?.login?.password
    )
      delete newUser?.login;

    if (newUser?.profile?.age) delete newUser?.profile?.age;

    await patchUser(oldUser._id, newUser);
  } catch (err: any) {
    if (err.response) {
      // network error
      toast.error("Error: Network Error");
    } else {
      toast.error("Erro ao carregar/salvar dados, por favor relogue.");
    }
  }
};
