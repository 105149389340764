import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Address = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#2E3451"
      d="M9.715 12c1.151 0 2-.849 2-2s-.849-2-2-2-2 .849-2 2 .848 2 2 2zM18 9h-4v2h4V9zM18 13h-3v2h3v-2zM9.715 12.75c2.039 0 3.715 1.876 3.715 3.25H6c0-1.374 1.676-3.25 3.715-3.25z"
    />
    <path
      fill="#2E3451"
      fillRule="evenodd"
      d="M20 4H4c-1.103 0-2 .841-2 1.875v12.25C2 19.159 2.897 20 4 20h16c1.103 0 2-.841 2-1.875V5.875C22 4.841 21.103 4 20 4zm0 14l-16-.011V6l16 .011V18z"
      clipRule="evenodd"
    />
  </svg>
);

export default Address;
