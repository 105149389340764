/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment-timezone";
import React, { memo, useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AvatarEmpty, CompanyAvatarEmpty } from "../../../assets/customIcons";
import { Button, Modal, TextArea } from "../../components";
import { getUserById, updateAVacancy } from "../../services/functions";
import { RootState } from "../../store/reducers";
import { userRoles } from "../../types/enumerators";
import { hasCorporateRole } from "../../utils";
import { User } from "../../types/interfaces";
import { setLoad } from "../../store/actions/configurationsActions";

interface Props {
  item: any;
  fetch: () => Promise<void>;
}

interface ParamsProps {
  placementVacancyId: string;
}

const CardExpandMessages = ({ item, fetch }: Props): JSX.Element => {
  const { placementVacancyId } = useParams<ParamsProps>();

  const [messages, setMessages] = useState<any[] | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useSelector((state: RootState) => state.userState);
  const company = useSelector((state: RootState) => state.companyState);

  const getMessages = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const messsagesList: any[] = [];

    const itemMessages: any[] = [];
    item?.messages?.forEach((itemM: any[]) => itemMessages.push(itemM));

    if (itemMessages) {
      for (let i = 0; i < itemMessages?.length; i += 1) {
        const user = (await getUserById(itemMessages[i]?.userId)) as User;

        messsagesList.push({
          userName: user?.profile?.name,
          userPhoto: hasCorporateRole(user)
            ? company?.profile?.logo
            : user?.profile?.photo,
          date: moment(itemMessages[i]?.date ?? "")
            .tz("America/Sao_Paulo")
            .format("DD [de] MMMM"),
          text: itemMessages[i]?.text ?? "",
          type: hasCorporateRole(user)
            ? userRoles.CORPORATE
            : userRoles.STUDENT,
        });
      }
    }

    setMessages(messsagesList);
    dispatch(setLoad(false) as unknown as Action);
  }, [company?.profile?.logo, dispatch, item?.messages]);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const handleChange = useCallback((e) => setMessage(e.target.value), []);

  const onClose = useCallback(() => {
    setOpenModal(false);
    setMessage("");
  }, []);

  const onSend = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const newMessage = {
      text: message,
      date: moment().tz("America/Sao_Paulo").toISOString(),
      userId: currentUser._id,
    };

    try {
      await updateAVacancy(item?.placementVacancyId, item?.userId, {
        messages: [...(item?.messages ?? []), newMessage],
      });
      toast.success("Mensagem enviada com sucesso!");
    } catch (err) {
      toast.error("Ocorreu um erro ao enviar sua mensagem");
    }
    await fetch();
    setOpenModal(false);
    setMessage("");

    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, item, message, currentUser._id, fetch]);

  return (
    <div className="width-100 max-height-440 overflow-x-hidden">
      {messages && messages?.length > 0 && (
        <div className="div-card-comments">
          <p className="comment-user-name">{item?.userName}</p>
          <p className="list-comment-date">{messages[0]?.date}</p>
        </div>
      )}
      {messages && messages?.length > 0 ? (
        messages?.map((msg, index) => (
          <div>
            <div className="width-100">
              {index === 0 ||
                (index > 0 && msg.date !== messages[index - 1].date && (
                  <div className="linha-data">
                    <span>{msg.date}</span>
                  </div>
                ))}

              <div className="div-area-comment">
                {msg.userPhoto ? (
                  <img
                    src={msg.userPhoto}
                    alt="Foto"
                    className="company-avatar-empty-card"
                  />
                ) : msg.type === userRoles.CORPORATE ? (
                  <CompanyAvatarEmpty
                    className="company-avatar-empty-card"
                    heightIcon={48}
                    widthIcon={48}
                  />
                ) : (
                  <AvatarEmpty className="company-avatar-empty-card" />
                )}
                <p className="comment-text">{msg.text}</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="margin-left-24">Não há mensagens</p>
      )}
      <div className="divider-card" />
      <div className="flex space-between padding-buttons flex-col-mobile gap-16-mobile">
        <Button
          text="Voltar"
          className="width-20 margin-right-24 width-100-responsive"
          onClick={() => history.push(`/company-vacancy/${placementVacancyId}`)}
          outline
          appearance="secondary"
        />
        <div className="width-30 flex width-100-mobile">
          <Button
            text="Enviar mensagem"
            className="width-100"
            onClick={() => setOpenModal(true)}
          />
        </div>
      </div>
      <Modal
        headerAction
        textHeader="Converse com a candidata"
        open={openModal}
        onClose={onClose}
        bodyClassName="padding-0"
      >
        <div className="width-310">
          <TextArea
            id="message"
            label="Mensagem*"
            maxLength={500}
            value={message}
            onChange={handleChange}
          />
          <div className="flex-row-center margin-top-32 flex-col-mobile gap-16-mobile">
            <Button
              text="Cancelar"
              className="width-100-mobile"
              outline
              appearance="secondary"
              onClick={onClose}
            />
            <Button
              text="Salvar"
              className="margin-left-32 width-100-mobile margin-left-0-mobile"
              onClick={onSend}
              disabled={!message}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default memo(CardExpandMessages);
