import React, { memo } from "react";
import { Button, Modal } from "../../../components";
import { Block, Unblock } from "../../../../assets/customIcons";
import "../style.scss";

interface Props {
  open: boolean;
  onclose: () => void;
  success: boolean;
  error?: {
    funct: () => void;
    user: {
      name: string;
      email: string;
    }[];
  };
}

const Error = ({ open, onclose, success, error }: Props): JSX.Element => (
  <Modal
    headerAction
    open={open}
    onClose={onclose}
    textHeader="Erro na ação desejada"
  >
    <div>
      <div className="admin-subscriptions-error">
        <Block />
        <p className="admin-subscriptions-error-text">
          Ocorreu um erro ao executar a ação com a(s) candidata(s):
        </p>
      </div>
      {error?.user?.map((item: { name: string; email: string }) => (
        <p className="admin-subscriptions-error-items">{`- ${item?.name} - ${item?.email}`}</p>
      ))}
      {success && (
        <div className="admin-subscriptions-error">
          <Unblock className="admin-subscriptions-error-success" />
          <p className="admin-subscriptions-error-text">
            Sucesso ao executar a ação com a(s) outra(s) candidata(s).
          </p>
        </div>
      )}
    </div>
    <div className="admin-subscriptions-modal-buttons margin-top-32">
      <Button text="Voltar" onClick={onclose} outline appearance="secondary" />
      <Button onClick={error?.funct} text="Tentar novamente" />
    </div>
  </Modal>
);

export default memo(Error);
