import React, { useMemo } from "react";
import { classVacancyType } from "../../../types/enumerators";
import { WorkshopViewProps } from "./types";

export const styles = {
  slider: {
    color: "#7e459b",
  },
};

const WorkshopEdit = ({
  subscription,
  course,
}: WorkshopViewProps): JSX.Element => {
  const stageWorkshop = useMemo(() => {
    if (subscription && course) {
      const workshop = course?.stages?.find(
        (item) => item?.type === classVacancyType.WORKSHOP,
      );

      const findUserWorkshop = subscription?.stages?.find(
        ({ stageId }) => stageId === workshop?.stage_id,
      );

      if (findUserWorkshop) return findUserWorkshop;
    }

    return null;
  }, [subscription, course]);

  const grades = useMemo(
    () => ({
      diversity:
        stageWorkshop?.grade?.find(({ type }) => type === "DIVERSITY")?.value ??
        0,
      behavior:
        stageWorkshop?.grade?.find(({ type }) => type === "BEHAVIOR")?.value ??
        0,
      technical:
        stageWorkshop?.grade?.find(({ type }) => type === "TECHNICAL")?.value ??
        0,
      final:
        stageWorkshop?.grade?.find(({ type }) => type === "FINAL")?.value ?? 0,
      morning: stageWorkshop?.participant?.morning || false,
      afternoon: stageWorkshop?.participant?.afternoon || false,
    }),
    [
      stageWorkshop?.grade,
      stageWorkshop?.participant?.afternoon,
      stageWorkshop?.participant?.morning,
    ],
  );

  const participant = useMemo(
    () =>
      stageWorkshop?.participant &&
      (stageWorkshop?.participant?.morning ||
        stageWorkshop?.participant?.afternoon),
    [stageWorkshop?.participant],
  );

  return (
    <div className="subscriptions-workshop">
      <div className="subscriptions-workshop-content propose">
        {participant ? (
          <div className="subscriptions-workshop-items">
            <div className="subscriptions-workshop-items-content">
              <div className="label-infos">
                <p className="label-title">Presença manhã</p>
                <p className="label-description">
                  {grades?.morning ? "Sim" : "Não"}
                </p>
              </div>

              <div className="label-infos">
                <p className="label-title">Presença tarde</p>
                <p className="label-description">
                  {grades?.afternoon ? "Sim" : "Não"}
                </p>
              </div>
            </div>

            <div className="subscriptions-workshop-items-content">
              <div className="label-infos">
                <p className="label-title">Nota de diversidade</p>
                <p className="label-description">{grades?.diversity ?? 0}</p>
              </div>

              <div className="label-infos">
                <p className="label-title">Nota comportamental</p>
                <p className="label-description">{grades?.behavior ?? 0}</p>
              </div>

              <div className="label-infos">
                <p className="label-title">Nota técnica</p>
                <p className="label-description">{grades?.technical ?? 0}</p>
              </div>

              <div className="label-infos">
                <p className="label-title">Nota final</p>
                <p className="label-description">{grades?.final ?? 0}</p>
              </div>
            </div>
          </div>
        ) : (
          <p>Este usuário não participou da Oficina.</p>
        )}
      </div>
    </div>
  );
};

export default WorkshopEdit;
