/* eslint-disable @typescript-eslint/no-explicit-any */
import { STEP_REGISTER } from "../types/stepRegisterTypes";

const initialState = {
  page: 0,
  maxPageVisited: 0,
  maxMenuVisited: 0,
};

export const stepRegisterReducer = (
  state = initialState,
  { type, payload }: { type: string; payload: any },
): any => {
  switch (type) {
    case STEP_REGISTER:
      return { ...state, ...payload };

    default:
      return state;
  }
};
