/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import {
  AttachmentMedia,
  CardMedia,
  Checkbox,
  Draggable,
  Input,
  InputDate,
  ModalMedia,
  TextArea,
} from "..";
import api from "../../services/api";
import { mediaType, userPortfolioType } from "../../types/enumerators";
import { Media, UserCurriculumPortfolio } from "../../types/interfaces";
import "./styles.scss";

interface Props {
  type?: string;
  portfolio: UserCurriculumPortfolio;
  setItem: any;
  setIsValid: any;
  setIsCurrentProject?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddPortfolio = ({
  type,
  portfolio,
  setItem,
  setIsValid,
  setIsCurrentProject,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [editMediaIndex, setEditMediaIndex] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [media, setMedia] = useState<Media>({});
  const [_isCurrentProject, _setIsCurrentProject] = useState(
    portfolio?.endDate !== undefined ? !portfolio?.endDate : false,
  );
  const [_portfolio, _setPortfolio] =
    useState<UserCurriculumPortfolio>(portfolio);

  useEffect(() => {
    if (type) {
      _setPortfolio({
        ...portfolio,
        type,
      });
    }
  }, [type, portfolio]);

  useEffect(() => {
    if (
      _portfolio &&
      _portfolio.title &&
      _portfolio.url &&
      (_portfolio.type !== userPortfolioType.PROJECT ||
        (_portfolio.startDate && _portfolio.endDate) ||
        _isCurrentProject)
    )
      setIsValid(true);
    else setIsValid(false);
  }, [_portfolio, _isCurrentProject, setIsValid]);

  useEffect(() => {
    if (_portfolio && _portfolio?.medias?.length) {
      _portfolio?.medias?.sort((a: Media, b: Media) => {
        if ("order" in a && "order" in b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        }
        return 0;
      });
    }
  }, [_portfolio]);

  const handleOnBlur = useCallback(
    () => setItem(_portfolio),
    [_portfolio, setItem],
  );

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: keyof UserCurriculumPortfolio,
      value?: boolean,
    ) => {
      if (value !== undefined) {
        _setPortfolio({ ..._portfolio, [prop]: value });
      } else _setPortfolio({ ..._portfolio, [prop]: event.target.value });
    },
    [_portfolio],
  );

  const handleChangeDate = useCallback(
    (value: string | string[] | null, prop: keyof UserCurriculumPortfolio) => {
      _setPortfolio({
        ..._portfolio,
        [prop]: value?.clone().startOf("month").toISOString(),
      });
      setItem({
        ..._portfolio,
        [prop]: value?.clone().startOf("month").toISOString(),
      });
    },
    [_portfolio, setItem],
  );

  const saveMedia = useCallback(() => {
    let { medias: newMedias } = _portfolio;

    if (editMediaIndex !== -1) {
      newMedias[editMediaIndex] = media;
    } else if (newMedias?.length) newMedias.push(media);
    else newMedias = [media];

    _setPortfolio({ ..._portfolio, medias: newMedias });
    setItem({ ..._portfolio, medias: newMedias });
    setMedia({});
    setEditMediaIndex(-1);
    setOpenModal(false);
  }, [_portfolio, editMediaIndex, media, setItem]);

  const removeMedia = useCallback(
    (index: number) => {
      let { medias: oldMedias } = _portfolio;
      oldMedias.splice(index, 1);
      oldMedias = oldMedias.map((item: Media, i: number) => ({
        ...item,
        order: i,
      }));
      _setPortfolio({ ..._portfolio, medias: oldMedias });
      setItem({ ..._portfolio, medias: oldMedias });
    },
    [_portfolio, setItem],
  );

  const editMedia = useCallback((item: Media, index: number) => {
    setMedia({ ...item });
    setEditMediaIndex(index);
    setOpenModal(true);
  }, []);

  const handleMedia = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      api
        .post("/fileUpload", data)
        .then((result) => {
          setOpenModal(true);
          setMedia({
            ...media,
            url: result?.data?.file_url.url,
            type: fileList[0].type.includes("image")
              ? mediaType.PHOTO
              : mediaType.DOCUMENT,
            order: _portfolio?.medias?.length ?? 0,
          });
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    },
    [_portfolio, media],
  );

  const handleChangeMedia = useCallback(
    (prop: keyof Media) => (event: ChangeEvent<HTMLInputElement>) =>
      setMedia({ ...media, [prop]: event.target.value }),
    [media],
  );

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setEditMediaIndex(-1);
    setMedia({});
  }, []);

  const reorderMedias = useCallback(
    (items: Media[]) => {
      let { medias: newMedias } = _portfolio;
      newMedias = items.map((item, index) => ({ ...item, order: index }));
      _setPortfolio({ ..._portfolio, medias: newMedias });
      setItem({ ..._portfolio, medias: newMedias });
    },
    [_portfolio, setItem],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <div className="flex-row-center margin-bottom-16 add-portfolio">
        {width < 991 && <p className="p-mobile">Título*</p>}
        <Input
          id="title"
          label="Título*"
          type="text"
          value={_portfolio ? _portfolio.title : ""}
          onChange={(e) => handleChange(e, "title")}
          onBlur={handleOnBlur}
          className="add-portfolio-input width-100 margin-bottom-20-mobile"
        />
        {width < 991 && <p className="p-mobile">Link*</p>}
        <Input
          label="Link*"
          type="text"
          value={_portfolio ? _portfolio.url : ""}
          onChange={(e) => handleChange(e, "url")}
          onBlur={handleOnBlur}
          className="width-100"
        />
      </div>
      {width < 991 && <p className="p-mobile">Descrição*</p>}
      <TextArea
        id="description"
        label="Descrição"
        maxLength={200}
        value={_portfolio ? _portfolio.description : ""}
        onChange={(e) => handleChange(e, "description")}
        onBlur={handleOnBlur}
        className="add-portolio-input-goals margin-bottom-20-mobile margin-top-0-mobile"
      />
      {_portfolio && _portfolio.type === userPortfolioType.PROJECT && (
        <div>
          <div className="flex margin-bottom-16 add-portfolio-inputs">
            <div>
              {width < 991 && (
                <p className="p-mobile margin-bottom-16-mobile">Início*</p>
              )}
              <InputDate
                label="Início*"
                helperText="MM/AAAA"
                value={_portfolio?.startDate ?? null}
                onChange={(value) => handleChangeDate(value, "startDate")}
                className="add-portolio-input-date margin-right-16"
              />
            </div>
            <div>
              {width < 991 && !_isCurrentProject && (
                <p className="p-mobile margin-bottom-16-mobile">Fim*</p>
              )}
              {!_isCurrentProject ? (
                <InputDate
                  label="Fim*"
                  helperText="MM/AAAA"
                  value={_portfolio?.endDate ?? null}
                  minDate={_portfolio?.startDate ?? null}
                  onChange={(value) => handleChangeDate(value, "endDate")}
                  className="add-portolio-input-date margin-right-16"
                />
              ) : (
                <div className="add-portfolio-input" />
              )}
            </div>

            {width > 990 && (
              <Checkbox
                id="_isCurrentProject"
                label="Continuo trabalhando neste projeto"
                className="margin-top-16"
                checked={_isCurrentProject}
                onChange={() => {
                  if (setIsCurrentProject)
                    setIsCurrentProject(!_isCurrentProject);
                  _setIsCurrentProject(!_isCurrentProject);
                  _setPortfolio({ ..._portfolio, endDate: null });
                  setItem({ ..._portfolio, endDate: null });
                }}
              />
            )}
          </div>
          {width < 991 && (
            <Checkbox
              id="_isCurrentProject"
              label="Continuo trabalhando neste projeto"
              className="margin-top-16"
              checked={_isCurrentProject}
              onChange={() => {
                if (setIsCurrentProject)
                  setIsCurrentProject(!_isCurrentProject);
                _setIsCurrentProject(!_isCurrentProject);
                _setPortfolio({ ..._portfolio, endDate: null });
                setItem({ ..._portfolio, endDate: null });
              }}
            />
          )}
          <AttachmentMedia
            onChange={handleMedia}
            className="margin-top-24"
            file
          />
          {_portfolio && _portfolio.medias?.length > 0 && (
            <Draggable items={_portfolio.medias} reorderItem={reorderMedias}>
              <div className="flex flex-wrap align-items">
                {_portfolio.medias?.map((item, index) => (
                  <DraggableComponent
                    key={Math.random()}
                    draggableId={Math.random().toString()}
                    index={index}
                    isDragDisabled={_portfolio.medias?.length === 1}
                  >
                    {(innerProvided) => (
                      <div
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                        {...innerProvided.dragHandleProps}
                      >
                        <CardMedia
                          key={Math.random()}
                          item={item}
                          onEdit={() => editMedia(item, index)}
                          onRemove={() => removeMedia(index)}
                        />
                      </div>
                    )}
                  </DraggableComponent>
                ))}
              </div>
            </Draggable>
          )}
        </div>
      )}

      <ModalMedia
        openModal={openModal}
        onClose={closeModal}
        onChange={handleChangeMedia}
        medias={media}
        onSave={saveMedia}
        disabled={!media.title || !media.description}
      />
    </div>
  );
};

export default memo(AddPortfolio);
