/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */
import React, {
  memo,
  useEffect,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import moment from "moment-timezone";
import "./style.scss";
import {
  Layout,
  HeaderProfile,
  CardMenuLeft,
  Modal,
  TextArea,
  Button,
} from "../../components";
import {
  acceptedProposalCompanyVacancy,
  acceptedProposalUserVacancy,
  addCompanyToUser,
  changeVacancySubscription,
  getUserById,
  refusedProposalCompanyVacancy,
  vacanciesPerUser,
} from "../../services/functions";
import CardCandidature from "../Candidature/CardCandidature";
import {
  placementVacancySubscriptionStatus,
  userRoles,
} from "../../types/enumerators";
import { RootState } from "../../store/reducers";
import {
  AvatarEmpty,
  ChevronUp,
  CompanyAvatarEmpty,
  Proposals as ProposalsIcon,
} from "../../../assets/customIcons";
import { isCorporate } from "../../utils";
import { setLoad } from "../../store/actions/configurationsActions";

interface ProposalsJobsProps {
  vacancieSubId?: string;
  jobTitle?: string;
  companyName?: string;
  jobStatus?: string;
  jobType?: string;
  jobPlace?: string;
  photo?: string;
  _id?: string;
  jobDescription?: string;
  startDate?: string | Date;
  vacanciesStages?: Array<{
    autoClose?: boolean;
    autoOpen?: boolean;
    id?: string;
    order: number;
    title?: string;
  }>;
  stages?: Array<{
    stageId?: string;
    autoClose?: boolean;
    autoOpen?: boolean;
    id?: string;
    order: number;
    title?: string;
    status?: keyof typeof placementVacancySubscriptionStatus;
  }>;
}

interface SelectedProposalProps {
  messages?: Array<{ text: string; date: string; userId: string }>;
  startDate?: Date;
  stageId?: string;
  status?: keyof typeof placementVacancySubscriptionStatus;
}

interface PropsProposals {
  proposalsJobs?: ProposalsJobsProps[];
  selectedProposal?: SelectedProposalProps;
  handleClick: (id: string) => void;
  isSelected?: string;
  showMainCard?: boolean;
  messages?: {
    text?: string;
    date?: string;
    userId?: string;
    photo?: string;
    type?: string;
  }[];
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  handleButtonClick: any;
}

const RenderProposals = ({
  proposalsJobs,
  selectedProposal,
  handleClick,
  isSelected,
  showMainCard,
  messages,
  setOpenModal,
  handleButtonClick,
}: PropsProposals): JSX.Element => (
  <div
    className={
      proposalsJobs && proposalsJobs?.length > 0 && selectedProposal
        ? "card-candidature card-candidature-100"
        : "card-candidature"
    }
  >
    <div className="card-title-div">
      <h2 className="card-title"> Propostas </h2>
    </div>
    <div className="flex">
      <div
        className={`card-candidature_scroll proposals-scroll ${
          proposalsJobs && proposalsJobs?.length > 0
            ? ""
            : "proposals-scroll-border"
        }`}
      >
        {proposalsJobs && proposalsJobs?.length > 0 ? (
          proposalsJobs?.map((vacancy) => (
            <div
              key={Math.random()}
              role="none"
              onClick={() => handleClick(vacancy?._id ?? "")}
              className="div-border-bottom pointer"
            >
              <CardCandidature
                data={vacancy}
                selectedId={isSelected === vacancy?._id}
              />
            </div>
          ))
        ) : (
          <p className="margin-left-24">Não há propostas disponíveis</p>
        )}
      </div>
      {showMainCard && (
        <div className="show-main-card">
          <div className="header-inside-card">
            <h2>Proposta da empresa</h2>
            <span>
              {moment(selectedProposal?.startDate)
                .tz("America/Sao_Paulo")
                .format("DD [de] MMMM")}
            </span>
          </div>
          {selectedProposal &&
            selectedProposal?.messages &&
            selectedProposal?.messages?.length > 0 &&
            messages?.map((msg) => (
              <div key={Math.random()} className="padding-24 flex-row-center">
                <div className="margin-right-16">
                  {msg?.photo ? (
                    <img
                      src={msg?.photo}
                      alt="Foto"
                      className="avatar-message"
                    />
                  ) : msg?.type === userRoles.CORPORATE ? (
                    <CompanyAvatarEmpty
                      className="company-avatar-empty"
                      heightIcon={48}
                      widthIcon={48}
                    />
                  ) : (
                    <AvatarEmpty className="company-avatar-empty" />
                  )}
                </div>
                <p className="proposal-messages">{msg?.text}</p>
              </div>
            ))}
          <div className="bottom-card-info flex space-between">
            <span
              role="button"
              tabIndex={0}
              onClick={() => setOpenModal(true)}
              className="talk-with-company margin-top-10"
            >
              Conversar com a empresa
            </span>
            <div className="buttons-wrapper">
              <button
                type="button"
                className="refuse-button"
                onClick={() => handleButtonClick(false)}
              >
                Recusar
              </button>
              <button
                type="button"
                className="accept-button"
                onClick={() => handleButtonClick(true)}
              >
                Aceitar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
);

const RenderProposalsMobile = ({
  proposalsJobs,
  handleClick,
  isSelected,
  showMainCard,
  selectedProposal,
  messages,
  setOpenModal,
  handleButtonClick,
}: PropsProposals): JSX.Element => (
  <div className="margin-top- margin-bottom-muiAccordion">
    {proposalsJobs && proposalsJobs?.length > 0 ? (
      proposalsJobs?.map((vacancy) => (
        <Accordion className="margin-top-0 header-modal">
          <AccordionSummary
            expandIcon={<ChevronUp widthIcon={24} heightIcon={24} />}
            className="expanded-icon margin-mui-expanded margin-bottom-accordion"
          >
            <div className="card-candidature_scroll proposals-scroll">
              <div
                key={Math.random()}
                role="none"
                onClick={() => handleClick(vacancy?._id ?? "")}
                className="div-border-bottom pointer"
              >
                <CardCandidature
                  data={vacancy}
                  selectedId={isSelected === vacancy?._id}
                />
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className="margin-mui-expanded">
            {showMainCard && (
              <div className="show-main-card">
                <div className="header-inside-card">
                  <h2>Proposta da empresa</h2>
                  <span>
                    {moment(selectedProposal?.startDate)
                      .tz("America/Sao_Paulo")
                      .format("DD [de] MMMM")}
                  </span>
                </div>
                {selectedProposal &&
                  selectedProposal?.messages &&
                  selectedProposal?.messages?.length > 0 &&
                  messages?.map((msg) => (
                    <div
                      key={Math.random()}
                      className="padding-24 flex-row-center"
                    >
                      <div className="margin-right-16">
                        {msg?.photo ? (
                          <img
                            src={msg?.photo}
                            alt="Foto"
                            className="avatar-message"
                          />
                        ) : (
                          <AvatarEmpty className="company-avatar-empty" />
                        )}
                      </div>
                      <p className="proposal-messages">{msg?.text}</p>
                    </div>
                  ))}
                <div className="bottom-card-info ">
                  <div className="buttons-wrapper flex-direction-column-reverse">
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={() => setOpenModal(true)}
                      className="talk-with-company margin-top-10"
                    >
                      Conversar com a empresa
                    </span>
                    <button
                      type="button"
                      className="refuse-button"
                      onClick={() => handleButtonClick(false)}
                    >
                      Recusar
                    </button>
                    <button
                      type="button"
                      className="accept-button bottom-20-mobile"
                      onClick={() => handleButtonClick(true)}
                    >
                      Aceitar
                    </button>
                  </div>
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      ))
    ) : (
      <div className="card-candidature margin-top-16-responsive">
        <p className="margin-left-24 not-proposed">
          Não há propostas disponíveis
        </p>
      </div>
    )}
  </div>
);

const Proposals = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isSelected, setIsSelected] = useState("");
  const [showMainCard, setShowMainCard] = useState(false);
  const [selectedProposal, setSelectedProposal] =
    useState<SelectedProposalProps>();
  const [proposalsJobs, setProposalsJobs] = useState<
    ProposalsJobsProps[] | []
  >();
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const user = useSelector((state: RootState) => state.userState);
  const [selectedVacancy, setSelectedVacancy] = useState<ProposalsJobsProps>();
  const [messages, setMessages] = useState<
    {
      type: string;
      text: string;
      photo: string;
      userId: string;
    }[]
  >();
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const loadSubscritedJobs = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    try {
      const jobs = await vacanciesPerUser(
        user?._id,
        placementVacancySubscriptionStatus.IN_PROCESS,
      );

      const jobsFiltered = jobs?.filter((job) => {
        if (job?.placementVacancyData?.stages) {
          const proposalItem = job?.placementVacancyData?.stages?.find(
            (item) => item?.title === "Proposta",
          );
          return job?.stages?.find(
            (item) =>
              item?.status === placementVacancySubscriptionStatus.IN_PROCESS &&
              item?.stageId === proposalItem?.id,
          );
        }
        return null;
      });

      const jobsMapped = jobsFiltered?.map((job) => ({
        vacancieSubId: job?._id,
        _id: job?.placementVacancyData?._id,
        jobTitle: job?.placementVacancyData?.title,
        companyName: job?.companyData?.profile?.name,
        jobType: job?.placementVacancyData?.type,
        jobPlace: job?.placementVacancyData?.place,
        photo: job?.companyData?.profile?.logo,
        jobDescription: job?.placementVacancyData?.description,
        startDate: job?.startDate,
        vacanciesStages: job?.placementVacancyData?.stages,
        stages: job?.stages,
        jobStatus: job?.placementVacancyData?.status ?? "",
        jobManagedBy: job?.placementVacancyData?.managedBy ?? "",
        jobTypeOfCandidature:
          job?.placementVacancyData?.typeOfCandidature ?? "",
        jobTime: job?.placementVacancyData?.startDate,
      }));
      setProposalsJobs(jobsMapped ?? []);
    } catch (err) {
      toast.error("Ocorreu um erro ao carregar as propostas");
    } finally {
      dispatch(setLoad(false) as unknown as Action);
    }
  }, [dispatch, user?._id]);

  const getMessages = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const messsagesList: any = [];

    if (selectedProposal?.messages && selectedProposal?.messages?.length) {
      for (let i = 0; i < selectedProposal?.messages?.length; i += 1) {
        const responseUser = await addCompanyToUser(
          await getUserById(selectedProposal?.messages[i]?.userId ?? ""),
        );
        messsagesList.push({
          photo: responseUser?.profile?.photo,
          text: selectedProposal?.messages[i]?.text ?? "",
          userId: selectedProposal?.messages[i]?.userId,
          type: isCorporate(responseUser)
            ? userRoles.CORPORATE
            : userRoles.STUDENT,
        });
      }
    }
    setMessages(messsagesList);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, selectedProposal?.messages]);

  useEffect(() => {
    if (selectedProposal) getMessages();
  }, [getMessages, selectedProposal]);

  useEffect(() => {
    setShouldUpdate(false);
    setShowMainCard(false);
    if (!proposalsJobs) loadSubscritedJobs();
  }, [user?._id, shouldUpdate, proposalsJobs, loadSubscritedJobs]);

  const handleClick = useCallback(
    (id: string) => {
      const selectedVacancyItem = proposalsJobs?.find(
        (item) => item?._id === id,
      );
      const proposalStageId = selectedVacancyItem?.vacanciesStages?.find(
        (item) => item?.title === "Proposta",
      );
      setIsSelected(id);

      setSelectedVacancy(proposalsJobs?.find((item) => item?._id === id));

      setSelectedProposal(
        selectedVacancyItem?.stages?.find(
          (item) => item?.stageId === proposalStageId?.id,
        ) ?? {},
      );

      setShowMainCard(true);
    },
    [proposalsJobs],
  );

  const handleButtonClick = useCallback(
    async (hasAccept: boolean) => {
      try {
        const updatedStages =
          selectedVacancy?.stages?.map((item) => {
            if (item?.stageId === selectedProposal?.stageId) {
              return {
                ...item,
                status: hasAccept
                  ? placementVacancySubscriptionStatus.APPROVED
                  : placementVacancySubscriptionStatus.NONE,
              };
            }
            return item;
          }) ?? [];
        await changeVacancySubscription(selectedVacancy?.vacancieSubId ?? "", {
          status: hasAccept
            ? placementVacancySubscriptionStatus.APPROVED
            : placementVacancySubscriptionStatus.NONE,
          stages: [...updatedStages],
        }).then(async () => {
          if (hasAccept) {
            await acceptedProposalCompanyVacancy(
              user?._id,
              selectedVacancy?._id ?? "",
            );
            await acceptedProposalUserVacancy(
              user?._id,
              selectedVacancy?._id ?? "",
            );
          } else
            await refusedProposalCompanyVacancy(
              user?._id,
              selectedVacancy?._id ?? "",
            );
        });
        setShouldUpdate(true);
        loadSubscritedJobs();
      } catch (error) {
        console.warn(error);
        toast.error("Ocorreu um erro ao aceitar a proposta");
      }
    },
    [
      selectedVacancy?.stages,
      selectedVacancy?.vacancieSubId,
      selectedVacancy?._id,
      loadSubscritedJobs,
      selectedProposal?.stageId,
      user?._id,
    ],
  );

  const handleChangeMessage = useCallback(
    (e) => setMessage(e.target.value),
    [],
  );

  const onSend = useCallback(async () => {
    let { messages: newMessages } = selectedProposal ?? {};

    const newMessage = {
      text: message,
      date: moment().tz("America/Sao_Paulo").toISOString(),
      userId: user?._id ?? "",
    };

    if (newMessages?.length) newMessages.push(newMessage);
    else newMessages = [newMessage];

    setSelectedProposal({ ...selectedProposal, messages: newMessages });

    const updatedStages =
      selectedVacancy?.stages?.map((item) => {
        if (item?.stageId === selectedProposal?.stageId) {
          return {
            ...item,
            messages: newMessages,
          };
        }
        return item;
      }) ?? [];

    try {
      await changeVacancySubscription(selectedVacancy?.vacancieSubId ?? "", {
        stages: [...updatedStages],
      });
      getMessages();
      toast.success("Mensagem enviada com sucesso!");
    } catch (err) {
      toast.error("Ocorreu um erro ao enviar sua mensagem");
    }
    setOpenModal(false);
    setMessage("");
  }, [getMessages, message, selectedProposal, selectedVacancy, user?._id]);

  const onClose = useCallback(() => {
    setMessage("");
    setOpenModal(false);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout className="grid-template-column-1 grid-responsive">
      <HeaderProfile />
      <div className="grid-column-1-11">
        <div className="grid-template-columns-1-5 display-block">
          <CardMenuLeft
            icon={<ProposalsIcon widthIcon={20} heightIcon={20} />}
            selectedMenuTitle="Propostas"
          />
          {width > 990 ? (
            <RenderProposals
              proposalsJobs={proposalsJobs}
              selectedProposal={selectedProposal}
              handleClick={handleClick}
              isSelected={isSelected}
              showMainCard={showMainCard}
              messages={messages}
              setOpenModal={setOpenModal}
              handleButtonClick={handleButtonClick}
            />
          ) : (
            <RenderProposalsMobile
              proposalsJobs={proposalsJobs}
              selectedProposal={selectedProposal}
              handleClick={handleClick}
              isSelected={isSelected}
              showMainCard={showMainCard}
              messages={messages}
              setOpenModal={setOpenModal}
              handleButtonClick={handleButtonClick}
            />
          )}
        </div>
      </div>
      <Modal
        headerAction
        textHeader="Converse com a empresa"
        open={openModal}
        onClose={onClose}
        bodyClassName="modal-talk-company-proposals"
      >
        <div className="width-310">
          <TextArea
            id="message"
            label="Escreva sua mensagem*"
            maxLength={500}
            value={message}
            onChange={handleChangeMessage}
            textFielRow={10}
          />
          <div className="flex-row-center margin-btn-message">
            <Button
              text="Cancelar"
              className="button-secondary width-100"
              onClick={onClose}
            />
            <Button
              text="Enviar"
              className="margin-left-24 width-100"
              onClick={onSend}
              disabled={!message}
            />
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

export default memo(Proposals);
