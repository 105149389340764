/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import moment from "moment";
import React, {
  memo,
  useState,
  Dispatch,
  useEffect,
  useCallback,
  ChangeEvent,
  SetStateAction,
} from "react";
import {
  info,
  education,
  reprograma,
  socioEconomicProfile,
  socioEconomicAssessment,
} from "./data";
import type {
  User,
  UserProfileAddress,
  ClassVacancy,
  ClassVacancySubscription,
} from "../../../types/interfaces";
import { Edit, Delete } from "../../../../assets/customIcons";
import {
  mediaType,
  autoCompleteType,
  classVacancyType,
  userIdentityType,
} from "../../../types/enumerators";
import {
  Button,
  Medias,
  ButtonMenu,
  FormAddress,
  Input,
  IconButton,
  Select,
  EditSocialMedias,
} from "../../../components";
import {
  calcSubscriptionRanking,
  getAllActiveClassVacancy,
  getAutocomplete,
  getClassVacancyById,
  getClassVacancySubscriptionById,
  patchUser,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import { Items, ConfirmRemove } from "../../Profile/Modal";
import {
  NumItems,
  Target,
  UniversityTitle,
  DisabledPersonDescription,
  EnglishLevel,
  HowToKnowReprograma,
  ITLevel,
  TypeOfStreet,
  OriginOfWater,
  Justification,
  LevelEducation,
  UnemployedTime,
  CourseOfReprograma,
  VolunteerDescription,
  EmployabilitySituation,
  DifficultiesProgramming,
} from "../../../types/constants/Course";
import { CurrentJoblevel } from "../../../types/constants/Employment";
import {
  IdentityType,
  ProfileGender,
  ProfileRace,
} from "../../../types/constants/User";
import {
  maskBRlegalIdentity,
  maskDate,
  maskPhone,
  maskReal,
  removeMaskReal,
} from "../../../utils";
import "../style.scss";
import { Add } from "../../../../assets/icons";

interface Props {
  user: User;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  subscription: ClassVacancySubscription;
  setSubscription: Dispatch<
    SetStateAction<ClassVacancySubscription | undefined>
  >;
  course: ClassVacancy;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  step: number;
  reload: () => void;
}

interface InfoProps {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  handleChangeUser: (value: string | number | boolean, prop: string) => void;
}

interface ReprogramaProps {
  course?: ClassVacancy;
  subscription: any;
  handleChangeRadioSubscription: (value: string, props: any) => void;
  handleChangeSelectSubscription: (
    event: {
      target: {
        value: string;
      };
    },
    prop: string,
  ) => void;
}

interface socioEconomicProps {
  user: User | null;
  course?: ClassVacancy;
  subscription: any;
  handleChangeUser: (value: string | number | boolean, prop: string) => void;
  handleChangeSubscription: (
    value: string | number | boolean,
    prop: string,
  ) => void;
  handleChangeRevenue: (value: string, props: string) => void;
  handleChangeRadioUser: any;
  needAccessibilityAdaptation?: boolean;
  handleChangeRadioSubscription: (value: string, props: any) => void;
  setNeedAccessibilityAdaptation: (
    value: SetStateAction<boolean | undefined>,
  ) => void;
  handleChangeSelectSubscription: (
    event: {
      target: {
        value: string;
      };
    },
    prop: string,
  ) => void;
}

interface socioEconomicAssessmentProps {
  user: User | null;
  course?: ClassVacancy;
  subscription: any;
  handleChangeSelectSubscription: (
    event: {
      target: {
        value: string;
      };
    },
    prop: string,
  ) => void;
}

interface ReceiptProps {
  item: any;
  type: "Portfólio" | "Certificado";
  index: number;
  modal: Dispatch<SetStateAction<boolean>>;
  setIndex: Dispatch<SetStateAction<number>>;
  endDate?: string | Date | null;
  startDate?: string | Date;
  setRemoved: Dispatch<
    SetStateAction<{
      id: number;
      type: string;
      title: string;
      text: string;
    }>
  >;
  setPortfolioType: Dispatch<SetStateAction<string>>;
  setOpenModalRemove: Dispatch<SetStateAction<boolean>>;
}

interface IRegistrationLabel {
  text: string;
  disabled?: boolean;
  optional?: boolean;
  alignStart?: boolean;
}

interface FormProps extends IRegistrationLabel {
  id: string;
  value: any;
  type?: "number" | "text" | "password";
  label?: string;
  options?: {
    value: string | number | boolean | null;
    label: string;
  }[];
  onChange: (value: any) => void;
  maxLength?: number;
  appearance?: "SELECT" | "TEXTAREA" | "INPUT";
}

const RegistrationLabel = ({
  alignStart = false,
  disabled,
  optional = false,
  text,
}: IRegistrationLabel): JSX.Element => (
  <p
    className={`label-title is-edit ${
      !alignStart ? "flex align-items-end" : ""
    } ${disabled ? "disabled" : ""}`}
  >
    {text}
    {!optional && "*"}
  </p>
);

const Receipt = ({
  item,
  type,
  index,
  modal,
  endDate,
  startDate,
  setIndex,
  setRemoved,
  setPortfolioType,
  setOpenModalRemove,
}: ReceiptProps): JSX.Element => (
  <div className="subscriptions-receipt">
    <div className="subscriptions-receipt-content">
      <p className="label-title">{type}</p>
      <p className="label-description">{item?.title}</p>
      {item?.name && <p className="label-description">{item?.name}</p>}
      {item?.url && <p className="label-description">{item?.url}</p>}
      {item?.type !== mediaType.LINK && startDate && (
        <p className="label-description">
          {`${moment(startDate).format(
            item?.fullDate ? "DD/MM/YYYY " : "MMMM/YYYY",
          )} - ${
            !endDate
              ? "Atualmente"
              : moment(endDate).format(
                  item?.fullDate ? "DD/MM/YYYY" : "MMMM/YYYY",
                )
          }`}
        </p>
      )}
      <p className="label-description">{item?.description}</p>
      {item?.medias?.length > 0 && (
        <Medias
          medias={item?.medias.filter(
            (innerItem: { type: string }) => innerItem.type !== mediaType.LINK,
          )}
          links={item?.medias.filter(
            (innerItem: { type: string }) => innerItem.type === mediaType.LINK,
          )}
        />
      )}
    </div>

    <div className="subscriptions-receipt-buttons">
      <IconButton
        icon={<Edit widthIcon={24} heightIcon={24} />}
        onClick={() => {
          setIndex(index);
          setPortfolioType(type === "Portfólio" ? "Portfólio" : "");
          modal(true);
        }}
        className="button-right-card-items"
      />

      <IconButton
        icon={<Delete widthIcon={24} heightIcon={24} />}
        onClick={() => {
          setRemoved({
            id: index,
            type,
            title: `Excluir ${type}`,
            text: `Tem certeza de que deseja excluir este ${type}?`,
          });
          setOpenModalRemove(true);
        }}
        className="button-right-card-items"
      />
    </div>
  </div>
);

const Form = ({
  id,
  type,
  value,
  label,
  alignStart = false,
  disabled,
  text,
  options,
  onChange,
  maxLength,
  appearance = "INPUT",
}: FormProps): JSX.Element => (
  <>
    <RegistrationLabel
      text={text}
      optional
      disabled={disabled}
      alignStart={alignStart}
    />

    {appearance === "SELECT" && (
      <Select
        id={id}
        label={label ?? ""}
        onChange={onChange}
        value={value}
        options={options || []}
        disabled={disabled}
      />
    )}

    {appearance === "INPUT" && (
      <Input
        id={id}
        label={label ?? ""}
        type={type || "text"}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
      />
    )}
  </>
);

const Info = ({ user, setUser, handleChangeUser }: InfoProps): JSX.Element => {
  const handleChangeDateUser = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) =>
    setUser(
      (prev) =>
        ({
          ...prev,
          profile: {
            ...prev?.profile,
            birthdate: maskDate(event.target.value),
          },
        } as User),
    );

  return user ? (
    <div className="grid-account-gap grid-account-gap-resposive">
      {!info(user?.profile)[1].notRender && (
        <div className="content-grid-row">
          <Form
            id={info(user?.profile)[1].key ?? ""}
            label={info(user?.profile)[1].title ?? ""}
            text={info(user?.profile)[1].title ?? ""}
            onChange={(e) =>
              handleChangeUser(
                e.target.value,
                info(user?.profile)[1].key as string,
              )
            }
            value={info(user?.profile)[1].value ?? ""}
          />
        </div>
      )}

      {!info(user?.profile)[2].notRender && (
        <div className="content-grid-row">
          <Form
            id={info(user?.profile)[2].key ?? ""}
            label={info(user?.profile)[2].title ?? ""}
            text={info(user?.profile)[2].title ?? ""}
            onChange={(e) =>
              handleChangeUser(
                e.target.value,
                info(user?.profile)[2].key as string,
              )
            }
            value={info(user?.profile)[2].value ?? ""}
          />
        </div>
      )}

      {!info(user?.profile)[3].notRender && (
        <div className="content-grid-row">
          <Form
            id={info(user?.profile)[3].key ?? ""}
            label={info(user?.profile)[3].title ?? ""}
            text={info(user?.profile)[3].title ?? ""}
            onChange={(e) =>
              handleChangeUser(
                e.target.value,
                info(user?.profile)[3].key as string,
              )
            }
            value={maskBRlegalIdentity(
              (info(user?.profile)[3].valueEdit as string) ?? "",
            )}
            maxLength={11}
          />
        </div>
      )}

      {!info(user?.profile)[4].notRender && (
        <div className="content-grid-row">
          <Form
            id={info(user?.profile)[4].key ?? ""}
            label={info(user?.profile)[4].title ?? ""}
            text={info(user?.profile)[4].title ?? ""}
            onChange={(e) =>
              handleChangeUser(
                e.target.value,
                info(user?.profile)[4].key as string,
              )
            }
            value={info(user?.profile)[4].value ?? ""}
            options={Object.keys(IdentityType)
              .map((key) => ({
                value: key,
                label: IdentityType[key as keyof typeof IdentityType],
              }))
              .filter((item) => item.value !== userIdentityType.BR_CPF)}
            appearance="SELECT"
          />
        </div>
      )}

      {!info(user?.profile)[5].notRender && (
        <div className="content-grid-row">
          <Form
            id={info(user?.profile)[5].key ?? ""}
            label={info(user?.profile)[5].title ?? ""}
            text={info(user?.profile)[5].title ?? ""}
            onChange={(e) =>
              handleChangeUser(
                e.target.value,
                info(user?.profile)[5].key as string,
              )
            }
            value={info(user?.profile)[5].value as string}
          />
        </div>
      )}

      {!info(user?.profile)[7].notRender && (
        <div className="content-grid-row">
          <Form
            id={info(user?.profile)[7].key ?? ""}
            label={info(user?.profile)[7].title ?? ""}
            text={info(user?.profile)[7].title ?? ""}
            onChange={handleChangeDateUser}
            value={info(user?.profile)[7].value ?? ""}
            maxLength={10}
          />
        </div>
      )}

      {!info(user?.profile)[8].notRender && (
        <div className="content-grid-row">
          <Form
            id={info(user?.profile)[8].key ?? ""}
            label={info(user?.profile)[8].title ?? ""}
            text={info(user?.profile)[8].title ?? ""}
            onChange={(e) =>
              handleChangeUser(
                e.target.value,
                info(user?.profile)[8].key as string,
              )
            }
            value={info(user?.profile)[8].value ?? ""}
          />
        </div>
      )}

      {!info(user?.profile)[9].notRender && (
        <div className="content-grid-row">
          <Form
            id={info(user?.profile)[9].key ?? ""}
            label={info(user?.profile)[9].title ?? ""}
            text={info(user?.profile)[9].title ?? ""}
            onChange={(e) =>
              handleChangeUser(
                e.target.value,
                info(user?.profile)[9].key as string,
              )
            }
            value={maskPhone(info(user?.profile)[9].valueEdit ?? "")}
            maxLength={14}
          />
        </div>
      )}
    </div>
  ) : (
    <div />
  );
};

const Reprograma = ({
  course,
  subscription,
  handleChangeRadioSubscription,
  handleChangeSelectSubscription,
}: ReprogramaProps): JSX.Element =>
  course && subscription ? (
    <div className="grid-account-gap grid-account-gap-resposive">
      {!reprograma(subscription?.form)[0].notRender && (
        <div className="content-grid-row">
          <Form
            id={reprograma(subscription, course)[0].key ?? ""}
            label={reprograma(subscription, course)[0].title ?? ""}
            text={reprograma(subscription, course)[0].title ?? ""}
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                reprograma(subscription, course)[0].key as string,
              )
            }
            value={reprograma(subscription, course)[0].valueEdit ?? ""}
            appearance="SELECT"
            options={Object.keys(Target)?.map((key) => ({
              value: key,
              label: Target[key as keyof typeof Target],
            }))}
          />
        </div>
      )}

      {!reprograma(subscription?.form)[1].notRender && (
        <div className="content-grid-row">
          <Form
            id={reprograma(subscription?.form)[1].key ?? ""}
            label={reprograma(subscription?.form)[1].title ?? ""}
            text={reprograma(subscription?.form)[1].title ?? ""}
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                reprograma(subscription?.form)[1].key as string,
              )
            }
            value={reprograma(subscription?.form)[1].valueEdit ?? ""}
            options={Object.keys(HowToKnowReprograma)?.map((key) => ({
              value: key,
              label:
                HowToKnowReprograma[key as keyof typeof HowToKnowReprograma],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!reprograma(subscription?.form)[2].notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={reprograma(subscription?.form)[2].title}
          />
          <RadioGroup
            aria-label={reprograma(subscription?.form)[2].value}
            name="student"
            value={
              reprograma(subscription?.form)[2].valueEdit !== undefined
                ? reprograma(subscription?.form)[2].valueEdit === "Sim"
                  ? "isReprogramaStudent"
                  : "isntReprogramaStudent"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                reprograma(subscription?.form)[2].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="isReprogramaStudent"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="isntReprogramaStudent"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!reprograma(subscription?.form)[3].notRender && (
        <div className="content-grid-row">
          <Form
            id={reprograma(subscription?.form)[3].key ?? ""}
            label={reprograma(subscription?.form)[3].title ?? ""}
            text={reprograma(subscription?.form)[3].title ?? ""}
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                reprograma(subscription?.form)[3].key as string,
              )
            }
            value={reprograma(subscription?.form)[3].valueEdit ?? ""}
            options={Object.keys(CourseOfReprograma)?.map((key) => ({
              value: key,
              label: CourseOfReprograma[key as keyof typeof CourseOfReprograma],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!reprograma(subscription, course)[4].notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={reprograma(subscription?.form)[4].title}
          />
          <RadioGroup
            aria-label={reprograma(subscription?.form)[4].value}
            name="similarCourse"
            value={
              reprograma(subscription?.form)[4].valueEdit !== undefined
                ? reprograma(subscription?.form)[4].valueEdit === "Sim"
                  ? "similarCourse"
                  : "notSimilarCourse"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                reprograma(subscription?.form)[4].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="similarCourse"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="notSimilarCourse"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!reprograma(subscription, course)[5].notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={reprograma(subscription?.form)[5].title}
          />
          <RadioGroup
            aria-label={reprograma(subscription?.form)[5].value}
            name="teacher"
            value={
              reprograma(subscription?.form)[5].valueEdit !== undefined
                ? reprograma(subscription?.form)[5].valueEdit === "Sim"
                  ? "isTeacher"
                  : "isntTeacher"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                reprograma(subscription?.form)[5].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="isTeacher"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="isntTeacher"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!reprograma(subscription, course)[6].notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={reprograma(subscription?.form)[6].title}
          />
          <RadioGroup
            aria-label={reprograma(subscription?.form)[6].value}
            name="juniorAssistant"
            value={
              reprograma(subscription?.form)[6].valueEdit !== undefined
                ? reprograma(subscription?.form)[6].valueEdit === "Sim"
                  ? "isJuniorAssistant"
                  : "isntJuniorAssistant"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                reprograma(subscription?.form)[6].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="isJuniorAssistant"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="isntJuniorAssistant"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!reprograma(subscription?.form)[7].notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={reprograma(subscription?.form)[7].title}
          />
          <RadioGroup
            aria-label={reprograma(subscription?.form)[7].value}
            name="student"
            value={
              reprograma(subscription?.form)[7].valueEdit !== undefined
                ? reprograma(subscription?.form)[7].valueEdit === "Sim"
                  ? "canParticipateOnSelectWorkshop"
                  : "cantParticipateOnSelectWorkshop"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                reprograma(subscription?.form)[7].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="canParticipateOnSelectWorkshop"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="cantParticipateOnSelectWorkshop"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!reprograma(subscription?.form)[8].notRender && (
        <div className="content-grid-row">
          <Form
            id={reprograma(subscription?.form)[8].key ?? ""}
            label={reprograma(subscription?.form)[8].title ?? ""}
            text={reprograma(subscription?.form)[8].title ?? ""}
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                reprograma(subscription?.form)[8].key as string,
              )
            }
            value={reprograma(subscription?.form)[8].valueEdit ?? ""}
            options={Object.keys(Justification)?.map((key) => ({
              value: key,
              label: Justification[key as keyof typeof Justification],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!reprograma(subscription?.form)[9].notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={reprograma(subscription?.form)[9].title}
          />
          <RadioGroup
            aria-label={reprograma(subscription?.form)[9].value}
            name="student"
            value={
              reprograma(subscription?.form)[9].valueEdit !== undefined
                ? reprograma(subscription?.form)[9].valueEdit === "Sim"
                  ? "isVolunteer"
                  : "isntVolunteer"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                reprograma(subscription?.form)[9].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="isVolunteer"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="isntVolunteer"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!reprograma(subscription?.form)[10].notRender && (
        <div className="content-grid-row">
          <Form
            id={reprograma(subscription?.form)[10].key ?? ""}
            label={reprograma(subscription?.form)[10].title ?? ""}
            text={reprograma(subscription?.form)[10].title ?? ""}
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                reprograma(subscription?.form)[10].key as string,
              )
            }
            value={reprograma(subscription?.form)[10].valueEdit ?? ""}
            options={Object.keys(VolunteerDescription)?.map((key) => ({
              value: key,
              label:
                VolunteerDescription[key as keyof typeof VolunteerDescription],
            }))}
            appearance="SELECT"
          />
        </div>
      )}
    </div>
  ) : (
    <div />
  );

const Education = ({
  course,
  subscription,
  handleChangeRadioSubscription,
  handleChangeSelectSubscription,
}: ReprogramaProps): JSX.Element =>
  course && subscription ? (
    <div className="grid-account-gap grid-account-gap-resposive">
      {!education(subscription, course)[0].notRender && (
        <div className="content-grid-row">
          <Form
            id={education(subscription, course)[0].key ?? ""}
            label={education(subscription, course)[0].title ?? ""}
            text={education(subscription, course)[0].title ?? ""}
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                education(subscription, course)[0].key as string,
              )
            }
            value={education(subscription, course)[0].valueEdit}
            options={Object.keys(ITLevel)?.map((key) => ({
              value: key,
              label: ITLevel[key as keyof typeof ITLevel],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!education(subscription, course)[1].notRender && (
        <div className="content-grid-row">
          <Form
            id={education(subscription, course)[1].key ?? ""}
            label={education(subscription, course)[1].title ?? ""}
            text={education(subscription, course)[1].title ?? ""}
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                education(subscription, course)[1].key as string,
              )
            }
            value={education(subscription, course)[1].valueEdit ?? ""}
            options={Object.keys(ITLevel)?.map((key) => ({
              value: key,
              label: ITLevel[key as keyof typeof ITLevel],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!education(subscription, course)[2].notRender && (
        <div className="content-grid-row">
          <Form
            id={education(subscription, course)[2].key ?? ""}
            label={education(subscription, course)[2].title ?? ""}
            text={education(subscription, course)[2].title ?? ""}
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                education(subscription, course)[2].key as string,
              )
            }
            value={education(subscription, course)[2].valueEdit ?? ""}
            options={Object.keys(DifficultiesProgramming).map((key) => ({
              value: key,
              label:
                DifficultiesProgramming[
                  key as keyof typeof DifficultiesProgramming
                ],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!education(subscription, course)[3].notRender && (
        <div className="content-grid-row">
          <Form
            id={education(subscription, course)[3].key ?? ""}
            label={education(subscription, course)[3].title ?? ""}
            text={education(subscription, course)[3].title ?? ""}
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                education(subscription, course)[3].key as string,
              )
            }
            value={education(subscription, course)[3].valueEdit ?? ""}
            options={Object.keys(LevelEducation).map((key) => ({
              value: key,
              label: LevelEducation[key as keyof typeof LevelEducation],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!education(subscription, course)[4].notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={education(subscription, course)[4].title ?? ""}
          />
          <RadioGroup
            aria-label="graduatedInIT"
            name="student"
            value={
              education(subscription, course)[4].value !== undefined
                ? education(subscription, course)[4].value === "Sim"
                  ? "graduatedInIT"
                  : "isntGraduatedInIT"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                education(subscription, course)[4].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="graduatedInIT"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="isntGraduatedInIT"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!education(subscription, course)[5].notRender && (
        <div className="content-grid-row">
          <Form
            id={education(subscription, course)[5].key ?? ""}
            label={education(subscription, course)[5].title ?? ""}
            text={education(subscription, course)[5].title ?? ""}
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                education(subscription, course)[5].key as string,
              )
            }
            value={education(subscription, course)[5].valueEdit ?? ""}
            options={UniversityTitle.map((item: string) => ({
              value: item,
              label: item,
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!education(subscription, course)[6].notRender && (
        <div className="content-grid-row">
          <Form
            id={education(subscription, course)[6].key ?? ""}
            label={education(subscription, course)[6].title ?? ""}
            text={education(subscription, course)[6].title ?? ""}
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                education(subscription, course)[6].key as string,
              )
            }
            value={education(subscription, course)[6].valueEdit ?? ""}
            options={Object.keys(EnglishLevel).map((key) => ({
              value: key,
              label: EnglishLevel[key as keyof typeof EnglishLevel],
            }))}
            appearance="SELECT"
          />
        </div>
      )}
    </div>
  ) : (
    <div />
  );

const SocioEconomicProfile = ({
  user,
  course,
  subscription,
  handleChangeUser,
  handleChangeRevenue,
  handleChangeRadioUser,
  handleChangeSubscription,
  needAccessibilityAdaptation,
  handleChangeRadioSubscription,
  setNeedAccessibilityAdaptation,
  handleChangeSelectSubscription,
}: socioEconomicProps): JSX.Element =>
  user && course && subscription ? (
    <div className="grid-account-gap grid-account-gap-resposive">
      {!socioEconomicProfile(user?.profile, subscription?.form)[0]
        .notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[0]
                .title ?? ""
            }
          />
          <RadioGroup
            aria-label={
              socioEconomicProfile(user?.profile, subscription?.form)[0].key
            }
            name="internet"
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[0]
                .value !== undefined
                ? socioEconomicProfile(user?.profile, subscription?.form)[0]
                    .value === "Sim"
                  ? "hasInternet"
                  : "hasntInternet"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[0].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="hasInternet"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="hasntInternet"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[1]
        .notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[1].title
            }
          />
          <RadioGroup
            aria-label={
              socioEconomicProfile(user?.profile, subscription?.form)[1].key
            }
            name="computer"
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[1]
                .value !== undefined
                ? socioEconomicProfile(user?.profile, subscription?.form)[1]
                    .value === "Sim"
                  ? "hasComputer"
                  : "hasntComputer"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[1].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="hasComputer"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="hasntComputer"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[2]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[2].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[2]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[2]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                socioEconomicProfile(user?.profile, subscription?.form)[2]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[2]
                .valueEdit ?? ""
            }
            options={Object.keys(EmployabilitySituation).map((key) => ({
              value: key,
              label:
                EmployabilitySituation[
                  key as keyof typeof EmployabilitySituation
                ],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[3]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[3].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[3]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[3]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                socioEconomicProfile(user?.profile, subscription?.form)[3]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[3]
                .valueEdit ?? ""
            }
            options={Object.keys(UnemployedTime).map((key) => ({
              value: key,
              label: UnemployedTime[key as keyof typeof UnemployedTime],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[4]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[4].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[4]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[4]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeSubscription(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[4]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[4]
                .valueEdit ?? ""
            }
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[5]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[5].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[5]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[5]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeSubscription(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[5]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[5]
                .valueEdit ?? ""
            }
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[6]
        .notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[6].title
            }
          />
          <RadioGroup
            aria-label={
              socioEconomicProfile(user?.profile, subscription?.form)[6].key
            }
            name="computer"
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[6]
                .value !== undefined
                ? socioEconomicProfile(user?.profile, subscription?.form)[6]
                    .value === "Sim"
                  ? "isEmployedInIT"
                  : "isntEmployedInIT"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[6].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="isEmployedInIT"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="isntEmployedInIT"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription, course)[7]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[7].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[7]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[7]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeSelectSubscription(
                e,
                socioEconomicProfile(user?.profile, subscription?.form)[7]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[7]
                .valueEdit ?? ""
            }
            options={Object.keys(CurrentJoblevel)?.map((key) => ({
              value: key,
              label: CurrentJoblevel[key as keyof typeof CurrentJoblevel],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[8]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[8].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[8]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[8]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeRevenue(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[8]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[8]
                .valueEdit ?? ""
            }
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[9]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[9].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[9]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[9]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeRevenue(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[9]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[9]
                .valueEdit ?? ""
            }
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[11]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[11].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[11]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[11]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeSubscription(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[11]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[11]
                .valueEdit ?? ""
            }
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[12]
        .notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[12].title
            }
          />
          <RadioGroup
            aria-label={
              socioEconomicProfile(user?.profile, subscription?.form)[12].key
            }
            name="hasChildren"
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[12]
                .value !== undefined
                ? socioEconomicProfile(user?.profile, subscription?.form)[12]
                    .value === "Sim"
                  ? "hasChildren"
                  : "hasntChildren"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[12].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="hasChildren"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="hasntChildren"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[13]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[13].key ??
              ""
            }
            type="number"
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[13]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[13]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeSubscription(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[13]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[13]
                .valueEdit ?? ""
            }
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[14]
        .notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[14].title
            }
          />
          <RadioGroup
            aria-label={
              socioEconomicProfile(user?.profile, subscription?.form)[14].key
            }
            name="childrenOfAge"
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[14]
                .value !== undefined
                ? socioEconomicProfile(user?.profile, subscription?.form)[14]
                    .value === "Sim"
                  ? "childrenOfAge"
                  : "notChildrenOfAge"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[14].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="childrenOfAge"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="notChildrenOfAge"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[15]
        .notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[15].title
            }
          />
          <RadioGroup
            aria-label={
              socioEconomicProfile(user?.profile, subscription?.form)[15].key
            }
            name="disabledPerson"
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[15]
                .value !== undefined
                ? socioEconomicProfile(user?.profile, subscription?.form)[15]
                    .value === "Sim"
                  ? "disabledPerson"
                  : "isntDisabledPerson"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioUser(
                e,
                socioEconomicProfile(user?.profile, subscription?.form)[15].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="disabledPerson"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="isntDisabledPerson"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[16]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[16].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[16]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[16]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeUser(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[16]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[16]
                .valueEdit ?? ""
            }
            options={Object.keys(DisabledPersonDescription)?.map((key) => ({
              value: key,
              label:
                DisabledPersonDescription[
                  key as keyof typeof DisabledPersonDescription
                ],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[17]
        .notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[17].title
            }
          />
          <RadioGroup
            aria-label={
              socioEconomicProfile(user?.profile, subscription?.form)[17].key
            }
            name="needAccessibilityAdaptation"
            value={
              needAccessibilityAdaptation !== undefined
                ? needAccessibilityAdaptation
                  ? "needAccessibilityAdaptation"
                  : "notNeedAccessibilityAdaptation"
                : ""
            }
            onChange={(e) => {
              setNeedAccessibilityAdaptation(
                e.target.value === "needAccessibilityAdaptation",
              );
              if (e.target.value !== "needAccessibilityAdaptation")
                handleChangeSubscription(
                  "",
                  socioEconomicProfile(user?.profile, subscription?.form)[17]
                    .key as string,
                );
            }}
          >
            <div>
              <FormControlLabel
                value="needAccessibilityAdaptation"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="notNeedAccessibilityAdaptation"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[18]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[18].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[18]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[18]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeSubscription(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[18]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[18]
                .valueEdit ?? ""
            }
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[19]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[19].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[19]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[19]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeUser(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[19]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[19]
                .valueEdit ?? ""
            }
            options={Object.keys(ProfileGender).map((key) => ({
              value: key,
              label: ProfileGender[key as keyof typeof ProfileGender],
            }))}
            appearance="SELECT"
          />
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[20]
        .notRender && (
        <div className="content-grid-row">
          <RegistrationLabel
            alignStart
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[20].title
            }
          />
          <RadioGroup
            aria-label={
              socioEconomicProfile(user?.profile, subscription?.form)[20].key
            }
            name="isLGBTQIA"
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[20]
                .value !== undefined
                ? socioEconomicProfile(user?.profile, subscription?.form)[20]
                    .value === "Sim"
                  ? "isLGBTQIA"
                  : "isntLGBTQIA"
                : ""
            }
            onChange={(e) =>
              handleChangeRadioSubscription(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[20].key,
              )
            }
          >
            <div>
              <FormControlLabel
                value="isLGBTQIA"
                control={<Radio />}
                label="Sim"
                className="margin-right-32"
              />
              <FormControlLabel
                value="isntLGBTQIA"
                control={<Radio />}
                label="Não"
              />
            </div>
          </RadioGroup>
        </div>
      )}

      {!socioEconomicProfile(user?.profile, subscription?.form)[21]
        .notRender && (
        <div className="content-grid-row">
          <Form
            id={
              socioEconomicProfile(user?.profile, subscription?.form)[21].key ??
              ""
            }
            label={
              socioEconomicProfile(user?.profile, subscription?.form)[21]
                .title ?? ""
            }
            text={
              socioEconomicProfile(user?.profile, subscription?.form)[21]
                .title ?? ""
            }
            onChange={(e) =>
              handleChangeUser(
                e.target.value,
                socioEconomicProfile(user?.profile, subscription?.form)[21]
                  .key as string,
              )
            }
            value={
              socioEconomicProfile(user?.profile, subscription?.form)[21]
                .valueEdit ?? ""
            }
            options={Object.keys(ProfileRace).map((key) => ({
              value: key,
              label: ProfileRace[key as keyof typeof ProfileRace],
            }))}
            appearance="SELECT"
          />
        </div>
      )}
    </div>
  ) : (
    <div />
  );

const SocioEconomicAssessment = ({
  user,
  course,
  subscription,
  handleChangeSelectSubscription,
}: socioEconomicAssessmentProps): JSX.Element =>
  user && course && subscription ? (
    <div className="grid-account-gap grid-account-gap-resposive">
      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[0].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[0].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[0].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[0].key as string,
            )
          }
          value={socioEconomicAssessment(subscription?.form)[0].valueEdit ?? ""}
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[1].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[1].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[1].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[1].key as string,
            )
          }
          value={socioEconomicAssessment(subscription?.form)[1].valueEdit ?? ""}
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[2].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[2].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[2].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[2].key as string,
            )
          }
          value={socioEconomicAssessment(subscription?.form)[2].valueEdit ?? ""}
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[3].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[3].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[3].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[3].key as string,
            )
          }
          value={socioEconomicAssessment(subscription?.form)[3].valueEdit ?? ""}
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[4].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[4].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[4].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[4].key as string,
            )
          }
          value={socioEconomicAssessment(subscription?.form)[4].valueEdit ?? ""}
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[5].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[5].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[5].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[5].key as string,
            )
          }
          value={socioEconomicAssessment(subscription?.form)[5].valueEdit ?? ""}
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[6].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[6].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[6].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[6].key as string,
            )
          }
          value={socioEconomicAssessment(subscription?.form)[6].valueEdit ?? ""}
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[7].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[7].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[7].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[7].key as string,
            )
          }
          value={socioEconomicAssessment(subscription?.form)[7].valueEdit ?? ""}
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[8].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[8].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[8].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[8].key as string,
            )
          }
          value={socioEconomicAssessment(subscription?.form)[8].valueEdit ?? ""}
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[9].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[9].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[9].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[9].key as string,
            )
          }
          value={socioEconomicAssessment(subscription?.form)[9].valueEdit ?? ""}
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[11].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[10].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[10].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[10].key as string,
            )
          }
          value={
            socioEconomicAssessment(subscription?.form)[10].valueEdit ?? ""
          }
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[11].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[11].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[11].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[11].key as string,
            )
          }
          value={
            socioEconomicAssessment(subscription?.form)[11].valueEdit ?? ""
          }
          options={Object.keys(NumItems)?.map((key) => ({
            value: key,
            label: NumItems[key as keyof typeof NumItems],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[12].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[12].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[12].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[12].key as string,
            )
          }
          value={
            socioEconomicAssessment(subscription?.form)[12].valueEdit ?? ""
          }
          options={Object.keys(OriginOfWater)?.map((key) => ({
            value: key,
            label: OriginOfWater[key as keyof typeof OriginOfWater],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[13].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[13].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[13].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[13].key as string,
            )
          }
          value={
            socioEconomicAssessment(subscription?.form)[13].valueEdit ?? ""
          }
          options={Object.keys(TypeOfStreet)?.map((key) => ({
            value: key,
            label: TypeOfStreet[key as keyof typeof TypeOfStreet],
          }))}
          appearance="SELECT"
        />
      </div>

      <div className="content-grid-row">
        <Form
          id={socioEconomicAssessment(subscription?.form)[14].key ?? ""}
          label={socioEconomicAssessment(subscription?.form)[14].title ?? ""}
          text={socioEconomicAssessment(subscription?.form)[14].title ?? ""}
          onChange={(e) =>
            handleChangeSelectSubscription(
              e,
              socioEconomicAssessment(subscription?.form)[14].key as string,
            )
          }
          value={
            socioEconomicAssessment(subscription?.form)[14].valueEdit ?? ""
          }
          options={Object.keys(LevelEducation)?.map((key) => ({
            value: key,
            label: LevelEducation[key as keyof typeof LevelEducation],
          }))}
          appearance="SELECT"
        />
      </div>
    </div>
  ) : (
    <div />
  );

const FormEdit = ({
  user,
  setUser,
  subscription,
  setSubscription,
  course,
  step,
  setIsEdit,
  reload,
}: Props): JSX.Element => {
  const [address, setAddress] = useState<UserProfileAddress | null>(null);
  const [portfolioType, setPortfolioType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModalRemove, setOpenModalRemove] = useState(false);
  const [removed, setRemoved] = useState({
    id: -1,
    type: "",
    text: "",
    title: "",
  });
  const [indexItem, setIndexItem] = useState(-1);
  const [calcPerCapitalRevenue, setCalcPerCapitalRevenue] = useState(false);
  const [needAccessibilityAdaptation, setNeedAccessibilityAdaptation] =
    useState<boolean | undefined>(
      !!subscription?.form?.accessibilityAdaptation || undefined,
    );
  const [classVacancy, setClassVacancy] = useState<
    {
      _id: string;
      title: string;
    }[]
  >();
  const [titleOptions, setTitleOptions] = useState<string[]>();

  const loadTitles = useCallback(async () => {
    const titleOptionsResponse = await getAutocomplete(
      autoCompleteType.EDUCATION_TITLE,
    );

    const titleOptionsMapped =
      titleOptionsResponse?.map((option: { name: string }) => option?.name) ??
      [];

    setTitleOptions(titleOptionsMapped);
  }, []);

  useEffect(() => {
    if (!titleOptions) loadTitles();
  }, [loadTitles, titleOptions]);

  const getClassVacancy = useCallback(async () => {
    try {
      const response = await getAllActiveClassVacancy();
      if (response?.data) setClassVacancy(response?.data);
    } catch {
      setClassVacancy([]);
    }
  }, []);

  useEffect(() => {
    if (!classVacancy) getClassVacancy();
  }, [classVacancy, getClassVacancy]);

  const handleChangeUser = useCallback(
    (value: string | number | boolean, prop: string) =>
      setUser(
        (prev) =>
          ({
            ...prev,
            profile: { ...prev?.profile, [prop]: value },
          } as User),
      ),
    [setUser],
  );

  const handleChangeSubscription = useCallback(
    (value: string | number | boolean, prop: string) =>
      setSubscription({
        ...subscription,
        form: { ...subscription?.form, [prop]: value },
      }),
    [setSubscription, subscription],
  );

  const handleChangeRadioUser = useCallback(
    (event: ChangeEvent<HTMLInputElement>, props) =>
      setUser({
        ...user,
        profile: {
          ...user?.profile,
          [props]: event.target.value === props,
        },
      }),
    [setUser, user],
  );

  const handleChangeRadioSubscription = useCallback(
    (value: string, props) =>
      setSubscription({
        ...subscription,
        form: {
          ...subscription?.form,
          [props]: value === props,
        },
      }),
    [setSubscription, subscription],
  );

  const handleChangeSelectSubscription = useCallback(
    (event: { target: { value: string } }, prop: string) => {
      if (prop === "reprogramaStudentOtherClasses")
        setSubscription({
          ...subscription,
          form: {
            ...subscription?.form,
            [prop]: [event.target.value],
          },
        });
      else
        setSubscription({
          ...subscription,
          form: {
            ...subscription?.form,
            [prop]: event.target.value as string,
          },
        });
    },
    [setSubscription, subscription],
  );

  const updateSocialMedia = useCallback(
    (value) =>
      setUser({
        ...user,
        profile: { ...user?.profile, socialMedias: value },
      }),
    [setUser, user],
  );

  const handleChangeRevenue = useCallback(
    (value: string, props: string) => {
      setSubscription({
        ...subscription,
        form: {
          ...subscription?.form,
          [props]: removeMaskReal(maskReal(value)),
        },
      });
    },
    [setSubscription, subscription],
  );

  const onSave = useCallback(async () => {
    setLoading(true);
    const subs = { ...subscription };
    if (user) {
      if (
        calcPerCapitalRevenue &&
        subscription?.form?.familyTotalRevenue &&
        subscription?.form?.familySize
      ) {
        const perCapitalRevenue =
          (subs?.form?.familyTotalRevenue ?? 0) /
            (Number(subs?.form?.familySize ?? 0) ?? 0) ?? 0;
        subs.form.perCapitalRevenue = perCapitalRevenue;
      }

      const newUser = JSON.parse(JSON.stringify({ ...user }));
      delete newUser?._id;
      delete newUser?.profile?.age;
      await patchUser(user?._id ?? "", newUser);

      await updateClassVacancySubscriptionById(subs?._id ?? "", {
        form: subs?.form,
      });

      const newSubscription = await getClassVacancySubscriptionById(
        subs?._id ?? "",
      );

      if (newSubscription) {
        const response = await getClassVacancyById(subs?.classVacancyId);

        if (response?.data) {
          const currentClassVacancy = response?.data;

          const subscriptionStage = currentClassVacancy?.stages?.find(
            (item: { type: keyof typeof classVacancyType }) =>
              item?.type === classVacancyType.SUBSCRIPTION,
          );

          const currentSubscriptionStage = subs?.stages?.find(
            (item: { stageId: string }) =>
              item?.stageId === subscriptionStage?.stage_id,
          );
          const index = subs?.stages?.findIndex(
            (item: { stageId: string }) =>
              item?.stageId === subscriptionStage?.stage_id,
          );

          const grade = await calcSubscriptionRanking(subs?._id ?? "");
          if (currentSubscriptionStage && index !== -1 && grade) {
            currentSubscriptionStage.grade = [
              {
                type: "FINAL",
                value: parseFloat(grade?.score?.toFixed(2)) ?? 0,
              },
            ];
            subs.stages[index] = currentSubscriptionStage;
            await updateClassVacancySubscriptionById(subs?._id ?? "", {
              stages: subs?.stages,
            });
          }
        }
      }

      setLoading(false);
      reload();
    }
  }, [calcPerCapitalRevenue, reload, subscription, user]);

  useEffect(() => {
    if (step === 5) setCalcPerCapitalRevenue(true);
  }, [step]);

  const onRemoveItem = useCallback(
    (index: number, type: string) => {
      setUser({
        ...user,
        curriculum: {
          ...(user?.curriculum || {}),
          [type]: user?.curriculum?.[type]?.filter(
            (_: any, idx: number) => idx !== index,
          ),
        },
      });
    },
    [setUser, user],
  );

  useEffect(() => {
    if (user?.profile?.address && !address) setAddress(user?.profile?.address);
  }, [user?.profile?.address, address]);

  const updateAddress = useCallback(
    (value) => {
      setAddress(value);
      setUser({
        ...user,
        profile: { ...user?.profile, address: value },
      });
    },
    [setUser, user],
  );

  return (
    <>
      <div className="mb-32 admin-subscriptions-edit">
        {step === 6 && user && (
          <div className="subscriptions-certificate">
            <div className="subscriptions-certificate-buttons">
              <IconButton
                icon={<Add />}
                text="Adicionar certificado"
                onClick={() => {
                  setIndexItem(-1);
                  setPortfolioType("");
                  setOpenModal(true);
                }}
                className="icon-button-primary"
              />

              <ButtonMenu
                icon={<Add />}
                text="Adicionar portfólio"
                items={[
                  {
                    text: "Link",
                    onClick: () => {
                      setPortfolioType("LINK");
                      setIndexItem(-1);
                      setOpenModal(true);
                    },
                  },
                  {
                    text: "Projeto",
                    onClick: () => {
                      setPortfolioType("PROJECT");
                      setIndexItem(-1);
                      setOpenModal(true);
                    },
                  },
                ]}
                className="icon-button-primary"
              />
            </div>
            {user?.curriculum?.portfolios?.map((item, index) => (
              <Receipt
                key={Math.random()}
                item={item}
                index={index}
                type="Portfólio"
                setPortfolioType={setPortfolioType}
                modal={setOpenModal}
                setIndex={setIndexItem}
                startDate={item?.startDate}
                endDate={item?.endDate}
                setRemoved={setRemoved}
                setOpenModalRemove={setOpenModalRemove}
              />
            ))}

            {user?.curriculum?.certificates?.map((item, index) => (
              <Receipt
                key={Math.random()}
                index={index}
                item={item}
                type="Certificado"
                modal={setOpenModal}
                setIndex={setIndexItem}
                startDate={item?.date}
                endDate={item?.expirationDate}
                setPortfolioType={setPortfolioType}
                setRemoved={setRemoved}
                setOpenModalRemove={setOpenModalRemove}
              />
            ))}

            <Items
              portfolio={user?.curriculum?.portfolios}
              certificate={user?.curriculum?.certificates}
              setUser={setUser}
              index={indexItem}
              type={portfolioType ? "portfolios" : "certificates"}
              openModal={openModal}
              typePortfolio={portfolioType}
              onClose={() => {
                setOpenModal(false);
              }}
            />

            <ConfirmRemove
              titleHeader={removed.title}
              text={removed.text}
              onClose={() => setOpenModalRemove(false)}
              onRemove={() =>
                onRemoveItem(
                  removed.id,
                  removed.type === "Portfólio" ? "portfolios" : "certificates",
                )
              }
              openModal={openModalRemove}
            />
          </div>
        )}
        {step === 2 ? (
          <FormAddress address={address} setAddress={updateAddress} />
        ) : (
          <div className="admin-subscriptions-edit-content">
            {step === 1 && (
              <Info
                user={user}
                setUser={setUser}
                handleChangeUser={handleChangeUser}
              />
            )}

            {step === 3 && (
              <Reprograma
                course={course}
                subscription={subscription}
                handleChangeRadioSubscription={handleChangeRadioSubscription}
                handleChangeSelectSubscription={handleChangeSelectSubscription}
              />
            )}

            {step === 4 && (
              <Education
                course={course}
                subscription={subscription}
                handleChangeRadioSubscription={handleChangeRadioSubscription}
                handleChangeSelectSubscription={handleChangeSelectSubscription}
              />
            )}

            {step === 5 && (
              <SocioEconomicProfile
                user={user}
                course={course}
                subscription={subscription}
                handleChangeUser={handleChangeUser}
                handleChangeRevenue={handleChangeRevenue}
                handleChangeRadioUser={handleChangeRadioUser}
                handleChangeSubscription={handleChangeSubscription}
                needAccessibilityAdaptation={needAccessibilityAdaptation}
                handleChangeRadioSubscription={handleChangeRadioSubscription}
                setNeedAccessibilityAdaptation={setNeedAccessibilityAdaptation}
                handleChangeSelectSubscription={handleChangeSelectSubscription}
              />
            )}

            {step === 7 && (
              <SocioEconomicAssessment
                user={user}
                course={course}
                subscription={subscription}
                handleChangeSelectSubscription={handleChangeSelectSubscription}
              />
            )}

            {step === 1 && (
              <EditSocialMedias
                socialMedias={user?.profile?.socialMedias}
                updateSocialMedia={updateSocialMedia}
              />
            )}
          </div>
        )}
      </div>
      <div className="admin-subscriptions-infos-buttons">
        <Button
          text="Cancelar"
          outline
          appearance="secondary"
          onClick={() => setIsEdit(false)}
        />
        <Button
          text="Salvar"
          className="margin-left-32"
          disabled={loading}
          onClick={onSave}
        />
      </div>
    </>
  );
};

export default memo(FormEdit);
