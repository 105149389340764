import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Redirect } from "react-router-dom";
import moment from "moment-timezone";
import {
  Address as AddressIcon,
  Avatar,
  Certificate as CertificateIcon,
  Curriculum,
  EducationMini as EducationIcon,
  Languages as LanguagesIcon,
  Phone,
  Portfolio,
} from "../../../assets/icons";
import {
  Card,
  Modal,
  Button,
  Finish,
  Layout,
  Progress,
  CardSteps,
  FormAddress,
  EditSocialMedias,
  FormPersonalData,
} from "../../components";
import { setNextButton } from "../../store/actions/nextButtonAction";
import { setStepRegister } from "../../store/actions/stepRegisterActions";
import { updateUser } from "../../store/actions/userActions";
import { RootState } from "../../store/reducers";
import {
  userGenderIdentity,
  userRace,
  userStatus,
} from "../../types/enumerators";
import {
  Certificate,
  ContactPhone,
  CurriculumWork,
  CurriculumGeneral,
  CurriculumSkills,
  Education,
  Languages,
  Portfolios,
  Skip,
} from "./Steps";
import "./styles.scss";
import { isActiveUser } from "../../utils";
import { setLoad } from "../../store/actions/configurationsActions";

interface Data {
  notSameProvince: boolean;
  notSameCountry: boolean;
}

const Register = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);

  const [isValid, setIsValid] = useState(false);
  const [isOptional, setIsOptional] = useState(true);
  const [addSkills, setAddSkills] = useState(false);
  const [addWork, setAddWork] = useState(false);
  const [addEducation, setAddEducation] = useState(false);
  const [addCertificate, setAddCertificate] = useState(false);
  const [addLanguages, setAddLanguages] = useState(false);
  const [addPortfolio, setAddPortfolio] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState<Data>({
    notSameCountry: false,
    notSameProvince: false,
  });

  const stepRegister = useSelector(
    (state: RootState) => state.stepRegisterState,
  );

  const user = useSelector((state: RootState) => state.userState);

  const { nextButton } = useSelector(
    (state: RootState) => state.nextButtonState,
  );
  const dispatch = useDispatch();

  const pages = useMemo(
    () => [
      "",
      "Informações pessoais",
      "Endereço",
      "Contato - Telefone",
      "Contato - Redes Sociais",
      "",
      "Currículo - Dados Gerais",
      "Currículo - Habilidades",
      "Currículo - Experiências",
      "Formação",
      "Idiomas",
      "Portfolio",
      "Certificados",
    ],
    [],
  );

  const itemsMenu = useMemo(
    () => [
      {
        icon: <Avatar />,
        text: "Informações pessoais",
        id: 1,
      },
      {
        icon: <AddressIcon />,
        text: "Endereço",
        id: 2,
      },
      {
        icon: <Phone />,
        text: "Contato",
        id: 3,
      },
      {
        icon: <Curriculum />,
        text: "Currículo",
        id: 6,
      },
      {
        icon: <EducationIcon />,
        text: "Formação",
        id: 9,
      },
      {
        icon: <LanguagesIcon />,
        text: "Idiomas",
        id: 10,
      },
      {
        icon: <Portfolio />,
        text: "Portfólio",
        id: 11,
      },
      {
        icon: <CertificateIcon />,
        text: "Certificados",
        id: 12,
      },
    ],
    [],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    switch (stepRegister.page) {
      case 0:
        setIsOptional(true);
        break;

      case 1:
        if (
          !user?.profile?.name ||
          !user?.profile?.pronouns ||
          !user?.profile?.birthdate ||
          (user?.profile?.birthdate &&
            user?.profile?.birthdate?.length !== 10) ||
          !user?.profile?.legalIdentity ||
          !user?.profile?.genderIdentity ||
          (user?.profile?.genderIdentity === userGenderIdentity.OTHERS &&
            !user?.profile?.genderIdentityDescription) ||
          (!user?.profile?.disabledPerson !== true &&
            !user?.profile?.disabledPersonDescription) ||
          !user?.profile?.race ||
          (user?.profile?.race === userRace.OTHERS &&
            !user?.profile?.raceDescription)
        )
          setIsValid(false);
        else setIsValid(true);
        setIsOptional(false);
        break;

      case 2:
        if (
          !user?.profile?.address?.zipCode ||
          !user?.profile?.address?.province ||
          !user?.profile?.address?.district ||
          !user?.profile?.address?.country ||
          (data?.notSameCountry && !user?.profile?.address.originCountry) ||
          (data?.notSameProvince && !user?.profile?.address.originProvince)
        )
          setIsValid(false);
        else setIsValid(true);
        setIsOptional(false);
        break;

      case 3:
        if (
          !user?.profile?.telephone ||
          user?.profile?.telephone === "+55 (11) _____-____"
        )
          setIsValid(false);
        else setIsValid(true);
        setIsOptional(false);
        break;

      case 4:
        if (user?.profile?.socialMedias?.length) {
          setIsValid(true);
          setIsOptional(false);
        } else setIsOptional(true);
        break;

      case 5:
        setIsOptional(false);
        break;

      case 6:
        if (user?.curriculum?.openForProposals !== undefined) {
          setIsValid(true);
        } else setIsValid(false);
        setIsOptional(false);
        break;

      case 7:
        if (user?.curriculum?.skills?.length > 0) {
          setIsValid(true);
          setIsOptional(false);
        } else setIsOptional(true);
        break;

      case 8:
        if (user?.curriculum?.works?.length > 0) {
          setIsValid(true);
          setIsOptional(false);
        } else setIsOptional(true);
        break;

      case 9:
        if (user?.curriculum?.educations?.length > 0) {
          setIsValid(true);
          setIsOptional(false);
        } else setIsOptional(true);
        break;

      case 10:
        if (user?.curriculum?.languages?.length > 0) {
          setIsValid(true);
          setIsOptional(false);
        } else setIsOptional(true);
        break;

      case 11:
        if (user?.curriculum?.portfolios?.length > 0) {
          setIsValid(true);
          setIsOptional(false);
        } else setIsOptional(true);
        break;

      case 12:
        if (user?.curriculum?.certificates?.length > 0) {
          setIsValid(true);
          setIsOptional(false);
        } else setIsOptional(true);
        break;

      default:
        setIsValid(false);
        setIsOptional(false);
    }
  }, [data, stepRegister.page, user]);

  useEffect(() => {
    const events = async (e: KeyboardEvent) => {
      const { key } = e;

      const action = key.toString();

      if (action === "F5") {
        e.preventDefault();
        setOpenModal(true);
      }
    };

    window.addEventListener("keydown", events);

    return () => {
      window.removeEventListener("keydown", events);
    };
  }, []);

  const onClose = useCallback(() => {
    setAddCertificate(false);
    setAddEducation(false);
    setAddLanguages(false);
    setAddPortfolio(false);
    setAddSkills(false);
    setAddWork(false);
  }, []);

  const onNext = useCallback(() => {
    onClose();

    dispatch(
      setStepRegister({ page: stepRegister.page + 1 }) as unknown as Action,
    );

    const menuItem = itemsMenu.find(
      (item) => item.id === stepRegister.page + 1,
    );

    if (menuItem && menuItem.id > stepRegister.maxPageVisited)
      dispatch(
        setStepRegister({ maxPageVisited: menuItem.id }) as unknown as Action,
      );

    const indexMenu = itemsMenu.findIndex(
      (item) => item.id === stepRegister.page + 1,
    );
    if (
      indexMenu !== -1 &&
      menuItem &&
      menuItem.id >= stepRegister.maxPageVisited
    )
      dispatch(
        setStepRegister({ maxMenuVisited: indexMenu }) as unknown as Action,
      );

    if (nextButton !== null) {
      const funcNextButton = () => nextButton();
      funcNextButton();
      dispatch(setNextButton(null) as unknown as Action);
    }
  }, [
    dispatch,
    itemsMenu,
    nextButton,
    onClose,
    stepRegister.maxPageVisited,
    stepRegister.page,
  ]);

  const onBack = useCallback(() => {
    dispatch(
      setStepRegister({ page: stepRegister.page - 1 }) as unknown as Action,
    );
    onClose();
  }, [dispatch, onClose, stepRegister.page]);

  const onFinish = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const newUser = {
      ...user,
      account: { ...user.account, status: userStatus.ACTIVE },
      createAt: moment().tz("America/Sao_Paulo").toISOString(),
    };

    dispatch(updateUser({ ...newUser }) as unknown as Action);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, user]);

  const updateSocialMedia = useCallback(
    (value) => {
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              profile: {
                ...user?.profile,
                socialMedias: value,
              },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [dispatch, user],
  );

  const renderCards = useMemo(() => {
    switch (stepRegister.page) {
      case 0:
        return onNext();

      case 1:
        return <FormPersonalData registration setIsValid={setIsValid} />;

      case 2:
        return (
          <FormAddress
            data={data}
            setData={setData}
            setIsValid={setIsValid}
            showLabel
          />
        );

      case 3:
        return <ContactPhone setIsValid={setIsValid} />;

      case 4:
        return (
          <EditSocialMedias
            setIsValid={setIsValid}
            setIsOptional={setIsOptional}
            socialMedias={user?.profile?.socialMedias}
            updateSocialMedia={updateSocialMedia}
            showText
          />
        );

      case 6:
        return <CurriculumGeneral setIsValid={setIsValid} />;

      default:
        return <div />;
    }
  }, [
    data,
    onNext,
    stepRegister.page,
    updateSocialMedia,
    user?.profile?.socialMedias,
  ]);

  const renderPages = useMemo(() => {
    switch (stepRegister.page) {
      case 5:
        return <Skip onNext={onNext} />;

      case 7:
        return (
          <CurriculumSkills
            title={pages[stepRegister.page]}
            addSkills={addSkills}
            setAddSkills={setAddSkills}
            setIsOptional={setIsOptional}
            setIsValid={setIsValid}
          />
        );

      case 8:
        return (
          <CurriculumWork
            title={pages[stepRegister.page]}
            addWork={addWork}
            hasTooltip={pages[stepRegister.page] === "Currículo - Experiências"}
            setAddWork={setAddWork}
            setIsOptional={setIsOptional}
            setIsValid={setIsValid}
          />
        );

      case 9:
        return (
          <Education
            title={pages[stepRegister.page]}
            addEducation={addEducation}
            setAddEducation={setAddEducation}
            setIsOptional={setIsOptional}
            setIsValid={setIsValid}
          />
        );

      case 10:
        return (
          <Languages
            title={pages[stepRegister.page]}
            addLanguages={addLanguages}
            setAddLanguages={setAddLanguages}
            setIsOptional={setIsOptional}
            setIsValid={setIsValid}
          />
        );

      case 11:
        return (
          <Portfolios
            title={pages[stepRegister.page]}
            addPortfolio={addPortfolio}
            setAddPortfolio={setAddPortfolio}
            setIsOptional={setIsOptional}
            setIsValid={setIsValid}
          />
        );

      case 12:
        return (
          <Certificate
            title={pages[stepRegister.page]}
            addCertificate={addCertificate}
            setAddCertificate={setAddCertificate}
            setIsOptional={setIsOptional}
            setIsValid={setIsValid}
          />
        );

      case 13:
        return <Finish type="user" onClick={onFinish} />;

      default:
        return <div />;
    }
  }, [
    addCertificate,
    addEducation,
    addLanguages,
    addPortfolio,
    addSkills,
    addWork,
    onFinish,
    onNext,
    pages,
    stepRegister.page,
  ]);

  return isActiveUser(user) ? (
    <Redirect to="/" />
  ) : (
    <Layout
      header
      back={stepRegister.page !== 1}
      onClickBack={onBack}
      className="padding-bottom-40 div-container-text-responsive grid-responsive-register"
    >
      {width > 990
        ? stepRegister.page !== 0 &&
          stepRegister.page !== 5 &&
          stepRegister.page !== 13 && (
            <div className="grid-column-2-5 margin-top-16">
              <div className="container-step">
                <CardSteps
                  itemsMenu={itemsMenu}
                  onClick={onClose}
                  className="card-steps-register"
                />
                <Progress
                  value={Math.round(
                    (stepRegister.page / (pages?.length ?? 1)) * 100,
                  )}
                  className="margin-top-32"
                />
              </div>
            </div>
          )
        : stepRegister.page !== 0 &&
          stepRegister.page !== 5 &&
          stepRegister.page !== 13 && (
            <div className=" grid-column-1-9">
              <div className="container-step ">
                <Progress
                  value={Math.round(
                    (stepRegister.page / (pages?.length ?? 1)) * 100,
                  )}
                  className="margin-bottom-progress "
                />
                <CardSteps
                  itemsMenu={itemsMenu}
                  className="card-steps-register"
                  orientation="horizontal"
                />
              </div>
            </div>
          )}
      {width > 990
        ? stepRegister.page >= 0 &&
          stepRegister.page < 7 &&
          stepRegister.page !== 5 &&
          stepRegister.page !== 13 && (
            <Card
              className={`margin-register-mobile height-fit-content card-register-page margin-top-16 padding-0 
            ${stepRegister.page !== 0 ? "border-card" : ""} ${
                stepRegister.page === 0
                  ? "grid-column-3-11"
                  : "grid-column-5-12"
              }`}
              titleHeader={pages[stepRegister.page]}
            >
              {renderCards}
            </Card>
          )
        : stepRegister.page >= 0 &&
          stepRegister.page < 7 &&
          stepRegister.page !== 5 &&
          stepRegister.page !== 13 && (
            <div className=" grid-column-1-9">
              <Card
                borderNone
                className={`margin-register-mobile height-fit-content card-register-page margin-top-16 padding-0 
            ${stepRegister.page !== 0 ? "border-card" : ""} `}
                titleHeader={pages[stepRegister.page]}
              >
                {renderCards}
              </Card>
            </div>
          )}
      {renderPages}
      {isOptional &&
        !addWork &&
        !addEducation &&
        !addPortfolio &&
        !addCertificate && (
          <Button
            outline
            text="Pular"
            appearance="secondary"
            className="grid-column-1-9-mobile grid-column-10-12"
            onClick={onNext}
          />
        )}
      {!isOptional &&
        !addWork &&
        !addEducation &&
        !addPortfolio &&
        !addCertificate &&
        stepRegister.page !== 5 &&
        stepRegister.page !== 13 && (
          <Button
            text="Avançar"
            className="button-primary grid-column-1-9-mobile grid-column-10-12 "
            onClick={onNext}
            disabled={!isValid}
          />
        )}
      <Modal
        headerAction
        textHeader="Atualizar página"
        open={openModal}
        onClose={() => setOpenModal(false)}
        className="company-register-modal"
      >
        <p className="p-class">
          Tem certeza que deseja atualizar a página? Você perderá os seus dados
          preenchidos.
        </p>

        <div className="company-register-modal-buttons">
          <Button
            text="Atualizar"
            className="width-200 margin-right-32 width-100-responsive margin-right-0-mobile margin-right-32 margin-left-0-mobile margin-top-20-mobile"
            onClick={() => window.location.reload()}
          />
          <Button
            text="Voltar"
            onClick={() => setOpenModal(false)}
            className="width-200 width-100-responsive"
          />
        </div>
      </Modal>
    </Layout>
  );
};

export default memo(Register);
