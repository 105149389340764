import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const Pause = ({ widthIcon, heightIcon, className }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
    className={className ?? ""}
  >
    <path
      fill="#606060"
      fillRule="evenodd"
      d="M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12zm9 4H9V8h2v8zm1 4c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3-4h-2V8h2v8z"
      clipRule="evenodd"
    />
  </svg>
);

export default Pause;
