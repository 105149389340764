/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from "react";
import moment from "moment";
import {
  Accessability,
  Birthday,
  Edit,
  Gender,
  Race,
} from "../../../../assets/icons";
import { Modal } from "../../../components";
import { ProfileGender, ProfileRace } from "../../../types/constants/User";
import { userGenderIdentity, userRace } from "../../../types/enumerators";

interface Props {
  user: any;
  openModal: boolean;
  onClose: () => void;
  onEdit: () => void;
  isMyProfile: boolean;
}

const HiddenText = () => (
  <p className="hidden-information">Informação ocultada pela usuária</p>
);

const GeneralData = ({
  user,
  openModal,
  onClose,
  onEdit,
  isMyProfile,
}: Props): JSX.Element => (
  <Modal
    headerAction
    textHeader="Dados gerais"
    open={openModal}
    onClose={onClose}
    bodyClassName="modal-profile header-edit-contacts height-fit-content"
  >
    <div className="flex flex-row">
      <Birthday width={40} />
      <div className="flex-col margin-left-10 width-100">
        <p className="title-view paragraph-edit-general">Idade</p>
        {(user?.profile?.birthdate &&
          !user?.filters?.publicProfile?.includes("profile.birthdate")) ||
        isMyProfile ? (
          <p className="view-bold sub-paragraph-edit-general ">
            {moment(user?.profile?.birthdate, "DD/MM/YYYY").isValid()
              ? moment().diff(
                  moment(user?.profile?.birthdate, "DD/MM/YYYY"),
                  "years",
                )
              : ""}
          </p>
        ) : (
          <HiddenText />
        )}
      </div>
      {isMyProfile && (
        <Edit width={36} onClick={onEdit} className="cursor-pointer" />
      )}
    </div>

    <div className="flex flex-row margin-top-24">
      <Gender />
      <div className="flex-col margin-left-10">
        <p className="title-view paragraph-edit-general">Gênero</p>
        {(user?.profile?.genderIdentity &&
          !user?.filters?.publicProfile?.includes("profile.genderIdentity")) ||
        isMyProfile ? (
          <p className="view-bold sub-paragraph-edit-general">
            {user?.profile?.genderIdentity === userGenderIdentity.OTHERS
              ? user?.profile?.genderIdentityDescription
              : ProfileGender[user?.profile?.genderIdentity]}
          </p>
        ) : (
          <HiddenText />
        )}
      </div>
    </div>

    <div className="flex flex-row margin-top-24">
      <Race />
      <div className="flex-col margin-left-10">
        <p className="title-view paragraph-edit-general">Raça</p>
        {(user?.profile?.race &&
          !user?.filters?.publicProfile?.includes("profile.race")) ||
        isMyProfile ? (
          <p className="view-bold  sub-paragraph-edit-general">
            {user?.profile?.race === userRace.OTHERS
              ? user?.profile?.raceDescription
              : ProfileRace[user?.profile?.race]}
          </p>
        ) : (
          <HiddenText />
        )}
      </div>
    </div>
    <div className="flex flex-row margin-top-24">
      <Accessability />
      <div className="flex-col margin-left-10">
        <p className="title-view paragraph-edit-general">Acessibilidade</p>
        {(user?.profile?.disabledPersonDescription &&
          !user?.filters?.publicProfile?.includes("profile.disabledPerson")) ||
        isMyProfile ? (
          <p className="view-bold sub-paragraph-edit-general">
            {user?.profile?.disabledPersonDescription}
          </p>
        ) : (
          <HiddenText />
        )}
      </div>
    </div>
  </Modal>
);

export default memo(GeneralData);
