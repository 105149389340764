import React, { memo, useCallback, useState } from "react";
import "./style.scss";
import { ReportProblemOutlined } from "@material-ui/icons";
import { ClassVacancySubscription } from "reprograma-types/dist/interface";
import ModalUnsubscribe from "./ModalUnsubscribe";
import { useAuth } from "../../auth";
import { giveUpUserFromClassVacancySubscription } from "../../services/functions";
import { User } from "../../types/interfaces";
import { createCourseSubscriptionLog } from "../../services/historicFunctions";
import { CourseSubscriptionAction } from "../../types/enumerators/actions/CourseSubscriptionAction";
import { CourseSubscriptionState } from "../../types/constants/Historic";

interface Props {
  userId: User["_id"];
  subscriptionId: ClassVacancySubscription["_id"];
  className?: string;
}

const Unsubscribe = ({
  userId,
  subscriptionId,
  className,
}: Props): JSX.Element => {
  const [openModal, setOpenModal] = useState(false);
  const { signOut } = useAuth();

  const cancellSubscription = useCallback(async () => {
    if (subscriptionId && userId) {
      createCourseSubscriptionLog(
        userId,
        CourseSubscriptionAction.COURSE_SUBSCRIPTION_UNSUBSCRIBE,
        CourseSubscriptionState.UNSUBSCRIBE_SUBMISSION,
        CourseSubscriptionState.UNSUBSCRIBE_SUBMITTED,
      );

      await giveUpUserFromClassVacancySubscription(subscriptionId);
      signOut();
    }
  }, [subscriptionId, signOut, userId]);

  return (
    <>
      <div
        className={`grid-row-1 margin-top-16 box-unsubscribe ${
          className ?? ""
        }`}
      >
        <div className="box-unsubscribe-container">
          <div className="box-unsubscribe-container icon">
            <ReportProblemOutlined />
          </div>
          <div className="box-unsubscribe-container text">
            <div>
              Não é possível se inscrever para mais de um Processo Seletivo.
            </div>
            <p>
              Se quiser participar de outro processo, você pode encerrar esta
              inscrição agora. Mas lembre-se, esta é uma ação irreversível.
            </p>
          </div>
        </div>
        <button
          className="box-unsubscribe button"
          type="button"
          onClick={() => setOpenModal(true)}
        >
          Cancelar inscrição
        </button>
      </div>
      <ModalUnsubscribe
        openModal={openModal}
        onClick={cancellSubscription}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default memo(Unsubscribe);
