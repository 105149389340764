import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { Button, InputAutocomplete, Modal, Select } from "../../components";
import { autoCompleteType } from "../../types/enumerators";
import { registerAutoComplete } from "../../utils";
import { setLoad } from "../../store/actions/configurationsActions";
import "./style.scss";

interface Props {
  open: boolean;
  onClose: () => void;
}

const ModalAddAutocomplete = ({ open, onClose }: Props): JSX.Element => {
  const [text, setText] = useState<string | string[] | null>();
  const [type, setType] = useState<keyof typeof autoCompleteType>();
  const dispatch = useDispatch();

  const onSave = useCallback(async () => {
    if (text) {
      dispatch(setLoad(true) as unknown as Action);
      if (Array.isArray(text))
        for (let i = 0; i < text?.length; i += 1)
          // eslint-disable-next-line no-await-in-loop
          await registerAutoComplete(
            text[i],
            type as keyof typeof autoCompleteType,
          );
      else
        await registerAutoComplete(
          text as string,
          type as keyof typeof autoCompleteType,
        );

      onClose();
      dispatch(setLoad(false) as unknown as Action);
    }
  }, [dispatch, onClose, text, type]);

  return (
    <Modal
      headerAction
      open={open}
      onClose={onClose}
      textHeader="Adcionar"
      className="admin-subscriptions-modal-tags"
    >
      <InputAutocomplete
        multiple
        label=""
        helperText="Pressione Enter para adicionar"
        onChange={(value) => setText(value)}
        value={text}
        options={[]}
        getOptionLabel={(option) => option}
      />
      <Select
        id="type"
        label="Selecione*"
        value={type ?? ""}
        onChange={(e: { target: { value: keyof typeof autoCompleteType } }) =>
          setType(e.target.value)
        }
        options={Object.keys(autoCompleteType).map((key) => ({
          value: key,
          label: autoCompleteType[key as keyof typeof autoCompleteType],
        }))}
        className="input-autocomplete-company"
      />
      <div className="admin-subscriptions-modal-buttons margin-top-32">
        <Button
          text="Cancelar"
          onClick={onClose}
          outline
          appearance="secondary"
        />
        <Button onClick={onSave} text="Salvar" disabled={!text || !type} />
      </div>
    </Modal>
  );
};

export default memo(ModalAddAutocomplete);
