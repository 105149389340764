import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import moment from "moment-timezone";
import Modal from "../Modal";
import { Check, Delete, ArrowBack, Edit } from "../../../assets/icons";
import type {
  Comments as CommentsProps,
  User as UserType,
} from "../../types/interfaces";
import { AvatarEmpty, Close } from "../../../assets/customIcons";
import { UserWithPhotoProps } from "../../pages/AdminClassVacancySubscriptions/Steps/types";
import TextArea from "../TextArea";
import Button from "../Button";
import { getUserById } from "../../services/functions";

interface UserData {
  name: string;
  photo?: string | null;
  userId?: string;
}

interface Props {
  userId: string;
  data: CommentsProps[];
  openModal: boolean;
  dataUser: UserData;
  onClose: () => void;
  submit: (updatedData: unknown) => void;
  type?: string;
}

interface HeaderProps {
  dataUser: UserData;
  onClose: () => void;
}

const HeaderModalComments = ({
  dataUser,
  onClose,
}: HeaderProps): JSX.Element => (
  <div className="admin-subscriptions-infos-header">
    <div className="admin-subscriptions-infos-header-top">
      <div>
        {dataUser?.photo ? (
          <img
            alt=""
            src={dataUser?.photo}
            className="img-avatar-header-modal"
          />
        ) : (
          <AvatarEmpty
            className="border-avatar-empty img-attachment-photo-small"
            heightIcon={32}
            widthIcon={32}
          />
        )}
        <p className="p-text-header-action-card">{dataUser?.name}</p>
      </div>
      <Close
        className="close-header-action-card margin-left-6 cursor-pointer"
        widthIcon="28"
        onClick={onClose}
      />
    </div>
  </div>
);

const ModalComments = ({
  openModal,
  onClose,
  userId,
  data,
  submit,
  dataUser,
  type = "TEXT",
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [dataComment, setDataComment] =
    useState<Record<string, UserWithPhotoProps[]>>();
  const [comment, setComment] = useState<CommentsProps>({
    date: moment().tz("America/Sao_Paulo").toISOString(),
    userId,
    text: "",
  });
  const [subitemEdit, setSubitemEdit] = useState<
    CommentsProps | UserWithPhotoProps
  >({
    date: moment().tz("America/Sao_Paulo").toISOString(),
    userId,
    text: "",
  });
  const [edit, setEdit] = useState(false);

  const formatingComments = useCallback(async () => {
    if (data) {
      setLoading(true);

      const getUsers = (await Promise.all(
        data?.map(async (item) => getUserById(item?.userId ?? "")),
      )) as UserType[];

      const userWithPhotos = data?.map((item) => {
        const find = getUsers?.find((subitem) => subitem?._id === item?.userId);

        return {
          ...item,
          userName: find?.profile?.name ?? "",
          photo: find?.profile?.photo ?? null,
        };
      });

      const groupUsers = userWithPhotos?.reduce((acc, curr) => {
        acc[curr?.date as string] = [...(acc[curr?.date as string] ?? [])];

        acc[curr?.date as string].push(curr);

        return acc;
      }, {} as Record<string, UserWithPhotoProps[]>);

      setDataComment(groupUsers);
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (data) formatingComments();
  }, [data, formatingComments]);

  const findObjectSelected = useCallback(
    (subitem: CommentsProps) => {
      const find = data?.find(
        (dataItem) =>
          dataItem?.text === subitem?.text &&
          dataItem?.date === subitem?.date &&
          dataItem?.userId === subitem?.userId,
      );
      return find;
    },
    [data],
  );

  const handleIndividualActions = useCallback(
    (subitem, action: string) => {
      const copyData = data;

      let commentSelected = findObjectSelected(subitem);

      if (commentSelected) {
        const indexCommentSelected = data.indexOf(commentSelected);

        if (action === "resolve") {
          commentSelected = {
            ...commentSelected,
            resolved: !subitem?.resolved,
          };

          copyData[indexCommentSelected] = commentSelected as CommentsProps;
        }

        if (action === "delete") copyData.splice(indexCommentSelected, 1);

        if (action === "edit" && comment) {
          commentSelected = {
            ...commentSelected,
            text: comment.text ?? "",
            updateAt: moment().tz("America/Sao_Paulo").toISOString(),
          };

          copyData[indexCommentSelected] = commentSelected as CommentsProps;
        }

        submit(copyData);
        formatingComments();
      }
    },
    [data, findObjectSelected, comment, submit, formatingComments],
  );

  const handleSubmmit = useCallback(() => {
    const currentComment = {
      text: comment?.text ?? "",
      date: moment(comment?.date).tz("America/Sao_Paulo").toISOString(),
      userId,
      type: type ?? "TEXT",
      resolved: false,
    };

    if (edit) handleIndividualActions(subitemEdit, "edit");
    else {
      data.push(currentComment);
      submit(data);
      formatingComments();
    }

    setEdit(false);
    setComment({
      date: moment().tz("America/Sao_Paulo").toISOString(),
      text: "",
      userId,
    });
  }, [
    comment,
    userId,
    type,
    edit,
    handleIndividualActions,
    subitemEdit,
    data,
    submit,
    formatingComments,
  ]);

  const LoadingCircle = useMemo(
    () => (
      <div className="subscriptions-loading subscriptions-loading-propose">
        <CircularProgress />
      </div>
    ),
    [],
  );

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      maxWidth="md"
      className="admin-subscriptions-infos modal-commments"
    >
      <>
        <HeaderModalComments dataUser={dataUser} onClose={onClose} />

        {!data || loading ? (
          LoadingCircle
        ) : (
          <div className="modal-comments">
            <div className="modal-comments-items mb-32">
              {dataComment &&
                Object.entries(dataComment)?.map(([key, individualComment]) => (
                  <div key={Math.random()}>
                    <div className="modal-comments-header">
                      <p>{moment(key).format("DD/MM/YYYY")}</p>
                    </div>

                    {individualComment?.map((subitem) => (
                      <div
                        className="modal-comments-comment"
                        key={Math.random()}
                      >
                        {subitem?.photo ? (
                          <img
                            className="border-avatar-empty img-attachment-photo-small"
                            alt=""
                            src={subitem?.photo}
                          />
                        ) : (
                          <AvatarEmpty
                            className="border-avatar-empty img-attachment-photo-small"
                            heightIcon={32}
                            widthIcon={32}
                          />
                        )}
                        <div className="modal-comments-comment-body">
                          <span> {subitem?.userName}</span>
                          <div className="h1-recent-search">
                            {subitem?.text}
                          </div>
                        </div>

                        <div className="modal-comments-comment-body-buttons">
                          {subitem?.userId === userId && (
                            <div className="modal-comments-comment-body-buttons">
                              <button
                                type="button"
                                aria-label="edit"
                                onClick={() => {
                                  setEdit(true);
                                  setSubitemEdit(subitem);
                                  setComment({ ...subitem });
                                }}
                              >
                                <Edit />
                              </button>

                              <button
                                aria-label="delete"
                                type="button"
                                onClick={() =>
                                  handleIndividualActions(subitem, "delete")
                                }
                              >
                                <Delete />
                              </button>
                            </div>
                          )}

                          <button
                            type="button"
                            onClick={() =>
                              handleIndividualActions(subitem, "resolve")
                            }
                          >
                            {!subitem?.resolved ? (
                              <Check width={28} />
                            ) : (
                              <ArrowBack width={28} />
                            )}
                            <p>{subitem?.resolved ? "Reabrir" : "Resolver"}</p>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
            </div>

            <TextArea
              id="description"
              label="Escreva seu comentário"
              value={comment?.text}
              maxLength={500}
              textFielRow={4}
              onChange={(e) =>
                setComment({
                  date: moment().tz("America/Sao_Paulo").toISOString(),
                  text: e.target.value,
                  resolved: false,
                })
              }
              className="mb-32"
            />

            <div className="admin-subscriptions-infos-buttons">
              <Button
                text="Cancelar"
                outline
                appearance="secondary"
                onClick={() => {
                  onClose();
                  setComment({
                    type,
                    date: moment().tz("America/Sao_Paulo").toISOString(),
                    userId,
                    text: "",
                  });
                }}
              />
              <Button
                text={!edit ? "Adicionar" : "Editar"}
                disabled={comment?.text === "" || loading}
                onClick={handleSubmmit}
              />
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};

export default memo(ModalComments);
