import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import { Unblock, Vacancy, Help } from "../../../assets/icons";
import {
  Card,
  HeaderProfile,
  IconButton,
  Layout,
  Progress,
} from "../../components";
import {
  createClassVacancy,
  getClassVacancyById,
  updateClassVacancyById,
} from "../../services/functions";
import { classVacancyStatus } from "../../types/enumerators";
import { ClassVacancy } from "../../types/interfaces";
import { GeneralData, Stages, CustomQuestions } from "./Steps";
import "./style.scss";
import { setLoad } from "../../store/actions/configurationsActions";

interface ParamsProps {
  id: string;
}

const AdminClassVacancyNewEdit = (): JSX.Element => {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>({
    status: classVacancyStatus.PENDING,
  } as ClassVacancy);
  const [title, setTitle] = useState("");
  const { id } = useParams<ParamsProps>();
  const history = useHistory();
  const dispatch = useDispatch();

  const getClassVacancy = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getClassVacancyById(id);
    setClassVacancy(response.data);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, id]);

  useEffect(() => {
    if (!classVacancy?._id && id) getClassVacancy();
  }, [classVacancy, getClassVacancy, id]);

  const itemsMenu = useMemo(
    () => [
      {
        icon: <Vacancy />,
        text: "Dados gerais",
        id: 1,
      },
      {
        icon: <Unblock />,
        text: "Etapas",
        id: 2,
      },
      {
        icon: <Help />,
        text: "Questões Personalizadas",
        id: 3,
      },
    ],
    [],
  );

  const nextPage = useCallback(() => setStep(step + 1), [step]);

  const onSave = useCallback(() => {
    if (id) updateClassVacancyById(id, classVacancy);
    else createClassVacancy(classVacancy);

    if (step < 3) nextPage();
    else history.push("/admin-class-vacancy");
  }, [id, classVacancy, step, nextPage, history]);

  const renderPages = useMemo(() => {
    switch (step) {
      case 1:
        setTitle("Dados Gerais");
        return (
          <GeneralData
            classVacancy={classVacancy}
            setClassVacancy={setClassVacancy}
            nextPage={() => {
              nextPage();
              setProgress((step / (itemsMenu?.length ?? 1)) * 110);
            }}
            onSave={onSave}
          />
        );

      case 2:
        setTitle("Etapas");
        return (
          <Stages
            classVacancy={classVacancy}
            setClassVacancy={setClassVacancy}
            nextPage={() => {
              nextPage();
              setProgress((step / (itemsMenu?.length ?? 1)) * 100);
            }}
            onSave={onSave}
          />
        );

      case 3:
        setTitle("Questões Personalizadas");
        return (
          <CustomQuestions
            classVacancy={classVacancy}
            setClassVacancy={setClassVacancy}
            onSave={onSave}
            finalProgress={setProgress}
          />
        );
      default:
        return <div />;
    }
  }, [step, classVacancy, onSave, nextPage, itemsMenu?.length]);

  return (
    <Layout className="admin-class-edit">
      <HeaderProfile />
      <div className="grid-column-2-12 grid-row-1 header-steps-company-register-vacancy">
        <p className="view-page">Processos &gt;&nbsp;</p>
        <p className={`view-page ${id ? "" : "view-step"}`}>
          {id ? "Editar" : "Criar Processo"}
        </p>
        <p className="view-bold-16">&nbsp;&gt;&nbsp;{title}</p>
      </div>
      <div className="grid-column-2-5 grid-row-2">
        <div className="admin-class-edit-company">
          <Card bodyClassName="height-fit-content">
            <div>
              {itemsMenu?.map((item) => (
                <IconButton
                  icon={item?.icon}
                  text={item?.text}
                  onClick={() => {
                    if (id) setStep(item?.id);
                    else if (item?.id <= step) setStep(item?.id);
                  }}
                  className={`steps-account ${
                    step !== item?.id ? "steps-account-disabled" : ""
                  }`}
                />
              ))}
            </div>
          </Card>
          {!id && (
            <div className="progress" style={{ marginTop: "20%" }}>
              <Progress value={Math.round(progress)} />
            </div>
          )}
        </div>
      </div>
      {renderPages}
    </Layout>
  );
};

export default memo(AdminClassVacancyNewEdit);
