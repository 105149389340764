import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";
import moment from "moment";
import { FormControlLabel, Switch } from "@material-ui/core";
import { Card, IconButton } from "../../../components";
import { Class, ClassForms } from "../../../types/interfaces";
import { Clock } from "../../../../assets/icons";
import { Edit } from "../../../../assets/customIcons";
import { researchType } from "../../../types/enumerators";
import { setLoad } from "../../../store/actions/configurationsActions";
import { updateClassById } from "../../../services/functions";
import { Research } from "../Modal";
import "../style.scss";

interface Props {
  currentClass: Class;
  reload: () => void;
}

const Forms = ({ currentClass, reload }: Props): JSX.Element => {
  const [type, setType] = useState<string>("");
  const [form, setForm] = useState<ClassForms>({} as ClassForms);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const listForm = useMemo(
    () => [
      {
        label: "1 mês",
        type: researchType.ONE_MONTHS,
      },
      {
        label: "3 meses",
        type: researchType.THREE_MONTHS,
      },
      {
        label: "6 meses",
        type: researchType.SIX_MONTHS,
      },
      {
        label: "12 meses",
        type: researchType.TWELVE_MONTHS,
      },
      {
        label: "Empresas",
        type: researchType.COMPANY,
      },
    ],
    [],
  );

  const disable = useCallback(
    async (row) => {
      dispatch(setLoad(true) as unknown as Action);
      const i =
        currentClass?.forms?.findIndex(
          (item: { type?: string }) => item?.type === row?.type,
        ) ?? -1;

      const updatedForms = currentClass?.forms ?? [];

      if (i !== -1) updatedForms[i].enable = false;

      await updateClassById(currentClass?._id ?? "", { forms: updatedForms });
      reload();
      dispatch(setLoad(false) as unknown as Action);
    },
    [currentClass?._id, currentClass?.forms, dispatch, reload],
  );

  const onClick = useCallback(
    (row: { label?: string; type: string }, disabled?: boolean) => {
      const currentForm = currentClass?.forms?.find(
        (item: { type?: string }) => item?.type === row?.type,
      );

      if (disabled && form?.enable) disable(form);
      else {
        setType(row?.type);
        setForm(currentForm as ClassForms);
        setOpenModal(true);
      }
    },
    [currentClass?.forms, form, disable],
  );

  return (
    <>
      <Card
        titleHeader="Formulários de pesquisa"
        className="grid-column-1-4 grid-row-3"
        bodyClassName="card-general-form"
      >
        <div>
          <div>
            <p className="title-form">
              Ative as pesquisas que você deseja para enviar notificações às
              candidatas.
            </p>
          </div>
          {listForm?.map((row) => (
            <div key={Math.random()} className="month-form">
              <div className="label-icon">
                <p>{row?.label}</p>
                <div>
                  {currentClass?.forms?.find(
                    (item: { type?: string }) =>
                      item?.type && item?.type === row?.type,
                  )?.startDate &&
                    moment(
                      currentClass?.forms?.find(
                        (item: { type?: string }) =>
                          item?.type && item?.type === row?.type,
                      )?.startDate,
                    ).isAfter(moment(), "day") && (
                      <div>
                        <Clock />
                      </div>
                    )}
                </div>
              </div>
              <div className="flex-row-center">
                {(currentClass?.forms?.find(
                  (item: { type?: string }) =>
                    item?.type && item?.type === row?.type,
                )?.enable ||
                  (currentClass?.forms?.find(
                    (item: { type?: string }) =>
                      item?.type && item?.type === row?.type,
                  )?.startDate &&
                    moment(
                      currentClass?.forms?.find(
                        (item: { type?: string }) =>
                          item?.type && item?.type === row?.type,
                      )?.startDate,
                    ).isAfter(moment(), "day"))) && (
                  <IconButton
                    icon={<Edit widthIcon={20} heightIcon={20} />}
                    onClick={() => onClick(row, false)}
                    className="cursor-pointer"
                  />
                )}
                <FormControlLabel
                  className="margin-left-12"
                  onClick={(e) => e.stopPropagation()}
                  control={
                    <Switch
                      checked={
                        currentClass?.forms?.find(
                          (item: { type?: string }) =>
                            item?.type && item?.type === row?.type,
                        )?.enable
                      }
                      onChange={() => onClick(row, true)}
                      name="enable"
                    />
                  }
                  label=""
                />
              </div>
            </div>
          ))}
        </div>
      </Card>
      <Research
        open={openModal}
        close={() => setOpenModal(false)}
        data={form}
        type={type}
        currentClass={currentClass}
      />
    </>
  );
};

export default memo(Forms);
