export const AdminClassViewStatus = {
  IN_PROCESS: "Em curso",
  FINISHED: "Formada",
  GIVE_UP: "Desistente",
  DISAPPROVED: "Desclassificada",
  CHANGED: "Transferida",
  STOPPED: "Pausada",
  REPLACED: "Substituída",
};

export const AutocompleteStatus = {
  PENDING: "Pendente",
  ACTIVE: "Ativo",
};

export const AutocompleteType = {
  SKILL: "Skills",
  LANGUAGE: "Idiomas",
  TAG: "Tags",
  WORK_POSITION: "Cargo",
  EDUCATION_TITLE: "Educação",
};

export const PlacementVacancyStatus = {
  PENDING: "Pendente",
  ACTIVE: "Ativa",
  FINISHED: "Finalizada",
  STOPPED: "Pausada",
};

export const PlacementVacancyTypeOfCandidature = {
  OPEN: "Aberta",
  CLOSE: "Fechada",
};
