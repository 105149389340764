/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import {
  ChevronRightPage,
  ChevronDoubleRight,
  ChevronLeftPage,
  ChevronDoubleLeft,
} from "../../../assets/icons";
import { setPageLimit } from "../../store/actions/configurationsActions";
import { RootState } from "../../store/reducers";
import ButtonPagination from "../ButtonPagination";
import Input from "../Input";
import "./styles.scss";

interface Props {
  page?: number;
  maxPage?: number;
  setPage?: any;
  showAllOption?: boolean;
  showAnyOption?: boolean;
  hideOptions?: boolean;
}

const Pagination = ({
  page = 1,
  maxPage = 1,
  setPage = () => null,
  showAllOption = false,
  showAnyOption = false,
  hideOptions = false,
}: Props): JSX.Element => {
  const { pageLimit: limit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const [isAnyOption, setIsAnyOption] = useState(false);
  const [currentLimit, setCurrentLimit] = useState<number | string>(limit);

  const dispatch = useDispatch();

  const setLimit = useCallback(
    (x: number) => {
      dispatch(setPageLimit(x) as unknown as Action);
    },
    [dispatch],
  );

  const handlePageMinus = useCallback(() => {
    if (page > 1) {
      setPage(page - 1);
    }
  }, [page, setPage]);

  const handlePagePlus = useCallback(() => {
    if (page < maxPage) {
      setPage(page + 1);
    }
  }, [maxPage, page, setPage]);

  const paginationOptions = useMemo(() => {
    const base = [
      {
        text: "10 linhas",
        onClick: () => {
          setIsAnyOption(false);
          setLimit(10);
        },
      },
      {
        text: "20 linhas",
        onClick: () => {
          setIsAnyOption(false);
          setLimit(20);
        },
      },
      {
        text: "30 linhas",
        onClick: () => {
          setIsAnyOption(false);
          setLimit(30);
        },
      },
    ];
    if (showAllOption) {
      base.push({
        text: "Exibir todas",
        onClick: () => {
          setIsAnyOption(false);
          setLimit(maxPage * limit);
          setPage(1);
        },
      });
    }
    if (showAnyOption) {
      base.push({
        text: "Personalizar",
        onClick: () => setIsAnyOption(true),
      });
    }
    return base;
  }, [limit, maxPage, setLimit, setPage, showAllOption, showAnyOption]);

  return (
    <div className="pagination">
      <div>
        <button
          aria-label="double-left"
          type="button"
          onClick={() => setPage(1)}
          className={`${page === 1 ? "disabled-btn" : ""} rotate`}
          disabled={page === 1}
        >
          <ChevronDoubleLeft />
        </button>
        <button
          aria-label="left"
          type="button"
          onClick={handlePageMinus}
          className={`${page === 1 ? "disabled-btn" : ""} rotate`}
          disabled={page === 1}
        >
          <ChevronLeftPage />
        </button>
        <div>
          <p className="block">{page}</p>
          <p className="media990px">{`de ${maxPage}`}</p>
        </div>
        <button
          aria-label="right"
          type="button"
          onClick={handlePagePlus}
          className={page === maxPage ? "disabled-btn" : ""}
          disabled={page === maxPage}
        >
          <ChevronRightPage />
        </button>
        <button
          aria-label="double-right"
          type="button"
          onClick={() => setPage(maxPage)}
          className={page === maxPage ? "disabled-btn" : ""}
          disabled={page === maxPage}
        >
          <ChevronDoubleRight />
        </button>
      </div>
      {!hideOptions && limit && (
        <ButtonPagination
          text={isAnyOption ? "" : `Ver ${limit} linhas`}
          openedClassName="btn-menu-pagination"
          items={paginationOptions}
        >
          {isAnyOption && (
            <div className="btn-menu-pagination">
              <p className="button-pagination-text">Ver</p>
              <Input
                className="input-any-option"
                type="number"
                value={currentLimit}
                onChange={({ target: { value } }) => {
                  if (Number(value) > -1) setCurrentLimit(value);
                }}
                onBlur={() => {
                  if (Number(currentLimit) > 0) setLimit(Number(currentLimit));
                }}
              />
              <p className="button-pagination-text padding-0">linhas</p>
            </div>
          )}
        </ButtonPagination>
      )}
    </div>
  );
};
export default memo(Pagination);
