import React, { memo, useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Action } from "redux";
import {
  Card,
  Layout,
  CardSteps,
  Progress,
  Button,
  Finish,
  FormAddress,
} from "../../components";
import {
  Address,
  Avatar,
  Curriculum,
  ReprogramaIcon,
} from "../../../assets/customIcons";
import {
  PersonalData,
  CompanyEmployment,
  YouReprograma,
  CompanyPersonalData,
  Employment,
} from "./Steps";
import "./styles.scss";
import { setNextButton } from "../../store/actions/nextButtonAction";
import { RootState } from "../../store/reducers";
import { setStepRegister } from "../../store/actions/stepRegisterActions";
import {
  createResearchEmployment,
  getClassById,
  getResearchByUserIdAndClassIdAndType,
  getUserIdResearchBySupervisionIdAndClassId,
} from "../../services/functions";
import { setEmployment } from "../../store/actions/employmentAction";
import { useAuth } from "../../auth";
import {
  researchType,
  userBootcampsStatus,
  userStatus,
} from "../../types/enumerators";
import { isCorporate, isStudent } from "../../utils";
import { setLoad } from "../../store/actions/configurationsActions";

interface ParamsProps {
  classId: string;
  type: keyof typeof researchType;
  id?: string;
}

const Employability = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [isValid, setIsValid] = useState(false);
  const { classId, type, id } = useParams<ParamsProps>();
  const employment = useSelector((state: RootState) => state.employmentState);
  const stepEmployment = useSelector(
    (state: RootState) => state.stepRegisterState,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { signOut, signInWithId } = useAuth();

  const company = useSelector((state: RootState) => state.companyState);
  const user = useSelector((state: RootState) => state.userState);

  const { nextButton } = useSelector(
    (state: RootState) => state.nextButtonState,
  );

  useEffect(() => {
    if (id && type === researchType.COMPANY) signInWithId(id);
  }, [signInWithId, id, type]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    dispatch(
      setStepRegister({
        page: 0,
        maxPageVisited: 0,
        maxMenuVisited: 0,
      }) as unknown as Action,
    );
  }, [dispatch]);

  const itemsCompany = useMemo(
    () => [
      {
        icon: (
          <Avatar
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Dados da empresa",
        id: 0,
      },
      {
        icon: (
          <Curriculum
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Empregabilidade",
        id: 1,
      },
    ],
    [width],
  );

  const itemsStudent = useMemo(
    () => [
      {
        icon: (
          <Avatar
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Dados pessoais",
        id: 0,
      },
      {
        icon: (
          <Address
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Endereço",
        id: 1,
      },
      {
        icon: (
          <Curriculum
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Empregabilidade",
        id: 2,
      },
      {
        icon: (
          <ReprogramaIcon
            widthIcon={width < 991 ? 20 : 24}
            heightIcon={width < 991 ? 20 : 24}
          />
        ),
        text: "Você e a {reprograma}",
        id: 3,
      },
    ],
    [width],
  );

  const itemsMenu = useMemo(
    () => (company?._id ? itemsCompany : itemsStudent),
    [company?._id, itemsCompany, itemsStudent],
  );

  const renderCards = useMemo(() => {
    if (company?._id) {
      switch (stepEmployment.page) {
        case 0:
          return <CompanyPersonalData templateRows setIsValid={setIsValid} />;

        case 1:
          return <CompanyEmployment templateRows setIsValid={setIsValid} />;

        default:
          return <div />;
      }
    }

    switch (stepEmployment.page) {
      case 0:
        return <PersonalData templateRows setIsValid={setIsValid} />;

      case 1:
        return <FormAddress setIsValid={setIsValid} showLabel />;

      case 2:
        return <Employment templateRows setIsValid={setIsValid} />;

      case 3:
        return <YouReprograma templateRows setIsValid={setIsValid} />;

      default:
        return <div />;
    }
  }, [company?._id, stepEmployment.page]);

  const onNext = useCallback(() => {
    const tempEmployment = {
      page: stepEmployment.page + 1,
      maxPageVisited:
        stepEmployment.maxPageVisited > stepEmployment.page + 1
          ? stepEmployment.maxPageVisited
          : stepEmployment.page + 1,
      maxMenuVisited:
        stepEmployment.maxMenuVisited > stepEmployment.page + 1
          ? stepEmployment.maxMenuVisited
          : stepEmployment.page + 1,
    };

    dispatch(setStepRegister(tempEmployment) as unknown as Action);

    if (nextButton !== null) {
      const funcNextButton = () => nextButton();
      funcNextButton();
      dispatch(setNextButton(null) as unknown as Action);
    }

    setIsValid(false);
  }, [stepEmployment, dispatch, nextButton]);

  const onBack = useCallback(() => {
    setIsValid(true);
    if (stepEmployment.page !== 0 || stepEmployment.page === 4)
      dispatch(
        setStepRegister({ page: stepEmployment.page - 1 }) as unknown as Action,
      );
  }, [dispatch, stepEmployment.page]);

  const getEmployment = useCallback(async () => {
    let response = await getResearchByUserIdAndClassIdAndType(
      user?._id,
      classId,
      type,
    );

    if (response?.length)
      dispatch(setEmployment(response[0]) as unknown as Action);
    else {
      await createResearchEmployment({
        userId: user?._id,
        date: new Date(),
        type,
        classId,
        companyId: company?._id ?? "",
        form: {},
      }).then(async (result) => {
        if (result) {
          response = await getResearchByUserIdAndClassIdAndType(
            user?._id,
            classId,
            type,
          );
          if (response?.length)
            dispatch(setEmployment(response[0]) as unknown as Action);
        }
      });
    }
  }, [classId, company?._id, dispatch, type, user?._id]);

  useEffect(() => {
    if (
      (!employment?._id ||
        !employment?.type ||
        !employment?.classId ||
        type !== employment?.type ||
        classId !== employment?.classId) &&
      !!user?._id &&
      (type !== researchType.COMPANY || !!company?._id)
    )
      getEmployment();
  }, [
    classId,
    company?._id,
    employment?._id,
    employment?.classId,
    employment?.type,
    getEmployment,
    type,
    user?._id,
  ]);

  useEffect(() => {
    if (
      !user?._id ||
      !employment?._id ||
      (classId && !employment?.classId) ||
      classId !== employment?.classId ||
      (type && !employment?.type) ||
      type !== employment?.type ||
      (!!company?._id && !employment?.companyId) ||
      (id && type === researchType.COMPANY && !company?._id)
    )
      dispatch(setLoad(true) as unknown as Action);
    else dispatch(setLoad(false) as unknown as Action);
  }, [
    company?._id,
    employment?.classId,
    employment?.companyId,
    employment?._id,
    employment?.type,
    classId,
    type,
    user?._id,
    dispatch,
    id,
  ]);

  const classWithActiveForm = useMemo(async () => {
    const response = await getClassById(classId);
    const formEnable = !!response?.data?.forms?.find(
      (item: { type: string; enable?: boolean }) =>
        item?.type === type && item?.enable,
    );
    return formEnable;
  }, [classId, type]);

  const getResearchWithSupervisionId = useCallback(async () => {
    const response = await getUserIdResearchBySupervisionIdAndClassId(
      user?._id,
      classId,
    );
    return !!response;
  }, [user?._id, classId]);

  useEffect(() => {
    const bootcamp = user?.bootcamps?.find(
      (item: { classId: string }) => item?.classId === classId,
    );
    const finished = bootcamp?.status === userBootcampsStatus.FINISHED;

    if (
      !!user?._id &&
      type !== researchType.COMPANY &&
      ((user?.account?.status !== userStatus.ACTIVE &&
        user?.account?.status !== userStatus.PENDING) ||
        !classWithActiveForm ||
        !isStudent(user) ||
        !bootcamp ||
        !finished)
    )
      signOut();
  }, [user, type, classWithActiveForm, signOut, classId]);

  useEffect(() => {
    let hasResearchWithSupervisionId = false;
    getResearchWithSupervisionId().then((result) => {
      hasResearchWithSupervisionId = result;
    });

    if (
      !!user?._id &&
      type === researchType.COMPANY &&
      (!company?._id ||
        !isCorporate(user) ||
        !classWithActiveForm ||
        !hasResearchWithSupervisionId)
    )
      signOut();
  }, [
    user,
    type,
    classWithActiveForm,
    getResearchWithSupervisionId,
    company?._id,
    signOut,
  ]);

  return (
    <Layout
      header
      back={stepEmployment.page !== 0}
      onClickBack={onBack}
      className="padding-bottom-40 grid-responsive-register"
    >
      {!employment?.hasFinished ? (
        <>
          {width > 990 && stepEmployment.page < itemsMenu.length && (
            <div className="grid-column-2-5">
              <div className="container-step">
                <CardSteps
                  smallScreen
                  itemsMenu={itemsMenu}
                  className="card-steps-register"
                />
                <Progress
                  value={Math.round(
                    (stepEmployment.page / (itemsMenu?.length ?? 0)) * 100,
                  )}
                  className="margin-top-32"
                />
              </div>
            </div>
          )}
          {width < 991 && stepEmployment.page < itemsMenu.length && (
            <div className="grid-column-1-11-mobile">
              <div className="container-step">
                <Progress
                  value={Math.round(
                    (stepEmployment.page / (itemsMenu?.length ?? 0)) * 100,
                  )}
                  className="margin-bottom-progress"
                />
                <CardSteps
                  smallScreen
                  itemsMenu={itemsMenu}
                  className="card-steps-register"
                  orientation="horizontal"
                />
              </div>
            </div>
          )}
          {stepEmployment.page < itemsMenu.length && (
            <Card
              className="height-fit grid-column-5-12 grid-column-1-11-mobile border-card"
              titleHeader={itemsMenu[stepEmployment.page].text}
            >
              <div className="grid-account-gap-resposive form-employment">
                {renderCards}
              </div>
            </Card>
          )}
          {stepEmployment.page < itemsMenu.length && (
            <Button
              disabled={!isValid}
              text={stepEmployment.page <= 2 ? "Avançar" : "Enviar"}
              className="grid-column-10-12 grid-column-1-9-mobile button-primary"
              onClick={onNext}
            />
          )}
          {stepEmployment.page === itemsMenu.length && (
            <Finish
              onClick={() => {
                if (company?._id) {
                  sessionStorage.setItem("@SESSION:type", "CORPORATE");
                  history.push("/welcome");
                } else history.push(`/profile/${user?._id}`);
              }}
              type={`research-${type}`}
            />
          )}
        </>
      ) : (
        <Finish
          onClick={() => {
            if (company?._id) {
              sessionStorage.setItem("@SESSION:type", "CORPORATE");
              history.push("/welcome");
            } else history.push(`/profile/${user?._id}`);
          }}
          type={`research-${type}`}
        />
      )}
    </Layout>
  );
};
export default memo(Employability);
