/* eslint-disable no-await-in-loop */
import React, { memo, useCallback, useEffect, useState } from "react";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FormControlLabel, Switch } from "@material-ui/core";
import moment from "moment-timezone";
import {
  Button,
  Card,
  HeaderProfile,
  Input,
  InputAutocomplete,
  InputDate,
  InputDateTime,
  Select,
  Layout,
  TextArea,
} from "../../components";
import {
  createClass,
  getAutocomplete,
  getClassById,
  getUserAdminClassVacancyWorkshopOrBootcamp,
  getUserByIdentification,
  patchUser,
  createUser,
  updateClassById,
  requestSignupLink,
} from "../../services/functions";
import { StructureOptions } from "../../types/constants/Course";
import {
  autoCompleteType,
  classStructure,
  userRoles,
  userStatus,
} from "../../types/enumerators";
import { Class } from "../../types/interfaces";
import {
  hasAdminClassBootcampRole,
  hasAdminClassWorkshopRole,
  registerAutoComplete,
} from "../../utils";
import "./style.scss";
import { setLoad } from "../../store/actions/configurationsActions";

interface ParamsProps {
  id: string;
}

const AdminClassNewEdit = (): JSX.Element => {
  const [currentClass, setCurrentClass] = useState<Class>({} as Class);
  const [oldListAllEmails, setOldListAllEmailsAdmWorkhop] =
    useState<string[]>();
  const [skillsOptions, setSkillOptions] = useState<string[]>();
  const [emailUserOptions, setEmailUserOptions] = useState<string[]>();
  const [title, setTitle] = useState("");
  const { id } = useParams<ParamsProps>();

  const history = useHistory();
  const dispatch = useDispatch();

  const isWorkshop = currentClass?.classStructure === classStructure.WORKSHOP;

  const getClass = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const response = await getClassById(id);
    setCurrentClass(response?.data);

    if (
      response?.data?.teachers ||
      response?.data?.facilitators ||
      response?.data?.techLeads ||
      response?.data?.volunteers
    )
      setOldListAllEmailsAdmWorkhop([
        ...(response?.data?.teachers ?? []),
        ...(response?.data?.techLeads ?? []),
        ...(response?.data?.facilitators ?? []),
        ...(response?.data?.volunteers ?? []),
      ]);

    setTitle(response?.data?.title);

    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, id]);

  useEffect(() => {
    if (!currentClass?._id && id) getClass();
  }, [currentClass?._id, getClass, id]);

  useEffect(() => {
    if (id && !currentClass?._id) dispatch(setLoad(true) as unknown as Action);
  }, [currentClass?._id, dispatch, id]);

  const loadSkills = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getAutocomplete(autoCompleteType.SKILL);
    const skillOptions = response?.map((option) => option?.name);
    setSkillOptions(skillOptions);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  useEffect(() => {
    if (!skillsOptions) loadSkills();
  }, [loadSkills, skillsOptions]);

  const loadUserAdminClassVacancyWorkshop = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getUserAdminClassVacancyWorkshopOrBootcamp();
    const responseEmailUserOptions = response?.map(
      (option: { login: { identification: string } }) =>
        option?.login?.identification,
    );
    setEmailUserOptions(responseEmailUserOptions);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch]);

  useEffect(() => {
    if (!emailUserOptions) loadUserAdminClassVacancyWorkshop();
  }, [emailUserOptions, loadUserAdminClassVacancyWorkshop]);

  const addClassId = useCallback(
    async (classId) => {
      dispatch(setLoad(true) as unknown as Action);
      if (
        currentClass?.teachers ||
        currentClass?.techLeads ||
        currentClass?.facilitators ||
        currentClass?.volunteers
      ) {
        const listAllEmails = [
          ...(currentClass?.teachers ?? []),
          ...(currentClass?.techLeads ?? []),
          ...(currentClass?.facilitators ?? []),
          ...(currentClass?.volunteers ?? []),
        ];

        for (let i = 0; i < listAllEmails?.length; i += 1) {
          let user = await getUserByIdentification(listAllEmails[i]);

          let changed = false;

          if (!user) {
            user = await createUser(listAllEmails[i], [
              isWorkshop
                ? userRoles.ADMIN_CLASS_VACANCY_WORKSHOP
                : userRoles.ADMIN_CLASS_VACANCY_BOOTCAMP,
            ]);
            await requestSignupLink(listAllEmails[i]);
          }

          if (user && user?.account?.status === userStatus.DISABLED) {
            user.account.status = userStatus.PENDING;
            changed = true;
          }

          if (user && isWorkshop && !hasAdminClassWorkshopRole(user)) {
            user.account.roles.push(userRoles.ADMIN_CLASS_VACANCY_WORKSHOP);
            changed = true;
          }

          if (user && !isWorkshop && !hasAdminClassBootcampRole(user)) {
            user.account.roles.push(userRoles.ADMIN_CLASS_VACANCY_BOOTCAMP);
            changed = true;
          }

          const classIds = user?.account?.classIds ?? [];
          if (!classIds?.find((item) => item === classId)) {
            classIds.push(classId);
            changed = true;
          }

          if (changed)
            await patchUser(user?._id ?? "", {
              account: { ...user?.account, classIds },
            });
        }
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [
      currentClass?.facilitators,
      currentClass?.teachers,
      currentClass?.techLeads,
      currentClass?.volunteers,
      dispatch,
      isWorkshop,
    ],
  );

  const removeClassId = useCallback(
    async (classId: string) => {
      dispatch(setLoad(true) as unknown as Action);
      if (oldListAllEmails?.length) {
        const newListAllEmails = [
          ...(currentClass?.teachers ?? []),
          ...(currentClass?.techLeads ?? []),
          ...(currentClass?.facilitators ?? []),
          ...(currentClass?.volunteers ?? []),
        ];

        const remove = oldListAllEmails.filter(
          (item) => !newListAllEmails.includes(item),
        );

        if (remove?.length) {
          for (let i = 0; i < remove?.length; i += 1) {
            const user = await getUserByIdentification(remove[i]);

            const classIds = user?.account?.classIds ?? [];
            const index = classIds?.findIndex((item) => item === classId);

            if (index !== -1) classIds?.splice(index, 1);

            if (classIds?.length === 0) {
              delete user?.account?.classIds;

              if (
                user?.account?.roles?.length &&
                user?.account?.roles?.length > 1
              ) {
                const role = isWorkshop
                  ? userRoles.ADMIN_CLASS_VACANCY_WORKSHOP
                  : userRoles.ADMIN_CLASS_VACANCY_BOOTCAMP;

                const idx = user?.account?.roles?.findIndex(
                  (item) => item === role,
                );

                if (idx > -1) user?.account?.roles?.splice(idx, 1);
              } else if (user?.account?.status)
                user.account.status = userStatus.DISABLED;

              await patchUser(user?._id ?? "", {
                account: { ...user?.account, classIds },
              });
            }
          }
        }
      }
      dispatch(setLoad(false) as unknown as Action);
    },
    [
      currentClass?.facilitators,
      currentClass?.teachers,
      currentClass?.techLeads,
      currentClass?.volunteers,
      dispatch,
      isWorkshop,
      oldListAllEmails,
    ],
  );

  const handleChange = useCallback(
    (
      e,
      props:
        | "code"
        | "title"
        | "description"
        | "classStructure"
        | "numStudents",
    ) => setCurrentClass({ ...currentClass, [props]: e.target.value }),
    [currentClass, setCurrentClass],
  );

  const handleChangeValue = useCallback(
    (
      value,
      props:
        | "teachers"
        | "techLeads"
        | "facilitators"
        | "volunteers"
        | "autoOpen"
        | "autoClose",
    ) => setCurrentClass({ ...currentClass, [props]: value }),
    [currentClass],
  );

  const handleChangeDate = useCallback(
    (value, props: "startDate" | "endDate") => {
      if (props === "startDate")
        setCurrentClass({
          ...currentClass,
          [props]: value?.tz("America/Sao_Paulo").startOf("minutes").format(),
        });
      else
        setCurrentClass({
          ...currentClass,
          [props]: value?.tz("America/Sao_Paulo").startOf("day").format(),
        });
    },
    [currentClass, setCurrentClass],
  );

  const handleChangeAutocomplete = useCallback(
    async (value) => {
      let { skills: newSkills } = currentClass;
      if (value && typeof value !== "string") {
        newSkills = value?.map((item: string) => {
          if (newSkills?.length) {
            const skill = newSkills?.find(
              (innerItem: { key: string }) => innerItem?.key === item,
            );
            return {
              key: item,
              level: skill?.level || 1,
              order: (skill?.order || newSkills?.length) ?? 0,
            };
          }
          return { key: item, level: 1, order: 0 };
        });
      }

      registerAutoComplete(value, autoCompleteType.SKILL);
      setCurrentClass({ ...currentClass, skills: newSkills });
    },
    [currentClass],
  );

  const onSave = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    if (id) {
      await updateClassById(id, currentClass);
      addClassId(id);
      removeClassId(id);
    } else {
      const result = await createClass(currentClass);
      addClassId(result?._id);
    }
    dispatch(setLoad(false) as unknown as Action);
    history.push("/admin-class");
  }, [addClassId, currentClass, dispatch, history, id, removeClassId]);

  return (
    <Layout className="padding-bottom-24">
      <HeaderProfile />
      <div className="grid-column-2-12 grid-row-1 header-steps-company-register-vacancy">
        <p className="view-page">Turmas &gt;&nbsp;</p>
        <p className={`view-step ${id ? "" : "view-bold-16"}`}>
          {id ? "Editar" : "Criar"}
        </p>
        {id && <p className="view-bold-16">&nbsp;&gt;&nbsp;{title}</p>}
      </div>
      <div className="grid-column-3-11 grid-row-2">
        <Card
          className="grid-column-1-8 border-card box admin-class-vacancy-new-edit"
          titleHeader={`${id ? "Editar " : "Nova "} turma`}
        >
          <div className="grid-2-column">
            <Input
              id="code"
              label="Código*"
              type="text"
              value={currentClass?.code ?? ""}
              onChange={(e) => handleChange(e, "code")}
              className="grid-row-1 grid-column-1-2"
            />
            <Input
              id="title"
              label="Nome*"
              type="text"
              value={currentClass?.title ?? ""}
              onChange={(e) => handleChange(e, "title")}
              className="grid-row-1 grid-column-2-3"
              disabled={!currentClass?.code}
            />
            <Select
              className="grid-row-2 grid-column-1-3"
              label="Estrutura*"
              options={Object.keys(StructureOptions).map((key) => ({
                value: key,
                label: StructureOptions[key as keyof typeof StructureOptions],
              }))}
              value={currentClass?.classStructure ?? ""}
              onChange={(e) => handleChange(e, "classStructure")}
              disabled={!currentClass?.code || !currentClass?.title}
            />
            {isWorkshop ? (
              <>
                <InputDateTime
                  label="Horário*"
                  future
                  minDate={!id ? moment() : undefined}
                  views={["year", "month", "date", "hours", "minutes"]}
                  format="DD/MM/YYYY HH:mm"
                  helperText="DD/MM/AAAA HH:MM"
                  value={currentClass?.startDate ?? null}
                  className="grid-row-3 grid-column-1-2 date-input"
                  onChange={(value) => handleChangeDate(value, "startDate")}
                  disabled={
                    !currentClass?.code ||
                    !currentClass?.title ||
                    !currentClass?.classStructure
                  }
                />
                <Input
                  id="vacancies"
                  label="N° de vagas*"
                  type="number"
                  value={currentClass?.numStudents ?? ""}
                  onChange={(e) => handleChange(e, "numStudents")}
                  className="grid-row-3 grid-column-2-3"
                  disabled={
                    !currentClass?.code ||
                    !currentClass?.title ||
                    !currentClass?.classStructure ||
                    !currentClass?.startDate
                  }
                />
              </>
            ) : (
              <>
                <InputDate
                  label="Abertura*"
                  future
                  views={["year", "month", "date"]}
                  format="DD/MM/YYYY"
                  minDate={!id ? moment() : undefined}
                  helperText="DD/MM/AAAA"
                  value={currentClass?.startDate ?? null}
                  className="grid-row-3 grid-column-1-2 date-input"
                  onChange={(value) => handleChangeDate(value, "startDate")}
                  disabled={
                    !currentClass?.code ||
                    !currentClass?.title ||
                    !currentClass?.classStructure
                  }
                />
                <InputDate
                  label="Encerramento"
                  format="DD/MM/YYYY"
                  future
                  minDate={currentClass?.startDate ?? null}
                  helperText="DD/MM/AAAA"
                  value={currentClass?.endDate ?? null}
                  views={["year", "month", "date"]}
                  className="grid-row-3 grid-column-2-3"
                  onChange={(value) => handleChangeDate(value, "endDate")}
                  disabled={
                    !currentClass?.code ||
                    !currentClass?.title ||
                    !currentClass?.classStructure ||
                    !currentClass?.startDate
                  }
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={currentClass?.autoOpen}
                      onChange={() =>
                        handleChangeValue(!currentClass?.autoOpen, "autoOpen")
                      }
                      name="autoOpen"
                    />
                  }
                  label="Abertura automática"
                  className="grid-row-4 grid-column-1-2"
                  disabled={
                    !currentClass?.code ||
                    !currentClass?.title ||
                    !currentClass?.classStructure ||
                    !currentClass?.startDate
                  }
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={currentClass?.autoClose}
                      name="autoClose"
                    />
                  }
                  label="Encerramento automático"
                  className="grid-row-4 grid-column-2-3"
                  onChange={() =>
                    handleChangeValue(!currentClass?.autoClose, "autoClose")
                  }
                  disabled={
                    !currentClass?.code ||
                    !currentClass?.title ||
                    !currentClass?.classStructure ||
                    !currentClass?.startDate ||
                    !currentClass?.endDate
                  }
                />
              </>
            )}
            <InputAutocomplete
              multiple
              label="Habilidades*"
              helperText="Pressione Enter para adicionar"
              className="grid-row-5 grid-column-1-3 skills-input"
              options={skillsOptions ?? [""]}
              getOptionLabel={(option) => option}
              value={
                currentClass?.skills?.length
                  ? currentClass?.skills?.map(
                      (item: { key: string }) => item?.key,
                    )
                  : []
              }
              onChange={handleChangeAutocomplete}
              disabled={
                !currentClass?.code ||
                !currentClass?.title ||
                !currentClass?.classStructure ||
                !currentClass?.startDate ||
                (isWorkshop && !currentClass?.numStudents) ||
                (!isWorkshop && !currentClass?.endDate)
              }
            />
            <InputAutocomplete
              multiple
              label="Professoras"
              helperText="Pressione Enter para adicionar"
              className="grid-row-6 grid-column-1-3 skills-input"
              options={emailUserOptions ?? [""]}
              getOptionLabel={(option) => option}
              value={currentClass?.teachers ?? []}
              onChange={(value) =>
                handleChangeValue(
                  typeof value === "string"
                    ? value?.trim()
                    : value?.map((item: string) => item?.toLowerCase().trim()),
                  "teachers",
                )
              }
              disabled={
                !currentClass?.code ||
                !currentClass?.title ||
                !currentClass?.classStructure ||
                !currentClass?.startDate ||
                (isWorkshop && !currentClass?.numStudents) ||
                (!isWorkshop && !currentClass?.endDate) ||
                !currentClass?.skills
              }
            />
            <InputAutocomplete
              multiple
              label="Líderes técnicas"
              helperText="Pressione Enter para adicionar"
              className="grid-row-7 grid-column-1-3 skills-input"
              options={emailUserOptions ?? [""]}
              getOptionLabel={(option) => option}
              value={currentClass?.techLeads ?? []}
              onChange={(value) =>
                handleChangeValue(
                  typeof value === "string"
                    ? value?.trim()
                    : value?.map((item: string) => item?.toLowerCase().trim()),
                  "techLeads",
                )
              }
              disabled={
                !currentClass?.code ||
                !currentClass?.title ||
                !currentClass?.classStructure ||
                !currentClass?.startDate ||
                (isWorkshop && !currentClass?.numStudents) ||
                (!isWorkshop && !currentClass?.endDate) ||
                !currentClass?.skills
              }
            />
            <InputAutocomplete
              multiple
              label="Facilitadoras"
              helperText="Pressione Enter para adicionar"
              className="grid-row-8 grid-column-1-3 skills-input"
              options={emailUserOptions ?? [""]}
              getOptionLabel={(option) => option}
              value={currentClass?.facilitators ?? []}
              onChange={(value) =>
                handleChangeValue(
                  typeof value === "string"
                    ? value?.trim()
                    : value?.map((item: string) => item?.toLowerCase().trim()),
                  "facilitators",
                )
              }
              disabled={
                !currentClass?.code ||
                !currentClass?.title ||
                !currentClass?.classStructure ||
                !currentClass?.startDate ||
                (isWorkshop && !currentClass?.numStudents) ||
                (!isWorkshop && !currentClass?.endDate) ||
                !currentClass?.skills
              }
            />
            <InputAutocomplete
              multiple
              label="Voluntárias"
              helperText="Pressione Enter para adicionar"
              className="grid-row-9 grid-column-1-3 skills-input"
              options={emailUserOptions ?? [""]}
              getOptionLabel={(option) => option}
              value={currentClass?.volunteers ?? []}
              onChange={(value) =>
                handleChangeValue(
                  typeof value === "string"
                    ? value?.trim()
                    : value?.map((item: string) => item?.toLowerCase().trim()),
                  "volunteers",
                )
              }
              disabled={
                !currentClass?.code ||
                !currentClass?.title ||
                !currentClass?.classStructure ||
                !currentClass?.startDate ||
                (isWorkshop && !currentClass?.numStudents) ||
                (!isWorkshop && !currentClass?.endDate) ||
                !currentClass?.skills
              }
            />
            <TextArea
              id="description"
              label="Descrição*"
              className="grid-row-10 grid-column-1-3 description-input"
              maxLength={500}
              value={currentClass?.description ?? ""}
              onChange={(e) => handleChange(e, "description")}
              disabled={
                !currentClass?.code ||
                !currentClass?.title ||
                !currentClass?.classStructure ||
                !currentClass?.startDate ||
                (isWorkshop && !currentClass?.numStudents) ||
                (!isWorkshop && !currentClass?.endDate) ||
                !currentClass?.skills
              }
            />
          </div>
        </Card>
      </div>
      <Button
        text="Cancelar"
        className="grid-column-7-9 grid-row-3"
        onClick={() => history.push("/admin-class")}
        outline
        appearance="secondary"
      />
      <Button
        text="Salvar"
        className={`${
          !currentClass?._id ? "button-primary" : ""
        } grid-column-9-11 grid-row-3`}
        onClick={onSave}
        disabled={
          !currentClass?.code ||
          !currentClass?.title ||
          !currentClass?.classStructure ||
          !currentClass?.startDate ||
          (isWorkshop && !currentClass?.numStudents) ||
          (!isWorkshop && !currentClass?.endDate) ||
          !currentClass?.skills ||
          !currentClass?.description
        }
      />
    </Layout>
  );
};

export default memo(AdminClassNewEdit);
