import React, { Dispatch, memo, SetStateAction, useCallback } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Checkbox } from "../../../components";
import { ScholarshipType } from "../../../types/constants/User";
import "../style.scss";

interface Props {
  hasScholarship: boolean;
  setHasScholarship: Dispatch<SetStateAction<boolean>>;
  scholarshipType: Array<keyof typeof ScholarshipType>;
  setScholarshipType: Dispatch<
    SetStateAction<Array<keyof typeof ScholarshipType>>
  >;
}

const HasScholarship = ({
  hasScholarship,
  setHasScholarship,
  scholarshipType,
  setScholarshipType,
}: Props): JSX.Element => {
  const onChangeScholarshopType = useCallback(
    (e, value: keyof typeof ScholarshipType) => {
      const newScholarshipType = [...scholarshipType];
      if (e.target.checked) newScholarshipType.push(value);
      else {
        const index = newScholarshipType.findIndex(
          (item: string) => item === value,
        );
        if (index > -1) newScholarshipType?.splice(index, 1);
      }
      setScholarshipType(newScholarshipType);
    },
    [scholarshipType, setScholarshipType],
  );

  return (
    <>
      <div>
        <p className="title-input">Tem bolsa?</p>
        <RadioGroup
          aria-label="hasScholarship"
          name="hasScholarship"
          onChange={(e) =>
            setHasScholarship(e.target.value === "hasScholarship")
          }
        >
          <div>
            <FormControlLabel
              checked={hasScholarship}
              value="hasScholarship"
              control={<Radio />}
              label="Sim"
            />
            <FormControlLabel
              checked={!hasScholarship}
              value="hasntScholarship"
              control={<Radio />}
              label="Não"
            />
          </div>
        </RadioGroup>
      </div>
      {hasScholarship && (
        <div className="flex-col gap-12">
          <p className="title-input">Qual?</p>
          {Object.keys(ScholarshipType)?.map((key) => (
            <Checkbox
              key={Math.random()}
              id={key}
              label={ScholarshipType[key as keyof typeof ScholarshipType]}
              checked={scholarshipType?.includes(
                key as keyof typeof ScholarshipType,
              )}
              onChange={(e) =>
                onChangeScholarshopType(e, key as keyof typeof ScholarshipType)
              }
            />
          ))}
        </div>
      )}
    </>
  );
};

export default memo(HasScholarship);
