import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { toast } from "react-toastify";
import moment from "moment";
import {
  AttachmentPhoto,
  Checkbox,
  Input,
  InputAutocomplete,
  Select,
  InputPassword,
  Button,
  Modal,
} from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { maskDate, maskPhone, registerAutoComplete } from "../../../utils";
import { getAutocomplete, resetPassword } from "../../../services/functions";
import api from "../../../services/api";
import { autoCompleteType } from "../../../types/enumerators";
import { RootState } from "../../../store/reducers";
import { updateUser } from "../../../store/actions/userActions";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const EditUserData = ({ setIsValid }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [ofAge, setOfAge] = useState<boolean>();
  const [workPositionOptions, setWorkPositionOptions] = useState<string[]>();
  const [position, setPosition] = useState("");
  const [businessUnity, setBusinessUnity] = useState("");
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const dispatch = useDispatch();
  const company = useSelector((state: RootState) => state.companyState);
  const user = useSelector((state: RootState) => state.userState);
  const [_user, _setUser] = useState(user);

  useEffect(() => {
    if (!_user?.profile?.name) _setUser({ ...user });
  }, [_user, user]);

  useEffect(() => {
    if (ofAge === undefined && user?.profile?.birthdate)
      setOfAge(
        moment().diff(
          moment(user?.profile?.birthdate, "DD/MM/YYYY"),
          "years",
        ) >= 18,
      );
  }, [ofAge, user?.profile?.birthdate]);

  const loadPositions = useCallback(async () => {
    const workposition = await getAutocomplete(autoCompleteType.WORK_POSITION);

    const workpositionMapped = workposition?.map(
      (option: { name: string }) => option?.name,
    );

    setWorkPositionOptions(workpositionMapped);
  }, []);

  useEffect(() => {
    if (!workPositionOptions) loadPositions();
  }, [loadPositions, workPositionOptions]);

  useEffect(() => {
    if (moment(user?.profile?.birthdate, moment.ISO_8601).isValid())
      _setUser({
        ..._user,
        profile: {
          ..._user?.profile,
          birthdate: moment(user?.profile?.birthdate).format("DD/MM/YYYY"),
        },
      });

    if (user?.account?.tags?.length) {
      const tagBusinessUnity = user?.account?.tags?.find((item: string) =>
        item.includes("business_unity"),
      );
      if (tagBusinessUnity) {
        const arrBusinessUnity = tagBusinessUnity.split(":");
        setBusinessUnity(arrBusinessUnity[1]);
      }

      const tagPosition = user?.account?.tags?.find((item: string) =>
        item.includes("position"),
      );
      if (tagPosition) {
        const arrPosition = tagPosition.split(":");
        setPosition(arrPosition[1]);
      }
    }

    if (
      _user?.profile?.name &&
      _user?.profile?.birthdate &&
      _user?.profile?.birthdate &&
      _user?.profile?.birthdate?.length === 10 &&
      moment(_user?.profile?.birthdate, "DD/MM/YYYY").isValid() &&
      businessUnity &&
      position
    )
      setIsValid(true);
    else setIsValid(false);
  }, [user, setIsValid, _user, businessUnity, position]);

  const handleOnBlur = useCallback(() => {
    if (
      _user?.profile?.name &&
      _user?.profile?.birthdate &&
      _user?.profile?.birthdate &&
      _user?.profile?.birthdate?.length === 10 &&
      moment(_user?.profile?.birthdate, "DD/MM/YYYY").isValid() &&
      businessUnity &&
      position
    ) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(updateUser({ ..._user }) as unknown as Action),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [_user, businessUnity, dispatch, position, setIsValid]);

  const handleChangeEmail = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      _setUser({
        ..._user,
        login: {
          ..._user.login,
          identification: event.target.value.toLowerCase().trim(),
        },
      }),
    [_user],
  );

  const handleSubmit = useCallback(async () => {
    try {
      if (password === confirmPassword) {
        setIsChangePasswordOpen(false);
        const response = await resetPassword(password);

        if (response) {
          toast.success("Senha alterada com sucesso");
          setPassword("");
          setConfirmPassword("");
        }
      } else {
        toast.error("As senhas digitadas não coincidem");
      }
    } catch (err) {
      toast.error("Ocorreu um erro ao alterar a senha");
    }
  }, [confirmPassword, password]);

  const handleChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
    ) =>
      _setUser({
        ..._user,
        profile: { ..._user?.profile, [prop]: event.target.value },
      }),
    [_user],
  );

  const handleChangeDate = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      _setUser({
        ..._user,
        profile: { ..._user?.profile, birthdate: maskDate(event.target.value) },
      }),
    [_user],
  );

  const handleChangePosition = useCallback(
    async (value: string | string[] | null) => {
      registerAutoComplete(value, autoCompleteType.WORK_POSITION);
      const { account } = _user;
      if (account) {
        let { tags: newTags } = account;

        if (newTags && newTags?.length) {
          const index = account?.tags?.findIndex((item: string) =>
            item.includes("position"),
          );
          if (index !== -1) newTags[index] = `position:${value}`;
          else newTags.push(`position:${value}`);
        } else newTags = [`position:${value}`];

        setPosition(value as string);

        _setUser({
          ..._user,
          account: {
            ...account,
            tags: newTags,
          },
        });
        dispatch(
          setNextButton(() =>
            dispatch(
              updateUser({
                ..._user,
                account: {
                  ...account,
                  tags: newTags,
                },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      }
    },
    [_user, dispatch],
  );

  const handleChangeBusinessUnity = useCallback(
    (
      e: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      const { account } = _user;
      if (account) {
        let { tags: newTags } = account;

        if (newTags && newTags?.length) {
          const index = account?.tags?.findIndex((item: string) =>
            item.includes("business_unity"),
          );
          if (index !== -1)
            newTags[index] = `business_unity:${e.target.value as string}`;
          else newTags.push(`business_unity:${e.target.value as string}`);
        } else newTags = [`business_unity:${e.target.value as string}`];

        setBusinessUnity(e.target.value as string);

        _setUser({
          ..._user,
          account: {
            ...account,
            tags: newTags,
          },
        });
      }
    },
    [_user],
  );

  const onChangeImage = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const fileList = e.target.files;

      if (!fileList) return;

      const data = new FormData();

      data?.append("file", fileList[0]);

      api
        .post("/fileUpload", data)
        .then((result) => {
          _setUser({
            ..._user,
            profile: { ..._user?.profile, photo: result?.data?.file_url.url },
          });
          dispatch(
            setNextButton(() =>
              updateUser({
                ..._user,
                profile: {
                  ..._user?.profile,
                  photo: result?.data?.file_url.url,
                },
              }),
            ) as unknown as Action,
          );
        })
        .catch(() => {
          toast.error("Ocorreu um erro durante o upload do arquivo");
        });
    },
    [_user, dispatch],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="grid-1-column-account display-block">
      <AttachmentPhoto
        photo={_user?.profile?.photo}
        type="foto"
        onChange={onChangeImage}
        className="grid-row-1 grid-column-1-4"
      />
      <div className="grid-row-2 width-100 margin-right-32">
        {width < 991 && <p className="p-mobile">E-mail</p>}
        <Input
          id="email"
          label={width < 991 ? "" : "E-mail"}
          type="text"
          onChange={handleChangeEmail}
          value={_user?.login?.identification}
          onBlur={handleOnBlur}
          className="grid-row-2  width-100 margin-right-32"
        />

        <p
          className="account-change-password-button margin-left-5"
          onClick={() => setIsChangePasswordOpen(true)}
        >
          Alterar senha
        </p>

        {isChangePasswordOpen && (
          <Modal
            open={isChangePasswordOpen}
            onClose={() => setIsChangePasswordOpen(false)}
            textHeader="Alterar senha"
            bodyClassName="flex-col align-items modal-change-password"
          >
            <h1 className="width-100 text-center">Alterar senha</h1>
            <div className="flex-col">
              <InputPassword
                label="Senha"
                helperText="Digite a nova senha"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onBlur={handleOnBlur}
                className={`${width > 900 ? "width-300" : "max-width-300"}`}
              />
              <InputPassword
                label="Senha"
                helperText="Digite novamente a nova senha"
                value={confirmPassword}
                onChange={(item) => setConfirmPassword(item.target.value)}
                className={`${
                  width > 900 ? "width-300" : "max-width-300"
                } margin-top-15`}
              />
            </div>

            <div className="area-buttons justify-center flex-row margin-top-20-mobile">
              <Button
                text="Cancelar"
                className="width-30 margin-right-10"
                onClick={() => setIsChangePasswordOpen(false)}
                outline
                appearance="secondary"
              />
              <Button
                text="Alterar"
                className={`width-30 ${
                  width > 990 ? "margin-left-10" : "margin-left-0-mobile"
                }`}
                onClick={() => handleSubmit()}
              />
            </div>
          </Modal>
        )}
      </div>
      {width < 991 && <p className="p-mobile margin-top-20">Seu nome</p>}
      <Input
        id="name"
        label={width < 991 ? "" : "Seu nome"}
        helperText="Por favor, coloque seu nome completo."
        type="text"
        value={_user?.profile?.name ?? ""}
        onChange={(e) => handleChange(e, "name")}
        onBlur={handleOnBlur}
        className="grid-row-2 width-100 margin-right-32"
      />
      <div className="grid-row-2 width-100 margin-right-32">
        {width < 991 && (
          <p className="p-mobile margin-top-20-mobile">Data de nascimento</p>
        )}
        <Input
          className="width-100 margin-right-32"
          id="birthdate"
          label={width < 991 ? "" : "DD/MM/AAAA"}
          type="text"
          value={_user?.profile?.birthdate ?? ""}
          maxLength={10}
          onChange={handleChangeDate}
          onBlur={handleOnBlur}
          disabled={!_user?.profile?.name}
        />
        <Checkbox
          id="ofAge"
          label="Sou maior de 18 anos"
          checked={
            ofAge === undefined
              ? moment().diff(
                  moment(_user?.profile?.birthdate, "DD/MM/YYYY"),
                  "years",
                ) >= 18
              : ofAge
          }
          onChange={() =>
            moment().diff(
              moment(_user?.profile?.birthdate, "DD/MM/YYYY"),
              "years",
            ) >= 18
              ? setOfAge(!ofAge)
              : {}
          }
          disabled={!_user?.profile?.name}
          className="text-checkbox"
        />
      </div>
      {width < 991 && <p className="p-mobile margin-top-5">Celular</p>}
      <Input
        id="telephone"
        label={width < 991 ? "" : "Celular"}
        type="text"
        value={maskPhone(_user?.profile?.telephone ?? "")}
        onChange={(e) => handleChange(e, "telephone")}
        onBlur={handleOnBlur}
        maxLength={14}
        className="grid-row-3 width-100 margin-right-32"
        disabled={
          !ofAge ||
          !_user?.profile?.name ||
          !_user?.profile?.birthdate ||
          (_user?.profile?.birthdate &&
            _user?.profile?.birthdate?.length !== 10) ||
          !moment(_user?.profile?.birthdate, "DD/MM/YYYY").isValid()
        }
      />
      {width < 991 && (
        <p className="p-mobile margin-top-20-mobile">Unidade de negócio</p>
      )}
      <Select
        id="company"
        label={width < 991 ? "" : "Unidade de negócio"}
        onChange={handleChangeBusinessUnity}
        onBlur={handleOnBlur}
        value={
          _user?.account?.tags
            ?.find((item: string | string[]) => item.includes("business_unity"))
            ?.replace("business_unity:", "") ?? ""
        }
        options={company?.profile?.location?.map(
          (item: { businessUnity: string }) => ({
            value: item.businessUnity,
            label: item.businessUnity,
          }),
        )}
        className="grid-row-3 width-100 margin-right-32"
        disabled={
          !ofAge ||
          !_user?.profile?.name ||
          !_user?.profile?.birthdate ||
          (_user?.profile?.birthdate &&
            _user?.profile?.birthdate?.length !== 10) ||
          !moment(_user?.profile?.birthdate, "DD/MM/YYYY").isValid()
        }
      />
      {width < 991 && <p className="p-mobile margin-top-20-mobile">Cargo</p>}
      <InputAutocomplete
        label={width < 991 ? "" : "Cargo"}
        helperText="Pressione Enter para adicionar"
        value={position}
        onBlur={(e) => handleChangePosition(e.target.value)}
        className="grid-row-3  width-100 margin-right-32"
        options={workPositionOptions ?? [""]}
        getOptionLabel={(option) => option}
        disabled={
          !ofAge ||
          !_user?.profile?.name ||
          !_user?.profile?.birthdate ||
          (_user?.profile?.birthdate &&
            _user?.profile?.birthdate?.length !== 10) ||
          !moment(_user?.profile?.birthdate, "DD/MM/YYYY").isValid() ||
          !businessUnity
        }
      />
    </div>
  );
};

export default memo(EditUserData);
