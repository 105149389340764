import React, { memo, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Company, User } from "../../types/interfaces";
import Card from "../Card";

interface Props {
  renderCardSearch: JSX.Element[] | null;
  search?: User[] | Company[] | null;
  typeSearch: "people" | "company";
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SearchResult = ({
  renderCardSearch,
  search,
  typeSearch,
}: Props): JSX.Element => {
  const history = useHistory();

  const query = useQuery();
  const searchParams = query.get("params");

  const location: {
    state: {
      typeSearch: string;
    };
  } = useLocation();

  const isPeopleSearch = useMemo(() => typeSearch === "people", [typeSearch]);

  return location?.state?.typeSearch !==
    (isPeopleSearch ? "company" : "people") ? (
    <Card
      removeTitleBorder
      resultSearch
      hideBorderRadius
      className={`header-search ${
        location?.state?.typeSearch !== "all" && "remove-border-bottom"
      } ${location?.state?.typeSearch === "all" ? "margin-bottom-24" : ""}`}
      bodyClassName={`${
        location?.state?.typeSearch === "all" ? "padding-0" : ""
      }`}
      titleHeader={
        location?.state?.typeSearch === typeSearch
          ? searchParams ?? ""
          : isPeopleSearch
          ? "Pessoas"
          : "Empresas"
      }
      subtitleHeader={
        location?.state?.typeSearch === typeSearch
          ? `${search?.length ?? 0} resultados`
          : ""
      }
    >
      {renderCardSearch}
      {!search?.length ? (
        <p className="title-unavaliable">
          {`Não há ${
            isPeopleSearch ? "nenhum usuário" : "nenhuma empresa"
          } que corresponde a busca`}
        </p>
      ) : (
        location?.state?.typeSearch !== typeSearch &&
        search?.length > 2 && (
          <>
            <div className="line-divider-midia margin-top-10" />
            <div className="more-about-company">
              <button
                type="button"
                className="more-about-button search-more"
                onClick={() =>
                  history.replace({
                    pathname: "/search",
                    search: `params=${searchParams ?? ""}`,
                    state: { typeSearch },
                  })
                }
              >
                Ver todos os resultados
              </button>
            </div>
          </>
        )
      )}
    </Card>
  ) : (
    <div />
  );
};

export default memo(SearchResult);
