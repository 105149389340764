import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ClassVacancy } from "reprograma-types/dist/interface";
import { useAuth } from "../../auth";
import {
  getActiveUserClassVacancySubscription,
  getClassVacancyById,
} from "../../services/functions";
import { setCourseSubscription } from "../../store/actions/courseActions";
import { RootState } from "../../store/reducers";
import { ClassVacancySubscription } from "../../types/interfaces";
import { hasCandidateRole } from "../../utils";
import { Attachment, FormPresentation, Intro, Submit } from "./Steps";
import "./style.scss";

const CourseAttachment = (): JSX.Element => {
  const [page, setPage] = useState(1);
  const [existForm, setExistForm] = useState(false);
  const [classVacancy, setClassVacancy] = useState<ClassVacancy>();
  const { signOut } = useAuth();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const courseSubscription = useSelector(
    (state: RootState) => state.courseState,
  );

  useEffect(() => {
    if (user?._id && !hasCandidateRole(user)) signOut();
  }, [signOut, user]);

  const getSubscription = useCallback(async () => {
    const subscription = await getActiveUserClassVacancySubscription(user._id);
    if (subscription?.data?.length) {
      const dataSubscription: ClassVacancySubscription =
        subscription?.data?.[0];
      dispatch(setCourseSubscription(dataSubscription) as unknown as Action);
    }
  }, [user._id, dispatch]);

  useEffect(() => {
    if (!courseSubscription?._id) getSubscription();
  }, [courseSubscription?._id, getSubscription]);

  const getClassVacancy = useCallback(async (classVacancyId: string) => {
    const response = await getClassVacancyById(classVacancyId);
    if (response?.data) setClassVacancy(response?.data);
  }, []);

  useEffect(() => {
    if (courseSubscription?.classVacancyId)
      getClassVacancy(courseSubscription?.classVacancyId);
  }, [courseSubscription?.classVacancyId, getClassVacancy]);

  const renderPages = useMemo(() => {
    switch (page) {
      case 1:
        return <Intro setPage={setPage} />;

      case 2:
        return <Attachment setPage={setPage} />;

      case 3:
        return (
          <FormPresentation
            subscription={courseSubscription}
            classVacancy={classVacancy}
            setExistForm={setExistForm}
            setPage={setPage}
          />
        );

      case 4:
        return <Submit existForm={existForm} />;

      default:
        return <div />;
    }
  }, [courseSubscription, classVacancy, existForm, page]);

  return renderPages;
};

export default memo(CourseAttachment);
