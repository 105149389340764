import axios, { AxiosRequestConfig } from "axios";
import { encode } from "../utils/crypto";
import { isDevelopment, useCrypto, useLocalhost } from "./api";

export const useLogs = true;

const historicApi = useLogs
  ? axios.create({
      baseURL:
        isDevelopment && useLocalhost
          ? "http://localhost:3001"
          : isDevelopment
          ? "https://develop.historic.reprograma.com.br"
          : "https://historic.reprograma.com.br",
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    })
  : null;

if (useCrypto) {
  const requestHandler = (request: AxiosRequestConfig) => {
    if (!(request.data instanceof FormData)) {
      request.data = encode(request.data);
    }
    return request;
  };
  historicApi?.interceptors.request.use((request) => requestHandler(request));
}

export default historicApi;
