import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Footer, Header, Layout } from "../../../components";
import { createCourseSubscriptionLog } from "../../../services/historicFunctions";
import { RootState } from "../../../store/reducers";
import { CourseSubscriptionState } from "../../../types/constants/Historic";
import { CourseSubscriptionAction } from "../../../types/enumerators/actions/CourseSubscriptionAction";
import Loading from "../../Loading";
import "../style.scss";

interface Props {
  existForm?: boolean;
}

const Submit = ({ existForm }: Props): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);

  const [loading, setLoading] = useState(false);

  return !loading ? (
    <div className="container-subimted">
      <Layout className="container-layout">
        <Header />
        <div className="content-submit">
          <div>
            <h1 className="title">Muito obrigada!</h1>
            <p className="paragraph">
              Recebemos seu {existForm ? "formulário" : "vídeo"}.
            </p>
          </div>
          <Button
            text="Ver página do curso"
            className="button-view-course"
            onClick={() => {
              setLoading(true);
              createCourseSubscriptionLog(
                user._id,
                CourseSubscriptionAction.COURSE_SUBSCRIPTION_DONE,
                CourseSubscriptionState.ATTACHMENT_SUBMITTED,
                CourseSubscriptionState.DONE,
              );
              setTimeout(() => {
                window.open("https://www.reprograma.com.br/#courses", "_self");
              }, 500);
            }}
          />
        </div>
      </Layout>
      <div className="content-footer">
        <Footer />
      </div>
    </div>
  ) : (
    <Loading />
  );
};
export default memo(Submit);
