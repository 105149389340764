import React, { memo, useState, useEffect, useCallback } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import moment from "moment-timezone";
import { Button, CardRegister } from "../../../components";
import {
  getAvailableWorkshop,
  updateClassVacancySubscriptionById,
} from "../../../services/functions";
import {
  ClassVacancy,
  ClassVacancyStages,
  ClassVacancySubscription,
  User,
} from "../../../types/interfaces";
import { userGenderIdentity } from "../../../types/enumerators";
import { createCourseSubscriptionLog } from "../../../services/historicFunctions";
import { CourseSubscriptionState } from "../../../types/constants/Historic";
import { CourseSubscriptionAction } from "../../../types/enumerators/actions/CourseSubscriptionAction";

interface Props {
  user: User;
  classVacancy: ClassVacancy;
  subscription: ClassVacancySubscription;
  findStageWorkshop: ClassVacancyStages;
}

interface IAvailableWorkshop {
  _id: string;
  code: string;
  title: string;
  startDate: string;
  numStudents: number;
  subscriptions?: number;
}

const ChoiceWorkshopClass = ({
  user,
  classVacancy,
  subscription,
  findStageWorkshop,
}: Props): JSX.Element => {
  const [workshopList, setWorkshopList] = useState<IAvailableWorkshop[]>();
  const [listChoiceWorkshop, setListChoiceWorkshop] = useState<string[]>([]);

  const getClassWorkshop = useCallback(async () => {
    const genderIdentityUser = user?.profile?.genderIdentity;
    const workshops = await getAvailableWorkshop(classVacancy?._id ?? "");

    const filteredWorkshops = workshops?.filter(
      (item) => item?.numStudents > (item?.subscriptions ?? 0),
    );

    if (
      genderIdentityUser === userGenderIdentity.TRANSVESTITE ||
      genderIdentityUser === userGenderIdentity.TRANSGENDER_MALE ||
      genderIdentityUser === userGenderIdentity.TRANSGENDER_FEMALE
    )
      setWorkshopList([...(workshops ?? [])]);
    else setWorkshopList([...(filteredWorkshops ?? [])]);
  }, [classVacancy?._id, user]);

  useEffect(() => {
    if (classVacancy?._id) getClassWorkshop();
  }, [classVacancy?._id, getClassWorkshop]);

  const handleChangeChecbox = useCallback(
    (value) => {
      const workshopSelected = value;

      if (
        !listChoiceWorkshop?.includes(workshopSelected) &&
        listChoiceWorkshop?.length < 2
      ) {
        setListChoiceWorkshop([...listChoiceWorkshop, workshopSelected]);
        return;
      }

      const updatedList = listChoiceWorkshop?.filter(
        (workshop) => workshop !== workshopSelected,
      );
      setListChoiceWorkshop(updatedList);
    },
    [listChoiceWorkshop],
  );

  const formatDateWorkshop = (
    title: string,
    startDate: string | number | Date,
  ) => {
    const classWorkshopStartDate = new Date(startDate);

    // TODO: remover horário fixo
    // const formatDate = moment(classWorkshopStartDate)
    //   .tz("America/Sao_Paulo")
    //   .format("DD/MM [às] HH:mm");
    const formatDate = `${moment(classWorkshopStartDate).format(
      "DD/MM",
    )} às 09:00`;

    return `${title} - ${formatDate}`;
  };

  const onSaveChoiceWorkshopList = useCallback(async () => {
    if (classVacancy && user?._id) {
      let optionsClassWorkshop: { order: number; classId: string }[];

      if (listChoiceWorkshop && listChoiceWorkshop?.length < 2)
        optionsClassWorkshop = workshopList?.map((item) => ({
          order: listChoiceWorkshop?.includes(item?._id) ? 0 : 1,
          classId: item._id,
        })) as { order: number; classId: string }[];
      else
        optionsClassWorkshop = listChoiceWorkshop?.map((item) => ({
          order: listChoiceWorkshop?.indexOf(item),
          classId: item,
        }));

      const firstChoiceWorkshopClassId =
        optionsClassWorkshop.length &&
        optionsClassWorkshop?.find((item) => item?.order === 0)?.classId;

      const stages = subscription?.stages?.map((item) => {
        if (item?.stageId === findStageWorkshop?.stage_id)
          return {
            ...item,
            options: optionsClassWorkshop,
            workshopClass: firstChoiceWorkshopClassId,
          };

        return {
          ...item,
        };
      });

      createCourseSubscriptionLog(
        user?._id,
        CourseSubscriptionAction.COURSE_SUBSCRIPTION_WORKSHOP_CHOICE_CLASS_SUBMITTED,
        CourseSubscriptionState.WORKSHOP_CHOICE_CLASS_SUBMISSION,
        CourseSubscriptionState.WORKSHOP_CHOICE_CLASS_SUBMITTED,
      );

      await updateClassVacancySubscriptionById(subscription?._id ?? "", {
        stages,
      });
      window.location.reload();
    }
  }, [
    classVacancy,
    user?._id,
    listChoiceWorkshop,
    workshopList,
    subscription,
    findStageWorkshop,
  ]);

  return (
    <>
      <CardRegister
        titleHeader="Sua inscrição"
        className="margin-top-16 margin-mobile-register border-card grid-column-5-12 grid-column-1-11-mobile choice-class"
      >
        <div>
          <div className="information-title">
            Olá! Para dar continuidade ao seu processo, chegou a hora de
            escolher a <b>Oficina</b> que ocorrerá no horário de sua
            preferência.
          </div>
          {workshopList && workshopList?.length > 0 ? (
            workshopList?.length <= 2 ? (
              <div>
                <p>
                  Selecione uma opção de oficina abaixo, você só pode escolher
                  uma.
                </p>
                <div>
                  <RadioGroup>
                    {workshopList?.map(({ _id, title, startDate }) => (
                      <FormControlLabel
                        key={Math.random()}
                        value={_id}
                        control={<Radio />}
                        label={formatDateWorkshop(title, startDate)}
                        className="margin-right-32"
                        checked={listChoiceWorkshop?.includes(_id)}
                        onChange={(e) =>
                          setListChoiceWorkshop([e.target.value])
                        }
                      />
                    ))}
                  </RadioGroup>
                </div>
              </div>
            ) : (
              <div>
                <p>
                  Você só pode escolher duas opções, sendo a 2ª apenas uma
                  reseva caso seja necessário algum ajuste.
                </p>
                <div className="input-list-checkbox">
                  {workshopList?.map(({ _id, title, startDate }) => (
                    <>
                      <input
                        key={Math.random()}
                        checked={listChoiceWorkshop?.includes(_id)}
                        type="checkbox"
                        id={_id}
                        name={title}
                        value={_id}
                        onChange={(e) => handleChangeChecbox(e.target.value)}
                      />
                      <label
                        htmlFor={_id}
                        data-position={
                          (listChoiceWorkshop?.indexOf(_id) ?? -1) + 1
                        }
                        className={`label-default ${
                          listChoiceWorkshop?.includes(_id) && "label-checked"
                        }`}
                      >
                        {formatDateWorkshop(title, startDate)}
                      </label>
                    </>
                  ))}
                </div>
              </div>
            )
          ) : (
            <p className="error">
              Ops! Ocorreu um problema, não foram encontradas opções de oficinas
              disponíveis. Favor entrar em contato com{" "}
              <a href="mailto:selecao@reprograma.com.br">
                selecao@reprograma.com.br
              </a>
              .
            </p>
          )}
        </div>
      </CardRegister>
      {workshopList?.length && (
        <Button
          text="Salvar"
          className="width-100 grid-column-1-11-mobile grid-row-2 grid-column-10-12 margin-bottom-48 button-course"
          onClick={onSaveChoiceWorkshopList}
          disabled={
            workshopList?.length >= 3
              ? listChoiceWorkshop?.length <= 1
              : !listChoiceWorkshop?.length
          }
        />
      )}
    </>
  );
};

export default memo(ChoiceWorkshopClass);
