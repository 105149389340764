/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineReducers } from "redux";
import { connectRouter, LOCATION_CHANGE } from "connected-react-router";
import reduceReducers from "reduce-reducers";
import { History } from "history";
import { companyReducer } from "./companyReducer";
import { nextButtonReducer } from "./nextButtonReducer";
import { stepRegisterReducer } from "./stepsReducer";
import { userReducer } from "./userReducer";
import { notificationsReducer } from "./notificationsReducer";
import { configurationsReducer } from "./configurationsReducer";
import { courseReducer } from "./courseReducer";
import { routeReducer, routeReducerInit } from "./routeReducer";
import { employmentReducer } from "./employmentReducer";

const combinedReducer = (history: History<unknown>) =>
  combineReducers({
    router: connectRouter(history),
    userState: userReducer,
    configurationsState: configurationsReducer,
    companyState: companyReducer,
    nextButtonState: nextButtonReducer,
    stepRegisterState: stepRegisterReducer,
    notificationsState: notificationsReducer,
    courseState: courseReducer,
    routeState: routeReducerInit,
    employmentState: employmentReducer,
  });

const crossSliceReducer = (state: any, action: any) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        routeState: routeReducer(state.routeState, action, state.userState),
      };

    default:
      return state;
  }
};

export const rootReducer = (history: History<unknown>) =>
  reduceReducers(combinedReducer(history), crossSliceReducer);

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;
