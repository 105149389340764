/* eslint-disable no-await-in-loop */
import React, { memo, useCallback, useState } from "react";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { Card } from "../../../components";
import { ClassVacancy } from "../../../types/interfaces";
import {
  classVacancyStatus,
  classVacancySubscriptionStatus,
} from "../../../types/enumerators";
import { setLoad } from "../../../store/actions/configurationsActions";
import {
  filterClassVacancySubscription,
  updateClassVacancyById,
} from "../../../services/functions";
import { Check } from "../../../../assets/icons";
import { IconVideo, Pause, Unblock } from "../../../../assets/customIcons";
import "../style.scss";

interface Props {
  classVacancy: ClassVacancy;
  reload: () => void;
}

const Stages = ({ classVacancy, reload }: Props): JSX.Element => {
  const [count, setCount] = useState<{ [x: string]: string }>();
  const dispatch = useDispatch();

  const getTotalValue = useCallback(
    async (stageId) => {
      const totalStage = (await filterClassVacancySubscription(
        classVacancy?._id ?? "",
        { hasStageId: stageId ?? "" },
        true,
      )) as { size?: number }[];

      const total = totalStage?.length ? totalStage[0]?.size : 0;
      return total;
    },
    [classVacancy?._id],
  );

  const getApprovedValue = useCallback(
    async (idx) => {
      let approvedStage: { size?: number }[];

      if (
        !!classVacancy?.stages &&
        classVacancy?.stages?.length &&
        idx < (classVacancy?.stages?.length ?? 0) - 1
      )
        approvedStage = (await filterClassVacancySubscription(
          classVacancy?._id ?? "",
          { hasStageId: classVacancy?.stages[idx + 1]?.stage_id },
          true,
        )) as { size?: number }[];
      else
        approvedStage = (await filterClassVacancySubscription(
          classVacancy?._id ?? "",
          { status: classVacancySubscriptionStatus.APPROVED },
          true,
        )) as { size?: number }[];

      const totalApproved = approvedStage?.length ? approvedStage[0]?.size : 0;
      return totalApproved;
    },
    [classVacancy?._id, classVacancy?.stages],
  );

  const getCount = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const counts: { [x: string]: string } = {};
    for (let i = 0; i < (classVacancy?.stages?.length ?? 0); i += 1) {
      const total = await getTotalValue(classVacancy?.stages[i]?.stage_id);
      const totalApproved = await getApprovedValue(i);
      counts[
        classVacancy?.stages[i]?.stage_id ?? ""
      ] = `${totalApproved} / ${total}`;
    }
    setCount(counts);
    dispatch(setLoad(false) as unknown as Action);
  }, [classVacancy?.stages, dispatch, getApprovedValue, getTotalValue]);

  const onChangeStatus = useCallback(
    async (stageId, status) => {
      dispatch(setLoad(true) as unknown as Action);

      const newClassVacancy = { ...classVacancy };
      const stageIndex =
        newClassVacancy?.stages?.findIndex(
          (item) => item?.stage_id === stageId,
        ) ?? -1;

      if (stageIndex !== -1 && newClassVacancy && newClassVacancy?.stages)
        newClassVacancy.stages[stageIndex].status = status;

      await updateClassVacancyById(newClassVacancy?._id ?? "", {
        stages: newClassVacancy?.stages,
      });

      reload();
      dispatch(setLoad(false) as unknown as Action);
    },
    [classVacancy, dispatch, reload],
  );

  return (
    <Card
      titleHeader="Etapas"
      className="grid-column-1-7 grid-row-3"
      onUpdate={getCount}
    >
      {classVacancy &&
        classVacancy?.stages &&
        classVacancy?.stages?.length > 0 &&
        classVacancy?.stages?.map((item, idx) => (
          <div
            key={Math.random()}
            className={`step connect ${
              idx < (classVacancy?.stages?.length ?? 0) - 1
                ? "margin-bottom-24"
                : "margin-bottom-0"
            }`}
          >
            {item?.status === classVacancyStatus.FINISHED ? (
              <div className="svg-connect line">
                <Check />
              </div>
            ) : item?.status === classVacancyStatus.ACTIVE ? (
              <div className="icon-step-vacancy-subscription active">
                {idx + 1}
              </div>
            ) : (
              <div className="icon-step-vacancy-subscription">{idx + 1}</div>
            )}
            <div className="flex space-between align-items width-100">
              <span className="margin-right-24 title-step-view">
                {item?.title}
              </span>
              <div className="divider-steps" />
              <div className="flex">
                {count && (
                  <span className="count-stages">{count[item?.stage_id]}</span>
                )}
                <Tooltip title="Ativar">
                  <div
                    aria-label="active"
                    role="button"
                    tabIndex={0}
                    className={`button-attachment-media ${
                      item?.status === classVacancyStatus.ACTIVE &&
                      "disabled-btn"
                    }`}
                    onClick={() => {
                      if (item?.status !== classVacancyStatus.ACTIVE)
                        onChangeStatus(
                          item?.stage_id,
                          classVacancyStatus.ACTIVE,
                        );
                    }}
                  >
                    <IconVideo className="steps-icons cursor-pointer" />
                  </div>
                </Tooltip>
                <Tooltip title="Pausar">
                  <div
                    aria-label="stop"
                    role="button"
                    tabIndex={0}
                    className={`button-attachment-media cursor-pointer ${
                      item?.status !== classVacancyStatus.ACTIVE &&
                      "disabled-btn"
                    }`}
                    onClick={() => {
                      if (item?.status === classVacancyStatus.ACTIVE)
                        onChangeStatus(
                          item?.stage_id,
                          classVacancyStatus.STOPPED,
                        );
                    }}
                  >
                    <Pause className="steps-icons cursor-pointer" />
                  </div>
                </Tooltip>
                <Tooltip title="Finalizar">
                  <div
                    aria-label="finish"
                    role="button"
                    tabIndex={0}
                    className={`button-attachment-media cursor-pointer ${
                      item?.status !== classVacancyStatus.ACTIVE &&
                      "disabled-btn"
                    }`}
                    onClick={() => {
                      if (item?.status === classVacancyStatus.ACTIVE)
                        onChangeStatus(
                          item?.stage_id,
                          classVacancyStatus.FINISHED,
                        );
                    }}
                  >
                    <Unblock className="steps-icons" />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
    </Card>
  );
};

export default memo(Stages);
