import React, {
  ChangeEvent,
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Checkbox, EditSocialMedias, Input } from "../../../components";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { updateUser } from "../../../store/actions/userActions";
import { RootState } from "../../../store/reducers";
import { maskPhone } from "../../../utils";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const EditContact = ({ setIsValid }: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [sameWhatsApp, setSameWhatsApp] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [_profile, _setProfile] = useState(user?.profile);

  useEffect(() => {
    if (user?.profile?.name && !_profile?.name) {
      _setProfile({ ...user?.profile });
    }
  }, [_profile?.name, user?.profile, user?.profile?.name]);

  const handleOnBlur = useCallback(() => {
    if (_profile?.telephone) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              profile: { ..._profile },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [_profile, dispatch, setIsValid, user]);

  const handleChange = useCallback(
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      prop: string,
      value?: boolean,
    ) => {
      if (value !== undefined) {
        _setProfile({ ..._profile, [prop]: value });
      } else _setProfile({ ..._profile, [prop]: e.target.value });
    },
    [_profile],
  );

  const handleChangeWhatsapp = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleChange(e, "isWhatsapp", !_profile?.isWhatsapp);
      if (_profile?.telephone) {
        setIsValid(true);
        dispatch(
          setNextButton(() =>
            dispatch(
              updateUser({
                ...user,
                profile: { ..._profile },
              }) as unknown as Action,
            ),
          ) as unknown as Action,
        );
      } else setIsValid(false);
    },
    [_profile, dispatch, handleChange, setIsValid, user],
  );

  const handleChangeCheckbox = useCallback(() => {
    setSameWhatsApp(!sameWhatsApp);
    if (_profile?.telephone) {
      setIsValid(true);
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              profile: { ..._profile },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    } else setIsValid(false);
  }, [_profile, dispatch, sameWhatsApp, setIsValid, user]);

  const updateSocialMedia = useCallback(
    (value) => {
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              profile: {
                ..._profile,
                socialMedias: value,
              },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_profile, dispatch, user],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="account-edit-contact">
      <div className="flex gap-48 margin-bottom-48 flex-col-mobile gap-20-mobile margin-bottom-20-mobile">
        <div>
          {width < 991 && <p className="p-mobile">WhatsApp</p>}
          <Input
            value={maskPhone(_profile?.telephone ?? "")}
            maxLength={14}
            onChange={(e) => handleChange(e, "telephone")}
            onBlur={handleOnBlur}
            type="text"
            label="WhatsApp*"
            className="width-190 width-100-responsive"
          />
          <Checkbox
            id="whatsapp"
            label="Não tenho WhatsApp"
            checked={!_profile?.isWhatsapp}
            onChange={handleChangeWhatsapp}
          />
        </div>
        <div>
          {width < 991 && <p className="p-mobile margin-contact">Telegram</p>}
          <Input
            value={maskPhone(_profile?.telegram ?? "")}
            maxLength={14}
            onChange={(e) => handleChange(e, "telegram")}
            onBlur={handleOnBlur}
            type="text"
            label="Telegram"
            disabled={!_profile?.telephone || sameWhatsApp}
            className="width-190 width-100-responsive"
          />
          <Checkbox
            id="sameWhatsApp"
            label="Mesmo número do WhatsApp"
            checked={sameWhatsApp}
            onChange={handleChangeCheckbox}
            disabled={!_profile?.telephone}
          />
        </div>
      </div>

      <EditSocialMedias
        className="social"
        setIsValid={setIsValid}
        socialMedias={_profile?.socialMedias}
        updateSocialMedia={updateSocialMedia}
      />
    </div>
  );
};

export default memo(EditContact);
