import React, {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  TableRow,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Card, Select, Table as TableComponent } from "../../../components";
import { Search } from "../../../../assets/customIcons";
import { AdminClassViewStatus } from "../../../types/constants/Admin";
import {
  userBootcampsStatus,
  userGenderIdentity,
  userRace,
} from "../../../types/enumerators";
import { ProfileGender, ProfileRace } from "../../../types/constants/User";
import { CourseStatus } from "../../../types/constants/Course";
import {
  Class,
  Comments as CommentProps,
  GeneralComment,
  UserAccount,
  UserBootcamps,
  UserCurriculum,
} from "../../../types/interfaces";
import { Region } from "../../../services/functions";
import "../style.scss";

interface Filters {
  name?: string | null;
  email?: string | null;
  genderIdentity?: Array<keyof typeof userGenderIdentity> | null;
  race?: Array<keyof typeof userRace> | null;
  disabledPerson?: boolean | null;
  region?: Region[];
  age?: number | null;
  tags?: string | null;
  status?: string | null;
  classId?: string | null;
  workshopClass?: string | null;
  participant?: string[] | null;
  anyStatus?: boolean | null;
  classStatus?: string | null;
}

interface List {
  _id?: string;
  userId?: string;
  account?: UserAccount;
  name?: string;
  photo?: string;
  localization?: string;
  race?: keyof typeof userRace;
  gender?: keyof typeof userGenderIdentity;
  legalIdentity?: string;
  classId?: string;
  status?: string;
  curriculum?: UserCurriculum;
  workshop?: string;
  diversity?: string;
  behavior?: string;
  technical?: string;
  morning?: "Sim" | "Não";
  afternoon?: "Sim" | "Não";
  bootcamps?: UserBootcamps[];
  bootcampStatus?: keyof typeof userBootcampsStatus;
  tags?: string[];
  comments?: GeneralComment[] | CommentProps[];
}

interface Props {
  data?: List[];
  getData: (filters?: Filters) => void;
  currentClass: Class;
  isWorkshop?: boolean;
  actions: {
    onTag?: (row: List) => void;
    onAllTag?: (listSelected: List[]) => void;
    onForm?: (row: List) => void;
    onDisqualify?: (row: List, status: string) => void;
    onTransferClass?: (row: List, status: string) => void;
    onGiveUp?: (row: List, status: string) => void;
    onReplaced?: (row: List, status: string) => void;
    onActiveClass?: (row: List) => void;
    onAddCertificate?: (row: List) => void;
    onShow?: (row: List) => void;
    onComment?: (row: List) => void;
    onMorning?: (row: List) => void;
    onAfternoon?: (row: List) => void;
    onEvaluate?: (row: List) => void;
    hasCommentUnresolved?: (row: List) => boolean;
    onAllGiveUp?: (listSelected: List[], status: string) => void;
    onAllReplaced?: (listSelected: List[], status: string) => void;
    onAllTransferClass?: (listSelected: List[], status: string) => void;
    onAllDisqualify?: (listSelected: List[], status: string) => void;
    onAllActiveClass?: (
      listSelected: List[],
      status: string | ((prevState: string) => string),
    ) => void;
    onAllMorning?: (listSelected: List[]) => void;
    onAllAfternoon?: (listSelected: List[]) => void;
    onAllAddToRecap?: (listSelected: List[]) => void;
  };
  setPropFilters: Dispatch<SetStateAction<Filters | undefined>>;
}

const Table = ({
  data,
  getData,
  currentClass,
  actions,
  isWorkshop = false,
  setPropFilters,
}: Props): JSX.Element => {
  const [filters, setFilters] = useState<Filters>();

  useEffect(() => setPropFilters(filters), [filters, setPropFilters]);

  const headCells = useMemo(
    () =>
      isWorkshop
        ? [
            {
              id: "name",
              label: "Nome",
              orderBy: false,
              asc: false,
            },
            {
              id: "workshop",
              label: "Nota",
              orderBy: false,
              asc: false,
            },
            {
              id: "diversity",
              label: "Diversidade",
              orderBy: false,
              asc: false,
            },
            {
              id: "behavior",
              label: "Comportamental",
              orderBy: false,
              asc: false,
            },
            {
              id: "technical",
              label: "Técnica",
              orderBy: false,
              asc: false,
            },
            {
              id: "morning",
              label: "Manhã",
              orderBy: false,
              asc: false,
            },
            {
              id: "afternoon",
              label: "Tarde",
              orderBy: false,
              asc: false,
            },
            {
              id: "tags",
              label: "Tags",
              orderBy: false,
              asc: false,
              classColumn: "w-240",
            },

            {
              id: "actions",
              label: "",
              orderBy: false,
              asc: false,
            },
          ]
        : [
            {
              id: "name",
              label: "Nome",
              orderBy: false,
              asc: false,
            },
            {
              id: "gender",
              label: "Gênero",
              orderBy: false,
              asc: false,
            },
            {
              id: "race",
              label: "Raça",
              orderBy: false,
              asc: false,
              classColumn: "w-8",
            },
            {
              id: "localization",
              label: "Região",
              orderBy: false,
              asc: false,
              classColumn: "w-80",
            },
            {
              id: "tags",
              label: "Tags",
              orderBy: false,
              asc: false,
              classColumn: "w-240",
            },
            {
              id: "status",
              label: "Status",
              orderBy: false,
              asc: false,
            },
            {
              id: "actions",
              label: "",
              orderBy: false,
              asc: false,
            },
          ],
    [isWorkshop],
  );

  const optionsAvaliantionTags = useMemo(
    () => [
      { value: "", label: "Todos" },
      { value: "Atenção", label: "Atenção" },
      { value: "Help", label: "Help" },
      { value: "Destaque", label: "Destaque" },
      { value: "Fluindo", label: "Fluindo" },
      { value: "Excelente comunicação", label: "Excelente comunicação" },
      { value: "Proatividade", label: "Proatividade" },
      { value: "Colaborativa", label: "Colaborativa" },
      { value: "Boa comunicação", label: "Boa comunicação" },
      { value: "Proatividade razoável", label: "Proatividade razoável" },
      { value: "Colaboração aceitável", label: "Colaboração aceitável" },
      {
        value: "Comunicação insatisfatória",
        label: "Comunicação insatisfatória",
      },
      {
        value: "Dificuldade no trabalho em equipe",
        label: "Dificuldade no trabalho em equipe",
      },
      { value: "Proatividade ruim", label: "Proatividade ruim" },
    ],
    [],
  );

  const participantOptions = useMemo(
    () => [
      {
        value: undefined,
        label: "Todas",
      },
      {
        value: "ALL",
        label: "Integral",
      },
      {
        value: "MORNING",
        label: "Manhã",
      },
      {
        value: "AFTERNOON",
        label: "Tarde",
      },
      {
        value: "NONE",
        label: "Nenhuma",
      },
    ],
    [],
  );

  const statusOptions = useMemo(() => {
    if (currentClass) {
      const listStatus = [{ value: "ALL", label: "Todos" }];
      const typesStatus = isWorkshop ? CourseStatus : AdminClassViewStatus;

      Object.keys(typesStatus).forEach((key) => {
        listStatus?.push({
          value: [key][0],
          label: typesStatus[key as keyof typeof typesStatus],
        });
      });

      return listStatus;
    }

    return [];
  }, [currentClass, isWorkshop]);

  const options = useCallback((type: string, hasAll?: boolean) => {
    const result: { value: string; label: string }[] = [];

    if (hasAll)
      result?.push({
        value: "ALL",
        label: "Todos",
      });

    if (type === "genderIdentity")
      Object.keys(ProfileGender).forEach((key) => {
        result?.push({
          value: [key][0],
          label: ProfileGender[key as keyof typeof ProfileGender],
        });
      });

    if (type === "race")
      Object.keys(ProfileRace).forEach((key) => {
        result?.push({
          value: [key][0],
          label: ProfileRace[key as keyof typeof ProfileRace],
        });
      });

    return result;
  }, []);

  const onToRedash = useCallback(
    (row: { legalIdentity: string }) =>
      window.open(
        `http://dashboard.reprograma.com.br/dashboards/7-pesquisa-por-cpf?p_cpf=${
          row?.legalIdentity ?? ""
        }`,
        "_blank",
      ),
    [],
  );

  const handleFilter = useCallback(
    (value?: string) => {
      const fields = {
        ...filters,
        participant: value,
      } as Filters;
      setFilters(fields);
      getData(fields);
    },
    [filters, getData],
  );

  return (
    <Card
      removeTitleBorder
      titleHeader="Alunas"
      className="grid-column-1-13 grid-row-4 margin-bottom-32 media-small-screen border-radius-mobile margin-bottom-16-mobile"
      bodyClassName="padding-0"
    >
      <TableRow className="admin-class-filters">
        <TextField
          autoComplete="off"
          placeholder="Buscar aluna"
          type="text"
          InputProps={{
            className: "search-header-profile Mui-input headerSearch",
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          className="search-header search-filter"
          value={filters?.name ?? ""}
          onChange={(e) =>
            setFilters((prev) => ({ ...prev, name: e.target.value }))
          }
          onBlur={() => getData(filters)}
        />
        <TextField
          autoComplete="off"
          placeholder="Buscar e-mail"
          type="text"
          InputProps={{
            className: "search-header-profile Mui-input headerSearch",
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          className="search-header search-filter"
          value={filters?.email ?? ""}
          onChange={(e) =>
            setFilters((prev) => ({ ...prev, email: e.target.value }))
          }
          onBlur={() => getData(filters)}
        />
        {!isWorkshop && (
          <Select
            className="status-filter last-filter"
            label="Raça"
            options={options("race", true)}
            value={filters?.race ?? ""}
            onChange={(e) =>
              e.target.value !== "ALL"
                ? setFilters((prev) => ({
                    ...prev,
                    race: [e.target.value],
                  }))
                : setFilters((prev) => ({
                    ...prev,
                    race: undefined,
                  }))
            }
            onBlur={() => getData(filters)}
          />
        )}
        {!isWorkshop && (
          <Select
            className="status-filter last-filter"
            label="Gênero"
            options={options("genderIdentity", true)}
            value={filters?.genderIdentity ?? ""}
            onChange={(e) =>
              e.target.value !== "ALL"
                ? setFilters((prev) => ({
                    ...prev,
                    genderIdentity: [e.target.value],
                  }))
                : setFilters((prev) => ({
                    ...prev,
                    genderIdentity: undefined,
                  }))
            }
            onBlur={() => getData(filters)}
          />
        )}
        {!isWorkshop && (
          <Select
            className="status-filter last-filter"
            label="Status"
            value={filters?.status ?? ""}
            onChange={(e) =>
              e.target.value !== "ALL"
                ? setFilters((prev) => ({
                    ...prev,
                    status: e.target.value,
                  }))
                : setFilters((prev) => ({
                    ...prev,
                    status: undefined,
                  }))
            }
            onBlur={() => getData(filters)}
            options={statusOptions}
          />
        )}
        <Select
          className="status-filter last-filter"
          label="Tags"
          value={filters?.tags ?? ""}
          onChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              tags: e.target.value,
            }))
          }
          onBlur={() => getData(filters)}
          options={optionsAvaliantionTags}
        />
        {isWorkshop && (
          <div className="admin-class-radios">
            <span className="admin-class-radios-title">Presença</span>
            <RadioGroup
              value={filters?.participant ? filters?.participant : undefined}
            >
              <div>
                {participantOptions?.map(({ value, label }) => (
                  <FormControlLabel
                    key={Math.random()}
                    value={value}
                    control={<Radio />}
                    label={label}
                    checked={
                      filters?.participant
                        ? value === (filters?.participant as unknown as string)
                        : undefined
                    }
                    className="margin-right-32"
                    onChange={() => handleFilter(value)}
                  />
                ))}
              </div>
            </RadioGroup>
          </div>
        )}
      </TableRow>
      <TableComponent
        hasMoreActions
        emptyText="Não há alunas cadastradas"
        enumStatus={AdminClassViewStatus}
        headCells={headCells}
        list={data ?? []}
        onToRedash={!isWorkshop ? onToRedash : undefined}
        onReload={getData}
        {...actions}
      />
    </Card>
  );
};

export default memo(Table);
