import React, { memo } from "react";
import "./styles.scss";

interface Props {
  className?: string;
  companyName: string | undefined;
}

const JobCompany = ({ className = "", companyName }: Props): JSX.Element => (
  <p className={`job-inf-text job-inf-company ${className}`}>
    {companyName ?? ""}
  </p>
);

export default memo(JobCompany);
