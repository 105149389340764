import React, { memo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/reducers";

const ViewAccount = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.userState);

  return (
    <div className="grid-1-column flex-row-center">
      <div className="flex flex-col">
        <p className="title-view">E-mail</p>
        <p className="view-account">{user?.login?.identification}</p>
      </div>
      <div className="flex flex-col">
        <p className="title-view">Senha</p>
        <p className="view-account">••••••••</p>
      </div>
    </div>
  );
};

export default memo(ViewAccount);
