import React from "react";
import { Modal } from "../../../components";
import { Close } from "../../../../assets/customIcons/index";
import Grades from "./Grades";
import "../style.scss";

interface Props {
  id: string;
  open: boolean;
  name: string;
  onClose: () => void;
  reload: () => void;
}

const Proposes = ({ id, open, name, onClose, reload }: Props): JSX.Element => (
  <Modal
    open={open}
    onClose={onClose}
    className="admin-subscriptions-infos"
    maxWidth="md"
  >
    <div className="admin-subscriptions-infos-header">
      <div className="admin-subscriptions-infos-header-top">
        <p className="p-text-header-action-card">Notas - {name}</p>
        <Close
          className="close-header-action-card margin-left-6 cursor-pointer"
          widthIcon="28"
          onClick={onClose}
        />
      </div>
    </div>
    <Grades id={id} onClose={onClose} reload={reload} />
  </Modal>
);

export default Proposes;
