/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Slider } from "@material-ui/core";
import { Draggable as DraggableComponent } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { Delete, Move } from "../../../../assets/icons";
import { Add } from "../../../../assets/customIcons";
import {
  Card,
  Draggable,
  IconButton,
  InputAutocomplete,
} from "../../../components";
import { updateUser } from "../../../store/actions/userActions";
import { setNextButton } from "../../../store/actions/nextButtonAction";
import { getAutocomplete } from "../../../services/functions";
import { autoCompleteType } from "../../../types/enumerators";
import { RootState } from "../../../store/reducers";
import { registerAutoComplete } from "../../../utils";

interface Props {
  title: string;
  addLanguages: boolean;
  setAddLanguages: Dispatch<SetStateAction<boolean>>;
  setIsOptional: Dispatch<SetStateAction<boolean>>;
  setIsValid: Dispatch<SetStateAction<boolean>>;
}

const styles = {
  slider: {
    width: "200px",
    marginLeft: "40px",
  },
};

const Languages = ({
  title,
  addLanguages,
  setAddLanguages,
  setIsOptional,
  setIsValid,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.userState);
  const [width, setWidth] = useState(window.innerWidth);
  const [_curriculum, _setCurriculum] = useState(user?.curriculum);
  const [languagesOptions, setLanguagesOptions] = useState<string[]>();

  useEffect(() => {
    if (
      Object.keys(user?.curriculum)?.length &&
      !Object.keys(_curriculum)?.length
    )
      _setCurriculum({ ...user?.curriculum });
  }, [_curriculum, user?.curriculum]);

  useEffect(() => {
    if (_curriculum?.languages?.length) {
      setIsOptional(false);
      setIsValid(true);
    } else setIsOptional(true);
  }, [_curriculum?.languages, setIsOptional, setIsValid]);

  useEffect(() => {
    if (_curriculum?.languages?.length) {
      _curriculum?.languages?.sort(
        (a: { order: number }, b: { order: number }) => {
          if ("order" in a && "order" in b) {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          }
          return 0;
        },
      );
    }
  }, [_curriculum?.languages]);

  const updateLevel = useCallback(
    (proecifience: number | number[], index: number) => {
      const { languages: newLanguages } = _curriculum;
      if (typeof proecifience === "number")
        newLanguages[index].proecifience = proecifience;
      _setCurriculum({ ..._curriculum, languages: newLanguages });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, languages: newLanguages },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_curriculum, dispatch, user],
  );

  const loadLanguages = useCallback(async () => {
    const response = await getAutocomplete(autoCompleteType.LANGUAGE);

    const skillOptions = response?.map((option) => option?.name);

    setLanguagesOptions(skillOptions);
  }, []);

  useEffect(() => {
    if (!languagesOptions) loadLanguages();
  }, [languagesOptions, loadLanguages]);

  const onUpdate = useCallback(
    async (value: string[] | string | null) => {
      let { languages: newLanguages } = _curriculum;
      if (value && typeof value !== "string") {
        newLanguages = value?.map((item) => {
          if (newLanguages) {
            const language = newLanguages.find(
              (innerItem: { language: string }) => innerItem.language === item,
            );
            return {
              language: item,
              proecifience: language?.proecifience || 1,
              order: language?.order ?? newLanguages?.length ?? 0,
            };
          }
          return { language: item, proecifience: 1, order: 1 };
        });
      }

      registerAutoComplete(value, autoCompleteType.LANGUAGE);
      _setCurriculum({ ..._curriculum, languages: newLanguages });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, languages: newLanguages },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_curriculum, dispatch, user],
  );

  const onRemove = useCallback(
    (index: number) => {
      let { languages: oldLanguages } = _curriculum;
      oldLanguages?.splice(index, 1);
      oldLanguages = oldLanguages?.map((item: any, i: number) => ({
        ...item,
        order: i,
      }));
      _setCurriculum({ ..._curriculum, languages: oldLanguages });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, languages: oldLanguages },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_curriculum, dispatch, user],
  );

  const reorderItem = useCallback(
    (items: any) => {
      let { languages: newLanguages } = _curriculum;
      newLanguages = items?.map((item: any, index: number) => ({
        ...item,
        order: index,
      }));
      _setCurriculum({ ..._curriculum, languages: newLanguages });
      dispatch(
        setNextButton(() =>
          dispatch(
            updateUser({
              ...user,
              curriculum: { ..._curriculum, languages: newLanguages },
            }) as unknown as Action,
          ),
        ) as unknown as Action,
      );
    },
    [_curriculum, dispatch, user],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className={`height-fit grid-column-5-12 grid-column-1-11-mobile margin-top-16 grid-7-column ${
        width > 990 ? "grid-row-1" : "grid-row-2"
      }`}
    >
      <Card
        borderNone
        titleHeader={title}
        className={`grid-column-1-8 height-fit-content ${
          addLanguages || width < 991 ? "border-card" : ""
        }`}
      >
        <p className="paragraph-work-title">
          Você tem conhecimento em outros idiomas? É só adicionar abaixo.
        </p>
        {!addLanguages ? (
          <IconButton
            icon={
              <Add
                widthIcon={width < 991 ? 20 : 24}
                heightIcon={width < 991 ? 20 : 24}
              />
            }
            text="Adicionar idioma"
            onClick={() => setAddLanguages(true)}
            className="icon-button-primary icon-button-primary-mobile"
          />
        ) : (
          <InputAutocomplete
            multiple
            label="Idioma"
            helperText="Pressione Enter para adicionar"
            className="language-input"
            options={languagesOptions ?? [""]}
            getOptionLabel={(option) => option}
            value={
              _curriculum?.languages?.map(
                (item: { language: string }) => item.language,
              ) || []
            }
            onChange={(values) => onUpdate(values)}
          />
        )}
      </Card>
      {_curriculum?.languages?.length > 0 && (
        <div className="grid-column-1-8 grid-row-2">
          {_curriculum?.languages?.length > 1 && (
            <p className="paragraph-reorder">
              Clique e arraste para ordenar como preferir
            </p>
          )}
          <Draggable items={_curriculum?.languages} reorderItem={reorderItem}>
            {_curriculum?.languages?.map(
              (
                item: {
                  language: string;
                  proecifience: number;
                },
                index: number,
              ) => (
                <DraggableComponent
                  key={Math.random()}
                  draggableId={index.toString()}
                  index={index}
                  isDragDisabled={_curriculum?.languages?.length === 1}
                >
                  {(innerProvided) => (
                    <div
                      ref={innerProvided.innerRef}
                      {...innerProvided.draggableProps}
                      {...innerProvided.dragHandleProps}
                    >
                      <Card className="margin-top-16">
                        <div className="flex-row-center space-between-responsive">
                          <div className="width-30">
                            <div className="pills">{item.language}</div>
                          </div>
                          {width > 990 ? (
                            <div className="width-100">
                              <Slider
                                value={item.proecifience}
                                step={1}
                                min={1}
                                max={3}
                                onChange={(_, value) =>
                                  updateLevel(value, index)
                                }
                                marks={[
                                  { value: 1, label: "Básico" },
                                  { value: 2, label: "Intermediário" },
                                  { value: 3, label: "Avançado" },
                                ]}
                                style={styles.slider}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="flex">
                            <IconButton
                              icon={<Delete />}
                              onClick={() => onRemove(index)}
                            />
                            {_curriculum?.languages?.length > 1 && (
                              <IconButton
                                icon={<Move />}
                                className="margin-left-8"
                              />
                            )}
                          </div>
                        </div>
                        {width < 991 ? (
                          <div className="width-100">
                            <Slider
                              value={item.proecifience}
                              step={1}
                              min={1}
                              max={3}
                              onChange={(_, value) => updateLevel(value, index)}
                              marks={[
                                { value: 1, label: "Básico" },
                                { value: 2, label: "Intermediário" },
                                { value: 3, label: "Avançado" },
                              ]}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Card>
                    </div>
                  )}
                </DraggableComponent>
              ),
            )}
          </Draggable>
        </div>
      )}
    </div>
  );
};

export default memo(Languages);
