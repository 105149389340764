import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { Button, Modal, Select } from "../../../components";
import { AvatarEmpty, Close } from "../../../../assets/customIcons";
import {
  classVacancyTemplate,
  classVacancyType,
} from "../../../types/enumerators";
import { ClassVacancySubscription, User } from "../../../types/interfaces";
import {
  FormEdit,
  MediaView,
  TalentAcademy,
  FormView,
  MediaEdit,
  WorkshopView,
  WorkshopEdit,
} from "../Steps";
import {
  getClassVacancySubscriptionById,
  getUserById,
} from "../../../services/functions";
import { UserViewProps } from "../Steps/types";
import { setLoad } from "../../../store/actions/configurationsActions";
import "../style.scss";

const UserView = ({
  open,
  onclose,
  data,
  subscriptionId,
  classVacancy,
  reload,
  isVolunteer,
}: UserViewProps): JSX.Element => {
  const [user, setUser] = useState<User>();
  const [subscription, setSubscription] = useState<ClassVacancySubscription>();
  const [menu, setMenu] = useState<string>();
  const [index, setIndex] = useState<number>(1);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();

  const items = useMemo(() => {
    const itemsMenu = [
      {
        label: "Dados pessoais",
        value: 1,
      },
      {
        label: "Endereço",
        value: 2,
      },
      {
        label: "Você e a {reprograma}",
        value: 3,
      },
      {
        label: "Educação",
        value: 4,
      },
      {
        label: "Perfil socioeconômico",
        value: 5,
      },
    ];

    if (classVacancy?.template === classVacancyTemplate.EDV)
      itemsMenu.push({
        label: "Comprovante",
        value: 6,
      });

    if (
      classVacancy?.template === classVacancyTemplate.TET ||
      classVacancy?.template === classVacancyTemplate.LEVEL_ONE
    )
      itemsMenu.push({
        label: "Critério Brasil",
        value: 7,
      });

    return itemsMenu;
  }, [classVacancy?.template]);

  const options = useMemo(() => {
    if (subscription && classVacancy) {
      const findItems = subscription?.stages?.map((item) => {
        const find = classVacancy?.stages?.find(
          (subitem) => item?.stageId === subitem?.stage_id,
        );
        return {
          ...item,
          type: find?.type,
          title: find?.title,
        };
      });

      return findItems?.filter(
        ({ type }) => type !== classVacancyType.EVALUATE,
      );
    }

    return [];
  }, [classVacancy, subscription]);

  const getSubscription = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getClassVacancySubscriptionById(subscriptionId);
    setSubscription(response);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, subscriptionId]);

  useEffect(() => {
    if (open && !!subscriptionId && subscription?._id !== subscriptionId)
      getSubscription();
  }, [getSubscription, open, subscription, subscriptionId]);

  const getUser = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);
    const response = await getUserById(subscription?.userId ?? "");
    setUser(response as User);
    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, subscription?.userId]);

  useEffect(() => {
    if (!user && !!subscription?.userId) getUser();
  }, [getUser, subscription?.userId, user]);

  const onClose = useCallback(() => {
    setMenu("");
    setIndex(1);
    setIsEdit(false);
    reload();
    onclose();
  }, [onclose, reload]);

  const onReload = useCallback(async () => {
    await getSubscription();
    await getUser();
  }, [getSubscription, getUser]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="md"
      className="admin-subscriptions-infos"
    >
      <div className="admin-subscriptions-infos-header">
        <div className="admin-subscriptions-infos-header-top">
          <div>
            {user?.photo ? (
              <img
                alt=""
                src={data?.photo}
                className="img-avatar-header-modal"
              />
            ) : (
              <AvatarEmpty
                className="border-avatar-empty img-attachment-photo-small"
                heightIcon={32}
                widthIcon={32}
              />
            )}
            <p className="p-text-header-action-card">{data?.name}</p>
          </div>
          <Close
            className="close-header-action-card margin-left-6 cursor-pointer"
            widthIcon="28"
            onClick={onClose}
          />
        </div>
        <div className="admin-subscriptions-infos-header-bottom">
          {!isVolunteer &&
            options?.length > 0 &&
            options?.map(({ title, type }) => (
              <button
                key={Math.random()}
                type="button"
                className={menu === type ? "is-active" : ""}
                onClick={() => setMenu(type ?? "")}
              >
                {title}
              </button>
            ))}
        </div>
      </div>

      <div className={`admin-subscriptions-infos-body ${menu}`}>
        {menu === classVacancyType.SUBSCRIPTION && (
          <>
            <Select
              value={index ?? ""}
              label="Selecione os dados"
              options={items}
              onChange={(e) => setIndex(e.target.value)}
              className="admin-subscriptions-infos-body-select"
            />
            {!isEdit ? (
              <FormView
                subscription={subscription}
                user={user as User}
                step={index}
              />
            ) : (
              <FormEdit
                user={user as User}
                setUser={setUser}
                subscription={subscription as ClassVacancySubscription}
                setSubscription={setSubscription}
                course={classVacancy}
                step={index}
                setIsEdit={setIsEdit}
                reload={onReload}
              />
            )}
          </>
        )}

        {menu === classVacancyType.UPLOAD_MEDIAS && (
          <div>
            {!isEdit ? (
              <MediaView subscription={subscription} course={classVacancy} />
            ) : (
              <MediaEdit
                subscription={subscription}
                course={classVacancy}
                reload={onReload}
                setIsEdit={setIsEdit}
              />
            )}
          </div>
        )}

        {menu === classVacancyType.TALENT_ACADEMY && (
          <TalentAcademy subscription={subscription} course={classVacancy} />
        )}

        {menu === classVacancyType.WORKSHOP && (
          <div>
            {!isEdit ? (
              <WorkshopView subscription={subscription} course={classVacancy} />
            ) : (
              <WorkshopEdit
                subscription={subscription}
                course={classVacancy}
                reload={reload}
                setIsEdit={setIsEdit}
              />
            )}
          </div>
        )}

        {!isEdit && !isVolunteer && (
          <div
            className={`admin-subscriptions-infos-buttons ${
              menu === classVacancyType.WORKSHOP ? "margin-right-24" : ""
            }`}
          >
            <Button
              text="Cancelar"
              onClick={onClose}
              outline
              appearance="secondary"
            />

            {menu !== classVacancyType.TALENT_ACADEMY && (
              <Button text="Editar" onClick={() => setIsEdit(true)} />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default memo(UserView);
