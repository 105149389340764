/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useEffect, useState } from "react";
import { Paper, TextField, InputAdornment } from "@material-ui/core";
import { Checkbox } from "..";
import { Close } from "../../../assets/customIcons";
import { ChevronDown, ChevronWhite, Search } from "../../../assets/icons";
import ButtonMenu from "../ButtonMenu";
import Counter from "../Counter";
import "./styles.scss";

interface Props {
  onClick?: any;
  onChange?: any;
  onSearch?: any;
  onClear?: any;
  onClose?: any;
  isOpen?: boolean;
  text?: string;
  items?: { text: string; onClick: () => void }[];
  values?: string[];
  checkedList?: string[];
  className?: string;
  prop?: string;
  notIcon?: boolean;
  typeCompany?: boolean;
  currentCompany?: boolean;
  widthCompany?: boolean;
}

const ButtonFilter = ({
  onClick,
  onChange,
  onSearch,
  onClear,
  onClose,
  isOpen,
  text,
  items,
  values,
  checkedList,
  className,
  prop,
  notIcon,
  typeCompany,
  currentCompany,
  widthCompany,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return items && items.length > 0 ? (
    <ButtonMenu
      text={text}
      items={items}
      icon={!notIcon && <ChevronWhite />}
      endIcon
      className={`menu-search ${className || ""}`}
      classNamePoper="menu-search-poper"
    />
  ) : (
    <div key={Math.random()}>
      <button
        type="button"
        className={`
          ${typeCompany && width < 991 ? "width-156-mobile" : ""}
          ${currentCompany && width < 991 ? "width-148" : ""}
          ${widthCompany && width < 991 ? "width-130" : ""}
            ${
              isOpen
                ? "filter-company-purple"
                : checkedList?.length
                ? "filter-counter"
                : "filter-company"
            }`}
        onClick={onClick}
      >
        {text}
        {!isOpen && checkedList && checkedList?.length > 0 && (
          <Counter circle={checkedList?.length} />
        )}
        {!notIcon && <ChevronDown />}
      </button>
      {isOpen ? (
        <Paper>
          <div className="location-div flex-col">
            <div className="location-content-search">
              <TextField
                autoComplete="off"
                id="recentSearch"
                placeholder="Buscar"
                type="text"
                className=" margin-0-mobile"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                autoFocus
                InputProps={{
                  className: "header-profile-search-profile Mui-input",
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div
              aria-label="close"
              role="button"
              tabIndex={0}
              className="exit-button"
              onClick={() => {
                onClose();
                setSearchValue("");
              }}
            >
              <Close widthIcon="24" heightIcon="24" />
            </div>
            <div className="location-content-button-filter">
              {searchValue && searchValue.length > -1 ? (
                <div>
                  {values
                    ?.filter(
                      (item) =>
                        item.toLowerCase().indexOf(searchValue.toLowerCase()) >
                        -1,
                    )
                    .map((item) => (
                      <div key={Math.random()}>
                        <Checkbox
                          key={Math.random()}
                          id={item}
                          label={item}
                          checked={
                            checkedList?.findIndex(
                              (value) => value === item,
                            ) !== -1
                          }
                          onChange={(e) =>
                            onChange(item, e.target.checked, prop)
                          }
                          className="checkbox-button"
                        />
                      </div>
                    ))}
                </div>
              ) : (
                values?.map((item) => (
                  <div key={Math.random()}>
                    <Checkbox
                      key={Math.random()}
                      id={item}
                      label={item}
                      checked={
                        checkedList?.findIndex((value) => value === item) !== -1
                      }
                      onChange={(e) => onChange(item, e.target.checked, prop)}
                      className="checkbox-button"
                    />
                  </div>
                ))
              )}
            </div>

            <div className="container-buttons">
              <button
                type="button"
                className="clean-button cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                  setSearchValue("");
                }}
              >
                LIMPAR
              </button>
              <button
                type="button"
                className="show-results-button"
                onClick={onSearch}
              >
                VER RESULTADOS
              </button>
            </div>
          </div>
        </Paper>
      ) : null}
    </div>
  );
};

export default memo(ButtonFilter);
