import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const FacebookProfile = ({
  widthIcon,
  heightIcon,
  className,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    className={className}
    fill="none"
    viewBox="0 0 28 28"
  >
    <path
      fill="#1877F2"
      d="M14 2.333C7.558 2.333 2.335 7.58 2.335 14.05c0 5.803 4.206 10.609 9.72 11.54v-9.096H9.242V13.22h2.814v-2.413c0-2.8 1.703-4.327 4.191-4.327 1.192 0 2.216.09 2.513.129v2.927h-1.726c-1.352 0-1.613.646-1.613 1.593v2.09h3.228l-.421 3.272H15.42v9.176c5.772-.706 10.247-5.634 10.247-11.622 0-6.466-5.223-11.712-11.666-11.712z"
    />
  </svg>
);

export default FacebookProfile;
