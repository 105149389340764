import React, { memo } from "react";
import Modal from "../../Modal";
import Button from "../../Button";

interface Props {
  openModal: boolean;
  onClick: () => void;
  onClose: () => void;
}

const ModalUnsubscribe = ({
  openModal,
  onClick,
  onClose,
}: Props): JSX.Element => (
  <Modal
    open={openModal}
    headerAction
    textHeader="Encerrar inscrição "
    onClose={onClose}
    bodyClassName="modal-unsubscribe height-fit-content"
  >
    <p className="p-text">
      Você realmente deseja encerrar sua inscrição no processo seletivo?
    </p>
    <p className="p-text">Essa ação é irreversível</p>
    <div className="flex-row-center margin-top-48  space-between flex-direction-column-reverse">
      <Button
        text="Cancelar"
        className="width-200 width-100-responsive margin-top-20-mobile margin-bottom-20-mobile margin-right-24"
        onClick={onClose}
        outline
        appearance="secondary"
      />
      <Button
        text="Encerrar"
        className="width-100-responsive width-200 button-unsubscribe"
        onClick={onClick}
      />
    </div>
  </Modal>
);

export default memo(ModalUnsubscribe);
