import React, { memo } from "react";
import {
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import LogoBlack from "../../../assets/images/LogoBlack.png";

const CookiesConsent = (): JSX.Element => (
  <div className="consent-form">
    <div className="consent-form-logo">
      <img alt="logoBlack" className="reprograma-logo" src={LogoBlack} />
    </div>

    <p className="consent-form-title">
      POLÍTICA DE COOKIES DA {`{REPROGRAMA}`}
    </p>
    <ol>
      <p>
        Quando <b>“Você”</b>, enquanto <b>“Usuário(a)”</b>, usa nosso website
        institucional{" "}
        <a href="www.reprograma.com.br" target="_blank" rel="noreferrer">
          www.reprograma.com.br
        </a>
        , incluindo a plataforma app.reprograma.com.br <b>(“Site”)</b>,{" "}
        <b>“Nós”</b> ou <b>“{`{reprograma}`}”</b> poderemos armazenar cookies em
        seu navegador e a partir das informações obtidas, utilizá-los para
        diferentes propósitos, conforme será abordado a seguir.
      </p>
      <li className="subtitle">
        <span>O QUE SÃO COOKIES?</span>

        <p>
          Cookies são pequenos arquivos que podem ser armazenados em seu
          dispositivo (computador, smartphone ou outro dispositivo conectado à
          Internet, como smartphones ou tablets) quando você visita nosso Site.
          Os cookies são úteis porque permitem que um site reconheça o
          dispositivo de um(a) Usuário(a).
        </p>
      </li>
      <li className="subtitle">
        <span>QUAIS TIPOS DE COOKIES UTILIZAMOS E PARA QUE OS UTILIZAMOS?</span>

        <TableContainer className="margin-top-24">
          <Table stickyHeader aria-label="simple table" className="table">
            <TableHead className="table-head">
              <TableRow className="table-row">
                <TableCell>Tipo cookies</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Nome cookies</TableCell>
                <TableCell>Duração</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className="table-row">
                <TableCell>Cookies Essenciais</TableCell>
                <TableCell>
                  Permitir que o usuário acesse a aplicação utilizando um login
                  realizado anteriormente.
                  <b />
                  <b />
                  Somente efetuaremos esta análise com o seu consentimento.
                </TableCell>
                <TableCell>Session storage</TableCell>
                <TableCell>2 dias</TableCell>
              </TableRow>

              <TableRow className="table-row">
                <TableCell>Cookies Essenciais</TableCell>
                <TableCell>
                  Cria uma cópia dos dados cadastrais do usuário, fornecidos no
                  cadastro, para garantir o correto funcionamento da plataforma
                  e compartilhar dados entre páginas da plataforma (estes dados
                  NÃO são compartilhados e nem visíveis para terceiros).
                </TableCell>
                <TableCell>Session storage - user</TableCell>
                <TableCell>Enquanto a plataforma estiver aberta</TableCell>
              </TableRow>
            </TableBody>

            <TableRow className="table-row">
              <TableCell>Cookies Essenciais</TableCell>
              <TableCell>
                Cria uma cópia dos dados cadastrais da empresa, fornecidos no
                cadastro, para garantir o correto funcionamento da plataforma e
                compartilhar dados entre páginas da plataforma (estes dados NÃO
                são compartilhados e nem visíveis para terceiros).
              </TableCell>
              <TableCell>Session storage - company</TableCell>
              <TableCell>Enquanto a plataforma estiver aberta</TableCell>
            </TableRow>

            <TableRow className="table-row">
              <TableCell>Cookies Analíticos/ Estatísticos</TableCell>
              <TableCell>
                Utilizado para análise de tráfego.
                <b />
                <b />
                Somente efetuaremos esta análise com o seu consentimento.
              </TableCell>
              <TableCell>GTM (Google)</TableCell>
              <TableCell>
                2 anos, podendo ser eliminado antes de acordo com as definições
                do browser
              </TableCell>
            </TableRow>

            <TableRow className="table-row">
              <TableCell>Cookies de Terceiros</TableCell>
              <TableCell>
                Utilizado para armazenar a chave de licença e configuração do
                serviço de tradução.
                <b />
                <b />
                Somente efetuaremos esta análise com o seu consentimento.
              </TableCell>
              <TableCell>Hand Talks</TableCell>
              <TableCell>Enquanto a plataforma estiver aberta</TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </li>
      <div className="index">
        <p>
          ● <b>Cookies Estritamente Essenciais/Necessários:</b> São cookies
          necessários para o funcionamento do Site. Os cookies estritamente
          necessários ajustam os dados do Site transmitidos para que
          correspondam à sua conexão com a internet, levam você às versões
          seguras do Site. Estes cookies não podem ser desativados.
        </p>
        <p>
          ●<b> Cookies Analíticos/Estatísticos:</b> São cookies que usamos para
          entender como você interage com o Site, a fim de compilar estatísticas
          anônimas e agregadas que nos permitem entender como você utiliza nosso
          Site e, dessa forma, podemos melhorar as estruturas e conteúdo do
          Site. Especificamente, utilizamos o Google Analytics que é a
          ferramenta de análise da Google que permite entendermos número total
          de visitantes, visitantes que retornaram ao seu site e os que o
          visitaram apenas uma vez; de onde os acessos ao site estão vindo;
          mostra o dia da semana, do mês e as horas que seu público mais acessa
          o site. Para mais informações sobre utilização do Google Analytics
          confira{" "}
          <a
            href="https://support.google.com/analytics/answer/7318509?hl=pt-BR"
            target="_blank"
            rel="noreferrer"
          >
            Política de declaração de privacidade - Ajuda do Google Analytics
          </a>
          . Seu tratamento é opcional e pode ser recusado.
        </p>
        <p>
          ● <b>Cookies Funcionais: </b>Cookies Funcionais: Esses cookies
          permitem que o Site se lembre das escolhas que você faz quando navega
          pelo Site e oferecem funcionalidades aprimoradas e recursos mais
          personalizados. Assim, podemos saudá-lo pelo nome e recordar as suas
          preferências (por exemplo, a sua escolha de idioma ou região). Seu
          tratamento é opcional e pode ser recusado.
        </p>
      </div>

      <li className="subtitle margin-left-10">
        <span> COMO POSSO GERENCIAR OU EXCLUIR COOKIES?</span>
        <p>
          Você poderá alterar as permissões, excluir, bloquear ou recusar os
          Cookies no seu navegador a qualquer momento, com exceção dos
          estritamente necessários/ essenciais, conforme tutoriais sobre os
          cookies nos respectivos navegadores nos links abaixo:
        </p>
        <div className="index">
          <p>
            {" "}
            ● Se usa o <b>Internet Explorer</b> -{" "}
            <a
              href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies"
              target="_blank"
              rel="noreferrer"
            >
              https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies
            </a>
          </p>
          <p>
            ● Se usa o <b>Firefox</b> -{" "}
            <a
              href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s"
              target="_blank"
              rel="noreferrer"
            >
              https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s
            </a>
          </p>
          <p>
            ● Se usa o <b>Safari</b> -{" "}
            <a
              href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac"
              target="_blank"
              rel="noreferrer"
            >
              https://support.apple.com/pt-br/guide/safari/sfri11471/mac
            </a>
          </p>
          <p>
            ● Se usa o <b>Google Chrome</b> -{" "}
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR"
              target="_blank"
              rel="noreferrer"
            >
              https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&oco=1&hl=pt-BR
            </a>
          </p>
          <p>
            ● Se usa o <b>o Microsoft Edge</b> -{" "}
            <a
              href="https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies"
              target="_blank"
              rel="noreferrer"
            >
              https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies
            </a>
          </p>
          <p>
            ● Se usa o <b>Opera</b> -{" "}
            <a
              href="https://help.opera.com/en/latest/web-preferences/#cookies"
              target="_blank"
              rel="noreferrer"
            >
              https://help.opera.com/en/latest/web-preferences/#cookies
            </a>
          </p>
        </div>
        <p>
          O Site pode conter links para sites de terceiros, que não estão
          sujeitos às disposições desta Política. Recomendamos que Você consulte
          as políticas de privacidade de terceiros antes de interagir com os
          seus sites.
        </p>
        <p>
          Para outras informações sobre tratamentos de dados pessoais acesse a
          nossa Política de Privacidade, disponível no link a seguir:
          <a
            href="https://drive.google.com/file/d/1rSnNnvPuW0L7OfiI1lIgtrx2tszXsTPe/view"
            target="_blank"
            rel="noreferrer"
          >
            Reprograma_Política de Privacidade.pdf
          </a>
        </p>
        <p>
          A {`{reprograma}`} possui uma Encarregada de Proteção de Dados
          Pessoais nomeada que poderá ser contatada por meio do e-mail
          dados@reprograma.com.br. Nossa Encarregada está disponível para
          atender solicitações, reclamações ou dúvidas. Responderemos a mensagem
          no menor tempo possível.
        </p>
      </li>
    </ol>

    <p>
      Caso <span> ACEITE</span> a " POLÍTICA DE COOKIES DA {`{REPROGRAMA}"`} ",
      por favor, clique em <span>“SIM”</span>.
    </p>
  </div>
);

export default memo(CookiesConsent);
