import React, {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import { RootState } from "../../store/reducers";
import { useAuth } from "../../auth";
import { ChevronDown, LogoBlack } from "../../../assets/icons";
import "./style.scss";

interface Props {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const Header = ({ page, setPage }: Props): JSX.Element => {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { signOut } = useAuth();
  const user = useSelector((state: RootState) => state.userState);
  const history = useHistory();

  const listMenu = useMemo(() => ["Time", "Processos", "Plataforma"], []);

  const handleToggle = useCallback((event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenMenu((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpenMenu(false);
  }, []);

  const handleListKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }, []);

  return (
    <div className="header-profile">
      <div className="flex header-profile-content">
        <div className="sub-div-container-header-profile">
          <button
            aria-label="back"
            type="button"
            className="header-profile-link"
          >
            <LogoBlack />
          </button>
        </div>
      </div>
      <div className="header-profile-menu">
        <div className="header-profile-menu-list">
          {listMenu.map((item, index) => (
            <button
              key={Math.random()}
              type="button"
              className={`
                    header-profile-link ${index === page ? "is-selected" : ""}
             `}
              onClick={() => setPage(index)}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
      <button
        type="button"
        className={`
            button-selected
            ${
              openMenu || history.location.pathname.includes("/account")
                ? "line-menu-purple-avatar"
                : ""
            }
          `}
        onClick={handleToggle}
      >
        <Popper
          open={openMenu}
          anchorEl={anchorEl}
          transition
          disablePortal
          className="header-profile-menu-profile"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Paper>
                  <MenuList
                    autoFocusItem={openMenu}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      key={Math.random()}
                      onClick={() => history.push("/admin-account")}
                    >
                      Minha conta
                    </MenuItem>
                    <MenuItem key={Math.random()} onClick={signOut}>
                      Sair
                    </MenuItem>
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            </Grow>
          )}
        </Popper>
        <div className="line-vertical div-avatar-header-profile">
          <img
            src={user?.profile?.photo}
            alt="imagem"
            className="img-avatar-header-profile"
          />
          <div>
            <h2 className="h2-text-header-profile">{user?.profile?.name}</h2>
            <p className="p-text-header-profile">Admin</p>
          </div>
          <ChevronDown
            className={`chervron-down ${
              openMenu ? "open-menu-header" : "close-menu-header"
            } `}
          />
        </div>
      </button>
    </div>
  );
};

export default memo(Header);
