import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
}

const ExternalLink = ({
  widthIcon,
  heightIcon,
  className,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    className={className}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="m11.704 13.707 6.293-6.293V11h2V4h-7v2h3.586l-6.293 6.293 1.414 1.414z"
      fill="#2E3451"
    />
    <path
      d="M19 18v-4h-2v4H6V7h4V5H6a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2z"
      fill="#2E3451"
    />
  </svg>
);

export default ExternalLink;
