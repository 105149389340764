import React from "react";
import { CircularProgress } from "@mui/material";
import { ClassVacancySubscriptionStage } from "../../../../types/interfaces";
import "../../style.scss";

interface Props {
  stage?: ClassVacancySubscriptionStage;
  steps?: {
    type?: string;
    title?: string;
    label?: string;
    text?: string;
  }[];
}

const View = ({ stage, steps }: Props): JSX.Element => {
  if (!stage)
    return (
      <div className="subscriptions-loading subscriptions-loading-propose">
        <CircularProgress />
      </div>
    );

  return (
    <div className="admin-subscriptions-infos-body">
      <div className="subscriptions-workshop-items">
        <div className="subscriptions-workshop-items-content">
          <div className="label-infos">
            <p className="label-title">Presença manhã</p>
            <p className="label-description">
              {stage?.participant?.morning ? "Sim" : "Não"}
            </p>
          </div>
          <div className="label-infos">
            <p className="label-title">Presença tarde</p>
            <p className="label-description">
              {stage?.participant?.afternoon ? "Sim" : "Não"}
            </p>
          </div>
        </div>
        <div className="subscriptions-workshop-items-content">
          {steps?.map((step) => {
            const upperCaseText = step?.label?.toUpperCase();
            const gradeVerification = stage?.grade?.find(
              (gradeItem) => gradeItem?.type === upperCaseText,
            );

            return (
              <div className="label-infos" key={step?.label}>
                <p className="label-title">{step?.text}</p>
                <p className="label-description">
                  {gradeVerification?.value ?? 0}
                </p>
              </div>
            );
          })}
          <div className="label-infos">
            <p className="label-title">Nota final</p>
            <p className="label-description">
              {stage?.grade?.find(({ type }) => type === "FINAL")?.value}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
