import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const ProposalsNew = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#939393"
      stroke="#939393"
      strokeWidth=".4"
      d="M21.631 9.12c-.228-.257-.54-.424-.881-.471v0l-5.315-.735-2.104-5.026c-.307-.736-1.152-1.083-1.888-.776-.35.146-.63.425-.776.776L8.564 7.914l-5.316.734c-.792.11-1.344.842-1.234 1.634.047.34.214.652.47.88l4.006 3.572-.823 5.604c-.09.569.172 1.136.662 1.438.484.31 1.106.297 1.578-.03L12 18.965l4.092 2.785c.66.448 1.559.277 2.007-.384.204-.3.287-.665.234-1.024l-.823-5.606 4.005-3.571c.596-.532.65-1.447.117-2.044zm-5.36 4.808c-.18.16-.267.4-.231.637l.857 6-4.494-3.056c-.243-.165-.56-.165-.803 0l-4.52 3.037.879-5.984c.034-.237-.053-.475-.231-.634l-4.285-3.863 5.72-.79c.25-.034.463-.198.56-.431l2.285-5.416.005.011 2.261 5.402c.097.233.31.398.561.432l5.727.824-4.292 3.83z"
    />
    <circle cx="18" cy="6" r="4" fill="#D30000" />
  </svg>
);

export default ProposalsNew;
