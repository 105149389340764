import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const Education = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#7E459B"
      stroke="#7E459B"
      strokeWidth=".4"
      d="M30.116 11.439l-13.75-6.03c-.233-.101-.497-.101-.73 0l-13.75 6.03c-.736.322-.736 1.38 0 1.702l4.947 2.17v5.79c0 3.121 4.027 5.566 9.168 5.566 5.14 0 9.167-2.445 9.167-5.566v-5.79l.917-.403v9.903c0 .513.41.928.916.928.507 0 .917-.415.917-.928V14.104l2.198-.963c.735-.322.735-1.38 0-1.702zM16.001 7.272l11.445 5.018-.908.398-10.424-1.319c-.502-.063-.96.297-1.023.806-.063.508.294.972.796 1.035l7.342.929L16 17.308 4.555 12.29l11.446-5.018zm7.333 13.83c0 .873-.708 1.773-1.944 2.468-2.944 1.655-7.826 1.66-10.778 0-1.236-.695-1.945-1.595-1.945-2.469v-4.987l6.97 3.056c.231.102.495.102.728 0l6.97-3.056v4.987z"
    />
  </svg>
);

export default Education;
