/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from "react";
import ErrorMobile from "./ErrorMobile";
import Errors from "./Errors";

interface Props {
  error?: 403 | 404 | 500 | 503 | 504 | "Ops!";
  mobile?: boolean;
}

const Error = ({ error, mobile }: Props): JSX.Element =>
  mobile ? <ErrorMobile /> : <Errors error={error} />;

export default memo(Error);
