import React from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
}

const PortfolioMax = ({ widthIcon, heightIcon }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "32"}
    height={heightIcon ?? "32"}
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      fill="#7E459B"
      d="M26.134 4.667H5.867c-1.397 0-2.533 1.13-2.533 2.518v17.63c0 1.389 1.136 2.518 2.533 2.518h20.267c1.397 0 2.533-1.13 2.533-2.518V7.185c0-1.389-1.136-2.518-2.533-2.518zM5.867 24.815V9.704h20.267l.003 15.11H5.867z"
    />
    <path
      fill="#7E459B"
      d="M12.572 12.591L7.876 17.26l4.696 4.668 1.79-1.78-2.904-2.888 2.905-2.887-1.791-1.78zm6.858 0l-1.791 1.78 2.904 2.888-2.904 2.888 1.79 1.78 4.696-4.668-4.695-4.668z"
    />
  </svg>
);

export default PortfolioMax;
