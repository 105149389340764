import React, { MouseEventHandler } from "react";

interface Props {
  widthIcon?: number | string;
  heightIcon?: number | string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const Edit = ({
  widthIcon,
  heightIcon,
  className,
  onClick,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon ?? "24"}
    height={heightIcon ?? "24"}
    className={className}
    onClick={onClick}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#606060"
      fillRule="evenodd"
      d="M19.753 7.615c.33.329.33.862 0 1.191L8.806 19.753c-.158.158-.372.247-.595.247H4.842c-.223 0-.437-.089-.595-.247-.158-.158-.247-.372-.247-.595v-3.369c0-.223.089-.437.247-.595L15.194 4.247c.329-.33.862-.33 1.19 0 .33.329.33.862 0 1.19l-10.7 10.701v2.178h2.178l10.7-10.7c.33-.33.862-.33 1.191 0z"
      clipRule="evenodd"
    />
    <path
      fill="#606060"
      fillRule="evenodd"
      d="M15.194 4.247c.329-.33.862-.33 1.19 0l3.37 3.368c.328.329.328.862 0 1.191-.33.329-.863.329-1.192 0l-3.368-3.368c-.329-.33-.329-.862 0-1.191zM16.036 11.332l-3.368-3.368 1.19-1.191 3.369 3.368-1.19 1.191z"
      clipRule="evenodd"
    />
  </svg>
);

export default Edit;
