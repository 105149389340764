/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useMemo, useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { HeaderProfile, Layout, ModalComments } from "../../../components";
import {
  Class,
  ClassVacancySubscriptionStage,
  ClassVacancy,
  UserBootcamps,
  GeneralComment,
  Comments as CommentsProps,
} from "../../../types/interfaces";
import { RootState } from "../../../store/reducers";
import { hasAdminClassWorkshopRole, region } from "../../../utils";
import { ProfileRace, ProfileGender } from "../../../types/constants/User";
import {
  getClassById,
  getPages,
  filterClassVacancySubscription,
  getClassVacancySubscriptionById,
  updateClassVacancySubscriptionById,
  filterClassVacancy,
} from "../../../services/functions";
import {
  classVacancyType,
  userGenderIdentity,
  userRace,
} from "../../../types/enumerators";
import { setLoad } from "../../../store/actions/configurationsActions";
import Errors from "../../Errors";
import { Count, Infos, Pagination, Path, Table } from "../Components";
import { Documents, Proposes, Tags } from "../Modal";
import Buttons from "./buttons";
import "../style.scss";
import Steps from "./steps";

interface ParamsProps {
  id: string;
}

interface List {
  _id: string;
  userId: string;
  name: string;
  photo: string;
  localization: string;
  race: keyof typeof userRace;
  gender: keyof typeof userGenderIdentity;
  workshop: string;
  diversity: string;
  behavior: string;
  technical: string;
  morning: "Sim" | "Não";
  afternoon: "Sim" | "Não";
  bootcamps: UserBootcamps[];
  tags: string[];
  comments: CommentsProps[];
}

interface Data {
  _id: string;
  userId: string;
  stages: {
    participant?: {
      morning?: boolean;
      afternoon?: boolean;
    };
  }[];
  user: {
    name: string;
    photo: string;
    legalIdentity: string;
    genderIdentity: string | number;
    race: string | number;
    state: string;
  };
  workshopScore: number;
  diversityScore: number;
  behaviorScore: number;
  technicalScore: number;
  bootcamps: [
    {
      classId: string;
      status: string;
    },
  ];
  tags: string[];
  miscellaneous?: {
    generalComment?: GeneralComment[];
  };
}

interface Filters {
  name?: string | null;
  email?: string | null;
  tags?: string | null;
  participant?: string[] | null;
}

const Workshop = (): JSX.Element => {
  const { id } = useParams<ParamsProps>();

  const [pageError, setPageError] = useState(false);

  const [currentClass, setCurrentClass] = useState<Class>();
  const [classVacancyId, setClassVacancyId] = useState<string>();
  const [workshopStageId, setWorkshopStageId] = useState<string>();
  const [title, setTitle] = useState();
  const [list, setList] = useState<List[]>();
  const [filters, setFilters] = useState<Filters>();

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [totalFilteredList, setTotalFilteredList] = useState(0);

  const [openModalDocument, setOpenModalDocument] = useState(false);
  const [openModalTags, setOpenModalTags] = useState(false);
  const [openModalComments, setOpenModalComments] = useState(false);
  const [openModalGrades, setOpenModalGrades] = useState(false);

  const [userSelected, setUserSelected] = useState<List>();
  const [usersSelected, setUsersSelected] = useState<List[]>();

  const { pageLimit } = useSelector(
    (state: RootState) => state.configurationsState,
  );

  const user = useSelector((state: RootState) => state.userState);

  const dispatch = useDispatch();

  const formattedParticipant = useCallback((value) => {
    switch (value) {
      case "ALL":
        return ["morning", "afternoon"];
      case "MORNING":
        return ["morning"];
      case "AFTERNOON":
        return ["afternoon"];
      case "NONE":
        return [""];
      default:
        return null;
    }
  }, []);

  const mapStudents = useCallback((response) => {
    if (!response) return [];

    return response?.map((item: Data) => ({
      _id: item?._id,
      userId: item?.userId,
      name: item?.user?.name ?? "",
      photo: item?.user?.photo ?? "",
      workshop: item?.workshopScore ?? "",
      diversity: item?.diversityScore ?? "",
      behavior: item?.behaviorScore ?? "",
      technical: item?.technicalScore ?? "",
      morning: item?.stages?.find(
        (innerItem) => innerItem?.participant?.morning,
      )
        ? "Sim"
        : "Não",
      afternoon: item?.stages?.find(
        (innerItem) => innerItem?.participant?.afternoon,
      )
        ? "Sim"
        : "Não",
      gender: item?.user?.genderIdentity
        ? ProfileGender[item?.user?.genderIdentity]
        : undefined,
      race: item?.user?.race ? ProfileRace[item?.user?.race] : undefined,
      localization: item?.user?.state ? region(item?.user?.state) : "",
      bootcamps: item?.bootcamps ?? [],
      tags: item?.tags,
      comments: item?.miscellaneous?.generalComment
        ?.filter(
          (innerItem) => innerItem?.element === classVacancyType.WORKSHOP,
        )
        ?.map((innerItem) => ({
          userId: innerItem?.userId,
          text: innerItem?.text,
          date: innerItem?.date,
          type: innerItem?.element,
          resolved: innerItem?.resolved,
        })),
    }));
  }, []);

  const getListWorkshop = useCallback(
    async (f?: Filters) => {
      if (pageLimit) {
        dispatch(setLoad(true) as unknown as Action);
        const filter = f ?? filters ?? {};
        const currentFilters = {
          ...filter,
          participant: filter?.participant
            ? formattedParticipant(filter?.participant)
            : undefined,
          workshopClass: currentClass?._id,
        };

        const numTotalList = (await filterClassVacancySubscription(
          classVacancyId,
          currentFilters,
          true,
        )) as { size?: number }[];
        if (numTotalList?.length)
          setTotalFilteredList(numTotalList[0]?.size ?? 0);
        else setTotalFilteredList(0);

        setMaxPage(
          await getPages(
            filterClassVacancySubscription,
            classVacancyId,
            currentFilters,
            true,
          ),
        );

        const response = await filterClassVacancySubscription(
          classVacancyId,
          currentFilters,
          false,
          { workshopStageId },
          page,
          pageLimit,
          "user.name",
        );

        setList(mapStudents(response));
        dispatch(setLoad(false) as unknown as Action);
      }
    },
    [
      classVacancyId,
      currentClass?._id,
      dispatch,
      filters,
      formattedParticipant,
      mapStudents,
      page,
      pageLimit,
      workshopStageId,
    ],
  );

  useEffect(() => {
    if (currentClass?._id && classVacancyId && workshopStageId)
      getListWorkshop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClass, classVacancyId, workshopStageId, page, pageLimit]);

  const getClassVacancy = useCallback(async () => {
    const response: { data: ClassVacancy[] } = await filterClassVacancy({
      workshopId: currentClass?._id,
    });
    let stageWId = "";
    let cvId = "";

    if (response?.data && response?.data?.length) {
      response?.data[0]?.stages?.forEach(
        (item: { type?: keyof typeof classVacancyType; stage_id: string }) => {
          if (item?.type === classVacancyType.WORKSHOP) {
            stageWId = item?.stage_id;
            cvId = response?.data[0]?._id ?? "";
          }
        },
      );
    }
    setClassVacancyId(cvId ?? "");
    setWorkshopStageId(stageWId ?? "");
  }, [currentClass?._id]);

  useEffect(() => {
    if (currentClass?._id && (!classVacancyId || !workshopStageId))
      getClassVacancy();
  }, [classVacancyId, currentClass?._id, getClassVacancy, workshopStageId]);

  const getClass = useCallback(async () => {
    const response = await getClassById(id);
    setCurrentClass(response?.data);
    setTitle(response?.data?.title);
  }, [id]);

  useEffect(() => {
    if (!currentClass && id) getClass();
  }, [currentClass, getClass, id]);

  useEffect(() => {
    if (
      id &&
      user?._id &&
      hasAdminClassWorkshopRole(user) &&
      (!user?.account?.classIds || !user?.account?.classIds?.includes(id))
    )
      setPageError(true);
  }, [id, user]);

  const onFrequencyMorning = useCallback(
    async (listSelected) => {
      dispatch(setLoad(true) as unknown as Action);
      for (let i = 0; i < listSelected?.length; i += 1) {
        const response = await getClassVacancySubscriptionById(
          listSelected[i]?._id,
        );

        const findUserWorkshop = response?.stages?.find(
          ({ stageId }: { stageId: string }) => stageId === workshopStageId,
        );

        const stages = response?.stages?.map(
          (general: ClassVacancySubscriptionStage, idx: number) =>
            idx === findUserWorkshop?.order
              ? {
                  ...response?.stages[idx],
                  participant: {
                    ...response?.stages[idx].participant,
                    morning: true,
                  },
                }
              : general,
        );

        if (stages)
          await updateClassVacancySubscriptionById(response?._id ?? "", {
            stages,
          });
      }

      getListWorkshop();
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, getListWorkshop, workshopStageId],
  );

  const onFrequencyAfternoon = useCallback(
    async (listSelected) => {
      dispatch(setLoad(true) as unknown as Action);
      for (let i = 0; i < listSelected?.length; i += 1) {
        const response = await getClassVacancySubscriptionById(
          listSelected[i]?._id,
        );

        const findUserWorkshop = response?.stages?.find(
          ({ stageId }: { stageId: string }) => stageId === workshopStageId,
        );

        const stages = response?.stages?.map(
          (general: ClassVacancySubscriptionStage, idx: number) =>
            idx === findUserWorkshop?.order
              ? {
                  ...response?.stages[idx],
                  participant: {
                    ...response?.stages[idx].participant,
                    afternoon: true,
                  },
                }
              : general,
        );

        if (stages)
          await updateClassVacancySubscriptionById(response?._id ?? "", {
            stages,
          });
      }
      getListWorkshop();
      dispatch(setLoad(false) as unknown as Action);
    },
    [dispatch, getListWorkshop, workshopStageId],
  );

  const submitCommentData = useCallback(
    async (updatedData) => {
      if (updatedData) {
        const miscellaneous = {
          generalComment: updatedData?.map((item: CommentsProps) => ({
            userId: item?.userId,
            text: item?.text,
            date: item?.date,
            element: classVacancyType.WORKSHOP,
            resolved: item?.resolved,
          })),
        };

        await updateClassVacancySubscriptionById(userSelected?._id ?? "", {
          miscellaneous,
        });
      }
    },
    [userSelected?._id],
  );

  const onCancel = useCallback(() => {
    setUserSelected(undefined);
    setUsersSelected(undefined);
    setOpenModalDocument(false);
    setOpenModalTags(false);
    setOpenModalComments(false);
    setOpenModalGrades(false);
    getListWorkshop(filters);
  }, [filters, getListWorkshop]);

  const actions = useMemo(
    () => ({
      onTag: (row: List) => {
        setUsersSelected([row]);
        setOpenModalTags(true);
      },
      onAllTag: (listSelected: List[]) => {
        setUsersSelected(listSelected);
        setOpenModalTags(true);
      },
      onAllMorning: onFrequencyMorning,
      onAllAfternoon: onFrequencyAfternoon,
      onEvaluate: (row: List) => {
        setUserSelected(row);
        setOpenModalGrades(true);
      },
      onComment: (row: List) => {
        setUserSelected(row);
        setOpenModalComments(true);
      },
      onMorning: (row: List) => onFrequencyMorning([row]),
      onAfternoon: (row: List) => onFrequencyAfternoon([row]),
      hasCommentUnresolved: (row: List) =>
        row?.comments?.filter(
          ({ type, resolved = false }) =>
            type === classVacancyType.WORKSHOP && !resolved,
        ).length > 0,
    }),
    [onFrequencyAfternoon, onFrequencyMorning],
  );

  return pageError ? (
    <Errors error={403} />
  ) : (
    <Layout className="admin-class-view admin-subscriptions">
      <HeaderProfile />
      <Path title={title ?? ""} />
      <Buttons
        data={(currentClass ?? {}) as Class}
        onClick={() => setOpenModalDocument(true)}
        reload={() => {
          getClass();
          getListWorkshop();
        }}
      />
      <Count
        isWorkshop
        classId={currentClass?._id ?? ""}
        classVacancyId={classVacancyId}
      />
      <Infos currentClass={(currentClass ?? {}) as Class} />
      <Steps
        currentClass={currentClass ?? ({} as Class)}
        reload={() => getListWorkshop()}
      />
      <Table
        isWorkshop
        data={(list ?? []) as List[]}
        getData={getListWorkshop}
        currentClass={(currentClass ?? {}) as Class}
        setPropFilters={setFilters}
        actions={actions}
      />
      <Pagination
        value={list?.length ?? 0}
        total={totalFilteredList}
        page={page}
        setPage={setPage}
        maxPage={maxPage}
      />

      <Proposes
        id={userSelected?._id ?? ""}
        open={openModalGrades}
        name={userSelected?.name ?? ""}
        onClose={onCancel}
        reload={() => getListWorkshop()}
      />

      {/* <Comments
        open={openModalComments}
        close={onCancel}
        user={userSelected as List}
        classVacancyId={classVacancyId ?? ""}
      /> */}

      <ModalComments
        openModal={openModalComments}
        onClose={onCancel}
        userId={user?._id}
        submit={submitCommentData}
        data={(userSelected?.comments ?? []) as CommentsProps[]}
        dataUser={{
          name: userSelected?.name ?? "",
          photo: userSelected?.photo ?? "",
          userId: userSelected?.userId ?? "",
        }}
      />

      <Documents
        open={openModalDocument}
        close={onCancel}
        medias={currentClass?.medias}
        classId={currentClass?._id ?? ""}
        reload={() => getListWorkshop()}
      />

      <Tags
        isWorkshop
        open={openModalTags}
        close={onCancel}
        users={(usersSelected ?? []) as List[]}
        classId={currentClass?._id ?? ""}
        reload={() => getListWorkshop()}
      />
    </Layout>
  );
};

export default memo(Workshop);
