/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Input, Select } from "../../../components";

interface Props {
  setIsValid: Dispatch<SetStateAction<boolean>>;
  integration: any;
  setIntegration: any;
}

const EditIntegrations = ({
  setIsValid,
  integration,
  setIntegration,
}: Props): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [currentToken, setCurrentToken] = useState(integration?.token ?? "");

  useEffect(() => {
    if (integration?.user && integration?.platform && integration?.token)
      setIsValid(true);
    else setIsValid(false);
  }, [integration, setIsValid]);

  const options = useMemo(
    () => [
      { label: "GREENHOUSE", value: "GREENHOUSE" },
      { label: "GUPY", value: "GUPY" },
    ],
    [],
  );

  const handleChange = useCallback(
    (value: string, prop: string) => {
      setIntegration({
        ...integration,
        [prop]: value,
      });
    },
    [integration, setIntegration],
  );

  const handleChangeSelect = useCallback(
    (value: unknown, prop: string) =>
      setIntegration({
        ...integration,
        [prop]: value as string,
      }),
    [integration, setIntegration],
  );

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="grid-2-column">
      {width > 990 && (
        <p className="title-input grid-column-1-3 grid-row-1">
          Cadastre abaixo uma nova integração.
        </p>
      )}

      <div className="flex grid-column-1-3 grid-row-2 display-block margin-top-32-">
        {width < 991 && <p className="p-mobile">Plataforma*</p>}
        <Select
          id="platform"
          label={width < 991 ? "" : "Plataforma*"}
          value={integration?.platform ?? ""}
          onChange={(e) => handleChangeSelect(e.target.value, "platform")}
          options={options}
          className="margin-right-32 width-40 width-100-mobile"
        />
        {width < 991 && <p className="p-mobile margin-top-20">E-mail*</p>}
        <Input
          id="user"
          type="text"
          value={integration?.user ?? ""}
          disabled={!integration?.platform}
          onChange={(e) =>
            handleChange(e.target.value.toLowerCase().trim(), "user")
          }
          label={width < 991 ? "" : "E-mail*"}
          className="width-60 width-100-mobile"
        />
      </div>
      <div className="flex grid-column-1-3 grid-row-3 margin-top_-16 display-block">
        {width < 991 && <p className="p-mobile">API Key (Token)*</p>}
        <Input
          id="token"
          value={
            currentToken
              ? // eslint-disable-next-line no-unsafe-optional-chaining
                currentToken
                  .substring(0, currentToken.length - 6)
                  ?.replace(/\D/g, "*")
                  .replace(/\d/g, "*") +
                currentToken.substring(
                  currentToken.length - 5,
                  currentToken.length,
                )
              : integration?.token ?? ""
          }
          onFocus={() => {
            setCurrentToken("");
            handleChange("", "token");
          }}
          type="text"
          label={width < 991 ? "" : "Token (api key)*"}
          onChange={(e) => handleChange(e.target.value, "token")}
          disabled={!integration?.user}
          className="width-100"
        />
      </div>
    </div>
  );
};

export default memo(EditIntegrations);
