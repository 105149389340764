import React, { memo, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import moment from "moment-timezone";
import { Card, Layout, UsersProfile, HeaderProfile } from "../../components";
import { User } from "../../types/interfaces";
import "./style.scss";
import { Address, VacancyExternal } from "../../../assets/icons/index";
import { CompanyAvatarEmpty } from "../../../assets/customIcons";
import { getTextDiffDate, isCorporate } from "../../utils";
import {
  createAnalytics,
  getCompany,
  getPlacementVacancy,
  getSuggestionsOfUsers,
} from "../../services/functions";
import {
  placementVacancyManagedBy,
  placementVacancyType,
  placementVacancyTypeOfCandidature,
} from "../../types/enumerators";
import { ManagedBy } from "../../types/constants/Vacancy";
import { RootState } from "../../store/reducers";
import GreenhouseLogo from "../../../assets/images/Greenhouse.png";
import GupyLogo from "../../../assets/images/Gupy.png";
import { setLoad } from "../../store/actions/configurationsActions";

interface ParamsProps {
  id: string;
}

interface VacancyProps {
  externalCompanyName: string;
  vacancyId: string;
  jobTitle: string;
  companyName: string;
  jobStatus: string;
  jobTime: string | Date;
  jobType: string;
  jobPlace: string;
  jobDescription: string;
  desiredSkills?: Array<{
    key?: string;
    level?: number;
  }>;
  jobTypeOfCandidature: "OPEN" | "CLOSE";
  companySite: string;
  url?: string;
  photo: string;
  companyDescription: string;
  companyId: string;
  managedBy: keyof typeof placementVacancyManagedBy;
}

const VacancyDescription = (): JSX.Element => {
  const [width, setWidth] = useState(window.innerWidth);
  const [vacancySelected, setVacancySelected] = useState<VacancyProps>(
    {} as VacancyProps,
  );
  const [users, setUsers] = useState<User[] | null>([]);
  const { id } = useParams<ParamsProps>();
  const user = useSelector((state: RootState) => state.userState);
  const history = useHistory();
  const dispatch = useDispatch();

  const getUsers = useCallback(async () => {
    if (user?.account?.companyIds && user?.account?.companyIds?.length) {
      const response = await getSuggestionsOfUsers(
        user?.account?.companyIds[0] ?? "",
      );
      setUsers(response);
    }
  }, [user?.account?.companyIds]);

  useEffect(() => {
    if (
      isCorporate(user) &&
      user?.account?.companyIds &&
      user?.account?.companyIds?.length
    )
      getUsers();
  }, [getUsers, user]);

  const loadCurrentVacancy = useCallback(async () => {
    dispatch(setLoad(true) as unknown as Action);

    const response = await getPlacementVacancy(id);
    const response2 = await getCompany(response?.companyId ?? "");

    setVacancySelected({
      vacancyId: response?._id ?? "",
      photo: response2?.profile?.logo ?? "",
      companyName: response2?.profile?.name ?? "",
      externalCompanyName: response?.externalCompanyName ?? "",
      companySite: response2?.profile?.site ?? "",
      companyDescription: response2?.profile?.description ?? "",
      url: response?.url ?? "",
      jobDescription: response?.description ?? "",
      desiredSkills: response?.desiredSkills ?? [],
      jobStatus: response?.status ?? "",
      jobTime: response?.startDate ?? "",
      jobTitle: response?.title ?? "",
      jobType: response?.type ?? "",
      jobPlace: response?.place ?? "",
      jobTypeOfCandidature:
        response?.typeOfCandidature ?? placementVacancyTypeOfCandidature.CLOSE,
      companyId: response?.companyId ?? "",
      managedBy: response?.managedBy ?? placementVacancyManagedBy.REPROGRAMA,
    });

    dispatch(setLoad(false) as unknown as Action);
  }, [dispatch, id]);

  useEffect(() => {
    loadCurrentVacancy();
  }, [loadCurrentVacancy]);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const labelSkilllevel = useCallback((level) => {
    switch (level) {
      case 1:
        return "básico";
      case 2:
        return "intermediário";
      case 3:
        return "avançado";
      default:
        return "";
    }
  }, []);

  const onClickVacancy = useCallback(
    async () =>
      createAnalytics({
        userId: user?._id,
        type: "PLACEMENT_EXTERNAL_VACANCY",
        entityId: vacancySelected?.vacancyId ?? "",
        date: moment().tz("America/Sao_Paulo").toISOString(),
      }),
    [user?._id, vacancySelected?.vacancyId],
  );

  return (
    <Layout className="grid-responsive vacancy">
      <HeaderProfile />
      {width > 990 && (
        <div className="grid-column-1-10 grid-row-1">
          <div className="flex-row-center">
            <p className="view-page">Vagas &gt;&nbsp;</p>
            <p className="view-step">{vacancySelected?.jobTitle}</p>
          </div>
        </div>
      )}

      <div className="grid-column-1-10 grid-row-2">
        <div className="vacancy-header">
          <Card hideBorderRadius className="margin-bottom-32">
            <div className="vacancy-header-image" />
            <div className="width-100 flex">
              {vacancySelected?.photo ? (
                <img
                  src={vacancySelected.photo}
                  alt={`${vacancySelected.companyName} Logo`}
                  className="vacancy-header-logo"
                />
              ) : (
                <CompanyAvatarEmpty
                  heightIcon={56}
                  widthIcon={56}
                  className="company-avatar-empty-card margin-bottom-16"
                />
              )}
            </div>
            <h1 className="vacancy-header-title margin-top-16">
              {vacancySelected?.jobTitle}
            </h1>
            <h2 className="vacancy-header-company">
              {vacancySelected?.managedBy ===
              placementVacancyManagedBy.EXTERNAL_VACANCY
                ? vacancySelected?.externalCompanyName
                : vacancySelected?.companyName ?? ""}
            </h2>
            <p className="vacancy-header-date">
              {moment(vacancySelected?.jobTime).isValid() &&
                getTextDiffDate(vacancySelected?.jobTime)}
            </p>
            <div className="flex space-between align-items-end">
              <div className="vacancy-header-location">
                <Address />
                <span className="vacancy-header-type">
                  {vacancySelected?.jobType === placementVacancyType.Remota
                    ? vacancySelected?.jobType
                    : vacancySelected?.jobType === placementVacancyType.Híbrida
                    ? `${vacancySelected?.jobType} - ${vacancySelected?.jobPlace}`
                    : vacancySelected?.jobPlace}
                </span>
              </div>
              {width > 990 &&
                vacancySelected?.managedBy !==
                  placementVacancyManagedBy.REPROGRAMA && (
                  <div className="flex align-items-center">
                    {vacancySelected?.managedBy !==
                      placementVacancyManagedBy.EXTERNAL_VACANCY && (
                      <p className="title-view">Administrado por:</p>
                    )}
                    {vacancySelected?.managedBy ===
                      placementVacancyManagedBy.GREENHOUSE && (
                      <img
                        alt="logo"
                        src={GreenhouseLogo}
                        className="greenhouse-logo-job"
                      />
                    )}
                    <p className="view-bold margin-left-8">
                      {ManagedBy[vacancySelected?.managedBy]}
                    </p>
                  </div>
                )}
            </div>
            {width < 991 &&
              vacancySelected?.managedBy !==
                placementVacancyManagedBy.REPROGRAMA && (
                <div className="flex align-items margin-top-40">
                  <p className="title-view">Administrado por:</p>
                  {vacancySelected?.managedBy ===
                    placementVacancyManagedBy.GREENHOUSE && (
                    <img
                      alt="logo"
                      src={GreenhouseLogo}
                      className="greenhouse-logo-job"
                    />
                  )}
                  {vacancySelected?.managedBy ===
                    placementVacancyManagedBy.GUPY && (
                    <img alt="logo" src={GupyLogo} className="gupy-logo-job" />
                  )}
                  <p className="view-bold margin-left-8">
                    {ManagedBy[vacancySelected?.managedBy]}
                  </p>
                </div>
              )}
          </Card>
          <Card
            hideBorderRadius
            removeTitleBorder
            titleHeader="Sobre a vaga"
            className="vacancy-header-about padding-0"
          >
            {vacancySelected?.desiredSkills &&
              vacancySelected?.desiredSkills.length > 0 && (
                <>
                  <h3 className="title-accordion margin-left-skill">
                    Habilidades
                  </h3>
                  <div className="candidature-skill-vacancy-container margin-left-skill">
                    {vacancySelected.desiredSkills?.map((skill) => (
                      <div
                        key={Math.random()}
                        className="candidature-skill-vacancy"
                      >
                        {`${skill?.key} (`}
                        <span className="italic-text">
                          {labelSkilllevel(skill?.level)}
                        </span>
                        )
                      </div>
                    ))}
                  </div>
                </>
              )}

            {vacancySelected?.jobDescription ? (
              <p className="vacancy-header-descriptions margin-top-0">
                {vacancySelected?.jobDescription}
              </p>
            ) : (
              ""
            )}

            {vacancySelected?.managedBy ===
              placementVacancyManagedBy.EXTERNAL_VACANCY && (
              <>
                <div>
                  <h4>Modelo de trabalho:</h4>
                  <p>{vacancySelected?.jobType}</p>
                </div>

                <div className="flex align-items">
                  <a
                    href={
                      vacancySelected?.url?.includes("http")
                        ? vacancySelected?.url
                        : `http://${vacancySelected?.url}`
                    }
                    target="_blank"
                    rel="noreferrer"
                    className="vacancy-header-external"
                    onClick={onClickVacancy}
                  >
                    Ver vaga
                  </a>
                  <VacancyExternal />
                </div>
              </>
            )}
          </Card>
          {vacancySelected?.managedBy !==
            placementVacancyManagedBy.EXTERNAL_VACANCY && (
            <Card
              hideBorderRadius
              removeTitleBorder
              titleHeader="Sobre a empresa"
              className="vacancy-header-about padding-0 "
            >
              <div>
                <div className="display-inline width-100">
                  {vacancySelected?.photo ? (
                    <img
                      src={vacancySelected?.photo}
                      alt="Logo"
                      className="vacancy-header-logo"
                    />
                  ) : (
                    <CompanyAvatarEmpty
                      widthIcon={60}
                      heightIcon={60}
                      className="company-avatar-empty-card"
                    />
                  )}
                  <div className="vacancy-company">
                    <h2
                      className="vacancy-company-name"
                      onClick={() =>
                        history.push(
                          `/company-profile/${vacancySelected?.companyId}`,
                        )
                      }
                    >
                      {vacancySelected?.companyName}
                    </h2>
                    <a
                      className="vacancy-company-website"
                      href={
                        vacancySelected?.companySite?.includes("http")
                          ? vacancySelected?.companySite
                          : `http://${vacancySelected?.companySite}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {vacancySelected?.companySite}
                    </a>
                  </div>
                </div>
                <p className="vacancy-company-description">
                  {vacancySelected?.companyDescription}
                </p>
              </div>
            </Card>
          )}
        </div>
      </div>
      {isCorporate(user) && <UsersProfile users={users ?? []} row="2" />}
    </Layout>
  );
};
export default memo(VacancyDescription);
