/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, Layout } from "../../components";
import "./style.scss";

interface Props {
  error?: 403 | 404 | 500 | 503 | 504 | "Ops!";
}

const listErrors = {
  "Ops!": {
    text: "Acesso negado!",
    message: "Você não tem permissão para acessar esta página.",
  },
  403: {
    text: "Acesso negado!",
    message: "Você não tem permissão para acessar esta página.",
  },
  404: {
    text: "Ooops!",
    message: "A página que você está tentando acessar não foi encontrada.",
  },
  500: {
    text: "Ooops!",
    message:
      "Esta página está indisponível agora. Mas nossa equipe já está trabalhando nisso.",
  },
  503: {
    text: "Ooops!",
    message: "Este serviço está indisponível agora. Volte mais tarde.",
  },
  504: {
    text: "Ooops!",
    message: "Esta página não está funcionando agora. Volte mais tarde.",
  },
};

const Errors = ({ error }: Props): JSX.Element => {
  const history = useHistory();

  const onBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Layout header back onClickBack={onBack} className="padding-bottom-40">
      <div className="grid-column-1-13">
        <h1 className="error-title">{error}</h1>
        <h2 className="error-text">
          {error ? listErrors[error].text : "Ops!"}
        </h2>
        <h3 className="error-message">
          {error ? listErrors[error].message : ""}
        </h3>
        <div className="error-button">
          <Button text="Voltar" onClick={onBack} />
        </div>
      </div>
    </Layout>
  );
};

export default memo(Errors);
